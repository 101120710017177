import React from 'react';
import {useNewWorksheetState} from "modules/worksheet/new-worksheet/useNewWorksheetState";
import {Stack} from "@mui/material";
import useTranslateFunction from "shared/language/useTranslateFunction";
import CustomerResultList from "modules/worksheet/new-worksheet/results/CustomerResultList";
import SalesOrderResultList from "modules/worksheet/new-worksheet/results/SalesOrderResultList";
import EquipmentResultList from "modules/worksheet/new-worksheet/results/EquipmentResultList";

const NewWorksheetResults = () => {
    const tk = useTranslateFunction(`Worksheet.Generic.NewWorksheet`)
    const s = useNewWorksheetState()

    const customers = s.customersByInfQuery.data?.pages?.flatMap(p => p) ?? []
    const equipments = s.equipmentsByInfQuery.data?.pages?.flatMap(p => p) ?? []
    const salesOrders = s.salesOrdersByInfQuery.data?.pages?.flatMap(p => p) ?? []

    const pageLimit = s.state.pageLimit

    const customersIsLoading = s.customersByInfQuery.isLoading || s.customersByInfQuery.isFetching || s.customersByInfQuery.isFetchingNextPage
    const salesOrdersIsLoading = s.salesOrdersByInfQuery.isLoading || s.salesOrdersByInfQuery.isFetching || s.salesOrdersByInfQuery.isFetchingNextPage
    const equipmentsIsLoading = s.equipmentsByInfQuery.isLoading || s.equipmentsByInfQuery.isFetching || s.equipmentsByInfQuery.isFetchingNextPage

    return (
        <Stack
            direction="column"
            spacing={3}
            width={1}
            alignItems="center"
            justifyContent="center"
            sx={{
                mb: 4
            }}
        >

            {(customers.length > 0 || customersIsLoading) && (
                <CustomerResultList
                    customers={customers}
                    customersIsLoading={customersIsLoading}
                    hasNextPage={s.customersHasNextPage}
                    onCustomersLoadMore={s.loadMoreCustomers}
                    subTablePageSize={s.state.subTablePageSize}
                />
            )}

            {(salesOrders.length > 0 || salesOrdersIsLoading) && (
                <SalesOrderResultList
                    salesOrders={salesOrders}
                    salesOrdersIsLoading={salesOrdersIsLoading}
                    hasNextPage={s.salesOrdersHasNextPage}
                    onSalesOrdersLoadMore={s.loadMoreSalesOrders}
                    subTablePageSize={s.state.subTablePageSize}
                />
            )}

            {(equipments.length > 0 || equipmentsIsLoading) && (
                <EquipmentResultList
                    equipments={equipments}
                    equipmentsIsLoading={equipmentsIsLoading}
                    hasNextPage={s.equipmentHasNextPage}
                    onEquipmentsLoadMore={s.loadMoreEquipments}
                    subTablePageSize={s.state.subTablePageSize}
                />
            )}
        </Stack>
    );
};

export default NewWorksheetResults;