import {CDAMWMWWorksheetSave, CDSMSapPartnerAddress} from "crowbar-api";
import {StringUtils} from "shared/utils/StringUtils";

export interface AddressFormatParams {
    zipCode?: string | undefined | null
    city?: string | undefined | null
    street?: string | undefined | null
    streetNo?: string | undefined | null
}

export default class SapPartnerAddressUtils {
    /*public static addressSapKeyEquals = (o1: CDSMSapPartnerAddress, o2: CDSMSapPartnerAddress): boolean => {
        if (!o1 || !o2) return false
        if (o1 === o2) return true
        return o1.cardCode === o2.cardCode &&
            o1.addressType === o2.addressType &&
            o1.addressName === o2.addressName
    }*/

    public static addressFormatEquals = (o1: CDSMSapPartnerAddress, o2: CDSMSapPartnerAddress): boolean => {
        if (!o1 || !o2) return false
        if (o1 === o2) return true
        return this.addressFormat(o1) === this.addressFormat(o2)
    }

    public static addressKey = (o: CDSMSapPartnerAddress): string => {
        if (!o) return ""
        return this.addressKeyFor(o.cardCode, o.addressType, o.addressName)
    }

    public static addressKeyFor = (cardCode?: string | null, addressType?: string | null, addressName?: string | null): string => {
        return Array.of(cardCode, addressType, addressName).filter(e => !!e).join("-")
    }

    public static addressFormatWorksheet = (ws: CDAMWMWWorksheetSave) => {
        return this.addressFormat({
            zipCode: ws.addressZipCode,
            city: ws.addressCity,
            street: ws.addressStreet,
            streetNo: ws.addressStreetNo
        })
    }

    public static addressFormat = (
        o: {
            zipCode?: string | undefined | null,
            city?: string | undefined | null,
            street?: string | undefined | null,
            streetNo?: string | undefined | null
        } | undefined
    ): string => {
        if (!o) return 'N/A'
        return StringUtils.notEmptyValuesAsArray(o.zipCode, o.city, o.street, o.streetNo).join(' ')
    }
}