import React from 'react';
import {CDSMNNewWorksheetSalesOrder} from "crowbar-api";
import {Button, Card, CardActions, CardContent, CircularProgress, Grid, Typography} from "@mui/material";
import ImageIcon from "shared/components/image-icon/ImageIcon";
import {WorksheetIconImages} from "modules/worksheet/editor/shared/component/images/WorksheetIconImages";
import useTranslateFunction from "shared/language/useTranslateFunction";
import NewWorksheetResultSalesOrderTable
    from "modules/worksheet/new-worksheet/results/NewWorksheetResultSalesOrderTable";
import NewWorksheetEquipmentCard from "modules/worksheet/new-worksheet/results/NewWorksheetEquipmentCard";
import NewWorksheetResultSalesOrderAddresses
    from "modules/worksheet/new-worksheet/results/NewWorksheetResultSalesOrderAddresses";
import {useOpenWorksheetEditor} from "modules/worksheet/editor/state/events/useOpenWorksheetEditor";
import {
    useNewWorksheetFromSalesOrderAction
} from "modules/worksheet/editor/state/events/useNewWorksheetFromSalesOrderAction";
import {toastError, toastSuccess} from "shared/toast/DefaultToasts";
import DateDisplay, {dateDisplayDefaultOptions} from "shared/components/date-display/DateDisplay";
import {useMountedState} from "shared/hook/useMountedState";

export interface NewWorksheetResultSalesOrderProps {
    salesOrder: CDSMNNewWorksheetSalesOrder
    pageSize: number
}

const NewWorksheetResultSalesOrder = ({salesOrder, pageSize}: NewWorksheetResultSalesOrderProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.NewWorksheet`)
    const newWorksheetAction = useNewWorksheetFromSalesOrderAction()
    const openWorksheetEditor = useOpenWorksheetEditor()

    const isMounted = useMountedState()

    const hasSapEquipment = !!salesOrder.sapEquipmentId

    const onNewWorksheetClick = async () => {
        if (!salesOrder.docEntry) {
            toastError('Sales order docEntry is null or undefined.')
            return
        }
        const newWorksheet = await newWorksheetAction.mutateAsync({docEntry: salesOrder.docEntry})
        if (isMounted()) {
            openWorksheetEditor(newWorksheet.id)
        } else {
            toastSuccess(`${tk('WorksheetCreatedSilent')}${newWorksheet.partnerCardName}`)
        }
    }

    return (
        <Card elevation={16} sx={{
            p: 2
        }}>
            <CardContent sx={{p: 0}}>
                <Grid container spacing={0.5}>
                    <Grid item xs={12}>

                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                            <Grid item xs={6}>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        display: "flex",
                                        alignItems: "center"
                                    }}
                                >
                                    <ImageIcon image={WorksheetIconImages.checklist} size={32}/>&nbsp;{tk('SalesOrder')}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="caption"
                                    sx={{
                                        fontSize: "18pt",
                                        fontWeight: "bold",
                                        display: "flex",
                                        justifyContent: "right",
                                        alignItems: "center"
                                    }}
                                >
                                    {Math.round((salesOrder.completionRate ?? 0) * 100)}%
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={6}>
                        <Typography
                            variant="h6"
                            sx={{
                                display: "flex",
                                alignItems: "center"
                            }}
                        >
                            {salesOrder.cardName} ({salesOrder.cardCode})
                        </Typography>
                        <Typography
                            variant="h6"
                            sx={{
                                display: "flex",
                                alignItems: "center"
                            }}
                        >
                            <DateDisplay
                                dateStr={salesOrder.docDate ?? ""}
                                options={{
                                    ...dateDisplayDefaultOptions,
                                    hour: undefined,
                                    minute: undefined
                                }}
                            />
                        </Typography>
                    </Grid>

                    <Grid item xs={6}>
                        <Typography
                            variant="h6"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "right",
                                textAlign: "right"
                            }}
                        >
                            {salesOrder.docNum}
                        </Typography>
                        <Typography
                            variant="h6"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "right",
                                textAlign: "right"
                            }}
                        >
                            {salesOrder.docStatus === 'O' ? tk('Open') : tk('Closed')}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>

                        <Grid container
                              direction="row"
                              spacing={1}
                              justifyContent="space-between"
                              alignItems="stretch">

                            <Grid item xs={12}>
                                <NewWorksheetResultSalesOrderAddresses
                                    salesOrder={salesOrder}
                                />
                            </Grid>
                            <Grid item
                                  xs={true}
                                /*sm={!hasSapEquipment ? 12 : 8}
                                md={!hasSapEquipment ? 12 : 9}
                                lg={!hasSapEquipment ? 12 : 10}*/
                            >
                                <NewWorksheetResultSalesOrderTable
                                    salesOrder={salesOrder}
                                    pageSize={pageSize}
                                />
                            </Grid>

                            {hasSapEquipment && (
                                <Grid item xs="auto">
                                    <NewWorksheetEquipmentCard
                                        coverImage={salesOrder.coverImage}
                                        manufacturer={salesOrder.manufacturer}
                                        vehicleType={salesOrder.vehicleType}
                                        manufacturerSn={salesOrder.manufacturerSn}
                                        internalSn={salesOrder.internalSn}
                                        yearOfManufacture={salesOrder.yearOfManufacture}
                                    />
                                </Grid>
                            )}

                        </Grid>

                    </Grid>

                </Grid>
            </CardContent>
            <CardActions sx={{
                display: "flex",
                justifyContent: "right"
            }}>
                <Button variant="contained"
                        color="primary"
                        onClick={onNewWorksheetClick}
                        disabled={newWorksheetAction.isLoading}
                >
                    {tk('NewWorksheet')}
                    {newWorksheetAction.isLoading && (
                        <CircularProgress size="16px"/>
                    )}
                </Button>
            </CardActions>
        </Card>
    )
}

export default NewWorksheetResultSalesOrder;