import {CDAMWMWModSapPartnerContactSave, CDSMSapPartnerContact} from "crowbar-api";
import {useWorksheetSaveState} from "modules/worksheet/editor/state/WorksheetSaveAtoms";
import {useNewAtomWithRef} from "shared/hook/useNewatomWithRef";
import {useMemo} from "react";
import WorksheetSelectedDataSourceMap from "crowbar-api/enum-map/WorksheetSelectedDataSourceMap";
import {toastError} from "shared/toast/DefaultToasts";
import ModSapTypeMap from "crowbar-api/enum-map/ModSapTypeMap";
import useSapPartnerContactsByCardCode from "crowbar-api/hooks/sap/useSapPartnerContactsByCardCode";


export interface SapPartnerContactSelectValue {
    /**
     * contact source: Contact code
     * mod: mod id
     */
    key: string
    cardCode: string | null | undefined
    source: 'contact' | 'mod'

    /**
     * in case of contact source
     */
    contact?: CDSMSapPartnerContact

    /**
     * in case of worksheet contact modifications
     */
    mod?: CDAMWMWModSapPartnerContactSave
}

export interface UsePartnerContactSelectValuesAtomResult {
    selectValues: SapPartnerContactSelectValue[]
    setSelectedByKey: (key: string) => void
    selectedKey: string | undefined
    addNewMod: (mod: CDAMWMWModSapPartnerContactSave, mode: 'new' | 'edit') => void
}

const useSapPartnerContactSelectValuesAtom = (cardCode: string | null | undefined): UsePartnerContactSelectValuesAtomResult => {
    const [worksheetSave, setWorksheetSave] = useWorksheetSaveState()
    const [selected, setSelected] = useNewAtomWithRef<string | undefined>(undefined)
    const {data: contacts} = useSapPartnerContactsByCardCode(cardCode)

    // automatically load from api, state mod values
    const selectValues = useMemo(() => {
        const partnerContacts = (contacts ?? [])
            .map((c): SapPartnerContactSelectValue => {
                return {
                    key: c.contactCode?.toString() ?? "",
                    cardCode: worksheetSave?.cardCode,
                    source: "contact",
                    contact: c
                }
            })
        const mods = (worksheetSave?.modSapPartnerContacts ?? [])
            .map((mod): SapPartnerContactSelectValue => {
                return {
                    key: mod.id ?? "",
                    cardCode: worksheetSave?.cardCode,
                    source: "mod",
                    mod: mod
                }
            })
        return [
            ...partnerContacts,
            ...mods
        ]
    }, [contacts, worksheetSave?.cardCode, worksheetSave?.modSapPartnerContacts])

    return useMemo<UsePartnerContactSelectValuesAtomResult>((): UsePartnerContactSelectValuesAtomResult => {

        const setValuesToWorksheet = (selectedValue: SapPartnerContactSelectValue) => {
            setWorksheetSave(prev => {
                const isContact = selectedValue.source === 'contact'

                const c = selectedValue.contact
                const m = selectedValue.mod

                return {
                    ...prev,
                    contactPersonSource: isContact ? WorksheetSelectedDataSourceMap.sapMasterData : WorksheetSelectedDataSourceMap.modSap,
                    modSapPartnerContact: selectedValue.source === "mod" ? selectedValue.mod?.id : undefined,
                    contactCode: isContact ? c?.contactCode : m?.contactCode,
                    contactName: isContact ? c?.name : `${m?.lastname} ${m?.firstname}`,
                    contactPosition: isContact ? c?.position : undefined,
                    contactPhone1: isContact ? c?.phone1 : m?.phone,
                    contactPhone2: isContact ? c?.phone2 : undefined,
                    contactPhone3: isContact ? c?.phone3 : undefined,
                    contactEmail: isContact ? c?.email : m?.email,
                    contactFirstname: isContact ? c?.firstName : m?.firstname,
                    contactMiddleName: isContact ? c?.middleName : m?.middleName,
                    contactLastname: isContact ? c?.lastName : m?.lastname
                }
            })
        }

        const setSelectedByKey = (key: string) => {
            const match = selectValues.find(v => v.key === key)
            if (!match) {
                toastError("Cannot select value. Not a values in the list.")
                return
            }
            setValuesToWorksheet(match)
            setSelected(key)
        }

        return {
            selectValues: selectValues,
            setSelectedByKey: setSelectedByKey,
            selectedKey: selected,
            addNewMod: (mod, mode) => {
                mod.type = mod.type === ModSapTypeMap.create ? ModSapTypeMap.create
                    : mode === "new" ? ModSapTypeMap.create : ModSapTypeMap.update
                setWorksheetSave(prev => {
                    return {
                        ...prev,
                        modSapPartnerContacts: [
                            ...(prev?.modSapPartnerContacts ?? []).filter(c => c.id !== mod.id),
                            mod
                        ]
                    }
                })
                if (mod.id) {
                    setValuesToWorksheet({
                        key: mod.id,
                        source: 'mod',
                        cardCode: worksheetSave?.cardCode,
                        contact: undefined,
                        mod: mod
                    })
                    setSelected(mod.id)
                }
            }
        }


    }, [selectValues, selected, setSelected, setWorksheetSave, worksheetSave?.cardCode]);
}

export default useSapPartnerContactSelectValuesAtom