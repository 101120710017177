import useTeamsHostInformation from "shared/teams/useTeamsHostInformation";
import {useEffect, useState} from "react";

export interface PhotoHostInformation {
    useTeamsCameraInput: boolean
    useNativeInput: boolean
}

const usePhotoHostInformation = (): PhotoHostInformation => {
    const [useTeamsCameraInput, setUseTeamsCameraInput] = useState<boolean>(false)
    const [useNativeInput, setUseNativeInput] = useState<boolean>(false)

    const teamsInfo = useTeamsHostInformation()

    useEffect(() => {
        (async () => {
            if (!teamsInfo.initialized) {
                setUseNativeInput(true)
                return
            }
            try {
                const androidOrIosTeamsPlatform = await teamsInfo?.androidOrIosTeamsPlatform()
                setUseTeamsCameraInput(androidOrIosTeamsPlatform)
                setUseNativeInput(!androidOrIosTeamsPlatform)
            } catch (e: any) {
                console.error(e)
                throw new Error(e)
            }
        })()
    }, [teamsInfo])

    return {
        useTeamsCameraInput,
        useNativeInput
    }
}

export default usePhotoHostInformation