import {useMutation} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import httpResponseFileDownload from "shared/utils/HttpResponseFileDownload";
import {DeliveryNoteTemplateApi} from "crowbar-api";

export const useDownloadAsDocx = (id?: string) => {
    return useMutation(["delivery-note-docx"], async () => {
        const response = await crowbarApiFactory(DeliveryNoteTemplateApi).downloadAsDocx(id, {
            responseType: "arraybuffer"
        });

        httpResponseFileDownload(response, "download.pdf");
    })
}