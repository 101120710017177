import {useQuery} from "@tanstack/react-query";
import {CDSMPartnerEquipmentPageRow, CDSMPSapPartnerEquipmentPagingParams, SapPartnerEquipmentApi} from "crowbar-api";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import AxiosCancelTokenHelper from "crowbar-api/AxiosCancelTokenHelper";
import {UseQueryOptionParams} from "crowbar-api/util/UseQueryOptionParams";

export const sapPartnerEquipmentSearchPageBy = async (params: CDSMPSapPartnerEquipmentPagingParams, signal?: AbortSignal | undefined) => {
    const response = await crowbarApiFactory(SapPartnerEquipmentApi).searchPageBy(
        params,
        AxiosCancelTokenHelper.cancelTokenFrom(signal)
    )
    if (response.status === 200) {
        return response.data
    } else {
        throw new Error('Failed to request equipments page.')
    }
}

export const useSapPartnerEquipmentSearchPageBy = (params: CDSMPSapPartnerEquipmentPagingParams, extraOptions?: UseQueryOptionParams) => {
    return useQuery<CDSMPartnerEquipmentPageRow[]>([
        'SelectSinglePartnerEquipment-equipments-query-1',
        params.cardCode,
        params.search,
        params.lastRowNumber,
        params.insIds
    ], async (context) => {
        return await sapPartnerEquipmentSearchPageBy(params, context.signal)
    }, {
        refetchOnWindowFocus: false,
        keepPreviousData: false,
        retry: 1,
        refetchOnMount: false,
        ...extraOptions
    })
}