import React from 'react';
import {CDAMWMServiceCallModel} from "crowbar-api";
import {Card, CardContent, Grid, Typography,} from "@mui/material";
import {
    FaultDescriptionTextArea
} from "modules/admin/worksheet/evaluation/tabs/service-calls/descriptions/FaultDescriptionTextArea";
import {
    ServiceCallComment
} from "modules/admin/worksheet/evaluation/tabs/service-calls/descriptions/ServiceCallComment";
import TaskDescriptionAndCallType
    from "modules/admin/worksheet/evaluation/tabs/service-calls/descriptions/TaskDescriptionAndCallType";
import useTranslateFunction from "shared/language/useTranslateFunction";
import FixesWorksheetDataIcon from "modules/admin/worksheet/evaluation/shared/FixesWorksheetDataIcon";

export type DescriptionsProps = {
    sc: CDAMWMServiceCallModel
    evalId: string
    disabled?: boolean
}
const Descriptions = ({
                          sc,
                          evalId,
                          disabled
                      }: DescriptionsProps) => {
    const tk = useTranslateFunction("Admin.Worksheet")
    return (
        <Card variant="outlined">
            <CardContent>
                <Grid container spacing={1}>

                    <Grid item xs={12}>
                        <Typography variant="h6">{tk('Worksheet')}&nbsp;<FixesWorksheetDataIcon/></Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <TaskDescriptionAndCallType
                            sc={sc}
                            evalId={evalId}
                            disabled={disabled}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <FaultDescriptionTextArea
                            sc={sc}
                            evalId={evalId}
                            disabled={disabled}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <ServiceCallComment
                            sc={sc}
                            evalId={evalId}
                            disabled={disabled}
                        />
                    </Grid>

                </Grid>
            </CardContent>
        </Card>
    );
};

export default Descriptions;