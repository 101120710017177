import React from 'react';
import {CDAMWMWorksheetStatus} from "crowbar-api";
import WorksheetStatusMap from "crowbar-api/enum-map/WorksheetStatusMap";
import {WorksheetIconImages} from "modules/worksheet/editor/shared/component/images/WorksheetIconImages";
import ImageIcon from "shared/components/image-icon/ImageIcon";
import {Stack, StackProps, Typography, TypographyProps} from "@mui/material";
import {useTranslation} from "react-i18next";

export type WorksheetStateDisplayProps = {
    'status'?: CDAMWMWorksheetStatus;

    stackProps?: StackProps
    typographyProps?: TypographyProps
}

const WorksheetStateDisplay = ({status, stackProps, typographyProps}: WorksheetStateDisplayProps) => {

    const {t} = useTranslation()

    let icon: string | undefined = undefined
    switch (status) {
        case WorksheetStatusMap.draft:
            icon = WorksheetIconImages.draftSmall
            break
        case WorksheetStatusMap.final:
            icon = WorksheetIconImages.signSmall
            break
        case WorksheetStatusMap.finalSignSkipped:
        case WorksheetStatusMap.finalSigned:
            icon = WorksheetIconImages.positiveVoteSmall
            break
        case WorksheetStatusMap.evaluated:
            icon = WorksheetIconImages.gavelSmall
            break
    }

    if (!icon) return <></>

    return (
        <Stack
            direction="row"
            spacing={0.5}
            alignItems="center"
            {...stackProps}
        >
            <ImageIcon
                image={icon}
                size={24}
            />
            <Typography
                variant="body2"
                {...typographyProps}
            >
                {t(WorksheetStatusMap.translationKey(status))}
            </Typography>
        </Stack>
    )
}

export default WorksheetStateDisplay;