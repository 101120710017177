import React from 'react';
import {CDAMCMCrowbarBlobModel} from "crowbar-api";
import {Card, CardMedia} from "@mui/material";
import EquipmentImageClickable from "modules/equipment/equipment-details/equipment-images/EquipmentImageClickable";

export type EquipmentImageProps = {
    blob: CDAMCMCrowbarBlobModel
    onClick: (blob: CDAMCMCrowbarBlobModel) => void
}

const EquipmentImage = ({blob, onClick}: EquipmentImageProps) => {
    return (
        <Card
            variant="outlined"
            sx={{
                height: 1,
                ':hover': {
                    boxShadow: "0 0px 15px rgb(0 0 0 / 0.4)",
                    cursor: 'pointer',
                }
            }}
        >
            <CardMedia sx={{height: 1}}>
                <EquipmentImageClickable blob={blob} onClick={onClick}/>
            </CardMedia>
        </Card>
    );
};

export default EquipmentImage;