import {useEffect, useState} from 'react';
import {Fab, FabProps} from "@mui/material";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

export type ScrollToTopButtonProps = FabProps & {}
const ScrollToTopButton = ({...props}: ScrollToTopButtonProps) => {
    const [visible, setVisible] = useState<boolean>(false)

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
    };

    useEffect(() => {
        const toggleVisible = () => {
            const scrolled = document.documentElement.scrollTop;
            if (scrolled > 300) {
                setVisible(true)
            } else if (scrolled <= 300) {
                setVisible(false)
            }
        };
        window.addEventListener('scroll', toggleVisible);
        return () => {
            window.removeEventListener('scroll', toggleVisible)
        }
    }, [visible, setVisible])

    if (!visible) {
        return <></>
    }

    return (
        <Fab
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "fixed",
                zIndex: 1040,
                cursor: 'pointer',
                color: (theme) => theme.palette.text.primary,
                bottom: (theme) => theme.spacing(3),
                right: (theme) => theme.spacing(3),
                ...props.sx
            }}
            {...props}
            onClick={scrollToTop}
        >
            <ArrowUpwardIcon/>
        </Fab>
    )
}

export default ScrollToTopButton;