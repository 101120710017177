import {PathRouteProps} from "react-router/dist/lib/components";
import WorksheetEditorPageBasic from "modules/worksheet/editor/ui/basic/WorksheetEditorPageBasic";
import WEditorEquipments from "modules/worksheet/editor/ui/equipments/WEditorEquipments";
import React from "react";
import WEditorFeedback from "modules/worksheet/editor/ui/feedback/WEditorFeedback";

export const worksheetHeaderOpenRoutes: PathRouteProps[] = [
    {
        path: "basic",
        element: <WorksheetEditorPageBasic/>
    },
    {
        path: "equipments",
        element: <WEditorEquipments/>
    },
    {
        path: "feedback",
        element: <WEditorFeedback/>
    }
]