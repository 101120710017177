import React from 'react';
import {Grid} from "@mui/material";
import {CDAMWMServiceCallEvalModel, CDAMWMServiceCallModel, CDAMWMWWorksheetQuery} from "crowbar-api";
import AddressSelect from "modules/admin/worksheet/evaluation/tabs/service-calls/address/AddressSelect";
import useTranslateFunction from "shared/language/useTranslateFunction";

export type AddressSelectsProps = {
    worksheet: CDAMWMWWorksheetQuery | null | undefined
    sc: CDAMWMServiceCallModel
    scEval: CDAMWMServiceCallEvalModel
    disabled?: boolean
    onMasterDataChange: () => Promise<void>
}
const AddressSelects = ({
                            worksheet,
                            sc,
                            scEval,
                            disabled,
                            onMasterDataChange
                        }: AddressSelectsProps) => {
    const tk = useTranslateFunction("Admin.Worksheet")
    return (
        <Grid container spacing={1}>
            <Grid item xs={6}>
                <AddressSelect
                    addressType="S"
                    label={tk('ShippingAddress')}
                    required={false}
                    worksheet={worksheet}
                    sc={sc}
                    scEval={scEval}
                    disabled={disabled}
                    onMasterDataChange={onMasterDataChange}
                />
            </Grid>
            <Grid item xs={6}>
                <AddressSelect
                    addressType="B"
                    label={tk('BillingAddress')}
                    required={true}
                    worksheet={worksheet}
                    sc={sc}
                    scEval={scEval}
                    disabled={disabled}
                    onMasterDataChange={onMasterDataChange}
                />
            </Grid>
        </Grid>
    )
}

export default AddressSelects;