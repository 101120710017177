import {InputAdornment, Typography} from "@mui/material";
import React from "react";
import {WEditorPageCatalogueItemRowUtils} from "modules/worksheet/editor/ui/catalogue/WEditorPageCatalogueItemRowUtils";
import useTranslateFunction from "shared/language/useTranslateFunction";
import NumberOnlyTextField, {NumberOnlyTextFieldProps} from "shared/components/inputs/NumberOnlyTextField";

type WEditorPageCatalogueItemRowQuantityInputFieldProps = NumberOnlyTextFieldProps & {
    disabled?: boolean
    unitOfQuantity?: string
}

const WEditorPageCatalogueItemRowQuantityInputField = ({

                                                           value,
                                                           unitOfQuantity,
                                                           onChange,
                                                           disabled
                                                       }: WEditorPageCatalogueItemRowQuantityInputFieldProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    return (
        <NumberOnlyTextField
            key="installed-quantity"
            sx={{
                height: 1,
                width: `${WEditorPageCatalogueItemRowUtils.largeQtyInputWidthFrom(value.toString(), unitOfQuantity ?? "XX")}px`,//numericInputWidthFromQtyLength(iqLength),
                margin: 0
            }}
            InputProps={{
                sx: {
                    height: 1,
                    marginBottom: 0,
                    fontSize: "46pt",
                },
                endAdornment: (
                    <InputAdornment position="end">
                        <Typography sx={{
                            fontSize: "22pt"
                        }}>{unitOfQuantity}</Typography>
                    </InputAdornment>
                )
            }}
            label={tk('Installed')}
            value={value}
            onChange={onChange}
            disabled={disabled}
        />
    )
}

export default WEditorPageCatalogueItemRowQuantityInputField