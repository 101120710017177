import React from 'react';
import {CDAMWMServiceCallEvalModel, CDAMWMServiceCallModel, CDAMWMWWorksheetQuery} from "crowbar-api";
import ServiceCallSelectedSourceMap from "crowbar-api/enum-map/ServiceCallSelectedSourceMap";
import VehicleTypeDisplay from "modules/worksheet/editor/shared/component/vehicle-type-display/VehicleTypeDisplay";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {Typography} from "@mui/material";

export type DescriptionCellProps = {
    row: CDAMWMServiceCallEvalModel
    worksheet: CDAMWMWWorksheetQuery | null | undefined
    serviceCalls: CDAMWMServiceCallModel[]
}
const DescriptionCell = ({row, worksheet, serviceCalls}: DescriptionCellProps) => {
    const tk = useTranslateFunction('Admin.Worksheet')

    const sc = serviceCalls.find(sc => sc.id === row.serviceCallId)
    if (!sc) return (
        <Typography>-</Typography>
    )

    if (sc.selectedSource === ServiceCallSelectedSourceMap.empty) {
        return (
            <Typography>
                {sc.emptySubject}
            </Typography>
        )
    } else {
        const modEquipment = !row.equipmentChanged && sc.modSapEquipmentId
            ? (worksheet?.modSapEquipments ?? []).find(m => m.id === sc.modSapEquipmentId)
            : undefined

        return (
            <VehicleTypeDisplay
                manufacturer={sc.manufacturer}
                vehicleType={sc.vehicleType}
                modManufacturer={modEquipment?.manufacturer}
                modVehicleType={modEquipment?.vehicleType}
            />
        )
    }
};

export default DescriptionCell;