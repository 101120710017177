import React, {SyntheticEvent, useState} from 'react';
import {Card, CardContent, Grid, Tab, Tabs} from "@mui/material";
//import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import useTranslateFunction from "shared/language/useTranslateFunction";
import MasterDataTab from "modules/admin/worksheet/evaluation/tabs/master-data/MasterDataTab";
import ServiceCalls from "modules/admin/worksheet/evaluation/tabs/service-calls/ServiceCalls";
import AdminComment from "modules/admin/worksheet/evaluation/tabs/admin-comment/AdminComment";
import Feedback from "modules/admin/worksheet/evaluation/tabs/feedback/Feedback";
import {CDAMWMWorksheetEvalModel, CDAMWMWWorksheetQuery} from "crowbar-api";
import History from "modules/admin/worksheet/evaluation/tabs/history/History";

export type WEvalTabsProps = {
    wEval: CDAMWMWorksheetEvalModel | null | undefined
    worksheet: CDAMWMWWorksheetQuery | null | undefined
    onDataChange: () => Promise<void>
}
const WEvalTabs = ({wEval, worksheet, onDataChange}: WEvalTabsProps) => {
    const tk = useTranslateFunction('Admin.Worksheet')

    const [currentTab, setCurrentTab] = useState<number>(0)

    const onTabChange = (e: SyntheticEvent<any>, value: any) => {
        e.preventDefault()
        const newValue = +value
        if (isNaN(newValue)) return
        setCurrentTab(newValue)
    }

    return (
        <Card elevation={3}>
            <CardContent sx={{px: 2, py: 0}}>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <Tabs
                            value={currentTab}
                            onChange={onTabChange}
                        >
                            <Tab
                                value={0}
                                //icon={<RadioButtonUncheckedIcon/>}
                                //iconPosition="end"
                                label={tk('MasterData')}
                            />
                            <Tab value={1}
                                //icon={<RadioButtonUncheckedIcon/>} iconPosition="end"
                                 label={tk('ServiceCalls')}/>
                            <Tab value={2} label={tk('History')}/>
                            <Tab value={3} label={tk('Comment')}/>
                            {(worksheet?.feedbackNum || worksheet?.feedbackText) && (
                                <Tab value={4} label={tk('Feedback')}/>
                            )}
                        </Tabs>
                    </Grid>
                    <Grid item xs={12} sx={{minHeight: "300px"}}>
                        {currentTab === 0 && (
                            <MasterDataTab
                                wEval={wEval}
                                worksheet={worksheet}
                                onDataChange={onDataChange}
                            />
                        )}
                        {currentTab === 1 && (
                            <ServiceCalls
                                wEval={wEval}
                                worksheet={worksheet}
                            />
                        )}
                        {currentTab === 2 && (
                            <History
                                wEval={wEval}
                                worksheet={worksheet}
                            />
                        )}
                        {currentTab === 3 && (
                            <AdminComment
                                wEval={wEval}
                                worksheet={worksheet}
                                onDataChange={onDataChange}
                            />
                        )}

                        {currentTab === 4 && (
                            <Feedback
                                wEval={wEval}
                                worksheet={worksheet}
                            />
                        )}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default WEvalTabs;