import {useQuery} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {CDSMServiceCallExistsResult, SapServiceCallApi} from "crowbar-api";
import AxiosCancelTokenHelper from "crowbar-api/AxiosCancelTokenHelper";
import {UseQueryOptionParams} from "crowbar-api/util/UseQueryOptionParams";
import {wait} from "shared/utils/wait";

export const sapServiceCallExists = async (callId?: number | null | undefined, signal?: AbortSignal | undefined) => {
    if (!callId) return null
    const response = await crowbarApiFactory(SapServiceCallApi)
        .exists(callId, AxiosCancelTokenHelper.cancelTokenFrom(signal))
    return response.data ?? null
}

export const useSapServiceCallExists = (callId?: number | null | undefined, extraOptions?: UseQueryOptionParams) => {
    return useQuery(['sap-service-call-exists', callId], async ({signal}) => {
        return sapServiceCallExists(callId, signal)
    }, {
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        cacheTime: 0,
        staleTime: 0,
        retry: 1,
        ...extraOptions
    })
}

export type UseSapServiceCallExistsLoopUntilFoundOptions = {
    timeout: number
    waitsInMilliseconds: number
}

export const useSapServiceCallExistsLoopUntilFoundByDocEntry = (
    callId: number | null | undefined,
    loopOptions: UseSapServiceCallExistsLoopUntilFoundOptions,
    extraOptions?: UseQueryOptionParams
) => {
    return useQuery(['sap-service-call-exists-loop-until-found-doc-entry', callId], async ({signal}) => {
        if (!callId) return null

        let timeout = loopOptions.timeout;
        let end = false
        let result: CDSMServiceCallExistsResult | null = null
        while (!end && timeout > 0) {
            result = await sapServiceCallExists(callId, signal)
            if (result?.docNum) {
                end = true
            } else {
                timeout -= loopOptions.waitsInMilliseconds
                await wait(loopOptions.waitsInMilliseconds, signal)
            }
        }

        return result
    }, {
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        cacheTime: 0,
        staleTime: 0,
        retry: 1,
        ...extraOptions
    })
}