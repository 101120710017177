import React from 'react';
import {useSelectedServiceCall} from "modules/worksheet/editor/shared/data/useSelectedServiceCall";
import {useServiceCallAtom} from "modules/worksheet/editor/state/atoms/useServiceCallAtom";
import useTranslateFunction from "shared/language/useTranslateFunction";
import WEditorSpeechToTextField from "modules/worksheet/editor/ui/description/WEditorSpeechToTextField";
import useWorksheetStatuses from "modules/worksheet/editor/state/atoms/useWorksheetStatuses";
import {Typography} from "@mui/material";

export interface WEditorTaskDescriptionProps {
    disabled?: boolean
}

const WEditorTaskDescription = ({disabled}: WEditorTaskDescriptionProps) => {

    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    const selectedServiceCall = useSelectedServiceCall()
    const [, setSelectedServiceCall] = useServiceCallAtom(selectedServiceCall?.id)

    const worksheetStatuses = useWorksheetStatuses()
    const matched = worksheetStatuses.serviceCallStatuses?.find(ss => ss.id === selectedServiceCall?.id)
    const taskDescriptionRequired = !matched || matched.taskDescriptionIsRequired

    if (!selectedServiceCall) return <></>

    return (
        <WEditorSpeechToTextField
            disabled={disabled}
            required={taskDescriptionRequired}
            sx={{marginTop: 2}}
            fullWidth
            minRows={4}
            maxRows={9} // https://stackoverflow.com/a/72170317/840315
            rows={undefined}
            label={(
                <Typography component="span">
                    {tk('TaskDescriptionLabel')}
                    {taskDescriptionRequired && (
                        <span> *</span>
                    )}
                </Typography>
            )}
            value={selectedServiceCall?.taskDescription}
            onBlur={value => {
                setSelectedServiceCall(prev => {
                    return {
                        ...prev,
                        taskDescription: value
                    }
                })
            }}
            onTranscribeSaved={value => {
                setSelectedServiceCall(prev => {
                    return {
                        ...prev,
                        taskDescription: ((prev.taskDescription ?? "") + "\r\n" + value).trim()
                    }
                })
            }}
        />
    )
}

export default WEditorTaskDescription;