import {useMutation} from "@tanstack/react-query";
import {CDAMPMPhotoRuleModel, PhotoRuleApi} from "crowbar-api";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";

const usePhotoRuleSave = () => {
    return useMutation(async (photoRule: CDAMPMPhotoRuleModel) => {
        const response = await crowbarApiFactory(PhotoRuleApi).save(photoRule)
        if (response.status !== 200) {
            throw new Error('Error during photo rule save.')
        }
    })
}

export default usePhotoRuleSave