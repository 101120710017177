import {useMutation} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {DeliveryNoteTemplateApi} from "crowbar-api";

export const deleteDeliveryNoteTemplate = async (id: string) => {
    await crowbarApiFactory(DeliveryNoteTemplateApi)._delete(id);
}

export const useDeleteDeliveryNoteTemplate = () => {
    return useMutation(async (id: string) => {
        await deleteDeliveryNoteTemplate(id)
    })
}