import {useNavigate, useParams} from "react-router-dom";
import {Box, CircularProgress, Stack, Typography} from "@mui/material";
import usePhotoRuleForEditorById
    from "modules/admin/worksheet/photo-rule/photo-rules/PhotoRuleEdit/usePhotoRuleForEditorById";
import {toastError, toastSuccess} from "shared/toast/DefaultToasts";
import {useEffect} from "react";
import {BackButton} from "shared/components/buttons/BackButton";
import PhotoRuleEditTitleInput
    from "modules/admin/worksheet/photo-rule/photo-rules/PhotoRuleEdit/PhotoRuleEditTitleInput";
import PhotoRuleEditManufacturers
    from "modules/admin/worksheet/photo-rule/photo-rules/PhotoRuleEdit/PhotoRuleEditManufacturers";
import PhotoRuleEditSteps from "modules/admin/worksheet/photo-rule/photo-rules/PhotoRuleEdit/PhotoRuleEditSteps";
import usePhotoRuleDelete from "modules/admin/worksheet/photo-rule/photo-rules/PhotoRuleEdit/usePhotoRuleDelete";
import useTranslateFunction from "shared/language/useTranslateFunction";
import ConfirmDialogButton from "shared/components/buttons/ConfirmDialogButton";

export interface PhotoRuleEditProps {
    // mode: 'new' | 'edit'
}

const PhotoRuleEdit = () => {
    const tk = useTranslateFunction('Admin.PhotoRule')

    const {ruleId} = useParams()
    const navigate = useNavigate()

    const photoRuleDelete = usePhotoRuleDelete()
    const {rule, status, isError, refetchRule} = usePhotoRuleForEditorById(ruleId)
    //const photoRuleSave = usePhotoRuleSave()

    //  const [titleValue, setTitleValue] = useState<string>("")

    // navigate back on error
    useEffect(() => {
        if (isError) {
            toastError('Failed to load Photo rule.')
            navigate("/admin/worksheet-admin/photo-rule/rules")
        }
    }, [isError, navigate])

    const onRuleDelete = () => {
        if (!ruleId) {
            toastError('Cant delete. Missing ruleId.')
            return
        }
        photoRuleDelete.mutate(ruleId, {
            onSuccess: () => {
                navigate("/admin/worksheet-admin/photo-rule/rules")
                toastSuccess(tk('RuleDeleted'))
            }
        })
    }

    if (status === "loading") {
        return <CircularProgress/>
    }

    return (
        <Stack direction="column" spacing={2}>
            <Typography component="h1" sx={{
                display: 'flex',
                alignItems: 'center',
                fontSize: "20pt"
            }}>
                <BackButton
                    to="/admin/worksheet-admin/photo-rule/rules"/> {tk('PhotoRuleEditTitle', {title: rule?.title})}
            </Typography>

            {rule?.id && (
                <>
                    <PhotoRuleEditTitleInput
                        ruleId={rule.id}
                        ruleTitle={rule?.title ?? ""}
                        afterSave={() => refetchRule()}
                    />

                    <Box>
                        <ConfirmDialogButton
                            color="error"
                            onConfirm={onRuleDelete}
                            buttonText={tk('RuleDelete')}
                        />
                    </Box>

                    <PhotoRuleEditManufacturers
                        ruleId={rule.id}
                    />

                    <Box sx={{
                        paddingTop: 5
                    }}>
                        <PhotoRuleEditSteps

                            ruleId={rule.id}
                        />
                    </Box>

                    <Box sx={{
                        paddingTop: 7
                    }}>

                    </Box>
                </>
            )}
        </Stack>
    )
}

export default PhotoRuleEdit