export const i18n_worksheet_generic_en = {
    "SelectedDataSource": {
        "NotSet": "Not set",
        "SapMasterData": "Master data",
        "ModSap": "Sap modification",
        "SapSalesOrder": "Sales order",
        "OwnPremise": "Own premise",
        "Missing": "No data"
    },
    "WorksheetStatus": {
        "Draft": "Draft",
        "Final": "To be signed",
        "FinalSigned": "Finished (signed)",
        "FinalSignSkipped": "Finished (not signed)",
        "Evaluated": "Evaluated",
        "Missing": "No data"
    },
    "WorksheetTripType": {
        "NotSet": "Not set",
        "SalesOrder": "Sales order",
        "PartnerPreset": "Customer preset",
        "AtWorkshop": "Workshop",
        "Missing": "No data",
    },
    "ServiceCallStatus": {
        "Draft": "Draft",
        "Final": "Final",
        "Missing": "No data"
    },
    "ServiceCallOperatingHoursType": {
        "Provided": "Provided",
        "WontProvideData": "Won't provide (-500 Ft)",
        "CantRead": "Can't read",
        "CounterNotWorking": "Counter is not working",
        "Missing": "No data"
    },
    "ServiceCallItemRowStatus": {
        "Default": "Default",
        "NotInstalled": "Not installed",
        "Installed": "Installed",
        "Missing": "No data"
    },
    "ServiceCallItemRowWarningType": {
        "None": "None",
        "IncludedInDifferentWorksheet": "The item has already been installed in another worksheet.",
        "TooManyInstalledQuantity": "The quantity installed exceeds the quantity ordered by the customer.",
        "RemainingInstalledQuantity": "Not all products have been installed!",
        "Missing": "No data"
    },
    "ServiceCallSelectedSource": {
        "NotSet": "Not set",
        "SapSalesOrder": "Sales order",
        "ManuallyAdded": "Manually added",
        "Empty": "Üres",
        "Missing": "No data"
    },
    "ServiceCallItemRowSelectedSourceMap": {
        "NotSet": "Not set",
        "SapMasterData": "Master data",
        "SapSalesOrder": "Sales order",
        "ManuallyEntered": "Manually entered",
        "Missing": "No data"
    },
    "ModSapStatus": {
        "Suggested": "Suggested",
        "Rejected": "Rejected",
        "Accepted": "Accepted",
        "Missing": "No data"
    },
    "ModSapType": {
        "Create": "Created",
        "Update": "Updated",
        "Missing": "No data"
    },
    "WorksheetSapSalesOrderRowType": {
        "Item": "Item",
        "Text": "Text",
        "Missing": "No data"
    },
    "ServiceCallBlobType": {
        "NotSet": "Not set",
        "PhotoRule": "Photography rule",
        "IssuePhoto": "Issue image",
    },
    "WorksheetSaveErrorCode": {
        "0": "Unknown error.",
        "800": "Previous version on the server is different than the one being saved.",
        "801": "Missing previous version value. Cannot save.",

        "900": "Operation got cancelled.",
        "901": "Failed to save the history of the changes.",

        "1000": "Invalid or missing worksheet id.",
        "1001": "Invalid or missing service call id.",
        "1002": "Invalid or missing service call row id.",
        "1003": "Invalid or missing sales order id.",
        "1004": "Invalid or missing sales order row id.",

        "1011": "Invalid or missing mod partner id.",
        "1012": "Invalid or missing mod partner address id.",
        "1013": "Invalid or missing mod partner contact id.",
        "1014": "Invalid or missing mod equipment id.",

        "4000": "Invalid status change",
    },
    "FormPrepare": {
        "Title": "New worksheet",
        "Source": "Select source:",
        "SourceCustomer": "Customer",
        "SourceSalesOrder": "Sales order",
        "SearchSalesOrders": "Order number, ...",
        "SearchCustomers": "Card code, name...",
        "SelectedCustomerFilter": "Selected customer: ",
        "SelectedCustomerFilterEmpty": "Select a customer.",
        "LoadMore": "Load more",
        "ShowAll": "Show all",
        "Hide": "Hide",
        "SelectBtn": "Select",
        "CardEquipmentTitle": "Selected equipment:",
        "CardEquipmentNotFound": "There is no equipment selected for the sales order.",
        "UserIdNullError": "User authentication error. Cannot read user id.",
    },
    "WorksheetTimeItemCodes": {
        "ListTitle": "Worksheet work time product codes",
        "Remove": "Remove",
        "Create": "Created",
        "Update": "Updated",
        "Add": "Add",
    },
    "WorksheetTripItemCodes": {
        "ListTitle": "Worksheet distance product codes",
        "Remove": "Remove",
        "Create": "Created",
        "Update": "Updated",
        "Add": "Add",
    },
    "NewWorksheet": {
        "Yes": "Yes",
        "No": "No",
        "Open": "Open",
        "Closed": "Closed",
        "ShowMore": "Show more...",
        "NewWorksheet": "New worksheet",
        "CustomersLoading": "Loading customers...",
        "EquipmentsLoading": "Loading equipments...",
        "SalesOrdersLoading": "Loading sales orders...",
        "Customer": "Customer",
        "SalesOrder": "Sales order",
        "Equipment": "Equipment",
        "NumOfEquipments": "Number of equipments",
        "DocNum": "Doc.No",
        "OpenRowCount": "Open rows",
        "ClosedRowCount": "Closed rows",
        "Date": "Date",
        "NoSalesOrder": "No sales orders found.",
        "WithEquipment": "Equipment?",
        "SalesPerson": "Sales person",
        "InternalSn": "Internal SN",
        "ShippingAddress": "Shipping address",
        "BillingAddress": "Billing address",
        "ItemCode": "ItemCode",
        "Description": "Description",
        "Quantity": "Quantity",
        "OpenSalesOrders": "Open sales orders",
        "NoSalesOrders": "No sales order found.",
        "NumberOfServiceCalls": "Number of service calls",
        "LoadMore": "Load more...",
        "AtLeast3Char": "Enter at least three characters.",
        "SearchCustomers": "Search customers",
        "SearchSalesOrders": "Search sales orders",
        "SearchEquipments": "Search equipments",
        "IncludeClosedSalesOrders": "Include closed sales orders",
        "SalesOrdersFrom": "Order from",
        "SalesOrdersTo": "Order to",
        "CompletionRateLabel": "Completion rate",
        "NewCustomer": "New customer",
        "PartnerName": "Partner name",
        "VatLocal": "Local VAT No",
        "VatInternational": "International VAT No",
        "Comment": "Comment",
        "Add": "Add",
        "Cancel": "Cancel",
        "WorksheetForNewCustomer": "Worksheet with new customer",
        "WorksheetForPhoenix": "Frekvent worksheet",
        "TableRowNumber": "Number of table rows",
        "OrderBy": "Order by",
        "ASC": "Ascending",
        "DESC": "Descending",
        "WorksheetCreatedSilent": "Worksheet successfully created: ",
        "CustomersIsLoading": "Loading customers...",
        "SalesOrdersIsLoading": "Loading sales orders...",
        "EquipmentsIsLoading": "Loading equipments...",
        "Customers": "Customers",
        "SalesOrders": "Sales orders",
        "Equipments": "Equipments",
    },
    "Mechanic": {
        "Draft": "Draft",
        "ToSign": "To be signed",
        "Finished": "Finished",
        "Evaluated": "Evaluated",
        "YourWorksheets": "My worksheets",
        "NewWorksheet": "New worksheet",
        "NoServiceCall": "No service call found",
        "ServiceCall": "Service call",
        "Worksheet": "Worksheet",
        "OpenWorksheet": "Open",
        "SalesOrder": "Sales order",
        "WarnMultipleServiceCalls": "The worksheet contains multiple service calls!",
        "ItemCode": "Itemcode",
        "ItemName": "Description",
        "NoItemRows": "No item rows found.",
        "OrderByField": "Sort by",
        "ModificationDates": "Modification time",
        "SerialId": "Worksheet number",
        "OrderByDirection": "Order",
        "ASC": "Oldest first",
        "DESC": "Latest first",
        "InstalledQuantity": "Installed",
        "WorksheetLoading": "Loading worksheets...",
        "LoadMore": "Load more",
        "Layout": "Layout",
        "WorksheetStatus": "Worksheet status",
        "MenuCatalogue": "Catalogue",
        "Description": "Description",
        "FaultDescription": "Faults",
        "MenuPhoto": "Photo",
        "Owner": "Owner",
        "PdfDownload": "Pdf download",
        "DateFilter": "Filter by time of work",
        "CreatedAt": "Time of creation",
        "UpdatedAt": "Time of last modification",
        "DeletedWorksheet": "Deleted worksheet!",
        "LastStatusChange": "Last status change",
        "SkipCustomerEmail": "Customer notification disabled!",
    },
    "Editor": {
        "Previous": "Előző",
        "Next": "Következő",
        "Yes": "Yes",
        "No": "No",
        "Save": "Save",
        "Add": "Add",
        "Cancel": "Cancel",
        "New": "New",
        "LoadMore": "Load more...",
        "Edited": "Edited",
        "Empty": "Empty",
        "WithoutEquipment": "Without equipment",
        "WithEquipment": "Equipment",
        "WithNewEquipment": "New equipment",
        "Subject": "Subject",
        "Source": "Source",
        "Equipment": "Equipment",
        "Equipments": "Equipments",
        "NewEquipment": "New equipment",
        "IncludeSalesOrderCheckbox": "Add rows from the sales order.",
        "MenuBasic": "Basic information",
        "MenuBasicTitle": "Change partner addresses, trip information here.",
        "MenuEquipments": "Equipments",
        "MenuEquipmentsTitle": "Handling service calls, their machines.",
        "MenuCatalogue": "Catalogue",
        "MenuCatalogueTitle": "Service call items, quantities.",
        "MenuPhoto": "Photo",
        "MenuPhotoTitle": "Capture photos for the selected vehicle.",
        "MenuDescription": "Description",
        "MenuDescriptionTitle": "Descriptions and faults.",
        "MenuFeedback": "Rating",
        "MenuFeedbackTitle": "Rating and feedback",
        "TextSelectedEquipment": "Selected equipment:",
        "ServiceCallNoEquipment": "Service call without equipment",
        "MissingSelectedEquipment": "No equipment is selected.",
        "HeaderSaveBtn": "Finalisation",
        "NewServiceCall": "Add new service call.",
        "NewServiceCallPlusTitle": "Add new service call.",
        "NewServiceCallDialogTitle": "Add new service call",
        "NewServiceCallDialogEmptyText": "When you add an empty service call, you cannot specify equipment specific settings.",
        "SelectSinglePartnerEquipmentPlaceholder": "Search with equipment data...",
        "EquipmentAlreadyAdded": "The vehicle is already added to the worksheet.",
        "Manufacturer": "Manufacturer",
        "VehicleType": "Vehicle type",
        "ManufacturerSn": "Manufacturer serial number",
        "InternalSn": "Internal number",
        "YearOfManufacture": "Year of manufacture",
        "Comment": "Comment",
        "ChangeServiceCallButtonTitle": "Here you can change the current service call.",
        "NoItemRows": "No product line has been added to the service call.",
        "HideCompletedRows": "Hide finished lines.",
        "InOrder": "Order",
        "Installed": "Installed",
        "Available": "Available",
        "ServiceCall": "Service call",
        "TotalInstalled": "Total installed",
        "AddNew": "Add new",
        "Edit": "Edit",
        "Address": "Address",
        "ContactPerson": "Contact person",
        "SalesPerson": "Sales person",
        "Order": "Order",
        "SalesOrder": "Sales order",
        "OpenMap": "Open the selected address using map.",
        "CallPhone": "Call the selected phone number.",
        "TripCost": "Cost of trip",
        "Occasion": "occasion",
        "ZipCode": "Zip",
        "City": "City",
        "StreetLine1": "Address",
        "StreetNo": "Street no",
        "NewPartnerAddress": "New address",
        "Email": "E-mail",
        "Firstname": "Firstname",
        "MiddleName": "Middle name",
        "Lastname": "Lastname",
        "Phone": "Phone number",
        "Contact": "Kapcsolattartó",
        "NewPartnerContact": "New contact",
        "ItemCode": "Item no.",
        "ItemName": "Item name",
        "SuppCatNum": "Mfr. catalogue no.",
        "Substitute": "Bp. catalogue no.",
        "OnlyInStock": "Only in stock or not stock item",
        "OnlyFrequentlyUsed": "Only frequently used item",
        "ItemAlreadyAdded": "The article can already be found in the products section.",
        "Quantity": "Quantity",
        "Quantities": "Quantities",
        "Delete": "Delete",
        "PhotoWithHelp": "Capture photo with rule",
        "StandalonePhoto": "Attach images",
        "NoSelectedServiceCall": "No selected service call.",
        "UploadedNumberOfFiles": "Successfully uploaded {{counter}} files.",
        "PhotoNoRule": "Not Rule-Related",
        "Replace": "Replace",
        "NoRuleForManufacturer": "There is no rule for the manufacturer.",
        "Rule": "Rule",
        "Finish": "Finish",
        "Step": "Lépés",
        "Picture": "Kép",
        "Back": "Back",
        "TakePhoto": "Take a photo",
        "Transcribing": "Transcribing.",
        "Distribute": "Distribute",
        "Mechanic": "Mechanic",
        "MissingMechanic": "In case of multiple working hours, the selecting the mechanic is mandatory!",
        "TaskDescriptionLabel": "Description of work done",
        "FaultDescriptionLabel": "Description of faults",
        "FaultDescription": "Error description",
        "OperatingHours": "Operating hours",
        "ModifiedEquipment": "Modified equipment data.",
        "AddedHere": "Added here.",
        "Saved": "Saved",
        "WorksheetSaved": "Worksheet save successful ({{serialId}}).",
        "OverwritePreviousVersion": "Overwrite previous version",
        "OverwritePreviousVersionText": "The server's version is newer than the currently known previous version. This can happen, for example, if the worksheet has been saved by another device in the meantime. Do you want to overwrite it? (Pressing the Cancel button will not save the worksheet!)",
        "Description": "Description",
        "Select": "Select",
        "BasicAddressTitle": "Place of work",
        "BasicDurationTitle": "Time of work",
        "BasicContactTitle": "Contact",
        "BasicSalesPersonTitle": "Salesperson",
        "BasicTripTitle": "Departure fee",
        "TripRequired": "Filling in the delivery fee is mandatory!",
        "AddressRequired": "The place of work must be specified!",
        "NotValidWorksheet": "The worksheet cannot be finalised. Not all requirements have been met.",
        "Signature": "Signature",
        "WorkDate": "Day of work",
        "Customer": "Customer",
        "CustomerCode": "Customer code",
        "NumberOfServiceCalls": "Number of service calls",
        "HasFaultDescriptions": "Has fault description?",
        "SkipSignature": "Skip",
        "MissingServiceCall": "No service call to the vehicle.",
        "ItemRows": "Product rows",
        "NoFaultDescription": "There is no fault description",
        "InSalesOrder": "In sales order",
        "SignatureNavigationHelp": "You can use the arrows displayed by the products table or click on a row in the service call table to navigate between service calls.",
        "Selected": "Selected",
        "StartDateLabel": "Start date",
        "EndDateLabel": "End date",
        "Other": "Other",
        "NewPhotoForStep": "Add image",
        "OnSalesOrder": "Vevői rendelésben",
        "FeedbackNumLabel": "How satisfied are you as a mechanic with the way the work went? Please rate.",
        "FeedbackTextLabel": "For more detailed feedback, please write below..",
        "FeedbackIsCompletedTrue": "The work is completed",
        "FeedbackIsCompletedFalse": "The work is not completed",
        "CloseAndSave": "Save and close",
        "CloseWithoutSave": "Close without saving",
        "WorksheetCloseDescription": "The changes to the worksheet have not yet been submitted to the server. Are you sure you want to close the worksheet?",
        "WorksheetCloseDescription2": "(All worksheet changes will be saved immediately to your device, which you can resume later when you open it, but the worksheet is not yet finalised.)",
        "WorksheetCloseDescription3": "Choose between closing with or without saving.",
        "WorksheetCloseTitle": "Munkalap bezárása",
        "SaveError": "Save failed.",
        "Brand": "Brand (SWW)",
        "Items": "Items",
        "ManuallyTyped": "Manually typed",
        "ManuallyTypedLabel": "Please enter the name of the product",
        "ManuallyTypedUnitMsr": "Unit of measure",
        "ToSign": "To sign",
        "AddToSignCart": "To sign",
        "RemoveFromSignCart": "In cart",
        "SignAdded": "Added to a cart for signature.",
        "SignRemoved": "Signature removed from cart.",
        "ClearAll": "Delete all",
        "SignAll": "Sign all",
        "Worksheets": "Worksheets",
        "NumAtCard": "Customer ref.no",
        "Name": "Name",
        "TripAckUnchecked": "You will need to confirm the delivery again!",
        "All": "All",
        "ShowAll": "Show all",
        "DisplayHelp": "Display rule",
        "UploadCancelled": "Upload cancelled",
        "DifferentServerVersion": "Changes to the worksheet have not yet been saved, but changes have already been made on the server.",
        "DifferentServerVersionWarningPrefix": "There are different worksheet changes on the server (also modified on another device). Overwrite by saving the current worksheet or ",
        "DifferentServerVersionWarningLink": "load the server version ",
        "DifferentServerVersionWarningSuffix": "(current changes will be lost).",
        "DifferentPreviousVersionError": "Different previous version identifier.",
        "WorksheetDelete": "Worksheet delete",
        "FailedToLoadWorksheet": "Failed to load worksheet by id. The requested worksheet id is wrong, or the worksheet got deleted and cannot be found anymore.",
        "Error": "Error",
        "EditSubject": "Edit subject",
        "RequiredField": "Required field",
        "EditDisabledMsg": "Modifying a completed worksheet is no longer possible!",
        "EditDisabledMsgEvaluated": "Modifying or signing an evaluated worksheet is no longer possible!",
        "PdfDownload": "Pdf download",
        "EquipmentImages": "Attached images",
        "SignatureRequired": "Signature required!",
        "WithSignature": "Attach signature",
        "FinishWithSignature": "Finish",
        "FinishWithoutSignature": "Finish (omit signature)",
        "DeletedWorksheet": "Deleted worksheet!",
        "ClosedSalesOrderRow": "An evaluated worksheet already includes the article!",
        "NoTimeRowsFoundTitle": "Missing working time",
        "NoTimeRowsFoundMessage": "You did not add any work hour line to the worksheet. Are you sure you want to continue?",
        "WorksheetForceReloadedFromServer": "The worksheet has been reloaded from the server as it has been modified in the meantime.",
    }
}