import {CDAMWMWServiceCallItemRowSave} from "crowbar-api";
import {IconButton} from "@mui/material";
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import useAvailableItemRowSum from "modules/worksheet/editor/state/helpers/useAvailableItemRowSum";
import {useServiceCallItemRowAtom} from "modules/worksheet/editor/state/atoms/useServiceCallItemRowAtom";
import {DateUtils} from "shared/utils/DateUtils";

interface WEditorPageCatalogueItemRowQtySyncBtnProps {
    itemRow: CDAMWMWServiceCallItemRowSave
    disabled?: boolean
}

const WEditorPageCatalogueItemRowQtySyncBtn = ({itemRow, disabled}: WEditorPageCatalogueItemRowQtySyncBtnProps) => {

    const irSum = useAvailableItemRowSum(itemRow)
    const [, setItemRow] = useServiceCallItemRowAtom(itemRow.serviceCallId, itemRow.id)

    const mode: 'left' | 'right' = itemRow.installedQuantity === 0 ? 'right' : 'left'

    const arrowDisabled = disabled || (irSum.available === 0 && mode === 'right')

    const onBtnClick = (cMode: 'left' | 'right') => {
        if (arrowDisabled) return

        if (cMode === "right") {
            // set all available to installedQuantity
            setItemRow(prev => {
                return {
                    ...prev,
                    installedQuantity: irSum.available,
                    updatedAt: DateUtils.utcTimestampStrNow()
                }
            })
        } else {
            // remove all installed
            setItemRow(prev => {
                return {
                    ...prev,
                    installedQuantity: 0,
                    updatedAt: DateUtils.utcTimestampStrNow()
                }
            })
        }
    }

    return (
        <IconButton
            onClick={() => onBtnClick(mode)}
            sx={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                fontSize: "32pt",
                cursor: "pointer",
                color: theme => arrowDisabled ? theme.palette.text.disabled : theme.palette.text.primary,
                ":hover": arrowDisabled ? {} : {
                    transform: "scale(1.2)",
                    color: theme => theme.palette.text.secondary
                }
            }}
        >
            {mode === "left"
                ? <ArrowCircleLeftIcon fontSize={"inherit"} color="inherit"/>
                : <ArrowCircleRightIcon fontSize={"inherit"} color="inherit"/>}
        </IconButton>
    )
}

export default WEditorPageCatalogueItemRowQtySyncBtn