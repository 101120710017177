import {useQuery} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {CDCMUserDetails, UserProfileApi} from "crowbar-api";
import AxiosCancelTokenHelper from "crowbar-api/AxiosCancelTokenHelper";
import {AxiosRequestConfig} from "axios";

export const findUserDetailsById = async (userId: string | null | undefined, options?: AxiosRequestConfig | undefined): Promise<CDCMUserDetails | null> => {
    if (!userId) return null
    const response = await crowbarApiFactory(UserProfileApi)
        .findUserById(userId, options)
    return response.data
}

export const useUserDetailsById = (userId: string | null | undefined) => {
    return useQuery(['user-profile-by-id', userId], async (context) => {
        return await findUserDetailsById(userId, AxiosCancelTokenHelper.cancelTokenFrom(context.signal))
    }, {
        cacheTime: Math.pow(10, 7),
        staleTime: Math.pow(10, 7),
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        retry: 2
    })
}