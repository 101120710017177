import React from 'react';
import {Box} from "@mui/material";

const WEditorCommonPlaceHolder = () => {
    // just a placeholder for a common page
    // (it works different from the others.)
    return (
        <Box>

        </Box>
    );
};

export default WEditorCommonPlaceHolder;