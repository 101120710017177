import React from 'react';
import {useSelectedServiceCall} from "modules/worksheet/editor/shared/data/useSelectedServiceCall";
import {useServiceCallAtom} from "modules/worksheet/editor/state/atoms/useServiceCallAtom";
import WEditorSpeechToTextField from "modules/worksheet/editor/ui/description/WEditorSpeechToTextField";
import useTranslateFunction from "shared/language/useTranslateFunction";

const WEditorDescriptionComment = () => {
    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)
    const selectedServiceCall = useSelectedServiceCall()
    const [, setSelectedServiceCall] = useServiceCallAtom(selectedServiceCall?.id)

    if (!selectedServiceCall) return <></>

    return (
        <WEditorSpeechToTextField
            sx={{marginTop: 2}}
            fullWidth
            minRows={4}
            maxRows={9} // https://stackoverflow.com/a/72170317/840315
            rows={undefined}
            label={tk('Comment')}
            value={selectedServiceCall?.comment}
            onBlur={value => {
                setSelectedServiceCall(prev => {
                    return {
                        ...prev,
                        comment: value
                    }
                })
            }}
            onTranscribeSaved={value => {
                setSelectedServiceCall(prev => {
                    return {
                        ...prev,
                        comment: ((prev.comment ?? "") + "\r\n" + value).trim()
                    }
                })
            }}
        />
    )
}

export default WEditorDescriptionComment;