import {useQuery} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {CDSMDeliveryOrDraftExistsParams, CDSMDeliveryOrDraftExistsResult, SapDeliveryApi} from "crowbar-api";
import AxiosCancelTokenHelper from "crowbar-api/AxiosCancelTokenHelper";
import {UseQueryOptionParams} from "crowbar-api/util/UseQueryOptionParams";
import {wait} from "shared/utils/wait";

export const sapDeliveryExistsOrDraft = async (p: CDSMDeliveryOrDraftExistsParams, signal?: AbortSignal | undefined) => {
    if (!p?.deliveryDraftDocEntry && !p?.deliveryDocEntry && !p?.deliveryDocNum) return []
    const response = await crowbarApiFactory(SapDeliveryApi)
        .exists(p, AxiosCancelTokenHelper.cancelTokenFrom(signal))
    return response.data ?? []
}

export const useSapDeliveryExistsOrDraft = (p: CDSMDeliveryOrDraftExistsParams, extraOptions?: UseQueryOptionParams) => {
    return useQuery(['sap-delivery-exists-or-draft', p], async ({signal}) => {
        return sapDeliveryExistsOrDraft(p, signal)
    }, {
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        cacheTime: 0,
        staleTime: 0,
        retry: 1,
        ...extraOptions
    })
}

export type UseSapDeliveryExistsLoopUntilFoundOptions = {
    timeout: number
    waitsInMilliseconds: number
}


/**
 * Tries to find the Delivery Draft DocEntry and DocNum based on the Params deliveryDraftDocEntry
 * until a certain timeout. The result must have a draftDocEntry to be matched.
 * @param p
 * @param loopOptions
 * @param extraOptions
 * @return The CDSMDeliveryOrDraftExistsResult or null.
 */
export const useSapDeliveryDraftExistsLoopUntilFoundByDraftDocEntry = (
    p: CDSMDeliveryOrDraftExistsParams,
    loopOptions: UseSapDeliveryExistsLoopUntilFoundOptions,
    extraOptions?: UseQueryOptionParams
) => {
    return useQuery(['sap-delivery-exists-loop-until-found-draft', p], async ({signal}): Promise<CDSMDeliveryOrDraftExistsResult | null> => {
        if (!p?.deliveryDraftDocEntry) return null

        let timeout = loopOptions.timeout;
        let end = false
        let matched: CDSMDeliveryOrDraftExistsResult | null = null
        while (!end && timeout > 0) {
            const result = await sapDeliveryExistsOrDraft(p, signal);
            matched = result
                ?.find(d => d.draftDocEntry && d.draftDocEntry === p.deliveryDraftDocEntry) ?? null
            if (matched) {
                end = true
            } else {
                timeout -= loopOptions.waitsInMilliseconds
                await wait(loopOptions.waitsInMilliseconds, signal)
            }
        }
        return matched
    }, {
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        cacheTime: 0,
        staleTime: 0,
        retry: 1,
        ...extraOptions
    })
}

/**
 * Tries to find the Delivery.DocEntry and DocNum based on the Params deliveryDraftDocEntry
 * until a certain timeout. The result must have a deliveryDocEntry to be matched.
 * Alternative: if the parameter deliveryDocEntry matches the result delivery doc entry
 * @param p
 * @param loopOptions
 * @param extraOptions
 * @return The CDSMDeliveryOrDraftExistsResult or null.
 */
export const useSapDeliveryExistsLoopUntilFoundByDraftDocEntryOrDeliveryDocEntry = (
    p: CDSMDeliveryOrDraftExistsParams,
    loopOptions: UseSapDeliveryExistsLoopUntilFoundOptions,
    extraOptions?: UseQueryOptionParams
) => {
    return useQuery(['sap-delivery-exists-loop-until-found-delivery-by-draft-doc-entry-or-delivery-doc-entry', p], async ({signal}): Promise<CDSMDeliveryOrDraftExistsResult | null> => {
        if (!p?.deliveryDraftDocEntry) return null

        let timeout = loopOptions.timeout;
        let end = false
        let matched: CDSMDeliveryOrDraftExistsResult | null = null
        while (!end && timeout > 0) {
            const result = await sapDeliveryExistsOrDraft(p, signal);
            matched = result
                ?.find(d => d.deliveryDocEntry && (
                    d.draftDocEntry === p.deliveryDraftDocEntry || d.deliveryDocEntry === p.deliveryDocEntry
                )) ?? null
            if (matched) {
                end = true
            } else {
                timeout -= loopOptions.waitsInMilliseconds
                await wait(loopOptions.waitsInMilliseconds, signal)
            }
        }
        return matched
    }, {
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        cacheTime: 0,
        staleTime: 0,
        retry: 1,
        ...extraOptions
    })
}

export const useSapDeliveryExistsLoopUntilFoundByDeliveryDocEntry = (
    p: CDSMDeliveryOrDraftExistsParams,
    loopOptions: UseSapDeliveryExistsLoopUntilFoundOptions,
    extraOptions?: UseQueryOptionParams
) => {
    return useQuery(['sap-delivery-exists-loop-until-found-delivery-by-delivery-doc-entry', p], async ({signal}): Promise<CDSMDeliveryOrDraftExistsResult | null> => {
        if (!p?.deliveryDocEntry) return null

        let timeout = loopOptions.timeout;
        let end = false
        let matched: CDSMDeliveryOrDraftExistsResult | null = null
        while (!end && timeout > 0) {
            const result = await sapDeliveryExistsOrDraft(p, signal);
            matched = result
                ?.find(d => d.deliveryDocEntry && d.deliveryDocEntry === p.deliveryDocEntry) ?? null
            if (matched) {
                end = true
            } else {
                timeout -= loopOptions.waitsInMilliseconds
                await wait(loopOptions.waitsInMilliseconds, signal)
            }
        }
        return matched
    }, {
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        cacheTime: 0,
        staleTime: 0,
        retry: 1,
        ...extraOptions
    })
}