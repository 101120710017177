import {Grid} from '@mui/material';
import React from 'react';
import WEditorDescriptionComment from "modules/worksheet/editor/ui/description/WEditorDescriptionComment";
import WEditorTaskDescription from "modules/worksheet/editor/ui/description/WEditorTaskDescription";
import WEditorFaultDescription from "modules/worksheet/editor/ui/description/WEditorFaultDescription";
import useWorksheetSignedState from "modules/worksheet/editor/state/helpers/useWorksheetSignedState";
import WEditorCallTypeSelect from "modules/worksheet/editor/ui/description/WEditorCallTypeSelect";

const WEditorPageDescription = () => {

    const {editDisabled} = useWorksheetSignedState()

    return (
        <Grid container spacing={1}>

            <Grid item xs={12}>
                <WEditorCallTypeSelect disabled={editDisabled}/>
                {/*<WEditorIsCompleted disabled={editDisabled}/>*/}
            </Grid>
            <Grid item xs={12}>
                <WEditorTaskDescription disabled={editDisabled}/>
            </Grid>
            <Grid item xs={12}>
                <WEditorFaultDescription disabled={editDisabled}/>
            </Grid>
            <Grid item xs={12}>
                <WEditorDescriptionComment/>
            </Grid>
        </Grid>
    );
};

export default WEditorPageDescription;