import {useMutation} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {CDAMWMWorksheetTimeItemCodeModel, WorksheetTimeItemCodeApi} from "crowbar-api";

const useSaveWorksheetTimeItemCode = () => {
    return useMutation(async (o: CDAMWMWorksheetTimeItemCodeModel) => {
        const response = await crowbarApiFactory(WorksheetTimeItemCodeApi)
            .save(o)
        if (response.status !== 200) {
            throw new Error('Invalid status code.')
        }
    })
}

export default useSaveWorksheetTimeItemCode