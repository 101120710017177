import React, {useState} from 'react';
import {useParams} from "react-router-dom";
import {
    useServiceCallBlobFindByWorksheetId
} from "crowbar-api/hooks/worksheet/blobs/useServiceCallBlobFindByWorksheetId";
import {Card, CardContent, CircularProgress, Grid, Stack, Typography} from "@mui/material";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {BackButton} from "shared/components/buttons/BackButton";
import {CDAMCMCrowbarBlobModel} from "crowbar-api";
import EquipmentImagesLightbox from "modules/equipment/equipment-details/equipment-images/EquipmentImagesLightbox";
import {useWorksheetQuery} from "crowbar-api/hooks/worksheet/useWorksheetQuery";
import EquipmentImageClickable from "modules/equipment/equipment-details/equipment-images/EquipmentImageClickable";
import useMechanics from "crowbar-api/hooks/mechanic/useMechanics";

const WorksheetImagesById = () => {
    const tk = useTranslateFunction("Admin.Worksheet")
    const {worksheetId} = useParams() // tied to the url

    const wsQuery = useWorksheetQuery(worksheetId)
    const ws = wsQuery.data
    const blobQuery = useServiceCallBlobFindByWorksheetId(worksheetId);
    const blobs = blobQuery.data ?? []
    const mechanicsQuery = useMechanics()
    const mechanics = mechanicsQuery.data ?? []

    const [lightboxOpen, setLightboxOpen] = useState(false)
    const [clickedImageId, setClickedImageId] = useState<string | undefined>(undefined)

    const onImageClick = (blob: CDAMCMCrowbarBlobModel) => {
        setClickedImageId(blob.id)
        setLightboxOpen(true)
    }

    const onLightboxClose = () => {
        setLightboxOpen(false)
    }

    if (blobQuery.isLoading) {
        return (
            <CircularProgress/>
        )
    }

    return (
        <Grid container spacing={1}>

            <Grid item xs={12}>
                <Stack direction="row" spacing={2}>
                    <Typography variant="h4" display="flex" alignItems="center">
                        <BackButton useOnClick={true} onClick={() => window.history.back()}/>
                        &nbsp;{ws?.serialId} {tk('Images')}
                    </Typography>
                </Stack>
            </Grid>

            {(!blobs || blobs.length < 1) && (
                <Typography>{tk('NoImagesFound')}</Typography>
            )}

            {blobs.map((blob, i) => {
                const mechanic = mechanics.find(m => m.userId === blob.createdBy)

                return (
                    <Grid key={[blob.id, i].join("-")} item xs={3} md={2}>
                        <Card variant="outlined" sx={{
                            ":hover": {
                                "border": "1px solid black",
                                "cursor": "pointer"
                            }
                        }}>
                            <EquipmentImageClickable
                                blob={blob}
                                onClick={onImageClick}
                            />

                            <CardContent>
                                <Typography fontWeight="bold">{blob.originalName}</Typography>
                                {mechanic?.fullName}
                                {blob.comment && (
                                    <Typography>Comment: {blob.comment}</Typography>
                                )}
                            </CardContent>

                        </Card>
                    </Grid>
                )
            })}

            <EquipmentImagesLightbox
                open={lightboxOpen}
                onClose={onLightboxClose}
                selectedImageId={clickedImageId}
                images={blobs}
            />
        </Grid>
    );
};

export default WorksheetImagesById;