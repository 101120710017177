import React from 'react';
import useTranslateFunction from "shared/language/useTranslateFunction";
import {Card, CardContent, Grid, Typography} from "@mui/material";
import PartnerContactMasterDataCurrent
    from "modules/admin/worksheet/evaluation/tabs/master-data/PartnerContactMasterDataCurrent";
import PartnerContactMasterDataSelect
    from "modules/admin/worksheet/evaluation/tabs/master-data/PartnerContactMasterDataSelect";
import PartnerContactMasterDataSelectAck
    from "modules/admin/worksheet/evaluation/tabs/master-data/PartnerContactMasterDataSelectAck";
import {CDAMWMWorksheetEvalModel, CDAMWMWWorksheetQuery} from "crowbar-api";
import FixesWorksheetDataIcon from "modules/admin/worksheet/evaluation/shared/FixesWorksheetDataIcon";

export type PartnerContactMasterDataProps = {
    wEval: CDAMWMWorksheetEvalModel | null | undefined
    worksheet: CDAMWMWWorksheetQuery | null | undefined
    onDataChange: () => void
}
const PartnerContactMasterData = ({worksheet, wEval, onDataChange}: PartnerContactMasterDataProps) => {
    const tk = useTranslateFunction('Admin.Worksheet')

    return (
        <Card variant="outlined">
            <CardContent>
                <Grid container spacing={1}>

                    <Grid item xs={12}>
                        <Typography variant="h6">{tk('Contact')}&nbsp;<FixesWorksheetDataIcon/></Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={0.5}>
                            <Grid item xs={6}>
                                <PartnerContactMasterDataCurrent worksheet={worksheet}/>
                            </Grid>
                            <Grid item xs={6}>

                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <Typography>
                                            SAP {tk('Contact')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container direction="row" spacing={1} alignItems="center">
                                            <Grid item xs={10}>
                                                <PartnerContactMasterDataSelect wEval={wEval} worksheet={worksheet}
                                                                                onDataChange={onDataChange}/>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <PartnerContactMasterDataSelectAck wEval={wEval} worksheet={worksheet}
                                                                                   onDataChange={onDataChange}/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>

            </CardContent>
        </Card>
    )
}

export default PartnerContactMasterData;