import {CDAMWMWorksheetSelectedDataSource} from "crowbar-api";

export default class WorksheetSelectedDataSourceMap {
    public static readonly notSet = CDAMWMWorksheetSelectedDataSource.NUMBER_0
    public static readonly sapMasterData = CDAMWMWorksheetSelectedDataSource.NUMBER_1
    public static readonly modSap = CDAMWMWorksheetSelectedDataSource.NUMBER_2
    public static readonly sapSalesOrder = CDAMWMWorksheetSelectedDataSource.NUMBER_3
    public static readonly ownPremise = CDAMWMWorksheetSelectedDataSource.NUMBER_4

    public static translationKey = (t?: CDAMWMWorksheetSelectedDataSource): string => {

        const preKey = "Worksheet.Generic.SelectedDataSource."

        if (t === this.notSet) {
            return `${preKey}NotSet`
        }

        if (t === this.sapMasterData) {
            return `${preKey}SapMasterData`
        }

        if (t === this.modSap) {
            return `${preKey}ModSap`
        }

        if (t === this.sapSalesOrder) {
            return `${preKey}SapSalesOrder`
        }

        if (t === this.ownPremise) {
            return `${preKey}OwnPremise`
        }

        return "Missing"
    }
}