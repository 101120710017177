import React from 'react';
import {Box, Button} from '@mui/material';
import {Waypoint} from "react-waypoint";
import {ArrayReduceToUniqueMap} from "shared/utils/ArrayReduceToUniqueMap";

export default class PagingRowHolder<T extends { rowNum?: number }> {
    isWaypoint: boolean = false;
    isWaypointDisabled: boolean = false;
    row?: T;

    public static findLastRowNumFor<T extends { rowNum?: number }>(rows: PagingRowHolder<T>[]): number {
        for (let i = rows.length - 1; i >= 0; i--) {
            const row = rows[i];
            if (row.isWaypoint) continue;
            if (!row.row) continue;
            return row.row.rowNum ?? 0;
        }

        return 0;
    }

    public static defaultWaypointRender(buttonText: string,
                                        onWaypointClicked: () => void,
                                        isWaypointDisabled?: boolean,
                                        bottomOffset: string | number = '-30%',
                                        className?: string): JSX.Element {
        return (
            <Box sx={{display: "flex", justifyContent: "center"}} className={className}>
                <Button disabled={isWaypointDisabled}
                        variant={"outlined"}
                        onClick={
                            () => onWaypointClicked()
                        }
                >
                    {buttonText}
                </Button>
                <Waypoint
                    onEnter={() => onWaypointClicked()}
                    bottomOffset={bottomOffset}
                />
            </Box>
        );
    }

    /**
     * Adds the new rows to the holder objects.
     * It creates a new array of holders, containing the previous state rows
     * and the new rows. Filters existing rows by the row num.
     * Always puts a waypoint at the end and filters previous ones.
     * @param stateRows
     * @param newRows
     * @param keyAccessor
     * @return New array os holders
     */
    public static addRowsToState<T extends {
        rowNum?: number
    }, K>(stateRows: PagingRowHolder<T>[], newRows: T[], keyAccessor: (e: PagingRowHolder<T>) => K): PagingRowHolder<T>[] {
        // new array from current state
        // to signal state change
        // and filter previous waypoint(s) (only allow one at the end)
        const rows = stateRows.filter(r => !r.isWaypoint);
        const rowKeys: number[] = rows.map(r => r.row?.rowNum ?? 0);
        const newRowsMapped = newRows
            // filter rows that are already in the rows array (by row num)
            .filter(newRow => newRow.rowNum !== undefined && rowKeys.indexOf(newRow.rowNum) < 0)
            // map to a holder
            .map(r => {
                const result: PagingRowHolder<T> = {
                    isWaypoint: false,
                    isWaypointDisabled: false,
                    row: r
                };
                return result;
            });

        // add rows
        rows.push(...newRowsMapped);

        // removing duplicates using the key
        const filteredRows = ArrayReduceToUniqueMap(rows, keyAccessor)

        // add waypoint at the end
        filteredRows.push({
            isWaypoint: true,
            isWaypointDisabled: filteredRows.length > 0
        });
        // return new rows array
        return filteredRows;
    }
}