import {CDAMWMWModSapPartnerAddressSave} from "crowbar-api";
import {useForm, UseFormRegisterReturn, UseFormReturn} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {yupStringToNumberOrNull} from "shared/utils/YupHelpers";
import {useTranslation} from "react-i18next";
import {useEffect, useRef} from "react";

export interface UsePartnerAddressFormParams {
    mode: 'new' | 'edit'
    editModAddress?: CDAMWMWModSapPartnerAddressSave
}

export interface PartnerAddressFormResult extends UseFormReturn<PartnerAddressFormValues, any> {
    zipCodeRegister: UseFormRegisterReturn<"zipCode">
    cityRegister: UseFormRegisterReturn<"city">
    streetRegister: UseFormRegisterReturn<"street">
    streetNoRegister: UseFormRegisterReturn<"streetNo">
    commentRegister: UseFormRegisterReturn<"comment">
}

export interface PartnerAddressFormValues {
    zipCode?: string
    city?: string
    street?: string
    streetNo?: string
    comment?: string
}

const prepareFormSchema = (tk: (key: string, params?: any) => string) => {
    return yup.object({
        zipCode: yup.number()
            .notRequired()
            .typeError(tk('OnlyNumbersAllowed')) // optional field
            .transform(yupStringToNumberOrNull)
            .nullable()
            //.default(undefined)
            .integer(tk('OnlyIntegersAllowed')),
        city: yup.string()
            .required(tk('FieldIsRequired'))
            .max(255, tk('StringIsLongerThan', {max: 255})),
        street: yup.string()
            //.required(tk('FieldIsRequired'))
            .max(255, tk('StringIsLongerThan', {max: 255})),
        streetNo: yup.string()
            //.required(tk('FieldIsRequired'))
            .max(255, tk('StringIsLongerThan', {max: 255})),
        comment: yup.string()
    })
}

export const usePartnerAddressForm = ({
                                          mode,
                                          editModAddress
                                      }: UsePartnerAddressFormParams): PartnerAddressFormResult => {
    const {t} = useTranslation()
    const tkValidation = (key: string, params?: any): string => {
        return t(`Generic.Validations.${key}`, params)
    }
    const formSchemaRef = useRef<any>(prepareFormSchema(tkValidation))
    formSchemaRef.current = prepareFormSchema(tkValidation)

    const form = useForm<PartnerAddressFormValues>({
        resolver: yupResolver(formSchemaRef.current)
    })

    const zipCodeRegister = form.register("zipCode")
    const cityRegister = form.register("city")
    const streetRegister = form.register("street")
    const streetNoRegister = form.register("streetNo")
    const commentRegister = form.register("comment")

    // If mode is update, load the values from the params
    useEffect(() => {
        // Only load the params if the form is not dirty
        if (!form.formState.isDirty && editModAddress && mode === "edit") {
            const setOpts = {
                shouldDirty: false,
                shouldTouch: false,
                shouldValidate: true
            }
            form.setValue("zipCode", editModAddress.zipCode ?? "", setOpts)
            form.setValue("city", editModAddress.city ?? "", setOpts)
            form.setValue("street", editModAddress.street ?? "", setOpts)
            form.setValue("streetNo", editModAddress.streetNo ?? "", setOpts)
            form.setValue("comment", editModAddress.comment ?? "", setOpts)
        }
    }, [editModAddress, form, form.formState.isDirty, mode])

    return {
        ...form,
        zipCodeRegister,
        cityRegister,
        streetRegister,
        streetNoRegister,
        commentRegister
    }
}