import React from "react";
import {CDAMWMWServiceCallItemRowSave} from "crowbar-api";
import ItemImageDisplay from "modules/worksheet/editor/ui/catalogue/item-image/ItemImageDisplay";

interface WEditorPageCatalogueItemRowImageProps {
    itemRow: CDAMWMWServiceCallItemRowSave
    disabled?: boolean
}

const WEditorPageCatalogueItemRowImage = ({itemRow, disabled}: WEditorPageCatalogueItemRowImageProps) => {
    return (
        <ItemImageDisplay itemCode={itemRow?.itemCode ?? undefined}
                          coverImage={itemRow?.sourceCoverImage ?? undefined}
                          disabled={disabled}
        />
    )
}

export default WEditorPageCatalogueItemRowImage