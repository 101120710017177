import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {WorksheetSaveWrapper} from "modules/worksheet/storage/WorksheetSaveStorage";
import {useWorksheetLocalFindWrapper} from "modules/worksheet/editor/shared/data/useWorksheetLocalFindWrapper";

export const worksheetQueryKey = (worksheetId: string): [string, string] => {
    return ['worksheet-editor-local-query-1', worksheetId]
}

/**
 * Queries the local storage for the specified worksheet id.
 * @param worksheetId id of the worksheet, can change and the result will change as well.
 * @returns {UseQueryResult} for the CDAMWMWWorksheetSave or undefined
 */
export const useWorksheetLocalQuery = (worksheetId: string | null | undefined): UseQueryResult<WorksheetSaveWrapper | null, unknown> => {
    const findWrapper = useWorksheetLocalFindWrapper()
    return useQuery(worksheetQueryKey(worksheetId ?? ""), async (): Promise<WorksheetSaveWrapper | null> => {
        return await findWrapper(worksheetId)
    }, {
        refetchOnWindowFocus: true,
        refetchOnMount: true,
        keepPreviousData: false,
        cacheTime: 0,
        staleTime: 0,
        retry: 0
    })
}