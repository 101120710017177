import {atom} from "jotai";
import {RouteObject} from "react-router-dom";

export enum WorksheetEditorPageName {
    BASIC = 0,
    EQUIPMENTS = 1,
    CATALOGUE = 2,
    DESCRIPTION = 3,
    PHOTO = 4,
    FEEDBACK = 5
}

export type WorksheetEditorPageRouteObject = RouteObject & {
    pageName: WorksheetEditorPageName
}

export const WorksheetEditorPageRoutesAtom = atom<WorksheetEditorPageRouteObject[]>([])