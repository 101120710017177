import React from 'react';
import {CDAMWMServiceCallEvalModel} from "crowbar-api";
import CheckIconButton from "shared/components/buttons/CheckIconButton";

export type DeliveryStatusCellProps = {
    row: CDAMWMServiceCallEvalModel
}
const DeliveryStatusCell = ({row,}: DeliveryStatusCellProps) => {

    const hasDelivery = (row.selectedDeliveryDocEntry ?? 0) > 0

    return (
        <CheckIconButton checked={hasDelivery}/>
    );
};

export default DeliveryStatusCell;