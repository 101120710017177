import React from 'react';
import TextFieldEx, {TextFieldExProps} from "shared/components/inputs/TextFieldEx";

export type TextAreaProps = TextFieldExProps & {}

const TextArea = ({...props}: TextAreaProps) => {
    return (
        <TextFieldEx
            fullWidth
            multiline
            rows={3}
            {...props}
        />
    );
};

export default TextArea;