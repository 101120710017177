import React from 'react';
import {
    CustomerNotificationConfigurationProps
} from "modules/admin/worksheet/notifications/customer-notification/CustomerNotificationConfigurationProps";
import {
    useCustomerNotificationConfigurationState
} from "modules/admin/worksheet/notifications/customer-notification/state/useCustomerNotificationConfigurationState";
import {Card, CardContent, Grid} from "@mui/material";
import SearchBoxAndDebounce from "shared/components/inputs/SearchBoxAndDebounce";

export type CustomerNotificationConfigurationFiltersProps = CustomerNotificationConfigurationProps & {}
export const CustomerNotificationConfigurationFilters = ({stateAtom}: CustomerNotificationConfigurationFiltersProps) => {
    const {search, setState} = useCustomerNotificationConfigurationState(stateAtom)

    const onSearchValueChange = (newValue: string) => {
        setState(prev => {
            return {
                ...prev,
                search: newValue,
                lastRowNumber: 0
            }
        })
    }

    return (
        <Card
            variant="outlined"
        >
            <CardContent>
                <Grid container spacing={1}>

                    <Grid item xs={12}>
                        <SearchBoxAndDebounce
                            value={search ?? ""}
                            onChange={onSearchValueChange}
                            size="small"
                        />
                    </Grid>

                </Grid>
            </CardContent>
        </Card>
    )
}