import React from 'react';
import {CDSMNNewWorksheetEquipment} from "crowbar-api";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {Accordion, AccordionDetails, AccordionSummary, Button, Grid, Typography} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LinearIndeterminateProgressWithLabel from "shared/components/progress/LinearIndeterminateProgressWithLabel";
import NewWorksheetResultEquipment from "modules/worksheet/new-worksheet/results/NewWorksheetResultEquipment";

export type EquipmentResultListProps = {
    equipments: CDSMNNewWorksheetEquipment[]
    equipmentsIsLoading: boolean
    hasNextPage: boolean
    onEquipmentsLoadMore: () => Promise<void>
    subTablePageSize: number
}
const EquipmentResultList = ({
                                 equipments,
                                 equipmentsIsLoading,
                                 hasNextPage,
                                 onEquipmentsLoadMore,
                                 subTablePageSize
                             }: EquipmentResultListProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.NewWorksheet`)

    return (
        <Accordion variant="outlined" defaultExpanded={true} sx={{width: 1}}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
            >
                <Typography fontWeight="bold">{tk('Equipments')}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{width: 1}}>
                <Grid container spacing={1}>

                    {equipments.map(e => (
                        <Grid item xs={12} key={'eq' + e.sapEquipmentId}>
                            <NewWorksheetResultEquipment
                                equipment={e}
                                pageSize={subTablePageSize}
                            />
                        </Grid>
                    ))}

                    {equipmentsIsLoading && (
                        <Grid item xs={12} sx={{my: 3}}>
                            <LinearIndeterminateProgressWithLabel label={tk('EquipmentsIsLoading')}/>
                        </Grid>
                    )}

                    <Grid item xs={12} display="flex" justifyContent="center" sx={{my: 3}}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={onEquipmentsLoadMore}
                            disabled={equipmentsIsLoading || !hasNextPage}
                        >
                            {tk('LoadMore')}
                        </Button>
                    </Grid>

                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

export default EquipmentResultList;