import {useQuery} from "@tanstack/react-query";
import {AxiosRequestConfig, AxiosResponse} from "axios";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {WorksheetPdfApi} from "crowbar-api";
import {UseWorksheetPdfDownloadProps} from "crowbar-api/hooks/worksheet/pdf/useWorksheetPdfDownload";
import AxiosCancelTokenHelper from "crowbar-api/AxiosCancelTokenHelper";

const blobToBase64 = async (blob: Blob) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result)
        reader.onerror = (err) => reject(err)
        reader.readAsDataURL(blob)
    })
}

export const useWorksheetPdfDownloadAsBase64 = ({
                                                    worksheetId,
                                                    layout,
                                                    hideCustomerSignature
                                                }: UseWorksheetPdfDownloadProps) => {
    return useQuery(['worksheet-pdf-download-as-object-url-1', worksheetId, layout], async ({signal}) => {
        const options: AxiosRequestConfig = {
            responseType: 'arraybuffer',
            headers: {
                'Accept': 'application/pdf'
            },
            cancelToken: AxiosCancelTokenHelper.cancelTokenFrom(signal).cancelToken
        }

        const pdfApi = crowbarApiFactory(WorksheetPdfApi)
        let response: AxiosResponse;
        if (layout === 'admin') {
            response = await pdfApi.downloadAdminPdf(worksheetId, hideCustomerSignature, options)
        } else {
            response = await pdfApi.downloadCustomerPdf(worksheetId, hideCustomerSignature, options)
        }

        const blob = new Blob([response.data], {type: "application/pdf"})
        return blobToBase64(blob)
    }, {
        networkMode: "online",
        refetchOnWindowFocus: false,
        refetchOnMount: true,
        staleTime: 0,
        cacheTime: 0,
        retry: 2
    })
}