import React from 'react';
import {CDAMWMServiceCallEvalModel, CDAMWMServiceCallModel, CDAMWMWWorksheetQuery} from "crowbar-api";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {
    useServiceCallEvalUpdateEval
} from "crowbar-api/hooks/worksheet/evaluation/service-call/useServiceCallEvalUpdateEval";
import {Checkbox, FormControlLabel} from "@mui/material";

export type LinkToDeliveryCheckboxProps = {
    worksheet: CDAMWMWWorksheetQuery | null | undefined
    sc: CDAMWMServiceCallModel
    scEval: CDAMWMServiceCallEvalModel
    disabled?: boolean
    onMasterDataChange: () => Promise<void>
}
const LinkToDeliveryCheckbox = ({
                                    worksheet,
                                    sc,
                                    scEval,
                                    disabled,
                                    onMasterDataChange
                                }: LinkToDeliveryCheckboxProps) => {
    const tk = useTranslateFunction('Admin.Worksheet')
    const updateEval = useServiceCallEvalUpdateEval()

    const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked;
        await updateEval.mutateAsync({
            id: scEval.id,
            deliveryDraftDocEntry: scEval.deliveryDraftDocEntry,
            equipmentAck: scEval.equipmentAck,
            linkDeliveryToServiceCall: checked,
            linkSalesOrderToDelivery: scEval.linkSalesOrderToDelivery,
            selectedCallId: scEval.selectedCallId,
            selectedDeliveryDocEntry: scEval.selectedDeliveryDocEntry,
            selectedEquipmentInsId: scEval.selectedEquipmentInsId
        })
        await onMasterDataChange()
    }

    const value = scEval?.linkDeliveryToServiceCall ?? false

    return (
        <FormControlLabel
            disabled={disabled || updateEval.isLoading || !!scEval.selectedCallId}
            control={
                <Checkbox
                    onChange={onChange}
                    checked={value}
                />
            }
            label={tk('LinkToDeliveryDescription')}
        />
    )
};

export default LinkToDeliveryCheckbox;