import {useMutation} from "@tanstack/react-query";
import {findUserDetailsById} from "crowbar-api/hooks/user/useUserDetailsById";
import {useWorksheetCartState} from "modules/worksheet/worksheet-cart/useWorksheetCartState";
import {CDAMWMWorksheetStatus} from "crowbar-api"

export type AddToWorksheetCartRequiredFields = {
    id?: string | null
    'status'?: CDAMWMWorksheetStatus;
    serialId?: string | null
    ownerId?: string | null
    startedAt?: string | undefined | null
    endedAt?: string | undefined | null
}

export type UseWorksheetCartAddOrRemoveEntryResult = {
    added?: boolean
    removed?: boolean
}


/**
 * Can be used to trigger add/remove with the same button and single call.
 * @return function Adds the Worksheet to the cart if it does not contain the entry, remove it otherwise
 * @param scope
 */
export const useWorksheetCartAddOrRemoveEntry = (scope?: number | string) => {
    const {addCartEntry, removeCartEntry, isInCart} = useWorksheetCartState(scope)
    return useMutation(['worksheet-cart-add-entry-1'], async (w: AddToWorksheetCartRequiredFields): Promise<UseWorksheetCartAddOrRemoveEntryResult> => {
        if (!w.id) {
            return {
                added: false,
                removed: false
            }
        }

        if (!isInCart(w.id)) {

            const userDetails = await findUserDetailsById(w.ownerId) // is it possible to combine this with useQuery instead?
            const mName = userDetails?.fullName

            addCartEntry({
                id: w.id,
                addedAt: new Date().getTime(),

                serialId: w.serialId,
                ownerId: w.ownerId,
                mechanicName: mName,
                startedAt: w.startedAt,
                endedAt: w.endedAt
            })
            return {
                added: true,
                removed: false
            }
        } else {
            removeCartEntry(w.id)
            return {
                added: false,
                removed: true
            }
        }
    })
}