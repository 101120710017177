import React from 'react';
import {Route, Routes} from "react-router-dom";
import EquipmentSearch from "modules/equipment/equipment-search/EquipmentSearch";
import EquipmentDetails from "modules/equipment/equipment-details/EquipmentDetails";

const EquipmentRoutes = () => {
    return (
        <Routes>
            <Route path={`details`} element={(
                <EquipmentDetails/>
            )}/>

            <Route index element={(
                <EquipmentSearch/>
            )}/>
        </Routes>
    )
}

export default EquipmentRoutes;