import React from 'react';
import {Card, CardContent, Container, Grid, Typography} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import useTranslateFunction from "shared/language/useTranslateFunction";

export type GenericErrorDisplayProps = {
    title?: string
    message?: string
    logs?: any
}

export const GenericErrorDisplay = ({title, message, logs}: GenericErrorDisplayProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    return (
        <Container>
            <Card>
                <CardContent>
                    <Grid container>
                        <Grid item xs={12} display="flex" alignItems="center">
                            <Typography component="h1" sx={{
                                fontSize: "56pt",
                                color: "red"
                            }}>
                                <ErrorIcon fontSize="inherit"/>&nbsp;{title ?? 'Error'}
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography>{message ?? "N/A"}</Typography>
                        </Grid>
                        <Grid item xs="auto">
                            <pre>{JSON.stringify(logs, null, 2)}</pre>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Container>
    );
}