import React, {useState} from 'react';
import {useSearchParams} from "react-router-dom";
import {Box, Card, CardContent, CircularProgress, Grid, Stack, Tab, Tabs, Typography} from "@mui/material";
import EquipmentImages from "modules/equipment/equipment-details/equipment-images/EquipmentImages";
import {BackButton} from "shared/components/buttons/BackButton";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {useSapPartnerEquipmentFindByInsId} from "crowbar-api/hooks/sap/useSapPartnerEquipmentFindByInsId";
import EquipmentDetailsFields from "modules/equipment/equipment-details/details/EquipmentDetailsFields";
import EquipmentServiceCalls from "modules/equipment/equipment-details/equipment-service-calls/EquipmentServiceCalls";

class EquipmentDetailsTab {
    public static ServiceCalls: number = 0
    public static Images: number = 1
}

const EquipmentDetails = () => {
    const tk = useTranslateFunction('Equipment')
    const [searchParams] = useSearchParams()
    const qParamSapEquipmentId = searchParams.get("sapEquipmentId")
    const sapEquipmentId = !!qParamSapEquipmentId && !isNaN(+qParamSapEquipmentId) ? +qParamSapEquipmentId : undefined
    const modSapEquipmentId = searchParams.get("modSapEquipmentId") ?? undefined

    const [selectedTab, setSelectedTab] = useState<number>(EquipmentDetailsTab.Images)

    const partnerEquipmentQuery = useSapPartnerEquipmentFindByInsId(sapEquipmentId)

    const partnerEquipment = partnerEquipmentQuery.data

    const onTabChange = (e: any, newValue: number) => {
        setSelectedTab(newValue)
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <BackButton to="/equipment" iconProps={{sx: {fontSize: "36pt"}}}/>
                    <Typography variant="h2">
                        {tk('Equipment')}
                    </Typography>
                </Stack>
            </Grid>

            <Grid item xs={6}>
                <Card variant="outlined">
                    <CardContent>
                        {partnerEquipmentQuery.isLoading ? (
                            <CircularProgress/>
                        ) : (
                            <EquipmentDetailsFields
                                equipment={partnerEquipment}
                            />
                        )}
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <Tabs value={selectedTab} onChange={onTabChange} aria-label="basic tabs example">
                        <Tab label={tk('ServiceCalls')} value={EquipmentDetailsTab.ServiceCalls}/>
                        <Tab label={tk('Images')} value={EquipmentDetailsTab.Images}/>
                    </Tabs>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <Box
                    sx={{
                        width: 1,
                        p: 1
                    }}
                >
                    {selectedTab === EquipmentDetailsTab.ServiceCalls && (
                        <EquipmentServiceCalls
                            sapEquipmentId={sapEquipmentId}
                            modSapEquipmentId={modSapEquipmentId}
                        />
                    )}
                    {selectedTab === EquipmentDetailsTab.Images && (
                        <EquipmentImages
                            sapEquipmentId={sapEquipmentId}
                            modSapEquipmentId={modSapEquipmentId}
                            coverImage={partnerEquipment?.coverImage}
                        />
                    )}
                </Box>
            </Grid>
        </Grid>
    );
};

export default EquipmentDetails;