import React, {useRef} from 'react';
import {
    useWorksheetEvalUpdatePartnerAddress
} from "crowbar-api/hooks/worksheet/evaluation/worksheet/useWorksheetEvalUpdatePartnerAddress";
import useSapPartnerAddressesByCardCode from "crowbar-api/hooks/sap/useSapPartnerAddressesByCardCode";
import SapPartnerAddressUtils from "crowbar-api/util/SapPartnerAddressUtils";
import WorksheetSelectedDataSourceMap from "crowbar-api/enum-map/WorksheetSelectedDataSourceMap";
import {CircularProgress} from "@mui/material";
import {CheckIconFabWithLoading} from "shared/components/buttons/CheckIconFabWithLoading";
import {
    useWorksheetEvalUpdateAddressAck
} from "crowbar-api/hooks/worksheet/evaluation/worksheet/useWorksheetEvalUpdateAddressAck";
import {CDAMWMWorksheetEvalModel, CDAMWMWWorksheetQuery} from "crowbar-api";

export type PartnerAddressMasterDataSelectAckProps = {
    wEval: CDAMWMWorksheetEvalModel | null | undefined
    worksheet: CDAMWMWWorksheetQuery | null | undefined
    onDataChange: () => void
}
const PartnerAddressMasterDataSelectAck = ({
                                               wEval,
                                               worksheet,
                                               onDataChange
                                           }: PartnerAddressMasterDataSelectAckProps) => {

    const ws = worksheet

    const ack = wEval?.addressAck

    const updateAddressAck = useWorksheetEvalUpdateAddressAck();
    const updatePartnerAddress = useWorksheetEvalUpdatePartnerAddress()

    const isUpdating = updateAddressAck.isLoading || updatePartnerAddress.isLoading

    const addressQuery = useSapPartnerAddressesByCardCode(ws?.cardCode, {
        cacheTime: 0,
        staleTime: 0,
        keepPreviousData: true,
        refetchOnMount: true,
        refetchOnWindowFocus: true
    })
    const selectedAddress = (addressQuery.data ?? []).find(a =>
        SapPartnerAddressUtils.addressKeyFor(null, a.addressType, a.addressName) === wEval?.selectedSapAddressAndType
    )
    const addressRef = useRef(selectedAddress)
    addressRef.current = selectedAddress

    const handleButtonClick = async () => {
        const p = addressRef.current

        if (ack || !p) {
            await updateServerAck(false)
        } else {
            await updatePartnerAddress.mutateAsync({
                originalId: ws?.id,
                evalId: wEval?.id,
                address: p.addressName,
                addressBlock: p.block,
                addressBuilding: p.building,
                addressCity: p.city,
                addressCountryCode: p.country,
                addressCounty: p.county,
                addressLatitude: null,
                addressLongitude: null,
                addressStreet: p.street,
                addressStreetNo: p.streetNo,
                addressType: p.addressType,
                addressZipCode: p.zipCode,
                partnerAddressSource: WorksheetSelectedDataSourceMap.sapMasterData
            })

            await updateServerAck(true)
        }
        onDataChange()
    }

    const updateServerAck = async (newValue: boolean) => {
        await updateAddressAck.mutateAsync({
            originalId: ws?.id,
            evalId: wEval?.id,
            field: newValue
        })
    }

    if (addressQuery.isLoading) {
        return (
            <CircularProgress/>
        )
    }

    return (
        <CheckIconFabWithLoading
            checked={ack}
            isLoading={isUpdating}
            disabled={isUpdating}
            onClick={handleButtonClick}
        />
    )
}

export default PartnerAddressMasterDataSelectAck;