import React from 'react';
import {CDAMWMServiceCallEvalModel, CDAMWMServiceCallModel, CDAMWMWWorksheetQuery} from "crowbar-api";
import NumberOnlyTextField from "shared/components/inputs/NumberOnlyTextField";

export type DeliveryDocNumInputProps = {
    worksheet: CDAMWMWWorksheetQuery | null | undefined
    sc: CDAMWMServiceCallModel
    scEval: CDAMWMServiceCallEvalModel
    disabled?: boolean
    onMasterDataChange: () => Promise<void>

    value?: number
    onChange?: (newValue: number) => void
}
const DeliveryDocNumInput = ({
                                 worksheet,
                                 sc,
                                 scEval,
                                 disabled,
                                 onMasterDataChange,
                                 value,
                                 onChange
                             }: DeliveryDocNumInputProps) => {

    const required = scEval.linkDeliveryToServiceCall ?? false
    const error = required && !scEval.manuallySetDeliveryDocEntry && !scEval.selectedDeliveryDocEntry

    return (
        <NumberOnlyTextField
            size="small"
            fullWidth={true}
            required={required}
            error={error}
            value={value ?? 0}
            onChange={onChange}
            hideZeroOnClick={true}
            disabled={disabled || !scEval.manuallySetDeliveryDocEntry || !!scEval.deliveryAck}

        />
    );
};

export default DeliveryDocNumInput;