import {EmailNotificationProps} from "modules/admin/worksheet/notifications/email-notifications/EmailNotificationProps";
import {
    useEmailNotificationListingState
} from "modules/admin/worksheet/notifications/email-notifications/state/useEmailNotificationListingState";
import {Stack} from "@mui/material";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {
    EmailNotificationsInfQueryLoader
} from "modules/admin/worksheet/notifications/email-notifications/results/EmailNotificationsInfQueryLoader";
import {
    EmailNotificationsResultTable
} from "modules/admin/worksheet/notifications/email-notifications/results/EmailNotificationsResultTable";


export type EmailNotificationResultsProps = EmailNotificationProps & {}
export const EmailNotificationResults = ({stateAtom}: EmailNotificationResultsProps) => {
    const tk = useTranslateFunction(`Admin.Worksheet`)

    const {
        notificationQuery,
    } = useEmailNotificationListingState(stateAtom)

    const onStateChanged = async () => {
        await notificationQuery.refetch()
    }

    return (
        <Stack
            direction="column"
            spacing={3}
            width={1}
            alignItems="center"
            justifyContent="center"
            sx={{
                mb: 4
            }}
        >
            <EmailNotificationsResultTable stateAtom={stateAtom} onStateChanged={onStateChanged}/>
            <EmailNotificationsInfQueryLoader stateAtom={stateAtom}/>
        </Stack>
    )
}