import React from 'react';
import {
    CircularProgress,
    Dialog,
    DialogContent,
    DialogProps,
    DialogTitle,
    Grid,
    Stack,
    Typography
} from "@mui/material";
import useTranslateFunction from "shared/language/useTranslateFunction";
import PsgManufacturerSelect from "modules/worksheet/editor/ui/photo/PsgManufacturerSelect";
import PhotoStepRuleSelect from "modules/worksheet/editor/ui/photo/PhotoStepRuleSelect";
import {useDisplayHelpDialogState} from "modules/worksheet/editor/ui/photo/useDisplayHelpDialogState";

export type WEditorDisplayHelpDialogProps = DialogProps & {}
const WEditorDisplayHelpDialog = ({...props}: WEditorDisplayHelpDialogProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    const {
        selectedManufacturerCode,
        selectedRuleId,
        setDialogState,
        ruleDetailsQuery
    } = useDisplayHelpDialogState()

    const onManufacturerCodeChange = (newValue: string) => {
        setDialogState(prev => {
            return {
                ...prev,
                selectedManufacturerCode: newValue ?? "",
                selectedRuleId: undefined
            }
        })
    }

    const onRuleIdChange = (newRuleId: string | null | undefined) => {
        setDialogState(prev => {
            return {
                ...prev,
                selectedRuleId: newRuleId ?? undefined
            }
        })
    }

    return (
        <Dialog
            onClose={() => setDialogState(prev => {
                return {
                    ...prev,
                    open: false
                }
            })}
            fullWidth
            {...props}
        >
            <DialogTitle>{tk('DisplayHelp')}</DialogTitle>
            <DialogContent>

                <Grid container spacing={1}>

                    <Grid item xs={12}>
                        <Stack direction="column" spacing={1}
                               sx={{
                                   marginTop: 1
                               }}>
                            <PsgManufacturerSelect selectedCode={selectedManufacturerCode ?? ""}
                                                   onChange={onManufacturerCodeChange}
                            />

                            <PhotoStepRuleSelect selectedManufacturerCode={selectedManufacturerCode}
                                                 selectedRuleId={selectedRuleId}
                                                 onChange={onRuleIdChange}
                            />
                        </Stack>
                    </Grid>

                    <Grid item xs={12}>

                        {(ruleDetailsQuery.isLoading || ruleDetailsQuery.isFetching) ? (
                            <CircularProgress/>
                        ) : (
                            <Grid container spacing={1}>
                                {(ruleDetailsQuery.data?.overridenSteps?.length ?? 0) > 0 && (
                                    ruleDetailsQuery.data?.overridenSteps?.map(s => (
                                        <Grid key={s.id} item xs={12}>
                                            <Typography variant="h6">{s.orderNum}. {s.title}</Typography>
                                            <Typography variant="body1" sx={{
                                                whiteSpace: "pre-line"
                                            }}>
                                                {s.description}
                                            </Typography>
                                        </Grid>
                                    ))
                                )}
                            </Grid>
                        )}

                    </Grid>

                </Grid>

            </DialogContent>
        </Dialog>
    );
};

export default WEditorDisplayHelpDialog;