import React from 'react';
import {CDAMWMWServiceCallSave} from "crowbar-api";
import {ListItemIcon, ListItemText, MenuItem, MenuList, Typography} from "@mui/material";
import ImageIcon from "shared/components/image-icon/ImageIcon";
import FinishedIcon from "modules/worksheet/editor/ui/drawer/FinishedIcon";
import useWorksheetStatuses from "modules/worksheet/editor/state/atoms/useWorksheetStatuses";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {useNavigate} from "react-router-dom";
import useWEditorStatusDrawerArgsAtom from "modules/worksheet/editor/state/atoms/useWEditorStatusDrawerArgsAtom";
import {WorksheetIconImages} from "modules/worksheet/editor/shared/component/images/WorksheetIconImages";

export interface WEditorStatusDrawerMenuProps {
    selected: boolean
    serviceCall: CDAMWMWServiceCallSave
    onSelectedClick: (serviceCallId: string) => void | Promise<void>
}

const WEditorStatusDrawerMenu = ({
                                     selected,
                                     serviceCall,
                                     onSelectedClick
                                 }: WEditorStatusDrawerMenuProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)
    /*const onSelectClick = () => {
        if (serviceCall.id) {
            onSelectedClick(serviceCall.id)
        }
    }*/
    const {setOpen} = useWEditorStatusDrawerArgsAtom()
    const statuses = useWorksheetStatuses()
    const foundServiceCallStatus = statuses.serviceCallStatuses.find(scs => scs.id === serviceCall.id)

    const opHours = serviceCall.currentEquipmentOperatingHours ?? "-"

    const navigate = useNavigate()

    const onMenuItemClick = (to: string) => {
        if (serviceCall?.id) {
            onSelectedClick(serviceCall.id)
        }
        navigate(to)
        setOpen(false)
    }

    return (
        <MenuList>
            <MenuItem onClick={() => onMenuItemClick("equipments")}>
                <ListItemIcon>
                    <ImageIcon image={WorksheetIconImages.alarm}/>
                </ListItemIcon>
                <ListItemText>{opHours}</ListItemText>
                <Typography variant="body2" color="text.secondary" alignContent="center">
                    <FinishedIcon value={statuses.basicCompleted}/>
                </Typography>
            </MenuItem>
            <MenuItem onClick={() => onMenuItemClick("catalogue")}>
                <ListItemIcon>
                    <ImageIcon image={WorksheetIconImages.checklist}/>
                </ListItemIcon>
                <ListItemText>{tk('MenuCatalogue')}</ListItemText>
                <Typography variant="body2" color="text.secondary">
                    <FinishedIcon value={foundServiceCallStatus?.catalogueCompleted ?? false}/>
                </Typography>
            </MenuItem>
            <MenuItem onClick={() => onMenuItemClick("descriptions")}>
                <ListItemIcon>
                    <ImageIcon image={WorksheetIconImages.text}/>
                </ListItemIcon>
                <ListItemText>{tk('Description')}</ListItemText>
                <Typography variant="body2" color="text.secondary">
                    <FinishedIcon value={foundServiceCallStatus?.descriptionCompleted ?? false}/>
                </Typography>
            </MenuItem>
            <MenuItem onClick={() => onMenuItemClick("descriptions")}>
                <ListItemIcon>
                    <ImageIcon image={WorksheetIconImages.warning}/>
                </ListItemIcon>
                <ListItemText>{tk('FaultDescription')}</ListItemText>
                <Typography variant="body2" color="text.secondary">
                    <FinishedIcon value={foundServiceCallStatus?.hasFaultDescription ?? false}
                                  onlyDisplayTrueState={true}/>
                </Typography>
            </MenuItem>
            <MenuItem onClick={() => onMenuItemClick("photo")}>
                <ListItemIcon>
                    <ImageIcon image={WorksheetIconImages.gallery}/>
                </ListItemIcon>
                <ListItemText>{tk('MenuPhoto')}</ListItemText>
                <Typography variant="body2" color="text.secondary">
                    <FinishedIcon value={foundServiceCallStatus?.hasPhoto ?? false} onlyDisplayTrueState={true}/>
                </Typography>
            </MenuItem>
        </MenuList>
    )
}

export default WEditorStatusDrawerMenu;