import * as React from 'react';
import {withTranslation, WithTranslation} from "react-i18next";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {
    CDAMDMDeliveryNoteModel,
    CDAMDMDeliveryNoteType,
    CDAMDMLeaseStatus,
    CDCMUserDetails,
    DeliveryNoteApi,
    DeliveryNoteTemplateApi,
    UserProfileApi
} from "crowbar-api";
import axios, {AxiosRequestConfig, CancelTokenSource} from "axios";
import PagingRowHolder from "shared/utils/PagingRowHolder";
import {toastRequestError} from "shared/toast/DefaultToasts";
import debounce from "lodash/debounce";
import {CircularProgress, Stack, Typography} from "@mui/material"
import DeliveryNoteListItem from "./DeliveryNoteListItem";

interface DeliveryNoteListProps extends WithTranslation {
    filterSearch: string
    filterType?: CDAMDMDeliveryNoteType
    filterLeaseStatus?: CDAMDMLeaseStatus
    filterShowDeleted?: boolean

    onEditClick: (dn: CDAMDMDeliveryNoteModel) => void
}

interface DeliveryNoteListState {
    filterSearch: string
    filterType?: CDAMDMDeliveryNoteType
    filterLeaseStatus?: CDAMDMLeaseStatus
    filterShowDeleted?: boolean

    rows: PagingRowHolder<CDAMDMDeliveryNoteModel>[]
    pageIsLoading: boolean

    users: CDCMUserDetails[]

    downloadPdfInProgress: string[] // DeliveryNote.Id array
    downloadDocxInProgress: string[] // DeliveryNote.Id array
}

class DeliveryNoteList extends React.Component<DeliveryNoteListProps, DeliveryNoteListState> {
    private _deliveryNoteApi: DeliveryNoteApi;
    private _userProfileApi: UserProfileApi;
    private _deliveryNoteTemplateApi: DeliveryNoteTemplateApi;

    private cancelTokenSource: CancelTokenSource;
    private axiosOptions: AxiosRequestConfig;

    private tKey = "DeliveryNote.Note.";
    private _loadPageDebounce = debounce(async () => {
        await this._loadPageAndSetState(true);
    }, 1000);

    constructor(props: Readonly<DeliveryNoteListProps> | DeliveryNoteListProps) {
        super(props);

        this.cancelTokenSource = axios.CancelToken.source();
        this.axiosOptions = {
            cancelToken: this.cancelTokenSource.token
        }

        this._deliveryNoteApi = crowbarApiFactory(DeliveryNoteApi);
        this._userProfileApi = crowbarApiFactory(UserProfileApi);
        this._deliveryNoteTemplateApi = crowbarApiFactory(DeliveryNoteTemplateApi);
        this.state = {
            filterSearch: '',
            filterType: this.props.filterType,
            filterLeaseStatus: this.props.filterLeaseStatus,
            filterShowDeleted: this.props.filterShowDeleted,
            rows: [],
            pageIsLoading: true,
            users: [],
            downloadPdfInProgress: [],
            downloadDocxInProgress: []
        }
    }

    async componentDidMount() {
        this.cancelTokenSource = axios.CancelToken.source();
        this.axiosOptions = {
            cancelToken: this.cancelTokenSource.token
        }
        const newRows = await this._loadPage(true);
        const users = await this._loadUsers();
        /*this._loadPage(() => {
            this._loadUsers(); // only once
        });*/
        const rows = PagingRowHolder.addRowsToState(this.state.rows, newRows, this._keyAccessor);
        this.setState({
            pageIsLoading: false,
            rows: rows,
            users: users
        });
    }

    componentDidUpdate(prevProps: Readonly<DeliveryNoteListProps>, prevState: Readonly<DeliveryNoteListState>, snapshot?: any) {
        let debounce = false;
        let updateState = false;
        if (this.props.filterSearch !== this.state.filterSearch) {
            debounce = true;
            updateState = true;
        }

        if (this.props.filterType !== this.state.filterType ||
            this.props.filterLeaseStatus !== this.state.filterLeaseStatus ||
            this.props.filterShowDeleted !== this.state.filterShowDeleted
        ) {
            debounce = false;
            updateState = true;
        }

        if (updateState) {
            this.setState({
                filterSearch: this.props.filterSearch,
                filterType: this.props.filterType,
                filterLeaseStatus: this.props.filterLeaseStatus,
                filterShowDeleted: this.props.filterShowDeleted
            }, async () => {
                if (debounce) {
                    this._loadPageDebounce();
                } else {
                    await this._loadPageAndSetState(true);
                }
            });
        }
    }

    componentWillUnmount() {
        this.cancelTokenSource.cancel();
    }

    _keyAccessor = (e: PagingRowHolder<CDAMDMDeliveryNoteModel>): string => {
        return e.row?.id ?? "w"
    }

    _loadPageAndSetState = async (newSearch: boolean) => {
        this.setState((prevState) => {
            return {
                pageIsLoading: newSearch,
                rows: newSearch ? [] : prevState.rows
            }
        }, async () => {
            const newRows = await this._loadPage(newSearch);
            const rows = PagingRowHolder.addRowsToState(newSearch ? [] : this.state.rows, newRows, this._keyAccessor);
            this.setState({
                pageIsLoading: false,
                rows: rows
            });
        })
    }

    _loadPage = (newPage: boolean): Promise<CDAMDMDeliveryNoteModel[]> => {
        const lastRowNum = newPage ? 0 : PagingRowHolder.findLastRowNumFor(this.state.rows);

        return this._deliveryNoteApi.searchPageBy({
            lastRowNumber: lastRowNum,
            limit: 12,
            orderByIsDesc: true,
            search: this.state.filterSearch?.trim() ?? '',
            type: this.state.filterType,
            leaseStatus: this.state.filterLeaseStatus,
            showDeleted: this.state.filterShowDeleted
        }, this.axiosOptions).then(response => {
            if (response.status !== 200) {
                toastRequestError(this.props.t, {
                    name: 'DnListPageLoading',
                    response: response,
                    reason: 'Http status is not OK'
                });
                return [];
            }

            // Double check the LineOrder on DeliveryNoteRows
            return response.data?.map(dn => {
                if (!dn || !dn.rows) return dn
                dn.rows = dn.rows.sort((r1, r2) => {
                    if (!r1?.lineOrder || !r2?.lineOrder) return 0
                    // r2 is greater
                    if (r1.lineOrder < r2.lineOrder) {
                        return -1
                    }
                    // r1 is greater
                    if (r1.lineOrder > r2.lineOrder) {
                        return 1;
                    }

                    // r1 equals r2
                    return 0
                })
                return dn
            })
        });
    }

    _loadUsers = (): Promise<CDCMUserDetails[]> => {
        return this._userProfileApi.findAllUsers(this.axiosOptions)
            .then(response => {
                if (response.status === 200) {
                    return response.data;
                    /*this.setState({
                        users: response.data
                    });*/
                } else {
                    toastRequestError(this.props.t, {
                        name: 'SirUserLoading',
                        reason: 'Http status is not OK',
                        response: response
                    });
                    return [];
                }
            });
    }

    private _onWaypointClicked = (): void => {
        // Prepare rows array
        const rows = [...this.state.rows];
        // Disable waypoints
        const waypoints = rows.filter(r => r.isWaypoint);
        for (const waypoint of waypoints) {
            waypoint.isWaypointDisabled = true;
        }
        this.setState({
            rows: rows
        }, async () => {
            await this._loadPageAndSetState(false);
        });
    }

    private _findCreatedByUserFor(item?: PagingRowHolder<any>): CDCMUserDetails | undefined {
        return this.state.users.find(u => u.id === item?.row?.createdBy);
    }

    _onEditClick = (dn: CDAMDMDeliveryNoteModel) => {
        this.props.onEditClick(dn);
    }

    render() {
        const t = this.props.t;
        return (
            <>
                {this.state.pageIsLoading ?
                    <Stack justifyContent={"center"} alignItems={"center"}>
                        <CircularProgress/>
                        <Typography>{t('Generic.Loading')}</Typography>
                    </Stack>
                    : ''}
                {!this.state.pageIsLoading && this.state.rows.length <= 1 && // 1 waypoint
                    <p><i>{t('DeliveryNote.Note.NoResult')}</i></p>
                }

                <Stack spacing={2}>
                    {this.state.rows.map((item, index) => {
                        return <DeliveryNoteListItem item={item} key={index}
                                                     onEditClick={this._onEditClick}
                                                     onWaypointClicked={this._onWaypointClicked}
                                                     createdBy={this._findCreatedByUserFor(item)}/>
                    })}
                </Stack>
            </>
        );
    }
}

export default withTranslation()(DeliveryNoteList);