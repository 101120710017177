import React from 'react';
import {CircularProgress, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import usePsgManufacturers from "crowbar-api/hooks/sap/usePsgManufacturers";
import useTranslateFunction from "shared/language/useTranslateFunction";

export interface PsgManufacturerSelectProps {
    selectedCode: string
    onChange: (newValue: string) => void
}

const PsgManufacturerSelect = ({selectedCode, onChange}: PsgManufacturerSelectProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)
    const manufacturersQuery = usePsgManufacturers()

    const isLoading = (manufacturersQuery.isLoading || manufacturersQuery.isFetching)

    const onSelectChange = (event: SelectChangeEvent<string>) => {
        onChange(event?.target?.value ?? "")
    }

    if (isLoading) {
        return (
            <CircularProgress/>
        )
    }

    return (
        <FormControl fullWidth>
            <InputLabel>{tk('Manufacturer')}</InputLabel>
            <Select
                fullWidth
                variant="outlined"
                value={selectedCode}
                label={tk('Manufacturer')}
                disabled={manufacturersQuery.isLoading || manufacturersQuery.isPaused || !manufacturersQuery.data}
                onChange={onSelectChange}
            >
                <MenuItem key={0} value="0">{tk('ShowAll')}</MenuItem>
                {(manufacturersQuery.data ?? []).map(m => (
                    <MenuItem key={m.code} value={m.code}>{m.name}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default PsgManufacturerSelect;