import {CDAMWMServiceCallItemRowSelectedSource} from "crowbar-api";

export default class ServiceCallItemRowSelectedSourceMap {
    public static readonly notSet = CDAMWMServiceCallItemRowSelectedSource.NUMBER_0
    public static readonly sapMasterData = CDAMWMServiceCallItemRowSelectedSource.NUMBER_1
    public static readonly sapSalesOrder = CDAMWMServiceCallItemRowSelectedSource.NUMBER_3
    public static readonly manuallyEntered = CDAMWMServiceCallItemRowSelectedSource.NUMBER_4

    public static translationKey = (t?: CDAMWMServiceCallItemRowSelectedSource): string => {

        const preKey = "Worksheet.Generic.ServiceCallItemRowSelectedSource."

        if (t === this.notSet) {
            return `${preKey}Default`
        }

        if (t === this.sapMasterData) {
            return `${preKey}NotInstalled`
        }

        if (t === this.sapSalesOrder) {
            return `${preKey}SapSalesOrder`
        }

        if (t === this.manuallyEntered) {
            return `${preKey}ManuallyEntered`
        }

        return "Missing"
    }
}