import React from "react";
import {toast, ToastOptions} from "react-toastify";
import {defaultToastConfig} from "./DefaultToastConfig";
import {TFunction} from "i18next";
import {AxiosResponse} from "axios";

// --------------------------------
// Generic Request Error
// --------------------------------
export interface IToastErrorParams {
    name?: string
    reason?: any,
    response?: AxiosResponse
}

export const toastRequestError = (t: TFunction, values: IToastErrorParams) => {
    console.error('Response error');
    console.error(values);
    return toastTranslatedError(t, 'Toast.ApiRequestFailed', values);
}

export const toastTranslatedError = (t: TFunction, key: string, values: {}) => {
    const msg = t(key, values);
    return toastError(msg);
}

// --------------------------------

export const toastDefault = (msg: string | JSX.Element, additionalOptions?: ToastOptions) => {
    console.log(msg);
    return toast(msg, {
        ...defaultToastConfig,
        ...additionalOptions,
    });
}

export const toastInfo = (msg: string | JSX.Element, additionalOptions?: ToastOptions) => {
    console.log(msg);
    return toast.info(msg, {
        ...defaultToastConfig,
        theme: "colored",
        ...additionalOptions
    });
}

export const toastSuccess = (msg: string | JSX.Element, additionalOptions?: ToastOptions) => {
    console.log(msg);
    return toast.success(msg, {
        ...defaultToastConfig,
        theme: "colored",
        ...additionalOptions
    });
}

export const toastError = (msg: string | JSX.Element, additionalOptions?: ToastOptions) => {
    console.error(msg);
    return toast.error(msg, {
        ...defaultToastConfig,
        theme: "colored",
        ...additionalOptions
    });
}

export const toastWarn = (msg: string | JSX.Element, additionalOptions?: ToastOptions) => {
    console.warn(msg);
    return toast.warn(msg, {
        ...defaultToastConfig,
        theme: "colored",
        ...additionalOptions
    });
}

//const mutableRefObject = React.useRef(null);
// https://fkhadra.github.io/react-toastify/use-a-controlled-progress-bar/
export const toastProgress = (ref: React.MutableRefObject<any>, msg?: string | JSX.Element, progress?: number) => {
    if (!ref) throw new Error('Upload progress needs ref!');
    if (ref.current === null) {
        ref.current = toast(msg, {
            ...defaultToastConfig,
            progress: progress
        });
    } else {
        if (progress && progress > 100) {
            toast.done(ref.current);
        } else {
            toast.update(ref.current, {
                ...defaultToastConfig,
                progress: progress
            });
        }
    }
}
/*
export const toastFieldErrors = <T,>(fieldErrors: FieldErrors<T>, combined: boolean = true, maxErrors: number = 3) => {
    // prepare
    const displayErrors: string[] = []
    for (const [, value] of Object.entries(fieldErrors)) {
        if (!value?.message) continue
        // Only add the error if it is different
        if (!displayErrors.includes(value.message)) {
            displayErrors.push(value.message)
        }

        if (displayErrors.length === maxErrors) {
            break
        }
    }
    // display
    if (combined) {
        toastError(displayErrors.join("\n\r"))
    } else {
        for (let displayError of displayErrors) {
            toastError(displayError)
        }
    }
}*/