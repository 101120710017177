import React from 'react';
import usePsgManufacturers from "crowbar-api/hooks/sap/usePsgManufacturers";
import {
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent
} from "@mui/material";
import {useEquipmentSearchState} from "modules/equipment/equipment-search/useEquipmentSearchState";
import {EquipmentSearchStateProps} from "modules/equipment/equipment-search/EquipmentSearch";

export type ManufacturerSelectProps = EquipmentSearchStateProps & {}
const ManufacturerSelect = ({searchStateAtom}: ManufacturerSelectProps) => {

    const {selectedManufacturer, setState} = useEquipmentSearchState(searchStateAtom)

    const manufacturersQuery = usePsgManufacturers()

    const manufacturers = manufacturersQuery.data ?? []

    const label = "Manufacturer"

    if (manufacturersQuery.isLoading) {
        return (
            <CircularProgress/>
        )
    }

    const handleChange = (event: SelectChangeEvent) => {
        setState(prev => {
            return {
                ...prev,
                selectedManufacturer: event.target.value as string,
                selectedVehicleTypeKey: undefined,
                selectedVehicleType: undefined
            }
        })
    }

    return (
        <FormControl fullWidth size="small">
            <InputLabel id="manufacturer-select-label" shrink={true}>{label}</InputLabel>
            <Select
                labelId="manufacturer-select-label"
                value={selectedManufacturer ?? ""}
                label={label}
                onChange={handleChange}
                input={<OutlinedInput notched label={label}/>}
            >
                <MenuItem value={""}>&nbsp;</MenuItem>
                {manufacturers.map((m, i) => (
                    <MenuItem key={[m.code, i].join("-")} value={m.code}>{m.name ?? ""}</MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default ManufacturerSelect;