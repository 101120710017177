import {useState} from "react";
import {Button, Stack, Switch} from "@mui/material";

export interface SwitchConfirmDeleteProps {
    buttonLabel: string
    onClick: () => void | Promise<void>
}

const SwitchConfirmDelete = ({buttonLabel, onClick}: SwitchConfirmDeleteProps) => {
    const [locked, setLocked] = useState(true)
    return (
        <Stack
            direction="row"
            spacing={0}
        >
            <Switch color="error" checked={!locked} onChange={(e) => setLocked(!e.target.checked)}/>
            <Button disabled={locked} variant="contained" color="error"
                    onClick={onClick}>{buttonLabel}</Button>
        </Stack>
    )
}

export default SwitchConfirmDelete