import {AppBar, Box, Checkbox, Container, FormControlLabel, Stack, Toolbar, Typography} from '@mui/material';
import React from 'react';

import BorderColorIcon from '@mui/icons-material/BorderColor';
import useTranslateFunction from "shared/language/useTranslateFunction";
import {WorksheetSignatureFinishButton} from "modules/worksheet/signature/signature-box/WorksheetSignatureFinishButton";
import {CDAMWMWWorksheetSignatureUpdate, CDAMWMWWorksheetStatusAndSignatureQuery} from "crowbar-api";
import {PrimitiveAtom, useAtom} from "jotai";
import {SignatureState} from "modules/worksheet/signature/signature-box/SignatureState";

export type WorksheetSignatureMenuProps = {
    worksheetStatuses: CDAMWMWWorksheetStatusAndSignatureQuery[]
    onCompleteReturnUrl?: string
    signatureStateAtom: PrimitiveAtom<SignatureState>
    onSignatureUpdated?: (updates: CDAMWMWWorksheetSignatureUpdate[]) => void
}
export const WorksheetSignatureMenu = ({
                                           worksheetStatuses,
                                           onCompleteReturnUrl,
                                           signatureStateAtom,
                                           onSignatureUpdated
                                       }: WorksheetSignatureMenuProps) => {

    const [{withSignature}, setSignatureState] = useAtom(signatureStateAtom)

    const onWithSignatureChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSignatureState(prev => {
            return {
                ...prev,
                withSignature: event.target.checked
            }
        })
    }

    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)
    return (
        <Box
            sx={{
                width: 1,
                flexGrow: 1,
                position: "fixed",
                bottom: 0,
                left: 0,
                zIndex: 1000
            }}
        >
            <AppBar position="static" color="inherit">
                <Container>
                    <Toolbar>
                        <BorderColorIcon
                            color="inherit"
                            sx={{mr: 2}}
                        />
                        <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                            {tk('Signature')}
                        </Typography>

                        <Stack direction="row" spacing={1}>
                            <FormControlLabel
                                control={(
                                    <Checkbox checked={withSignature} onChange={onWithSignatureChange}/>
                                )}
                                label={tk('WithSignature')}
                            />

                            <WorksheetSignatureFinishButton
                                worksheetStatuses={worksheetStatuses}
                                onCompleteReturnUrl={onCompleteReturnUrl}
                                signatureStateAtom={signatureStateAtom}
                                onSignatureUpdated={onSignatureUpdated}
                            />
                        </Stack>
                    </Toolbar>
                </Container>
            </AppBar>
        </Box>
    )
}