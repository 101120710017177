import {PrimitiveAtom, SetStateAction, useAtom} from "jotai";
import {useSapEquipmentSearchFindPageInfQuery} from "crowbar-api/hooks/equipment/useSapEquipmentSearchFindPageInfQuery";
import {CDAMEMSapEquipmentSearchParameters, CDAMEMSapEquipmentSearchQuery, CDSMSapPsgVehicleType} from "crowbar-api";
import {UseInfiniteQueryResult} from "@tanstack/react-query";

export interface EquipmentSearchState {
    searchInput?: string | undefined
    selectedManufacturer?: string | undefined
    selectedVehicleTypeKey?: string | undefined
    selectedVehicleType?: CDSMSapPsgVehicleType | undefined

    selectedSapEquipmentId?: string | undefined
    selectedModSapEquipmentId?: string | undefined

    searchParams: CDAMEMSapEquipmentSearchParameters
}

export type UseEquipmentSearchStateResult = EquipmentSearchState & {
    setState: (update: SetStateAction<EquipmentSearchState>) => void

    searchInfQuery: UseInfiniteQueryResult<CDAMEMSapEquipmentSearchQuery[], unknown>
    isLoading: boolean
    isFetching: boolean
    isFetchingNextPage: boolean
    anyHasNextPage: boolean
}

export const useEquipmentSearchState = (atom: PrimitiveAtom<EquipmentSearchState>): UseEquipmentSearchStateResult => {
    const [state, setState] = useAtom(atom)

    const searchInfQuery = useSapEquipmentSearchFindPageInfQuery({
        ...state.searchParams,
        manufacturer: state.selectedManufacturer ?? undefined,
        vehicleType: state.selectedVehicleType?.name ?? undefined,
        search: state.searchInput ?? "",
        orderByIsDesc: false
    })

    return {
        ...state,
        setState,
        searchInfQuery,
        isLoading: searchInfQuery.isLoading,
        isFetching: searchInfQuery.isFetching,
        isFetchingNextPage: searchInfQuery.isFetchingNextPage,
        anyHasNextPage: searchInfQuery.hasNextPage ?? false,
    }
}