import React from 'react';
import {Route, Routes} from "react-router-dom";
import PhotoRule from "modules/admin/worksheet/photo-rule/PhotoRule";
import WorksheetTimeItemCodes from "modules/admin/worksheet/worksheet-time-item-codes/WorksheetTimeItemCodes";
import MechanicAdmin from "modules/admin/worksheet/mechanic/MechanicAdmin";
import WorksheetAdminMenus from "modules/admin/worksheet/WorksheetAdminMenus";
import WorksheetTripItemCodes from "modules/admin/worksheet/worksheet-trip-item-codes/WorksheetTripItemCodes";
import {WorksheetList} from "modules/admin/worksheet/worksheet-list/WorksheetList";
import WorksheetNotifications from "modules/admin/worksheet/notifications/WorksheetNotifications";
import WorksheetEvaluation from "modules/admin/worksheet/evaluation/WorksheetEvaluation";
import WorksheetCallTypes from "modules/admin/worksheet/call-types/WorksheetCallTypes";
import WorksheetImages from "modules/admin/worksheet/worksheet-images/WorksheetImages";

const WorksheetAdminRoutes = () => {
    return (
        <Routes>
            <Route index element={<WorksheetAdminMenus/>}/>

            <Route path="worksheets/*" element={<WorksheetList/>}/>
            <Route path="worksheet-images/*" element={<WorksheetImages/>}/>
            <Route path="evaluation/*" element={<WorksheetEvaluation/>}/>

            <Route path="photo-rule/*" element={<PhotoRule/>}/>

            <Route path="worksheet-trip-item-codes/*" element={<WorksheetTripItemCodes/>}/>
            <Route path="worksheet-time-item-codes/*" element={<WorksheetTimeItemCodes/>}/>

            <Route path="call-types" element={<WorksheetCallTypes/>}/>

            <Route path="mechanic-admin/*" element={<MechanicAdmin/>}/>

            <Route path="worksheet-notifications/*" element={<WorksheetNotifications/>}/>
        </Routes>
    )
}

export default WorksheetAdminRoutes;