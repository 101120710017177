import React from 'react';
import {CDSMPartnerEquipmentPageRow} from "crowbar-api";
import {Box, CircularProgress, Paper, Stack, Typography} from "@mui/material";
import ProgressiveImage from "shared/components/progressive-image/ProgressiveImage";

import missingPhotoSrc from 'shared/asset/missing-photo.png'

interface NewServiceCallDialogCardProps {
    selected: boolean
    equipment: CDSMPartnerEquipmentPageRow
    onSelect: (equipment?: CDSMPartnerEquipmentPageRow) => void
}

const NewServiceCallDialogCard = ({selected, equipment, onSelect}: NewServiceCallDialogCardProps) => {

    const onCardClick = () => {
        if (selected) {
            onSelect(undefined)
        } else {
            onSelect(equipment)
        }
    }

    return (
        <Paper elevation={3}
               onClick={onCardClick}
               sx={theme => {
                   return {
                       cursor: 'pointer',
                       width: 1,
                       border: "2px 2px 2px 4px",
                       borderStyle: "solid",
                       borderColor: selected ? theme.palette.primary.main : 'transparent'
                   }
               }}>
            <Stack direction="row">
                <Stack direction="column">

                    {equipment.coverImageUris?.thumbMedium && (
                        <ProgressiveImage queryKey="new-service-call-dialog-eq-img"
                                          src={equipment.coverImageUris.thumbMedium}>
                            {(isLoading, imageObjectUrl) => (
                                isLoading ? <CircularProgress/>
                                    : <img src={imageObjectUrl} height="150" alt=""/>
                            )}
                        </ProgressiveImage>
                    )}
                    {!equipment.coverImageUris?.thumbMedium && (
                        <Box sx={{
                            p: "25px"
                        }}>
                            <Box
                                component="img"
                                src={missingPhotoSrc}
                                sx={{
                                    height: "100px"
                                }}
                            />
                        </Box>
                    )}
                </Stack>
                <Stack direction="column" sx={{
                    width: 0.5,
                    p: 2
                }}>
                    <Typography>
                        {equipment.vehicleManufacturer} {equipment.vehicleType} {equipment.yoM ? `(${equipment.yoM})` : ""}
                    </Typography>
                    <Typography sx={{
                        fontWeight: "bold"
                    }}>{equipment.manufacturerSn} {equipment.internalSn ? `(${equipment.internalSn})` : ""}</Typography>

                </Stack>
            </Stack>
        </Paper>
    );
};

export default NewServiceCallDialogCard;