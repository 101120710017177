import {CDAMWMWServiceCallItemRowSave} from "crowbar-api";
import {useIsTimeMatchFn} from "crowbar-api/hooks/worksheet/validations/useIsTimeMatchFn";

/**
 * Decides for the item row if the
 * row is used for work hour logging (time=true)
 * or not.
 * ItemCode is time:
 *  1. if the IsTime = true on the ServiceCallItemRow
 *  2. if the timeCodes contains the ItemCode (can be offline)
 */
const useIsTimeMatch = (itemRow: CDAMWMWServiceCallItemRowSave) => {
    const fn = useIsTimeMatchFn()
    return fn(itemRow)
}

export default useIsTimeMatch