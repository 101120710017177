import React from 'react';
import {SvgIcon, SvgIconProps} from "@mui/material";

export type DocxIconProps = SvgIconProps & {}

const DocxIcon = ({...props}: DocxIconProps) => {
    return (
        <SvgIcon {...props}>
            <path
                d="M19.2006 6.667L12.343 0H2.74324C2.01635 0.0021103 1.31986 0.283775 0.805874 0.783477C0.291887 1.28318 0.00217063 1.96032 0 2.667V21.333C0.00217063 22.0397 0.291887 22.7168 0.805874 23.2165C1.31986 23.7162 2.01635 23.9979 2.74324 24H16.4574C17.1843 23.9979 17.8808 23.7162 18.3947 23.2165C18.9087 22.7168 19.1984 22.0397 19.2006 21.333V20H24.001V9.333H19.2006V6.667ZM11.657 2.16L16.9778 7.333H11.657V2.16ZM22.6289 10.667V18.667H6.17152V10.667H22.6289Z"/>
            <path
                d="M12.2941 14.6196C12.2941 15.4716 12.051 16.1241 11.5648 16.5773C11.081 17.0304 10.3813 17.257 9.46558 17.257H8V12.0814H9.62488C10.4698 12.0814 11.1259 12.3044 11.5931 12.7505C12.0604 13.1965 12.2941 13.8196 12.2941 14.6196ZM11.1542 14.6479C11.1542 13.5364 10.6633 12.9806 9.68152 12.9806H9.09741V16.3507H9.56824C10.6255 16.3507 11.1542 15.7831 11.1542 14.6479Z"/>
            <path
                d="M17.4843 14.6621C17.4843 15.5188 17.2719 16.1772 16.8471 16.6375C16.4223 17.0977 15.8134 17.3278 15.0205 17.3278C14.2275 17.3278 13.6186 17.0977 13.1938 16.6375C12.769 16.1772 12.5566 15.5164 12.5566 14.655C12.5566 13.7936 12.769 13.1364 13.1938 12.6832C13.621 12.2277 14.2322 12 15.0275 12C15.8229 12 16.4306 12.2289 16.8507 12.6868C17.2731 13.1446 17.4843 13.8031 17.4843 14.6621ZM13.7071 14.6621C13.7071 15.2403 13.8169 15.6757 14.0363 15.9684C14.2558 16.261 14.5839 16.4073 15.0205 16.4073C15.896 16.4073 16.3338 15.8256 16.3338 14.6621C16.3338 13.4963 15.8984 12.9133 15.0275 12.9133C14.5909 12.9133 14.2617 13.0608 14.0399 13.3558C13.818 13.6485 13.7071 14.0839 13.7071 14.6621Z"/>
            <path
                d="M20.1753 12.9204C19.7623 12.9204 19.4425 13.0762 19.216 13.3877C18.9894 13.6969 18.8761 14.1287 18.8761 14.6833C18.8761 15.8374 19.3092 16.4144 20.1753 16.4144C20.5388 16.4144 20.9789 16.3236 21.4958 16.1418V17.0623C21.071 17.2393 20.5966 17.3278 20.0727 17.3278C19.3198 17.3278 18.744 17.1 18.3451 16.6445C17.9463 16.1867 17.7469 15.5306 17.7469 14.6763C17.7469 14.1382 17.8448 13.6674 18.0407 13.2638C18.2366 12.8579 18.5174 12.5475 18.8832 12.3328C19.2514 12.1156 19.6821 12.0071 20.1753 12.0071C20.678 12.0071 21.1831 12.1286 21.6905 12.3717L21.3365 13.2638C21.1429 13.1718 20.9482 13.0915 20.7524 13.0231C20.5565 12.9546 20.3641 12.9204 20.1753 12.9204Z"/>
        </SvgIcon>
    );
};

export default DocxIcon;