import {useMutation} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {CDAMPMPhotoRuleStepModel, PhotoRuleStepApi} from "crowbar-api";

const usePhotoRuleStepSave = () => {
    return useMutation(async (step: CDAMPMPhotoRuleStepModel) => {
        const response = await crowbarApiFactory(PhotoRuleStepApi)
            .save(step)
        if (response.status !== 200) {
            throw new Error('Failed to save photo rule step.')
        }
    })
}

export default usePhotoRuleStepSave