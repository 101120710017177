import PhotoRuleRoutes from "modules/admin/worksheet/photo-rule/PhotoRuleRoutes";
import {Container} from "@mui/material";


const PhotoRule = () => {
    return (
        <Container sx={{
            marginTop: 4,
        }}>
            <PhotoRuleRoutes/>
        </Container>
    )
}

export default PhotoRule