import {useEffect, useState} from "react";
import {InputAdornment, Stack, TextField} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import {TextBoxAndDebounceProps} from "shared/components/inputs/TextBoxAndDebounce";
import {useDebounce} from "shared/hook/useDebounce";

export type SearchBoxAndDebounceProps = TextBoxAndDebounceProps & {
    /**
     * For DB search, replaces * char to %
     */
    replaceStar?: boolean
}

/**
 * Same as the TextBoxAndDebounce, but with startAdornment search icon
 * and endAdornment clear icon.
 *
 * Also supports replaceStar, which the normal text input doesn't.
 *
 * Presents an input, which handles the user input with useState and
 * after debounce 1 sec calls the prop onChange event.
 *
 * Initial value van change. Will not mess up the input or debounce!
 * However, it will not be used - initialValue changes will not change the input's value
 * @param onChange
 * @param initialValue
 * @param label
 * @param replaceStar
 * @param props
 * @constructor
 */
const SearchBoxAndDebounce = ({
                                  onChange,
                                  value,
                                  label,
                                  replaceStar,
                                  ...props
                              }: SearchBoxAndDebounceProps) => {
    const [currentValue, setCurrentValue] = useState<string>(value)

    // debounce
    const inputDebounce1s = useDebounce(1000)

    const handleChange = (newValue: string): void => {
        let sValue = newValue ?? '';
        if (replaceStar) {
            sValue = sValue.replaceAll("*", "%")
        }

        // Set the controlled input value immediately
        // (otherwise it would block!)
        setCurrentValue(sValue)

        // Debounce the event to parent
        if (sValue === '') {
            // if manually deleted, drop all debounced calls
            inputDebounce1s.cancel()
            // and set the value instantly
            onChange?.('')
        } else {
            // any typed string should debounce
            inputDebounce1s(() => {
                onChange?.(sValue)
            })
        }
    }

    // Synchronize value changes to currentValue
    useEffect(() => {
        inputDebounce1s.cancel()
        setCurrentValue(value)
    }, [inputDebounce1s, value])

    return (
        <Stack direction={'row'} spacing={2} sx={{
            width: 1
        }}
        >
            <TextField
                variant="outlined"
                fullWidth
                label={label}
                InputLabelProps={{
                    shrink: true,
                    ...props.InputLabelProps
                }}
                /* all the parent props */
                {...props}
                /* fix controlled inputs */
                value={currentValue}
                onChange={(e) => handleChange(e?.target?.value ?? "")}
                /* set the start and end icons, use the parent props as well */
                InputProps={{
                    ...props.InputProps,
                    autoComplete: "off",
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon/>
                        </InputAdornment>
                    ),
                    endAdornment: currentValue.length === 0 ? undefined : (
                        <InputAdornment
                            position="end"
                            sx={{
                                ":hover": {
                                    cursor: "pointer"
                                }
                            }}
                            onClick={() => handleChange('')} // Clear
                        >
                            <ClearIcon/>
                        </InputAdornment>
                    )
                }}
            />
        </Stack>
    )
}

export default SearchBoxAndDebounce