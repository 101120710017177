import {useInfiniteQuery} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {CDSMNNewWorksheetCustomerParams, NewWorksheetQueryApi} from "crowbar-api";
import AxiosCancelTokenHelper from "crowbar-api/AxiosCancelTokenHelper";
import {InfQueryUtils} from "shared/utils/InfQueryUtils";
import {StringUtils} from "shared/utils/StringUtils";

export const useNewWorksheetPageCustomersByInfQuery = (
    enabled: boolean,
    {
        lastRowNumber, // lastRowNumber is never read from the params! We use getNextPageParam !
        ...params // all the other params are read (and will re-fetch from page 0)
    }: CDSMNNewWorksheetCustomerParams
) => {
    return useInfiniteQuery(['new-worksheet-page-customers-inf-query', enabled, params], async ({
                                                                                                    signal,
                                                                                                    pageParam
                                                                                                }) => {

        if (!enabled || StringUtils.lengthNotCountingCharsLike(params.search ?? "") < 3) {
            return []
        }

        const response = await crowbarApiFactory(NewWorksheetQueryApi)
            .pageCustomersBy({...params, lastRowNumber: pageParam}, AxiosCancelTokenHelper.cancelTokenFrom(signal))

        return response.data ?? []

    }, {
        networkMode: 'always', // will not fire unless we have network
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        cacheTime: Math.pow(10, 7),
        staleTime: Math.pow(10, 7),
        getNextPageParam: InfQueryUtils.getNextPageParamForRowNum
    })
}