import React from "react";
import {
    Box,
    Button,
    CircularProgress,
    Grid,
    Paper,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material"
import {BackButton} from "shared/components/buttons/BackButton";
import usePhotoRuleTemplates from "modules/admin/worksheet/photo-rule/photo-rules/PhotoRuleEdit/usePhotoRuleTemplates";
import TableBody from "@mui/material/TableBody";
import DateDisplay from "shared/components/date-display/DateDisplay";
import {CDAMPMPhotoRuleStepTemplateModel} from "crowbar-api";
import {useNavigate} from "react-router-dom";
import useTranslateFunction from "shared/language/useTranslateFunction";


const PhotoStepTemplateList = () => {
    const tk = useTranslateFunction(`Admin.PhotoRule`)

    const navigate = useNavigate()
    const templatesQuery = usePhotoRuleTemplates()

    const onRowClick = (row: CDAMPMPhotoRuleStepTemplateModel) => {
        navigate(`/admin/worksheet-admin/photo-rule/templates/edit/${row.id}`)
    }

    const onNewClick = () => {
        navigate("/admin/worksheet-admin/photo-rule/templates/new")
    }

    return (
        <Grid
            container
        >
            <Grid item xs={12}>
                <Typography component="h1" sx={{
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: "20pt"
                }}>
                    <BackButton to="/admin/worksheet-admin/photo-rule/rules"/> {tk('Templates')}
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Box>
                    <Button variant="contained" color="primary" onClick={onNewClick}>{tk('Add')}</Button>
                </Box>
            </Grid>

            <Grid item xs={12}>
                {templatesQuery.isLoading ? (
                    <CircularProgress/>
                ) : (
                    <Box>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">{tk('Title')}</TableCell>
                                        <TableCell align="right">{tk('CreatedAt')}</TableCell>
                                        <TableCell align="right">{tk('UpdatedAt')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {templatesQuery.data?.map(row => (
                                        <TableRow
                                            key={row.id}
                                            sx={{
                                                cursor: "pointer"
                                            }}
                                            onClick={() => onRowClick(row)}
                                        >
                                            <TableCell align="left">
                                                {row.title}
                                            </TableCell>
                                            <TableCell align="right">
                                                <DateDisplay dateStr={row.createdAt ?? ""}/>
                                            </TableCell>
                                            <TableCell align="right">
                                                <DateDisplay dateStr={row.updatedAt ?? ""}/>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                )}
            </Grid>
        </Grid>
    )
}

export default PhotoStepTemplateList