import {useUserContext} from "shared/hook/UserContext";
import {useEffect} from "react";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {UserProfileApi} from "crowbar-api";
import axios from "axios";
import {atom, useAtom} from "jotai";
import {toastError, toastInfo} from "shared/toast/DefaultToasts";
import {useTranslation} from "react-i18next";

const registerCalledUserIdsAtom = atom<string[]>([])

export const useCrowbarUserAutoRegister = () => {
    const {t} = useTranslation()
    const [userId] = useUserContext()
    const [calledUsersIds, setCalledUserIds] = useAtom(registerCalledUserIdsAtom)

    useEffect(() => {

        if (!userId || calledUsersIds.find(u => u === userId)) {
            return
        }

        const addIdToUserIds = () => {
            setCalledUserIds(p => {
                return [
                    ...p,
                    userId
                ]
            })
        }

        const cancelToken = axios.CancelToken.source()
        crowbarApiFactory(UserProfileApi).registerUserToApplication({
            cancelToken: cancelToken.token
        }).then(res => {
            addIdToUserIds()
            if (res.data.isNewUser) {
                toastInfo(t('Generic.UserAutoRegistered'))
            }
        }, error => {
            toastError('Error during user registration.')
            console.error(error)
            addIdToUserIds()
        })

        return () => {
            cancelToken.cancel()
        }
    }, [calledUsersIds, setCalledUserIds, t, userId])
}