import React from 'react';
import {Field, FieldProps} from "formik";
import {DateUtils} from "shared/utils/DateUtils";
import NativeDateInputEx from "shared/components/inputs/NativeDateInputEx";

export type FormikDatePickerProps = {
    fieldName: string
    label: string
    required: boolean
    setter: (fieldName: string, date?: string) => void
}

const FormikDatePicker = ({fieldName, label, required, setter}: FormikDatePickerProps) => {


    return (
        <Field name={fieldName}>
            {(fieldProps: FieldProps) => {
                const formatted = DateUtils.intlDateToFormat(new Date(fieldProps.field.value), "fr-CA")
                return (
                    <NativeDateInputEx
                        fullWidth
                        size="small"
                        label={label}
                        value={formatted}
                        sx={{width: "100%"}}
                        required={required}
                        onBlurWithServerValue={(dateComponent) => {
                            setter?.(fieldName, new Date(dateComponent).toJSON())
                        }}
                    />
                )
            }}
        </Field>
    );
};

export default FormikDatePicker;