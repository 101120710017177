import {CDAMWMWWorksheetVersion} from "crowbar-api";

export class WorksheetVersionUtils {
    public static equalsWithVersions = (o1: CDAMWMWWorksheetVersion, o2: CDAMWMWWorksheetVersion) => {
        if (o1 === o2) return true
        if (o1 === null || o2 === null) return false
        return o1.id === o2.id &&
            o1.serialId === o2.serialId &&
            o1.version === o2.version &&
            o1.previousVersion === o2.previousVersion &&
            o1.ownerId === o2.ownerId
    }

    public static equalsWithoutVersions = (o1: CDAMWMWWorksheetVersion, o2: CDAMWMWWorksheetVersion) => {
        if (o1 === o2) return true
        if (o1 === null || o2 === null) return false
        return o1.id === o2.id &&
            o1.serialId === o2.serialId &&
            o1.ownerId === o2.ownerId
    }
}