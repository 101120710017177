import {useMutation} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {LicensePlateApi} from "crowbar-api";

export const deleteLicensePlate = async (id: string) => {
    await crowbarApiFactory(LicensePlateApi)._delete(id);
}

export const useDeleteLicensePlate = () => {
    return useMutation(async (id: string) => {
        await deleteLicensePlate(id)
    })
}