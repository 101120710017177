import React from 'react';
import {Grid} from "@mui/material";
import PartnerMasterData from "modules/admin/worksheet/evaluation/tabs/master-data/PartnerMasterData";
import PartnerAddressMasterData from "modules/admin/worksheet/evaluation/tabs/master-data/PartnerAddressMasterData";
import PartnerContactMasterData from "modules/admin/worksheet/evaluation/tabs/master-data/PartnerContactMasterData";
import WEvalDates from "modules/admin/worksheet/evaluation/tabs/master-data/WEvalDates";
import {CDAMWMWorksheetEvalModel, CDAMWMWWorksheetQuery} from "crowbar-api";
import SignatureDisplay from "modules/admin/worksheet/evaluation/tabs/master-data/SignatureDisplay";
import ExtraOptions from "modules/admin/worksheet/evaluation/tabs/master-data/ExtraOptions/ExtraOptions";

export type MasterDataTabProps = {
    wEval: CDAMWMWorksheetEvalModel | null | undefined
    worksheet: CDAMWMWWorksheetQuery | null | undefined
    onDataChange: () => Promise<void>
}

const MasterDataTab = ({wEval, worksheet, onDataChange}: MasterDataTabProps) => {

    return (
        <Grid
            container
            spacing={1}
            sx={{
                p: 1
            }}
        >
            <Grid item xs={12}>
                <WEvalDates wEval={wEval} worksheet={worksheet} onDataChange={onDataChange}/>
            </Grid>
            <Grid item xs={12}>
                <PartnerMasterData wEval={wEval} worksheet={worksheet} onDataChange={onDataChange}/>
            </Grid>
            <Grid item xs={12}>
                <PartnerAddressMasterData wEval={wEval} worksheet={worksheet} onDataChange={onDataChange}/>
            </Grid>
            <Grid item xs={12}>
                <PartnerContactMasterData wEval={wEval} worksheet={worksheet} onDataChange={onDataChange}/>
            </Grid>
            <Grid item xs={12}>
                <SignatureDisplay wEval={wEval} worksheet={worksheet}/>
            </Grid>
            <Grid item xs={12}>
                <ExtraOptions wEval={wEval} worksheet={worksheet} onDataChange={onDataChange}/>
            </Grid>
        </Grid>
    )
}

export default MasterDataTab;