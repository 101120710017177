import React, {useState} from 'react';
import {Box, CircularProgress, Grid, IconButton, Stack} from "@mui/material";
import {CDSMPartnerEquipmentPageRow} from "crowbar-api";

import LeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import RightIcon from '@mui/icons-material/ChevronRight';
import NewServiceCallDialogCard
    from "modules/worksheet/editor/shared/dialog/new-service-call-dialog/NewServiceCallDialogCard";
import useTranslateFunction from "shared/language/useTranslateFunction";
import SearchBoxAndDebounce from "shared/components/inputs/SearchBoxAndDebounce";
import {useSapPartnerEquipmentSearchPageBy} from "crowbar-api/hooks/sap/useSapPartnerEquipmentSearchPageBy";

import RefreshIcon from '@mui/icons-material/Refresh';

interface SelectSinglePartnerEquipmentProps {
    pageLimit: number
    cardCode: string | undefined | null
    orderByDesc?: boolean

    onEquipmentSelected: (equipment?: CDSMPartnerEquipmentPageRow) => void

    displayRefreshButton?: boolean
}

const SelectSinglePartnerEquipment = ({
                                          pageLimit,
                                          cardCode,
                                          orderByDesc,
                                          onEquipmentSelected,
                                          displayRefreshButton
                                      }: SelectSinglePartnerEquipmentProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    const [searchValue, setSearchValue] = useState<string>("")
    const [lastRowNum, setLastRowNum] = useState<number>(0)

    const [selectedEquipment, setSelectedEquipment] = useState<CDSMPartnerEquipmentPageRow | undefined>(undefined)

    const equipmentsQuery = useSapPartnerEquipmentSearchPageBy({
        limit: pageLimit,
        orderByIsDesc: !!orderByDesc,
        search: searchValue.trim(),
        cardCode: cardCode ?? "",
        lastRowNumber: Math.max(lastRowNum, 0),
        showDeleted: false
    })

    /* const equipmentsQuery = useQuery<CDSMPartnerEquipmentPageRow[]>([
         'SelectSinglePartnerEquipment-equipments-query-1',
         cardCode,
         searchValue.trim(),
         lastRowNum
     ], async (context) => {
         const response = await crowbarApiFactory(SapPartnerEquipmentApi).searchPageBy(
             {
                 limit: pageLimit,
                 orderByIsDesc: !!orderByDesc,
                 showDeleted: false,
                 search: searchValue.trim(),
                 cardCode: cardCode ?? "",
                 lastRowNumber: Math.max(lastRowNum, 0)
             },
             AxiosCancelTokenHelper.cancelTokenFrom(context.signal)
         )
         if (response.status === 200) {
             return response.data
         } else {
             throw new Error('Failed to request equipments page.')
         }
     }, {
         refetchOnWindowFocus: false,
         keepPreviousData: false,
         retry: 1,
         refetchOnMount: false,

     })*/

    const findLastFieldForPages = <T, >(pages: any[][], nameOfField: string): T | undefined => {
        if (pages.length < 1) return undefined

        let lastField: T | undefined = undefined
        for (let i = pages.length; i >= 0; i--) {
            if (pages[i].length < 1) continue;
            lastField = pages[i][pages[i].length - 1]?.[nameOfField]
            break
        }

        return lastField
    }

    const findFirstFieldForPage = <T, >(page: any[] | undefined, nameOfField: string): T | undefined => {
        if (!page || page.length < 1) return undefined
        return page[0]?.[nameOfField]
    }

    const findLastFieldForPage = <T, >(page: any[] | undefined, nameOfField: string): T | undefined => {
        if (!page || page.length < 1) return undefined
        return page[page.length - 1]?.[nameOfField]
    }

    const onSearch = (newSearchValue: string) => {
        setSearchValue(newSearchValue)
        setLastRowNum(0)
        onEquipmentSelect(undefined)
    }

    const onPreviousPage = () => {
        const last = findFirstFieldForPage(equipmentsQuery.data, 'rowNum') as number
        const newVal = isNaN(last) ? 0 : Math.max(last - pageLimit - 1, 0)
        setLastRowNum(newVal)
        onEquipmentSelect(undefined)
    }

    const onNextPage = () => {
        const newVal = findLastFieldForPage(equipmentsQuery.data, 'rowNum') as number ?? 0
        setLastRowNum(newVal)
        onEquipmentSelect(undefined)
    }

    const onEquipmentSelect = (equipment?: CDSMPartnerEquipmentPageRow) => {
        setSelectedEquipment(equipment)
        onEquipmentSelected(equipment)
    }

    return (
        <Stack
            direction="column"
            spacing={1}
            sx={{width: 1, height: 1}}
            display="flex"
        >
            <Grid container alignItems="center" width={1}>

                <Grid item xs={true}>
                    <SearchBoxAndDebounce
                        fullWidth
                        value=""
                        placeholder={tk('SelectSinglePartnerEquipmentPlaceholder')}
                        onChange={onSearch}
                        size="small"
                        replaceStar={true}
                    />
                </Grid>


                {displayRefreshButton && (
                    <Grid item xs="auto">
                        <IconButton
                            disabled={equipmentsQuery.isLoading || equipmentsQuery.isRefetching}
                            onClick={() => equipmentsQuery.refetch().then()}
                        >
                            {(equipmentsQuery.isLoading || equipmentsQuery.isRefetching) ? (
                                <CircularProgress/>
                            ) : (
                                <RefreshIcon/>
                            )}
                        </IconButton>
                    </Grid>
                )}

            </Grid>


            <Box sx={{height: 1}}>
                {equipmentsQuery.isLoading && (
                    <CircularProgress/>
                )}

                <Grid container spacing={1}>
                    {!equipmentsQuery.isLoading && equipmentsQuery.isSuccess && equipmentsQuery.data && (
                        equipmentsQuery.data.map((eq, i) => (
                            <Grid item key={i} xs={12} sm={12} md={12} lg={12}>
                                <NewServiceCallDialogCard equipment={eq}
                                                          onSelect={onEquipmentSelect}
                                                          selected={eq.insId !== undefined && eq.insId === selectedEquipment?.insId}
                                />
                            </Grid>
                        ))
                    )}
                </Grid>
            </Box>

            <Box sx={{
                width: 1,
                display: 'flex',
                flexDirection: 'row-reverse'
            }}>
                <IconButton aria-label="right" size="large"
                            disabled={!equipmentsQuery.data || equipmentsQuery.data?.length < pageLimit}
                            onClick={onNextPage}>
                    <RightIcon fontSize="inherit"/>
                </IconButton>
                <IconButton aria-label="left" size="large"
                            disabled={lastRowNum === 0}
                            onClick={onPreviousPage}>
                    <LeftIcon fontSize="inherit"/>
                </IconButton>
            </Box>
        </Stack>
    );
};

export default SelectSinglePartnerEquipment;