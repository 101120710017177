import {CDAMWMWModSapPartnerContactSave} from "crowbar-api";
import {useForm, UseFormRegisterReturn, UseFormReturn} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useTranslation} from "react-i18next";
import {useEffect, useRef} from "react";

export interface UsePartnerContactFormParams {
    mode: 'new' | 'edit'
    editModContact?: CDAMWMWModSapPartnerContactSave
}

export interface PartnerContactFormResult extends UseFormReturn<PartnerContactFormValues, any> {
    emailRegister: UseFormRegisterReturn<"email">
    firstnameRegister: UseFormRegisterReturn<"firstname">
    middleNameRegister: UseFormRegisterReturn<"middleName">
    lastnameRegister: UseFormRegisterReturn<"lastname">
    phoneRegister: UseFormRegisterReturn<"phone">
    commentRegister: UseFormRegisterReturn<"comment">
}

export interface PartnerContactFormValues {
    email?: string
    firstname?: string
    middleName?: string
    lastname?: string
    phone?: string
    comment?: string
}

const prepareFormSchema = (tk: (key: string, params?: any) => string) => {
    return yup.object({
        email: yup.string()
            .email(tk('InvalidEmailAddress'))
            .notRequired()
            .max(255, tk('StringIsLongerThan', {max: 255})),
        firstname: yup.string()
            .required(tk('FieldIsRequired'))
            .max(255, tk('StringIsLongerThan', {max: 255})),
        middleName: yup.string()
            .notRequired()
            .max(255, tk('StringIsLongerThan', {max: 255})),
        lastname: yup.string()
            .required(tk('FieldIsRequired'))
            .max(255, tk('StringIsLongerThan', {max: 255})),
        phone: yup.string()
            .notRequired()
            .max(255, tk('StringIsLongerThan', {max: 255})),
        comment: yup.string()
    })
}

export const usePartnerContactForm = ({
                                          mode,
                                          editModContact
                                      }: UsePartnerContactFormParams): PartnerContactFormResult => {

    const {t} = useTranslation()
    const tkValidation = (key: string, params?: any): string => {
        return t(`Generic.Validations.${key}`, params)
    }
    const formSchemaRef = useRef<any>(prepareFormSchema(tkValidation))
    formSchemaRef.current = prepareFormSchema(tkValidation)

    const form = useForm<PartnerContactFormValues>({
        resolver: yupResolver(formSchemaRef.current)
    })

    const emailRegister = form.register("email");
    const firstnameRegister = form.register("firstname")
    const middleNameRegister = form.register("middleName")
    const lastnameRegister = form.register("lastname")
    const phoneRegister = form.register("phone")
    const commentRegister = form.register("comment")

    // If mode is update, load the values from the params
    useEffect(() => {
        // Only load the params if the form is not dirty
        if (!form.formState.isDirty && editModContact && mode === "edit") {
            const setOpts = {
                shouldDirty: false,
                shouldTouch: false,
                shouldValidate: true
            }
            form.setValue("email", editModContact.email ?? "", setOpts)
            form.setValue("firstname", editModContact.firstname ?? "", setOpts)
            form.setValue("middleName", editModContact.middleName ?? "", setOpts)
            form.setValue("lastname", editModContact.lastname ?? "", setOpts)
            form.setValue("phone", editModContact.phone ?? "", setOpts)
            form.setValue("comment", editModContact.comment ?? "", setOpts)
        }
    }, [editModContact, form, mode])

    return {
        ...form,
        emailRegister,
        firstnameRegister,
        middleNameRegister,
        lastnameRegister,
        phoneRegister,
        commentRegister
    }
}