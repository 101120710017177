import React from 'react';

import {Paper, Stack, Typography} from "@mui/material";
import {useSelectedServiceCall} from "modules/worksheet/editor/shared/data/useSelectedServiceCall";
import WEditorStatusDrawer from "modules/worksheet/editor/ui/drawer/WEditorStatusDrawer";
import useTranslateFunction from "shared/language/useTranslateFunction";
import useWEditorStatusDrawerArgsAtom from "modules/worksheet/editor/state/atoms/useWEditorStatusDrawerArgsAtom";
import WEditorHeaderContentCompactDetails from "modules/worksheet/editor/ui/header/WEditorHeaderContentCompactDetails";
import WEditorHeaderCompactImage from "modules/worksheet/editor/ui/header/WEditorHeaderCompactImage";
import {useWorksheetSaveState} from "modules/worksheet/editor/state/WorksheetSaveAtoms";

/*interface WorksheetEditorHeaderContentCompactProps {

}*/

const WorksheetEditorHeaderContentCompact = () => {
    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)
    const [ws] = useWorksheetSaveState()
    const selectedServiceCall = useSelectedServiceCall()

    const {setOpen} = useWEditorStatusDrawerArgsAtom()

    const onOpenDrawerClick = () => {
        setOpen(true)
    }

    if (!selectedServiceCall || !ws) {
        return <Typography>{tk('MissingSelectedEquipment')}</Typography>
    }

    return (
        <Paper variant="outlined"
               sx={{
                   padding: 1,
                   ":hover": {
                       cursor: "pointer",
                       transform: "scale(1.005)"
                       // border: "1px dotted black",
                   }
               }}
        >
            <WEditorStatusDrawer/>

            <Stack direction="row" spacing={1} onClick={onOpenDrawerClick}>

                <WEditorHeaderCompactImage
                    selectedServiceCall={selectedServiceCall}
                />

                <WEditorHeaderContentCompactDetails
                    worksheet={ws}
                    serviceCall={selectedServiceCall}
                />

                {/*<WEditorSelectServiceCallDrawer/>
                        <WEditorStatusDrawerButton/>
                        */}
            </Stack>

        </Paper>
    )
}

export default WorksheetEditorHeaderContentCompact;