import React, {useEffect, useState} from 'react';
import {
    CDAMWMServiceCallEvalModel,
    CDAMWMServiceCallModel,
    CDAMWMWorksheetEvalModel,
    CDAMWMWWorksheetQuery
} from "crowbar-api";
import {Card, CardContent, Grid, TextField, Typography} from '@mui/material';
import useTranslateFunction from "shared/language/useTranslateFunction";
import {
    useServiceCallEvalUpdateSubject
} from "crowbar-api/hooks/worksheet/evaluation/service-call/useServiceCallEvalUpdateSubject";
import {toastError} from "shared/toast/DefaultToasts";
import FixesWorksheetDataIcon from "modules/admin/worksheet/evaluation/shared/FixesWorksheetDataIcon";

export type EmptySubjectChangeProps = {
    worksheet: CDAMWMWWorksheetQuery | null | undefined
    wEval: CDAMWMWorksheetEvalModel | null | undefined
    selectedServiceCall: CDAMWMServiceCallModel
    scEvalId: string | undefined
    selectedEval: CDAMWMServiceCallEvalModel | undefined
    onMasterDataChange: () => void
    disabled?: boolean
}
const EmptySubjectChange = ({
                                worksheet,
                                wEval,
                                selectedServiceCall,
                                scEvalId,
                                selectedEval,
                                onMasterDataChange,
                                disabled
                            }: EmptySubjectChangeProps) => {
    const tk = useTranslateFunction("Admin.Worksheet")

    const [currentSubject, setCurrentSubject] = useState<string>(selectedServiceCall?.emptySubject ?? "")

    useEffect(() => {
        setCurrentSubject(selectedServiceCall?.emptySubject ?? "")
    }, [selectedServiceCall?.emptySubject])

    const updateSubject = useServiceCallEvalUpdateSubject();

    const onBlur = async () => {

        if (!selectedEval?.id || !selectedEval?.serviceCallId) {
            toastError("Failed to save. Missing service call eval id or service call id.")
            return
        }

        await updateSubject.mutateAsync({
            evalId: selectedEval.id,
            originalId: selectedEval.serviceCallId,
            field: currentSubject
        })

        await onMasterDataChange()
    }

    return (
        <Card variant="outlined">
            <CardContent>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="h6">{tk("Subject")} <FixesWorksheetDataIcon/></Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            required
                            error={!selectedServiceCall?.emptySubject}
                            value={currentSubject}
                            onChange={(e) => setCurrentSubject(
                                e.target.value?.substring(0, 100) ?? ""
                            )}
                            onBlur={onBlur}
                            disabled={disabled || updateSubject.isLoading}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default EmptySubjectChange;