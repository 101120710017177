import {CDAMWMWorksheetSelectedDataSource, CDAMWMWorksheetTripType} from "crowbar-api"
import WorksheetSelectedDataSourceMap from "crowbar-api/enum-map/WorksheetSelectedDataSourceMap";

export type RequiredFieldsForWorksheetBasicValidation = {
    partnerAddressSource?: CDAMWMWorksheetSelectedDataSource
    addressAcknowledged?: boolean | null
    distanceAcknowledged?: boolean | null
    cardCode?: string | null
    tripType?: CDAMWMWorksheetTripType
    tripDistanceKm?: number | null

    'addressZipCode'?: string | null;
    'addressCity'?: string | null;
    'addressStreet'?: string | null;
    'addressStreetNo'?: string | null;
}

export default class BasicCompletedValidation {
    public static isCompleted = (ws?: RequiredFieldsForWorksheetBasicValidation): boolean => {
        if (!ws) return false

        if (ws.partnerAddressSource === WorksheetSelectedDataSourceMap.notSet) {
            return false
        }

        // For phoenix worksheets (internal)
        // it is not needed to provide the trip fields.
        // But we still require to tick the ack fields
        if (!ws.addressAcknowledged || !ws.distanceAcknowledged) {
            return false
        }

        // Change 2023-02-22: it is no longer required to provide distanceKm > 0 values
        // for any worksheet. Code stays here as comment!
        /*const worksheetAddressFormat = SapPartnerAddressUtils.addressFormat({
            city: ws?.addressCity,
            zipCode: ws?.addressZipCode,
            street: ws?.addressStreet,
            streetNo: ws?.addressStreetNo
        })
        const addressIsPhoenix = worksheetAddressFormat === CrowbarConstants.PhoenixForkliftAddressFormat

        if (ws.cardCode !== CrowbarConstants.PhoenixForkliftCardCode &&
            !addressIsPhoenix && (
                ws.tripType === WorksheetTripTypeMap.notSet ||
                ws.tripDistanceKm === undefined ||
                ws.tripDistanceKm === null ||
                ws.tripDistanceKm < 1
            )) {
            return false
        }*/

        return true
    }
}