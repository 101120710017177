import {useMutation} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {CDAMWMEEquipmentChangeServiceCallEvalUpdate, ServiceCallEvalApi} from "crowbar-api";
import {AxiosRequestConfig} from "axios";


export const useServiceCallEvalUpdateEquipment = () => {
    return useMutation(async (p: CDAMWMEEquipmentChangeServiceCallEvalUpdate, options?: AxiosRequestConfig) => {
        await crowbarApiFactory(ServiceCallEvalApi)
            .updateEquipment(p, options)
    })
}