import React from 'react';
import {CDAMWMWorksheetEvalModel, CDAMWMWWorksheetQuery} from "crowbar-api";
import {Grid, TextField} from "@mui/material";
import {SadFaceRating} from "modules/worksheet/editor/ui/feedback/SadFaceRating";

export type FeedbackProps = {
    wEval: CDAMWMWorksheetEvalModel | null | undefined
    worksheet: CDAMWMWWorksheetQuery | null | undefined
}
const Feedback = ({
                      wEval,
                      worksheet
                  }: FeedbackProps) => {

    const feedbackNum = worksheet?.feedbackNum;
    const feedbackText = worksheet?.feedbackText;

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <SadFaceRating
                    value={feedbackNum ?? null}
                    onChange={() => {/**/
                    }}
                    sx={{
                        fontSize: "32pt"
                    }}
                />
            </Grid>

            {feedbackText && (
                <Grid item xs={12}>
                    <TextField
                        value={feedbackText}
                        disabled={true}
                        fullWidth={true}
                        multiline
                        minRows={3}
                    />
                </Grid>
            )}

        </Grid>
    );
};

export default Feedback;