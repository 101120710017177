import usePhotoRulesPaging from "modules/admin/worksheet/photo-rule/photo-rules/usePhotoRulesPaging";
import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import DateDisplay from "shared/components/date-display/DateDisplay";
import BasicCenteredPagingButtons from "shared/components/generic-paging/BasicCenteredPagingButtons";
import React from "react";
import {CDAMPMPhotoRuleModel} from "crowbar-api";
import {useNavigate} from "react-router-dom";
import {useNewAtomWithRef} from "shared/hook/useNewatomWithRef";
import usePhotoRuleSave from "modules/admin/worksheet/photo-rule/photo-rules/PhotoRuleEdit/usePhotoRuleSave";
import {v4 as uuid} from 'uuid'
import usePsgManufacturers from "crowbar-api/hooks/sap/usePsgManufacturers";
import useTranslateFunction from "shared/language/useTranslateFunction";
import SearchBoxAndDebounce from "shared/components/inputs/SearchBoxAndDebounce";

const PhotoRuleList = () => {
    const tk = useTranslateFunction(`Admin.PhotoRule`)

    const [addDisabled, setAddDisabled] = useNewAtomWithRef<boolean>(false)
    const [selectedManufacturer, setSelectedManufacturer] = useNewAtomWithRef<string>("")

    const photoRuleSave = usePhotoRuleSave()
    const manufacturers = usePsgManufacturers()

    const navigate = useNavigate()

    const q = usePhotoRulesPaging()

    const onAddClick = async () => {
        setAddDisabled(true)
        const id = uuid()
        await photoRuleSave.mutateAsync({
            id,
            title: ""
        })
        navigate(`/admin/worksheet-admin/photo-rule/rules/edit/${id}`)
        setAddDisabled(false)
    }

    const onRowClick = (row: CDAMPMPhotoRuleModel) => {
        navigate(`/admin/worksheet-admin/photo-rule/rules/edit/${row.id}`)
    }

    const onSearch = (searchValue: string) => {
        q.setPagingParams(prev => {
            return {
                ...prev,
                search: searchValue ?? ""
            }
        })
    }

    const onManufacturerSelect = (e: SelectChangeEvent<string>) => {
        setSelectedManufacturer(e.target.value)
        q.setPagingParams(prev => {
            return {
                ...prev,
                hasManufacturerCode: e.target.value ?? ""
            }
        })
    }

    return (
        <Box
            sx={{
                width: 1,
                maxWidth: "900px"
            }}
        >
            <Grid
                container
                spacing={2}
            >
                <Grid item xs={12}>
                    <Typography component="h1" fontSize="large">
                        {tk('PhotoRules')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Button variant="outlined" color="primary"
                            onClick={() => navigate("/admin/worksheet-admin/photo-rule/templates")}
                    >
                        {tk('EditTemplates')}
                    </Button>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <SearchBoxAndDebounce
                        value=""
                        onChange={onSearch}
                        replaceStar={true}
                        size="small"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth size="small">
                        <InputLabel id="select-label">{tk('Manufacturer')}</InputLabel>
                        <Select
                            labelId="select-label"
                            value={selectedManufacturer}
                            label={tk('Manufacturer')}
                            onChange={onManufacturerSelect}
                        >
                            <MenuItem value="">&nbsp;</MenuItem>
                            {(manufacturers?.data ?? []).map(m => (
                                <MenuItem key={m.code} value={m.code}>{m.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <Box>
                        <Button variant="contained" color="primary" disabled={addDisabled} onClick={onAddClick}>
                            {tk('Add')}
                        </Button>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    {q.dataLoadingStatus === "loading" && (
                        <CircularProgress/>
                    )}

                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">{tk('RuleTitle')}</TableCell>
                                    <TableCell align="right">{tk('CreatedAt')}</TableCell>
                                    <TableCell align="right">{tk('UpdatedAt')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {q.data?.map(row => (
                                    <TableRow
                                        key={row.id}
                                        sx={{
                                            cursor: "pointer"
                                        }}
                                        onClick={() => onRowClick(row)}
                                    >
                                        <TableCell align="left">{row.title}</TableCell>
                                        <TableCell align="right"><DateDisplay
                                            dateStr={row.createdAt ?? ""}/></TableCell>
                                        <TableCell align="right"><DateDisplay
                                            dateStr={row.updatedAt ?? ""}/></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <BasicCenteredPagingButtons
                        isLoading={q.dataLoadingStatus !== "loading"}
                        hasPreviousPage={q.hasPreviousPage}
                        hasNextPage={q.hasNextPage}
                        onPreviousClick={() => {
                            q.previousPage()
                        }}
                        onNextClick={() => {
                            q.nextPage()
                        }}
                    />
                </Grid>
            </Grid>

        </Box>
    )
}

export default PhotoRuleList