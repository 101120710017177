import {useMutation} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {CDAMWMEServiceCallEvalUpdate, ServiceCallEvalApi} from "crowbar-api";
import {AxiosRequestConfig} from "axios";

export const useServiceCallEvalUpdateEval = () => {
    return useMutation(async (p: CDAMWMEServiceCallEvalUpdate, options?: AxiosRequestConfig) => {
        await crowbarApiFactory(ServiceCallEvalApi)
            .updateEval(p, options)
    })
}