import {PrimitiveAtom, SetStateAction, useAtom} from "jotai";
import {useWorksheetSearchPageInfQuery} from "crowbar-api/hooks/worksheet/search/useWorksheetSearchPageInfQuery";
import {CDAMWMWWorksheetSearchQuery, CDAMWMWWorksheetSearchQueryParams} from "crowbar-api";
import {newWorksheetSubTablePageSizes} from "modules/worksheet/new-worksheet/useNewWorksheetState";
import {UseInfiniteQueryResult} from "@tanstack/react-query";
import {
    useLocalAndServerWorksheetVersions,
    UseLocalAndServerWorksheetVersionsResult
} from "modules/worksheet/mechanic/state/useLocalAndServerWorksheetVersions";
import {useUserContext} from "shared/hook/UserContext";
import {useEffect} from "react";
import {DateUtils} from "shared/utils/DateUtils";

export interface UseMechanicListingStateResult {
    state: MechanicListingState
    setState: (update: SetStateAction<MechanicListingState>) => void

    isLoading: boolean
    isFetching: boolean
    isFetchingNextPage: boolean
    anyHasNextPage: boolean

    worksheetInfQuery: UseInfiniteQueryResult<CDAMWMWWorksheetSearchQuery[], unknown>

    worksheetVersions: UseLocalAndServerWorksheetVersionsResult
}

export interface MechanicListingState {
    mode: 'worksheet' | 'service-call'

    searchValue: string
    pageLimit: number
    selectedOrderByField: 'OrderByModificationDates' | 'OrderBySerialId'
    orderBy: 'ASC' | 'DESC'

    worksheetSearch: CDAMWMWWorksheetSearchQueryParams

    /**
     * Number of rows shown for the tables inside the entries.
     * Not a query field.
     */
    subTablePageSize: typeof newWorksheetSubTablePageSizes[number]

    /**
     * If set, these ownerIds will be used to query the worksheets
     */
    ownerIds?: string[]

    showDeletedWorksheets: boolean
}

const defaultWorksheetFrom = DateUtils.newServerUTCDateAndAdd({month: -99})
const defaultWorksheetTo = DateUtils.newServerUTCDateAndAdd({month: 1})

export interface MechanicListingStateProps {
    mechanicStateAtom: PrimitiveAtom<MechanicListingState>
}

export const useMechanicListingState = (
    mechanicStateAtom: PrimitiveAtom<MechanicListingState>
): UseMechanicListingStateResult => {
    const [state, setState] = useAtom(mechanicStateAtom)

    const [userId] = useUserContext()

    // set the date filters to the default values initially
    useEffect(() => {
        setState(prev => {
            return {
                ...prev,
                worksheetSearch: {
                    ...prev.worksheetSearch,
                    worksheetFrom: defaultWorksheetFrom,
                    worksheetTo: defaultWorksheetTo
                }
            }
        })
        // We do not want to update the values even if the defaults from the state change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setState])

    const worksheetInfQuery = useWorksheetSearchPageInfQuery({
        ...state.worksheetSearch,
        ownerIds: state.ownerIds ? state.ownerIds : userId ? [userId] : [],
        search: state.searchValue,
        limit: state.pageLimit,
        orderBySerialId: state.selectedOrderByField === 'OrderBySerialId',
        orderByModificationDates: state.selectedOrderByField === 'OrderByModificationDates',
        orderByIsDesc: state.orderBy !== 'ASC',
        includeDeletedWorksheet: state.showDeletedWorksheets
    })

    const worksheetVersions = useLocalAndServerWorksheetVersions()

    return {
        state,
        setState,

        isLoading: worksheetInfQuery.isLoading,
        isFetching: worksheetInfQuery.isFetching,
        isFetchingNextPage: worksheetInfQuery.isFetchingNextPage,
        anyHasNextPage: worksheetInfQuery.hasNextPage ?? false,

        worksheetInfQuery,

        worksheetVersions
    }
}