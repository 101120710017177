import React, {useState} from 'react';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    Grid,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {CDSMSSapItemForWorksheetSearchQuery, SapItemApi} from "crowbar-api";
import {useSapItemByItemCode} from "crowbar-api/hooks/sap/useSapItemByItemCode";

export type AddItemDialogProps = DialogProps & {

    onItemSelected: (item: CDSMSSapItemForWorksheetSearchQuery) => void
}

const AddItemDialog = ({onItemSelected, ...props}: AddItemDialogProps) => {
    const tk = useTranslateFunction("Admin.Worksheet")

    const [itemCode, setItemCode] = useState<string>("")

    const itemQuery = useSapItemByItemCode(itemCode)

    const queryItemByItemCode = async () => {
        crowbarApiFactory(SapItemApi)
    }

    const onAddClick = () => {
        if (!itemQuery?.data) return

        onItemSelected(itemQuery.data)
    }

    return (
        <Dialog
            fullWidth
            {...props}
        >
            <DialogContent>
                <Grid container spacing={1}>

                    <Grid item xs={12} fontWeight="bold">
                        {tk('ItemCode')}
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            size="small"
                            value={itemCode}
                            onChange={(e) => setItemCode(e.target.value)}
                            InputProps={{
                                autoComplete: "off"
                            }}
                            onBlur={queryItemByItemCode}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        {itemQuery.isLoading && (
                            <CircularProgress/>
                        )}
                    </Grid>

                    {itemQuery.data && (
                        <Grid item xs={12}>
                            <Typography>{itemQuery.data.itemCode} {itemQuery.data.itemName}, {itemQuery.data.buyUnitMsr}</Typography>
                        </Grid>
                    )}

                </Grid>
            </DialogContent>
            <DialogActions>
                <Stack spacing={1} direction="row" width={1} justifyContent="space-between">
                    <Button variant="outlined" color="primary"
                            onClick={(e) => props?.onClose?.(e, 'escapeKeyDown')}>{tk('Cancel')}</Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onAddClick}
                        disabled={!itemQuery.data}
                    >
                        {tk('Add')}
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

export default AddItemDialog;