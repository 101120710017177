import React from 'react';
import {
    MechanicListingState,
    MechanicListingStateProps,
    useMechanicListingState
} from "modules/worksheet/mechanic/state/useMechanicListingState";
import {Grid} from "@mui/material";
import LinearIndeterminateProgressWithLabel from "shared/components/progress/LinearIndeterminateProgressWithLabel";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {PrimitiveAtom} from "jotai";

export type MechanicWorksheetInfQueryLoaderProps = MechanicListingStateProps & {
    mechanicStateAtom: PrimitiveAtom<MechanicListingState>
}
const MechanicWorksheetInfQueryLoader = ({mechanicStateAtom}: MechanicWorksheetInfQueryLoaderProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.Mechanic`)
    const {isLoading, isFetchingNextPage, isFetching} = useMechanicListingState(mechanicStateAtom)

    const displayLoader = isLoading || isFetching || isFetchingNextPage

    return (
        <Grid container direction="row" spacing="center" alignItems="center" sx={{
            height: "20px"
        }}>
            <Grid item xs={12}>
                {displayLoader && (
                    <LinearIndeterminateProgressWithLabel
                        label={tk('WorksheetLoading')}
                    />
                )}
            </Grid>
        </Grid>
    );
};

export default MechanicWorksheetInfQueryLoader;