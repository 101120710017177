import React from 'react';
import {EquipmentSearchStateProps} from "modules/equipment/equipment-search/EquipmentSearch";
import {Grid, Stack, Typography} from "@mui/material";
import {useEquipmentSearchState} from "modules/equipment/equipment-search/useEquipmentSearchState";
import RenderIfVisible from "shared/render-if-visible/render-if-visible";
import EquipmentSearchResultGridEntryPlaceholder
    from "modules/equipment/equipment-search/results/EquipmentSearchResultGridEntryPlaceholder";
import EquipmentSearchResultGridEntry from "modules/equipment/equipment-search/results/EquipmentSearchResultGridEntry";
import {CDAMEMSapEquipmentSearchQuery} from "crowbar-api";
import {useNavigate} from "react-router-dom";
import ImageIcon from "shared/components/image-icon/ImageIcon";
import {WorksheetIconImages} from "modules/worksheet/editor/shared/component/images/WorksheetIconImages";
import useTranslateFunction from "shared/language/useTranslateFunction";

export type EquipmentSearchResultGridProps = EquipmentSearchStateProps & {}
const EquipmentSearchResultGrid = ({searchStateAtom}: EquipmentSearchResultGridProps) => {
    const tk = useTranslateFunction('Equipment')
    const navigate = useNavigate()
    const {searchInfQuery} = useEquipmentSearchState(searchStateAtom)

    const entries = searchInfQuery.data?.pages?.flatMap(p => p) ?? []

    const isLoading = searchInfQuery.isLoading || searchInfQuery.isFetching

    const onEntryClick = (equipment: CDAMEMSapEquipmentSearchQuery) => {
        navigate(`/equipment/details?sapEquipmentId=${equipment.insId ?? ""}&modSapEquipmentId=${equipment.modSapEquipmentId ?? ""}`)
    }

    if (!isLoading && entries.length < 1) {
        return (
            <Stack direction="row" spacing={1} alignItems="center">
                <ImageIcon image={WorksheetIconImages.sadNotFoundMedium} size={64}/>
                <Typography>{tk('NoEquipmentsFound')}</Typography>
            </Stack>
        )
    }

    return (
        <Grid container spacing={1} justifyContent="center">
            {entries.map((e, i) => (
                <Grid item
                      xs={4}
                      md={3}
                      key={[e.insId, e.modSapEquipmentId, i].join("-")}
                >
                    <RenderIfVisible
                        defaultHeight={300}
                        stayRendered={true}
                        placeholderElement={(
                            <EquipmentSearchResultGridEntryPlaceholder/>
                        )}
                    >

                        <EquipmentSearchResultGridEntry
                            equipment={e}
                            onClick={onEntryClick}
                        />

                    </RenderIfVisible>
                </Grid>
            ))}
        </Grid>
    );
};

export default EquipmentSearchResultGrid;