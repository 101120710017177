import React from 'react';
import {CDAMWMWServiceCallSearchQuery, CDAMWMWWorksheetSearchQuery} from "crowbar-api";
import {MechanicListingState, useMechanicListingState} from "modules/worksheet/mechanic/state/useMechanicListingState";
import ResultEntryModeWorksheet from "modules/worksheet/mechanic/results/ResultEntryModeWorksheet";
import ResultEntryModeServiceCall from "modules/worksheet/mechanic/results/ResultEntryModeServiceCall";
import {Card, Stack} from "@mui/material";
import {WorksheetVersionMismatch} from "modules/worksheet/mechanic/state/useLocalAndServerWorksheetVersions";
import {byPropertiesOf} from "shared/utils/ArraySort";
import {useWorksheetCartState} from "modules/worksheet/worksheet-cart/useWorksheetCartState";
import {PrimitiveAtom} from "jotai";

export interface MechanicWorksheetResultEntryProps {
    mechanicStateAtom: PrimitiveAtom<MechanicListingState>
    worksheet: CDAMWMWWorksheetSearchQuery
    mismatch?: WorksheetVersionMismatch
}

const MechanicWorksheetResultEntry = ({mechanicStateAtom, worksheet, mismatch}: MechanicWorksheetResultEntryProps) => {

    const {state: {mode, subTablePageSize}} = useMechanicListingState(mechanicStateAtom)

    const {cartEntries, addCartEntry} = useWorksheetCartState()

    const serviceCalls = (worksheet?.serviceCalls ?? [])
        .sort(byPropertiesOf<CDAMWMWServiceCallSearchQuery>(['displayOrder']))
    const numOfServiceCalls = serviceCalls.length

    // only display it in worksheet mode, if the number of service calls > 1
    if (mode === 'worksheet' && numOfServiceCalls > 1) {
        return (
            <ResultEntryModeWorksheet
                worksheet={worksheet}
                mismatch={mismatch}
            />
        )
    } else if (mode === 'service-call' || numOfServiceCalls < 2) {
        if (numOfServiceCalls > 0) {
            return (
                <Stack direction="column" spacing={1}>
                    {serviceCalls.map(sc => (
                        <ResultEntryModeServiceCall
                            key={[worksheet.id, sc.id ?? "@"].join("-")}
                            worksheet={worksheet}
                            mismatch={mismatch}
                            serviceCallId={sc?.id}
                            pageSize={subTablePageSize}
                        />
                    ))}
                </Stack>
            )
        } else {
            return (
                <ResultEntryModeServiceCall
                    worksheet={worksheet}
                    mismatch={mismatch}
                    pageSize={subTablePageSize}
                />
            )
        }
    } else { // other modes maybe?
        return (
            <Card>
                <p>{worksheet.serialId}, Not supported mode: {mode}</p>
            </Card>
        )
    }
}

export default MechanicWorksheetResultEntry;