import React from 'react';
import {Box, Stack} from "@mui/material";
import WorksheetEditorHeader from "modules/worksheet/editor/ui/header/WorksheetEditorHeader";
import WorksheetEditorRoutes from "modules/worksheet/editor/WorksheetEditorRoutes";
import WEditorDifferentVersionWarning from "modules/worksheet/editor/ui/header/WEditorDifferentVersionWarning";

const WorksheetEditorUi = () => {
    return (
        <>
            <Stack
                direction="column"
                spacing={1}
                sx={{
                    top: 70,
                    zIndex: 20,
                    width: 1,
                    height: 1,
                    position: "sticky",
                    backgroundColor: "white"
                }}>
                <WEditorDifferentVersionWarning/>
                <WorksheetEditorHeader/>
            </Stack>

            <Box>
                <WorksheetEditorRoutes/>
            </Box>
        </>
    );
};

export default WorksheetEditorUi;