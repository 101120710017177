import {AxiosInstance} from "axios";
import {Configuration} from "crowbar-api";
import {BaseAPI} from "crowbar-api/dist/base";
import {crowbarApiAxios, crowbarApiAxiosNoErrorInterceptors} from "shared/axios-init/AxiosInitialization";

export const crowbarApiFactory = <T extends BaseAPI>(type: {
    new(configuration?: Configuration | undefined, basePath?: string | undefined, axios?: AxiosInstance | undefined): T
}, axios?: AxiosInstance, skipErrorInterceptors: boolean = false) => {
    const configuration = new Configuration({
        basePath: window.location.origin
    });
    const defaultAxiosInstance = (skipErrorInterceptors) ? crowbarApiAxiosNoErrorInterceptors : crowbarApiAxios
    return new type(configuration, undefined, axios ?? defaultAxiosInstance) as T;
}