import React from 'react';
import {CDAMWMWServiceCallSave} from "crowbar-api";
import {Box, Grid, Stack} from '@mui/material';
import WEditorEquipmentTextLines from "modules/worksheet/editor/ui/equipments/WEditorEquipmentTextLines";
import WEditorStatusDrawerMenu from "modules/worksheet/editor/ui/drawer/WEditorStatusDrawerMenu";
import {useNewAtomWithRef} from "shared/hook/useNewatomWithRef";

import "./WEditorStatusDrawerEntry.scss"
import EquipmentThumbnailForServiceCall
    from "modules/worksheet/editor/shared/component/equipment-thumbnail/EquipmentThumbnailForServiceCall";

export interface WEditorStatusDrawerEntryProps {
    selected: boolean
    serviceCall: CDAMWMWServiceCallSave
    onSelectedClick: (serviceCallId: string) => void | Promise<void>
}

const WEditorStatusDrawerEntry = ({selected, serviceCall, onSelectedClick}: WEditorStatusDrawerEntryProps) => {

    const [shake, setShake] = useNewAtomWithRef<boolean>(false)

    const onSelectClick = () => {
        setShake(true)
        setTimeout(() => {
            if (serviceCall.id) {
                onSelectedClick(serviceCall.id)
            }
            setShake(false)
        }, 50)
    }

    return (
        <Grid
            className={shake ? "drawer-shake" : ""}
            container
            direction="row"
            spacing={0.5}
            sx={{
                padding: 1,
                display: "flex",
                justifyContent: "space-between"
            }}
        >
            <Grid item xs="auto">
                <Stack
                    direction="column"
                    spacing={1}
                    onClick={onSelectClick}
                    sx={{
                        ":hover": {
                            "cursor": "pointer"
                        }
                    }}
                >
                    <EquipmentThumbnailForServiceCall size={128} serviceCall={serviceCall}/>

                    <Box sx={{
                        minWidth: "20vw"
                    }}>
                        <WEditorEquipmentTextLines serviceCall={serviceCall}/>
                    </Box>
                </Stack>
            </Grid>

            <Grid item xs="auto">
                <WEditorStatusDrawerMenu
                    selected={selected}
                    serviceCall={serviceCall}
                    onSelectedClick={onSelectedClick}
                />
            </Grid>


            {/*<Grid container>
                <Grid item xs="auto">
                    <Box
                        sx={{
                            padding: 2
                        }}
                    >
                        <Button variant="outlined" color="primary" disabled={selected} onClick={onSelectClick}>
                            {selected ? <CheckBoxIcon/> : <CheckBoxOutlineBlankIcon/>}
                        </Button>
                    </Box>

                </Grid>
            </Grid>*/}
        </Grid>
    );
};

export default WEditorStatusDrawerEntry;