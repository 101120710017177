import React from 'react';
import {Grid, Stack} from "@mui/material";
import LabelField from "shared/components/typography/LabelField";
import useTranslateFunction from "shared/language/useTranslateFunction";
import DateDisplay, {dateDisplayDefaultOptions} from "shared/components/date-display/DateDisplay";
import {WorksheetDownloadPdfButton} from "modules/admin/worksheet/worksheet-list/results/WorksheetDownloadPdfButton";
import {WorksheetIconImages} from "modules/worksheet/editor/shared/component/images/WorksheetIconImages";
import {CDAMWMWorksheetEvalModel, CDAMWMWWorksheetQuery} from "crowbar-api";

export type WEvalHeaderLinesProps = {
    wEval: CDAMWMWorksheetEvalModel | null | undefined
    worksheet: CDAMWMWWorksheetQuery | null | undefined
}

const WEvalHeaderLines = ({wEval, worksheet}: WEvalHeaderLinesProps) => {
    const tk = useTranslateFunction("Admin.Worksheet")

    const ws = worksheet

    const salesOrder = ws?.salesOrders?.find(() => true)

    return (
        <Grid container spacing={1}>

            <Grid item md={6} xs={12}>
                <Stack direction="column" spacing={0.5}>
                    <LabelField
                        label={tk('CustomerCode')}
                        content={ws?.cardCode ?? ""}
                    />
                    <LabelField
                        label={tk('CustomerName')}
                        content={ws?.partnerCardName ?? ""}
                    />
                    {salesOrder?.docNum && (
                        <>
                            <LabelField
                                label={tk('SalesOrder')}
                                content={salesOrder.docNum.toString()}
                            />
                            <LabelField
                                label={tk('SalesOrderDate')}
                                content={(
                                    <DateDisplay
                                        dateStr={salesOrder.docDate ?? ""}
                                        trimDotFromEnd={true}
                                        options={{
                                            ...dateDisplayDefaultOptions,
                                            hour: undefined,
                                            minute: undefined
                                        }}
                                    />
                                )}
                            />
                        </>
                    )}
                    {(ws?.tripDistanceKm && ws.tripDistanceKm > 0) && (
                        <LabelField
                            label={tk('TripDistanceKm')}
                            content={ws.tripDistanceKm.toString() + " km"}
                        />
                    )}
                </Stack>

            </Grid>

            <Grid item md={6} xs={12}>
                <Grid container spacing={0.5}>

                    <Grid item xs={12}>
                        <LabelField
                            label={tk('LastStatusChange')}
                            content={(
                                <DateDisplay
                                    dateStr={
                                        ws?.evaluatedDate ??
                                        ws?.finalSignedOrSkippedDate ??
                                        ws?.finalDate ??
                                        ws?.updatedAt ??
                                        ws?.createdAt ??
                                        ""
                                    }
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Stack direction="row" spacing={1} justifyContent="end" alignItems="center">
                            <WorksheetDownloadPdfButton
                                worksheetId={ws?.id}
                                image={WorksheetIconImages.pdfColor}
                                pdfLayout="customer"
                                label={tk('CustomerVersion')}
                            />

                            <WorksheetDownloadPdfButton
                                worksheetId={ws?.id}
                                image={WorksheetIconImages.pdfBlack}
                                pdfLayout="admin"
                                label={tk('AdminVersion')}
                            />
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>

        </Grid>
    );
};

export default WEvalHeaderLines;