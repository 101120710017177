import React from 'react';
import {Card, CardContent, Grid, Stack, Typography, TypographyProps} from "@mui/material";
import {CDAMEMSapEquipmentSearchQuery} from "crowbar-api";
import VehicleTypeDisplay from "modules/worksheet/editor/shared/component/vehicle-type-display/VehicleTypeDisplay";
import {StringUtils} from "shared/utils/StringUtils";
import EquipmentCoverOrBlobImage from "modules/equipment/equipment-search/results/EquipmentCoverOrBlobImage";
import CrowbarBlobTypeMap from "crowbar-api/enum-map/CrowbarBlobTypeMap";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import useTranslateFunction from "shared/language/useTranslateFunction";

export type EquipmentSearchResultGridEntryProps = {
    equipment: CDAMEMSapEquipmentSearchQuery
    onClick: (equipment: CDAMEMSapEquipmentSearchQuery) => void
}

const StyledTypography = ({children, ...props}: TypographyProps) => {
    return (
        <Typography variant="caption" fontWeight="bold" margin={0} {...props}>
            {children}
        </Typography>
    )
}

const EquipmentSearchResultGridEntry = ({equipment, onClick}: EquipmentSearchResultGridEntryProps) => {
    const tk = useTranslateFunction("Equipment")
    const firstImage = (equipment.blobs ?? [])
        .filter(e => e.type === CrowbarBlobTypeMap.equipmentImage)
        .find(() => true)

    return (
        <Card
            variant="outlined"
            sx={{
                ":hover": {
                    borderWidth: "2px",
                    borderColor: "red",
                    cursor: "pointer"
                }
            }}
            onClick={() => onClick(equipment)}
        >
            <CardContent>
                <Grid container spacing={1}>
                    <Grid item xs={12} sx={{p: 0}}>
                        <Stack direction="column" spacing={0}>
                            <Grid container spacing={1} justifyContent="space-between">

                                <Grid item xs="auto">
                                    <Stack direction="row" spacing={0} alignItems="center">
                                        {(StringUtils.notUndefinedNullAndEmpty(equipment.modSapEquipmentId) &&
                                            !equipment.insId) && (
                                            <Grid item xs="auto" title={tk('ModEquipmentWarn')}>
                                                <ErrorOutlineIcon/>
                                            </Grid>
                                        )}
                                        {StringUtils.undefinedNullOrEmpty(equipment.vehicleManufacturer) &&
                                        StringUtils.undefinedNullOrEmpty(equipment.vehicleType) ? (
                                            <StyledTypography variant="caption" fontWeight="bold">N/A</StyledTypography>
                                        ) : (
                                            <VehicleTypeDisplay
                                                variant="caption"
                                                manufacturer={equipment.vehicleManufacturer}
                                                vehicleType={equipment.vehicleType}
                                                fontWeight="bold"
                                            />
                                        )}
                                    </Stack>

                                    {equipment.manufacturerSn && (
                                        <StyledTypography variant="caption"
                                                          fontWeight="bold">{equipment.manufacturerSn}</StyledTypography>
                                    )}
                                    {equipment.internalSn && (
                                        <StyledTypography variant="caption"
                                                          fontWeight="bold">{equipment.internalSn}</StyledTypography>
                                    )}
                                </Grid>

                                <Grid item xs="auto">
                                    <StyledTypography title={equipment.itemName ?? ""}>
                                        {equipment.itemCode ?? "-"}
                                    </StyledTypography>
                                </Grid>
                            </Grid>

                            <Typography
                                component="div"
                                variant="caption"
                                sx={{
                                    width: 1,
                                    p: 0,
                                    textOverflow: 'ellipsis',
                                    overflow: "hidden",
                                    display: "-webkit-box",
                                    lineHeight: "15px",
                                    height: "30px",
                                    WebkitLineClamp: 2,
                                    WebkitBoxOrient: "vertical"
                                }}
                            >
                                {equipment.cardCode} {equipment.cardName}
                            </Typography>

                        </Stack>
                    </Grid>
                    <Grid item xs={12}>

                        <EquipmentCoverOrBlobImage
                            coverImage={equipment.coverImage ?? undefined}
                            parentFolder={firstImage?.parentDirectory}
                            filename={firstImage?.fileName}

                        />

                    </Grid>
                </Grid>

            </CardContent>
        </Card>
    );
};

export default EquipmentSearchResultGridEntry;