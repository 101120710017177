import React, {useMemo} from 'react';
import {Box, BoxProps, CircularProgress} from "@mui/material";
import ProgressiveImage from "shared/components/progressive-image/ProgressiveImage";
import {StringUtils} from "shared/utils/StringUtils";
import {WorksheetIconImages} from "modules/worksheet/editor/shared/component/images/WorksheetIconImages";

export type EquipmentThumbnailProps = BoxProps & {
    thumbSrc?: string | null | undefined
    /**
     * image size in pixels
     * or it uses full width
     */
    size?: number

    imageProps?: BoxProps
}
const EquipmentThumbnail = ({thumbSrc, size, imageProps, ...props}: EquipmentThumbnailProps) => {
    const missingImage = useMemo(() => (
        <Box
            sx={{
                width: size ?? 1,
                height: size ?? 1,
                objectFit: "contain"
            }}
            component="img"
            src={WorksheetIconImages.forkliftLarge}
        />
    ), [size])


    return (
        <Box
            {...props}
            sx={{
                width: size ? `${size}px` : 1,
                height: size ? `${size}px` : 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                ...props.sx
            }}
        >
            {(thumbSrc && StringUtils.notUndefinedNullAndEmpty(thumbSrc))
                ? (
                    <ProgressiveImage queryKey={'worksheet-equipment-images'}
                                      src={thumbSrc}
                                      retry={thumbSrc ? 3 : 0}
                    >
                        {(isLoading, imageObjectUrl, hasError) => {
                            if (isLoading) {
                                return <CircularProgress/>
                            } else if (hasError) {
                                return "Error"
                            } else {
                                return (
                                    <Box
                                        sx={{
                                            width: size ?? 1,
                                            //height: size ?? 1,
                                            objectFit: "contain",
                                            aspectRatio: "1/1",
                                            ...imageProps?.sx
                                        }}
                                        component="img"
                                        src={imageObjectUrl}
                                    />
                                )
                            }
                        }}
                    </ProgressiveImage>
                ) : (
                    missingImage
                )}
        </Box>
    )
}

export default EquipmentThumbnail;