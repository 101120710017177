import {useMutation} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {WorksheetApi} from "crowbar-api";

export type UseWorksheetUpdateEmailNotificationAddresses = {
    worksheetId?: string
    value?: string
}
export const useWorksheetUpdateEmailNotificationAddresses = () => {
    return useMutation(async ({worksheetId, value}: UseWorksheetUpdateEmailNotificationAddresses) => {
        await crowbarApiFactory(WorksheetApi)
            .updateEmailNotificationAddresses(worksheetId, value)
    })
}