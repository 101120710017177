import {useMutation} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {PhotoRuleApi} from "crowbar-api";


const usePhotoRuleDelete = () => {
    return useMutation(async (ruleId: string) => {
        const response = await crowbarApiFactory(PhotoRuleApi).deletePermanentlyById(ruleId)
        if (response.status !== 200) {
            throw new Error('Failed to delete photo rule.')
        }
    })
}

export default usePhotoRuleDelete