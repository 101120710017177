import React from 'react';
import {useServiceCallsAtom} from "modules/worksheet/editor/state/atoms/useServiceCallsAtom";
import WEditorStatusDrawerEntry from "modules/worksheet/editor/ui/drawer/WEditorStatusDrawerEntry";
import {byPropertiesOf} from "shared/utils/ArraySort";
import {CDAMWMWServiceCallSave} from "crowbar-api";
import {Divider, Grid, ListItem} from "@mui/material";
import {useWorksheetEditorHeaderInfoAtom} from "modules/worksheet/editor/state/WorksheetEditorHeaderAtoms";
import useWEditorStatusDrawerArgsAtom from "modules/worksheet/editor/state/atoms/useWEditorStatusDrawerArgsAtom";
import useTranslateFunction from "shared/language/useTranslateFunction";
import WEditorAddNewServiceCallDialogButton
    from "modules/worksheet/editor/ui/equipments/WEditorAddNewServiceCallDialogButton";
import useWorksheetSignedState from "modules/worksheet/editor/state/helpers/useWorksheetSignedState";

const WEditorStatusDrawerEntries = () => {
    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)
    const {setOpen} = useWEditorStatusDrawerArgsAtom()
    const [info, setInfo] = useWorksheetEditorHeaderInfoAtom()
    const [serviceCalls] = useServiceCallsAtom()

    const {editDisabled} = useWorksheetSignedState()

    const currentServiceCalls = (serviceCalls ?? [])
        .sort(byPropertiesOf<CDAMWMWServiceCallSave>(
            ['displayOrder']
        ))

    const onSelectedClick = (serviceCallId: string) => {
        setInfo(prev => {
            return {
                ...prev,
                selectedServiceCallId: serviceCallId
            }
        })
        setOpen(false)
    }

    return (
        <Grid container direction="column" spacing={0.5}>
            {currentServiceCalls.map(sc => (
                <Grid item key={sc.id} xs="auto">
                    <ListItem sx={{
                        width: "auto"
                    }}>
                        <WEditorStatusDrawerEntry
                            selected={info.selectedServiceCallId === sc.id}
                            serviceCall={sc}
                            onSelectedClick={onSelectedClick}
                        />
                    </ListItem>
                    <Divider sx={{width: 1}}/>
                </Grid>
            ))}
            {!editDisabled && (
                <Grid item key={0} xs="auto">
                    <ListItem sx={{
                        width: "auto",
                        display: "flex",
                        justifyContent: "center"
                    }}>
                        <WEditorAddNewServiceCallDialogButton/>
                    </ListItem>
                </Grid>
            )}
        </Grid>
    );
};

export default WEditorStatusDrawerEntries;