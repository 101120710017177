import {
    CDAMWMModSapType,
    CDAMWMWModSapEquipmentSave,
    CDAMWMWModSapPartnerAddressSave,
    CDAMWMWModSapPartnerContactSave,
    CDAMWMWModSapPartnerSave,
    CDAMWMWServiceCallBlobSave,
    CDAMWMWServiceCallItemRowSave,
    CDAMWMWServiceCallSave,
    CDAMWMWServiceCallTranscriptionSave,
    CDAMWMWWorksheetSapSalesOrderRowSave,
    CDAMWMWWorksheetSapSalesOrderSave,
    CDAMWMWWorksheetSave,
    CDSMPartnerEquipmentPageRow,
    CDSMSPartnerEquipmentForWorksheet,
    CDSMSPartnerForWorksheet,
    CDSMSSapSalesOrderForWorksheetSearchQuery
} from "crowbar-api"
import {v4 as uuid} from 'uuid'
import WorksheetStatusMap from "crowbar-api/enum-map/WorksheetStatusMap";
import WorksheetSelectedDataSourceMap from "crowbar-api/enum-map/WorksheetSelectedDataSourceMap";
import WorksheetTripTypeMap from "crowbar-api/enum-map/WorksheetTripTypeMap";
import ServiceCallStatusMap from "crowbar-api/enum-map/ServiceCallStatusMap";
import ServiceCallOperatingHoursTypeMap from "crowbar-api/enum-map/ServiceCallOperatingHoursTypeMap";
import ServiceCallItemRowStatusMap from "crowbar-api/enum-map/ServiceCallItemRowStatusMap";
import ServiceCallItemRowWarningTypeMap from "crowbar-api/enum-map/ServiceCallItemRowWarningTypeMap";
import ServiceCallSelectedSourceMap from "crowbar-api/enum-map/ServiceCallSelectedSourceMap";
import ServiceCallItemRowSelectedSourceMap from "crowbar-api/enum-map/ServiceCallItemRowSelectedSourceMap";
import ModSapStatusMap from "crowbar-api/enum-map/ModSapStatusMap";
import {StringUtils} from "shared/utils/StringUtils";
import WorksheetSapSalesOrderRowTypeMap from "crowbar-api/enum-map/WorksheetSapSalesOrderRowTypeMap";
import ServiceCallBlobTypeMap from "crowbar-api/enum-map/ServiceCallBlobTypeMap";
import {DateUtils} from "shared/utils/DateUtils";

export class WorksheetSaveFactory {

    public static newWorksheetSaveFromSalesOrder = (salesOrder: CDSMSSapSalesOrderForWorksheetSearchQuery,
                                                    ownerId: string,
                                                    isTrip: (itemRow: CDAMWMWServiceCallItemRowSave) => (boolean | undefined)
    ): CDAMWMWWorksheetSave => {
        const w = WorksheetSaveFactory.newWorksheetSave(ownerId)

        // Worksheet Partner
        w.partnerSource = WorksheetSelectedDataSourceMap.sapSalesOrder
        w.cardCode = salesOrder.cardCode
        w.partnerCardName = salesOrder.partnerCardName
        w.partnerCardType = salesOrder.partnerCardType
        w.partnerContactPerson = salesOrder.partnerContactPerson
        w.partnerVatHu = salesOrder.partnerVatHu
        w.partnerVatInternational = salesOrder.partnerVatInternational

        // Worksheet Partner Address
        w.partnerAddressSource = WorksheetSelectedDataSourceMap.notSet
        // only use the sales order address automatically, if the shipping is not set
        if (StringUtils.notUndefinedNullAndEmpty(salesOrder.billingAddress) &&
            StringUtils.undefinedNullOrEmpty(salesOrder.shippingAddress)
        ) {
            w.partnerAddressSource = WorksheetSelectedDataSourceMap.sapSalesOrder
            w.address = salesOrder.billingAddress
            w.addressType = "B"
            w.addressStreet = salesOrder.billingAddressStreet
            w.addressStreetNo = salesOrder.billingAddressStreetNo
            w.addressBlock = salesOrder.billingAddressBlock
            w.addressBuilding = salesOrder.billingAddressBuilding
            w.addressZipCode = salesOrder.billingAddressZipCode
            w.addressCity = salesOrder.billingAddressCity
            w.addressCounty = salesOrder.billingAddressCounty
            w.addressCountryCode = salesOrder.billingAddressCountryCode
        }

        // Worksheet Sales Person
        w.salesPersonSource = WorksheetSelectedDataSourceMap.sapSalesOrder
        w.slpCode = salesOrder.slpCode
        w.slpName = salesOrder.slpName
        w.slpEmployeeId = salesOrder.slpEmployeeId
        w.slpUserSign = salesOrder.slpUserSign
        w.slpPhone1 = salesOrder.slpPhone1
        w.slpPhone2 = salesOrder.slpPhone2

        // Worksheet Contact Person
        w.contactPersonSource = WorksheetSelectedDataSourceMap.sapSalesOrder
        w.contactCode = salesOrder.contactCode
        w.contactName = salesOrder.contactName
        w.contactPosition = salesOrder.contactPosition
        w.contactPhone1 = salesOrder.contactPhone1
        w.contactPhone2 = salesOrder.contactPhone2
        w.contactPhone3 = salesOrder.contactPhone3
        w.contactEmail = salesOrder.contactEmail
        w.contactFirstname = salesOrder.contactFirstname
        w.contactMiddleName = salesOrder.contactMiddleName
        w.contactLastname = salesOrder.contactLastname

        // Worksheet main
        w.status = WorksheetStatusMap.draft
        w.tripType = WorksheetTripTypeMap.notSet

        const newDocStatus = salesOrder.docStatus === "O" ? "O" : "C"

        // Worksheet Sales Order
        const so = WorksheetSaveFactory.newSalesOrderSave(newDocStatus, w.id)
        w.salesOrders?.push(so)

        // so fields
        so.docEntry = salesOrder.docEntry
        so.docNum = salesOrder.docNum
        so.docStatus = newDocStatus
        so.docDate = salesOrder.docDate
        so.docDueDate = salesOrder.docDueDate
        so.cardCode = salesOrder.cardCode
        so.cardName = salesOrder.cardName
        so.contactCode = salesOrder.contactCode
        so.numAtCard = salesOrder.numAtCard
        so.machineId = salesOrder.machineId
        so.insId = salesOrder.insId
        so.sapEquipmentId = salesOrder.sapEquipmentId
        so.manufacturerSn = salesOrder.manufacturerSn
        so.internalSn = salesOrder.internalSn
        so.itemCode = salesOrder.itemCode
        so.itemName = salesOrder.itemName
        so.equipmentContactCode = salesOrder.equipmentContactCode
        so.street = salesOrder.street
        so.streetNo = salesOrder.streetNo
        so.block = salesOrder.block
        so.building = salesOrder.building
        so.zipCode = salesOrder.zipCode
        so.city = salesOrder.city
        so.county = salesOrder.county
        so.countryCode = salesOrder.countryCode
        so.manufacturer = salesOrder.manufacturer
        so.vehicleType = salesOrder.vehicleType
        so.yearOfManufacture = salesOrder.yearOfManufacture
        so.mode = salesOrder.mode
        so.voltage = salesOrder.voltage
        so.engineType = salesOrder.engineType
        so.engineCode = salesOrder.engineCode
        so.liftingMastType = salesOrder.liftingMastType
        so.liftingMastCode = salesOrder.liftingMastCode
        so.typeSizeFront = salesOrder.typeSizeFront
        so.typeSizeBack = salesOrder.typeSizeBack
        so.opGroupNumber = salesOrder.opGroupNumber
        so.coverImage = salesOrder.coverImage
        so.slpCode = salesOrder.slpCode
        so.slpName = salesOrder.slpName
        so.billingAddress = salesOrder.billingAddress
        so.shippingAddress = salesOrder.shippingAddress
        so.billToCode = salesOrder.billToCode
        so.billingAddressStreet = salesOrder.billingAddressStreet
        so.billingAddressStreetNo = salesOrder.billingAddressStreetNo
        so.billingAddressBlock = salesOrder.billingAddressBlock
        so.billingAddressBuilding = salesOrder.billingAddressBuilding
        so.billingAddressZipCode = salesOrder.billingAddressZipCode
        so.billingAddressCity = salesOrder.billingAddressCity
        so.billingAddressCounty = salesOrder.billingAddressCounty
        so.billingAddressCountryCode = salesOrder.billingAddressCountryCode
        so.shipToCode = salesOrder.shipToCode
        so.shippingAddressStreet = salesOrder.shippingAddressStreet
        so.shippingAddressStreetNo = salesOrder.shippingAddressStreetNo
        so.shippingAddressBlock = salesOrder.shippingAddressBlock
        so.shippingAddressBuilding = salesOrder.shippingAddressBuilding
        so.shippingAddressZipCode = salesOrder.shippingAddressZipCode
        so.shippingAddressCity = salesOrder.shippingAddressCity
        so.shippingAddressCounty = salesOrder.shippingAddressCounty
        so.shippingAddressCountryCode = salesOrder.shippingAddressCountryCode

        // rows and row fields
        so.rows = salesOrder.rows?.map((row, i) => {
            const sor = WorksheetSaveFactory.newSalesOrderRowSave(so.id)
            sor.rowType = row.rowType
            sor.docEntry = row.docEntry
            sor.lineNum = row.lineNum ?? -1
            sor.visOrder = i + 1
            sor.lineStatus = row.lineStatus
            sor.itemCode = row.itemCode
            sor.description = row.description
            sor.quantity = row.quantity
            sor.openQuantity = row.openQuantity
            sor.unitOfQuantity = row.unitOfQuantity
            sor.lineOfBusiness = row.lineOfBusiness
            sor.placeOfExp = row.placeOfExp
            sor.lineText = row.lineText
            sor.sourceCoverImage = row.coverImage

            return sor
        }) ?? []

        // Service Call
        // SAP Sales Order can only have 1 Equipment
        // If it has the ins id set, meaning equipment selected
        // we create a service call as well (with the so item rows)
        if (salesOrder.insId) {
            const sc = WorksheetSaveFactory.newServiceCallSave(w.id)
            w.serviceCalls?.push(sc)

            sc.selectedSource = ServiceCallSelectedSourceMap.sapSalesOrder

            sc.sapEquipmentId = salesOrder.sapEquipmentId
            sc.manufacturerSn = salesOrder.manufacturerSn
            sc.internalSn = salesOrder.internalSn
            sc.itemCode = salesOrder.itemCode
            sc.itemName = salesOrder.itemName
            sc.contactCode = salesOrder.contactCode
            sc.street = salesOrder.street
            sc.streetNo = salesOrder.streetNo
            sc.block = salesOrder.block
            sc.building = salesOrder.building
            sc.zipCode = salesOrder.zipCode
            sc.city = salesOrder.city
            sc.county = salesOrder.county
            sc.countryCode = salesOrder.countryCode
            sc.manufacturer = salesOrder.manufacturer
            sc.vehicleType = salesOrder.vehicleType
            sc.yearOfManufacture = salesOrder.yearOfManufacture
            sc.mode = salesOrder.mode
            sc.voltage = salesOrder.voltage
            sc.engineType = salesOrder.engineType
            sc.engineCode = salesOrder.engineCode
            sc.liftingMastType = salesOrder.liftingMastType
            sc.liftingMastCode = salesOrder.liftingMastCode
            sc.typeSizeFront = salesOrder.typeSizeFront
            sc.typeSizeBack = salesOrder.typeSizeBack
            sc.opGroupNumber = salesOrder.opGroupNumber
            sc.coverImage = salesOrder.coverImage

            WorksheetSaveFactory.copySalesOrderRowsToServiceCallAsItemRows(so, sc, isTrip)
        }

        return w
    }

    public static newWorksheetSaveFromEquipment = (equipment: CDSMSPartnerEquipmentForWorksheet, ownerId: string) => {

        const w = WorksheetSaveFactory.newWorksheetSave(ownerId)

        // Worksheet Partner
        w.partnerSource = WorksheetSelectedDataSourceMap.sapSalesOrder
        w.cardCode = equipment.cardCode
        w.partnerCardName = equipment.partnerCardName
        w.partnerCardType = equipment.partnerCardType
        w.partnerContactPerson = equipment.partnerContactPerson
        w.partnerVatHu = equipment.partnerVatHu
        w.partnerVatInternational = equipment.partnerVatInternational

        // Worksheet main
        w.status = WorksheetStatusMap.draft
        w.tripType = WorksheetTripTypeMap.notSet

        // Service Call for the Equipment
        const sc = WorksheetSaveFactory.newServiceCallSave(w.id)
        w.serviceCalls?.push(sc)

        sc.selectedSource = ServiceCallSelectedSourceMap.sapPartnerEquipment

        sc.sapEquipmentId = equipment.sapEquipmentId
        sc.manufacturerSn = equipment.manufacturerSn
        sc.internalSn = equipment.internalSn
        sc.itemCode = equipment.itemCode
        sc.itemName = equipment.itemName
        sc.contactCode = equipment.equipmentContactCode
        sc.street = equipment.street
        sc.streetNo = equipment.streetNo
        sc.block = equipment.block
        sc.building = equipment.building
        sc.zipCode = equipment.zipCode
        sc.city = equipment.city
        sc.county = equipment.county
        sc.countryCode = equipment.countryCode
        sc.manufacturer = equipment.manufacturer
        sc.vehicleType = equipment.vehicleType
        sc.yearOfManufacture = equipment.yearOfManufacture
        sc.mode = equipment.mode
        sc.voltage = equipment.voltage
        sc.engineType = equipment.engineType
        sc.engineCode = equipment.engineCode
        sc.liftingMastType = equipment.liftingMastType
        sc.liftingMastCode = equipment.liftingMastCode
        sc.typeSizeFront = equipment.typeSizeFront
        sc.typeSizeBack = equipment.typeSizeBack
        sc.opGroupNumber = equipment.opGroupNumber
        sc.coverImage = equipment.coverImage

        return w
    }

    public static newWorksheetSaveFromPartner = (partner: CDSMSPartnerForWorksheet, ownerId: string) => {
        const w = WorksheetSaveFactory.newWorksheetSave(ownerId)

        // Worksheet Partner
        w.partnerSource = WorksheetSelectedDataSourceMap.sapSalesOrder
        w.cardCode = partner.cardCode
        w.partnerCardName = partner.partnerCardName
        w.partnerCardType = partner.partnerCardType
        w.partnerContactPerson = partner.partnerContactPerson
        w.partnerVatHu = partner.partnerVatHu
        w.partnerVatInternational = partner.partnerVatInternational

        // Worksheet main
        w.status = WorksheetStatusMap.draft
        w.tripType = WorksheetTripTypeMap.notSet

        return w
    }

    public static newWorksheetSaveFromModPartner = (modPartner: CDAMWMWModSapPartnerSave, ownerId: string) => {
        const w = WorksheetSaveFactory.newWorksheetSave(ownerId)

        // Worksheet Partner
        w.modSapPartnerId = modPartner.id
        w.partnerSource = WorksheetSelectedDataSourceMap.modSap
        w.cardCode = undefined
        w.partnerCardName = modPartner.partnerName
        w.partnerCardType = modPartner.partnerType
        w.partnerContactPerson = undefined
        w.partnerVatHu = modPartner.vatLocal
        w.partnerVatInternational = modPartner.vatInternational

        // Worksheet main
        w.status = WorksheetStatusMap.draft
        w.tripType = WorksheetTripTypeMap.notSet

        // Add the mod partner
        w.modSapPartners = [modPartner]

        return w
    }

    public static copySalesOrderSaveRowsToServiceCallAsItemRows = (worksheetSave: CDAMWMWWorksheetSave,
                                                                   serviceCall: CDAMWMWServiceCallSave,
                                                                   isTrip: (itemRow: CDAMWMWServiceCallItemRowSave) => (boolean | undefined)): void => {
        const so = worksheetSave?.salesOrders?.find(e => true)
        if (!so) return
        WorksheetSaveFactory.copySalesOrderRowsToServiceCallAsItemRows(so, serviceCall, isTrip)
    }

    public static copySalesOrderRowsToServiceCallAsItemRows = (salesOrder: CDAMWMWWorksheetSapSalesOrderSave,
                                                               serviceCall: CDAMWMWServiceCallSave,
                                                               isTrip: (itemRow: CDAMWMWServiceCallItemRowSave) => (boolean | undefined)): void => {
        serviceCall.itemRows = salesOrder.rows
            ?.filter(sor => sor.rowType === WorksheetSapSalesOrderRowTypeMap.item) // CDAMWMWorksheetSapSalesOrderRowType
            .map(sor => {
                const ir = WorksheetSaveFactory.newServiceCallItemRowSave(serviceCall.id)

                ir.rowSelectedSource = ServiceCallItemRowSelectedSourceMap.sapSalesOrder
                ir.sourceSalesOrderRowId = sor.id
                ir.sourceDocEntry = sor.docEntry
                ir.sourceLineNum = sor.lineNum
                ir.sourceCoverImage = sor.sourceCoverImage
                ir.itemCode = sor.itemCode
                ir.itemName = sor.description
                ir.lineNum = sor.lineNum
                ir.visOrder = sor.visOrder
                ir.sourceQuantity = sor.quantity
                ir.installedQuantity = 0
                ir.unitOfQuantity = sor.unitOfQuantity
                ir.comment = undefined

                if (isTrip) {
                    ir.isTrip = isTrip(ir)
                }

                return ir
            }) ?? []
    }

    public static newWorksheetSave = (ownerId: string): CDAMWMWWorksheetSave => {
        const wid = uuid();
        const today = DateUtils.newServerUTCDate()
        return {
            id: wid,
            serialId: "-", // only the server can set this
            ownerId: ownerId,
            status: WorksheetStatusMap.draft,
            partnerSource: WorksheetSelectedDataSourceMap.notSet,
            partnerAddressSource: WorksheetSelectedDataSourceMap.notSet,
            salesPersonSource: WorksheetSelectedDataSourceMap.notSet,
            contactPersonSource: WorksheetSelectedDataSourceMap.notSet,
            tripType: WorksheetTripTypeMap.notSet,
            startedAt: today,
            endedAt: today,
            serviceCalls: [],
            salesOrders: [],
            modSapPartners: [],
            modSapPartnerContacts: [],
            modSapPartnerAddresses: [],
            modSapEquipments: []
        }
    }

    public static newServiceCallSave = (worksheetId: string | undefined): CDAMWMWServiceCallSave => {
        const scId = uuid();
        return {
            id: scId,
            worksheetId: worksheetId,
            serialId: "-",
            isEquipmentFromSapMod: false,
            status: ServiceCallStatusMap.draft,
            operatingHoursType: ServiceCallOperatingHoursTypeMap.provided,
            selectedSource: ServiceCallSelectedSourceMap.notSet,
            displayOrder: 0,
            itemRows: [],
            blobs: [],
            transcriptions: []
        }
    }

    public static newServiceCallSaveFromPartnerEquipment = (worksheetSave: CDAMWMWWorksheetSave,
                                                            pe: CDSMPartnerEquipmentPageRow,
                                                            copySalesOrderRows: boolean = true,
                                                            isTrip: (itemRow: CDAMWMWServiceCallItemRowSave) => (boolean | undefined)): CDAMWMWServiceCallSave => {
        const newServiceCall = WorksheetSaveFactory.newServiceCallSave(worksheetSave.id)
        if (copySalesOrderRows) {
            WorksheetSaveFactory.copySalesOrderSaveRowsToServiceCallAsItemRows(worksheetSave, newServiceCall, isTrip)
        }

        newServiceCall.displayOrder = 1 + (worksheetSave.serviceCalls ?? [])
            .reduce((s, c) => Math.max(s, c.displayOrder ?? 0), 0)

        return {
            ...newServiceCall,
            selectedSource: ServiceCallSelectedSourceMap.sapPartnerEquipment,
            sapEquipmentId: pe.insId,
            itemCode: pe.itemCode,
            itemName: pe.itemName,
            manufacturerSn: pe.manufacturerSn,
            internalSn: pe.internalSn,
            zipCode: pe.zipCode,
            city: pe.city,
            street: pe.street,
            streetNo: pe.streetNo,
            block: pe.block,
            building: pe.building,
            contactCode: pe.contactCod,
            manufacturer: pe.vehicleManufacturer,
            vehicleType: pe.vehicleType,
            yearOfManufacture: pe.yoM,
            coverImage: pe.coverImage
        }
    }

    public static newServiceCallSaveFromModEquipment = (worksheetSave: CDAMWMWWorksheetSave,
                                                        mod: CDAMWMWModSapEquipmentSave,
                                                        copySalesOrderRows: boolean = false,
                                                        isTrip: (itemRow: CDAMWMWServiceCallItemRowSave) => (boolean | undefined)): CDAMWMWServiceCallSave => {
        const newServiceCall = WorksheetSaveFactory.newServiceCallSave(worksheetSave.id)
        if (copySalesOrderRows) {
            WorksheetSaveFactory.copySalesOrderSaveRowsToServiceCallAsItemRows(worksheetSave, newServiceCall, isTrip)
        }

        newServiceCall.displayOrder = 1 + (worksheetSave.serviceCalls ?? [])
            .reduce((s, c) => Math.max(s, c.displayOrder ?? 0), 0)

        return {
            ...newServiceCall,
            selectedSource: ServiceCallSelectedSourceMap.manuallyAdded,
            isEquipmentFromSapMod: true,
            modSapEquipmentId: mod.id,
            sapEquipmentId: mod.sapEquipmentId,
            manufacturerSn: mod.manufacturerSn,
            internalSn: mod.internalSn,
            street: mod.street,
            streetNo: mod.streetNo,
            zipCode: mod.zipCode,
            city: mod.city,
            countryCode: mod.countryCode,
            manufacturer: mod.manufacturer,
            vehicleType: mod.vehicleType,
            yearOfManufacture: mod.yearOfManufacture,
            mode: mod.mode,
            voltage: mod.voltage,
            engineType: mod.engineType,
            engineCode: mod.engineCode,
            liftingMastType: mod.liftingMastType,
            liftingMastCode: mod.liftingMastCode,
            typeSizeFront: mod.typeSizeFront,
            typeSizeBack: mod.typeSizeBack,
            opGroupNumber: mod.opGroupNumber
        }
    }

    public static newServiceCallSaveFromEmptySubject = (worksheetSave: CDAMWMWWorksheetSave,
                                                        emptySubject: string,
                                                        copySalesOrderRows: boolean = false,
                                                        isTrip: (itemRow: CDAMWMWServiceCallItemRowSave) => (boolean | undefined)): CDAMWMWServiceCallSave => {

        const newServiceCall = WorksheetSaveFactory.newServiceCallSave(worksheetSave.id)
        if (copySalesOrderRows) {
            WorksheetSaveFactory.copySalesOrderSaveRowsToServiceCallAsItemRows(worksheetSave, newServiceCall, isTrip)
        }

        newServiceCall.displayOrder = 1 + (worksheetSave.serviceCalls ?? [])
            .reduce((s, c) => Math.max(s, c.displayOrder ?? 0), 0)

        return {
            ...newServiceCall,
            selectedSource: ServiceCallSelectedSourceMap.empty,
            emptySubject: emptySubject ?? ""
        }
    }

    public static newServiceCallBlobSave = (serviceCallId: string, blobId: string): CDAMWMWServiceCallBlobSave => {
        return {
            serviceCallId: serviceCallId,
            blobId: blobId,
            blobType: ServiceCallBlobTypeMap.notSet
        }
    }

    public static newServiceCallItemRowSave = (serviceCallId?: string | undefined): CDAMWMWServiceCallItemRowSave => {
        const rowId = uuid();
        return {
            id: rowId,
            serviceCallId: serviceCallId,
            status: ServiceCallItemRowStatusMap.default,
            warningType: ServiceCallItemRowWarningTypeMap.none,
            rowSelectedSource: ServiceCallItemRowSelectedSourceMap.notSet
        }
    }

    public static newServiceCallTranscriptionSave = (serviceCallId?: string, externalId?: string, blobId?: string): CDAMWMWServiceCallTranscriptionSave => {
        return {
            serviceCallId: serviceCallId,
            externalId: externalId,
            crowbarBlobId: blobId
        }
    }

    public static newSalesOrderSave = (docStatus: 'O' | 'C', worksheetId?: string | undefined): CDAMWMWWorksheetSapSalesOrderSave => {
        const soId = uuid();
        return {
            id: soId,
            worksheetId: worksheetId,
            docStatus: docStatus,
            rows: []
        }
    }

    public static newSalesOrderRowSave = (salesOrderId?: string | undefined): CDAMWMWWorksheetSapSalesOrderRowSave => {
        const sorId = uuid();
        return {
            id: sorId,
            worksheetSapSalesOrderId: salesOrderId
        }
    }

    public static newModSapPartnerSave = (type: CDAMWMModSapType): CDAMWMWModSapPartnerSave => {
        const id = uuid();
        return {
            id: id,
            type: type,
            status: ModSapStatusMap.suggested
        }
    }

    public static newModSapPartnerContactSave = (type: CDAMWMModSapType): CDAMWMWModSapPartnerContactSave => {
        const id = uuid();
        return {
            id: id,
            type: type,
            status: ModSapStatusMap.suggested
        }
    }

    public static newModSapPartnerAddressSave = (type: CDAMWMModSapType): CDAMWMWModSapPartnerAddressSave => {
        const id = uuid();
        return {
            id: id,
            type: type,
            status: ModSapStatusMap.suggested
        }
    }

    public static newModSapEquipmentSave = (type: CDAMWMModSapType): CDAMWMWModSapEquipmentSave => {
        const id = uuid();
        return {
            id: id,
            type: type,
            status: ModSapStatusMap.suggested
        }
    }
}