import {useQuery} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {MechanicDashboardApi} from "crowbar-api";
import AxiosCancelTokenHelper from "crowbar-api/AxiosCancelTokenHelper";

export const useMechanicDashboardWorksheets = (ownerIds: string[]) => {
    return useQuery(['dashboard-mechanic-worksheets-1', ownerIds], async (context) => {
        const response = await crowbarApiFactory(MechanicDashboardApi)
            .findWorksheetsFor(ownerIds, AxiosCancelTokenHelper.cancelTokenFrom(context.signal))
        return response.data ?? []
    }, {
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        cacheTime: Math.pow(10, 7),
        staleTime: Math.pow(10, 7),
        retry: 3
    })
}