import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {ServiceCallBlobApi} from "crowbar-api";
import ServiceCallBlobTypeMap from "crowbar-api/enum-map/ServiceCallBlobTypeMap";
import {useQuery} from "@tanstack/react-query";
import AxiosCancelTokenHelper from "crowbar-api/AxiosCancelTokenHelper";
import {UseQueryOptionParams} from "crowbar-api/util/UseQueryOptionParams";

export const useSapEquipmentImageBlobsByEquipmentIdOrModId = (
    equipmentId?: number | null | undefined,
    modEquipmentId?: string | null | undefined,
    extraOptions?: UseQueryOptionParams
) => {
    return useQuery(['sap-equipment-image-blobs-by-eqId-and-modId', equipmentId, modEquipmentId], async (context) => {
        if (!equipmentId && !modEquipmentId) return []
        const response = await crowbarApiFactory(ServiceCallBlobApi).findBlobsForSapEquipmentByInsIdOrModId(
            ServiceCallBlobTypeMap.photoRule, equipmentId ?? undefined, modEquipmentId ?? undefined, AxiosCancelTokenHelper.cancelTokenFrom(context.signal)
        )

        if (response.status !== 200) {
            throw new Error(`Failed to load images for  equipmentId:${equipmentId}, modEquipmentId:${modEquipmentId}`)
        }

        return response.data ?? []
    }, {
        networkMode: 'online', // will not fire unless we have network
        placeholderData: [],
        refetchOnMount: false,
        cacheTime: Math.pow(10, 7),
        staleTime: Math.pow(10, 7),
        ...extraOptions
    })
}