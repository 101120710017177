import React from 'react';
import {Grid} from "@mui/material";
import {
    CDAMWMServiceCallEvalModel,
    CDAMWMServiceCallModel,
    CDAMWMWorksheetEvalModel,
    CDAMWMWWorksheetQuery
} from "crowbar-api";
import SapDelivery from "modules/admin/worksheet/evaluation/tabs/service-calls/sap-documents/SapDelivery";
import SapServiceCall from "modules/admin/worksheet/evaluation/tabs/service-calls/sap-documents/SapServiceCall";
import {
    useSapDeliveryDraftExistsLoopUntilFoundByDraftDocEntry,
    useSapDeliveryExistsLoopUntilFoundByDeliveryDocEntry,
    useSapDeliveryExistsLoopUntilFoundByDraftDocEntryOrDeliveryDocEntry
} from "crowbar-api/hooks/sap/useSapDeliveryExistsOrDraft";

export type SapDocumentsProps = {
    wEval: CDAMWMWorksheetEvalModel | null | undefined
    worksheet: CDAMWMWWorksheetQuery | null | undefined
    sc: CDAMWMServiceCallModel
    scEval: CDAMWMServiceCallEvalModel
    disabled?: boolean
    onMasterDataChange: () => Promise<void>
}

const SapDocuments = ({
                          wEval,
                          worksheet,
                          sc,
                          scEval,
                          disabled,
                          onMasterDataChange
                      }: SapDocumentsProps) => {

    const draftLoopQuery = useSapDeliveryDraftExistsLoopUntilFoundByDraftDocEntry({
        deliveryDraftDocEntry: scEval.deliveryDraftDocEntry ?? undefined,
        deliveryDocEntry: scEval.selectedDeliveryDocEntry ?? undefined
    }, {
        timeout: 30000,
        waitsInMilliseconds: 1000
    }, {
        refetchOnWindowFocus: true
    })

    const deliveryLoopQueryByDraft = useSapDeliveryExistsLoopUntilFoundByDraftDocEntryOrDeliveryDocEntry({
        deliveryDraftDocEntry: scEval.deliveryDraftDocEntry ?? undefined,
        deliveryDocEntry: scEval.selectedDeliveryDocEntry ?? undefined
    }, {
        timeout: 30000,
        waitsInMilliseconds: 1000
    }, {
        refetchOnWindowFocus: true
    })

    const deliveryLoopQueryByDocEntry = useSapDeliveryExistsLoopUntilFoundByDeliveryDocEntry({
        deliveryDocEntry: scEval.selectedDeliveryDocEntry ?? undefined
    }, {
        timeout: 15000,
        waitsInMilliseconds: 1000
    }, {
        refetchOnWindowFocus: true
    })

    const draftsQueryIsLoading = draftLoopQuery.isLoading || draftLoopQuery.isFetching
    const deliveryQueryIsLoading = deliveryLoopQueryByDraft.isLoading || deliveryLoopQueryByDraft.isFetching ||
        deliveryLoopQueryByDocEntry.isLoading || deliveryLoopQueryByDocEntry.isFetching

    const deliveryDraftDocKeys = draftLoopQuery?.data ?? deliveryLoopQueryByDraft?.data
    const deliveryDocKeys = scEval?.manuallySetDeliveryDocEntry
        ? deliveryLoopQueryByDocEntry?.data
        : deliveryLoopQueryByDraft?.data ?? deliveryLoopQueryByDocEntry?.data

//console.log('deliveryDraftDocKeys', deliveryDraftDocKeys, 'deliveryDocKeys', deliveryDocKeys)
    const onDataChangeForDelivery = async () => {
        draftLoopQuery.refetch().then()
        deliveryLoopQueryByDraft.refetch().then()
        deliveryLoopQueryByDocEntry.refetch().then();
        onMasterDataChange().then()
    }

    const onDataChange = async () => {
        onMasterDataChange().then()
    }

    return (
        <Grid container spacing={1} alignItems="stretch">

            <Grid item xs={6}>
                <SapDelivery
                    wEval={wEval}
                    worksheet={worksheet}
                    sc={sc}
                    scEval={scEval}
                    disabled={disabled}
                    onMasterDataChange={onDataChangeForDelivery}
                    deliveryDraftDocKeys={deliveryDraftDocKeys}
                    deliveryDocKeys={deliveryDocKeys}
                    draftsQueryIsLoading={draftsQueryIsLoading}
                    deliveryQueryIsLoading={deliveryQueryIsLoading}
                />
            </Grid>

            <Grid item xs={6}>
                <SapServiceCall
                    wEval={wEval}
                    worksheet={worksheet}
                    sc={sc}
                    scEval={scEval}
                    disabled={disabled}
                    onMasterDataChange={onDataChange}
                    deliveryDocKeys={deliveryDocKeys}
                />
            </Grid>

        </Grid>
    )
}

export default SapDocuments;