import {useMutation} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {PhotoRuleApi} from "crowbar-api";

export interface UsePhotoRuleChangeTitleMutateArgs {
    ruleId: string,
    newTitle: string
}

const usePhotoRuleChangeTitle = () => {
    return useMutation(async (args: UsePhotoRuleChangeTitleMutateArgs) => {
        const response = await crowbarApiFactory(PhotoRuleApi).changeTitle(args.ruleId, args.newTitle)
        if (response.status !== 200) {
            throw new Error('Error during photo rule title save.')
        }
    })
}

export default usePhotoRuleChangeTitle