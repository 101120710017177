import React from 'react';
import {
    CDAMWMServiceCallEvalModel,
    CDAMWMServiceCallModel,
    CDAMWMWWorksheetQuery,
    CDSMSapPartnerAddress
} from "crowbar-api";
import {CircularProgress, Grid, MenuItem, Select, SelectChangeEvent, Stack, Typography} from "@mui/material";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {
    useServiceCallEvalUpdateShippingSapAddressAndType
} from "crowbar-api/hooks/worksheet/evaluation/service-call/useServiceCallEvalUpdateShippingSapAddressAndType";
import useSapPartnerAddressesByCardCode from "crowbar-api/hooks/sap/useSapPartnerAddressesByCardCode";
import {byPropertiesOf} from "shared/utils/ArraySort";
import {
    useServiceCalEvalUpdateBillingSapAddressAndType
} from "crowbar-api/hooks/worksheet/evaluation/service-call/useServiceCalEvalUpdateBillingSapAddressAndType";
import SapPartnerAddressUtils from "crowbar-api/util/SapPartnerAddressUtils";
import RefreshButton from "shared/components/buttons/RefreshButton";

export type AddressSelectProps = {
    addressType: 'S' | 'B'
    label: string
    required?: boolean

    worksheet: CDAMWMWWorksheetQuery | null | undefined
    sc: CDAMWMServiceCallModel
    scEval: CDAMWMServiceCallEvalModel
    disabled?: boolean
    onMasterDataChange: () => Promise<void>
}
const AddressSelect = ({
                           addressType,
                           label,
                           required,
                           worksheet,
                           sc,
                           scEval,
                           disabled,
                           onMasterDataChange
                       }: AddressSelectProps) => {
    const tk = useTranslateFunction("Admin.Worksheet")

    const addressesQuery = useSapPartnerAddressesByCardCode(worksheet?.cardCode)
    const addresses = (addressesQuery.data ?? [])
        .filter(a => a.addressType === addressType)
        .sort(byPropertiesOf<CDSMSapPartnerAddress>(['addressName', 'city']))

    const updateShippingSapAddressAndType = useServiceCallEvalUpdateShippingSapAddressAndType()
    const updateBillingSapAddressAndType = useServiceCalEvalUpdateBillingSapAddressAndType()

    const rawValue = addressType === "S" ? scEval.shippingSapAddressAndType ?? "" : scEval.billingSapAddressAndType ?? ""
    const value = addresses.find(a => SapPartnerAddressUtils.addressKeyFor(null, a.addressType, a.addressName) === rawValue)
        ? rawValue
        : ""

    const onChange = async (event: SelectChangeEvent<string>) => {
        const newValue = event.target.value;
        if (addressType === 'S') {
            await updateShippingSapAddressAndType.mutateAsync({
                evalId: scEval.id,
                originalId: scEval.serviceCallId,
                field: newValue
            })
        } else {
            await updateBillingSapAddressAndType.mutateAsync({
                evalId: scEval.id,
                originalId: scEval.serviceCallId,
                field: newValue
            })
        }

        await onMasterDataChange()
    }

    return (
        <Stack direction="column" spacing={1}>
            <Typography>{label}{required && " *"}</Typography>

            {addressesQuery.isLoading ? (
                <CircularProgress/>
            ) : (
                <Grid container spacing={0} alignItems="center">
                    <Grid item xs={true}>
                        <Select
                            size="small"
                            fullWidth
                            disabled={disabled}
                            value={value}
                            required={required}
                            onChange={onChange}
                            error={required && !value}
                        >
                            {!required && (
                                <MenuItem key="" value="">&nbsp;</MenuItem>
                            )}
                            {addresses.map(a => {
                                const key = SapPartnerAddressUtils.addressKeyFor(null, a.addressType, a.addressName)

                                return (
                                    <MenuItem key={key} value={key}>{SapPartnerAddressUtils.addressFormat(a)}</MenuItem>
                                )
                            })}
                        </Select>
                    </Grid>
                    <Grid item xs="auto">
                        <RefreshButton
                            isLoading={addressesQuery.isFetching}
                            onClick={() => addressesQuery.refetch().then()}
                            /*iconProps={{
                                fontSize: "large",
                            }}*/
                        />
                    </Grid>
                </Grid>
            )}
        </Stack>
    )
}

export default AddressSelect;