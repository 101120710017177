import React from 'react';
import {WorksheetListFilters} from "modules/admin/worksheet/worksheet-list/filters/WorksheetListFilters";
import {MechanicListingState} from "modules/worksheet/mechanic/state/useMechanicListingState";
import {DateUtils} from "shared/utils/DateUtils";
import {Container, Grid, Stack, Typography} from "@mui/material";
import useTranslateFunction from "shared/language/useTranslateFunction";
import MechanicWorksheetOrderBy from "modules/worksheet/mechanic/order/MechanicWorksheetOrderBy";
import {WorksheetListResults} from "modules/admin/worksheet/worksheet-list/results/WorksheetListResults";
import {WorksheetListRefreshButton} from "modules/admin/worksheet/worksheet-list/WorksheetListRefreshButton";
import {atomWithStorage} from "jotai/utils";

const defaultMechanicListingState: MechanicListingState = {
    mode: 'worksheet',
    searchValue: "",
    pageLimit: 10,
    selectedOrderByField: 'OrderBySerialId',
    orderBy: 'DESC',
    worksheetSearch: {
        ownerIds: undefined, // undefined until user changes the filter
        includeDraft: false,
        includeFinal: false,
        includeFinalSignedOrSkipped: true,
        includeEvaluated: false,
        limit: 10,
        showDeleted: false,
        worksheetFrom: DateUtils.newServerUTCDateAndAdd({month: -99}),
        worksheetTo: DateUtils.newServerUTCDateAndAdd({month: 1})
    },
    subTablePageSize: 5,
    showDeletedWorksheets: false
}

const mechanicAtom = atomWithStorage<MechanicListingState>("UiState-Admin-WorksheetList", defaultMechanicListingState)

export const WorksheetList = () => {
    const tk = useTranslateFunction(`Admin.Worksheet`)
    return (
        <Container>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Stack direction="row" spacing={1} justifyContent="space-between">
                        <Typography variant="h4">
                            {tk('WorksheetAdmin')}
                        </Typography>
                        <WorksheetListRefreshButton mechanicStateAtom={mechanicAtom}/>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <WorksheetListFilters stateAtom={mechanicAtom}/>
                </Grid>
                <Grid item xs={12}>
                    <MechanicWorksheetOrderBy mechanicStateAtom={mechanicAtom}/>
                </Grid>
                <Grid item xs={12}>
                    <WorksheetListResults mechanicStateAtom={mechanicAtom}/>
                </Grid>
            </Grid>
        </Container>
    )
}