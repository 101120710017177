import {CDAMNMEmailNotificationSearch} from "crowbar-api"
import {MRT_ColumnDef} from "material-react-table";
import {EmailNotificationProps} from "modules/admin/worksheet/notifications/email-notifications/EmailNotificationProps";
import {
    useEmailNotificationListingState
} from "modules/admin/worksheet/notifications/email-notifications/state/useEmailNotificationListingState";
import {useMaterialReactTableLocalization} from "i18n/material-table/useMaterialReactTableLocalization";
import {useMemo} from "react";
import useTranslateFunction from "shared/language/useTranslateFunction";
import DateDisplay from "shared/components/date-display/DateDisplay";
import EmailNotificationStatusMap from "crowbar-api/enum-map/EmailNotificationStatusMap";
import {useTranslation} from "react-i18next";
import {GraphEmailAddressParser} from "crowbar-api/util/GraphEmailAddressParser";
import {
    MaterialReactTableLastRowNumPaging
} from "shared/components/material-react-table/MaterialReactTableLastRowNumPaging";
import {EmailNotificationSourceMap} from "crowbar-api/enum-map/EmailNotificationSourceMap";
import {
    EmailNotificationResendButton
} from "modules/admin/worksheet/notifications/email-notifications/results/EmailNotificationResendButton";

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {Stack, Typography} from "@mui/material";
import {green, yellow} from "@mui/material/colors";

export type EmailNotificationsResultEntryRowProps = EmailNotificationProps & {
    onStateChanged: () => void
}
export const EmailNotificationsResultTable = ({stateAtom, onStateChanged}: EmailNotificationsResultEntryRowProps) => {
    const {t} = useTranslation()
    const tk = useTranslateFunction(`Admin.Worksheet`)
    const {notificationQuery, setState, lastRowNumber, limit} = useEmailNotificationListingState(stateAtom)

    const mrt_locale = useMaterialReactTableLocalization()

    const entries = notificationQuery.data ?? []

    const columns = useMemo<MRT_ColumnDef<CDAMNMEmailNotificationSearch>[]>(() => [
        {
            accessorFn: (row) => (
                <span>
                    {t(EmailNotificationSourceMap.translationKey(row.source))}
                </span>
            ),
            header: tk('Source'),
            size: 70
        },
        {
            accessorFn: (row) => (
                <Stack direction="row" spacing={1} alignItems="center">
                    {row.status === EmailNotificationStatusMap.failure && (
                        <div title={`${row.errorMessage}  ${row.errorTrace}`}>
                            <HelpOutlineIcon color="error"/>
                        </div>
                    )}
                    <Typography
                        variant="body2"
                        color={
                            row.status === EmailNotificationStatusMap.failure ? "error" :
                                row.status === EmailNotificationStatusMap.skipped ? yellow[700] :
                                    green[700]
                        }
                    >
                        {t(EmailNotificationStatusMap.translationKey(row.status))}
                    </Typography>
                </Stack>
            ),
            header: tk('Status'),
            size: 120,
        },
        {
            accessorKey: "serialId",
            header: tk("Worksheet"),
            size: 95
        },
        {
            accessorFn: (row) => (
                <DateDisplay dateStr={row.createdAt ?? ""}/>
            ),
            header: tk('EmailCreatedAt'),
            size: 120
        },
        {
            accessorKey: "cardCode",
            header: tk('Customer'),
            size: 75
        },
        {
            accessorKey: "partnerCardName",
            header: tk('Name'),
            size: 100
        },
        {
            accessorFn: (row) => (
                <span>{GraphEmailAddressParser.parseAddresses(row.recipient)}</span>
            ),
            header: "E-mail",
            size: 200
        },
        {
            accessorFn: (row) => (
                <EmailNotificationResendButton
                    row={row}
                    onUpdated={onStateChanged}
                />
            ),
            header: tk('Resend')
        }

    ], [onStateChanged, t, tk])

    const onLastRowNumPaginationChange = (newLastRowNumber: number, newLimit: number) => {
        setState(prev => {
            return {
                ...prev,
                lastRowNumber: newLastRowNumber,
                limit: newLimit
            }
        })
    }

    return (
        <MaterialReactTableLastRowNumPaging
            lastRowNumber={lastRowNumber ?? 0}
            limit={limit ?? 10}
            data={entries}
            onLastRowNumPaginationChange={onLastRowNumPaginationChange}

            columns={columns}
            localization={mrt_locale}
            initialState={{
                density: 'compact',
            }}
            rowNumberMode="original" //default
            enableGlobalFilter={false} //disable search feature
            enableColumnResizing={true}
            enableColumnFilters={false}
            enableColumnActions={false}
            enableSorting={false}
            enableFullScreenToggle={false}
            positionToolbarAlertBanner="bottom"
        />
    )
}