import {app} from "@microsoft/teams-js";

export interface ITeamsContext {
    language: 'hu' | 'en'
    theme: 'default' | 'dark'
    userObjectId: string | undefined
    loginHint: string | undefined
    userPrincipalName: string | undefined
    context: app.Context | undefined
}

export default class TeamsContext {
    public async get(): Promise<ITeamsContext> {
        const context = await app.getContext()
        const language = context.app.locale === 'hu-hu' ? 'hu' : 'en';
        const theme = context.app.theme === 'dark' ? 'dark' : 'default';

        return {
            language: language,
            theme: theme,
            // userObjectId: context.app.userObjectId,
            userObjectId: context.user?.id,
            loginHint: context.user?.loginHint,
            userPrincipalName: context.user?.userPrincipalName,
            context: context
        }
    }
}