import {useMutation} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {CDAMPMPhotoRuleStepTemplateModel, PhotoRuleStepTemplateApi} from "crowbar-api";


const usePhotoRuleTemplateSave = () => {
    return useMutation(async (template: CDAMPMPhotoRuleStepTemplateModel) => {
        const response = await crowbarApiFactory(PhotoRuleStepTemplateApi).save(template)
        if (response.status !== 200) {
            throw new Error('Could not save template.')
        }
    })
}

export default usePhotoRuleTemplateSave