import {useQuery} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {UserProfileApi} from "crowbar-api";

export const useFindAllUsers = () => {
    return useQuery(['user-find-all-users'], async (context) => {
        const response = await crowbarApiFactory(UserProfileApi)
            .findAllUsers()
        return response.data
    }, {
        networkMode: 'online', // will not fire unless we have network
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        cacheTime: Math.pow(10, 7),
        staleTime: Math.pow(10, 7),
    })
}