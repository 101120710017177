import axios, {CancelToken} from "axios";

export default class AxiosCancelTokenHelper {
    public static cancelTokenFrom = (signal: AbortSignal | undefined): { cancelToken: CancelToken } => {
        const source = axios.CancelToken.source()
        // Cancel the request if React Query signals to abort
        signal?.addEventListener('abort', () => {
            source.cancel('Query cancelled by React query.')
        })
        return {cancelToken: source.token}
    }
}

