import React from 'react';
import {BackButton} from "shared/components/buttons/BackButton";
import {Grid, IconButton, Stack, Typography} from "@mui/material";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {useNavigate} from "react-router-dom";

import CloseIcon from '@mui/icons-material/Close';
import {
    CDAMWMWWorksheetSave,
    CDAMWMWWorksheetSignatureUpdate,
    CDAMWMWWorksheetStatusAndSignatureQuery
} from "crowbar-api";
import useWorksheetSignatureState from "modules/worksheet/signature/useWorksheetSignatureState";
import {useMechanicWorksheetsUrl} from "modules/worksheet/editor/shared/data/useMechanicWorksheetsUrl";

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import {WorksheetPdfRender} from "modules/worksheet/signature/WorksheetPdfRender";
import {WorksheetSignatureMenu} from "modules/worksheet/signature/signature-box/WorksheetSignatureMenu";
import {useNewAtomWithRef} from "shared/hook/useNewatomWithRef";
import {SignatureState} from "modules/worksheet/signature/signature-box/SignatureState";
import SentryErrorBoundary from "shared/components/error/SentryErrorBoundary";

export type WorksheetSignatureProps = {
    onCompleteReturnUrl?: string
    onBackReturnUrl?: string

    worksheetStatuses?: CDAMWMWWorksheetStatusAndSignatureQuery[]

    currentWorksheet: CDAMWMWWorksheetSave

    onSignatureUpdated?: (updates: CDAMWMWWorksheetSignatureUpdate[]) => void
}
const WorksheetSignature = ({
                                onCompleteReturnUrl,
                                onBackReturnUrl,
                                worksheetStatuses,
                                currentWorksheet,
                                onSignatureUpdated
                            }: WorksheetSignatureProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    const ws = currentWorksheet

    const navigate = useNavigate()

    const mechanicWorksheetsUrl = useMechanicWorksheetsUrl()

    const {currentServiceCallIndex, setState} = useWorksheetSignatureState()

    const worksheetStatusesOrCurrentWorksheet = worksheetStatuses ?? [{
        id: currentWorksheet.id,
        serialId: currentWorksheet.serialId,
        version: currentWorksheet.version,
        previousVersion: currentWorksheet.previousVersion,
        ownerId: currentWorksheet.ownerId,
        startedAt: currentWorksheet.startedAt,
        endedAt: currentWorksheet.endedAt,
        status: currentWorksheet.status,
        signatureBase64: currentWorksheet.signatureBase64,
        signatureName: currentWorksheet.signatureName
    }]

    const firstStatus = worksheetStatusesOrCurrentWorksheet.find(() => true)

    const [, , signatureStateAtom] = useNewAtomWithRef<SignatureState>({
        withSignature: true,
        dialogOpen: false,
        signName: firstStatus?.signatureName ?? undefined,
        dataUrl: firstStatus?.signatureBase64 ?? undefined
    })

    const onCloseClick = () => {
        navigate(mechanicWorksheetsUrl)
    }

    const onBackClick = () => {
        navigate(onBackReturnUrl ?? mechanicWorksheetsUrl)
    }

    if (!ws) {
        return (
            <Typography>
                Error: Missing worksheet data.
            </Typography>
        )
    }

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12}>

                    <Stack direction="row" sx={{
                        display: "flex",
                        justifyContent: "space-between"
                    }}>
                        <Typography variant="h4" fontWeight="bold">
                            <BackButton useOnClick={true} onClick={onBackClick}/>
                            &nbsp;
                            {ws?.serialId ?? tk('Worksheet')}
                        </Typography>

                        <IconButton onClick={onCloseClick}>
                            <CloseIcon/>
                        </IconButton>
                    </Stack>
                </Grid>

                <Grid item xs={12} justifyContent="center">
                    <SentryErrorBoundary>
                        {currentWorksheet?.id && (
                            <WorksheetPdfRender worksheetId={currentWorksheet.id} layout="customer"/>
                        )}
                    </SentryErrorBoundary>
                </Grid>

            </Grid>

            <WorksheetSignatureMenu
                worksheetStatuses={worksheetStatusesOrCurrentWorksheet}
                onCompleteReturnUrl={onCompleteReturnUrl}
                signatureStateAtom={signatureStateAtom}
                onSignatureUpdated={onSignatureUpdated}
            />
        </>
    );
};

export default WorksheetSignature;