import React, {useState} from 'react';
import {Button, Grid} from "@mui/material";
import CostRateSelect from "modules/admin/worksheet/evaluation/tabs/service-calls/catalogue/CostRateSelect";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {CDAMWMServiceCallItemRowEvalModel, CDSMSapCostRate} from 'crowbar-api';
import {
    useServiceCallItemRowEvalUpdateOcrCode
} from "crowbar-api/hooks/worksheet/evaluation/service-call-item-row/useServiceCallItemRowEvalUpdateOcrCode";

export type CostRatesProps = {
    rowEvals: CDAMWMServiceCallItemRowEvalModel[]
    onDataChange: () => Promise<void>
    disabled?: boolean
}
const CostRates = ({rowEvals, onDataChange, disabled}: CostRatesProps) => {
    const tk = useTranslateFunction('Admin.Worksheet')

    const [dim1Value, setDim1Value] = useState<CDSMSapCostRate | undefined>(undefined)
    const [dim2Value, setDim2Value] = useState<CDSMSapCostRate | undefined>(undefined)
    const [dim4Value, setDim4Value] = useState<CDSMSapCostRate | undefined>(undefined)

    const updateOcrCode = useServiceCallItemRowEvalUpdateOcrCode();

    const onDimClick = async (dim: number, value: string | undefined) => {
        await updateOcrCode.mutateAsync({
            itemRowsIds: rowEvals.filter(r => r.id).map(r => r.id!),
            ocrCodeValue: value,
            dim: dim
        })
        await onDataChange()
    }

    return (
        <Grid container spacing={1}>

            <Grid item xs={4}>
                <Grid container direction="row" spacing={1} sx={{
                    alignItems: "end",
                    justifyContent: "space-between"
                }}>
                    <Grid item xs={true}>
                        <CostRateSelect
                            label={tk('Dim1')}
                            sapDim={1}
                            value={dim1Value}
                            onChange={setDim1Value}
                            disabled={disabled || updateOcrCode.isLoading}
                        />
                    </Grid>
                    <Grid item xs="auto" paddingBottom={0.5}>
                        <Button
                            variant="contained"
                            onClick={() => onDimClick(1, dim1Value?.ocrCode)}
                            disabled={disabled || updateOcrCode.isLoading}
                        >
                            {tk('Overwrite')}
                        </Button>
                    </Grid>

                </Grid>
            </Grid>

            <Grid item xs={4}>
                <Grid container direction="row" spacing={1} sx={{
                    alignItems: "end",
                    justifyContent: "space-between"
                }}>
                    <Grid item xs={true}>
                        <CostRateSelect
                            label={tk('Dim2')}
                            sapDim={2}
                            value={dim2Value}
                            onChange={setDim2Value}
                            disabled={disabled || updateOcrCode.isLoading}
                        />
                    </Grid>
                    <Grid item xs="auto" paddingBottom={0.5}>
                        <Button
                            variant="contained"
                            onClick={() => onDimClick(2, dim2Value?.ocrCode)}
                            disabled={disabled || updateOcrCode.isLoading}
                        >
                            {tk('Overwrite')}
                        </Button>
                    </Grid>

                </Grid>
            </Grid>

            <Grid item xs={4}>
                <Grid container direction="row" spacing={1} sx={{
                    alignItems: "end",
                    justifyContent: "space-between"
                }}>
                    <Grid item xs={true}>
                        <CostRateSelect
                            label={tk('Dim4')}
                            sapDim={4}
                            value={dim4Value}
                            onChange={setDim4Value}
                            disabled={disabled || updateOcrCode.isLoading}
                        />
                    </Grid>
                    <Grid item xs="auto" paddingBottom={0.5}>
                        <Button
                            variant="contained"
                            onClick={() => onDimClick(4, dim4Value?.ocrCode)}
                            disabled={disabled || updateOcrCode.isLoading}
                        >
                            {tk('Overwrite')}
                        </Button>
                    </Grid>

                </Grid>
            </Grid>

        </Grid>
    );
};

export default CostRates;