import {CDAMWMServiceCallItemRowStatus, CDAMWMWServiceCallItemRowSave} from "crowbar-api";
import {useServiceCallItemRowAtom} from "modules/worksheet/editor/state/atoms/useServiceCallItemRowAtom";
import ServiceCallItemRowStatusMap from "crowbar-api/enum-map/ServiceCallItemRowStatusMap";
import {DateUtils} from "shared/utils/DateUtils";
import useIsTimeMatch from "crowbar-api/hooks/worksheet/validations/useIsTimeMatch";
import {toastError} from "shared/toast/DefaultToasts";
import useTranslateFunction from "shared/language/useTranslateFunction";
import useCurrentServiceCallTimeDistributedRowsFor
    from "modules/worksheet/editor/ui/catalogue/useCurrentServiceCallTimeDistributedRowsFor";
import useWorksheetSignedState from "modules/worksheet/editor/state/helpers/useWorksheetSignedState";
import CheckIconButton from "shared/components/buttons/CheckIconButton";


interface WEditorPageCatalogueItemRowCheckButtonProps {
    itemRow: CDAMWMWServiceCallItemRowSave
}

const WEditorPageCatalogueItemRowCheckButton = ({
                                                    itemRow
                                                }: WEditorPageCatalogueItemRowCheckButtonProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    const [, setItemRow] = useServiceCallItemRowAtom(itemRow.serviceCallId, itemRow.id)

    const {editDisabled} = useWorksheetSignedState()

    const isTimeMatch = useIsTimeMatch(itemRow)
    const findMatchingTimeRows = useCurrentServiceCallTimeDistributedRowsFor()

    const buttonClick = (action: 'cancel' | 'install') => {

        if (editDisabled) {
            return;
        }

        const timeRows = findMatchingTimeRows(itemRow.itemCode)

        // Check if the mechanic is selected
        // or not in case of a distributed time row
        if (isTimeMatch /* is time match row (hook) */ &&
            !itemRow.timeUserId /* time user id is not filled */ &&
            (itemRow.installedQuantity ?? 0) > 0 /* and the quantity is not zero */ &&
            (timeRows.filter(
                    r => !!r.sourceTimeRowId
                ).length > 0
                /* any of the time rows with this itemCode has a sourceTimeRowId (shared)  */
            )
        ) {
            toastError(tk('MissingMechanic'))
            return
        }

        let newState: CDAMWMServiceCallItemRowStatus | undefined = undefined
        if (action === 'install') {
            if (itemRow?.status === ServiceCallItemRowStatusMap.installed) {
                newState = undefined
            } else {
                newState = ServiceCallItemRowStatusMap.installed
            }
        } else if (action === 'cancel') {
            // cancel/notInstalled state: was planned, but got discarded
            if (itemRow?.status === ServiceCallItemRowStatusMap.notInstalled) {
                newState = undefined
            } else {
                newState = ServiceCallItemRowStatusMap.notInstalled
            }
        }
        setItemRow(prev => {
            return {
                ...prev,
                status: newState,
                lastStatusChangeAt: DateUtils.newServerUTCDate()
            }
        })
    }

    return (
        <CheckIconButton
            checked={itemRow?.status === ServiceCallItemRowStatusMap.installed}
            onClick={() => buttonClick('install')}
            checkCircleIconProps={{
                sx: {
                    fontSize: "44pt"
                }
            }}
        />
    )
}

export default WEditorPageCatalogueItemRowCheckButton