import {CDAMWMWServiceCallItemRowSave} from "crowbar-api";
import {useWorksheetSaveState} from "modules/worksheet/editor/state/WorksheetSaveAtoms";
import {useCallback} from "react";
import ServiceCallItemRowSelectedSourceMap from "crowbar-api/enum-map/ServiceCallItemRowSelectedSourceMap";

export const useIsClosedSalesOrderRow = (itemRow: CDAMWMWServiceCallItemRowSave) => {
    const [ws] = useWorksheetSaveState()

    return useCallback(() => {
        if (itemRow.rowSelectedSource !== ServiceCallItemRowSelectedSourceMap.sapSalesOrder) return false

        const foundSalesOrderRow = ws?.salesOrders
            ?.find(so => so.docEntry === itemRow.sourceDocEntry)
            ?.rows
            ?.find(sor => sor.id === itemRow.sourceSalesOrderRowId)

        return foundSalesOrderRow?.lineStatus === "C"
    }, [itemRow.rowSelectedSource, itemRow.sourceDocEntry, itemRow.sourceSalesOrderRowId, ws?.salesOrders])
}