import {CDAMDMDeliveryNoteType} from "crowbar-api";

export class DeliveryNoteTypeMap {
    public notDefined = CDAMDMDeliveryNoteType.NUMBER_0;
    public sale = CDAMDMDeliveryNoteType.NUMBER_1;
    public lease = CDAMDMDeliveryNoteType.NUMBER_2;
    public repair = CDAMDMDeliveryNoteType.NUMBER_3;
    public other = CDAMDMDeliveryNoteType.NUMBER_4;

    public translationKey(preKey: string, t: CDAMDMDeliveryNoteType): string {
        let tKey = "NotDefinedText";
        if (t === this.sale) {
            tKey = "SaleText";
        }
        if (t === this.lease) {
            tKey = "LeaseText";
        }
        if (t === this.repair) {
            tKey = "RepairText";
        }
        if (t === this.other) {
            tKey = "OtherText";
        }
        return preKey + tKey;
    }

    public mapTo(s: string): CDAMDMDeliveryNoteType {
        if (this.sale.toString() === s) {
            return this.sale;
        }
        if (this.lease.toString() === s) {
            return this.lease;
        }
        if (this.repair.toString() === s) {
            return this.repair;
        }
        if (this.other.toString() === s) {
            return this.other;
        }
        return this.notDefined
    }
}