import {useTranslation} from "react-i18next";
import {MRT_Localization} from "material-react-table";
import {MRT_Localization_HU} from "i18n/material-table/hu";
import {MRT_Localization_EN} from "material-react-table/locales/en";

/**
 * Maps the i18next language to material react table locale
 */
export const useMaterialReactTableLocalization = (): MRT_Localization => {
    const {i18n} = useTranslation()
    return i18n.language === 'hu' ? MRT_Localization_HU : MRT_Localization_EN
}