import useAllPhotoRulesAndManufacturers from "crowbar-api/hooks/photo-rule/useAllPhotoRulesAndManufacturers";
import {
    useSapEquipmentImageBlobsByEquipmentIdOrModId
} from "crowbar-api/hooks/worksheet/blobs/useSapEquipmentImageBlobsByEquipmentIdOrModId";
import {CDAMPMPhotoRuleAndManufacturersQuery, CDAMWMESapEquipmentServiceCallBlobQuery} from "crowbar-api";
import {useMemo} from "react";
import {UseQueryResult} from "@tanstack/react-query";
import {useSelectedServiceCall} from "modules/worksheet/editor/shared/data/useSelectedServiceCall";
import {byPropertiesOf} from "shared/utils/ArraySort";
import useServiceCallBlobsByScId from "crowbar-api/hooks/worksheet/blobs/useServiceCallBlobsByScId";
import ServiceCallBlobTypeMap from "crowbar-api/enum-map/ServiceCallBlobTypeMap";

export interface PhotoBlobGroup {
    rule: CDAMPMPhotoRuleAndManufacturersQuery
    blobs: CDAMWMESapEquipmentServiceCallBlobQuery[]
}

export interface UsePhotoBlobsResult {
    ungroupedBlobs: CDAMWMESapEquipmentServiceCallBlobQuery[]
    groupedBlobs: PhotoBlobGroup[]

    isLoading: boolean

    photoRulesAndManufacturersQuery: UseQueryResult<CDAMPMPhotoRuleAndManufacturersQuery[], unknown>
    serviceCallImageBlobsQuery: UseQueryResult<CDAMWMESapEquipmentServiceCallBlobQuery[], unknown>
    sapEquipmentImageBlobsQuery: UseQueryResult<CDAMWMESapEquipmentServiceCallBlobQuery[], unknown>

    refetchImages: () => Promise<void>
}

const usePhotoBlobs = (): UsePhotoBlobsResult => {

    const selectedServiceCall = useSelectedServiceCall()
    const serviceCallId = selectedServiceCall?.id
    const sapEquipmentId = selectedServiceCall?.sapEquipmentId
    const modSapEquipmentId = selectedServiceCall?.modSapEquipmentId

    const photoRulesAndManufacturersQuery = useAllPhotoRulesAndManufacturers()
    // find images directly linked to this service call
    const serviceCallImageBlobsQuery = useServiceCallBlobsByScId(ServiceCallBlobTypeMap.photoRule, serviceCallId)
    // find images by the Sap equipment id or the Mod eq. id (searching in all the service call images - even deleted service calls.)
    const sapEquipmentImageBlobsQuery = useSapEquipmentImageBlobsByEquipmentIdOrModId(sapEquipmentId, modSapEquipmentId)

    const groups = useMemo(() => {
        const ungroupedBlobs: CDAMWMESapEquipmentServiceCallBlobQuery[] = []
        const groupedBlobs: PhotoBlobGroup[] = []

        const serviceCallImageBlobs = serviceCallImageBlobsQuery.data ?? []
        const sapEquipmentImageBlobs = sapEquipmentImageBlobsQuery.data ?? []

        // combine the results, only keeping unique results (based on id)
        const combinedBlobs = [
            // all the directly linked blobs
            ...serviceCallImageBlobs,
            // and only the blobs from other serviceCalls not already in the serviceCallImageBlobs  
            ...sapEquipmentImageBlobs
                .filter(eqImg => serviceCallImageBlobs
                    .find(scImg => scImg.blobId === eqImg.blobId && scImg.serviceCallId === eqImg.serviceCallId) === undefined
                )
        ]

        for (let imageBlob of combinedBlobs) {
            const matchingRule = photoRulesAndManufacturersQuery.data?.find(pr => pr.id === imageBlob.photoRuleId)
            if (matchingRule) {
                const foundGroupEntry = groupedBlobs.find(g => g.rule?.id === matchingRule.id)
                if (foundGroupEntry) {
                    foundGroupEntry.blobs.push(imageBlob)
                } else {
                    groupedBlobs.push({
                        rule: matchingRule,
                        blobs: [imageBlob]
                    })
                }
            } else {
                ungroupedBlobs.push(imageBlob)
            }
        }

        // add all the serviceCallImageBlobs images not yet processed
        // to the ungrouped group
        /*    for (const serviceCallImageBlob of serviceCallImageBlobs) {
                if (sapEquipmentImageBlobs.find(sb => sb.blobId === serviceCallImageBlob.blobId) === undefined) {
                    ungroupedBlobs.push(serviceCallImageBlob)
                }
            }*/

        // Sort and return
        const sortedUngroupedBlobs = ungroupedBlobs
            .sort(byPropertiesOf<CDAMWMESapEquipmentServiceCallBlobQuery>(['orderNum', 'subOrderNum', 'createdAt', 'updatedAt']))

        const sortedGroupedBlobs: PhotoBlobGroup[] = []
        for (const groupedBlob of groupedBlobs) {
            sortedGroupedBlobs.push({
                ...groupedBlob,
                blobs: groupedBlob.blobs.sort(byPropertiesOf<CDAMWMESapEquipmentServiceCallBlobQuery>(['orderNum', 'subOrderNum', 'createdAt', 'updatedAt']))
            })
        }

        return {
            ungroupedBlobs: sortedUngroupedBlobs,
            groupedBlobs: sortedGroupedBlobs
        }
    }, [photoRulesAndManufacturersQuery.data, sapEquipmentImageBlobsQuery.data, serviceCallImageBlobsQuery.data])

    const refetch = async () => {
        await Promise.allSettled([
            serviceCallImageBlobsQuery.refetch(),
            sapEquipmentImageBlobsQuery.refetch()
        ])
    }

    return {
        ...groups,
        isLoading: photoRulesAndManufacturersQuery.isLoading || photoRulesAndManufacturersQuery.isFetching ||
            sapEquipmentImageBlobsQuery.isLoading || sapEquipmentImageBlobsQuery.isFetching,
        photoRulesAndManufacturersQuery: photoRulesAndManufacturersQuery,
        serviceCallImageBlobsQuery: serviceCallImageBlobsQuery,
        sapEquipmentImageBlobsQuery: sapEquipmentImageBlobsQuery,
        refetchImages: refetch
    }
}

export default usePhotoBlobs