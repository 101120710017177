import {
    CDAMWMWModSapEquipmentSave,
    CDAMWMWModSapPartnerAddressSave,
    CDAMWMWModSapPartnerContactSave,
    CDAMWMWModSapPartnerSave,
    CDAMWMWServiceCallBlobSave,
    CDAMWMWServiceCallItemRowSave,
    CDAMWMWServiceCallSave,
    CDAMWMWServiceCallTranscriptionSave,
    CDAMWMWWorksheetSapSalesOrderRowSave,
    CDAMWMWWorksheetSapSalesOrderSave,
    CDAMWMWWorksheetSave
} from "crowbar-api";
import {byPropertiesOf} from "shared/utils/ArraySort";
import {equals} from "shared/utils/ObjectEquals";
import {ObjectUtils} from "shared/utils/ObjectUtils";

const skipFields = ['createdAt', 'createdBy', 'updatedAt', 'updatedBy', 'lastStatusChangeAt']

const sort = (w: CDAMWMWWorksheetSave): void => {
    w.serviceCalls = w.serviceCalls?.sort(byPropertiesOf<CDAMWMWServiceCallSave>(['id'])) ?? []
    w.salesOrders = w.salesOrders?.sort(byPropertiesOf<CDAMWMWWorksheetSapSalesOrderSave>(['id'])) ?? []
    w.modSapPartners = w.modSapPartners?.sort(byPropertiesOf<CDAMWMWModSapPartnerSave>(['id'])) ?? []
    w.modSapPartnerContacts = w.modSapPartnerContacts?.sort(byPropertiesOf<CDAMWMWModSapPartnerContactSave>(['id'])) ?? []
    w.modSapPartnerAddresses = w.modSapPartnerAddresses?.sort(byPropertiesOf<CDAMWMWModSapPartnerAddressSave>(['id'])) ?? []
    w.modSapEquipments = w.modSapEquipments?.sort(byPropertiesOf<CDAMWMWModSapEquipmentSave>(['id'])) ?? []

    // Service Calls
    for (const serviceCall of w.serviceCalls) {
        serviceCall.blobs = serviceCall.blobs?.sort(byPropertiesOf<CDAMWMWServiceCallBlobSave>(['serviceCallId', 'blobId'])) ?? []
        serviceCall.itemRows = serviceCall.itemRows?.sort(byPropertiesOf<CDAMWMWServiceCallItemRowSave>(['id'])) ?? []
        serviceCall.transcriptions = serviceCall.transcriptions?.sort(byPropertiesOf<CDAMWMWServiceCallTranscriptionSave>(['serviceCallId', 'crowbarBlobId'])) ?? []
    }
    // Sales Orders
    for (const salesOrder of w.salesOrders) {
        salesOrder.rows = salesOrder.rows?.sort(byPropertiesOf<CDAMWMWWorksheetSapSalesOrderRowSave>(['id']))
    }
}

export const WorksheetSaveEquals = (ws1?: CDAMWMWWorksheetSave | null, ws2?: CDAMWMWWorksheetSave | null): boolean => {
    if (ws1 === ws2) return true
    if (!ws1 || !ws2) return false

    // Deep equals
    // We need to change the objects before calling equals, so we clone them
    const w1 = ObjectUtils.cloneDeepWithOptions(ws1, {skipFields, transformNullToUndefined: true})
    const w2 = ObjectUtils.cloneDeepWithOptions(ws2, {skipFields, transformNullToUndefined: true})
    // Manually remove fields
    w1.previousVersion = undefined
    w1.version = undefined
    w2.previousVersion = undefined
    w2.version = undefined
    // Sorting
    sort(w1)
    sort(w2)
    // Equals
    const result = equals(w1, w2, false, false)
    console.log('Equals result', result, 'for local', w1, 'and server', w2)
    return !!result
}