export const i18n_equipmentHu = {
    "LoadMore": "Továbbiak betöltése...",
    "EquipmentsLoading": "Gépek betöltése...",
    "Equipments": "Gépek",
    "Equipment": "Gép részletei",
    "ModEquipmentWarn": "Szerelő által hozzáadott, nem ellenőrzött gép törzsadat!",
    "EquipmentImages": "Berendezés fotók",
    "NoImagesFound": "Nem található kép a géphez.",
    "NoEquipmentsFound": "Nem található berendezés a megadott keresésre.",
    "ManufacturerSn": "Gyártási szám",
    "InternalSn": "Házi azonosító",
    "Manufacturer": "Gyártó",
    "VehicleType": "Gép típus",
    "ServiceCalls": "Szervizhívások",
    "Images": "Képek",
    "WorksheetEquipmentImages": "Munkalapok képei",
    "SapCoverImage": "SAP kép",
    "NoServiceCallsFound": "Nem található szervizhívás.",
    "Subject": "Tárgy",
    "Description": "Leírás",
}