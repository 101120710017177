import {useNewAtomWithRef} from "shared/hook/useNewatomWithRef";
import {CDSMSSapItemForWorksheetSearchQuery, CDSMSSapItemForWorksheetSearchQueryParams} from "crowbar-api";
import {SetStateAction} from "jotai";
import UsePagingUtils from "shared/components/generic-paging/UsePagingUtils";
import useSapItemSearchQueryBy from "modules/worksheet/editor/ui/catalogue/useSapItemSearchQueryBy";

export interface UseAddItemDialogAtomResult {

    source: "sap" | "empty"
    setSource: (update: SetStateAction<"sap" | "empty">) => void
    viewMode: 'list' | 'grid'
    setViewMode: (update: SetStateAction<"list" | "grid">) => void

    selectedItemCode?: string
    setSelected: (itemCode?: string) => void

    manuallyTypedItemName?: string
    setManuallyTypedItemName: (update: SetStateAction<string>) => void
    manuallyTypedUnitOfMeasure: string
    setManuallyTypedUnitOfMeasure: (update: SetStateAction<string>) => void

    itemsPage: number
    itemsPerPage: number
    itemQueryStatus: string
    items: CDSMSSapItemForWorksheetSearchQuery[]
    nextPage: () => void
    hasNextPage: boolean
    previousPage: () => void
    hasPreviousPage: boolean

    queryParams: CDSMSSapItemForWorksheetSearchQueryParams
    setQueryParams: (update: SetStateAction<CDSMSSapItemForWorksheetSearchQueryParams>) => void
    manuallySetQueryParams: boolean
}

const useAddItemDialogPagingAtom = (): UseAddItemDialogAtomResult => {
    const [source, setSource] = useNewAtomWithRef<'sap' | 'empty'>('sap')
    const [viewMode, setViewMode] = useNewAtomWithRef<'list' | 'grid'>('grid')
    const [manuallySetQueryParams, setManuallySetQueryParams] = useNewAtomWithRef<boolean>(false)
    const [selected, setSelected] = useNewAtomWithRef<string | undefined>(undefined)
    const [manuallyTypedItemName, setManuallyTypedItemName] = useNewAtomWithRef<string>("")
    const [manuallyTypedUnitOfMeasure, setManuallyTypedUnitOfMeasure] = useNewAtomWithRef<string>("db")
    const [queryParams, setQueryParams] = useNewAtomWithRef<CDSMSSapItemForWorksheetSearchQueryParams>({
        lastRowNumber: 0,
        limit: 20,
        onlyFrequentlyUsed: true,
        onlyInStock: true,
        search: "",
        searchInItemCode: true,
        searchInItemName: true,
        searchInSuppCatNum: true,
        searchInSubstitute: true
    })

    const {data, status} = useSapItemSearchQueryBy(queryParams)

    return {
        source,
        setSource,
        viewMode,
        setViewMode,
        selectedItemCode: selected,
        setSelected: itemCode => {
            setSelected(itemCode)
        },
        manuallyTypedItemName,
        setManuallyTypedItemName,
        manuallyTypedUnitOfMeasure,
        setManuallyTypedUnitOfMeasure,
        itemsPage: queryParams.lastRowNumber && queryParams.limit ? (queryParams.lastRowNumber / queryParams.limit) + 1 : 1,
        itemsPerPage: queryParams.limit ?? 50,
        itemQueryStatus: status,
        items: [...data ?? []],
        nextPage: () => {
            setQueryParams(prev => {
                return {
                    ...prev,
                    lastRowNumber: UsePagingUtils.nextPageRowNumber(prev.lastRowNumber, prev.limit) // (prev.lastRowNumber ?? 0) + (prev.limit ?? 0)
                }
            })
        },
        hasNextPage: UsePagingUtils.hasNextPage(data, queryParams.limit), // data ? data.length >= (queryParams.limit ?? 0) : false,
        previousPage: () => {
            setQueryParams(prev => {
                return {
                    ...prev,
                    lastRowNumber: UsePagingUtils.previousPageRowNumber(prev.lastRowNumber, prev.limit) // Math.max((prev.lastRowNumber ?? 0) - (prev.limit ?? 0), 0)
                }
            })
        },
        hasPreviousPage: UsePagingUtils.hasPreviousPage(queryParams.lastRowNumber, queryParams.limit), // queryParams.lastRowNumber && queryParams.limit ? queryParams.lastRowNumber - queryParams.limit >= 0 : false,
        queryParams,
        setQueryParams: update => {
            setManuallySetQueryParams(true)
            setQueryParams(update)
        },
        manuallySetQueryParams: manuallySetQueryParams,
    }
}

export default useAddItemDialogPagingAtom