import React from 'react';
import {useSapCostRateFindAll} from "crowbar-api/hooks/sap/useSapCostRateFindAll";
import {byPropertiesOf} from "shared/utils/ArraySort";
import {CDSMSapCostRate} from "crowbar-api";
import {LinearProgress, MenuItem, Select, Stack, Typography} from "@mui/material";

export type CostRateSelectProps = {
    label?: string
    sapDim: number

    value: CDSMSapCostRate | string | undefined
    onChange: (r: CDSMSapCostRate | undefined) => void
    disabled?: boolean
}

const CostRateSelect = ({
                            label,
                            sapDim,
                            value,
                            onChange,
                            disabled
                        }: CostRateSelectProps) => {
    const costRateQuery = useSapCostRateFindAll()
    const costRates = (costRateQuery.data ?? [])
        .filter(r => r.active === 'Y' && r.dimCode === sapDim)
        .sort(byPropertiesOf(['ocrCode']))

    if (costRateQuery.isLoading || costRateQuery.isFetching) {
        return (
            <LinearProgress/>
        )
    }

    const selectValueRaw = (typeof value === 'string')
        ? value
        : value?.ocrCode

    const selectValue = costRates.find(r => r.ocrCode === selectValueRaw)?.ocrCode ?? ""

    return (
        <Stack>
            {label && (<Typography variant="body2">{label}</Typography>)}
            <Select
                size="small"
                fullWidth
                value={selectValue}
                disabled={disabled}
                onChange={(e) => onChange(costRates.find(r => r.ocrCode === e.target.value))}
            >
                {costRates.map(r => (
                    <MenuItem key={r.ocrCode} value={r.ocrCode}>{r.ocrName}</MenuItem>
                ))}
            </Select>
        </Stack>
    )
}

export default CostRateSelect;