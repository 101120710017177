import React, {useState} from 'react';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    FormControlLabel,
    Grid,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {CDAMWMWorksheetCallTypeModel} from "crowbar-api";
import {useSapCallTypes} from "crowbar-api/hooks/sap/useSapCallTypes";
import {useWorksheetCallTypeSave} from "crowbar-api/hooks/worksheet/call-type/useWorksheetCallTypeSave";
import ConfirmDialogButton from "shared/components/buttons/ConfirmDialogButton";
import {useWorksheetCallTypeDelete} from "crowbar-api/hooks/worksheet/call-type/useWorksheetCallTypeDelete";
import NumberOnlyTextField from "shared/components/inputs/NumberOnlyTextField";

export type CallTypeDialogProps = DialogProps & {
    mode: 'new' | 'edit'
    editCallType: CDAMWMWorksheetCallTypeModel | undefined
    onChanged: () => void
    onDialogClosed: () => void
}
const CallTypeDialog = ({mode, editCallType, onChanged, onDialogClosed, ...props}: CallTypeDialogProps) => {
    const tk = useTranslateFunction('Admin.Worksheet')

    const [sapCallType, setSapCallType] = useState<number | undefined>(editCallType?.sapCallType ?? undefined)
    const [name, setName] = useState<string | undefined>(editCallType?.name ?? undefined)
    const [visOrder, setVisOrder] = useState<number | undefined>(editCallType?.visOrder ?? undefined)
    const [isSubstitute, setIsSubstitute] = useState<boolean>(editCallType?.substitutesTaskDescription ?? false)

    const sapCallTypesQuery = useSapCallTypes();
    const sapCallTypes = sapCallTypesQuery?.data ?? []

    const callTypeSave = useWorksheetCallTypeSave()
    const callTypeDelete = useWorksheetCallTypeDelete()

    const onSubmit = async () => {

        const saveModel: CDAMWMWorksheetCallTypeModel = {
            ...(mode === "edit" ? editCallType : {}),
            sapCallType,
            name,
            visOrder,
            substitutesTaskDescription: isSubstitute
        }

        await callTypeSave.mutateAsync(saveModel)

        onChanged?.()
    }

    const onCallDelete = async () => {
        if (!editCallType?.id) return
        await callTypeDelete.mutateAsync(editCallType.id)

        onChanged?.()
    }

    const onCallTypeChange = (e: SelectChangeEvent<string | number>) => {
        const newValue = +e.target.value
        setSapCallType(newValue && !isNaN(newValue) ? newValue : undefined)
    }

    return (
        <Dialog fullWidth={true} {...props}>
            <DialogContent>

                <Grid container spacing={1}>

                    <Grid item xs={12}>
                        <Typography variant="h3">
                            {mode === 'new' ? tk('New') + ' ' + tk('CallType') : tk('Edit')}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography>SAP {tk('CallType')}</Typography>
                        <Select
                            value={sapCallType ?? ""}
                            onChange={onCallTypeChange}
                            fullWidth
                        >
                            <MenuItem value="">&nbsp;</MenuItem>
                            {sapCallTypes.map(ct => (
                                <MenuItem key={ct.callTypeId} value={ct.callTypeId}>{ct.name}</MenuItem>
                            ))}
                        </Select>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography>{tk('NameDescription')}</Typography>
                        <TextField
                            value={name}
                            onChange={(e) => setName(e.target.value?.substring(0, 254))}
                            fullWidth={true}
                            InputProps={{
                                autoComplete: "off"
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography>{tk('VisOrder')}</Typography>
                        <NumberOnlyTextField
                            value={visOrder ?? -1}
                            onChange={(n) => setVisOrder(n)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isSubstitute}
                                    onChange={(e) => setIsSubstitute(e.target.checked)}
                                />
                            }
                            label={tk('SubstitutesTaskDescriptionEx')}
                        />
                    </Grid>

                </Grid>

            </DialogContent>
            <DialogActions>
                <Stack direction="row" spacing={1} width={1} justifyContent="space-between">
                    <Stack direction="row" spacing={1}>
                        <Button
                            variant="outlined"
                            color="error"
                            onClick={onDialogClosed}
                        >
                            {tk('Cancel')}
                        </Button>
                        {mode === 'edit' && (
                            <ConfirmDialogButton
                                onConfirm={onCallDelete}
                                buttonText={tk('Delete')}
                            />
                        )}
                    </Stack>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onSubmit}
                    >
                        {tk('Ok')}
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

export default CallTypeDialog;