import React from 'react';
import {Button, Grid, Typography} from "@mui/material";
import MechanicAdminList from "modules/admin/worksheet/mechanic/MechanicAdminList";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {useNewAtomWithRef} from "shared/hook/useNewatomWithRef";
import MechanicAdminDialog from "modules/admin/worksheet/mechanic/MechanicAdminDialog";
import useMechanics from "crowbar-api/hooks/mechanic/useMechanics";

const MechanicAdmin = () => {

    const tk = useTranslateFunction("Admin.Mechanic")

    const [dialogKey, setDialogKey] = useNewAtomWithRef<number>(1)
    const [dialogOpen, setDialogOpen] = useNewAtomWithRef<boolean>(false)

    const mechanicsQuery = useMechanics()

    const onAddClick = () => {
        setDialogKey((x: number) => x + 1)
        setDialogOpen(true)
    }

    const onDialogCancel = () => {
        setDialogOpen(false)
    }

    const onDialogSubmit = async () => {
        setDialogOpen(false)
        await mechanicsQuery.refetch()
    }

    return (
        <Grid container spacing={1}>

            <Grid item xs={12}>
                <Typography variant="h4">{tk('Title')}</Typography>
            </Grid>

            <Grid item xs={12}>
                <Button variant="contained" onClick={onAddClick}>{tk('Add')}</Button>
            </Grid>

            <Grid item xs={12}>
                <MechanicAdminDialog
                    mode="new"
                    key={dialogKey}
                    open={dialogOpen}
                    onCancel={onDialogCancel}
                    onSubmit={onDialogSubmit}
                />

                <MechanicAdminList
                    mechanicsQuery={mechanicsQuery}
                />
            </Grid>
        </Grid>
    );
};

export default MechanicAdmin;