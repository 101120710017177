export class WEditorPageCatalogueItemRowUtils {
    public static largeQtyInputWidthFrom = (value?: string, uom?: string | null): number => {
        const measured = (value?.toString() ?? "X").length * 42 + (uom?.toString() ?? "XX").length * 22 + 26
        return Math.max(measured, 110)
    }

    public static smallQtyInputWidthFrom = (value?: string, uom?: string | null): number => {
        const measured = (value?.toString() ?? "X").length * 24 + (uom?.toString() ?? "XX").length * 18 + 12
        return Math.max(measured, 100)
    }
}