import React from 'react';
import {
    Button,
    ButtonProps,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
    Grid,
    Stack
} from "@mui/material";
import {OverridableStringUnion} from "@mui/types";
import {ButtonPropsColorOverrides} from "@mui/material/Button/Button";
import useTranslateFunction from "shared/language/useTranslateFunction";

export type ConfirmDialogProps = DialogProps & {
    onConfirm: () => void
    onAlternativeConfirm?: () => void

    okButtonText?: string
    okButtonColor?: OverridableStringUnion<'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
        ButtonPropsColorOverrides>
    okButtonProps?: ButtonProps

    cancelButtonText?: string
    cancelButtonColor?: OverridableStringUnion<'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
        ButtonPropsColorOverrides>
    cancelButtonProps?: ButtonProps

    alternativeOkButtonText?: string
    alternativeOkButtonColor?: OverridableStringUnion<'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
        ButtonPropsColorOverrides>
    alternativeOkButtonProps?: ButtonProps

    dialogTitle?: string
    dialogText?: string | React.ReactElement
}

const ConfirmDialog = ({
                           onConfirm,
                           onAlternativeConfirm,
                           okButtonText,
                           okButtonColor,
                           okButtonProps,
                           alternativeOkButtonText,
                           alternativeOkButtonColor,
                           alternativeOkButtonProps,
                           cancelButtonText,
                           cancelButtonColor,
                           cancelButtonProps,
                           dialogTitle,
                           dialogText,
                           ...props
                       }: ConfirmDialogProps) => {
    const tk = useTranslateFunction(`Generic.ConfirmButton`)

    return (
        <Dialog
            fullWidth
            {...props}
        >
            <DialogTitle>{dialogTitle ?? tk('Confirm')}</DialogTitle>
            <DialogContent>{dialogText ?? tk('DefaultText')}</DialogContent>
            <DialogActions>
                <Grid container sx={{width: 1}} justifyContent="space-between">
                    <Grid item xs="auto">
                        <Button variant="outlined"
                                color={cancelButtonColor ?? "error"}
                                onClick={(e) => props.onClose?.(e, "escapeKeyDown")}
                                {...cancelButtonProps}
                        >
                            {cancelButtonText ?? tk('Cancel')}
                        </Button>
                    </Grid>
                    <Grid item xs="auto">
                        <Stack direction="row" spacing={1}>

                            {alternativeOkButtonText && (
                                <Button
                                    variant="outlined"
                                    color={alternativeOkButtonColor ?? "primary"}
                                    onClick={() => onAlternativeConfirm?.()}
                                    {...alternativeOkButtonProps}
                                >
                                    {alternativeOkButtonText}
                                </Button>
                            )}

                            <Button
                                variant="contained"
                                color={okButtonColor ?? "primary"}
                                onClick={onConfirm}
                                {...okButtonProps}
                            >
                                {okButtonText ?? tk('Yes')}
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmDialog;