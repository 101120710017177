import React from 'react';
import {
    CDAMWMServiceCallEvalModel,
    CDAMWMServiceCallModel,
    CDAMWMWorksheetEvalModel,
    CDAMWMWWorksheetQuery
} from "crowbar-api";
import {CircularProgress, Grid, Typography} from "@mui/material";
import VehicleTypeDisplay from "modules/worksheet/editor/shared/component/vehicle-type-display/VehicleTypeDisplay";
import useTranslateFunction from "shared/language/useTranslateFunction";
import HistoryForItemRows from "modules/admin/worksheet/evaluation/tabs/history/HistoryForItemRows";
import {useWorksheetCallTypeFindAll} from "crowbar-api/hooks/worksheet/call-type/useWorksheetCallTypeFindAll";

export type HistoryForServiceCallProps = {
    wEval: CDAMWMWorksheetEvalModel | null | undefined
    worksheet: CDAMWMWWorksheetQuery | null | undefined

    scEval: CDAMWMServiceCallEvalModel
    sc: CDAMWMServiceCallModel
}

const HistoryForServiceCall = ({
                                   wEval,
                                   worksheet,
                                   scEval,
                                   sc
                               }: HistoryForServiceCallProps) => {
    const tk = useTranslateFunction("Admin.Worksheet")

    const modEquipment = worksheet?.modSapEquipments?.find(me => me.id === sc.modSapEquipmentId)

    const callTypesQuery = useWorksheetCallTypeFindAll()
    const callTypes = callTypesQuery.data ?? []
    const originalCallType = callTypes.find(ct => ct.id === scEval.originalCallTypeId)
    const currentCallType = callTypes.find(ct => ct.id === sc.callTypeId)

    return (
        <Grid container spacing={1}>

            <Grid item xs={12}>
                <Typography variant="h6">
                    {sc.serialId} {sc.emptySubject ?? ""}
                    <VehicleTypeDisplay manufacturer={sc.manufacturer} vehicleType={sc.vehicleType}/>
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={0.5}>
                    {scEval.equipmentChanged && (
                        <>
                            <Grid item xs={12}>
                                <Typography variant="body2" color="red"
                                            fontWeight="bold">{tk('EquipmentChanged')}</Typography>
                            </Grid>

                            <Grid item xs={6}>
                                <Typography fontWeight="bold">
                                    {tk('WorksheetOriginal')}&nbsp;
                                    {modEquipment && (
                                        <span>(MOD)</span>
                                    )}
                                </Typography>
                                <Typography>
                                    <VehicleTypeDisplay
                                        manufacturer={scEval.originalManufacturer}
                                        vehicleType={scEval.originalVehicleType}
                                    />
                                    SN: {scEval.originalManufacturerSn} <br/>
                                    Internal: {scEval.originalInternalSn} <br/>
                                    Comment: {scEval.originalComment}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography fontWeight="bold">{tk('CurrentValue')}</Typography>

                                <VehicleTypeDisplay
                                    manufacturer={sc.manufacturer}
                                    vehicleType={sc.vehicleType}
                                />
                                SN: {sc.manufacturerSn} <br/>
                                Internal: {sc.internalSn} <br/>
                                Comment: {sc.comment}
                            </Grid>
                        </>
                    )}

                    {scEval.subjectChanged && (
                        <>
                            <Grid item xs={6}>
                                Original subject: {scEval.originalEmptySubject}
                            </Grid>
                            <Grid item xs={6}>
                                Current subject: {sc.emptySubject}
                            </Grid>
                        </>
                    )}

                    <Grid item xs={12}>
                        <Typography variant="h6">{tk('Catalogue')}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <HistoryForItemRows wEval={wEval} worksheet={worksheet} scEval={scEval} sc={sc}/>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h6">{tk('WorksheetFields')}</Typography>
                    </Grid>

                    {scEval.callTypeIdChanged && (
                        <Grid item xs={12}>
                            <Typography>
                                {tk('CallTypeIdChanged')}&nbsp;
                                {callTypesQuery.isLoading ? (
                                    <CircularProgress/>
                                ) : (
                                    <Typography component="span" fontStyle="italic">
                                        {tk('WorksheetOriginal')}: {originalCallType?.name ?? "-"}, {tk('CurrentValue')}: {currentCallType?.name ?? "-"}
                                    </Typography>
                                )}
                            </Typography>
                        </Grid>
                    )}

                    {scEval.taskDescriptionChanged && (
                        <Grid item xs={12}>
                            <Typography>
                                {tk('TaskDescriptionChanged')}&nbsp;
                                {callTypesQuery.isLoading ? (
                                    <CircularProgress/>
                                ) : (
                                    <Typography component="span" fontStyle="italic">
                                        {tk('WorksheetOriginal')}: {scEval.originalTaskDescription ?? "-"}, {tk('CurrentValue')}: {sc.taskDescription ?? "-"}
                                    </Typography>
                                )}
                            </Typography>
                        </Grid>
                    )}

                    {scEval.faultDescriptionChanged && (
                        <Grid item xs={12}>
                            <Typography>
                                {tk('FaultDescriptionChanged')}&nbsp;
                                {callTypesQuery.isLoading ? (
                                    <CircularProgress/>
                                ) : (
                                    <Typography component="span" fontStyle="italic">
                                        {tk('WorksheetOriginal')}: {scEval.originalFaultDescription ?? "-"}, {tk('CurrentValue')}: {sc.faultDescription ?? "-"}
                                    </Typography>
                                )}
                            </Typography>
                        </Grid>
                    )}

                    {scEval.commentChanged && (
                        <Grid item xs={12}>
                            <Typography>
                                {tk('CommentChanged')}&nbsp;
                                {callTypesQuery.isLoading ? (
                                    <CircularProgress/>
                                ) : (
                                    <Typography component="span" fontStyle="italic">
                                        {tk('WorksheetOriginal')}: {scEval.originalComment ?? "-"}, {tk('CurrentValue')}: {sc.comment ?? "-"}
                                    </Typography>
                                )}
                            </Typography>
                        </Grid>
                    )}

                    {scEval.currentEquipmentOperatingHoursChanged && (
                        <Grid item xs={12}>
                            <Typography>
                                {tk('CurrentEquipmentOperatingHoursChanged')}&nbsp;
                                {callTypesQuery.isLoading ? (
                                    <CircularProgress/>
                                ) : (
                                    <Typography component="span" fontStyle="italic">
                                        {tk('WorksheetOriginal')}: {scEval.originalCurrentEquipmentOperatingHours ?? "-"}, {tk('CurrentValue')}: {sc.currentEquipmentOperatingHours ?? "-"}
                                    </Typography>
                                )}
                            </Typography>
                        </Grid>
                    )}

                </Grid>
            </Grid>
        </Grid>
    )
}

export default HistoryForServiceCall;