import {useMemo} from "react";
import {v4 as uuid} from "uuid";
import ModSapTypeMap from "crowbar-api/enum-map/ModSapTypeMap";
import ModSapStatusMap from "crowbar-api/enum-map/ModSapStatusMap";
import {
    SapPartnerContactSelectValue
} from "modules/worksheet/editor/ui/basic/contact-select/useSapPartnerContactSelectValuesAtom";
import {CDAMWMWModSapPartnerContactSave} from "crowbar-api";
import {useWorksheetSaveState} from "modules/worksheet/editor/state/WorksheetSaveAtoms";

export interface UseSelectedSapContactAsModResult {
    selectedValueAsMod?: CDAMWMWModSapPartnerContactSave
}

const mapSelectedContactToMod = (cardCode: string | undefined | null,
                                 sc: SapPartnerContactSelectValue | undefined): CDAMWMWModSapPartnerContactSave | undefined => {
    // nothing is selected --> new empty mod
    if (!sc) {
        return {
            id: uuid(),
            type: ModSapTypeMap.create,
            status: ModSapStatusMap.suggested
        }
    }
    // Editing an sap contact --> new mod with address values
    if (sc.source === "contact") {
        const c = sc.contact ?? {}
        return {
            id: uuid(),
            type: ModSapTypeMap.update,
            status: ModSapStatusMap.suggested,
            contactCode: c.contactCode,
            contactIdentifier: c.name,
            firstname: c.firstName,
            middleName: c.middleName,
            lastname: c.lastName,
            phone: c.phone1 ?? c.phone2 ?? c.phone3,
            email: c.email
        }
    }
    // Editing a mod
    if (sc.source === "mod") {
        return sc.mod
    }
}

const useSelectedSapContactAsMod = (selectValues: SapPartnerContactSelectValue[],
                                    selectedKey: string | undefined): UseSelectedSapContactAsModResult => {
    const [worksheetSave] = useWorksheetSaveState()

    return useMemo(() => {
        const match = selectValues.find(v => v.key === selectedKey)
        return {
            selectedValueAsMod: mapSelectedContactToMod(worksheetSave?.cardCode, match)
        }
    }, [selectValues, selectedKey, worksheetSave?.cardCode]);
}

export default useSelectedSapContactAsMod