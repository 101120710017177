import {useSelectedServiceCall} from "modules/worksheet/editor/shared/data/useSelectedServiceCall";
import useWorksheetTimeItemCodes from "crowbar-api/hooks/worksheet/worksheet-time-item-codes/useWorksheetTimeItemCodes";
import {useCallback} from "react";

/**
 * Finds the time rows matching the itemCode given.
 * (Check is the same as for {@link useIsTimeMatch})
 * @return CDAMWMWServiceCallItemRowSave[]
 */
const useCurrentServiceCallTimeDistributedRowsFor = () => {
    const selectedServiceCall = useSelectedServiceCall()
    const timeCodes = useWorksheetTimeItemCodes()

    return useCallback((itemCode?: string | null | undefined) => {
        if (!itemCode || !selectedServiceCall?.itemRows) return []

        return selectedServiceCall.itemRows.filter(r => r.itemCode === itemCode &&
            (r.isTime ||
                (timeCodes?.data && timeCodes.data.find(c => c.itemCode === r.itemCode) !== undefined)
            )
        )
    }, [selectedServiceCall?.itemRows, timeCodes.data])
}

export default useCurrentServiceCallTimeDistributedRowsFor