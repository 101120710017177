import {useInfiniteQuery} from "@tanstack/react-query";
import {CDAMEMSapEquipmentSearchParameters, SapEquipmentSearchApi} from "crowbar-api";
import {InfQueryUtils} from "shared/utils/InfQueryUtils";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import AxiosCancelTokenHelper from "crowbar-api/AxiosCancelTokenHelper";

export const useSapEquipmentSearchFindPageInfQuery = (
    {
        lastRowNumber, // lastRowNumber is never read from the params! We use getNextPageParam !
        ...params // all the other params are read (and will re-fetch from page 0)
    }: CDAMEMSapEquipmentSearchParameters
) => {
    return useInfiniteQuery(['sap-equipment-search-find-page-1', params], async ({signal, pageParam}) => {
        const response = await crowbarApiFactory(SapEquipmentSearchApi)
            .findPage({...params, lastRowNumber: pageParam}, AxiosCancelTokenHelper.cancelTokenFrom(signal))
        return response.data ?? []
    }, {
        networkMode: 'online', // will not fire unless we have network
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        cacheTime: 0,// Math.pow(10, 7),
        staleTime: 0, // Math.pow(10, 7),
        getNextPageParam: InfQueryUtils.getNextPageParamForRowNum
    })
}