import React, {SyntheticEvent, useMemo, useState} from 'react';
import {Autocomplete, LinearProgress, Stack, TextField} from "@mui/material";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {useWorksheetEvalUpdateEval} from "crowbar-api/hooks/worksheet/evaluation/worksheet/useWorksheetEvalUpdateEval";
import {useSapPartnersPageBy} from "crowbar-api/hooks/sap/useSapPartnersPageBy";
import {ArrayReduceToUniqueMap} from "shared/utils/ArrayReduceToUniqueMap";
import RefreshButton from "shared/components/buttons/RefreshButton";
import {CDAMWMWorksheetEvalModel, CDAMWMWWorksheetQuery} from "crowbar-api";

export type PartnerMasterDataSelectProps = {
    wEval: CDAMWMWorksheetEvalModel | null | undefined
    worksheet: CDAMWMWWorksheetQuery | null | undefined
    onDataChange: () => void
}

const PartnerMasterDataSelect = ({wEval, worksheet, onDataChange}: PartnerMasterDataSelectProps) => {
    const tk = useTranslateFunction('Admin.Worksheet')

    const ws = worksheet

    const [selectedCardCode, setSelectedCardCode] = useState<string | null>(wEval?.selectedSapPartnerCardCode ?? null)
    const [inputValue, setInputValue] = useState<string>("")

    // console.log("values eval: ", wEval?.selectedSapPartnerCardCode, "selectedCardCode: ", selectedCardCode, "inputValue: ", inputValue)

    const evalUpdateEval = useWorksheetEvalUpdateEval()

    const updateServerSelectedCardCodeTo = async (newCardCode: string | null | undefined) => {
        await evalUpdateEval.mutateAsync({
            originalId: ws?.id,
            evalId: wEval?.id,
            partnerAck: wEval?.partnerAck,
            contactAck: wEval?.contactAck,
            addressAck: wEval?.addressAck,
            selectedSapPartnerCardCode: newCardCode,
            selectedSapAddressAndType: wEval?.selectedSapAddressAndType,
            selectedSapContactCode: wEval?.selectedSapContactCode
        })
        onDataChange()
    }

    const evalSelectedPartnerQuery = useSapPartnersPageBy({
        search: wEval?.selectedSapPartnerCardCode ?? "",
        limit: 1,
        cardType: "C",
        lastRowNumber: 0,
        showDeleted: false
    }, {
        keepPreviousData: true,
    })

    const currentSelectedPartnerQuery = useSapPartnersPageBy({
        search: selectedCardCode ?? "",
        limit: 1,
        cardType: "C",
        lastRowNumber: 0,
        showDeleted: false
    }, {
        keepPreviousData: true,
    })

    const sapPartnersQuery = useSapPartnersPageBy({
        search: inputValue,
        limit: 100,
        cardType: "C",
        lastRowNumber: 0,
        showDeleted: false,
        orderByIsDesc: false
    }, {
        keepPreviousData: true,
        cacheTime: 0,
        staleTime: 0,
        refetchOnWindowFocus: true,
        refetchOnMount: true
    })

    const options = useMemo(() => [
        ...evalSelectedPartnerQuery.data ?? [],
        ...currentSelectedPartnerQuery.data ?? [],
        ...sapPartnersQuery.data ?? []
    ], [currentSelectedPartnerQuery.data, evalSelectedPartnerQuery.data, sapPartnersQuery.data])

    const selectOptions = ArrayReduceToUniqueMap(options, e => e.cardCode).map(o => o.cardCode!)

    if (evalSelectedPartnerQuery.isLoading
        || currentSelectedPartnerQuery.isLoading
        || sapPartnersQuery.isLoading) {
        return (
            <LinearProgress/>
        )
    }

    const onRefreshClick = () => {
        sapPartnersQuery.refetch().then()
    }

    return (
        <Stack direction="row" spacing={1} alignItems="center">

            <RefreshButton onClick={onRefreshClick} isLoading={sapPartnersQuery.isRefetching}/>

            <Autocomplete
                size="small"
                disabled={wEval?.partnerAck}
                multiple={false}
                options={selectOptions}
                value={selectedCardCode}
                onChange={(e: SyntheticEvent, newValue: string | undefined | null) => {
                    setSelectedCardCode(newValue ?? null)
                    updateServerSelectedCardCodeTo(newValue).then()
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue)
                }}
                getOptionLabel={cardCode => {
                    const option = options.find(o => o.cardCode === cardCode)
                    return option ? `${option.cardCode} ${option.cardName}` : "N/A"
                }}
                isOptionEqualToValue={() => true} // prevents invalid value warning. https://github.com/mui/material-ui/issues/29727
                fullWidth={true}
                renderInput={(params) => (
                    <TextField required={true} error={!params.inputProps.value} {...params} />
                )}
            />
        </Stack>
    );
};

export default PartnerMasterDataSelect;