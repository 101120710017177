import React from 'react';
import {Grid, GridProps, Typography} from "@mui/material";

export interface LabelFieldProps {
    label?: string
    content?: string | React.ReactElement

    hideColon?: boolean
    hideSpace?: boolean

    gridContainerProps?: GridProps
}

const LabelField = ({label, content, hideColon, hideSpace, gridContainerProps}: LabelFieldProps) => {
    return (
        <Grid container justifyContent="space-between" {...gridContainerProps}>
            <Grid item alignItems="left">
                <Typography
                    fontWeight="bold"
                    sx={{
                        wordWrap: "break-word"
                    }}
                >
                    {label}{!hideColon && (":")}{!hideSpace && (<>&nbsp;</>)}
                </Typography>
            </Grid>
            <Grid item alignItems="right">{content ?? ""}</Grid>
        </Grid>
    );
};

export default LabelField;