import {useQuery} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {PhotoRuleApi} from "crowbar-api";
import AxiosCancelTokenHelper from "crowbar-api/AxiosCancelTokenHelper";


const useAllPhotoRulesAndManufacturers = () => {
    return useQuery(['photo-all-rules-and-manufacturers'], async (context) => {
        const response = await crowbarApiFactory(PhotoRuleApi)
            .allRulesAnfManufacturers(AxiosCancelTokenHelper.cancelTokenFrom(context.signal))
        if (response.status !== 200) {
            throw new Error('Failed to load all rules and manufacturers models.')
        }
        return response.data
    }, {
        networkMode: 'online', // will not fire unless we have network
        placeholderData: [],
        cacheTime: Math.pow(10, 7),
        staleTime: Math.pow(10, 7),
        refetchOnMount: false
    })
}

export default useAllPhotoRulesAndManufacturers