import {CDAMWMServiceCallBlobType} from "crowbar-api";

export default class ServiceCallBlobTypeMap {
    public static readonly notSet = CDAMWMServiceCallBlobType.NUMBER_0
    public static readonly photoRule = CDAMWMServiceCallBlobType.NUMBER_1
    public static readonly issuePhoto = CDAMWMServiceCallBlobType.NUMBER_2

    public static translationKey = (t?: CDAMWMServiceCallBlobType): string => {

        const preKey = "Worksheet.Generic.ServiceCallBlobType."

        if (t === this.notSet) {
            return `${preKey}NotSet`
        }

        if (t === this.photoRule) {
            return `${preKey}PhotoRule`
        }

        if (t === this.issuePhoto) {
            return `${preKey}IssuePhoto`
        }

        return "Missing"
    }
}