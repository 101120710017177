import {CDAMWMWModSapPartnerAddressSave, CDSMSapPartnerAddress} from "crowbar-api";
import {useMemo} from "react";
import SapPartnerAddressUtils from "crowbar-api/util/SapPartnerAddressUtils";
import {useWorksheetSaveState} from "modules/worksheet/editor/state/WorksheetSaveAtoms";
import {StringUtils} from "shared/utils/StringUtils";
import WorksheetSelectedDataSourceMap from "crowbar-api/enum-map/WorksheetSelectedDataSourceMap";
import ModSapTypeMap from "crowbar-api/enum-map/ModSapTypeMap";
import {toastError} from "shared/toast/DefaultToasts";
import {useNewAtomWithRef} from "shared/hook/useNewatomWithRef";
import useSapPartnerAddressesByCardCode from "crowbar-api/hooks/sap/useSapPartnerAddressesByCardCode";
import CrowbarConstants from "crowbar-api/CrowbarConstants";

export interface SapPartnerAddressSelectValue {
    // selected: boolean
    /**
     * address source: cardcode-addressType-addressName
     * mod: mod id
     */
    key: string
    cardCode: string | null | undefined
    source: 'address' | 'sales-order' | 'mod' | 'own-premise'
    /**
     * in case of sap address or sales order address
     * Sales orders are mapped to an address object
     */
    address?: CDSMSapPartnerAddress
    /**
     * in case of worksheet address modifications
     */
    mod?: CDAMWMWModSapPartnerAddressSave
}

export interface UsePartnerAddressSelectValuesAtomResult {
    selectValues: SapPartnerAddressSelectValue[]
    setSelectedByKey: (key: string) => void
    selectedKey: string | undefined
    // setSelectedByValue: (v: SapPartnerAddressSelectValue) => void
    addNewMod: (mod: CDAMWMWModSapPartnerAddressSave, mode: 'new' | 'edit') => void
}

//const sapPartnerAddressSelectValuesAtom = atom<SapPartnerAddressSelectValue[]>([])
//const selectedPartnerAddressAtom = atom<string | undefined>(undefined)

const useSapPartnerAddressSelectValuesAtom = (cardCode: string | null | undefined): UsePartnerAddressSelectValuesAtomResult => {
    const [worksheetSave, setWorksheetSave] = useWorksheetSaveState()
    const [selected, setSelected] = useNewAtomWithRef<string | undefined>(undefined)
    const {data: addresses} = useSapPartnerAddressesByCardCode(cardCode)

    // automatically load from api, sales order and state mod values, keep selected
    const selectValues = useMemo(() => {
        const salesOrderAddresses: SapPartnerAddressSelectValue[] = []
        const so = worksheetSave?.salesOrders?.find(() => true)
        if (so && StringUtils.notUndefinedNullAndEmpty(so.shippingAddress)) {
            salesOrderAddresses.push({
                //  selected: false,
                key: SapPartnerAddressUtils.addressKeyFor(so.cardCode, "S", so.shippingAddress),
                source: "sales-order",
                cardCode: so.cardCode,
                address: {
                    cardCode: so.cardCode ?? undefined,
                    addressType: "S",
                    code: so.shipToCode ?? undefined,
                    addressName: so.shippingAddress ?? undefined,
                    country: so.shippingAddressCountryCode ?? undefined,
                    zipCode: so.shippingAddressZipCode ?? undefined,
                    city: so.shippingAddressCity ?? undefined,
                    street: so.shippingAddressStreet ?? undefined,
                    streetNo: so.shippingAddressStreetNo ?? undefined,
                    block: so.shippingAddressBlock ?? undefined,
                    county: so.shippingAddressCounty ?? undefined,
                    building: so.shippingAddressBuilding ?? undefined
                }
            })
        }

        if (so && StringUtils.notUndefinedNullAndEmpty(so.billingAddress)) {
            const newAddress: SapPartnerAddressSelectValue = {
                //  selected: false,
                key: SapPartnerAddressUtils.addressKeyFor(so.cardCode, "B", so.billingAddress),
                source: "sales-order",
                cardCode: so.cardCode,
                address: {
                    cardCode: so.cardCode ?? undefined,
                    addressType: "B",
                    code: so.billToCode ?? undefined,
                    addressName: so.billingAddress ?? undefined,
                    country: so.billingAddressCountryCode ?? undefined,
                    zipCode: so.billingAddressZipCode ?? undefined,
                    city: so.billingAddressCity ?? undefined,
                    street: so.billingAddressStreet ?? undefined,
                    streetNo: so.billingAddressStreetNo ?? undefined,
                    block: so.billingAddressBlock ?? undefined,
                    county: so.billingAddressCounty ?? undefined,
                    building: so.billingAddressBuilding ?? undefined
                }
            }

            // only add the billing address, if it is not the same as the shipping one.
            if (salesOrderAddresses.length < 1 ||
                (salesOrderAddresses[0].address && newAddress.address &&
                    !SapPartnerAddressUtils.addressFormatEquals(salesOrderAddresses[0].address, newAddress.address))) {
                salesOrderAddresses.push(newAddress)
            }
        }

        const partnerAddresses = (addresses ?? [])
            // filter addresses that are same as the sales order ones
            .filter(
                a => salesOrderAddresses.find(
                    soa => soa.address && SapPartnerAddressUtils.addressFormatEquals(a, soa.address)
                ) === undefined
            )
            .map((a): SapPartnerAddressSelectValue => {
                return {
                    //  selected: false,
                    key: SapPartnerAddressUtils.addressKey(a),
                    cardCode: cardCode,
                    source: 'address',
                    address: a
                }
            })

        // Merge the sales order and the partner addresses
        const mergedAddresses = [
            ...salesOrderAddresses,
            ...partnerAddresses
        ]

        // we always want the main address (phoenix) to be in the list
        // we only add it, if it is missing from the merged addresses
        if (mergedAddresses.find(a => a.key === CrowbarConstants.PhoenixForkliftAddressKey) === undefined) {
            const fixAddress: SapPartnerAddressSelectValue = {
                source: "own-premise",
                key: CrowbarConstants.PhoenixForkliftAddressKey,
                cardCode: CrowbarConstants.PhoenixForkliftCardCode,
                address: CrowbarConstants.PhoenixForkliftAddress,
                mod: undefined
            }
            mergedAddresses.push(fixAddress)
        }
        // load all the modifications
        const modifications = (worksheetSave?.modSapPartnerAddresses ?? [])
            .map((mod): SapPartnerAddressSelectValue => {
                return {
                    //  selected: false,
                    source: "mod",
                    key: mod.id!,
                    cardCode: mod.cardCode,
                    mod: mod
                }
            })

        return [
            ...mergedAddresses,
            ...modifications
        ]
    }, [addresses, cardCode, worksheetSave?.modSapPartnerAddresses, worksheetSave?.salesOrders])

    // prepare the result object
    return useMemo(() => {
        const setValuesToWorksheet = (selectedValue: SapPartnerAddressSelectValue) => {
            setWorksheetSave(prev => {
                const s = selectedValue.source
                const isAddress = s === "address" || s === "sales-order" || s === "own-premise"

                const a = selectedValue.address
                const m = selectedValue.mod

                return {
                    ...prev,
                    partnerAddressSource:
                        s === "address" ? WorksheetSelectedDataSourceMap.sapMasterData :
                            s === "sales-order" ? WorksheetSelectedDataSourceMap.sapSalesOrder :
                                s === "mod" ? WorksheetSelectedDataSourceMap.modSap :
                                    s === "own-premise" ? WorksheetSelectedDataSourceMap.ownPremise :
                                        WorksheetSelectedDataSourceMap.notSet,
                    modSapPartnerAddressId: selectedValue.source === "mod" ? selectedValue.mod?.id : undefined,
                    addressCode: isAddress ? a?.code : m?.id,
                    address: isAddress ? a?.addressName : m?.address,
                    addressType: isAddress ? a?.addressType : m?.addressType,
                    addressStreet: isAddress ? a?.street : m?.street,
                    addressStreetNo: isAddress ? a?.streetNo : m?.streetNo,
                    addressBlock: isAddress ? a?.block : undefined,
                    addressBuilding: isAddress ? a?.building : undefined,
                    addressZipCode: isAddress ? a?.zipCode : m?.zipCode,
                    addressCity: isAddress ? a?.city : m?.city,
                    addressCounty: isAddress ? a?.county : undefined,
                    addressCountryCode: isAddress ? a?.country : m?.countryCode,
                    addressLatitude: undefined,
                    addressLongitude: undefined
                }
            })
        }

        const setSelectedByKey = (key: string) => {
            const match = selectValues.find(v => v.key === key)
            if (!match) {
                toastError("Cannot select value. Not a values in the list.")
                return
            }
            setValuesToWorksheet(match)
            setSelected(key)
        }

        return {
            selectValues: selectValues,
            setSelectedByKey: setSelectedByKey,
            selectedKey: selected,
            addNewMod: (mod, mode) => {
                mod.type = mod.type === ModSapTypeMap.create ? ModSapTypeMap.create
                    : mode === "new" ? ModSapTypeMap.create : ModSapTypeMap.update
                setWorksheetSave(prev => {
                    return {
                        ...prev,
                        modSapPartnerAddresses: [
                            ...(prev?.modSapPartnerAddresses ?? []).filter(a => a.id !== mod.id),
                            mod
                        ]
                    }
                })
                if (mod.id) {
                    setValuesToWorksheet({
                        key: mod.id,
                        source: 'mod',
                        cardCode: worksheetSave?.cardCode,
                        address: undefined,
                        mod: mod
                    })
                    setSelected(mod.id)
                }
            }
        }
    }, [selectValues, selected, setSelected, setWorksheetSave, worksheetSave?.cardCode])
}

export default useSapPartnerAddressSelectValuesAtom