import {useMutation} from "@tanstack/react-query";
import {CDAMPMPhotoRuleStepTemplateModel, PhotoRuleStepTemplateApi} from "crowbar-api";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";

const usePhotoStepTemplateSave = () => {
    return useMutation(async (template: CDAMPMPhotoRuleStepTemplateModel) => {
        const response = await crowbarApiFactory(PhotoRuleStepTemplateApi).save(template)
        if (response.status !== 200) {
            throw new Error('Cannot save template.')
        }
    })
}

export default usePhotoStepTemplateSave