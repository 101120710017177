import {useMemo} from "react";
import {focusAtom} from "jotai-optics";
import {worksheetSaveStateAtom} from "modules/worksheet/editor/state/WorksheetSaveAtoms";
import {useAtom} from "jotai";

export const useServiceCallBlobAtom = (serviceCallId: string | undefined, blobId: string | undefined) => {
    const focusedAtom = useMemo(() => {
        return focusAtom(worksheetSaveStateAtom, (optic) =>
            optic
                .optional()
                .prop('serviceCalls')
                .optional()
                .find(sc => sc.id === serviceCallId)
                .prop('blobs')
                .optional()
                .find(b => b.serviceCallId === serviceCallId && b.blobId === blobId)
        )
    }, [blobId, serviceCallId])

    return useAtom(focusedAtom)
}