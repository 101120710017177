import * as React from 'react';
import Header from "./header/Header";
import {Box, Typography} from "@mui/material";
import {useBuildInfo} from "modules/generic/query-warmup/useBuildInfo";
import DateDisplay from "shared/components/date-display/DateDisplay";
import useTranslateFunction from "shared/language/useTranslateFunction";

interface DefaultLayoutProps {
    children?: React.ReactNode
}

const DefaultLayout = ({children}: DefaultLayoutProps) => {
    const tk = useTranslateFunction("Generic")
    const {buildTime, environmentName} = useBuildInfo()

    return (
        <>
            <Header/>
            <Box sx={{
                pt: "90px",
                px: 1,
            }}>
                {children}
            </Box>
            <Box sx={{
                position: "fixed",
                bottom: 0,
                left: 0,
                p: 0.5
            }}>
                <Typography variant="caption" color="grey" fontSize="8pt">
                    {tk('Version')}:&nbsp;<DateDisplay
                    dateStr={buildTime ?? ""}
                    variant="caption" color="grey" fontSize="8pt"
                />&nbsp;
                    {environmentName}
                </Typography>
            </Box>
        </>
    );
}

export default DefaultLayout;