import React from 'react';
import {CDAMPMPhotoRuleStepTemplateModel} from "crowbar-api";
import {Box, Button, Grid, TextField} from '@mui/material';
import {useNewAtomWithRef} from "shared/hook/useNewatomWithRef";
import {v4 as uuid} from 'uuid'
import SwitchConfirmDelete from "shared/components/switch-confirm-delete/SwitchConfirmDelete";
import useTranslateFunction from "shared/language/useTranslateFunction";

export interface PhotoStepTemplateEditFieldsProps {
    mode: 'new' | 'edit'
    template?: CDAMPMPhotoRuleStepTemplateModel | null
    onSubmitClicked: (newTemplate: CDAMPMPhotoRuleStepTemplateModel) => Promise<void>
    onDeleteClicked: () => Promise<void>
}

const PhotoStepTemplateEditFields = ({
                                         mode,
                                         template,
                                         onSubmitClicked,
                                         onDeleteClicked
                                     }: PhotoStepTemplateEditFieldsProps) => {
    const tk = useTranslateFunction(`Admin.PhotoRule`)

    const [currentTemplate, setCurrentTemplate] = useNewAtomWithRef(template ?? {
        id: uuid(),
        title: "",
        description: ""
    })

    return (
        <Grid container spacing={3} sx={{marginTop: 3}}>
            <Grid item xs={8}>
                <TextField
                    label={tk('Title')}
                    fullWidth
                    required
                    value={currentTemplate?.title}
                    onChange={(e) => {
                        setCurrentTemplate(prev => {
                            return {
                                ...prev,
                                title: e.target.value
                            }
                        })
                    }}
                />
            </Grid>
            <Grid item xs={12}>

                <TextField
                    label={tk('Description')}
                    fullWidth
                    multiline
                    required
                    minRows={3}
                    maxRows={8}
                    value={currentTemplate?.description}
                    onChange={(e) => {
                        setCurrentTemplate(prev => {
                            return {
                                ...prev,
                                description: e.target.value
                            }
                        })
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <Box display="flex" justifyContent={mode === "edit" ? "space-between" : "end"}>
                    {mode === "edit" && (
                        <SwitchConfirmDelete buttonLabel={tk('DeleteTemplate')} onClick={onDeleteClicked}/>
                    )}
                    <Button variant="contained" color="primary" onClick={() => onSubmitClicked(currentTemplate)}>
                        {tk('Save')}
                    </Button>
                </Box>
            </Grid>
        </Grid>
    )
}

export default PhotoStepTemplateEditFields;