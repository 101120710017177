import React from 'react';
import WorksheetStateDisplay from "shared/components/worksheet/WorksheetStateDisplay";
import {Button, CircularProgress, Stack} from "@mui/material";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {CDAMWMWorksheetEvalModel, CDAMWMWorksheetStatus, CDAMWMWWorksheetQuery} from "crowbar-api";
import WorksheetStatusMap from "crowbar-api/enum-map/WorksheetStatusMap";
import {useWEvalStatuses} from "modules/admin/worksheet/evaluation/state/useWEvalStatuses";
import ConfirmDialogButton from "shared/components/buttons/ConfirmDialogButton";
import {useWorksheetUpdateStatus} from "crowbar-api/hooks/worksheet/status-and-signature/useWorksheetUpdateStatus";

export type WEvalHeaderStatusProps = {
    wEval: CDAMWMWorksheetEvalModel | null | undefined
    worksheet: CDAMWMWWorksheetQuery | null | undefined
    onStateChanged?: () => void
}
const WEvalHeaderStatus = ({wEval, worksheet, onStateChanged}: WEvalHeaderStatusProps) => {
    const tk = useTranslateFunction("Admin.Worksheet")

    const {status} = useWEvalStatuses()
    const isEvaluated = status === WorksheetStatusMap.evaluated
    const canBeEvaluated = status && status > WorksheetStatusMap.final && status < WorksheetStatusMap.evaluated
    const disableEvalButton = !status || status <= WorksheetStatusMap.final

    const removeEvalToState = !!worksheet?.signatureBase64 ? WorksheetStatusMap.finalSigned : WorksheetStatusMap.finalSignSkipped
    const removeEvalText = removeEvalToState === WorksheetStatusMap.finalSigned
        ? tk('ChangeToFinalSigned')
        : tk('ChangedToFinalSignSkipped')

    const updateStatus = useWorksheetUpdateStatus()

    const setEvalTo = async (toStatus: CDAMWMWorksheetStatus) => {
        if (!worksheet?.id) return
        await updateStatus.mutateAsync([{
            id: worksheet.id,
            status: toStatus,
            ignoreStatusChangeRule: true
        }])
        onStateChanged?.()
    }

    return (
        <Stack direction="row" spacing={1} alignItems="center">
            <WorksheetStateDisplay
                status={status}
                stackProps={{
                    py: 1,
                    justifyContent: "left"
                }}
            />
            {canBeEvaluated && (
                <Button
                    disabled={disableEvalButton || updateStatus.isLoading}
                    onClick={() => setEvalTo(WorksheetStatusMap.evaluated)}
                >
                    {tk('EvaluateButton')}
                    {updateStatus.isLoading && (
                        <CircularProgress/>
                    )}
                </Button>
            )}
            {isEvaluated && (
                <ConfirmDialogButton
                    buttonProps={{disabled: updateStatus.isLoading}}
                    onConfirm={() => setEvalTo(removeEvalToState)}
                    buttonRender={(props, onButtonClick) => (
                        <Button
                            disabled={updateStatus.isLoading}
                            onClick={onButtonClick}
                        >
                            <Stack direction="row" spacing={1} alignItems="center">
                                {removeEvalText}
                                {updateStatus.isLoading && (
                                    <CircularProgress size="small"/>
                                )}
                            </Stack>
                        </Button>
                    )}
                />
            )}
        </Stack>
    );
};

export default WEvalHeaderStatus;