import React from 'react';
import {CDAMWMWServiceCallItemRowSave} from "crowbar-api";
import {FormControl, MenuItem, Select, SelectChangeEvent} from '@mui/material';
import useMechanics from "crowbar-api/hooks/mechanic/useMechanics";
import useTranslateFunction from "shared/language/useTranslateFunction";
import ServiceCallItemRowStatusMap from "crowbar-api/enum-map/ServiceCallItemRowStatusMap";
import useIsTimeMatch from "crowbar-api/hooks/worksheet/validations/useIsTimeMatch";
import {useServiceCallItemRowAtom} from "modules/worksheet/editor/state/atoms/useServiceCallItemRowAtom";
import useWorksheetSignedState from "modules/worksheet/editor/state/helpers/useWorksheetSignedState";

export interface WEditorPageCatalogueRowMechanicSelectProps {
    itemRow: CDAMWMWServiceCallItemRowSave
}

const WEditorPageCatalogueRowMechanicSelect = ({itemRow}: WEditorPageCatalogueRowMechanicSelectProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    const [, setItemRow] = useServiceCallItemRowAtom(itemRow.serviceCallId, itemRow.id)

    const {editDisabled} = useWorksheetSignedState()

    // const [selected, setSelected] = useNewAtomWithRef<string | undefined>(itemRow.timeUserId ?? undefined)

    const mechanicsQuery = useMechanics()
    const isTimeMatch = useIsTimeMatch(itemRow)

    const disabled = editDisabled || itemRow.status === ServiceCallItemRowStatusMap.installed

    const onChange = (e: SelectChangeEvent) => {
        // setSelected(e.target.value as string)
        setItemRow(prev => {
            return {
                ...prev,
                timeUserId: e.target.value as string
            }
        })
    }

    if (isTimeMatch) {
        return (
            <FormControl fullWidth size="small">
                <Select
                    required
                    value={
                        mechanicsQuery?.data?.find(m => m.userId === itemRow.timeUserId) !== undefined
                            ? itemRow.timeUserId ?? "" :
                            ""
                    }
                    //    label={tk('Mechanic')}
                    onChange={onChange}
                    disabled={disabled}
                >
                    {(mechanicsQuery.data ?? []).map(m => (
                        <MenuItem key={m.userId} value={m.userId}>{m.fullName}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        )
    } else {
        return <></>
    }
};

export default WEditorPageCatalogueRowMechanicSelect;