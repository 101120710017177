import React from 'react';
import useTranslateFunction from "shared/language/useTranslateFunction";
import {CDAMWMServiceCallEvalModel, CDAMWMServiceCallModel, CDAMWMWWorksheetQuery} from "crowbar-api";
import ServiceCallSelectedSourceMap from "crowbar-api/enum-map/ServiceCallSelectedSourceMap";

export type ManufacturerSnCellProps = {
    row: CDAMWMServiceCallEvalModel
    worksheet: CDAMWMWWorksheetQuery | null | undefined
    serviceCalls: CDAMWMServiceCallModel[]
}
const ManufacturerSnCell = ({row, worksheet, serviceCalls}: ManufacturerSnCellProps) => {
    const tk = useTranslateFunction('Admin.Worksheet')
    const sc = serviceCalls.find(sc => sc.id === row.serviceCallId)
    if (!sc) return (
        <span>-</span>
    )

    if (sc.selectedSource === ServiceCallSelectedSourceMap.empty) {
        return (
            <span>-</span>
        )
    } else {
        const modEquipment = !row.equipmentChanged && sc.modSapEquipmentId
            ? (worksheet?.modSapEquipments ?? []).find(m => m.id === sc.modSapEquipmentId)
            : undefined

        return (
            modEquipment ? (
                <span>{modEquipment?.manufacturerSn}</span>
            ) : (
                <span>{sc?.manufacturerSn}</span>
            )
        )
    }
};

export default ManufacturerSnCell;