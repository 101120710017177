import React from 'react';
import {CCMLLastUserActionEntry, CDCMUserDetails} from "crowbar-api";
import {Card, CardContent, TextField, Typography} from "@mui/material";
import DateDisplay from "shared/components/date-display/DateDisplay";

export interface LastUserActivityCardProps {
    lua: CCMLLastUserActionEntry
    userDetails?: CDCMUserDetails
}

const LastUserActivityCard = ({lua, userDetails}: LastUserActivityCardProps) => {
    return (
        <Card elevation={16}>
            <CardContent>
                <Typography fontWeight="bold">{userDetails?.fullName}</Typography>
                <Typography variant="caption" fontWeight="bold">
                    <DateDisplay dateStr={lua.loggedAt ?? ""}/>
                </Typography>
                <Typography fontStyle="italic">{userDetails?.email}</Typography>
                <Typography variant="caption">{lua.userId}</Typography>

                <TextField
                    value={JSON.stringify(lua, null, 2) ?? ""}
                    multiline={true}
                    minRows={3}
                    maxRows={6}
                    fullWidth
                />
            </CardContent>
        </Card>
    );
};

export default LastUserActivityCard;