import {atom, SetStateAction, useAtom} from "jotai";
import {useCallback} from "react";

export interface WorksheetSignatureState {
    worksheetIdOptions: WorksheetIdOption[]
    selectedWorksheetId: string | undefined
    currentServiceCallIndex: number
}

export interface WorksheetIdOption {
    id: string
    serialId?: string | null
}

export interface UseWorksheetSignatureStateResult extends WorksheetSignatureState {
    setState: (update: SetStateAction<WorksheetSignatureState>) => void

    changeSelectedWorksheet: (worksheetId: string) => void
    prepareFor: (worksheets: WorksheetIdOption[]) => void
}

const defaultState: WorksheetSignatureState = {
    worksheetIdOptions: [],
    selectedWorksheetId: undefined,
    currentServiceCallIndex: 0
}

const stateAtom = atom<WorksheetSignatureState>(defaultState)

const useWorksheetSignatureState = (): UseWorksheetSignatureStateResult => {

    const [state, setState] = useAtom(stateAtom)

    const changeSelectedWorksheet = useCallback((worksheetId: string) => {
        setState(prev => {
            return {
                ...prev,
                selectedWorksheetId: worksheetId,
                currentServiceCallIndex: 0
            }
        })
    }, [setState])

    const prepareFor = (worksheets: WorksheetIdOption[]) => {
        if (!worksheets?.length || worksheets.length < 1) {
            throw new Error('Could not prepare signature for no worksheets provided.')
        }
        const firstId = worksheets.find(() => true)?.id
        setState({
            ...defaultState,
            worksheetIdOptions: worksheets,
            selectedWorksheetId: firstId,
            currentServiceCallIndex: 0
        })
    }

    return {
        ...state,
        setState,
        changeSelectedWorksheet,
        prepareFor
    }
}

export default useWorksheetSignatureState