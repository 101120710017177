import React, {useState} from 'react';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {
    NewServiceCallDialogResult
} from "modules/worksheet/editor/shared/dialog/new-service-call-dialog/NewServiceCallDialogResult";
import SelectSinglePartnerEquipment
    from "modules/worksheet/editor/shared/dialog/new-service-call-dialog/SelectSinglePartnerEquipment";
import {CDSMPartnerEquipmentPageRow} from "crowbar-api";
import PartnerEquipmentForm
    from "modules/worksheet/editor/shared/component/partner-equipment-form/PartnerEquipmentForm";
import {
    PartnerEquipmentFormValues,
    usePartnerEquipmentForm
} from "modules/worksheet/editor/shared/component/partner-equipment-form/usePartnerEquipmentForm";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {StringUtils} from "shared/utils/StringUtils";

export interface NewServiceCallDialogProps {
    open: boolean
    onDismiss: () => void
    onSubmit: (result: NewServiceCallDialogResult) => boolean | Promise<boolean>
    cardCode: string | undefined | null
}

const NewServiceCallDialog = ({open, onDismiss, onSubmit, cardCode}: NewServiceCallDialogProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    const [currentType, setCurrentType] = useState<string>('equipment')
    const [selectedEquipment, setSelectedEquipment] = useState<CDSMPartnerEquipmentPageRow | undefined>(undefined)

    const [emptySubject, setEmptySubject] = useState<string>("")

    const partnerEquipmentForm = usePartnerEquipmentForm({
        mode: "new",
        editModEquipment: undefined
    })

    const allowSubmit = (currentType === 'new-equipment' && partnerEquipmentForm.formState.isValid) ||
        (currentType === 'empty' && StringUtils.notUndefinedNullAndEmpty(emptySubject)) ||
        (currentType === 'equipment' && selectedEquipment)

    const resetDialog = () => {
        setSelectedEquipment(undefined)
        setEmptySubject("")
    }

    const onClose = () => {
        onDismiss()
        resetDialog()
    }

    const onTypeChange = (e: any, value: string) => {
        setCurrentType(value)
        setSelectedEquipment(undefined)
    }

    const onDialogSubmit = async () => {
        let submitHandled = false
        if (currentType === 'empty') {
            submitHandled = await onSubmit({
                mode: "empty",
                emptySubject: emptySubject
            })
        } else if (currentType === 'equipment') {
            submitHandled = await onSubmit({
                mode: "equipment",
                selectedEquipment: selectedEquipment
            })
        } else if (currentType === 'new-equipment') {
            const handleFunction = partnerEquipmentForm.handleSubmit(onPartnerEquipmentFormSubmit, e => console.error(e))
            // handleSubmit returns a function that needs to be called. So () is missing at the end.
            await handleFunction()
            submitHandled = true
        }

        if (submitHandled) {
            resetDialog()
        }
    }

    const onEquipmentSelect = (equipment?: CDSMPartnerEquipmentPageRow) => {
        setSelectedEquipment(equipment)
    }

    const onPartnerEquipmentFormSubmit = (formData: PartnerEquipmentFormValues): void => {
        onSubmit({
            mode: "new-equipment",
            newPartnerFormValues: formData
        })
    }

    const onDialogClose = (e: any, reason: "backdropClick" | "escapeKeyDown") => {
        if (reason === "escapeKeyDown") {
            onClose()
        }
        // else noop
    }

    return (
        <Dialog open={open} onClose={onDialogClose}>
            <DialogTitle>
                {tk('NewServiceCallDialogTitle')}
            </DialogTitle>
            <DialogContent>
                <Stack direction={"column"} spacing={2} sx={{
                    minHeight: currentType === 'equipment' ? "70vh" : "25vh",
                    minWidth: "300px"
                }}>
                    <Stack direction={"row"} spacing={2} alignItems="center">
                        <FormLabel>{tk('Source')}:</FormLabel>
                        <RadioGroup row
                                    value={currentType}
                                    onChange={onTypeChange}
                        >
                            <FormControlLabel value="equipment" control={<Radio/>} label={tk('WithEquipment')}/>
                            <FormControlLabel value="new-equipment" control={<Radio/>} label={tk('WithNewEquipment')}/>
                            <FormControlLabel value="empty" control={<Radio/>} label={tk('WithoutEquipment')}/>
                        </RadioGroup>
                    </Stack>

                    {currentType === 'empty' && (
                        <Stack direction="column" spacing={1}>
                            <Typography>
                                {tk('NewServiceCallDialogEmptyText')}
                            </Typography>
                            <TextField
                                fullWidth
                                label={tk('Subject')}
                                value={emptySubject}
                                onChange={e => setEmptySubject(e.target.value?.substring(0, 100))}
                            />
                        </Stack>

                    )}
                    {currentType === 'equipment' && (
                        <SelectSinglePartnerEquipment pageLimit={10}
                                                      cardCode={cardCode}
                                                      orderByDesc={false}
                                                      onEquipmentSelected={onEquipmentSelect}
                        />
                    )}
                    {currentType === 'new-equipment' && (
                        <PartnerEquipmentForm
                            mode={"new"}
                            form={partnerEquipmentForm}
                        />
                    )}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="error" onClick={() => onClose()}>
                    {tk('Cancel')}
                </Button>
                <div style={{flex: "1 0 0"}}/>
                <Button
                    variant="contained" color="primary"
                    onClick={() => onDialogSubmit()}
                    disabled={!allowSubmit}
                >
                    {tk('Add')}
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default NewServiceCallDialog;