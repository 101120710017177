import {worksheetSaveStateAtom} from "modules/worksheet/editor/state/WorksheetSaveAtoms";
import {useAtom} from "jotai";
import {useMemo} from "react";
import {focusAtom} from "jotai-optics";

export const useServiceCallTranscriptionsAtom = (
    serviceCallId: string | undefined) => {

    const focusedAtom = useMemo(() => {
        return focusAtom(worksheetSaveStateAtom, (optic) =>
            optic
                .optional()
                .prop('serviceCalls')
                .optional()
                .find(sc => sc.id === serviceCallId)
                .prop('transcriptions')
        )
    }, [serviceCallId])

    return useAtom(focusedAtom)
}