import {MRT_Localization} from "material-react-table";

export const MRT_Localization_HU: MRT_Localization = {
    actions: 'Műveletek',
    and: 'és',
    cancel: 'Mégsem',
    changeFilterMode: 'Szűrési mód váltása',
    changeSearchMode: 'Keresési mód váltása',
    clearFilter: 'Szűrő törlése',
    clearSearch: 'Keresés törlése',
    clearSort: 'Rendezés törlése',
    clickToCopy: 'Kattintson a másoláshoz',
    columnActions: 'Oszlop Műveletek',
    copiedToClipboard: 'Vágólapra másolva',
    dropToGroupBy: 'Drop to group by {column}',
    edit: 'Szerkesztés',
    expand: 'Bővítés',
    expandAll: 'Minden bővítése',
    filterArrIncludes: 'Tartalmazza',
    filterArrIncludesAll: 'Összeset tartalmazza',
    filterArrIncludesSome: 'Tartalmazza',
    filterBetween: 'Között',
    filterBetweenInclusive: 'Inkluzív között',
    filterByColumn: 'szűrés {column} szerint',
    filterContains: 'Tartalmazza',
    filterEmpty: 'Üres',
    filterEndsWith: 'Végződik',
    filterEquals: 'Egyenlő',
    filterEqualsString: 'Megegyezik',
    filterFuzzy: 'Fuzzy',
    filterGreaterThan: 'Nagyobb, mint',
    filterGreaterThanOrEqualTo: 'Nagyobb vagy egyenlő, mint',
    filterInNumberRange: 'Között',
    filterIncludesString: 'Tartalmazza',
    filterIncludesStringSensitive: 'Tartalmazza',
    filterLessThan: 'Kevesebb mint',
    filterLessThanOrEqualTo: 'Kevesebb vagy egyenlő',
    filterMode: 'Szűrési mód: {filterType}',
    filterNotEmpty: 'Nem üres',
    filterNotEquals: 'Nem egyenlő',
    filterStartsWith: 'Kezdődik',
    filterWeakEquals: 'Egyenlő',
    filteringByColumn: 'Szűrés {column} - {filterType} {filterValue}',
    goToFirstPage: 'Az első oldalra',
    goToLastPage: 'Tovább az utolsó oldalra',
    goToNextPage: 'Tovább a következő oldalra',
    goToPreviousPage: 'Vissza az előző oldalra',
    grab: 'Fogd meg',
    groupByColumn: 'Csoportosítva {column}',
    groupedBy: 'Csoportosítva ',
    hideAll: 'Mindent elrejteni',
    hideColumn: '{column} elrejtése',
    max: 'Max',
    min: 'Min',
    move: 'Mozgatás',
    noRecordsToDisplay: 'Nincs megjelenítendő rekord',
    noResultsFound: 'Nem találhatü eredmény',
    of: '/',
    or: 'vagy',
    pinToLeft: 'Kitúzni balra',
    pinToRight: 'Kitűzni jobbra',
    resetColumnSize: 'Oszlopméret visszaállítása',
    resetOrder: 'Sorrend visszaállítása',
    rowActions: 'Sor műveletek',
    rowNumber: '#',
    rowNumbers: 'Sorszámok',
    rowsPerPage: 'Sorok oldalanként',
    save: 'Mentés',
    search: 'Keresés',
    selectedCountOfRowCountRowsSelected:
        '{selectedCount} / {rowCount} sorból kiválasztva',
    select: 'Kiválaszt',
    showAll: 'Összes mutatása',
    showAllColumns: 'Minden oszlop megjelenítése',
    showHideColumns: 'Oszlopok megjelenítése/elrejtése',
    showHideFilters: 'Szűrők megjelenítése/elrejtése',
    showHideSearch: 'Keresés megjelenítése/elrejtése',
    sortByColumnAsc: 'Rendezés {column} növekvő',
    sortByColumnDesc: 'Rendezés {column} csökkenő',
    sortedByColumnAsc: 'Rendezve {column} növekvő',
    sortedByColumnDesc: 'Rendezve {column} csökkenő',
    thenBy: ', utána a ',
    toggleDensity: 'Sűrűség váltása',
    toggleFullScreen: 'Teljes képernyő',
    toggleSelectAll: 'Összes kiválasztása',
    toggleSelectRow: 'Sor kiválasztásának váltása',
    toggleVisibility: 'Láthatóság átkapcsolása',
    ungroupByColumn: 'Csoportosítás feloldása {column}',
    unpin: 'Kitűzés megszüntetése',
    unpinAll: 'Minden kitűzés megszüntetése',
    unsorted: 'Rendezetlen',
    collapse: "Összezárás",
    collapseAll: "Összes összezárása"
}