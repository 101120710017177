import React from 'react';
import {CircularProgress, Grid, Typography} from "@mui/material";
import WorksheetSignatureNavigation from "modules/worksheet/signature/head/WorksheetSignatureNavigation";
import WorksheetSignature from "modules/worksheet/signature/WorksheetSignature";
import useWorksheetSignatureState from "modules/worksheet/signature/useWorksheetSignatureState";
import useWorksheetQueryAsSave from "crowbar-api/hooks/worksheet/useWorksheetQueryAsSave";
import {CDAMWMWWorksheetSignatureUpdate, CDAMWMWWorksheetStatusAndSignatureQuery} from "crowbar-api";

export type NavigableWorksheetSignaturesProps = {
    worksheetStatuses: CDAMWMWWorksheetStatusAndSignatureQuery[]
    returnUrl?: string

    onSignatureUpdated: (updates: CDAMWMWWorksheetSignatureUpdate[]) => void
}
const NavigableWorksheetSignatures = ({
                                          returnUrl,
                                          worksheetStatuses,
                                          onSignatureUpdated
                                      }: NavigableWorksheetSignaturesProps) => {

    const {selectedWorksheetId} = useWorksheetSignatureState()

    const worksheetQuery = useWorksheetQueryAsSave(selectedWorksheetId)

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <WorksheetSignatureNavigation/>
            </Grid>
            <Grid item xs={12}>
                {worksheetQuery.isLoading || worksheetQuery.isFetching ? (
                    <CircularProgress/>
                ) : worksheetQuery.data ? (
                    <WorksheetSignature
                        onCompleteReturnUrl={returnUrl}
                        currentWorksheet={worksheetQuery.data}
                        onSignatureUpdated={onSignatureUpdated}
                        worksheetStatuses={worksheetStatuses}
                    />
                ) : (
                    <Typography>
                        Failed to load worksheet for signature.
                    </Typography>
                )}

            </Grid>
        </Grid>
    );
};

export default NavigableWorksheetSignatures;