import React from 'react';
import {
    Checkbox,
    LinearProgress,
    Paper,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import {useTranslation} from "react-i18next";
import {CDSMSapPartner} from "crowbar-api";

export interface BusinessPartnerTableProps {
    items: CDSMSapPartner[]
    selected?: CDSMSapPartner
    onSelectClick: (item: CDSMSapPartner) => void
    isLoading: boolean
}

const BusinessPartnerTable = ({items, selected, onSelectClick, isLoading}: BusinessPartnerTableProps) => {
    const {t} = useTranslation();
    const TKey = "DeliveryNote.SelectPartner.";

    return (
        <TableContainer component={Paper}>
            {isLoading ? <LinearProgress variant={"query"}/> : <LinearProgress variant={"determinate"} value={0}/>}
            <Table padding={"none"} sx={{minWidth: "700px", tableLayout: "fixed", overflowWrap: "break-word"}}>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{minWidth: "5%", width: "6%", paddingX: "5px"}}></TableCell>
                        <TableCell sx={{minWidth: "8%", width: "15%", paddingX: "5px"}}
                                   key={"cardCode"}>{t(`${TKey}ColCardCode`)}</TableCell>
                        <TableCell sx={{paddingX: "5px"}}
                                   key={"cardName"}>{t(`${TKey}ColCardName`)}</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {isLoading ?
                        [...Array(14)].map((_, i) => {
                            return (
                                <TableRow key={i}>
                                    <TableCell align={"center"} sx={{height: "40px"}}>
                                        <Checkbox disabled/>
                                    </TableCell>
                                    <TableCell colSpan={2} sx={{paddingRight: 5}}>
                                        <Skeleton animation="wave" height={35}/>
                                    </TableCell>
                                </TableRow>)
                        })
                        :
                        items.map((item) => {
                            return (<TableRow
                                hover
                                selected={selected === item}
                                key={item.cardCode}
                                sx={{
                                    '&:last-child td, &:last-child th': {border: 0},
                                    ':hover': {cursor: "pointer"}
                                }}
                                onClick={() => onSelectClick(item)}
                            >
                                <TableCell align={"center"} sx={{height: "40px"}}><Checkbox
                                    checked={selected === item}/></TableCell>
                                <TableCell sx={{paddingX: "5px"}}>{item.cardCode}</TableCell>
                                <TableCell sx={{paddingX: "5px"}}>{item.cardName}</TableCell>
                            </TableRow>)
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default BusinessPartnerTable;