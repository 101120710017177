import React from 'react';
import {CDSMNNewWorksheetSalesOrder} from "crowbar-api";
import SapPartnerAddressUtils from "crowbar-api/util/SapPartnerAddressUtils";
import {Grid, Stack, Typography} from '@mui/material';
import ReceiptIcon from "@mui/icons-material/Receipt";
import LocationOpenMapsIconButton from "shared/components/buttons/LocationOpenMapsIconButton";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import useTranslateFunction from "shared/language/useTranslateFunction";

export interface NewWorksheetResultSalesOrderAddressesProps {
    salesOrder: CDSMNNewWorksheetSalesOrder
}

const NewWorksheetResultSalesOrderAddresses = ({salesOrder}: NewWorksheetResultSalesOrderAddressesProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.NewWorksheet`)
    const billingAddress = SapPartnerAddressUtils.addressFormat({
        zipCode: salesOrder.billingAddressZipCode,
        city: salesOrder.billingAddressCity,
        street: salesOrder.billingAddressStreet,
        streetNo: salesOrder.billingAddressStreetNo
    })

    const shippingAddress = SapPartnerAddressUtils.addressFormat({
        zipCode: salesOrder.shippingAddressZipCode,
        city: salesOrder.shippingAddressCity,
        street: salesOrder.shippingAddressStreet,
        streetNo: salesOrder.shippingAddressStreetNo
    })

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <ReceiptIcon/>
                    <Typography>
                        {tk('BillingAddress')}<br/>{billingAddress}
                    </Typography>
                    <LocationOpenMapsIconButton address={billingAddress}/>
                </Stack>
            </Grid>
            {shippingAddress && (
                <Grid item xs={12} md={6}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <LocalShippingIcon/>
                        <Typography>
                            {tk('ShippingAddress')}<br/>{shippingAddress}
                        </Typography>
                        <LocationOpenMapsIconButton address={shippingAddress}/>
                    </Stack>
                </Grid>
            )}
        </Grid>
    );
};

export default NewWorksheetResultSalesOrderAddresses;