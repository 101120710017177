import React from 'react';
import {Box, LinearProgress, Stack, Typography} from "@mui/material";

export interface LinearIndeterminateProgressWithLabelProps {
    label?: string
}

const LinearIndeterminateProgressWithLabel = ({label}: LinearIndeterminateProgressWithLabelProps) => {
    return (
        <Stack
            direction="row"
            spacing={0.5}
            sx={{
                width: 1
            }}
            alignItems="center"
        >
            <Box sx={{width: 1}}>
                <LinearProgress/>
            </Box>

            {label && (
                <Typography variant="body2">
                    {label}
                </Typography>
            )}
        </Stack>
    )
}

export default LinearIndeterminateProgressWithLabel;