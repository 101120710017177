import {Button, Grid} from "@mui/material";
import React from "react";
import useTranslateFunction from "shared/language/useTranslateFunction";

export interface BasicCenteredPagingButtonsProps {
    isLoading?: boolean

    hasPreviousPage: boolean
    hasNextPage: boolean

    onPreviousClick: () => void
    onNextClick: () => void
}

const BasicCenteredPagingButtons = ({
                                        isLoading,
                                        hasPreviousPage,
                                        hasNextPage,
                                        onPreviousClick,
                                        onNextClick
                                    }: BasicCenteredPagingButtonsProps) => {

    const tk = useTranslateFunction(`Generic`)

    return (
        <>
            {isLoading && (
                <Grid container
                      direction={"row"}
                      sx={{
                          width: 1,
                          display: "flex",
                          justifyContent: "center"
                      }}>

                    <Grid item>
                        <Button
                            disabled={!hasPreviousPage}
                            onClick={onPreviousClick}
                        >{tk('Previous')}</Button>
                    </Grid>
                    <Grid item>
                        <Button
                            disabled={!hasNextPage}
                            onClick={onNextClick}
                        >{tk('Next')}</Button>
                    </Grid>

                </Grid>
            )}
        </>
    )
}

export default BasicCenteredPagingButtons