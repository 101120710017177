import {useMutation} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {CDAMWMWWorksheetSignatureUpdate, WorksheetApi} from "crowbar-api";

/**
 * Updates the signature, state and signName directly. (without a full worksheet save if used from editor)
 * Applicable to batch signature updates for multiple worksheets.
 * Note for Editor: Because this bypasses the worksheetSave localStorage --> worksheet merge save
 * the editor must update the local state by hand
 */
export const useWorksheetUpdateSignature = () => {
    return useMutation(['worksheet-update-signature-and-status'], async (e: CDAMWMWWorksheetSignatureUpdate[]) => {
        // Save the signature directly
        const response = await crowbarApiFactory(WorksheetApi)
            .updateSignatureAndStatusFor(e)
        if (response.status !== 200) {
            throw new Error('Failed to save signature. Server returned a not OK state.')
        }
    })
}