import {CDAMWMModSapStatus} from "crowbar-api";

export default class ModSapStatusMap {
    public static readonly suggested = CDAMWMModSapStatus.NUMBER_0
    public static readonly rejected = CDAMWMModSapStatus.NUMBER_1
    public static readonly accepted = CDAMWMModSapStatus.NUMBER_2

    public static translationKey = (t?: CDAMWMModSapStatus): string => {

        const preKey = "Worksheet.Generic.ModSapStatus."

        if (t === this.suggested) {
            return `${preKey}Suggested`
        }

        if (t === this.rejected) {
            return `${preKey}Rejected`
        }

        if (t === this.accepted) {
            return `${preKey}Accepted`
        }

        return "Missing"
    }
}