import React from 'react';
import WorksheetSelectedDataSourceMap from "crowbar-api/enum-map/WorksheetSelectedDataSourceMap";
import {PartnerDisplay} from "modules/admin/worksheet/evaluation/tabs/master-data/PartnerDisplay";
import {CDAMWMWWorksheetQuery} from "crowbar-api";

export type PartnerMasterDataCurrentProps = {
    worksheet: CDAMWMWWorksheetQuery | null | undefined
}
const PartnerMasterDataCurrent = ({worksheet}: PartnerMasterDataCurrentProps) => {
    const ws = worksheet
    if (!ws) return (
        <></>
    )

    const isMod = ws.partnerSource === WorksheetSelectedDataSourceMap.modSap
    const modId = ws.modSapPartnerId;
    const mod = ws.modSapPartners?.find(p => p.id === modId)

    const partnerCardCode = !isMod ? ws.cardCode : undefined
    const partnerName = isMod && mod ? mod.partnerName : ws.partnerCardName
    const vatLocal = isMod && mod ? mod.vatLocal : ws.partnerVatHu
    const vatInt = isMod && mod ? mod.vatInternational : ws.partnerVatInternational

    return (
        <PartnerDisplay
            isMod={isMod}
            modType={mod?.type}
            cardCode={partnerCardCode}
            partnerName={partnerName}
            vatLocal={vatLocal}
            vatInternational={vatInt}
        />
    );
};

export default PartnerMasterDataCurrent;