import React from 'react';
import {Box, Card, CardContent, CardHeader, Stack, Typography} from "@mui/material";
import EquipmentThumbnail from "modules/worksheet/editor/shared/component/equipment-thumbnail/EquipmentThumbnail";
import {useAzureBlobStorageUrlFactory} from "shared/hook/useAzureBlobStorageUrlFactory";
import VehicleTypeDisplay from "modules/worksheet/editor/shared/component/vehicle-type-display/VehicleTypeDisplay";
import useTranslateFunction from "shared/language/useTranslateFunction";

export interface NewWorksheetEquipmentCardProps {
    coverImage?: string | null | undefined
    manufacturer?: string | null | undefined
    vehicleType?: string | null | undefined
    manufacturerSn?: string | null | undefined
    internalSn?: string | null | undefined
    yearOfManufacture?: number | null | undefined
}

const NewWorksheetEquipmentCard = (props: NewWorksheetEquipmentCardProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.NewWorksheet`)
    const blobUrlFac = useAzureBlobStorageUrlFactory()

    const coverImageUrls = blobUrlFac.sapImageFromName(props.coverImage)

    return (
        <Card variant="outlined" sx={{height: 1}}>
            <CardHeader
                title={(
                    <VehicleTypeDisplay
                        manufacturer={props.manufacturer}
                        vehicleType={props.vehicleType}
                        sx={{
                            maxWidth: "135px",
                            textOverflow: "ellipsis" // ?
                        }}
                    />
                )}
            />
            <CardContent>
                <Stack direction="column" sx={{width: 1}} spacing={2}>

                    <Box
                        sx={{
                            width: 1,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <EquipmentThumbnail size={150} thumbSrc={coverImageUrls.thumbMedium}/>
                    </Box>

                    <Stack
                        direction="column"
                        spacing={0}
                        sx={{
                            maxWidth: "135px"
                        }}
                    >
                        <Typography variant="body1" fontWeight="bold">
                            SN: {props.manufacturerSn}
                        </Typography>

                        {props.internalSn && (
                            <Typography variant="body1" fontWeight="bold">
                                {tk('InternalSn')}:&nbsp;{props.internalSn}
                            </Typography>
                        )}
                        {props.yearOfManufacture && (
                            <Typography variant="caption">
                                {props.yearOfManufacture}
                            </Typography>
                        )}
                    </Stack>

                </Stack>
            </CardContent>
        </Card>
    )
}

export default NewWorksheetEquipmentCard;