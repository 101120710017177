import {atom, SetStateAction, useAtom} from "jotai";
import usePhotoRuleDetailsById from "crowbar-api/hooks/photo-rule/usePhotoRuleDetailsById";
import useAllPhotoRulesAndManufacturers from "crowbar-api/hooks/photo-rule/useAllPhotoRulesAndManufacturers";
import {UseQueryResult} from "@tanstack/react-query";
import {CDAMPMPhotoRuleAndManufacturersQuery, CDAMPMPhotoRuleDetailsQuery} from "crowbar-api";

export interface DisplayHelpDialogState {
    open: boolean

    selectedServiceCallId: string | undefined

    selectedManufacturerCode: string | undefined
    selectedRuleId: string | undefined
}

const initialValue = {
    open: false,
    selectedServiceCallId: undefined,
    selectedManufacturerCode: undefined,
    selectedRuleId: undefined,
}

const stateAtom = atom<DisplayHelpDialogState>(initialValue)

export interface UseDisplayHelpDialogStateResult extends DisplayHelpDialogState {
    setDialogState: (update: SetStateAction<DisplayHelpDialogState>) => void

    ruleDetailsQuery: UseQueryResult<CDAMPMPhotoRuleDetailsQuery | null, unknown>
    //  psgManufacturersQuery: UseQueryResult<CDSMSapPsgManufacturer[], unknown>
    rulesAndManufacturersQuery: UseQueryResult<CDAMPMPhotoRuleAndManufacturersQuery[], unknown>

}

export const useDisplayHelpDialogState = (): UseDisplayHelpDialogStateResult => {
    const [dialogState, setDialogState] = useAtom(stateAtom)

    const ruleDetailsQuery = usePhotoRuleDetailsById(dialogState.selectedRuleId)
    //const psgManufacturersQuery = usePsgManufacturers()

    const rulesAndManufacturersQuery = useAllPhotoRulesAndManufacturers()

    return {
        ...dialogState,
        setDialogState,
        ruleDetailsQuery,
        // psgManufacturersQuery,
        rulesAndManufacturersQuery
    }
}