import React from 'react';
import {Box, Button, ButtonProps, CircularProgress, Stack} from "@mui/material";
import useTranslateFunction from "shared/language/useTranslateFunction";

import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import {useWorksheetCartState} from "modules/worksheet/worksheet-cart/useWorksheetCartState";
import {
    AddToWorksheetCartRequiredFields,
    useWorksheetCartAddOrRemoveEntry
} from "modules/worksheet/worksheet-cart/useWorksheetCartAddOrRemoveEntry";
import {toastInfo} from "shared/toast/DefaultToasts";
import WorksheetStatusMap from "crowbar-api/enum-map/WorksheetStatusMap";


export type AddToWorksheetCartButtonProps = ButtonProps & {
    scope?: number | string
    worksheet: AddToWorksheetCartRequiredFields
}

const AddToWorksheetCartButton = ({scope, worksheet, ...props}: AddToWorksheetCartButtonProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    const {isInCart} = useWorksheetCartState(scope)


    const addOrRemoveEntry = useWorksheetCartAddOrRemoveEntry(scope)

    const onClick = async () => {
        const r = await addOrRemoveEntry.mutateAsync(worksheet)
        if (r.added) {
            toastInfo(tk('SignAdded'), {toastId: "SignAdded"})
        }
        if (r.removed) {
            toastInfo(tk('SignRemoved'), {toastId: "SignRemoved"})
        }
    }

    if (worksheet.status !== WorksheetStatusMap.final /* final needs to be signed only */) {
        return <Box></Box>
    }

    return (
        <Button
            color="secondary"
            variant="outlined"
            onClick={() => onClick()}
            {...props}
        >
            <Stack
                direction="row"
                spacing={0.5}
                sx={{
                    display: "flex",
                    alignItems: "center"
                }}>

                {isInCart(worksheet.id) ? (
                    <>
                        <CheckIcon/>&nbsp;{tk('RemoveFromSignCart')}
                    </>

                ) : (
                    <>
                        <AddIcon/>&nbsp;{tk('AddToSignCart')}
                    </>
                )}
                {addOrRemoveEntry.isLoading && (
                    <CircularProgress size="24px" color="secondary"/>
                )}
            </Stack>
        </Button>
    );
};

export default AddToWorksheetCartButton;