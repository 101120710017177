import RecordRTC, {RecordRTCPromisesHandler} from 'recordrtc'

import MicIcon from '@mui/icons-material/Mic'
import StopCircleIcon from '@mui/icons-material/StopCircle'
import PauseCircleIcon from '@mui/icons-material/PauseCircle'

import {useAtom} from "jotai"
import React from "react"
import {DurationDisplay} from "shared/components/audio/DurationDisplay";
import {CircularProgress, IconButton, Stack} from "@mui/material";
import MicOffIcon from '@mui/icons-material/MicOff';
import {msalTeamsAuthenticationHappenedAtom} from "App";

import {media as TeamsMedia, SdkError} from "@microsoft/teams-js";
import {toastError, toastWarn} from "shared/toast/DefaultToasts";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {CCMCMCrowbarBlobAndUri, CrowbarBlobApi} from "crowbar-api";
import axios, {CancelTokenSource} from "axios";
import {useNewAtomWithRef} from "shared/hook/useNewatomWithRef";
import useTranslateFunction from "shared/language/useTranslateFunction";

const timeSlice = 500

export interface IMicrophoneRecorderProps {
    title?: string
    hideAudioUi?: boolean
    hideDuration?: boolean
    hideDurationWhileNotRecording?: boolean
    disabled?: boolean
    hideHeader?: boolean

    onRecorderDone: (blob: CCMCMCrowbarBlobAndUri | undefined) => void
}

export interface IMicrophoneRecorderState {
    recorderInitialized: boolean
    mediaDeviceNotFound: boolean
    recording: boolean
    paused: boolean
    /**
     * Can signal recording cancel.
     * If set, the current recording will be discarded
     */
    cancelled: boolean

    duration?: number

    visualizeBlob?: Blob
}

const initialRecorderState = {
    recorderInitialized: true,
    mediaDeviceNotFound: false,
    recording: false,
    paused: false,

    cancelled: false
}

export const MicrophoneRecorder = (props: IMicrophoneRecorderProps): JSX.Element => {
    const tk = useTranslateFunction(`Generic.Microphone`)
    // Global state
    const [teamsInitialized] = useAtom(msalTeamsAuthenticationHappenedAtom)
    // Local state
    const [state, setState] = useNewAtomWithRef<IMicrophoneRecorderState>(initialRecorderState)

    const [recorderInstance, setRecorderInstance] = useNewAtomWithRef<RecordRTCPromisesHandler | undefined>(undefined)
    const [cancelTokenSource] = useNewAtomWithRef<CancelTokenSource>(axios.CancelToken.source())
    const [blobSavingInProgress, setBlobSavingInProgress] = useNewAtomWithRef<boolean>(false)

    const cancelTokenOptions = {
        cancelToken: cancelTokenSource.token
    }

    /*const onCancelRecording = useCallback(async () => {
        cancelTokenSource.cancel()
        await recorderInstance?.stopRecording()
        setRecorderInstance(undefined)
        setState((p) => {
            return {
                ...p,
                recording: false,
                paused: false,
                cancelled: false,
                duration: 0
            }
        })
    }, [cancelTokenSource, recorderInstance, setRecorderInstance, setState])*/

    const uploadFileToApi = async (blob: Blob): Promise<CCMCMCrowbarBlobAndUri | undefined> => {
        const uploadedFile = new File([blob], `${+new Date()}.bin`, {
            type: blob.type,
            lastModified: new Date().getTime()
        })
        const response = await crowbarApiFactory(CrowbarBlobApi, undefined, true)
            .uploadAudioFile(false, uploadedFile, cancelTokenOptions)
        if (response.status === 200) {
            // toastSuccess("Success. Uploaded. " + response.data?.uris?.uri)
            return response.data
        } else {
            toastError("Failed to upload. " + JSON.stringify(response.data))
            return undefined
        }
    }

    const onRecordingDone = async (blob: Blob): Promise<any> => {
        try {
            setBlobSavingInProgress(true)
            const uploadResult = await uploadFileToApi(blob)
            if (uploadResult) {
                props.onRecorderDone(uploadResult)
            }
        } finally {
            setBlobSavingInProgress(false)
        }
    }

    // events
    const onStartRecording = async (
        e: React.MouseEvent<HTMLButtonElement>
    ): Promise<void> => {
        e.preventDefault()

        // Media capture promise for both Teams and Web
        const mediaCapture = new Promise<boolean>((resolve, reject) => {
            if (!teamsInitialized) {
                return resolve(true)
            }
            const mediaInput: TeamsMedia.MediaInputs = {
                mediaType: TeamsMedia.MediaType.Audio,
                maxMediaCount: 1,
            }
            // https://docs.microsoft.com/en-us/microsoftteams/platform/concepts/device-capabilities/mobile-camera-image-permissions
            TeamsMedia.selectMedia(mediaInput, (error, attachments) => {
                // NOT_SUPPORTED_ON_PLATFORM
                if (error?.errorCode === 100) {
                    return resolve(false)
                } else if (error?.errorCode === 1000 || error?.errorCode === 8000) {
                    toastWarn(tk('UserAbort'))
                    return resolve(false)
                } else if (error?.errorCode === 3000) {
                    toastWarn(tk('HWNotSupported'))
                    return resolve(false)
                } else if (error) {
                    toastError(tk('TeamsError') + ` ${error.errorCode} - ${error.message}`)
                    return resolve(false)
                }

                // Success
                if (!attachments?.length) {
                    toastError(tk('NoRecording'))
                    return resolve(false)
                }
                const audioResult = attachments[0];
                audioResult.getMedia((error: SdkError, blob: Blob) => {
                    if (error || !blob) {
                        toastError(`${error?.message} ${!blob}`)
                        return resolve(false)
                    }
                    // RecordRTC.invokeSaveAsDialog(blob)

                    onRecordingDone(blob)

                    /*uploadFileToApi(blob).then((blobAndUri) => {
                        // toastInfo("Teams recording upload called.")
                        props.onRecorderDone(blobAndUri)
                    })*/

                    return resolve(false)
                })
            })
        })

        mediaCapture.then(async (shouldContinue) => {
            if (!shouldContinue) {
                return;
            }

            const stream = await navigator.mediaDevices.getUserMedia({
                video: false,
                audio: true,
            })

            const recorder = new RecordRTCPromisesHandler(stream, {
                type: 'audio',
                // recorderType: state.recorderType,
                //recorderType: RecordRTC.StereoAudioRecorder,
                recorderType: RecordRTC.MediaStreamRecorder,
                mimeType: 'audio/webm',
                // audioBitsPerSecond: 160000,
                timeSlice: timeSlice,
                ondataavailable: onRecorderDataAvailable,
            })

            // save recorder (jotai)
            setRecorderInstance(recorder)

            /// await prepareMediaDevice()
            /// if (!state.recorder) return
            await recorder.startRecording()

            // set recording
            setState((prev) => {
                return {
                    ...prev,
                    recording: true,
                    paused: false,
                    cancelled: false,
                    duration: 0,
                }
            })
        })
    }

    const onStopRecording = async (
        e: React.MouseEvent<HTMLButtonElement>,
        recorderInstanceProp: RecordRTCPromisesHandler | undefined
    ): Promise<void> => {
        e.preventDefault()

        await recorderInstanceProp?.stopRecording()
        await recorderInstanceProp?.getState();
        const blob = await recorderInstanceProp?.getBlob()

        // set recording
        setState((prev) => {
            return {
                ...prev,
                recording: false,
                paused: false,
                cancelled: false,
                duration: 0,
            }
        })

        // Save audio
        if (blob) {
            await onRecordingDone(blob)
            /*await uploadFileToApi(blob).then(blobAndUri => {
                props.onRecorderDone(blobAndUri)
            })*/
            // RecordRTC.invokeSaveAsDialog(blob) // debug
        }
    }

    const onPauseRecording = async (
        paused: boolean,
        recorderInstanceProp: RecordRTCPromisesHandler | undefined
    ) => {
        if (paused) {
            await recorderInstanceProp?.pauseRecording()
        } else {
            await recorderInstanceProp?.resumeRecording()
        }
        setState((prev) => {
            return {
                ...prev,
                paused: paused,
                cancelled: false
            }
        })
    }

    const onRecorderDataAvailable = (blob: Blob): void => {
        setState((p) => {
            return {
                ...p,
                duration: (p.duration ?? 0) + timeSlice,
                visualizeBlob: blob,
            }
        })
    }

    // render
    if (props.disabled) {
        return <MicOffIcon/>
    }

    if (!state.recorderInitialized) {
        return <CircularProgress/>
    }

    if (state.mediaDeviceNotFound) {
        return <MicOffIcon/>
    }

    return (
        <Stack direction="row" spacing={1} sx={{
            display: "flex",
            alignItems: "center"
        }}>
            {(!props.hideDuration && (!props.hideDurationWhileNotRecording || state.recording)) && (
                <DurationDisplay hideMillis={true} duration={state.duration}/>
            )}

            {state.paused && <p>Paused</p>}
            {!state.recording ? (
                <IconButton
                    onClick={onStartRecording}
                    disabled={blobSavingInProgress}
                >
                    <MicIcon/>
                </IconButton>
            ) : (
                <IconButton color="error" onClick={(e) => onStopRecording(e, recorderInstance)}>
                    <StopCircleIcon/>
                </IconButton>
            )}
            {state.recording && (
                <IconButton
                    onClick={() =>
                        state.paused
                            ? onPauseRecording(false, recorderInstance)
                            : onPauseRecording(true, recorderInstance)
                    }
                >
                    <PauseCircleIcon/>
                </IconButton>
            )}
            {blobSavingInProgress && (
                <Stack direction={"row"} spacing={2} sx={{
                    display: "flex",
                    alignItems: "center"
                }}>
                    <CircularProgress/>
                    <p>{tk('SaveAudio')}</p>
                </Stack>
            )}
        </Stack>
    )
}