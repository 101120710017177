import {Paper, Stack} from '@mui/material';
import React from 'react';
import WorksheetEditorHeaderMenu from "modules/worksheet/editor/ui/header/WorksheetEditorHeaderMenu";
import WorksheetEditorHeaderContent from "modules/worksheet/editor/ui/header/WorksheetEditorHeaderContent";
import WorksheetEditorHeaderAction from "modules/worksheet/editor/ui/header/WorksheetEditorHeaderAction";
import OnlineDetectorBar from "shared/components/OnlineDetector/OnlineDetectorBar";
import {useWindowSize} from "shared/hook/useWindowSize";
import useWorksheetHeaderOpenState from "modules/worksheet/editor/ui/header/useWorksheetHeaderOpenState";
import {
    WEditorHeaderIsCompletedStatusLine
} from "modules/worksheet/editor/ui/header/WEditorHeaderIsCompletedStatusLine";

const WorksheetEditorHeader = () => {
    const {height} = useWindowSize()
    const open = useWorksheetHeaderOpenState()


    return (
        <Paper square
               elevation={3}
               sx={{
                   padding: 0,
                   width: 1,
               }}>

            <Stack direction={"column"} spacing={0} sx={{
                width: 1,
                height: 1
            }}>
                <Stack direction={"row"} spacing={0} sx={{
                    width: 1,
                    height: 1,
                }}>
                    <Stack direction={"column"} spacing={0} sx={{
                        width: 1,
                        height: 1
                    }}>
                        <WorksheetEditorHeaderMenu/>
                        <WEditorHeaderIsCompletedStatusLine/>
                    </Stack>
                </Stack>


                <Stack direction={"column"} spacing={0} sx={{
                    width: 1,
                    padding: 0,
                    // height: height ? `calc(${height}px - 20%)` : '50vh',
                    height: open ? height ? height * 0.70 : '50vh' : 1,
                    minHeight: "100px",
                    overflow: "auto",
                }}>
                    <WorksheetEditorHeaderContent/>
                </Stack>

                <Stack direction={"row"} sx={{
                    marginTop: 0,
                    padding: 1
                }}>
                    <OnlineDetectorBar showOnlineState={true}/>
                    <WorksheetEditorHeaderAction/>
                </Stack>
            </Stack>
        </Paper>
    );
};

export default WorksheetEditorHeader;