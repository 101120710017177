const i18n_homeHu = {
    "language": "Nyelv",
    "lang": "Magyar",
    "langChange": "Nyelv váltás",
    "Hungarian": "Magyar",
    "English": "Angol",
    "ConnectedToTeamsToast": "Teams csatlakozás rendben :)",
    "TabConfig": {
        "Module": "Modul",
        "Select": "Kiválaszt",
        "ServiceIssueReport": "Szerviz hibabejelentő",
        "DeliveryNote": "Szállítólevél kitöltő",
    },
    "Error": {
        "InternalErrorTitle": "Belső hiba",
        "MessageKeyMissing": "Kifejezés kulcsa hiányzik a fordításhoz"
    },
    "FormGeneric": {
        "RequiredField": "Ezt a mezőt kötelező kitölteni."
    },
    "MechanicDashboard": {
        "MyWorksheets": "Munkalapjaim",
        "DraftNum": "Piszkozatok száma",
        "FinalNum": "Aláírásra várók száma",
        "Open": "Megnyitás",
    }
};
export default i18n_homeHu;