import {atom, SetStateAction, useAtom} from "jotai";
import {focusAtom} from "jotai-optics";

export interface WorksheetEditorHeaderState {
    info: WorksheetEditorHeaderInfoState
    //equipments: WorksheetEditorHeaderEquipmentState[]
}

export interface WorksheetEditorHeaderInfoState {
    /**
     * If the worksheet id changes,
     * the whole state can be reset/reloaded for the
     * new, different id.
     * Id must have a value, but initially it is undefined.
     */
    worksheetId: string | null | undefined

    /**
     * If the server has different version than the current one
     * (user changes the worksheet on another device, leaving the local changes hanging)
     */
    differentServerVersionFlag?: boolean

    selectedServiceCallId?: string

    showTextLines?: boolean

    newServiceCallDialogOpen: boolean
}

/*
export interface WorksheetEditorHeaderEquipmentState {
    insId: number
    title: string

    thumbnailImage?: string
}*/
export const WorksheetEditorHeaderStateAtom = atom<WorksheetEditorHeaderState>({
    info: {
        worksheetId: undefined,
        selectedServiceCallId: undefined,
        showTextLines: true,
        newServiceCallDialogOpen: false
    }
})

const infoAtom = focusAtom<WorksheetEditorHeaderState,
    WorksheetEditorHeaderInfoState,
    void | Promise<void>>(WorksheetEditorHeaderStateAtom, optic => optic.prop("info"))

const infoNewServiceCallDialogOpenAtom = focusAtom<WorksheetEditorHeaderState,
    boolean,
    void | Promise<void>>(WorksheetEditorHeaderStateAtom, optic => optic.path("info.newServiceCallDialogOpen"))
/*
const equipmentsAtom = focusAtom<
    WorksheetEditorHeaderState,
    WorksheetEditorHeaderEquipmentState[],
    void | Promise<void>
>(WorksheetEditorHeaderStateAtom, optic => optic.prop('equipments'))*/

export const useWorksheetEditorStateAtom = (): [
    WorksheetEditorHeaderState,
    (update: SetStateAction<WorksheetEditorHeaderState>) => (void | Promise<void>)
] => {
    return useAtom(WorksheetEditorHeaderStateAtom)
}

export const useWorksheetEditorHeaderInfoAtom = (): [
    WorksheetEditorHeaderInfoState,
    (update: SetStateAction<WorksheetEditorHeaderInfoState>) => (void | Promise<void>)
] => {
    const [value, setValue] = useAtom(infoAtom)
    if (!value) {
        throw new Error('Invalid state. Editor header info is undefined!')
    }
    return [value, setValue]
}

export const useInfoNewServiceCallDialogOpenAtom = (): [
    boolean,
    (useUpdate: SetStateAction<boolean>) => (void | Promise<void>)
] => {
    return useAtom(infoNewServiceCallDialogOpenAtom)
}
/*
export const useWorksheetEditorHeaderEquipmentsAtom = (): [
    WorksheetEditorHeaderEquipmentState[],
    (update: SetStateAction<WorksheetEditorHeaderEquipmentState[]>) => (void | Promise<void>)
] => {
    const [value, setValue] = useAtom(equipmentsAtom, scope)
    if (!value) {
        throw new Error('Invalid state. Editor header equipments is undefined!')
    }
    return [value, setValue]
}*/