import {CDAMWMWWorksheetSave, WorksheetApi} from "crowbar-api";
import {UseNewWorksheetFromProps} from "modules/worksheet/editor/state/events/UseNewWorksheetFromProps";
import {useWorksheetSaveService} from "modules/worksheet/service/useWorksheetSaveService";
import {useMutation} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";

export interface UseSaveNewWorksheetViaApiProps extends UseNewWorksheetFromProps {
    newWorksheet: CDAMWMWWorksheetSave
}

/**
 * Saves the new worksheet to the server and localstorage (optionally reloads it from the api).
 * The new worksheet object should be appropriately created by one of the WorksheetSaveFactory methods.
 * The worksheet is saved to the server and local storage by key.
 *
 * @returns MutationFunction<CDAMWMWWorksheetSave, number> The mutation fn returns the created Worksheet.
 *
 * Note: "Opening" a worksheet requires preparation for the ui, so it is done by worksheet.id and it will
 * also load the worksheet from the server (as a WorksheetSave model). Hence, keeping it up to date.
 *
 */
export const useSaveNewWorksheetViaApi = () => {
    const worksheetSaveService = useWorksheetSaveService()

    return useMutation(async (
        {newWorksheet, reloadWorksheetObjectAfterSave}: UseSaveNewWorksheetViaApiProps
    ): Promise<CDAMWMWWorksheetSave> => {
        // Save the worksheet to local storage
        await worksheetSaveService.saveToStorage(newWorksheet)
        // Saving the new worksheet to the server
        const worksheetSaveResponse = await worksheetSaveService
            .saveToServer(newWorksheet.id ?? "", false) // new worksheets have no previous version.
        if (worksheetSaveResponse.hasError) {
            throw new Error("Failed to create new worksheet: " + worksheetSaveResponse.saveError?.message ?? "")
        }

        if (reloadWorksheetObjectAfterSave) {
            const worksheetAsSaveResponse = await crowbarApiFactory(WorksheetApi)
                .findWorksheetQueryByIdAsSave(newWorksheet.id)
            if (worksheetAsSaveResponse.status !== 200) {
                throw new Error('Failed to read worksheet after save. reloadWorksheetObjectAfterSave:true')
            }
            newWorksheet = worksheetAsSaveResponse.data
        }

        return newWorksheet
    })
}