import {StringUtils} from "shared/utils/StringUtils";

export type RequiredFieldsForDescriptionValidation = {
    serviceCalls?: RequiredServiceCallFieldsForDescriptionValidation[]
}

export type RequiredServiceCallFieldsForDescriptionValidation = {
    taskDescription?: string | null
    callTypeId?: number | null | undefined
}

export default class DescriptionCompletedValidation {
    public static isCompleted = (ws?: RequiredFieldsForDescriptionValidation,
                                 taskDescriptionRequiredFn?: (currentCallTypeId: (number | null | undefined)) => boolean): boolean => {
        if (!ws || !ws.serviceCalls) return false

        for (let serviceCall of ws.serviceCalls) {
            const callTypeSubstitutes = !!serviceCall.callTypeId && // we have a selected callType
                !!taskDescriptionRequiredFn &&  // we have a check fn
                !taskDescriptionRequiredFn(serviceCall.callTypeId) // not required according to the check fn

            // not completed, if the callType is not enough or the description is not filled in
            if (!callTypeSubstitutes && !DescriptionCompletedValidation.isCompletedFor(serviceCall)) {
                return false
            }
        }

        return true
    }

    public static isCompletedFor = (sc: RequiredServiceCallFieldsForDescriptionValidation): boolean => {
        return StringUtils.notUndefinedNullAndEmpty(sc.taskDescription)
    }
}