import {useMutation} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {PhotoRuleManufacturerApi} from "crowbar-api";

const usePhotoRuleManufacturerDelete = () => {
    return useMutation(async (id: string) => {
        const response = await crowbarApiFactory(PhotoRuleManufacturerApi).deletePermanentlyById(id)
        if (response.status !== 200) {
            throw new Error('Failed to delete rule manufacturer. Status is not 200.')
        }
    })
}

export default usePhotoRuleManufacturerDelete