import {useQuery} from "@tanstack/react-query";
import AxiosCancelTokenHelper from "crowbar-api/AxiosCancelTokenHelper";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {SapServiceCallApi} from "crowbar-api";

export const useSapServiceCallsByInsId = (insId: number | undefined) => {
    return useQuery(['sap-service-calls-by-insId-1', insId], async (context) => {
        if (!insId) return []
        const response = await crowbarApiFactory(SapServiceCallApi)
            .findByInsId(insId, AxiosCancelTokenHelper.cancelTokenFrom(context.signal))
        return response.data ?? []
    }, {
        refetchOnWindowFocus: false,
        refetchOnMount: true,
        cacheTime: 0,
        staleTime: 0,
        retry: 3
    })
}