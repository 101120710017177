import {useCallback, useState} from 'react';

export type UseDeleteOldWorksheetRecordsResult = {
    isLoading: boolean
    deleteOldWorksheetRecords: (months: number) => Promise<void>
}
export const useDeleteOldWorksheetRecords = (): UseDeleteOldWorksheetRecordsResult => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const deleteOldWorksheetRecords = useCallback(async (months: number): Promise<void> => {
        const dbName = "CrowbarTeams"
        const storeName = "worksheetDocuments"
        return new Promise((resolve, reject) => {
            setIsLoading(true)

            const now = Date.now();
            const monthsAgo = now - (months * 30 * 24 * 60 * 60 * 1000);

            const request = indexedDB.open(dbName);

            request.onsuccess = function (event: any) {
                const db = event.target.result;
                const transaction = db.transaction(storeName, 'readwrite');
                const objectStore = transaction.objectStore(storeName);

                const requestGetAllKeys = objectStore.getAllKeys();

                requestGetAllKeys.onsuccess = function (event: any) {
                    const keys = event.target.result;

                    keys.forEach(function (key: any) {
                        const requestGet = objectStore.get(key);

                        requestGet.onsuccess = function (event: any) {
                            const record = event.target.result;

                            if (record.savedAtTimestamp < monthsAgo) {
                                const requestDelete = objectStore.delete(key);

                                requestDelete.onsuccess = function () {
                                    console.log(`Deleted worksheet record with key ${key}`);
                                };

                                requestDelete.onerror = function () {
                                    console.error(`Error deleting worksheet record with key ${key}`);
                                };
                            }
                        };
                    });
                };

                transaction.oncomplete = function () {
                    console.log('Finished deleting old worksheet records');
                    db.close();
                    setIsLoading(false);
                    resolve();
                };
            };

            request.onerror = function (event: any) {
                console.error('Failed to delete old worksheet records. Error opening database:', event.target.errorCode);
                setIsLoading(false);
                reject(event.target.errorCode);
            };
        });
    }, []);

    return {
        isLoading,
        deleteOldWorksheetRecords
    }
}