import {Route, Routes} from "react-router-dom";
import React from "react";
import {PathRouteProps} from "react-router/dist/lib/components";

export interface WEditorHeaderContentRoutesProps {
    routes: (PathRouteProps)[]
}

const WEditorHeaderContentRoutes = ({routes}: WEditorHeaderContentRoutesProps) => {
    return (
        <Routes>
            {routes.map(r => (
                <Route
                    key={r.path}
                    {...r}
                />
            ))}
        </Routes>
    )
}

export default WEditorHeaderContentRoutes