import React from 'react';

import RefreshIcon from '@mui/icons-material/Refresh';
import {CircularProgress, IconButton, IconButtonProps, SvgIconProps} from "@mui/material";

export type RefreshButtonProps = IconButtonProps & {
    iconProps?: SvgIconProps
    isLoading?: boolean
}
const RefreshButton = ({iconProps, isLoading, ...props}: RefreshButtonProps) => {

    return (
        <IconButton disabled={isLoading} {...props}>
            {isLoading ? (
                <CircularProgress/>
            ) : (
                <RefreshIcon {...iconProps} />
            )}
        </IconButton>
    )
}

export default RefreshButton;