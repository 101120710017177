import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {PhotoRuleStepTemplateApi} from "crowbar-api";
import {useQuery} from "@tanstack/react-query";
import AxiosCancelTokenHelper from "crowbar-api/AxiosCancelTokenHelper";


const usePhotoRuleTemplatesForSteps = (stepIds: string[]) => {
    return useQuery(['photo-rule-template-by-stepIds', stepIds], async (context) => {
        if (!stepIds || stepIds.length === 0) return []
        const response = await crowbarApiFactory(PhotoRuleStepTemplateApi)
            .findTemplatesForStepIds(stepIds, AxiosCancelTokenHelper.cancelTokenFrom(context.signal))
        if (response.status !== 200) {
            throw new Error('Failed to load rule templates by step ids.')
        }

        return response.data
            ?.filter(t => !t.isDeleted)
    })
}

export default usePhotoRuleTemplatesForSteps