import React from 'react';
import {CDAMWMServiceCallEvalModel, CDAMWMServiceCallModel, CDAMWMWWorksheetQuery} from "crowbar-api";
import {Checkbox, FormControlLabel} from "@mui/material";
import {
    useServiceCallEvalUpdateEval
} from "crowbar-api/hooks/worksheet/evaluation/service-call/useServiceCallEvalUpdateEval";
import useTranslateFunction from "shared/language/useTranslateFunction";

export type LinkToSalesOrderCheckboxProps = {
    worksheet: CDAMWMWWorksheetQuery | null | undefined
    sc: CDAMWMServiceCallModel
    scEval: CDAMWMServiceCallEvalModel
    disabled?: boolean
    onMasterDataChange: () => Promise<void>
}
const LinkToSalesOrderCheckbox = ({
                                      worksheet,
                                      sc,
                                      scEval,
                                      disabled,
                                      onMasterDataChange
                                  }: LinkToSalesOrderCheckboxProps) => {
    const tk = useTranslateFunction('Admin.Worksheet')
    const updateEval = useServiceCallEvalUpdateEval()

    const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked;
        await updateEval.mutateAsync({
            id: scEval.id,
            deliveryDraftDocEntry: scEval.deliveryDraftDocEntry,
            equipmentAck: scEval.equipmentAck,
            linkDeliveryToServiceCall: scEval.linkDeliveryToServiceCall,
            linkSalesOrderToDelivery: checked,
            selectedCallId: scEval.selectedCallId,
            selectedDeliveryDocEntry: scEval.selectedDeliveryDocEntry,
            selectedEquipmentInsId: scEval.selectedEquipmentInsId
        })
        await onMasterDataChange()
    }

    const value = scEval?.linkSalesOrderToDelivery ?? false

    return (
        <FormControlLabel
            disabled={disabled || updateEval.isLoading || !!scEval.deliveryAck}
            control={
                <Checkbox
                    onChange={onChange}
                    checked={value}
                />
            }
            label={tk('LinkToSalesOrderDescription')}
        />
    )
}

export default LinkToSalesOrderCheckbox;