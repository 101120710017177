import {useAtom} from "jotai";
import {appAuthenticationErrorAtom, appAuthenticationErrorReasonAtom, appIsInitializedAtom} from "App";
import msalAuth, {msalManualAuthenticationHappenedAtom} from "shared/msal/MsalAuth";
import {Box, Button, Card, CardContent, CardMedia, Container, Grid, Stack, Typography} from "@mui/material";

import msLogo from "shared/asset/Microsoft_logo.svg"
import crowbarLogo from "shared/asset/web192_Crowbar-final-01.png"
import useTranslateFunction from "shared/language/useTranslateFunction";

const LoginScreen = () => {

    const tk = useTranslateFunction(`Generic.Login`)

    const [initialized, setInitialized] = useAtom(appIsInitializedAtom);
    const [authenticationError, setAuthenticationError] = useAtom(appAuthenticationErrorAtom);
    const [authenticationErrorReason, setAuthenticationErrorReason] = useAtom(appAuthenticationErrorReasonAtom);
    const [msalManualAuthHappened, setManualAuthHappened] = useAtom(msalManualAuthenticationHappenedAtom);

    const login = async (): Promise<void> => {
        const response = await msalAuth.manualLogin()
        console.log(response)
        setManualAuthHappened(true)
        if (response === undefined || !response?.idToken) {
            setInitialized(false)
            setAuthenticationError(true)
            setAuthenticationErrorReason(JSON.stringify(response))
        } else {
            setInitialized(true)
            console.log("Manual login success for user.");
        }
    }

    let errorReasonPretty = "N/A"
    if (authenticationErrorReason) {
        try {
            const obj = JSON.parse(authenticationErrorReason)
            errorReasonPretty = JSON.stringify(obj, null, 2)
        } catch {
            errorReasonPretty = authenticationErrorReason
        }
    }

    return (
        <Container>
            <Grid
                container
                spacing={2}
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{
                    width: 1,
                    marginTop: 6,
                }}
            >

                <Grid item xs={12}>
                    <Grid container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                    >
                        <Grid item xs={12} sm={10} md="auto">
                            <Card
                                elevation={3}
                                sx={{
                                    // width: 1,
                                    // height: 1,
                                    display: "flex"
                                }}
                            >
                                <Grid container spacing={0}>

                                    <Grid item xs={5}>
                                        <CardMedia
                                            component="img"
                                            image={crowbarLogo}
                                            sx={{
                                                objectFit: "cover"
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={7}>
                                        <CardContent
                                            sx={{
                                                // flex: '1 0 auto'
                                            }}
                                        >
                                            <Stack direction="column" spacing={1}>

                                                <Typography variant="h4" component="div" gutterBottom>
                                                    Crowbar
                                                </Typography>

                                                <Typography variant="caption" display="block" gutterBottom>
                                                    {tk('RequiresAuthentication')}
                                                </Typography>

                                                <Button
                                                    variant="outlined"
                                                    sx={{
                                                        backgroundColor: theme => theme.palette.background.default,
                                                        color: theme => theme.palette.text.primary,
                                                        wordWrap: 'normal',
                                                        width: 1,
                                                    }}
                                                    onClick={login}
                                                >
                                                    <Box
                                                        component={"img"}
                                                        src={msLogo}
                                                        sx={{
                                                            width: "32px",
                                                            height: "32px",
                                                            marginRight: 2,
                                                        }}
                                                    />
                                                    <Typography flexWrap="wrap" whiteSpace="normal"
                                                                title={tk('LoginWithMs')}>
                                                        {tk('Microsoft')}
                                                    </Typography>
                                                </Button>

                                            </Stack>
                                        </CardContent>
                                    </Grid>


                                </Grid>


                            </Card>
                        </Grid>
                    </Grid>
                </Grid>


                {msalManualAuthHappened && !initialized ? (

                        <Grid item xs={12}>
                            <Grid container
                                  direction="row"
                                  justifyContent="center"
                                  alignItems="center"
                            >
                                <Grid item xs={12} sm={10}>
                                    <Card
                                        elevation={3}
                                        sx={{
                                            width: "100%"
                                        }}
                                    >
                                        <CardContent>
                                            <Stack
                                                direction="column"
                                                spacing={1}
                                                sx={{
                                                    p: 1
                                                }}
                                            >
                                                <Typography variant="h4">{tk('LoginFailed')}</Typography>

                                                <Typography variant="caption">
                                                    {tk('ContactAdmin')}
                                                </Typography>

                                                {authenticationError && (
                                                    <>
                                                        <Typography variant="caption" fontStyle="italic">
                                                            {tk('TechnicalDetails')}:
                                                        </Typography>

                                                        <pre>
                                                            {errorReasonPretty}
                                                        </pre>

                                                    </>
                                                )}

                                            </Stack>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                    : (<></>)
                }
            </Grid>
        </Container>
    )
}

export default LoginScreen