import axios, {AxiosInstance} from "axios";
import {toastError} from "shared/toast/DefaultToasts";
import i18n from "i18n/i18n";

export type ParsedError = {
    type: 'GenericError' | 'ServerError' | 'NetworkError' | 'UnknownError'
    message: string
}

export type CrowbarApiError = ParsedError & {
    toastId: number | string
    time?: Date
}

const parseErrorAsMessage = (error: any): ParsedError => {
    let message: string
    if (typeof error === "string") {
        message = error ?? 'N/A'
        return {
            type: 'GenericError',
            message
        }
    } else if (error.response) {
        // The client was given an error response (5xx, 4xx)
        const se = i18n.t('Generic.ServerError')

        const d = JSON.stringify({
            data: error.response.data,
            status: error.response.status
        })

        message = `${se} ${d}`
        return {
            type: 'ServerError',
            message
        }

    } else if (error.request) {
        // The client never received a response, and the request was never left
        const ne = i18n.t('Generic.NetworkResponseError')

        const errorJson = error.toJSON()
        const jsonMessage = errorJson?.message
        const d = jsonMessage === "Network Error"
            ? i18n.t('Generic.NetworkResponseErrorSuggestion')
            : errorJson?.message ?? "N/A"

        /* JSON.stringify({
            message: errorJson.message,
            // stack: errorJson.stack
        })*/

        message = `${ne} ${d}`
        return {
            type: 'NetworkError',
            message
        }
    } else {
        // Anything else
        const ue = i18n.t('Generic.UnknownError')

        message = `${ue} ${error.message ?? "N/A"}`
        return {
            type: 'UnknownError',
            message
        }
    }
}

const onResponseError = (error: any): Promise<any> => {
    if (!axios.isCancel(error)) {
        // log the error to console
        console.error(error);
        // toast
        const parsedError = parseErrorAsMessage(error)

        if (parsedError.type === "NetworkError") {
            toastError(parsedError.message, {
                toastId: "network-error" // custom toast id to avoid dupes
            })
        } else {
            toastError(parsedError.message)
        }

        // reject
        return Promise.reject(error);
    } else {
        // Cancel token, do nothing
        return new Promise(() => {
        });
    }
}

export function setupBackendResponseInterceptorsTo(axiosInstance: AxiosInstance): AxiosInstance {
    axiosInstance.interceptors.response.use(res => res, onResponseError);
    return axiosInstance;
}