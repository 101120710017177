import {useQuery} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {WorksheetApi} from "crowbar-api";
import AxiosCancelTokenHelper from "crowbar-api/AxiosCancelTokenHelper";

export const queryWorksheetAsSave = async (id: string | null | undefined, signal?: AbortSignal | undefined) => {
    if (!id) return null
    const r = await crowbarApiFactory(WorksheetApi, undefined, true)
        .findWorksheetQueryByIdAsSave(id, false, AxiosCancelTokenHelper.cancelTokenFrom(signal))
    /*if (r.status !== 200) {
        throw new Error('Failed to load worksheet by id as save.')
    }*/
    return r?.data ?? null
}

const useWorksheetQueryAsSave = (id: string | null | undefined) => {
    return useQuery(['worksheet-query-as-save', id], async (context) => {
        return queryWorksheetAsSave(id, context.signal)
    }, {
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        cacheTime: 0,
        staleTime: 0,
        retry: 3
    })
}

export default useWorksheetQueryAsSave