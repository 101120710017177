import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {DeliveryNoteTemplateApi} from "crowbar-api";
import httpResponseFileDownload from "shared/utils/HttpResponseFileDownload";
import {useMutation} from "@tanstack/react-query";

export const useDownloadSampleXML = () => {
    return useMutation(["sample-xml"], async () => {
        const response = await crowbarApiFactory(DeliveryNoteTemplateApi).sampleXmlDownload()

        await httpResponseFileDownload(response);
    })
}