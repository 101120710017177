import {Box, Button, ButtonProps, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle} from "@mui/material";
import React, {useState} from "react";
import useTranslateFunction from "shared/language/useTranslateFunction";
/*
interface ConfirmButtonProps {
    dialogTitle?: string
    dialogText?: string
    fullWidth?: boolean | undefined
    maxWidth?: false | Breakpoint | undefined
    buttonVariant?: "text" | "contained" | "outlined" | undefined
    buttonColor?: "inherit" | "error" | "primary" | "secondary" | "success" | "info" | "warning" | undefined
    buttonText: string | JSX.Element
    onConfirm?: () => void | Promise<void>
    onDismiss?: () => void | Promise<void>

    buttonRender?: (props: ConfirmButtonProps, onButtonClick: () => Promise<void>) => JSX.Element
}*/

export type ConfirmDialogButtonProps = Omit<DialogProps, "open"> & {
    buttonRender?: (props: ConfirmDialogButtonProps, onButtonClick: () => Promise<void>) => React.ReactElement

    buttonText?: string | React.ReactElement
    buttonProps?: ButtonProps

    dialogTitle?: string
    dialogText?: string
    onConfirm?: () => void | Promise<void>
    onDismiss?: () => void | Promise<void>
}

const ConfirmDialogButton = (confirmDialogProps: ConfirmDialogButtonProps) => {

    const tk = useTranslateFunction(`Generic.ConfirmButton`)
    const [open, setOpen] = useState(false)

    const {
        buttonRender,
        buttonText,
        buttonProps,
        onConfirm,
        onDismiss,
        dialogTitle,
        dialogText,
        ...props
    } = confirmDialogProps

    const onButtonClick = async () => {
        setOpen(true)
    }

    const onCancelButtonClick = () => {
        setOpen(false)
        onDismiss?.()
    }

    const onOkButtonClick = () => {
        setOpen(false)
        onConfirm?.()
    }

    return (
        <>
            <Dialog
                {...props}
                fullWidth
                open={open}
                onClose={() => {
                }/*do nothing*/}
            >
                <DialogTitle>{dialogTitle ?? tk('DefaultTitle')}</DialogTitle>
                <DialogContent>
                    {dialogText ?? tk('DefaultText')}
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color={'error'} onClick={onCancelButtonClick}>{tk('Cancel')}</Button>
                    <Box sx={{flex: '1 0 0'}}/>
                    <Button variant={'contained'} color={'primary'} onClick={onOkButtonClick}>{tk('Yes')}</Button>
                </DialogActions>
            </Dialog>

            {buttonRender
                ? buttonRender(confirmDialogProps, onButtonClick)
                : <Button variant="outlined"
                          color="error"
                          onClick={onButtonClick}
                          {...buttonProps}
                >
                    {buttonText ?? tk("Confirm")}
                </Button>
            }
        </>
    )
}

export default ConfirmDialogButton