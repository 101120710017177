import React from 'react';
import {CDAMWMWServiceCallItemRowSearchQuery} from "crowbar-api";
import useWorksheetTimeItemCodes from "crowbar-api/hooks/worksheet/worksheet-time-item-codes/useWorksheetTimeItemCodes";
import {Stack, Typography} from "@mui/material";
import AccessTimeIcon from '@mui/icons-material/AccessTime';

export type ResultEntryServiceCallWorkHoursProps = {
    itemRows?: CDAMWMWServiceCallItemRowSearchQuery[]
}
const ResultEntryServiceCallWorkHours = ({
                                             itemRows
                                         }: ResultEntryServiceCallWorkHoursProps) => {
    const worksheetTimeItemCodesQuery = useWorksheetTimeItemCodes()
    const worksheetTimeItemCodes = worksheetTimeItemCodesQuery.data ?? []

    const times = (itemRows ?? []).reduce<Record<string, CDAMWMWServiceCallItemRowSearchQuery[]>>((result, row) => {
        const key = row.itemCode
        if (!key ||
            worksheetTimeItemCodes.find(tic => tic.itemCode === key) === undefined
        ) return result

        if (!result[key]) {
            result[key] = []
        }
        result[key].push(row)
        return result
    }, {})

    const hasTimes = Object.values(times).reduce((sum, rows) =>
            sum + rows.reduce((rowSum, row) => rowSum + (row.installedQuantity ?? 0), 0)
        , 0) > 0

    if (!hasTimes) {
        return (
            <></>
        )
    }

    return (
        <Stack direction="row" spacing={0.5} alignItems="center">
            <AccessTimeIcon/>
            <Typography variant="caption">
                {Object
                    .entries(times)
                    .filter(([itemCode, timeRows]) => timeRows.length > 0)
                    .map(([itemCode, timeRows]) => {
                        //const wtic = worksheetTimeItemCodes.find(tic => tic.itemCode === itemCode)
                        const firstRow = timeRows.find(() => true)
                        const unit = timeRows.filter(tr => tr.unitOfQuantity).find(() => true)?.unitOfQuantity ?? ""
                        const timeSum = timeRows.reduce((rowSum, row) => rowSum + (row.installedQuantity ?? 0), 0)

                        if (timeSum <= 0) {
                            return (
                                <span key={itemCode}></span>
                            )
                        }

                        const description = firstRow?.itemName ?? firstRow?.itemCode ?? ""

                        return (
                            <span
                                key={itemCode}>{description} {timeSum} {!description.includes(unit) ? unit : ""}&nbsp;</span>
                        )
                    })}
            </Typography>
        </Stack>
    );
};

export default ResultEntryServiceCallWorkHours;