import React from "react";
import useTranslateFunction from "shared/language/useTranslateFunction";
import useWorksheetSignedState from "modules/worksheet/editor/state/helpers/useWorksheetSignedState";
import LockIcon from '@mui/icons-material/Lock';
import {Stack, Typography} from "@mui/material";

export const WEditorHeaderIsCompletedStatusLine = () => {
    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)
    const {editDisabled, evaluated} = useWorksheetSignedState()

    if (!editDisabled) return <></>

    return (
        <Stack
            direction="row"
            spacing={1}
            sx={{
                width: 1,
                backgroundColor: "green",
                display: "flex",
                alignItems: "center",
                padding: "4px"
            }}
        >
            <LockIcon sx={{fontSize: "10pt"}}/>

            <Typography variant="body2" fontSize="10pt">
                {evaluated ? (
                    tk('EditDisabledMsgEvaluated')
                ) : (
                    tk('EditDisabledMsg')
                )}
            </Typography>
        </Stack>
    )
}