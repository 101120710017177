import {CDAMNMEmailNotificationSearch} from "crowbar-api";
import {Button} from "@mui/material";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {useEmailNotificationUpdateResendFor} from "crowbar-api/hooks/notification/useEmailNotificationUpdateResendFor";
import {toastSuccess} from "shared/toast/DefaultToasts";

export type EmailNotificationResendButtonProps = {
    row: CDAMNMEmailNotificationSearch
    onUpdated: () => void
}
export const EmailNotificationResendButton = ({row, onUpdated}: EmailNotificationResendButtonProps) => {
    const tk = useTranslateFunction(`Admin.Worksheet`)

    const updateResendFor = useEmailNotificationUpdateResendFor()

    const onClick = async (resend: boolean) => {
        await updateResendFor.mutateAsync({
            enId: row.id,
            resendValue: resend
        })
        if (resend) {
            toastSuccess(tk('MarkedAsResend'), {toastId: "marked-as-resend"})
        } else {
            toastSuccess(tk('ResendRevoked'), {toastId: "resend-revoked"})
        }
        onUpdated?.()
    }

    if (!row.resend) {
        return (
            <Button
                onClick={() => onClick(true)}
            >
                {tk('Resend')}
            </Button>
        )
    } else {
        return (
            <Button
                onClick={() => onClick(false)}
            >
                {tk('RevokeResend')}
            </Button>
        )
    }
}