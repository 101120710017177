import React from 'react';
import {CDAMWMWorksheetEvalModel, CDAMWMWWorksheetQuery} from "crowbar-api";
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import DateDisplay, {dateDisplayDefaultOptions} from "shared/components/date-display/DateDisplay";
import useTranslateFunction from "shared/language/useTranslateFunction";
import SapPartnerAddressUtils from "crowbar-api/util/SapPartnerAddressUtils";

export type HistoryProps = {
    wEval: CDAMWMWorksheetEvalModel | null | undefined
    worksheet: CDAMWMWWorksheetQuery | null | undefined
}
const HistoryForWorksheet = ({
                                 wEval,
                                 worksheet
                             }: HistoryProps) => {
    const tk = useTranslateFunction("Admin.Worksheet")

    return (
        <TableContainer component={Paper}>
            <Table sx={{minWidth: 650}} size="small" aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>{tk('What?')}</TableCell>
                        <TableCell align="right">{tk('WorksheetOriginal')}</TableCell>
                        <TableCell align="right">{tk('CurrentValue')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>

                    {wEval?.startDateChanged && (
                        <TableRow>
                            <TableCell component="th" scope="row">
                                {tk('StartedAt')}
                            </TableCell>
                            <TableCell align="right">
                                <DateDisplay
                                    dateStr={wEval.originalStartedAt ?? ""}
                                    options={{
                                        ...dateDisplayDefaultOptions,
                                        hour: undefined,
                                        minute: undefined
                                    }}
                                />
                            </TableCell>
                            <TableCell align="right">
                                <DateDisplay
                                    dateStr={worksheet?.startedAt ?? ""}
                                    options={{
                                        ...dateDisplayDefaultOptions,
                                        hour: undefined,
                                        minute: undefined
                                    }}
                                />
                            </TableCell>
                        </TableRow>
                    )}

                    {wEval?.endDateChanged && (
                        <TableRow>
                            <TableCell component="th" scope="row">
                                {tk('EndedAt')}
                            </TableCell>
                            <TableCell align="right">
                                <DateDisplay
                                    dateStr={wEval.originalEndedAt ?? ""}
                                    options={{
                                        ...dateDisplayDefaultOptions,
                                        hour: undefined,
                                        minute: undefined
                                    }}
                                />
                            </TableCell>
                            <TableCell align="right">
                                <DateDisplay
                                    dateStr={worksheet?.endedAt ?? ""}
                                    options={{
                                        ...dateDisplayDefaultOptions,
                                        hour: undefined,
                                        minute: undefined
                                    }}
                                />
                            </TableCell>
                        </TableRow>
                    )}

                    {wEval?.partnerChanged && (
                        <TableRow>
                            <TableCell component="th" scope="row">
                                {tk('Partner')}
                            </TableCell>
                            <TableCell align="right">
                                {wEval?.originalCardCode} {wEval?.originalPartnerCardName}
                            </TableCell>
                            <TableCell align="right">
                                {worksheet?.cardCode} {worksheet?.partnerCardName}
                            </TableCell>
                        </TableRow>
                    )}

                    {wEval?.addressChanged && (
                        <TableRow>
                            <TableCell component="th" scope="row">
                                {tk('Address')}
                            </TableCell>
                            <TableCell align="right">
                                {SapPartnerAddressUtils.addressFormat({
                                    zipCode: wEval?.originalAddressZipCode,
                                    city: wEval?.originalAddressCity,
                                    street: wEval?.originalAddressStreet,
                                    streetNo: wEval?.originalAddressStreetNo
                                })}
                            </TableCell>
                            <TableCell align="right">
                                {SapPartnerAddressUtils.addressFormat({
                                    zipCode: worksheet?.addressZipCode,
                                    city: worksheet?.addressCity,
                                    street: worksheet?.addressStreet,
                                    streetNo: worksheet?.addressStreetNo
                                })}
                            </TableCell>
                        </TableRow>
                    )}

                    {wEval?.contactChanged && (
                        <TableRow>
                            <TableCell component="th" scope="row">
                                {tk('Contact')}
                            </TableCell>
                            <TableCell align="right">
                                {wEval?.originalContactFirstname} {wEval?.originalContactMiddleName} {wEval?.originalContactLastname} ({wEval?.originalContactCode})
                            </TableCell>
                            <TableCell align="right">
                                {worksheet?.contactFirstname} {worksheet?.contactMiddleName} {worksheet?.contactLastname} ({worksheet?.contactCode})
                            </TableCell>
                        </TableRow>
                    )}

                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default HistoryForWorksheet;