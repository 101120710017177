import {CDAMWMServiceCallStatus} from "crowbar-api";

export default class ServiceCallStatusMap {
    public static readonly draft = CDAMWMServiceCallStatus.NUMBER_0
    public static readonly final = CDAMWMServiceCallStatus.NUMBER_1

    public static translationKey = (t?: CDAMWMServiceCallStatus): string => {

        const preKey = "Worksheet.Generic.ServiceCallStatus."

        if (t === this.draft) {
            return `${preKey}Draft`
        }

        if (t === this.final) {
            return `${preKey}Final`
        }

        return "Missing"
    }
}