import React from 'react';

import {CDAMWMWServiceCallSave} from "crowbar-api";
import {Box, Grid, Paper} from "@mui/material";
import {useWorksheetEditorHeaderInfoAtom} from "modules/worksheet/editor/state/WorksheetEditorHeaderAtoms";
import WEditorOperatingHoursSet from "modules/worksheet/editor/ui/equipments/WEditorOperatingHoursSet";
import WEditorEquipmentCardImage from "modules/worksheet/editor/ui/equipments/WEditorEquipmentCardImage";
import ServiceCallSelectedSourceMap from "crowbar-api/enum-map/ServiceCallSelectedSourceMap";

interface WorksheetEditorHeaderEquipmentCardDetailsProps {
    serviceCall: CDAMWMWServiceCallSave
    disabled?: boolean
}

const WorksheetEditorHeaderEquipmentCardDetails = ({
                                                       serviceCall,
                                                       disabled
                                                   }: WorksheetEditorHeaderEquipmentCardDetailsProps) => {
    // const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    const [info] = useWorksheetEditorHeaderInfoAtom()

    const selected = info.selectedServiceCallId === serviceCall.id

    const isEmptySource = serviceCall.selectedSource === ServiceCallSelectedSourceMap.empty

    return (
        <Paper variant={"outlined"} sx={{
            borderColor: selected ? 'green' : 'inherit',
            borderWidth: "2px",
            padding: 2
        }}>
            <Grid container spacing={1} justifyContent="space-between">

                <Grid item /*xs={6} sm={5} md={4}*/ xs={true}>
                    <WEditorEquipmentCardImage serviceCall={serviceCall} disabled={disabled}/>
                </Grid>

                {!isEmptySource && (
                    <Grid
                        item
                        xs="auto"
                        sx={{
                            display: "flex",
                            justifyContent: "end"
                        }}
                    >
                        <Box sx={{width: "300px"}}>
                            <WEditorOperatingHoursSet
                                serviceCall={serviceCall}
                                disabled={disabled}
                            />
                        </Box>
                    </Grid>
                )}

            </Grid>
        </Paper>
    )
}

export default WorksheetEditorHeaderEquipmentCardDetails;