import React from 'react';
import {atom, PrimitiveAtom} from "jotai";
import {EquipmentSearchState} from "modules/equipment/equipment-search/useEquipmentSearchState";
import {Card, CardContent, Grid, Stack} from "@mui/material";
import SelectedEquipment from "modules/equipment/equipment-search/filters/SelectedEquipment";
import SearchInput from "modules/equipment/equipment-search/filters/SearchInput";
import EquipmentSearchResults from "modules/equipment/equipment-search/results/EquipmentSearchResults";

const equipmentSearch = atom<EquipmentSearchState>({
    searchParams: {
        limit: 20,
        lastRowNumber: 0,
        search: '',
        orderByIsDesc: false,
        includeModEquipments: true,
        includeSapEquipments: true,
        showDeleted: false
    }
})

export type EquipmentSearchStateProps = {
    searchStateAtom: PrimitiveAtom<EquipmentSearchState>
}

const EquipmentSearch = () => {
    return (
        <Stack direction="column" spacing={1}>

            <Card>
                <CardContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <SearchInput searchStateAtom={equipmentSearch}/>
                        </Grid>
                        <Grid item xs={12}>
                            <SelectedEquipment searchStateAtom={equipmentSearch}/>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>


            <EquipmentSearchResults searchStateAtom={equipmentSearch}/>

        </Stack>
    )
}

export default EquipmentSearch;