import React from 'react';
import {CDAMWMServiceCallEvalModel, CDAMWMServiceCallModel} from "crowbar-api";
import {LinearProgress, MenuItem, Select, SelectChangeEvent, Stack, Typography} from "@mui/material";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {
    useServiceCallEvalUpdateSelectedSapCallType
} from "crowbar-api/hooks/worksheet/evaluation/service-call/useServiceCallEvalUpdateSelectedSapCallType";
import {useSapCallTypes} from "crowbar-api/hooks/sap/useSapCallTypes";

export type SapCallTypeSelectProps = {
    sc: CDAMWMServiceCallModel
    scEval: CDAMWMServiceCallEvalModel
    disabled?: boolean
}

const SapCallTypeSelect = ({sc, scEval, disabled}: SapCallTypeSelectProps) => {

    const tk = useTranslateFunction('Admin.Worksheet')

    const sapCallTypesQuery = useSapCallTypes();
    const sapCallTypes = sapCallTypesQuery.data ?? []

    //const callTypeQuery = useWorksheetCallTypeFindAll()
    //const callTypes = callTypeQuery.data ?? []

    const updateCallTypeId = useServiceCallEvalUpdateSelectedSapCallType();

    const onChange = async (e: SelectChangeEvent<number>) => {
        const newValue = +e.target.value
        await updateCallTypeId.mutateAsync({
            evalId: scEval.id,
            originalId: sc.id,
            field: newValue
        })
        scEval.selectedSapCallType = newValue
    }

    return (
        <Stack direction="column" spacing={0.5}>
            <Typography>{tk('SapCallType')} *</Typography>

            {sapCallTypesQuery.isLoading ? (
                <LinearProgress/>
            ) : (
                <Select
                    size="small"
                    disabled={disabled}
                    fullWidth
                    required
                    value={scEval?.selectedSapCallType ?? ""}
                    onChange={onChange}
                    error={!scEval?.selectedSapCallType}
                >
                    {sapCallTypes.map(c => (
                        <MenuItem key={c.callTypeId} value={c.callTypeId}>
                            {c.name}
                        </MenuItem>
                    ))}
                </Select>
            )}
        </Stack>
    )
}

export default SapCallTypeSelect;