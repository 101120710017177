import WEditorDirectPhotoButton from "modules/worksheet/editor/ui/photo/WEditorDirectPhotoButton";
import {CircularProgress, Grid, Stack} from "@mui/material";
import usePhotoBlobs from "modules/worksheet/editor/ui/photo/usePhotoBlobs";
import {useSelectedServiceCall} from "modules/worksheet/editor/shared/data/useSelectedServiceCall";
import WEditorPhotoDisplay from "modules/worksheet/editor/ui/photo/WEditorPhotoDisplay";
import React from "react";
import WEditorDisplayHelpDialogButton from "modules/worksheet/editor/ui/photo/WEditorDisplayHelpDialogButton";

const WorksheetEditorPagePhoto = () => {

    // const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    const selectedServiceCall = useSelectedServiceCall()
    const photoBlobs = usePhotoBlobs()

    const afterUploadComplete = () => {
        photoBlobs.refetchImages().then()
    }

    const afterDelete = () => {
        photoBlobs.refetchImages().then()
    }

    if (!selectedServiceCall) return <></>

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Stack direction="row" spacing={2}>
                    <WEditorDisplayHelpDialogButton
                        selectedServiceCall={selectedServiceCall}
                    />

                    {/* As of 2023-01-27 this feature is removed
                    <WEditorPhotoHelperDialogButton
                        selectedServiceCall={selectedServiceCall}
                        photoBlobs={photoBlobs}
                        afterClose={afterUploadComplete}
                    />
                    */}
                    <WEditorDirectPhotoButton
                        afterUploadComplete={afterUploadComplete}
                    />
                </Stack>
            </Grid>
            <Grid item xs={12}>
                {photoBlobs.isLoading ? (
                    <CircularProgress/>
                ) : (
                    <WEditorPhotoDisplay
                        selectedServiceCall={selectedServiceCall}
                        photoBlobs={photoBlobs}
                        afterDelete={afterDelete}
                    />
                )}
            </Grid>
        </Grid>
    )
}

export default WorksheetEditorPagePhoto