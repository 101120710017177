import React from 'react';
import {CDSMSapServiceCall} from "crowbar-api";
import {Card, CardContent, Grid, Stack, Typography} from "@mui/material";
import LabeledField from "shared/components/typography/LabeledField";
import DateDisplay, {dateDisplayDefaultOptions} from "shared/components/date-display/DateDisplay";
import useTranslateFunction from "shared/language/useTranslateFunction";

export type EquipmentServiceCallEntryProps = {
    serviceCall: CDSMSapServiceCall
}
const EquipmentServiceCallEntry = ({serviceCall}: EquipmentServiceCallEntryProps) => {
    const tk = useTranslateFunction("Equipment")
    return (
        <Card>
            <CardContent>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Stack direction="row" justifyContent="space-between">

                            <Stack direction="column" spacing={0}>
                                <Typography>{serviceCall.cardCode}</Typography>
                                <Typography>{serviceCall.cardName}</Typography>
                                <Typography>{serviceCall.billingAddress}</Typography>
                                {serviceCall.shippingAddress !== serviceCall.billingAddress && (
                                    <Typography>{serviceCall.shippingAddress}</Typography>
                                )}
                            </Stack>

                            <Stack direction="column" spacing={0} textAlign="end">
                                <Typography>{serviceCall.itemCode}</Typography>
                                <Typography>{serviceCall.itemName}</Typography>
                                <DateDisplay
                                    dateStr={serviceCall.startDate ?? ""}
                                    options={{
                                        ...dateDisplayDefaultOptions,
                                        hour: undefined,
                                        minute: undefined
                                    }}
                                />
                                {serviceCall.endDate !== serviceCall.startDate && (
                                    <DateDisplay
                                        dateStr={serviceCall.endDate ?? ""}
                                        options={{
                                            ...dateDisplayDefaultOptions,
                                            hour: undefined,
                                            minute: undefined
                                        }}
                                    />
                                )}
                            </Stack>

                        </Stack>
                    </Grid>

                    <Grid item xs={12}>
                        <Stack direction="column">

                            <LabeledField label={tk('Subject')} value={serviceCall.subject}
                                          hideIfValueIsMissing={true}/>
                            <LabeledField label={tk('Description')} value={serviceCall.description}
                                          hideIfValueIsMissing={true}/>

                        </Stack>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default EquipmentServiceCallEntry;