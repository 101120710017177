import React from 'react';
import {Button, Card, CardContent, CircularProgress, Grid, Typography} from "@mui/material";
import {useMechanicDashboardWorksheets} from "crowbar-api/hooks/dashboard/mechanic/useMechanicDashboardWorksheets";
import {useUserContext} from "shared/hook/UserContext";
import WorksheetStatusMap from "crowbar-api/enum-map/WorksheetStatusMap";
import useTranslateFunction from "shared/language/useTranslateFunction";

import LaunchIcon from '@mui/icons-material/Launch';
import {useNavigate} from "react-router-dom";
import {useMechanicWorksheetsUrl} from "modules/worksheet/editor/shared/data/useMechanicWorksheetsUrl";

const MechanicDashboard = () => {
    const tk = useTranslateFunction('Home.MechanicDashboard')
    const [userId] = useUserContext()
    const worksheetsQuery = useMechanicDashboardWorksheets(!userId ? [] : [userId])

    const worksheets = worksheetsQuery.data ?? []
    const draftWorksheets = worksheets.filter(w => w.status === WorksheetStatusMap.draft)
    const finalWorksheets = worksheets.filter(w => w.status === WorksheetStatusMap.final)

    const myWorksheetsUrl = useMechanicWorksheetsUrl()
    const navigate = useNavigate()

    return (
        <Grid container spacing={1}>

            <Grid item xs={12} sm={6} md={4}>
                <Card>
                    {worksheetsQuery.isLoading ? (
                        <CircularProgress/>
                    ) : (
                        <CardContent>
                            <Typography variant="h4" sx={{
                                mb: 2
                            }}>
                                {tk('MyWorksheets')}
                            </Typography>

                            <Grid container spacing={0.5}>
                                <Grid item xs={8}>
                                    <Typography variant="body1" width={1}>
                                        {tk('DraftNum')}:

                                    </Typography>
                                </Grid>
                                <Grid item xs={4} sx={{
                                    display: "flex",
                                    justifyContent: "right"
                                }}>
                                    <Typography variant="caption" fontSize={18}>
                                        {draftWorksheets.length}
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant="body1">
                                        {tk('FinalNum')}:

                                    </Typography>
                                </Grid>
                                <Grid item xs={4} sx={{
                                    display: "flex",
                                    justifyContent: "right"
                                }}>
                                    <Typography variant="caption" fontSize={18}>
                                        {finalWorksheets.length}
                                    </Typography>
                                </Grid>
                            </Grid>


                            <Grid item xs={12} sx={{
                                display: "flex",
                                justifyContent: "right"
                            }}>
                                <Button
                                    variant="outlined"
                                    onClick={() => navigate(myWorksheetsUrl)}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center"
                                    }}>
                                    <LaunchIcon/>
                                    {tk('Open')}
                                </Button>
                            </Grid>

                        </CardContent>
                    )}
                </Card>
            </Grid>

        </Grid>
    );
};

export default MechanicDashboard;