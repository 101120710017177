import React from 'react';
import WEditorGenericPhotoUploadButton from "modules/worksheet/editor/ui/photo/WEditorGenericPhotoUploadButton";
import useTranslateFunction from "shared/language/useTranslateFunction";

export type WEditorDirectPhotoButtonProps = {
    afterUploadComplete: () => void
}

const WEditorDirectPhotoButton = ({afterUploadComplete}: WEditorDirectPhotoButtonProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    const label = tk('StandalonePhoto')

    return (
        <WEditorGenericPhotoUploadButton buttonLabel={label} afterUploadComplete={afterUploadComplete}
                                         allowMultiple={true}/>
    )
}

export default WEditorDirectPhotoButton;