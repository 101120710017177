import {useMutation} from "@tanstack/react-query";
import {CDAMPMPhotoRuleStepOrderSave, PhotoRuleStepApi} from "crowbar-api";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";


const usePhotoRuleStepSaveOrder = () => {
    return useMutation(async (orders: CDAMPMPhotoRuleStepOrderSave[]) => {
        const response = await crowbarApiFactory(PhotoRuleStepApi).setOrderNumbersLike(orders)
        if (response.status !== 200) {
            throw new Error('Failed to save photo rule step order numbers.')
        }
    })
}

export default usePhotoRuleStepSaveOrder