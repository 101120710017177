import {useQuery} from "@tanstack/react-query";
import {CDAMTMTvhBlobSearchModel, TvhBlobApi} from "crowbar-api";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import AxiosCancelTokenHelper from "crowbar-api/AxiosCancelTokenHelper";


const useTvhBlobFindBySapItemCodes = (itemCode?: string | undefined) => {
    return useQuery<CDAMTMTvhBlobSearchModel[]>(['worksheet-editor-page-catalogue-tvh-images', itemCode], async ({signal}) => {
        if (!itemCode) return []
        const response = await crowbarApiFactory(TvhBlobApi)
            .findBySapItemCodes([itemCode], AxiosCancelTokenHelper.cancelTokenFrom(signal))
        if (response.status === 200) {
            return response.data
        } else {
            return []
        }
    }, {
        cacheTime: Math.pow(10, 7),
        staleTime: Math.pow(10, 7),
        refetchOnMount: false,
        refetchOnWindowFocus: false
    })
}

export default useTvhBlobFindBySapItemCodes