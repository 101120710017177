import {CDAMWMWorksheetSapSalesOrderRowType} from "crowbar-api";

export default class WorksheetSapSalesOrderRowTypeMap {
    public static readonly item = CDAMWMWorksheetSapSalesOrderRowType.NUMBER_0
    public static readonly text = CDAMWMWorksheetSapSalesOrderRowType.NUMBER_1

    public static translationKey = (t?: CDAMWMWorksheetSapSalesOrderRowType): string => {

        const preKey = "Worksheet.Generic.WorksheetSapSalesOrderRowType."

        if (t === this.item) {
            return `${preKey}Item`
        }

        if (t === this.text) {
            return `${preKey}Text`
        }

        return "Missing"
    }
}