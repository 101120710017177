import React, {useEffect, useState} from 'react';
import useSapPartnerAddressesByCardCode from "crowbar-api/hooks/sap/useSapPartnerAddressesByCardCode";
import {useWorksheetEvalUpdateEval} from "crowbar-api/hooks/worksheet/evaluation/worksheet/useWorksheetEvalUpdateEval";
import {LinearProgress, MenuItem, Select, Stack} from "@mui/material";
import SapPartnerAddressUtils from "crowbar-api/util/SapPartnerAddressUtils";
import useTranslateFunction from "shared/language/useTranslateFunction";
import RefreshButton from "shared/components/buttons/RefreshButton";
import {CDAMWMWorksheetEvalModel, CDAMWMWWorksheetQuery} from "crowbar-api";

export type PartnerAddressMasterDataSelectProps = {
    wEval: CDAMWMWorksheetEvalModel | null | undefined
    worksheet: CDAMWMWWorksheetQuery | null | undefined
    onDataChange: () => void
}
const PartnerAddressMasterDataSelect = ({wEval, worksheet, onDataChange}: PartnerAddressMasterDataSelectProps) => {
    const tk = useTranslateFunction("Admin.Worksheet")

    const ws = worksheet

    const [selectedAddress, setSelectedAddress] = useState<string | null>(wEval?.selectedSapAddressAndType ?? null)

    const evalUpdateEval = useWorksheetEvalUpdateEval()

    useEffect(() => {
        setSelectedAddress(wEval?.selectedSapAddressAndType ?? null)
    }, [wEval?.selectedSapAddressAndType])

    const updateServerSelectedAddressTo = async (newAddress: string | null | undefined) => {
        await evalUpdateEval.mutateAsync({
            originalId: ws?.id,
            evalId: wEval?.id,
            partnerAck: wEval?.partnerAck,
            contactAck: wEval?.contactAck,
            addressAck: wEval?.addressAck,
            selectedSapPartnerCardCode: wEval?.selectedSapPartnerCardCode,
            selectedSapAddressAndType: newAddress,
            selectedSapContactCode: wEval?.selectedSapContactCode
        })
        onDataChange()
    }

    const addressQuery = useSapPartnerAddressesByCardCode(ws?.cardCode, {
        refetchOnWindowFocus: true,
        refetchOnMount: true,
        cacheTime: 0,
        staleTime: 0,
        keepPreviousData: true,
        retry: 1
    })
    const addresses = addressQuery.data ?? []

    const selectedInAddress = addresses.find(a => selectedAddress === SapPartnerAddressUtils.addressKeyFor(null, a.addressType, a.code)) !== undefined

    if (addressQuery.isLoading) {
        return (
            <LinearProgress/>
        )
    }

    const onRefreshClick = () => {
        addressQuery.refetch().then()
    }

    return (
        <Stack direction="row" spacing={1} alignItems="center">
            <RefreshButton onClick={onRefreshClick} isLoading={addressQuery.isFetching}/>
            <Select
                size="small"
                defaultValue=""
                fullWidth
                disabled={wEval?.addressAck}
                value={selectedInAddress ? (selectedAddress ?? "") : ""}
                onChange={event => {
                    setSelectedAddress(event.target.value)
                    updateServerSelectedAddressTo(event.target.value).then()
                }}
                error={!wEval?.selectedSapAddressAndType}
            >
                <MenuItem disabled value="">{tk('PleaseChoose')}</MenuItem>
                {addresses.map(a => {
                    const key = SapPartnerAddressUtils.addressKeyFor(null, a.addressType, a.code)
                    return (
                        <MenuItem key={key} value={key}>
                            {SapPartnerAddressUtils.addressFormat(a)}
                        </MenuItem>
                    )
                })}
            </Select>
        </Stack>
    );
};

export default PartnerAddressMasterDataSelect;