import {useQuery} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {CDAMPMPhotoRuleStepModel, PhotoRuleStepApi} from "crowbar-api";
import AxiosCancelTokenHelper from "crowbar-api/AxiosCancelTokenHelper";
import {byPropertiesOf} from "shared/utils/ArraySort";


const usePhotoRuleEditSteps = (ruleId: string) => {
    return useQuery(['photo-rule-edit-steps', ruleId], async (context) => {
        const response = await crowbarApiFactory(PhotoRuleStepApi).findByRuleId(ruleId, AxiosCancelTokenHelper.cancelTokenFrom(context.signal))
        if (response.status !== 200) {
            throw new Error('Failed to load steps for photo rule by id. Status is not 200.')
        }
        return response.data?.sort(byPropertiesOf<CDAMPMPhotoRuleStepModel>(['orderNum'])) ?? []
    }, {
        initialData: [],
        cacheTime: 0
    })
}

export default usePhotoRuleEditSteps