import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {CCMCMCrowbarBlobAndUri, CrowbarBlobApi} from "crowbar-api";
import CrowbarBlobTypeMap from "crowbar-api/enum-map/CrowbarBlobTypeMap";
import {useCallback} from "react";
import {useNewAtomWithRef} from "shared/hook/useNewatomWithRef";
import axios, {AxiosRequestConfig} from "axios";
import {SetStateAction} from "jotai";

export interface UseBlobUploadResult {
    isUploading: boolean
    progress: number | undefined
    abortController: AbortController
    abort: () => void
    upload: (image: File) => Promise<CCMCMCrowbarBlobAndUri | undefined>
}

const upload = (image: File, abortSignal: AbortSignal,
                setIsUploading: (update: SetStateAction<boolean>) => void,
                setProgress: (update?: SetStateAction<number | undefined>) => void): Promise<CCMCMCrowbarBlobAndUri | undefined> => {
    const options: AxiosRequestConfig = {
        onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(progressEvent.loaded * 100 /
                progressEvent.total)
            setProgress(percentCompleted)
        },
        signal: abortSignal
    }
    return crowbarApiFactory(CrowbarBlobApi)
        .uploadFileAs(CrowbarBlobTypeMap.equipmentImage, true, image, options)
        .then(
            response => response.data,
            thrown => {
                if (axios.isCancel(thrown)) {
                    console.log(thrown)
                    setIsUploading(false)
                    setProgress(undefined)
                }
                return undefined
            }
        )
}

const useBlobUpload = (): UseBlobUploadResult => {

    const [isUploading, setIsUploading] = useNewAtomWithRef(false)
    const [progress, setProgress] = useNewAtomWithRef<number | undefined>(undefined)
    const [abortController, setAbortController] = useNewAtomWithRef<AbortController>(new AbortController())

    const uploadFn = useCallback((image: File): Promise<CCMCMCrowbarBlobAndUri | undefined> => {
        const newAbortController = new AbortController()
        setAbortController(newAbortController)
        setIsUploading(true)
        setProgress(0)
        //  const blob = await upload(image, newAbortController.signal, setIsUploading, setProgress)

        return upload(image, newAbortController.signal, setIsUploading, setProgress)
            .finally(() => {
                setIsUploading(false)
                setProgress(undefined)
            })
    }, [setAbortController, setIsUploading, setProgress])

    const abort = useCallback(() => {
        abortController.abort()
        setIsUploading(false)
        setProgress(undefined)
    }, [abortController, setIsUploading, setProgress])

    /*const uploadFn = useCallback(async (image: File) => {
        setIsUploading(true)
        setProgress(0)
        try {
            const options: AxiosRequestConfig = {
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round(progressEvent.loaded * 100 /
                        progressEvent.total)
                    setProgress(percentCompleted)
                },
                signal: abortSignal
            }
            const response = await crowbarApiFactory(CrowbarBlobApi)
                .uploadFileAs(CrowbarBlobTypeMap.equipmentImage, true, image, options)
                .then(r => r, rejected => {
                    console.log(rejected)
                    return rejected
                })
                .catch(thrown => {
                    if (axios.isCancel(thrown)) {
                        console.log(thrown)
                    }
                    throw thrown
                })

            if (response.status !== 200) {
                throw new Error('Failed to upload blob.')
            }
            return response.data
        } catch (thrown) {
            if (axios.isCancel(thrown)) {
                console.log(thrown)
            }
            throw thrown
        } finally {
            setIsUploading(false)
            setProgress(undefined)
        }
    }, [abortSignal, setIsUploading, setProgress])*/

    return {
        isUploading,
        progress,
        abortController,
        abort,
        upload: uploadFn
    }
}

export default useBlobUpload