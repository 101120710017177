import React from 'react';
import {CDSMNNewWorksheetSalesOrder} from "crowbar-api";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {Accordion, AccordionDetails, AccordionSummary, Button, Grid, Typography} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LinearIndeterminateProgressWithLabel from "shared/components/progress/LinearIndeterminateProgressWithLabel";
import NewWorksheetResultSalesOrder from "modules/worksheet/new-worksheet/results/NewWorksheetResultSalesOrder";

export type SalesOrderResultListProps = {
    salesOrders: CDSMNNewWorksheetSalesOrder[]
    salesOrdersIsLoading: boolean
    hasNextPage: boolean
    onSalesOrdersLoadMore: () => Promise<void>
    subTablePageSize: number
}
const SalesOrderResultList = ({
                                  salesOrders,
                                  salesOrdersIsLoading,
                                  hasNextPage,
                                  onSalesOrdersLoadMore,
                                  subTablePageSize
                              }: SalesOrderResultListProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.NewWorksheet`)
    return (
        <Accordion variant="outlined" defaultExpanded={true} sx={{width: 1}}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
            >
                <Typography fontWeight="bold">{tk('SalesOrders')}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{width: 1}}>
                <Grid container spacing={1}>

                    {salesOrders.map(so => (
                        <Grid item xs={12} key={'so' + so.docEntry}>
                            <NewWorksheetResultSalesOrder
                                salesOrder={so}
                                pageSize={subTablePageSize}
                            />
                        </Grid>
                    ))}

                    {salesOrdersIsLoading && (
                        <Grid item xs={12} sx={{my: 3}}>
                            <LinearIndeterminateProgressWithLabel label={tk('SalesOrdersIsLoading')}/>
                        </Grid>
                    )}

                    <Grid item xs={12} display="flex" justifyContent="center" sx={{my: 3}}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={onSalesOrdersLoadMore}
                            disabled={salesOrdersIsLoading || !hasNextPage}
                        >
                            {tk('LoadMore')}
                        </Button>
                    </Grid>

                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

export default SalesOrderResultList;