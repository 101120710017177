import {useMutation} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {CDAMWMWWorksheetSave, WorksheetSapSalesOrderApi} from "crowbar-api";
import {useUserContext} from "shared/hook/UserContext";
import useIsTripMatchFn from "crowbar-api/hooks/worksheet/validations/useIsTripMatchFn";
import {WorksheetSaveFactory} from "modules/worksheet/storage/WorksheetSaveFactory";
import {UseNewWorksheetFromProps} from "modules/worksheet/editor/state/events/UseNewWorksheetFromProps";
import {useSaveNewWorksheetViaApi} from "modules/worksheet/editor/state/events/useSaveNewWorksheetViaApi";

export interface UseNewWorksheetFromSalesOrderActionProps extends UseNewWorksheetFromProps {
    docEntry: number
}

/**
 * Creates a new worksheet from an SAP Sales Order.
 * The sales order is requested from the server by the docEntry key.
 * The worksheet is then saved to the server and local storage (by key).
 *
 * @returns MutationFunction<CDAMWMWWorksheetSave, number> The mutation fn returns the created Worksheet.
 *
 * Note: "Opening" a worksheet requires preparation for the ui, so it is done by worksheet.id and it will
 * also load the worksheet from the server (as a WorksheetSave model). Hence, keeping it up to date.
 *
 */
export const useNewWorksheetFromSalesOrderAction = () => {
    const [userId] = useUserContext()
    const isTrip = useIsTripMatchFn()
    const saveNewWorksheet = useSaveNewWorksheetViaApi()

    return useMutation(async (
        {docEntry, reloadWorksheetObjectAfterSave}: UseNewWorksheetFromSalesOrderActionProps
    ): Promise<CDAMWMWWorksheetSave> => {
        const salesOrderQueryResponse = await crowbarApiFactory(WorksheetSapSalesOrderApi)
            .findForWorksheetByDocEntry(docEntry)
        if (salesOrderQueryResponse.status !== 200) {
            throw new Error(
                'The sales order query required for creating ' +
                'a new worksheet from a sales order did not return OK state. ' +
                'Status: ' + salesOrderQueryResponse.status
            )
        }
        const salesOrder = salesOrderQueryResponse.data
        if (!salesOrder?.docEntry) {
            throw new Error('Sales order not found')
        }

        // Create a new WorksheetSave from the Sales order
        if (!userId) {
            throw new Error('UserIdNullError')
        }

        // New model created on client side
        const newWorksheet: CDAMWMWWorksheetSave = WorksheetSaveFactory
            .newWorksheetSaveFromSalesOrder(salesOrder, userId, isTrip)
        return await saveNewWorksheet.mutateAsync({newWorksheet, reloadWorksheetObjectAfterSave})
    })
}