import React from 'react';
import {withTranslation, WithTranslation} from "react-i18next";
import {FormikProps} from "formik";
import {IDeliveryNoteDialogFields} from "./IDeliveryNoteDialogFields";
import {DeliveryNoteTypeMap} from "crowbar-api/enum-map/DeliveryNoteTypeMap";
import LeaseStatusMap from "crowbar-api/enum-map/LeaseStatusMap";
import {CDAMDMDeliveryNoteModel} from "crowbar-api";
import {Grid} from "@mui/material";
import FormikSelect from "shared/formik/FormikSelect";
import FormikDatePicker from "shared/formik/FormikDatePicker";
import {SapSelectOption} from "crowbar-api/enum-map/SapMappingUtils";

interface DeliveryNoteDialogHeadFormProps extends WithTranslation {
    mode: 'new' | 'edit'
    editItem?: CDAMDMDeliveryNoteModel
    formikProps: FormikProps<IDeliveryNoteDialogFields>
}

interface DeliveryNoteDialogHeadFormState {
    formikProps: FormikProps<IDeliveryNoteDialogFields>

    typeOptions: SapSelectOption[],
    leaseOptions: SapSelectOption[],
}

const TKey = "DeliveryNote.Note.Dialog.";

class DeliveryNoteDialogHeadForm extends React.Component<DeliveryNoteDialogHeadFormProps, DeliveryNoteDialogHeadFormState> {

    private typeMap = new DeliveryNoteTypeMap()
    private leaseMap = new LeaseStatusMap()

    constructor(props: Readonly<DeliveryNoteDialogHeadFormProps> | DeliveryNoteDialogHeadFormProps) {
        super(props)

        const t = this.props.t
        const tot = 'DeliveryNote.Note.Type.'
        const to: SapSelectOption[] = [
            {key: this.typeMap.sale.toString(), text: t(this.typeMap.translationKey(tot, this.typeMap.sale))},
            {key: this.typeMap.lease.toString(), text: t(this.typeMap.translationKey(tot, this.typeMap.lease))},
            {key: this.typeMap.repair.toString(), text: t(this.typeMap.translationKey(tot, this.typeMap.repair))},
            {key: this.typeMap.other.toString(), text: t(this.typeMap.translationKey(tot, this.typeMap.other))},
        ]

        const lot = 'DeliveryNote.Note.LeaseStatus.'
        const lo: SapSelectOption[] = [
            {key: this.leaseMap.open.toString(), text: t(this.leaseMap.translationKey(lot, this.leaseMap.open))},
            {
                key: this.leaseMap.finished.toString(),
                text: t(this.leaseMap.translationKey(lot, this.leaseMap.finished))
            },
        ]

        this.state = {
            formikProps: this.props.formikProps,
            typeOptions: to,
            leaseOptions: lo
        }
    }


    componentDidUpdate(prevProps: Readonly<DeliveryNoteDialogHeadFormProps>, prevState: Readonly<DeliveryNoteDialogHeadFormState>, snapshot?: any) {
        if (this.state.formikProps !== this.props.formikProps) {
            this.setState({
                formikProps: this.props.formikProps
            })
        }
    }

    render() {
        const t = this.props.t;
        const fProps = this.state.formikProps;
        return (
            <React.Fragment>
                <Grid container item xs={12} md={6}>
                    <Grid item xs={9}>
                        <FormikSelect fieldName="type"
                                      options={this.state.typeOptions}
                                      label={t(`${TKey}Type`)}
                                      setter={(fieldName, option) => {
                                          fProps.setFieldValue(fieldName, option)
                                          if (option === this.typeMap.lease.toString() && fProps.values.leaseState === "") {
                                              fProps.setFieldValue("leaseState", this.leaseMap.open.toString())
                                          }
                                          if (option !== this.typeMap.lease.toString() && fProps.values.leaseState !== "") {
                                              fProps.setFieldValue("leaseState", "")
                                          }
                                      }
                                      }
                        />
                    </Grid>
                </Grid>


                {fProps.values.type === this.typeMap.lease.toString() && (

                    <Grid container item xs={12} md={6}>
                        <Grid item xs={9}>
                            <FormikSelect fieldName="leaseState"
                                          options={this.state.leaseOptions}
                                          label={t(`${TKey}LeaseStatus`)}
                                          required={true}
                                          setter={(fieldName, option) => {
                                              fProps.setFieldValue(fieldName, option)
                                          }
                                          }
                            />

                        </Grid>
                    </Grid>

                )}


                <Grid container item xs={12} md={6}>
                    <Grid item xs={9}>
                        <FormikDatePicker fieldName="datedAt" label={t(`${TKey}DatedAt`)}
                                          required={true}
                                          setter={(fieldName, date) => {
                                              fProps.setFieldValue(fieldName, date)
                                          }
                                          }/>
                    </Grid>
                </Grid>

            </React.Fragment>
        )
    }
}

export default withTranslation()(DeliveryNoteDialogHeadForm);