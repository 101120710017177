import React, {useState} from 'react';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {CircularProgress, IconButton, Menu, MenuItem} from "@mui/material";
import {useUserRoles} from "shared/hook/useUserRoles";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {CDAMWMWWorksheetSearchQuery} from "crowbar-api";
import {useWorksheetSetDeleted} from "crowbar-api/hooks/worksheet/useWorksheetSetDeleted";
import {toastError} from "shared/toast/DefaultToasts";
import ChangeStateDialog from "modules/admin/worksheet/worksheet-list/actions/ChangeStateDialog";

export type MoreMenuProps = {
    worksheet: CDAMWMWWorksheetSearchQuery
    onStateChanged?: () => void
}

const MoreMenu = ({worksheet, onStateChanged}: MoreMenuProps) => {
    const tk = useTranslateFunction(`Admin.Worksheet`)

    const [dialogOpen, setDialogOpen] = useState(false)

    const {canChangeWorksheetStates} = useUserRoles()

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const worksheetSetDeleted = useWorksheetSetDeleted()

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const onDeleteChange = async (isDeleted: boolean) => {
        if (!worksheet?.id) {
            toastError('Missing worksheet id.')
            return
        }
        await worksheetSetDeleted.mutateAsync({
            worksheetId: worksheet.id,
            deleted: isDeleted
        })
        handleClose()
        onStateChanged?.()
    }

    const onChangeStatus = () => {
        setDialogOpen(true)
        handleClose()
    }

    if (!canChangeWorksheetStates) {
        return <></>
    }

    return (
        <>
            <IconButton aria-label="settings" onClick={handleClick}>
                <MoreVertIcon/>
            </IconButton>

            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem
                    onClick={() => worksheet.isDeleted ? onDeleteChange(false) : onDeleteChange(true)}
                    disabled={worksheetSetDeleted.isLoading}
                >
                    {worksheetSetDeleted.isLoading && (
                        <CircularProgress/>
                    )}
                    {worksheet.isDeleted ? tk('Undelete') : tk('Delete')}
                </MenuItem>
                <MenuItem onClick={onChangeStatus}>{tk('ChangeStatus')}</MenuItem>
            </Menu>

            <ChangeStateDialog
                open={dialogOpen}
                worksheet={worksheet}
                onStateChanged={onStateChanged}
                onChangeDialogClose={() => setDialogOpen(false)}
            />
        </>
    );
};

export default MoreMenu;