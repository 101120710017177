import {useQuery} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {LastUserActionApi} from "crowbar-api";
import AxiosCancelTokenHelper from "crowbar-api/AxiosCancelTokenHelper";

export const useFindAllLastUserAction = () => {
    return useQuery(['last-user-action-find-all'], async (context) => {
        const response = await crowbarApiFactory(LastUserActionApi)
            .findAll(AxiosCancelTokenHelper.cancelTokenFrom(context.signal))

        return response.data
    }, {
        networkMode: 'online', // will not fire unless we have network
        refetchOnMount: true,
        refetchOnWindowFocus: true,
        cacheTime: 0,
        staleTime: 0,
    })
}