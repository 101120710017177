import {useMutation} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {AgentNotificationConfigurationApi, CDAMNMAgentNotificationConfigurationModel} from "crowbar-api";

export const useAgentNotificationConfigurationSave = () => {
    return useMutation(['agent-notification-configuration-save-1'], async (
        anc: CDAMNMAgentNotificationConfigurationModel
    ) => {
        await crowbarApiFactory(AgentNotificationConfigurationApi)
            .save(anc)
    })
}