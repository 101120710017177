export const i18n_dnEn = {
    "Title": "Templates",
    "TemplateDownloadTextPre": "Template sample XML datasource",
    "TemplateDownloadTextLink": "download here",
    "Form": {
        "TitleNew": "New template",
        "TitleEdit": "Edit template",
        "CancelButton": "Cancel",
        "DeleteButton": "Delete",
        "SaveButton": "Save",
        "InvalidFileExtError": "File must be docx",
        "FileReaderSuccess": "The file successfully read",
        "FileUploadOnEditDisabled": "File upload during editing is disabled.",
        "FileUploadLabelEmpty": "no file selected",
        "FileUploadButtonText": "Browse",
        "FileUploadText": "File upload",
        "DefaultText": "Default?",
        "DescriptionText": "Description",
        "DefaultCbLabel": "Make this the default template",
        "FormInvalidFile": "Invalid file or no file selected",
        "SaveSuccess": "Template saved",
        "EditLoadingFailed": "Failed to load data",
        "DeleteSuccess": "Deleted successfully",
        "DownloadTemplate": "Download file"
    },
    "List": {
        "TitleHeading": "Templates",
        "NewButtonText": "New template",
        "ColCreatedAt": "Created",
        "ColUploader": "Uploader",
        "ColDescription": "Description",
        "ColDefault": "Default?",
        "NoDefaultWarning": "Warning! No default template is set!",
    },
    "LicensePlate": {
        "Title": "Vehicle registration plate no.",
        "NewButton": "New",
        "FormInvalid": "Vehicle registration plate no. required",
        "SaveSuccess": "Vehicle registration plate no. saved successfully",
        "TitleNew": "New vehicle registration plate no.",
        "TitleEdit": "Edit vehicle registration plate no.",
        "CancelButton": "Cancel",
        "DeleteButton": "Delete",
        "SaveButton": "Save",
        "DescriptionLabel": "Description",
        "PlateNumberLabel": "Plate number",
        "ColCreatedAt": "Created",
        "ColUploader": "Uploader",
        "ColDescription": "Description",
        "ColPlateNumber": "Plate number",
    },
    "Note": {
        "Header": "Delivery notes",
        "Settings": "Settings",
        "ListLoadMoreText": "Load more...",
        "ListItemCustomerText": "Customer",
        "ListItemCustomerAddressText": "Customer address",
        "ListItemStateText": "State",
        "ShowDeletedText": "Show deleted",
        "ShowDeletedYes": "Yes",
        "ShowDeletedNo": "No",
        "ListItemTypeText": "Type",
        "ListItemSupplierText": "Supplier",
        "ListIssuerText": "Issuer",
        "SearchText": "Search",
        "TypeText": "Type",
        "LeaseStatusText": "Rental status",
        "NoResult": "No result",
        "CreatedBy": "Created by",
        "Type": {
            "All": "All",
            "SaleText": "Sale",
            "LeaseText": "Rental",
            "RepairText": "Repair",
            "OtherText": "Other",
        },
        "LeaseStatus": {
            "All": "All",
            "Missing": "No data",
            "Open": "Open",
            "Finished": "Finished",
        },
        "Dialog": {
            "NewButtonText": "New note",
            "NewTitle": "New delivery note",
            "EditTitle": "Edit delivery note",
            "Browse": "Browse",
            "Type": "Type",
            "LeaseStatus": "Rental state",
            "DatedAt": "Dated at",
            "Supplier": "Supplier",
            "SupplierName": "Name",
            "SupplierAddress": "Address",
            "SupplierContactName": "Contact name",
            "IssuerName": "Issuer name",
            "Customer": "Customer",
            "SelectEmployee": "Select employee",
            "CustomerName": "Name",
            "CustomerBillingAddress": "Billing address",
            "CustomerShippingAddress": "Shipping address",
            "CustomerContactName": "Contact name",
            "CustomerContactPhone": "Contact person's phone no.",
            "Delivery": "Delivery",
            "OrderNumber": "Order number",
            "LicensePlateNumber": "Vehicle registration plate no.",
            "NewRow": "New row",
            "Row": "Row",
            "ItemName": "Item name",
            "Quantity": "Quantity",
            "UnitOfQuantity": "UoQ",
            "OperatingHours": "Operating hours",
            "RemoveTitle": "Remove row",
            "Remove": "Remove",
            "Source": "Source",
            "EquipmentMasterData": "Equipment master data",
            "ItemMasterData": "Item master data",
            "EmptyRow": "Empty row",
            "SelectBtn": "Select",
            "NewRowDialogTitle": "Select new row",
            "EmptyRowP": "Adding empty row.",
            "DialogSearch": "Search",
            "ColCardCode": "Customer code",
            "ColCardName": "Name",
            "ColVehicleManufacturer": "Manufacturer",
            "ColVehicleType": "Type",
            "ColVehicleYoM": "YoM",
            "ColManufacturerSn": "Manufacturer SN",
            "InternalSn": "Internal SN",
            "ColItemCode": "Code",
            "ColItemName": "Description",
            "ColSww": "Manufacturer's reference number",
            "AddBtn": "Add",
            "CancelBtn": "Cancel",
            "InvalidSourceError": "Invalid source.",
            "EquipmentRequiredError": "Selecting an equipment is required.",
            "ItemRequiredError": "Selecting an item is required.",
            "EqLoading": "Loading equipments...",
            "ItemLoading": "Loading items...",
            "Cancel": "Cancel",
            "Save": "Save",
            "ReqRows": "Add at least one row.",
            "ReqRowFields": "The quantity and the unit of measurement fields are required for every row.",
            "ReqField": "Field is required.",
            "TooLong50": "Cannot be longer than 50 characters.",
            "TooLong255": "Cannot be longer than 255 characters.",
            "TooLongRows255": "The description and the unit of measurement cannot be longer than 255 characters.",
            "InvalidNumberRows": "Invalid numbers for some rows.",
            "ShowOnlySelectedCustomerEqs": "Show only the selected customer's equipments.",
            "ShowOnlyPhoenixEqs": "Show only our own equipments.",
            "CancelConfirmMessage": "Are you sure? Unsaved changes will be lost.",
            "Delete": "Delete",
            "Undelete": "Restore",
            "DeleteConfirmMessage": "Are you sure you want to delete the delivery note?",
            "UndeleteSuccess": "Restored. Please save the changes."
        },
    },
    "SelectPartner": {
        "Title": "Select business partner",
        "SelectBtn": "Select",
        "Cancel": "Cancel",
        "Please": "Please select a BP.",
        "ColCardCode": "Code",
        "ColCardName": "Name",
        "SearchLabel": "Search",
    },
}