// Important, custom import expression for teams!
import {app as TeamsApp} from "@microsoft/teams-js";
import {DynamicThemeUtil} from "shared/theme/DynamicThemeUtil";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {TestApi, UserProfileApi} from "crowbar-api";
import {toastSuccess} from "shared/toast/DefaultToasts";
import {useAtom} from "jotai";
import {
    appAuthenticationErrorAtom,
    appAuthenticationErrorReasonAtom,
    appCurrentMuiThemeAtom,
    appCurrentThemeAtom,
    appIsInitializedAtom,
    msalTeamsAuthenticationHappenedAtom,
    msalTeamsContextAtom,
    msalTeamsInitializationHappenedAtom,
    msalTeamsInitializationRejectedAtom,
    msalUserClaimsAtom
} from "App";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";
import appContext from "AppContext";
import TeamsContext from "shared/teams/TeamsContext";

export const useTeamsInitialize = (): void => {
    const {i18n, t} = useTranslation()
    const [, setInitialized] = useAtom(appIsInitializedAtom);
    const [, setAuthenticationError] = useAtom(appAuthenticationErrorAtom);
    const [, setAuthenticationErrorReason] = useAtom(appAuthenticationErrorReasonAtom);
    const [, setCurrentTheme] = useAtom(appCurrentThemeAtom);
    const [, setCurrentMuiTheme] = useAtom(appCurrentMuiThemeAtom);
    const [, setTeamsAuthHappened] = useAtom(msalTeamsAuthenticationHappenedAtom);
    const [, setTeamsInitHappened] = useAtom(msalTeamsInitializationHappenedAtom);
    const [, setTeamsInitRejected] = useAtom(msalTeamsInitializationRejectedAtom);
    const [, setTeamsContext] = useAtom(msalTeamsContextAtom)
    const [, setUserClaims] = useAtom(msalUserClaimsAtom)

    useEffect(() => {
        const msTeamsInitialize = async () => {
            // Auth
            // If embedded in teams, we always ask the teams sdk for the token
            // (it caches it automatically and always returns the correct key)
            // If not embedded, we use msal to authenticate and store the token in
            // local storage.
            try {
                await TeamsApp.initialize()
                TeamsApp.notifySuccess()
                setTeamsInitHappened(true)
                setInitialized(true)
                setTeamsAuthHappened(true)
                // AppContext help to decide between MsTeams and normal auth context
                // by default it handles this as not a teams context
                appContext.setTeamsInitialized(true); // signal teams context
            } catch {
                setTeamsInitRejected(true)
                // end the initialization
                return
            }

            // Read Teams context and set theme and language
            const ctx = await new TeamsContext().get()
            // Save teams context to atom
            setTeamsContext(ctx)

            // Themes
            /* not supported dark theme as of 2023-03
            setCurrentTheme(ctx.theme === "dark" ? "ct-theme-dark" : "ct-default-theme")
            setCurrentMuiTheme(ctx.theme === "dark" ? muiCreateTheme({
                palette: {
                    mode: "dark"
                }
            }) : muiCreateTheme())
            DynamicThemeUtil.setTheme(ctx.theme);
             */
            setCurrentTheme("ct-default-theme")
            DynamicThemeUtil.setTheme("default");

            // Language
            i18n?.changeLanguage(ctx.language);

            // Load claims from server
            const userProfileApi = crowbarApiFactory(UserProfileApi);
            try {
                const claims = await userProfileApi.userClaims()
                setUserClaims(claims.data)
            } catch (reason: any) {
                console.error('API failed to read claims for user. Reason: ' + reason?.toString());
            }

            // Check authentication and set the error flag if needed
            const testApi = crowbarApiFactory(TestApi);
            try {
                await testApi.heartbeat()
                console.log('API Heartbeat OK.')

                toastSuccess(t('Home.ConnectedToTeamsToast'), {toastId: "teams-initialized"})
            } catch (reason: any) {
                console.error('API Heartbeat error. Reason: ' + reason);
                setAuthenticationError(true)
                setAuthenticationErrorReason(reason)
            }
        }

        (async () => {
            await msTeamsInitialize()
        })()

        // eslint-disable-next-line
    }, [] /* runs once */)
}