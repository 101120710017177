import {StringUtils} from "shared/utils/StringUtils";

export class NumberUtils {
    public static cleanNumberOnlyHTMLInputValue = (value: string): string => {
        let cleanedInputValue = value.replace(/[^.\d]/g, '')
        if (cleanedInputValue.startsWith("0") || cleanedInputValue.endsWith(".")) {
            cleanedInputValue = StringUtils.trimStartWhile(cleanedInputValue, c => c === '0')
            if (cleanedInputValue.startsWith(".")) {
                cleanedInputValue = "0" + cleanedInputValue
            }
            // cleanedInputValue = StringUtils.trimEndWhile(cleanedInputValue, c => c === '.')
        }

        if (cleanedInputValue === "") {
            cleanedInputValue = "0"
        }

        return cleanedInputValue
    }

    public static asNumberOrZero = (num: string): number => {
        return isNaN(+num) ? 0 : +num
    }
}