import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    FormControlLabel,
    Grid,
    Stack,
    Switch,
    Typography
} from '@mui/material';
import React from 'react';
import useTranslateFunction from "shared/language/useTranslateFunction";
import {useNotificationCenterState} from "modules/generic/notification-center/useNotificationCenterState";
import {UseNotificationCenter} from "react-toastify/addons/use-notification-center";
import NotificationEntry from "modules/generic/notification-center/NotificationEntry";

export interface NotificationCenterProps<T extends {}> extends UseNotificationCenter<T> {

}

const NotificationCenter = <T extends {}, >({notifications, markAllAsRead, clear}: NotificationCenterProps<T>) => {
    const tk = useTranslateFunction("Generic.NotificationCenter")

    const {showRead, setState} = useNotificationCenterState()

    const onShowReadChange = (checked: boolean) => {
        setState(prev => {
            return {
                ...prev,
                showRead: checked
            }
        })
    }

    const onClearAll = () => {
        clear()
    }

    const onMarkAllAsRead = () => {
        markAllAsRead(true)
    }

    return (
        <Card variant="outlined" sx={{p: 1, width: "550px"}}>
            <CardContent>
                <Grid container spacing={1}>

                    <Grid item xs={12}>
                        <Grid container spacing={1} alignItems="center" justifyContent="space-between">
                            <Grid item xs="auto">
                                <Typography>{tk('Notifications')}</Typography>
                            </Grid>
                            <Grid item xs="auto">
                                <FormControlLabel
                                    label={tk('ShowRead')}
                                    control={(
                                        <Switch
                                            checked={showRead}
                                            onChange={(event, checked) => onShowReadChange(checked)}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Box sx={{
                            height: "400px",
                            overflowY: "scroll",
                            overflowX: "hidden",
                            p: 1
                        }}>
                            <Stack direction="column" spacing={1}>
                                {notifications
                                    .filter(n => !n.read || showRead)
                                    .map((notification, i) => (
                                        <NotificationEntry key={[notification.id, i].join("-")}
                                                           notification={notification}/>
                                    ))}
                            </Stack>
                        </Box>
                    </Grid>

                </Grid>
            </CardContent>
            <CardActions sx={{
                display: "flex",
                justifyContent: "space-between"
            }}>
                <Button variant="text" onClick={onClearAll}>
                    {tk('ClearAll')}
                </Button>
                <Button variant="text" onClick={onMarkAllAsRead}>
                    {tk('MarkAllAsRead')}
                </Button>
            </CardActions>
        </Card>
    );
};

export default NotificationCenter;