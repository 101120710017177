import React, {useEffect, useState} from 'react';
import {Route, Routes, useParams} from "react-router-dom";
import {Stack} from "@mui/material";
import {useWorksheetEditorHeaderInfoAtom} from "modules/worksheet/editor/state/WorksheetEditorHeaderAtoms";
import {useWorksheetLocalQuery} from "modules/worksheet/editor/shared/data/useWorksheetLocalQuery";
import {
    useScheduledWorksheetSaveToLocalState
} from "modules/worksheet/editor/shared/data/useScheduledWorksheetSaveToLocalState";
import {useWorksheetSaveState} from "modules/worksheet/editor/state/WorksheetSaveAtoms";
import WEditorFailedToLoadWorksheetErrorPage
    from "modules/worksheet/editor/ui/error/WEditorFailedToLoadWorksheetErrorPage";
import useAutoSelectServiceCall from "modules/worksheet/editor/shared/data/useAutoSelectServiceCall";
import WorksheetEditorUi from "modules/worksheet/editor/WorksheetEditorUi";
import LinearProgress from "@mui/material/LinearProgress";
import useWorksheetQueryAsSave from "crowbar-api/hooks/worksheet/useWorksheetQueryAsSave";
import SentryErrorBoundary from "shared/components/error/SentryErrorBoundary";
import WorksheetEditorSignature from "modules/worksheet/editor/ui/signature/WorksheetEditorSignature";
import {useWorksheetInitialize} from "modules/worksheet/editor/shared/data/useWorksheetInitialize";

const WorksheetEditor = () => {
    const {worksheetId} = useParams() // tied to the url
    const [initializationExecuted, setInitializationExecuted] = useState<boolean>(false) // tied to the component

    const {
        mutate: worksheetInitialize,
        isLoading: worksheetInitializeIsLoading
    } = useWorksheetInitialize()

    const [ws] = useWorksheetSaveState()
    const [, setInfo] = useWorksheetEditorHeaderInfoAtom()

    // Load the local value and the server value as well
    const worksheetLocalQuery = useWorksheetLocalQuery(worksheetId)
    const worksheetServerQuery = useWorksheetQueryAsSave(worksheetId)

    // Initial worksheet header state setter
    // runs when worksheet id is changes
    useEffect(() => {
        setInfo(prev => {
            return {
                ...prev,
                worksheetId: worksheetId
            }
        })
        console.log("Worksheet editor worksheetId state set by url: " + worksheetId)
    }, [setInfo, worksheetId])

    // Initial worksheet save state setter
    // runs when worksheet id changes
    useEffect(() => {
        // set the ws atom values, use the server query value if the
        // local is missing or the worksheetId is not matching
        if (!worksheetServerQuery.isLoading && !worksheetInitializeIsLoading &&
            (ws?.id === undefined || ws.id !== worksheetId || !initializationExecuted)
        ) {
            console.log(`Starting worksheet initialization for '${worksheetId}'.`)
            setInitializationExecuted(true)
            worksheetInitialize({worksheetId, serverWorksheet: worksheetServerQuery.data ?? undefined})
        }
    }, [
        worksheetId, /* url parameter */
        worksheetServerQuery /* changes a lot! Init is still not called just because of that */,
        worksheetInitialize /* only the mutate(), the whole useMutate return would change on every render */,
        worksheetInitializeIsLoading, /* same */
        ws?.id, /* local state's id */
        /* tracking the execution state. we must call the init even if we navigate to the same
        * url (meaning the same worksheetId url param and without this state,
        * the init would not run, the worksheetId would be the same even after the new component
        * mount and the useEffect wouldn't even trigger) */
        initializationExecuted,
    ])

    // Periodically save the in memory atom state to indexed db
    // only called here and uses useEffect and setTimeout to auto save.
    useScheduledWorksheetSaveToLocalState()

    // Auto select service call if needed
    useAutoSelectServiceCall()

    if (worksheetLocalQuery.isError) {
        return <WEditorFailedToLoadWorksheetErrorPage worksheetQuery={worksheetLocalQuery}/>
    }

    if (!worksheetId) return <></>

    return (
        <SentryErrorBoundary>
            <Routes>
                <Route
                    path="signature"
                    element={(
                        <WorksheetEditorSignature/>
                    )}
                />
                <Route path="*" element={
                    <Stack
                        key={worksheetId}
                        direction={"column"}
                        spacing={2}
                        sx={{
                            padding: 2
                        }}
                    >
                        {(worksheetLocalQuery.isLoading || worksheetServerQuery.isLoading ||
                            worksheetServerQuery.isFetching || worksheetServerQuery.isFetching) ? (
                            <LinearProgress/>
                        ) : (
                            <WorksheetEditorUi/>
                        )}
                    </Stack>
                }/>
            </Routes>
        </SentryErrorBoundary>
    )
}

export default WorksheetEditor;