import {useQuery} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {LicensePlateApi} from "crowbar-api";
import AxiosCancelTokenHelper from "crowbar-api/AxiosCancelTokenHelper";

export const fetchLicensePlatesFindAll = async (signal: AbortSignal | undefined) => {
    const response = await crowbarApiFactory(LicensePlateApi).findAll(AxiosCancelTokenHelper.cancelTokenFrom(signal));
    return response.data ?? [];
}

export const useLicensePlatesFindAll = () => {
    return useQuery(["license-plates"], async ({signal}) => {
        return await fetchLicensePlatesFindAll(signal)
    }, {
        networkMode: "always",
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        cacheTime: Math.pow(10, 7),
        staleTime: Math.pow(10, 7),
        retry: 3
    })
}