import React, {useEffect, useMemo, useRef, useState} from 'react'

type Props = {
    /**
     * Whether the element should be visible initially or not.
     * Useful e.g. for always setting the first N items to visible.
     * Default: false
     */
    initialVisible?: boolean
    /** An estimate of the element's height */
    defaultHeight?: number
    /** How far outside the viewport in pixels should elements be considered visible?  */
    visibleOffset?: number
    /** Should the element stay rendered after it becomes visible? */
    stayRendered?: boolean
    root?: HTMLElement | null
    /** E.g. 'span', 'tbody'. Default = 'div' */
    rootElement?: string
    rootElementClass?: string
    placeholderElement?: JSX.Element
    children: React.ReactNode
}

/**
 * fork of https://github.com/NightCafeStudio/react-render-if-visible/blob/main/src/render-if-visible.tsx
 * Changed: placeholderElement
 * @param initialVisible
 * @param defaultHeight
 * @param visibleOffset
 * @param stayRendered
 * @param root
 * @param rootElement
 * @param rootElementClass
 * @param placeholderElement
 * @param placeholderElementClass
 * @param children
 * @constructor
 */
const RenderIfVisible = ({
                             initialVisible = false,
                             defaultHeight = 300,
                             visibleOffset = 1000,
                             stayRendered = true,
                             root = null,
                             rootElement = 'div',
                             rootElementClass = '',
                             placeholderElement = <></>,
                             children,
                         }: Props) => {
    const [isVisible, setIsVisible] = useState<boolean | undefined>(initialVisible)
    const wasVisible = useRef<boolean | undefined>(initialVisible)
    const placeholderHeight = useRef<number | undefined>(defaultHeight)
    const intersectionRef = useRef<HTMLDivElement | null>(null)

    // Set visibility with intersection observer
    useEffect(() => {
        if (intersectionRef.current) {
            const localRef = intersectionRef.current
            const observer = new IntersectionObserver(
                (entries) => {
                    // Before switching off `isVisible`, set the height of the placeholder
                    if (!entries[0].isIntersecting) {
                        placeholderHeight.current = localRef!.offsetHeight
                    }
                    if (window?.requestIdleCallback) {
                        window.requestIdleCallback(
                            () => setIsVisible(entries[0].isIntersecting),
                            {
                                timeout: 600,
                            }
                        )
                    } else {
                        setIsVisible(entries[0].isIntersecting)
                    }
                },
                {root, rootMargin: `${visibleOffset}px 0px ${visibleOffset}px 0px`}
            )

            observer.observe(localRef)
            return () => {
                if (localRef) {
                    observer.unobserve(localRef)
                }
            }
        }
        return () => {
        }
    }, [root, visibleOffset])

    useEffect(() => {
        if (isVisible) {
            wasVisible.current = true
        }
    }, [isVisible])

    const rootClasses = useMemo(
        () => `renderIfVisible ${rootElementClass}`,
        [rootElementClass]
    )

    return React.createElement(rootElement, {
        children: isVisible || (stayRendered && wasVisible.current) ? (
            <>{children}</>
        ) : (
            placeholderElement
        ),
        ref: intersectionRef,
        className: rootClasses,
    })
}

export default RenderIfVisible