import {CDAMWMWModSapPartnerAddressSave} from "crowbar-api";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import React from "react";
import {
    PartnerAddressFormValues,
    usePartnerAddressForm
} from "modules/worksheet/editor/shared/component/partner-address-form/usePartnerAddressForm";
import PartnerAddressForm from "modules/worksheet/editor/shared/component/partner-address-form/PartnerAddressForm";
import useTranslateFunction from "shared/language/useTranslateFunction";

interface WEditorPageBasicPartnerAddressDialogProps {
    mode: 'new' | 'edit'
    open: boolean
    onDismiss: () => void
    onSubmit: (data: PartnerAddressFormValues) => void | Promise<void>
    editModSapPartnerAddress?: CDAMWMWModSapPartnerAddressSave
}

const WEditorPageBasicPartnerAddressDialog = ({
                                                  mode,
                                                  open,
                                                  onDismiss,
                                                  onSubmit,
                                                  editModSapPartnerAddress
                                              }: WEditorPageBasicPartnerAddressDialogProps) => {

    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    const form = usePartnerAddressForm({
        mode,
        editModAddress: editModSapPartnerAddress
    })

    const onClose = () => {
        onDismiss()
    }

    const onDialogSubmit = async () => {
        const handleFunction = form.handleSubmit((data, event) => {
            onClose()
            onSubmit(data)
        }, e => console.error(e))

        await handleFunction()
    }

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle>
                {mode === 'new' ? tk('NewPartnerAddress') : tk('Edit')}
            </DialogTitle>
            <DialogContent>
                <Box sx={{p: 1}}>
                    <PartnerAddressForm mode={mode} form={form}/>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    color="error"
                    variant="outlined"
                    onClick={() => onClose()}
                >
                    {tk('Cancel')}
                </Button>
                <div style={{flex: "1 0 0"}}/>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => onDialogSubmit()}
                    disabled={!form.formState.isDirty && !form.formState.isValid}
                >
                    {
                        mode === "new" ? tk('Add') : tk('Edit')
                    }
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default WEditorPageBasicPartnerAddressDialog