import React from 'react';
import {
    CustomerNotificationConfigurationState
} from "modules/admin/worksheet/notifications/customer-notification/state/useCustomerNotificationConfigurationState";
import {atomWithStorage} from "jotai/utils";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {Stack} from "@mui/material";
import {
    CustomerNotificationConfigurationFilters
} from "modules/admin/worksheet/notifications/customer-notification/filters/CustomerNotificationConfigurationFilters";
import {
    CustomerNotificationConfigurationResults
} from "modules/admin/worksheet/notifications/customer-notification/results/CustomerNotificationConfigurationResults";


const defaultValue: CustomerNotificationConfigurationState = {
    search: "",
    showDeleted: false,
    lastRowNumber: 0,
    limit: 50,
    orderByIsDesc: true
}

const stateAtom = atomWithStorage<CustomerNotificationConfigurationState>("UiState-Admin-CustomerNotificationConfiguration", defaultValue)

const CustomerNotificationConfiguration = () => {
    const tk = useTranslateFunction(`Admin.Worksheet`)

    return (
        <Stack direction="column" spacing={1}>
            <h1>{tk('CustomerNotificationConfiguration')}</h1>

            <CustomerNotificationConfigurationFilters stateAtom={stateAtom}/>
            <CustomerNotificationConfigurationResults stateAtom={stateAtom}/>
        </Stack>
    );
};

export default CustomerNotificationConfiguration;