import {useQuery} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {SapPsgVehicleTypeCategoryApi} from "crowbar-api";
import AxiosCancelTokenHelper from "crowbar-api/AxiosCancelTokenHelper";

const usePsgManufacturers = () => {
    return useQuery(['photo-rule-psg-manufacturers'], async (context) => {
        const response = await crowbarApiFactory(SapPsgVehicleTypeCategoryApi)
            .findAllManufacturers(AxiosCancelTokenHelper.cancelTokenFrom(context.signal))
        if (response.status !== 200) {
            throw new Error('Failed to load psg manufacturers.')
        }
        return response.data
    }, {
        cacheTime: Math.pow(10, 7),
        staleTime: Math.pow(10, 7),
        retry: 1,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        placeholderData: []
    })
}

export default usePsgManufacturers