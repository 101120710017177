import React from 'react';
import {CDAMWMServiceCallEvalModel, CDAMWMServiceCallModel, CDAMWMWWorksheetQuery} from "crowbar-api";
import ServiceCallSelectedSourceMap from "crowbar-api/enum-map/ServiceCallSelectedSourceMap";
import ModSapTypeMap from "crowbar-api/enum-map/ModSapTypeMap";
import useTranslateFunction from "shared/language/useTranslateFunction";

export type TypeCellProps = {
    row: CDAMWMServiceCallEvalModel
    worksheet: CDAMWMWWorksheetQuery | null | undefined
    serviceCalls: CDAMWMServiceCallModel[]

}
const TypeCell = ({row, worksheet, serviceCalls}: TypeCellProps) => {
    const tk = useTranslateFunction('Admin.Worksheet')

    const sc = serviceCalls.find(sc => sc.id === row.serviceCallId)
    if (!sc) return (
        <span>-</span>
    )

    const modEquipment = !row.equipmentChanged && sc.modSapEquipmentId
        ? (worksheet?.modSapEquipments ?? []).find(m => m.id === sc.modSapEquipmentId)
        : undefined

    if (sc.selectedSource === ServiceCallSelectedSourceMap.empty) {
        return (
            <span>
                {tk('Subject')}
            </span>
        )
    }

    if (!modEquipment) {
        return (
            <span>
                    {tk('Equipment')}
                </span>
        )
    } else {
        const isCreate = modEquipment.type === ModSapTypeMap.create
        return (
            <span>
                    {isCreate ? (
                        tk('NewEquipment')
                    ) : (
                        tk('EditedEquipment')
                    )}
                </span>
        )
    }
}

export default TypeCell;