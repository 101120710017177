import React from 'react';
import useWorksheetSaveMutation, {
    UseWorksheetSaveMutationResult
} from "crowbar-api/hooks/worksheet/useWorksheetSaveMutation";
import {toastError, toastSuccess} from "shared/toast/DefaultToasts";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {useNewAtomWithRef} from "shared/hook/useNewatomWithRef";
import ConfirmDialog from "shared/components/confirm-dialog/ConfirmDialog";
import {useWorksheetEditorHeaderInfoAtom} from "modules/worksheet/editor/state/WorksheetEditorHeaderAtoms";

export interface WorksheetSaveConfirmationDialogProps {
    render: ((onClickStartSave: () => Promise<UseWorksheetSaveMutationResult>, isLoading: boolean) => JSX.Element) | undefined
}

/**
 * Accepts a render function. The onClick that starts the save is passed in as a parameter.
 * Along with the currently loading state.
 *
 * Sadly, {@link useWorksheetSaveMutation}'s state is not shared by default
 * if we use useMutation, but possible with some hacks:
 * https://github.com/TanStack/query/issues/2304
 * We use {@link useCustomMutation} to share the state!
 * This way, if the {@link useWorksheetSaveMutation} is called somewhere else,
 * we can check the isLoading property directly to correctly determine the save state!
 *
 * @param render
 * @constructor
 */
const WorksheetSaveConfirmationDialog = ({render}: WorksheetSaveConfirmationDialogProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)
    const [confirmDialogOpen, setConfirmDialogOpen] = useNewAtomWithRef<boolean>(false)

    const worksheetSave = useWorksheetSaveMutation()
    const [, setInfo] = useWorksheetEditorHeaderInfoAtom()

    const onClickStartSave = async (): Promise<UseWorksheetSaveMutationResult> => {
        return await runSave(false)
    }

    const runSave = async (ignorePreviousVersion: boolean): Promise<UseWorksheetSaveMutationResult> => {
        let r: UseWorksheetSaveMutationResult
        try {
            r = await worksheetSave.mutateAsync({ignorePreviousVersion: ignorePreviousVersion})
        } catch (e) {
            toastError(tk('SaveError') + ' ' + e)
            return {
                hasError: true,
                differentPreviousVersionError: false,
                saveResult: undefined
            }
        }
        console.log(r)
        if (r.differentPreviousVersionError) {
            setConfirmDialogOpen(true)
        }

        const serialId = r.saveResult?.saveResult?.serialId
        if (r.hasError) {
            if (r.differentPreviousVersionError) {
                toastError(tk('SaveError') + ' ' + tk('DifferentPreviousVersionError'))
            } else {
                const error = r?.saveResult?.saveError ?? "N/A"
                toastError(tk('SaveError') + ' ' + error)
            }
        } else {
            if (ignorePreviousVersion) {
                setInfo(prev => {
                    return {
                        ...prev,
                        differentServerVersionFlag: false
                    }
                })
            }
            toastSuccess(tk('WorksheetSaved', {serialId: serialId ?? ""}))
        }

        return r
    }

    const onIgnorePreviousConfirmed = async () => {
        setConfirmDialogOpen(false)
        await runSave(true)
    }

    return (
        <>
            {!!render && render(onClickStartSave, worksheetSave.isLoading)}

            <ConfirmDialog
                open={confirmDialogOpen}
                onConfirm={onIgnorePreviousConfirmed}
                onClose={() => setConfirmDialogOpen(false)}
                dialogTitle={tk("OverwritePreviousVersion")}
                dialogText={tk('OverwritePreviousVersionText')}
            />
        </>
    )
}

export default WorksheetSaveConfirmationDialog;