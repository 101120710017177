import React from 'react';
import useTranslateFunction from "shared/language/useTranslateFunction";
import {v4 as uuid} from "uuid";
import useSaveWorksheetTimeItemCode
    from "crowbar-api/hooks/worksheet/worksheet-time-item-codes/useSaveWorksheetTimeItemCode";
import {Button, Stack, TextField} from '@mui/material';
import {useNewAtomWithRef} from "shared/hook/useNewatomWithRef";
import {toastError} from "shared/toast/DefaultToasts";

import AddIcon from '@mui/icons-material/Add';
import {CDAMWMWorksheetTimeItemCodeModel} from "crowbar-api";

export interface WorksheetTimeItemCodeAddFieldProps {
    codes: CDAMWMWorksheetTimeItemCodeModel[]
    onItemCodeAdded: () => Promise<void>
}

const WorksheetTimeItemCodeAddField = ({codes, onItemCodeAdded}: WorksheetTimeItemCodeAddFieldProps) => {
    const tk = useTranslateFunction('Worksheet.Generic.WorksheetTimeItemCodes')

    const [input, setInput] = useNewAtomWithRef<string>("")

    const saveItemCode = useSaveWorksheetTimeItemCode()

    const onAdd = async () => {
        if (!input || input.length > 50) {
            toastError('Required and Length > 50')
            return
        }
        if (!/^\d+$/.test(input)) {
            toastError('Only numbers allowed.')
            return
        }
        if (codes.find(c => c.itemCode === input) !== undefined) {
            toastError('Already exists.')
            return
        }
        await saveItemCode.mutateAsync({
            id: uuid(),
            itemCode: input
        })
        await onItemCodeAdded()
        setInput("")
    }

    return (
        <Stack direction="row" spacing={1}>
            <TextField
                value={input}
                onChange={(e) => setInput(e.target.value)}
            />
            <Button
                variant="text"
                color="primary"
                onClick={onAdd}
            >
                <AddIcon/>
                {tk('Add')}
            </Button>
        </Stack>
    );
};

export default WorksheetTimeItemCodeAddField;