import React from 'react';
import WorksheetEditorHeaderContentCompact
    from "modules/worksheet/editor/ui/header/WorksheetEditorHeaderContentCompact";
import WEditorHeaderContentRoutes from "modules/worksheet/editor/ui/header/WEditorHeaderContentRoutes";
import {Box} from "@mui/material";
import useWorksheetHeaderOpenState from "modules/worksheet/editor/ui/header/useWorksheetHeaderOpenState";
import {worksheetHeaderOpenRoutes} from "modules/worksheet/editor/ui/header/WorksheetHeaderOpenRoutes";

interface WorksheetEditorHeaderContentProps {

}

const WorksheetEditorHeaderContent = ({}: WorksheetEditorHeaderContentProps) => {
    const open = useWorksheetHeaderOpenState()
    return (
        <Box sx={{
            padding: 1
        }}>
            {open
                ? (
                    <WEditorHeaderContentRoutes
                        routes={worksheetHeaderOpenRoutes}
                    />
                )
                : (<WorksheetEditorHeaderContentCompact/>)
            }
        </Box>
    );
};

export default WorksheetEditorHeaderContent;