import useWorksheetTripItemCodes from "crowbar-api/hooks/worksheet/worksheet-trip-item-codes/useWorksheetTripItemCodes";
import {useCallback} from "react";
import {CDAMWMWServiceCallItemRowSave, CDAMWMWWorksheetSapSalesOrderRowSave} from "crowbar-api";

export type IsTripMatchItemRow = CDAMWMWServiceCallItemRowSave | CDAMWMWWorksheetSapSalesOrderRowSave |
    { isTrip?: boolean } | { itemCode?: string }

/**
 * Decides for the item row if the
 * row can be used for distance calculations (trip=true)
 *
 * ItemCode is trip:
 *  1. if the IsTrip = true on the ServiceCallItemRow (static db value)
 *  2. if the tripCodes db list contain the ItemCode
 *  If the server is offline, this list
 */
const useIsTripMatchFn = () => {
    const tripCodes = useWorksheetTripItemCodes()
    return useCallback((itemRow: IsTripMatchItemRow) => {
        const isTripMatch = "isTrip" in itemRow && itemRow.isTrip

        const tripCodesMatch = "itemCode" in itemRow &&
            tripCodes?.data &&
            tripCodes.data.find(c => c.itemCode === itemRow.itemCode) !== undefined

        return isTripMatch || tripCodesMatch
    }, [tripCodes.data])
}

export default useIsTripMatchFn