import WEditorPageBasicAddressBox from "modules/worksheet/editor/ui/basic/WEditorPageBasicAddressBox";
import WEditorPageBasicContactBox from "modules/worksheet/editor/ui/basic/WEditorPageBasicContactBox";
import {Grid} from "@mui/material";
import WEditorPageBasicTrip from "modules/worksheet/editor/ui/basic/trip/WEditorPageBasicTrip";
import {useWorksheetSaveState} from "modules/worksheet/editor/state/WorksheetSaveAtoms";
import WEditorPageBasicDurationBox from "modules/worksheet/editor/ui/basic/WEditorPageBasicDurationBox";

interface WEditorPageBasicCustomerProps {
    disabled?: boolean
}

const WEditorPageBasicCustomer = ({disabled}: WEditorPageBasicCustomerProps) => {

    const [ws] = useWorksheetSaveState()

    return (
        <Grid container direction="row" spacing={2} alignItems="stretch">
            <Grid item xs={12} md={6}>
                <WEditorPageBasicAddressBox disabled={disabled}/>
            </Grid>
            <Grid item xs={12} md={6}>
                <WEditorPageBasicDurationBox disabled={disabled}/>
            </Grid>
            <Grid item xs={12} md={6}>
                <WEditorPageBasicTrip disabled={disabled}/>
            </Grid>
            <Grid item xs={12} md={6}>
                <WEditorPageBasicContactBox disabled={disabled}/>
            </Grid>
            {/*(ws?.salesPersonSource !== undefined && ws.salesPersonSource !== WorksheetSelectedDataSourceMap.notSet) && (
                <Grid item xs={12} md={3} sx={{height: "100%"}}>
                    <WEditorBasicSalesPerson/>
                </Grid>
            )*/}
        </Grid>
    )
}

export default WEditorPageBasicCustomer