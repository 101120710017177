const i18n_sirI18nEn = {
    "Home": {
        "Title": "Service issue reports",
        "SearchLabel": "Search",
        "NewButtonText": "New report",
        "EditIconTitle": "Edit",
    },
    "Select": {
        "Title": "Select customer or vehicle",
        "EditErrorNoId": "Missing ID",
        "EditErrorNoIdMsg": "ID is missing from the URL",
        "SearchLabel": "Search",
        "SkipButtonText": "Skip",
    },
    "SelectList": {
        "LoadMoreButtonText": "Load more",
        "SelectCustomerText": "Choose a customer or vehicle (optional)"
    },
    "SirForm": {
        "NewTitle": "New report",
        "EditTitle": "Edit report",
        "PartnerNameLabel": "Partner name",
        "AddressLabel": "Address",
        "DescriptionLabel": "Description",
        "ContactNameLabel": "Contact name",
        "PhoneLabel": "Phone",
        "EmailLabel": "E-mail",
        "VehicleManufacturerLabel": "Vehicle manufacturer",
        "VehicleTypeLabel": "Vehicle type",
        "VehicleSnLabel": "Manufacturer SN",
        "ShippingAddressShort": "Shipping addresses",
        "BillingAddressShort": "Billing addresses",
        "AddressDropdownLabel": "SAP addresses",
        "ContactDropdownLabel": "SAP contacts",
        "SelectLabel": "Select",
        "MissingName": "Missing name",
        "SubmitNew": "Add",
        "SubmitEdit": "Update",
        "ToastSirNotFound": "Issue not found.",
    }
};
export default i18n_sirI18nEn;