import React from 'react';
import {CDAMWMWServiceCallSearchQuery, CDAMWMWWorksheetSearchQuery} from "crowbar-api";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    Grid,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
    Typography
} from "@mui/material";
import ImageIcon from "shared/components/image-icon/ImageIcon";
import {WorksheetIconImages} from "modules/worksheet/editor/shared/component/images/WorksheetIconImages";
import FinishedIcon from "modules/worksheet/editor/ui/drawer/FinishedIcon";
import useTranslateFunction from "shared/language/useTranslateFunction";
import VehicleTypeDisplay from "modules/worksheet/editor/shared/component/vehicle-type-display/VehicleTypeDisplay";
import ResultEntryServiceCallTextLines from "modules/worksheet/mechanic/results/ResultEntryServiceCallTextLines";
import {useMountedState} from "shared/hook/useMountedState";
import {useOpenWorksheetEditor} from "modules/worksheet/editor/state/events/useOpenWorksheetEditor";
import EquipmentThumbnailForServiceCall
    from "modules/worksheet/editor/shared/component/equipment-thumbnail/EquipmentThumbnailForServiceCall";
import {useWorksheetStatuses} from "modules/worksheet/mechanic/results/useWorksheetStatuses";
import ResultEntryServiceCallWorkHours from "modules/worksheet/mechanic/results/ResultEntryServiceCallWorkHours";

export type ResultEntryModeWorksheetServiceCallProps = {
    worksheet: CDAMWMWWorksheetSearchQuery
    serviceCall: CDAMWMWServiceCallSearchQuery

    hideOpenButton?: boolean
}

const ResultEntryModeWorksheetServiceCall = ({
                                                 worksheet,
                                                 serviceCall,
                                                 hideOpenButton
                                             }: ResultEntryModeWorksheetServiceCallProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.Mechanic`)

    const statuses = useWorksheetStatuses(worksheet)
    const foundServiceCallStatus = statuses.serviceCallStatuses.find(scs => scs.id === serviceCall.id)

    const opHours = serviceCall.currentEquipmentOperatingHours ?? "-"

    const openWorksheetEditor = useOpenWorksheetEditor()
    const isMounted = useMountedState()

    const onOpenWorksheetClick = () => {
        if (!isMounted() || !worksheet?.id) return
        openWorksheetEditor(worksheet.id, serviceCall.id)
    }

    return (
        <Card variant="outlined">
            <CardContent>

                <Grid container spacing={1} sx={{
                    display: "flex",
                    alignItems: "center"
                }}>

                    <Grid item xs="auto">
                        <EquipmentThumbnailForServiceCall size={128} serviceCall={serviceCall}/>
                    </Grid>
                    <Grid item xs={true} sx={{height: 1}}>
                        <VehicleTypeDisplay
                            manufacturer={serviceCall.manufacturer}
                            vehicleType={serviceCall.vehicleType}
                            fontWeight="bold"
                        />
                        <Typography>{serviceCall.manufacturerSn}</Typography>
                        {serviceCall.internalSn && (
                            <Typography>{serviceCall.internalSn}</Typography>
                        )}

                        <ResultEntryServiceCallTextLines
                            subject={serviceCall?.emptySubject}
                            faultDescription={serviceCall?.faultDescription}
                            taskDescription={serviceCall?.taskDescription}
                            callTypeId={serviceCall?.callTypeId}
                        />

                        <ResultEntryServiceCallWorkHours
                            itemRows={serviceCall?.itemRows}
                        />

                    </Grid>
                    <Grid item xs="auto">
                        <MenuList dense>
                            <MenuItem>
                                <ListItemIcon>
                                    <ImageIcon image={WorksheetIconImages.alarm} size={16}/>
                                </ListItemIcon>
                                <ListItemText>{opHours}</ListItemText>
                                <Typography variant="body2" color="text.secondary" alignContent="center">
                                    <FinishedIcon
                                        value={statuses.basicCompleted}
                                        sx={{
                                            fontSize: "10pt"
                                        }}
                                    />
                                </Typography>
                            </MenuItem>
                            <MenuItem>
                                <ListItemIcon>
                                    <ImageIcon image={WorksheetIconImages.checklist} size={16}/>
                                </ListItemIcon>
                                <ListItemText>{tk('MenuCatalogue')}</ListItemText>
                                <Typography variant="body2" color="text.secondary">
                                    <FinishedIcon
                                        value={foundServiceCallStatus?.catalogueCompleted ?? false}
                                        sx={{
                                            fontSize: "10pt"
                                        }}
                                    />
                                </Typography>
                            </MenuItem>
                            <MenuItem>
                                <ListItemIcon>
                                    <ImageIcon image={WorksheetIconImages.text} size={16}/>
                                </ListItemIcon>
                                <ListItemText>{tk('Description')}</ListItemText>
                                <Typography variant="body2" color="text.secondary">
                                    <FinishedIcon
                                        value={foundServiceCallStatus?.descriptionCompleted ?? false}
                                        sx={{
                                            fontSize: "10pt"
                                        }}
                                    />
                                </Typography>
                            </MenuItem>
                            <MenuItem>
                                <ListItemIcon>
                                    <ImageIcon image={WorksheetIconImages.warning} size={16}/>
                                </ListItemIcon>
                                <ListItemText>{tk('FaultDescription')}</ListItemText>
                                <Typography variant="body2" color="text.secondary">
                                    <FinishedIcon
                                        value={foundServiceCallStatus?.hasFaultDescription ?? false}
                                        onlyDisplayTrueState={true}
                                        sx={{
                                            fontSize: "10pt"
                                        }}
                                    />
                                </Typography>
                            </MenuItem>
                            <MenuItem>
                                <ListItemIcon>
                                    <ImageIcon image={WorksheetIconImages.gallery} size={16}/>
                                </ListItemIcon>
                                <ListItemText>{tk('MenuPhoto')}</ListItemText>
                                <Typography variant="body2" color="text.secondary">
                                    <FinishedIcon
                                        value={foundServiceCallStatus?.hasPhoto ?? false}
                                        onlyDisplayTrueState={true}
                                        sx={{
                                            fontSize: "10pt"
                                        }}
                                    />
                                </Typography>
                            </MenuItem>
                        </MenuList>
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions sx={{
                display: "flex",
                justifyContent: "right"
            }}>
                {!hideOpenButton && (
                    <Button variant="contained"
                            color="primary"
                            onClick={onOpenWorksheetClick}
                    >
                        {tk('OpenWorksheet')}
                    </Button>
                )}
            </CardActions>
        </Card>
    )
}

export default ResultEntryModeWorksheetServiceCall;