import React, {useState} from 'react';
import {useFindAllLastUserAction} from "crowbar-api/hooks/last-user-action/useFindAllLastUserAction";
import {useFindAllUsers} from "crowbar-api/hooks/user/useFindAllUsers";
import {Button, CircularProgress, Container, Grid, TextField, Typography} from "@mui/material";
import LastUserActivityCard from "modules/admin/last-user-activity/LastUserActivityCard";
import {byPropertiesOf} from "shared/utils/ArraySort";
import {CCMLLastUserActionEntry} from "crowbar-api";
import {DateUtils} from "shared/utils/DateUtils";

const ListLastUserActivities = () => {
    const [hideOlderThanMin, setHideOlderThanMin] = useState<number>(60)
    const findAllLastUserAction = useFindAllLastUserAction()
    const findAllUsers = useFindAllUsers()
    return (
        <Container>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h4">
                        Last user activities
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Hide older than n minutes"
                        value={hideOlderThanMin}
                        onChange={(e) => {
                            const newValue = +e.target.value
                            setHideOlderThanMin(isNaN(newValue) ? 60 : newValue)
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button onClick={async () => {
                        await findAllLastUserAction.refetch()
                        await findAllUsers.refetch()
                    }}>
                        Re-fetch all
                    </Button>
                </Grid>

                {(findAllLastUserAction.isLoading
                    || findAllLastUserAction.isFetching
                    || findAllUsers.isLoading
                    || findAllUsers.isFetching
                ) && (
                    <Grid item xs={12}>
                        <CircularProgress/>
                    </Grid>
                )}
                {findAllLastUserAction.data
                    ?.sort(byPropertiesOf<CCMLLastUserActionEntry>(['-loggedAt']))
                    .filter(lua => {
                        const parsed = DateUtils.parseServerUTCToDate(lua.loggedAt)
                        if (!parsed) return true
                        return +parsed > (+new Date()) - hideOlderThanMin * 60000
                    })
                    .map((lua, i) => (
                        <Grid item xs={12} sx={{p: 2}}
                              key={[lua.userId, i].join('-')}
                        >
                            <LastUserActivityCard
                                lua={lua}
                                userDetails={findAllUsers.data?.find(u => u.id === lua.userId)}
                            />
                        </Grid>
                    ))}
            </Grid>
        </Container>
    );
};

export default ListLastUserActivities;