import React from 'react';
import {UseQueryResult} from "@tanstack/react-query";
import {Grid, Typography} from '@mui/material';

import ErrorIcon from '@mui/icons-material/Error';
import {WorksheetSaveWrapper} from "modules/worksheet/storage/WorksheetSaveStorage";
import useTranslateFunction from "shared/language/useTranslateFunction";

export interface WEditorFailedToLoadWorksheetErrorPageProps {
    worksheetQuery?: UseQueryResult<WorksheetSaveWrapper | null, unknown>
}

const WEditorFailedToLoadWorksheetErrorPage = ({
                                                   worksheetQuery
                                               }: WEditorFailedToLoadWorksheetErrorPageProps) => {

    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    return (
        <Grid container>
            <Grid item xs={12} display="flex" alignItems="center">
                <Typography component="h1" sx={{
                    fontSize: "56pt",
                    color: "red"
                }}>
                    <ErrorIcon fontSize="inherit"/>&nbsp;{tk('Error')}
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography>{tk('FailedToLoadWorksheet')}</Typography>
            </Grid>
            <Grid item xs="auto">
                <pre>{JSON.stringify(worksheetQuery, null, 2)}</pre>
            </Grid>
        </Grid>
    );
};

export default WEditorFailedToLoadWorksheetErrorPage;