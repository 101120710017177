import React from 'react';
import {Typography, TypographyProps} from "@mui/material";
import {StringUtils} from "shared/utils/StringUtils";

export type Typography100EllipsisProps = Omit<TypographyProps, "children"> & {
    maxChar: number
    children: string
}

const TypographyMaxNCharEllipsis = ({maxChar, children, ...props}: Typography100EllipsisProps) => {
    const ellipsis = "..."
    const ellipsisLength = ellipsis.length
    const text = children.length < maxChar - ellipsisLength
        ? children
        : StringUtils.trimEndWhile(children.substring(0, maxChar - ellipsisLength).trim(), c => c === ".").trim() + ellipsis
    return (
        <Typography
            {...props}
            sx={{
                overflow: "hidden",
                ...props.sx
            }}
        >
            {text}
        </Typography>
    )
}

export default TypographyMaxNCharEllipsis;