import React from 'react';
import {Card, CardContent, CircularProgress, Grid, Stack, Typography} from '@mui/material';
import NativeDateInput from "shared/components/inputs/NativeDateInput";
import {DateUtils} from "shared/utils/DateUtils";
import {
    useWorksheetEvalUpdateStartedAt
} from "crowbar-api/hooks/worksheet/evaluation/worksheet/useWorksheetEvalUpdateStartedAt";
import {
    useWorksheetEvalUpdateEndedAt
} from "crowbar-api/hooks/worksheet/evaluation/worksheet/useWorksheetEvalUpdateEndedAt";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {CDAMWMWorksheetEvalModel, CDAMWMWWorksheetQuery} from "crowbar-api";
import FixesWorksheetDataIcon from "modules/admin/worksheet/evaluation/shared/FixesWorksheetDataIcon";

export type WEvalDatesProps = {
    wEval: CDAMWMWorksheetEvalModel | null | undefined
    worksheet: CDAMWMWWorksheetQuery | null | undefined
    onDataChange: () => Promise<void>
}
const WEvalDates = ({wEval, worksheet, onDataChange}: WEvalDatesProps) => {
    const tk = useTranslateFunction('Admin.Worksheet')

    const evalId = wEval?.id

    const wid = worksheet?.id
    const startedAt = worksheet?.startedAt
    const endedAt = worksheet?.endedAt

    const startFormatted = DateUtils.parseServerUTCToNativeDateInput(startedAt)
    const endFormatted = DateUtils.parseServerUTCToNativeDateInput(endedAt)

    // const sameDates = startedAt === endedAt

    const updateStartedAt = useWorksheetEvalUpdateStartedAt();
    const updateEndedAt = useWorksheetEvalUpdateEndedAt();

    const onChange = async (field: "start" | "end", date: string) => {
        if (!wid || !evalId) return
        if (field === "start") {
            await updateStartedAt.mutateAsync({
                field: date,
                originalId: wid,
                evalId
            })
        } else {
            await updateEndedAt.mutateAsync({
                field: date,
                originalId: wid,
                evalId
            })
        }
        await onDataChange()
    }

    return (
        <Card variant="outlined">
            <CardContent>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="h6">{tk('WorksheetDate')}&nbsp;<FixesWorksheetDataIcon/></Typography>
                    </Grid>

                    <Grid item xs={12}>

                        <Grid container direction="row" spacing={1} alignItems="center">
                            <Grid item xs={true}>
                                <Stack direction="column" spacing={1} width={1}>
                                    <Typography>{tk('StartDate')}</Typography>
                                    <NativeDateInput
                                        size="small"
                                        fullWidth
                                        sx={{
                                            width: 1
                                        }}
                                        value={startFormatted ?? ""}
                                        onChangeWithServerValue={(date) => onChange("start", date)}
                                    />
                                </Stack>
                            </Grid>
                            <Grid item xs={1}>
                                {updateStartedAt.isLoading && (
                                    <CircularProgress/>
                                )}
                            </Grid>

                            <Grid item xs={true}>
                                <Stack direction="column" spacing={1} width={1}>
                                    <Typography>{tk('EndDate')}</Typography>
                                    <NativeDateInput
                                        size="small"
                                        fullWidth
                                        sx={{
                                            width: 1
                                        }}
                                        value={endFormatted ?? ""}
                                        onChangeWithServerValue={(date) => onChange("end", date)}
                                    />
                                </Stack>
                            </Grid>
                            <Grid item xs={1}>
                                {updateEndedAt.isLoading && (
                                    <CircularProgress/>
                                )}
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default WEvalDates;