import {CDCMImageBlobUris} from "crowbar-api";
import {StringUtils} from "shared/utils/StringUtils";

/**
 * use the hook to get an instance of this
 * {@link useAzureBlobStorageUrlFactory}
 * (*needs storage account, which depends on the environment)
 * (**could read other configurations as well)
 */
export default class AzureBlobStorageUrlFactory {
    private readonly _storageAccount: string;

    private readonly _thumbSmallSize = 64
    private readonly _thumbMediumSize = 256
    private readonly _thumbLargeSize = 512

    constructor(storageAccount: string) {
        this._storageAccount = storageAccount;
    }

    private azureUrl = () => `https://${this._storageAccount}.blob.core.windows.net`

    public sapImageFromName = (fileName: string | null | undefined): CDCMImageBlobUris => {
        return {
            uri: this.sapPathFor(fileName),
            thumbSmall: this.sapPathFor(fileName, this._thumbSmallSize),
            thumbMedium: this.sapPathFor(fileName, this._thumbMediumSize),
            thumbLarge: this.sapPathFor(fileName, this._thumbLargeSize),
        }
    }

    public imageFromName = (type: 'tvh' | 'user' | 'equipment', parentFolder: string | null | undefined, fileName: string | null | undefined): CDCMImageBlobUris => {
        return {
            uri: this.imagePathFor(type, parentFolder, fileName),
            thumbSmall: this.imagePathFor(type, parentFolder, fileName, this._thumbSmallSize),
            thumbMedium: this.imagePathFor(type, parentFolder, fileName, this._thumbMediumSize),
            thumbLarge: this.imagePathFor(type, parentFolder, fileName, this._thumbLargeSize),
        }
    }

    private thumbnailNameFrom = (fileName: string, thumbSize: number, keepOriginalExtension: boolean = false): string | undefined => {
        const dotIndex = fileName.lastIndexOf(".")
        if (dotIndex === -1) return undefined
        const fileNameLeft = fileName.substring(0, dotIndex)
        const originalExtension = "." + fileName.substring(dotIndex + 1)
        return `${fileNameLeft}_${thumbSize}px_thumb${keepOriginalExtension ? originalExtension : '.jpg'}`
    }

    private buildBlobUrl = (prefixParts: string[], fileName: string, thumbSize?: number): string | undefined => {
        const parts = [...prefixParts]
        if (!thumbSize) {
            parts.push(fileName)
        } else {
            const thumbName = this.thumbnailNameFrom(fileName, thumbSize)
            if (thumbName) {
                parts.push("thumbs")
                parts.push(thumbName)
            } else {
                return undefined
            }
        }

        return parts.join("/")
    }

    private sapPathFor = (fileName: string | null | undefined, thumbSize?: number): string | undefined => {
        if (!fileName || fileName.trim() === "") return undefined

        const parts = [
            this.azureUrl(),
            "images",
            "sap"
        ]

        return this.buildBlobUrl(parts, fileName, thumbSize)
    }

    private imagePathFor = (type: 'tvh' | 'user' | 'equipment',
                            parentDirectory: string | null | undefined,
                            fileName: string | null | undefined,
                            thumbSize?: number): string | undefined => {
        if (!fileName || !parentDirectory ||
            StringUtils.undefinedNullOrEmpty(parentDirectory) ||
            StringUtils.undefinedNullOrEmpty(fileName)) return undefined

        const parts = [
            this.azureUrl(),
            "images",
            type,
            parentDirectory
        ]

        return this.buildBlobUrl(parts, fileName, thumbSize)
    }
}