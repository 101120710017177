import {AxiosError, AxiosInstance, AxiosRequestConfig} from "axios";
import {authentication as TeamsAuth} from "@microsoft/teams-js";
import appContext from "../../AppContext";
import msalAuth from "../msal/MsalAuth";

const onRequest = (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    // console.info(`[HTTP Request] [${JSON.stringify(config)}]`);
    return new Promise<AxiosRequestConfig>((resolve, reject) => {

        config.baseURL = window.location.origin;

        /*const isPublicRoute = publicRoutes.filter(
            r => config.url?.endsWith(r)
        ).length

        if (isPublicRoute) {
            resolve(config)
        }*/

        if (!appContext.isMicrosoftTeams()) {
            msalAuth.getTokenSilent().then(token => {
                if (token) {
                    config.headers = config.headers ?? {};
                    config.headers.Authorization = token;
                    config.headers['Authorization'] = 'Bearer ' + token;
                    resolve(config);
                } else {
                    console.error('Failed to get token from msal.');
                    reject();
                }
            });
        } else {
            TeamsAuth.getAuthToken().then(token => {
                if (config && config?.headers) {
                    config.headers.Authorization = token;
                    config.headers['Authorization'] = 'Bearer ' + token;
                }

                resolve(config);
            }, reason => {
                console.error('Failed to get auth token from teams. Reason: ' + reason);
                reject(reason);
            })
        }
    });
}

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
    console.error(`[HTTP Request Error] [${JSON.stringify(error)}]`);
    return Promise.reject(error);
}

export function setupBackendInterceptorsTo(axiosInstance: AxiosInstance): AxiosInstance {
    axiosInstance.interceptors.request.use(onRequest, onRequestError);
    // axiosInstance.interceptors.response.use(onResponse, onResponseError);
    return axiosInstance;
}