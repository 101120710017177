import React from 'react';
import {Route, Routes} from "react-router-dom";
import WorksheetImagesById from "modules/admin/worksheet/worksheet-images/images-by-id/WorksheetImagesById";

const WorksheetImages = () => {

    return (
        <Routes>
            <Route path=":worksheetId" element={<WorksheetImagesById/>}/>
        </Routes>
    );
};

export default WorksheetImages;