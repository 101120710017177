import React from 'react';
import {Card, Grid} from "@mui/material";
import SearchBoxAndDebounce from "shared/components/inputs/SearchBoxAndDebounce";
import {
    MechanicListingStateProps,
    useMechanicListingState
} from "modules/worksheet/mechanic/state/useMechanicListingState";
import {WorksheetStatusFilterButtonGroup} from "modules/worksheet/mechanic/filters/WorksheetStatusFilterButtonGroup";
import MechanicWorksheetDisplayModeToggle from "modules/worksheet/mechanic/filters/MechanicWorksheetDisplayModeToggle";

export type MechanicWorksheetFiltersProps = MechanicListingStateProps & {}
const MechanicWorksheetFilters = ({mechanicStateAtom}: MechanicWorksheetFiltersProps) => {
    const {state, setState} = useMechanicListingState(mechanicStateAtom)

    const onSearchValueChange = (newValue: string) => {
        setState(prev => {
            return {
                ...prev,
                searchValue: newValue
            }
        })
    }

    return (
        <Card
            variant="outlined"
            sx={{
                p: 2
            }}
        >
            <Grid container spacing={1}>

                <Grid item xs={12}>
                    <SearchBoxAndDebounce
                        value={state.searchValue}
                        onChange={onSearchValueChange}
                        size="small"
                    />
                </Grid>

                <Grid item xs={12}>

                    <Grid container spacing={1} justifyContent="space-between">
                        <Grid item xs="auto" sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <WorksheetStatusFilterButtonGroup mechanicStateAtom={mechanicStateAtom}/>
                        </Grid>

                        <Grid item xs="auto" sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <MechanicWorksheetDisplayModeToggle mechanicStateAtom={mechanicStateAtom}/>
                        </Grid>
                    </Grid>

                </Grid>

            </Grid>
        </Card>
    );
};

export default MechanicWorksheetFilters;