import React from 'react';
import {useAzureBlobStorageUrlFactory} from "shared/hook/useAzureBlobStorageUrlFactory";
import {StringUtils} from "shared/utils/StringUtils";
import EquipmentThumbnail from "modules/worksheet/editor/shared/component/equipment-thumbnail/EquipmentThumbnail";

export type EquipmentImageProps = {
    coverImage?: string
    parentFolder?: string
    filename?: string
}

const EquipmentCoverOrBlobImage = ({
                                       coverImage,
                                       parentFolder,
                                       filename
                                   }: EquipmentImageProps) => {

    const blobUrlFac = useAzureBlobStorageUrlFactory()
    const coverImageUrls = StringUtils.notUndefinedNullAndEmpty(coverImage)
        ? blobUrlFac.sapImageFromName(coverImage)
        : undefined
    const blobUrls = StringUtils.notUndefinedNullAndEmpty(parentFolder) && StringUtils.notUndefinedNullAndEmpty(filename)
        ? blobUrlFac.imageFromName("equipment", parentFolder, filename)
        : undefined

    return (
        <EquipmentThumbnail
            thumbSrc={blobUrls?.thumbMedium ?? coverImageUrls?.thumbMedium}
        />
    )
}

export default EquipmentCoverOrBlobImage;