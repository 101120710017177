import React from 'react';
import {withTranslation, WithTranslation} from "react-i18next";
import {CDSMPartnerEquipmentPageRow, CDSMSapItem, SapItemApi, SapPartnerEquipmentApi} from "crowbar-api";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack
} from "@mui/material";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import axios, {AxiosRequestConfig, CancelTokenSource} from "axios";
import {toastError, toastRequestError} from "shared/toast/DefaultToasts";
import {SapSelectOption} from "crowbar-api/enum-map/SapMappingUtils";
import {ArrowCircleLeft, ArrowCircleRight} from "@mui/icons-material";
import DeliveryNoteNewRowEquipmentTable from "./DeliveryNoteNewRowEquipmentTable";
import DeliveryNoteNewRowItemTable from "./DeliveryNoteNewRowItemTable";
import SearchBoxAndDebounce from "shared/components/inputs/SearchBoxAndDebounce";

export type DnSourceType = 'equipment' | 'item' | 'empty'

export interface NewDeliveryNoteParams {
    source: DnSourceType

    selectedEquipment?: CDSMPartnerEquipmentPageRow
    selectedItem?: CDSMSapItem
}

interface DeliveryNoteNewRowDialogProps extends WithTranslation {
    onCancel: () => void
    onSelected: (selected: NewDeliveryNoteParams) => void

    customerCardCode?: string
}

interface DeliveryNoteNewRowDialogState {
    open: boolean

    customerCardCode?: string
    onlyPhoenixEquipments: boolean
    onlySelectedCustomerEquipments: boolean

    sourceOptions: SapSelectOption[]
    selectedSource: DnSourceType

    equipmentSearch: string
    equipmentRows: CDSMPartnerEquipmentPageRow[]
    equipmentsAreLoading: boolean
    equipmentLastRowNum: number
    selectedEquipment?: CDSMPartnerEquipmentPageRow

    itemSearch: string
    itemRows: CDSMSapItem[]
    itemsAreLoading: boolean
    itemsLastRowNum: number
    selectedItem?: CDSMSapItem
}

const TKey = "DeliveryNote.Note.Dialog.";

class DeliveryNoteNewRowDialog extends React.Component<DeliveryNoteNewRowDialogProps, DeliveryNoteNewRowDialogState> {
    private _sapPartnerEquipmentApi: SapPartnerEquipmentApi;
    private _sapItemApi: SapItemApi;

    private limit = 10;

    private cancelTokenSource: CancelTokenSource;
    private axiosOptions: AxiosRequestConfig

    constructor(props: Readonly<DeliveryNoteNewRowDialogProps> | DeliveryNoteNewRowDialogProps) {
        super(props);
        const t = this.props.t;
        this.cancelTokenSource = axios.CancelToken.source();
        this.axiosOptions = {
            cancelToken: this.cancelTokenSource.token
        }

        this.state = {
            open: false,
            customerCardCode: this.props.customerCardCode,
            onlyPhoenixEquipments: false,
            onlySelectedCustomerEquipments: false,
            sourceOptions: [
                {key: 'equipment', text: t(`${TKey}EquipmentMasterData`)},
                {key: 'item', text: t(`${TKey}ItemMasterData`)},
                {key: 'empty', text: t(`${TKey}EmptyRow`)},
            ],
            selectedSource: 'equipment',

            equipmentSearch: '',
            equipmentRows: [],
            equipmentsAreLoading: true,
            equipmentLastRowNum: 0,
            selectedEquipment: undefined,

            itemSearch: '',
            itemRows: [],
            itemsAreLoading: true,
            itemsLastRowNum: 0,
            selectedItem: undefined,
        }

        this._sapPartnerEquipmentApi = crowbarApiFactory(SapPartnerEquipmentApi);
        this._sapItemApi = crowbarApiFactory(SapItemApi);
    }

    componentDidMount() {
        this.cancelTokenSource = axios.CancelToken.source();
        this.axiosOptions = {
            cancelToken: this.cancelTokenSource.token
        }
        this._loadEquipmentPage();
        this._loadItemPage();
    }

    componentDidUpdate(prevProps: Readonly<DeliveryNoteNewRowDialogProps>, prevState: Readonly<DeliveryNoteNewRowDialogState>, snapshot?: any) {
        if (this.state.customerCardCode !== this.props.customerCardCode) {
            this.setState({
                customerCardCode: this.props.customerCardCode,
                onlyPhoenixEquipments: false,
                onlySelectedCustomerEquipments: false
            }, () => {
                this._loadEquipmentPage()
            })
        }
    }

    componentWillUnmount() {
        this.cancelTokenSource.cancel();
    }

    _newButtonClick = () => {
        this.setState({
            open: true
        })
    }

    _onSourceChange = (event: SelectChangeEvent<string>) => {
        //  'equipment' | 'item' | 'empty'
        const newValue = event.target.value

        this.setState({
            selectedSource: newValue !== undefined && ['equipment', 'item', 'empty'].includes(newValue as string)
                ? newValue as DnSourceType : 'empty'
        })
    }

    _onEquipmentSelection = (item: CDSMPartnerEquipmentPageRow) => {
        this.setState({
            selectedEquipment: item ?? undefined
        })
    }

    _onItemSelection = (item: CDSMSapItem) => {
        this.setState({
            selectedItem: item ?? undefined
        })
    }

    _onEquipmentSearchChange = (value: string) => {
        this.setState({
            equipmentSearch: value ?? '',
            equipmentsAreLoading: true,
            equipmentLastRowNum: 0,
            equipmentRows: [],
            selectedEquipment: undefined,
        }, () => {
            this._loadEquipmentPage()
        })
    }

    _onItemSearchChange = (value: string) => {
        this.setState({
            itemSearch: value ?? '',
            itemsAreLoading: true,
            itemsLastRowNum: 0,
            itemRows: [],
            selectedItem: undefined,
        }, () => {
            this._loadItemPage()
        })
    }

    _loadEquipmentPage = () => {

        let cardCodeFilter: string | undefined
        if (this.state.onlySelectedCustomerEquipments) {
            cardCodeFilter = this.state.customerCardCode
        }

        if (this.state.onlyPhoenixEquipments) {
            cardCodeFilter = "V100729"
        }

        this._sapPartnerEquipmentApi.searchPageBy({
            orderByIsDesc: false,
            search: this.state.equipmentSearch ?? '',
            limit: this.limit,
            lastRowNumber: this.state.equipmentLastRowNum,
            cardCode: cardCodeFilter ?? ''
        }, this.axiosOptions).then(response => {
            if (response.status === 200) {
                this.setState({
                    equipmentsAreLoading: false,
                    equipmentRows: response.data
                })
            } else {
                toastRequestError(this.props.t, {
                    name: 'DnNewRowEqPage',
                    response: response,
                    reason: 'Http status not OK'
                })
                this.setState({
                    equipmentsAreLoading: false
                })
            }
        });
    }

    _loadItemPage = () => {
        this._sapItemApi.searchPageBy({
            orderByIsDesc: false,
            search: this.state.itemSearch ?? '',
            limit: this.limit,
            lastRowNumber: this.state.itemsLastRowNum
        }, this.axiosOptions).then(response => {
            if (response.status === 200) {
                this.setState({
                    itemsAreLoading: false,
                    itemRows: response.data
                })
            } else {
                toastRequestError(this.props.t, {
                    name: 'DnNewRowItemPage',
                    response: response,
                    reason: 'Http status not OK'
                })
                this.setState({
                    itemsAreLoading: false
                })
            }
        })
    }

    _onCancelClick = () => {
        this.setState({
            open: false
        }, () => {
            this.props.onCancel()
        })
    }

    _onAddClick = () => {
        const t = this.props.t;
        let result: NewDeliveryNoteParams;
        if (this.state.selectedSource === "empty") {
            result = {
                source: "empty"
            }
        } else if (this.state.selectedSource === "equipment") {
            result = {
                source: "equipment",
                selectedEquipment: this.state.selectedEquipment
            }
        } else if (this.state.selectedSource === "item") {
            result = {
                source: "item",
                selectedItem: this.state.selectedItem
            }
        } else {
            toastError(t(`${TKey}InvalidSourceError`))
            return
        }

        // Validate
        if (this.state.selectedSource === "equipment" && !result.selectedEquipment) {
            toastError(t(`${TKey}EquipmentRequiredError`))
            return;
        }

        if (this.state.selectedSource === "item" && !result.selectedItem) {
            toastError(t(`${TKey}ItemRequiredError`))
            return;
        }

        // Close dialog
        this.setState({
            open: false
        }, () => {
            // Call parent
            this.props.onSelected(result)
        })
    }

    render() {
        const t = this.props.t;
        return (
            <>
                <Button
                    variant="contained"
                    sx={{marginY: 1}}
                    onClick={() => this._newButtonClick()}
                >
                    {t(`${TKey}NewRow`)}
                </Button>
                <Dialog open={this.state.open}
                        hideBackdrop={false}
                        fullWidth={true}
                        maxWidth="lg"
                >
                    <DialogTitle>{t(`${TKey}NewRowDialogTitle`)}</DialogTitle>
                    <DialogContent>
                        <FormControl fullWidth sx={{my: 2}}>
                            <InputLabel id="select-source-id" size="small">
                                {t(`${TKey}Source`)}
                            </InputLabel>
                            <Select
                                size="small"
                                labelId="select-source-id"
                                defaultValue="equipment"
                                label={t(`${TKey}Source`)}
                                value={this.state.selectedSource}
                                onChange={(event) => {
                                    this._onSourceChange(event)
                                    this.setState({
                                        selectedEquipment: undefined,
                                        selectedItem: undefined,
                                    })
                                }}
                            >
                                {this.state.sourceOptions.map((option) => {
                                    return (
                                        <MenuItem key={option.key}
                                                  value={option.key}>{option.text}</MenuItem>)
                                })}
                            </Select>
                        </FormControl>
                        {this.state.selectedSource === "empty" &&
                            <p>{t(`${TKey}EmptyRowP`)}</p>
                        }

                        {this.state.selectedSource === "equipment" &&
                            <Stack spacing={2}>
                                <SearchBoxAndDebounce
                                    value={this.state.equipmentSearch ?? ""}
                                    size={"small"}
                                    onChange={(newValue) => {
                                        this._onEquipmentSearchChange(newValue)
                                    }}
                                />

                                <Stack>
                                    <FormControlLabel label={t(`${TKey}ShowOnlyPhoenixEqs`)}
                                                      control={
                                                          <Checkbox
                                                              checked={this.state.onlyPhoenixEquipments}
                                                              onChange={(event) => {
                                                                  this.setState({
                                                                      onlyPhoenixEquipments: event.target.checked ?? false,
                                                                      selectedEquipment: undefined,
                                                                  }, () => {
                                                                      this._loadEquipmentPage()
                                                                  })
                                                              }}/>
                                                      }/>
                                    <FormControlLabel label={t(`${TKey}ShowOnlySelectedCustomerEqs`)}
                                                      control={
                                                          <Checkbox
                                                              checked={this.state.onlySelectedCustomerEquipments}
                                                              onChange={(event) => {
                                                                  this.setState({
                                                                      onlySelectedCustomerEquipments: event.target.checked ?? false,
                                                                      selectedEquipment: undefined,
                                                                  }, () => {
                                                                      this._loadEquipmentPage()
                                                                  })
                                                              }}/>
                                                      }/>
                                </Stack>

                                <DeliveryNoteNewRowEquipmentTable items={this.state.equipmentRows}
                                                                  onSelectClick={this._onEquipmentSelection}
                                                                  selected={this.state.selectedEquipment}
                                                                  isLoading={this.state.equipmentsAreLoading}
                                />

                                <Stack direction={"row"}>
                                    <Button
                                        disabled={this.state.equipmentLastRowNum < 1 || this.state.equipmentsAreLoading}
                                        onClick={() => {
                                            this.setState((prevState) => {
                                                return {
                                                    selectedEquipment: undefined,
                                                    equipmentsAreLoading: true,
                                                    equipmentLastRowNum: Math.max(prevState.equipmentLastRowNum - this.limit, 0)
                                                }
                                            }, () => {
                                                this._loadEquipmentPage()
                                            })
                                        }}
                                    >
                                        <ArrowCircleLeft/>
                                    </Button>

                                    <Button
                                        disabled={this.state.equipmentRows.length < this.limit || this.state.equipmentsAreLoading}
                                        onClick={() => {
                                            this.setState((prevState) => {
                                                return {
                                                    selectedEquipment: undefined,
                                                    equipmentsAreLoading: true,
                                                    equipmentLastRowNum: prevState.equipmentLastRowNum + this.limit
                                                }
                                            }, () => {
                                                this._loadEquipmentPage()
                                            })
                                        }}
                                    >
                                        <ArrowCircleRight/>
                                    </Button>
                                </Stack>
                            </Stack>
                        }

                        {this.state.selectedSource === "item" &&
                            <Stack spacing={2}>
                                <SearchBoxAndDebounce
                                    value={this.state.itemSearch ?? ""}
                                    size={"small"}
                                    onChange={(newValue) => {
                                        this._onItemSearchChange(newValue)
                                    }}
                                />

                                <DeliveryNoteNewRowItemTable items={this.state.itemRows}
                                                             onSelectClick={this._onItemSelection}
                                                             selected={this.state.selectedItem}
                                                             isLoading={this.state.itemsAreLoading}
                                />

                                <Stack direction={"row"}>
                                    <Button disabled={this.state.itemsLastRowNum < 1 || this.state.itemsAreLoading}
                                            onClick={() => {
                                                this.setState((prevState) => {
                                                    return {
                                                        selectedItem: undefined,
                                                        itemsAreLoading: true,
                                                        itemsLastRowNum: Math.max(prevState.itemsLastRowNum - this.limit, 0)
                                                    }
                                                }, () => {
                                                    this._loadItemPage()
                                                })
                                            }}
                                    >
                                        <ArrowCircleLeft/>
                                    </Button>

                                    <Button
                                        disabled={this.state.itemRows.length < this.limit || this.state.itemsAreLoading}
                                        onClick={() => {
                                            this.setState((prevState) => {
                                                return {
                                                    selectedItem: undefined,
                                                    itemsAreLoading: true,
                                                    itemsLastRowNum: prevState.itemsLastRowNum + this.limit
                                                }
                                            }, () => {
                                                this._loadItemPage()
                                            })
                                        }}
                                    >
                                        <ArrowCircleRight/>
                                    </Button>
                                </Stack>
                            </Stack>
                        }
                    </DialogContent>
                    <DialogActions sx={{display: "flex", justifyContent: "space-between"}}>
                        <Button
                            variant="outlined"
                            onClick={this._onCancelClick}
                        >
                            {t(`${TKey}CancelBtn`)}
                        </Button>
                        <Button
                            variant="contained"
                            onClick={this._onAddClick}
                        >
                            {t(`${TKey}AddBtn`)}
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

export default withTranslation()(DeliveryNoteNewRowDialog);