import {Box, SvgIconProps, SxProps, Theme} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React from "react";

const BackButtonContainerStyle: SxProps<Theme> = (theme) => ({
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.text.primary}`,
    borderRadius: "50%",
    color: theme.palette.text.primary,
    display: 'inline-flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    cursor: 'pointer'
})

export type BackButtonProps = {
    to?: string
    useOnClick?: boolean
    onClick?: () => void
    iconProps?: SvgIconProps
}

export const BackButton = ({iconProps, ...props}: BackButtonProps) => {
    const {t} = useTranslation()
    const navigate = useNavigate()

    const onClick = props.onClick ? props.onClick : () => {
        navigate(-1)
    }

    if (props.useOnClick) {
        return (
            <Box sx={BackButtonContainerStyle}
                 onClick={(e) => {
                     e.preventDefault()
                     onClick()
                 }} title={t('Generic.Back')}
            >
                <ArrowBackIcon {...iconProps} />
            </Box>
        )
    }

    return (
        <a onClick={() => {
            navigate(props.to ?? "/")
        }} title={t('Generic.Back')}>
            <Box sx={BackButtonContainerStyle}>
                <ArrowBackIcon  {...iconProps} />
            </Box>
        </a>
    )
}