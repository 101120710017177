import React from 'react';
import {MicrophoneRecorder} from "shared/components/audio/MicrophoneRecorder";
import {
    CCMCMCrowbarBlobAndUri,
    CDAMSMTranscriptionResultModel,
    CSTranscribeLanguage,
    SpeechToTextApi
} from "crowbar-api";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {toastError} from "shared/toast/DefaultToasts";
import {CircularProgress, Stack, Typography} from "@mui/material";
import TextArea from "shared/components/inputs/TextArea";
import {TextFieldExProps} from "shared/components/inputs/TextFieldEx";
import {useNewAtomWithRef} from "shared/hook/useNewatomWithRef";
import useTranslateFunction from "shared/language/useTranslateFunction";

export type SpeechToTextProps = TextFieldExProps & {
    // worksheetId: string
    disabled?: boolean
    onTranscribeDone: (transcribedText: string, transcribeResult: CDAMSMTranscriptionResultModel) => void
}

//const textValueAtom = atom<string>("")
//const transcriptionInProgressAtom = atom<boolean>(false)

const SpeechToText = ({onTranscribeDone, disabled, ...props}: SpeechToTextProps) => {

    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    // const [textValue, setTextValue] = useAtom(textValueAtom)
    const [transcriptionInProgress, setTranscriptionInProgress] = useNewAtomWithRef(false)

    const onMicrophoneRecorderDone = async (blob: CCMCMCrowbarBlobAndUri): Promise<any> => {
        if (!blob?.id) return
        const response = await crowbarApiFactory(SpeechToTextApi).transcribeBlob(blob.id, CSTranscribeLanguage.NUMBER_1)
        if (response.status === 200) {
            const transcribeResult = response.data
            onTranscribeDone(transcribeResult.transcript ?? "", response.data)
            /*setTextValue((p) => {
                return (p.trim().length > 0 ? p + '\r\n' : p) + transcribeResult.transcript
            })*/
        } else {
            toastError("Error transcribing. " + JSON.stringify(response.data))
        }
    }

    return (
        <Stack direction="column" spacing={0}>

            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >

                <Typography>{props.label}</Typography>

                {!disabled && (
                    <Stack
                        direction="row"
                        spacing={0}
                    >
                        <MicrophoneRecorder
                            hideDurationWhileNotRecording={true}
                            onRecorderDone={async (blob) => {
                                if (!blob?.id) return
                                setTranscriptionInProgress(true)
                                try {
                                    await onMicrophoneRecorderDone(blob)
                                } finally {
                                    setTranscriptionInProgress(false)
                                }
                            }}
                        />
                        {transcriptionInProgress && (
                            <Stack direction={"row"} spacing={0} sx={{
                                display: "flex",
                                alignItems: "center"
                            }}>
                                <CircularProgress/>
                                <Typography>{tk('Transcribing')}</Typography>
                            </Stack>
                        )}
                    </Stack>
                )}

            </Stack>

            <TextArea
                disabled={disabled || transcriptionInProgress}
                {...props}
                label={undefined}
            />
        </Stack>
    )
}

export default SpeechToText;