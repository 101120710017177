import {StringUtils} from "shared/utils/StringUtils";
import {CDAMWMWModSapPartnerContactSave, CDSMSapPartnerContact} from "crowbar-api";

export default class SapPartnerContactUtils {

    public static contactFormatEquals = (o1: CDSMSapPartnerContact, o2: CDSMSapPartnerContact): boolean => {
        if (!o1 || !o2) return false
        if (o1 === o2) return true
        return this.contactFormat(o1) === this.contactFormat(o2)
    }

    public static contactFormat = (
        o: CDSMSapPartnerContact | undefined | null
    ): string => {
        if (!o) return 'N/A'
        const ver1 = StringUtils.notEmptyValuesAsArray(o.lastName, o.middleName, o.firstName).join(' ')
        return StringUtils.notUndefinedNullAndEmpty(ver1) ? ver1 : o.name ?? ""
    }

    public static contactFormatMod = (
        o: CDAMWMWModSapPartnerContactSave | undefined | null
    ): string => {
        if (!o) return 'N/A'
        return StringUtils.notEmptyValuesAsArray(o.lastname, o.middleName, o.firstname).join(' ')
    }
}