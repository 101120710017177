import {EmailNotificationProps} from "modules/admin/worksheet/notifications/email-notifications/EmailNotificationProps";
import {
    useEmailNotificationListingState
} from "modules/admin/worksheet/notifications/email-notifications/state/useEmailNotificationListingState";
import {EmailNotificationSourceMap} from "crowbar-api/enum-map/EmailNotificationSourceMap";
import {Grid, MenuItem, Select, SelectChangeEvent, Stack, Typography} from "@mui/material";
import useTranslateFunction from "shared/language/useTranslateFunction";
import React from "react";

export type EmailNotificationSourceSelectProps = EmailNotificationProps & {}
export const EmailNotificationSourceSelect = ({stateAtom}: EmailNotificationSourceSelectProps) => {
    const tk = useTranslateFunction("Admin.Worksheet")
    const {source, setState} = useEmailNotificationListingState(stateAtom)

    const handleChange = (e: SelectChangeEvent<number>) => {
        setState(prev => {
            return {
                ...prev,
                source: EmailNotificationSourceMap.from(e.target.value),
                lastRowNumber: 0
            }
        })
    }

    return (
        <Stack direction="column" spacing={1} sx={{width: 1}}>
            <Typography variant="caption">{tk('Source')}</Typography>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Select
                        fullWidth
                        value={source}
                        onChange={handleChange}
                    >
                        <MenuItem value={EmailNotificationSourceMap.agent}>{tk('Agent')}</MenuItem>
                        <MenuItem value={EmailNotificationSourceMap.customer}>{tk('Customer')}</MenuItem>
                    </Select>
                </Grid>
            </Grid>
        </Stack>
    )
}