import React from 'react';
import {
    PartnerFormValues,
    useNewPartnerForm,
    UseNewPartnerFormResult
} from "modules/worksheet/new-worksheet/new-partner/useNewPartnerForm";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import NewPartnerForm from "modules/worksheet/new-worksheet/new-partner/NewPartnerForm";

export interface NewPartnerDialogProps {
    open: boolean
    onDismiss: () => void
    onSubmit: (data: PartnerFormValues, form: UseNewPartnerFormResult) => void | Promise<void>
}

const NewPartnerDialog = ({open, onDismiss, onSubmit}: NewPartnerDialogProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.NewWorksheet`)

    const form = useNewPartnerForm()

    const onClose = () => {
        onDismiss()
    }

    const onDialogSubmit = async () => {
        const handleFunction = form.handleSubmit((data) => {
            onClose()
            onSubmit(data, form)
        }, e => console.log(e))

        await handleFunction()
    }

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle>
                {tk('NewCustomer')}
            </DialogTitle>
            <DialogContent sx={{p: 3}}>
                <NewPartnerForm form={form}/>
            </DialogContent>
            <DialogActions sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
            }}>
                <Button
                    color="error"
                    variant="outlined"
                    onClick={() => onClose()}
                >
                    {tk('Cancel')}
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => onDialogSubmit()}
                    disabled={!form.formState.isDirty && !form.formState.isValid}
                >
                    {tk('Add')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default NewPartnerDialog;