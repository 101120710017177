import React from 'react';
import {Box, Typography} from "@mui/material";

import CommentIcon from '@mui/icons-material/Comment';

export interface WorksheetEditorPageCatalogueTextRowProps {
    lineText?: string
}

const WorksheetEditorPageCatalogueTextRow = ({lineText}: WorksheetEditorPageCatalogueTextRowProps) => {
    return (
        <Box
            sx={{
                p: 1,
                display: "flex",
                alignItems: "center"
            }}
        >
            <Typography variant="h6" fontWeight="bold">
                <CommentIcon/>&nbsp;{lineText}
            </Typography>
        </Box>
    );
};

export default WorksheetEditorPageCatalogueTextRow;