import React from 'react';
import SearchBoxAndDebounce from "shared/components/inputs/SearchBoxAndDebounce";
import {EquipmentSearchStateProps} from "modules/equipment/equipment-search/EquipmentSearch";
import {useEquipmentSearchState} from "modules/equipment/equipment-search/useEquipmentSearchState";

export type SearchInputProps = EquipmentSearchStateProps & {}
const SearchInput = ({searchStateAtom}: SearchInputProps) => {

    const {searchInput, setState} = useEquipmentSearchState(searchStateAtom)

    const onChange = (newValue: string) => {
        setState(prev => {
            return {
                ...prev,
                searchInput: newValue
            }
        })
    }

    return (
        <SearchBoxAndDebounce
            size={"small"}
            value={searchInput ?? ""}
            onChange={onChange}
            replaceStar={true}
        />
    )
}

export default SearchInput;