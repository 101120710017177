import {useWEvalQueries} from "modules/admin/worksheet/evaluation/state/useWEvalQueries";
import {CDAMWMWorksheetStatus} from "crowbar-api";

export interface UseWEvalStatusesResult {
    status: CDAMWMWorksheetStatus | undefined
    //worksheetEvalIsPossible: boolean
}

export const useWEvalStatuses = (): UseWEvalStatusesResult => {
    const {wEvalQuery, worksheetQuery} = useWEvalQueries()

    const status = worksheetQuery?.data?.status ?? undefined

    const wEval = wEvalQuery.data;

    //const a = !!wEval?.addressAck && !!wEval?.contactAck && !!wEval?.partnerAck

    return {
        status,
        //worksheetEvalIsPossible: a
    }
}