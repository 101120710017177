import React from 'react';

import {IconButton, IconButtonProps} from "@mui/material";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export type ExpandButtonProps = Omit<IconButtonProps, "onClick"> & {
    expanded: boolean
    onClick: (newExpanded: boolean) => void
}

const ExpandButton = ({expanded, onClick, ...props}: ExpandButtonProps) => {
    return (
        <IconButton
            {...props}
            onClick={() => onClick(!expanded)}
        >
            {expanded ? (
                <ExpandLessIcon/>
            ) : (
                <ExpandMoreIcon/>
            )}
        </IconButton>
    )
};

export default ExpandButton;