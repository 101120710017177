import React, {useState} from 'react';
import {
    useWorksheetUpdateEmailNotificationAddresses
} from "crowbar-api/hooks/worksheet/useWorksheetUpdateEmailNotificationAddresses";
import {CDAMWMWWorksheetQuery} from "crowbar-api";
import EmailAddressesInput from "shared/components/inputs/EmailAddressesInput";
import {toastError} from "shared/toast/DefaultToasts";

export type EmailAddressInputProps = {
    worksheet: CDAMWMWWorksheetQuery | null | undefined
    onDataChange: () => void
}
const EmailAddressInput = ({worksheet, onDataChange}: EmailAddressInputProps) => {

    const [emailAddresses, setEmailAddresses] = useState<string[]>(
        (worksheet?.emailNotificationAddresses ?? "").split(",").map(s => s.trim()).filter(s => !!s)
    )

    const updateEmailNotificationAddresses = useWorksheetUpdateEmailNotificationAddresses()

    const onDelete = async (option: string) => {
        const filteredAddresses = emailAddresses.filter(a => a !== option);
        setEmailAddresses(filteredAddresses)
        await onBlur(filteredAddresses)
    }

    const onBlur = async (newAddresses: string[]) => {
        if (!worksheet?.id) {
            toastError('Missing worksheet id. Failed to save email addresses.')
            return
        }
        await updateEmailNotificationAddresses.mutateAsync({
            worksheetId: worksheet.id,
            value: newAddresses.join(",")
        })
        await onDataChange()
    }

    if (!worksheet?.overrideEmailNotificationAddresses) {
        return <></>
    }

    return (
        <EmailAddressesInput
            defaultValue={[]}
            emailAddresses={emailAddresses}
            onChange={value => setEmailAddresses(value)}
            onDelete={onDelete}
            onBlur={() => onBlur(emailAddresses)}
            error={emailAddresses.length === 0}
        />
    );
};

export default EmailAddressInput;