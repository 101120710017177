/**
 * Reduce function
 * Reduce values to unique list of values.
 * Uses includes to check
 * usage example:
 *  .reduce(toUnique, [] as string[])
 */
export const toUnique = <T>(previousValue: T[], currentValue: T): T[] => {
    if (previousValue.includes(currentValue)) {
        return previousValue
    } else {
        return [...previousValue, currentValue]
    }
}