import {CDAMPMPhotoRuleStepModel, CDAMPMPhotoRuleStepTemplateModel} from "crowbar-api";
import {Box, Grid, Paper, Typography} from "@mui/material";
import {UseQueryResult} from "@tanstack/react-query";
import PhotoRuleEditStepMoveButtons
    from "modules/admin/worksheet/photo-rule/photo-rules/PhotoRuleEdit/PhotoRuleEditStepMoveButtons";
import PhotoRuleEditStepFields
    from "modules/admin/worksheet/photo-rule/photo-rules/PhotoRuleEdit/PhotoRuleEditStepFields";
import useTranslateFunction from "shared/language/useTranslateFunction";

export interface PhotoRuleEditStepProps {
    ruleId: string
    stepsQuery: UseQueryResult<CDAMPMPhotoRuleStepModel[], unknown>
    templatesQuery: UseQueryResult<CDAMPMPhotoRuleStepTemplateModel[], unknown>
    step: CDAMPMPhotoRuleStepModel
    template: CDAMPMPhotoRuleStepTemplateModel | undefined
}

const PhotoRuleEditStep = ({ruleId, stepsQuery, templatesQuery, step, template}: PhotoRuleEditStepProps) => {
    const tk = useTranslateFunction(`Admin.PhotoRule`)

    const lastOrderNum = stepsQuery.data?.find((s, i, a) => (i + 1) === a.length)?.orderNum ?? 1

    const afterStepDelete = () => {
        stepsQuery.refetch().then()
    }

    const afterTemplateLoaded = () => {
        stepsQuery.refetch().then(() => {
            templatesQuery.refetch().then()
        })
    }

    const afterTemplateSaved = () => {
        stepsQuery.refetch().then(() => {
            templatesQuery.refetch().then()
        })
    }

    return (
        <Paper sx={{
            padding: 2
        }}>
            <Grid
                container
                spacing={4}
                direction="row"
                sx={{
                    height: 1
                }}
            >

                <Grid item xs="auto">
                    <Grid container
                          direction="column"
                          spacing={0}
                          alignItems="flex-start"
                          justifyContent="space-between"
                          sx={{
                              height: 1
                          }}
                    >
                        <Grid item xs="auto">
                            <Typography sx={{
                                fontSize: "32pt",
                                fontWeight: "bold"
                            }}>
                                {step.orderNum}.
                            </Typography>
                        </Grid>
                        <Grid item xs="auto">
                            <PhotoRuleEditStepMoveButtons
                                lastOrderNum={lastOrderNum}
                                stepsQuery={stepsQuery}
                                step={step}
                            />
                        </Grid>

                    </Grid>
                </Grid>


                <Grid item xs>
                    <Box sx={{
                        width: 1,
                        height: 1
                    }}>

                        {template && (
                            <Typography>{tk('Template')}: {template?.title}</Typography>
                        )}

                        <PhotoRuleEditStepFields
                            ruleId={ruleId}
                            step={step}
                            template={template}
                            afterDelete={afterStepDelete}
                            afterTemplateLoaded={afterTemplateLoaded}
                            afterTemplateSaved={afterTemplateSaved}
                        />

                    </Box>
                </Grid>

            </Grid>
        </Paper>
    )
}

export default PhotoRuleEditStep