import {atom, SetStateAction, useAtom} from "jotai";
import {
    CDAMPMPhotoRuleAndManufacturersQuery,
    CDAMPMPhotoRuleDetailsQuery,
    CDAMWMESapEquipmentServiceCallBlobQuery,
    CDSMSapPsgManufacturer
} from "crowbar-api";
import {useCallback, useMemo} from "react";
import {UsePhotoBlobsResult} from "modules/worksheet/editor/ui/photo/usePhotoBlobs";
import {UseQueryResult} from "@tanstack/react-query";
import usePhotoRuleDetailsById from "crowbar-api/hooks/photo-rule/usePhotoRuleDetailsById";
import {byPropertiesOf} from "shared/utils/ArraySort";
import usePsgManufacturers from "crowbar-api/hooks/sap/usePsgManufacturers";
import {
    useSapEquipmentImageBlobsByEquipmentIdOrModId
} from "crowbar-api/hooks/worksheet/blobs/useSapEquipmentImageBlobsByEquipmentIdOrModId";

export interface PhotoHelperDialogState {
    open: boolean

    selectedManufacturerCode: string | undefined
    selectedRuleId: string | undefined

    currentOrderNum: number
    currentSubOrderNum: number

    rules: CDAMPMPhotoRuleAndManufacturersQuery[]

    /**
     * Extra states for the steps loaded from the api.
     * Steps added manually when adding multiple
     * images for the same step creates multiple stepStates,
     * but we only reference the same rule's stepId.
     *
     * These are only temporary, needed because we can only save a
     * ServiceCallBlob if we already have a blob uploaded, however the option
     * in needed to be able to start the upload (user click in adding a
     * new photo for the same step, we add the temp state, user uploads a blob,
     * we save it to the api and re-fetch the blobs. Temp state is trash now).
     *
     * The steps loaded from the API do not produce states here at all.
     * A step only gets created here if it contains information.
     * (Like when a blob is added or multiple images create a new step state).
     *
     * These are merged with the query results and presented to other components
     * via the hook.
     */
    stepStates: PhotoHelperDialogStep[]
}

/*export interface PhotoHelperDialogRule {
    rule: CDAMPMPhotoRuleAndManufacturersQuery
    steps: PhotoHelperDialogStep[]
}*/

/**
 * Convert PhotoRuleSteps to DialogSteps.
 * DialogSteps: Multiple steps can have the same RuleStep
 * (when uploading multiple images to a single RuleStep).
 * It also tracks the uploaded blob, so we can display it right away
 * in the dialog.
 * Removing an image is possible, but it will only delete the ServiceCallBlob entry (isDeleted=true).
 */
export interface PhotoHelperDialogStep {
    stepId: string // CDAMPMPhotoRuleStepModel
    orderNum: number
    subOrderNum: number
    blob?: CDAMWMESapEquipmentServiceCallBlobQuery
}

const initialValue = {
    open: false,
    selectedManufacturerCode: undefined,
    selectedRuleId: undefined,
    currentOrderNum: 1,
    currentSubOrderNum: 1,
    rules: [],
    stepStates: []
}

const stateAtom = atom<PhotoHelperDialogState>(initialValue)

export interface UsePhotoHelperDialogStateResult extends PhotoHelperDialogState {

    mergedSteps: PhotoHelperDialogStep[]

    setDialogState: (update: SetStateAction<PhotoHelperDialogState>) => void

    ruleDetailsQuery: UseQueryResult<CDAMPMPhotoRuleDetailsQuery | null, unknown>
    blobsQuery: UseQueryResult<CDAMWMESapEquipmentServiceCallBlobQuery[], unknown>
    psgManufacturersQuery: UseQueryResult<CDSMSapPsgManufacturer[], unknown>

    prepareDialogStateFor: (serviceCallManufacturerCode: string | undefined | null,
                            photoBlobs: UsePhotoBlobsResult,
                            jumpToStepNum?: number,
                            jumpToSubStemNum?: number) => void
}

const usePhotoHelperDialogState = (equipmentId?: number | null | undefined, modEquipmentId?: string | null | undefined): UsePhotoHelperDialogStateResult => {
    const [dialogState, setDialogState] = useAtom(stateAtom)

    const ruleDetailsQuery = usePhotoRuleDetailsById(dialogState.selectedRuleId)
    // const blobsQuery = usePhotoRuleBlobsForAllByRuleId(dialogState.selectedRuleId)
    const blobsQuery = useSapEquipmentImageBlobsByEquipmentIdOrModId(equipmentId, modEquipmentId)
    const psgManufacturersQuery = usePsgManufacturers()

    const mergedSteps = useMemo(() => {
        if (!ruleDetailsQuery?.data?.overridenSteps) return []
        const querySteps: PhotoHelperDialogStep[] = []
        for (const step of ruleDetailsQuery.data.overridenSteps.filter(s => s.id)) {

            const foundBlobs = (blobsQuery.data ?? [])
                //.flatMap(b => b.blobs ?? [])
                .filter(b => b.orderNum === step.orderNum)
                .sort(byPropertiesOf<CDAMWMESapEquipmentServiceCallBlobQuery>(['orderNum', 'subOrderNum', 'createdAt', 'updatedAt']))

            if (foundBlobs.length > 0) {
                for (const foundBlob of foundBlobs) {
                    querySteps.push({
                        stepId: step.id!,
                        orderNum: step.orderNum ?? 1,
                        subOrderNum: foundBlob.subOrderNum ?? 1,
                        blob: foundBlob
                    })
                }
            } else {
                querySteps.push({
                    stepId: step.id!,
                    orderNum: step.orderNum ?? 1,
                    subOrderNum: 1,
                    blob: undefined
                })
            }
        }

        /*  ruleDetailsQuery.data.overridenSteps
          .filter(s => s.id)
          .map((s): PhotoHelperDialogStep => {
              const foundBlob = (blobsQuery.data ?? [])
                  .find(b => b. === s.orderNum)
              return {
                  stepId: s.id!,
                  orderNum: s.orderNum ?? 1,
                  subOrderNum: 1,
                  blob:
              }
          })*/

        querySteps.push(
            // filtering the temporary states after a query re-fetch
            ...dialogState.stepStates
                .filter(ss => querySteps.find(qs => qs.orderNum === ss.orderNum && qs.subOrderNum === ss.subOrderNum) === undefined)
        )
        return querySteps.sort(byPropertiesOf<PhotoHelperDialogStep>(['orderNum', 'subOrderNum']))

    }, [blobsQuery.data, dialogState.stepStates, ruleDetailsQuery?.data?.overridenSteps])

    const prepareDialogStateFor = useCallback(
        (
            serviceCallManufacturerCode: string | undefined | null,
            photoBlobs: UsePhotoBlobsResult,
            jumpToStepNum?: number,
            jumpToSubStemNum?: number
        ) => {
            setDialogState(prev => {
                return {
                    ...prev,
                    selectedManufacturerCode: serviceCallManufacturerCode ?? undefined,
                    selectedRuleId: undefined,
                    currentOrderNum: jumpToStepNum ?? 1,
                    currentSubOrderNum: jumpToSubStemNum ?? 1,
                    rules: (photoBlobs.photoRulesAndManufacturersQuery?.data ?? []),
                    stepStates: []
                }
            })

        }, [setDialogState])

    return {
        ...dialogState,
        mergedSteps,
        setDialogState,
        ruleDetailsQuery,
        blobsQuery,
        psgManufacturersQuery,
        prepareDialogStateFor
    }
}

export default usePhotoHelperDialogState