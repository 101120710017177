import {StringUtils} from "shared/utils/StringUtils";


export class PsgVehicleTypeUtils {
    public static key = (manufacturerCode: string, lineId: number) => {
        return `${manufacturerCode}-${lineId}`
    }

    public static keyNullable = (manufacturerCode?: string | null, lineId?: number | null) => {
        if (!manufacturerCode || !lineId) return undefined
        return `${manufacturerCode}-${lineId}`
    }

    public static lineIdFromKey = (key: string): number | undefined => {
        if (StringUtils.undefinedNullOrEmpty(key) || !key.includes("-")) return undefined;
        const lineId = +key.split('-')[1]
        if (!lineId || isNaN(lineId)) return undefined
        return lineId
    }
}