import WorksheetEditorHeaderMenuNav from "modules/worksheet/editor/ui/header/WorksheetEditorHeaderMenuNav";
import {WorksheetHeaderMenuItem} from "modules/worksheet/editor/ui/header/WorksheetEditorHeaderMenu";

interface WorksheetEditorHeaderMenuCommonProps {
    headerMenuItem: WorksheetHeaderMenuItem
    checked?: boolean
}

const WorksheetEditorHeaderMenuCommon = ({headerMenuItem, checked}: WorksheetEditorHeaderMenuCommonProps) => {

    // const [, setInfo] = useWorksheetEditorHeaderInfoAtom()

    // const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    /*const onBtnClick = () => {
        setInfo(prev => {
            return {
                ...prev,
                open: !prev.open
            }
        })
    }*/

    return (
        <WorksheetEditorHeaderMenuNav
            headerMenuItem={headerMenuItem}
            onNavLinkClick={() => {
            }}
            // isActive={info.open}
            checked={checked}
        />
    )
}

export default WorksheetEditorHeaderMenuCommon