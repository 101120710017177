import React from 'react';
import {useWorksheetSaveState} from "modules/worksheet/editor/state/WorksheetSaveAtoms";
import WorksheetSignature from "modules/worksheet/signature/WorksheetSignature";
import {Typography} from "@mui/material";
import {useMechanicWorksheetsUrl} from "modules/worksheet/editor/shared/data/useMechanicWorksheetsUrl";
import {CDAMWMWWorksheetSignatureUpdate} from "crowbar-api";

const WorksheetEditorSignature = () => {
    const [ws, setWs] = useWorksheetSaveState()
    const mechanicWsUrl = useMechanicWorksheetsUrl()

    const onSignatureUpdate = (updates: CDAMWMWWorksheetSignatureUpdate[]) => {
        // After the signature updates, we must change the local state as well.
        // The worksheet signature is a generic ui, capable handling multiple worksheets.
        // It does not use the useWorksheetSaveState() hook, so we need to do it here.
        if (!ws?.id) return
        const matchingUpdate = updates.find(up => up.id === ws.id)
        if (!matchingUpdate) return
        setWs(prev => {
            return {
                ...prev,
                status: matchingUpdate.status,
                signatureBase64: matchingUpdate.signatureBase64,
                signatureName: matchingUpdate.signatureName
            }
        })
    }

    if (!ws) {
        return (
            <Typography>Failed to load worksheet in editor for the signature.</Typography>
        )
    }

    return (
        <WorksheetSignature
            currentWorksheet={ws}
            // returnUrl={`/worksheet/edit/${ws?.id}/basic`}
            onCompleteReturnUrl={mechanicWsUrl}
            onBackReturnUrl={`/worksheet/edit/${ws?.id}/basic`}
            onSignatureUpdated={onSignatureUpdate}
        />
    );
};

export default WorksheetEditorSignature;