import {atom, SetStateAction, useAtom} from "jotai";
import {
    CDSMNNewWorksheetCustomer,
    CDSMNNewWorksheetCustomerParams,
    CDSMNNewWorksheetEquipment,
    CDSMNNewWorksheetEquipmentParams,
    CDSMNNewWorksheetSalesOrder,
    CDSMNNewWorksheetSalesOrderProps
} from "crowbar-api";
import {DateUtils} from "shared/utils/DateUtils";
import {UseInfiniteQueryResult} from "@tanstack/react-query";
import {
    useNewWorksheetPageCustomersByInfQuery
} from "crowbar-api/hooks/worksheet/new-worksheet/useNewWorksheetPageCustomersByInfQuery";
import {
    useNewWorksheetPageSalesOrdersByInfQuery
} from "crowbar-api/hooks/worksheet/new-worksheet/useNewWorksheetPageSalesOrdersByInfQuery";
import {
    useNewWorksheetPageEquipmentsByInfQuery
} from "crowbar-api/hooks/worksheet/new-worksheet/useNewWorksheetPageEquipmentsByInfQuery";

export interface UseNewWorksheetStateResult {
    state: NewWorksheetState
    setState: (update: SetStateAction<NewWorksheetState>) => void
    resetState: () => void

    isLoading: boolean
    isFetching: boolean
    isFetchingNextPage: boolean

    customersHasNextPage: boolean
    salesOrdersHasNextPage: boolean
    equipmentHasNextPage: boolean
    anyHasNextPage: boolean

    loadMore: () => Promise<void>
    loadMoreCustomers: () => Promise<void>
    loadMoreSalesOrders: () => Promise<void>
    loadMoreEquipments: () => Promise<void>

    customersByInfQuery: UseInfiniteQueryResult<CDSMNNewWorksheetCustomer[], unknown>
    salesOrdersByInfQuery: UseInfiniteQueryResult<CDSMNNewWorksheetSalesOrder[], unknown>
    equipmentsByInfQuery: UseInfiniteQueryResult<CDSMNNewWorksheetEquipment[], unknown>
}

export interface NewWorksheetCustomerSearchState extends CDSMNNewWorksheetCustomerParams {

}

export interface NewWorksheetEquipmentSearchState extends CDSMNNewWorksheetEquipmentParams {

}

export interface NewWorksheetSalesOrderSearchState extends CDSMNNewWorksheetSalesOrderProps {

}

export const newWorksheetSubTablePageSizes = [5, 10, 20, 50, 100] as const

export interface NewWorksheetState {
    searchValue: string
    pageLimit: number
    orderBy: 'ASC' | 'DESC'

    searchCustomers: boolean
    searchEquipments: boolean
    searchSalesOrders: boolean

    customerSearch: NewWorksheetCustomerSearchState
    equipmentSearch: NewWorksheetEquipmentSearchState
    salesOrderSearch: NewWorksheetSalesOrderSearchState

    /**
     * Number of rows shown for the tables inside the entries.
     * Not a query field.
     */
    subTablePageSize: typeof newWorksheetSubTablePageSizes[number]
    filtersExpanded: boolean
}

const defaultState: NewWorksheetState = {
    searchValue: "",
    pageLimit: 10,
    orderBy: 'DESC',
    searchCustomers: true,
    searchEquipments: true,
    searchSalesOrders: true,
    customerSearch: {
        includeContacts: true,
        includeAddresses: true,
        lastRowNumber: 0,
        limit: 10, // we use pageLimit, just init here
        showDeleted: false
    },
    equipmentSearch: {
        lastRowNumber: 0,
        limit: 10, // we use pageLimit, just init here
        showDeleted: false
    },
    salesOrderSearch: {
        includeClosed: false,
        includeItemRows: true,
        includeTextRows: true,
        completionRateMin: 0,
        completionRateMax: 1,
        salesOrderFrom: DateUtils.dateToServerFormat(DateUtils.newDateAndAdd({year: -1})),
        salesOrderTo: DateUtils.dateToServerFormat(new Date()),
        lastRowNumber: 0,
        limit: 10, // we use pageLimit, just init here
        showDeleted: false
    },
    subTablePageSize: 5,
    filtersExpanded: false
}

const newWorksheetState = atom<NewWorksheetState>(defaultState)

export const useNewWorksheetState = (): UseNewWorksheetStateResult => {

    const [state, setState] = useAtom(newWorksheetState)

    const customersByInfQuery = useNewWorksheetPageCustomersByInfQuery(state.searchCustomers, {
        ...state.customerSearch,
        search: state.searchValue,
        limit: state.pageLimit,
        orderByIsDesc: state.orderBy !== 'ASC'
    })

    const salesOrdersByInfQuery = useNewWorksheetPageSalesOrdersByInfQuery(state.searchSalesOrders, {
        ...state.salesOrderSearch,
        search: state.searchValue,
        limit: state.pageLimit,
        orderByIsDesc: state.orderBy !== 'ASC'
    })

    const equipmentsByInfQuery = useNewWorksheetPageEquipmentsByInfQuery(state.searchEquipments, {
        ...state.equipmentSearch,
        search: state.searchValue,
        limit: state.pageLimit,
        orderByIsDesc: state.orderBy !== 'ASC'
    })

    return {
        state,
        setState,
        resetState: () => setState(defaultState),

        isLoading: customersByInfQuery.isLoading || salesOrdersByInfQuery.isLoading ||
            equipmentsByInfQuery.isLoading,
        isFetching: customersByInfQuery.isFetching || salesOrdersByInfQuery.isFetching ||
            equipmentsByInfQuery.isFetching,
        isFetchingNextPage: customersByInfQuery.isFetchingNextPage || salesOrdersByInfQuery.isFetchingNextPage ||
            equipmentsByInfQuery.isFetchingNextPage,
        customersHasNextPage: (customersByInfQuery.hasNextPage ?? false),
        salesOrdersHasNextPage: (salesOrdersByInfQuery.hasNextPage ?? false),
        equipmentHasNextPage: (equipmentsByInfQuery.hasNextPage ?? false),
        anyHasNextPage: (customersByInfQuery.hasNextPage ?? false) ||
            (salesOrdersByInfQuery.hasNextPage ?? false) ||
            (equipmentsByInfQuery.hasNextPage ?? false),

        loadMore: async () => {
            // parallel execution
            await Promise.all([
                customersByInfQuery.fetchNextPage(),
                equipmentsByInfQuery.fetchNextPage(),
                salesOrdersByInfQuery.fetchNextPage()
            ])
        },
        loadMoreCustomers: async () => {
            await customersByInfQuery.fetchNextPage()
        },
        loadMoreSalesOrders: async () => {
            await salesOrdersByInfQuery.fetchNextPage()
        },
        loadMoreEquipments: async () => {
            await equipmentsByInfQuery.fetchNextPage()
        },

        customersByInfQuery,
        salesOrdersByInfQuery,
        equipmentsByInfQuery,

    }
}