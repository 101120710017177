import React, {useEffect, useMemo} from 'react';
import {TextFieldProps} from "@mui/material/TextField/TextField";
import {TextField} from "@mui/material";
import {useNewAtomWithRef} from "shared/hook/useNewatomWithRef";
import debounce from "lodash/debounce";
import {NumberUtils} from "shared/utils/NumberUtils";
import CrowbarConstants from "crowbar-api/CrowbarConstants";
import {toastError} from "shared/toast/DefaultToasts";
import useTranslateFunction from "shared/language/useTranslateFunction";

export type NumberOnlyTextFieldProps = Omit<TextFieldProps, "value" | "onChange"> & {
    value: number
    onChange?: (newValue: number) => void

    hideZeroOnClick?: boolean

    debounceTime?: number
}

const NumberOnlyTextField = ({
                                 value,
                                 onChange,
                                 hideZeroOnClick,
                                 debounceTime,
                                 ...props
                             }: NumberOnlyTextFieldProps) => {
    const tk = useTranslateFunction('Generic.Validations')
    const [currentValue, setCurrentValue] = useNewAtomWithRef<string>(value.toString())

    useEffect(() => {
        setCurrentValue(value.toString())
    }, [setCurrentValue, value])

    const changeDebounce = useMemo(() => debounce((value: number) => {
        onChange?.(value)
    }, debounceTime ?? 50), [debounceTime, onChange])

    const onValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = NumberUtils.cleanNumberOnlyHTMLInputValue(event.target.value)
        const newValueAsNumber = NumberUtils.asNumberOrZero(newValue)
        if (newValueAsNumber > CrowbarConstants.IntegerMax) {
            toastError(tk('NumberTooLarge'))
            setCurrentValue("0")
            changeDebounce(0)
        } else {
            setCurrentValue(newValue)
            changeDebounce(newValueAsNumber)
        }
    }

    const onClick = () => {
        if (!props.disabled && hideZeroOnClick && currentValue === "0") {
            setCurrentValue("")
        }
    }

    return (
        <TextField
            InputLabelProps={{
                shrink: true
            }}
            inputProps={{
                autoComplete: "off",
            }}
            onClick={onClick}
            value={currentValue}
            onChange={onValueChange}
            {...props}
            error={isNaN(+currentValue) || props.error}
        />
    )
}

export default NumberOnlyTextField;