import React from 'react';
import {CatalogueTableRow} from "modules/admin/worksheet/evaluation/tabs/service-calls/catalogue/table/CatalogueTable";
import useTranslateFunction from "shared/language/useTranslateFunction";

export type SalesOrderStatusCellProps = {
    row: CatalogueTableRow
}
const SalesOrderStatusCell = ({row}: SalesOrderStatusCellProps) => {
    const tk = useTranslateFunction('Admin.Worksheet')
    return (
        <span>
            {row.salesOrderRow?.lineStatus
                ? row.salesOrderRow.lineStatus === 'O' ? tk('Open') : tk('Closed')
                : "-"
            }
        </span>
    )
}

export default SalesOrderStatusCell;