import React from 'react';
import {CDAMWMWServiceCallItemRowSave} from "crowbar-api";
import {Stack} from "@mui/material";
import ServiceCallItemRowSelectedSourceMap from "crowbar-api/enum-map/ServiceCallItemRowSelectedSourceMap";
import WEditorPageCatalogueItemRowDetailsBtn
    from "modules/worksheet/editor/ui/catalogue/WEditorPageCatalogueItemRowDetailsBtn";
import WEditorPageCatalogueItemRowDeleteBtn
    from "modules/worksheet/editor/ui/catalogue/WEditorPageCatalogueItemRowDeleteBtn";
import useWorksheetSignedState from "modules/worksheet/editor/state/helpers/useWorksheetSignedState";

export interface WEditorPageCatalogueItemRowActionsProps {
    itemRow: CDAMWMWServiceCallItemRowSave
}

const WEditorPageCatalogueItemRowActions = ({
                                                itemRow
                                            }: WEditorPageCatalogueItemRowActionsProps) => {

    const {editDisabled} = useWorksheetSignedState()

    // Allow row delete:
    // 1. if the row source is the master data (manually added) or manually entered text row
    // 2. or it has time source id (added from a different time row distribution)
    const allowDelete = (itemRow.rowSelectedSource === ServiceCallItemRowSelectedSourceMap.sapMasterData)
        || itemRow.sourceTimeRowId
        || (itemRow.rowSelectedSource === ServiceCallItemRowSelectedSourceMap.manuallyEntered)

    return (
        <Stack direction="row" spacing={1}>
            {itemRow.rowSelectedSource === ServiceCallItemRowSelectedSourceMap.sapSalesOrder && (
                <WEditorPageCatalogueItemRowDetailsBtn itemRow={itemRow}/>
            )}
            {allowDelete && (
                <WEditorPageCatalogueItemRowDeleteBtn itemRow={itemRow} disabled={editDisabled}/>
            )}
            {
                /* as of 2023.07.14 timesharing/splitting is disabled,
                        all other functionality connected to this action stays in the code,
                         but the button is not visible
                         https://trello.com/c/PENBJdLg

             <WEditorTimeDistributeButton
                serviceCallId={itemRow.serviceCallId}
                itemRow={itemRow}
                status={itemRow?.status}
                disabled={
                    true
                    // || editDisabled
                }
            />

                         */
            }
        </Stack>
    );
};

export default WEditorPageCatalogueItemRowActions;