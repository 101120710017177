import React from 'react';
import {CDAMWMServiceCallEvalModel, CDAMWMServiceCallModel, CDAMWMWWorksheetQuery} from "crowbar-api";
import CheckIconButton from "shared/components/buttons/CheckIconButton";

export type SapServiceCallAckProps = {
    worksheet: CDAMWMWWorksheetQuery | null | undefined
    sc: CDAMWMServiceCallModel
    scEval: CDAMWMServiceCallEvalModel
}
const SapServiceCallAck = ({
                               worksheet,
                               sc,
                               scEval,
                           }: SapServiceCallAckProps) => {
    return (
        <CheckIconButton
            size="large"
            checked={!!scEval.selectedCallId}
            disabled={false}
            onClick={() => {/*noop*/
            }}
            checkCircleIconProps={{
                sx: {
                    fontSize: "28pt",
                    ":hover": {
                        cursor: "default"
                    }
                }
            }}
        />
    );
};

export default SapServiceCallAck;