import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {DeliveryNoteTemplateApi} from "crowbar-api";
import {useMutation} from "@tanstack/react-query";
import httpResponseFileDownload from "shared/utils/HttpResponseFileDownload";

export const useDownloadTemplate = (id?: string) => {
    return useMutation(["template"], async () => {
        const response = await crowbarApiFactory(DeliveryNoteTemplateApi).downloadTemplate(id, {
            responseType: "arraybuffer"
        });

        httpResponseFileDownload(response);
    })
}