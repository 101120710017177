import {useMutation} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {WorksheetApi} from "crowbar-api";

export type UseWorksheetSetDeletedProps = {
    worksheetId: string
    deleted: boolean
}
export const useWorksheetSetDeleted = () => {
    return useMutation(['worksheet-set-deleted-by-id'], async ({worksheetId, deleted}: UseWorksheetSetDeletedProps) => {
        await crowbarApiFactory(WorksheetApi)
            .setDelete(worksheetId, deleted)
    })
}