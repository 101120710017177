import React from 'react';
import {Box, Stack, ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";
import ImageIcon from "shared/components/image-icon/ImageIcon";
import {WorksheetIconImages} from "modules/worksheet/editor/shared/component/images/WorksheetIconImages";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {
    MechanicListingStateProps,
    useMechanicListingState
} from "modules/worksheet/mechanic/state/useMechanicListingState";
import {CDAMWMWWorksheetSearchQueryParams} from "crowbar-api";

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

interface StyledToggleButtonProps {
    name: string
    image: string | JSX.Element
    text: string
    checked: boolean
    // onCheckClick: (name: string, checked: boolean) => void
}

/**
 * Separate component does not work.
 * ToggleButtonGroup children must be ToggleButton s
 * @param name
 * @param image
 * @param text
 * @param checked
 * @param onCheckClick
 * @constructor
 */
export const StyledToggleButton = ({name, image, text, checked}: StyledToggleButtonProps) => {
    return (
        <ToggleButton
            value={name} // toggle button value is like includeDraft or includeEvaluated
            sx={{
                position: "relative"
            }}
        >
            <Box sx={{
                position: "absolute",
                top: 0,
                left: 0,
                p: 0.5,
                color: "primary.main"
            }}>
                { /* mui CheckBox here results in a weird issue, the button group's onChange called twice
                  and the value is set to the previous value */
                    checked
                        ? <CheckBoxIcon/>
                        : <CheckBoxOutlineBlankIcon/>
                }
            </Box>
            <Stack direction="column" spacing={0.5} sx={{
                //  maxWidth: "70px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                {typeof image === "string" ? (
                    <ImageIcon image={image} size={32}/>
                ) : (
                    image
                )}
                <Typography>
                    {text}
                </Typography>
            </Stack>

        </ToggleButton>
    )
}

const booleansForWorksheetSearch = (worksheetSearch?: CDAMWMWWorksheetSearchQueryParams) => {
    return {
        includeDraft: worksheetSearch?.includeDraft ?? false,
        includeFinal: worksheetSearch?.includeFinal ?? false,
        includeFinalSignedOrSkipped: worksheetSearch?.includeFinalSignedOrSkipped ?? false,
        includeEvaluated: worksheetSearch?.includeEvaluated ?? false
    }
}

export type WorksheetStatusFilterButtonGroupProps = MechanicListingStateProps & {}

export const WorksheetStatusFilterButtonGroup = ({mechanicStateAtom}: WorksheetStatusFilterButtonGroupProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.Mechanic`)

    const {state, setState} = useMechanicListingState(mechanicStateAtom)

    const {
        includeDraft,
        includeFinal,
        includeFinalSignedOrSkipped,
        includeEvaluated
    } = booleansForWorksheetSearch(state?.worksheetSearch)

    const btnGroupValue: string[] = []
    if (includeDraft) btnGroupValue.push("includeDraft")
    if (includeFinal) btnGroupValue.push("includeFinal")
    if (includeFinalSignedOrSkipped) btnGroupValue.push("includeFinalSignedOrSkipped")
    if (includeEvaluated) btnGroupValue.push("includeEvaluated")

    const onChange = (e: any, value: string[]) => {
        setState(prev => {
            return {
                ...prev,
                worksheetSearch: {
                    ...prev.worksheetSearch,
                    includeDraft: value.includes("includeDraft"),
                    includeFinal: value.includes("includeFinal"),
                    includeFinalSignedOrSkipped: value.includes("includeFinalSignedOrSkipped"),
                    includeEvaluated: value.includes("includeEvaluated")
                }
            }
        })
    }

    /*const onCheckClick = (name: string, checked: boolean) => {
        let newArr = [...btnGroupValue]
        const indexOfName = newArr.indexOf(name)
        if (checked && indexOfName === -1 ) { // not found
            newArr.push(name)
        }
        if (!checked && indexOfName >= 0) {
            newArr.splice(indexOfName, 1)
        }

        onChange(null, newArr)
    }*/

    return (
        <Stack direction="column" spacing={0.5}>

            <Typography variant="caption">
                {tk('WorksheetStatus')}
            </Typography>

            <ToggleButtonGroup
                exclusive={false}
                value={btnGroupValue}
                onChange={onChange}
            >
                {StyledToggleButton({
                    name: "includeDraft",
                    image: WorksheetIconImages.draftMedium,
                    text: tk('Draft'),
                    checked: includeDraft
                })}
                {StyledToggleButton({
                    name: "includeFinal",
                    image: WorksheetIconImages.signMedium,
                    text: tk('ToSign'),
                    checked: includeFinal
                })}
                {StyledToggleButton({
                    name: "includeFinalSignedOrSkipped",
                    image: WorksheetIconImages.positiveVoteMedium,
                    text: tk('Finished'),
                    checked: includeFinalSignedOrSkipped
                })}
                {StyledToggleButton({
                    name: "includeEvaluated",
                    image: WorksheetIconImages.gavelMedium,
                    text: tk('Evaluated'),
                    checked: includeEvaluated
                })}
            </ToggleButtonGroup>
        </Stack>
    )
}