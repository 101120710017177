import React from 'react';
import {Card, CardActions, CardContent, Grid} from "@mui/material";
import {CDAMWMWWorksheetSearchQuery} from "crowbar-api";
import {WorksheetVersionMismatch} from "modules/worksheet/mechanic/state/useLocalAndServerWorksheetVersions";
import ResultEntryModeWorksheetServiceCall
    from "modules/worksheet/mechanic/results/ResultEntryModeWorksheetServiceCall";
import ServiceCallHeaderGridItems from "modules/worksheet/mechanic/results/ServiceCallHeaderGridItems";
import AddToWorksheetCartButton from "modules/worksheet/worksheet-cart/AddToWorksheetCartButton";

export interface ResultEntryModeWorksheetProps {
    worksheet: CDAMWMWWorksheetSearchQuery
    mismatch?: WorksheetVersionMismatch
}

const ResultEntryModeWorksheet = ({worksheet, mismatch}: ResultEntryModeWorksheetProps) => {
    //const tk = useTranslateFunction(`Worksheet.Generic.Mechanic`)

    //const openWorksheetEditor = useOpenWorksheetEditor()

    //const isMounted = useMountedState()

    const serviceCalls = worksheet?.serviceCalls ?? []
    //const salesOrder = worksheet?.salesOrders?.find(() => true)

    //const hasSapEquipment = !!salesOrder?.sapEquipmentId

    return (
        <Card elevation={16} sx={{
            p: 2
        }}>
            <CardContent>
                <Grid container spacing={1}>

                    <ServiceCallHeaderGridItems
                        worksheet={worksheet}
                        mismatch={mismatch}
                        serviceCallId={undefined}
                    />

                    <Grid item xs={12}>
                        <Grid container spacing={0.5}>
                            {serviceCalls.map(sc => (
                                <Grid item xs={12} key={sc.id}>
                                    <ResultEntryModeWorksheetServiceCall
                                        worksheet={worksheet}
                                        serviceCall={sc}
                                    />
                                </Grid>
                            ))}
                        </Grid>

                    </Grid>

                </Grid>
            </CardContent>
            <CardActions>
                <AddToWorksheetCartButton scope="editor" worksheet={worksheet}/>
            </CardActions>
        </Card>
    );
};

export default ResultEntryModeWorksheet;