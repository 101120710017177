import React from 'react';
import {Button, Card, CardActions, CardContent, Grid, Typography} from "@mui/material";
import {useWorksheetCartState} from "modules/worksheet/worksheet-cart/useWorksheetCartState";
import useTranslateFunction from "shared/language/useTranslateFunction";
import WorksheetCartEntryCard from "modules/worksheet/worksheet-cart/WorksheetCartEntryCard";
import {useNavigate} from "react-router-dom";
import useWorksheetSignatureState, {WorksheetIdOption} from "modules/worksheet/signature/useWorksheetSignatureState";

export type WorksheetCartProps = {
    scope?: string | number
}
const WorksheetCart = ({scope}: WorksheetCartProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    const {cartEntries, setCartEntries} = useWorksheetCartState(scope)

    const navigate = useNavigate()
    const {prepareFor} = useWorksheetSignatureState()

    const onClearAll = () => {
        setCartEntries([])
    }

    const onSignAll = () => {
        // 1. Read the selected worksheets from the cart
        const options = cartEntries.map((e): WorksheetIdOption => {
            return {
                id: e.id,
                serialId: e.serialId
            }
        })
        // 2. prepare the signature state
        prepareFor(options)
        // 3. navigate to the url
        navigate('/worksheet/worksheet-signature')
    }

    return (
        <Card variant="outlined" sx={{p: 1, minWidth: "350px", maxWidth: "70vw"}}>
            <CardContent>
                <Grid container spacing={1}>

                    <Grid item xs={12}>
                        <Typography>{tk('ToSign')}</Typography>
                    </Grid>

                    {cartEntries.map((e, i) => (
                        <Grid item xs={12} key={[e.id, i].join("-")}>
                            <WorksheetCartEntryCard scope={scope} entry={e}/>
                        </Grid>
                    ))}

                </Grid>
            </CardContent>
            <CardActions sx={{
                display: "flex",
                justifyContent: "space-between"
            }}>
                <Button variant="text" onClick={onClearAll}>
                    {tk('ClearAll')}
                </Button>
                <Button variant="contained" onClick={onSignAll}>
                    {tk('SignAll')}
                </Button>
            </CardActions>
        </Card>
    )
}

export default WorksheetCart;