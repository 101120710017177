import React, {useRef, useState} from "react";
import {CDAMDMDeliveryNoteTemplateModel} from "crowbar-api";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    TextField,
    Typography
} from "@mui/material";
import AlertDialog from "shared/components/alert-dialog/AlertDialog";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {toastError, toastSuccess} from "shared/toast/DefaultToasts";
import {v4 as uuidv4} from "uuid";
import {useDeleteDeliveryNoteTemplate} from "crowbar-api/hooks/delivery-note/useDeleteDeliveryNoteTemplate";
import {useSaveDeliveryNoteTemplate} from "crowbar-api/hooks/delivery-note/useSaveDeliveryNoteTemplate";
import {useDownloadTemplate} from "crowbar-api/hooks/delivery-note/useDownloadTemplate";

interface TemplateFormDialogProps {
    mode: 'new' | 'edit'
    editItem?: CDAMDMDeliveryNoteTemplateModel
    open: boolean
    onCancel: () => void
    onSubmit: () => void
}

const TemplateDialog = ({mode, editItem, open, onCancel, onSubmit}: TemplateFormDialogProps) => {
    const tk = useTranslateFunction("DeliveryNote.Form.")
    const deleteDeliveryNoteTemplate = useDeleteDeliveryNoteTemplate();
    const saveDeliveryNoteTemplate = useSaveDeliveryNoteTemplate();
    const downloadTemplate = useDownloadTemplate(editItem?.id)

    const [description, setDescription] = useState<string>(editItem?.description ?? "");
    const [isDefaultChecked, setIsDefaultChecked] = useState<boolean>(editItem?.defaultTemplate ?? false);
    const [fileLabel, setFileLabel] = useState<string | undefined>("");
    const [isFileLoaded, setIsFileLoaded] = useState<boolean>(false);
    const [fileBase64, setFileBase64] = useState<string | undefined>(undefined);
    const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState<boolean>(false);
    const hiddenFileInputRef = useRef<HTMLInputElement>(null);

    if ((open && mode === "edit") && (!editItem || !editItem.id)) {
        toastError(tk('EditLoadingFailed'))
    }

    const handleTemplateDownload = async () => {
        await downloadTemplate.mutateAsync();
    }

    const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDescription(event.target.value);
    }

    const handleDefaultCheckChange = () => {
        setIsDefaultChecked(!isDefaultChecked);
    }

    const handleBrowseClick = () => {
        hiddenFileInputRef?.current?.click()
    }

    const handleFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event) return;
        const files = event.target.files;
        if (!files || files.length < 1) return;
        const file = files[0];
        await getBase64(file).then(fileBase64 => {
            setFileBase64(fileBase64);
            setFileLabel(file.name)
            setIsFileLoaded(true);

            toastSuccess(tk('FileReaderSuccess'));
        }).catch(error => {
            setFileBase64(undefined);
            setFileLabel(undefined)
            setIsFileLoaded(false);

            toastError("Failed to read file: " + error);
        });

    }

    const getBase64 = (file: File): Promise<string> => {
        return new Promise<string>((resolve, reject) => {
            if (!(/\.(docx$)/i.test(file.name))) {
                toastError(tk('InvalidFileExtError'));
                reject();
            }

            // Make new FileReader
            let reader = new FileReader();
            reader.onload = () => {
                const c = reader.result as string;
                const searchString = "base64,";
                const indexOf = c.indexOf(searchString);
                if (indexOf === -1) {
                    reject("Could not parse as base64");
                }
                const cF = c?.substring(indexOf + searchString.length);
                resolve(cF);
            }
            // Convert the file to mimetype + base64 text
            reader.readAsDataURL(file);
        });
    }

    const handleDeleteConfirm = async (res: "ok" | "cancel") => {
        if (res === 'cancel') {
            setIsDeleteConfirmOpen(false)
            return;
        }

        if (!editItem || !editItem.id) {
            toastError('Failed to load item');
            return;
        }

        await deleteDeliveryNoteTemplate.mutateAsync(editItem.id as string)
        setIsDeleteConfirmOpen(false);
        onSubmit();
    }

    const handleDelete = () => {
        setIsDeleteConfirmOpen(true)
    }

    const handleSubmit = async () => {
        if (mode === "new" && (!isFileLoaded || !fileBase64)) {
            toastError(tk('FormInvalidFile'));
            return;
        }

        const template: CDAMDMDeliveryNoteTemplateModel = (mode === 'new') ?
            {
                id: uuidv4(),
                defaultTemplate: isDefaultChecked,
                description: description,
                fileBase64: fileBase64
            } :
            {
                id: editItem?.id,
                defaultTemplate: isDefaultChecked,
                description: description,
                fileBase64: "" //does not override FileBase64 in database, needed because the field is required
            };

        await saveDeliveryNoteTemplate.mutateAsync(template)
        onSubmit();
    }

    return (
        <>
            <Dialog open={open}
                    hideBackdrop={false}
                    fullScreen={false}
                    fullWidth={true}
                    maxWidth="md"
                    sx={{
                        borderRadius: 0
                    }}
            >
                <DialogTitle>{tk(mode === "new" ? "TitleNew" : "TitleEdit")}</DialogTitle>
                <DialogContent>
                    {mode === "edit" &&
                        <Button
                            variant={"contained"}
                            onClick={handleTemplateDownload}
                        >
                            {tk("DownloadTemplate")}
                        </Button>
                    }

                    <TextField label={tk("DescriptionText")}
                               value={description}
                               size={"small"}
                               autoComplete={"off"}
                               fullWidth
                               sx={{my: 2}}
                               onChange={handleDescriptionChange}
                    />

                    <Typography>{tk("DefaultText")}</Typography>

                    <FormControlLabel
                        control={<Checkbox
                            checked={isDefaultChecked}
                            onChange={handleDefaultCheckChange}
                        />}
                        label={tk("DefaultCbLabel")}
                    />
                    {mode === "new" ?
                        <div>
                            <Typography>{tk("FileUploadText")}</Typography>
                            <Grid container direction={"row"} alignItems={"center"} spacing={2}>
                                <Grid item>
                                    <Button
                                        variant={"contained"}
                                        onClick={handleBrowseClick}
                                    >
                                        {tk("FileUploadButtonText")}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Typography>{fileLabel ?? tk("FileUploadLabelEmpty").toString()}</Typography>
                                </Grid>
                            </Grid>

                            <input type="file"
                                   accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                   onChange={handleFileSelect}
                                   id="templateUpload"
                                   style={{display: 'none'}}
                                   ref={hiddenFileInputRef}
                            />
                        </div>
                        :
                        <p>{tk("FileUploadOnEditDisabled")}</p>
                    }

                </DialogContent>
                <DialogActions disableSpacing={false}>
                    <Button
                        variant={"outlined"}
                        onClick={onCancel}
                    >
                        {tk("CancelButton")}
                    </Button>
                    {mode === "new" ? null :
                        <Button variant={"outlined"}
                                color={"error"}
                                onClick={handleDelete}>
                            {tk("DeleteButton")}
                        </Button>
                    }
                    <Button
                        variant={"contained"}
                        onClick={handleSubmit}
                    >
                        {tk("SaveButton")}
                    </Button>
                </DialogActions>
            </Dialog>
            <AlertDialog
                isOpen={isDeleteConfirmOpen}
                onClose={handleDeleteConfirm}
            />
        </>
    );
}

export default TemplateDialog;