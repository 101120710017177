import React from 'react';
import {CDAMWMServiceCallEvalModel} from "crowbar-api";
import CheckIconButton from "shared/components/buttons/CheckIconButton";

export type ServiceCallStatusCellProps = {
    row: CDAMWMServiceCallEvalModel
}
const ServiceCallStatusCell = ({row}: ServiceCallStatusCellProps) => {

    return (
        <CheckIconButton checked={!!row.selectedCallId}/>
    );
};

export default ServiceCallStatusCell;