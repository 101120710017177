import {useNavigate, useParams} from "react-router-dom";
import usePhotoStepTemplateById
    from "modules/admin/worksheet/photo-rule/photo-step-template/edit/usePhotoStepTemplateById";
import {useEffect} from "react";
import {toastError, toastSuccess} from "shared/toast/DefaultToasts";
import usePhotoStepTemplateDelete, {
    PhotoStepTemplateDeleteError
} from "modules/admin/worksheet/photo-rule/photo-step-template/edit/usePhotoStepTemplateDelete";
import {CircularProgress, Grid, Typography} from "@mui/material";
import {BackButton} from "shared/components/buttons/BackButton";
import PhotoStepTemplateEditFields
    from "modules/admin/worksheet/photo-rule/photo-step-template/edit/PhotoStepTemplateEditFields";
import {CDAMPMPhotoRuleStepTemplateModel} from "crowbar-api";
import usePhotoStepTemplateSave
    from "modules/admin/worksheet/photo-rule/photo-step-template/edit/usePhotoStepTemplateSave";
import useTranslateFunction from "shared/language/useTranslateFunction";

export interface PhotoStepTemplateEditProps {
    mode: 'new' | 'edit'
}

const PhotoStepTemplateEdit = ({mode}: PhotoStepTemplateEditProps) => {
    const tk = useTranslateFunction(`Admin.PhotoRule`)

    const {templateId} = useParams()
    const navigate = useNavigate()

    const templateQuery = usePhotoStepTemplateById(templateId)
    const templateDelete = usePhotoStepTemplateDelete()
    const templateSave = usePhotoStepTemplateSave()

    // navigate back on error
    useEffect(() => {
        if (mode === "edit" && templateQuery.isError) {
            toastError('Failed to load Template.')
            navigate("/admin/worksheet-admin/photo-rule/templates")
        }
    }, [templateQuery.isError, navigate, mode])

    const onDelete = async () => {
        if (!templateId) {
            toastError('Cant delete. Missing templateId.')
            return
        }

        await templateDelete.mutateAsync(templateId, {
            onError: (e) => {
                if (e instanceof PhotoStepTemplateDeleteError) {
                    toastError(tk('TemplateInUse'))
                } else {
                    throw e
                }
            },
            onSuccess: () => {
                navigate("/admin/worksheet-admin/photo-rule/templates")
                toastSuccess(tk('TemplateDeleted'))
            }
        })
    }

    const onSubmit = async (newTemplate: CDAMPMPhotoRuleStepTemplateModel) => {
        await templateSave.mutateAsync(newTemplate, {
            onError: (e) => {
                console.error(e)
                toastError(tk('TemplateSaveFailed'))
            },
            onSuccess: () => {
                navigate("/admin/worksheet-admin/photo-rule/templates")
                toastSuccess(tk('TemplateSaved'))
            }
        })
    }

    if (mode === "edit" && templateQuery.isLoading) {
        return <CircularProgress/>
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography component="h1" sx={{
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: "20pt"
                }}>
                    <BackButton to="/admin/worksheet-admin/photo-rule/templates"/>
                    {
                        mode === "edit"
                            ? tk('TemplateEdit')
                            : tk('NewTemplate')
                    }
                </Typography>
            </Grid>

            <PhotoStepTemplateEditFields
                mode={mode}
                template={templateQuery.data}
                onSubmitClicked={onSubmit}
                onDeleteClicked={onDelete}
            />
        </Grid>
    )
}

export default PhotoStepTemplateEdit