import React, {useMemo} from 'react';
import {Lightbox, SlideImage} from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import {CDAMCMCrowbarBlobModel} from "crowbar-api";
import {useAzureBlobStorageUrlFactory} from "shared/hook/useAzureBlobStorageUrlFactory";
import CrowbarBlobTypeMap from "crowbar-api/enum-map/CrowbarBlobTypeMap";
import {StringUtils} from "shared/utils/StringUtils";

export type EquipmentImagesLightboxProps = {
    open: boolean
    onClose: () => void
    selectedImageId: string | undefined
    images: CDAMCMCrowbarBlobModel[]
}

type BlobSlideImage = SlideImage & {
    id: string
}

const EquipmentImagesLightbox = ({
                                     open,
                                     onClose,
                                     selectedImageId,
                                     images
                                 }: EquipmentImagesLightboxProps) => {

    const urlFactory = useAzureBlobStorageUrlFactory()

    const slideImages = useMemo(() => images
            .filter(blob => blob.type === CrowbarBlobTypeMap.equipmentImage &&
                StringUtils.notUndefinedNullAndEmpty(blob.parentDirectory) &&
                StringUtils.notUndefinedNullAndEmpty(blob.fileName) &&
                !!blob.id
            )
            .map((b): BlobSlideImage => {
                const src = urlFactory.imageFromName("equipment", b.parentDirectory, b.fileName)
                return {
                    id: b.id!,
                    src: src.uri ?? "",
                    width: window.screen.width,
                    height: window.screen.height
                }
            })
        , [images, urlFactory])

    const selectedSlideImage = slideImages.find(i => i.id === selectedImageId)
    const currentIndex = selectedSlideImage ? slideImages.indexOf(selectedSlideImage) : 0

    return (
        <Lightbox
            open={open}
            index={currentIndex}
            close={onClose}
            plugins={[Zoom, Thumbnails]}
            carousel={{finite: true}}
            zoom={{
                maxZoomPixelRatio: 10
            }}
            slides={slideImages}
        />
    );
};

export default EquipmentImagesLightbox;