import {CDAMWMWServiceCallItemRowSave, CDAMWMWServiceCallSave} from "crowbar-api";
import {useWorksheetSaveState} from "modules/worksheet/editor/state/WorksheetSaveAtoms";
import ServiceCallItemRowSelectedSourceMap from "crowbar-api/enum-map/ServiceCallItemRowSelectedSourceMap";
import {StringUtils} from "shared/utils/StringUtils";

export interface AvailableItemRowSum {
    available: number
    availableAsLocaleStr: string

    installedQuantity: number
    installedQuantityAsLocaleStr: string

    unitOfQuantity: string | undefined

    hasSalesOrder: boolean
    salesOrderQuantity: number | undefined

    serviceCallAvailableQuantities: ServiceCallAvailableQuantity[]
}

export interface ServiceCallAvailableQuantity {
    serviceCall: CDAMWMWServiceCallSave
    installed: number
    unitOfQuantity: string | undefined
}

const useAvailableItemRowSum = (currentItemRow: CDAMWMWServiceCallItemRowSave): AvailableItemRowSum => {
    const [worksheetSave] = useWorksheetSaveState()

    /*
        LineMatching
        For sales orders: matching source sales order row ids.
        (one worksheet can only have one sales order, so theoretically sourceLineNum should work too)
        For manually added rows: no matching is done, no available quantity left.
    */

    const scQuantities = worksheetSave?.serviceCalls?.map((sc): ServiceCallAvailableQuantity => {
        const itemRows = (sc?.itemRows ?? [])
            .filter(ir => ir.sourceSalesOrderRowId === currentItemRow.sourceSalesOrderRowId)
        const sumOfInstalledQuantity = itemRows
            .reduce((sum, ir) => sum + (ir?.installedQuantity ?? 0), 0)
        const uoq = itemRows
            ?.find(x => true)
            ?.unitOfQuantity ?? undefined
        return {
            serviceCall: sc,
            installed: sumOfInstalledQuantity,
            unitOfQuantity: uoq
        }
    })

    const currentServiceCall = scQuantities?.find(scq => scq.serviceCall.id === currentItemRow.serviceCallId)

    const installedSum = (scQuantities ?? []).reduce((sum, scq) => sum + scq.installed, 0)
    const installedSumLocaleStr = installedSum.toLocaleString(navigator.language, {
        maximumFractionDigits: 2
    })

    const available = Math.max((currentItemRow.sourceQuantity ?? 0) - installedSum, 0)
    const availableAsLocaleStr = available.toLocaleString(navigator.language, {
        maximumFractionDigits: 2
    })

    return {
        available: available,
        availableAsLocaleStr: availableAsLocaleStr,
        installedQuantity: installedSum,
        installedQuantityAsLocaleStr: installedSumLocaleStr,
        unitOfQuantity: currentServiceCall?.unitOfQuantity,
        hasSalesOrder: currentItemRow.rowSelectedSource === ServiceCallItemRowSelectedSourceMap.sapSalesOrder &&
            StringUtils.notUndefinedNullAndEmpty(currentItemRow.sourceSalesOrderRowId),
        salesOrderQuantity: currentItemRow.sourceQuantity ?? undefined,
        serviceCallAvailableQuantities: scQuantities ?? []
    }
}

export default useAvailableItemRowSum