import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import useTranslateFunction from "shared/language/useTranslateFunction";
import PartnerEquipmentForm
    from "modules/worksheet/editor/shared/component/partner-equipment-form/PartnerEquipmentForm";
import {
    PartnerEquipmentFormValues,
    usePartnerEquipmentForm
} from "modules/worksheet/editor/shared/component/partner-equipment-form/usePartnerEquipmentForm";
import {toastError} from "shared/toast/DefaultToasts";
import {useModSapEquipment} from "modules/worksheet/editor/state/atoms/useModSapEquipment";
import {useServiceCallAtom} from "modules/worksheet/editor/state/atoms/useServiceCallAtom";

export interface WEditorEquipmentEditFormDialogProps {
    open: boolean
    serviceCallId?: string
    onDialogClose: () => void

    modId?: string
}

const WEditorEquipmentEditFormDialog = ({
                                            open,
                                            serviceCallId,
                                            onDialogClose,
                                            modId
                                        }: WEditorEquipmentEditFormDialogProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    const [editModEquipment, setMod] = useModSapEquipment(modId)

    const partnerEquipmentForm = usePartnerEquipmentForm({
        mode: "edit",
        editModEquipment: editModEquipment
    })

    const [, setServiceCall] = useServiceCallAtom(serviceCallId)

    const onDialogSave = async () => {
        console.log(partnerEquipmentForm.getValues())

        const handleFunction = partnerEquipmentForm.handleSubmit(onPartnerEquipmentFormSubmit, e => console.error(e))
        // handleSubmit returns a function that needs to be called. So () is missing at the end.
        await handleFunction()
    }

    const onPartnerEquipmentFormSubmit = (formData: PartnerEquipmentFormValues) => {
        if (!editModEquipment) {
            toastError('Missing editModEquipment parameter.')
            return
        }

        setMod(prev => {
            return {
                ...prev,
                manufacturer: formData.manufacturer,
                vehicleType: formData.vehicleType,
                manufacturerSn: formData.manufacturerSn,
                internalSn: formData.internalSn,
                yearOfManufacture: formData.yoM,
                comment: formData.comment
            }
        })

        setServiceCall(prev => {
            return {
                ...prev,
                modSapEquipmentId: editModEquipment.id
            }
        })

        onDialogClose()
    }

    return (
        <Dialog open={open} onClose={onDialogClose} fullWidth>
            <DialogTitle>{tk('Edit')}</DialogTitle>
            <DialogContent>
                <PartnerEquipmentForm
                    mode="edit"
                    form={partnerEquipmentForm}
                    defaultManufacturerCode={editModEquipment?.manufacturer ?? ""}
                    defaultVehicleTypeName={editModEquipment?.vehicleType ?? ""}
                />
            </DialogContent>
            <DialogActions sx={{
                display: "flex",
                justifyContent: "space-between"
            }}>
                <Button variant="text" color="error" onClick={onDialogClose}>{tk('Cancel')}</Button>
                <Button variant="text" color="primary" onClick={onDialogSave}>{tk('Save')}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default WEditorEquipmentEditFormDialog;