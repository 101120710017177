import {useQuery} from "@tanstack/react-query";
import {UseQueryOptionParams} from "crowbar-api/util/UseQueryOptionParams";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {SapItemApi} from "crowbar-api";
import AxiosCancelTokenHelper from "crowbar-api/AxiosCancelTokenHelper";

export const useSapItemByItemCode = (itemCode: string | null | undefined, extraOptions?: UseQueryOptionParams) => {
    return useQuery(['sap-item-by-item-code', itemCode], async ({signal}) => {
        if (!itemCode) return null
        const response = await crowbarApiFactory(SapItemApi)
            .findByItemCode(itemCode, AxiosCancelTokenHelper.cancelTokenFrom(signal))
        return response?.data ?? null
    }, {
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        staleTime: Math.pow(10, 8),
        cacheTime: Math.pow(10, 8),
        retry: 1,
        keepPreviousData: true,
        ...extraOptions
    })
}