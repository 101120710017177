import {useQuery} from "@tanstack/react-query";
import {UseQueryOptionParams} from "crowbar-api/util/UseQueryOptionParams";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {SapPartnerApi} from "crowbar-api";

export const useSapPartnerByCardCode = (cardCode: string | undefined | null, extraOptions?: UseQueryOptionParams) => {
    return useQuery(['sap-partner-by-card-code-1', cardCode], async () => {
        if (!cardCode) return null
        const response = await crowbarApiFactory(SapPartnerApi)
            .findByCardCode(cardCode)
        return response.data ?? null
    }, {
        networkMode: "online",
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        cacheTime: Math.pow(10, 7),
        staleTime: Math.pow(10, 7),
        retry: 3,
        ...extraOptions
    })
}