import React from 'react';
import {CatalogueTableRow} from "modules/admin/worksheet/evaluation/tabs/service-calls/catalogue/table/CatalogueTable";
import NumberOnlyTextField from "shared/components/inputs/NumberOnlyTextField";
import {
    useServiceCallItemRowEvalUpdateInstalledQuantity
} from "crowbar-api/hooks/worksheet/evaluation/service-call-item-row/useServiceCallItemRowEvalUpdateInstalledQuantity";
import {toastError} from "shared/toast/DefaultToasts";

export type InstalledQuantityCellProps = {
    row: CatalogueTableRow
    onDataChange: () => Promise<void>
    disabled?: boolean
}
const DeliveryQuantityCell = ({row, onDataChange, disabled}: InstalledQuantityCellProps) => {
    //const tripDistance = row.eval?.isTrip ? row.eval?.allocatedTripDistance : undefined
    const value = row?.row?.installedQuantity ?? 0
    /*if (row.eval?.isTrip) {
        value = row?.row?.installedQuantity ? row.row.installedQuantity : tripDistance ?? 0
    } else {
        value = row?.row?.installedQuantity ?? 0
    }*/

    const updateInstalledQuantity = useServiceCallItemRowEvalUpdateInstalledQuantity()

    const onBlur = async (event: React.FocusEvent<HTMLInputElement>) => {
        const newValue = +event.target.value
        if (isNaN(newValue) || !row.eval?.id || !row.row?.id) {
            toastError('Failed to update value.')
            return
        }
        await updateInstalledQuantity.mutateAsync({
            evalId: row.eval.id,
            originalId: row.row.id,
            field: newValue
        })
        await onDataChange()
    }

    return (
        <NumberOnlyTextField
            size="small"
            value={value}
            // onChange={onChange}
            onBlur={onBlur}
            hideZeroOnClick={true}
            required={true}
            error={value === undefined || value === null}
            disabled={disabled || updateInstalledQuantity.isLoading}
        />
    )
}

export default DeliveryQuantityCell;