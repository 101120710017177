import {useMutation} from "@tanstack/react-query";
import {CDAMWMWWorksheetStatusUpdate, WorksheetApi} from "crowbar-api";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";

/**
 * Updates the status directly. (without a full worksheet save if used from editor)
 * Applicable to batch status updates for multiple worksheets.
 * Note for Editor: Because this bypasses the worksheetSave localStorage --> worksheet merge save
 * the editor must update the local state by hand
 */
export const useWorksheetUpdateStatus = () => {
    return useMutation(['worksheet-update-status'], async (e: CDAMWMWWorksheetStatusUpdate[]) => {
        const response = await crowbarApiFactory(WorksheetApi)
            .updateStatusFor(e)
        if (response.status !== 200) {
            throw new Error('Failed to save statuses. Server returned a not OK state.')
        }
    })
}