import {useMutation} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {CDAMWMESingleFieldWorksheetEvalUpdate1Int32, ServiceCallEvalApi} from "crowbar-api";
import {AxiosRequestConfig} from "axios";

export const useServiceCallEvalUpdateOwnerCode = () => {
    return useMutation(async (p: CDAMWMESingleFieldWorksheetEvalUpdate1Int32, options?: AxiosRequestConfig) => {
        await crowbarApiFactory(ServiceCallEvalApi)
            .updateOwnerCode(p, options)
    })
}