import React from 'react';
import {CDAMWMServiceCallEvalModel, CDAMWMServiceCallModel, CDAMWMWWorksheetQuery} from "crowbar-api";
import {Stack, Typography} from "@mui/material";
import {vehicleTypeDisplayFn} from "modules/worksheet/editor/shared/component/vehicle-type-display/VehicleTypeDisplay";
import useTranslateFunction from "shared/language/useTranslateFunction";
import LabelField from "shared/components/typography/LabelField";
import DateDisplay from "shared/components/date-display/DateDisplay";

export type CurrentEquipmentDetailsProps = {
    worksheet: CDAMWMWWorksheetQuery | null | undefined
    selectedServiceCall: CDAMWMServiceCallModel
    selectedEval: CDAMWMServiceCallEvalModel | undefined
}
const CurrentEquipmentDetails = ({worksheet, selectedServiceCall, selectedEval}: CurrentEquipmentDetailsProps) => {
    const tk = useTranslateFunction("Admin.Worksheet")
    const mod = worksheet?.modSapEquipments?.find(e => e.id === selectedServiceCall?.modSapEquipmentId)

    const vehicleType = vehicleTypeDisplayFn({
        manufacturer: selectedServiceCall?.manufacturer,
        vehicleType: selectedServiceCall?.vehicleType,
        modManufacturer: mod?.manufacturer,
        modVehicleType: mod?.vehicleType
    })

    const displayMod = (!selectedEval?.equipmentChanged && !!mod)

    const manufacturerSn = displayMod
        ? mod.manufacturerSn
        : selectedServiceCall?.manufacturerSn

    const internalSn = displayMod
        ? mod.internalSn
        : selectedServiceCall?.internalSn

    const yom = displayMod
        ? mod.yearOfManufacture
        : selectedServiceCall?.yearOfManufacture

    const comment = displayMod
        ? mod.comment
        : selectedServiceCall?.comment

    const diffDates = worksheet?.startedAt !== worksheet?.endedAt

    return (
        <Stack direction="row" spacing={1}>
            <Stack direction="column" spacing={0.2}>
                <LabelField
                    label={tk('ManufacturerAndVehicleType')}
                    content={vehicleType}
                    gridContainerProps={{
                        justifyContent: "start"
                    }}
                />
                <LabelField
                    label={tk('Customer')}
                    content={worksheet?.cardCode ?? ""}
                    gridContainerProps={{
                        justifyContent: "start"
                    }}
                />
                <LabelField
                    label={tk('ManufacturerSn')}
                    content={manufacturerSn ?? ""}
                    gridContainerProps={{
                        justifyContent: "start"
                    }}
                />
                <LabelField
                    label={tk('CurrentOperatingHours')}
                    content={selectedServiceCall?.currentEquipmentOperatingHours?.toString() ?? ""}
                    gridContainerProps={{
                        justifyContent: "start"
                    }}
                />
            </Stack>

            <Stack direction="column" spacing={0.2}>
                <LabelField
                    label={tk('InternalSn')}
                    content={internalSn ?? ""}
                    gridContainerProps={{
                        justifyContent: "start"
                    }}
                />
                <LabelField
                    label={tk('YoM')}
                    content={yom?.toString() ?? ""}
                    gridContainerProps={{
                        justifyContent: "start"
                    }}
                />
                <LabelField
                    label={tk('Comment')}
                    content={comment ?? ""}
                    gridContainerProps={{
                        justifyContent: "start"
                    }}
                />
                <LabelField
                    label={tk('WorksheetDate')}
                    content={diffDates ? (
                        <Stack direction="row" spacing={1}>
                            <DateDisplay dateStr={worksheet?.startedAt ?? ""} options={{hour: undefined}}/>
                            <Typography>-</Typography>
                            <DateDisplay dateStr={worksheet?.endedAt ?? ""} options={{hour: undefined}}/>
                        </Stack>
                    ) : (
                        <DateDisplay dateStr={worksheet?.endedAt ?? ""} options={{hour: undefined}}/>
                    )}
                    gridContainerProps={{
                        justifyContent: "start"
                    }}
                />
            </Stack>
        </Stack>
    )
}

export default CurrentEquipmentDetails;