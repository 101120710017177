import React from "react";
import {Box, Card, Stack, Typography} from "@mui/material";
import WorksheetEditorPageCatalogueItemRow
    from "modules/worksheet/editor/ui/catalogue/WorksheetEditorPageCatalogueItemRow";
import ServiceCallItemRowStatusMap from "crowbar-api/enum-map/ServiceCallItemRowStatusMap";
import useTranslateFunction from "shared/language/useTranslateFunction";
import WorksheetEditorPageCatalogueTextRow
    from "modules/worksheet/editor/ui/catalogue/WorksheetEditorPageCatalogueTextRow";
import {
    useServiceCallItemRowsAndTextLines
} from "modules/worksheet/editor/state/atoms/useServiceCallItemRowsAndTextLines";
import RenderIfVisible from "shared/render-if-visible/render-if-visible";
import WEditorPageCatalogueItemRowPlaceHolder
    from "modules/worksheet/editor/ui/catalogue/WEditorPageCatalogueItemRowPlaceHolder";

interface WorksheetEditorPageCatalogueItemRowsProps {
    disabled?: boolean
}

const WorksheetEditorPageCatalogueItemRows = ({disabled}: WorksheetEditorPageCatalogueItemRowsProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    const allRows = useServiceCallItemRowsAndTextLines()

    return (
        <Box
            /* elevation={2}*/
            sx={{

                marginBottom: "20vh"
            }}
        >
            <Stack direction={"column"} spacing={1}>
                {(!allRows || allRows.length === 0) && (
                    <Box sx={{
                        display: "flex",
                        justifyContent: "center"
                    }}>
                        <Typography>
                            {tk('NoItemRows')}
                        </Typography>
                    </Box>
                )}
                {/* <FormControlLabel
                    label={tk('HideCompletedRows')}
                    control={<Checkbox
                        checked={hideCompletedRows}
                        onChange={onHideCompletedCheckBoxChange}
                    />}
                />*/}

                {allRows && (
                    allRows.map((itemRow) => {
                        if ('status' in itemRow) {
                            return (
                                <Card
                                    key={itemRow.id}
                                    elevation={16}
                                    sx={{marginBottom: 2}}
                                >
                                    <RenderIfVisible defaultHeight={300} stayRendered={true} placeholderElement={(
                                        <WEditorPageCatalogueItemRowPlaceHolder/>
                                    )}>
                                        <WorksheetEditorPageCatalogueItemRow
                                            itemRow={itemRow}
                                            disabled={
                                                disabled ||
                                                itemRow?.status === ServiceCallItemRowStatusMap.installed ||
                                                itemRow?.status === ServiceCallItemRowStatusMap.notInstalled
                                            }
                                        />
                                    </RenderIfVisible>
                                </Card>
                            )
                        } else if ('lineText' in itemRow && itemRow.lineText) {
                            return (
                                <Card
                                    key={itemRow.id}
                                    variant="outlined"
                                    sx={{marginBottom: 2}}
                                >
                                    <WorksheetEditorPageCatalogueTextRow
                                        lineText={itemRow.lineText}
                                    />
                                </Card>
                            )
                        } else {
                            return <></>
                        }
                    })
                )}
            </Stack>
        </Box>
    )
}

export default WorksheetEditorPageCatalogueItemRows