import {useAtom} from "jotai";
import msalAuth from "shared/msal/MsalAuth";
import {msalTeamsAuthenticationHappenedAtom, msalTeamsContextAtom, msalUserClaimsAtom} from "App";

/**
 * @return userId, userClaimsArray
 */
export const useUserContext = (): [string | undefined, string[]] => {
    const [teamsAuth] = useAtom(msalTeamsAuthenticationHappenedAtom)
    const [teamsContext] = useAtom(msalTeamsContextAtom)
    const [userClaims] = useAtom(msalUserClaimsAtom);

    let userId: string | undefined
    let claims: string[]
    if (!teamsAuth) {
        userId = msalAuth.getUserId()
        claims = msalAuth.getUserClaims() ?? []
    } else {
        userId = teamsContext?.userObjectId
        claims = userClaims ?? []
    }

    return [
        userId,
        claims
    ]
}