import React from 'react';
import {CatalogueTableRow} from "modules/admin/worksheet/evaluation/tabs/service-calls/catalogue/table/CatalogueTable";

export type SalesOrderQuantityCellProps = {
    row: CatalogueTableRow
}
const SalesOrderQuantityCell = ({row}: SalesOrderQuantityCellProps) => {
    return (
        <span>
               {row.salesOrderRow?.quantity
                   ? row.salesOrderRow?.quantity
                   : "-"
               }
        </span>
    )
}

export default SalesOrderQuantityCell;