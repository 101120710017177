import React, {useState} from 'react';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    Typography
} from "@mui/material";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {CDAMWMWorksheetStatus, CDAMWMWWorksheetSearchQuery} from "crowbar-api";
import WorksheetStatusMap from "crowbar-api/enum-map/WorksheetStatusMap";
import {useTranslation} from "react-i18next";
import {toastError} from "shared/toast/DefaultToasts";
import {useWorksheetUpdateStatus} from "crowbar-api/hooks/worksheet/status-and-signature/useWorksheetUpdateStatus";

export type ChangeStateDialogProps = DialogProps & {
    worksheet: CDAMWMWWorksheetSearchQuery
    onStateChanged?: () => void
    onChangeDialogClose: () => void
}
const ChangeStateDialog = ({worksheet, onStateChanged, onChangeDialogClose, ...props}: ChangeStateDialogProps) => {
    const {t} = useTranslation()
    const tk = useTranslateFunction(`Admin.Worksheet`)

    const updateStatus = useWorksheetUpdateStatus()

    const [selectedStatus, setSelectedStatus] = useState<CDAMWMWorksheetStatus>(worksheet.status ?? CDAMWMWorksheetStatus.NUMBER_0)

    const onSaveClick = async () => {
        if (!worksheet?.id || worksheet?.status?.valueOf() === selectedStatus.valueOf()) {
            return
        }

        await updateStatus.mutateAsync([{
            id: worksheet.id,
            status: selectedStatus,
            ignoreStatusChangeRule: true
        }])

        onStateChanged?.()
        onChangeDialogClose?.()
    }

    const handleChange = (event: SelectChangeEvent<number>) => {
        const newSelected = +event.target.value
        if (isNaN(newSelected)) {
            toastError('Invalid status: ' + event.target.value)
            return
        }
        const newEnumValue = WorksheetStatusMap.allStatusesAsMap().get(newSelected)
        if (!newEnumValue) {
            toastError('Could not find status for ' + newSelected)
            return
        }
        setSelectedStatus(newEnumValue)
    }

    return (
        <Dialog {...props} onClose={onChangeDialogClose}>
            <DialogContent>
                <Grid container spacing={1}>
                    <Stack direction="column" spacing={1} sx={{mt: 3}}>

                        <FormControl fullWidth>
                            <InputLabel id="status-select-label">{tk('Status')}</InputLabel>
                            <Select
                                labelId="status-select-label"
                                value={selectedStatus.valueOf()}
                                label={tk('Status')}
                                onChange={handleChange}
                            >
                                {WorksheetStatusMap.allStatuses().map(s => (
                                    <MenuItem key={s.valueOf()} value={s.valueOf()}>
                                        {t(WorksheetStatusMap.translationKey(s))}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <Typography>
                            {tk('StateChangeWarn')}
                        </Typography>
                        <Typography>
                            {tk('StateChangeWarn2')}
                        </Typography>
                    </Stack>

                </Grid>
            </DialogContent>
            <DialogActions>
                <Stack direction="row" spacing={1} justifyContent="space-between">
                    <Button variant="outlined" color="error" onClick={() => onChangeDialogClose()}>
                        {tk('Cancel')}
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        disabled={updateStatus.isLoading || worksheet?.status?.valueOf() === selectedStatus.valueOf()}
                        onClick={onSaveClick}
                    >
                        {updateStatus.isLoading && (
                            <CircularProgress/>
                        )}
                        {tk('Save')}
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

export default ChangeStateDialog;