import React from 'react';
import {Route, Routes} from "react-router-dom";
import {Roles} from "shared/authorization/Roles";
import WorksheetEditor from "modules/worksheet/editor/WorksheetEditor";
import RequireAuth from "shared/authorization/RequireAuth";
import NewWorksheet from "modules/worksheet/new-worksheet/NewWorksheet";
import MechanicWorksheets, {WorksheetEditorCartScope} from "modules/worksheet/mechanic/MechanicWorksheets";
import WorksheetSignatureFromCart from "modules/worksheet/signature/WorksheetSignatureFromCart";
import {useMechanicWorksheetsUrl} from "modules/worksheet/editor/shared/data/useMechanicWorksheetsUrl";
import WEditorFailedToLoadWorksheetErrorPage
    from "modules/worksheet/editor/ui/error/WEditorFailedToLoadWorksheetErrorPage";

const WorksheetRoutes = () => {
    //const [, userClaimsArray] = useUserContext()

    const mechanicWsUrl = useMechanicWorksheetsUrl()

    return (
        <Routes>
            <Route path={`new`} element={(
                <RequireAuth requiredRole={Roles.Mechanic}>
                    <NewWorksheet/>
                </RequireAuth>
            )}/>

            <Route path={`edit/:worksheetId/*`} element={(
                <RequireAuth requiredRole={Roles.Mechanic}>
                    <WorksheetEditor/>
                </RequireAuth>
            )}/>

            <Route path="failed-to-load" element={<WEditorFailedToLoadWorksheetErrorPage/>}/>

            <Route path={`worksheet-signature`} element={(
                <WorksheetSignatureFromCart cartScope={WorksheetEditorCartScope} returnUrl={mechanicWsUrl}/>
            )}/>

            <Route path={`mechanic/:userId/worksheets`} element={(
                <RequireAuth requiredRole={Roles.Mechanic}>
                    <MechanicWorksheets/>
                </RequireAuth>
            )}/>
        </Routes>
    )
}

export default WorksheetRoutes;