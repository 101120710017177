import React, {useState} from 'react';

import {Button, CircularProgress, IconButton, Stack} from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import useTranslateFunction from "shared/language/useTranslateFunction";
import {toastError} from "shared/toast/DefaultToasts";
import {useWorksheetSaveState} from "modules/worksheet/editor/state/WorksheetSaveAtoms";
import useWorksheetStatuses from "modules/worksheet/editor/state/atoms/useWorksheetStatuses";
import WorksheetStatusMap from "crowbar-api/enum-map/WorksheetStatusMap";
import {
    worksheetAutomaticSaveFrequencyInMs
} from "modules/worksheet/editor/shared/data/useScheduledWorksheetSaveToLocalState";
import {useNavigate} from "react-router-dom";
import {UseWorksheetSaveMutationResult} from "crowbar-api/hooks/worksheet/useWorksheetSaveMutation";
import WorksheetSaveConfirmationDialog
    from "modules/worksheet/editor/shared/component/worksheet-save-dialog/WorksheetSaveConfirmationDialog";
import ConfirmDialog from "shared/components/confirm-dialog/ConfirmDialog";
import {useHasAnyTimeMatchRowsValidation} from "modules/worksheet/editor/ui/header/useHasAnyTimeMatchRowsValidation";
import useWorksheetSignedState from "modules/worksheet/editor/state/helpers/useWorksheetSignedState";

interface WorksheetEditorHeaderActionProps {

}

const WorksheetEditorHeaderAction = ({}: WorksheetEditorHeaderActionProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    const [validationConfirmDialogOpen, setValidationConfirmDialogOpen] = useState<boolean>(false)
    const [validationConfirmDialogTitle, setValidationConfirmDialogTitle] = useState("")
    const [validationConfirmDialogMessage, setValidationConfirmDialogMessage] = useState("")

    const [, setWs] = useWorksheetSaveState()
    const {valid} = useWorksheetStatuses()
    const {evaluated} = useWorksheetSignedState()

    const navigate = useNavigate()

    const hasAnyTimeMatchRows = useHasAnyTimeMatchRowsValidation()

    // const worksheetSave = useWorksheetSaveMutation()

    const onFinalizeClick = async (onClickStartSave: () => Promise<UseWorksheetSaveMutationResult>) => {
        if (!valid) {
            toastError(tk('NotValidWorksheet'))
            return
        }

        // Check if user added any work hour rows, confirm if not
        if (!hasAnyTimeMatchRows) {
            setValidationConfirmDialogTitle(tk('NoTimeRowsFoundTitle'))
            setValidationConfirmDialogMessage(tk('NoTimeRowsFoundMessage'))
            setValidationConfirmDialogOpen(true)
        } else {
            // later, we can add additional confirm messages here.
            // otherwise just start the save and navigation
            worksheetSaveAndNavigate(onClickStartSave)
        }
    }

    const onValidationDialogConfirm = (onClickStartSave: () => Promise<UseWorksheetSaveMutationResult>) => {
        setValidationConfirmDialogOpen(false)
        worksheetSaveAndNavigate(onClickStartSave)
    }

    const worksheetSaveAndNavigate = (onClickStartSave: () => Promise<UseWorksheetSaveMutationResult>) => {
        setWs(prev => {
            return {
                ...prev,
                status: prev?.status === null || prev?.status === undefined || prev?.status === WorksheetStatusMap.draft
                    ? WorksheetStatusMap.final
                    : prev.status
            }
        })
        setTimeout(() => {
            onClickStartSave().then((r) => {
                if (!r.hasError) {
                    navigate("signature")
                } else {
                    console.error(r)
                }
            }, () => {/* no-op */
            })
        }, worksheetAutomaticSaveFrequencyInMs * 2)
    }

    return (
        <Stack
            direction="row"
            spacing={1}
            sx={{
                width: 1,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end"
            }}>

            <WorksheetSaveConfirmationDialog
                render={(onClickStartSave, isLoading) => (
                    <>
                        {isLoading && (
                            <CircularProgress/>
                        )}
                        <IconButton
                            onClick={onClickStartSave}
                            disabled={isLoading || evaluated}
                        >
                            <SaveIcon sx={{
                                color: isLoading || evaluated ? "text.disabled" : "text.primary"
                            }}/>
                        </IconButton>

                        <Button variant={"contained"}
                                color="success"
                                onClick={() => onFinalizeClick(onClickStartSave)}
                                disabled={isLoading || evaluated}
                        >
                            {tk('HeaderSaveBtn')}
                        </Button>

                        <ConfirmDialog
                            open={validationConfirmDialogOpen}
                            onClose={() => setValidationConfirmDialogOpen(false)}
                            onConfirm={() => onValidationDialogConfirm(onClickStartSave)}
                            dialogTitle={validationConfirmDialogTitle}
                            dialogText={validationConfirmDialogMessage}
                        />
                    </>
                )}
            />
        </Stack>
    )
}

export default WorksheetEditorHeaderAction;