import React, {useEffect} from 'react';
import {atom, useAtom} from "jotai";
import {appSidebarOpenAtom} from "./Navbar";
import crowbarLogo from "./web128_crowbar_logo.jpg"
import {Divider, Drawer, IconButton, ListItemIcon, ListItemText, MenuItem, Stack, styled} from "@mui/material";
import ClassIcon from '@mui/icons-material/Class';
import StarIcon from '@mui/icons-material/Star';
import CloseIcon from '@mui/icons-material/Close';
import {useUserContext} from "shared/hook/UserContext";
import {useNavigate} from "react-router-dom";
import useTranslateFunction from "shared/language/useTranslateFunction";
import SettingsIcon from '@mui/icons-material/Settings';
import {useUserRoles} from "shared/hook/useUserRoles";
import ImageIcon from "shared/components/image-icon/ImageIcon";
import {WorksheetIconImages} from "modules/worksheet/editor/shared/component/images/WorksheetIconImages";

interface SidebarEntry {
    key: string
    translationKey: string
    icon: JSX.Element | undefined
    linkTo: string
}

const sidebarEntriesAtom = atom<SidebarEntry[]>([])

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));


const Sidebar = () => {
    const tk = useTranslateFunction(`Header.Sidebar`)

    const navigate = useNavigate();

    const [userId] = useUserContext()
    const roles = useUserRoles()

    const [sidebarEntries, setSidebarEntries] = useAtom(sidebarEntriesAtom)
    const [sidebarOpen, setSidebarOpen] = useAtom(appSidebarOpenAtom)

    useEffect(() => {
        const entries: SidebarEntry[] = [
            {
                key: 'MyWorksheets',
                translationKey: 'MyWorksheets',
                icon: <ClassIcon/>,
                linkTo: `/worksheet/mechanic/${userId}/worksheets`
            },
            {
                key: 'Equipments',
                translationKey: "Equipments",
                icon: <ImageIcon image={WorksheetIconImages.forkliftSmall}/>,
                linkTo: `/equipment`
            }
        ]

        if (roles.administrator) {
            entries.push({
                key: 'Admin',
                translationKey: 'Admin',
                icon: <SettingsIcon/>,
                linkTo: '/admin'
            })
        }

        setSidebarEntries(entries)
    }, [roles.administrator, setSidebarEntries, userId])

    const navigateTo = (link: string) => {
        navigate(link)
        setSidebarOpen(false)
    }

    return (
        <>
            <Drawer
                PaperProps={{
                    sx: {width: {xs: "100%", sm: "90%", md: 350}}
                }}
                anchor={'left'}
                open={sidebarOpen}
                onClose={() => setSidebarOpen(false)}
            >
                <DrawerHeader>
                    <Stack direction="row" spacing={2} sx={{alignItems: "center"}}
                           onClick={() => {
                               navigateTo("/")
                           }}
                    >
                        <div>
                            <a className="d-flex align-items-center mx-3 text-dark text-decoration-none">
                                <img src={crowbarLogo} alt="Crowbar" height="32"/>
                            </a>
                        </div>
                        <p>Crowbar</p>
                    </Stack>
                    <IconButton onClick={() => setSidebarOpen(false)}>
                        <CloseIcon/>
                    </IconButton>
                </DrawerHeader>
                <Divider/>

                {sidebarEntries.map(sidebarEntry => (
                    <MenuItem key={sidebarEntry.key} onClick={() => {
                        navigateTo(sidebarEntry.linkTo)
                    }}>
                        <ListItemIcon>
                            {sidebarEntry.icon ? sidebarEntry.icon : <StarIcon/>}
                        </ListItemIcon>
                        <ListItemText primary={tk(sidebarEntry.translationKey)}/>
                    </MenuItem>
                ))}
            </Drawer>
        </>
    )
}

export default Sidebar;