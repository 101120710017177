import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {CDSMNNewWorksheetEquipment, CDSMNNewWorksheetEquipmentSalesOrder} from "crowbar-api";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {useMaterialReactTableLocalization} from "i18n/material-table/useMaterialReactTableLocalization";
import MaterialReactTable, {MRT_ColumnDef} from "material-react-table";
import DateDisplay, {dateDisplayDefaultOptions} from "shared/components/date-display/DateDisplay";
import {Card, Grid, IconButton, LinearProgress} from "@mui/material";
import RenderIfVisible from "shared/render-if-visible/render-if-visible";
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import {
    useNewWorksheetFromSalesOrderAction
} from "modules/worksheet/editor/state/events/useNewWorksheetFromSalesOrderAction";
import {useOpenWorksheetEditor} from "modules/worksheet/editor/state/events/useOpenWorksheetEditor";
import {toastError, toastSuccess} from "shared/toast/DefaultToasts";
import {PaginationState} from "@tanstack/table-core";
import {useMountedState} from "shared/hook/useMountedState";

export interface NewWorksheetResultEquipmentTableProps {
    equipment: CDSMNNewWorksheetEquipment
    pageSize: number
}

const NewWorksheetResultEquipmentTable = ({equipment, pageSize}: NewWorksheetResultEquipmentTableProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.NewWorksheet`)

    const [pagination, setPagination] = useState<PaginationState>({pageSize: pageSize, pageIndex: 0})

    const mrt_locale = useMaterialReactTableLocalization()

    const newWorksheetFromSalesOrderAction = useNewWorksheetFromSalesOrderAction()
    const openWorksheetEditor = useOpenWorksheetEditor()

    const isMounted = useMountedState()

    // Allows to update the pageSize property from outside
    useEffect(() => {
        setPagination(prev => ({...prev, pageSize: pageSize}))
    }, [pageSize])

    const onNewWorksheetForSalesOrderRowClick = useCallback(async (soDocEntry: number) => {
        if (isNaN(soDocEntry)) {
            toastError('Missing sales order docEntry')
            return
        }
        const newWorksheet = await newWorksheetFromSalesOrderAction
            .mutateAsync({docEntry: soDocEntry})
        if (isMounted()) {
            openWorksheetEditor(newWorksheet.id)
        } else {
            toastSuccess(`${tk('WorksheetCreatedSilent')}${newWorksheet.partnerCardName}`)
        }
    }, [isMounted, newWorksheetFromSalesOrderAction, openWorksheetEditor, tk])

    const onRowClick = useCallback(async (o: CDSMNNewWorksheetEquipmentSalesOrder) => {
        if (!o.docEntry) return
        await onNewWorksheetForSalesOrderRowClick(o.docEntry)
    }, [onNewWorksheetForSalesOrderRowClick])

    const columns = useMemo<MRT_ColumnDef<CDSMNNewWorksheetEquipmentSalesOrder>[]>(() => [
        {
            accessorKey: 'docNum',
            header: tk('DocNum'),
            minSize: 20,
            size: 90,
            maxSize: 200,
        },
        {
            accessorKey: 'slpName',
            header: tk('SalesPerson'),
            minSize: 20,
            size: 90,
            maxSize: 200,
        },
        {
            accessorFn: (row) => (
                <DateDisplay dateStr={row.docDate ?? ""}
                             options={{
                                 ...dateDisplayDefaultOptions,
                                 hour: undefined,
                                 minute: undefined
                             }}
                />
            ),
            header: tk('Date'),
            minSize: 20,
            size: 100,
            maxSize: 200,
        },
        {
            accessorFn: (row) => `${row.openRowCount ?? 0}/${row.closedRowCount ?? 0}`,
            header: tk('OpenRowCount') + '/' + tk('ClosedRowCount'),
            size: 90,
            maxSize: 200
        },
        /*{
             accessorFn: (row) => row.closedRowCount ?? 0,
             header: tk('ClosedRowCount'),
             size: 70,
             maxSize: 200
         },*/
        {
            accessorFn: (row) => row,
            header: tk('NewWorksheet'),
            Cell: ({cell}) => (
                <IconButton
                    disabled={newWorksheetFromSalesOrderAction.isLoading}
                    onClick={() => onRowClick(cell.getValue<CDSMNNewWorksheetEquipmentSalesOrder>())}
                >
                    <ArrowCircleRightIcon/>
                </IconButton>
            ),
            size: 70,
            maxSize: 200
        }
    ], [newWorksheetFromSalesOrderAction.isLoading, onRowClick, tk])

    return (
        <Card variant="outlined" sx={{
            width: 1,
        }}>
            <Grid container spacing={0}>
                {newWorksheetFromSalesOrderAction.isLoading && (
                    <Grid item xs={12}>
                        <LinearProgress/>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <RenderIfVisible stayRendered={true}>
                        <MaterialReactTable
                            columns={columns}
                            data={equipment.salesOrders ?? []}
                            localization={mrt_locale}
                            initialState={{
                                density: 'compact'
                            }}
                            state={{
                                pagination
                            }}
                            onPaginationChange={setPagination}
                            rowNumberMode="original" //default
                            enableColumnResizing={true}
                            enableColumnFilters={false}
                            enableColumnActions={false}
                            enableFullScreenToggle={false}
                        />
                    </RenderIfVisible>
                </Grid>
            </Grid>
        </Card>
    )
}

export default NewWorksheetResultEquipmentTable;