import React from 'react';
import {CDAMWMServiceCallEvalModel, CDAMWMServiceCallModel} from "crowbar-api";
import {useSapSalesPersons} from "crowbar-api/hooks/sap/useSapSalesPersons";
import {LinearProgress, MenuItem, Select, SelectChangeEvent, Stack, Typography} from "@mui/material";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {
    useServiceCallEvalUpdateSlpCode
} from "crowbar-api/hooks/worksheet/evaluation/service-call/useServiceCallEvalUpdateSlpCode";

export type SlpCodeSelectProps = {
    sc: CDAMWMServiceCallModel
    scEval: CDAMWMServiceCallEvalModel
    disabled?: boolean
}
const SlpCodeSelect = ({
                           sc,
                           scEval,
                           disabled
                       }: SlpCodeSelectProps) => {
    const tk = useTranslateFunction("Admin.Worksheet")
    const salesPersonsQuery = useSapSalesPersons()
    const salesPersons = salesPersonsQuery.data ?? []

    const updateSlpCode = useServiceCallEvalUpdateSlpCode()

    const onChange = async (event: SelectChangeEvent) => {
        const rawValue = +event.target.value
        const newValue = isNaN(rawValue) ? undefined : rawValue

        await updateSlpCode.mutateAsync({
            evalId: scEval.id,
            originalId: scEval.serviceCallId,
            field: newValue
        })

        scEval.slpCode = newValue
    }

    return (
        <Stack direction="column" spacing={0.5}>
            <Typography>{tk('SapSalesPerson')} *</Typography>

            {salesPersonsQuery.isLoading ? (
                <LinearProgress/>
            ) : (
                <Select
                    size="small"
                    disabled={disabled || updateSlpCode.isLoading}
                    fullWidth
                    required
                    value={scEval?.slpCode?.toString() ?? ""}
                    onChange={onChange}
                    error={!scEval?.slpCode}
                >
                    {salesPersons.map(sp => (
                        <MenuItem key={sp.slpCode?.toString()} value={sp.slpCode?.toString()}>
                            {sp.slpName}
                        </MenuItem>
                    ))}
                </Select>
            )}
        </Stack>
    );
};

export default SlpCodeSelect;