export class WorksheetIconImages {
    public static factory = `${process.env.PUBLIC_URL}/images/worksheet/factory-24px.png`
    public static checklist = `${process.env.PUBLIC_URL}/images/worksheet/checklist-24px.png`
    public static forkliftSmall = `${process.env.PUBLIC_URL}/images/worksheet/forklift-24px.png`
    public static forkliftLarge = `${process.env.PUBLIC_URL}/images/worksheet/forklift-256px.png`
    public static gallery = `${process.env.PUBLIC_URL}/images/worksheet/gallery-bw-24px.png`
    public static text = `${process.env.PUBLIC_URL}/images/worksheet/text-format-24px.png`
    public static alarm = `${process.env.PUBLIC_URL}/images/worksheet/alarm-clock-24px.png`
    public static warning = `${process.env.PUBLIC_URL}/images/worksheet/warning-24px.png`
    public static client = `${process.env.PUBLIC_URL}/images/worksheet/client-24px.png`

    public static feedbackSmall = `${process.env.PUBLIC_URL}/images/worksheet/feedback-24px.png`
    public static feedbackColorSmall = `${process.env.PUBLIC_URL}/images/worksheet/feedback-color-24px.png`

    public static draftSmall = `${process.env.PUBLIC_URL}/images/worksheet/draft-24px.png`
    public static draftMedium = `${process.env.PUBLIC_URL}/images/worksheet/draft-64px.png`
    public static signSmall = `${process.env.PUBLIC_URL}/images/worksheet/sign-24px.png`
    public static signMedium = `${process.env.PUBLIC_URL}/images/worksheet/sign-64px.png`
    public static positiveVoteSmall = `${process.env.PUBLIC_URL}/images/worksheet/positive-vote-f9af18-24px.png`
    public static positiveVoteMedium = `${process.env.PUBLIC_URL}/images/worksheet/positive-vote-f9af18-64px.png`
    public static gavelSmall = `${process.env.PUBLIC_URL}/images/worksheet/gavel-24px.png`
    public static gavelMedium = `${process.env.PUBLIC_URL}/images/worksheet/gavel-64px.png`

    public static frekventForkliftSmall = `${process.env.PUBLIC_URL}/images/worksheet/frekvent-forklift-24px.png`
    public static frekventForkliftLarge = `${process.env.PUBLIC_URL}/images/worksheet/frekvent-forklift-256px.png`

    public static clipboardSmall = `${process.env.PUBLIC_URL}/images/worksheet/clipboard-24px.png`
    public static clipboardLarge = `${process.env.PUBLIC_URL}/images/worksheet/clipboard-256px.png`

    public static pdfBlack = `${process.env.PUBLIC_URL}/images/worksheet/pdf-file-black-64px.png`
    public static pdfColor = `${process.env.PUBLIC_URL}/images/worksheet/pdf-file-color-64px.png`

    public static sadNotFoundSmall = `${process.env.PUBLIC_URL}/images/worksheet/sad-not-found-search-24px.png`
    public static sadNotFoundMedium = `${process.env.PUBLIC_URL}/images/worksheet/sad-not-found-search-64px.png`
    public static sadNotFoundLarge = `${process.env.PUBLIC_URL}/images/worksheet/sad-not-found-search-512px.png`
}