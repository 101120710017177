import {Box, Stack, TextField} from "@mui/material";
import PsgVehicleTypeSelect from "modules/worksheet/editor/shared/component/PsgVehicleTypeSelect";
import {PartnerEquipmentFormResult} from "./usePartnerEquipmentForm";
import useTranslateFunction from "shared/language/useTranslateFunction";


export interface PartnerEquipmentFormProps {
    mode: 'new' | 'edit'
    form: PartnerEquipmentFormResult

    defaultManufacturerCode?: string
    defaultVehicleTypeName?: string
}


const PartnerEquipmentForm = ({
                                  mode,
                                  form,
                                  defaultManufacturerCode,
                                  defaultVehicleTypeName
                              }: PartnerEquipmentFormProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    const {
        watch,
        formState: {errors},
        manufacturerRegister,
        vehicleTypeRegister,
        manufacturerSnRegister,
        internalSnRegister,
        yoMRegister,
        commentRegister,
        setValue,
        control
    } = form

    return (
        <Box sx={{
            width: 1
        }}>
            <Stack direction="column" spacing={1}>

                <PsgVehicleTypeSelect
                    defaultManufacturerCode={defaultManufacturerCode ?? ""}
                    defaultVehicleTypeName={defaultVehicleTypeName ?? ""}
                    manufacturerRegister={manufacturerRegister}
                    manufacturerError={errors.manufacturer}
                    manufacturerWatch={watch("manufacturer")}
                    vehicleTypeRegister={vehicleTypeRegister}
                    vehicleTypeWatch={watch("vehicleType")}
                    vehicleTypeError={errors.vehicleType}
                    setValue={setValue}
                    control={control}
                />

                <TextField
                    inputRef={manufacturerSnRegister.ref}
                    error={!!errors?.manufacturerSn?.message}
                    helperText={errors?.manufacturerSn?.message}
                    fullWidth
                    InputLabelProps={{shrink: true}}
                    InputProps={{
                        autoComplete: "off"
                    }}
                    label={tk('ManufacturerSn')}
                    {...manufacturerSnRegister}
                />

                <TextField
                    inputRef={internalSnRegister.ref}
                    error={!!errors?.internalSn?.message}
                    helperText={errors?.internalSn?.message}
                    fullWidth
                    InputLabelProps={{shrink: true}}
                    label={tk('InternalSn')}
                    {...internalSnRegister}
                />

                <TextField
                    inputRef={yoMRegister.ref}
                    error={!!errors?.yoM?.message}
                    helperText={errors?.yoM?.message}
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                        inputMode: 'numeric'
                    }}
                    type="number"
                    label={tk('YearOfManufacture')}
                    {...yoMRegister}
                />

                <TextField
                    inputRef={commentRegister.ref}
                    error={!!errors.comment?.message}
                    helperText={errors.comment?.message}
                    fullWidth
                    multiline={true}
                    rows={3}
                    InputLabelProps={{shrink: true}}
                    label={tk('Comment')}
                    {...commentRegister}
                />
            </Stack>

        </Box>
    )
}

export default PartnerEquipmentForm