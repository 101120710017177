export const i18n_adminEn = {
    "EquipmentImages": {
        "Title": "Equipment images",
        "Loading": "Loading...",
        "Manufacturer": "Manufacturer",
        "Set": "Set",
        "ColManufacturerName": "Manufacturer",
        "ColTypeName": "Type",
        "ColImage": "Image",
        "SetSuccessMessage": "Image successfully set for the selected equipments."
    },
    "ProductImages": {
        "Title": "Product images",
        "SearchPlaceholder": "Search products...",
        "Set": "Set",
        "ColItemCode": "ItemCode",
        "ColItemName": "Name",
        "ColImage": "Image",
        "LoadMoreStart": "Load more",
        "LoadMoreProgress": "Loading more...",
        "OnlyFrequentlyUsedLabel": "Only frequently used products?",
        "OnlyFrequentlyUsedOn": "Yes",
        "OnlyFrequentlyUsedOff": "No",
        "NoResult": "No result...",
        "SetSuccessMessage": "Image successfully set for the selected items."
    },
    "Mechanic": {
        "Title": "Mechanics",
        "Mechanic": "Mechanic",
        "Remove": "Remove",
        "Create": "Created at",
        "Update": "Updated at",
        "Add": "Add",
        "Active": "Active?",
        "Yes": "Yes",
        "No": "No",
        "Edit": "Edit",
        "Cancel": "Cancel",
        "SelectIds": "Specify Azure user and SAP user",
        "SapEmployee": "Sap employee",
        "AzureUser": "Azure user",
        "AzureSelectDesc": "Only users that are assigned to the 'Mechanic' app role will be displayed! (Azure AD -> Enterprise applications -> Users and groups).",
        "Save": "Save",
        "Delete": "Delete",
    },
    "PhotoRule": {
        "PhotoRules": "Rules of photography",
        "Add": "Add",
        "RuleTitle": "Rule Title",
        "CreatedAt": "Created",
        "UpdatedAt": "Updated",
        "Manufacturer": "Manufacturer",
        "ManufacturerAlreadyExists": "Manufacturer already added to rule.",
        "NoSelectedManufacturer": "No manufacturer selected.",
        "DeleteManufacturer": "Delete manufacturer assignment.",
        "AddStep": "Add a step",
        "Template": "Template",
        "Templates": "Templates",
        "EditTemplates": "Edit templates",
        "Title": "Title",
        "Description": "Description",
        "Delete": "Delete",
        "SaveAsTemplate": "Save as template",
        "LoadTemplate": "Load template",
        "Override": "Overwrite template?",
        "Ok": "Ok",
        "Cancel": "Cancel",
        "Save": "Save",
        "NameOfTemplate": "Name of template",
        "StepDeleted": "Step deleted.",
        "RuleDelete": "Delete rule",
        "RuleDeleted": "Rule deleted.",
        "AddNew": "Add new",
        "ReplaceExisting": "Replace existing",
        "ReplaceDescription": "Overwrites an existing template with the current values.",
        "NewDescription": "Saves the current values as a new template.",
        "TemplateInUse": "The template is in use. Deleting it could cause unexpected results.",
        "TemplateDeleted": "Template deleted.",
        "TemplateEdit": "Edit template",
        "TemplateSaved": "Template is saved.",
        "TemplateSaveFailed": "Template is failed to save.",
        "DeleteTemplate": "Delete template",
        "NewTemplate": "New template",
        "NoManufacturersAdded": "No manufacturer added!",
    },
    "Worksheet": {
        "WorksheetAdmin": "Manage worksheets",
        "Mechanics": "Mechanics",
        "LoadMore": "Load more",
        "CustomerVersion": "Customer version",
        "AdminVersion": "Admin version",
        "ToEvaluated": "Change to evaluated",
        "ToEvaluatedTitle": "Change worksheet status",
        "ToEvaluatedText": "Are you sure you want to change the status of the worksheet to evaluated?",
        "CancelEvaluated": "Change to not evaluated",
        "CancelEvaluatedTitle": "Change worksheet status",
        "CancelEvaluatedText": "Are you sure you want to change the status of the worksheet to completed? (With or without signature depends on the current stored signature)",
        "ShowDeleted": "Show deleted",
        "DeletedWorksheet": "Deleted worksheet!",
        "Delete": "Delete",
        "Undelete": "Undelete",
        "ChangeStatus": "Change status",
        "Cancel": "Cancel",
        "Save": "Save",
        "Status": "Status",
        "StateChangeWarn": "Changing the status of a worksheet manually can also result in an invalid worksheet! (In such a case, there are no enforced checks that are required when writing the worksheet. For example, to have all rows checked or to have a task description.)",
        "StateChangeWarn2": "The worksheet being edited by the mechanic can overwrite this change according to his or her save.",
        "EmailNotifications": "E-mail notifications",
        "DateFilter": "Start and end of the creation of a worksheet",
        "LoadingNotifications": "Loading notifications",
        "RowNum": "#",
        "Source": "Source type",
        "Worksheet": "Worksheet",
        "CreatedAt": "Created at",
        "EmailCreatedAt": "Email created at",
        "Agent": "Agent",
        "Customer": "Customer",
        "Missing": "Missing data",
        "Resend": "Resend",
        "RevokeResend": "Revoke resend",
        "MarkedAsResend": "Marked as resend.",
        "ResendRevoked": "Resend revoked.",
        "Name": "Name",
        "CustomerNotificationConfiguration": "Customer notification configuration",
        "Yes": "Yes",
        "No": "No",
        "Edit": "Edit",
        "AddNew": "Add new",
        "PressEnterToAdd": "Press enter to add new e-mail address to the list.",
        "Employee": "Employee",
        "NotifyOnFinishedWorksheet": "Notification",
        "NotifyOnFinishedWorksheetEx": "Send notification of completed worksheets.",
        "NotifyOnFinishedWorksheetWithFaults": "Only with faults?",
        "NotifyOnFinishedWorksheetWithFaultsEx": "Send notification of worksheets with fault description.",
        "OnlyNotifyIfCustomerSalesPerson": "Only if sales person?",
        "OnlyNotifyIfCustomerSalesPersonEx": "Only notify if the employee is the customer's registered salesperson.",
        "AgentNotificationConfiguration": "Agent e-mail notification configurations",
        "EvaluateButton": "Switch to evaluated",
        "CustomerCode": "Card code",
        "CustomerName": "Card name",
        "SalesOrder": "Sales order",
        "SalesOrderDate": "Sales order date",
        "TripDistanceKm": "Worksheet trip",
        "WorksheetDate": "Worksheet date",
        "StartDate": "Start date",
        "EndDate": "End date",
        "LastStatusChange": "Last status change",
        "SubstitutesTaskDescription": "Substitutes description?",
        "New": "New",
        "Ok": "Ok",
        "CallTypeDialogDescription": "These service call types can be selected when writing a worksheet. SAP call type matching will be selected on admin, but not required.",
        "CallType": "Call type",
        "NameDescription": "Displayed name. (May differ from SAP name).",
        "SubstitutesTaskDescriptionEx": "(If yes, you are not required to provide a task description when writing a worksheet.)",
        "VisOrder": "Order",
        "MasterData": "Master data",
        "ServiceCalls": "Service calls",
        "History": "History",
        "Comment": "Comment",
        "Feedback": "Feedback",
        "RefreshSAPData": "Refresh SAP data",
        "Partner": "Partner",
        "Contact": "Contact",
        "Address": "Address",
        "TaskAddress": "Place of work",
        "NewPartnerOnWorksheet": "New partner on the worksheet",
        "EditedPartnerOnWorksheet": "Modified partner on the worksheet",
        "NewPartnerAddressOnWorksheet": "New address on the worksheet",
        "EditedPartnerAddressOnWorksheet": "Modified address on the worksheet",
        "NewPartnerContactOnWorksheet": "New contact on the worksheet",
        "EditedPartnerContactOnWorksheet": "Modified contact on the worksheet",
        "PleaseChoose": "Please choose",
        "Type": "Type",
        "Equipment": "Equipment",
        "NewEquipment": "New equipment",
        "EditedEquipment": "Edited equipment",
        "Subject": "Subject",
        "Description": "Description",
        "ManufacturerSn": "Serial",
        "Images": "Images",
        "Delivery": "Delivery",
        "ServiceCall": "Service call",
        "Select": "Select",
        "Change": "Change",
        "EquipmentChangeConfirmText": "Are you sure you want to replace the equipment on the worksheet with the one you currently have? (All machine details will be overwritten on the worksheet).",
        "ReplaceEquipment": "Replace equipment",
        "ManufacturerAndVehicleType": "Manufacturer, type",
        "InternalSn": "Internal sn",
        "YoM": "YoM",
        "TaskDescription": "Task description",
        "FaultDescription": "Fault description",
        "RequiredToReplaceModEquipment": "Replacement of a manually added equipment is mandatory!",
        "SapServiceCall": "SAP service call",
        "Catalogue": "Catalogue",
        "SapCallType": "Call type",
        "SapTechnician": "Technician",
        "CurrentOperatingHours": "Operating hours",
        "Dim1": "Business",
        "Dim2": "Person",
        "Dim3": "Lead",
        "Overwrite": "Overwrite",
        "Open": "Open",
        "Closed": "Closed",
        "SalesOrderQuantity": "Sales qty",
        "InstalledQuantity": "Installed qty",
        "DeliveryQuantity": "Quantity of delivery",
        "UnitOfQuantity": "UoQ",
        "RowAck": "",
        "ServiceCallLong": "Service call",
        "LinkToSalesOrderDescription": "Attach to purchase order (use the purchase order as the source when creating a delivery).",
        "CreateNewDraft": "Create draft",
        "CreateNewDelivery": "Create delivery",
        "DraftNumber": "Draft number",
        "LinkToDeliveryDescription": "Attach to delivery (use the delivery created in the delivery section as a linked document when creating a service call).",
        "CreateNew": "Create",
        "DeliveryAsDraft": "Delivery as draft",
        "ManuallySetDeliveryDocEntry": "Enter a service call manually.",
        "FindingDraft": "Searching for draft...",
        "FindingDelivery": "Searching for delivery...",
        "FindingServiceCall": "Searching for service call...",
        "InvalidDeliveryNumber": "The delivery number is invalid. Only existing deliveries can be entered (may take a little while to be available (< 1 minute) compared to SAP addition).",
        "AckAndRequiredToReplaceModEquipment": "Accepting a selected equipment and replacing a manually added equipment is mandatory!",
        "DeliveryMissingError": "When linked to a delivery, it is obligatory to provide and accept the delivery number (you can enter it manually as well).",
        "RowAckRequired": "Accepting all lines is mandatory to create a shipment.",
        "SapCallTypeIsRequired": "Specifying the SAP call type is mandatory.",
        "TechnicianIsRequired": "Specifying a technician is mandatory.",
        "ShippingAddress": "Shipping address",
        "BillingAddress": "Billing address",
        "BillingAddressIsRequired": "Selecting a billing address is mandatory!",
        "PartnerAckRequired": "Accepting partner master data is mandatory!",
        "AddressAckRequired": "Accepting partner address master data is mandatory!",
        "OnWorksheet": "Worksheet:",
        "SapSalesPerson": "Sales person",
        "OriginalInstalledQuantity": "Original worksheet qty",
        "TripWarnTitle": "Missing delivery charge",
        "TripWarnText": "The delivery charge on the worksheet has not been assigned to a item row. Do you want to continue?",
        "TripDistanceOnRows": "Trip on rows",
        "FixesWorksheetDataIconTitle": "Automatically replaces the data entered on the worksheet, so that it appears correctly in both the printed version and the equipment history.",
        "TripDistanceWarn": "The worksheet has {{tripSumOnWorksheet}} km trip cost, but the rows have been assigned {{tripSumOnRows}} km!",
        "ItemCode": "Itemcode",
        "Add": "Add",
        "AdminCommentLabel": "My comment to the worksheet:",
        "What?": "What?",
        "WorksheetOriginal": "Original worksheet value",
        "CurrentValue": "Current value",
        "EquipmentChanged": "The equipment on the worksheet has been replaced!",
        "AddedDuringEval": "Added by admin!",
        "WorksheetFields": "Worksheet fields",
        "CallTypeIdChanged": "Call type modified!",
        "StartedAt": "Start date",
        "EndedAt": "Completion day",
        "TaskDescriptionChanged": "Task description modified!",
        "FaultDescriptionChanged": "Fault description modified!",
        "CommentChanged": "Megjegyzés módosítva!",
        "CurrentEquipmentOperatingHoursChanged": "Hours of operation modified!",
        "MissingSignature": "Missing signature",
        "Signature": "Signature",
        "PartnerAckBeforeEquipmentAck": "Partner approval is mandatory before approving equipment.",
        "Evaluation": "Evaluate",
        "SapOwnerCode": "Owner",
        "ExtraOptions": "Extra options",
        "SkipCustomerEmail": "Do not send an email notification to the client about this worksheet (the notification's state will be set to skipped after the worksheet is set to evaluated).",
        "ResetFilterByMechanicTitle": "Reset to default",
        "ClearFilter": "Empty the filter (delete all the selected mechanics).",
        "InvalidEquipmentForPartner": "Invalid equipment. Not currently found at the partner (maybe there was a partner change?).",
        "OverrideEmailNotificationAddresses": "Send e-mail and overwrite default customer notification email recipient(s).",
        "EmailRecipient": "Notification e-mail",
        "LinkEquipmentToServiceCall": "Assign the selected equipment to the service call.",
        "NoImagesFound": "No images found.",
    },
    "EmailNotificationStatus": {
        "Success": "Sikeres",
        "Failure": "Sikertelen",
        "Skipped": "Kihagyott",
    }
}