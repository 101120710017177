import AzureBlobStorageUrlFactory from "crowbar-api/blob-storage/AzureBlobStorageUrlFactory";
import {useRef} from "react";

export const useAzureBlobStorageUrlFactory = (): AzureBlobStorageUrlFactory => {

    let domain = "crowbarteamsdev"
    if (process.env.NODE_ENV === 'production') {
        domain = "crowbarteams"
    }
    if (process.env.REACT_APP_ENV === 'staging') {
        domain = "crowbarteamsdev"
    }

    const ref = useRef<AzureBlobStorageUrlFactory>(
        new AzureBlobStorageUrlFactory(domain)
    )
    return ref.current
}