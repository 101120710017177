import {azureAdConfig, msalConfig} from "./MsalConfig";
import {
    AccountInfo,
    AuthenticationResult,
    PopupRequest,
    PublicClientApplication,
    SilentRequest
} from "@azure/msal-browser";
import {atom} from "jotai";

export const msalManualAuthenticationHappenedAtom = atom<boolean>(false)

// todo: msal react? https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/errors.md#interaction_in_progress

/**
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/acquire-token.md
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/caching.md
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/token-lifetimes.md#token-renewal
 */
export class MsalAuth {
    private userNameKey = 'ct-username-msal';
    private _msalObj?: PublicClientApplication;

    public initialize() {
        this.setNewMsalObj()
    }

    private setNewMsalObj = () => {
        this._msalObj = new PublicClientApplication(msalConfig);
    }

    public publicClientApplication(): PublicClientApplication {
        if (!this._msalObj) {
            throw new Error('MSAL not initialized!');
        }
        return this._msalObj;
    }

    public isLoggedIn(): boolean {
        return (this.getUserName() !== undefined);
    }

    public getUserName(): string | undefined {
        return localStorage.getItem(this.userNameKey) ?? undefined;
    }

    public setUserName(userName: string | undefined) {
        if (!userName) {
            this.logout()
            return
        }
        localStorage.setItem(this.userNameKey, userName);
    }

    public logout = async (): Promise<void> => {
        // this._msalObj?.logout()
        await this._msalObj?.logoutPopup()
        localStorage.removeItem(this.userNameKey)
        this.setNewMsalObj()
    }

    public getCurrentUserAccount = (): AccountInfo | undefined => {
        const userName = this.getUserName()
        if (!userName) return undefined
        return this._msalObj?.getAccountByUsername(userName) ?? undefined
    }

    public getUserClaims = (): string[] => {
        const idTokenClaims = this.getCurrentUserAccount()?.idTokenClaims
        if (!idTokenClaims) return []
        const idTokenClaimsObj = idTokenClaims as { roles: string[] }
        return idTokenClaimsObj.roles ?? []
    }

    public getUserId = (): string | undefined => {
        return this.getCurrentUserAccount()?.localAccountId
    }

    /*public getSid(): string |  undefined {
        return localStorage.getItem(this.sidKey) ?? undefined;
    }

    public setSid(sid: string | undefined): void {
        if (!sid) {
            localStorage.removeItem(this.sidKey);
            return;
        }
        localStorage.setItem(this.sidKey, sid);
    }*/

    public getScopes(): string[] {
        const prefix = 'api://';
        const clientId = msalConfig.auth.clientId;
        const result = [];
        for (let msalScope of azureAdConfig.msalScopes) {
            result.push(
                `${prefix}${azureAdConfig.teamsDomain}/${clientId}/${msalScope}`
            );
        }
        return result;
    }

    public async getTokenSilent(): Promise<string | undefined> {
        if (!this._msalObj) {
            throw new Error('Msal not initialized.');
        }

        // Check if not logged in and try popup login if not
        if (this._msalObj.getAllAccounts().length < 1) {
            await this.manualLogin()
            return await this._silentTokenRequest()
        } else {
            try {
                return await this._silentTokenRequest();
            } catch (e: any) {
                console.error(e)
                await this.logout() // todo: handle logout here? this fails for now for https://learn.microsoft.com/en-us/answers/questions/38202/interactionrequiredautherror-aadsts50058-a-silent.html
                await this.manualLogin()
                return await this._silentTokenRequest()
            }
        }
    }

    private _silentTokenRequest(): Promise<string | undefined> {
        if (!this._msalObj) {
            throw new Error('Msal not initialized.');
        }

        const tokenRequest: SilentRequest = {
            scopes: this.getScopes(),
            redirectUri: `${window.location.origin}`,
            account: this._msalObj.getAllAccounts()[0] ?? undefined
        };

        if (this.getUserName() && tokenRequest?.account) {
            // tokenRequest.loginHint = this.getUserName();
            tokenRequest.account.username = this.getUserName() ?? '';
        }

        const silentTokenRequest = this._msalObj.acquireTokenSilent(tokenRequest).then((tokenResponse: AuthenticationResult) => {
            if (!tokenResponse?.accessToken) {
                console.error('Login failed. Response:' + JSON.stringify(tokenResponse));
                return undefined;
            }

            // save sid
            this.setUserName(tokenResponse?.account?.username);

            // Callback code here
            // console.log('Msal.js returned token: '+tokenResponse?.accessToken);
            return tokenResponse?.accessToken;
        })

        return silentTokenRequest.catch(async (error) => {
            await this.logout()
            await this.manualLogin()
            return silentTokenRequest
        });
    }

    public manualLogin(): Promise<AuthenticationResult | any> {
        if (!this._msalObj) {
            throw new Error('Msal not initialized.');
        }

        const tokenRequest: PopupRequest = {
            scopes: this.getScopes(),
            redirectUri: `${window.location.origin}`
        };

        return this._msalObj.loginPopup(tokenRequest).then((tokenResponse: AuthenticationResult) => {
            if (!tokenResponse?.idToken) {
                console.error('Login failed. Response:' + JSON.stringify(tokenResponse));
                return tokenResponse
            }

            // save sid
            this.setUserName(tokenResponse?.account?.username);

            return tokenResponse;
        }).catch((error: any) => {
            console.error(error);
            return error;
        });
    }
}

const msalAuth = new MsalAuth();
export default msalAuth;