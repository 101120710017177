import {UseNewWorksheetFromProps} from "modules/worksheet/editor/state/events/UseNewWorksheetFromProps";
import {useUserContext} from "shared/hook/UserContext";
import {useMutation} from "@tanstack/react-query";
import {CDAMWMWWorksheetSave, SapPartnerEquipmentApi} from "crowbar-api";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {WorksheetSaveFactory} from "modules/worksheet/storage/WorksheetSaveFactory";
import {useSaveNewWorksheetViaApi} from "modules/worksheet/editor/state/events/useSaveNewWorksheetViaApi";

export interface UseNewWorksheetFromEquipmentActionProp extends UseNewWorksheetFromProps {
    sapEquipmentId: number
}

export const useNewWorksheetFromEquipmentAction = () => {
    const [userId] = useUserContext()
    const saveNewWorksheet = useSaveNewWorksheetViaApi()

    return useMutation(async (
        {sapEquipmentId, reloadWorksheetObjectAfterSave}: UseNewWorksheetFromEquipmentActionProp
    ): Promise<CDAMWMWWorksheetSave> => {
        const equipmentQueryResponse = await crowbarApiFactory(SapPartnerEquipmentApi)
            .findByInsIdForWorksheet(sapEquipmentId)
        if (equipmentQueryResponse.status !== 200) {
            throw new Error(
                'The equipment query required for creating ' +
                'a new worksheet did not return OK state. ' +
                'Status: ' + equipmentQueryResponse.status
            )
        }
        const equipment = equipmentQueryResponse.data
        if (!equipment?.sapEquipmentId) {
            throw new Error('Equipment not found')
        }

        // Create new WorksheetSave from the equipment
        if (!userId) {
            throw new Error('UserIdNullError')
        }

        const newWorksheet: CDAMWMWWorksheetSave = WorksheetSaveFactory
            .newWorksheetSaveFromEquipment(equipment, userId)
        return await saveNewWorksheet.mutateAsync({newWorksheet, reloadWorksheetObjectAfterSave})
    })
}