import React from 'react';
import {WithTranslation, withTranslation} from "react-i18next";
import {Route, Routes} from "react-router-dom";
import NotFound from "shared/components/not-found/NotFound";
import "./DeliveryNotes.css"
import LicensePlate from "modules/delivery-note/license-plate/LicensePlate";
import Template from "modules/delivery-note/template/Template";
import DeliveryNote from "modules/delivery-note/delivery-note/DeliveryNote";
import {Container} from "@mui/material";

interface DeliveryNotesProps extends WithTranslation {

}

interface DeliveryNotesState {

}

class DeliveryNotes extends React.Component<DeliveryNotesProps, DeliveryNotesState> {

    constructor(props: Readonly<DeliveryNotesProps> | DeliveryNotesProps) {
        super(props);

        this.state = {}
    }

    render() {
        return (
            <Container>
                <Routes>
                    <Route path="license-plate" element={<LicensePlate/>}/>
                    <Route path="template" element={<Template/>}/>
                    <Route path="" element={<DeliveryNote/>}/>
                    <Route element={<NotFound/>}/>
                </Routes>
            </Container>
        );
    };
}

export default withTranslation()(DeliveryNotes);