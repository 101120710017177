import {useMutation} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {CDAMWMWServiceCallSave, ServiceCallApi} from "crowbar-api";


const useDirectServiceCallSave = () => {
    return useMutation(async (scSave: CDAMWMWServiceCallSave) => {
        await crowbarApiFactory(ServiceCallApi).saveServiceCallDirectly(scSave)
    })
}

export default useDirectServiceCallSave