export default class AsyncInterval {
    private readonly _asyncFn: () => Promise<any>
    private readonly _delayMs: number
    private _isRunning: boolean = false

    public constructor(asyncFn: () => Promise<any>, delayMs: number) {
        this._asyncFn = asyncFn
        this._delayMs = delayMs
    }

    private cycle = async () => {
        await this._asyncFn();
        await this.delay(this._delayMs);
        if (this._isRunning) {
            await this.cycle();
        }
    }

    public start = async () => {
        if (this._isRunning) return;
        this._isRunning = true;
        await this.cycle();
    }

    public stop = () => {
        if (this._isRunning) this._isRunning = false
    }

    private delay = (ms: number) => {
        return new Promise(res =>
            setTimeout(() => {
                res(1)
            }, ms)
        )
    }
}