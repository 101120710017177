import React from 'react';
import {CDAMDMDeliveryNoteModel, DeliveryNoteApi} from "crowbar-api";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack} from "@mui/material";
import axios, {AxiosRequestConfig, CancelTokenSource} from "axios";
import {Form, Formik, FormikProps} from 'formik';
import {withTranslation, WithTranslation} from "react-i18next";
import {deliveryNoteDialogEmptyFields, IDeliveryNoteDialogFields} from "./IDeliveryNoteDialogFields";
import DeliveryNoteDialogHeadForm from "./DeliveryNoteDialogHeadForm";
import DeliveryNoteDialogIssuerForm from "./DeliveryNoteDialogIssuerForm";
import DeliveryNoteDialogDeliveryForm from "./DeliveryNoteDialogDeliveryForm";
import CrowbarConstants from "crowbar-api/CrowbarConstants";
import {DeliveryNoteValidation} from "./DeliveryNoteValidation";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {toastError, toastInfo, toastRequestError} from "shared/toast/DefaultToasts";
import {v4 as uuid} from 'uuid'
import AlertDialog from "shared/components/alert-dialog/AlertDialog";
import {TFunction} from "i18next";
import DeliveryNoteDialogSupplierForm from './DeliveryNoteDialogSupplierForm';
import DeliveryNoteDialogCustomerForm from "./DeliveryNoteDialogCustomerForm";
import DeliveryNoteDialogRowsForm from './DeliveryNoteDialogRowsForm';
import {DateUtils} from "shared/utils/DateUtils";

interface DeliveryNoteDialogProps extends WithTranslation {
    open: boolean
    mode: 'new' | 'edit'
    editItem?: CDAMDMDeliveryNoteModel
    onNewDialogOpen: () => void
    onDialogClose: () => void
    onCancel: () => void
    //onDelete: () => void
    onSave: () => void
}

interface DeliveryNoteDialogState {
    dialogOpen: boolean
    initialFormValues: IDeliveryNoteDialogFields

    supplierCardCode?: string
    customerCardCode?: string
    formIsSaving: boolean

    cancelConfirmOpen: boolean
    deleteConfirmOpen: boolean
    deleteParameter?: any
}

const TKey = "DeliveryNote.Note.Dialog.";

class DeliveryNoteDialog extends React.Component<DeliveryNoteDialogProps, DeliveryNoteDialogState> {

    private cancelTokenSource: CancelTokenSource;
    private axiosOptions: AxiosRequestConfig
    // private supplierFormRef: any;
    private readonly _deliveryNoteApi: DeliveryNoteApi;

    constructor(props: Readonly<DeliveryNoteDialogProps> | DeliveryNoteDialogProps) {
        super(props);

        this.cancelTokenSource = axios.CancelToken.source()
        this.axiosOptions = {
            cancelToken: this.cancelTokenSource.token
        }

        this._deliveryNoteApi = crowbarApiFactory(DeliveryNoteApi);

        this.state = {
            dialogOpen: false,
            initialFormValues: deliveryNoteDialogEmptyFields,

            supplierCardCode: CrowbarConstants.PhoenixForkliftCardCode,
            formIsSaving: false,

            cancelConfirmOpen: false,
            deleteConfirmOpen: false
        }
    }

    componentDidMount() {
        this.cancelTokenSource = axios.CancelToken.source()
        this.axiosOptions = {
            cancelToken: this.cancelTokenSource.token
        }
    }

    componentWillUnmount() {
        this.cancelTokenSource.cancel();
    }

    componentDidUpdate(prevProps: Readonly<DeliveryNoteDialogProps>, prevState: Readonly<DeliveryNoteDialogState>, snapshot?: any) {
        if (this.props.open !== this.state.dialogOpen) {
            this.setState({
                dialogOpen: this.props.open
            }, () => {
                // Patch form values from editItem when editing
                if (this.props.mode === "edit") {
                    this._patchForm()
                }
            })
        }
    }

    _onClickNewBtn = () => {
        this.props.onNewDialogOpen()
    }

    _onCancelClick = (fProps: FormikProps<IDeliveryNoteDialogFields>) => {
        if (fProps.dirty) {
            this.setState({cancelConfirmOpen: true})
        } else {
            this._closeDialogAndCallParentCancel()
        }
    }

    _closeDialogAndCallParentCancel = () => {
        this.props.onDialogClose()
        this.props.onCancel()
    }

    _patchForm = () => {
        const e = this.props.editItem;
        console.log(e)
        if (!e) return
        this.setState((p) => {
            const pi = p.initialFormValues;
            const res: IDeliveryNoteDialogFields = {
                ...pi,
                type: e.type?.toString() ?? pi.type,
                leaseState: e.leaseStatus?.toString() ?? pi.leaseState,
                // datedAt: moment(e.datedAt).toDate() ?? pi.datedAt ?? new Date(),
                datedAt: e.datedAt ? e.datedAt : DateUtils.newServerUTCDate(),
                supplierCardCode: e.supplierCardCode ?? pi.supplierCardCode,
                supplierCardName: e.supplierCardName ?? pi.supplierCardName,
                supplierAddress: e.supplierAddress ?? pi.supplierAddress,
                issuerEmployeeId: e.issuerEmployeeId ?? pi.issuerEmployeeId,
                issuerContactPersonCode: e.issuerContactPersonCode ?? pi.issuerContactPersonCode,
                customerCardCode: e.customerCardCode ?? pi.customerCardCode,
                customerCardName: e.customerCardName ?? pi.customerCardName,
                customerBillingAddress: e.customerBillingAddress ?? pi.customerBillingAddress,
                customerShippingAddress: e.customerShippingAddress ?? pi.customerShippingAddress,
                orderNumber: e.orderNumber ?? pi.orderNumber,
                isDeleted: e.isDeleted ?? pi.isDeleted,
                rows: !e.rows ? [] : e.rows.map(row => {
                    return {
                        uuid: uuid(),
                        description: row.description ?? '',
                        quantity: row.quantity ?? 0,
                        unitOfQuantity: row.unitOfQuantity ?? '',
                        source: row.sourceType?.valueOf() ?? 0,
                        opHours: row.operatingHours ?? undefined
                    }
                })
            }
            return {
                initialFormValues: res,
                customerCardCode: res.customerCardCode
            }
        })
    }

    _onSaveClick = (fProps: FormikProps<IDeliveryNoteDialogFields>) => {
        const v = new DeliveryNoteValidation(this.props.t, fProps);
        if (v.validate()) {
            // Send
            const dnm = v.prepareObject(this.props.mode, this.props.editItem);
            if (!dnm) {
                toastError('Failed to map form to DeliveryNoteModel.')
                return
            }
            this.setState({
                formIsSaving: true
            }, () => {
                this._submitForm(dnm).then(updated => {
                    // Close form
                    fProps.resetForm({values: deliveryNoteDialogEmptyFields})
                    console.log(fProps)
                    this.setState({
                        // dialogOpen: false,
                        formIsSaving: false
                    }, () => {
                        this.props.onSave()
                    })
                })
            })
        }
    }

    _submitForm = (dnm: CDAMDMDeliveryNoteModel): Promise<CDAMDMDeliveryNoteModel | undefined> => {
        return this._deliveryNoteApi.save(dnm, this.axiosOptions).then(response => {
            if (response.status === 200) {
                return response.data
            } else {
                toastRequestError(this.props.t, {
                    name: "DeliveryNoteSubmit",
                    response: response,
                    reason: "Http status not OK"
                })
                return undefined
            }
        })
    }

    _onDeleteClick = (fProps: FormikProps<IDeliveryNoteDialogFields>, t: TFunction) => {
        const isDeleteAction = !fProps.values.isDeleted; // Already deleted means un-delete
        if (isDeleteAction) {
            // show confirm dialog
            // set delete state
            this.setState((prevState) => {
                return {
                    deleteConfirmOpen: true,
                    deleteParameter: fProps
                }
            })
        } else {
            // undelete
            // just set isDeleted state and show toaster
            fProps.setFieldValue('isDeleted', false)
            toastInfo(t(`${TKey}UndeleteSuccess`))
        }
    }

    _deleteAndSave = (fProps: FormikProps<IDeliveryNoteDialogFields>) => {
        fProps.setFieldValue('isDeleted', true)
        fProps.values.isDeleted = true // stupid formik does not save setter changes this way...
        setTimeout((args) => {
            this._onSaveClick(fProps)
        }, 50)
    }

    render() {
        const t = this.props.t;
        return (
            <Box sx={{my: 1}}>
                <AlertDialog isOpen={this.state.cancelConfirmOpen}
                             message={t(`${TKey}CancelConfirmMessage`)}
                             onClose={res => {
                                 this.setState({cancelConfirmOpen: false}, () => {
                                     if (res === "ok") {
                                         this._closeDialogAndCallParentCancel()
                                     }
                                 })
                             }}
                />

                <AlertDialog isOpen={this.state.deleteConfirmOpen}
                             message={t(`${TKey}DeleteConfirmMessage`)}
                             parameters={this.state.deleteParameter}
                             onClose={(res, fProps: FormikProps<IDeliveryNoteDialogFields>) => {
                                 this.setState({deleteConfirmOpen: false}, () => {
                                     if (res === "ok") {
                                         this._deleteAndSave(fProps)
                                     }
                                 })
                             }}
                />

                <Button variant='contained'
                        onClick={this._onClickNewBtn}>{t('DeliveryNote.Note.Dialog.NewButtonText')}</Button>

                <Formik initialValues={this.state.initialFormValues}
                        enableReinitialize={true}
                        onSubmit={(values, formikHelpers) => console.log(values)}
                >
                    {fProps =>
                        <Dialog open={this.state.dialogOpen}
                                fullScreen={false}
                                fullWidth={true}
                                maxWidth={'lg'}
                                hideBackdrop={false}
                        >
                            <DialogTitle>{t(this.props.mode === "new" ? `${TKey}NewTitle` : `${TKey}EditTitle`) + ` ${this.props.editItem?.serialId ?? ''}`}</DialogTitle>
                            <DialogContent>
                                <Form>
                                    <Grid container spacing={2} sx={{pt: 1}} alignItems={"flex-start"}>
                                        <DeliveryNoteDialogHeadForm formikProps={fProps}
                                                                    mode={this.props.mode}
                                                                    editItem={this.props.editItem}
                                        />
                                        <DeliveryNoteDialogIssuerForm formikProps={fProps}
                                                                      supplierCardCode={this.state.supplierCardCode}
                                                                      mode={this.props.mode}
                                                                      editItem={this.props.editItem}
                                        />
                                        <DeliveryNoteDialogDeliveryForm formikProps={fProps}
                                                                        mode={this.props.mode}
                                                                        editItem={this.props.editItem}
                                        />
                                    </Grid>
                                    <Grid container spacing={2} alignItems={"flex-start"}>
                                        <DeliveryNoteDialogSupplierForm formikProps={fProps}
                                                                        mode={this.props.mode}
                                                                        editItem={this.props.editItem}
                                                                        onSupplierCardCodeChanged={(sCardCode: string | undefined) => {
                                                                            this.setState({
                                                                                supplierCardCode: sCardCode
                                                                            })
                                                                        }}/>
                                        <DeliveryNoteDialogCustomerForm formikProps={fProps}
                                                                        mode={this.props.mode}
                                                                        editItem={this.props.editItem}
                                                                        onCustomerCardCodeChanged={(cCardCode: string | undefined) => {
                                                                            this.setState({
                                                                                customerCardCode: cCardCode
                                                                            })
                                                                        }}
                                        />
                                        <DeliveryNoteDialogRowsForm formikProps={fProps}
                                                                    mode={this.props.mode}
                                                                    editItem={this.props.editItem}
                                                                    customerCardCode={this.state.customerCardCode}
                                        />
                                    </Grid>
                                </Form>
                            </DialogContent>
                            <DialogActions sx={{display: "flex", justifyContent: "space-between"}}>
                                <Stack direction={"row"} spacing={2}>
                                    <Button
                                        variant="outlined"
                                        onClick={() => {
                                            this.cancelTokenSource.cancel('User cancelled the action.')
                                            this._onCancelClick(fProps)
                                        }}
                                    >
                                        {t(`${TKey}Cancel`)}
                                    </Button>

                                    {this.props.mode === "edit" &&
                                        <Button
                                            variant={"outlined"}
                                            color={fProps.values.isDeleted ? "info" : "error"}
                                            onClick={() => {
                                                this._onDeleteClick(fProps, t)
                                            }}
                                        >
                                            {fProps.values.isDeleted ? t(`${TKey}Undelete`) : t(`${TKey}Delete`)}
                                        </Button>
                                    }
                                </Stack>
                                <Stack>

                                    <Button
                                        variant='contained'
                                        onClick={() => this._onSaveClick(fProps)}
                                        disabled={this.state.formIsSaving}
                                    >
                                        {t(`${TKey}Save`)}
                                    </Button>
                                </Stack>
                            </DialogActions>
                        </Dialog>
                    }
                </Formik>
            </Box>
        );
    }
}

export default withTranslation()(DeliveryNoteDialog);