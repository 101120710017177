import React from 'react';
import {Button, Card, CardActions, CardContent, Grid, Stack, Typography} from "@mui/material";
import {CDAMWMWWorksheetSearchQuery} from "crowbar-api";
import {WorksheetVersionMismatch} from "modules/worksheet/mechanic/state/useLocalAndServerWorksheetVersions";
import {useMountedState} from "shared/hook/useMountedState";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {useOpenWorksheetEditor} from "modules/worksheet/editor/state/events/useOpenWorksheetEditor";
import NewWorksheetEquipmentCard from "modules/worksheet/new-worksheet/results/NewWorksheetEquipmentCard";
import ServiceCallTable from "modules/worksheet/mechanic/results/ServiceCallTable";
import ServiceCallHeaderGridItems from "modules/worksheet/mechanic/results/ServiceCallHeaderGridItems";
import ResultEntryServiceCallTextLines from "modules/worksheet/mechanic/results/ResultEntryServiceCallTextLines";
import AddToWorksheetCartButton from "modules/worksheet/worksheet-cart/AddToWorksheetCartButton";
import WorksheetStatusMap from "crowbar-api/enum-map/WorksheetStatusMap";
import {WorksheetDownloadPdfButton} from "modules/admin/worksheet/worksheet-list/results/WorksheetDownloadPdfButton";
import {WorksheetIconImages} from "modules/worksheet/editor/shared/component/images/WorksheetIconImages";

export interface ResultEntryModeServiceCallProps {
    worksheet: CDAMWMWWorksheetSearchQuery
    mismatch?: WorksheetVersionMismatch

    serviceCallId?: string

    pageSize: number
}

const ResultEntryModeServiceCall = ({
                                        worksheet,
                                        mismatch,
                                        serviceCallId,
                                        pageSize
                                    }: ResultEntryModeServiceCallProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.Mechanic`)

    const openWorksheetEditor = useOpenWorksheetEditor()

    const isMounted = useMountedState()

    const serviceCall = worksheet?.serviceCalls?.find(sc => sc.id === serviceCallId)
    // const salesOrder = worksheet?.salesOrders?.find(() => true)

    const hasSapEquipment = !!serviceCall?.sapEquipmentId

    const itemRows = serviceCall?.itemRows ?? []

    const showPdfDownload = worksheet.status === WorksheetStatusMap.finalSigned ||
        worksheet.status === WorksheetStatusMap.finalSignSkipped ||
        worksheet.status === WorksheetStatusMap.evaluated

    const onOpenWorksheetClick = () => {
        if (!isMounted() || !worksheet?.id) return
        openWorksheetEditor(worksheet.id, serviceCallId)
    }

    return (
        <Card elevation={16} sx={{
            p: 2
        }}>
            <CardContent>
                <Grid container spacing={0.5}>

                    <Grid item xs={12}>

                        <Grid container spacing={0}>

                            <ServiceCallHeaderGridItems
                                worksheet={worksheet}
                                mismatch={mismatch}
                                serviceCallId={serviceCallId}
                            />

                            <Grid item xs={12}>
                                <ResultEntryServiceCallTextLines
                                    faultDescription={serviceCall?.faultDescription}
                                    taskDescription={serviceCall?.taskDescription}
                                />
                            </Grid>

                            {!serviceCall ? (
                                <Typography>
                                    {tk('NoServiceCall')}
                                </Typography>
                            ) : (

                                <Grid item xs={12}>

                                    <Grid container spacing={1}>

                                        <Grid item xs={true}>
                                            <ServiceCallTable
                                                pageSize={pageSize}
                                                itemRows={itemRows}
                                            />
                                        </Grid>

                                        {hasSapEquipment && (
                                            <Grid item xs="auto">
                                                <NewWorksheetEquipmentCard
                                                    coverImage={serviceCall.coverImage}
                                                    manufacturer={serviceCall.manufacturer}
                                                    vehicleType={serviceCall.vehicleType}
                                                    manufacturerSn={serviceCall.manufacturerSn}
                                                    internalSn={serviceCall.internalSn}
                                                    yearOfManufacture={serviceCall.yearOfManufacture}
                                                />
                                            </Grid>
                                        )}

                                    </Grid>

                                </Grid>

                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                <Stack direction="row" spacing={1} sx={{
                    width: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}>
                    <Stack direction="row" spacing={1} sx={{
                        display: "flex",
                        alignItems: "center"
                    }}>
                        <AddToWorksheetCartButton scope="editor" worksheet={worksheet}/>

                        {showPdfDownload && (
                            <WorksheetDownloadPdfButton
                                worksheetId={worksheet?.id}
                                image={WorksheetIconImages.pdfColor}
                                pdfLayout="customer"
                                label={tk('PdfDownload')}
                            />
                        )}
                    </Stack>
                    <Button variant="contained"
                            color="primary"
                            onClick={onOpenWorksheetClick}
                    >
                        {tk('OpenWorksheet')}
                    </Button>
                </Stack>
            </CardActions>
        </Card>
    );
};

export default ResultEntryModeServiceCall;