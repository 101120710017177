import React from 'react';
import {CDAMWMWWorksheetQuery} from "crowbar-api";
import {
    useWorksheetUpdateOverrideEmailNotificationAddresses
} from "crowbar-api/hooks/worksheet/useWorksheetUpdateOverrideEmailNotificationAddresses";
import {Checkbox, FormControlLabel, Typography} from "@mui/material";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {toastError} from "shared/toast/DefaultToasts";
import {
    useCustomerNotificationConfigurationSearchPage
} from "crowbar-api/hooks/notification/useCustomerNotificationConfigurationSearchPage";

export type OverrideEmailAddressesCheckboxProps = {
    worksheet: CDAMWMWWorksheetQuery | null | undefined
    onDataChange: () => void
}

const OverrideEmailAddressesCheckbox = ({worksheet, onDataChange}: OverrideEmailAddressesCheckboxProps) => {
    const tk = useTranslateFunction("Admin.Worksheet")
    const updateOverrideEmailNotificationAddresses = useWorksheetUpdateOverrideEmailNotificationAddresses()

    const customerConfigsQuery = useCustomerNotificationConfigurationSearchPage({
        lastRowNumber: 0,
        limit: 50,
        orderByIsDesc: false,
        search: worksheet?.cardCode ?? "",
        showDeleted: false
    })
    const customerConfigs = (customerConfigsQuery.data ?? [])
        .filter(config =>
            config.cardCode === worksheet?.cardCode &&
            config.emailNotificationsEnabled &&
            !!config.emailAddresses
        )
    const examples = customerConfigs.map(c => c.emailAddresses).join(",")

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!worksheet?.id) {
            toastError('Can\'t change. Missing worksheet.id')
            return
        }
        const checked = event.target.checked;
        await updateOverrideEmailNotificationAddresses.mutateAsync({
            worksheetId: worksheet.id,
            value: checked
        })
        await onDataChange()
    }

    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={worksheet?.overrideEmailNotificationAddresses ?? false}
                    onChange={handleChange}
                />
            }
            label={
                <Typography sx={{wordBreak: "break-word"}}>
                    {tk('OverrideEmailNotificationAddresses') + (examples ? ` (${examples})` : '')}
                </Typography>
            }
        />
    );
};

export default OverrideEmailAddressesCheckbox;