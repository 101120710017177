import React from 'react';
import {CDAMWMWorksheetCallTypeModel} from "crowbar-api";
import {Button, ButtonProps, Checkbox, CheckboxProps, FormControlLabel, Grid} from "@mui/material";
import {useWorksheetCallTypeFindAll} from "crowbar-api/hooks/worksheet/call-type/useWorksheetCallTypeFindAll";

export type CallTypeSelectButtonsProps = {
    selectedCallTypeId?: number | null | undefined
    onChange?: (ct: CDAMWMWorksheetCallTypeModel) => void
    disabled?: boolean

    buttonProps?: ButtonProps
    checkboxProps?: CheckboxProps
}

export const CallTypeSelectButtons = ({
                                          selectedCallTypeId,
                                          onChange,
                                          disabled,
                                          buttonProps,
                                          checkboxProps
                                      }: CallTypeSelectButtonsProps) => {

    const callTypeQuery = useWorksheetCallTypeFindAll()
    const callTypes = callTypeQuery.data ?? []

    const setSelectedCallType = (e: React.MouseEvent<HTMLButtonElement>, ct: CDAMWMWorksheetCallTypeModel) => {
        e.preventDefault()
        onChange?.(ct)
    }

    return (
        <Grid container spacing={0.5}>

            {callTypes.map(ct => (
                <Grid key={ct.id} item xs="auto">
                    <Button variant="outlined" onClick={(e) => setSelectedCallType(e, ct)}
                            disabled={disabled} {...buttonProps}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={selectedCallTypeId === ct.id}
                                    disabled={disabled}
                                    {...checkboxProps}
                                />
                            }
                            label={ct.name ?? "-"}
                        />
                    </Button>
                </Grid>
            ))}

        </Grid>
    );
};

export default CallTypeSelectButtons;