import React from 'react';
import {Button, ButtonProps, CircularProgress} from "@mui/material";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {PrimitiveAtom, useAtom} from "jotai";
import {SignatureState} from "modules/worksheet/signature/signature-box/SignatureState";
import {
    useWorksheetUpdateSignature
} from "crowbar-api/hooks/worksheet/status-and-signature/useWorksheetUpdateSignature";
import {CDAMWMWWorksheetSignatureUpdate, CDAMWMWWorksheetStatusAndSignatureQuery} from "crowbar-api";
import WorksheetSignatureCanvasDialog from "modules/worksheet/signature/signature-box/WorksheetSignatureCanvasDialog";
import {useNavigate} from "react-router-dom";
import {toastError} from "shared/toast/DefaultToasts";
import WorksheetStatusMap from "crowbar-api/enum-map/WorksheetStatusMap";

export type WorksheetSignatureFinishButtonProps = ButtonProps & {
    worksheetStatuses: CDAMWMWWorksheetStatusAndSignatureQuery[]
    onCompleteReturnUrl?: string
    signatureStateAtom: PrimitiveAtom<SignatureState>

    onSignatureUpdated?: (updates: CDAMWMWWorksheetSignatureUpdate[]) => void
}

export const WorksheetSignatureFinishButton = ({
                                                   worksheetStatuses,
                                                   onCompleteReturnUrl,
                                                   signatureStateAtom,
                                                   onSignatureUpdated,
                                                   ...props
                                               }: WorksheetSignatureFinishButtonProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    const [{dialogOpen, withSignature, signName}, setSignatureState] = useAtom(signatureStateAtom)

    const navigate = useNavigate()

    const worksheetUpdateSignature = useWorksheetUpdateSignature()

    const onSignatureFinished = async (
        getDataUrl: () => string | undefined,
        isEmpty: () => (boolean | undefined)
    ) => {

        // Validate values before doing anything
        if (withSignature) {
            const hasSignature = !isEmpty?.() ?? false
            if (!hasSignature) {
                toastError(tk('SignatureRequired'))
                return
            }
        }

        const dataUrl = withSignature ? getDataUrl() : undefined

        setDialogOpen(false)
        await saveAction(signName, dataUrl)
    }

    const onFinishClick = async () => {
        if (withSignature) {
            // display signature dialog wit the canvas
            setDialogOpen(true)
        } else {
            // save without signature
            await saveAction(signName, undefined)
        }
    }

    const setDialogOpen = (o: boolean) => {
        setSignatureState(prev => {
            return {
                ...prev,
                dialogOpen: o
            }
        })
    }

    const saveAction = async (signName: string | undefined, dataUrl: string | undefined) => {
        const updates: CDAMWMWWorksheetSignatureUpdate[] = []
        // Every model included in the updates array means an update
        // will run for that worksheet.
        // Not including it in the updates array skips it.

        // 1. prepare the status updates
        for (const worksheetStatus of worksheetStatuses) {
            if (!withSignature) {
                // Change after 2023-02-03. We can remove existing signatures now!.
                // This check commented out
                // if we had a signature before, skip means nothing!
                // else we update the status with skipped
                //if (worksheetStatus?.status === WorksheetStatusMap.finalSigned && worksheetStatus?.signatureBase64) {
                //    continue
                //}

                updates.push({
                    id: worksheetStatus.id,
                    status: WorksheetStatusMap.finalSignSkipped,
                    signatureBase64: null,
                    signatureName: signName
                })
            } else {
                // we overwrite every signature and status for the current value
                updates.push({
                    id: worksheetStatus.id,
                    status: WorksheetStatusMap.finalSigned,
                    signatureBase64: dataUrl,
                    signatureName: signName
                })
            }
        }

        // 2. run the update
        await worksheetUpdateSignature.mutateAsync(updates)

        // 3. Call param callback with the updates
        // Note: when the editor updates the values, the current local state must
        // be updated by the new signature..etc fields, because the direct update to worksheet
        // bypasses the localStorage-->worksheetMerge automatic saves.
        // However, when these updates are ran by other components, there is no need for this usually,
        // but can be useful to know.
        onSignatureUpdated?.(updates)

        // 4. Go to the param return url
        if (onCompleteReturnUrl) {
            navigate(onCompleteReturnUrl)
        }
    }

    return (
        <>
            <Button
                {...props}
                variant="contained"
                onClick={onFinishClick}
                disabled={worksheetUpdateSignature.isLoading}
            >
                {withSignature ? tk('FinishWithSignature') : tk('FinishWithoutSignature')}
                {worksheetUpdateSignature.isLoading && (
                    <CircularProgress/>
                )}
            </Button>

            <WorksheetSignatureCanvasDialog
                open={dialogOpen}
                onClose={(event, reason) => {
                    if (reason === "escapeKeyDown") {
                        setDialogOpen(false)
                    }
                }}
                signatureStateAtom={signatureStateAtom}
                worksheetStatuses={worksheetStatuses}
                signWidth={400}
                signHeight={150}
                onSignatureFinished={onSignatureFinished}
                onSignatureClose={() => setDialogOpen(false)}
            />
        </>
    )
}