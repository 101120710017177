import {useQuery} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {CDSMSSapItemForWorksheetSearchQueryParams, SapItemApi} from "crowbar-api";
import AxiosCancelTokenHelper from "crowbar-api/AxiosCancelTokenHelper";

const useSapItemSearchQueryBy = (queryParams: CDSMSSapItemForWorksheetSearchQueryParams) => {
    return useQuery(['add-item-search-paging', queryParams], async (context) => {
        const response = await crowbarApiFactory(SapItemApi).searchQueryBy(queryParams, AxiosCancelTokenHelper.cancelTokenFrom(context.signal))
        if (response.status !== 200) {
            throw new Error('Failed to load item page.')
        }
        return response.data
    }, {
        cacheTime: Math.pow(10, 7),
        staleTime: Math.pow(10, 7),
        retry: 3,
        refetchOnWindowFocus: false,
        refetchOnMount: false
    })
}

export default useSapItemSearchQueryBy