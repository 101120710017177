import React from 'react';
import {CDAMWMServiceCallModel, CDAMWMWorksheetCallTypeModel} from "crowbar-api";
import {
    useServiceCallEvalUpdateCallTypeId
} from "crowbar-api/hooks/worksheet/evaluation/service-call/useServiceCallEvalUpdateCallTypeId";
import {CircularProgress, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {useWorksheetCallTypeFindAll} from "crowbar-api/hooks/worksheet/call-type/useWorksheetCallTypeFindAll";
import {byPropertiesOf} from "shared/utils/ArraySort";

export type CallTypeSelectProps = {
    sc: CDAMWMServiceCallModel
    evalId: string
    disabled?: boolean
}

const CallTypeSelect = ({sc, evalId, disabled}: CallTypeSelectProps) => {

    const updateCallTypeId = useServiceCallEvalUpdateCallTypeId();

    const onCallTypeChange = async (event: SelectChangeEvent) => {
        const newValueRaw = event.target.value;
        const newValue = !newValueRaw || isNaN(+newValueRaw) ? undefined : +newValueRaw
        await updateCallTypeId.mutateAsync({
            evalId: evalId,
            originalId: sc.id,
            field: newValue
        })
        sc.callTypeId = newValue // instead of refetch
    }

    const callTypeQuery = useWorksheetCallTypeFindAll()
    const callTypes = (callTypeQuery.data ?? [])
        .sort(byPropertiesOf<CDAMWMWorksheetCallTypeModel>(['visOrder']))

    if (callTypeQuery.isLoading) {
        return (
            <CircularProgress/>
        )
    }

    return (
        <Select
            fullWidth
            size="small"
            disabled={disabled || updateCallTypeId.isLoading}
            value={sc.callTypeId?.toString() ?? ""}
            onChange={onCallTypeChange}
        >
            <MenuItem value="">&nbsp;</MenuItem>
            {callTypes.map(ct => (
                <MenuItem key={ct.id} value={ct.id}>{ct.name}</MenuItem>
            ))}
        </Select>
    )
}

export default CallTypeSelect;