import {
    useServiceCallFindByWorksheetId
} from "crowbar-api/hooks/worksheet/service-call/useServiceCallFindByWorksheetId";
import {useCallback, useMemo, useState} from "react";
import {
    useServiceCallItemRowsFindByServiceCallId
} from "crowbar-api/hooks/worksheet/service-call-item-rows/useServiceCallItemRowsFindByServiceCallId";
import {
    useServiceCallEvalFindByWorksheetEvalId
} from "crowbar-api/hooks/worksheet/evaluation/service-call/useServiceCallEvalFindByWorksheetEvalId";
import {
    useSapEquipmentImageBlobsByEquipmentIdsOrModIds
} from "crowbar-api/hooks/worksheet/blobs/useSapEquipmentImageBlobsByEquipmentIdsOrModIds";
import {
    useServiceCallItemRowFindTripItemRowsByWorksheetId
} from "crowbar-api/hooks/worksheet/service-call-item-rows/useServiceCallItemRowFindTripItemRowsByWorksheetId";

export const useServiceCallQueries = (wEvalId: string | null | undefined, worksheetId: string | null | undefined) => {

    const [selectedServiceCallEvalId, setSelectedServiceCallEvalId] = useState<string | undefined>(undefined)

    const scEvalQuery = useServiceCallEvalFindByWorksheetEvalId(wEvalId)

    const scEvals = useMemo(() => scEvalQuery.data ?? [], [scEvalQuery.data])
    //const scEvals = scEvalQuery.data ?? []

    const selectedServiceCallId = useMemo(() => scEvals.find(e => e.id === selectedServiceCallEvalId)?.serviceCallId, [scEvals, selectedServiceCallEvalId])
    //const selectedServiceCallId = scEvals.find(e => e.id === selectedServiceCallEvalId)?.serviceCallId

    const serviceCallsQuery = useServiceCallFindByWorksheetId(worksheetId)
    const itemRowsQuery = useServiceCallItemRowsFindByServiceCallId(selectedServiceCallId)

    const serviceCalls = useMemo(() => serviceCallsQuery.data ?? [], [serviceCallsQuery.data])
    const selectedServiceCall = useMemo(() => serviceCalls.find(sc => sc.id === selectedServiceCallId), [selectedServiceCallId, serviceCalls])
    //const serviceCalls = serviceCallsQuery.data ?? []
    //const selectedServiceCall = serviceCalls.find(sc => sc.id === selectedServiceCallId)

    const imageBlobsQuery = useSapEquipmentImageBlobsByEquipmentIdsOrModIds(
        serviceCalls
            ?.filter(sc => sc.sapEquipmentId)
            .map(sc => sc.sapEquipmentId!),
        serviceCalls
            ?.filter(sc => sc.modSapEquipmentId)
            .map(sc => sc.modSapEquipmentId!)
    )
    const imageBlobs = useMemo(() => imageBlobsQuery.data ?? [], [imageBlobsQuery.data])
    //const imageBlobs = imageBlobsQuery.data ?? []

    const tripItemRowsQuery = useServiceCallItemRowFindTripItemRowsByWorksheetId(worksheetId)

    const tripItemRows = useMemo(() => tripItemRowsQuery.data ?? [], [tripItemRowsQuery.data])

    const refetchServiceCallQueries = useCallback(async () => {
        await Promise.all([
            scEvalQuery.refetch(),
            serviceCallsQuery.refetch(),
            tripItemRowsQuery.refetch()
        ])
    }, [scEvalQuery, serviceCallsQuery, tripItemRowsQuery])

    const refetchAll = useCallback(async () => {
        await Promise.all([
            scEvalQuery.refetch(),
            serviceCallsQuery.refetch(),
            itemRowsQuery.refetch(),
            imageBlobsQuery.refetch(),
            tripItemRowsQuery.refetch()
        ])
    }, [imageBlobsQuery, itemRowsQuery, scEvalQuery, serviceCallsQuery, tripItemRowsQuery])

    const isLoading = useMemo(() => {
        return scEvalQuery.isLoading ||
            serviceCallsQuery.isLoading ||
            itemRowsQuery.isLoading ||
            imageBlobsQuery.isLoading ||
            tripItemRowsQuery.isLoading
    }, [imageBlobsQuery.isLoading, itemRowsQuery.isLoading, scEvalQuery.isLoading, serviceCallsQuery.isLoading, tripItemRowsQuery.isLoading])

    const isFetching = useMemo(() => {
        return scEvalQuery.isFetching ||
            serviceCallsQuery.isFetching ||
            itemRowsQuery.isFetching ||
            imageBlobsQuery.isFetching ||
            tripItemRowsQuery.isFetching
    }, [imageBlobsQuery.isFetching, itemRowsQuery.isFetching, scEvalQuery.isFetching, serviceCallsQuery.isFetching, tripItemRowsQuery.isFetching])


    return {
        selectedServiceCallEvalId,
        setSelectedServiceCallEvalId,

        // derived from the evals
        selectedServiceCallId,

        scEvalQuery,
        serviceCalls,
        selectedServiceCall,
        imageBlobs,
        tripItemRows,

        scEvals,
        serviceCallsQuery,
        itemRowsQuery,
        imageBlobsQuery,
        tripItemRowsQuery,

        isLoading,
        isFetching,

        refetchServiceCallQueries,
        refetchAll
    }
}