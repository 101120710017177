import {useWorksheetSaveState} from "modules/worksheet/editor/state/WorksheetSaveAtoms";
import useIsTripMatchFn from "crowbar-api/hooks/worksheet/validations/useIsTripMatchFn";
import {
    WorksheetValidationResult,
    WorksheetValidationUtils
} from "modules/worksheet/editor/shared/validation/WorksheetValidationUtils";
import {useIsTaskDescriptionRequiredFn} from "crowbar-api/hooks/worksheet/validations/useIsTaskDescriptionRequiredFn";

const useWorksheetStatuses = (): WorksheetValidationResult => {
    const [ws] = useWorksheetSaveState()

    const isTripFn = useIsTripMatchFn()
    const taskDescriptionRequiredFn = useIsTaskDescriptionRequiredFn();

    return WorksheetValidationUtils.validateCompletenessFor(ws, isTripFn, taskDescriptionRequiredFn)
}

export default useWorksheetStatuses