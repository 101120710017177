import {useMutation} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {UserProfileApi} from "crowbar-api";

const useExplicitRegisterAzureUserToApplication = () => {
    return useMutation(async (userId: string) => {
        const r = await crowbarApiFactory(UserProfileApi)
            .explicitRegisterAzureUserToApplication(userId)
        if (r.status !== 200) {
            throw new Error('Failed to register user for id ' + userId);
        }
        return r.data
    })
}

export default useExplicitRegisterAzureUserToApplication