import {useEffect} from "react";
import {useWorksheetEditorHeaderInfoAtom} from "modules/worksheet/editor/state/WorksheetEditorHeaderAtoms";
import {useWorksheetSaveState} from "modules/worksheet/editor/state/WorksheetSaveAtoms";

/**
 * if the service calls array changes
 * set the first entry to selected if nothing
 * else is selected yet
 */
const useAutoSelectServiceCall = () => {
    const [info, setInfo] = useWorksheetEditorHeaderInfoAtom()
    const [ws] = useWorksheetSaveState()

    useEffect(() => {
        if (ws?.serviceCalls &&
            ws?.serviceCalls.length > 0 && (
                // not set yet
                info.selectedServiceCallId === undefined ||
                // cannot find currently
                ws.serviceCalls.find(sc => sc.id === info.selectedServiceCallId) === undefined
            )
        ) {
            const sId = ws.serviceCalls[0].id
            if (!sId) return // hint
            console.log("Selected first service call by default.")
            setInfo((prev) => {
                return {
                    ...prev,
                    selectedServiceCallId: sId
                }
            })
        }
    }, [info.selectedServiceCallId, setInfo, ws?.serviceCalls])
}

export default useAutoSelectServiceCall