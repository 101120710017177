import {
    PartnerContactFormValues
} from "modules/worksheet/editor/shared/component/partner-contact-form/usePartnerContactForm";
import {CDAMWMWModSapPartnerContactSave} from "crowbar-api";
import {useNewAtomWithRef} from "shared/hook/useNewatomWithRef";
import {v4 as uuid} from "uuid";
import {toastError} from "shared/toast/DefaultToasts";
import ModSapTypeMap from "crowbar-api/enum-map/ModSapTypeMap";
import WEditorPageBasicContactDialog
    from "modules/worksheet/editor/ui/basic/contact-select/WEditorPageBasicContactDialog";

const applyFormValuesToMod = (data: PartnerContactFormValues, mod: CDAMWMWModSapPartnerContactSave) => {
    mod.firstname = data.firstname
    mod.middleName = data.middleName
    mod.lastname = data.lastname
    mod.email = data.email
    mod.phone = data.phone
    mod.comment = data.comment
}

interface WEditPageBasicContactButtonProps {
    renderButton: (onClick: () => void, disabled?: boolean) => JSX.Element
    mode: 'new' | 'edit'
    selectedValueAsMod?: CDAMWMWModSapPartnerContactSave | undefined
    selectedKey: string | undefined
    addNewMod: (mod: CDAMWMWModSapPartnerContactSave, mode: ("new" | "edit")) => void
}

const WEditPageBasicContactButton = ({

                                         renderButton,
                                         mode,
                                         selectedValueAsMod,
                                         selectedKey,
                                         addNewMod
                                     }: WEditPageBasicContactButtonProps) => {
    const [dialogKey, setDialogKey] = useNewAtomWithRef<string>(uuid())
    const [open, setOpen] = useNewAtomWithRef<boolean>(false)

    const onBtnClick = () => {
        setDialogKey(uuid())
        setOpen(true)
    }

    const onSubmit = (data: PartnerContactFormValues) => {
        if (!selectedValueAsMod) {
            toastError('Error during partner address submit. Edit mod is undefined.')
            return
        }
        const newMod: CDAMWMWModSapPartnerContactSave = {
            ...selectedValueAsMod,
            id: mode === "new" ? uuid() : selectedValueAsMod.id
        }
        applyFormValuesToMod(data, newMod)
        newMod.type = newMod.type === ModSapTypeMap.create ? ModSapTypeMap.create
            : mode === "new" ? ModSapTypeMap.create : ModSapTypeMap.update
        addNewMod(newMod, mode)
    }

    return (
        <>
            {renderButton(onBtnClick, mode === 'edit' && !selectedKey)}

            <WEditorPageBasicContactDialog
                key={dialogKey}
                mode={mode}
                open={open}
                onDismiss={() => setOpen(false)}
                onSubmit={onSubmit}
                editModContact={mode === 'edit' ? selectedValueAsMod : undefined}
            />
        </>
    )
}

export default WEditPageBasicContactButton