import {InputAdornment, Stack, Typography} from '@mui/material';
import React from 'react';
import NumberOnlyTextField, {NumberOnlyTextFieldProps} from "shared/components/inputs/NumberOnlyTextField";

export type DistanceInputProps = NumberOnlyTextFieldProps & {
    disabled?: boolean
}

const DistanceInput = ({disabled, value, onChange, ...props}: DistanceInputProps) => {

    return (
        <Stack direction="row" spacing={0.5}>
            <NumberOnlyTextField
                sx={{
                    width: "170px"
                }}
                InputProps={{
                    sx: {
                        height: 1,
                        marginBottom: 0,
                        fontSize: "30pt",
                    },
                    endAdornment: (
                        <InputAdornment position="end">
                            <Typography sx={{
                                fontSize: "18pt"
                            }}>km</Typography>
                        </InputAdornment>
                    )
                }}
                hideZeroOnClick={true}
                value={value}
                onChange={onChange}
                disabled={disabled}
                {...props}
            />
        </Stack>
    );
};

export default DistanceInput;