import {useMemo} from "react";
import {useNewAtomWithRef} from "shared/hook/useNewatomWithRef";
import {CDAMPMPhotoRuleModel, CDAMPMPhotoRulePagingParams, PhotoRuleApi} from "crowbar-api";
import {useQuery} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import AxiosCancelTokenHelper from "crowbar-api/AxiosCancelTokenHelper";
import {UsePagingResult} from "shared/components/generic-paging/UsePagingResult";
import UsePagingUtils from "shared/components/generic-paging/UsePagingUtils";

export interface UsePhotoRulesPagingResult extends UsePagingResult<CDAMPMPhotoRuleModel, CDAMPMPhotoRulePagingParams> {

}

const usePhotoRulesPaging = (): UsePhotoRulesPagingResult => {

    const [pagingParams, setPagingParams] = useNewAtomWithRef<CDAMPMPhotoRulePagingParams>({
        search: "",
        limit: 50,
        lastRowNumber: 0,
        showDeleted: false,
        hasManufacturerCode: ""
    })

    const {status, data} = useQuery(['photo-rule-page', pagingParams], async (context) => {
        const response = await crowbarApiFactory(PhotoRuleApi)
            .pageBy(pagingParams, AxiosCancelTokenHelper.cancelTokenFrom(context.signal))
        if (response.status !== 200) {
            throw new Error('Response status is not 200')
        }
        return response.data
    }, {
        cacheTime: 0,// Math.pow(8, 8),
        retry: 0,
        refetchOnWindowFocus: false
    })

    return useMemo(() => {
        return {
            pagingParams,
            setPagingParams,
            dataLoadingStatus: status,
            data: data ?? [],
            hasNextPage: UsePagingUtils.hasNextPage(data, pagingParams.limit),
            nextPage: () => {
                setPagingParams(prev => {
                    return {
                        ...prev,
                        lastRowNumber: UsePagingUtils.nextPageRowNumber(prev.lastRowNumber, prev.limit)
                    }
                })
            },
            hasPreviousPage: UsePagingUtils.hasPreviousPage(pagingParams.lastRowNumber, pagingParams.limit),
            previousPage: () => {
                setPagingParams(prev => {
                    return {
                        ...prev,
                        lastRowNumber: UsePagingUtils.previousPageRowNumber(prev.lastRowNumber, prev.limit)
                    }
                })
            }
        }
    }, [data, pagingParams, setPagingParams, status])
}

export default usePhotoRulesPaging