import React, {useMemo} from 'react';
import {
    CDAMWMESapEquipmentServiceCallBlobQuery,
    CDAMWMServiceCallEvalModel,
    CDAMWMServiceCallItemRowModel,
    CDAMWMServiceCallModel,
    CDAMWMWWorksheetQuery
} from "crowbar-api";
import {useMaterialReactTableLocalization} from "i18n/material-table/useMaterialReactTableLocalization";
import MaterialReactTable, {MRT_ColumnDef, MRT_RowSelectionState, MRT_Updater} from "material-react-table";
import useTranslateFunction from "shared/language/useTranslateFunction";
import TypeCell from "modules/admin/worksheet/evaluation/tabs/service-calls/service-call-table/TypeCell";
import DescriptionCell from "modules/admin/worksheet/evaluation/tabs/service-calls/service-call-table/DescriptionCell";
import ManufacturerSnCell
    from "modules/admin/worksheet/evaluation/tabs/service-calls/service-call-table/ManufacturerSnCell";
import ImagesCell from "modules/admin/worksheet/evaluation/tabs/service-calls/service-call-table/ImagesCell";
import DeliveryStatusCell
    from "modules/admin/worksheet/evaluation/tabs/service-calls/service-call-table/DeliveryStatusCell";
import ServiceCallStatusCell
    from "modules/admin/worksheet/evaluation/tabs/service-calls/service-call-table/ServiceCallStatusCell";
import TripDistanceOnRows
    from "modules/admin/worksheet/evaluation/tabs/service-calls/service-call-table/TripDistanceOnRows";

export type ServiceCallTableProps = {
    selectedEvalId: string | undefined
    //onRowSelectionChange: (updaterOrValue: MRT_Updater<MRT_RowSelectionState>) => void
    onSelectedEvalChange: (newEvalId: string | undefined) => void

    worksheet: CDAMWMWWorksheetQuery | null | undefined
    scEvals: CDAMWMServiceCallEvalModel[]
    serviceCalls: CDAMWMServiceCallModel[]
    imageBlobs: CDAMWMESapEquipmentServiceCallBlobQuery[]
    tripItemRows: CDAMWMServiceCallItemRowModel[]
}

const ServiceCallTable = ({
                              selectedEvalId,
                              onSelectedEvalChange,
                              worksheet,
                              scEvals,
                              serviceCalls,
                              imageBlobs,
                              tripItemRows
                          }: ServiceCallTableProps) => {

    const tk = useTranslateFunction('Admin.Worksheet')

    const mrt_locale = useMaterialReactTableLocalization()

    const currentRowSelection = Object.fromEntries(scEvals.map(e => [e.id, e.id === selectedEvalId]))

    // const tableInstanceRef = useRef<MRT_TableInstance | null>(null)

    const onRowSelectionChange = (updaterOrValue: MRT_Updater<MRT_RowSelectionState>) => {

        const newValue = updaterOrValue instanceof Function ? updaterOrValue(currentRowSelection) : updaterOrValue
        let newSelected: string | undefined = undefined
        for (const key in newValue) {
            if (newValue[key] === true) {
                newSelected = key
                break
            }
        }
        onSelectedEvalChange(newSelected)
    }

    const columns = useMemo<MRT_ColumnDef<CDAMWMServiceCallEvalModel>[]>(() => [
        {
            accessorFn: row => (
                <TypeCell row={row} worksheet={worksheet} serviceCalls={serviceCalls}/>
            ),
            header: tk('Type'),
            size: 100,
            id: "type"
        },
        {
            accessorFn: row => (
                <DescriptionCell row={row} worksheet={worksheet} serviceCalls={serviceCalls}/>
            ),
            header: tk('Description'),
            size: 300,
            id: "description"
        },
        {
            accessorFn: row => (
                <ManufacturerSnCell row={row} worksheet={worksheet} serviceCalls={serviceCalls}/>
            ),
            header: tk('ManufacturerSn'),
            size: 200,
            id: "manufacturerSn"
        },
        {
            accessorFn: row => (
                <ImagesCell row={row} serviceCalls={serviceCalls} imageBlobs={imageBlobs}/>
            ),
            header: tk('Images'),
            size: 100,
            id: "images"
        },
        {
            accessorFn: row => (
                <TripDistanceOnRows row={row} tripItemRows={tripItemRows}/>
            ),
            header: tk('TripDistanceOnRows'),
            size: 100,
            id: "tripDistanceOnRows"
        },
        {
            accessorFn: row => (
                <DeliveryStatusCell row={row}/>
            ),
            header: tk('Delivery'),
            size: 100,
            id: "deliveryStatus"
        },
        {
            accessorFn: row => (
                <ServiceCallStatusCell row={row}/>
            ),
            header: tk('ServiceCall'),
            size: 100,
            id: "serviceCallStatus"
        },
    ], [imageBlobs, serviceCalls, tk, tripItemRows, worksheet])

    return (
        <MaterialReactTable
            // tableInstanceRef={tableInstanceRef}
            data={[...scEvals]} // even if the scEvals array changes, it will not rerender sometimes if not called with [...x]

            enableRowSelection
            enableMultiRowSelection={false} //use radio buttons instead of checkboxes
            onRowSelectionChange={onRowSelectionChange}
            getRowId={(row) => row.id ?? ""} //give each row a more useful id
            //clicking anywhere on the row will select it
            muiTableBodyRowProps={({row}) => ({
                onClick: row.getToggleSelectedHandler(),
                sx: {cursor: 'pointer'},
            })}

            state={{
                rowSelection: currentRowSelection
            }}

            columns={columns}
            localization={mrt_locale}
            initialState={{
                density: 'compact',
            }}
            rowNumberMode="original" //default
            enableGlobalFilter={false} //disable search feature
            enableColumnResizing={true}
            enableColumnFilters={false}
            enableColumnActions={false}
            enableSorting={true}
            enableFullScreenToggle={false}
            positionToolbarAlertBanner="bottom"

            enablePagination={false}
            enableBottomToolbar={false}
            enableTopToolbar={false}
        />
    )
}

export default ServiceCallTable;