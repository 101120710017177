import {useWorksheetSaveState} from "modules/worksheet/editor/state/WorksheetSaveAtoms";
import WEditPageBasicContactButton from "modules/worksheet/editor/ui/basic/contact-select/WEditPageBasicContactButton";
import {Box, Button, Card, CardActions, CardContent, Stack, Typography} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import WEditorPageBasicContactSelect
    from "modules/worksheet/editor/ui/basic/contact-select/WEditorPageBasicContactSelect";
import useSapPartnerContactSelectValuesAtom
    from "modules/worksheet/editor/ui/basic/contact-select/useSapPartnerContactSelectValuesAtom";
import useSelectedSapContactAsMod from "modules/worksheet/editor/ui/basic/contact-select/useSelectedSapContactAsMod";
import useSapPartnerContactsByCardCode from "crowbar-api/hooks/sap/useSapPartnerContactsByCardCode";
import useTranslateFunction from "shared/language/useTranslateFunction";
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';

interface WEditorPageBasicContactBoxProps {
    disabled?: boolean
}

const WEditorPageBasicContactBox = ({disabled}: WEditorPageBasicContactBoxProps) => {

    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    const [worksheetSave] = useWorksheetSaveState()
    const {data: contacts} = useSapPartnerContactsByCardCode(worksheetSave?.cardCode)

    const {
        selectedKey,
        setSelectedByKey,
        selectValues,
        addNewMod
    } = useSapPartnerContactSelectValuesAtom(worksheetSave?.cardCode)
    const {selectedValueAsMod} = useSelectedSapContactAsMod(selectValues, selectedKey)

    const contactsLength = (contacts?.length ?? 0) + (worksheetSave?.modSapPartnerContacts?.length ?? 0)

    return (
        <Card variant="outlined" sx={{height: "100%"}}>

            <Stack direction="column" justifyContent="space-between" spacing={0} sx={{height: 1}}>

                <CardContent>
                    <Stack direction="row" justifyContent="space-between">
                        <Typography variant="h6" fontWeight="bold">
                            {tk('BasicContactTitle')}
                        </Typography>
                        <Box>
                            <Typography variant="h6">
                                <ContactPhoneIcon sx={{
                                    fontSize: "inherit"
                                }}/>
                                {contactsLength > 0 && (
                                    <Box component="span" sx={{color: 'red'}}>
                                        <sup><small>{contactsLength}</small></sup>
                                    </Box>
                                )}
                            </Typography>
                        </Box>
                    </Stack>

                    <WEditorPageBasicContactSelect
                        disabled={disabled}
                        selectedKey={selectedKey}
                        selectValues={selectValues}
                        setSelectedByKey={setSelectedByKey}
                    />
                </CardContent>
                <CardActions>
                    <WEditPageBasicContactButton

                        mode="new"
                        renderButton={onClick => (
                            <Button variant="text" size="small" disabled={disabled} onClick={onClick}
                                    startIcon={<AddCircleIcon/>}>
                                {tk('AddNew')}
                            </Button>
                        )}
                        selectedKey={selectedKey}
                        selectedValueAsMod={selectedValueAsMod}
                        addNewMod={addNewMod}
                    />
                    <WEditPageBasicContactButton

                        mode="edit"
                        renderButton={(onClick, renderDisabled) => (
                            <Button variant="text" size="small" onClick={onClick} startIcon={<EditIcon/>}
                                    disabled={disabled || renderDisabled}>
                                {tk('Edit')}
                            </Button>
                        )}
                        selectedKey={selectedKey}
                        selectedValueAsMod={selectedValueAsMod}
                        addNewMod={addNewMod}
                    />
                </CardActions>
            </Stack>

        </Card>
    )
}

export default WEditorPageBasicContactBox