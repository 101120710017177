import {useMutation} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {CDAMDMDeliveryNoteTemplateModel, DeliveryNoteTemplateApi} from "crowbar-api";

export const saveDeliveryNoteTemplate = async (template: CDAMDMDeliveryNoteTemplateModel) => {
    await crowbarApiFactory(DeliveryNoteTemplateApi).save(template);
}

export const useSaveDeliveryNoteTemplate = () => {
    return useMutation(async (template: CDAMDMDeliveryNoteTemplateModel) => {
        await saveDeliveryNoteTemplate(template)
    })
}