import * as yup from "yup";
import {yupStringToNumberOrNull} from "shared/utils/YupHelpers";
import {useEffect, useRef} from "react";
import {useTranslation} from "react-i18next";
import {useForm, UseFormRegisterReturn, UseFormReturn} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {CDAMWMWModSapEquipmentSave} from "crowbar-api";

export interface UsePartnerEquipmentFormParams {
    mode: 'new' | 'edit'
    editModEquipment?: CDAMWMWModSapEquipmentSave
}

export interface PartnerEquipmentFormResult extends UseFormReturn<PartnerEquipmentFormValues, any> {
    manufacturerRegister: UseFormRegisterReturn<"manufacturer">
    vehicleTypeRegister: UseFormRegisterReturn<"vehicleType">
    manufacturerSnRegister: UseFormRegisterReturn<"manufacturerSn">
    internalSnRegister: UseFormRegisterReturn<"internalSn">
    yoMRegister: UseFormRegisterReturn<"yoM">
    commentRegister: UseFormRegisterReturn<"comment">
}

export interface PartnerEquipmentFormValues {
    manufacturer?: string
    vehicleType?: string
    manufacturerSn?: string
    internalSn?: string
    yoM?: number
    comment?: string
}

const prepareFormSchema = (tk: (key: string, params?: any) => string) => {
    const yomError = tk('NumberBetween', {min: "1900", max: "2500"});
    return yup.object({
        manufacturer: yup.string()
            .required(tk('FieldIsRequired')),
        vehicleType: yup.string()
            .notRequired()
            .nullable(),
        manufacturerSn: yup.string()
            .required(tk('FieldIsRequired'))
            .max(50, tk('StringIsLongerThan', {max: 50})),
        internalSn: yup.string()
            .max(50, tk('StringIsLongerThan', {max: 50}))
            .notRequired()
            .nullable(),
        yoM: yup.number()
            .notRequired()
            .typeError(tk('OnlyNumbersAllowed')) // optional field
            .transform(yupStringToNumberOrNull)
            .nullable()
            //.default(undefined)
            .integer(tk('OnlyIntegersAllowed'))
            .positive(yomError)
            .min(1900, yomError)
            .max(2500, yomError),
        comment: yup.string()
    });
}

export const usePartnerEquipmentForm = ({
                                            mode,
                                            editModEquipment
                                        }: UsePartnerEquipmentFormParams): PartnerEquipmentFormResult => {
    const {t} = useTranslation()
    const tkValidation = (key: string, params?: any): string => {
        return t(`Generic.Validations.${key}`, params)
    }
    const formSchemaRef = useRef<any>(prepareFormSchema(tkValidation))
    formSchemaRef.current = prepareFormSchema(tkValidation)

    const form = useForm<PartnerEquipmentFormValues>({
        resolver: yupResolver(formSchemaRef.current)
    })

    /*  const formRef = useRef<UseFormReturn<PartnerEquipmentFormValues, any>>(form)
      formRef.current = form*/

    const manufacturerRegister = form.register("manufacturer")
    const vehicleTypeRegister = form.register("vehicleType")
    const manufacturerSnRegister = form.register("manufacturerSn");
    const internalSnRegister = form.register("internalSn");
    const yoMRegister = form.register("yoM");
    const commentRegister = form.register("comment");

    // If mode is update, load the values from the params
    useEffect(() => {
        // Only load the params if the form is not dirty
        if (!form.formState.isDirty && editModEquipment && mode === 'edit') {
            const setOpts = {
                shouldDirty: false,
                shouldTouch: false,
                shouldValidate: true
            }
            form.setValue('manufacturer', editModEquipment.manufacturer ?? "", setOpts)
            form.setValue('vehicleType', editModEquipment.vehicleType ?? "", setOpts)
            form.setValue('manufacturerSn', editModEquipment.manufacturerSn ?? "", setOpts)
            form.setValue('internalSn', editModEquipment.internalSn ?? "", setOpts)
            form.setValue('yoM', editModEquipment.yearOfManufacture ?? undefined, setOpts)
            form.setValue('comment', editModEquipment.comment ?? "", setOpts)
        }
    }, [mode, editModEquipment, form.formState.isDirty, form])

    return {
        ...form,
        manufacturerRegister,
        vehicleTypeRegister,
        manufacturerSnRegister,
        internalSnRegister,
        yoMRegister,
        commentRegister
    }
}