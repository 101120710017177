import React from 'react';
import {
    CDAMWMServiceCallEvalModel,
    CDAMWMServiceCallModel,
    CDAMWMWorksheetEvalModel,
    CDAMWMWWorksheetQuery,
    CDSMDeliveryOrDraftExistsResult
} from "crowbar-api";
import {Card, CardContent, CircularProgress, Grid, Stack, Typography} from "@mui/material";
import useTranslateFunction from "shared/language/useTranslateFunction";
import LinkToDeliveryCheckbox
    from "modules/admin/worksheet/evaluation/tabs/service-calls/sap-documents/LinkToDeliveryCheckbox";
import CreateServiceCallButton
    from "modules/admin/worksheet/evaluation/tabs/service-calls/sap-documents/CreateServiceCallButton";
import NumberOnlyTextField from "shared/components/inputs/NumberOnlyTextField";
import {useSapServiceCallExistsLoopUntilFoundByDocEntry} from "crowbar-api/hooks/sap/useSapServiceCallExists";
import SapServiceCallAck from "modules/admin/worksheet/evaluation/tabs/service-calls/sap-documents/SapServiceCallAck";
import LinkEquipmentToServiceCallCheckbox
    from "modules/admin/worksheet/evaluation/tabs/service-calls/sap-documents/LinkEquipmentToServiceCallCheckbox";

export type SapServiceCallProps = {
    wEval: CDAMWMWorksheetEvalModel | null | undefined
    worksheet: CDAMWMWWorksheetQuery | null | undefined
    sc: CDAMWMServiceCallModel
    scEval: CDAMWMServiceCallEvalModel
    disabled?: boolean
    onMasterDataChange: () => Promise<void>
    deliveryDocKeys: CDSMDeliveryOrDraftExistsResult | null | undefined
}
const SapServiceCall = ({
                            wEval,
                            worksheet,
                            sc,
                            scEval,
                            disabled,
                            onMasterDataChange
                        }: SapServiceCallProps) => {
    const tk = useTranslateFunction("Admin.Worksheet")

    const sapCallId = scEval.selectedCallId

    const sapServiceCallExistsQuery = useSapServiceCallExistsLoopUntilFoundByDocEntry(sapCallId, {
        timeout: 30000,
        waitsInMilliseconds: 1000
    })

    const sapServiceCallDocNum = sapServiceCallExistsQuery.data?.docNum

    return (
        <Card variant="outlined">
            <CardContent>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="h6">{tk('ServiceCallLong')}</Typography>
                    </Grid>

                    <Grid item xs={12}>

                        <Grid container direction="row" spacing={1}>
                            <Grid item xs={2}>
                                <CreateServiceCallButton
                                    wEval={wEval}
                                    worksheet={worksheet}
                                    sc={sc}
                                    scEval={scEval}
                                    disabled={disabled}
                                    onMasterDataChange={onMasterDataChange}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <NumberOnlyTextField
                                    value={sapServiceCallDocNum ?? 0}
                                    size="small"
                                    fullWidth={true}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs="auto">
                                <SapServiceCallAck
                                    worksheet={worksheet}
                                    sc={sc}
                                    scEval={scEval}
                                />
                            </Grid>
                            <Grid item xs={true}>
                                {(sapServiceCallExistsQuery.isLoading || sapServiceCallExistsQuery.isFetching) && (
                                    <Stack direction="row" spacing={0.5} alignItems="center" justifyContent="center">
                                        <CircularProgress/>
                                        <Typography variant="caption" fontSize="small">
                                            {tk('FindingServiceCall')}
                                        </Typography>
                                    </Stack>
                                )}
                            </Grid>
                        </Grid>

                    </Grid>

                    <Grid item xs={12}>
                        <LinkToDeliveryCheckbox
                            worksheet={worksheet}
                            sc={sc}
                            scEval={scEval}
                            disabled={disabled}
                            onMasterDataChange={onMasterDataChange}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <LinkEquipmentToServiceCallCheckbox
                            worksheet={worksheet}
                            sc={sc}
                            scEval={scEval}
                            disabled={disabled}
                            onMasterDataChange={onMasterDataChange}
                        />
                    </Grid>

                </Grid>
            </CardContent>
        </Card>
    )
}

export default SapServiceCall;