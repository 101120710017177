import {JsonUtils} from "shared/utils/JsonUtils";

interface EmailAddresses {
    To: GraphEmailAddress[]
    Cc: GraphEmailAddress[]
    Bcc: GraphEmailAddress[]
}

interface GraphEmailAddress {
    EmailAddress: EmailAddress
}

interface EmailAddress {
    Address: string
    Name: string
}

export class GraphEmailAddressParser {
    public static parseAddresses(recipients: string | null | undefined) {
        const [parsed, addresses] = JsonUtils.tryParse<EmailAddresses[]>(recipients)
        if (!parsed) return undefined
        try {
            return addresses?.map(a => a?.To?.map(ta => ta?.EmailAddress?.Address)).join(',')
        } catch {
            return undefined
        }
    }
}