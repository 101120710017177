import {useQuery} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {WorksheetEvalApi} from "crowbar-api";
import AxiosCancelTokenHelper from "crowbar-api/AxiosCancelTokenHelper";
import {UseQueryOptionParams} from "crowbar-api/util/UseQueryOptionParams";

export const useWorksheetEvalFindOrCreateEvalByWorksheetId = (worksheetId: string | undefined | null, extraOptions?: UseQueryOptionParams) => {
    return useQuery(['worksheet-find-or-create-eval-by-wid-1', worksheetId], async ({signal}) => {
        if (!worksheetId) return null
        const response = await crowbarApiFactory(WorksheetEvalApi)
            .findOrCreateEvalByWorksheetId(worksheetId, AxiosCancelTokenHelper.cancelTokenFrom(signal))
        return response.data ?? null
    }, {
        retry: 1,
        refetchOnWindowFocus: false,
        refetchOnMount: true,
        networkMode: "online",
        ...extraOptions
    })
}