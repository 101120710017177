import ModSapTypeMap from "crowbar-api/enum-map/ModSapTypeMap";
import {
    SapPartnerContactSelectValue
} from "modules/worksheet/editor/ui/basic/contact-select/useSapPartnerContactSelectValuesAtom";
import SapPartnerContactUtils from "crowbar-api/util/SapPartnerContactUtils";
import {useWorksheetSaveState} from "modules/worksheet/editor/state/WorksheetSaveAtoms";
import {MenuItem, Select, SelectChangeEvent, Stack, Typography} from "@mui/material";
import {useEffect} from "react";
import WorksheetSelectedDataSourceMap from "crowbar-api/enum-map/WorksheetSelectedDataSourceMap";
import useTranslateFunction from "shared/language/useTranslateFunction";
import PhoneIcon from '@mui/icons-material/Phone';
import ShareIcon from '@mui/icons-material/Share';

interface WEditorPageBasicContactSelectProps {
    selectValues: SapPartnerContactSelectValue[]
    setSelectedByKey: (key: string) => void
    selectedKey: string | undefined

    disabled?: boolean
}

const formatMenuItemText = (v: SapPartnerContactSelectValue, tk: (key: string) => string): string => {
    if (v.source === "contact" && v.contact) {
        return `(SAP) ${SapPartnerContactUtils.contactFormat(v.contact)}`
    } else if (v.source === "mod" && v.mod) {
        return `(${v.mod.type === ModSapTypeMap.create ? tk('New') : tk('Edited')}) ${SapPartnerContactUtils.contactFormatMod(v.mod)}`
    } else {
        return "N/A"
    }
}

const renderPhone = (selected: SapPartnerContactSelectValue | undefined, onShareClick: (num: string) => void): JSX.Element[] => {
    const phoneNums: string[] = []

    if (selected?.contact) {
        if (selected.contact.phone1)
            phoneNums.push(selected.contact.phone1)
        if (selected.contact.phone2)
            phoneNums.push(selected.contact.phone2)
        if (selected.contact.phone3)
            phoneNums.push(selected.contact.phone3)
    }

    if (selected?.mod) {
        if (selected.mod.phone)
            phoneNums.push(selected.mod.phone)
    }

    return phoneNums.map((n, i) => (
        <Stack key={i} direction="row" sx={{
            display: "flex",
            alignItems: "center"
        }}>
            <Typography
                variant="body1"

                sx={{
                    marginTop: 0.5,
                    padding: 1,
                    display: "flex",
                    alignItems: "center",
                    ":hover": {
                        cursor: "pointer"
                    }
                }}
            >
                <PhoneIcon onClick={() => window.open(`tel:${n}`, '_self')}/>
                &nbsp;{n}&nbsp;
                <ShareIcon onClick={() => onShareClick(n)}/>
            </Typography>
        </Stack>

    ))
}

const WEditorPageBasicContactSelect = ({
                                           selectValues,
                                           setSelectedByKey,
                                           selectedKey,
                                           disabled
                                       }: WEditorPageBasicContactSelectProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    const [worksheetSave] = useWorksheetSaveState()

    const selected = selectValues.find(v => v.key === selectedKey)
    const selectValue = selected !== undefined ? (selectedKey ?? "") : ""

    const onCallClick = () => {
        if (!selectValue) return
        const found = selectValues.find(v => v.key === selectValue)
        if (!found) return

    }

    // automatically select sales order value if needed
    useEffect(() => {
        if (selectedKey) return
        // if nothing is selected, check if we can select based on the worksheet fields
        // (worksheet is created with sales order values if possible)
        if (worksheetSave?.contactPersonSource === WorksheetSelectedDataSourceMap.modSap &&
            worksheetSave?.modSapPartnerContact &&
            selectValues.find(v => v.key === worksheetSave?.modSapPartnerContact) !== undefined
        ) {
            setSelectedByKey(worksheetSave.modSapPartnerContact)
        } else if (
            worksheetSave?.contactPersonSource === WorksheetSelectedDataSourceMap.sapMasterData ||
            worksheetSave?.contactPersonSource === WorksheetSelectedDataSourceMap.sapSalesOrder
        ) {
            const match = selectValues.find(v =>
                v.contact?.contactCode === worksheetSave?.contactCode
            )
            if (match) {
                setSelectedByKey(match.key)
            }
        }
    }, [selectValues, selectedKey, setSelectedByKey, worksheetSave?.contactCode, worksheetSave?.contactPersonSource, worksheetSave?.modSapPartnerContact])

    const handleChange = (event: SelectChangeEvent) => {
        setSelectedByKey(event.target.value)
    }

    return (
        <Stack direction="column" spacing={0.5}>
            <Stack direction="row" spacing={0.5}>
                <Select
                    fullWidth
                    disabled={disabled || selectValues.length < 1}
                    // label={tk('Contact')}
                    onChange={handleChange}
                    value={selectValue}
                    // error={!selectedKey}
                >
                    {/* <MenuItem key={-1} value="">
                        &nbsp;
                    </MenuItem>*/}
                    {selectValues.map(v => (
                        <MenuItem key={v.key} value={v.key}>
                            {formatMenuItemText(v, tk)}
                        </MenuItem>
                    ))}
                </Select>
                {/*<IconButton onClick={onCallClick}>
                    <PhoneIcon/>
                </IconButton>*/}
            </Stack>
            {selected && renderPhone(selected, (num) => {
                if (navigator?.share) {
                    navigator.share({
                        text: num
                    }).then()
                }
            })}
        </Stack>
    )
}

export default WEditorPageBasicContactSelect