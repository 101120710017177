import {useWorksheetSaveState} from "modules/worksheet/editor/state/WorksheetSaveAtoms";
import {useWorksheetSaveService, WorksheetSaveServiceResult} from "modules/worksheet/service/useWorksheetSaveService";
import {useCustomMutation} from "shared/hook/useCustomMutation";
import {queryWorksheetAsSave} from "crowbar-api/hooks/worksheet/useWorksheetQueryAsSave";
import {findWorksheetVersionsFor} from "crowbar-api/hooks/worksheet/versions/useFindWorksheetVersionsFor";
import WorksheetStatusMap from "crowbar-api/enum-map/WorksheetStatusMap";

export interface UseWorksheetSaveMutationArgs {
    ignorePreviousVersion?: boolean
}

export interface UseWorksheetSaveMutationResult {
    differentPreviousVersionError: boolean
    hasError: boolean
    saveResult: WorksheetSaveServiceResult | undefined
}

const useWorksheetSaveMutation = () => {
    const saveService = useWorksheetSaveService()
    const [ws, setWs] = useWorksheetSaveState()

    return useCustomMutation(['worksheet-save-1'], async (args: UseWorksheetSaveMutationArgs): Promise<UseWorksheetSaveMutationResult> => {
        if (!ws?.id) throw new Error('Missing worksheet id from current state. Cannot save worksheet to server.')

        const version = (await findWorksheetVersionsFor([ws?.id]))?.find(() => true)
        if (version && version.status === WorksheetStatusMap.evaluated && version.status !== ws.status) {
            throw new Error('Cannot save worksheet. Already evaluated!')
        }

        const saveResult = await saveService.saveToServer(ws?.id, args?.ignorePreviousVersion ?? false)
        const updatedServerWorksheet = await queryWorksheetAsSave(ws?.id)
        if (!updatedServerWorksheet?.id) {
            throw new Error(`Could not load worksheet after saving it for id ${ws?.id}`)
        }

        if (!saveResult.hasError) {
            if (!saveResult?.saveResult?.version) {
                throw new Error('Error while saving. Missing versions.')
            }
            // load the saved worksheet into the atom
            setWs(prev => {
                return {
                    ...updatedServerWorksheet ?? prev,
                    version: saveResult?.saveResult?.version,
                    previousVersion: saveResult?.saveResult?.previousVersion
                }
            })
        }

        return {
            differentPreviousVersionError: saveResult.differentPreviousVersionError,
            hasError: saveResult.hasError,
            saveResult: saveResult
        }
    }, {
        networkMode: "always"
    })
}

export default useWorksheetSaveMutation