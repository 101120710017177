import {CDAMCMCrowbarBlobType} from "crowbar-api";

export default class CrowbarBlobTypeMap {
    public static userImage = CDAMCMCrowbarBlobType.NUMBER_0
    public static productImage = CDAMCMCrowbarBlobType.NUMBER_1
    public static equipmentImage = CDAMCMCrowbarBlobType.NUMBER_2
    public static audio = CDAMCMCrowbarBlobType.NUMBER_3
    public static document = CDAMCMCrowbarBlobType.NUMBER_4

    public static mapTo(s: string): CDAMCMCrowbarBlobType {
        if (this.userImage.toString() === s) {
            return this.userImage
        }
        if (this.productImage.toString() === s) {
            return this.productImage
        }
        if (this.equipmentImage.toString() === s) {
            return this.equipmentImage
        }
        if (this.audio.toString() === s) {
            return this.audio
        }
        if (this.document.toString() === s) {
            return this.document
        }
        throw new Error(`Cannot map ${s} to CrowbarBlobType. Unknown type.`)
    }
}