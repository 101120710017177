import React from 'react';
import {CDSMNNewWorksheetCustomer} from "crowbar-api";
import {Accordion, AccordionDetails, AccordionSummary, Button, Grid, Typography} from "@mui/material";
import NewWorksheetResultCustomer from "modules/worksheet/new-worksheet/results/NewWorksheetResultCustomer";
import LinearIndeterminateProgressWithLabel from "shared/components/progress/LinearIndeterminateProgressWithLabel";
import useTranslateFunction from "shared/language/useTranslateFunction";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export type CustomerResultListProps = {
    customers: CDSMNNewWorksheetCustomer[]
    customersIsLoading: boolean
    hasNextPage: boolean
    onCustomersLoadMore: () => Promise<void>
    subTablePageSize: number
}
const CustomerResultList = ({
                                customers,
                                customersIsLoading,
                                hasNextPage,
                                onCustomersLoadMore,
                                subTablePageSize
                            }: CustomerResultListProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.NewWorksheet`)

    return (
        <Accordion variant="outlined" defaultExpanded={true} sx={{width: 1}}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
            >
                <Typography fontWeight="bold">{tk('Customers')}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{width: 1}}>
                <Grid container spacing={1}>

                    {customers.map(c => (
                        <Grid item xs={12} key={'c' + c.cardCode}>
                            <NewWorksheetResultCustomer
                                customer={c}
                                pageSize={subTablePageSize}
                            />
                        </Grid>
                    ))}

                    {customersIsLoading && (
                        <Grid item xs={12} sx={{my: 3}}>
                            <LinearIndeterminateProgressWithLabel label={tk('CustomersIsLoading')}/>
                        </Grid>
                    )}

                    <Grid item xs={12} display="flex" justifyContent="center" sx={{my: 3}}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={onCustomersLoadMore}
                            disabled={customersIsLoading || !hasNextPage}
                        >
                            {tk('LoadMore')}
                        </Button>
                    </Grid>

                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

export default CustomerResultList;