import React from 'react';
import {
    CDAMWMServiceCallEvalModel,
    CDAMWMServiceCallModel,
    CDAMWMWorksheetEvalModel,
    CDAMWMWWorksheetQuery
} from "crowbar-api";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {Button, CircularProgress} from "@mui/material";
import {
    useServiceCallEvalCreateSapServiceCall
} from "crowbar-api/hooks/worksheet/evaluation/service-call/useServiceCallEvalCreateSapServiceCall";
import {toastError} from "shared/toast/DefaultToasts";
import {sapErrorMessage} from "modules/admin/worksheet/evaluation/tabs/service-calls/sap-documents/SapErrorMessage";
import ServiceCallSelectedSourceMap from "crowbar-api/enum-map/ServiceCallSelectedSourceMap";
import {StringUtils} from "shared/utils/StringUtils";

export type CreateServiceCallButtonProps = {
    wEval: CDAMWMWorksheetEvalModel | null | undefined
    worksheet: CDAMWMWWorksheetQuery | null | undefined
    sc: CDAMWMServiceCallModel
    scEval: CDAMWMServiceCallEvalModel
    disabled?: boolean
    onMasterDataChange: () => Promise<void>
}

const CreateServiceCallButton = ({
                                     wEval,
                                     worksheet,
                                     sc,
                                     scEval,
                                     disabled,
                                     onMasterDataChange
                                 }: CreateServiceCallButtonProps) => {
    const tk = useTranslateFunction('Admin.Worksheet')

    const createSapServiceCall = useServiceCallEvalCreateSapServiceCall()

    const onClick = async () => {
        // obvious validation
        if (!scEval.worksheetEvalId || !scEval.id) {
            return
        }

        // validate required data
        // equipment validation
        if (sc.selectedSource !== ServiceCallSelectedSourceMap.empty &&
            scEval.linkEquipmentToServiceCall && (
                !sc.sapEquipmentId || !scEval.equipmentAck
            )) {
            toastError(tk('AckAndRequiredToReplaceModEquipment'))
            return
        }
        // delivery link validation
        if (scEval.linkDeliveryToServiceCall && (!scEval.selectedDeliveryDocEntry || !scEval.deliveryAck)) {
            toastError(tk('DeliveryMissingError'))
            return
        }

        // check billing address
        if (StringUtils.undefinedNullOrEmpty(scEval.billingSapAddressAndType)) {
            toastError(tk('BillingAddressIsRequired'))
            return
        }

        // validate partner and partner address master data selection (required)
        if (!wEval?.partnerAck) {
            toastError(tk('PartnerAckRequired'))
            return
        }
        /*if (!wEval?.addressAck) {
            toastError(tk('AddressAckRequired'))
            return
        }*/

        // call sap service call create
        const result = await createSapServiceCall.mutateAsync({
            evalId: scEval.worksheetEvalId,
            scEvalId: scEval.id,
            linkDelivery: scEval.linkDeliveryToServiceCall ?? false
        })

        // display sap error
        if (!result.success) {
            toastError(sapErrorMessage(result), {
                autoClose: false
            })
        }

        await onMasterDataChange()
    }

    return (
        <Button
            variant="contained"
            disabled={disabled || createSapServiceCall.isLoading || !!scEval.selectedCallId}
            onClick={onClick}
            sx={{
                width: 1
            }}
        >
            {createSapServiceCall.isLoading ? (
                <CircularProgress size="28px"/>
            ) : (
                tk('CreateNew')
            )}
        </Button>
    );
};

export default CreateServiceCallButton;