import React, {useEffect} from 'react';

import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import {Badge, IconButton, IconButtonProps, Popover} from "@mui/material";
import NotificationCenter from "modules/generic/notification-center/NotificationCenter";
import {useNotificationCenter} from "react-toastify/addons/use-notification-center";

export type NotificationCenterButtonProps = IconButtonProps & {}

const NotificationCenterButton = ({...props}: NotificationCenterButtonProps) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const notificationCenter = useNotificationCenter<{}/*we do not use additional data for now*/>({
        // rehydrate from localstorage
        data: JSON.parse(localStorage.getItem("notifications") ?? '[]'),
    })

    // Save the last 100 notifications to localstorage
    useEffect(() => {
        const n = notificationCenter?.notifications
        const nSaved = n ? n.slice(Math.max(0, n.length - 100), n.length) : []
        localStorage.setItem("notifications", JSON.stringify(nSaved))
    }, [notificationCenter.notifications])

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const open = Boolean(anchorEl)

    return (
        <>
            <IconButton {...props} onClick={handleClick}>
                <Badge variant="dot" color="primary" invisible={notificationCenter.unreadCount === 0}>
                    <NotificationsNoneIcon/>
                </Badge>
            </IconButton>

            <Popover
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <NotificationCenter {...notificationCenter} />
            </Popover>
        </>
    )
}

export default NotificationCenterButton;