import React, {useState} from 'react';
import {Button, ButtonProps} from "@mui/material";
import {CDAMNMAgentNotificationConfigurationModel, CDSMSapEmployee} from "crowbar-api";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {
    AgentNotificationConfigurationDialog
} from "modules/admin/worksheet/notifications/agent-notification/results/AgentNotificationConfigurationDialog";

export type AgentNotificationConfigurationDialogButtonProps = ButtonProps & {
    mode: 'new' | 'edit'
    editConfiguration?: CDAMNMAgentNotificationConfigurationModel
    onConfigurationChange: () => void

    employees: CDSMSapEmployee[]
}
export const AgentNotificationConfigurationDialogButton = ({
                                                               mode,
                                                               editConfiguration,
                                                               onConfigurationChange,
                                                               employees,
                                                               ...props
                                                           }: AgentNotificationConfigurationDialogButtonProps) => {
    const tk = useTranslateFunction(`Admin.Worksheet`)

    const [open, setOpen] = useState(false)
    const [dialogKey, setDialogKey] = useState<number>(1)

    const onButtonClick = () => {
        setDialogKey(x => x + 1)
        setOpen(true)
    }

    const onDialogConfigurationChange = () => {
        setOpen(false)
        onConfigurationChange?.()
    }

    return (
        <>
            <Button onClick={onButtonClick} {...props}>
                {mode === "edit" ? tk('Edit') : tk('AddNew')}
            </Button>

            <AgentNotificationConfigurationDialog
                employees={employees}
                key={dialogKey}
                mode={mode}
                editConfiguration={editConfiguration}
                onDialogClose={() => setOpen(false)}
                onConfigurationChange={onDialogConfigurationChange}

                open={open}
                onClose={() => setOpen(false)}
            />
        </>
    )
}