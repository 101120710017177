import React from 'react';
import {withTranslation, WithTranslation} from "react-i18next";
import {FormikProps} from "formik";
import {IDeliveryNoteDialogFields} from "./IDeliveryNoteDialogFields";
import {
    CDAMDMDeliveryNoteModel,
    CDSMSapPartner,
    CDSMSapPartnerAddress,
    SapEmployeeApi,
    SapPartnerAddressApi,
    SapPartnerContactApi
} from "crowbar-api";
import BusinessPartnerDialog from "./delivery-note-business-partner-dialog/BusinessPartnerDialog";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {toastRequestError} from "shared/toast/DefaultToasts";
import SapMappingUtils, {SapSelectOption} from "crowbar-api/enum-map/SapMappingUtils";
import axios, {AxiosRequestConfig, CancelTokenSource} from "axios";
import CrowbarConstants from "crowbar-api/CrowbarConstants";
import {Button, Grid} from "@mui/material";
import FormikTextField from "shared/formik/FormikTextField";
import FormikAutoComplete from "shared/formik/FormikAutoComplete";
import FormikSelect from "shared/formik/FormikSelect";

interface DeliveryNoteDialogSupplierProps extends WithTranslation {
    formikProps: FormikProps<IDeliveryNoteDialogFields>
    mode: 'new' | 'edit'
    editItem?: CDAMDMDeliveryNoteModel
    onSupplierCardCodeChanged: (sCardCode?: string) => void
}

interface DeliveryNoteDialogSupplierState {
    formikProps: FormikProps<IDeliveryNoteDialogFields>
    partnerSelectDialogOpen: boolean
    partnerSelectDialogSource: string
    partnerSelectDialogSetter: (selected?: CDSMSapPartner) => void

    selectedCustomer?: CDSMSapPartner
    supplierAddresses: CDSMSapPartnerAddress[]
    supplierAddressOptions: SapSelectOption[]
    addressOptionsLoading: boolean
    supplierContactOptions: SapSelectOption[]
    contactOptionsLoading: boolean
}

const TKey = "DeliveryNote.Note.Dialog.";

class DeliveryNoteDialogSupplier extends React.Component<DeliveryNoteDialogSupplierProps, DeliveryNoteDialogSupplierState> {
    private readonly _sapPartnerAddressApi: SapPartnerAddressApi;
    private readonly _sapPartnerContactApi: SapPartnerContactApi;
    private readonly _sapEmployeeApi: SapEmployeeApi;

    private cancelTokenSource: CancelTokenSource;
    private axiosOptions: AxiosRequestConfig;

    constructor(props: Readonly<DeliveryNoteDialogSupplierProps> | DeliveryNoteDialogSupplierProps) {
        super(props);

        this.cancelTokenSource = axios.CancelToken.source();
        this.axiosOptions = {
            cancelToken: this.cancelTokenSource.token
        }

        this._sapPartnerAddressApi = crowbarApiFactory(SapPartnerAddressApi);
        this._sapPartnerContactApi = crowbarApiFactory(SapPartnerContactApi);
        this._sapEmployeeApi = crowbarApiFactory(SapEmployeeApi);

        this.state = {
            formikProps: this.props.formikProps,
            partnerSelectDialogOpen: false,
            partnerSelectDialogSource: 's',
            partnerSelectDialogSetter: () => {
            },
            supplierAddresses: [],
            supplierAddressOptions: [],
            addressOptionsLoading: false,
            supplierContactOptions: [],
            contactOptionsLoading: false,
        }
    }


    componentDidMount() {
        this.cancelTokenSource = axios.CancelToken.source();
        this.axiosOptions = {
            cancelToken: this.cancelTokenSource.token
        }
        this._patchEditValueForIssuerIfNeeded()
        this._loadAddressAndContactTimeout(true)
    }

    componentWillUnmount() {
        this.cancelTokenSource.cancel()
    }

    componentDidUpdate(prevProps: Readonly<DeliveryNoteDialogSupplierProps>, prevState: Readonly<DeliveryNoteDialogSupplierState>, snapshot?: any) {
        if (this.state.formikProps !== this.props.formikProps) {
            this.setState({
                formikProps: this.props.formikProps
            })
        }
    }

    _loadAddressOptions = () => {
        const cardCode = this.state.formikProps?.values?.supplierCardCode
        if (!cardCode) {
            this.setState({supplierAddressOptions: []})
            return
        }

        this.setState({
            addressOptionsLoading: true,
            supplierAddressOptions: []
        }, () => {
            this._sapPartnerAddressApi.findByCardCode(cardCode, this.axiosOptions).then((response: any) => {
                if (response.status !== 200) {
                    toastRequestError(this.props.t, {
                        name: "DnSupplierFormReqAddresses",
                        response: response,
                        reason: 'Http status not OK'
                    })
                    this.setState({addressOptionsLoading: false})
                    return
                }
                // OK
                // map addresses to dropdown
                const addressesToDropdown = this._mapAddressesToDropdown(response.data);
                this.setState({
                    supplierAddresses: response.data,
                    supplierAddressOptions: addressesToDropdown,
                    addressOptionsLoading: false
                })
            })
        })
    }

    _mapAddressesToDropdown(addresses: CDSMSapPartnerAddress[]): SapSelectOption[] {
        const sapAddressUtils = new SapMappingUtils(this.props.t);
        const [billing, shipping] = sapAddressUtils.mapAddressesToDropdown(addresses);
        const result: SapSelectOption[] = [];
        result.push(...billing)
        result.push(...shipping)
        return result;
    }

    _loadContactOptions = (keepPreviousOptions: boolean = false) => {
        const cardCode = this.state.formikProps?.values?.supplierCardCode
        if (!cardCode) {
            if (!keepPreviousOptions) this.setState({supplierContactOptions: []})
            return
        }

        this.setState((prevState) => {
            return {
                contactOptionsLoading: true,
                supplierContactOptions: !keepPreviousOptions ? [] : [...prevState.supplierContactOptions]
            }
        }, () => {
            const sapMappingUtils = new SapMappingUtils(this.props.t);

            if (cardCode === CrowbarConstants.PhoenixForkliftCardCode) {
                // Load Employee data
                this._sapEmployeeApi.findAll(this.axiosOptions).then(response => {
                    if (response.status !== 200) {
                        this.setState({contactOptionsLoading: false})
                        return
                    }
                    // OK
                    const empMapped = sapMappingUtils.mapEmployeeToDropdown(response.data)
                    this.setState((prevState) => {
                        const filtered = empMapped.filter(mapped => prevState.supplierContactOptions.find(supplierContact => supplierContact.key === mapped.key) === undefined)
                        return {
                            contactOptionsLoading: false,
                            supplierContactOptions: [...prevState.supplierContactOptions, ...filtered]
                        }
                    })
                })
            } else {
                // Load Business Partner Contacts
                this._sapPartnerContactApi.findByCardCode(cardCode, this.axiosOptions).then(response => {
                    if (response.status !== 200) {
                        this.setState({contactOptionsLoading: false})
                        return
                    }
                    // OK
                    const contacts = response.data;
                    const contactsMapped = sapMappingUtils.mapContactsToDropdown(contacts)
                    // Try setting the default selected contact person based on the business partner data
                    const customerContactPerson = this.state.selectedCustomer?.contactPerson;
                    if (customerContactPerson) {
                        const matched = contactsMapped.find(c => c.key?.toString() === customerContactPerson)
                        // text, not the key, coz, SAP....
                        if (matched) {
                            this.state.formikProps.setFieldValue('supplierContactName', matched?.key)
                            this.state.formikProps.setFieldValue('supplierContactNameValue', matched?.text)
                        }
                    }

                    this.setState((prevState) => {
                        const filtered = contactsMapped.filter(mapped => prevState.supplierContactOptions.find(supplierContact => supplierContact.key === mapped.key) === undefined)
                        return {
                            contactOptionsLoading: false,
                            supplierContactOptions: [...prevState.supplierContactOptions, ...filtered],
                        }
                    })
                })
            }
        })
    }

    _patchEditValueForIssuerIfNeeded = () => {
        // if edit, set value from input
        if (this.props.mode !== "edit") return
        const val = this.props.editItem?.supplierContactName ?? ''
        this.setState((prevState) => {
            if (!(prevState.supplierContactOptions.filter((o) => o.key === "edit"))) { //Temporary solution for React.StrictMode duplication
                return {
                    supplierContactOptions: [{key: 'edit', text: val}, ...prevState.supplierContactOptions]
                }
            } else {
                return null
            }
        }, () => {
            this.state.formikProps.setFieldValue('supplierContactNameValue', val)
        })
    }


    /**
     * Hack to wait for new value to be applied
     * Pending https://github.com/jaredpalmer/formik/issues/529
     */
    _loadAddressAndContactTimeout = (keepPreviousContactOptions: boolean = false) => {
        setTimeout(() => {
            this._loadAddressOptions()
            this._loadContactOptions(keepPreviousContactOptions)
        }, 50)
    }

    _openBusinessPartnerSelectDialogOpen = (setter: (selected?: CDSMSapPartner) => void) => {
        this.setState({
            partnerSelectDialogOpen: true,
            partnerSelectDialogSetter: setter
        })
    }

    _onBusinessPartnerDialogSelected = (sourceId: string, selected?: CDSMSapPartner) => {
        this.setState({
            partnerSelectDialogOpen: false,
        }, () => {
            // call the setter
            this.state?.partnerSelectDialogSetter(selected)
            // Hack to wait for new value to be applied
            this._loadAddressAndContactTimeout()
        })
    }

    _onBusinessPartnerDialogClosed = (sourceId: string) => {
        this.setState({
            partnerSelectDialogOpen: false
        })
    }

    _onAddressSelectPressed = () => {
        const m = new SapMappingUtils(this.props.t);
        const fProps = this.props.formikProps;
        const found = this.state.supplierAddresses?.find(a => m.mapAddressToKey(a) === fProps.values.supplierAddressDropdown)
        const newValue = (!found) ? '' : m.mapAddressToText(found)
        fProps.setFieldValue("supplierAddress", newValue)
    }

    render() {
        const t = this.props.t;
        const fProps = this.state.formikProps;
        return (
            <React.Fragment>
                <BusinessPartnerDialog sourceId={this.state.partnerSelectDialogSource}
                                       open={this.state.partnerSelectDialogOpen}
                                       onCancel={this._onBusinessPartnerDialogClosed}
                                       onSelected={this._onBusinessPartnerDialogSelected}
                />

                <Grid container item xs={12} md={6} sx={{paddingRight: 1}} alignItems={"flex-start"}>
                    <Grid item>
                        <input type="hidden" name="supplierCardCode" value={fProps.values.supplierCardCode}/>
                    </Grid>

                    <Grid item>
                        <h2>{t(`${TKey}Supplier`)}</h2>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={7} sm={9}>
                            <FormikTextField fieldName={"supplierCardName"}
                                             label={t(`${TKey}SupplierName`)}
                                             type={"text"}
                                             required={true}
                                             setter={(newValue) => {
                                                 fProps.setFieldValue("supplierAddressDropdown", "")
                                                 fProps.setFieldValue("supplierCardCode", "")
                                                 fProps.setFieldValue("supplierCardName", newValue)
                                                 this._loadAddressAndContactTimeout()
                                             }}
                            />
                        </Grid>

                        <Grid item xs={5} sm={3}>
                            <Button
                                variant="contained"
                                sx={{
                                    maxWidth: 1,
                                    minWidth: "80%"
                                }}
                                onClick={() => {
                                    this._openBusinessPartnerSelectDialogOpen((selected?: CDSMSapPartner) => {
                                            fProps.setFieldValue('supplierCardCode', selected?.cardCode)
                                            fProps.setFieldValue('supplierCardName', selected?.cardName)
                                            this.setState({
                                                selectedCustomer: selected ?? undefined
                                            })
                                            // Hack to wait for new value to be applied
                                            // Pending https://github.com/jaredpalmer/formik/issues/529

                                            // Callback to parent
                                            this.props.onSupplierCardCodeChanged(selected?.cardCode ?? undefined)
                                        }
                                    )
                                }
                                }
                            >
                                {t(`${TKey}Browse`)}
                            </Button>
                        </Grid>


                        {fProps.values.supplierCardCode &&
                            <Grid item xs={12}>
                                <b>SAP: {fProps.values.supplierCardCode}</b>
                            </Grid>
                        }


                        <Grid item xs={7} sm={9}>
                            <FormikSelect fieldName="supplierAddressDropdown"
                                          label={t(`${TKey}SupplierAddress`)}
                                          options={this.state.supplierAddressOptions}
                                          disabled={this.state.addressOptionsLoading}
                                          setter={(fieldName, option) => {
                                              fProps.setFieldValue(fieldName, option)
                                          }}/>
                        </Grid>

                        <Grid item xs={5} sm={3}>
                            <Button
                                variant="contained"
                                sx={{
                                    maxWidth: 1,
                                    minWidth: "80%"
                                }}
                                onClick={this._onAddressSelectPressed}
                            >{t(`${TKey}SelectBtn`)}</Button>
                        </Grid>

                        <Grid item xs={9}>
                            <FormikTextField variant="outlined"
                                             fieldName={"supplierAddress"}
                                             type={"text"}
                                             label={" "}
                                             required={true}
                                             multiline={true}
                                             rows={3}
                                             setter={(newValue) => {
                                                 fProps.setFieldValue("supplierAddress", newValue)
                                             }}/>
                        </Grid>

                        <Grid item xs={9}>
                            <FormikAutoComplete fieldName="supplierContactNameValue"
                                                label={t(`${TKey}SupplierContactName`)}
                                                options={this.state.supplierContactOptions}
                                                freeSolo={true}
                                                setter={(fieldName, key, value) => {
                                                    fProps.setFieldValue("supplierContactNameKey", key)
                                                    fProps.setFieldValue("supplierContactNameValue", value)
                                                }}/>
                        </Grid>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

export default withTranslation()(DeliveryNoteDialogSupplier);