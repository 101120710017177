import React from 'react';
import {CDAMWMWorksheetTimeItemCodeModel} from "crowbar-api";
import {Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import DateDisplay from "shared/components/date-display/DateDisplay";
import useTranslateFunction from "shared/language/useTranslateFunction";
import useDeleteWorksheetTimeItemCode
    from "crowbar-api/hooks/worksheet/worksheet-time-item-codes/useDeleteWorksheetTimeItemCode";

export interface WorksheetTimeItemCodesListProps {
    codes: CDAMWMWorksheetTimeItemCodeModel[]
    onItemCodeRemoved: () => Promise<void>
}

const WorksheetTimeItemCodesList = ({codes, onItemCodeRemoved}: WorksheetTimeItemCodesListProps) => {
    const tk = useTranslateFunction('Worksheet.Generic.WorksheetTimeItemCodes')

    const deleteItemCode = useDeleteWorksheetTimeItemCode()

    const onDelete = async (id: string | null | undefined) => {
        if (!id) return
        await deleteItemCode.mutateAsync(id)
        await onItemCodeRemoved()
    }

    return (
        <TableContainer component={Paper}>
            <Table
                size={"small"}
                sx={{
                    minWidth: {
                        xs: 1,
                        md: "450px"
                    }
                }}
            >
                <TableHead sx={{
                    backgroundColor: "background.dark"
                }}>
                    <TableRow
                        sx={{
                            backgroundColor: "grey.A200"
                        }}
                    >
                        <TableCell>ItemCode</TableCell>
                        <TableCell>{tk('Create')}</TableCell>
                        <TableCell>{tk('Update')}</TableCell>
                        <TableCell align="right">{tk('Remove')}?</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {codes.map(code => (
                        <TableRow key={code.id}
                                  sx={{
                                      ":hover": {
                                          backgroundColor: "grey.A400"
                                      }
                                  }}
                        >
                            <TableCell component="th" scope="row">{code.itemCode}</TableCell>
                            <TableCell><DateDisplay dateStr={code.createdAt ?? ""}/></TableCell>
                            <TableCell><DateDisplay dateStr={code.updatedAt ?? ""}/></TableCell>
                            <TableCell align="right">
                                {code?.id && (
                                    <Button variant="text"
                                            color="error"
                                            onClick={() => onDelete(code.id)}
                                    >
                                        {tk('Remove')}
                                    </Button>
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default WorksheetTimeItemCodesList;