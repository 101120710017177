import React from 'react';
import {EmailNotificationProps} from "modules/admin/worksheet/notifications/email-notifications/EmailNotificationProps";
import {
    useEmailNotificationListingState
} from "modules/admin/worksheet/notifications/email-notifications/state/useEmailNotificationListingState";
import {CDAMNMEmailNotificationStatus} from "crowbar-api";
import {Grid, MenuItem, Select, SelectChangeEvent, Stack, Typography} from "@mui/material";
import useTranslateFunction from "shared/language/useTranslateFunction";
import EmailNotificationStatusMap from "crowbar-api/enum-map/EmailNotificationStatusMap";
import {useTranslation} from "react-i18next";

export type EmailNotificationStatusSelectProps = EmailNotificationProps & {}

export const EmailNotificationStatusSelect = ({stateAtom}: EmailNotificationStatusSelectProps) => {
    const {t} = useTranslation()
    const tk = useTranslateFunction("Admin.Worksheet")
    const {status, setState} = useEmailNotificationListingState(stateAtom)

    const onStatusChange = (newStatus?: CDAMNMEmailNotificationStatus) => {
        setState(prev => {
            return {
                ...prev,
                status: newStatus,
                lastRowNumber: 0
            }
        })
    }

    const onSelectStatusChange = (e: SelectChangeEvent<number | string>) => {
        const val = e.target.value
        if (!isNaN(+val) && val !== "") {
            const matchingStatus = EmailNotificationStatusMap.all()
                .find(s => s.valueOf() === +val)
            if (matchingStatus !== undefined) {
                onStatusChange(matchingStatus)
                return
            }
        }

        onStatusChange(undefined)
    }

    return (
        <Stack direction="column" spacing={1} sx={{width: 1}}>
            <Typography variant="caption">{tk('Status')}</Typography>

            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Select
                        fullWidth
                        value={status?.valueOf() ?? ""}
                        onChange={onSelectStatusChange}
                    >
                        <MenuItem value="">&nbsp;</MenuItem>
                        <MenuItem value={EmailNotificationStatusMap.success.valueOf()}>
                            {t(EmailNotificationStatusMap.translationKey(EmailNotificationStatusMap.success))}
                        </MenuItem>
                        <MenuItem value={EmailNotificationStatusMap.failure.valueOf()}>
                            {t(EmailNotificationStatusMap.translationKey(EmailNotificationStatusMap.failure))}
                        </MenuItem>
                        <MenuItem value={EmailNotificationStatusMap.skipped.valueOf()}>
                            {t(EmailNotificationStatusMap.translationKey(EmailNotificationStatusMap.skipped))}
                        </MenuItem>
                    </Select>
                </Grid>
            </Grid>


        </Stack>
    )
}