import React, {useMemo} from 'react';
import {useWorksheetCallTypeFindAll} from "crowbar-api/hooks/worksheet/call-type/useWorksheetCallTypeFindAll";
import {IconButton, LinearProgress} from "@mui/material";
import MaterialReactTable, {MRT_ColumnDef} from "material-react-table";
import {CDAMWMWorksheetCallTypeModel} from "crowbar-api";
import {useSapCallTypes} from "crowbar-api/hooks/sap/useSapCallTypes";
import useTranslateFunction from "shared/language/useTranslateFunction";
import EditIcon from '@mui/icons-material/Edit';
import {useMaterialReactTableLocalization} from "i18n/material-table/useMaterialReactTableLocalization";

export type CallTypeTableProps = {
    onEditClick: (ct: CDAMWMWorksheetCallTypeModel) => void
}

const CallTypeTable = ({onEditClick}: CallTypeTableProps) => {
    const tk = useTranslateFunction("Admin.Worksheet")

    const callTypeQuery = useWorksheetCallTypeFindAll();
    const sapCallTypesQuery = useSapCallTypes();

    const mrt_locale = useMaterialReactTableLocalization()

    const columns = useMemo<MRT_ColumnDef<CDAMWMWorksheetCallTypeModel>[]>(() => [
        {
            accessorKey: "id",
            header: "Id"
        },
        {
            accessorKey: "visOrder",
            header: tk('VisOrder')
        },
        {
            accessorKey: "name",
            header: "Name",
        },
        {
            accessorFn: r => sapCallTypesQuery?.data?.find(c => c.callTypeId === r.sapCallType)?.name ?? "-",
            header: "SAP"
        },
        {
            accessorFn: r => r.substitutesTaskDescription ? tk('Yes') : tk('No'),
            header: tk('SubstitutesTaskDescription')
        },
        {
            accessorFn: r => (
                <IconButton
                    onClick={() => onEditClick(r)}
                >
                    <EditIcon/>
                </IconButton>
            ),
            header: tk('Edit')
        }
    ], [onEditClick, sapCallTypesQuery?.data, tk])

    if (callTypeQuery.isLoading || callTypeQuery.isFetching) {
        return (
            <LinearProgress/>
        )
    }

    return (
        <MaterialReactTable
            data={callTypeQuery?.data ?? []}

            columns={columns}
            localization={mrt_locale}
            initialState={{
                density: 'compact',
            }}
            rowNumberMode="original" //default
            enableGlobalFilter={false} //disable search feature
            enableColumnResizing={true}
            enableColumnFilters={false}
            enableColumnActions={false}
            enableSorting={true}
            enableFullScreenToggle={false}
            positionToolbarAlertBanner="bottom"
        />
    )
}

export default CallTypeTable;