import React, {useEffect, useState} from "react";
import ProgressiveImage from "shared/components/progressive-image/ProgressiveImage";
import {Box} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import {Lightbox, SlideImage} from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";

interface ProgressiveImageSingleAndLightboxProps {
    looksDisabled?: boolean
    queryKey: string
    thumbnailSrc?: string | undefined | null
    lightboxImages: string[]

    hideLoadingSpinner?: boolean
    disableOnClick?: boolean
}

const ProgressiveImageSingleAndLightbox = React.memo(({
                                                          looksDisabled,
                                                          lightboxImages,
                                                          queryKey,
                                                          thumbnailSrc,
                                                          hideLoadingSpinner,
                                                          disableOnClick
                                                      }: ProgressiveImageSingleAndLightboxProps) => {

    const [lightboxOpen, setLightboxOpen] = useState(false);
    // const [currentImageIndex, setCurrentIndex] = useState(0);
    const [currentLightboxImages, setCurrentLightboxImages] = useState<string[]>(lightboxImages)

    const hasLightboxImages = currentLightboxImages?.length > 0

    useEffect(() => {
        if (lightboxImages !== currentLightboxImages) {
            setCurrentLightboxImages(lightboxImages)
        }
    }, [currentLightboxImages, lightboxImages])

    /*const gotoPrevious = () =>
        currentImageIndex > 0 && setCurrentIndex(currentImageIndex - 1);

    const gotoNext = () =>
        currentImageIndex + 1 < currentLightboxImages.length &&
        setCurrentIndex(currentImageIndex + 1);*/

    const onLightboxClose = () => {
        setLightboxOpen(false)
    }

    const onImageClick = () => {
        if (hasLightboxImages && !disableOnClick) {
            setLightboxOpen(true)
        }
    }

    return (
        <>
            <Box
                sx={{
                    width: 1,
                    cursor: hasLightboxImages ? 'pointer' : 'auto',
                    filter: looksDisabled ? "grayscale(1)" : "grayscale(0)"
                }}
                onClick={onImageClick}>
                {thumbnailSrc
                    ? (
                        <ProgressiveImage queryKey={queryKey} src={thumbnailSrc}>
                            {(isLoading, imageObjectUrl, hasError) => (
                                <>
                                    {(isLoading && !hideLoadingSpinner) && (
                                        <CircularProgress sx={{width: 1, height: 1}}/>
                                    )}
                                    {!isLoading && !hasError && (
                                        <Box component="img"
                                             sx={{
                                                 width: 1,
                                                 height: 1,
                                                 objectFit: "contain"
                                             }}
                                             src={thumbnailSrc} loading={"lazy"}
                                        />
                                    )}
                                    {hasError && (
                                        <BrokenImageIcon sx={{width: 1, height: 1}}/>
                                    )}
                                </>
                            )}
                        </ProgressiveImage>
                    )
                    : (
                        <InsertPhotoIcon sx={{width: 1, height: 1}}/>
                    )
                }
            </Box>

            {currentLightboxImages.length > 0 && lightboxOpen && (
                <Lightbox
                    open={lightboxOpen}
                    close={onLightboxClose}
                    plugins={[Zoom, Thumbnails]}
                    carousel={{finite: true}}
                    zoom={{
                        maxZoomPixelRatio: 10
                    }}
                    slides={currentLightboxImages.map((src): SlideImage => {
                        return {
                            src: src,
                            width: window.screen.width,
                            height: window.screen.height
                        }
                    })}
                />
            )}
        </>
    )
})

export default ProgressiveImageSingleAndLightbox