import {useNavigate} from "react-router-dom";
import {useWorksheetEditorHeaderInfoAtom} from "modules/worksheet/editor/state/WorksheetEditorHeaderAtoms";
import {useRef} from "react";
import {toastError} from "shared/toast/DefaultToasts";

export type EventOpenWorksheetEditor = (worksheetId: string | undefined | null, serviceCallId?: string | null) => void

/**
 * Prepares the state for a new worksheet.
 * Sets the default state. "Clears" previous changes.
 * @param scope
 * @param worksheetId
 */
export const useOpenWorksheetEditor = (): EventOpenWorksheetEditor => {
    // const {t} = useTranslation()
    const navigate = useNavigate();
    const [, setInfo] = useWorksheetEditorHeaderInfoAtom()
    //const [ws, setWs] = useWorksheetSaveState()

    const func = (worksheetId: string | undefined | null, serviceCallId?: string | null) => {
        if (!worksheetId) {
            toastError('Invalid worksheet id.')
            return
        }

        // Prepare initial state
        setInfo(prev => {
            return {
                //open: false,
                selectedServiceCallId: serviceCallId ?? undefined,
                newServiceCallDialogOpen: false,
                worksheetId: worksheetId,
                showTextLines: true
            }
        })

        // Navigate to editor
        navigate(`/worksheet/edit/${worksheetId!}`)
    }

    const funcRef = useRef<EventOpenWorksheetEditor>(func)
    funcRef.current = func

    return funcRef.current
}