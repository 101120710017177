import {useQuery} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {DeliveryNoteTemplateApi} from "crowbar-api";
import AxiosCancelTokenHelper from "crowbar-api/AxiosCancelTokenHelper";

export const fetchDeliveryNoteTemplatesFindAll = async (signal: AbortSignal | undefined) => {
    const response = await crowbarApiFactory(DeliveryNoteTemplateApi).findAll(AxiosCancelTokenHelper.cancelTokenFrom(signal));
    return response.data ?? [];
}

export const useDeliveryNoteTemplatesFindAll = () => {
    return useQuery(["delivery-note-templates"], async ({signal}) => {
        return await fetchDeliveryNoteTemplatesFindAll(signal)
    }, {
        networkMode: "always",
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        cacheTime: Math.pow(10, 7),
        staleTime: Math.pow(10, 7),
        retry: 3
    })
}