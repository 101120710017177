import {CDSMSapEmployee, CDSMSapPartnerAddress, CDSMSapPartnerContact} from "crowbar-api";
import {TFunction} from "i18next";
import {isNaN} from "formik";

export interface SapSelectOption {
    key: string | number | undefined,
    text: string
    //addressType: 'B' | 'S'
    isHeader?: boolean
}

export default class SapMappingUtils {


    constructor(private t: TFunction) {
    }

    /**
     * Automatically adds the labels for shipping and billing
     * key for the addresses: [addressName]-[addressType]
     *      type: B or S
     *      name: sap required input
     *  SAP table does not have a single primary key.
     * @returns [billing, shipping] dropdown option arrays
     */
    public mapAddressesToDropdown(addresses: CDSMSapPartnerAddress[], skipHeaders?: boolean): [billingAddresses: SapSelectOption[], shippingAddresses: SapSelectOption[]] {
        const t = this.t;
        const shippingAddresses = addresses.filter(a => a.addressType === 'S');
        const billingAddresses = addresses.filter(a => a.addressType === 'B');

        const mappedShippingAddresses = shippingAddresses.map((a): SapSelectOption => {
            return {
                key: this.mapAddressToKey(a),
                text: `${a.zipCode ?? ''} ${a.city ?? ''} ${a.street ?? ''} ${a.streetNo ?? ''} ${a.block ?? ''} ${a.building ?? ''}`,
            }
        });
        const mappedBillingAddresses = billingAddresses.map((a): SapSelectOption => {
            return {
                key: this.mapAddressToKey(a),
                text: `${a.zipCode ?? ''} ${a.city ?? ''} ${a.street ?? ''} ${a.streetNo ?? ''} ${a.block ?? ''} ${a.building ?? ''}`,
            }
        });

        const resultBilling: SapSelectOption[] = [];
        if (!skipHeaders && mappedBillingAddresses.length > 0) {
            resultBilling.push({
                key: 'B', text: t('Sir.SirForm.BillingAddressShort'), isHeader: true
            });
        }
        resultBilling.push(...mappedBillingAddresses);

        const resultShipping: SapSelectOption[] = [];
        if (!skipHeaders && mappedShippingAddresses.length > 0) {
            resultShipping.push({
                key: 'S', text: t('Sir.SirForm.ShippingAddressShort'), isHeader: true
            });
        }
        resultShipping.push(...mappedShippingAddresses);

        return [resultBilling, resultShipping]
    }

    public mapAddressToKey(address: CDSMSapPartnerAddress): string {
        return `${address.addressName}-${address.addressType}`;
    }

    public mapAddressToText(a: CDSMSapPartnerAddress): string {
        const street = `${a.street ?? ""} ${a.streetNo ?? ""} ${a.block ?? ""} ${a.building ?? ""}`.trim()
        const city = `${a.zipCode ?? ""} ${a.city ?? ""}`.trim()
        const country = `${a.country ? this.t("Country." + a.country) : ""}`.trim()
        return `${street}\r\n${city}\r\n${country}`
    }

    public mapContactsToDropdown(contacts: CDSMSapPartnerContact[]): SapSelectOption[] {
        if (!contacts) return [];

        return contacts
            .filter(c => c.contactCode && !isNaN(c.contactCode))
            .map(c => {
                return {
                    key: c.contactCode ?? 0,
                    text: this.parseContactNameFrom(c)
                }
            });
    }

    public mapEmployeeToDropdown(employees: CDSMSapEmployee[]): SapSelectOption[] {
        if (!employees) return []

        return employees
            .filter(e => e.empId && !isNaN(e.empId))
            .map(e => {
                return {
                    key: e.empId ?? 0,
                    text: `${e.lastName}${e.middleName ? ` ${e.middleName}` : ''} ${e.firstName}`
                }
            })
    }

    public parseContactNameFrom(c?: CDSMSapPartnerContact): string {
        const t = this.t;
        if (!c) return t('Sir.SirForm.MissingName');

        return (c.lastName && c.firstName) ? `${c.lastName ?? ''}${c.middleName ? ` ${c.middleName} ` : ' '}${c.firstName ?? ''}` :
            c.name ?? t('Sir.SirForm.MissingName');
    }
}