export const ArrayReduceToUniqueMap = <K, V>(array: V[], keyAccessor: (e: V) => K) => {
    return Array.from(
        array.reduce(
            (map, obj) => {
                const key = keyAccessor(obj)
                if (key && !map.has(key)) {
                    map.set(key, obj)
                }

                return map
            }, new Map<K, V>()
        )
            .values()
    )
}