import useBlobUpload, {UseBlobUploadResult} from "crowbar-api/hooks/photo-rule/useBlobUpload";
import {CDAMWMServiceCallBlobType, CDAMWMWServiceCallBlobSave} from "crowbar-api";
import {useCallback} from "react";
import {WorksheetSaveFactory} from "modules/worksheet/storage/WorksheetSaveFactory";
import {toastError} from "shared/toast/DefaultToasts";
import ServiceCallBlobTypeMap from "crowbar-api/enum-map/ServiceCallBlobTypeMap";
import {useSelectedServiceCall} from "modules/worksheet/editor/shared/data/useSelectedServiceCall";
import {useServiceCallBlobsAtom} from "modules/worksheet/editor/state/atoms/useServiceCallBlobsAtom";
import {useTranslation} from "react-i18next";
import useDirectServiceCallBlobSave from "crowbar-api/hooks/worksheet/blobs/useDirectServiceCallBlobSave";
import useDirectServiceCallSave from "modules/worksheet/editor/ui/photo/useDirectServiceCallSave";
import {ObjectUtils} from "shared/utils/ObjectUtils";
import {useReplaceServiceCallBlobSave} from "crowbar-api/hooks/worksheet/blobs/useReplaceServiceCallBlobSave";

export interface ServiceCallBlobUploadResult extends UseBlobUploadResult {
    uploadAndNewServiceCallBlob: (image: File, serviceCallId: string, otherProps?: CDAMWMWServiceCallBlobSave) => Promise<CDAMWMWServiceCallBlobSave>
    runUploadForSelectedServiceCall: (file: File, otherProps?: ServiceCallBlobUploadOtherProps) => Promise<void>
    runReplaceForSelectedServiceCall: (file: File, oldBlobId: string, otherProps?: ServiceCallBlobUploadOtherProps) => Promise<void>

    cancelUpload: () => void
}

export interface ServiceCallBlobUploadOtherProps {
    'photoRuleStepId'?: string | null;
    'subOrderNum'?: number;
    'comment'?: string | null;
    'blobType'?: CDAMWMServiceCallBlobType;
}

const useServiceCallBlobUpload = (): ServiceCallBlobUploadResult => {
    const blobUpload = useBlobUpload()
    const directServiceCallSave = useDirectServiceCallSave()
    const directServiceCallBlobSave = useDirectServiceCallBlobSave()
    const replaceServiceCallBlobSave = useReplaceServiceCallBlobSave()

    const {t} = useTranslation()

    const selectedServiceCall = useSelectedServiceCall()
    const [, setBlobs] = useServiceCallBlobsAtom(selectedServiceCall?.id)

    const uploadFn = useCallback(
        async (image: File, serviceCallId: string, otherProps?: ServiceCallBlobUploadOtherProps): Promise<CDAMWMWServiceCallBlobSave> => {
            const blob = await blobUpload.upload(image)
            if (!blob?.id) {
                throw new Error('Upload returned invalid blob id.')
            }
            const newBlob = {
                ...WorksheetSaveFactory.newServiceCallBlobSave(serviceCallId, blob.id),
                ...otherProps
            }
            await directServiceCallBlobSave.mutateAsync(newBlob)
            return newBlob
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [blobUpload.upload])

    const replaceFn = useCallback(
        async (image: File, oldBlobId: string, serviceCallId: string, otherProps?: ServiceCallBlobUploadOtherProps): Promise<CDAMWMWServiceCallBlobSave> => {
            const blob = await blobUpload.upload(image)
            if (!blob?.id) {
                throw new Error('Upload returned invalid blob id.')
            }
            const newBlob = {
                ...WorksheetSaveFactory.newServiceCallBlobSave(serviceCallId, blob.id),
                ...otherProps
            }
            await replaceServiceCallBlobSave.mutateAsync({
                blobSave: newBlob,
                oldBlobId: oldBlobId,
                oldServiceCallId: serviceCallId
            })
            return newBlob
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [blobUpload.upload])

    const runForSelectedServiceCallSave = useCallback(async (
        newBlobCallBack: (selectedServiceCallId: string) => Promise<CDAMWMWServiceCallBlobSave>
    ) => {
        const tk = (key: string, args?: any) => {
            return t(`Worksheet.Generic.Editor.${key}`, args)
        }

        if (!selectedServiceCall?.id) {
            toastError(tk('NoSelectedServiceCall'))
            return
        }

        const selectedServiceCallClean = ObjectUtils.cloneDeepAndSkip(
            selectedServiceCall, ['createdAt', 'createdBy', 'updatedAt', 'updatedBy']
        )
        await directServiceCallSave.mutateAsync(selectedServiceCallClean)

        const newBlob = await newBlobCallBack(selectedServiceCall.id)

        setBlobs(prev => {
            return [
                // if we upload the same image, the blob upload will return the same blob object
                // this way, we create the same blob object again.
                // if we deleted it before, it would duplicate the entries.
                // We filter the previous blobs by key, replacing it with the new one
                ...prev.filter(pb => pb.blobId !== newBlob.blobId && pb.serviceCallId !== newBlob.serviceCallId),
                newBlob
            ]
        })
    }, [directServiceCallSave, selectedServiceCall, setBlobs, t])

    const runUploadFor = useCallback(async (file: File, otherProps?: ServiceCallBlobUploadOtherProps) => {
        if (!file) {
            return
        }

        await runForSelectedServiceCallSave(async (selectedServiceCallId) => {
            return await uploadFn(file, selectedServiceCallId, {
                blobType: ServiceCallBlobTypeMap.photoRule,
                ...otherProps
            })
        })
    }, [runForSelectedServiceCallSave, uploadFn])

    const runReplaceFor = useCallback(async (file: File, oldBlobId: string, otherProps?: ServiceCallBlobUploadOtherProps) => {
        if (!file) {
            return
        }

        await runForSelectedServiceCallSave(async (selectedServiceCallId) => {
            return await replaceFn(file, oldBlobId, selectedServiceCallId, {
                blobType: ServiceCallBlobTypeMap.photoRule,
                ...otherProps
            })
        })
    }, [runForSelectedServiceCallSave, replaceFn])

    const cancelUpload = () => {
        blobUpload.abort()
    }

    return {
        ...blobUpload,
        uploadAndNewServiceCallBlob: uploadFn,
        runUploadForSelectedServiceCall: runUploadFor,
        runReplaceForSelectedServiceCall: runReplaceFor,
        cancelUpload: cancelUpload
    }
}

export default useServiceCallBlobUpload