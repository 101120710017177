import React from 'react';
import {TextField} from "@mui/material";
import {TextFieldProps} from "@mui/material/TextField/TextField";
import {DateUtils} from "shared/utils/DateUtils";

export type NativeDateInputProps = TextFieldProps & {
    onChangeWithNativeValue?: (dateComponent: string) => void
    onChangeWithServerValue?: (dateComponent: string) => void

    onBlurWithNativeValue?: (dateComponent: string) => void
    onBlurWithServerValue?: (dateComponent: string) => void
}
/**
 * Example defaultValue:
 *  defaultValue={DateUtils.intlDateToFormat(new Date(), "fr-CA")}
 * @param onChangeWithNativeValue
 * @param onChangeWithServerValue
 * @param onBlurWithNativeValue
 * @param onBlurWithServerValue
 * @param props
 * @constructor
 */
const NativeDateInput = ({
                             onChangeWithNativeValue,
                             onChangeWithServerValue,
                             onBlurWithNativeValue,
                             onBlurWithServerValue,
                             ...props
                         }: NativeDateInputProps) => {

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value
        const json = DateUtils.dateToServerFormat(new Date(val))
        onChangeWithNativeValue?.(val)
        onChangeWithServerValue?.(json ?? "")
    }

    const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const val = e.target.value
        const json = DateUtils.dateToServerFormat(new Date(val))
        onBlurWithNativeValue?.(val)
        onBlurWithServerValue?.(json ?? "")
    }

    return (
        <TextField
            type="date"
            InputLabelProps={{
                shrink: true,
            }}
            //defaultValue={DateUtils.intlDateToFormat(new Date(), "fr-CA")}
            {...props}
            sx={{
                width: 170,
                ...props.sx
            }}
            onChange={onChange}
            onBlur={onBlur}
        />
    );
};

export default NativeDateInput;