import React from 'react';
import {useQuery} from "@tanstack/react-query";
import {StringUtils} from "shared/utils/StringUtils";

export interface ProgressiveImageProps {
    /**
     * Image query key
     */
    queryKey: string

    /**
     * the image source
     * can change and the component will update
     */
    src: string | undefined

    onError?: (e: any) => {}
    retry?: number

    children: (isLoading: boolean, imageObjectUrl: string | undefined, hasError?: boolean) => React.ReactNode
}

const ProgressiveImage = React.memo((props: ProgressiveImageProps) => {
        const query = useQuery([props.queryKey, props.src], async (): Promise<string | null> => {
            if (StringUtils.undefinedNullOrEmpty(props.src)) return null
            const response = await fetch(props.src!)
            if (response.status !== 200) throw new Error('Failed to load image')
            const blob = await response.blob();
            return URL.createObjectURL(blob)
        }, {
            staleTime: Math.pow(10, 7),
            cacheTime: Math.pow(10, 7),
            retry: props.src ? props.retry : 0,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            onError: err => {
                props.onError?.(err)
            }
        })

        return (
            <>
                {props.children(query.isLoading, query.data ?? undefined, query.isError)}
            </>
        );
    }, (prevProps, nextProps) =>
        prevProps.queryKey === nextProps.queryKey &&
        prevProps.src === nextProps.src
)

export default ProgressiveImage