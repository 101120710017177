import React from 'react';
import {CDAMWMModSapType} from "crowbar-api";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {Grid, Typography} from "@mui/material";
import ModSapTypeMap from "crowbar-api/enum-map/ModSapTypeMap";

export type PartnerDisplayProps = {
    isMod?: boolean
    modType?: CDAMWMModSapType
    'cardCode'?: string | null;
    'partnerName'?: string | null;
    'vatLocal'?: string | null;
    'vatInternational'?: string | null;
    'comment'?: string | null;
}

export const PartnerDisplay = (p: PartnerDisplayProps) => {
    const tk = useTranslateFunction('Admin.Worksheet')

    const modCreate = p.modType === ModSapTypeMap.create

    return (
        <Grid container spacing={1}>
            {p.isMod && (
                <Grid item xs={12}>
                    {modCreate ? (
                        <Typography color="red">{tk('NewPartnerOnWorksheet')}</Typography>
                    ) : (
                        <Typography color="red">{tk('EditedPartnerOnWorksheet')}</Typography>
                    )}
                </Grid>
            )}

            <Grid item xs={12}>
                <Typography>
                    {p.cardCode && (
                        <span>{p.cardCode}<br/></span>
                    )}
                    {p.partnerName}<br/>
                    {p.vatLocal && (
                        <span>{p.vatLocal}<br/></span>
                    )}
                    {p.vatInternational && (
                        <span>{p.vatInternational}<br/></span>
                    )}
                    {p.comment && (
                        <span>{p.comment}<br/></span>
                    )}
                </Typography>
            </Grid>

        </Grid>
    )
}