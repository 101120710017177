import {CDAMWMServiceCallItemRowStatus} from "crowbar-api";

export default class ServiceCallItemRowStatusMap {
    public static readonly default = CDAMWMServiceCallItemRowStatus.NUMBER_0
    public static readonly notInstalled = CDAMWMServiceCallItemRowStatus.NUMBER_1
    public static readonly installed = CDAMWMServiceCallItemRowStatus.NUMBER_2

    public static translationKey = (t?: CDAMWMServiceCallItemRowStatus): string => {

        const preKey = "Worksheet.Generic.ServiceCallItemRowStatus."

        if (t === this.default) {
            return `${preKey}Default`
        }

        if (t === this.notInstalled) {
            return `${preKey}NotInstalled`
        }

        if (t === this.installed) {
            return `${preKey}Installed`
        }

        return "Missing"
    }
}