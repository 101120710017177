import {useMutation} from "@tanstack/react-query";
import {CDAMWMWorksheetTripItemCodeModel, WorksheetTripItemCodeApi} from "crowbar-api";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";

const useSaveWorksheetTripItemCode = () => {
    return useMutation(async (o: CDAMWMWorksheetTripItemCodeModel) => {
        const response = await crowbarApiFactory(WorksheetTripItemCodeApi)
            .save(o)
        if (response.status !== 200) {
            throw new Error('Invalid status code.')
        }
    })
}

export default useSaveWorksheetTripItemCode