import {Field, FieldProps} from "formik";
import React from "react";
import TextFieldEx, {TextFieldExProps} from "shared/components/inputs/TextFieldEx";

export type FormikTextFieldProps = Omit<TextFieldExProps, 'onBlur' | 'value'> & {
    fieldName: string
    label?: string
    type?: string
    setter?: (newValue?: string) => void
}

const FormikTextField = ({
                             fieldName,
                             type,
                             setter,
                             label = "",
                             ...props
                         }: FormikTextFieldProps) => {
    return (
        <Field name={fieldName} type={type}>
            {(fieldProps: FieldProps) => (
                <TextFieldEx
                    label={`${label}`}
                    variant="outlined"
                    fullWidth
                    size="small"
                    autoComplete="off"
                    {...props}
                    InputLabelProps={{
                        shrink: true,
                        ...props.InputLabelProps
                    }}
                    value={fieldProps.field.value}
                    onBlur={(value) => {
                        if (fieldProps.field.value !== value) setter?.(value)
                    }}
                    error={!!fieldProps.meta.error}
                    helperText={fieldProps.meta.error ?? ""}
                />
            )}
        </Field>
    )
}

export default FormikTextField;