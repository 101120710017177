import {UseQueryOptionParams} from "crowbar-api/util/UseQueryOptionParams";
import {useQuery} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {ServiceCallItemRowApi} from "crowbar-api";

export const useServiceCallItemRowsFindByServiceCallId = (scId: string | null | undefined, extraOptions?: UseQueryOptionParams) => {
    return useQuery(['service-call-item-row-find-by-service-call-id-1', scId], async () => {
        if (!scId) return []
        const response = await crowbarApiFactory(ServiceCallItemRowApi)
            .findByServiceCallId(scId)
        return response.data ?? []
    }, {
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        cacheTime: Math.pow(10, 8),
        staleTime: Math.pow(10, 8),
        retry: 1,
        ...extraOptions
    })
}