import {Button} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import usePhotoRuleEditStepAdd
    from "modules/admin/worksheet/photo-rule/photo-rules/PhotoRuleEdit/usePhotoRuleEditStepAdd";
import {CDAMPMPhotoRuleStepModel} from "crowbar-api";
import {useNewAtomWithRef} from "shared/hook/useNewatomWithRef";
import {UseQueryResult} from "@tanstack/react-query";
import {useMemo} from "react";
import useTranslateFunction from "shared/language/useTranslateFunction";

export interface PhotoRuleEditStepAddButtonProps {
    ruleId: string
    stepsQuery: UseQueryResult<CDAMPMPhotoRuleStepModel[], unknown>
}

const PhotoRuleEditStepAddButton = ({
                                        ruleId,
                                        stepsQuery
                                    }: PhotoRuleEditStepAddButtonProps) => {
    const tk = useTranslateFunction(`Admin.PhotoRule`)

    const [addDisabled, setAddDisabled] = useNewAtomWithRef<boolean>(false)

    const stepAdd = usePhotoRuleEditStepAdd()

    const nextOrderNum = useMemo(() => {
        return (stepsQuery.data?.find((s, i, a) => (i + 1) === a.length)?.orderNum ?? 0) + 1
    }, [stepsQuery])

    const onAddClick = async () => {
        setAddDisabled(true)
        await stepAdd.mutateAsync({
            ruleId: ruleId,
            orderNum: nextOrderNum
        }, {
            onSettled: async () => {
                await stepsQuery.refetch()
                setAddDisabled(false)
            }
        })
    }

    return (
        <Button variant="contained"
                color="primary"
                disabled={addDisabled}
                onClick={onAddClick}
        >
            <AddIcon/>
            {tk('AddStep')}
        </Button>
    )
}

export default PhotoRuleEditStepAddButton