import React from 'react';
import IconButtonAndDebounce, {IconButtonAndDebounceProps} from "shared/components/buttons/IconButtonAndDebounce";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {SvgIconProps} from "@mui/material";
import {green} from "@mui/material/colors";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export type CheckIconButtonProps = IconButtonAndDebounceProps & {
    checkCircleIconProps?: SvgIconProps
    checked?: boolean
}

const CheckIconButton = ({checkCircleIconProps, checked, ...props}: CheckIconButtonProps) => {
    return (
        <IconButtonAndDebounce
            {...props}
            sx={{
                padding: 0,
                fontSize: "24pt",
                color: theme => checked ? green[900] : theme.palette.text.primary,
                ":hover": checked ? {} : {
                    transform: "scale(1.2)"
                },
                ...props.sx
            }}
        >
            {checked ? (
                <CheckCircleIcon
                    {...checkCircleIconProps}
                />
            ) : (
                <CheckCircleOutlineIcon
                    {...checkCircleIconProps}
                />
            )}
        </IconButtonAndDebounce>
    )
}

export default CheckIconButton