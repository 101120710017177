import {BaseLocalStorage} from "shared/local-storage/BaseLocalStorage";
import localForage from "localforage";
import {WorksheetSaveLog} from "modules/worksheet/model/WorksheetSaveLog";
import {atom} from "jotai";

export class WorksheetSaveLogStorage extends BaseLocalStorage<WorksheetSaveLog> {
    createNewInstance(): LocalForage {
        return localForage.createInstance(
            this.defaultLocalForageOptions("worksheetSaveLog")
        );
    }

    getValueKey(value: WorksheetSaveLog): string {
        if (!value.id) {
            throw new Error('Could not read of the log entry. Null or undefined.')
        }
        return value.id
    }
}

export const WorksheetSaveLogStorageAtom = atom<WorksheetSaveLogStorage>(new WorksheetSaveLogStorage())
/*
const internalWorksheetSaveLogStorageAtoms = atom<IAtomLocalStorageFactoryResult<WorksheetSaveLog>>(get =>
    get(worksheetSaveLogStorageAtom).createNewAtomsFor([])
)

export const WorksheetSaveLogStorageAtoms = atom<IAtomLocalStorageFactoryResult<WorksheetSaveLog>>(get =>
    get(internalWorksheetSaveLogStorageAtoms)
);*/