import React from 'react';
import {CDSMSapPartnerEquipment} from "crowbar-api";
import {Grid, Stack} from "@mui/material";
import LabeledField from "shared/components/typography/LabeledField";
import useTranslateFunction from "shared/language/useTranslateFunction";

export type EquipmentDetailsFieldsProps = {
    equipment: CDSMSapPartnerEquipment | undefined | null
}
const EquipmentDetailsFields = ({equipment}: EquipmentDetailsFieldsProps) => {
    const tk = useTranslateFunction("Equipment")

    if (!equipment) {
        return (
            <p>N/A</p>
        )
    }

    return (
        <Grid>
            <Grid item xs={12}>
                <Stack direction="column" spacing={0}>
                    <LabeledField hideIfValueIsMissing={true} label={tk('Manufacturer')}
                                  value={equipment.vehicleManufacturer}/>
                    <LabeledField hideIfValueIsMissing={true} label={tk('VehicleType')} value={equipment.vehicleType}/>
                    <LabeledField hideIfValueIsMissing={true} label={tk('ManufacturerSn')}
                                  value={equipment.manufacturerSn}/>
                    <LabeledField hideIfValueIsMissing={true} label={tk('InternalSn')} value={equipment.internalSn}/>
                </Stack>
            </Grid>
        </Grid>
    );
};

export default EquipmentDetailsFields;