import {useMutation} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import httpResponseFileDownload from "shared/utils/HttpResponseFileDownload";
import {DeliveryNoteTemplateApi} from "crowbar-api";

export const useDownloadAsPdf = (id?: string) => {
    return useMutation(["delivery-note-pdf"], async () => {
        const response = await crowbarApiFactory(DeliveryNoteTemplateApi).downloadAsPdf(id, {
            responseType: "arraybuffer",
            headers: {
                "Accept": "application/pdf"
            }
        });

        httpResponseFileDownload(response, "download.docx");
    })
}