import {useForm, UseFormRegisterReturn, UseFormReturn} from "react-hook-form";
import * as yup from "yup";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {useRef} from "react";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";

export interface UseNewPartnerFormResult extends UseFormReturn<PartnerFormValues, any> {
    partnerNameRegister: UseFormRegisterReturn<"partnerName">
    vatLocalRegister: UseFormRegisterReturn<"vatLocal">
    vatInternationalRegister: UseFormRegisterReturn<"vatInternational">
    commentRegister: UseFormRegisterReturn<"comment">
}

export interface PartnerFormValues {
    partnerName?: string
    vatLocal?: string
    vatInternational?: string
    comment?: string
}

const prepareFormSchema = (tk: (key: string, params?: any) => string) => {
    return yup.object({
        partnerName: yup.string()
            .required(tk('FieldIsRequired'))
            .max(100, tk('StringIsLongerThan', {max: 100})),
        vatLocal: yup.string()
            .notRequired()
            .max(50, tk('StringIsLongerThan', {max: 50})),
        vatInternational: yup.string()
            .notRequired()
            .max(50, tk('StringIsLongerThan', {max: 50})),
        comment: yup.string()
    })
}

export const useNewPartnerForm = (): UseNewPartnerFormResult => {
    const tkValidation = useTranslateFunction('Generic.Validations.')
    const formSchemaRef = useRef<any>(prepareFormSchema(tkValidation))
    formSchemaRef.current = prepareFormSchema(tkValidation)

    const form = useForm<PartnerFormValues>({
        resolver: yupResolver(formSchemaRef.current)
    })

    const partnerNameRegister = form.register("partnerName")
    const vatLocalRegister = form.register("vatLocal")
    const vatInternationalRegister = form.register("vatInternational")
    const commentRegister = form.register("comment")

    return {
        ...form,
        partnerNameRegister,
        vatLocalRegister,
        vatInternationalRegister,
        commentRegister
    }
}