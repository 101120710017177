export const i18n_header_hu = {
    "Nav": {
        "DeliveryNotes": "Szállítólevelek",
        "Worksheet": "Munkalap",
        "MyWorksheets": "Saját munkalapjaim",
        "Admin": "Admin",
        "Images": "Képek",
        "Equipments": "Gépek",
        "EquipmentImages": "Berendezések képei",
        "WorksheetTripItemCodes": "Munkalap kiszállási díj termékkódok",
        "WorksheetTimeItemCodes": "Munkalap idő termékkódok",
        "CallTypes": "Hívás típusok",
        "WorksheetAdmin": "Munkalapok kezelése",
        "MechanicAdmin": "Szerelők",
        "ProductImages": "Termékek képei",
        "PhotoRuleTemplates": "Fényképezés lépés sablonok",
        "PhotoRules": "Fényképezés szabályai",
        "SignOut": "Kilépés",
        "Notifications": "Értesítés napló",
        "CustomerNotificationConfiguration": "Vevői értesítések beállításai",
        "AgentNotificationConfiguration": "Ügyintéző értesítések beállításai",

    },
    "Sidebar": {
        "MyWorksheets": "Munkalapjaim",
        "Equipments": "Gépek",
        "Admin": "Admin",
    }
}