import React from 'react';
import {useNewWorksheetFromPartnerAction} from "modules/worksheet/editor/state/events/useNewWorksheetFromPartnerAction";
import {useOpenWorksheetEditor} from "modules/worksheet/editor/state/events/useOpenWorksheetEditor";
import {Button, CircularProgress, Stack} from "@mui/material";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {toastSuccess} from "shared/toast/DefaultToasts";
import {useMountedState} from "shared/hook/useMountedState";
import ImageIcon from 'shared/components/image-icon/ImageIcon';
import {WorksheetIconImages} from "modules/worksheet/editor/shared/component/images/WorksheetIconImages";
import CrowbarConstants from "crowbar-api/CrowbarConstants";

const NewPhoenixWorksheetButton = () => {
    const tk = useTranslateFunction(`Worksheet.Generic.NewWorksheet`)

    const newWorksheetFromPartnerAction = useNewWorksheetFromPartnerAction()
    const openWorksheetEditor = useOpenWorksheetEditor()

    const isMounted = useMountedState()

    const onClick = async () => {
        const newWorksheet = await newWorksheetFromPartnerAction.mutateAsync({
            cardCode: CrowbarConstants.PhoenixForkliftCardCode
        })
        if (isMounted()) {
            openWorksheetEditor(newWorksheet.id)
        } else {
            toastSuccess(`${tk('WorksheetCreatedSilent')}${newWorksheet.partnerCardName}`)
        }
    }

    return (
        <Button
            variant="outlined"
            onClick={onClick}
            color="primary"
            disabled={newWorksheetFromPartnerAction.isLoading}
            sx={{
                display: "block",
                textAlign: "center",
                maxWidth: "150px"
            }}
        >
            <Stack direction="column" spacing={0.5} alignItems="center">
                {newWorksheetFromPartnerAction.isLoading ? <CircularProgress/>
                    : (
                        /*<FlutterDashIcon/>*/
                        <ImageIcon image={WorksheetIconImages.frekventForkliftSmall} size={24}/>
                    )
                }
                {tk('WorksheetForPhoenix')}
            </Stack>
        </Button>
    );
};

export default NewPhoenixWorksheetButton;