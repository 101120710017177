import {Avatar, List, ListItemAvatar, ListItemButton, ListItemText} from "@mui/material"
import ClassIcon from "@mui/icons-material/Class";
import HistoryIcon from '@mui/icons-material/History';
import React from "react";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {useNavigate} from "react-router-dom";

export const AdminIndex = () => {
    const tk = useTranslateFunction(`Header.Nav`)

    const navigate = useNavigate()

    return (
        <List sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}>
            <ListItemButton onClick={() => navigate('/admin/worksheet-admin')}>
                <ListItemAvatar>
                    <Avatar>
                        <ClassIcon/>
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={tk('Worksheet')}/>
            </ListItemButton>

            <ListItemButton onClick={() => navigate('/admin/last-user-activities')}>
                <ListItemAvatar>
                    <Avatar>
                        <HistoryIcon/>
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Last user activity"/>
            </ListItemButton>


        </List>
    )
}