import React from 'react';
import {withTranslation, WithTranslation} from "react-i18next";
import {FormikProps} from "formik";
import {IDeliveryNoteDialogFields} from "./IDeliveryNoteDialogFields";
import axios, {AxiosRequestConfig, CancelTokenSource} from "axios";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {CDAMDMDeliveryNoteModel, LicensePlateApi} from "crowbar-api";
import {toastRequestError} from "shared/toast/DefaultToasts";
import {Grid} from "@mui/material";
import FormikTextField from "shared/formik/FormikTextField";
import FormikAutoComplete from 'shared/formik/FormikAutoComplete';
import {SapSelectOption} from 'crowbar-api/enum-map/SapMappingUtils';

interface DeliveryNoteDialogDeliveryFormProps extends WithTranslation {
    formikProps: FormikProps<IDeliveryNoteDialogFields>
    mode: 'new' | 'edit'
    editItem?: CDAMDMDeliveryNoteModel
}

interface DeliveryNoteDialogDeliveryFormState {
    formikProps: FormikProps<IDeliveryNoteDialogFields>

    licOptions: SapSelectOption[]
}

const TKey = "DeliveryNote.Note.Dialog.";

class DeliveryNoteDialogDeliveryForm extends React.Component<DeliveryNoteDialogDeliveryFormProps, DeliveryNoteDialogDeliveryFormState> {

    private readonly _licensePlateApi: LicensePlateApi;

    private cancelTokenSource: CancelTokenSource;
    private axiosOptions: AxiosRequestConfig;

    constructor(props: Readonly<DeliveryNoteDialogDeliveryFormProps> | DeliveryNoteDialogDeliveryFormProps) {
        super(props);

        this.cancelTokenSource = axios.CancelToken.source()
        this.axiosOptions = {
            cancelToken: this.cancelTokenSource.token
        }

        this._licensePlateApi = crowbarApiFactory(LicensePlateApi);

        this.state = {
            formikProps: this.props.formikProps,
            licOptions: []
        }
    }

    componentDidMount() {

        this.cancelTokenSource = axios.CancelToken.source()
        this.axiosOptions = {
            cancelToken: this.cancelTokenSource.token
        }

        this._patchPlateNumIfNeeded()
        this._loadPlatesAndAddToOptions()
    }

    componentDidUpdate(prevProps: Readonly<DeliveryNoteDialogDeliveryFormProps>, prevState: Readonly<DeliveryNoteDialogDeliveryFormState>, snapshot?: any) {
        if (this.state.formikProps !== this.props.formikProps) {
            this.setState({
                formikProps: this.props.formikProps
            })
        }
    }

    componentWillUnmount() {
        this.cancelTokenSource.cancel()
    }

    _loadPlatesAndAddToOptions = () => {
        this._licensePlateApi.findAll(this.axiosOptions).then(response => {
            if (response.status === 200) {
                const lPlates: SapSelectOption[] = response.data.filter(lp => lp.id !== null && lp.id !== undefined).map(lp => {
                    return {key: lp.id as string, text: lp.plateNumber ?? ''}
                })
                this.setState((prevState) => {
                    const filtered = lPlates.filter(mapped => prevState.licOptions.find(licOption => licOption.key === mapped.key) === undefined)
                    return {
                        licOptions: [...prevState.licOptions, ...filtered]
                    }
                })
            } else {
                toastRequestError(this.props.t, {
                    name: 'DnDeliveryPlates',
                    response: response,
                    reason: 'Http status not OK'
                })
            }
        })
    }

    _patchPlateNumIfNeeded = () => {
        if (this.props.mode !== "edit") return
        const val = this.props.editItem?.licensePlateNumber ?? ''
        this.setState((prevState) => {
            if (!(this.state.licOptions.filter(o => o.key === "edit"))) { //Temporary solution for React.StrictMode duplication
                return {
                    licOptions: [{key: 'edit', text: val}, ...prevState.licOptions]
                }
            } else {
                return null
            }
        }, () => {
            this.state.formikProps.setFieldValue('licensePlateNumberValue', val)
        })
    }

    render() {
        const t = this.props.t;
        const fProps = this.state.formikProps;
        return (
            <React.Fragment>
                <Grid container item xs={12} md={6}>
                    <Grid item xs={9}>
                        <FormikTextField fieldName="orderNumber" label={t(`${TKey}OrderNumber`)} type="text"
                                         setter={(newValue) => {
                                             fProps.setFieldValue("orderNumber", newValue)
                                         }}/>
                    </Grid>
                </Grid>

                <Grid container item xs={12} md={6}>
                    <Grid item xs={9}>
                        <FormikAutoComplete fieldName='licensePlateNumberValue'
                                            label={t(`${TKey}LicensePlateNumber`)}
                                            freeSolo={true}
                                            options={this.state.licOptions} setter={(fieldName, key, value) => {
                            fProps.setFieldValue('licensePlateNumberKey', key)
                            fProps.setFieldValue('licensePlateNumberValue', value)
                        }}/>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

export default withTranslation()(DeliveryNoteDialogDeliveryForm);