import {PrimitiveAtom, SetStateAction, useAtom} from "jotai";
import {UseQueryResult} from "@tanstack/react-query";
import {CDAMNMEmailNotificationSearch, CDAMNMEmailNotificationSearchParameters} from "crowbar-api";
import {useEmailNotificationSearchPage} from "crowbar-api/hooks/notification/useEmailNotificationSearchPage";
import {useEffect} from "react";
import {DateUtils} from "shared/utils/DateUtils";

export interface UseEmailNotificationListingStateResult extends EmailNotificationListingState {
    setState: (update: SetStateAction<EmailNotificationListingState>) => void

    isLoading: boolean
    isFetching: boolean

    notificationQuery: UseQueryResult<CDAMNMEmailNotificationSearch[], unknown>
}

export interface EmailNotificationListingState extends CDAMNMEmailNotificationSearchParameters {
}

const defaultWorksheetStart = DateUtils.newServerUTCDateAndAdd({year: -1})
const defaultWorksheetEnd = DateUtils.newServerUTCDateAndAdd({month: 1})

export const useEmailNotificationListingState = (stateAtom: PrimitiveAtom<EmailNotificationListingState>): UseEmailNotificationListingStateResult => {
    const [state, setState] = useAtom(stateAtom)

    // set the date filters to the default values initially
    useEffect(() => {
        setState(prev => {
            return {
                ...prev,
                worksheetStart: defaultWorksheetStart,
                worksheetEnd: defaultWorksheetEnd
            }
        })
        // We do not want to update the values even if the defaults from the state change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setState])

    const notificationQuery = useEmailNotificationSearchPage({
        search: state.search,
        source: state.source,
        lastRowNumber: state.lastRowNumber,
        limit: state.limit,
        orderByIsDesc: state.orderByIsDesc,
        showDeleted: state.showDeleted,
        worksheetStart: state.worksheetStart,
        worksheetEnd: state.worksheetEnd,
        status: state.status
    })

    return {
        ...state,
        setState,

        isLoading: notificationQuery.isLoading,
        isFetching: notificationQuery.isFetching,

        notificationQuery
    }
}