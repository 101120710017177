import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {useWorksheetSaveService} from "modules/worksheet/service/useWorksheetSaveService";
import {CDAMWMWWorksheetSave} from "crowbar-api";
import {worksheetQueryKey} from "modules/worksheet/editor/shared/data/useWorksheetLocalQuery";
import {StringUtils} from "shared/utils/StringUtils";
import {WorksheetSaveWrapper} from "modules/worksheet/storage/WorksheetSaveStorage";

/**
 * @returns {UseMutationResult}, a function allowing to change the local storage for a CDAMWMWWorksheetSave
 */
export const useWorksheetLocalChange = (): UseMutationResult<WorksheetSaveWrapper, unknown, CDAMWMWWorksheetSave, unknown> => {
    const queryClient = useQueryClient()
    const worksheetSaveService = useWorksheetSaveService()

    return useMutation(async (worksheetSave: CDAMWMWWorksheetSave) => {
        return await worksheetSaveService.saveToStorage(worksheetSave)
    }, {
        networkMode: 'offlineFirst', // important! always save even if we have no netw. connection
        onSuccess: async (_, worksheetSave) => {
            if (StringUtils.undefinedNullOrEmpty(worksheetSave.id)) {
                return
            }
            await queryClient.invalidateQueries(worksheetQueryKey(worksheetSave.id!))
        }
    })
}