import React from 'react';
import EmailNotifications from "modules/admin/worksheet/notifications/email-notifications/EmailNotifications";
import {Route, Routes} from "react-router-dom";
import CustomerNotificationConfiguration
    from "modules/admin/worksheet/notifications/customer-notification/CustomerNotificationConfiguration";
import {
    AgentNotificationConfiguration
} from "modules/admin/worksheet/notifications/agent-notification/AgentNotificationConfiguration";
import {Container} from "@mui/material";

const WorksheetNotifications = () => {
    return (
        <Container maxWidth="xl">
            <Routes>
                <Route index={true} element={<EmailNotifications/>}/>
                <Route path="customer-configuration" element={<CustomerNotificationConfiguration/>}/>
                <Route path="agent-configuration" element={<AgentNotificationConfiguration/>}/>
            </Routes>
        </Container>
    );
};

export default WorksheetNotifications;