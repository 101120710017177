import {Grid} from "@mui/material";
import PhotoRuleEditStep from "modules/admin/worksheet/photo-rule/photo-rules/PhotoRuleEdit/PhotoRuleEditStep";
import {UseQueryResult} from "@tanstack/react-query";
import {CDAMPMPhotoRuleStepModel, CDAMPMPhotoRuleStepTemplateModel} from "crowbar-api";

export interface PhotoRuleEditStepsGridProps {
    ruleId: string
    stepsQuery: UseQueryResult<CDAMPMPhotoRuleStepModel[], unknown>
    templatesQuery: UseQueryResult<CDAMPMPhotoRuleStepTemplateModel[], unknown>
}

const PhotoRuleEditStepsGrid = ({ruleId, stepsQuery, templatesQuery}: PhotoRuleEditStepsGridProps) => {
    return (
        <Grid
            container
            direction="row"
            spacing="2"
            sx={{
                width: 1
            }}>
            {stepsQuery.data?.map((step, i) => {
                const matchingTemplate = templatesQuery.data
                    ?.find(t => t.id === step.photoRuleStepTemplateId)
                return (
                    <Grid key={[step.id, i].join("-")} item xs={12}>
                        <PhotoRuleEditStep
                            ruleId={ruleId}
                            stepsQuery={stepsQuery}
                            templatesQuery={templatesQuery}
                            step={step}
                            template={matchingTemplate}
                        />
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default PhotoRuleEditStepsGrid