import {
    PartnerAddressFormResult
} from "modules/worksheet/editor/shared/component/partner-address-form/usePartnerAddressForm";
import {Box, Stack, TextField} from "@mui/material";
import useTranslateFunction from "shared/language/useTranslateFunction";


export interface PartnerAddressFormProps {
    mode: 'new' | 'edit'
    form: PartnerAddressFormResult
}

const PartnerAddressForm = ({
                                mode,
                                form
                            }: PartnerAddressFormProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    const {
        // watch,
        formState: {errors},
        zipCodeRegister,
        cityRegister,
        streetRegister,
        streetNoRegister,
        commentRegister
    } = form

    return (
        <Box sx={{
            width: 1
        }}>
            <Stack direction={"column"} spacing={1}>
                <TextField
                    inputRef={zipCodeRegister.ref}
                    error={!!errors?.zipCode?.message}
                    helperText={errors?.zipCode?.message}
                    fullWidth
                    InputLabelProps={{shrink: true}}
                    InputProps={{
                        autoComplete: "off"
                    }}
                    label={tk('ZipCode')}
                    type="number"
                    {...zipCodeRegister}
                />

                <TextField
                    inputRef={cityRegister.ref}
                    error={!!errors?.city?.message}
                    helperText={errors?.city?.message}
                    fullWidth
                    InputLabelProps={{shrink: true}}
                    InputProps={{
                        autoComplete: "off"
                    }}
                    label={tk('City')}
                    {...cityRegister}
                />

                <TextField
                    inputRef={streetRegister.ref}
                    error={!!errors?.street?.message}
                    helperText={errors?.street?.message}
                    fullWidth
                    InputLabelProps={{shrink: true}}
                    InputProps={{
                        autoComplete: "off"
                    }}
                    label={tk('StreetLine1')}
                    {...streetRegister}
                />

                <TextField
                    inputRef={streetNoRegister.ref}
                    error={!!errors?.streetNo?.message}
                    helperText={errors?.streetNo?.message}
                    fullWidth
                    InputLabelProps={{shrink: true}}
                    InputProps={{
                        autoComplete: "off"
                    }}
                    label={tk('StreetNo')}
                    {...streetNoRegister}
                />

                <TextField
                    inputRef={commentRegister.ref}
                    error={!!errors.comment?.message}
                    helperText={errors.comment?.message}
                    fullWidth
                    multiline={true}
                    rows={3}
                    InputLabelProps={{shrink: true}}
                    label={tk('Comment')}
                    {...commentRegister}
                />
            </Stack>
        </Box>
    )
}

export default PartnerAddressForm