import React, {useCallback, useState} from 'react';
import {Button, Container, Grid, Typography} from "@mui/material";
import CallTypeTable from "modules/admin/worksheet/call-types/table/CallTypeTable";
import {CDAMWMWorksheetCallTypeModel} from "crowbar-api";
import {useWorksheetCallTypeFindAll} from "crowbar-api/hooks/worksheet/call-type/useWorksheetCallTypeFindAll";
import useTranslateFunction from "shared/language/useTranslateFunction";
import CallTypeDialog from "modules/admin/worksheet/call-types/dialog/CallTypeDialog";


const WorksheetCallTypes = () => {
    const tk = useTranslateFunction('Admin.Worksheet')

    const [dialogMode, setDialogMode] = useState<'new' | 'edit'>('new')
    const [dialogOpen, setDialogOpen] = useState<boolean>(false)
    const [editCallType, setEditCallType] = useState<CDAMWMWorksheetCallTypeModel | undefined>(undefined)

    const {refetch: callTypeRefetch} = useWorksheetCallTypeFindAll();

    const onNewClick = useCallback(() => {
        setDialogMode('new')
        setEditCallType(undefined)
        setDialogOpen(true)
    }, [])

    const onEditClick = useCallback((ct: CDAMWMWorksheetCallTypeModel) => {
        setDialogMode('edit')
        setEditCallType(ct)
        setDialogOpen(true)
    }, [])

    const onCallTypeChanged = () => {
        callTypeRefetch().then()
        setDialogOpen(false)
    }

    return (
        <Container>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h3">
                        {tk('CallType')}
                    </Typography>
                    <Typography>
                        {tk('CallTypeDialogDescription')}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Button onClick={onNewClick}>
                        {tk('New')}
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    {(dialogOpen) && (
                        <CallTypeDialog
                            mode={dialogMode}
                            editCallType={editCallType}
                            open={dialogOpen}
                            onChanged={onCallTypeChanged}
                            onDialogClosed={() => setDialogOpen(false)}
                        />
                    )}
                    <CallTypeTable onEditClick={onEditClick}/>
                </Grid>

            </Grid>
        </Container>
    );
};

export default WorksheetCallTypes;