import React from 'react';
import TextArea, {TextAreaProps} from "shared/components/inputs/TextArea";
import {
    useServiceCallEvalUpdateTaskDescription
} from "crowbar-api/hooks/worksheet/evaluation/service-call/useServiceCallEvalUpdateTaskDescription";
import {CDAMWMServiceCallModel} from "crowbar-api";
import useTranslateFunction from "shared/language/useTranslateFunction";

export type TaskDescriptionTextAreaProps = {
    sc: CDAMWMServiceCallModel
    evalId: string

    textAreaProps?: Omit<TextAreaProps, 'value' | 'onBlur'>

    disabled?: boolean
}

export const TaskDescriptionTextArea = ({sc, evalId, disabled}: TaskDescriptionTextAreaProps) => {
    const tk = useTranslateFunction("Admin.Worksheet")
    const updateTaskDescription = useServiceCallEvalUpdateTaskDescription()

    const onBlur = async (newValue: string) => {
        if (newValue === sc.taskDescription) return

        await updateTaskDescription.mutateAsync({
            evalId: evalId,
            originalId: sc?.id,
            field: newValue
        })
        sc.taskDescription = newValue // instead of full refetch
    }

    return (
        <TextArea
            size="small"
            value={sc?.taskDescription}
            onBlur={onBlur}
            disabled={disabled || updateTaskDescription.isLoading}
            minRows={3}
            rows={undefined}
        />
    )
}