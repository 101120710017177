import {useQuery} from "@tanstack/react-query";
import {UseQueryOptionParams} from "crowbar-api/util/UseQueryOptionParams";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {WorksheetSapSalesOrderApi} from "crowbar-api";
import AxiosCancelTokenHelper from "crowbar-api/AxiosCancelTokenHelper";

/**
 * {@link useNewWorksheetFromSalesOrderAction also uses the same endpoint, but in a custom way.}
 */
export const useSapSalesOrderFindForWorksheetByDocEntry = (docEntry: number | undefined, extraOptions?: UseQueryOptionParams) => {
    return useQuery(['sap-sales-order-find-worksheet-by-doc-entry', docEntry], async ({signal}) => {
        if (!docEntry) return null
        const response = await crowbarApiFactory(WorksheetSapSalesOrderApi)
            .findForWorksheetByDocEntry(docEntry, AxiosCancelTokenHelper.cancelTokenFrom(signal))
        return response.data ?? null
    }, {
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        staleTime: 0,
        cacheTime: 0,
        retry: 1,
        ...extraOptions
    })
}