import {useMutation} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {CDAMWMEWorksheetEvalUpdate, WorksheetEvalApi} from "crowbar-api";
import {AxiosRequestConfig} from "axios";

export const useWorksheetEvalUpdateEval = () => {
    return useMutation(async (p: CDAMWMEWorksheetEvalUpdate, options?: AxiosRequestConfig) => {
        await crowbarApiFactory(WorksheetEvalApi)
            .updateEval(p, options)
    })
}