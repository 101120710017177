export class AppContext {

    private teamsInitialized: boolean = false;

    public setTeamsInitialized(p: boolean): void {
        this.teamsInitialized = p;
    }

    public isMicrosoftTeams(): boolean {
        return this.teamsInitialized;
    }
}

const appContext = new AppContext();

export default appContext;