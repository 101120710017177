import {useMutation} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {CCMWECreateDeliveryForEvalParameters, WorksheetEvalApi} from "crowbar-api";

export const useWorksheetEvalCreateSapDelivery = () => {
    return useMutation(async (p: CCMWECreateDeliveryForEvalParameters, options?: any) => {
        const response = await crowbarApiFactory(WorksheetEvalApi)
            .createDeliveryForEval(p, options)
        return response.data ?? null
    })
}