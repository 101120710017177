const i18n_sirI18nHu = {
    "Home": {
        "Title": "Szerviz hibabejelentések",
        "SearchLabel": "Keresés",
        "NewButtonText": "Új bejelentés",
        "EditIconTitle": "Szerkesztés",
    },
    "Select": {
        "Title": "Vevő vagy gép kiválasztása",
        "EditErrorNoId": "Hiányzó azonosító.",
        "EditErrorNoIdMsg": "Hiányzik az azonosító az URL-ből.",
        "SearchLabel": "Keresés",
        "SkipButtonText": "Kihagyás",
    },
    "SelectList": {
        "LoadMoreButtonText": "Továbbiak betöltése",
        "SelectCustomerText": "Válasszon vevőt vagy gépet (opcionális)"
    },
    "SirForm": {
        "NewTitle": "Új bejelentés",
        "EditTitle": "Bejelentés szerkesztése",
        "PartnerNameLabel": "Partner neve",
        "AddressLabel": "Cím",
        "DescriptionLabel": "Leírás",
        "ContactNameLabel": "Kapcsolattartó neve",
        "PhoneLabel": "Telefonszám",
        "EmailLabel": "E-mail",
        "VehicleManufacturerLabel": "Gép gyártója",
        "VehicleTypeLabel": "Gép típusa",
        "VehicleSnLabel": "Gyártói sorozatszám",
        "ShippingAddressShort": "Szállítási címek",
        "BillingAddressShort": "Számlázási címek",
        "AddressDropdownLabel": "SAP címek",
        "ContactDropdownLabel": "SAP kapcsolattartók",
        "SelectLabel": "Kiválaszt",
        "MissingName": "Hiányzó név",
        "SubmitNew": "Hozzáadás",
        "SubmitEdit": "Módosítás",
        "ToastSirNotFound": "Bejelentés nem található.",
    }
};
export default i18n_sirI18nHu;