import React from 'react';
import {CDAMWMWServiceCallSave} from "crowbar-api";
import {useModSapEquipments} from "modules/worksheet/editor/state/atoms/useModSapEquipments";
import WEditorEquipmentTextWithMod from "modules/worksheet/editor/ui/equipments/WEditorEquipmentTextWithMod";

export interface WEditorEquipmentTextLinesProps {
    serviceCall: CDAMWMWServiceCallSave
}

const WEditorEquipmentTextLines = ({serviceCall}: WEditorEquipmentTextLinesProps) => {

    const [modEquipments] = useModSapEquipments()

    const findMod = () => {
        return (modEquipments ?? []).find(
            m => m.id === serviceCall.modSapEquipmentId
        )
    }

    return (
        <WEditorEquipmentTextWithMod serviceCall={serviceCall} foundMod={findMod()}/>
    )
}

export default WEditorEquipmentTextLines;