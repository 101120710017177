import {matchPath, useLocation} from "react-router-dom";
import {worksheetHeaderOpenRoutes} from "modules/worksheet/editor/ui/header/WorksheetHeaderOpenRoutes";

/**
 * If the current path matches one of the worksheet editor paths that
 * are displayed as "open" (not with the fixed, compact header),
 * returns true.
 */
const useWorksheetHeaderOpenState = (): boolean => {
    const {pathname} = useLocation();

    return worksheetHeaderOpenRoutes
        .filter(r => matchPath(`/worksheet/edit/:worksheetId/${r.path}`, pathname))
        .length > 0
}

export default useWorksheetHeaderOpenState