import React, {MouseEvent} from 'react';
import {useDebounce} from "shared/hook/useDebounce";
import {IconButton, IconButtonProps} from "@mui/material";

export type IconButtonAndDebounceProps = IconButtonProps & {}

const IconButtonAndDebounce = ({onClick, ...props}: IconButtonAndDebounceProps) => {

    // debounce
    const inputDebounce100ms = useDebounce(50)

    const onButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
        inputDebounce100ms(() => {
            onClick?.(e)
        })
    }

    return (
        <IconButton {...props} onClick={onButtonClick}>
            {props.children}
        </IconButton>
    )
}

export default IconButtonAndDebounce;