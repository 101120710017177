import React from 'react';
import {Checkbox, FormControlLabel} from "@mui/material";
import {CDAMWMWorksheetEvalModel, CDAMWMWWorksheetQuery} from "crowbar-api";
import {useWorksheetUpdateSkipCustomerEmail} from "crowbar-api/hooks/worksheet/useWorksheetUpdateSkipCustomerEmail";
import useTranslateFunction from "shared/language/useTranslateFunction";

export type SkipCustomerEmailProps = {
    wEval: CDAMWMWorksheetEvalModel | null | undefined
    worksheet: CDAMWMWWorksheetQuery | null | undefined
    onDataChange: () => void
}

const SkipCustomerEmail = ({wEval, worksheet, onDataChange}: SkipCustomerEmailProps) => {
    const tk = useTranslateFunction("Admin.Worksheet")

    const updateSkipCustomerEmail = useWorksheetUpdateSkipCustomerEmail()

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!worksheet?.id) {
            return
        }
        await updateSkipCustomerEmail.mutateAsync({
            worksheetId: worksheet.id,
            skipEmail: event.target.checked
        })
        await onDataChange()
    }

    return (
        <FormControlLabel
            control={
                <Checkbox checked={worksheet?.skipCustomerEmail ?? false} onChange={handleChange}/>
            }
            label={tk("SkipCustomerEmail")}
        />
    );
};

export default SkipCustomerEmail;