import React from 'react';
import {WorksheetEditorPageBasicProps} from "modules/worksheet/editor/ui/basic/WorksheetEditorPageBasic";
import {Box, Stack, Toolbar} from "@mui/material";
import WorksheetEditorHeaderMenuButton from "modules/worksheet/editor/ui/header/WorksheetEditorHeaderMenuButton";
import WorksheetEditorHeaderMenuNav from "modules/worksheet/editor/ui/header/WorksheetEditorHeaderMenuNav";
import WorksheetEditorHeaderMenuCommon from 'modules/worksheet/editor/ui/header/WorksheetEditorHeaderMenuCommon'
import useWorksheetStatuses from "modules/worksheet/editor/state/atoms/useWorksheetStatuses";
import useTranslateFunction from "shared/language/useTranslateFunction";
import ImageIcon from "shared/components/image-icon/ImageIcon";
import {WorksheetIconImages} from "modules/worksheet/editor/shared/component/images/WorksheetIconImages";
import WEditorHeaderCloseButton from "modules/worksheet/editor/ui/header/WEditorHeaderCloseButton";

interface WorksheetEditorHeaderMenuProps extends WorksheetEditorPageBasicProps {

}

export interface WorksheetHeaderMenuItem {
    index: number
    to: string
    title: string
    text: string
    icon?: JSX.Element
    hideCheckbox?: boolean
}

const WorksheetEditorHeaderMenu = ({}: WorksheetEditorHeaderMenuProps) => {

    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    const worksheetStatuses = useWorksheetStatuses()
    // const [userId] = useUserContext()

    const headerMenuItems: WorksheetHeaderMenuItem[] = [
        {
            index: 0,
            to: "basic",
            text: tk('MenuBasic'),
            title: tk('MenuBasicTitle'),
            icon: <ImageIcon image={WorksheetIconImages.factory}/>
        },
        {
            index: 1,
            to: "equipments",
            text: tk('MenuEquipments'),
            title: tk('MenuEquipmentsTitle'),
            icon: <ImageIcon image={WorksheetIconImages.forkliftSmall}/>
        },
        {
            index: 2,
            to: "catalogue",
            text: tk('MenuCatalogue'),
            title: tk('MenuCatalogueTitle'),
            icon: <ImageIcon image={WorksheetIconImages.checklist}/>
        },
        {
            index: 3,
            to: "descriptions",
            text: tk('MenuDescription'),
            title: tk('MenuDescriptionTitle'),
            icon: <ImageIcon image={WorksheetIconImages.text}/>
        },
        {
            index: 4,
            to: "photo",
            text: tk('MenuPhoto'),
            title: tk('MenuPhotoTitle'),
            icon: <ImageIcon image={WorksheetIconImages.gallery}/>,
            hideCheckbox: true
        },
        {
            index: 5,
            to: "feedback",
            text: tk('MenuFeedback'),
            title: tk('MenuFeedbackTitle'),
            icon: <ImageIcon image={WorksheetIconImages.feedbackColorSmall}/>,
            hideCheckbox: true
        }
    ]

    return (
        <Stack direction="row" spacing={0} sx={{
            backgroundColor: "grey.A200",
            overflow: "hidden"
        }}>
            <Toolbar sx={{
                flexGrow: 1
            }}>
                <Box sx={{
                    flexGrow: 1,
                    display: {xs: "flex", sm: "none"}
                }}>
                    <WorksheetEditorHeaderMenuButton
                        headerMenuItems={headerMenuItems.filter(i => ![0, 1].includes(i.index))}/>
                    <WorksheetEditorHeaderMenuCommon headerMenuItem={headerMenuItems[0]}/>
                    <WorksheetEditorHeaderMenuCommon headerMenuItem={headerMenuItems[1]}/>
                </Box>

                <Box sx={{
                    flexGrow: 1,
                    display: {xs: "none", sm: "flex"}
                }}>
                    <WorksheetEditorHeaderMenuCommon
                        headerMenuItem={headerMenuItems[0]}
                        checked={worksheetStatuses.basicCompleted}
                    />
                    <WorksheetEditorHeaderMenuCommon
                        headerMenuItem={headerMenuItems[1]}
                        checked={worksheetStatuses.equipmentsCompleted}
                    />
                    <WorksheetEditorHeaderMenuNav
                        headerMenuItem={headerMenuItems[2]}
                        checked={worksheetStatuses.catalogueCompleted}
                    />
                    <WorksheetEditorHeaderMenuNav
                        headerMenuItem={headerMenuItems[3]}
                        checked={worksheetStatuses.descriptionCompleted}
                    />
                    <WorksheetEditorHeaderMenuNav
                        headerMenuItem={headerMenuItems[4]}
                    />
                    <WorksheetEditorHeaderMenuNav
                        headerMenuItem={headerMenuItems[5]}
                    />
                </Box>
            </Toolbar>

            <Box sx={{
                // width: "100px"
                height: 1,
                alignItems: "flex-start",
                display: "flex"
            }}>
                <WEditorHeaderCloseButton/>
            </Box>
        </Stack>

    );
};

export default WorksheetEditorHeaderMenu;