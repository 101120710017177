import React from 'react';
import {CDAMWMWServiceCallItemRowSave} from "crowbar-api";
import ConfirmDialogButton from "shared/components/buttons/ConfirmDialogButton";
import {useServiceCallItemRowsAtom} from "modules/worksheet/editor/state/atoms/useServiceCallItemRowsAtom";
import useTranslateFunction from "shared/language/useTranslateFunction";
import Button from "@mui/material/Button";

export interface WEditorPageCatalogueItemRowDeleteBtnProps {
    itemRow: CDAMWMWServiceCallItemRowSave
    disabled?: boolean
}

const WEditorPageCatalogueItemRowDeleteBtn = ({itemRow, disabled}: WEditorPageCatalogueItemRowDeleteBtnProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    const [, setRows] = useServiceCallItemRowsAtom(itemRow.serviceCallId)

    const onClick = () => {
        setRows(prev => {
            return [
                ...(prev?.filter(ir => ir.id !== itemRow.id) ?? [])
            ]
        })
    }

    return (
        <ConfirmDialogButton
            fullWidth
            onConfirm={onClick}
            buttonRender={(props, onButtonClick) => (
                <Button
                    color="error"
                    variant="outlined"
                    size="small"
                    onClick={onButtonClick}
                    disabled={disabled}
                >
                    {tk('Delete')}
                </Button>
            )}
        />
    );
};

export default WEditorPageCatalogueItemRowDeleteBtn;