import React from 'react';
import {Button} from "@mui/material";
import {useNavigate} from "react-router-dom";
import useTranslateFunction from "shared/language/useTranslateFunction";

const NewWorksheetButton = () => {
    const tk = useTranslateFunction(`Worksheet.Generic.Mechanic`)
    const navigate = useNavigate()

    const onClick = () => {
        navigate('/worksheet/new')
    }

    return (
        <Button
            variant="outlined"
            onClick={onClick}
            sx={{
                display: "block",
                textAlign: "center",
                maxWidth: "150px"
            }}
        >
            {tk('NewWorksheet')}
        </Button>
    );
};

export default NewWorksheetButton;