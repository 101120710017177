import React from 'react';

import {WorksheetHeaderMenuItem} from "modules/worksheet/editor/ui/header/WorksheetEditorHeaderMenu";
import {Box, Tab, Typography} from "@mui/material";
import {useMatch, useNavigate} from "react-router-dom";

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

interface WorksheetEditorHeaderMenuNavProps {
    headerMenuItem: WorksheetHeaderMenuItem
    checked?: boolean
    isActive?: boolean
    /**
     * After the default menu navigation.
     */
    onNavLinkClick?: (headerMenuItem: WorksheetHeaderMenuItem) => void
}

const WorksheetEditorHeaderMenuNav = ({
                                          headerMenuItem,
                                          checked,
                                          onNavLinkClick,
                                          ...props
                                      }: WorksheetEditorHeaderMenuNavProps) => {

    const navigate = useNavigate()
    const match = useMatch(`/worksheet/edit/:worksheetId/${headerMenuItem.to}`)
    const currentlyIsActive = !!match || props.isActive

    const onNavLinkClickDefault = () => {
        window.scrollTo(0, 0)
        navigate(headerMenuItem.to)
        if (onNavLinkClick) {
            onNavLinkClick(headerMenuItem)
        }
    }

    return (
        <Box sx={{
            position: "relative"
        }}>
            <Tab
                disableRipple={true}
                onClick={onNavLinkClickDefault}
                icon={headerMenuItem.icon}
                title={headerMenuItem.title}
                label={
                    <>
                        <Typography sx={{
                            fontSize: "9pt"
                        }}>
                            {headerMenuItem.text}
                        </Typography>
                        {!headerMenuItem.hideCheckbox && (
                            <Box
                                sx={{
                                    position: "absolute",
                                    right: 2,
                                    top: 2
                                }}
                            >
                                {checked ? <CheckBoxIcon sx={{
                                        fontSize: "11pt",
                                        color: "secondary.main"
                                    }}
                                    /> :
                                    <CheckBoxOutlineBlankIcon sx={{fontSize: "11pt"}}/>}
                            </Box>
                        )}
                    </>
                }
                sx={{
                    // backgroundColor: theme => isActive ? darken(theme.palette.grey.A400, 0.1) : "transparent",
                    /*borderLeft: isActive ? "1px solid" : "0px",
                    borderTop: isActive ? "1px solid" : "0px",
                    borderRight: isActive ? "1px solid" : "0px",*/
                    // borderColor: "primary",
                    backgroundColor: currentlyIsActive ? "background.paper" : "transparent",
                    opacity: 1,
                    // boxShadow: currentlyIsActive ? "0px -10px 8px rgba(0,0,0,0.35), 0px 8px 0px 1px rgb(255,255,255, 1.0)" : ""
                    // boxShadow: currentlyIsActive ? "0px -5px 5px rgba(0,0,0,0.35)" : ""
                }}
            />
        </Box>
    )
}

export default WorksheetEditorHeaderMenuNav;