import {atom, SetStateAction, useAtom} from "jotai";
import {useCallback} from "react";
import {focusAtom} from "jotai-optics";


export interface WEditorStatusDrawerArgs {
    open: boolean
}

export interface UseWEditorStatusDrawerArgsAtomResult {
    args: WEditorStatusDrawerArgs
    setArgs: (update: SetStateAction<WEditorStatusDrawerArgs>) => void
    open: boolean
    setOpen: (update: SetStateAction<boolean>) => void
    reset: () => void
}

const initialArgs = {
    open: false
}

const argsAtom = atom<WEditorStatusDrawerArgs>(initialArgs)
const argsOpenAtom = focusAtom(argsAtom, optic => optic.prop('open'))

const useWEditorStatusDrawerArgsAtom = (): UseWEditorStatusDrawerArgsAtomResult => {
    const [args, setArgs] = useAtom(argsAtom)
    const [open, setOpen] = useAtom(argsOpenAtom)

    const reset = useCallback(() => {
        setArgs(initialArgs)
    }, [setArgs])

    return {
        args,
        setArgs,
        open,
        setOpen,
        reset
    }
}

export default useWEditorStatusDrawerArgsAtom