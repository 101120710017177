import {useParams} from "react-router-dom";
import {
    useWorksheetEvalFindOrCreateEvalByWorksheetId
} from "crowbar-api/hooks/worksheet/evaluation/worksheet/useWorksheetEvalFindOrCreateEvalByWorksheetId";
import {useWorksheetQuery} from "crowbar-api/hooks/worksheet/useWorksheetQuery";

export const useWEvalQueries = () => {
    const {worksheetId} = useParams()

    const wEvalQuery = useWorksheetEvalFindOrCreateEvalByWorksheetId(worksheetId, {
        refetchOnMount: false,
        refetchOnWindowFocus: true,
        cacheTime: Math.pow(10, 8),
        staleTime: Math.pow(10, 8),
        keepPreviousData: true
    })
    const worksheetQuery = useWorksheetQuery(worksheetId, {
        refetchOnMount: false,
        refetchOnWindowFocus: true,
        cacheTime: Math.pow(10, 8),
        staleTime: Math.pow(10, 8),
        keepPreviousData: true
    })

    return {
        wEvalQuery,
        worksheetQuery
    }
}