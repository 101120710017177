import React from 'react';
import WorksheetAdminRoutes from "modules/admin/worksheet/WorksheetAdminRoutes";

const WorksheetAdminDashboard = () => {
    // const tk = useTranslateFunction('Header.Nav')

    return (
        <WorksheetAdminRoutes/>
    );
};

export default WorksheetAdminDashboard;