import {useMutation} from "@tanstack/react-query";
import {CDAMWMWServiceCallBlobSave, ServiceCallBlobApi} from "crowbar-api";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";


const useDirectServiceCallBlobSave = () => {
    return useMutation(async (blobSave: CDAMWMWServiceCallBlobSave) => {
        await crowbarApiFactory(ServiceCallBlobApi).saveServiceCallBlobDirectly(blobSave)
    })
}

export default useDirectServiceCallBlobSave