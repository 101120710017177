import {useSelectedServiceCall} from "modules/worksheet/editor/shared/data/useSelectedServiceCall";
import {
    useFirstWorksheetSapSalesOrderAtom
} from "modules/worksheet/editor/state/atoms/useFirstWorksheetSapSalesOrderAtom";
import {useServiceCallItemRowsAtom} from "modules/worksheet/editor/state/atoms/useServiceCallItemRowsAtom";
import {useWorksheetEditorHeaderInfoAtom} from "modules/worksheet/editor/state/WorksheetEditorHeaderAtoms";
import WorksheetSapSalesOrderRowTypeMap from "crowbar-api/enum-map/WorksheetSapSalesOrderRowTypeMap";
import {CDAMWMWServiceCallItemRowSave, CDAMWMWWorksheetSapSalesOrderRowSave} from "crowbar-api";
import {byPropertiesOf} from "shared/utils/ArraySort";

export const useServiceCallItemRowsAndTextLines = (): (CDAMWMWServiceCallItemRowSave | CDAMWMWWorksheetSapSalesOrderRowSave)[] => {
    const selectedServiceCall = useSelectedServiceCall()
    const [salesOrder] = useFirstWorksheetSapSalesOrderAtom()
    const [itemRows] = useServiceCallItemRowsAtom(selectedServiceCall?.id)

    const [info] = useWorksheetEditorHeaderInfoAtom()
    const showTextRows = info.showTextLines === true

    const textRows = !showTextRows ? [] : (salesOrder?.rows ?? [])
        .filter(sor => sor.rowType === WorksheetSapSalesOrderRowTypeMap.text)

    return ([
        ...(itemRows ?? []),
        ...textRows
    ]).sort(byPropertiesOf<CDAMWMWServiceCallItemRowSave>(['visOrder']))
}