import React from 'react';
import TextArea, {TextAreaProps} from "shared/components/inputs/TextArea";
import {CDAMWMServiceCallModel} from "crowbar-api";
import {Box, Grid, Typography} from "@mui/material";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {
    useServiceCallEvalUpdateFaultDescription
} from "crowbar-api/hooks/worksheet/evaluation/service-call/useServiceCallEvalUpdateFaultDescription";

export type FaultDescriptionTextAreaProps = {
    sc: CDAMWMServiceCallModel
    evalId: string

    textAreaProps?: Omit<TextAreaProps, 'value' | 'onBlur'>

    disabled?: boolean
}

export const FaultDescriptionTextArea = ({sc, evalId, disabled}: FaultDescriptionTextAreaProps) => {
    const tk = useTranslateFunction("Admin.Worksheet")
    const updateFaultDescription = useServiceCallEvalUpdateFaultDescription()

    const onBlur = async (newValue: string) => {
        if (newValue === sc.faultDescription) return

        await updateFaultDescription.mutateAsync({
            evalId: evalId,
            originalId: sc?.id,
            field: newValue
        })
        sc.faultDescription = newValue // instead of full refetch
    }

    return (
        <Grid container spacing={1}>

            <Grid item xs={12}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs="auto">
                        <Box height="40px" display="flex" alignItems="center">
                            <Typography variant="body2">{tk('FaultDescription')}</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <TextArea
                    size="small"
                    value={sc?.faultDescription}
                    onBlur={onBlur}
                    disabled={disabled || updateFaultDescription.isLoading}
                    minRows={3}
                    rows={undefined}
                />
            </Grid>

        </Grid>
    )
}