import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';

// twitter boostrap
//import 'bootstrap/dist/css/bootstrap.min.css';
//import 'bootstrap-icons/font/bootstrap-icons.css';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// eslint-disable-next-line @typescript-eslint/no-unused-vars
//import 'bootstrap/dist/js/bootstrap.bundle.min';
// sentry
import {BrowserTracing} from "@sentry/tracing";
import {HashRouter} from 'react-router-dom';
// yet-another-react-lightbox
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

if (process.env.NODE_ENV === "production") {
    Sentry.init({
        dsn: "https://711d4c2944c44059ab8e140f071ed0b8@o1143657.ingest.sentry.io/6204524",
        integrations: [new BrowserTracing()],
        environment: process.env.NODE_ENV ?? 'N/A',
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <HashRouter>
            <App/>
        </HashRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Kendo
//FunnyKendoLicensingTool();
