import {useMutation} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {PhotoRuleStepTemplateApi} from "crowbar-api";
import axios, {AxiosError} from "axios";

export class PhotoStepTemplateDeleteError extends Error {

    public type: 'in-use' | undefined = undefined

    constructor(type: 'in-use', message: string) {
        super(message);
        this.type = type
    }
}

const usePhotoStepTemplateDelete = () => {
    return useMutation(async (templateId: string) => {
        try {
            const response = await crowbarApiFactory(PhotoRuleStepTemplateApi).deletePermanentlyById(templateId)
            if (response.status !== 200) {
                throw new Error('Cant delete template.')
            }
        } catch (e) {
            if (axios.isAxiosError(e)) {
                const error: AxiosError = e
                if (error.response?.status === 409) {
                    throw new PhotoStepTemplateDeleteError('in-use', 'Template is still in use.')
                }
            }
            throw e
        }
    })
}

export default usePhotoStepTemplateDelete