import React from 'react';
import {
    MechanicListingStateProps,
    useMechanicListingState
} from "modules/worksheet/mechanic/state/useMechanicListingState";
import {Grid} from "@mui/material";
import {ResultEntryModeWorksheet} from "modules/admin/worksheet/worksheet-list/results/ResultEntryModeWorksheet";
import {ArrayReduceToUniqueMap} from "shared/utils/ArrayReduceToUniqueMap";

export type WorksheetListResultListProps = MechanicListingStateProps & {}
export const WorksheetListResultList = ({mechanicStateAtom}: WorksheetListResultListProps) => {

    const {
        worksheetInfQuery
    } = useMechanicListingState(mechanicStateAtom)

    // reduce needed to filter duplicates from paging (if a new worksheet added during paging!)
    const entries = ArrayReduceToUniqueMap(
        worksheetInfQuery.data?.pages?.flatMap(p => p) ?? [],
        e => e.id
    )

    const onStateChanged = async () => {
        await worksheetInfQuery.refetch()
    }

    return (
        <Grid container spacing={1}>
            {entries.map(w => (
                <Grid item xs={12} key={w.id}>
                    <ResultEntryModeWorksheet
                        worksheet={w}
                        onStateChanged={onStateChanged}
                    />
                </Grid>
            ))}
        </Grid>
    )
}