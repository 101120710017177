import {CDAMWMWServiceCallSave} from "crowbar-api";
import {useWorksheetEditorHeaderInfoAtom} from "modules/worksheet/editor/state/WorksheetEditorHeaderAtoms";
import {useWorksheetSaveState} from "modules/worksheet/editor/state/WorksheetSaveAtoms";

export const useSelectedServiceCall = (): CDAMWMWServiceCallSave | undefined => {
    const [info] = useWorksheetEditorHeaderInfoAtom()
    const [worksheetSave] = useWorksheetSaveState()
    /*const worksheetQuery = useWorksheetLocalQuery(info.worksheetId)

    const flagsOk = !worksheetQuery.isLoading && !worksheetQuery.isError && worksheetQuery.isSuccess &&
        worksheetQuery.data?.serviceCalls &&
        worksheetQuery.data?.serviceCalls.length > 0 &&
        info.selectedServiceCallId !== undefined
    if (!flagsOk) {
        return undefined
    }

    return worksheetQuery.data?.serviceCalls?.find(sc => sc.id === info.selectedServiceCallId)*/
    return worksheetSave?.serviceCalls?.find(sc => sc.id === info.selectedServiceCallId)
}