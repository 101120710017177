import {useQuery} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {PhotoRuleApi} from "crowbar-api";
import AxiosCancelTokenHelper from "crowbar-api/AxiosCancelTokenHelper";

const usePhotoRuleDetailsById = (photoRuleId: string | null | undefined) => {
    return useQuery(['photo-rule-details-by-id', photoRuleId], async (context) => {
        if (!photoRuleId) return null
        const response = await crowbarApiFactory(PhotoRuleApi)
            .ruleDetailsById(photoRuleId, AxiosCancelTokenHelper.cancelTokenFrom(context.signal))
        if (response.status === 404) {
            return null
        }
        if (response.status !== 200) {
            throw new Error('Failed to query photo details by id.: ' + photoRuleId)
        }
        return response.data
    }, {
        networkMode: 'online', // will not fire unless we have network
        cacheTime: Math.pow(10, 7),
        staleTime: Math.pow(10, 7),
        refetchOnMount: false,
        refetchOnWindowFocus: false
    })
}

export default usePhotoRuleDetailsById