import {useQuery} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {SapPartnerAddressApi} from "crowbar-api";
import AxiosCancelTokenHelper from "crowbar-api/AxiosCancelTokenHelper";
import {UseQueryOptionParams} from "crowbar-api/util/UseQueryOptionParams";

const useSapPartnerAddressesByCardCode = (cardCode: string | null | undefined, extraOptions?: UseQueryOptionParams) => {
    return useQuery(['sap-partner-address-by-cardCode', cardCode], async (context) => {
        if (!cardCode) {
            return []
        }
        const response = await crowbarApiFactory(SapPartnerAddressApi, undefined, true)
            .findByCardCode(cardCode, AxiosCancelTokenHelper.cancelTokenFrom(context.signal))
        if (response.status !== 200) {
            throw new Error('Response type is not ok.')
        }
        return response.data
    }, {
        cacheTime: Math.pow(10, 7),
        staleTime: Math.pow(10, 7),
        placeholderData: [],
        retry: 1,
        ...extraOptions
    })
}

export default useSapPartnerAddressesByCardCode