import {useMutation} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {WorksheetTimeItemCodeApi} from "crowbar-api";

const useDeleteWorksheetTimeItemCode = () => {
    return useMutation(async (id: string) => {
        const response = await crowbarApiFactory(WorksheetTimeItemCodeApi).deletePermanently(id)
        if (response.status !== 200) {
            throw new Error('Invalid status code.')
        }
    })
}

export default useDeleteWorksheetTimeItemCode