import {PrimitiveAtom, SetStateAction, useAtom} from "jotai";
import {
    CDAMNMCustomerNotificationConfigurationSearch,
    CDAMNMCustomerNotificationConfigurationSearchParameters
} from "crowbar-api";
import {UseQueryResult} from "@tanstack/react-query";
import {
    useCustomerNotificationConfigurationSearchPage
} from "crowbar-api/hooks/notification/useCustomerNotificationConfigurationSearchPage";

export interface UseCustomerNotificationConfigurationStateResult extends CustomerNotificationConfigurationState {
    setState: (update: SetStateAction<CustomerNotificationConfigurationState>) => void

    isLoading: boolean
    isFetching: boolean

    configurationQuery: UseQueryResult<CDAMNMCustomerNotificationConfigurationSearch[], unknown>
}

export interface CustomerNotificationConfigurationState extends CDAMNMCustomerNotificationConfigurationSearchParameters {

}

export const useCustomerNotificationConfigurationState = (stateAtom: PrimitiveAtom<CustomerNotificationConfigurationState>): UseCustomerNotificationConfigurationStateResult => {
    const [state, setState] = useAtom(stateAtom)

    const configurationQuery = useCustomerNotificationConfigurationSearchPage({
        search: state.search,
        lastRowNumber: state.lastRowNumber,
        limit: state.limit,
        orderByIsDesc: state.orderByIsDesc,
        showDeleted: state.showDeleted
    })

    return {
        ...state,
        setState,

        isLoading: configurationQuery.isLoading,
        isFetching: configurationQuery.isFetching,

        configurationQuery
    }
}