import React from 'react';
import {CDAMWMWorksheetEvalModel, CDAMWMWWorksheetQuery} from "crowbar-api";
import {Stack} from "@mui/material";
import OverrideEmailAddressesCheckbox
    from "modules/admin/worksheet/evaluation/tabs/master-data/ExtraOptions/OverrideEmailAddressesCheckbox";
import useTranslateFunction from "shared/language/useTranslateFunction";
import EmailAddressInput from "modules/admin/worksheet/evaluation/tabs/master-data/ExtraOptions/EmailAddressInput";

export type OverrideEmailAddressesProps = {
    wEval: CDAMWMWorksheetEvalModel | null | undefined
    worksheet: CDAMWMWWorksheetQuery | null | undefined
    onDataChange: () => void
}
const OverrideEmailAddresses = ({wEval, worksheet, onDataChange}: OverrideEmailAddressesProps) => {
    const tk = useTranslateFunction("Admin.Worksheet")


    // checkbox -> enable disable allows to edit the textbox -> copy textbox from the other admin input mybe?

    return (
        <Stack direction="column" spacing={1}>
            <OverrideEmailAddressesCheckbox worksheet={worksheet} onDataChange={onDataChange}/>
            <EmailAddressInput worksheet={worksheet} onDataChange={onDataChange}/>
        </Stack>
    );
};

export default OverrideEmailAddresses;