import {useWorksheetSaveState} from "modules/worksheet/editor/state/WorksheetSaveAtoms";
import {CDAMWMWModSapEquipmentSave, CDAMWMWServiceCallSave} from "crowbar-api";
import {useCallback} from "react";
import {WorksheetSaveFactory} from "modules/worksheet/storage/WorksheetSaveFactory";
import ModSapTypeMap from "crowbar-api/enum-map/ModSapTypeMap";

export type UseAddNewModSapEquipmentResult = (serviceCall: CDAMWMWServiceCallSave) => CDAMWMWModSapEquipmentSave

const useAddNewModSapEquipment = (): UseAddNewModSapEquipmentResult => {
    const [, setWorksheet] = useWorksheetSaveState()

    return useCallback((serviceCall: CDAMWMWServiceCallSave) => {
        const newMod: CDAMWMWModSapEquipmentSave = {
            ...WorksheetSaveFactory.newModSapEquipmentSave(ModSapTypeMap.update),
            sapEquipmentId: serviceCall.sapEquipmentId,
            manufacturerSn: serviceCall.manufacturerSn,
            internalSn: serviceCall.internalSn,
            manufacturer: serviceCall.manufacturer,
            vehicleType: serviceCall.vehicleType,
            yearOfManufacture: serviceCall.yearOfManufacture,
            comment: serviceCall.comment
        }
        setWorksheet(prev => {
            const foundSc = prev?.serviceCalls?.find(sc => sc.id === serviceCall.id)
            if (foundSc) {
                foundSc.modSapEquipmentId = newMod.id
            }
            return {
                ...prev,
                modSapEquipments: [
                    ...(prev?.modSapEquipments ?? []),
                    newMod
                ]
            }
        })
        return newMod
    }, [setWorksheet])
}

export default useAddNewModSapEquipment