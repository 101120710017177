import BasicCompletedValidation, {
    RequiredFieldsForWorksheetBasicValidation
} from "modules/worksheet/editor/shared/validation/BasicCompletedValidation";
import CatalogueCompletedValidation, {
    RequiredFieldsForWorksheetCatalogueValidation,
    RequiredServiceCallFieldsForWorksheetCatalogueValidation
} from "modules/worksheet/editor/shared/validation/CatalogueCompletedValidation";
import DescriptionCompletedValidation, {
    RequiredFieldsForDescriptionValidation,
    RequiredServiceCallFieldsForDescriptionValidation
} from "modules/worksheet/editor/shared/validation/DescriptionCompletedValidation";
import EquipmentsCompletedValidation, {
    RequiredFieldsForEquipmentsValidation,
    RequiredFieldsForServiceCallEquipmentsValidation
} from "modules/worksheet/editor/shared/validation/EquipmentsCompletedValidation";
import {IsTripMatchItemRow} from "crowbar-api/hooks/worksheet/validations/useIsTripMatchFn";
import {StringUtils} from "shared/utils/StringUtils";

export interface ServiceCallStatus {
    id: string
    catalogueCompleted: boolean
    descriptionCompleted: boolean
    hasFaultDescription: boolean
    hasPhoto: boolean

    taskDescriptionIsRequired: boolean
}

export interface WorksheetValidationResult {
    basicCompleted: boolean
    equipmentsCompleted: boolean
    catalogueCompleted: boolean
    descriptionCompleted: boolean

    /**
     * If all validations passed
     */
    valid: boolean

    serviceCallStatuses: ServiceCallStatus[]
}

// Type magic

export type RequiredFieldsForWorksheetCompletenessValidation =
    Omit<RequiredFieldsForWorksheetBasicValidation, "serviceCalls"> &
    Omit<RequiredFieldsForWorksheetCatalogueValidation, "serviceCalls"> &
    Omit<RequiredFieldsForDescriptionValidation, "serviceCalls"> &
    Omit<RequiredFieldsForEquipmentsValidation, "serviceCalls"> & {
    serviceCalls?: RequiredServiceCallFieldsForWorksheetCompletenessValidation[]

}

export type RequiredServiceCallFieldsForWorksheetCompletenessValidation =
    RequiredServiceCallFieldsForWorksheetCatalogueValidation &
    RequiredServiceCallFieldsForDescriptionValidation &
    RequiredFieldsForServiceCallEquipmentsValidation & {
    id?: string | null
    faultDescription?: string | null
    blobs?: any[]
    callTypeId?: number | null
}

export class WorksheetValidationUtils {
    public static validateCompletenessFor = (ws?: RequiredFieldsForWorksheetCompletenessValidation,
                                             isTripFn?: (itemRow: IsTripMatchItemRow) => (boolean | undefined),
                                             taskDescriptionRequiredFn?: (currentCallTypeId: (number | null | undefined)) => boolean): WorksheetValidationResult => {
        const scStatuses = (ws?.serviceCalls ?? []).map(
            (sc): ServiceCallStatus => {
                const taskDescriptionIsRequired = taskDescriptionRequiredFn?.(sc.callTypeId) ?? true
                return {
                    id: sc.id ?? "",
                    catalogueCompleted: CatalogueCompletedValidation.isCompletedFor(sc),
                    descriptionCompleted: DescriptionCompletedValidation.isCompletedFor(sc),
                    hasFaultDescription: StringUtils.notUndefinedNullAndEmpty(sc.faultDescription),
                    hasPhoto: (sc.blobs ?? []).length > 0,
                    taskDescriptionIsRequired
                }
            })

        const b = BasicCompletedValidation.isCompleted(ws)
        const e = EquipmentsCompletedValidation.isCompleted(ws)
        const c = CatalogueCompletedValidation.isCompleted(ws, isTripFn)
        const d = DescriptionCompletedValidation.isCompleted(ws, taskDescriptionRequiredFn)
        return {
            basicCompleted: b,
            equipmentsCompleted: e,
            catalogueCompleted: c,
            descriptionCompleted: d,
            valid: b && e && c && d,
            serviceCallStatuses: scStatuses
        }
    }
}