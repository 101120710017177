import React from 'react';
import {Autocomplete, Chip, TextField} from "@mui/material";
import {AutocompleteRenderInputParams} from "@mui/material/Autocomplete/Autocomplete";

export type EmailAddressesInputProps = {
    emailAddresses: string[]
    onChange: (validEmailAddresses: string[]) => void
    onBlur?: () => void
    onDelete?: (option: string) => void
    defaultValue?: string[] | undefined

    renderInput?: (params: AutocompleteRenderInputParams) => React.ReactElement

    error?: boolean
}
const EmailAddressesInput = ({
                                 emailAddresses,
                                 onChange,
                                 onBlur,
                                 onDelete,
                                 defaultValue,
                                 renderInput,
                                 error
                             }: EmailAddressesInputProps) => {

    const onEmailAddressesChange = (newValues: string[]) => {
        onChange(newValues.filter(v => v.includes('@')))
    }

    return (
        <Autocomplete
            disablePortal
            freeSolo={true}
            multiple
            fullWidth
            handleHomeEndKeys
            defaultValue={defaultValue}
            value={emailAddresses}
            onChange={(e, value) => onEmailAddressesChange(value)}
            onBlur={onBlur}
            options={emailAddresses}
            filterOptions={() => [] /* HIDES OPTIONS WHEN TYPING! */}
            renderTags={(value: readonly string[], getTagProps) =>
                value.map((option: string, index: number) => (
                    <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({index})}
                        onDelete={() => onDelete?.(option)}
                    />
                ))
            }
            renderInput={renderInput ? renderInput : (params: AutocompleteRenderInputParams) => (
                <TextField
                    ref={params.InputProps.ref}
                    {...params}
                    InputLabelProps={{shrink: true}}
                    variant="outlined"
                    label="E-mail"
                    placeholder="info@e-mail.com..."
                    error={error}
                />
            )}
        />
    );
};

export default EmailAddressesInput;