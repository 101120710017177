import React, {useMemo} from 'react';
import {
    AgentNotificationConfigurationProps
} from "modules/admin/worksheet/notifications/agent-notification/AgentNotificationConfigurationProps";
import {
    useAgentNotificationConfigurationState
} from "modules/admin/worksheet/notifications/agent-notification/state/useAgentNotificationConfigurationState";
import {useMaterialReactTableLocalization} from "i18n/material-table/useMaterialReactTableLocalization";
import useTranslateFunction from "shared/language/useTranslateFunction";
import MaterialReactTable, {MRT_ColumnDef} from "material-react-table";
import {
    AgentNotificationConfigurationDialogButton
} from "modules/admin/worksheet/notifications/agent-notification/results/AgentNotificationConfigurationDialogButton";
import {CDAMNMAgentNotificationConfigurationModel} from "crowbar-api";
import {CircularProgress} from "@mui/material";

type AgentConfigurationTableColumn = CDAMNMAgentNotificationConfigurationModel & {
    totalCount?: number
    employeeName?: string
}

export type AgentNotificationConfigurationResultsTableProps = AgentNotificationConfigurationProps & {
    onStateChanged: () => void
}

export const AgentNotificationConfigurationResultsTable = ({
                                                               stateAtom,
                                                               onStateChanged
                                                           }: AgentNotificationConfigurationResultsTableProps) => {
    const tk = useTranslateFunction(`Admin.Worksheet`)
    const {
        filteredConfigurations,
        employeesQuery
    } = useAgentNotificationConfigurationState(stateAtom)

    const mrt_locale = useMaterialReactTableLocalization()

    const employees = useMemo(() => employeesQuery.data ?? [], [employeesQuery.data])

    const entries = useMemo(() => (
        (filteredConfigurations ?? [])
            .map((e): AgentConfigurationTableColumn => {
                const employee = employees.find(emp => emp.empId === e.sapEmployeeId)
                return {
                    ...e,
                    totalCount: filteredConfigurations?.length ?? 0,
                    employeeName: `${employee?.lastName ?? ""} ${employee?.middleName ?? ""} ${employee?.firstName ?? ""}`
                }
            })
    ), [filteredConfigurations, employees])

    const columns = useMemo<MRT_ColumnDef<AgentConfigurationTableColumn>[]>(() => [
        {
            accessorKey: "employeeName",
            header: tk('Employee')
        },
        {
            accessorKey: "emailAddresses",
            header: "E-mail"
        },
        {
            accessorFn: row => (row.notifyOnFinishedWorksheet ? tk("Yes") : tk("No")),
            header: tk('NotifyOnFinishedWorksheet')
        },
        {
            accessorFn: row => (row.notifyOnFinishedWorksheetWithFaults ? tk("Yes") : tk("No")),
            header: tk('NotifyOnFinishedWorksheetWithFaults')
        },
        {
            accessorFn: row => (row.onlyNotifyIfCustomerSalesPerson ? tk("Yes") : tk("No")),
            header: tk('OnlyNotifyIfCustomerSalesPerson')
        },
        {
            accessorFn: row => (
                <AgentNotificationConfigurationDialogButton
                    mode="edit"
                    editConfiguration={row}
                    onConfigurationChange={onStateChanged}
                    employees={employees}
                />
            ),
            header: tk('Edit')
        }
    ], [employees, onStateChanged, tk])

    return (
        <MaterialReactTable
            enablePagination={true}
            renderFallbackValue={<CircularProgress/>}
            data={entries}
            columns={columns}
            localization={mrt_locale}
            initialState={{
                density: 'compact',
            }}
            rowNumberMode="original" //default
            enableGlobalFilter={false} //disable search feature
            enableColumnResizing={true}
            enableColumnFilters={false}
            enableColumnActions={false}
            enableSorting={false}
            enableFullScreenToggle={false}
            positionToolbarAlertBanner="bottom"
        />
    )
}