import React, {useState} from 'react';
import {CDSMPartnerEquipmentPageRow} from "crowbar-api";
import {Button, Dialog, DialogActions, DialogContent, DialogProps, Stack} from "@mui/material";
import SelectSinglePartnerEquipment
    from "modules/worksheet/editor/shared/dialog/new-service-call-dialog/SelectSinglePartnerEquipment";
import useTranslateFunction from "shared/language/useTranslateFunction";
import ConfirmDialogButton from "shared/components/buttons/ConfirmDialogButton";

export type SelectEquipmentDialogProps = DialogProps & {
    cardCode: string | null | undefined
    onCancel: () => void
    onSave: (equipment: CDSMPartnerEquipmentPageRow) => void

    displayRefreshButton?: boolean
}
const SelectEquipmentDialog = ({
                                   cardCode,
                                   onCancel,
                                   onSave,
                                   displayRefreshButton,
                                   ...props
                               }: SelectEquipmentDialogProps) => {

    const tk = useTranslateFunction("Admin.Worksheet")

    const [selected, setSelected] = useState<CDSMPartnerEquipmentPageRow | undefined>(undefined)

    const onEquipmentSelectedInner = (equipment?: (CDSMPartnerEquipmentPageRow | undefined)) => {
        setSelected(equipment)
    }

    const onConfirm = () => {
        if (!selected) return
        onSave(selected)
    }

    return (
        <Dialog
            PaperProps={{
                sx: {
                    minHeight: "90vh"
                }
            }}
            {...props}
        >
            <DialogContent sx={{height: 1}}>
                <SelectSinglePartnerEquipment
                    cardCode={cardCode}
                    onEquipmentSelected={onEquipmentSelectedInner}
                    orderByDesc={false}
                    pageLimit={4}
                    displayRefreshButton={displayRefreshButton}
                />
            </DialogContent>
            <DialogActions>
                <Stack direction="row" justifyContent="space-between" alignItems="center" width={1}>
                    <Button color="error" onClick={onCancel}>{tk('Cancel')}</Button>

                    <ConfirmDialogButton
                        buttonProps={{
                            color: "primary",
                            variant: "contained",
                            disabled: !selected
                        }}
                        dialogText={tk('EquipmentChangeConfirmText')}
                        onConfirm={onConfirm}
                    />
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

export default SelectEquipmentDialog;