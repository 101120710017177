import React from 'react';
import {CatalogueTableRow} from "modules/admin/worksheet/evaluation/tabs/service-calls/catalogue/table/CatalogueTable";

export type OriginalInstalledQtyProps = {
    row: CatalogueTableRow
}
const OriginalInstalledQty = ({
                                  row
                              }: OriginalInstalledQtyProps) => {

    const isTrip = row.eval?.isTrip
    const displayValue = isTrip ? row.eval?.allocatedTripDistance : row.eval?.originalInstalledQuantity

    return (
        <>
            {displayValue ?? 0}
        </>
    )
}

export default OriginalInstalledQty;