import React from "react";
import {Button, CircularProgress, Stack} from "@mui/material";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {useWorksheetDelete} from "crowbar-api/hooks/worksheet/useWorksheetDelete";
import ConfirmDialogButton, {ConfirmDialogButtonProps} from "shared/components/buttons/ConfirmDialogButton";
import {useMechanicWorksheetsUrl} from "modules/worksheet/editor/shared/data/useMechanicWorksheetsUrl";
import {useNavigate} from "react-router-dom";
import {useWorksheetSaveState} from "modules/worksheet/editor/state/WorksheetSaveAtoms";

export type WorksheetDeleteButtonProps = ConfirmDialogButtonProps & {
    disabled?: boolean
}

export const WorksheetDeleteButton = ({disabled, ...props}: WorksheetDeleteButtonProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    const [ws, setWs] = useWorksheetSaveState()
    const worksheetDelete = useWorksheetDelete()
    const mechanicUrl = useMechanicWorksheetsUrl()
    const navigate = useNavigate()

    const onDeleteClick = async () => {
        if (!ws?.id) return
        await worksheetDelete.mutateAsync(ws.id)
        setWs(undefined)
        navigate(mechanicUrl)
    }

    return (
        <ConfirmDialogButton
            {...props}
            fullWidth
            onConfirm={onDeleteClick}
            buttonRender={(buttonProps, onButtonClick) => (
                <Button
                    color="error"
                    variant="outlined"
                    size="small"
                    onClick={onButtonClick}
                    disabled={disabled}
                >
                    <Stack direction="row" spacing={1} alignItems="center">
                        {tk('Delete')}
                        {worksheetDelete.isLoading && (
                            <CircularProgress/>
                        )}
                    </Stack>
                </Button>
            )}
        />
    )
}