import {DeliveryNoteTypeMap} from "crowbar-api/enum-map/DeliveryNoteTypeMap";

export interface IDeliveryNoteDialogFields {
    type?: string
    leaseState?: string
    datedAt?: string
    supplierCardCode?: string
    supplierCardName?: string

    supplierAddressDropdown?: string
    supplierAddress?: string

    supplierContactNameKey?: string
    supplierContactNameValue?: string

    issuerEmployeeId?: number
    issuerContactPersonCode?: number
    issuerNameKey?: string
    issuerNameValue?: string

    customerCardCode?: string
    customerCardName?: string

    customerBillingAddressDropdown?: string
    customerBillingAddress?: string
    customerShippingAddressDropdown?: string
    customerShippingAddress?: string

    customerContactNameKey?: string
    customerContactNameValue?: string
    customerContactPhoneKey?: string
    customerContactPhoneValue?: string

    orderNumber?: string

    licensePlateNumberKey?: string
    licensePlateNumberValue?: string

    isDeleted?: boolean

    rows: {
        uuid: string
        source: number
        description: string
        quantity?: number
        unitOfQuantity?: string
        opHours?: number
    }[]
}

export const deliveryNoteDialogEmptyFields: IDeliveryNoteDialogFields = {
    type: new DeliveryNoteTypeMap().sale.toString(),
    leaseState: '',
    datedAt: new Date().toJSON(),
    supplierCardCode: 'V100729',
    supplierCardName: 'Frekvent Forklift Kft.',
    supplierAddressDropdown: '',
    supplierAddress: `Kisszállási út 11.\r\n6440 Jánoshalma\r\nMagyarország`,
    supplierContactNameKey: '',
    supplierContactNameValue: '',
    issuerEmployeeId: 0,
    issuerContactPersonCode: 0,
    issuerNameKey: '',
    issuerNameValue: '',
    customerCardCode: '',
    customerCardName: '',
    customerBillingAddressDropdown: '',
    customerBillingAddress: '',
    customerShippingAddressDropdown: '',
    customerShippingAddress: '',
    customerContactNameKey: '',
    customerContactNameValue: '',
    customerContactPhoneKey: '',
    customerContactPhoneValue: '',
    orderNumber: '',
    licensePlateNumberKey: '',
    licensePlateNumberValue: '',
    rows: []
}