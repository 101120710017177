import React from 'react';
import {Container} from "@mui/material";
import EquipmentRoutes from "modules/equipment/EquipmentRoutes";

const EquipmentModule = () => {
    return (
        <Container maxWidth="xl">
            <EquipmentRoutes/>
        </Container>
    )
}

export default EquipmentModule;