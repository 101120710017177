import {CDAMWMModSapType, CDAMWMServiceCallItemRowStatus} from "crowbar-api";

export default class ModSapTypeMap {
    public static readonly create = CDAMWMModSapType.NUMBER_0
    public static readonly update = CDAMWMModSapType.NUMBER_1

    public static translationKey = (t?: CDAMWMServiceCallItemRowStatus): string => {

        const preKey = "Worksheet.Generic.ModSapType."

        if (t === this.create) {
            return `${preKey}Create`
        }

        if (t === this.update) {
            return `${preKey}Update`
        }

        return "Missing"
    }
}