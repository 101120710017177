export const i18n_genericHu = {
    "Loading": "Betöltés...",
    "Yes": "Igen",
    "No": "Nem",
    "Back": "Vissza",
    "Search": "Keresés",
    "Previous": "Előző",
    "Next": "Következő",
    "SingOut": "Kilépés",
    "NetworkError": "Hálózati hiba",
    "ServerError": "Szerver oldali hiba",
    "NetworkResponseError": "Hálózati hiba",
    "UnknownError": "Ismeretlen hiba",
    "Error": "Hiba",
    "GenericErrorText": "Szoftverhiba történt. Kérem jelezze a rendszergazdának! (Amennyiben a felugró ablakban nem tudta elküldeni a visszajelzését, akkor más formában.)",
    "GenericErrorText2": "Esetleg próbálja meg ismét végrehajtani a műveletet.",
    "NetworkResponseErrorSuggestion": "Probléma van az internet kapcsolattal vagy a szerver nem elérhető.",
    "Version": "Verzió",
    "NewVersionNotification": "Új verzió érhető el az alkalmazáshoz. Kérem töltse újra az oldalt.",
    "ReloadPage": "Oldal újratöltése",
    "Dismiss": "Bezárás",
    "OnlineDetector": {
        "OfflineMessage": "A szerver nem elérhető. Kérem ellenőrizze az internet kapcsolatot. Karbantartás után töltse újra az oldalt."
    },
    "NotificationCenter": {
        "Notifications": "Értesítések",
        "ShowRead": "Olvasottak mutatása",
        "ClearAll": "Minden törlése",
        "MarkAllAsRead": "Összes olvasottnak jelölése",
        "NoNotifications": "Nincs értesítés."
    },
    "Login": {
        "RequiresAuthentication": "Jelentkezzen be fiókjába.",
        "LoginWithMs": "Bejelentkezés a Microsoft segítségével",
        "Microsoft": "Microsoft fiók",
        "LoginFailed": "Bejelentkezés sikertelen.",
        "ContactAdmin": "Próbálja újra. Amennyiben így sem tud bejelentkezni, lépjen kapcsolat az adminisztrátorral.",
        "TechnicalDetails": "Műszaki részletek",
    },
    "ImageUploadSingle": {
        "Browse": "Tallózás",
        "NoFileName": "Válasszon ki egy fájlt...",
        "UploadSuccess": "Kép sikeresen feltöltve.",
        "Upload": "Feltöltés",
        "CoverImage": "Borítókép",
        "UploadInfo": "Töltsön fel egy képet. Támogatott formátumok: jpg, png, webp"
    },
    "Microphone": {
        "UserAbort": "Felhasználó megszakította a felvételt.",
        "HWNotSupported": "Ez az eszköz nincs támogatva ezen a platformon.",
        "TeamsError": "Teams SDK hiba média hívás közben.",
        "NoRecording": "Felvétel után a hanganyag nem található.",
        "SaveAudio": "Hanganyag mentése."
    },
    "Camera": {
        "NotFound": "A fájl nem található.",
        "TeamsError": "Teams SDK hiba média hívás közben.",
        "PermissionDenied": "Az engedélyt a felhasználó nem adta meg.",
        "HWNotSupported": "Ez az eszköz nincs támogatva ezen a platformon.",
        "UserAbort": "Felhasználó megszakította a műveletet.",
        "OldPlatform": "Nem támogatott platform.",
        "SizeExceeded": "A fénykép mérete túl nagy."
    },
    "Sap": {
        "DefaultSelectCustomerBtnText": "Vevő kiválasztása",
        "DefaultSelectCustomerTitle": "Válasszon ki egy vevőt",
        "DefaultSelectCustomerCancelText": "Mégsem",
        "DefaultSelectCustomerOkText": "Kiválaszt",
    },
    "UserAutoRegistered": "Felhasználó sikeresen regisztrálva a programba.",
    "Validations": {
        "FieldIsRequired": "A mező kitöltése kötelező.",
        "OnlyNumbersAllowed": "Csak szám adható meg.",
        "OnlyIntegersAllowed": "Csak egész szám adható meg.",
        "StringIsTooLong": "Túl hosszú a megadott szöveg.",
        "StringIsLongerThan": "A megadott szöveg nem lehet hosszabb mint {{max}} karakter.",
        "StringIsTooShort": "Túl rövig a megadott szöveg.",
        "NumberBetween": "A megadott érték {{min}} és {{max}} karakter között kell legyen.",
        "InvalidEmailAddress": "Érvénytelen e-mail cím.",
        "NumberTooLarge": "Túl nagy szám.",
    },
    "ConfirmButton": {
        "Cancel": "Mégsem",
        "Yes": "Igen",
        "Confirm": "Rendben",
        "DefaultTitle": "Megerősítés",
        "DefaultText": "Biztos benne?",
    },
    "Equipment": {
        "EquipmentImages": "Gépek képei"
    }
}