import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Stack, TextField} from "@mui/material";
import AlertDialog from "shared/components/alert-dialog/AlertDialog";
import {v4 as uuidv4} from 'uuid';
import {CDAMDMLicensePlateModel} from "crowbar-api";
import {toastError} from "shared/toast/DefaultToasts";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {useSaveLicensePlate} from "crowbar-api/hooks/delivery-note/useSaveLicensePlate";
import {useDeleteLicensePlate} from "crowbar-api/hooks/delivery-note/useDeleteLicensePlate";

interface LicensePlateDialogProps extends DialogProps {
    mode: "new" | "edit"
    editItem?: CDAMDMLicensePlateModel
    open: boolean
    onCancel: () => void
    onSubmit: () => void
}

const LicensePlateDialog = ({mode, editItem, open, onCancel, onSubmit}: LicensePlateDialogProps) => {
    const {t} = useTranslation();
    const tk = useTranslateFunction("DeliveryNote.LicensePlate.");
    const saveLicensePlate = useSaveLicensePlate();
    const deleteLicensePlate = useDeleteLicensePlate();

    const [plateNumber, setPlateNumber] = useState<string>(editItem?.plateNumber ?? "");
    const [description, setDescription] = useState<string>(editItem?.description ?? "");
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false);

    if ((open && mode === "edit") && (!editItem || !editItem.id)) {
        toastError(t('DeliveryNote.Form.EditLoadingFailed'));
    }

    const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDescription(event.target.value);
    }

    const handlePlateNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPlateNumber(event.target.value);
    }

    const handleDelete = () => {
        setDeleteConfirmOpen(true);
    }

    const handleSubmit = async () => {
        if (!plateNumber) {
            toastError(tk('FormInvalid'));
            return;
        }

        const licensePlate: CDAMDMLicensePlateModel = {
            id: mode === "new" ? uuidv4() : editItem?.id,
            description: description,
            plateNumber: plateNumber
        }

        await saveLicensePlate.mutateAsync(licensePlate);
        onSubmit();
    }

    const handleDeleteConfirm = async (res: 'ok' | 'cancel') => {
        setDeleteConfirmOpen(false)
        if (res === 'cancel') return;

        if (!editItem || !editItem.id) {
            toastError('Failed to load item');
            return;
        }

        await deleteLicensePlate.mutateAsync(editItem.id as string)
        onSubmit();
    }

    return (
        <div>
            <Dialog open={open}
                    hideBackdrop={false}
                    fullScreen={false}
                    fullWidth={true}
                    maxWidth="md"
            >
                <DialogTitle>{tk(mode === "new" ? 'TitleNew' : 'TitleEdit')}</DialogTitle>
                <DialogContent>

                    <Stack sx={{m: 1}} spacing={2}>
                        <TextField label={tk('DescriptionLabel')}
                                   value={description}
                                   size={"small"}
                                   autoComplete={"off"}
                                   onChange={handleDescriptionChange}
                        />
                        <TextField label={tk('PlateNumberLabel')}
                                   value={plateNumber}
                                   size={"small"}
                                   autoComplete={"off"}
                                   onChange={handlePlateNumberChange}
                        />
                    </Stack>

                </DialogContent>
                <DialogActions>
                    <Button variant={"outlined"}
                            onClick={onCancel}
                    >
                        {tk('CancelButton')}
                    </Button>

                    {mode === "new" ? "" :
                        <Button variant={"outlined"}
                                color={"error"}
                                onClick={handleDelete}
                        >
                            {tk('DeleteButton')}
                        </Button>
                    }

                    <Button variant={"contained"}
                            onClick={handleSubmit}
                    >
                        {tk('SaveButton')}
                    </Button>
                </DialogActions>
            </Dialog>
            <AlertDialog
                isOpen={deleteConfirmOpen}
                onClose={handleDeleteConfirm}
            />
        </div>
    );
}

export default LicensePlateDialog;