import React from 'react';
import WorksheetRoutes from "modules/worksheet/WorksheetRoutes";
import {Container} from "@mui/material";
import {useWorksheetCartSaveToLocalStorage} from "modules/worksheet/worksheet-cart/useWorksheetCartSaveToLocalStorage";

const WorksheetModule = () => {

    useWorksheetCartSaveToLocalStorage("editor")

    return (
        <Container maxWidth="xl">
            <WorksheetRoutes/>
        </Container>
    );
};

export default WorksheetModule;