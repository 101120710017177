import {CDSMNNewWorksheetSalesOrderProps, NewWorksheetQueryApi} from "crowbar-api";
import {useInfiniteQuery} from "@tanstack/react-query";
import {InfQueryUtils} from "shared/utils/InfQueryUtils";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import AxiosCancelTokenHelper from "crowbar-api/AxiosCancelTokenHelper";

export const useNewWorksheetPageSalesOrdersByInfQuery = (
    enabled: boolean,
    {
        lastRowNumber, // lastRowNumber is never read from the params! We use getNextPageParam !
        ...params // all the other params are read (and will re-fetch from page 0)
    }: CDSMNNewWorksheetSalesOrderProps) => {
    return useInfiniteQuery(['new-worksheet-page-sales-orders-inf-query', enabled, params], async ({
                                                                                                       signal,
                                                                                                       pageParam
                                                                                                   }) => {

        if (!enabled) {
            return []
        }

        const response = await crowbarApiFactory(NewWorksheetQueryApi)
            .pageSalesOrdersBy({...params, lastRowNumber: pageParam}, AxiosCancelTokenHelper.cancelTokenFrom(signal))

        return response.data ?? []
    }, {
        networkMode: 'online', // will not fire unless we have network
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        cacheTime: Math.pow(10, 7),
        staleTime: Math.pow(10, 7),
        getNextPageParam: InfQueryUtils.getNextPageParamForRowNum
    })
}