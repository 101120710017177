export class MapUtils {
    public static GoogleMapsQueryUrlWithoutProtocol = "www.google.com/maps/search/?api=1&query="
    public static openUrlForQuery = (query: string): void => {
        const urlQuery = encodeURIComponent(query)
        const openUrl = `${MapUtils.GoogleMapsQueryUrlWithoutProtocol}${urlQuery}`
        if ((navigator.platform.indexOf("iPhone") !== -1) ||
            (navigator.platform.indexOf("iPad") !== -1) ||
            (navigator.platform.indexOf("iPod") !== -1)
        ) {
            /* if we're on iOS, open in Apple Maps */
            window.open(`maps://${openUrl}`)
        } else {
            /* else use Google */
            window.open(`https://${openUrl}`)
        }
    }
}