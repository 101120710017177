import {useQuery} from "@tanstack/react-query";
import AxiosCancelTokenHelper from "crowbar-api/AxiosCancelTokenHelper";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {WorksheetApi} from "crowbar-api";

export const useWorksheetFindStatusAndSignatureByIds = (worksheetIds: string[]) => {
    return useQuery(['worksheet-status-signature-by-ids', worksheetIds], async (context) => {
        const response = await crowbarApiFactory(WorksheetApi)
            .findStatusAndSignatureByIds(worksheetIds, AxiosCancelTokenHelper.cancelTokenFrom(context.signal))
        return response.data
    }, {
        refetchOnWindowFocus: false,
        refetchOnMount: true,
        retry: 3,
        staleTime: Math.pow(10, 7),
        cacheTime: Math.pow(10, 7),
    })
}