import {CDAMWMWWorksheetVersion} from "crowbar-api";
import {useFindWorksheetVersionsFor} from "crowbar-api/hooks/worksheet/versions/useFindWorksheetVersionsFor";
import {UseQueryResult} from "@tanstack/react-query";
import {useLocalWorksheetVersions} from "modules/worksheet/service/useLocalWorksheetVersions";
import {useMemo} from "react";
import {WorksheetVersionUtils} from "crowbar-api/hooks/worksheet/versions/WorksheetVersionUtils";

export interface WorksheetVersionMismatch {
    type?: 'server-in-local' | 'local-in-server'
    local: CDAMWMWWorksheetVersion
    server: CDAMWMWWorksheetVersion
}

export interface UseLocalAndServerWorksheetVersionsResult {
    localWorksheetVersions: UseQueryResult<CDAMWMWWorksheetVersion[], unknown>
    serverWorksheetVersions: UseQueryResult<CDAMWMWWorksheetVersion[], unknown>

    mismatches: WorksheetVersionMismatch[]
}

const findNotEqualVersionsInTargetBy = (target: CDAMWMWWorksheetVersion[], searchElements: CDAMWMWWorksheetVersion[], targetIsLocal: boolean) => {
    const result: WorksheetVersionMismatch[] = []
    for (const targetVersion of target) {
        const foundMatch = searchElements.find(searchElement => searchElement.id === targetVersion.id)
        if (foundMatch && !WorksheetVersionUtils.equalsWithVersions(foundMatch, targetVersion)) {
            result.push({
                local: targetIsLocal ? targetVersion : foundMatch,
                server: targetIsLocal ? foundMatch : targetVersion
            })
        }
    }
    return result
}

export const useLocalAndServerWorksheetVersions = (): UseLocalAndServerWorksheetVersionsResult => {
    const localWorksheetVersions = useLocalWorksheetVersions()
    const localKeys = useMemo(() => (
        localWorksheetVersions.data?.map(v => v.id!) ?? []
    ), [localWorksheetVersions.data])
    const serverWorksheetVersions = useFindWorksheetVersionsFor(localKeys)

    const mismatches = useMemo(() => {
        const local = localWorksheetVersions.data ?? []
        const server = serverWorksheetVersions.data ?? []

        const localMismatches = findNotEqualVersionsInTargetBy(local, server, true)
            .map((m): WorksheetVersionMismatch => {
                return {
                    ...m,
                    type: 'server-in-local'
                }
            })
        const localMismatchIds = localMismatches
            .filter(m => m.server?.id !== undefined)
            .map(m => m.server?.id!)

        const serverMismatches = findNotEqualVersionsInTargetBy(server, local, false)
            .filter(m => m.server?.id && !localMismatchIds.includes(m.server?.id))
            .map((m): WorksheetVersionMismatch => {
                return {
                    ...m,
                    type: 'local-in-server'
                }
            })

        return [...serverMismatches, ...localMismatches]
    }, [localWorksheetVersions.data, serverWorksheetVersions.data])

    return {
        localWorksheetVersions,
        serverWorksheetVersions,
        mismatches
    }
}