import React from 'react';
import useNetwork from "shared/hook/useNetwork";
import {Stack, Typography} from "@mui/material";

import CloudIcon from '@mui/icons-material/Cloud';

import CloudOffIcon from '@mui/icons-material/CloudOff';

export interface OnlineDetectorBarProps {
    showOnlineState: boolean
    alignText?: "right" | "left" | "inherit" | "center" | "justify" | undefined
}

const OnlineDetectorBar = ({showOnlineState, alignText}: OnlineDetectorBarProps) => {
    const {online} = useNetwork()

    /*if (online && showOnlineState) {
        return (
            <Box sx={{width: 1, p: 0, backgroundColor: "transparent", display: "flex", alignItems: "center"}}>
                <Typography sx={{width: 1, px: "15px", py: 0, color: "black"}} align={alignText}>
                    <CloudIcon/>
                </Typography>
            </Box>
        )
    } else {
        return (
            <Box sx={{width: 1, p: 0, backgroundColor: "#FFCC00", display: "flex", alignItems: "center"}}>
                <Typography sx={{width: 1, px: "15px", py: 0, color: "white"}} align={alignText}>
                    <CloudOffIcon/>
                </Typography>
            </Box>
        )
    }*/

    if (!showOnlineState) {
        return (
            <></>
        )
    }

    return (
        <Stack
            direction="row"
            spacing={1}
            sx={{
                width: 1,
                p: 0,
                display: "flex",
                alignItems: "center"
            }}
        >
            <Typography
                align={alignText}
            >
                {online ? (
                    <CloudIcon/>
                ) : (
                    <CloudOffIcon/>
                )}
            </Typography>
            {!online && (
                <Typography
                    sx={{
                        color: online ? "text.primary" : "error.main"
                    }}
                >
                    &nbsp;Offline
                </Typography>
            )}
        </Stack>
    )
};

export default OnlineDetectorBar;