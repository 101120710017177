import React, {ChangeEvent} from 'react';
import {useNewAtomWithRef} from "shared/hook/useNewatomWithRef";
import usePhotoHostInformation from "modules/worksheet/editor/ui/photo/usePhotoHostInformation";
import useServiceCallBlobUpload from "modules/worksheet/editor/ui/photo/useServiceCallBlobUpload";
import {toastError, toastSuccess, toastWarn} from "shared/toast/DefaultToasts";
import {media as TeamsMedia, SdkError} from "@microsoft/teams-js";
import {TeamsMediaUtils} from "shared/teams/TeamsMediaUtils";
import {Box, Button, CircularProgress, Stack, Typography} from "@mui/material";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import CircularProgressWithLabel from "shared/components/progress/CircularProgressWithLabel";
import useTranslateFunction from "shared/language/useTranslateFunction";
import WorksheetSaveConfirmationDialog
    from "modules/worksheet/editor/shared/component/worksheet-save-dialog/WorksheetSaveConfirmationDialog";
import {UseWorksheetSaveMutationResult} from "crowbar-api/hooks/worksheet/useWorksheetSaveMutation";
import {useWorksheetSaveState} from "modules/worksheet/editor/state/WorksheetSaveAtoms";

export interface WEditorGenericPhotoUploadButtonProps {
    /**
     * If we need to call replace instead of save
     * set to true
     * (replace deletes the previous blobId, ServiceCallId row)
     */
    replace?: boolean
    replaceBlobId?: string

    buttonLabel: string
    /**
     * if set, the uploaded service call photo will be attached to
     * the specified rule step by id.
     */
    photoRuleStepId?: string
    /**
     * if set, the uploaded service call photo will be
     * ordered with the sub order number (used when attached to step)
     */
    subOrderNum?: number
    afterUploadComplete: () => void

    allowMultiple?: boolean
}

const WEditorGenericPhotoUploadButton = ({
                                             replace,
                                             replaceBlobId,
                                             buttonLabel,
                                             photoRuleStepId,
                                             subOrderNum,
                                             afterUploadComplete,
                                             allowMultiple
                                         }: WEditorGenericPhotoUploadButtonProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    const [ws] = useWorksheetSaveState()

    const [inputValue, setInputValue] = useNewAtomWithRef<string>("")
    const [uploading, setUploading] = useNewAtomWithRef(false)
    const [currentFile, setCurrentFile] = useNewAtomWithRef<string | undefined>(undefined)
    const [currentFileNum, setCurrentFileNum] = useNewAtomWithRef<number | undefined>(undefined)
    const [totalFileNum, setTotalFileNum] = useNewAtomWithRef<number | undefined>(undefined)
    const hostInfo = usePhotoHostInformation()

    const {
        isUploading,
        progress,
        runUploadForSelectedServiceCall,
        runReplaceForSelectedServiceCall,
        cancelUpload
    } = useServiceCallBlobUpload()

    const uploadFilesArray = async (files: File[], onClickStartSave: () => Promise<UseWorksheetSaveMutationResult>) => {
        try {
            setUploading(true)
            setCurrentFileNum(1)
            setTotalFileNum(files.length)

            // if the worksheet contains modEquipments, we need to save the whole worksheet,
            // not just the serviceCall
            if ((ws?.modSapEquipments ?? []).length > 0) {
                await onClickStartSave()
            }

            // Save files
            let counter = 0
            for (let i = 0; i < files.length; i++) {
                const file = files[i]
                if (!file) continue
                setCurrentFile(file?.name ?? "N/A")
                setCurrentFileNum(i + 1)

                if (replace && replaceBlobId) {
                    await runReplaceForSelectedServiceCall(file, replaceBlobId, {
                        photoRuleStepId: photoRuleStepId,
                        subOrderNum: subOrderNum
                    })
                } else {
                    await runUploadForSelectedServiceCall(file, {
                        photoRuleStepId: photoRuleStepId,
                        subOrderNum: subOrderNum
                    })
                }

                counter++
            }
            toastSuccess(tk('UploadedNumberOfFiles', {counter}))
            // Call prop
            afterUploadComplete()
        } finally {
            setUploading(false)
            setCurrentFile(undefined)
            setCurrentFileNum(undefined)
            setTotalFileNum(undefined)
        }
    }

    const onTeamsClick = async (onClickStartSave: () => Promise<UseWorksheetSaveMutationResult>) => {
        TeamsMedia.selectMedia({
            ...TeamsMediaUtils.defaultMediaInput,
            maxMediaCount: allowMultiple ? 10 : 1
        }, async (error: SdkError, attachments: TeamsMedia.Media[]) => {
            if (error) {
                if (error.message) {
                    toastError(" ErrorCode: " + error.errorCode + error.message);
                } else {
                    toastError(" ErrorCode: " + error.errorCode);
                }
            }
            if (attachments && attachments.length > 0) {
                const files = await TeamsMediaUtils.mapAttachmentsToFiles(attachments)
                await uploadFilesArray(files, onClickStartSave)
            }
        });
    }

    const onNativePhotoChange = async (e: ChangeEvent<HTMLInputElement>, onClickStartSave: () => Promise<UseWorksheetSaveMutationResult>) => {
        if (!e.target?.files) return
        const files: File[] = []
        for (let i = 0; i < e.target.files.length; i++) {
            const file = e.target.files.item(i)
            if (!file) continue
            files.push(file)
        }
        await uploadFilesArray(files, onClickStartSave)
    }

    const onCancelClick = () => {
        cancelUpload()
        setUploading(false)
        toastWarn(tk('UploadCancelled'))
        // Call prop
        afterUploadComplete()
    }

    return (
        <WorksheetSaveConfirmationDialog
            render={(onClickStartSave, isLoading) => (
                <>

                    <Stack direction="row" spacing={1}>
                        {hostInfo.useTeamsCameraInput
                            ? (
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    disabled={isLoading || uploading}
                                    onClick={() => onTeamsClick(onClickStartSave)}
                                >
                                    <AddAPhotoIcon sx={{
                                        fontSize: "22pt"
                                    }}/>&nbsp;{buttonLabel}
                                </Button>)
                            : hostInfo.useNativeInput
                                ? (
                                    <Button variant="outlined"
                                            color="primary"
                                            component="label"
                                            disabled={isLoading || uploading}
                                            sx={{
                                                width: "150px",
                                            }}
                                    >
                                        <input
                                            type="file"
                                            accept="image/*"
                                            hidden
                                            multiple={allowMultiple ?? false}
                                            value={inputValue}  // we do not set the input ever on change
                                            // allows the input to load the same file multiple times
                                            onClick={() => setInputValue("")}
                                            onChange={(e) => onNativePhotoChange(e, onClickStartSave)}
                                        />
                                        <Stack direction="column">
                                            <Box sx={{
                                                width: 1,
                                                display: "flex",
                                                justifyContent: "center",
                                                justifyItems: "center"
                                            }}>
                                                <AddAPhotoIcon sx={{
                                                    fontSize: "22pt"
                                                }}/>
                                            </Box>
                                            <Box sx={{
                                                width: 1,
                                                textAlign: "center"
                                            }}>
                                                <Typography>{buttonLabel}</Typography>
                                            </Box>

                                        </Stack>
                                    </Button>
                                )
                                : (<></>)
                        }
                        <Stack direction="column" spacing={1}>
                            {(isUploading) && (hostInfo.useTeamsCameraInput ? (
                                <CircularProgress/>
                            ) : hostInfo.useNativeInput ? (
                                <CircularProgressWithLabel variant="determinate" value={progress ?? 0}/>
                            ) : (
                                <></>
                            ))}
                            {(isUploading && currentFile) && (
                                <Typography>{(currentFileNum && totalFileNum) && (
                                    <>{currentFileNum} / {totalFileNum}</>
                                )} {currentFile}</Typography>
                            )}
                            {isUploading && currentFile && (
                                <Button color="error" variant="outlined" onClick={onCancelClick}>
                                    {tk('Cancel')}
                                </Button>
                            )}
                        </Stack>
                    </Stack>

                </>
            )}
        />
    )
}

export default WEditorGenericPhotoUploadButton;