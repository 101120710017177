import React, {useCallback, useEffect, useMemo} from 'react';
import {useNewAtomWithRef} from "shared/hook/useNewatomWithRef";
import SignatureCanvas from "react-signature-canvas";
import {toastError} from "shared/toast/DefaultToasts";

export type SignatureCanvasForBase64Props = {
    signWidth: number
    signHeight: number
    initialSignatureBase64?: string | null

    children: (
        canvasElement: React.ReactElement,
        getDataUrl: () => string | undefined,
        clear: () => void,
        isEmpty: () => (boolean | undefined)
    ) => React.ReactElement
}

const SignatureCanvasForBase64 = ({
                                      signWidth,
                                      signHeight,
                                      initialSignatureBase64,
                                      children
                                  }: SignatureCanvasForBase64Props) => {

    const [signRef, setSignRef] = useNewAtomWithRef<SignatureCanvas | null>(null)

    const getDataUrl = useCallback(() => {
        const canvas = signRef?.getCanvas()
        if (!canvas) {
            toastError('No canvas found. App error.')
            return
        }
        return canvas.toDataURL("image/png")
    }, [signRef])

    const clear = useCallback(() => {
        signRef?.clear()
    }, [signRef])

    const isEmpty = useCallback(() => {
        return signRef?.isEmpty() ?? undefined
    }, [signRef])

    useEffect(() => {
        if (initialSignatureBase64 && signRef) {
            if (signRef && getDataUrl() !== initialSignatureBase64) {

                signRef.fromDataURL(initialSignatureBase64, {
                    width: signWidth,
                    height: signHeight,
                    ratio: 1
                })
            }
        }
    }, [getDataUrl, signHeight, signRef, signWidth, initialSignatureBase64])

    const canvas = useMemo(() => (
        <SignatureCanvas
            canvasProps={{
                width: `${signWidth}px`,
                height: `${signHeight}px`
            }}
            clearOnResize={false}
            ref={(ref) => {
                if (ref !== signRef) {
                    setSignRef(ref)
                }
            }}
        />
    ), [setSignRef, signHeight, signRef, signWidth])

    return children(canvas, getDataUrl, clear, isEmpty)
};

export default SignatureCanvasForBase64;