import React from 'react';
import {Card, CardContent, Grid, Skeleton} from '@mui/material';

const WEditorPageCatalogueItemRowPlaceHolder = () => {
    return (
        <Card>
            <CardContent>
                <Grid container spacing={0.5} sx={{height: 1, p: 1}}>
                    <Grid item xs={12}>
                        <Skeleton variant="rounded"/>
                    </Grid>
                    <Grid item xs={3}>
                        <Skeleton variant="rounded" width={145} height={145}/>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default WEditorPageCatalogueItemRowPlaceHolder;