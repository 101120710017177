import {useAzureBlobStorageUrlFactory} from "shared/hook/useAzureBlobStorageUrlFactory";
import {Box, CircularProgress} from "@mui/material";
import ProgressiveImageSingleAndLightbox
    from "shared/components/progressive-image-single-and-lightbox/ProgressiveImageSingleAndLightbox";
import React from "react";
import useTvhBlobFindBySapItemCodes from "modules/worksheet/editor/ui/catalogue/useTvhBlobFindBySapItemCodes";

interface ItemImageDisplayProps {
    itemCode?: string
    coverImage?: string
    disabled?: boolean
    disableOnClick?: boolean
    hideBorder?: boolean
}

const ItemImageDisplay = React.memo(({
                                         itemCode,
                                         coverImage,
                                         hideBorder,
                                         disableOnClick,
                                         disabled
                                     }: ItemImageDisplayProps) => {
    const blobStorageUrlFactory = useAzureBlobStorageUrlFactory()

    const tvhBlobsQuery = useTvhBlobFindBySapItemCodes(itemCode)

    const sourceCoverImageUris = coverImage ? blobStorageUrlFactory.sapImageFromName(coverImage) : undefined

    const tvhBlobUris = !tvhBlobsQuery?.data ? [] : tvhBlobsQuery.data
        .map((tvhBlob): string => {
            const tvhBlobImageUris = tvhBlob ? blobStorageUrlFactory.imageFromName("tvh", tvhBlob.parentDirectory, tvhBlob.fileName) : undefined
            return tvhBlobImageUris?.uri ?? ""
        })
        .filter(b => b)

    const firstTvhBlob = tvhBlobsQuery?.data?.find(() => true)
    const thumbnailImageUris = firstTvhBlob ? blobStorageUrlFactory.imageFromName("tvh", firstTvhBlob.parentDirectory, firstTvhBlob.fileName) : undefined

    return (
        <Box sx={{
            width: "145px",
            minWidth: "145px",
            maxWidth: "145px",
            height: "145px",
            overflow: "hidden",
            border: hideBorder ? '0px' : "1px solid",
            borderColor: disabled ? "gray" : "black"
        }}>
            {tvhBlobsQuery.isLoading
                ? (
                    <CircularProgress sx={{width: 1, height: 1}}/>
                )
                : (
                    <ProgressiveImageSingleAndLightbox
                        queryKey="worksheet-editor-tvh-blob-thumbnail"
                        thumbnailSrc={sourceCoverImageUris?.thumbMedium ? sourceCoverImageUris.thumbMedium : thumbnailImageUris?.thumbMedium}
                        lightboxImages={sourceCoverImageUris?.thumbMedium
                            ? sourceCoverImageUris?.uri ? [sourceCoverImageUris.uri] : []
                            : tvhBlobUris
                        }
                        disableOnClick={disableOnClick}
                        looksDisabled={disabled}
                    />
                )
            }
        </Box>
    )
}, (prevProps, nextProps) => {
    return prevProps.itemCode === nextProps.itemCode &&
        prevProps.coverImage === nextProps.coverImage &&
        prevProps.disabled === nextProps.disabled
})

export default ItemImageDisplay