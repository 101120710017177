import React, {useState} from 'react';
import {Button, CircularProgress, Stack} from "@mui/material";
import useTranslateFunction from "shared/language/useTranslateFunction";
import SelectEquipmentDialog
    from "modules/admin/worksheet/evaluation/tabs/service-calls/equipment-select/SelectEquipmentDialog";
import {CDAMWMWorksheetEvalModel, CDSMPartnerEquipmentPageRow} from "crowbar-api";
import {
    useServiceCallEvalUpdateEquipment
} from "crowbar-api/hooks/worksheet/evaluation/service-call/useServiceCallEvalUpdateEquipment";
import ServiceCallSelectedSourceMap from "crowbar-api/enum-map/ServiceCallSelectedSourceMap";
import FixesWorksheetDataIcon from "modules/admin/worksheet/evaluation/shared/FixesWorksheetDataIcon";
import {toastWarn} from "shared/toast/DefaultToasts";


export type SelectEquipmentDialogButtonProps = {
    scEvalId: string | undefined
    serviceCallId: string | undefined
    cardCode: string | undefined | null
    onMasterDataChange: () => void
    wEval: CDAMWMWorksheetEvalModel | null | undefined
    disabled?: boolean
}
const SelectEquipmentDialogButton = ({
                                         scEvalId,
                                         serviceCallId,
                                         cardCode,
                                         disabled,
                                         wEval,
                                         onMasterDataChange
                                     }: SelectEquipmentDialogButtonProps) => {
    const tk = useTranslateFunction("Admin.Worksheet")

    const [open, setOpen] = useState<boolean>(false)

    const evalUpdateEquipment = useServiceCallEvalUpdateEquipment()

    const onClick = () => {

        if (!wEval?.partnerAck) {
            toastWarn(tk('PartnerAckBeforeEquipmentAck'))
            return
        }

        setOpen(true)
    }

    const onEquipmentSave = async (e: CDSMPartnerEquipmentPageRow) => {
        setOpen(false)
        await evalUpdateEquipment.mutateAsync({
            evalId: scEvalId,
            originalId: serviceCallId,

            selectedSource: ServiceCallSelectedSourceMap.sapPartnerEquipment,
            isEquipmentFromSapMod: false,
            sapEquipmentId: e.insId,
            block: e.block,
            building: e.building,
            city: e.city,
            contactCode: e.contactCod,
            internalSn: e.internalSn,
            itemCode: e.itemCode,
            itemName: e.itemName,
            manufacturer: e.vehicleManufacturer,
            manufacturerSn: e.manufacturerSn,
            vehicleType: e.vehicleType,
            yearOfManufacture: e.yoM,
            zipCode: e.zipCode,
            coverImage: e.coverImage,
            street: e.street,
            streetNo: e.streetNo,

            countryCode: e.countryCode,
            county: e.county,
            liftingMastCode: e.liftingMastCode,
            liftingMastType: e.liftingMastType,
            engineCode: e.engineCode,
            engineType: e.engineType,
            mode: e.mode,
            opGroupNumber: e.opGroupNumber,
            typeSizeFront: e.typeSizeFront,
            typeSizeBack: e.typeSizeBack,
            voltage: e.voltage
        })

        onMasterDataChange()
    }

    return (
        <Stack direction="row">

            {open && (
                <SelectEquipmentDialog
                    open={open}
                    onClose={() => setOpen(false)}
                    fullWidth={true}
                    cardCode={cardCode}
                    onCancel={() => setOpen(false)}
                    onSave={onEquipmentSave}
                    displayRefreshButton={true}
                />
            )}

            <Button
                size="small"
                variant="contained"
                onClick={onClick}
                disabled={disabled || evalUpdateEquipment.isLoading}
            >
                <Stack direction="row" spacing={1} alignItems="center">
                    {evalUpdateEquipment.isLoading && (
                        <CircularProgress/>
                    )}
                    {tk('ReplaceEquipment')}&nbsp;<FixesWorksheetDataIcon/>
                </Stack>
            </Button>
        </Stack>
    )
}

export default SelectEquipmentDialogButton