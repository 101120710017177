import {CDAMWMWServiceCallItemRowSave} from "crowbar-api";
import {Box, Card, CardContent, Grid, Stack, Typography} from "@mui/material";
import React from "react";
import WEditorPageCatalogueItemRowImage from "modules/worksheet/editor/ui/catalogue/WEditorPageCatalogueItemRowImage";
import WEditorPageCatalogueItemRowQuantityInput
    from "modules/worksheet/editor/ui/catalogue/WEditorPageCatalogueItemRowQuantityInput";
import WEditorPageCatalogueItemRowCheckButton
    from "modules/worksheet/editor/ui/catalogue/WEditorPageCatalogueItemRowCheckButton";
import ServiceCallItemRowSelectedSourceMap from "crowbar-api/enum-map/ServiceCallItemRowSelectedSourceMap";
import WEditorPageCatalogueItemRowAvailableQuantity
    from "modules/worksheet/editor/ui/catalogue/WEditorPageCatalogueItemRowAvailableQuantity";
import WEditorPageCatalogueItemRowActions
    from "modules/worksheet/editor/ui/catalogue/WEditorPageCatalogueItemRowActions";
import WEditorPageCatalogueRowMechanicSelect
    from "modules/worksheet/editor/ui/catalogue/WEditorPageCatalogueRowMechanicSelect";
import useIsTripMatch from "crowbar-api/hooks/worksheet/validations/useIsTripMatch";
import {WEditorClosedSalesOrderWarning} from "modules/worksheet/editor/ui/catalogue/WEditorClosedSalesOrderWarning";

interface WorksheetEditorPageCatalogueItemRowProps {
    itemRow: CDAMWMWServiceCallItemRowSave
    disabled?: boolean
}

const WorksheetEditorPageCatalogueItemRow = ({

                                                 itemRow,
                                                 disabled
                                             }: WorksheetEditorPageCatalogueItemRowProps) => {

    const isTripMatch = useIsTripMatch(itemRow)

    if (isTripMatch) {
        return <></>
    }

    return (
        <Card sx={{
            p: 1,
            //  border: 2,
            // borderColor: (theme) => disabled ? theme.palette.text.disabled : theme.palette.text.primary
        }}>
            <CardContent sx={{p: 0, '&:last-child': {p: 1}}}>
                <Stack direction="column" spacing={1}>
                    <Typography>{itemRow.itemCode} {itemRow.itemName}</Typography>

                    <WEditorClosedSalesOrderWarning itemRow={itemRow}/>

                    <Grid container>

                        <Grid item xs="auto">
                            <Stack direction="row" spacing={2}>
                                <WEditorPageCatalogueItemRowImage itemRow={itemRow} disabled={disabled}/>
                            </Stack>
                        </Grid>

                        {itemRow.rowSelectedSource === ServiceCallItemRowSelectedSourceMap.sapSalesOrder && (
                            <Grid item xs="auto">
                                <WEditorPageCatalogueItemRowAvailableQuantity itemRow={itemRow} disabled={disabled}/>
                            </Grid>
                        )}


                        {itemRow?.id && itemRow?.serviceCallId && (
                            <Grid item xs>
                                <Box sx={{
                                    height: 1,
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center"
                                }}>
                                    <Stack direction="row">
                                        <WEditorPageCatalogueItemRowQuantityInput
                                            key={`${itemRow.id}-${itemRow.updatedAt}`}
                                            itemRow={itemRow}

                                            disabled={disabled || false}
                                        />
                                    </Stack>
                                </Box>
                            </Grid>
                        )}
                    </Grid>

                    <Grid container
                          alignItems="center"
                          justifyContent="space-between"
                    >
                        <Grid item xs="auto">
                            <WEditorPageCatalogueItemRowActions
                                itemRow={itemRow}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <Grid container
                                  spacing={1}
                                  alignItems="center"
                                  justifyContent="end"
                            >
                                <Grid item xs={true}>
                                    <WEditorPageCatalogueRowMechanicSelect
                                        itemRow={itemRow}
                                    />
                                </Grid>
                                <Grid item xs="auto" sx={{
                                    display: "flex",
                                    justifyContent: "end",
                                    justifyItems: "end"
                                }}>
                                    {itemRow?.id && itemRow?.serviceCallId && (
                                        <WEditorPageCatalogueItemRowCheckButton
                                            itemRow={itemRow}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                </Stack>

            </CardContent>
        </Card>
    )
}

export default React.memo(WorksheetEditorPageCatalogueItemRow, (prevProps, nextProps) => {
    return prevProps.itemRow.id === nextProps.itemRow.id
        && prevProps.itemRow.installedQuantity === nextProps.itemRow.installedQuantity
        && prevProps.itemRow.timeUserId === nextProps.itemRow.timeUserId
        && prevProps.disabled === nextProps.disabled
})