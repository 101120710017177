import {useQuery} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {WarmupQueryApi} from "crowbar-api";
import AxiosCancelTokenHelper from "crowbar-api/AxiosCancelTokenHelper";

export const useWarmupQuery = () => {
    return useQuery(['warmUp-query-1'], async (context) => {
        const response = await crowbarApiFactory(WarmupQueryApi)
            .queryWarmup(AxiosCancelTokenHelper.cancelTokenFrom(context.signal))
        return response.data
    }, {
        retry: 3,
        refetchOnWindowFocus: false,
        refetchOnMount: true
    })
}