import React, {useState} from 'react';
import {
    CustomerNotificationConfigurationDialog
} from "modules/admin/worksheet/notifications/customer-notification/results/CustomerNotificationConfigurationDialog";
import {Button, ButtonProps} from "@mui/material";
import {CDAMNMCustomerNotificationConfigurationSearch} from "crowbar-api";
import useTranslateFunction from "shared/language/useTranslateFunction";

export type CustomerNotificationConfigurationDialogButtonProps = ButtonProps & {
    mode: 'new' | 'edit'
    editConfiguration?: CDAMNMCustomerNotificationConfigurationSearch
    onConfigurationChange: () => void
}
const CustomerNotificationConfigurationDialogButton = ({
                                                           mode,
                                                           editConfiguration,
                                                           onConfigurationChange,
                                                           ...props
                                                       }: CustomerNotificationConfigurationDialogButtonProps) => {
    const tk = useTranslateFunction(`Admin.Worksheet`)

    const [open, setOpen] = useState(false)
    const [dialogKey, setDialogKey] = useState<number>(1)

    const onButtonClick = () => {
        setDialogKey(x => x + 1)
        setOpen(true)
    }

    const onDialogConfigurationChange = () => {
        setOpen(false)
        onConfigurationChange?.()
    }

    return (
        <>
            <Button onClick={onButtonClick} {...props}>
                {mode === "edit" ? tk('Edit') : tk('AddNew')}
            </Button>

            <CustomerNotificationConfigurationDialog
                key={dialogKey}
                mode={mode}
                editConfiguration={editConfiguration}
                onDialogClose={() => setOpen(false)}
                onConfigurationChange={onDialogConfigurationChange}

                open={open}
                onClose={() => setOpen(false)}
            />
        </>
    );
};

export default CustomerNotificationConfigurationDialogButton;