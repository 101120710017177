import React from 'react';
import {CDAMWMESapEquipmentServiceCallBlobQuery, CDAMWMServiceCallEvalModel, CDAMWMServiceCallModel} from "crowbar-api";
import {Link} from "@mui/material";

export type ImagesCellProps = {
    row: CDAMWMServiceCallEvalModel
    serviceCalls: CDAMWMServiceCallModel[]
    imageBlobs: CDAMWMESapEquipmentServiceCallBlobQuery[]
}
const ImagesCell = ({row, serviceCalls, imageBlobs}: ImagesCellProps) => {
    const sc = serviceCalls.find(sc => sc.id === row.serviceCallId)
    if (!sc) return (
        <span>-</span>
    )

    const count = imageBlobs.filter(i => i.serviceCallId === row.serviceCallId)
        .length

    return (
        <Link
            target="_blank"
            href={`/#/equipment/details?sapEquipmentId=${sc.sapEquipmentId ?? ""}&modSapEquipmentId=${sc.modSapEquipmentId ?? ""}`}
            sx={{
                ":hover": {
                    "color": "red"
                }
            }}
        >
            {count}
        </Link>
    )
};

export default ImagesCell;