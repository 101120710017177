import {isObject, transform} from "lodash";

export type ValuesOrderedCompareFn = (a: [string, any], b: [string, any]) => number

export class ObjectUtils {
    /*public static copyMatchingPropertiesFrom = <S  extends object, T extends S, >(source: S, into: T) => {
        for (const [key, value] of Object.entries(source)) {
            if (key in into) {
                Object.assign(into, {[key]: value})
            }
        }
    }

    public static copyMatchingPropertiesFromSameType = <S extends object, T extends S, >(source: S, into: T) => {
        for (const [key, value] of Object.entries(source)) {
            if (key in into) {
                Object.assign(into, {[key]: value})
            }
        }
    }*/

    /*public static copyMatchingPropertiesAsNew = <S  extends object, T extends new() => T, >(source: S): T => {
        const result: T = {} as T
        ObjectUtils.copyMatchingPropertiesFrom(source, result)
        return result
    }*/

    public static cloneDeepAndSkip = <T extends object>(obj: T, skipFields: string[], maxDepth: number = 10): T =>
        transform(obj, (r: any, v: any, k: string | number | symbol) => {
            //if (isUndefined(v) || isNull(v)) return;
            if (k && typeof k === 'string' && skipFields.includes(k)) return;
            r[k] = isObject(v) && maxDepth > 0 ? ObjectUtils.cloneDeepAndSkip(v, skipFields, maxDepth - 1) : v;
        });

    public static cloneDeepWithOptions = <T extends object>(
        obj: T,
        options: {
            // skip these fields
            skipFields: string[],
            // change any null value to undefined
            transformNullToUndefined?: boolean
        },
        maxDepth: number = 10
    ): T =>
        transform(obj, (r: any, v: any, k: string | number | symbol) => {
            //if (isUndefined(v) || isNull(v)) return;
            if (k && typeof k === 'string' && options.skipFields.includes(k)) return;

            let usedValue = v
            if (options.transformNullToUndefined) {
                if (usedValue === null) {
                    usedValue = undefined
                }
            }

            r[k] = isObject(usedValue) && maxDepth > 0 ? ObjectUtils.cloneDeepWithOptions(usedValue, options, maxDepth - 1) : usedValue;
        });

    public static valuesOrdered = <T extends object>(obj: T, compareFn?: ValuesOrderedCompareFn): any[] => {
        const usedCompare: ValuesOrderedCompareFn = compareFn
            ? compareFn
            : ([keyOfA], [keyOfB]) => {
                if (keyOfA < keyOfB) {
                    return -1;
                }
                if (keyOfA > keyOfB) {
                    return 1;
                }
                return 0;
            }

        return Object.entries(obj)
            .sort(usedCompare)
            .map(([, value]) => value)
    }
}