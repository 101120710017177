import React from 'react';
import {Container, Grid, Paper, Typography} from "@mui/material";
import {NavLink} from "react-router-dom";
import useTranslateFunction from "shared/language/useTranslateFunction";

const WorksheetAdminMenus = () => {
    const tk = useTranslateFunction('Header.Nav')
    return (
        <Container>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h4">Worksheet Admin</Typography>
                </Grid>

                <Grid item xs={12}>
                    <Paper elevation={2} sx={{
                        p: 2
                    }}>
                        <Typography variant="h5">Worksheets</Typography>

                        <Grid container spacing={2}>
                            <Grid item>
                                <NavLink to="/admin/worksheet-admin/worksheets" style={{
                                    textDecoration: "none"
                                }}>
                                    <span>{tk('WorksheetAdmin')}</span>
                                </NavLink>
                            </Grid>
                        </Grid>

                    </Paper>
                </Grid>

                <Grid item xs={12}>
                    <Paper elevation={2} sx={{
                        p: 2
                    }}>
                        <Typography variant="h5">Master Data</Typography>

                        <Grid container spacing={2}>
                            <Grid item>
                                <NavLink to="/admin/worksheet-admin/mechanic-admin" style={{
                                    textDecoration: "none"
                                }}>
                                    <span>{tk('MechanicAdmin')}</span>
                                </NavLink>
                            </Grid>
                            <Grid item>
                                <NavLink to="/admin/worksheet-admin/worksheet-trip-item-codes" style={{
                                    textDecoration: "none"
                                }}>
                                    <span>{tk('WorksheetTripItemCodes')}</span>
                                </NavLink>
                            </Grid>
                            <Grid item>
                                <NavLink to="/admin/worksheet-admin/worksheet-time-item-codes" style={{
                                    textDecoration: "none"
                                }}>
                                    <span>{tk('WorksheetTimeItemCodes')}</span>
                                </NavLink>
                            </Grid>
                            <Grid item>
                                <NavLink to="/admin/worksheet-admin/call-types" style={{
                                    textDecoration: "none"
                                }}>
                                    <span>{tk('CallTypes')}</span>
                                </NavLink>
                            </Grid>
                        </Grid>

                    </Paper>
                </Grid>

                <Grid item xs={12}>
                    <Paper elevation={2} sx={{
                        p: 2
                    }}>
                        <Typography variant="h5">Photo Rules</Typography>

                        <Grid container spacing={2}>
                            <Grid item>
                                <NavLink to="/admin/worksheet-admin/photo-rule/templates" style={{
                                    textDecoration: "none"
                                }}>
                                    <span>{tk('PhotoRuleTemplates')}</span>
                                </NavLink>
                            </Grid>
                            <Grid item>
                                <NavLink to="/admin/worksheet-admin/photo-rule/rules" style={{
                                    textDecoration: "none"
                                }}>
                                    <span>{tk('PhotoRules')}</span>
                                </NavLink>
                            </Grid>
                        </Grid>


                    </Paper>
                </Grid>

                <Grid item xs={12}>
                    <Paper elevation={2} sx={{
                        p: 2
                    }}>
                        <Typography variant="h5">Notifications</Typography>

                        <Grid container spacing={2}>
                            <Grid item>
                                <NavLink to="/admin/worksheet-admin/worksheet-notifications" style={{
                                    textDecoration: "none"
                                }}>
                                    <span>{tk('Notifications')}</span>
                                </NavLink>
                            </Grid>

                            <Grid item>
                                <NavLink to="/admin/worksheet-admin/worksheet-notifications/agent-configuration"
                                         style={{
                                             textDecoration: "none"
                                         }}>
                                    <span>{tk('AgentNotificationConfiguration')}</span>
                                </NavLink>
                            </Grid>

                            <Grid item>
                                <NavLink to="/admin/worksheet-admin/worksheet-notifications/customer-configuration"
                                         style={{
                                             textDecoration: "none"
                                         }}>
                                    <span>{tk('CustomerNotificationConfiguration')}</span>
                                </NavLink>
                            </Grid>
                        </Grid>


                    </Paper>
                </Grid>

            </Grid>
        </Container>
    );
};

export default WorksheetAdminMenus;