import React from 'react';
import {CDAMWMServiceCallEvalModel, CDAMWMServiceCallModel, CDAMWMWWorksheetQuery} from "crowbar-api";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {
    useServiceCallEvalUpdateLinkEquipmentToServiceCall
} from "crowbar-api/hooks/worksheet/evaluation/service-call/useServiceCallEvalUpdateLinkEquipmentToServiceCall";
import {Checkbox, FormControlLabel} from "@mui/material";

export type LinkEquipmentToServiceCallCheckboxProps = {
    worksheet: CDAMWMWWorksheetQuery | null | undefined
    sc: CDAMWMServiceCallModel
    scEval: CDAMWMServiceCallEvalModel
    disabled?: boolean
    onMasterDataChange: () => Promise<void>
}
const LinkEquipmentToServiceCallCheckbox = ({
                                                worksheet,
                                                sc,
                                                scEval,
                                                disabled,
                                                onMasterDataChange
                                            }: LinkEquipmentToServiceCallCheckboxProps) => {
    const tk = useTranslateFunction('Admin.Worksheet')

    const updateLinkEquipmentToServiceCall = useServiceCallEvalUpdateLinkEquipmentToServiceCall()

    const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked;
        await updateLinkEquipmentToServiceCall.mutateAsync({
            evalId: scEval.id,
            originalId: scEval.serviceCallId,
            field: checked
        })
        await onMasterDataChange()
    }

    const value = scEval?.linkEquipmentToServiceCall ?? false

    return (
        <FormControlLabel
            disabled={disabled || updateLinkEquipmentToServiceCall.isLoading || !!scEval.selectedCallId}
            control={
                <Checkbox
                    onChange={onChange}
                    checked={value}
                />
            }
            label={tk('LinkEquipmentToServiceCall')}
        />
    )
};

export default LinkEquipmentToServiceCallCheckbox;