import React from 'react';
import {useWorksheetCartState, WorksheetCartEntry} from "modules/worksheet/worksheet-cart/useWorksheetCartState";
import {Card, CardContent, Grid, IconButton, Typography} from "@mui/material";
import {dateDisplayDefaultOptions} from "shared/components/date-display/DateDisplay";
import DateRangeDisplay from "shared/components/date-display/DateRangeDisplay";

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

export type WorksheetCartEntryProps = {
    scope?: string | number
    entry: WorksheetCartEntry
}

const WorksheetCartEntryCard = ({scope, entry}: WorksheetCartEntryProps) => {

    const {removeCartEntry} = useWorksheetCartState(scope)

    const onDeleteClick = () => {
        removeCartEntry(entry.id)
    }

    return (
        <Card variant="outlined">
            <CardContent sx={{width: 1, p: 1, '&:last-child': {pb: 1}}}>
                <Grid container spacing={0.5}>
                    <Grid item xs={6}>
                        <Typography fontWeight="bold">
                            {entry.serialId}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{
                        display: "flex",
                        justifyContent: "flex-end"
                    }}>
                        <DateRangeDisplay
                            startDate={entry.startedAt}
                            endDate={entry.endedAt}
                            options={{
                                ...dateDisplayDefaultOptions,
                                hour: undefined,
                                minute: undefined
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography fontStyle="italic">
                            {entry.mechanicName}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{
                        display: "flex",
                        justifyContent: "flex-end"
                    }}>
                        <IconButton color="primary" onClick={onDeleteClick}>
                            <DeleteOutlineIcon/>
                        </IconButton>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default WorksheetCartEntryCard;