import React from 'react';
import {CDAMWMWWorksheetSearchQuery} from "crowbar-api";
import {Box, Button, Card, CardActions, CardContent, Grid, Stack, Typography} from "@mui/material";
import ServiceCallHeaderGridItems from "modules/worksheet/mechanic/results/ServiceCallHeaderGridItems";
import ResultEntryModeWorksheetServiceCall
    from "modules/worksheet/mechanic/results/ResultEntryModeWorksheetServiceCall";
import {WorksheetIconImages} from "modules/worksheet/editor/shared/component/images/WorksheetIconImages";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {WorksheetDownloadPdfButton} from "modules/admin/worksheet/worksheet-list/results/WorksheetDownloadPdfButton";
import {useUserRoles} from "shared/hook/useUserRoles";
import MoreMenu from "modules/admin/worksheet/worksheet-list/actions/MoreMenu";
import {useNavigate} from "react-router-dom";
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import {red} from "@mui/material/colors";
import ImageIcon from '@mui/icons-material/Image';

export type ResultEntryModeWorksheetProps = {
    worksheet: CDAMWMWWorksheetSearchQuery
    onStateChanged?: () => void
}

export const ResultEntryModeWorksheet = ({worksheet, onStateChanged}: ResultEntryModeWorksheetProps) => {
    const tk = useTranslateFunction(`Admin.Worksheet`)

    const navigate = useNavigate()

    const serviceCalls = worksheet?.serviceCalls ?? []

    const {canEvaluateWorksheets} = useUserRoles()

    return (
        <Card elevation={16} sx={{
            p: 2,
            border: worksheet.isDeleted ? "2px solid red" : ""
        }}>
            <CardContent>
                <Grid container spacing={1}>

                    <Grid item xs={12}>
                        <Stack direction="row" spacing={1}>
                            <ServiceCallHeaderGridItems
                                worksheet={worksheet}
                                serviceCallId={undefined}
                            />

                            <Box>
                                <MoreMenu
                                    worksheet={worksheet}
                                    onStateChanged={onStateChanged}
                                />
                            </Box>

                        </Stack>
                    </Grid>

                    {worksheet.adminComment && (
                        <Grid item xs={12}>
                            <Card variant="outlined" sx={{p: 1}}>
                                <Typography variant="body2" fontSize="10pt" alignItems="center" color={red[500]}>
                                    <ChatBubbleOutlineIcon sx={{fontSize: "10pt", color: red[500]}}/>
                                    <b color="black">Admin comment:</b> {worksheet.adminComment}
                                </Typography>
                            </Card>
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <Grid container spacing={0.5}>
                            {serviceCalls.map(sc => (
                                <Grid item xs={12} key={sc.id}>
                                    <ResultEntryModeWorksheetServiceCall
                                        worksheet={worksheet}
                                        serviceCall={sc}
                                        hideOpenButton={true}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>

                </Grid>
            </CardContent>
            <CardActions
                sx={{
                    px: 2,
                    display: "flex",
                    justifyContent: "space-between"
                }}
            >
                {canEvaluateWorksheets && (
                    <Button onClick={() => navigate(`/admin/worksheet-admin/evaluation/by-wid/${worksheet.id}`)}>
                        {tk('Evaluation')}
                    </Button>
                    /*<Stack direction="row" spacing={1}>
                       <StatusChange
                            worksheet={worksheet}
                            onStateChanged={onStateChanged}
                        />
                        <Button onClick={() => navigate(`/admin/worksheet-admin/evaluation/by-wid/${worksheet.id}`)}>
                            Evaluation
                        </Button>
                    </Stack>*/
                )}

                <Stack direction="row" spacing={1}>
                    {(worksheet?.numberOfUploadedImages && worksheet.numberOfUploadedImages > 0) ? (
                        <Button
                            variant="outlined"
                            size="large"
                            onClick={() => navigate(`/admin/worksheet-admin/worksheet-images/${worksheet.id}`)}
                        >
                            <ImageIcon/>
                            {tk('Images')} ({worksheet.numberOfUploadedImages})
                        </Button>
                    ) : (<></>)}
                    <WorksheetDownloadPdfButton
                        worksheetId={worksheet?.id}
                        label={tk('CustomerVersion')}
                        image={WorksheetIconImages.pdfColor}
                        pdfLayout="customer"
                    />
                    <WorksheetDownloadPdfButton
                        worksheetId={worksheet?.id}
                        label={tk('AdminVersion')}
                        image={WorksheetIconImages.pdfBlack}
                        pdfLayout="admin"
                    />
                </Stack>
            </CardActions>
        </Card>
    )
}