import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import DeliveryNoteList from "./DeliveryNoteList";
import {CDAMDMDeliveryNoteModel, CDAMDMDeliveryNoteType, CDAMDMLeaseStatus} from "crowbar-api";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SettingsIcon from '@mui/icons-material/Settings';
import DeliveryNoteNewDialog from "./DeliveryNoteDialog";
import DeliveryNoteSearch from "modules/delivery-note/delivery-note/DeliveryNoteSearch";
import {Container} from "@mui/material";


interface DeliveryNoteState {
    noteListKey: number
    filterSearch: string
    filterType?: CDAMDMDeliveryNoteType
    filterLeaseStatus?: CDAMDMLeaseStatus
    filterShowDeleted?: boolean

    newDialogKey: number
    dialogOpen: boolean
    dialogMode: 'new' | 'edit'
    dialogEditItem?: CDAMDMDeliveryNoteModel
}

const DeliveryNote = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const [state, setState] = useState<DeliveryNoteState>({
        dialogOpen: false,
        noteListKey: 1,
        newDialogKey: 1,
        filterSearch: '',
        dialogMode: 'new'
    })

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const menuItems = [
        {
            key: 'licensePlate',
            text: t('DeliveryNote.LicensePlate.Title'),
            path: '/delivery-notes/license-plate'
        },
        {
            key: 'template',
            text: t('DeliveryNote.Title'),
            path: '/delivery-notes/template'
        }
    ]

    const handleNavigate = (path: string) => {
        if (path) navigate(path)
        setAnchorEl(null);
    };

    const _onFilterChange = (search: string, type?: CDAMDMDeliveryNoteType, leaseStatus?: CDAMDMLeaseStatus, showDeleted?: boolean): void => {
        setState((p) => {
            return {
                ...p,
                filterSearch: search,
                filterType: type,
                filterLeaseStatus: leaseStatus,
                filterShowDeleted: showDeleted
            }
        });
    }

    const _onListEditClick = (dn: CDAMDMDeliveryNoteModel) => {
        setState((p) => {
            return {
                ...p,
                dialogMode: "edit",
                dialogEditItem: dn,
                dialogOpen: true
            }
        })
    }

    const _onDialogClose = (action: 'save' | 'cancel'): void => {
        setState((prevState) => {
            return {
                ...prevState,
                dialogOpen: false,
                dialogMode: "new",
                dialogEditItem: undefined,
                // Destroy old component and force to create new
                // by setting the key of the component to + 1
                newDialogKey: prevState.newDialogKey + 1,
                // Load the page again by re-rendering the list component
                // we dont change filters (user will change it he wants
                noteListKey: (action === "cancel") ? prevState.noteListKey : prevState.noteListKey + 1
            }
        })
    }

    return (
        <Container maxWidth={"lg"}>
            <Box sx={{display: "flex", alignItems: "center"}}>
                <h1><i className="bi bi-truck"/> {t('DeliveryNote.Note.Header')}
                    <Button
                        sx={{marginLeft: "20px"}}
                        variant="contained"
                        disableElevation
                        onClick={handleClick}
                        endIcon={<KeyboardArrowDownIcon/>}
                    >
                        <SettingsIcon/>{t('DeliveryNote.Note.Settings')}
                    </Button>
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                    >
                        {menuItems.map((item) => {
                            return <MenuItem onClick={() => handleNavigate(item.path)} key={item.key} disableRipple>
                                {item.text}
                            </MenuItem>
                        })}
                    </Menu>
                </h1>
            </Box>

            <DeliveryNoteNewDialog key={'nd' + state.newDialogKey}
                                   open={state.dialogOpen}
                                   mode={state.dialogMode}
                                   onNewDialogOpen={() => {
                                       setState((p) => {
                                           return {
                                               ...p,
                                               dialogOpen: true,
                                               dialogMode: "new",
                                               dialogEditItem: undefined
                                           }
                                       })
                                   }}
                                   onDialogClose={() => {
                                       setState((p) => {
                                           return {
                                               ...p,
                                               dialogOpen: false
                                           }
                                       })
                                   }}
                                   editItem={state.dialogEditItem}
                                   onSave={() => _onDialogClose('save')}
                                   onCancel={() => _onDialogClose('cancel')}
            />

            <DeliveryNoteSearch onFilterChange={_onFilterChange}
            />

            <DeliveryNoteList key={'nl' + state.noteListKey}
                              filterSearch={state.filterSearch}
                              filterType={state.filterType}
                              filterLeaseStatus={state.filterLeaseStatus}
                              filterShowDeleted={state.filterShowDeleted}
                              onEditClick={_onListEditClick}
            />
        </Container>
    )
}

export default DeliveryNote