import {useMutation} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {CDAMWMESingleFieldWorksheetEvalUpdate1Decimal, ServiceCallItemRowEvalApi} from "crowbar-api";
import {AxiosRequestConfig} from "axios";

export const useServiceCallItemRowEvalUpdateInstalledQuantity = () => {
    return useMutation(async (p: CDAMWMESingleFieldWorksheetEvalUpdate1Decimal, options?: AxiosRequestConfig) => {
        await crowbarApiFactory(ServiceCallItemRowEvalApi)
            .updateInstalledQuantity(p, options)
    })
}