const i18n_homeEn = {
    "language": "Language",
    "lang": "English",
    "langChange": "Change language",
    "Hungarian": "Hungarian",
    "English": "English",
    "ConnectedToTeamsToast": "Connected to Teams :)",
    "TabConfig": {
        "Module": "Module",
        "Select": "Select",
        "ServiceIssueReport": "Service issue reporting",
        "DeliveryNote": "Delivery note",
    },
    "Error": {
        "InternalErrorTitle": "Internal error",
        "MessageKeyMissing": "Expression key missing for translation"
    },
    "FormGeneric": {
        "RequiredField": "This field is required."
    },
    "MechanicDashboard": {
        "MyWorksheets": "My worksheets",
        "DraftNum": "Number of drafts",
        "FinalNum": "Number of unsigned",
        "Open": "Open",
    }
};
export default i18n_homeEn;