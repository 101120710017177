import {Field, FieldProps} from "formik";
import React, {useState} from "react";
import {Autocomplete, TextField} from "@mui/material";
import {SapSelectOption} from "crowbar-api/enum-map/SapMappingUtils";

export type FormikAutoCompleteProps = {
    fieldName: string
    label?: string
    type?: string
    //setter?: (fieldName: string, option: SapSelectOption) => void
    setter?: (fieldName: string, key?: string, value?: string) => void
    disabled?: boolean
    options: SapSelectOption[]
    required?: boolean
    freeSolo: boolean
}

const FormikAutoComplete = ({
                                fieldName,
                                type,
                                setter,
                                disabled = false,
                                options,
                                label,
                                required,
                                freeSolo = false,
                            }: FormikAutoCompleteProps) => {
    const [typedValue, setTypedValue] = useState<string>("");

    return (
        <Field name={fieldName} type={type}>
            {(fieldProps: FieldProps) => (
                <Autocomplete
                    freeSolo={freeSolo}
                    size="small"
                    disabled={disabled && options?.length === 0}
                    options={options}
                    value={fieldProps.field.value}
                    onChange={(event, newValue, reason) => {
                        if (reason === "selectOption") {
                            setTypedValue("")
                            setter?.(fieldName, newValue.key, newValue.text);
                        }
                    }}
                    onInputChange={(event, newValue, reason) => {
                        if (reason === "clear") {
                            setTypedValue("")
                            setter?.(fieldName, undefined, "");
                        }
                        if (reason === "input") {
                            setTypedValue(newValue);
                        }
                    }}
                    onBlur={() => {
                        if (typedValue) {
                            setter?.(fieldName, undefined, typedValue)
                        }
                    }}
                    getOptionLabel={() => {
                        return fieldProps.field.value ?? "";
                    }}
                    isOptionEqualToValue={(option, value) => option.key === value.key}
                    renderInput={(params) => <TextField {...params}
                                                        label={label}
                                                        required={required}
                                                        error={!!fieldProps.meta.error}
                                                        helperText={fieldProps.meta.error ?? ""}
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}/>}
                    renderOption={
                        (props, option) => {
                            return (<li {...props} key={option.key}>
                                {option.text}
                            </li>)
                        }
                    }
                />
            )}
        </Field>
    )
}

export default FormikAutoComplete;