import React from 'react';
import {Grid, Stack, Typography} from "@mui/material";
import {BackButton} from "shared/components/buttons/BackButton";
import useTranslateFunction from "shared/language/useTranslateFunction";
import NewWorksheetFilters from "modules/worksheet/new-worksheet/filters/NewWorksheetFilters";
import NewWorksheetResults from "modules/worksheet/new-worksheet/results/NewWorksheetResults";
import ScrollToTopButton from "shared/components/buttons/ScrollToTopButton";
import NewPartnerButton from "modules/worksheet/new-worksheet/new-partner/NewPartnerButton";
import NewPhoenixWorksheetButton from "modules/worksheet/new-worksheet/new-phoenix-worksheet/NewPhoenixWorksheetButton";
import NewWorksheetOptions from "modules/worksheet/new-worksheet/order/NewWorksheetOptions";

const NewWorksheet = () => {
    const tk = useTranslateFunction(`Worksheet.Generic.NewWorksheet`)


    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12}>

                    <Grid container spacing={1} justifyContent="space-between">
                        <Grid item xs="auto">
                            <Typography variant="h4">
                                <BackButton useOnClick={true} to={""}/>
                                &nbsp;{tk('NewWorksheet')}
                            </Typography>
                        </Grid>
                        <Grid item xs="auto" sx={{display: "flex", justifyContent: "right"}}>
                            <Stack direction="row" spacing={1}>
                                <NewPhoenixWorksheetButton/>
                                <NewPartnerButton/>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <NewWorksheetFilters/>
                </Grid>

                <Grid item xs={12}>
                    <NewWorksheetOptions/>
                </Grid>

                <Grid item xs={12}>
                    <NewWorksheetResults/>
                </Grid>
            </Grid>

            <ScrollToTopButton/>
        </>
    )
}

export default NewWorksheet;