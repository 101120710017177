import React, {useState} from "react";
import {CDAMDMLicensePlateModel} from "crowbar-api";
import LicensePlateDialog from "modules/delivery-note/license-plate/LicensePlateDialog";
import LicensePlateList from "modules/delivery-note/license-plate/LicensePlateList";
import {Link} from "react-router-dom";
import {Box, Button, Container, IconButton, Stack} from "@mui/material";
import {ArrowCircleLeft} from "@mui/icons-material";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {useLicensePlatesFindAll} from "crowbar-api/hooks/delivery-note/useLicensePlatesFindAll";

const LicensePlate = () => {
    const tk = useTranslateFunction("DeliveryNote.LicensePlate.")

    const licensePlateQuery = useLicensePlatesFindAll();

    const [dialogKey, setDialogKey] = useState<number>(0);
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [DialogMode, setDialogMode] = useState<"new" | "edit">("new");
    const [editItem, setEditItem] = useState<CDAMDMLicensePlateModel | undefined>(undefined);

    const handleNewLicensePlate = () => {
        setDialogKey(x => x + 1);
        setIsDialogOpen(true);
        setDialogMode("new");
        setEditItem(undefined);
    }

    const handleLicensePlateEdit = (lp: CDAMDMLicensePlateModel) => {
        setDialogKey(x => x + 1);
        setIsDialogOpen(true);
        setDialogMode("edit");
        setEditItem(lp);
    }

    const handleDialogCancel = () => {
        setIsDialogOpen(false);
        setEditItem(undefined);
    }

    const handleDialogSubmit = async () => {
        await licensePlateQuery.refetch();
        setIsDialogOpen(false);
        setEditItem(undefined);
    }

    return (
        <Container maxWidth={"lg"}>
            <Box sx={{display: "flex", alignItems: "center"}}>

                <Link to="/delivery-notes">
                    <IconButton color={"primary"}>
                        <ArrowCircleLeft fontSize={"large"}/>
                    </IconButton>
                </Link>

                <h1>{tk("Title")}</h1>
            </Box>

            <Stack spacing={1}>
                <Box>
                    <Button
                        onClick={handleNewLicensePlate}
                        variant={"contained"}
                    >
                        {tk("NewButton")}
                    </Button>
                </Box>

                <LicensePlateDialog
                    open={isDialogOpen}
                    mode={DialogMode}
                    key={dialogKey}
                    editItem={editItem}
                    onCancel={handleDialogCancel}
                    onSubmit={handleDialogSubmit}
                />

                <LicensePlateList
                    onEdit={handleLicensePlateEdit}
                />
            </Stack>
        </Container>
    );
}

export default LicensePlate;