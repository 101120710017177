import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {WorksheetApi} from "crowbar-api";
import AxiosCancelTokenHelper from "crowbar-api/AxiosCancelTokenHelper";
import {useQuery} from "@tanstack/react-query";
import {UseQueryOptionParams} from "crowbar-api/util/UseQueryOptionParams";

export const queryWorksheetQuery = async (id: string | null | undefined, signal?: AbortSignal | undefined) => {
    if (!id) return null
    const r = await crowbarApiFactory(WorksheetApi, undefined, true)
        .findWorksheetQueryById(id, false, AxiosCancelTokenHelper.cancelTokenFrom(signal))
    return r?.data ?? null
}

export const useWorksheetQuery = (id: string | null | undefined, extraOptions?: UseQueryOptionParams) => {
    return useQuery(['worksheet-query-1', id], async (context) => {
        return queryWorksheetQuery(id, context.signal)
    }, {
        refetchOnMount: true,
        refetchOnWindowFocus: true,
        cacheTime: 0,
        staleTime: 0,
        retry: 3,
        ...extraOptions
    })
}