import React from 'react';
import {withTranslation, WithTranslation} from "react-i18next";
import {FormikProps} from "formik";
import {
    CDAMDMDeliveryNoteModel,
    CDCMUserDetails,
    CDSMSapEmployee,
    CDSMSapPartnerContact,
    SapEmployeeApi,
    SapPartnerContactApi,
    UserProfileApi
} from "crowbar-api";
import {IDeliveryNoteDialogFields} from "./IDeliveryNoteDialogFields";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import axios, {AxiosRequestConfig, CancelTokenSource} from "axios";
import {toastRequestError} from "shared/toast/DefaultToasts";
import SapMappingUtils, {SapSelectOption} from "crowbar-api/enum-map/SapMappingUtils";
import CrowbarConstants from "crowbar-api/CrowbarConstants";
import {Grid} from "@mui/material";
import FormikAutoComplete from "shared/formik/FormikAutoComplete";

interface DeliveryNoteDialogIssuerFormProps extends WithTranslation {
    formikProps: FormikProps<IDeliveryNoteDialogFields>
    mode: 'new' | 'edit'
    editItem?: CDAMDMDeliveryNoteModel
    supplierCardCode?: string
}

interface DeliveryNoteDialogIssuerFormState {
    formikProps: FormikProps<IDeliveryNoteDialogFields>
    employees: CDSMSapEmployee[]
    contacts: CDSMSapPartnerContact[]
    issuerNameOptions: SapSelectOption[]
    userDetails?: CDCMUserDetails

    supplierCardCode?: string
}

const TKey = "DeliveryNote.Note.Dialog.";

class DeliveryNoteDialogIssuerForm extends React.Component<DeliveryNoteDialogIssuerFormProps, DeliveryNoteDialogIssuerFormState> {
    private readonly _sapEmployeeApi: SapEmployeeApi;
    private readonly _sapPartnerContactApi: SapPartnerContactApi;
    private readonly _userProfileApi: UserProfileApi;

    private cancelTokenSource: CancelTokenSource;
    private axiosOptions: AxiosRequestConfig;

    constructor(props: Readonly<DeliveryNoteDialogIssuerFormProps> | DeliveryNoteDialogIssuerFormProps) {
        super(props);

        this.cancelTokenSource = axios.CancelToken.source();
        this.axiosOptions = {
            cancelToken: this.cancelTokenSource.token
        }

        this._sapEmployeeApi = crowbarApiFactory(SapEmployeeApi);
        this._sapPartnerContactApi = crowbarApiFactory(SapPartnerContactApi);
        this._userProfileApi = crowbarApiFactory(UserProfileApi);

        this.state = {
            formikProps: this.props.formikProps,
            employees: [],
            contacts: [],
            issuerNameOptions: [],
            supplierCardCode: this.props.supplierCardCode,
        }
    }

    componentDidMount() {
        this.cancelTokenSource = axios.CancelToken.source();
        this.axiosOptions = {
            cancelToken: this.cancelTokenSource.token
        }

        this._loadUserProfileSelectAndAddToIssuer()
        this._loadEmployeesToState()
    }

    componentDidUpdate(prevProps: Readonly<DeliveryNoteDialogIssuerFormProps>, prevState: Readonly<DeliveryNoteDialogIssuerFormState>, snapshot?: any) {
        if (this.state.formikProps !== this.props.formikProps) {
            this.setState({
                formikProps: this.props.formikProps
            })
        }

        if (this.state.supplierCardCode !== this.props.supplierCardCode) {
            this.setState({
                supplierCardCode: this.props.supplierCardCode
            }, () => {
                this._supplierCardCodeChanged(this.props.supplierCardCode)
            })
        }
    }

    componentWillUnmount() {
        this.cancelTokenSource.cancel()
    }

    _loadUserProfileSelectAndAddToIssuer = () => {
        this._loadUserProfile().then(up => {
            const name = up ? `${up.surname} ${up.givenName}` : undefined
            this.setState((prevState) => {
                const newOptions = [...prevState.issuerNameOptions, {key: 'msUser', text: name ?? 'N/A'}]
                if (this.props.mode === "edit") {
                    newOptions.push({key: 'preValue', text: this.props.editItem?.issuerName ?? ''})
                }
                return {
                    userDetails: up,
                    issuerNameOptions: newOptions
                }
            }, () => {
                if (this.props.mode === "new") {
                    this.state.formikProps.setFieldValue('issuerNameValue', name ?? '')
                    this.state.formikProps.setFieldValue('issuerNameKey', 'msUser' ?? '')
                } else {
                    // Patch value from editItem
                    this.state.formikProps.setFieldValue('issuerNameValue', this.props.editItem?.issuerName ?? '')
                    this.state.formikProps.setFieldValue('issuerNameKey', 'preValue')
                }
            })
        })
    }

    _loadUserProfile = (): Promise<CDCMUserDetails | undefined> => {
        return this._userProfileApi.userDetails(this.axiosOptions).then(response => {
            if (response.status === 200) {
                return response.data
            } else {
                toastRequestError(this.props.t, {
                    name: 'DnIssuerUser',
                    response: response,
                    reason: 'Http status not OK'
                })
                return undefined
            }
        })
    }

    _loadEmployees = (): Promise<CDSMSapEmployee[] | undefined> => {
        return this._sapEmployeeApi.findAll(this.axiosOptions).then(response => {
            if (response.status === 200) {
                return response.data
            } else {
                toastRequestError(this.props.t, {
                    name: 'DnIssuerEmployees',
                    response: response,
                    reason: 'Http status not OK'
                })
                return undefined
            }
        })
    }

    _loadEmployeesToState = () => {
        this._loadEmployees().then(employees => {
            if (!employees) return
            const mapped = new SapMappingUtils(this.props.t).mapEmployeeToDropdown(employees);
            this.setState((prevState) => {
                return {
                    employees: employees,
                    issuerNameOptions: [...prevState.issuerNameOptions, ...mapped]
                }
            })
        })
    }

    _loadPartnerContactsFor = (cardCode: string): Promise<CDSMSapPartnerContact[] | undefined> => {
        return this._sapPartnerContactApi.findByCardCode(cardCode).then(response => {
            if (response.status === 200) {
                return response.data;
            } else {
                toastRequestError(this.props.t, {
                    name: 'DnIssuerLoadPartnerContacts',
                    response: response,
                    reason: 'Http status not OK'
                })
                return undefined
            }
        })
    }

    _loadPartnerContactsToState = (cardCode: string) => {
        this._loadPartnerContactsFor(cardCode).then(contacts => {
            if (!contacts) return
            const mappedContacts = new SapMappingUtils(this.props.t).mapContactsToDropdown(contacts);
            this.setState((prevState) => {
                const filtered = mappedContacts.filter(mapped => prevState.issuerNameOptions.find(issuerName => issuerName.key === mapped.key) === undefined)
                return {
                    contacts: prevState.contacts,
                    issuerNameOptions: [...prevState.issuerNameOptions, ...filtered]
                }
            })
        })
    }

    _supplierCardCodeChanged = (sCardCode?: string): void => {
        this.setState({
            issuerNameOptions: []
        }, () => {
            if (sCardCode === CrowbarConstants.PhoenixForkliftCardCode) {
                this._loadUserProfileSelectAndAddToIssuer()
                this._loadEmployeesToState()
            } else if (sCardCode) {
                this._loadPartnerContactsToState(sCardCode)
            }
        })
    }

    render() {
        const t = this.props.t;
        const fProps = this.state.formikProps;
        return (
            <React.Fragment>
                <Grid container item xs={12} md={6}>
                    <Grid item xs={9}>
                        <FormikAutoComplete fieldName="issuerNameValue" label={t(`${TKey}IssuerName`)}
                                            options={this.state.issuerNameOptions}
                                            setter={(fieldName, key, value) => {
                                                fProps.setFieldValue("issuerNameKey", key)
                                                fProps.setFieldValue("issuerNameValue", value)
                                            }}
                                            freeSolo={true}
                                            required={true}
                        />
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

export default withTranslation()(DeliveryNoteDialogIssuerForm);