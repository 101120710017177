import i18n, * as i18next from 'i18next';
//import Backend from 'i18next-http-backend';
//import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';
import i18n_sirI18nHu from "./service-issue-reports/sir-i18n.hu";
import i18n_sirI18nEn from "./service-issue-reports/sir-i18n.en";
import i18n_homeHu from "./home/home.hu";
import i18n_homeEn from "./home/home.en";
import {i18n_toastHu} from "./shared/toast.hu";
import {i18n_toastEn} from "./shared/toast.en";
import {i18n_genericHu} from "./shared/generic.hu";
import {i18n_genericEn} from "./shared/generin.en";
import {i18n_alertsEn} from "./shared/alerts.en";
import {i18n_alertsHu} from "./shared/alerts.hu";
import {i18n_dnHu} from "./delivery-note/dn.hu";
import {i18n_dnEn} from "./delivery-note/dn.en";
import {i18n_country_en, i18n_country_hu} from "./country";
import {i18n_header_en} from "./shared/header.en";
import {i18n_header_hu} from "./shared/header.hu";
import {i18n_adminHu} from "i18n/admin/admin.hu";
import {i18n_adminEn} from "i18n/admin/admin.en";
import {i18n_worksheet_generic_en} from "i18n/worksheet/generic.en";
import {i18n_worksheet_generic_hu} from "i18n/worksheet/generic.hu";
import {i18n_equipmentHu} from "i18n/equipment/equipment.hu";
import {i18n_equipmentEn} from "i18n/equipment/equipment.en";

// https://www.i18next.com/translation-function/essentials
// Translate: Use dot (.) to separate deeper links.
const resources = {
    hu: {
        translation: {
            "Admin": i18n_adminHu,
            "Alerts": i18n_alertsHu,
            "Country": i18n_country_hu,
            "DeliveryNote": i18n_dnHu,
            "Equipment": i18n_equipmentHu,
            "Generic": i18n_genericHu,
            "Header": i18n_header_hu,
            "Toast": i18n_toastHu,
            "Home": i18n_homeHu,
            "Sir": i18n_sirI18nHu,
            "Worksheet": {
                "Generic": i18n_worksheet_generic_hu
            }
        }
    },
    en: {
        translation: {
            "Admin": i18n_adminEn,
            "Alerts": i18n_alertsEn,
            "Country": i18n_country_en,
            "DeliveryNote": i18n_dnEn,
            "Equipment": i18n_equipmentEn,
            "Generic": i18n_genericEn,
            "Header": i18n_header_en,
            "Toast": i18n_toastEn,
            "Home": i18n_homeEn,
            "Sir": i18n_sirI18nEn,
            "Worksheet": {
                "Generic": i18n_worksheet_generic_en
            }
        }
    }
};

// https://github.com/i18next/next-i18next/issues/1781#issuecomment-1092620082
// Workaround for https://github.com/isaachinman/next-i18next/issues/1781
declare module 'i18next' {
    interface TFunction {
        <TKeys extends i18next.TFuncKey = string,
            TInterpolationMap extends object = i18next.StringMap>(
            key: TKeys,
            options?: i18next.TOptions<TInterpolationMap> | string,
        ): string;
    }
}

i18n
    // load translation using http -> see /public/locales
    // learn more: https://github.com/i18next/i18next-http-backend
    //.use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    //.use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources,
        // lng: 'hu',
        lng: i18n.options.lng,
        fallbackLng: 'hu',
        debug: true,
        returnObjects: false,
        returnNull: false,
        returnDetails: false,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    });

export default i18n;