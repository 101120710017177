import React from 'react';
import {
    Button,
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import useTranslateFunction from "shared/language/useTranslateFunction";
import DateDisplay from "shared/components/date-display/DateDisplay";
import {CDAMCMMechanicModel} from "crowbar-api";
import {UseQueryResult} from "@tanstack/react-query";
import MechanicAdminDialog from "modules/admin/worksheet/mechanic/MechanicAdminDialog";
import {useNewAtomWithRef} from "shared/hook/useNewatomWithRef";

export interface MechanicAdminListProps {
    mechanicsQuery: UseQueryResult<CDAMCMMechanicModel[], unknown>
}

const MechanicAdminList = ({mechanicsQuery}: MechanicAdminListProps) => {
    const tk = useTranslateFunction("Admin.Mechanic")

    const [dialogKey, setDialogKey] = useNewAtomWithRef<number>(1)
    const [dialogOpen, setDialogOpen] = useNewAtomWithRef<boolean>(false)
    const [editMechanic, setEditMechanic] = useNewAtomWithRef<CDAMCMMechanicModel | undefined>(undefined)

    if (mechanicsQuery.isLoading) {
        return <CircularProgress/>
    }

    const onEdit = (m: CDAMCMMechanicModel) => {
        if (!m?.userId) return
        setDialogKey(x => x + 1)
        setDialogOpen(true)
        setEditMechanic(m)
    }

    const onDialogCancel = () => {
        setDialogOpen(false)
    }

    const onDialogSubmit = () => {
        setDialogOpen(false)
        mechanicsQuery.refetch().then()
    }

    return (
        <>
            <MechanicAdminDialog
                mode="edit"
                editMechanic={editMechanic}
                key={dialogKey}
                open={dialogOpen}
                onCancel={onDialogCancel}
                onSubmit={onDialogSubmit}
            />

            <TableContainer component={Paper}>
                <Table
                    size={"small"}
                    sx={{
                        minWidth: {
                            xs: 1,
                            md: "450px"
                        }
                    }}
                >
                    <TableHead sx={{
                        backgroundColor: "background.dark"
                    }}>
                        <TableRow
                            sx={{
                                backgroundColor: "grey.A200"
                            }}
                        >
                            <TableCell>{tk('Mechanic')}</TableCell>
                            <TableCell>{tk('Create')}</TableCell>
                            <TableCell>{tk('Update')}</TableCell>
                            <TableCell>{tk('Active')}</TableCell>
                            <TableCell>SAP ID</TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(mechanicsQuery.data ?? []).map(m => (
                            <TableRow key={m.userId}>
                                <TableCell>{m.fullName}</TableCell>
                                <TableCell><DateDisplay dateStr={m.createdAt ?? ""}/></TableCell>
                                <TableCell><DateDisplay dateStr={m.updatedAt ?? ""}/></TableCell>
                                <TableCell>{m.active ? tk('Yes') : tk('No')}</TableCell>
                                <TableCell>{m.sapEmployeeId}</TableCell>
                                <TableCell align="right">
                                    <Button variant="text"
                                            onClick={() => onEdit(m)}
                                    >
                                        {tk('Edit')}
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default MechanicAdminList;