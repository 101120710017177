import React from 'react';

interface ImageProps {
    base64Image: string;
}

const Base64Image: React.FC<ImageProps> = ({base64Image}) => {
    const src = (base64Image.startsWith("data:")) ? base64Image : `data:image/jpeg;base64,${base64Image}`
    return (
        <img src={base64Image} alt="Signature"/>
    )
};

export default Base64Image;