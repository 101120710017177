import React from 'react';
import {Grid, Stack} from "@mui/material";
import WEvalHeaderTitle from "modules/admin/worksheet/evaluation/header/WEvalHeaderTitle";
import WEvalHeaderLines from "modules/admin/worksheet/evaluation/header/WEvalHeaderLines";
import WEvalHeaderStatus from "modules/admin/worksheet/evaluation/header/WEvalHeaderStatus";
import {CDAMWMWorksheetEvalModel, CDAMWMWWorksheetQuery} from "crowbar-api";
import {BackButton} from "shared/components/buttons/BackButton";

export type WEvalHeaderProps = {
    wEval: CDAMWMWorksheetEvalModel | null | undefined
    worksheet: CDAMWMWWorksheetQuery | null | undefined
    onDataChange: () => Promise<void>
}
const WEvalHeader = ({wEval, worksheet, onDataChange}: WEvalHeaderProps) => {
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Stack direction="row" spacing={1} display="flex" alignItems="center">
                    <BackButton to="/admin/worksheet-admin/worksheets" iconProps={{sx: {fontSize: "26pt"}}}/>
                    <WEvalHeaderTitle wEval={wEval} worksheet={worksheet}/>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <WEvalHeaderStatus
                    wEval={wEval}
                    worksheet={worksheet}
                    onStateChanged={onDataChange}
                />
            </Grid>
            <Grid item xs={12}>
                <WEvalHeaderLines wEval={wEval} worksheet={worksheet}/>
            </Grid>
        </Grid>
    );
};

export default WEvalHeader;