import React from 'react';
import {
    CDAMWMServiceCallEvalModel,
    CDAMWMServiceCallModel,
    CDAMWMWorksheetEvalModel,
    CDAMWMWWorksheetQuery
} from "crowbar-api";
import {
    useServiceCallItemRowEvalFindByServiceCallEvalId
} from "crowbar-api/hooks/worksheet/evaluation/service-call-item-row/useServiceCallItemRowEvalFindByServiceCallEvalId";
import {
    useServiceCallItemRowsFindByServiceCallId
} from "crowbar-api/hooks/worksheet/service-call-item-rows/useServiceCallItemRowsFindByServiceCallId";
import {CircularProgress, Grid, Typography} from "@mui/material";
import useTranslateFunction from "shared/language/useTranslateFunction";

export type HistoryForItemRowsProps = {
    wEval: CDAMWMWorksheetEvalModel | null | undefined
    worksheet: CDAMWMWWorksheetQuery | null | undefined

    scEval: CDAMWMServiceCallEvalModel
    sc: CDAMWMServiceCallModel
}
const HistoryForItemRows = ({
                                wEval,
                                worksheet,
                                scEval,
                                sc
                            }: HistoryForItemRowsProps) => {

    const tk = useTranslateFunction("Admin.Worksheet")

    const itemRowEvalsQuery = useServiceCallItemRowEvalFindByServiceCallEvalId(scEval.id)
    const itemRowEvals = itemRowEvalsQuery.data ?? []
    const itemRowsQuery = useServiceCallItemRowsFindByServiceCallId(scEval.serviceCallId)
    const itemRows = itemRowsQuery.data ?? []

    if (itemRowsQuery.isLoading || itemRowEvalsQuery.isLoading) {
        return (
            <CircularProgress/>
        )
    }

    return (
        <Grid container spacing={0}>

            <Grid item xs={12}>

            </Grid>

            {itemRows.map(ir => {

                const itemRowEval = itemRowEvals.find(ire => ire.serviceCallItemRowId === ir.id)

                if (!itemRowEval) {
                    return (
                        <Grid item xs={12} key={ir.id}>
                            <Typography>Failed to find row eval for {ir.id}</Typography>
                        </Grid>
                    )
                }

                if (ir.installedQuantity === itemRowEval.originalInstalledQuantity) {
                    return <Grid item xs={12} key={ir.id}></Grid>
                }

                return (
                    <Grid item xs={12} key={ir.id}>
                        <Typography fontWeight="lighter" fontStyle="italic">
                            <Typography component="span">{ir.itemCode} {ir.itemName}&nbsp;</Typography>
                            {itemRowEval.addedDuringEval && (
                                <span>{tk('AddedDuringEval')}&nbsp;</span>
                            )}
                            {tk('WorksheetOriginal')}: {itemRowEval.originalInstalledQuantity ?? "-"}, {tk('CurrentValue')}: {ir.installedQuantity ?? "-"}
                        </Typography>
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default HistoryForItemRows;