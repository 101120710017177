import React, {useEffect, useState} from 'react';
import useTranslateFunction from "shared/language/useTranslateFunction";
import {useWorksheetEvalUpdateEval} from "crowbar-api/hooks/worksheet/evaluation/worksheet/useWorksheetEvalUpdateEval";
import useSapPartnerContactsByCardCode from "crowbar-api/hooks/sap/useSapPartnerContactsByCardCode";
import {LinearProgress, MenuItem, Select, Stack} from "@mui/material";
import SapPartnerContactUtils from "crowbar-api/util/SapPartnerContactUtils";
import RefreshButton from "shared/components/buttons/RefreshButton";
import {CDAMWMWorksheetEvalModel, CDAMWMWWorksheetQuery} from "crowbar-api";

export type PartnerContactMasterDataSelectProps = {
    wEval: CDAMWMWorksheetEvalModel | null | undefined
    worksheet: CDAMWMWWorksheetQuery | null | undefined
    onDataChange: () => void
}

const PartnerContactMasterDataSelect = ({wEval, worksheet, onDataChange}: PartnerContactMasterDataSelectProps) => {

    const tk = useTranslateFunction("Admin.Worksheet")
    const ws = worksheet

    const [selectedContact, setSelectedContact] = useState<number | null>(wEval?.selectedSapContactCode ?? null)

    const evalUpdateEval = useWorksheetEvalUpdateEval()

    useEffect(() => {
        setSelectedContact(wEval?.selectedSapContactCode ?? null)
    }, [wEval?.selectedSapContactCode])

    const updateServerSelectedContactTo = async (newContact: number | null | undefined) => {
        await evalUpdateEval.mutateAsync({
            originalId: ws?.id,
            evalId: wEval?.id,
            partnerAck: wEval?.partnerAck,
            contactAck: wEval?.contactAck,
            addressAck: wEval?.addressAck,
            selectedSapPartnerCardCode: wEval?.selectedSapPartnerCardCode,
            selectedSapAddressAndType: wEval?.selectedSapAddressAndType,
            selectedSapContactCode: newContact
        })
        onDataChange()
    }

    const contactQuery = useSapPartnerContactsByCardCode(ws?.cardCode, {
        refetchOnWindowFocus: true,
        refetchOnMount: true,
        cacheTime: 0,
        staleTime: 0,
        keepPreviousData: true,
        retry: 1
    })
    const contacts = contactQuery?.data ?? []

    if (contactQuery.isLoading) {
        return (
            <LinearProgress/>
        )
    }

    const onRefreshClick = () => {
        contactQuery.refetch().then()
    }

    return (
        <Stack direction="row" spacing={1} alignItems="center">
            <RefreshButton onClick={onRefreshClick} isLoading={contactQuery.isFetching}/>
            <Select
                size="small"
                defaultValue={0}
                fullWidth
                disabled={wEval?.contactAck}
                value={contacts.find(c => c.contactCode === selectedContact)?.contactCode ?? ""}
                onChange={event => {
                    const newValue = +event.target.value
                    const newContact = isNaN(newValue) ? null : newValue
                    setSelectedContact(newContact)
                    updateServerSelectedContactTo(newContact).then()
                }}
            >
                <MenuItem disabled value={0}>{tk('PleaseChoose')}</MenuItem>
                {contacts.map(c => {
                    const key = c.contactCode
                    return (
                        <MenuItem key={key} value={key}>
                            {SapPartnerContactUtils.contactFormat(c)}
                        </MenuItem>
                    )
                })}
            </Select>
        </Stack>
    )
}

export default PartnerContactMasterDataSelect;