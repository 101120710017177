import {useQuery} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {MechanicApi} from "crowbar-api";
import AxiosCancelTokenHelper from "crowbar-api/AxiosCancelTokenHelper";

const useMechanics = () => {
    return useQuery(['mechanics'], async (context) => {
        const r = await crowbarApiFactory(MechanicApi).findAllMechanic(AxiosCancelTokenHelper.cancelTokenFrom(context.signal))
        if (r.status !== 200) {
            throw new Error('Invalid response code.')
        }
        return r.data ?? []
    }, {
        refetchOnMount: "always",
        refetchOnWindowFocus: false,
        cacheTime: Math.pow(10, 7),
        staleTime: Math.pow(10, 7),
        retry: 3
    })
}

export default useMechanics