import React from 'react';
import TextArea, {TextAreaProps} from "shared/components/inputs/TextArea";
import {CDAMWMServiceCallModel} from "crowbar-api";
import {Stack, Typography} from "@mui/material";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {
    useServiceCallEvalUpdateComment
} from "crowbar-api/hooks/worksheet/evaluation/service-call/useServiceCallEvalUpdateComment";

export type ServiceCallCommentProps = {
    sc: CDAMWMServiceCallModel
    evalId: string

    textAreaProps?: Omit<TextAreaProps, 'value' | 'onBlur'>

    disabled?: boolean
}

export const ServiceCallComment = ({sc, evalId, disabled}: ServiceCallCommentProps) => {
    const tk = useTranslateFunction("Admin.Worksheet")
    const updateComment = useServiceCallEvalUpdateComment()

    const onBlur = async (newValue: string) => {
        if (newValue === sc.comment) return

        await updateComment.mutateAsync({
            evalId: evalId,
            originalId: sc?.id,
            field: newValue
        })
        sc.comment = newValue // instead of full refetch
    }

    return (
        <Stack direction="column" spacing={0.5}>
            <Typography variant="body2">{tk('Comment')}</Typography>
            <TextArea
                size="small"
                value={sc?.comment}
                onBlur={onBlur}
                disabled={disabled || updateComment.isLoading}
                minRows={1}
                rows={undefined}
            />
        </Stack>
    )
}