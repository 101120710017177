import React from 'react';
import {Stack, Typography} from "@mui/material";
import ImageIcon from "shared/components/image-icon/ImageIcon";
import {WorksheetIconImages} from "modules/worksheet/editor/shared/component/images/WorksheetIconImages";
import useTranslateFunction from "shared/language/useTranslateFunction";

const ImageNotFoundText = () => {
    const tk = useTranslateFunction(`Equipment`)
    return (
        <Stack direction="row" spacing={1} alignItems="center">
            <ImageIcon image={WorksheetIconImages.sadNotFoundMedium} size={64}/>
            <Typography>{tk('NoImagesFound')}</Typography>
        </Stack>
    );
};

export default ImageNotFoundText;