import {useQuery} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {SapCostRateApi} from "crowbar-api";
import {UseQueryOptionParams} from "crowbar-api/util/UseQueryOptionParams";
import AxiosCancelTokenHelper from "crowbar-api/AxiosCancelTokenHelper";

export const useSapCostRateFindAll = (extraOptions?: UseQueryOptionParams) => {
    return useQuery(['sap-cost-rate-find-all'], async ({signal}) => {
        const response = await crowbarApiFactory(SapCostRateApi)
            .findAll(AxiosCancelTokenHelper.cancelTokenFrom(signal))
        return response.data ?? []
    }, {
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        cacheTime: 0,
        staleTime: 0,
        retry: 1,
        ...extraOptions
    })
}