import {useWorksheetSaveState} from "modules/worksheet/editor/state/WorksheetSaveAtoms";
import {useWorksheetLocalFindWrapper} from "modules/worksheet/editor/shared/data/useWorksheetLocalFindWrapper";
import {useMutation} from "@tanstack/react-query";
import {WorksheetSaveEquals} from "crowbar-api/util/WorksheetSaveEquals";
import {CDAMWMWWorksheetSave} from "crowbar-api";
import {toastWarn} from "shared/toast/DefaultToasts";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {useWorksheetEditorHeaderInfoAtom} from "modules/worksheet/editor/state/WorksheetEditorHeaderAtoms";
import {useNavigate} from "react-router-dom";
import WorksheetStatusMap from "crowbar-api/enum-map/WorksheetStatusMap";


export type UseWorksheetInitializeProps = {
    worksheetId: string | undefined
    serverWorksheet: CDAMWMWWorksheetSave | null | undefined
}

/**
 * We need to decide which worksheet object to use. We have:
 *  - the local value (from indexeddb)
 *  - the server value (from the API)
 * We can't just always use the API value, because the local value can have unsaved changes.
 * The local value will always be available faster than the server value.
 * However, we need to wait for the server value, before we can decide, because
 * the local value can get out of date if the worksheet is updated from another source.
 * (The other source (different device, browser...etc) decided to make changes to the same
 * worksheet, while we already had a local version from the worksheet)
 * Cases:
 * - The local value has unsaved changes
 *      - The API value is newer: We need to ask the user
 *      - The API value is older (can't be the same if we have unsaved changes):
 *           We automatically use the local value
 * - The local value has no unsaved changes: We use the local value
 *
 * Versions     Values      Means                       Action
 * Equals       Equals      Values are saved            Use local, fallback to server
 * Equals       Diffs       Local unsaved changes       Use local, fallback to server
 * Diffs        Equals      Save without real changes.  Use server value (updated version flags)
 * Diffs        Diffs       I. User needs to decide    Load local value, show warning
 *
 *  I. We have local changes, while the server has different version (possibly changed on another device)
 */
export const useWorksheetInitialize = () => {
    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    const navigate = useNavigate()

    const [, setWorksheetSaveState] = useWorksheetSaveState()
    const [, setInfo] = useWorksheetEditorHeaderInfoAtom()

    const findWrapper = useWorksheetLocalFindWrapper()

    return useMutation(['worksheet-initializer-mutation-1'], async ({
                                                                        worksheetId,
                                                                        serverWorksheet
                                                                    }: UseWorksheetInitializeProps) => {
        // Clear the different version flag
        setInfo(prev => {
            return {
                ...prev,
                differentServerVersionFlag: false
            }
        })

        const localWrapperQuery = await findWrapper(worksheetId)
        const localWorksheet = localWrapperQuery?.ws

        if (!serverWorksheet) {
            navigate("/worksheet/failed-to-load")
            return
        }

        const serverWsIsEvaluated = serverWorksheet?.status === WorksheetStatusMap.evaluated
        // const localWsIsEvaluated = localWorksheet?.status === WorksheetStatusMap.evaluated
        const differentServerStatus = serverWorksheet?.status !== localWorksheet?.status && serverWorksheet?.status !== undefined

        // No local value found, just use the server's
        if (!localWorksheet) {
            console.log(`Worksheet initialization. No local value found for ${worksheetId}. Loading server value: ${!serverWorksheet ? 'Missing' : 'Found'}`)
            setWorksheetSaveState(serverWorksheet ?? undefined)
            return
        }

        // Server value is evaluated. Ignore the local value and load the server's value (no changes allowed)
        // or if the local worksheet's status is different from the server's status.
        if (serverWsIsEvaluated || differentServerStatus) {
            console.log(`Worksheet initialization. Worksheet is evaluated for ${worksheetId}. Loading server value: ${!serverWorksheet ? 'Missing' : 'Found'}`)
            if (differentServerStatus) {
                toastWarn(tk('WorksheetForceReloadedFromServer'))
            }
            setWorksheetSaveState(serverWorksheet ?? undefined)
            return
        }

        // check equality
        const worksheetsAreEqual = WorksheetSaveEquals(localWorksheet, serverWorksheet)
        // check version
        const versionsAreEquals = localWorksheet?.version === serverWorksheet?.version &&
            localWorksheet?.previousVersion === serverWorksheet?.previousVersion

        if (versionsAreEquals) {
            // Values are saved or we have local unsaved changes
            console.log(
                `Worksheet initialization. Versions are equal for ${worksheetId}. Loading local value. 
                Server value: ${!serverWorksheet ? 'Missing' : 'Found'}`
            )
            setWorksheetSaveState(localWorksheet ?? serverWorksheet ?? undefined)
        } else if (worksheetsAreEqual) {
            // different version flags but same data
            console.log(
                `Worksheet initialization. Versions are DIFFERENT, 
                but we have the same data for ${worksheetId}. Loading server value. 
                Server value: ${!serverWorksheet ? 'Missing' : 'Found'}`
            )
            setWorksheetSaveState(serverWorksheet ?? undefined)
        } else {
            // different version flags and values, either could make sense?
            console.log(
                `Worksheet initialization. Versions and data are both DIFFERENT 
                for ${worksheetId}. Meaning we have local changes, 
                while the server has different version (possibly changed on another device).
                Loading local value.`
            )
            setWorksheetSaveState(localWorksheet ?? undefined)
            setInfo(prev => {
                return {
                    ...prev,
                    differentServerVersionFlag: true
                }
            })
            toastWarn(tk('DifferentServerVersion'), {
                toastId: "DifferentServerVersion",
                autoClose: false
            })
        }
    })
}