import React from 'react';
import SignatureCanvasForBase64 from 'shared/components/signature/SignatureCanvasForBase64';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    FormControlLabel,
    IconButton,
    Stack,
    Typography
} from "@mui/material";
import {CDAMWMWWorksheetStatusAndSignatureQuery} from "crowbar-api";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {TextField} from "shared/components/inputs/TextField";
import {PrimitiveAtom, useAtom} from "jotai";
import {SignatureState} from "modules/worksheet/signature/signature-box/SignatureState";
import SentryErrorBoundary from "shared/components/error/SentryErrorBoundary";
import FormatClearIcon from "@mui/icons-material/FormatClear";

export type WorksheetSignatureCanvasDialogProps = DialogProps & {
    signatureStateAtom: PrimitiveAtom<SignatureState>
    worksheetStatuses: CDAMWMWWorksheetStatusAndSignatureQuery[]
    signWidth: number
    signHeight: number

    onSignatureClose: () => void
    onSignatureFinished: (getDataUrl: () => string | undefined,
                          isEmpty: () => (boolean | undefined)
    ) => void
}
const WorksheetSignatureCanvasDialog = ({
                                            signatureStateAtom,
                                            worksheetStatuses,
                                            signWidth,
                                            signHeight,
                                            onSignatureClose,
                                            onSignatureFinished,
                                            ...props
                                        }: WorksheetSignatureCanvasDialogProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    const [{withSignature, signName, dataUrl}, setSignatureState] = useAtom(signatureStateAtom)

    const onWithSignatureChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSignatureState(prev => {
            return {
                ...prev,
                withSignature: event.target.checked
            }
        })
    }

    const setSignName = (newName: string) => {
        setSignatureState(prev => {
            return {
                ...prev,
                signName: newName ?? ""
            }
        })
    }

    return (

        <SignatureCanvasForBase64
            signWidth={signWidth}
            signHeight={signHeight}
            initialSignatureBase64={dataUrl ?? undefined}
        >
            {(canvasElement, getDataUrl, clear, isEmpty) => (

                <Dialog {...props}>
                    <DialogContent>

                        <Stack direction="column" spacing={1}>
                            <Typography fontWeight="bold">
                                {tk('Signature')}
                            </Typography>

                            <TextField
                                size="small"
                                showClearButton={!!signName}
                                onClearClick={() => setSignName("")}
                                fullWidth
                                label={tk('Name')}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                autoComplete="off"
                                value={signName ?? ""}
                                onChange={event => setSignName(event.target.value.substring(0, 100))}
                            />

                            <FormControlLabel
                                control={(
                                    <Checkbox checked={withSignature} onChange={onWithSignatureChange}/>
                                )}
                                label={tk('WithSignature')}
                            />

                            {withSignature && (
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    sx={{
                                        border: "2px solid black",
                                        borderRadius: "15px"
                                    }}
                                >
                                    <SentryErrorBoundary>

                                        {canvasElement}

                                    </SentryErrorBoundary>

                                    <IconButton
                                        onClick={() => {
                                            clear()
                                        }}
                                    >
                                        <FormatClearIcon/>
                                    </IconButton>

                                </Stack>
                            )}

                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Stack direction="row" spacing={1} justifyContent="space-between">

                            <Button variant="outlined" color="error" onClick={() => onSignatureClose()}>
                                {tk('Cancel')}
                            </Button>

                            <Button
                                variant="contained"
                                onClick={() => onSignatureFinished(getDataUrl, isEmpty)}
                            >
                                {withSignature ? tk('FinishWithSignature') : tk('FinishWithoutSignature')}
                            </Button>
                        </Stack>
                    </DialogActions>
                </Dialog>
            )}
        </SignatureCanvasForBase64>
    )
}

export default WorksheetSignatureCanvasDialog;