import React, {useState} from 'react';
import {Card, CardContent, Grid, LinearProgress, Stack, Typography} from "@mui/material";
import useTranslateFunction from "shared/language/useTranslateFunction";
import CostRates from "modules/admin/worksheet/evaluation/tabs/service-calls/catalogue/CostRates";
import {CDAMWMServiceCallEvalModel, CDAMWMServiceCallModel, CDAMWMWWorksheetQuery} from "crowbar-api";
import {
    useServiceCallItemRowEvalFindByServiceCallEvalId
} from "crowbar-api/hooks/worksheet/evaluation/service-call-item-row/useServiceCallItemRowEvalFindByServiceCallEvalId";
import CatalogueTable from "modules/admin/worksheet/evaluation/tabs/service-calls/catalogue/table/CatalogueTable";
import {
    useServiceCallItemRowsFindByServiceCallId
} from "crowbar-api/hooks/worksheet/service-call-item-rows/useServiceCallItemRowsFindByServiceCallId";
import {
    useSapSalesOrderFindForWorksheetByDocEntry
} from "crowbar-api/hooks/sap/useSapSalesOrderFindForWorksheetByDocEntry";
import ExpandButton from "shared/components/buttons/ExpandButton";
import TripDistanceWarn from "modules/admin/worksheet/evaluation/tabs/service-calls/catalogue/TripDistanceWarn";
import AddItemButton from "modules/admin/worksheet/evaluation/tabs/service-calls/catalogue/AddItemButton";

export type CatalogueProps = {
    worksheet: CDAMWMWWorksheetQuery | null | undefined
    sc: CDAMWMServiceCallModel
    scEval: CDAMWMServiceCallEvalModel
    disabled?: boolean

    onQtyChanged: () => Promise<void>
}

const Catalogue = ({worksheet, sc, scEval, disabled, onQtyChanged}: CatalogueProps) => {
    const tk = useTranslateFunction('Admin.Worksheet')

    const [moreOpen, setMoreOpen] = useState<boolean>(false)

    const rowEvalsQuery = useServiceCallItemRowEvalFindByServiceCallEvalId(scEval.id, {
        keepPreviousData: true
    })
    const rowEvals = rowEvalsQuery.data ?? []

    const rowsQuery = useServiceCallItemRowsFindByServiceCallId(sc?.id, {
        keepPreviousData: true
    })
    const rows = rowsQuery.data ?? []

    const docEntry = worksheet?.salesOrders?.find(() => true)?.docEntry
    const salesOrderQuery = useSapSalesOrderFindForWorksheetByDocEntry(docEntry)
    const salesOrder = salesOrderQuery.data

    const onDataChange = async () => {
        await Promise.all([
            rowEvalsQuery.refetch(),
            rowsQuery.refetch(),
            onQtyChanged()
        ])
    }

    if (rowEvalsQuery.isLoading || rowsQuery.isLoading || salesOrderQuery.isLoading) {
        return (
            <LinearProgress/>
        )
    }

    return (
        <Card variant="outlined">
            <CardContent>
                <Grid container spacing={1}>

                    <Grid item xs={12}>

                        <Grid container spacing={1} justifyContent="space-between" alignItems="center">
                            <Grid item xs="auto">
                                <Typography variant="h6">{tk('Catalogue')}</Typography>
                            </Grid>

                            <Grid item xs="auto">
                                <ExpandButton expanded={moreOpen} onClick={() => setMoreOpen(!moreOpen)}/>
                            </Grid>
                        </Grid>

                    </Grid>

                    <Grid item xs={12}>
                        <Stack direction="row" spacing={1} alignItems="center">

                            <AddItemButton
                                rows={rows}
                                sc={sc}
                                worksheet={worksheet}
                                scEval={scEval}
                                disabled={disabled || !!scEval.deliveryAck}
                                onItemAdded={onDataChange}
                            />

                            <TripDistanceWarn
                                worksheet={worksheet}
                                disabled={disabled}
                            />
                        </Stack>
                    </Grid>

                    {(moreOpen) && (
                        <Grid item xs={12}>
                            <CostRates
                                rowEvals={rowEvals}
                                onDataChange={onDataChange}
                                disabled={disabled || !!scEval.deliveryAck}
                            />
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <CatalogueTable
                            worksheet={worksheet}
                            rows={rows}
                            rowEvals={rowEvals}
                            salesOrder={salesOrder}
                            onDataChange={onDataChange}
                            disabled={disabled || !!scEval.deliveryAck}
                        />
                    </Grid>

                </Grid>
            </CardContent>
        </Card>
    )
}

export default Catalogue;