import {useQuery} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {ServiceCallBlobApi} from "crowbar-api";
import {UseQueryOptionParams} from "crowbar-api/util/UseQueryOptionParams";

export const useServiceCallBlobFindByWorksheetId = (id: string | null | undefined, extraOptions?: UseQueryOptionParams) => {
    return useQuery(['service-call-blob-find-by-worksheet-id', id], async () => {
        if (!id) return []
        const response = await crowbarApiFactory(ServiceCallBlobApi)
            .findByWorksheetId(id)
        return response.data ?? []
    }, {
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        retry: 1,
        cacheTime: Math.pow(10, 8),
        staleTime: Math.pow(10, 8),
        ...extraOptions
    })
}