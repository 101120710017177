import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from "@mui/material";
import {useNewAtomWithRef} from "shared/hook/useNewatomWithRef";
import usePhotoRuleTemplates from "modules/admin/worksheet/photo-rule/photo-rules/PhotoRuleEdit/usePhotoRuleTemplates";
import {CDAMPMPhotoRuleStepModel, CDAMPMPhotoRuleStepTemplateModel} from "crowbar-api";
import AddIcon from '@mui/icons-material/Add';
import FindReplaceIcon from '@mui/icons-material/FindReplace';
import usePhotoRuleTemplateSave
    from "modules/admin/worksheet/photo-rule/photo-rules/PhotoRuleEdit/usePhotoRuleTemplateSave";
import {toastError} from "shared/toast/DefaultToasts";
import {v4 as uuid} from 'uuid';
import useTranslateFunction from "shared/language/useTranslateFunction";

export interface SaveTemplateDialogProps {
    step: CDAMPMPhotoRuleStepModel
    afterTemplateSaved: () => void | Promise<void>
}

const SaveTemplateDialog = ({step, afterTemplateSaved}: SaveTemplateDialogProps) => {

    const [open, setOpen] = useNewAtomWithRef(false)
    const [mode, setMode] = useNewAtomWithRef<'new' | 'replace'>('new')
    const [selectedKey, setSelectedKey] = useNewAtomWithRef<string | undefined>(undefined)

    const tk = useTranslateFunction(`Admin.PhotoRule`)

    const templatesQuery = usePhotoRuleTemplates()
    const templateSave = usePhotoRuleTemplateSave()

    const onOkClick = async () => {
        let template: CDAMPMPhotoRuleStepTemplateModel
        if (mode === "replace") {
            const matchingTemplate = templatesQuery.data?.find(t => t.id === selectedKey)
            if (!matchingTemplate) {
                toastError('Failed to load selected template.')
                return
            }
            template = {
                ...matchingTemplate,
                title: step.title ?? "",
                description: step.description ?? ""
            }
        } else {
            template = {
                id: uuid(),
                title: step.title ?? "",
                description: step.description ?? ""
            }
        }

        await templateSave.mutateAsync(template, {
            onSuccess: async () => {
                await afterTemplateSaved()
                setOpen(false)
            }
        })
    }

    const onModeChange = (event: any, value: 'new' | 'replace') => {
        if (value) {
            setMode(value)
        }
    }

    const openDialog = () => {
        setOpen(true)
        templatesQuery.refetch().then()
    }

    return (
        <>
            <Button variant="outlined" color="primary" onClick={openDialog}>{tk('SaveAsTemplate')}</Button>

            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                fullWidth
            >
                <DialogTitle>{tk('SaveAsTemplate')}</DialogTitle>
                <DialogContent>

                    <ToggleButtonGroup
                        value={mode}
                        exclusive
                        onChange={onModeChange}
                    >
                        <ToggleButton value="new">
                            <AddIcon/> {tk('AddNew')}
                        </ToggleButton>
                        <ToggleButton value="replace">
                            <FindReplaceIcon/> {tk('ReplaceExisting')}
                        </ToggleButton>
                    </ToggleButtonGroup>


                    {templatesQuery.isLoading ? (
                        <CircularProgress/>
                    ) : (
                        mode === "replace"
                            ? (
                                <>
                                    <Typography>{tk('ReplaceDescription')}</Typography>
                                    < FormControl fullWidth sx={{
                                        marginTop: 2
                                    }}>
                                        <InputLabel id="select-label">{tk('Template')}</InputLabel>
                                        <Select
                                            labelId="select-label"
                                            fullWidth
                                            value={selectedKey ?? ""}
                                            label={tk('Template')}
                                            onChange={(e) => setSelectedKey(e.target.value)}
                                        >
                                            {templatesQuery.data?.map(t => (
                                                <MenuItem key={t.id} value={t.id}>{t.title}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </>
                            ) : (
                                <Typography>{tk('NewDescription')}</Typography>
                            )
                    )}
                </DialogContent>
                <DialogActions>
                    <Stack direction="row" justifyContent="space-between" sx={{width: 1}}>
                        <Button
                            disabled={templateSave.isLoading}
                            variant="contained"
                            color="error"
                            onClick={() => setOpen(false)}>
                            {tk('Cancel')}
                        </Button>
                        <Button
                            disabled={mode === "replace" && !selectedKey}
                            variant="outlined"
                            color="primary"
                            onClick={onOkClick}
                        >
                            {tk('Ok')} {templateSave.isLoading && <CircularProgress/>}
                        </Button>
                    </Stack>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default SaveTemplateDialog