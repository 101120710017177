import React, {useState} from "react";

import {CDAMWMWServiceCallItemRowSave} from "crowbar-api";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import {Box, Typography} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {StringUtils} from "shared/utils/StringUtils";
import useAvailableItemRowSum, {
    ServiceCallAvailableQuantity
} from "modules/worksheet/editor/state/helpers/useAvailableItemRowSum";

import BalanceIcon from '@mui/icons-material/Balance';
import useTranslateFunction from "shared/language/useTranslateFunction";

const serviceCallTableRow = (currentItemRow: CDAMWMWServiceCallItemRowSave, available: ServiceCallAvailableQuantity, index: number): JSX.Element => {
    const sc = available.serviceCall

    const summary = StringUtils.notUndefinedNullAndEmpty(sc.vehicleType) ||
    StringUtils.notUndefinedNullAndEmpty(sc.manufacturerSn)
        ? `${sc.vehicleType} ${sc.manufacturerSn ? `(${sc.manufacturerSn})` : ''}`
        : `${sc.serialId}`
    return (
        <TableRow key={sc.id}>
            <TableCell>{summary}</TableCell>
            <TableCell align="right">{available.installed} {available.unitOfQuantity}</TableCell>
        </TableRow>
    )
}

interface WEditorPageCatalogueItemRowDetailsBtnProps {
    itemRow: CDAMWMWServiceCallItemRowSave
    disabled?: boolean
}

const WEditorPageCatalogueItemRowDetailsBtn = ({itemRow, disabled}: WEditorPageCatalogueItemRowDetailsBtnProps) => {

    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    const irSum = useAvailableItemRowSum(itemRow)

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(
        null
    );

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
        <Box>
            <Button aria-describedby={id} variant="outlined" disabled={disabled} onClick={handleClick} size="small">
                <BalanceIcon/>&nbsp;{tk('Quantities')}
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
            >
                <Box>
                    <TableContainer component={Paper}>
                        <Table
                            size="small"
                            sx={{
                                // minWidth: 650
                            }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{tk('ServiceCall')}</TableCell>
                                    <TableCell align="right">{tk('Installed')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {irSum.serviceCallAvailableQuantities
                                    .map((sc, i) => serviceCallTableRow(itemRow, sc, i))
                                }
                            </TableBody>
                        </Table>

                        {}
                        <Typography>
                            <b>{tk('TotalInstalled')}:</b> {irSum.installedQuantityAsLocaleStr}
                        </Typography>
                        <Typography>
                            <b>{tk('Available')}:</b> {irSum.availableAsLocaleStr}
                        </Typography>

                    </TableContainer>
                </Box>
            </Popover>
        </Box>
    )
}

export default WEditorPageCatalogueItemRowDetailsBtn