import {useMutation} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {CDAMDMLicensePlateModel, LicensePlateApi} from "crowbar-api";

export const saveLicensePlate = async (licensePlate: CDAMDMLicensePlateModel) => {
    await crowbarApiFactory(LicensePlateApi).save(licensePlate);
}

export const useSaveLicensePlate = () => {
    return useMutation(async (licensePlate: CDAMDMLicensePlateModel) => {
        await saveLicensePlate(licensePlate)
    })
}