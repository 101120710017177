import React from 'react';
import {CDAMWMWModSapEquipmentSave, CDAMWMWServiceCallSave} from "crowbar-api";
import {Stack, SxProps, Theme, Typography} from "@mui/material";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {StringUtils} from "shared/utils/StringUtils";
import VehicleTypeDisplay from "modules/worksheet/editor/shared/component/vehicle-type-display/VehicleTypeDisplay";
import ServiceCallSelectedSourceMap from "crowbar-api/enum-map/ServiceCallSelectedSourceMap";

export interface WEditorEquipmentCardTextProps {
    serviceCall: CDAMWMWServiceCallSave
    foundMod: CDAMWMWModSapEquipmentSave | undefined
}

const textSx: SxProps<Theme> = (theme) => {
    return {
        wordWrap: "break-word"
    }
}

const WEditorEquipmentTextWithMod = ({serviceCall, foundMod}: WEditorEquipmentCardTextProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    const hasInternalSn = StringUtils.notUndefinedNullAndEmpty(serviceCall.internalSn)
    const hasYearOfManufacture = !!serviceCall.yearOfManufacture

    const isEmptySource = serviceCall.selectedSource === ServiceCallSelectedSourceMap.empty

    const superScript = !foundMod ? <></> : (
        <sup style={{color: "red"}} title={tk('ModifiedEquipment')}>M</sup>
    )

    return (
        <Stack direction="row" spacing={0.25}>
            <Stack direction="column" justifyContent="start">
                {!isEmptySource ? (
                    <>
                        <VehicleTypeDisplay
                            sx={textSx}
                            manufacturer={serviceCall.manufacturer}
                            vehicleType={serviceCall.vehicleType}
                            modManufacturer={foundMod?.manufacturer}
                            modVehicleType={foundMod?.vehicleType}
                        />
                        <Typography
                            sx={textSx}
                        >
                            {!foundMod ? serviceCall.manufacturerSn : foundMod.manufacturerSn}
                        </Typography>
                        {hasInternalSn && (
                            <Typography
                                sx={textSx}
                            >
                                {!foundMod ? serviceCall.internalSn : foundMod.internalSn}
                            </Typography>
                        )}
                        {hasYearOfManufacture && (
                            <Typography
                                sx={textSx}
                            >
                                {serviceCall.yearOfManufacture}
                            </Typography>
                        )}
                    </>
                ) : (
                    <Typography>
                        {serviceCall.emptySubject}
                    </Typography>
                )}
            </Stack>
            {superScript}
        </Stack>
    )
}

export default WEditorEquipmentTextWithMod;