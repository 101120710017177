import React from 'react';
import {CDAMWMWModSapEquipmentSave, CDAMWMWServiceCallSave} from "crowbar-api";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {useWorksheetEditorHeaderInfoAtom} from "modules/worksheet/editor/state/WorksheetEditorHeaderAtoms";
import {useWorksheetSaveState} from "modules/worksheet/editor/state/WorksheetSaveAtoms";
import {toastError} from "shared/toast/DefaultToasts";
import {Box, Button, Grid, Stack} from "@mui/material";
import ConfirmDialogButton from "shared/components/buttons/ConfirmDialogButton";
import DeleteIcon from "@mui/icons-material/Delete";
import {useNewAtomWithRef} from "shared/hook/useNewatomWithRef";
import WEditorEquipmentEditFormDialog from "modules/worksheet/editor/ui/equipments/WEditorEquipmentEditFormDialog";
import useAddNewModSapEquipment from "modules/worksheet/editor/ui/equipments/useAddNewModSapEquipment";
import {useModSapEquipments} from "modules/worksheet/editor/state/atoms/useModSapEquipments";
import WEditorEquipmentTextLines from "modules/worksheet/editor/ui/equipments/WEditorEquipmentTextLines";
import EquipmentThumbnailForServiceCall
    from "modules/worksheet/editor/shared/component/equipment-thumbnail/EquipmentThumbnailForServiceCall";
import ServiceCallSelectedSourceMap from "crowbar-api/enum-map/ServiceCallSelectedSourceMap";
import WEditorEquipmentEditSubjectDialog
    from "modules/worksheet/editor/ui/equipments/WEditorEquipmentEditSubjectDialog";

export interface WEditorEquipmentCardImageProps {
    serviceCall: CDAMWMWServiceCallSave
    disabled?: boolean
}


const WEditorEquipmentCardImage = ({serviceCall, disabled}: WEditorEquipmentCardImageProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    const [modDialogOpen, setModDialogOpen] = useNewAtomWithRef<boolean>(false)
    const [modDialogKey, setModDialogKey] = useNewAtomWithRef<number>(0)
    const [subjectDialogOpen, setSubjectDialogOpen] = useNewAtomWithRef<boolean>(false)
    const [subjectDialogKey, setSubjectDialogKey] = useNewAtomWithRef<number>(0)

    const [info, setInfo] = useWorksheetEditorHeaderInfoAtom()
    const [, setWorksheetSave] = useWorksheetSaveState()

    const [currentMod, setCurrentMod] = useNewAtomWithRef<CDAMWMWModSapEquipmentSave | undefined>(undefined)

    //const modExists = useModSapEquipmentExists(serviceCall.modSapEquipmentId)
    const addNewModSapEquipment = useAddNewModSapEquipment()
    // const [modSapEquipmentById, setModSapEquipment] = useModSapEquipment(serviceCall.modSapEquipmentId)
    const [modEquipments] = useModSapEquipments()

    const selected = info.selectedServiceCallId === serviceCall.id

    const findMod = () => {
        return (modEquipments ?? []).find(
            m => m.id === serviceCall.modSapEquipmentId
        )
    }

    const onServiceCallSelect = () => {
        if (!serviceCall?.id) {
            toastError('Invalid service id.')
            return
        }
        setInfo((prev) => {
            return {
                ...prev,
                selectedServiceCallId: serviceCall.id ?? undefined
            }
        })
    }

    const onServiceCallDelete = () => {
        if (!serviceCall?.id) {
            toastError('Invalid service call id.')
            return
        }
        setWorksheetSave(prev => {
            return {
                ...prev,
                serviceCalls: prev?.serviceCalls?.filter(sc => sc.id !== serviceCall.id)
            }
        })
    }

    const onEditClick = () => {
        setModDialogKey(x => x + 1)
        setSubjectDialogKey(x => x + 1)

        // only the subject needs to be edited, no mod needed
        if (serviceCall?.selectedSource === ServiceCallSelectedSourceMap.empty) {
            setSubjectDialogOpen(true)
            return
        }

        const foundMod = findMod()
        if (!foundMod) {
            const addedMod = addNewModSapEquipment(serviceCall)
            setCurrentMod(addedMod)
            setModDialogOpen(true)
            return
        } else {
            setCurrentMod(foundMod)
            setModDialogOpen(true)
        }
    }

    const onModEditDialogClose = () => {
        setModDialogOpen(false)
    }

    const onSubjectDialogClose = () => {
        setSubjectDialogOpen(false)
    }

    return (
        <Grid container spacing={0.25}>
            <Grid item xs={12}>
                <Stack direction="row" spacing={1}>
                    <EquipmentThumbnailForServiceCall size={150} serviceCall={serviceCall}/>

                    <Stack direction="column" alignContent="space-between">

                        <WEditorEquipmentTextLines serviceCall={serviceCall}/>

                        <Box>
                            {!selected && (
                                <Button variant="text" onClick={onServiceCallSelect}>
                                    {tk('Select')}
                                </Button>
                            )}
                            {/*!selected ? (
                                <IconButton onClick={onServiceCallSelect}>
                                    <CheckBoxOutlineBlankIcon/>
                                </IconButton>
                            ) : (
                                <IconButton>
                                    <CheckBoxIcon/>
                                </IconButton>
                            )*/}

                        </Box>

                    </Stack>


                </Stack>
            </Grid>

            <Grid item xs={12}>
                <Stack direction="row" spacing={1}>
                    <ConfirmDialogButton buttonText={'Delete'}
                                         fullWidth
                                         onConfirm={onServiceCallDelete}
                                         buttonRender={(props, onButtonClick) => (
                                             <Button
                                                 variant="text"
                                                 onClick={onButtonClick}
                                                 color="error"
                                                 disabled={disabled}
                                             >
                                                 <DeleteIcon/>&nbsp;{tk('Delete')}
                                             </Button>
                                         )}
                    />

                    <Button variant="text" onClick={onEditClick} disabled={disabled}>
                        {tk('Edit')}
                    </Button>

                    <WEditorEquipmentEditFormDialog
                        key={"mod" + modDialogKey}
                        open={modDialogOpen}
                        serviceCallId={serviceCall.id}
                        onDialogClose={onModEditDialogClose}
                        modId={currentMod?.id}
                    />

                    <WEditorEquipmentEditSubjectDialog
                        key={"subject" + subjectDialogKey}
                        open={subjectDialogOpen}
                        serviceCall={serviceCall}
                        onDialogClose={onSubjectDialogClose}
                    />

                </Stack>
            </Grid>
        </Grid>
    )
}

export default WEditorEquipmentCardImage;