import React from 'react';
import {Box, Card, CardContent, Grid, LinearProgress, Typography} from "@mui/material";
import ServiceCallTable
    from "modules/admin/worksheet/evaluation/tabs/service-calls/service-call-table/ServiceCallTable";
import {useServiceCallQueries} from "modules/admin/worksheet/evaluation/tabs/service-calls/useServiceCallQueries";
import EquipmentSelect from "modules/admin/worksheet/evaluation/tabs/service-calls/equipment-select/EquipmentSelect";
import ServiceCallSelectedSourceMap from "crowbar-api/enum-map/ServiceCallSelectedSourceMap";
import SapCallTypeSelect from "modules/admin/worksheet/evaluation/tabs/service-calls/inputs/SapCallTypeSelect";
import useTranslateFunction from "shared/language/useTranslateFunction";
import TechnicianSelect from "modules/admin/worksheet/evaluation/tabs/service-calls/inputs/TechnicianSelect";
import {CDAMWMWorksheetEvalModel, CDAMWMWWorksheetQuery} from "crowbar-api";
import CurrentOperatingHours from "modules/admin/worksheet/evaluation/tabs/service-calls/inputs/CurrentOperatingHours";
import Catalogue from "modules/admin/worksheet/evaluation/tabs/service-calls/catalogue/Catalogue";
import SapDocuments from "modules/admin/worksheet/evaluation/tabs/service-calls/sap-documents/SapDocuments";
import AddressSelects from "modules/admin/worksheet/evaluation/tabs/service-calls/address/AddressSelects";
import Descriptions from "modules/admin/worksheet/evaluation/tabs/service-calls/descriptions/Descriptions";
import SlpCodeSelect from "modules/admin/worksheet/evaluation/tabs/service-calls/inputs/SlpCodeSelect";
import OwnerSelect from "modules/admin/worksheet/evaluation/tabs/service-calls/inputs/OwnerSelect";
import EmptySubjectChange from "modules/admin/worksheet/evaluation/tabs/service-calls/inputs/EmptySubjectChange";

export type ServiceCallsProps = {
    wEval: CDAMWMWorksheetEvalModel | null | undefined
    worksheet: CDAMWMWWorksheetQuery | null | undefined
    disabled?: boolean
}
const ServiceCalls = ({wEval, worksheet, disabled}: ServiceCallsProps) => {
    const tk = useTranslateFunction("Admin.Worksheet")

    const {
        selectedServiceCallEvalId,
        setSelectedServiceCallEvalId,
        selectedServiceCall,
        scEvals,
        serviceCalls,
        imageBlobs,
        tripItemRows,
        isLoading,
        isFetching,
        refetchAll,
        refetchServiceCallQueries
    } = useServiceCallQueries(wEval?.id, wEval?.worksheetId)

    const mustSetEquipment = selectedServiceCall !== undefined && selectedServiceCall?.selectedSource !== ServiceCallSelectedSourceMap.empty

    const selectedEval = scEvals.find(e => e.id === selectedServiceCallEvalId)

    const onDataChangeFull = async () => {
        await refetchAll()
    }

    const onDataChangeBase = async () => {
        await refetchServiceCallQueries()
    }

    const onRowSelectionChange = (newSelectedEvalId: string | undefined) => {
        setSelectedServiceCallEvalId(newSelectedEvalId)
    }

    return (
        <Grid container spacing={1} sx={{
            pb: 3
        }}>
            <Grid item xs={12}>
                <Box sx={{height: "4px"}}>
                    {(isLoading || isFetching) && (
                        <LinearProgress/>
                    )}
                </Box>
            </Grid>
            <Grid item xs={12}>
                <ServiceCallTable
                    selectedEvalId={selectedServiceCallEvalId}
                    onSelectedEvalChange={onRowSelectionChange}

                    worksheet={worksheet}
                    scEvals={scEvals}
                    serviceCalls={serviceCalls}
                    imageBlobs={imageBlobs}
                    tripItemRows={tripItemRows}

                />
            </Grid>
            {mustSetEquipment && (
                <>
                    <Grid item xs={12}>
                        <EquipmentSelect
                            key={selectedServiceCall?.id}
                            selectedServiceCall={selectedServiceCall}
                            scEvalId={selectedServiceCallEvalId}
                            selectedEval={selectedEval}
                            worksheet={worksheet}
                            wEval={wEval}
                            onMasterDataChange={onDataChangeFull}
                            disabled={disabled}
                        />
                    </Grid>
                </>
            )}

            {selectedServiceCall?.selectedSource === ServiceCallSelectedSourceMap.empty && (
                <>
                    <Grid item xs={12}>
                        <EmptySubjectChange
                            selectedServiceCall={selectedServiceCall}
                            scEvalId={selectedServiceCallEvalId}
                            selectedEval={selectedEval}
                            worksheet={worksheet}
                            wEval={wEval}
                            onMasterDataChange={onDataChangeFull}
                            disabled={disabled}
                        />
                    </Grid>
                </>
            )}

            {(selectedServiceCall && selectedServiceCallEvalId && selectedEval) && (
                <>
                    <Grid item xs={12}>
                        <Catalogue
                            worksheet={worksheet}
                            sc={selectedServiceCall}
                            scEval={selectedEval}
                            disabled={disabled}
                            onQtyChanged={refetchServiceCallQueries}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Descriptions
                            sc={selectedServiceCall}
                            evalId={selectedServiceCallEvalId}
                            disabled={disabled}
                        />
                    </Grid>

                    <Grid item xs={12}>

                        <Card variant="outlined">
                            <CardContent>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">{tk('SapServiceCall')}</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <SapCallTypeSelect
                                            sc={selectedServiceCall}
                                            scEval={selectedEval}
                                            disabled={disabled}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TechnicianSelect
                                            sc={selectedServiceCall}
                                            scEval={selectedEval}
                                            disabled={disabled}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <CurrentOperatingHours
                                            sc={selectedServiceCall}
                                            scEval={selectedEval}
                                            disabled={disabled}
                                            onMasterDataChange={onDataChangeBase}
                                        />
                                    </Grid>
                                    <Grid item xs={2.5}>
                                        <SlpCodeSelect
                                            sc={selectedServiceCall}
                                            scEval={selectedEval}
                                            disabled={disabled}
                                        />
                                    </Grid>
                                    <Grid item xs={2.5}>
                                        <OwnerSelect
                                            sc={selectedServiceCall}
                                            scEval={selectedEval}
                                            disabled={disabled}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <AddressSelects
                                            worksheet={worksheet}
                                            sc={selectedServiceCall}
                                            scEval={selectedEval}
                                            disabled={disabled}
                                            onMasterDataChange={onDataChangeBase}
                                        />
                                    </Grid>

                                </Grid>
                            </CardContent>
                        </Card>

                    </Grid>

                    <Grid item xs={12}>
                        <SapDocuments
                            wEval={wEval}
                            worksheet={worksheet}
                            sc={selectedServiceCall}
                            scEval={selectedEval}
                            disabled={disabled}
                            onMasterDataChange={onDataChangeBase}
                        />
                    </Grid>
                </>
            )}


        </Grid>
    )
}

export default ServiceCalls;