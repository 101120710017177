import React, {useEffect, useState} from 'react';
import {
    CDAMWMServiceCallEvalModel,
    CDAMWMServiceCallModel,
    CDAMWMWorksheetEvalModel,
    CDAMWMWWorksheetQuery,
    CDSMDeliveryOrDraftExistsResult
} from "crowbar-api";
import {Card, CardContent, CircularProgress, Grid, Stack, Typography} from "@mui/material";
import useTranslateFunction from "shared/language/useTranslateFunction";
import LinkToSalesOrderCheckbox
    from "modules/admin/worksheet/evaluation/tabs/service-calls/sap-documents/LinkToSalesOrderCheckbox";
import CreateDeliveryButton
    from "modules/admin/worksheet/evaluation/tabs/service-calls/sap-documents/CreateDeliveryButton";
import DeliveryDocNumInput
    from "modules/admin/worksheet/evaluation/tabs/service-calls/sap-documents/DeliveryDocNumInput";
import NumberOnlyTextField from "shared/components/inputs/NumberOnlyTextField";
import SapDeliveryAck from "modules/admin/worksheet/evaluation/tabs/service-calls/sap-documents/SapDeliveryAck";
import UpdateManuallyCheckbox
    from "modules/admin/worksheet/evaluation/tabs/service-calls/sap-documents/UpdateManuallyCheckbox";

export type SapDeliveryProps = {
    wEval: CDAMWMWorksheetEvalModel | null | undefined
    worksheet: CDAMWMWWorksheetQuery | null | undefined
    sc: CDAMWMServiceCallModel
    scEval: CDAMWMServiceCallEvalModel
    disabled?: boolean
    onMasterDataChange: () => Promise<void>

    deliveryDraftDocKeys: CDSMDeliveryOrDraftExistsResult | null | undefined
    deliveryDocKeys: CDSMDeliveryOrDraftExistsResult | null | undefined

    draftsQueryIsLoading: boolean
    deliveryQueryIsLoading: boolean
}

const SapDelivery = ({
                         wEval,
                         worksheet,
                         sc,
                         scEval,
                         disabled,
                         onMasterDataChange,
                         deliveryDraftDocKeys,
                         deliveryDocKeys,
                         draftsQueryIsLoading,
                         deliveryQueryIsLoading
                     }: SapDeliveryProps) => {
    const tk = useTranslateFunction("Admin.Worksheet")

    const draftDocNum = deliveryDraftDocKeys?.draftDocNum

    const deliveryDocNum = scEval?.manuallySetDeliveryDocEntry
        ? deliveryDocKeys?.deliveryDocNum
        : deliveryDraftDocKeys?.deliveryDocNum ?? deliveryDocKeys?.deliveryDocNum

    const [docNum, setDocNum] = useState<number>(deliveryDocNum ?? 0)

    useEffect(() => {
        if ((!scEval?.manuallySetDeliveryDocEntry || docNum === 0) /*&& deliveryDocNum*/ && deliveryDocNum !== docNum) {
            setDocNum(deliveryDocNum ?? 0)
        }
    }, [deliveryDocNum, docNum, scEval?.manuallySetDeliveryDocEntry])

    return (
        <Card variant="outlined">
            <CardContent>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="h6">{tk('Delivery')}</Typography>
                    </Grid>

                    <Grid item xs={12}>

                        <Grid container direction="row" spacing={1}>
                            <Grid item xs={4}>
                                <CreateDeliveryButton
                                    wEval={wEval}
                                    label={tk('DeliveryAsDraft')}
                                    disabled={disabled}
                                    asDraft={true}
                                    worksheet={worksheet}
                                    sc={sc}
                                    scEval={scEval}
                                    onDataChange={onMasterDataChange}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <NumberOnlyTextField
                                    value={draftDocNum ?? 0}
                                    size="small"
                                    fullWidth={true}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={true}>
                                {draftsQueryIsLoading && (
                                    <Stack direction="row" spacing={0.5} alignItems="center" justifyContent="center">
                                        <CircularProgress/>
                                        <Typography variant="caption" fontSize="small">
                                            {tk('FindingDraft')}
                                        </Typography>
                                    </Stack>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>

                        <Grid container direction="row" spacing={1}>
                            <Grid item xs={4}>
                                <CreateDeliveryButton
                                    wEval={wEval}
                                    label={tk('CreateNewDelivery')}
                                    disabled={disabled}
                                    asDraft={false}
                                    worksheet={worksheet}
                                    sc={sc}
                                    scEval={scEval}
                                    onDataChange={onMasterDataChange}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <DeliveryDocNumInput
                                    value={docNum}
                                    onChange={(newValue) => setDocNum(newValue)}
                                    worksheet={worksheet}
                                    sc={sc}
                                    scEval={scEval}
                                    onMasterDataChange={onMasterDataChange}
                                    disabled={disabled}
                                />
                            </Grid>
                            <Grid item xs="auto">
                                <SapDeliveryAck
                                    currentDocNum={docNum}
                                    worksheet={worksheet}
                                    sc={sc}
                                    scEval={scEval}
                                    onMasterDataChange={onMasterDataChange}
                                />
                            </Grid>
                            <Grid item xs={true}>
                                {deliveryQueryIsLoading && (
                                    <Stack direction="row" spacing={0.5} alignItems="center" justifyContent="center">
                                        <CircularProgress/>
                                        <Typography variant="caption" fontSize="small">
                                            {tk('FindingDelivery')}
                                        </Typography>
                                    </Stack>
                                )}
                            </Grid>
                        </Grid>

                    </Grid>

                    <Grid item xs={12}>
                        <LinkToSalesOrderCheckbox
                            worksheet={worksheet}
                            sc={sc}
                            scEval={scEval}
                            disabled={disabled}
                            onMasterDataChange={onMasterDataChange}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <UpdateManuallyCheckbox
                            worksheet={worksheet}
                            sc={sc}
                            scEval={scEval}
                            disabled={disabled}
                            onMasterDataChange={onMasterDataChange}
                        />
                    </Grid>

                </Grid>
            </CardContent>
        </Card>
    )
}

export default SapDelivery;