import {useQuery} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {SapPsgVehicleTypeCategoryApi} from "crowbar-api";
import AxiosCancelTokenHelper from "crowbar-api/AxiosCancelTokenHelper";

const usePsgVehicleTypes = () => {
    return useQuery(["sap-psg-vehicle-types-api"], async (context) => {
        const response = await crowbarApiFactory(SapPsgVehicleTypeCategoryApi, undefined, true)
            .findAll(AxiosCancelTokenHelper.cancelTokenFrom(context.signal))
        if (response.status !== 200) {
            throw new Error('Response type is not ok.')
        }
        return response.data ?? []
    }, {
        cacheTime: Math.pow(10, 7),
        staleTime: Math.pow(10, 7),
        retry: 3,
        refetchOnMount: false,
        refetchOnWindowFocus: false
    })
}

export default usePsgVehicleTypes