import {Grid, IconButton, MenuItem, Select, SelectChangeEvent, Stack} from "@mui/material";
import {
    SapPartnerAddressSelectValue
} from "modules/worksheet/editor/ui/basic/address-select/useSapPartnerAddressSelectValuesAtom";
import {useWorksheetSaveState} from "modules/worksheet/editor/state/WorksheetSaveAtoms";
import SapPartnerAddressUtils from "crowbar-api/util/SapPartnerAddressUtils";
import ModSapTypeMap from "crowbar-api/enum-map/ModSapTypeMap";
import {useEffect} from "react";
import WorksheetSelectedDataSourceMap from "crowbar-api/enum-map/WorksheetSelectedDataSourceMap";
import useTranslateFunction from "shared/language/useTranslateFunction";
import ShareIcon from '@mui/icons-material/Share';
import {MapUtils} from "shared/utils/MapUtils";
import LocationOpenMapsIconButton from "shared/components/buttons/LocationOpenMapsIconButton";
import CrowbarConstants from "crowbar-api/CrowbarConstants";

interface WEditorPageBasicAddressSelectProps {
    //  addresses: CDSMSapPartnerAddress[]
    selectValues: SapPartnerAddressSelectValue[]
    setSelectedByKey: (key: string) => void
    selectedKey: string | undefined

    disabled?: boolean
}

const formatMenuItemText = (v: SapPartnerAddressSelectValue, tk: (key: string) => string, hideType?: boolean): string => {

    let typeStr = ""
    let locationName = ""

    if ((v.source === "address" || v.source === "own-premise") && v.address) {
        typeStr = "(SAP)"
        locationName = `${SapPartnerAddressUtils.addressFormat(v.address)}`
    } else if (v.source === "mod" && v.mod) {
        typeStr = `(${v.mod.type === ModSapTypeMap.create ? tk('New') : tk('Edited')})`
        locationName = `${SapPartnerAddressUtils.addressFormat(v.mod)}`
    } else if (v.source === "sales-order" && v.address) {
        typeStr = `(${tk('SalesOrder')})`
        locationName = `${SapPartnerAddressUtils.addressFormat(v.address)}`
    } else {
        locationName = "N/A"
    }

    return !hideType && typeStr ? `${typeStr} ${locationName}` : locationName
}

const WEditorPageBasicAddressSelect = ({
                                           selectValues,
                                           setSelectedByKey,
                                           selectedKey,
                                           disabled
                                       }: WEditorPageBasicAddressSelectProps) => {

    const [worksheetSave] = useWorksheetSaveState()

    const selectValue = selectValues.find(v => v.key === selectedKey) !== undefined ? (selectedKey ?? "") : ""

    const openMaps = () => {
        if (!selectValue) return
        const found = selectValues.find(v => v.key === selectValue)
        if (!found) return;
        MapUtils.openUrlForQuery(formatMenuItemText(found, tk, true))
    }

    const shareClick = () => {
        if (!navigator.share) return
        if (!selectValue) return
        const found = selectValues.find(v => v.key === selectValue)
        if (!found) return
        navigator.share({
            text: formatMenuItemText(found, tk, true)
        }).then()
    }

    // automatically select value if needed
    useEffect(() => {
        //const sv = selectValues.find(v => v.selected)
        if (selectedKey) return
        // if nothing is selected, check if we can select based on the worksheet fields
        if (worksheetSave?.partnerAddressSource === WorksheetSelectedDataSourceMap.modSap &&
            worksheetSave?.modSapPartnerAddressId &&
            selectValues.find(v => v.key === worksheetSave?.modSapPartnerAddressId) !== undefined
        ) {
            setSelectedByKey(worksheetSave.modSapPartnerAddressId)
        } else if (
            (worksheetSave?.partnerAddressSource === WorksheetSelectedDataSourceMap.sapMasterData ||
                worksheetSave?.partnerAddressSource === WorksheetSelectedDataSourceMap.sapSalesOrder)
        ) {
            const matched = selectValues.find(v =>
                v.address?.addressName === worksheetSave?.address &&
                v.address?.addressType === worksheetSave?.addressType &&
                v.cardCode === worksheetSave?.cardCode
            )
            if (matched) {
                setSelectedByKey(matched.key)
            }
        } else if (worksheetSave?.partnerAddressSource === WorksheetSelectedDataSourceMap.ownPremise) {
            const matched = selectValues.find(v =>
                v.address?.addressName === worksheetSave?.address &&
                v.address?.addressType === worksheetSave?.addressType &&
                v.cardCode === CrowbarConstants.PhoenixForkliftCardCode
            )
            if (matched) {
                setSelectedByKey(matched.key)
            }
        }
    }, [selectValues, selectedKey, setSelectedByKey, worksheetSave?.address, worksheetSave?.addressType, worksheetSave?.cardCode, worksheetSave?.modSapPartnerAddressId, worksheetSave?.partnerAddressSource])

    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    const handleChange = (event: SelectChangeEvent) => {
        setSelectedByKey(event.target.value)
    }

    return (
        <Stack direction="column" spacing={0.5}>
            <Grid container spacing={0.5} alignItems="center">
                <Grid item xs={true}>
                    <Select
                        fullWidth
                        //  label={tk('Address')}
                        disabled={disabled}
                        onChange={handleChange}
                        value={selectValue}
                        error={!selectedKey}
                        inputProps={{
                            sx: {
                                borderWidth: "16px"
                            }
                        }}
                    >
                        {selectValues.map(v => (
                            <MenuItem key={v.key} value={v.key}>
                                {formatMenuItemText(v, tk)}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item xs="auto">
                    <LocationOpenMapsIconButton
                        disableMapOpenOnClick={true}
                        onOpenMapsClick={openMaps}
                    />
                </Grid>
                <Grid item xs="auto">
                    <IconButton onClick={shareClick}>
                        <ShareIcon/>
                    </IconButton>
                </Grid>
            </Grid>
        </Stack>
    )
}

export default WEditorPageBasicAddressSelect