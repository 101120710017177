import React, {useEffect} from 'react';
import {Route, Routes} from 'react-router-dom';
import {CircularProgress, LinearProgress} from "@mui/material";
import useWorksheetEditorRoutes from "modules/worksheet/editor/state/useWorksheetEditorRoutes";
import {useDeleteOldWorksheetRecords} from "modules/worksheet/service/useDeleteOldWorksheetRecords";

export interface WorksheetEditorRoutesProps {

}

const WorksheetEditorRoutes = ({}: WorksheetEditorRoutesProps) => {

    const pageRoutes = useWorksheetEditorRoutes()

    const {deleteOldWorksheetRecords, isLoading} = useDeleteOldWorksheetRecords()

    useEffect(() => {
        deleteOldWorksheetRecords(1).then()
    }, [deleteOldWorksheetRecords])

    if (isLoading) {
        return (
            <LinearProgress/>
        )
    }

    return (
        <Routes>
            {pageRoutes.map((route, i) => (
                <Route key={i} path={route.path} index={route.index} element={route.element}/>
            ))}
            <Route path="*" element={<CircularProgress/>}/>
        </Routes>
    );
};

export default WorksheetEditorRoutes;