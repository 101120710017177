import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    FormControlLabel,
    FormGroup,
    Grid,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {CDAMNMCustomerNotificationConfigurationModel, CDAMNMCustomerNotificationConfigurationSearch} from "crowbar-api";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {useState} from "react";
import {toastError} from "shared/toast/DefaultToasts";
import ConfirmDialogButton from "shared/components/buttons/ConfirmDialogButton";
import {
    useCustomerNotificationConfigurationSave
} from "crowbar-api/hooks/notification/useCustomerNotificationConfigurationSave";
import {v4 as uuid} from 'uuid'
import EmailAddressesInput from "shared/components/inputs/EmailAddressesInput";

export type CustomerNotificationConfigurationDialogProps = DialogProps & {
    mode: 'new' | 'edit'
    editConfiguration?: CDAMNMCustomerNotificationConfigurationSearch

    onDialogClose: () => void
    onConfigurationChange: () => void
}

export const CustomerNotificationConfigurationDialog = ({
                                                            mode,
                                                            editConfiguration,
                                                            onDialogClose,
                                                            onConfigurationChange,
                                                            ...props
                                                        }: CustomerNotificationConfigurationDialogProps) => {
    const tk = useTranslateFunction(`Admin.Worksheet`)

    const [emailAddresses, setEmailAddresses] = useState<string[]>(
        (editConfiguration?.emailAddresses ?? "").split(",").map(s => s.trim()).filter(s => !!s)
    )
    const [cardCode, setCardCode] = useState<string | undefined>(editConfiguration?.cardCode)
    const [emailNotificationsEnabled, setEmailNotificationsEnabled] = useState<boolean>(editConfiguration?.emailNotificationsEnabled ?? false)
    const [notifyOnOwnPremiseWorksheets, setNotifyOnOwnPremiseWorksheets] = useState<boolean>(editConfiguration?.notifyOnOwnPremiseWorksheets ?? false)

    const configurationSave = useCustomerNotificationConfigurationSave()

    const asModel = (): CDAMNMCustomerNotificationConfigurationModel => {
        return {
            id: mode === "new" ? uuid() : editConfiguration?.id,
            cardCode: cardCode,
            emailAddresses: emailAddresses.join(","),
            emailNotificationsEnabled: emailNotificationsEnabled,
            notifyOnOwnPremiseWorksheets: notifyOnOwnPremiseWorksheets,
            isDeleted: editConfiguration?.isDeleted ?? false
        }
    }

    const onDialogSubmit = async () => {
        if (!cardCode) {
            toastError('Missing CardCode')
            return
        }
        if (emailAddresses.length < 1) {
            toastError('Missing e-mail address.')
            return
        }

        await configurationSave.mutateAsync({
            ...asModel()
        })


        // callback
        onConfigurationChange?.()
    }

    const onDelete = async () => {
        await configurationSave.mutateAsync({
            ...asModel(),
            isDeleted: true
        })
        // callback
        onConfigurationChange?.()
    }

    return (
        <Dialog
            fullWidth={true}
            {...props}
        >
            <DialogContent>
                <Grid container spacing={2}>

                    <Grid item xs={12}>
                        <TextField
                            label={tk('Customer')}
                            value={cardCode ?? ""}
                            onChange={(e) => setCardCode(e.target.value)}
                            disabled={mode !== "new"}
                            InputLabelProps={{shrink: true}}
                            placeholder="V100165..."
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <EmailAddressesInput
                            defaultValue={[]}
                            emailAddresses={emailAddresses}
                            onChange={(newValues) => setEmailAddresses(newValues)}
                            onDelete={(option) => setEmailAddresses(emailAddresses.filter(a => a !== option))}
                        />

                        <Typography variant="caption" fontSize="small">{tk('PressEnterToAdd')}</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={emailNotificationsEnabled}
                                              onChange={e => setEmailNotificationsEnabled(e.target.checked)}/>
                                }
                                label={tk('EmailNotificationsEnabledEx')}
                            />
                        </FormGroup>
                    </Grid>

                    <Grid item xs={12}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={notifyOnOwnPremiseWorksheets}
                                              onChange={e => setNotifyOnOwnPremiseWorksheets(e.target.checked)}/>
                                }
                                label={tk('NotifyOnOwnPremiseWorksheetsEx')}
                            />
                        </FormGroup>
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions>
                <Stack direction="row" spacing={1} width={1} justifyContent="space-between">
                    <Stack direction="row" spacing={1}>
                        <Button color="error" onClick={onDialogClose}>{tk('Cancel')}</Button>

                        {mode === "edit" && (
                            <ConfirmDialogButton
                                color="error"
                                onConfirm={onDelete}
                                buttonText={tk('Delete')}
                            />
                        )}
                    </Stack>

                    <Button
                        color="primary"
                        variant="contained"
                        onClick={onDialogSubmit}
                        disabled={emailAddresses.length < 1 || !cardCode}
                    >
                        {tk('Save')}
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    )
}