import React from 'react';
import {
    CDAMPMPhotoRuleAndManufacturersQuery,
    CDAMPMPhotoRuleStepModel,
    CDAMWMESapEquipmentServiceCallBlobQuery
} from "crowbar-api";
import {Box, Card, CardActions, CardContent, CardMedia, Grid, IconButton, Stack, Typography} from "@mui/material";
import {useAzureBlobStorageUrlFactory} from "shared/hook/useAzureBlobStorageUrlFactory";
import ProgressiveImage from "shared/components/progressive-image/ProgressiveImage";
import useTranslateFunction from "shared/language/useTranslateFunction";
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmDialogButton from "shared/components/buttons/ConfirmDialogButton";
import LoopIcon from '@mui/icons-material/Loop';
import DateDisplay from "shared/components/date-display/DateDisplay";
import {useUserDetailsById} from "crowbar-api/hooks/user/useUserDetailsById";
import WEditorPhotoEntryComment from "modules/worksheet/editor/ui/photo/WEditorPhotoEntryComment";
import {useSelectedServiceCall} from "modules/worksheet/editor/shared/data/useSelectedServiceCall";

import FolderSharedIcon from '@mui/icons-material/FolderShared';

export interface WEditorPhotoEntryProps {
    rule?: CDAMPMPhotoRuleAndManufacturersQuery
    step?: CDAMPMPhotoRuleStepModel
    blob: CDAMWMESapEquipmentServiceCallBlobQuery
    onImageClick: (blob?: CDAMWMESapEquipmentServiceCallBlobQuery) => void
    onReplaceClick: () => void
    onDeleteClick: () => void

    hideReplaceButton?: boolean
}

const WEditorPhotoEntry = ({
                               rule,
                               step,
                               blob,
                               onImageClick,
                               onReplaceClick,
                               onDeleteClick,
                               hideReplaceButton
                           }: WEditorPhotoEntryProps) => {

    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    const urlFactory = useAzureBlobStorageUrlFactory()
    const imageUrl = (blob.blobs && blob.blobs.length > 0)
        ? urlFactory.imageFromName("equipment", blob.blobs[0].parentDirectory, blob.blobs[0].fileName)
        : undefined

    const blobObj = blob?.blobs?.find(() => true)
    const originalName = blobObj?.originalName

    const userQuery = useUserDetailsById(blobObj?.createdBy)
    const user = userQuery?.data

    const selectedServiceCall = useSelectedServiceCall()
    const blobFromDifferentServiceCall = selectedServiceCall?.id !== blob.serviceCallId

    return (
        <Card
            variant="outlined"
            sx={{
                // border: "2px solid black",
                ':hover': {
                    boxShadow: "0 0px 15px rgb(0 0 0 / 0.4)",
                }
            }}
        >
            {(imageUrl?.thumbMedium) && (
                <CardMedia>

                    <Box
                        onClick={() => onImageClick(blob ?? undefined)}
                        sx={{
                            cursor: 'pointer',
                            width: "200px",
                            height: "200px",
                        }}
                    >

                        <ProgressiveImage
                            queryKey="equipment-photo'"
                            src={imageUrl.thumbMedium}
                            retry={3}
                        >
                            {(isLoading, imageObjectUrl, hasError) => {

                                if (imageObjectUrl) {
                                    return (
                                        <Box component="img" src={imageObjectUrl} alt={blob.photoRuleId ?? ""}
                                             sx={{
                                                 width: "200px",
                                                 height: "200px",
                                                 objectFit: "cover"
                                             }}
                                        />
                                    )
                                }
                            }}
                        </ProgressiveImage>
                    </Box>
                </CardMedia>
            )}

            <CardContent
                sx={{
                    width: "200px"
                }}
            >

                <Stack direction="column" spacing={0.1} sx={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }}>

                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={0.5}
                        sx={{
                            width: 1,
                            // textOverflow: "ellipsis"
                        }}
                    >
                        {blob.orderNum && (
                            <Typography
                                variant="subtitle1"
                                fontWeight="bold"
                                sx={{
                                    display: "flex",
                                    alignItems: "center"
                                }}
                            >
                                #{blob.orderNum}/{blob.subOrderNum}
                                {blobFromDifferentServiceCall && (
                                    <FolderSharedIcon/>
                                )}
                            </Typography>
                        )}
                        {step?.title ? (
                            <Typography variant="subtitle1" fontWeight="normal">
                                {step.title}
                            </Typography>
                        ) : originalName && (
                            <Typography
                                variant="subtitle1"
                                fontWeight="normal"
                                sx={{
                                    display: "flex",
                                    alignItems: "center"
                                }}
                            >
                                {blobFromDifferentServiceCall && (
                                    <FolderSharedIcon/>
                                )}
                                {originalName}
                            </Typography>
                        )}
                    </Stack>

                    {blobObj?.createdAt && (
                        <Typography variant="caption" fontWeight="normal">
                            <DateDisplay dateStr={blobObj.createdAt}/>
                        </Typography>
                    )}
                    {user?.fullName && (
                        <Typography variant="caption" fontWeight="normal">
                            {user.fullName}
                        </Typography>
                    )}

                    <WEditorPhotoEntryComment blobQuery={blob}/>
                </Stack>

            </CardContent>
            <CardActions>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <ConfirmDialogButton
                            onConfirm={onDeleteClick}
                            buttonRender={(props, onButtonClick) => (
                                <IconButton color="default" onClick={onButtonClick}><DeleteIcon/></IconButton>
                            )}
                        />

                    </Grid>
                    {!hideReplaceButton && blob?.orderNum && blob.subOrderNum && blob.photoRuleStepId && (
                        <Grid item>
                            <IconButton title={tk('Replace')} onClick={onReplaceClick}><LoopIcon/></IconButton>
                        </Grid>
                    )}

                </Grid>
            </CardActions>
        </Card>
    )
}

export default WEditorPhotoEntry;