import React from 'react';
import {Stack, Typography} from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import TypographyMaxNCharEllipsis from "shared/components/typography/TypographyMaxNCharEllipsis";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {useWorksheetCallTypeFindAll} from "crowbar-api/hooks/worksheet/call-type/useWorksheetCallTypeFindAll";
import {StringUtils} from "shared/utils/StringUtils";
import TitleIcon from '@mui/icons-material/Title';

export type ResultEntryServiceCallTextLinesProps = {
    subject?: string | null
    taskDescription?: string | null
    faultDescription?: string | null
    callTypeId?: number | null
}
const ResultEntryServiceCallTextLines = ({
                                             subject,
                                             taskDescription,
                                             faultDescription,
                                             callTypeId
                                         }: ResultEntryServiceCallTextLinesProps) => {
    const callTypesQuery = useWorksheetCallTypeFindAll()
    const callTypes = callTypesQuery.data ?? []
    const callType = callTypes.find(ct => !!callTypeId && ct.id === callTypeId)

    return (
        <Stack direction="column" spacing={0} sx={{mt: 2}}>

            {subject && (
                <Stack direction="row" spacing={0.5} alignItems="center">
                    <TitleIcon color="primary"/>
                    <TypographyMaxNCharEllipsis
                        maxChar={100}
                        variant="body2"
                    >
                        {subject}
                    </TypographyMaxNCharEllipsis>
                </Stack>
            )}

            {(StringUtils.notUndefinedNullAndEmpty(callType?.name) || StringUtils.notUndefinedNullAndEmpty(taskDescription)) && (
                <Stack direction="row" spacing={0.5} alignItems="center">
                    <DescriptionIcon color="primary"/>
                    {StringUtils.notUndefinedNullAndEmpty(callType?.name) && (
                        <Typography variant="body2" fontWeight="bold">{callType?.name ?? ""}</Typography>
                    )}
                    <TypographyMaxNCharEllipsis
                        maxChar={100}
                        variant="body2"
                    >
                        {taskDescription ?? ""}
                    </TypographyMaxNCharEllipsis>
                </Stack>
            )}

            {faultDescription && (
                <Stack direction="row" spacing={0.5} alignItems="center">
                    <WarningAmberIcon color="primary"/>
                    <TypographyMaxNCharEllipsis
                        maxChar={100}
                        variant="body2"
                    >
                        {faultDescription}
                    </TypographyMaxNCharEllipsis>
                </Stack>
            )}
        </Stack>
    );
};

export default ResultEntryServiceCallTextLines;