import React, {useEffect} from 'react';
import {Typography} from "@mui/material";
import {atom, useAtom} from "jotai";

const isDev = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
const isStaging = window?.location?.host?.includes('staging.')

interface EnvTextState {
    text: string
    replacer: string
    currentPos: number
}

const devTextAtom = atom<EnvTextState>({
    text: isStaging ? 'TESTING MODE' : isDev ? 'DEV MODE' : "",
    replacer: '_',
    currentPos: 0
})

const replaceAt = (text: string, index: number, replacement: string) => {
    return text.substring(0, index) + replacement + text.substring(index + replacement.length);
}

const generateDevText = (dt: EnvTextState): string => {
    if (!dt || !dt.text || !dt.replacer) return 'Broken mode? :D'
    return replaceAt(dt.text, dt.currentPos ?? 0, dt.replacer)
}

interface DevRibbonProps {
    disableAnimation?: boolean
}

const NavbarDevRibbon = ({disableAnimation}: DevRibbonProps) => {

    const [devText, setDevText] = useAtom(devTextAtom)

    useEffect(() => {
        if (disableAnimation) return
        const timer = setTimeout(() => {
            setDevText({
                ...devText,
                currentPos: devText.currentPos >= devText.text.length - 1 ? 0 : devText.currentPos + 1
            })
        }, 500);
        return () => clearTimeout(timer);
    }, [devText, disableAnimation, setDevText])

    if (isDev || isStaging) {
        return (
            <Typography
                variant="caption"
                sx={{
                    color: "red",
                    fontFamily: "consolas",
                    fontSize: "12pt"
                }}
            >
                {generateDevText(devText)}
            </Typography>
        )
    }

    return <></>
}

export default NavbarDevRibbon;