import {useEffect, useState} from "react";
import {Stack, TextField} from "@mui/material";
import {TextFieldProps} from "@mui/material/TextField/TextField";
import {useDebounce} from "shared/hook/useDebounce";

export type TextBoxAndDebounceProps = Omit<TextFieldProps, 'onChange' | 'value'> & {
    onChange?: (newValue: string) => void
    value: string
    label?: string
}

/**
 * Presents an input, which handles the user input with useState and
 * after debounce 1 sec calls the prop onChange event.
 *
 * Initial value van change. Will not mess up the input or debounce!
 * However, it will not be used - initialValue changes will not change the input's value
 *
 * Example usage:
 *          <TextBoxAndDebounce
 *            initialValue={state.searchValue}
 *            onChange={onSearchChange}
 *          />
 * @param onChange
 * @param value
 * @param label
 * @param replaceStar
 * @param props
 * @constructor
 */
const TextBoxAndDebounce = ({
                                onChange,
                                value,
                                label,
                                ...props
                            }: TextBoxAndDebounceProps) => {
    const [currentValue, setCurrentValue] = useState<string>(value)

    // debounce
    const inputDebounce1s = useDebounce(1000)

    const handleChange = (newValue: string): void => {
        let sValue = newValue ?? '';

        // Set the controlled input value immediately
        // (otherwise it would block!)
        setCurrentValue(sValue)

        // Debounce the event to parent
        if (sValue === '') {
            // if manually deleted, drop all debounced calls
            inputDebounce1s.cancel()
            // and set the value instantly
            onChange?.('')
        } else {
            // any typed string should debounce
            inputDebounce1s(() => {
                onChange?.(sValue)
            })
        }
    }

    // Synchronize value changes to currentValue
    useEffect(() => {
        inputDebounce1s.cancel()
        setCurrentValue(value)
    }, [inputDebounce1s, value])

    return (
        <Stack direction={'row'} spacing={2} sx={{
            width: 1
        }}
        >
            <TextField
                variant="outlined"
                fullWidth
                label={label}
                InputLabelProps={{
                    shrink: true,
                    ...props.InputLabelProps
                }}
                /* all the parent props */
                {...props}
                /* fix controlled inputs */
                value={currentValue}
                onChange={(e) => handleChange(e?.target?.value ?? "")}
            />
        </Stack>
    )
}

export default TextBoxAndDebounce