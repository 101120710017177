import {Grid} from "@mui/material";
import {CDSMSSapItemForWorksheetSearchQuery} from "crowbar-api";
import AddItemSelectCard from "modules/worksheet/editor/ui/catalogue/add-item-dialog/AddItemSelectCard";
import React from "react";
import AddItemSelectListRow from "modules/worksheet/editor/ui/catalogue/add-item-dialog/AddItemSelectListRow";

interface AddItemDialogItemsProps {
    items: CDSMSSapItemForWorksheetSearchQuery[]
    selectedItemCode?: string
    setSelected: (itemCode?: string) => void
    viewMode: 'list' | 'grid'
}

const AddItemDialogItems = ({items, selectedItemCode, setSelected, viewMode}: AddItemDialogItemsProps) => {

    return (
        <Grid
            container
            direction={"row"}
            spacing={1}
            justifyContent="center"
            sx={{
                height: 1
            }}
        >

            {viewMode === "grid" && (
                items.map((item: CDSMSSapItemForWorksheetSearchQuery) => (
                    <Grid item key={item.itemCode} xs="auto">
                        <AddItemSelectCard

                            item={item}
                            selected={selectedItemCode === item.itemCode}
                            setAsSelected={() => {
                                setSelected(item.itemCode)
                            }}
                        />
                    </Grid>
                ))
            )}

            {viewMode === "list" && (
                items.map((item: CDSMSSapItemForWorksheetSearchQuery) => (
                    <Grid item key={item.itemCode} xs={12}>
                        <AddItemSelectListRow

                            item={item}
                            selected={selectedItemCode === item.itemCode}
                            setAsSelected={() => {
                                setSelected(item.itemCode)
                            }}
                        />
                    </Grid>
                ))
            )}

        </Grid>
    )
}

export default AddItemDialogItems