import React from 'react';

import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal';
import useTranslateFunction from "shared/language/useTranslateFunction";
import {SvgIconProps, Typography} from "@mui/material";

export type FixesWorksheetDataIconProps = SvgIconProps & {}

const FixesWorksheetDataIcon = ({
                                    ...p
                                }: FixesWorksheetDataIconProps) => {
    const tk = useTranslateFunction("Admin.Worksheet")
    return (
        <Typography component="span" title={tk('FixesWorksheetDataIconTitle')}>
            <AutoFixNormalIcon {...p} />
        </Typography>
    );
};

export default FixesWorksheetDataIcon;