import React, {useEffect} from 'react';
import NavigableWorksheetSignatures from "modules/worksheet/signature/NavigableWorksheetSignatures";
import useWorksheetSignatureState from "modules/worksheet/signature/useWorksheetSignatureState";
import {useNavigate} from "react-router-dom";
import {
    useWorksheetFindStatusAndSignatureByIds
} from "crowbar-api/hooks/worksheet/status-and-signature/useWorksheetFindStatusAndSignatureByIds";
import {CircularProgress} from "@mui/material";
import {useWorksheetCartState} from "modules/worksheet/worksheet-cart/useWorksheetCartState";

export type WorksheetSignatureFromCartProps = {
    cartScope: string | number
    returnUrl?: string
}

/**
 * The use of NavigableWorksheetSignatures requires a prepare call for the signature state.
 * (useWorksheetSignatureState -> prepareFor)
 * @constructor
 */
const WorksheetSignatureFromCart = ({cartScope, returnUrl}: WorksheetSignatureFromCartProps) => {

    const {worksheetIdOptions} = useWorksheetSignatureState()
    const worksheetStatuses = useWorksheetFindStatusAndSignatureByIds(worksheetIdOptions.map(o => o.id))
    const {setCartEntries} = useWorksheetCartState(cartScope)

    const navigate = useNavigate()

    const onSignatureUpdated = () => {
        // after update, clear the entries
        setCartEntries([])
    }

    useEffect(() => {
        if (!worksheetIdOptions || worksheetIdOptions.length < 1) {
            navigate("/")
        }
    }, [navigate, worksheetIdOptions])

    if (worksheetStatuses.isLoading || worksheetStatuses.isFetching) {
        return (
            <CircularProgress/>
        )
    }

    return (
        <NavigableWorksheetSignatures
            worksheetStatuses={worksheetStatuses.data ?? []}
            returnUrl={returnUrl}
            onSignatureUpdated={onSignatureUpdated}
        />
    )
}

export default WorksheetSignatureFromCart;