export class InfQueryUtils {
    public static getNextPageParamForRowNum = <T extends {
        rowNum?: number
    }>(lastPage: T[], pages: T[][]): number | undefined => {
        if (pages.length < 1) return 0

        // if the last page had no rows, there is no next page avail!
        if (pages[pages.length - 1].length < 1) {
            return undefined
        }
        // find the last rowNum going backwards
        // (if for some reason a page is empty, skip it)
        let lastRowNumber: number | undefined = undefined;
        for (let i = pages.length - 1; i >= 0; i--) {
            if (pages[i].length < 1) continue;
            lastRowNumber = pages[i][pages[i].length - 1]?.rowNum
            break
        }
        return lastRowNumber ?? 0
    }
}