import React from 'react';

import TaskAltIcon from '@mui/icons-material/TaskAlt';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import {SvgIconProps} from "@mui/material";

export type FinishedIconProps = SvgIconProps & {
    value: boolean
    onlyDisplayTrueState?: boolean
}

const FinishedIcon = ({value, onlyDisplayTrueState, ...props}: FinishedIconProps) => {
    return value
        ? <TaskAltIcon {...props} />
        : (!onlyDisplayTrueState) ? <RadioButtonUncheckedIcon {...props} /> : <></>
}

export default FinishedIcon;