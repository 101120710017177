import React from 'react';
import {Grid, Stack, Typography} from "@mui/material";
import MechanicWorksheetFilters from "modules/worksheet/mechanic/filters/MechanicWorksheetFilters";
import MechanicWorksheetResults from "modules/worksheet/mechanic/results/MechanicWorksheetResults";
import useTranslateFunction from "shared/language/useTranslateFunction";
import NewWorksheetButton from "modules/worksheet/mechanic/new-worksheet/NewWorksheetButton";
import MechanicWorksheetOrderBy from "modules/worksheet/mechanic/order/MechanicWorksheetOrderBy";
import ScrollToTopButton from "shared/components/buttons/ScrollToTopButton";
import WorksheetCartButton from "modules/worksheet/worksheet-cart/WorksheetCartButton";
import {DateUtils} from "shared/utils/DateUtils";
import {atom} from "jotai";
import {MechanicListingState} from "modules/worksheet/mechanic/state/useMechanicListingState";

export const WorksheetEditorCartScope = "editor"
/// jotai removed the scope api:  https://github.com/pmndrs/jotai/discussions/1880 https://jotai.org/docs/guides/migrating-to-v2-api
//export const WorksheetEditorCartContext = createContext("editor")
///const worksheetEditorStore = createStore()

const defaultMechanicListingState: MechanicListingState = {
    mode: 'service-call',
    searchValue: "",
    pageLimit: 10,
    selectedOrderByField: 'OrderByModificationDates',
    orderBy: 'DESC',
    worksheetSearch: {
        ownerIds: [],
        includeDraft: true,
        includeFinal: true,
        includeFinalSignedOrSkipped: true,
        includeEvaluated: false,
        limit: 10,
        showDeleted: false,
        worksheetFrom: DateUtils.newServerUTCDateAndAdd({month: -99}),
        worksheetTo: DateUtils.newServerUTCDateAndAdd({month: 1})
    },
    subTablePageSize: 5,
    showDeletedWorksheets: false
}

export const mechanicAtom = atom<MechanicListingState>(defaultMechanicListingState)

const MechanicWorksheets = () => {
    const tk = useTranslateFunction(`Worksheet.Generic.Mechanic`)

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12}>

                    <Stack direction="row" justifyContent="space-between">
                        <Typography variant="h4">
                            {tk('YourWorksheets')}
                        </Typography>

                        <Stack direction="row" spacing={4}>
                            <WorksheetCartButton scope={WorksheetEditorCartScope}/>
                            <NewWorksheetButton/>
                        </Stack>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <MechanicWorksheetFilters mechanicStateAtom={mechanicAtom}/>
                </Grid>
                <Grid item xs={12}>
                    <MechanicWorksheetOrderBy mechanicStateAtom={mechanicAtom}/>
                </Grid>
                <Grid item xs={12}>
                    <MechanicWorksheetResults mechanicStateAtom={mechanicAtom}/>
                </Grid>
            </Grid>

            <ScrollToTopButton/>
        </>
    )
}

export default MechanicWorksheets;