import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {CDAMWMRWorksheetSaveResult, CDAMWMWWorksheetSave, EWorksheetSaveError, WorksheetApi} from "crowbar-api";
import {useAtom} from "jotai";
import {useMemo} from "react";
import {useUserContext} from "shared/hook/UserContext";
import {CancelToken} from "axios";
import {DateUtils} from "shared/utils/DateUtils";
import {
    newWorksheetSaveWrapperFor,
    WorksheetSaveStorage,
    WorksheetSaveStorageAtom,
    WorksheetSaveWrapper
} from "modules/worksheet/storage/WorksheetSaveStorage";
import {WorksheetSaveLogStorage, WorksheetSaveLogStorageAtom} from "modules/worksheet/storage/WorksheetSaveLogStorage";
import {WorksheetSaveDraftSearchParams} from "modules/worksheet/storage/WorksheetSaveDraftSearchParams";
import {ObjectUtils} from "shared/utils/ObjectUtils";

export interface WorksheetSaveServiceResult {
    differentPreviousVersionError: boolean
    hasError: boolean
    saveError?: EWorksheetSaveError

    worksheet?: CDAMWMWWorksheetSave
    saveResult?: CDAMWMRWorksheetSaveResult
    saveTimeMs?: number
}

export interface WorksheetSaveService {
    /**
     * Save the worksheet object to local store.
     * Automatically creates a new wrapper object
     * @param w
     * @param markAsSavedToServer if the value is not dirty
     */
    saveToStorage: (w: CDAMWMWWorksheetSave, markAsSavedToServer?: boolean) => Promise<WorksheetSaveWrapper>
    /**
     * Reads the worksheet wrapper from local store (found by the id given) and
     * save it to the server using the api.
     * The server returns the new version id.
     * We update the local worksheet with the new version id (also with the serial id..etc).
     * @param worksheetId
     * @param ignorePreviousVersion
     * @param cancelToken
     */
    saveToServer: (worksheetId: string,
                   ignorePreviousVersion: boolean,
                   cancelToken?: CancelToken) => Promise<WorksheetSaveServiceResult>

    clearFromStorage: (worksheetId: string) => Promise<void>

    findAllWrapper: () => Promise<WorksheetSaveWrapper[]>
    findAll: () => Promise<CDAMWMWWorksheetSave[]>
    findDraftWrappers: (searchParams: WorksheetSaveDraftSearchParams) => Promise<WorksheetSaveWrapper[]>
    findDrafts: (searchParams: WorksheetSaveDraftSearchParams) => Promise<CDAMWMWWorksheetSave[]>
    findWrapper: (worksheetId: string) => Promise<WorksheetSaveWrapper | null>
    find: (worksheetId: string) => Promise<CDAMWMWWorksheetSave | null>

    keys: () => Promise<string[]>
}

export class WorksheetSaveServiceImpl implements WorksheetSaveService {
    private readonly userId: string;
    private readonly worksheetSaveStorage: WorksheetSaveStorage;
    private readonly worksheetSaveLogStorage: WorksheetSaveLogStorage;

    /**
     * These fields are entirely handled by the server.
     * Even if we set the value, it will be ignored.
     * If we set an invalid value, the server won't be able to
     * deserialize the DateTime .net type. So we skip it on save.
     * @private
     */
    private removeFieldsOnSave = ['createdAt', 'createdBy', 'updatedAt', 'updatedBy']

    constructor(
        userId: string,
        worksheetSaveStorage: WorksheetSaveStorage,
        worksheetSaveLogStorage: WorksheetSaveLogStorage
    ) {
        this.userId = userId;
        this.worksheetSaveStorage = worksheetSaveStorage;
        this.worksheetSaveLogStorage = worksheetSaveLogStorage;
    }

    private worksheetApi = (): WorksheetApi => {
        return crowbarApiFactory(WorksheetApi, undefined, true)
    }

    public loadFromServerAsSave = async (worksheetId: string, cancelToken?: CancelToken): Promise<CDAMWMWWorksheetSave | undefined> => {
        const response = await this.worksheetApi().findWorksheetQueryByIdAsSave(worksheetId, false, {
            cancelToken: cancelToken
        })
        if (response.status === 404) {
            return undefined
        }
        if (response.status === 200) {
            // return WorksheetSaveFactory.fromQuery(response.data)
            return response.data
        }

        throw new Error("Unknown status code: " + response.status)
    }

    /*public pageFromServerAsSave = async (searchParams: CDAMWMWWorksheetSearchParams, cancelToken?: CancelToken): Promise<CDAMWMWWorksheetSave[]> => {
        const response = await this.worksheetApi().searchPageBy(searchParams, {
            cancelToken: cancelToken
        })
        if (response.status === 200) {
            return WorksheetSaveFactory.fromQueries(response.data)
        }

        throw new Error("Could not load worksheets from server. " + JSON.stringify(response.data))
    }*/

    public saveToStorage = async (w: CDAMWMWWorksheetSave, markAsSavedToServer?: boolean) => {
        const newWrapper = newWorksheetSaveWrapperFor(w, DateUtils.utcTimestampNow(), this.userId)
        newWrapper.savedToServer = markAsSavedToServer ?? false
        await this.worksheetSaveStorage.setItemFor(newWrapper)
        return newWrapper
    }

    public saveToServer = async (
        worksheetId: string,
        ignorePreviousVersion: boolean,
        cancelToken?: CancelToken
    ): Promise<WorksheetSaveServiceResult> => {
        try {
            const storageWorksheetWrapper = await this.worksheetSaveStorage.getItem(worksheetId)
            if (!storageWorksheetWrapper) {
                return {
                    differentPreviousVersionError: false,
                    hasError: true,
                    saveError: {
                        errorCode: 0,
                        message: `Worksheet wrapper not found locally by id: '${worksheetId}'.`
                    }
                }
            }

            if (storageWorksheetWrapper?.ws === undefined) {
                return {
                    differentPreviousVersionError: false,
                    hasError: true,
                    saveError: {
                        errorCode: 0,
                        message: `WorksheetSave object is undefined in wrapper. Found locally by id: '${worksheetId}'.`
                    }
                }
            }

            storageWorksheetWrapper.ws.previousVersion = storageWorksheetWrapper.ws.version

            const wsToSave = this.prepareWorksheetObjectForSave(storageWorksheetWrapper.ws)

            const response = await this.worksheetApi()
                .merge(ignorePreviousVersion, wsToSave, {
                    cancelToken: cancelToken,
                    validateStatus: (statusCode) => [200, 406].includes(statusCode)
                })
            if (response.status === 200) {

                // Update current storage value
                /* const serverWorksheet = await this.loadFromServerAsSave(worksheetId, cancelToken)
                 if (!serverWorksheet) {
                     throw new Error(`Could not query the worksheet after save with id: '${worksheetId}'.`)
                 }
                 serverWorksheet.previousVersion = serverWorksheet?.version
                 const newWrapper = newWorksheetSaveWrapperFor(serverWorksheet, DateUtils.utcTimestampNow(), this.userId)
                 newWrapper.savedToServer = true
                 await this.worksheetSaveStorage.setItemFor(newWrapper)
                 */
                // Remove local storage value
                await this.worksheetSaveStorage.removeItemBy(worksheetId)

                // Update log
                /*await this.worksheetSaveLogStorage.setItemFor({
                    id: uuid(),
                    date: new Date(),
                    payload: undefined,
                    type: "success"
                })*/

                return {
                    differentPreviousVersionError: false,
                    hasError: false,
                    saveResult: response.data,
                    saveTimeMs: response.data.saveTimeMs
                }
            } else if (response.status === 406) {
                console.error(response)
                return {
                    differentPreviousVersionError: true,
                    hasError: true
                }
            } else {
                const error = response.data as EWorksheetSaveError
                return {
                    differentPreviousVersionError: false,
                    hasError: true,
                    saveError: error
                }
            }
        } catch (e: unknown) {
            console.log(e)
            return {
                differentPreviousVersionError: false,
                hasError: true,
            }
        }
    }

    public clearFromStorage = async (worksheetId: string): Promise<void> => {
        await this.worksheetSaveStorage.removeItemBy(worksheetId)
    }

    public prepareWorksheetObjectForSave = (ws: CDAMWMWWorksheetSave): CDAMWMWWorksheetSave => {
        return ObjectUtils.cloneDeepAndSkip(ws, this.removeFieldsOnSave)
    }

    public findAllWrapper = async (): Promise<WorksheetSaveWrapper[]> => {
        return await this.worksheetSaveStorage.getAll()
    }

    public findAll = async (): Promise<CDAMWMWWorksheetSave[]> => {
        return (await this.findAllWrapper()).filter(w => w?.ws !== undefined).map(w => w!.ws!)
    }

    public findDraftWrappers = async (searchParams: WorksheetSaveDraftSearchParams): Promise<WorksheetSaveWrapper[]> => {
        return await this.worksheetSaveStorage.findDraftsFor(searchParams)
    }

    public findDrafts = async (searchParams: WorksheetSaveDraftSearchParams): Promise<CDAMWMWWorksheetSave[]> => {
        return (await this.findDraftWrappers(searchParams)).filter(w => w?.ws !== undefined).map(w => w!.ws!)
    }

    public findWrapper = async (worksheetId: string): Promise<WorksheetSaveWrapper | null> => {
        return await this.worksheetSaveStorage.getItem(worksheetId)
    }

    public find = async (worksheetId: string): Promise<CDAMWMWWorksheetSave | null> => {
        return (await this.findWrapper(worksheetId))?.ws ?? null
    }

    public keys = (): Promise<string[]> => {
        return this.worksheetSaveStorage.keys()
    }
}

export const useWorksheetSaveService = (): WorksheetSaveService => {
    const [userId] = useUserContext()
    const [worksheetSaveStorage] = useAtom(WorksheetSaveStorageAtom)

    const [worksheetSaveLogStorage] = useAtom(WorksheetSaveLogStorageAtom)

    return useMemo(() => new WorksheetSaveServiceImpl(
        userId ?? "",
        worksheetSaveStorage,
        worksheetSaveLogStorage
    ), [userId, worksheetSaveLogStorage, worksheetSaveStorage])
}