import React from 'react';
import {useWorksheetSaveState} from "modules/worksheet/editor/state/WorksheetSaveAtoms";
import {CDAMWMWServiceCallSave} from "crowbar-api";
import {Grid} from "@mui/material";
import WorksheetEditorHeaderEquipmentCardDetails
    from "modules/worksheet/editor/ui/equipments/WorksheetEditorHeaderEquipmentCardDetails";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {byPropertiesOf} from "shared/utils/ArraySort";
import WEditorAddNewServiceCallDialogButton
    from "modules/worksheet/editor/ui/equipments/WEditorAddNewServiceCallDialogButton";
import useWorksheetSignedState from "modules/worksheet/editor/state/helpers/useWorksheetSignedState";

const WEditorEquipments = () => {
    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)
    // const [info] = useWorksheetEditorHeaderInfoAtom()

    const [worksheetSave] = useWorksheetSaveState()
    const {editDisabled} = useWorksheetSignedState()

    const serviceCalls = (worksheetSave?.serviceCalls ?? [])
        .sort(byPropertiesOf<CDAMWMWServiceCallSave>(['displayOrder']))

    return (
        <>
            <Grid container spacing={1} justifyContent="center">
                {serviceCalls.map((sc, index) => (
                    <Grid key={index} item xs={12}>
                        <WorksheetEditorHeaderEquipmentCardDetails serviceCall={sc} disabled={editDisabled}/>
                    </Grid>
                ))}
                {!editDisabled && (
                    <Grid key={-1}
                          item
                          xs="auto"
                    >
                        <WEditorAddNewServiceCallDialogButton
                        />

                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default WEditorEquipments;