import React from 'react';
import EquipmentThumbnail from "modules/worksheet/editor/shared/component/equipment-thumbnail/EquipmentThumbnail";
import {useAzureBlobStorageUrlFactory} from "shared/hook/useAzureBlobStorageUrlFactory";
import {CDAMWMServiceCallSelectedSource} from "crowbar-api";
import ServiceCallSelectedSourceMap from "crowbar-api/enum-map/ServiceCallSelectedSourceMap";
import {WorksheetIconImages} from "modules/worksheet/editor/shared/component/images/WorksheetIconImages";

export type RequiredFieldsForEquipmentThumbnail = {
    coverImage?: string | null
    'selectedSource'?: CDAMWMServiceCallSelectedSource;
}

export type EquipmentThumbnailForServiceCallProps = {
    serviceCall?: RequiredFieldsForEquipmentThumbnail

    size?: number
}
const EquipmentThumbnailForServiceCall = ({serviceCall, size}: EquipmentThumbnailForServiceCallProps) => {

    const blobUrlFac = useAzureBlobStorageUrlFactory()

    const coverImageUrls = blobUrlFac.sapImageFromName(serviceCall?.coverImage)

    const currentThumb = serviceCall?.selectedSource === ServiceCallSelectedSourceMap.empty
        ? WorksheetIconImages.clipboardLarge
        : coverImageUrls.thumbMedium

    return (
        <EquipmentThumbnail size={size ?? 128} thumbSrc={currentThumb}/>
    )
}

export default EquipmentThumbnailForServiceCall;