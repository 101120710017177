import React, {useEffect} from 'react';
import {UsePhotoBlobsResult} from "modules/worksheet/editor/ui/photo/usePhotoBlobs";
import {Lightbox, SlideImage} from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import {useNewAtomWithRef} from "shared/hook/useNewatomWithRef";
import {CDAMWMESapEquipmentServiceCallBlobQuery} from "crowbar-api";
import {useAzureBlobStorageUrlFactory} from "shared/hook/useAzureBlobStorageUrlFactory";

export interface WEditorPhotoLightboxProps {
    openSignal: number
    photoBlobs: UsePhotoBlobsResult
    currentBlob: string | undefined
}

const WEditorPhotoLightbox = ({openSignal, photoBlobs, currentBlob}: WEditorPhotoLightboxProps) => {
    const [prevOpenSignal, setPrevOpenSignal] = useNewAtomWithRef(openSignal)
    const [lightboxOpen, setLightboxOpen] = useNewAtomWithRef(false)

    const urlFactory = useAzureBlobStorageUrlFactory()

    useEffect(() => {
        if (prevOpenSignal !== openSignal) {
            setPrevOpenSignal(openSignal)
            setLightboxOpen(true)
        }
    }, [openSignal, prevOpenSignal, setLightboxOpen, setPrevOpenSignal])

    const onClose = () => {
        setLightboxOpen(false)
    }

    const allBlobs: CDAMWMESapEquipmentServiceCallBlobQuery[] = []
    for (let groupedBlob of photoBlobs.groupedBlobs) {
        allBlobs.push(...groupedBlob.blobs)
    }
    allBlobs.push(...photoBlobs.ungroupedBlobs)

    const found = allBlobs.find(b => b.blobId === currentBlob)
    const currentIndex = found ? allBlobs.indexOf(found) : 0

    return (
        <Lightbox
            open={lightboxOpen}
            index={currentIndex}
            close={onClose}
            plugins={[Zoom, Thumbnails]}
            carousel={{finite: true}}
            zoom={{
                maxZoomPixelRatio: 10
            }}
            slides={allBlobs
                .filter(blob => blob.blobs && blob.blobs.length === 1)
                .map((blob): SlideImage => {
                    const b = (blob.blobs!)[0]
                    const src = urlFactory.imageFromName("equipment", b.parentDirectory, b.fileName)
                    return {
                        src: src.uri ?? "",
                        width: window.screen.width,
                        height: window.screen.height
                    }
                })}
        />
    );
};

export default WEditorPhotoLightbox;