import React from 'react';
import {Route, Routes} from 'react-router-dom';
import Home from 'modules/home/Home';
import TabConfig from "modules/home/TabConfig";
import NotFound from "shared/components/not-found/NotFound";
import DeliveryNotes from "modules/delivery-note/DeliveryNotes";
import DefaultLayout from "layout/DefaultLayout";
import UnAuthorized from "modules/generic/unauthorized/UnAuthorized";
import {Roles} from "shared/authorization/Roles";
import AdminRoutes from "modules/admin/AdminRoutes";
import WorksheetModule from "modules/worksheet/WorksheetModule";
import RequireAuth from "shared/authorization/RequireAuth";
import SentryErrorBoundary from "shared/components/error/SentryErrorBoundary";
import EquipmentModule from "modules/equipment/EquipmentModule";

const RoutesWithLayout = () => {
    return (
        <DefaultLayout>
            <SentryErrorBoundary>
                <Routes>
                    <Route path="/" element={<Home/>}/>

                    <Route path="/tab-config" element={<TabConfig/>}/>

                    {/*  <Route path="/msal/logout" element={<Logout/>}/> */}

                    <Route path="/delivery-notes/*" element={<DeliveryNotes/>}/>

                    {/* Worksheet Module */}
                    <Route path="/worksheet/*" element={(
                        <RequireAuth requiredRole={Roles.Mechanic}>
                            <WorksheetModule/>
                        </RequireAuth>
                    )}/>

                    {/* Equipment Module */}
                    <Route path="/equipment/*" element={(
                        <EquipmentModule/>
                    )}/>

                    {/* Admin Module */}
                    <Route path="/admin/*" element={(
                        <RequireAuth requiredRole={Roles.Administrator}>
                            <AdminRoutes/>
                        </RequireAuth>
                    )}/>

                    <Route path="/unauthorized" element={(
                        <UnAuthorized/>
                    )}/>

                    <Route element={(
                        <NotFound/>
                    )}/>
                </Routes>
            </SentryErrorBoundary>
        </DefaultLayout>
    )
}

export default RoutesWithLayout;