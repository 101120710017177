import React from 'react';
import {useWorksheetPdfDownload} from "crowbar-api/hooks/worksheet/pdf/useWorksheetPdfDownload";
import {toastError} from "shared/toast/DefaultToasts";
import {Button, CircularProgress, Stack} from "@mui/material";
import ImageIcon from "shared/components/image-icon/ImageIcon";

export type WorksheetDownloadPdfButtonProps = {
    worksheetId: string | null | undefined
    image: string
    label?: string
    pdfLayout: 'admin' | 'customer'
}

export const WorksheetDownloadPdfButton = ({worksheetId, image, label, pdfLayout}: WorksheetDownloadPdfButtonProps) => {

    const pdfDownload = useWorksheetPdfDownload()

    const downloadPdf = async () => {
        if (!worksheetId) {
            toastError('Missing worksheet id.')
            return
        }
        await pdfDownload.mutateAsync({
            worksheetId: worksheetId,
            layout: pdfLayout
        })
    }

    return (
        <Button
            variant="outlined"
            disabled={pdfDownload.isLoading}
            size="large"
            onClick={downloadPdf}
        >
            <Stack direction="row" spacing={1} alignItems="center">
                {pdfDownload.isLoading ? (
                    <CircularProgress size={32}/>
                ) : (
                    <ImageIcon image={image} size={32}/>
                )}
                {label ?? ""}
            </Stack>
        </Button>
    )
}