import {useMutation} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {PhotoRuleManufacturerApi} from "crowbar-api";
import {v4 as uuid} from 'uuid'

export interface UseAddRuleManufacturerArgs {
    manufacturerCode: string
    ruleId: string
}

const useAddRuleManufacturer = () => {
    return useMutation(async (args: UseAddRuleManufacturerArgs) => {
        const response = await crowbarApiFactory(PhotoRuleManufacturerApi).save({
            id: uuid(),
            sapManufacturerCode: args.manufacturerCode,
            photoRuleId: args.ruleId
        })
        if (response.status !== 200) {
            throw new Error('Failed to save rule manufacturer addition. Status is not ok.')
        }
    })
}

export default useAddRuleManufacturer