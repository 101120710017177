export const i18n_dnHu = {
    "Title": "Sablonok",
    "TemplateDownloadTextPre": "Sablonhoz a minta XML adatforrás",
    "TemplateDownloadTextLink": "letöltése itt",
    "Form": {
        "TitleNew": "Új sablon",
        "TitleEdit": "Sablon szerkesztése",
        "CancelButton": "Mégsem",
        "DeleteButton": "Törlés",
        "SaveButton": "Mentés",
        "InvalidFileExtError": "A fájl legyen docx",
        "FileReaderSuccess": "Fájl sikeresen beolvasva",
        "FileUploadOnEditDisabled": "A fájl feltöltése szerkesztésnél nem lehetséges.",
        "FileUploadLabelEmpty": "nincs fájl kiválasztva",
        "FileUploadButtonText": "Tallózás",
        "FileUploadText": "Fájl feltöltés",
        "DefaultText": "Alapértelmezett?",
        "DescriptionText": "Leírás",
        "DefaultCbLabel": "Legyen ez az elsődleges sablon",
        "FormInvalidFile": "Érvénytelen vagy nem megadott fájl",
        "SaveSuccess": "Sablon mentve",
        "EditLoadingFailed": "Nem sikerült betölteni az adatokat",
        "DeleteSuccess": "Sikeresen törölve",
        "DownloadTemplate": "Fájl letöltése"
    },
    "List": {
        "TitleHeading": "Sablonok",
        "NewButtonText": "Új sablon",
        "ColCreatedAt": "Létrehozva",
        "ColUploader": "Feltöltötte",
        "ColDescription": "Leírás",
        "ColDefault": "Alapértelmezett?",
        "NoDefaultWarning": "Figyelem! Nincs alapértelmezett sablon beállítva!",
    },
    "LicensePlate": {
        "Title": "Rendszám",
        "NewButton": "Új rendszám",
        "FormInvalid": "Rendszám megadása kötelező",
        "SaveSuccess": "Rendszám sikeresen mentve",
        "TitleNew": "Új rendszám",
        "TitleEdit": "Rendszám szerkesztése",
        "CancelButton": "Mégsem",
        "DeleteButton": "Törlés",
        "SaveButton": "Mentés",
        "DescriptionLabel": "Leírás",
        "PlateNumberLabel": "Rendszám",
        "ColCreatedAt": "Létrehozva",
        "ColUploader": "Feltöltötte",
        "ColDescription": "Leírás",
        "ColPlateNumber": "Rendszám",
    },
    "Note": {
        "Header": "Szállítólevelek",
        "Settings": "Beállítások",
        "ListLoadMoreText": "További betöltése..",
        "ListItemCustomerText": "Vevő",
        "ListItemCustomerAddressText": "Vevő címe",
        "ListItemStateText": "Státusz",
        "ShowDeletedText": "Töröltek megjelenítése",
        "ShowDeletedYes": "Igen",
        "ShowDeletedNo": "Nem",
        "ListItemTypeText": "Típus",
        "ListItemSupplierText": "Szállító",
        "ListIssuerText": "Kiállító",
        "SearchText": "Keresés",
        "TypeText": "Típus",
        "LeaseStatusText": "Bérlet státusza",
        "NoResult": "Nincs találat.",
        "CreatedBy": "Létrehozta",
        "Type": {
            "All": "Összes",
            "SaleText": "Eladás",
            "LeaseText": "Bérlet",
            "RepairText": "Javítás",
            "OtherText": "Egyéb",
        },
        "LeaseStatus": {
            "All": "Összes",
            "Missing": "N/A",
            "Open": "Nyitott",
            "Finished": "Befejezett",
        },
        "Dialog": {
            "NewButtonText": "Új levél",
            "NewTitle": "Új szállítólevél",
            "EditTitle": "Szállítólevél szerkesztése",
            "Browse": "Tallózás",
            "Type": "Típus",
            "LeaseStatus": "Bérlet státusza",
            "DatedAt": "Kiállítás dátuma",
            "Supplier": "Szállító",
            "SupplierName": "Név",
            "SupplierAddress": "Cím",
            "SupplierContactName": "Kapcsolattartó",
            "IssuerName": "Kiállító",
            "Customer": "Vevő",
            "SelectEmployee": "Alkalmazott választása",
            "CustomerName": "Név",
            "CustomerBillingAddress": "Számlázási cím",
            "CustomerShippingAddress": "Szállítási cím",
            "CustomerContactName": "Kapcsolattartó",
            "CustomerContactPhone": "Kapcsolattartó telefonszáma",
            "Delivery": "Szállítás",
            "OrderNumber": "Megrendelés száma, azonosító",
            "LicensePlateNumber": "Rendszám",
            "NewRow": "Új sor",
            "Row": "Sor",
            "ItemName": "Termék megnevezése",
            "Quantity": "Mennyiség",
            "UnitOfQuantity": "ME",
            "OperatingHours": "Üzemóra",
            "RemoveTitle": "Sor törlése",
            "Remove": "Törlés",
            "Source": "Forrás",
            "EquipmentMasterData": "Berendezés törzsadat",
            "ItemMasterData": "Cikktörzsadat",
            "EmptyRow": "Üres sor",
            "SelectBtn": "Kiválaszt",
            "NewRowDialogTitle": "Új sor kiválasztása",
            "EmptyRowP": "Üres sor hozzáadása.",
            "DialogSearch": "Keresés",
            "ColCardCode": "Vevőkód",
            "ColCardName": "Név",
            "ColVehicleManufacturer": "Gyártó",
            "ColVehicleType": "Típus",
            "ColVehicleYoM": "Gyártási év",
            "ColManufacturerSn": "Gyártási szám",
            "InternalSn": "Házi szám",
            "ColItemCode": "Cikkszám",
            "ColItemName": "Leírás",
            "ColSww": "Gyártói cikkszám",
            "AddBtn": "Hozzáadás",
            "CancelBtn": "Mégsem",
            "InvalidSourceError": "Érvénytelen forrás.",
            "EquipmentRequiredError": "Gép választása kötelező.",
            "ItemRequiredError": "Cikk választása közelező.",
            "EqLoading": "Gépek betöltése...",
            "ItemLoading": "Cikkek betöltése...",
            "Cancel": "Mégsem",
            "Save": "Mentés",
            "ReqRows": "Legalább egy sor megadása kötelező.",
            "ReqRowFields": "A mennyiség és mennyiségi egység kitöltése kötelező minden sornál.",
            "ReqField": "A mező kitöltése kötelező.",
            "TooLong50": "Nem lehet több mint 50 karakter.",
            "TooLong255": "Nem lehet több mint 255 karakter.",
            "TooLongRows255": "A leírás és a mennyiségi egység nem lehet több mint 255 karakter.",
            "InvalidNumberRows": "Érvénytelen számértékek a soroknál.",
            "ShowOnlySelectedCustomerEqs": "Csak a kiválasztott vevő gépeinek mutatása.",
            "ShowOnlyPhoenixEqs": "Csak saját gépek mutatása.",
            "CancelConfirmMessage": "Biztos benne? Minden nem mentett módosítás el fog veszni.",
            "Delete": "Törlés",
            "Undelete": "Visszaállítás",
            "DeleteConfirmMessage": "Biztosan törli a szállítólevelet?",
            "UndeleteSuccess": "Visszaállítva. Kérem mentse a módosításokat."
        },
    },
    "SelectPartner": {
        "Title": "Üzleti partner kiválasztása",
        "SelectBtn": "Kiválaszt",
        "Cancel": "Mégsem",
        "Please": "Kérem válasszon ki egy ÜP-t.",
        "ColCardCode": "Kód",
        "ColCardName": "Név",
        "SearchLabel": "Keresés",
    },
}