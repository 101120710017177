import {Card, CardContent, CircularProgress, List, ListItem, Stack, Typography} from "@mui/material";
import PagingRowHolder from "shared/utils/PagingRowHolder";
import {DeliveryNoteTypeMap} from "crowbar-api/enum-map/DeliveryNoteTypeMap";
import LeaseStatusMap from "crowbar-api/enum-map/LeaseStatusMap";
import {CDAMDMDeliveryNoteModel, CDAMDMDeliveryNoteType, CDCMUserDetails} from "crowbar-api";
import {useTranslation} from "react-i18next";
import DateDisplay, {dateDisplayDefaultOptions} from "shared/components/date-display/DateDisplay";
import DocxIcon from "shared/components/icons/DocxIcon";
import EditIcon from "shared/components/icons/EditIcon";
import {useDownloadAsPdf} from "crowbar-api/hooks/delivery-note/useDownloadAsPdf";
import {useDownloadAsDocx} from "crowbar-api/hooks/delivery-note/useDownloadAsDocx";

interface DeliveryNoteListItemProps {
    item: PagingRowHolder<CDAMDMDeliveryNoteModel>
    createdBy?: CDCMUserDetails;
    onEditClick: (dn: CDAMDMDeliveryNoteModel) => void;
    onWaypointClicked: () => void;
}

const DeliveryNoteListItem = ({item, onEditClick, createdBy, onWaypointClicked}: DeliveryNoteListItemProps) => {
    const {t} = useTranslation()
    const tKey = "DeliveryNote.Note.";
    const dnMap = new DeliveryNoteTypeMap();
    const lsMap = new LeaseStatusMap();

    const downloadPdf = useDownloadAsPdf(item.row?.id);
    const downloadDocx = useDownloadAsDocx(item.row?.id);

    const handleEditClick = () => {
        if (item?.row) {
            onEditClick(item?.row);
        }
    }

    const handleDownload = async (action: "pdf" | "docx") => {
        if (action === "pdf") {
            await downloadPdf.mutateAsync();
        }
        if (action === "docx") {
            await downloadDocx.mutateAsync();
        }
    }

    if (item?.isWaypoint) {
        const bText = t(tKey + 'ListLoadMoreText');
        return PagingRowHolder.defaultWaypointRender(bText, onWaypointClicked, item.isWaypointDisabled);
    }

    return (
        <Card sx={[{minWidth: 275}, (item?.row?.isDeleted === true && {border: "2px solid red"})]}
              raised={true}
        >
            <CardContent>
                <Stack>
                    <Stack justifyContent={"space-between"} direction={"row"} spacing={1}>
                        <Stack>
                            <Typography variant={"h6"}>
                                <b>{t(tKey + 'ListItemCustomerText')}:</b> {item?.row?.customerCardName} {item?.row?.customerCardCode &&
                                <b>({item?.row?.customerCardCode})</b>}
                            </Typography>

                            <List dense={true} sx={{m: 2}}>
                                {item?.row?.rows?.map(row => {
                                    return (
                                        <ListItem key={row.id}>
                                            <Typography sx={{fontStyle: "italic"}} variant={"body2"}>
                                                {`${row.lineOrder} - ${row.description} ${row.quantity} ${row.unitOfQuantity}`}
                                            </Typography>
                                        </ListItem>)
                                })}
                            </List>
                        </Stack>

                        <Stack sx={{flexShrink: 0}} alignItems={"end"}>
                            <Typography>{item?.row?.serialId}</Typography>

                            {item?.row?.datedAt &&
                                <Typography sx={{fontStyle: "italic"}}>
                                    <DateDisplay
                                        dateStr={item?.row?.datedAt}
                                        format="YYYY-MM-DD"
                                        trimDotFromEnd={true}
                                        options={{
                                            ...dateDisplayDefaultOptions,
                                            hour: undefined,
                                            minute: undefined
                                        }}
                                    />
                                </Typography>}

                            <Typography>
                                <>{t(dnMap.translationKey(tKey + 'Type.', item?.row?.type ?? CDAMDMDeliveryNoteType.NUMBER_0))}</>
                                {item?.row?.type === dnMap.lease &&
                                    <>: {t(lsMap.translationKey(tKey + 'LeaseStatus.', item?.row?.leaseStatus))}</>}
                            </Typography>
                        </Stack>
                    </Stack>

                    <Stack justifyContent={"space-between"} direction={"row"} spacing={1}>
                        <Stack>
                            {item?.row?.customerShippingAddress &&
                                <Typography>
                                    <b>{t(tKey + 'ListItemCustomerAddressText')}</b>: <i>{item?.row?.customerShippingAddress?.replaceAll('\r\n', ' ')}</i>
                                </Typography>}

                            <Typography
                                title={t(tKey + 'CreatedBy') + ' ' + (createdBy?.fullName ?? '-') + ', ' + item?.row?.createdAt}>
                                <b>{t(tKey + 'ListIssuerText')}</b>: {item?.row?.issuerName}
                            </Typography>

                            <Typography>
                                <b>{t(tKey + 'ListItemSupplierText')}</b>: {item?.row?.supplierCardName}{item?.row?.supplierCardCode &&
                                <> ({item?.row?.supplierCardCode})</>}
                                {item?.row?.licensePlateNumber &&
                                    <> - {item?.row?.licensePlateNumber}</>}
                            </Typography>
                        </Stack>


                        <Stack direction={"row"} justifyContent="flex-end" alignItems="flex-end" spacing={5}>
                            {downloadDocx.isLoading ?
                                <CircularProgress size={35}/>
                                : <DocxIcon
                                    fontSize={"large"}
                                    sx={{cursor: "pointer"}}
                                    color={"primary"}
                                    onClick={() => handleDownload("docx")}/>}

                            <EditIcon
                                viewBox={"4 4 16 16"}
                                fontSize={"large"}
                                sx={theme => {
                                    return {
                                        cursor: "pointer",
                                        color: theme.palette.primary.main,
                                        stroke: "white"
                                    }
                                }}
                                color={"disabled"}
                                onClick={handleEditClick}
                            />
                        </Stack>
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    )
}

export default DeliveryNoteListItem;