import {useQuery} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {UserProfileApi} from "crowbar-api";

const useProfileImage = () => {
    return useQuery(['loadProfileImage'], async () => {
        try {
            const userProfileApi = crowbarApiFactory(UserProfileApi, undefined, true)
            const response = await userProfileApi.getSmallCurrentUserPhotoBase64()
            if (response.status !== 200) return
            return response.data
        } catch (e: any) {
            return undefined
        }
    }, {
        retry: 3,
        cacheTime: Math.pow(10, 8),
        staleTime: Math.pow(10, 8),
        refetchOnWindowFocus: false,
        refetchOnMount: false
    })
}

export default useProfileImage