import {atom, SetStateAction, useAtom} from "jotai";
import {useCallback} from "react";
import {
    readWorksheetCartEntriesFromLocalStorage
} from "modules/worksheet/worksheet-cart/useWorksheetCartSaveToLocalStorage";
import {findWorksheetVersionsFor} from "crowbar-api/hooks/worksheet/versions/useFindWorksheetVersionsFor";
import WorksheetStatusMap from "crowbar-api/enum-map/WorksheetStatusMap";

export type WorksheetCartEntry = {
    id: string

    addedAt: number

    serialId?: string | null
    ownerId?: string | null
    startedAt?: string | null
    endedAt?: string | null

    mechanicName?: string | null
}

export interface CleanCartEntriesResult {
    removedEntries?: WorksheetCartEntry[]
}

export type UseWorksheetCartStateResult = {
    cartEntries: WorksheetCartEntry[]
    numberOfEntries: number
    setCartEntries: (update: SetStateAction<WorksheetCartEntry[]>) => void
    addCartEntry: (e: WorksheetCartEntry) => void
    removeCartEntry: (id: string) => void
    isInCart: (id: string | null | undefined) => boolean

    readCartEntriesFromLocalStorage: () => WorksheetCartEntry[]

    cleanCartEntriesIsLoading: boolean
    /**
     * Loads worksheets from the server by id and checks if
     * they are deleted or not.
     * isDeleted=true or missing worksheets are removed from the cart.
     * @returns CleanCartEntriesResult List of removed entries. Can be used to notify.
     */
    cleanCartEntries: () => Promise<CleanCartEntriesResult>

    /**
     * marks the state as "loaded" when true. default false, only set to true if the local storage is loaded
     */
    hydrated: boolean
    /**
     * the local entries for each cart item
     * @param update
     */
    setHydrated: (update: SetStateAction<boolean>) => void
}

const hydratedAtom = atom<boolean>(false)
const scopedCartEntries = atom<WorksheetCartEntry[]>([])
const cleanCartEntriesIsLoadingAtom = atom<boolean>(false)

export const useWorksheetCartState = (scope?: number | string): UseWorksheetCartStateResult => {
    const [hydrated, setHydrated] = useAtom(hydratedAtom) // jotai removed the scope api:  https://github.com/pmndrs/jotai/discussions/1880 https://jotai.org/docs/guides/migrating-to-v2-api
    const [entries, setEntries] = useAtom(scopedCartEntries) // todo: if needed, support jotai states for diff. parents as well
    const [cleanCartEntriesIsLoading, setCleanCartEntriesIsLoading] = useAtom(cleanCartEntriesIsLoadingAtom)

    const add = useCallback((e: WorksheetCartEntry) => {
        setEntries(prev => {
            return [
                ...prev,
                e
            ]
        })
    }, [setEntries])

    const remove = useCallback((id: string) => {
        setEntries(prev => prev.filter(e => e.id !== id))
    }, [setEntries])

    const isInCart = useCallback((id: string | null | undefined) => {
        return entries.find(e => e.id === id) !== undefined
    }, [entries])

    const readCartEntriesFromLocalStorage = useCallback(() => {
        return readWorksheetCartEntriesFromLocalStorage(scope)
    }, [scope])

    const cleanCartEntries = useCallback(async (): Promise<CleanCartEntriesResult> => {
        try {
            setCleanCartEntriesIsLoading(true)
            const removedEntries: WorksheetCartEntry[] = []
            // --
            const currentEntryIds = entries.map(e => e.id)
            const versions = await findWorksheetVersionsFor(currentEntryIds)
            const deletedIds = versions
                .filter(v => !!v?.isDeleted)
                .map(v => v.id)
            const alreadySignedIds = versions
                .filter(v => (v.status?.valueOf() ?? 0) >= WorksheetStatusMap.finalSigned.valueOf())
                .map(v => v.id)

            for (const entry of entries) {
                if (!deletedIds.includes(entry.id) && !alreadySignedIds.includes(entry.id)) {
                    continue
                }
                // remove from cart
                remove(entry.id)
                // add to log
                removedEntries.push(entry)
            }
            // --
            return {
                removedEntries
            }
        } finally {
            setCleanCartEntriesIsLoading(false)
        }
    }, [entries, remove, setCleanCartEntriesIsLoading])

    return {
        cartEntries: entries,
        numberOfEntries: entries?.length ?? 0,
        setCartEntries: setEntries,
        addCartEntry: add,
        removeCartEntry: remove,
        isInCart,
        cleanCartEntriesIsLoading,
        cleanCartEntries,
        hydrated,
        setHydrated,
        readCartEntriesFromLocalStorage
    }
}