import React from 'react';
import {Route, Routes} from "react-router-dom";
import WorksheetEvaluationByWId from "modules/admin/worksheet/evaluation/WorksheetEvaluationByWId";

const WorksheetEvaluationRoutes = () => {
    return (
        <Routes>
            <Route path="by-wid/:worksheetId" element={<WorksheetEvaluationByWId/>}/>
        </Routes>
    );
};

export default WorksheetEvaluationRoutes;