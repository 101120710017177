import React from 'react';
import CheckIconButton from "shared/components/buttons/CheckIconButton";
import {
    CDAMWMServiceCallEvalModel,
    CDAMWMServiceCallModel,
    CDAMWMWorksheetEvalModel,
    CDAMWMWWorksheetQuery
} from "crowbar-api";
import {
    useServiceCallEvalUpdateEquipmentAck
} from "crowbar-api/hooks/worksheet/evaluation/service-call/useServiceCallEvalUpdateEquipmentAck";
import ServiceCallSelectedSourceMap from "crowbar-api/enum-map/ServiceCallSelectedSourceMap";
import {toastError, toastWarn} from "shared/toast/DefaultToasts";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {sapPartnerEquipmentFindByInsId} from "crowbar-api/hooks/sap/useSapPartnerEquipmentFindByInsId";

export type EquipmentAckProps = {
    selectedServiceCall: CDAMWMServiceCallModel
    selectedEval: CDAMWMServiceCallEvalModel | undefined
    wEval: CDAMWMWorksheetEvalModel | null | undefined
    onMasterDataChange: () => void
    disabled?: boolean
    worksheet: CDAMWMWWorksheetQuery | null | undefined
}

const EquipmentAck = ({
                          selectedServiceCall,
                          selectedEval,
                          wEval,
                          onMasterDataChange,
                          disabled,
                          worksheet
                      }: EquipmentAckProps) => {

    const tk = useTranslateFunction("Admin.Worksheet")
    const deliveryAck = (selectedEval?.deliveryAck ?? false)
    const isChecked = selectedEval?.equipmentAck ?? false

    const updateEquipmentAck = useServiceCallEvalUpdateEquipmentAck();

    const onClick = async () => {

        /*if (deliveryAck) {
            return
        }*/

        if (selectedServiceCall?.selectedSource === ServiceCallSelectedSourceMap.manuallyAdded) {
            toastWarn(tk('RequiredToReplaceModEquipment'))
            return
        }

        if (!wEval?.partnerAck) {
            toastWarn(tk('PartnerAckBeforeEquipmentAck'))
            return
        }

        if (!selectedServiceCall?.sapEquipmentId || !worksheet?.cardCode) {
            toastError('Missing manufacturer sn or partner.')
            return
        }

        // Check if the equipments exists currently for the currently selected partner
        // (if the user changed the partner for the ws, the previously selected equipment will be invalid 100%)
        const searchResult = await sapPartnerEquipmentFindByInsId(selectedServiceCall.sapEquipmentId)
        const hasMatchForPartner = searchResult?.insId === selectedServiceCall.sapEquipmentId && searchResult?.cardCode === worksheet.cardCode

        if (!hasMatchForPartner) {
            toastError(tk('InvalidEquipmentForPartner'))
            return
        }

        // Update
        await updateEquipmentAck.mutateAsync({
            evalId: selectedEval?.id,
            originalId: selectedServiceCall.id,
            field: !isChecked
        })
        onMasterDataChange()
    }

    return (
        <CheckIconButton

            checked={isChecked}
            onClick={onClick}
            disabled={disabled || updateEquipmentAck.isLoading}
            checkCircleIconProps={{
                sx: {
                    fontSize: "46pt"
                }
            }}
        />
    );
};

export default EquipmentAck;