import {useCallback, useEffect, useRef} from "react";

/**
 * Set the component destroyed flag when the component is destroyed
 * @returns isMounted function
 */
export const useMountedState = () => {
    const mountedRef = useRef(false)
    const isMounted = useCallback(() => mountedRef.current, [])
    useEffect(() => {
        mountedRef.current = true

        return () => {
            mountedRef.current = false
        }
    }, [])

    return isMounted
}