import React from 'react';
import {Box, Card, CardContent, Grid, Stack} from "@mui/material";
import {
    CDAMWMServiceCallEvalModel,
    CDAMWMServiceCallModel,
    CDAMWMWorksheetEvalModel,
    CDAMWMWWorksheetQuery
} from "crowbar-api";
import SelectEquipmentDialogButton
    from "modules/admin/worksheet/evaluation/tabs/service-calls/equipment-select/SelectEquipmentDialogButton";
import CurrentEquipmentDetails
    from "modules/admin/worksheet/evaluation/tabs/service-calls/equipment-select/CurrentEquipmentDetails";
import EquipmentAck from "modules/admin/worksheet/evaluation/tabs/service-calls/equipment-select/EquipmentAck";
import EquipmentThumbnailForServiceCall
    from "modules/worksheet/editor/shared/component/equipment-thumbnail/EquipmentThumbnailForServiceCall";

export type EquipmentSelectProps = {
    worksheet: CDAMWMWWorksheetQuery | null | undefined
    wEval: CDAMWMWorksheetEvalModel | null | undefined
    selectedServiceCall: CDAMWMServiceCallModel
    scEvalId: string | undefined
    selectedEval: CDAMWMServiceCallEvalModel | undefined
    onMasterDataChange: () => void
    disabled?: boolean
}
const EquipmentSelect = ({
                             worksheet,
                             wEval,
                             selectedServiceCall,
                             scEvalId,
                             selectedEval,
                             onMasterDataChange,
                             disabled
                         }: EquipmentSelectProps) => {

    const equipmentAck = (selectedEval?.equipmentAck ?? false)

    return (
        <Card variant="outlined">
            <CardContent>
                <Grid container direction="row" spacing={1} alignItems="center" justifyContent="center">
                    <Grid item xs="auto">
                        <Stack direction="column" spacing={1} justifyContent="center" alignItems="center">
                            <EquipmentThumbnailForServiceCall size={75} serviceCall={selectedServiceCall}/>
                            <Box>
                                <SelectEquipmentDialogButton
                                    cardCode={worksheet?.cardCode ?? undefined}
                                    serviceCallId={selectedServiceCall.id}
                                    scEvalId={scEvalId}
                                    wEval={wEval}
                                    onMasterDataChange={onMasterDataChange}
                                    disabled={disabled || equipmentAck}
                                />
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid item xs={true}>
                        <Stack direction="row" spacing={1}>
                            <CurrentEquipmentDetails
                                worksheet={worksheet}
                                selectedServiceCall={selectedServiceCall}
                                selectedEval={selectedEval}
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs="auto">
                        <EquipmentAck
                            selectedServiceCall={selectedServiceCall}
                            selectedEval={selectedEval}
                            wEval={wEval}
                            onMasterDataChange={onMasterDataChange}
                            disabled={disabled}
                            worksheet={worksheet}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>

    );
};

export default EquipmentSelect;