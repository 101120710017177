import React, {useMemo} from 'react';
import {
    CDAMWMServiceCallItemRowEvalModel,
    CDAMWMServiceCallItemRowModel,
    CDAMWMWWorksheetQuery,
    CDSMSSapSalesOrderForWorksheetSearchQuery,
    CDSMSSapSalesOrderRowsForWorksheetSearchQuery
} from "crowbar-api";
import MaterialReactTable, {MRT_ColumnDef} from 'material-react-table';
import {useMaterialReactTableLocalization} from "i18n/material-table/useMaterialReactTableLocalization";
import useTranslateFunction from "shared/language/useTranslateFunction";
import SalesOrderStatusCell
    from 'modules/admin/worksheet/evaluation/tabs/service-calls/catalogue/table/SalesOrderStatusCell';
import DescriptionCell from "modules/admin/worksheet/evaluation/tabs/service-calls/catalogue/table/DescriptionCell";
import CostRateCell from "modules/admin/worksheet/evaluation/tabs/service-calls/catalogue/table/CostRateCell";
import SalesOrderQuantityCell
    from "modules/admin/worksheet/evaluation/tabs/service-calls/catalogue/table/SalesOrderQuantityCell";
import DeliveryQuantityCell
    from "modules/admin/worksheet/evaluation/tabs/service-calls/catalogue/table/DeliveryQuantityCell";
import RowAckCell from "modules/admin/worksheet/evaluation/tabs/service-calls/catalogue/table/RowAckCell";
import {red} from "@mui/material/colors";
import ServiceCallItemRowSelectedSourceMap from "crowbar-api/enum-map/ServiceCallItemRowSelectedSourceMap";
import {byPropertiesOf} from "shared/utils/ArraySort";
import OriginalInstalledQty
    from "modules/admin/worksheet/evaluation/tabs/service-calls/catalogue/table/OriginalInstalledQty";
import {Stack} from "@mui/material";
import FixesWorksheetDataIcon from "modules/admin/worksheet/evaluation/shared/FixesWorksheetDataIcon";

export type CatalogueTableProps = {
    worksheet: CDAMWMWWorksheetQuery | null | undefined
    rows: CDAMWMServiceCallItemRowModel[]
    rowEvals: CDAMWMServiceCallItemRowEvalModel[]
    salesOrder: CDSMSSapSalesOrderForWorksheetSearchQuery | null | undefined
    onDataChange: () => Promise<void>
    disabled?: boolean
}

export type CatalogueTableRow = {
    visOrder: number
    row?: CDAMWMServiceCallItemRowModel
    eval?: CDAMWMServiceCallItemRowEvalModel
    salesOrderRow?: CDSMSSapSalesOrderRowsForWorksheetSearchQuery | undefined
}

const CatalogueTable = ({worksheet, rows, rowEvals, salesOrder, onDataChange, disabled}: CatalogueTableProps) => {

    const tk = useTranslateFunction('Admin.Worksheet')
    const mrt_locale = useMaterialReactTableLocalization()

    const tableRows = useMemo(() => {
        const result: CatalogueTableRow[] = []
        for (let rowEval of rowEvals) {
            const row = rows.find(r => r.id === rowEval.serviceCallItemRowId)
            const salesOrderRow = salesOrder?.rows?.find(r => row && r.itemCode === row.itemCode && r.lineNum === row.sourceLineNum)
            result.push({
                visOrder: row?.visOrder ?? 0,
                row: row,
                eval: rowEval,
                salesOrderRow
            })
        }
        return result.sort(byPropertiesOf(['visOrder']))
    }, [rowEvals, rows, salesOrder?.rows])

    const columns = useMemo<MRT_ColumnDef<CatalogueTableRow>[]>(() => [
        {
            accessorFn: row => row.row?.itemCode ?? "-",
            header: tk('Type'),
            size: 100,
            id: "type"
        },
        {
            accessorFn: row => (
                <DescriptionCell row={row}/>
            ),
            header: tk('Description'),
            size: 300,
            id: "description"
        },
        {
            accessorFn: row => (
                <SalesOrderStatusCell row={row}/>
            ),
            header: tk('Status'),
            size: 80,
            id: "status"
        },
        {
            accessorFn: row => (
                <CostRateCell row={row} sapDim={1} onDataChange={onDataChange} disabled={disabled}/>
            ),
            header: tk('Dim1'),
            size: 150,
            id: "dim1"
        },
        {
            accessorFn: row => (
                <CostRateCell row={row} sapDim={2} onDataChange={onDataChange} disabled={disabled}/>
            ),
            header: tk('Dim2'),
            size: 150,
            id: "dim2"
        },
        {
            accessorFn: row => (
                <CostRateCell row={row} sapDim={4} onDataChange={onDataChange} disabled={disabled}/>
            ),
            header: tk('Dim4'),
            size: 150,
            id: "dim4"
        },
        {
            accessorFn: row => row?.row?.unitOfQuantity ?? "-",
            header: tk('UnitOfQuantity'),
            size: 70,
            id: "unitOfQuantity"
        },
        {
            accessorFn: row => (
                <SalesOrderQuantityCell row={row}/>
            ),
            header: tk('SalesOrderQuantity'),
            size: 100,
            id: "salesOrderQuantity"
        },
        {
            accessorFn: row => (
                <OriginalInstalledQty row={row}/>
            ),
            header: tk('OriginalInstalledQuantity'),
            size: 100,
            id: "installedQuantity"
        },
        {
            accessorFn: row => (
                <DeliveryQuantityCell row={row} onDataChange={onDataChange} disabled={disabled}/>
            ),
            header: tk('DeliveryQuantity'),
            Header: () => (
                <Stack direction="row" spacing={0.5}>
                    {tk('DeliveryQuantity')}
                    <FixesWorksheetDataIcon/>
                </Stack>
            ),
            size: 150,
            id: "deliveryQuantity"
        },
        {
            accessorFn: row => (
                <RowAckCell row={row} onDataChange={onDataChange} disabled={disabled}/>
            ),
            header: tk('RowAck'),
            size: 50,
            id: "ack"
        },
    ], [disabled, onDataChange, tk])

    return (
        <MaterialReactTable
            data={[...tableRows]}

            getRowId={(row) => row.eval?.id ?? ""} //give each row a more useful id

            /*muiTableBodyRowProps={({row, table, isDetailPanel, staticRowIndex}) => {
                const mr = tableRows.find(r => r.eval?.id === row.id)
                const manuallyAddedRow = mr && mr.row?.rowSelectedSource !== ServiceCallItemRowSelectedSourceMap.sapSalesOrder
                return {
                    sx: {
                       // backgroundColor: manuallyAddedRow ? orange[100] : "inherit"
                        color: manuallyAddedRow ? red[400] : "inherit"
                    }
                }
            }}*/

            muiTableBodyCellProps={({row}) => {
                const mr = tableRows.find(r => r.eval?.id === row.id)
                const diffSalesOrderQty = mr && mr.row?.installedQuantity !== mr.salesOrderRow?.quantity
                const manuallyAddedRow = mr && mr.row?.rowSelectedSource !== ServiceCallItemRowSelectedSourceMap.sapSalesOrder
                return {
                    sx: {
                        color: /*mr && mr.row?.itemCode &&*/ (diffSalesOrderQty || manuallyAddedRow) ? red[400] : "inherit"
                    }
                }
            }}

            columns={columns}
            localization={mrt_locale}
            initialState={{
                density: 'compact',
                pagination: {
                    pageIndex: 0,
                    pageSize: 100
                }
            }}
            rowNumberMode="original" //default
            enableGlobalFilter={false} //disable search feature
            enableColumnResizing={true}
            enableColumnFilters={false}
            enableColumnActions={false}
            enableSorting={false}
            enableFullScreenToggle={false}
            positionToolbarAlertBanner="bottom"

            enablePagination={false}
            enableBottomToolbar={false}
            enableTopToolbar={false}
        />
    )
}

export default CatalogueTable;