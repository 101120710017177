import {Box, Stack, TextField} from "@mui/material";
import {
    PartnerContactFormResult
} from "modules/worksheet/editor/shared/component/partner-contact-form/usePartnerContactForm";
import useTranslateFunction from "shared/language/useTranslateFunction";


export interface PartnerContactFormProps {
    mode: 'new' | 'edit'
    form: PartnerContactFormResult
}

const PartnerContactForm = ({mode, form}: PartnerContactFormProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    const {
        formState: {errors},
        emailRegister,
        firstnameRegister,
        middleNameRegister,
        lastnameRegister,
        phoneRegister,
        commentRegister
    } = form

    return (
        <Box sx={{
            width: 1
        }}>
            <Stack direction="column" spacing={1}>
                <TextField
                    inputRef={emailRegister.ref}
                    error={!!errors?.email?.message}
                    helperText={errors?.email?.message}
                    fullWidth
                    InputLabelProps={{shrink: true}}
                    InputProps={{
                        autoComplete: "off"
                    }}
                    label={tk('Email')}
                    {...emailRegister}
                />

                <TextField
                    inputRef={firstnameRegister.ref}
                    error={!!errors?.firstname?.message}
                    helperText={errors?.firstname?.message}
                    fullWidth
                    InputLabelProps={{shrink: true}}
                    InputProps={{
                        autoComplete: "off"
                    }}
                    label={tk('Firstname')}
                    {...firstnameRegister}
                />

                <TextField
                    inputRef={middleNameRegister.ref}
                    error={!!errors?.middleName?.message}
                    helperText={errors?.middleName?.message}
                    fullWidth
                    InputLabelProps={{shrink: true}}
                    InputProps={{
                        autoComplete: "off"
                    }}
                    label={tk('MiddleName')}
                    {...middleNameRegister}
                />

                <TextField
                    inputRef={lastnameRegister.ref}
                    error={!!errors?.lastname?.message}
                    helperText={errors?.lastname?.message}
                    fullWidth
                    InputLabelProps={{shrink: true}}
                    InputProps={{
                        autoComplete: "off"
                    }}
                    label={tk('Lastname')}
                    {...lastnameRegister}
                />

                <TextField
                    inputRef={phoneRegister.ref}
                    error={!!errors?.phone?.message}
                    helperText={errors?.phone?.message}
                    fullWidth
                    InputLabelProps={{shrink: true}}
                    InputProps={{
                        autoComplete: "off"
                    }}
                    label={tk('Phone')}
                    {...phoneRegister}
                />

                <TextField
                    inputRef={commentRegister.ref}
                    error={!!errors.comment?.message}
                    helperText={errors.comment?.message}
                    fullWidth
                    multiline={true}
                    rows={3}
                    InputLabelProps={{shrink: true}}
                    label={tk('Comment')}
                    {...commentRegister}
                />
            </Stack>
        </Box>
    )
}

export default PartnerContactForm