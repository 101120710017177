import React from 'react';
import {CDAMWMWorksheetTripItemCodeModel} from "crowbar-api";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {useNewAtomWithRef} from "shared/hook/useNewatomWithRef";
import useSaveWorksheetTripItemCode
    from "crowbar-api/hooks/worksheet/worksheet-trip-item-codes/useSaveWorksheetTripItemCode";
import {toastError} from "shared/toast/DefaultToasts";
import {v4 as uuid} from "uuid";
import {Button, Stack, TextField} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

export interface WorksheetTripItemCodeAddFieldProps {
    codes: CDAMWMWorksheetTripItemCodeModel[]
    onItemCodeAdded: () => Promise<void>
}

const WorksheetTripItemCodeAddField = ({codes, onItemCodeAdded}: WorksheetTripItemCodeAddFieldProps) => {
    const tk = useTranslateFunction('Worksheet.Generic.WorksheetTripItemCodes')

    const [input, setInput] = useNewAtomWithRef<string>("")

    const saveItemCode = useSaveWorksheetTripItemCode()

    const onAdd = async () => {
        if (!input || input.length > 50) {
            toastError('Required and Length > 50')
            return
        }
        if (!/^\d+$/.test(input)) {
            toastError('Only numbers allowed.')
            return
        }
        if (codes.find(c => c.itemCode === input) !== undefined) {
            toastError('Already exists.')
            return
        }
        await saveItemCode.mutateAsync({
            id: uuid(),
            itemCode: input
        })
        await onItemCodeAdded()
        setInput("")
    }

    return (
        <Stack direction="row" spacing={1}>
            <TextField
                value={input}
                onChange={(e) => setInput(e.target.value)}
            />
            <Button
                variant="text"
                color="primary"
                onClick={onAdd}
            >
                <AddIcon/>
                {tk('Add')}
            </Button>
        </Stack>
    );
};

export default WorksheetTripItemCodeAddField;