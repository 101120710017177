import React, {useState} from 'react';

import {IconButton, Menu, MenuItem} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import {NavLink} from "react-router-dom";
import {WorksheetHeaderMenuItem} from "modules/worksheet/editor/ui/header/WorksheetEditorHeaderMenu";
import {useWorksheetEditorHeaderInfoAtom} from "modules/worksheet/editor/state/WorksheetEditorHeaderAtoms";

interface WorksheetEditorHeaderMenuButtonProps {
    headerMenuItems: WorksheetHeaderMenuItem[]
}

const WorksheetEditorHeaderMenuButton = ({headerMenuItems}: WorksheetEditorHeaderMenuButtonProps) => {
    const [toolbarOpen, setToolbarOpen] = useState<boolean>(false)
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
    const [, setInfo] = useWorksheetEditorHeaderInfoAtom()
    const onToolbarOpen = (e: React.MouseEvent<HTMLElement>) => {
        setToolbarOpen(true)
        setAnchorEl(e.currentTarget)
    }

    const onToolbarClose = () => {
        setToolbarOpen(false)
        setAnchorEl(null)
        setInfo(prev => {
            return {
                ...prev,
                open: false
            }
        })
    }

    return (
        <>
            <IconButton
                size="large"
                edge="start"
                color="inherit"
                sx={{mr: 2}}
                onClick={onToolbarOpen}
            >
                <MenuIcon/>
            </IconButton>
            <Menu
                id="worksheet-header-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
                keepMounted
                open={toolbarOpen}
                onClose={onToolbarClose}
                sx={{
                    display: {xs: "block", sm: "none"},
                }}
            >
                {headerMenuItems.map((item, i) => {
                    return (
                        <NavLink
                            key={i}
                            onClick={onToolbarClose}
                            style={props => {
                                return {
                                    textDecoration: props.isActive ? "underline" : "none"
                                }
                            }} to={item.to}>
                            <MenuItem
                                title={item.title}
                            >
                                {item.text}
                            </MenuItem>
                        </NavLink>
                    )
                })}
            </Menu>
        </>
    );
};

export default WorksheetEditorHeaderMenuButton;