import React from 'react';
import {
    MechanicListingStateProps,
    useMechanicListingState
} from "modules/worksheet/mechanic/state/useMechanicListingState";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {Button, Stack} from "@mui/material";
import MechanicWorksheetInfQueryLoader from "modules/worksheet/mechanic/results/MechanicWorksheetInfQueryLoader";
import {WorksheetListResultList} from "modules/admin/worksheet/worksheet-list/results/WorksheetListResultList";

export type WorksheetListResultsProps = MechanicListingStateProps & {}
export const WorksheetListResults = ({mechanicStateAtom}: WorksheetListResultsProps) => {
    const tk = useTranslateFunction(`Admin.Worksheet`)

    const {
        worksheetInfQuery,
        anyHasNextPage,
        isLoading,
        isFetching,
        isFetchingNextPage
    } = useMechanicListingState(mechanicStateAtom)

    const loadMore = async () => {
        // parallel execution
        await Promise.all([
            worksheetInfQuery.fetchNextPage(),
        ])
    }

    return (
        <Stack
            direction="column"
            spacing={3}
            width={1}
            alignItems="center"
            justifyContent="center"
            sx={{
                mb: 4
            }}
        >

            <WorksheetListResultList mechanicStateAtom={mechanicStateAtom}/>

            <MechanicWorksheetInfQueryLoader mechanicStateAtom={mechanicStateAtom}/>

            <Button
                variant="contained"
                color="primary"
                onClick={() => loadMore()}
                disabled={isLoading || isFetching || isFetchingNextPage || !anyHasNextPage}
            >
                {tk('LoadMore')}
            </Button>
        </Stack>
    )
}