import {useInfiniteQuery} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {CDAMWMWWorksheetSearchQueryParams, WorksheetApi} from "crowbar-api";
import AxiosCancelTokenHelper from "crowbar-api/AxiosCancelTokenHelper";
import {InfQueryUtils} from "shared/utils/InfQueryUtils";

export const useWorksheetSearchPageInfQuery = (
    {
        lastRowNumber, // lastRowNumber is never read from the params! We use getNextPageParam !
        ...params // all the other params are read (and will re-fetch from page 0)
    }: CDAMWMWWorksheetSearchQueryParams) => {
    return useInfiniteQuery(['worksheet-search-page-by', params], async ({signal, pageParam}) => {
        const response = await crowbarApiFactory(WorksheetApi)
            .searchPageBy({...params, lastRowNumber: pageParam}, AxiosCancelTokenHelper.cancelTokenFrom(signal))
        return response.data ?? []
    }, {
        networkMode: 'online', // will not fire unless we have network
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        cacheTime: 0,// Math.pow(10, 7),
        staleTime: 0, // Math.pow(10, 7),
        getNextPageParam: InfQueryUtils.getNextPageParamForRowNum
    })
}