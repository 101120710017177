import {useQuery} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {CDAMWMServiceCallBlobType, ServiceCallBlobApi} from "crowbar-api";
import AxiosCancelTokenHelper from "crowbar-api/AxiosCancelTokenHelper";

const useServiceCallBlobsByScId = (blobType: CDAMWMServiceCallBlobType, scId: string | null | undefined) => {
    return useQuery(['service-call-blobs-by-sc-id', blobType, scId], async (context) => {
        if (!scId) return []
        const response = await crowbarApiFactory(ServiceCallBlobApi).findBlobsForServiceCallId(
            blobType,
            scId,
            AxiosCancelTokenHelper.cancelTokenFrom(context.signal)
        )

        if (response.status !== 200) {
            throw new Error('Failed to load images for scId: ' + scId)
        }

        return response.data ?? []
    }, {
        networkMode: 'online', // will not fire unless we have network
        placeholderData: [],
        refetchOnMount: false,
        cacheTime: Math.pow(10, 7),
        staleTime: Math.pow(10, 7),
    })
}

export default useServiceCallBlobsByScId