import React from 'react';
import ArticleIcon from '@mui/icons-material/Article';
import BuildIcon from '@mui/icons-material/Build';
import {Stack, ToggleButtonGroup, Typography} from "@mui/material";
import {
    MechanicListingStateProps,
    useMechanicListingState
} from "modules/worksheet/mechanic/state/useMechanicListingState";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {StyledToggleButton} from "modules/worksheet/mechanic/filters/WorksheetStatusFilterButtonGroup";

export type MechanicWorksheetDisplayModeToggleProps = MechanicListingStateProps & {}
const MechanicWorksheetDisplayModeToggle = ({mechanicStateAtom}: MechanicWorksheetDisplayModeToggleProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.Mechanic`)

    const {state, setState} = useMechanicListingState(mechanicStateAtom)

    const btnGroupValue: string[] = []
    const modeIsWorksheet = state.mode === "worksheet"
    const modeIsServiceCall = state.mode === "service-call"
    if (modeIsWorksheet) btnGroupValue.push("worksheet")
    if (modeIsServiceCall) btnGroupValue.push("service-call")

    const onChange = (e: any, value: string[]) => {
        setState(prev => {
            return {
                ...prev,
                mode: value.includes("worksheet") ? "worksheet" : "service-call"
            }
        })
    }

    return (
        <Stack direction="column" spacing={0.5}>

            <Typography variant="caption">
                {tk('Layout')}
            </Typography>

            <ToggleButtonGroup
                exclusive={true}
                value={btnGroupValue}
                onChange={onChange}
            >
                {StyledToggleButton({
                    name: "worksheet",
                    image: (
                        <ArticleIcon sx={{
                            fontSize: "2rem"
                        }}/>
                    ),
                    text: tk('Worksheet'),
                    checked: modeIsWorksheet
                })}
                {StyledToggleButton({
                    name: "service-call",
                    image: (
                        <BuildIcon sx={{
                            fontSize: "2rem"
                        }}/>
                    ),
                    text: tk('ServiceCallShort'),
                    checked: modeIsServiceCall
                })}
            </ToggleButtonGroup>
        </Stack>
    )
}

export default MechanicWorksheetDisplayModeToggle;