import {useWorksheetSaveState} from "modules/worksheet/editor/state/WorksheetSaveAtoms";

import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import {Box, Button, Card, CardActions, CardContent, Grid, Stack, Typography} from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import WEditorPageBasicAddressSelect
    from "modules/worksheet/editor/ui/basic/address-select/WEditorPageBasicAddressSelect";
import {
    WEditPageBasicPartnerAddressButton
} from "modules/worksheet/editor/ui/basic/address-select/WEditPageBasicPartnerAddressButton";
import useSapPartnerAddressSelectValuesAtom
    from "modules/worksheet/editor/ui/basic/address-select/useSapPartnerAddressSelectValuesAtom";
import useSapPartnerAddressesByCardCode from "crowbar-api/hooks/sap/useSapPartnerAddressesByCardCode";
import useTranslateFunction from "shared/language/useTranslateFunction";
import React from "react";
import {toastError, toastWarn} from "shared/toast/DefaultToasts";
import CheckIconButton from "shared/components/buttons/CheckIconButton";

interface WEditorPageBasicAddressBoxProps {
    disabled?: boolean
}

const WEditorPageBasicAddressBox = ({disabled}: WEditorPageBasicAddressBoxProps) => {

    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    const [worksheetSave, setWs] = useWorksheetSaveState()
    const {data: addresses} = useSapPartnerAddressesByCardCode(worksheetSave?.cardCode)

    const {
        selectValues,
        selectedKey,
        setSelectedByKey,
        addNewMod
    } = useSapPartnerAddressSelectValuesAtom(worksheetSave?.cardCode)

    const addressLength = (addresses?.length ?? 0) + (worksheetSave?.modSapPartnerAddresses?.length ?? 0)

    const checkClick = () => {
        if (disabled) {
            return;
        }

        // always allow to uncheck, only run the validation when checking
        if (!worksheetSave?.addressAcknowledged && !selectedKey) {
            toastError(tk('AddressRequired'))
            return
        }

        // warn the user the distanceAck is unchecked
        if (worksheetSave?.distanceAcknowledged) {
            toastWarn(tk('TripAckUnchecked'), {toastId: 'TripAckUnchecked'})
        }

        // Set the selected again
        // use case: when the address is auto selected and the user just clicks on Ack
        // the selected value would not be copied to the worksheet values (no onChange on the select)
        if (selectedKey) {
            setSelectedByKey(selectedKey)
        }

        setWs(prev => {
            return {
                ...prev,
                addressAcknowledged: !prev?.addressAcknowledged,
                // changing the addressAck unchecks the tripAck
                distanceAcknowledged: false
            }
        })
    }

    return (
        <Card variant="outlined" sx={{height: "100%"}}>
            <CardContent>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h6" fontWeight="bold">
                        {tk('BasicAddressTitle')}
                    </Typography>
                    <Box>
                        <Typography variant="h6">

                            <LocalShippingIcon sx={{
                                fontSize: "inherit"
                            }}/>
                            {addressLength > 0 && (
                                <Box component="span" sx={{color: 'red'}}>
                                    <sup><small>{addressLength}</small></sup>
                                </Box>
                            )}
                        </Typography>
                    </Box>
                </Stack>

                <WEditorPageBasicAddressSelect
                    disabled={disabled || !!worksheetSave?.addressAcknowledged}
                    selectedKey={selectedKey}
                    selectValues={selectValues}
                    setSelectedByKey={setSelectedByKey}
                />
            </CardContent>
            <CardActions>
                <Grid container justifyContent="space-between">
                    <Stack direction="row" spacing={1}>
                        <WEditPageBasicPartnerAddressButton

                            mode="new"
                            renderButton={onClick => (
                                <Button variant="text" size="small"
                                        disabled={!!worksheetSave?.addressAcknowledged || disabled}
                                        onClick={onClick}
                                        startIcon={<AddCircleIcon/>}>
                                    {tk('AddNew')}
                                </Button>
                            )}
                            selectedKey={selectedKey}
                            addNewMod={addNewMod}
                        />
                        <WEditPageBasicPartnerAddressButton

                            mode="edit"
                            renderButton={(onClick, renderDisabled) => (
                                <Button variant="text" size="small" onClick={onClick} startIcon={<EditIcon/>}
                                        disabled={disabled || !!worksheetSave?.addressAcknowledged || renderDisabled}>
                                    {tk('Edit')}
                                </Button>
                            )}
                            selectedKey={selectedKey}
                            addNewMod={addNewMod}
                        />
                    </Stack>

                    <CheckIconButton
                        onClick={checkClick}
                        checked={!!worksheetSave?.addressAcknowledged}
                        sx={{
                            display: "flex",
                            justifyContent: "right",
                        }}
                        checkCircleIconProps={{
                            sx: {
                                cursor: disabled ? "inherit" : "pointer",
                                fontSize: "30pt",
                            }
                        }}
                    />
                </Grid>
            </CardActions>
        </Card>
    )
}

export default WEditorPageBasicAddressBox