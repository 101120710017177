import {useQuery} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {ServiceCallBlobApi} from "crowbar-api";
import ServiceCallBlobTypeMap from "crowbar-api/enum-map/ServiceCallBlobTypeMap";
import AxiosCancelTokenHelper from "crowbar-api/AxiosCancelTokenHelper";
import {UseQueryOptionParams} from "crowbar-api/util/UseQueryOptionParams";

export const useSapEquipmentImageBlobsByEquipmentIdsOrModIds = (
    equipmentIds: number[],
    modEquipmentIds: string[],
    extraOptions?: UseQueryOptionParams
) => {
    return useQuery(['sap-equipment-image-blobs-by-eqIds-and-ModIds', equipmentIds, modEquipmentIds], async (context) => {
        if ((!equipmentIds && !modEquipmentIds) || (equipmentIds.length < 1 && modEquipmentIds.length < 1)) return []
        const response = await crowbarApiFactory(ServiceCallBlobApi).findBlobsForSapEquipmentByInsIdsOrModIds({
            blobType: ServiceCallBlobTypeMap.photoRule,
            equipmentIds: equipmentIds,
            modEquipmentIds: modEquipmentIds
        }, AxiosCancelTokenHelper.cancelTokenFrom(context.signal))

        return response.data ?? []
    }, {
        networkMode: 'online', // will not fire unless we have network
        placeholderData: [],
        refetchOnMount: false,
        cacheTime: Math.pow(10, 7),
        staleTime: Math.pow(10, 7),
        ...extraOptions
    })
}