import React from 'react';
import {useEquipmentSearchState} from "modules/equipment/equipment-search/useEquipmentSearchState";
import {Grid} from "@mui/material";
import ManufacturerSelect from "modules/equipment/equipment-search/filters/ManufacturerSelect";
import VehicleTypeSelect from "modules/equipment/equipment-search/filters/VehicleTypeSelect";
import {EquipmentSearchStateProps} from "modules/equipment/equipment-search/EquipmentSearch";

export type SelectedEquipmentProps = EquipmentSearchStateProps & {}

export type OnEquipmentSelected = (source: 'sap' | 'mod', id: string) => void

const SelectedEquipment = ({searchStateAtom}: SelectedEquipmentProps) => {

    const {
        selectedSapEquipmentId,
        selectedModSapEquipmentId,
        setState
    } = useEquipmentSearchState(searchStateAtom)

    const onEquipmentSelected: OnEquipmentSelected = (source: 'sap' | 'mod', id: string) => {
        setState(prev => {
            return {
                ...prev,
                selectedSapEquipmentId: source === 'sap' ? id : prev.selectedSapEquipmentId,
                selectedModSapEquipmentId: source === 'mod' ? id : prev.selectedModSapEquipmentId
            }
        })
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
                <ManufacturerSelect searchStateAtom={searchStateAtom}/>
            </Grid>
            <Grid item xs={12} md={6}>
                <VehicleTypeSelect searchStateAtom={searchStateAtom}/>
            </Grid>
        </Grid>
    );
};

export default SelectedEquipment;