export const i18n_worksheet_generic_hu = {
    "SelectedDataSource": {
        "NotSet": "Nem beállított",
        "SapMasterData": "Törzsadat",
        "ModSap": "Sap módosítás",
        "SapSalesOrder": "Vevői rendelés",
        "OwnPremise": "Saját telephely",
        "Missing": "N/A"
    },
    "WorksheetStatus": {
        "Draft": "Piszkozat",
        "Final": "Aláírandó",
        "FinalSigned": "Befejezett (aláírva)",
        "FinalSignSkipped": "Befejezett (aláírás kihagyva)",
        "Evaluated": "Kiértékelt",
        "Missing": "N/A"
    },
    "WorksheetTripType": {
        "NotSet": "Nem beállított",
        "SalesOrder": "Vevői rendelés",
        "PartnerPreset": "Vevői beállítás",
        "AtWorkshop": "Műhely",
        "Missing": "N/A",
    },
    "ServiceCallStatus": {
        "Draft": "Piszkozat",
        "Final": "Végleges",
        "Missing": "N/A"
    },
    "ServiceCallOperatingHoursType": {
        "Provided": "Megadott",
        "WontProvideData": "Nem adom meg (-500 Ft)",
        "CantRead": "Nem olvasható",
        "CounterNotWorking": "Számláló nem működik",
        "Missing": "N/A"
    },
    "ServiceCallItemRowStatus": {
        "Default": "Alapértelmezett",
        "NotInstalled": "Nem beszerelt",
        "Installed": "Beszerelt",
        "Missing": "N/A"
    },
    "ServiceCallItemRowWarningType": {
        "None": "Nincs",
        "IncludedInDifferentWorksheet": "Termék beszerelésre került már másik munkalapon is!",
        "TooManyInstalledQuantity": "A beszerelt mennyiség meghaladja a vevői rendelés mennyiségét.",
        "RemainingInstalledQuantity": "Nem került minden termék beszerelésre!",
        "Missing": "N/A"
    },
    "ServiceCallSelectedSource": {
        "NotSet": "Nem beállított",
        "SapSalesOrder": "Vevői rendelés",
        "ManuallyAdded": "Kézzel hozzáadott",
        "Empty": "Üres",
        "Missing": "N/A"
    },
    "ServiceCallItemRowSelectedSourceMap": {
        "NotSet": "Nem beállított",
        "SapMasterData": "Törzsadat",
        "SapSalesOrder": "Vevői rendelés",
        "ManuallyEntered": "Kézzel beírt",
        "Missing": "N/A"
    },
    "ModSapStatus": {
        "Suggested": "Ajánlott",
        "Rejected": "Visszautasított",
        "Accepted": "Elfogadott",
        "Missing": "N/A"
    },
    "ModSapType": {
        "Create": "Létrehozva",
        "Update": "Módosítva",
        "Missing": "N/A"
    },
    "WorksheetSapSalesOrderRowType": {
        "Item": "Termék",
        "Text": "Szöveg",
        "Missing": "N/A"
    },
    "ServiceCallBlobType": {
        "NotSet": "Nem beállított",
        "PhotoRule": "Fotózási szabály",
        "IssuePhoto": "Hiba képe",
    },
    "WorksheetSaveErrorCode": {
        "0": "Ismeretlen hiba.",
        "800": "Az előző verzió a szerveren eltér a most mentettől.",
        "801": "Hiányzó előző verzió érték. Nem lehet menteni.",

        "900": "A művelet meg lett szakítva.",
        "901": "Nem sikerült menteni a módosítási naplót.",

        "1000": "Hiányzó vagy érvénytelen munkalap azonosító.",
        "1001": "Hiányzó vagy érvénytelen szerviz hívás azonosító.",
        "1002": "Hiányzó vagy érvénytelen szerviz hívás sor azonosító.",
        "1003": "Hiányzó vagy érvénytelen vevői rendelés azonosító.",
        "1004": "Hiányzó vagy érvénytelen vevői rendelés sor azonosító.",

        "1011": "Hiányzó vagy érvénytelen mod partner azonosító.",
        "1012": "Hiányzó vagy érvénytelen mod cím azonosító.",
        "1013": "Hiányzó vagy érvénytelen mod kapcsolattartó azonosító.",
        "1014": "Hiányzó vagy érvénytelen mod gép azonosító.",

        "4000": "Érvénytelen státuszmódosítás.",
    },
    "FormPrepare": {
        "Title": "Új munkalap",
        "Source": "Forrás kiválaszása:",
        "SourceCustomer": "Vevő",
        "SourceSalesOrder": "Vevői rendelés",
        "SearchSalesOrders": "Rendelés száma, ...",
        "SearchCustomers": "Vevő kódja, neve...",
        "SelectedCustomerFilter": "Kiválasztott vevő: ",
        "SelectedCustomerFilterEmpty": "Válasszon ki egy vevőt.",
        "LoadMore": "Továbbiak betöltése",
        "ShowAll": "Összes mutatása",
        "Hide": "Elrejtés",
        "SelectBtn": "Kiválaszt",
        "CardEquipmentTitle": "A kiválasztott gép:",
        "CardEquipmentNotFound": "Nincs gép megjelölve a rendeléshez.",
        "UserIdNullError": "Felhasználó hitelesítési hiba. Nem lehet kiolvasni a felhasználói azonosítót."
    },
    "WorksheetTimeItemCodes": {
        "ListTitle": "Munkalap munkaidő termékkódok",
        "Remove": "Törlés",
        "Create": "Létrehozva",
        "Update": "Módosítva",
        "Add": "Hozzáadás",
    },
    "WorksheetTripItemCodes": {
        "ListTitle": "Munkalap kiszállási díj termékkódok",
        "Remove": "Törlés",
        "Create": "Létrehozva",
        "Update": "Módosítva",
        "Add": "Hozzáadás",
    },
    "NewWorksheet": {
        "Yes": "Igen",
        "No": "Nem",
        "Open": "Nyitott",
        "Closed": "Zárt",
        "ShowMore": "Továbbiak mutatása...",
        "NewWorksheet": "Új munkalap",
        "CustomersLoading": "Ügyfelek betöltése...",
        "EquipmentsLoading": "Berendezések betöltése...",
        "SalesOrdersLoading": "Vevői rendelések betöltése...",
        "Customer": "Vevő",
        "SalesOrder": "Vevői rendelés",
        "Equipment": "Berendezés",
        "NumOfEquipments": "Berendezései száma",
        "DocNum": "Bizonylatszám",
        "OpenRowCount": "Nyitott sor",
        "ClosedRowCount": "Zárt sor",
        "Date": "Dátum",
        "NoSalesOrder": "Nem található vevői rendelés.",
        "WithEquipment": "Berendezés?",
        "SalesPerson": "Értékesítő",
        "InternalSn": "Házi szám",
        "ShippingAddress": "Szállítási cím",
        "BillingAddress": "Számlázási cím",
        "ItemCode": "Cikkszám",
        "Description": "Leírás",
        "Quantity": "Mennyiség",
        "OpenSalesOrders": "Nyitott vevői rendelések",
        "NoSalesOrders": "Nem található vevői rendelés.",
        "NumberOfServiceCalls": "Szervizhívások száma",
        "LoadMore": "Továbbiak betöltése...",
        "AtLeast3Char": "Adjon meg legalább három karaktert.",
        "SearchCustomers": "Vevők keresése",
        "SearchSalesOrders": "Vevői rendelések keresése",
        "SearchEquipments": "Berendezések keresése",
        "IncludeClosedSalesOrders": "Zárt vevői rendelések keresése",
        "SalesOrdersFrom": "Rendelés ettől",
        "SalesOrdersTo": "Rendelés eddig",
        "CompletionRateLabel": "Befejezettségi mutató",
        "NewCustomer": "Új vevő",
        "PartnerName": "Partner neve",
        "VatLocal": "Helyi adószám",
        "VatInternational": "Nemzetközi adószám",
        "Comment": "Megjegyzés",
        "Add": "Hozzáadás",
        "Cancel": "Mégsem",
        "WorksheetForNewCustomer": "Munkalap új vevővel",
        "WorksheetForPhoenix": "Frekvent munkalap",
        "TableRowNumber": "A táblázat sorainak száma",
        "OrderBy": "Rendezés",
        "ASC": "Növekvő",
        "DESC": "Csökkenő",
        "WorksheetCreatedSilent": "Munkalap sikeresen létrehozva: ",
        "CustomersIsLoading": "Vevők betöltése...",
        "SalesOrdersIsLoading": "Vevői rendelések betöltése...",
        "EquipmentsIsLoading": "Gépek betöltése...",
        "Customers": "Vevők",
        "SalesOrders": "Vevői rendelések",
        "Equipments": "Gépek",
    },
    "Mechanic": {
        "Draft": "Piszkozat",
        "ToSign": "Aláírandó",
        "Finished": "Befejezett",
        "Evaluated": "Kiértékelt",
        "YourWorksheets": "Munkalapjaim",
        "NewWorksheet": "Új munkalap",
        "NoServiceCall": "Nem található szervizhívás",
        "ServiceCall": "Szervizhívás",
        "ServiceCallShort": "Sz.hívás",
        "Worksheet": "Munkalap",
        "OpenWorksheet": "Megnyitás",
        "SalesOrder": "Vevői rendelés",
        "WarnMultipleServiceCalls": "A munkalap több szervizhívást is tartalmaz!",
        "ItemCode": "Cikkszám",
        "ItemName": "Leírás",
        "NoItemRows": "Nincs termék sor.",
        "OrderByField": "Rendezés",
        "ModificationDates": "Módosítás ideje",
        "SerialId": "Munkalapszám",
        "OrderByDirection": "Sorrend",
        "ASC": "Legrégebbi elöl",
        "DESC": "Legújabb elöl",
        "InstalledQuantity": "Beszerelve",
        "WorksheetLoading": "Munkalapok betöltése...",
        "LoadMore": "Továbbiak betöltése",
        "Layout": "Elrendezés",
        "WorksheetStatus": "Munkalap státusza",
        "MenuCatalogue": "Darabjegyzék",
        "Description": "Leírás",
        "FaultDescription": "Hibaleírás",
        "MenuPhoto": "Fotók",
        "Owner": "Tulajdonos",
        "PdfDownload": "Pdf letöltése",
        "DateFilter": "Szűrés munkavégzés ideje szerint",
        "CreatedAt": "Létrehozás ideje",
        "UpdatedAt": "Utolsó módosítás ideje",
        "DeletedWorksheet": "Törölt munkalap!",
        "LastStatusChange": "Utolsó státusz módosítás",
        "SkipCustomerEmail": "Ügyfél értesítő letiltva!",
    },
    "Editor": {
        "Previous": "Előző",
        "Next": "Következő",
        "Yes": "Igen",
        "No": "Nem",
        "Save": "Mentés",
        "Add": "Hozzáadás",
        "Cancel": "Mégsem",
        "New": "Új",
        "LoadMore": "Továbbiak betöltése...",
        "Edited": "Szerkesztett",
        "Empty": "Üres",
        "WithoutEquipment": "Gép nélkül",
        "WithEquipment": "Géppel",
        "WithNewEquipment": "Új géppel",
        "Subject": "Tárgy",
        "Equipment": "Gép",
        "Equipments": "Gépek",
        "NewEquipment": "Új gép",
        "IncludeSalesOrderCheckbox": "Adja hozzá a vevői rendelés sorait.",
        "Source": "Forrás",
        "MenuBasic": "Alapadatok",
        "MenuBasicTitle": "Itt változtass partner címeket, kiszállási információkat itt.",
        "MenuEquipments": "Gépek",
        "MenuEquipmentsTitle": "Szerviz hívások kezelése, gépeik.",
        "MenuCatalogue": "Darabjegyzék",
        "MenuCatalogueTitle": "Szerviz hívás termékei, mennyiségek.",
        "MenuPhoto": "Fotók",
        "MenuPhotoTitle": "Fényképek készítése a géphez.",
        "MenuDescription": "Leírás",
        "MenuDescriptionTitle": "Leírás és hibák.",
        "MenuFeedback": "Értékelés",
        "MenuFeedbackTitle": "Értékelés és visszajelzés",
        "TextSelectedEquipment": "Kiválasztott gép:",
        "ServiceCallNoEquipment": "Gép nélküli szerviz hívás",
        "MissingSelectedEquipment": "Nincs kiválasztott gép.",
        "HeaderSaveBtn": "Véglegesítés",
        "NewServiceCall": "Új szervizhívás hozzáadása.",
        "NewServiceCallPlusTitle": "Új szervizhívás hozzáadása.",
        "NewServiceCallDialogTitle": "Új szervizhívás hozzáadása",
        "NewServiceCallDialogEmptyText": "Üres szervizhívás hozzáadásakor nem lehet gép specifikus beállításokat hozzáadni.",
        "SelectSinglePartnerEquipmentPlaceholder": "Gép adataival keresés...",
        "EquipmentAlreadyAdded": "A gép már hozzá lett adva a munkalaphoz.",
        "Manufacturer": "Gyártó",
        "VehicleType": "Típus",
        "ManufacturerSn": "Gyártási szám",
        "InternalSn": "Házi azonosító",
        "YearOfManufacture": "Gyártási év",
        "Comment": "Megjegyzés",
        "ChangeServiceCallButtonTitle": "Itt tudja változtatni a kiválasztott szervizhívást.",
        "NoItemRows": "Nincs termék sor hozzáadva a szervizhíváshoz.",
        "HideCompletedRows": "Befejezett sorok elrejtése.",
        "InOrder": "Rendelésben",
        "Installed": "Beszerelve",
        "Available": "Elérhető",
        "ServiceCall": "Szervizhívás",
        "TotalInstalled": "Összesen beszerelt",
        "AddNew": "Új hozzáadása",
        "Edit": "Szerkesztés",
        "Address": "Cím",
        "ContactPerson": "Kapcsolattartó",
        "SalesPerson": "Értékesítő",
        "Order": "Rendelés",
        "SalesOrder": "Vevői rendelés",
        "OpenMap": "A kijelölt cím megnyitása térképpel.",
        "CallPhone": "A kijelölt telefonszám hívása.",
        "TripCost": "Kiszállási díj",
        "Occasion": "alkalom",
        "ZipCode": "Irányítószám",
        "City": "Város",
        "StreetLine1": "Cím",
        "StreetNo": "Házszám",
        "NewPartnerAddress": "Új cím",
        "Email": "E-mail",
        "Firstname": "Keresztnév",
        "MiddleName": "Második név",
        "Lastname": "Vezetéknév",
        "Phone": "Telefonszám",
        "Contact": "Kapcsolattartó",
        "NewPartnerContact": "Új kapcsolattartó",
        "ItemCode": "Saját cikkszám",
        "ItemName": "Alkatrésznév",
        "SuppCatNum": "Gyártói katalógusszám",
        "Substitute": "Kereskedői katalógusszám",
        "OnlyInStock": "Csak raktáron lévő vagy nem készletezhető",
        "OnlyFrequentlyUsed": "Csak gyakran használt termék",
        "ItemAlreadyAdded": "A cikk már megtalálható a termékek között.",
        "Quantity": "Mennyiség",
        "Quantities": "Mennyiségek",
        "Delete": "Törlés",
        "PhotoWithHelp": "Fényképezés szabály szerint",
        "StandalonePhoto": "Képek feltöltése",
        "NoSelectedServiceCall": "Nincs szervizhívás kiválasztva.",
        "UploadedNumberOfFiles": "Sikeresen feltöltött {{counter}} fájlt.",
        "PhotoNoRule": "Szabályhoz nem köthető",
        "Replace": "Csere",
        "NoRuleForManufacturer": "Nincs szabály a gyártóhoz.",
        "Rule": "Szabály",
        "Finish": "Befejezés",
        "Step": "Lépés",
        "Picture": "Kép",
        "Back": "Vissza",
        "TakePhoto": "Kép készítése",
        "Transcribing": "Leiratozás.",
        "Distribute": "Megosztás",
        "Mechanic": "Szerelő",
        "MissingMechanic": "Több munkaidő esetén a szerelő kiválasztása kötelező!",
        "TaskDescriptionLabel": "Elvégzett munka leírása",
        "FaultDescriptionLabel": "Hibák leírása",
        "FaultDescription": "Hibaleírás",
        "OperatingHours": "Üzemóra",
        "ModifiedEquipment": "Szerkesztett gépadat.",
        "AddedHere": "Itt hozzáadott.",
        "Saved": "Mentve",
        "WorksheetSaved": "Munkalap mentése sikeres ({{serialId}})",
        "OverwritePreviousVersion": "Előző verzió felülírása",
        "OverwritePreviousVersionText": "A szerveren található verzió frisebb, mint a jelenleg ismert előző verzió. Ez például akkor fordulhat elő, ha a munkalapot időközben más eszköz is menti. Szeretné felülírni? (A mégsem gomb megnyomásával nem kerül mentésre a munkalap!)",
        "Description": "Leírás",
        "Select": "Kiválaszt",
        "BasicAddressTitle": "Munkavégzés helye",
        "BasicDurationTitle": "Munkavégzés ideje",
        "BasicContactTitle": "Kapcsolattartó",
        "BasicSalesPersonTitle": "Értékesítő",
        "BasicTripTitle": "Kiszállási díj",
        "TripRequired": "A kiszállási díj kitöltése kötelező!",
        "AddressRequired": "A munkavégzés helyének megadása kötelező!",
        "NotValidWorksheet": "A munkalap nem véglegesíthető. Nem minden követelmény teljesített.",
        "Signature": "Aláírás",
        "WorkDate": "Munkavégzés napja",
        "Customer": "Vevő",
        "CustomerCode": "Vevőkód",
        "NumberOfServiceCalls": "Szervizhívások száma",
        "HasFaultDescriptions": "Van hibaleírás?",
        "SkipSignature": "Kihagyás",
        "MissingServiceCall": "Nincs szervizhívás a géphez.",
        "ItemRows": "Termék sorok",
        "NoFaultDescription": "Nincs hibaleírás",
        "InSalesOrder": "Vevői rendelésben",
        "SignatureNavigationHelp": "Használhatja a termékeknél megjelenő nyilakat vagy kattinthat a szervizhívások táblázatban is egy sorra a szervizhívások közötti navigáláshoz.",
        "Selected": "Kiválasztott",
        "StartDateLabel": "Kezdés napja",
        "EndDateLabel": "Befejezés napja",
        "Other": "Egyéb",
        "NewPhotoForStep": "Kép hozzáadása",
        "OnSalesOrder": "Vevői rendelésben",
        "FeedbackNumLabel": "Mennyire elégedett mint szerelő a munka alakulásával? Kérem értékelje.",
        "FeedbackTextLabel": "A részletesebb visszajelzéseit kérem írja le.",
        "FeedbackIsCompletedTrue": "Befejezett munka",
        "FeedbackIsCompletedFalse": "Nem befejezett munka",
        "CloseAndSave": "Mentés és bezárás",
        "CloseWithoutSave": "Bezárás mentés nélkül",
        "WorksheetCloseDescription": "A munkalap módosításai még nem lettek beküldve a szerverre. Biztosan be szeretné zárni a munkalapot?",
        "WorksheetCloseDescription2": "(Minden munkalap módosítás azonnal mentésre kerül az Ön eszközére, amit később megnyitáskor tud folytatni, viszont még nem véglegesítettük a munkalapot.)",
        "WorksheetCloseDescription3": "Válasszon a bezárás mentéssel vagy mentés nélkül opciók között.",
        "WorksheetCloseTitle": "Munkalap bezárása",
        "SaveError": "Sikertelen mentés.",
        "Brand": "Márka (SWW)",
        "Items": "Cikkek",
        "ManuallyTyped": "Kézzel beírt",
        "ManuallyTypedLabel": "Kérem adja meg a termék nevét",
        "ManuallyTypedUnitMsr": "Mennyiségi egység",
        "ToSign": "Aláírandó",
        "AddToSignCart": "Aláírni",
        "RemoveFromSignCart": "Kosárban",
        "SignAdded": "Aláírásra kosárba rakva.",
        "SignRemoved": "Aláírás kosárból eltávolítva.",
        "ClearAll": "Összes törlése",
        "SignAll": "Összes aláírása",
        "Worksheets": "Munkalapok",
        "NumAtCard": "Vevői h.szám",
        "Name": "Név",
        "TripAckUnchecked": "A kiszállási díjat újra meg kell így erősítenie!",
        "All": "Összes",
        "ShowAll": "Összes mutatása",
        "DisplayHelp": "Szabály megjelenítése",
        "UploadCancelled": "Feltöltés megszakítva",
        "DifferentServerVersion": "A munkalap módosításai még nem kerültek mentésre, de a szerveren már közben történtek módosítások.",
        "DifferentServerVersionWarningPrefix": "A szerveren eltérő munkalap módosítások találhatóak (másik eszközön is lett módosítva). Írja felül a jelenlegi munkalap mentésével vagy ",
        "DifferentServerVersionWarningLink": "töltse be a szerver változatát ",
        "DifferentServerVersionWarningSuffix": "(az aktuális módosítások elvesznek).",
        "DifferentPreviousVersionError": "Eltérő előző változat azonosító.",
        "WorksheetDelete": "Munkalap törlése",
        "FailedToLoadWorksheet": "Nem sikerült betölteni az azonosító szerint a munkalapot. A kért munkalap azonosítója rossz, vagy a munkalapot törölték és már nem található meg.",
        "Error": "Hiba",
        "EditSubject": "Tárgy szerkesztése",
        "RequiredField": "Kötelező mező",
        "EditDisabledMsg": "Befejezett munkalap módosítása már nem lehetséges!",
        "EditDisabledMsgEvaluated": "Kiértékelt munkalap módosítása és aláírása már nem lehetséges!",
        "PdfDownload": "Pdf letöltése",
        "EquipmentImages": "Csatolt képek",
        "SignatureRequired": "Aláírás megadása kötelező!",
        "WithSignature": "Aláírás csatolása",
        "FinishWithSignature": "Befejezés",
        "FinishWithoutSignature": "Befejezés (aláírás kihagyása)",
        "DeletedWorksheet": "Törölt munkalap!",
        "ClosedSalesOrderRow": "Kiértékelt munkalapon már szerepel a cikk!",
        "NoTimeRowsFoundTitle": "Hiányzó munkaidő",
        "NoTimeRowsFoundMessage": "Nem adott hozzá munkaidő sort a darabjegyzékhez. Biztosan folytatja?",
        "WorksheetForceReloadedFromServer": "A munkalap újra be lett töltve a szerverről, mivel időközben módosítva lett.",
    }
}