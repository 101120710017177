import {useCallback} from "react";
import {useWorksheetCallTypeFindAll} from "crowbar-api/hooks/worksheet/call-type/useWorksheetCallTypeFindAll";

/**
 * Decides whether the task description is required or not.
 * Uses the WorksheetCallTypes master data.
 * The task description is not required, if the currently selected serviceCall.callTypeId's
 * callType.substitutesTaskDescription is true.
 *
 * If the master data is loading or failed, we return true.
 * If the id parameter is null or undefined, we return true
 *
 * @return true if required, false if not.
 */
export const useIsTaskDescriptionRequiredFn = () => {
    const callTypesQuery = useWorksheetCallTypeFindAll();
    return useCallback((currentCallTypeId: number | null | undefined) => {
        const callTypes = callTypesQuery.data
        if (!callTypes) return true

        const found = callTypes.find(ct => ct.id === currentCallTypeId)

        return !found || !found.substitutesTaskDescription
    }, [callTypesQuery.data])
}