import React from 'react';
import {
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Typography
} from "@mui/material";
import useTranslateFunction from "shared/language/useTranslateFunction";
import useAllPhotoRulesAndManufacturers from "crowbar-api/hooks/photo-rule/useAllPhotoRulesAndManufacturers";

export interface PhotoStepRuleSelectProps {
    // rules: CDAMPMPhotoRuleAndManufacturersQuery[]
    selectedManufacturerCode: string | null | undefined
    selectedRuleId: string | null | undefined
    onChange: (newRuleId: string | null | undefined) => void
}

const PhotoStepRuleSelect = ({selectedManufacturerCode, selectedRuleId, onChange}: PhotoStepRuleSelectProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    const onSelectChange = (e: SelectChangeEvent) => {
        onChange(e?.target?.value)
    }

    const photoRulesQuery = useAllPhotoRulesAndManufacturers()

    const filteredRules = (photoRulesQuery?.data ?? [])
        .filter(r => selectedManufacturerCode === "0" || r.manufacturers?.find(m => m.sapManufacturerCode === selectedManufacturerCode) !== undefined)

    if (photoRulesQuery.isLoading || photoRulesQuery.isFetching) {
        return (
            <CircularProgress/>
        )
    }

    return (
        filteredRules?.length > 0 ? (
            <FormControl fullWidth>
                <InputLabel>{tk('Rule')}</InputLabel>
                <Select variant="outlined"
                        fullWidth
                        value={selectedRuleId ?? ""}
                        required
                        label={tk("Rule")}
                        onChange={onSelectChange}
                >
                    {filteredRules.map(rule => (
                        <MenuItem key={rule.id} value={rule.id}>{rule.title}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        ) : (
            <Typography>{tk('NoRuleForManufacturer')}</Typography>
        )
    )
}

export default PhotoStepRuleSelect;