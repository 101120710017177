import {useAtom} from "jotai";
import {msalTeamsAuthenticationHappenedAtom, msalTeamsInitializationHappenedAtom} from "App";
import {app, HostClientType} from "@microsoft/teams-js";
import {useCallback} from "react";

export interface TeamsHostInformation {
    initialized: boolean
    /**
     * Checks the MS Teams platform information.
     * Only appropriate in case of teamsInitialized true.
     * @return true, if the platform is android or ios. false otherwise.
     */
    androidOrIosTeamsPlatform: () => Promise<boolean>
}

const useTeamsHostInformation = (): TeamsHostInformation => {
    const [teamsAuthHappened] = useAtom(msalTeamsAuthenticationHappenedAtom)
    const [teamsInitHappened] = useAtom(msalTeamsInitializationHappenedAtom)
    // const [context] = useAtom(msalTeamsContextAtom, DefaultCrowbarContext)

    const platformFn = useCallback(async () => {
        let context: app.Context;
        try {
            context = await app.getContext();
        } catch {
            return false
        }
        if (!context) return false

        const clientType = context.app.host.clientType
        return clientType === HostClientType.android
            || clientType === HostClientType.ios
            || clientType === HostClientType.ipados
            || clientType === HostClientType.teamsRoomsAndroid
    }, [])

    return {
        initialized: teamsInitHappened === true && teamsAuthHappened,
        androidOrIosTeamsPlatform: platformFn
    }
}

export default useTeamsHostInformation