import React, {useEffect} from 'react';
import {useWarmupQuery} from "crowbar-api/hooks/common/useWarmupQuery";
import {CircularProgress, Container, Grid, Stack, Typography} from "@mui/material";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {useBuildInfo} from "modules/generic/query-warmup/useBuildInfo";

export interface QueryWarmupProps {
    children?: JSX.Element | JSX.Element[]
}

export const QueryWarmup = ({children}: QueryWarmupProps) => {
    const tk = useTranslateFunction("Generic")
    const warmUpQuery = useWarmupQuery()

    const {setBuildTime, setEnvironmentName} = useBuildInfo()

    useEffect(() => {
        setBuildTime(warmUpQuery.data?.buildTime ?? undefined)
        setEnvironmentName(warmUpQuery.data?.environmentName ?? undefined)
    }, [setBuildTime, setEnvironmentName, warmUpQuery.data?.buildTime, warmUpQuery.data?.environmentName])

    if (warmUpQuery.isLoading || warmUpQuery.isError) {
        return (
            <Container>
                <Grid container spacing={1}>
                    <Grid item xs={12} sx={{
                        display: "flex",
                        justifyContent: "center"
                    }}>
                        <Stack direction="column" spacing={1}>
                            {warmUpQuery.isLoading && (
                                <>
                                    <CircularProgress/>
                                    <Typography>{tk('Loading')}</Typography>
                                </>
                            )}
                            {warmUpQuery.isError && (
                                <>
                                    <Typography variant="h2" color="error">{tk('Error')}</Typography>
                                    <Typography>
                                        <pre>{JSON.stringify(warmUpQuery.error, null, 2)}</pre>
                                    </Typography>
                                </>
                            )}
                        </Stack>
                    </Grid>

                </Grid>
            </Container>

        )
    } else {
        return (
            <>
                {children}
            </>
        )
    }
}