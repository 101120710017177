import React from 'react';
import {useTeamsInitialize} from "shared/teams/TeamsInitialize";
import {useCrowbarUserAutoRegister} from "shared/hook/useCrowbarUserAutoRegister";
import {useAtom} from "jotai";
import {appIsInitializedAtom} from "App";
import msalAuth from "shared/msal/MsalAuth";
import LoginScreen from "shared/authorization/LoginScreen";
import {MsalProvider} from '@azure/msal-react';
import {Route, Routes} from "react-router-dom";

export interface CrowbarTeamsMsalAuthenticationProps {
    children: JSX.Element
}

const CrowbarTeamsMsalAuthentication = ({children}: CrowbarTeamsMsalAuthenticationProps) => {

    const [initialized] = useAtom(appIsInitializedAtom)

    // Init Teams
    useTeamsInitialize()

    // Auto register users
    useCrowbarUserAutoRegister()

    if (!initialized && !msalAuth.isLoggedIn()) {
        return <LoginScreen/>
    }

    return (
        <Routes>
            <Route path="/blank" element={
                <p>This is an empty page used for Microsoft authentication as a redirect uri. You should not see this
                    page. Please contact the administrator.</p>
            }/>
            <Route path="*" element={
                <MsalProvider instance={msalAuth.publicClientApplication()}>
                    {children}
                </MsalProvider>
            }/>
        </Routes>
    )
}

export default CrowbarTeamsMsalAuthentication;