import {useMutation} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {CDAMWMEPartnerContactChangeWorksheetEvalUpdate, WorksheetEvalApi} from "crowbar-api";
import {AxiosRequestConfig} from "axios";

export const useWorksheetEvalUpdatePartnerContact = () => {
    return useMutation(async (p: CDAMWMEPartnerContactChangeWorksheetEvalUpdate, options?: AxiosRequestConfig) => {
        await crowbarApiFactory(WorksheetEvalApi)
            .updatePartnerContact(p, options)
    })
}