import React from 'react';
import {Typography, TypographyProps} from "@mui/material";
import {StringUtils} from "shared/utils/StringUtils";

export type VehicleTypeDisplayFnProps = {
    manufacturer?: string | undefined | null
    vehicleType?: string | undefined | null

    modManufacturer?: string | undefined | null
    modVehicleType?: string | undefined | null
}

export type VehicleTypeDisplayProps = TypographyProps & VehicleTypeDisplayFnProps & {}

const typeContainsManufacturer = (manufacturer?: string | undefined | null, vehicleType?: string | undefined | null) => {
    return (!vehicleType ? false : !manufacturer ? true
        : vehicleType.toLowerCase().indexOf((manufacturer ?? "").toLowerCase()) > -1)
}

export const vehicleTypeDisplayFn = ({
                                         manufacturer,
                                         vehicleType,
                                         modManufacturer,
                                         modVehicleType
                                     }: VehicleTypeDisplayFnProps) => {
    const hasVehicleType = StringUtils.notUndefinedNullAndEmpty(vehicleType)
    const hasModVehicleType = StringUtils.notUndefinedNullAndEmpty(modVehicleType)
    const scContains = typeContainsManufacturer(manufacturer, vehicleType)
    const modContains = typeContainsManufacturer(modManufacturer, modVehicleType)

    const textParts: string[] = []
    if (!scContains && manufacturer) {
        textParts.push(manufacturer)
    } else if (!modContains && modManufacturer) {
        textParts.push(modManufacturer)
    }

    if (hasVehicleType) {
        textParts.push(vehicleType ?? "")
    } else if (hasModVehicleType) {
        textParts.push(modVehicleType ?? "")
    }

    return textParts.join(" ")
}

const VehicleTypeDisplay = ({
                                manufacturer,
                                vehicleType,
                                modManufacturer,
                                modVehicleType,
                                ...props
                            }: VehicleTypeDisplayProps) => {

    const text = vehicleTypeDisplayFn({manufacturer, vehicleType, modManufacturer, modVehicleType})

    return (
        <Typography {...props}>
            {text}
        </Typography>
    )
}

export default VehicleTypeDisplay;