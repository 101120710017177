import React from 'react';
import SpeechToText, {SpeechToTextProps} from "modules/worksheet/speech-to-text/SpeechToText";
import {CDAMSMTranscriptionResultModel} from "crowbar-api";
import {useSelectedServiceCall} from "modules/worksheet/editor/shared/data/useSelectedServiceCall";
import {useServiceCallTranscriptionsAtom} from "modules/worksheet/editor/state/atoms/useServiceCallTranscriptionsAtom";
import {WorksheetSaveFactory} from "modules/worksheet/storage/WorksheetSaveFactory";
import {useMountedState} from "shared/hook/useMountedState";

export type WEditorSpeechToTextFieldProps = Omit<SpeechToTextProps, "onTranscribeDone"> & {
    onTranscribeSaved: (transcribedText: string) => void
    disabled?: boolean
}

const WEditorSpeechToTextField = ({onTranscribeSaved, disabled, ...props}: WEditorSpeechToTextFieldProps) => {

    const selectedServiceCall = useSelectedServiceCall()
    const [, setTranscriptions] = useServiceCallTranscriptionsAtom(selectedServiceCall?.id)

    const isMounted = useMountedState()

    const onTranscribeDone = (transcribedText: string, transcribeResult: CDAMSMTranscriptionResultModel) => {
        if (isMounted() && transcribeResult && selectedServiceCall?.id) {
            setTranscriptions(prev => {
                return [
                    ...(prev ?? []),
                    WorksheetSaveFactory.newServiceCallTranscriptionSave(
                        selectedServiceCall.id,
                        transcribeResult.externalId,
                        transcribeResult.crowbarBlobId
                    )
                ]
            })
        }
        onTranscribeSaved?.(transcribedText)
    }

    return (
        <SpeechToText
            onTranscribeDone={onTranscribeDone}
            disabled={disabled}
            {...props}
        />
    );
};

export default WEditorSpeechToTextField;