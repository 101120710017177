import {useQuery} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {CDAMPMPhotoRuleManufacturerModel, PhotoRuleManufacturerApi} from "crowbar-api";
import AxiosCancelTokenHelper from "crowbar-api/AxiosCancelTokenHelper";
import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    Typography
} from "@mui/material";
import {useNewAtomWithRef} from "shared/hook/useNewatomWithRef";
import useAddRuleManufacturer
    from "modules/admin/worksheet/photo-rule/photo-rules/PhotoRuleEdit/useAddRuleManufacturer";
import {toastError, toastWarn} from "shared/toast/DefaultToasts";
import PhotoRuleEditManufacturerEntry
    from "modules/admin/worksheet/photo-rule/photo-rules/PhotoRuleEdit/PhotoRuleEditManufacturerEntry";
import {byPropertiesOf} from "shared/utils/ArraySort";
import usePsgManufacturers from "crowbar-api/hooks/sap/usePsgManufacturers";
import useTranslateFunction from "shared/language/useTranslateFunction";

export interface PhotoRuleEditManufacturersProps {
    ruleId: string
}

const PhotoRuleEditManufacturers = ({ruleId}: PhotoRuleEditManufacturersProps) => {
    const tk = useTranslateFunction(`Admin.PhotoRule`)

    const [selectedManufacturer, setSelectedManufacturer] = useNewAtomWithRef<string>("")
    const [addingDisabled, setAddingDisabled] = useNewAtomWithRef<boolean>(false)

    const addRuleManufacturer = useAddRuleManufacturer()

    const photoRulesQuery = useQuery(['photo-rule-manufacturers', ruleId], async (context) => {
        const response = await crowbarApiFactory(PhotoRuleManufacturerApi)
            .findByRuleId(ruleId, AxiosCancelTokenHelper.cancelTokenFrom(context.signal))
        if (response.status !== 200) {
            throw new Error('Failed to load photo rule manufacturers.')
        }
        return response.data
            ?.filter(m => !m.isDeleted)
            .sort(byPropertiesOf<CDAMPMPhotoRuleManufacturerModel>(['sapManufacturerCode']))
    })

    const psgManufacturersQuery = usePsgManufacturers()

    const handleChange = (e: SelectChangeEvent<string>) => {
        setSelectedManufacturer(e.target.value)
    }

    const onAddButtonClick = async () => {
        if (!selectedManufacturer) {
            toastError('NoSelectedManufacturer')
            return
        }
        if (photoRulesQuery?.data?.find(r => r.sapManufacturerCode === selectedManufacturer) !== undefined) {
            toastWarn(tk('ManufacturerAlreadyExists'))
            return
        }
        setAddingDisabled(true)
        try {
            await addRuleManufacturer.mutateAsync({
                ruleId: ruleId,
                manufacturerCode: selectedManufacturer
            })
            await photoRulesQuery.refetch()
        } finally {
            setAddingDisabled(false)
        }
    }

    const afterManufacturerDelete = async () => {
        await photoRulesQuery.refetch()
    }

    return (
        <Stack direction="column" spacing={1}>
            <Stack direction="row" spacing={1}>
                <FormControl fullWidth>
                    <InputLabel id="photo-rule-manufacturer-select-label">{tk('Manufacturer')}</InputLabel>
                    <Select
                        labelId="photo-rule-manufacturer-select-label"
                        value={selectedManufacturer}
                        label={tk('Manufacturer')}
                        onChange={handleChange}
                    >
                        {psgManufacturersQuery?.data?.map(m => (
                            <MenuItem key={m.code} value={m.code}>{m.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Button variant="contained" color="primary"
                        disabled={addingDisabled || !selectedManufacturer}
                        onClick={onAddButtonClick}>
                    {tk('Add')}
                </Button>
            </Stack>

            {(photoRulesQuery.data?.length ?? 0) < 1 && (
                <Typography>
                    {tk('NoManufacturersAdded')}
                </Typography>
            )}

            <Grid container direction="row" spacing={1}>
                {photoRulesQuery.data?.map(m => (
                    <Grid key={m.id} item xs="auto">
                        <PhotoRuleEditManufacturerEntry
                            prMan={m}
                            manufacturerName={
                                psgManufacturersQuery?.data?.find(psgMan => psgMan.code === m.sapManufacturerCode)?.name ?? undefined
                            }
                            afterDeleted={afterManufacturerDelete}
                        />
                    </Grid>
                ))}
            </Grid>
        </Stack>
    )
}

export default PhotoRuleEditManufacturers