import {useQuery} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {SapPartnerEquipmentApi} from "crowbar-api";
import AxiosCancelTokenHelper from "crowbar-api/AxiosCancelTokenHelper";

export const sapPartnerEquipmentFindByInsId = async (insId: number | undefined, signal?: AbortSignal | undefined) => {
    if (!insId) return null
    const response = await crowbarApiFactory(SapPartnerEquipmentApi)
        .findByInsId(insId, AxiosCancelTokenHelper.cancelTokenFrom(signal))
    return response.data ?? null
}

export const useSapPartnerEquipmentFindByInsId = (insId: number | undefined) => {
    return useQuery(['sap-partner-equipment-find-by-insId-1'], async (context) => {
        return await sapPartnerEquipmentFindByInsId(insId, context.signal)
    }, {
        refetchOnWindowFocus: false,
        refetchOnMount: true,
        cacheTime: 0,
        staleTime: 0,
        retry: 3
    })
}