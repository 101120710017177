import React from 'react';
import {withTranslation, WithTranslation} from "react-i18next";
import {CDAMDMDeliveryNoteType, CDAMDMLeaseStatus} from "crowbar-api";
import {
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    Switch,
    Typography
} from "@mui/material"
import {DeliveryNoteTypeMap} from "crowbar-api/enum-map/DeliveryNoteTypeMap";
import LeaseStatusMap from "crowbar-api/enum-map/LeaseStatusMap";
import {SapSelectOption} from "crowbar-api/enum-map/SapMappingUtils";
import SearchBoxAndDebounce from "shared/components/inputs/SearchBoxAndDebounce";

interface DeliveryNoteSearchProps extends WithTranslation {
    onFilterChange: (search: string, type?: CDAMDMDeliveryNoteType, leaseStatus?: CDAMDMLeaseStatus, showDeleted?: boolean) => void;
}

interface DeliveryNoteSearchState {
    search: string
    type?: string
    leaseStatus?: string
    showDeleted?: boolean
}

class DeliveryNoteSearch extends React.Component<DeliveryNoteSearchProps, DeliveryNoteSearchState> {

    private readonly _typeOptions: SapSelectOption[];
    private readonly _leaseStatusOptions: SapSelectOption[];
    private readonly _deliveryNoteTypeMap = new DeliveryNoteTypeMap();
    private readonly _leaseStatusMap = new LeaseStatusMap();

    constructor(props: Readonly<DeliveryNoteSearchProps> | DeliveryNoteSearchProps) {
        super(props);

        this.state = {
            search: '',
            type: 'All',
            leaseStatus: 'All',
            showDeleted: false
        }

        const t = this.props.t;
        this._typeOptions = [
            {key: 'All', text: t('DeliveryNote.Note.Type.All')},
            {key: 'SaleText', text: t('DeliveryNote.Note.Type.SaleText')},
            {key: 'LeaseText', text: t('DeliveryNote.Note.Type.LeaseText')},
            {key: 'RepairText', text: t('DeliveryNote.Note.Type.RepairText')},
            {key: 'OtherText', text: t('DeliveryNote.Note.Type.OtherText')},
        ];
        this._leaseStatusOptions = [
            {key: 'All', text: t('DeliveryNote.Note.LeaseStatus.All')},
            {key: 'Open', text: t('DeliveryNote.Note.LeaseStatus.Open')},
            {key: 'Finished', text: t('DeliveryNote.Note.LeaseStatus.Finished')},
        ];
    }

    _callParent = () => {
        let newTypeValue: CDAMDMDeliveryNoteType | undefined;
        switch (this.state.type) {
            case 'All':
                newTypeValue = undefined;
                break;
            case 'SaleText':
                newTypeValue = this._deliveryNoteTypeMap.sale;
                break;
            case 'LeaseText':
                newTypeValue = this._deliveryNoteTypeMap.lease;
                break;
            case 'RepairText':
                newTypeValue = this._deliveryNoteTypeMap.repair;
                break;
            case 'OtherText':
                newTypeValue = this._deliveryNoteTypeMap.other;
                break;
            default:
                newTypeValue = this._deliveryNoteTypeMap.notDefined;
        }

        let newLeaseStatus: CDAMDMLeaseStatus | undefined;
        if (newTypeValue === this._deliveryNoteTypeMap.lease) {
            switch (this.state.leaseStatus) {
                case 'All':
                    newLeaseStatus = undefined;
                    break;
                case 'Open':
                    newLeaseStatus = this._leaseStatusMap.open;
                    break;
                case 'Finished':
                    newLeaseStatus = this._leaseStatusMap.finished;
                    break;
                default:
                    newLeaseStatus = undefined;
            }
        } else {
            newLeaseStatus = undefined;
        }

        this.props.onFilterChange(this.state.search, newTypeValue, newLeaseStatus, this.state.showDeleted ?? false);
    }

    _onSearchChange = (newValue: string) => {
        this.setState({
            search: newValue ?? ''
        }, () => {
            this._callParent();
        });
    }

    _onChangeType = (event: SelectChangeEvent) => {
        this.setState({
            type: event.target.value ?? ''
        }, () => {
            this._callParent();
        });
    }

    _onChangeLeaseStatus = (event: SelectChangeEvent) => {
        this.setState({
            leaseStatus: event.target.value ?? ''
        }, () => {
            this._callParent();
        });
    }

    _onChangeShowDeleted = (event: any, checked?: boolean) => {
        this.setState({
            showDeleted: checked ?? false
        }, () => {
            this._callParent();
        })
    }

    render() {
        const t = this.props.t;

        return (
            <Grid container direction={"column"} spacing={1}>
                <Grid item>
                    <SearchBoxAndDebounce
                        value={this.state.search}
                        size={"small"}
                        onChange={this._onSearchChange}
                    />
                </Grid>

                <Grid item>
                    <Stack direction={"row"} spacing={1}>
                        <FormControl fullWidth>
                            <InputLabel id="select-lease-type-text-id" size="small">
                                {t("DeliveryNote.Note.TypeText")}
                            </InputLabel>
                            <Select
                                size="small"
                                labelId="select-lease-type-text-id"
                                label={t("DeliveryNote.Note.TypeText")}
                                value={this.state.type}
                                onChange={(event) => this._onChangeType(event)}
                            >
                                {this._typeOptions.map((option) => {
                                    return (
                                        <MenuItem key={option.key}
                                                  value={option.key}
                                        >
                                            {option.text}
                                        </MenuItem>)
                                })}
                            </Select>
                        </FormControl>


                        {this.state.type === "LeaseText" &&
                            <FormControl fullWidth>
                                <InputLabel id="select-lease-status-text-id" size="small">
                                    {t("DeliveryNote.Note.LeaseStatusText")}
                                </InputLabel>
                                <Select
                                    fullWidth
                                    size="small"
                                    labelId="select-lease-status-text-id"
                                    label={t("DeliveryNote.Note.LeaseStatusText")}
                                    value={this.state.leaseStatus}
                                    onChange={(event) => this._onChangeLeaseStatus(event)}
                                >
                                    {this._leaseStatusOptions.map((option) => {
                                        return (
                                            <MenuItem key={option.key}
                                                      value={option.key}
                                            >
                                                {option.text}
                                            </MenuItem>)
                                    })}
                                </Select>
                            </FormControl>
                        }
                    </Stack>
                </Grid>

                <Stack direction={"row"} alignItems={"center"} spacing={1}>
                    <FormControlLabel label={t("DeliveryNote.Note.ShowDeletedText")}
                                      labelPlacement="start"
                                      control={<Switch
                                          checked={this.state.showDeleted}
                                          onChange={this._onChangeShowDeleted}/>}
                    />

                    <Typography>{this.state.showDeleted ? t("DeliveryNote.Note.ShowDeletedYes") : t("DeliveryNote.Note.ShowDeletedNo")}</Typography>
                </Stack>
            </Grid>
        )
    }
}

export default withTranslation()(DeliveryNoteSearch);