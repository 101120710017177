import {MechanicListingState, useMechanicListingState} from "modules/worksheet/mechanic/state/useMechanicListingState";
import useMechanics from "crowbar-api/hooks/mechanic/useMechanics";
import {useEffect} from "react";
import {PrimitiveAtom, useAtom} from "jotai";

export const useWorksheetListState = (stateAtom: PrimitiveAtom<MechanicListingState>) => {

    const [state, setState] = useAtom(stateAtom)

    const mechanicQuery = useMechanics()

    // set the default ownerIds using the mechanics query result
    // only set if the value is not changed yet
    useEffect(() => {
        if (state.ownerIds === undefined && mechanicQuery.data) {
            setState(prev => {
                return {
                    ...prev,
                    ownerIds: mechanicQuery.data
                        ?.filter(m => m.userId)
                        ?.map(m => m.userId!) ?? []
                }
            })
        }
    }, [mechanicQuery.data, setState, state.ownerIds])

    return useMechanicListingState(stateAtom)
}