import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {withTranslation, WithTranslation} from "react-i18next";
import {Button} from "@mui/material";

interface AlertProps extends WithTranslation {
    title?: string
    message?: string | JSX.Element
    okButton?: string
    cancelButton?: string
    hideBackdrop?: boolean
    parameters?: any
    isOpen: boolean
    onClose: (res: 'ok' | 'cancel', parameters?: any) => void
}

interface AlertState {
    open: boolean
}

class AlertDialog extends React.Component<AlertProps, AlertState> {
    constructor(props: Readonly<AlertProps> | AlertProps) {
        super(props);

        this.state = {
            open: props.isOpen
        }
    }

    componentDidUpdate(prevProps: Readonly<AlertProps>, prevState: Readonly<AlertState>, snapshot?: any) {
        if (this.props.isOpen !== this.state.open) {
            this.setState({
                open: this.props.isOpen
            })
        }
    }

    _handleClose = (res: 'ok' | 'cancel') => {
        this.setState({
            open: false
        });
        // Callback to prop
        this.props.onClose(res, this.props.parameters);
    };

    render() {
        const t = this.props.t;
        return (
            <Dialog
                hideBackdrop={this.props.hideBackdrop}
                open={this.state.open}
                onClose={() => this._handleClose('cancel')}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {this.props.title ? this.props.title : t('Alerts.Confirm.DefaultTitle')}
                </DialogTitle>
                <DialogContent>
                    {this.props.message ? this.props.message :
                        <DialogContentText id="alert-dialog-description">
                            {t('Alerts.Confirm.DefaultMessage')}
                        </DialogContentText>
                    }
                </DialogContent>
                <DialogActions>
                    <Button
                        color="error"
                        variant="outlined"
                        onClick={() => this._handleClose('cancel')}
                    >
                        {this.props.cancelButton ? this.props.cancelButton : t('Alerts.Confirm.DefaultCancel')}
                    </Button>
                    <div style={{flex: '1 0 0'}}/>
                    <Button color="primary" variant="contained"
                            onClick={() => this._handleClose('ok')} autoFocus
                    >
                        {this.props.okButton ? this.props.okButton : t('Alerts.Confirm.DefaultOk')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withTranslation()(AlertDialog);