import {useMutation} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {MechanicApi} from "crowbar-api";

export const useDeleteMechanic = () => {
    return useMutation(['admin-mechanic-delete-1'], async (userId: string) => {
        const response = await crowbarApiFactory(MechanicApi)
            ._delete(userId)
        if (response.status !== 200) {
            throw new Error('Server returned not OK state.')
        }
    })
}