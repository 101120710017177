export const i18n_equipmentEn = {
    "LoadMore": "Load more...",
    "EquipmentsLoading": "Loading equipments...",
    "Equipments": "Equipments",
    "Equipment": "Equipment details",
    "ModEquipmentWarn": "Unverified machine master data added by the mechanic!",
    "EquipmentImages": "Equipment images",
    "NoImagesFound": "No image found for the equipment.",
    "NoEquipmentsFound": "No equipment found for the specified search.",
    "ManufacturerSn": "Manufacturer SN",
    "InternalSn": "Internal SN",
    "Manufacturer": "Manufacturer",
    "VehicleType": "Vehicle type",
    "ServiceCalls": "Service calls",
    "Images": "Images",
    "WorksheetEquipmentImages": "Worksheet images",
    "SapCoverImage": "SAP image",
    "NoServiceCallsFound": "No service call found.",
    "Subject": "Subject",
    "Description": "Description",

}