import React from 'react';
import useTranslateFunction from "shared/language/useTranslateFunction";
import useWorksheetTripItemCodes from "crowbar-api/hooks/worksheet/worksheet-trip-item-codes/useWorksheetTripItemCodes";
import {CircularProgress, Container, Stack, Typography} from "@mui/material";
import WorksheetTripItemCodeAddField
    from "modules/admin/worksheet/worksheet-trip-item-codes/WorksheetTripItemCodeAddField";
import WorksheetTripItemCodesList from "modules/admin/worksheet/worksheet-trip-item-codes/WorksheetTripItemCodesList";

const WorksheetTripItemCodes = () => {

    const tk = useTranslateFunction('Worksheet.Generic.WorksheetTripItemCodes')

    const codesQuery = useWorksheetTripItemCodes()

    const onItemCodeAdded = async () => {
        await codesQuery.refetch()
    }

    const onItemCodeRemoved = async () => {
        await codesQuery.refetch()
    }

    return (
        <Container>
            <Stack direction="column" spacing={1}>
                <Typography
                    variant="h4"
                >
                    {tk('ListTitle')}
                </Typography>
                {codesQuery.isLoading ? (
                    <CircularProgress/>
                ) : (
                    <>
                        <WorksheetTripItemCodeAddField
                            codes={codesQuery.data ?? []}
                            onItemCodeAdded={onItemCodeAdded}
                        />
                        <WorksheetTripItemCodesList
                            codes={codesQuery.data ?? []}
                            onItemCodeRemoved={onItemCodeRemoved}
                        />
                    </>
                )}
            </Stack>
        </Container>
    );
};

export default WorksheetTripItemCodes;