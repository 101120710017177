import {toastError} from "shared/toast/DefaultToasts";
import TextBoxAndDebounce from "shared/components/inputs/TextBoxAndDebounce";
import usePhotoRuleChangeTitle
    from "modules/admin/worksheet/photo-rule/photo-rules/PhotoRuleEdit/usePhotoRuleChangeTitle";
import {Box, Stack} from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import useTranslateFunction from "shared/language/useTranslateFunction";

export interface PhotoRuleEditTitleInputProps {
    ruleId: string
    ruleTitle: string
    afterSave: () => void
}

const PhotoRuleEditTitleInput = ({
                                     ruleId,
                                     ruleTitle,
                                     afterSave
                                 }: PhotoRuleEditTitleInputProps) => {
    const tk = useTranslateFunction(`Admin.PhotoRule`)

    const titleChange = usePhotoRuleChangeTitle()
    const onTitleChange = async (newValue: string) => {
        //   setTitleValue(newValue)
        if (newValue.length > 254) {
            toastError('Title too long.')
            return
        }
        await titleChange.mutateAsync({
            ruleId: ruleId,
            newTitle: newValue
        })
        afterSave()
    }

    return (
        <Stack direction="row" spacing={1} sx={{
            width: 1
        }}>
            <Box sx={{
                width: {
                    xs: "90%",
                    md: "50%"
                }
            }}>
                <TextBoxAndDebounce
                    value={ruleTitle ?? ""}
                    onChange={onTitleChange}
                    label={tk('RuleTitle')}
                />
            </Box>

            {titleChange.isLoading && (
                <SaveIcon/>
            )}
        </Stack>
    )
}

export default PhotoRuleEditTitleInput