import React from 'react';
import {CDAMWMWServiceCallSave} from "crowbar-api";
import {FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Typography} from "@mui/material";
import ServiceCallOperatingHoursTypeMap from "crowbar-api/enum-map/ServiceCallOperatingHoursTypeMap";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {useTranslation} from "react-i18next";
import {useServiceCallAtom} from "modules/worksheet/editor/state/atoms/useServiceCallAtom";
import NumberOnlyTextField from "shared/components/inputs/NumberOnlyTextField";

export interface WEditorOperatingHoursSetProps {
    serviceCall: CDAMWMWServiceCallSave
    disabled?: boolean
}

const opHourValues = ServiceCallOperatingHoursTypeMap.values()

const WEditorOperatingHoursSet = ({serviceCall, disabled}: WEditorOperatingHoursSetProps) => {
    const {t} = useTranslation()
    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    const [, setServiceCall] = useServiceCallAtom(serviceCall.id)

    const onOperatingHoursTypeChange = (event: SelectChangeEvent) => {
        const valueFound = opHourValues.find(opValue => opValue === +event.target.value)
        if (valueFound === undefined || !setServiceCall) return
        setServiceCall(prev => {
            return {
                ...prev,
                operatingHoursType: valueFound
            }
        })
    }

    const onCurrentOpHourChange = (newValue: number) => {
        if (!setServiceCall) return
        setServiceCall(prev => {
            return {
                ...prev,
                operatingHoursType: ServiceCallOperatingHoursTypeMap.provided,
                currentEquipmentOperatingHours: newValue
            }
        })
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography>{tk('OperatingHours')}</Typography>
            </Grid>
            <Grid item xs={12}>

                <FormControl
                    sx={{
                        width: 1
                    }}
                >
                    <InputLabel>{tk('OperatingHours')}</InputLabel>
                    <Select
                        label={tk('OperatingHours')}
                        fullWidth
                        disabled={disabled}
                        value={serviceCall.operatingHoursType?.toString() ?? ""}
                        onChange={onOperatingHoursTypeChange}
                    >
                        {opHourValues.map(v => (
                            <MenuItem key={v} value={v}>
                                {t(ServiceCallOperatingHoursTypeMap.translationKey(v))}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

            </Grid>

            <Grid item xs={12}>
                <NumberOnlyTextField
                    value={serviceCall.currentEquipmentOperatingHours ?? 0}
                    hideZeroOnClick={true}
                    onChange={onCurrentOpHourChange}
                    disabled={disabled || serviceCall.operatingHoursType !== ServiceCallOperatingHoursTypeMap.provided}
                    required
                    fullWidth={false}
                    sx={{
                        width: 1,
                        input: {
                            textAlign: "right"
                        }
                    }}
                    error={!serviceCall.currentEquipmentOperatingHours}
                />
            </Grid>

        </Grid>
    )
}

export default WEditorOperatingHoursSet