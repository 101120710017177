import React from 'react';
import {NotificationCenterItem} from "react-toastify/addons/use-notification-center";
import {Card, CardContent, Grid, Stack, Typography} from "@mui/material";

import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import DateDisplay from "shared/components/date-display/DateDisplay";

export type NotificationEntryProps<T extends {}> = {
    notification: NotificationCenterItem<T>
}
const NotificationEntry = <T extends {}>({notification}: NotificationEntryProps<T>) => {
    const notificationContent = notification?.content

    const currentContent = (typeof notificationContent === "function")
        ? "N/A"
        : notificationContent ?? "N/A"


    let icon
    switch (notification.type) {
        case "error":
            icon = (<ErrorIcon color="error" sx={{fontSize: "28pt"}}/>);
            break
        case "info":
            icon = (<InfoIcon color="info"/>);
            break
        case "warning":
            icon = (<WarningIcon color="warning"/>);
            break
        case "success":
            icon = (<ThumbUpIcon color="primary"/>);
            break
        default:
            icon = (<InfoIcon color="primary"/>);
    }

    return (
        <Card variant="outlined">
            <CardContent sx={{width: 1, p: 1, '&:last-child': {pb: 1}}}>
                <Grid container spacing={0.5} sx={{
                    width: 1,
                    display: "flex",
                    justifyContent: "center"
                }}>
                    <Grid item xs={1.5}>
                        {icon}
                    </Grid>
                    <Grid item xs={true}>
                        <Stack direction="column" spacing={0.5} sx={{width: 1}}>
                            <Typography variant="body2">
                                {currentContent}
                            </Typography>
                            <Typography variant="overline">
                                <DateDisplay dateStr={new Date(notification.createdAt).toJSON()}/>
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default NotificationEntry;