import {CDAMWMServiceCallOperatingHoursType, CDAMWMServiceCallSelectedSource} from "crowbar-api"
import ServiceCallOperatingHoursTypeMap from "crowbar-api/enum-map/ServiceCallOperatingHoursTypeMap";
import ServiceCallSelectedSourceMap from "crowbar-api/enum-map/ServiceCallSelectedSourceMap";

export type RequiredFieldsForEquipmentsValidation = {
    serviceCalls?: RequiredFieldsForServiceCallEquipmentsValidation[]
}

export type RequiredFieldsForServiceCallEquipmentsValidation = {
    selectedSource?: CDAMWMServiceCallSelectedSource | null
    operatingHoursType?: CDAMWMServiceCallOperatingHoursType | null
    currentEquipmentOperatingHours?: number | null
}

export default class EquipmentsCompletedValidation {
    public static isCompleted = (ws?: RequiredFieldsForEquipmentsValidation): boolean => {
        if (!ws || !ws.serviceCalls || ws.serviceCalls.length < 1) return false

        for (let serviceCall of ws.serviceCalls) {
            if (!EquipmentsCompletedValidation.isCompletedFor(serviceCall)) {
                return false
            }
        }

        return true
    }

    public static isCompletedFor = (serviceCall: RequiredFieldsForServiceCallEquipmentsValidation) => {
        const opType = serviceCall.operatingHoursType
        const opVal = serviceCall.currentEquipmentOperatingHours

        // if the sc source is empty (subject provided), nothing is required
        if (serviceCall.selectedSource === ServiceCallSelectedSourceMap.empty) {
            return true
        }

        // Operating hours field is required if the opHourType is provided
        // in other cases, the value is not required
        if (opType === ServiceCallOperatingHoursTypeMap.provided && !opVal) {
            return false
        }

        return true
    }
}