import {EmailNotificationProps} from "modules/admin/worksheet/notifications/email-notifications/EmailNotificationProps";
import {
    useEmailNotificationListingState
} from "modules/admin/worksheet/notifications/email-notifications/state/useEmailNotificationListingState";
import useTranslateFunction from "shared/language/useTranslateFunction";
import LinearIndeterminateProgressWithLabel from "shared/components/progress/LinearIndeterminateProgressWithLabel";
import React from "react";


export type EmailNotificationsInfQueryLoaderProps = EmailNotificationProps & {}
export const EmailNotificationsInfQueryLoader = ({stateAtom}: EmailNotificationsInfQueryLoaderProps) => {
    const tk = useTranslateFunction(`Admin.Worksheet`)
    const {isLoading, isFetching} = useEmailNotificationListingState(stateAtom)

    const displayLoader = isLoading || isFetching

    if (!displayLoader) return <></>

    return (
        <LinearIndeterminateProgressWithLabel
            label={tk('LoadingNotifications')}
        />
    );
}