import React from 'react';
import {useUserContext} from "shared/hook/UserContext";
import {Container} from "@mui/material";
import MechanicDashboard from "modules/dashboard/mechanic/MechanicDashboard";

const Home = () => {
    const [, userClaims] = useUserContext()

    const hasMechanicRole = userClaims.includes('Mechanic')

    return (
        <Container>
            {hasMechanicRole && (
                <MechanicDashboard/>
            )}
        </Container>
    )
}

export default Home;