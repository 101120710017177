import React from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {green} from "@mui/material/colors";
import {Box, CircularProgress, Fab} from "@mui/material";

export type CheckIconFabWithLoadingProps = {
    checked?: boolean
    isLoading?: boolean
    disabled?: boolean
    onClick: () => void
}

export const CheckIconFabWithLoading = ({
                                            checked,
                                            isLoading,
                                            disabled,
                                            onClick
                                        }: CheckIconFabWithLoadingProps) => {
    return (
        <Box sx={{position: 'relative'}}>
            <Fab
                onClick={onClick}
                disabled={disabled}
                sx={{
                    color: checked ? green[500] : "primary",
                }}
            >
                {(checked)
                    ? (
                        <CheckCircleIcon sx={{fontSize: "42pt"}}/>)
                    : (
                        <CheckCircleOutlineIcon sx={{fontSize: "42pt"}}/>
                    )
                }
            </Fab>
            {isLoading && (
                <CircularProgress
                    size={68}
                    sx={{
                        color: green[500],
                        position: 'absolute',
                        top: -6,
                        left: -6,
                        zIndex: 1,
                    }}
                />
            )}
        </Box>
    )
}