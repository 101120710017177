import {useQuery} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {WorksheetTripItemCodeApi} from "crowbar-api";
import AxiosCancelTokenHelper from "crowbar-api/AxiosCancelTokenHelper";

const useWorksheetTripItemCodes = () => {
    return useQuery(['worksheet-trip-item-codes'], async (context) => {
        const response = await crowbarApiFactory(WorksheetTripItemCodeApi).pageAll({
            lastRowNumber: 0,
            limit: 500,
            showDeleted: false,
            orderByIsDesc: false,
            search: ""
        }, AxiosCancelTokenHelper.cancelTokenFrom(context.signal))
        if (response.status !== 200) {
            throw new Error('Invalid response status.')
        }
        return response.data ?? []
    }, {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        retry: 3,
        staleTime: Math.pow(10, 7),
        cacheTime: Math.pow(10, 7),
    })
}

export default useWorksheetTripItemCodes