import React from 'react';

import crowbarLogo from "./web128_crowbar_logo.jpg"
import {atom, useAtom} from "jotai";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from '@mui/icons-material/Close';
import {Link, NavLink} from "react-router-dom";
import {AppBar, Box, Button, Container, darken, IconButton, Stack, Toolbar, Typography} from "@mui/material";
import NavbarUserProfileIconButton from "layout/header/NavbarUserProfileIconButton";
import NavbarAdminMenu from "layout/header/NavbarAdminMenu";
import NavbarWorksheetMenu from "layout/header/NavbarWorksheetMenu";
import NavbarDevRibbon from "layout/header/NavbarDevRibbon";
import useTranslateFunction from "shared/language/useTranslateFunction";
import NotificationCenterButton from "modules/generic/notification-center/NotificationCenterButton";

//export const userProfileSmallImageAtom = atom<CDCGMUserPhoto | undefined>(undefined)
export const appSidebarOpenAtom = atom<boolean>(false)
//export const navbarDisplaySearchInputAtom = atom<boolean>(false)
//export const navbarSearchInputAtom = atom<string | undefined>(undefined)

const Navbar = () => {
    const tk = useTranslateFunction('Header.Nav')
    const [sidebarOpen, setSidebarOpen] = useAtom(appSidebarOpenAtom)

    //const [userId, userClaims] = useUserContext()

    return (
        <>
            <AppBar
                position="fixed"
                variant="elevation"
                sx={{
                    backgroundColor: theme => darken(theme.palette.background.default, 0.075),
                    color: theme => theme.palette.text.primary,
                    height: "auto"
                }}
            >
                <Container maxWidth={false}>
                    <Toolbar disableGutters>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            sx={{mr: 2}}
                            onClick={() => {
                                setSidebarOpen(!sidebarOpen)
                            }}
                        >
                            {sidebarOpen ? <CloseIcon/> : <MenuIcon/>}
                        </IconButton>
                        <Link to="/">
                            <img src={crowbarLogo} alt="Crowbar" height="32"/>
                        </Link>
                        <Typography
                            variant="h5"
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                                mx: 2,
                                display: {xs: 'none', md: 'flex'},
                                fontFamily: 'Oxygen',
                                fontWeight: 400,
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            Crowbar
                        </Typography>

                        <Stack direction="row" spacing={1} sx={{flexGrow: 1}}>
                            <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>

                                <NavbarWorksheetMenu/>

                                <NavLink to="/equipment" style={{
                                    textDecoration: "none"
                                }}>
                                    <Button sx={{
                                        color: "text.primary"
                                    }}>
                                        {tk('Equipments')}
                                    </Button>
                                </NavLink>

                                <NavLink to="/delivery-notes" style={{
                                    textDecoration: "none"
                                }}>
                                    <Button sx={{
                                        color: "text.primary"
                                    }}>
                                        {tk('DeliveryNotes')}
                                    </Button>
                                </NavLink>

                                <NavbarAdminMenu/>
                            </Box>

                            <NavbarDevRibbon/>
                        </Stack>

                        <Box sx={{flexGrow: 0, mr: 3}}>
                            <NotificationCenterButton/>
                        </Box>

                        <Box sx={{flexGrow: 0}}>
                            <NavbarUserProfileIconButton/>
                        </Box>

                    </Toolbar>
                </Container>
            </AppBar>
        </>
    );
};

export default Navbar;