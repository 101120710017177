import React, {useEffect, useMemo, useState} from 'react';
import {Card, Grid, Typography} from "@mui/material";
import {CDSMNNewWorksheetSalesOrder, CDSMNNewWorksheetSalesOrderRow} from "crowbar-api";
import useTranslateFunction from "shared/language/useTranslateFunction";
import MaterialReactTable, {MRT_ColumnDef} from 'material-react-table';
import {useMaterialReactTableLocalization} from "i18n/material-table/useMaterialReactTableLocalization";
import RenderIfVisible from "shared/render-if-visible/render-if-visible";
import {PaginationState} from "@tanstack/table-core";

export interface NewWorksheetResultSalesOrderTableProps {
    salesOrder: CDSMNNewWorksheetSalesOrder
    pageSize: number
}

const NewWorksheetResultSalesOrderTable = ({salesOrder, pageSize}: NewWorksheetResultSalesOrderTableProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.NewWorksheet`)

    const [pagination, setPagination] = useState<PaginationState>({pageSize: pageSize, pageIndex: 0})

    const mrt_locale = useMaterialReactTableLocalization()

    // Allows to update the pageSize property from outside
    useEffect(() => {
        setPagination(prev => ({...prev, pageSize: pageSize}))
    }, [pageSize])

    const columns = useMemo<MRT_ColumnDef<CDSMNNewWorksheetSalesOrderRow>[]>(() => [
        {
            accessorKey: 'itemCode',
            header: tk('ItemCode'),
            minSize: 20,
            size: 90,
            maxSize: 40,
        },
        {
            accessorFn: row => row.rowType === 0 ? row.description : row.lineText,
            header: tk('Description'),
            minSize: 20,
            size: 150,
            maxSize: 200,
            Cell: ({cell, row}) => (
                <Typography fontFamily="inherit"
                            fontSize="inherit"
                            fontWeight={row.original.rowType === 0 ? 'italic' : 'bold'}
                            title={cell.getValue<string>()}
                >
                    {cell.getValue<string>()}
                </Typography>
            )
        },
        {
            accessorFn: (row) => (
                row.quantity ? `${row.quantity ?? 0} ${row.unitOfQuantity}` : ''
            ),
            header: tk('Quantity'),
            minSize: 20,
            size: 70,
            maxSize: 200,
        },
        {
            accessorFn: (row) => (
                row.lineStatus === 'O' ? tk('Yes')
                    : row.lineStatus === 'C' ? tk('No') : ""
            ),
            header: tk('Open'),
            minSize: 20,
            size: 70,
            maxSize: 200

        }
    ], [tk])

    return (
        <Card variant="outlined" sx={{
            height: 1,
            width: 1,
            minHeight: 340
        }}>
            <Grid container>
                <Grid item xs={12}>
                    <RenderIfVisible stayRendered={true}>
                        <MaterialReactTable
                            columns={columns}
                            data={salesOrder.rows ?? []}
                            localization={mrt_locale}
                            initialState={{
                                density: 'compact'
                            }}
                            state={{
                                pagination
                            }}
                            onPaginationChange={setPagination}
                            rowNumberMode="original" //default
                            enableColumnResizing={true}
                            enableColumnFilters={false}
                            enableColumnActions={false}
                            enableFullScreenToggle={false}
                            muiTableBodyCellProps={({table, row}) => {
                                return {
                                    sx: (theme) => ({
                                        color: (row.original.lineStatus === 'C')
                                            ? theme.palette.text.disabled
                                            : theme.palette.text.primary
                                    })
                                }
                            }}
                        />
                    </RenderIfVisible>
                </Grid>
            </Grid>
        </Card>
    )
}

export default NewWorksheetResultSalesOrderTable;