import {useMutation} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {CCMWECreateServiceCallForEvalParameters, ServiceCallEvalApi} from "crowbar-api";
import {AxiosRequestConfig} from "axios";

export const useServiceCallEvalCreateSapServiceCall = () => {
    return useMutation(async (p: CCMWECreateServiceCallForEvalParameters, options?: AxiosRequestConfig) => {
        const result = await crowbarApiFactory(ServiceCallEvalApi)
            .createServiceCallForEval(p, options)
        return result.data ?? null
    })
}