import React from 'react';
import {atomWithStorage} from "jotai/utils";
import {
    AgentNotificationConfigurationState
} from "modules/admin/worksheet/notifications/agent-notification/state/useAgentNotificationConfigurationState";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {Stack} from "@mui/material";
import {
    AgentNotificationConfigurationFilters
} from "modules/admin/worksheet/notifications/agent-notification/filters/AgentNotificationConfigurationFilters";
import {
    AgentNotificationConfigurationResults
} from "modules/admin/worksheet/notifications/agent-notification/results/AgentNotificationConfigurationResults";

const defaultValue: AgentNotificationConfigurationState = {
    search: "",
    showDeleted: false,
    lastRowNumber: 0,
    limit: 1000,
    orderByIsDesc: false
}

const stateAtom = atomWithStorage<AgentNotificationConfigurationState>("UiState-Admin-AgentNotificationConfiguration", defaultValue)

export const AgentNotificationConfiguration = () => {
    const tk = useTranslateFunction(`Admin.Worksheet`)

    return (
        <Stack direction="column" spacing={1}>
            <h1>{tk('AgentNotificationConfiguration')}</h1>

            <AgentNotificationConfigurationFilters stateAtom={stateAtom}/>
            <AgentNotificationConfigurationResults stateAtom={stateAtom}/>
        </Stack>
    )
}