import React from 'react';
import {CDAMPMPhotoRuleAndManufacturersQuery, CDAMWMESapEquipmentServiceCallBlobQuery} from "crowbar-api";
import {Card, CardContent, Grid, Typography} from "@mui/material";
import WEditorPhotoEntry from "modules/worksheet/editor/ui/photo/WEditorPhotoEntry";
import usePhotoRuleDetailsById from "crowbar-api/hooks/photo-rule/usePhotoRuleDetailsById";

export interface WEditorPhotoGroupProps {
    rule: CDAMPMPhotoRuleAndManufacturersQuery
    subTitle?: string
    blobs: CDAMWMESapEquipmentServiceCallBlobQuery[]

    onImageClick: (rule?: CDAMPMPhotoRuleAndManufacturersQuery, blob?: CDAMWMESapEquipmentServiceCallBlobQuery) => void
    onReplaceClick: (rule?: CDAMPMPhotoRuleAndManufacturersQuery, blob?: CDAMWMESapEquipmentServiceCallBlobQuery) => void
    onDeleteClick: (rule?: CDAMPMPhotoRuleAndManufacturersQuery, blob?: CDAMWMESapEquipmentServiceCallBlobQuery) => void
}

const WEditorPhotoGroup = ({
                               rule,
                               subTitle,
                               blobs,
                               onImageClick,
                               onReplaceClick,
                               onDeleteClick
                           }: WEditorPhotoGroupProps) => {

    const ruleDetailsQuery = usePhotoRuleDetailsById(rule.id)
    const ruleDetails = ruleDetailsQuery.data
    const steps = ruleDetails?.overridenSteps ?? []

    return (
        <Card variant="outlined">
            <CardContent>
                <Grid container direction="column" spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="h5" component="div">
                            {rule.title}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {subTitle && (
                            <Typography variant="subtitle2">
                                ({subTitle})
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={12}>

                        <Grid container spacing={1}>
                            {blobs
                                // .sort(byPropertiesOf<CDAMWMESapEquipmentServiceCallBlobQuery>(['orderNum', 'subOrderNum', 'createdAt', 'updatedAt']))
                                .map((blob, i) => (
                                    <Grid key={[blob.blobId, i].join('-')} item xs="auto">
                                        <WEditorPhotoEntry
                                            rule={rule}
                                            step={steps.find(s => s.id === blob.photoRuleStepId)}
                                            blob={blob}
                                            onImageClick={() => onImageClick(rule, blob)}
                                            onReplaceClick={() => onReplaceClick(rule, blob)}
                                            onDeleteClick={() => onDeleteClick(rule, blob)}
                                        />
                                    </Grid>
                                ))}

                        </Grid>

                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default WEditorPhotoGroup;