export const i18n_genericEn = {
    "Loading": "Loading...",
    "Yes": "Yes",
    "No": "No",
    "Back": "Back",
    "Search": "Search",
    "Previous": "Previous",
    "Next": "Next",
    "SingOut": "Sign out",
    "NetworkError": "Network error",
    "ServerError": "Server error",
    "NetworkResponseError": "Network error",
    "UnknownError": "Unknown error",
    "Error": "Hiba",
    "GenericErrorText": "A software error has occurred. Please report it to the administrator! (If you could not send your feedback in the pop-up window, you can send it in another form.)",
    "GenericErrorText2": "If necessary, try to perform the operation again.",
    "NetworkResponseErrorSuggestion": "There is a problem with the internet connection or the server is not available.",
    "Version": "Version",
    "NewVersionNotification": "A new version of the application is available. Please reload the page.",
    "ReloadPage": "Reload page",
    "Dismiss": "Close",
    "OnlineDetector": {
        "OfflineMessage": "The server is unavailable. Please check your internet connection. After maintenance, reload the page."
    },
    "NotificationCenter": {
        "Notifications": "Notifications",
        "ShowRead": "Show read",
        "ClearAll": "Clear all",
        "MarkAllAsRead": "Mark all as read",
        "NoNotifications": "No notification."
    },
    "Login": {
        "RequiresAuthentication": "Login to your account.",
        "LoginWithMs": "Login with Microsoft",
        "Microsoft": "Microsoft account",
        "LoginFailed": "Authentication failed..",
        "ContactAdmin": "Try again. If you still can't log in, please contact the administrator.",
        "TechincalDetails": "Technical details",

    },
    "ImageUploadSingle": {
        "Browse": "Browse",
        "NoFileName": "Select a file...",
        "UploadSuccess": "Image successfully uploaded.",
        "Upload": "Upload",
        "CoverImage": "Cover image",
        "UploadInfo": "Upload an image. Supported formats: jpg, png, webp"
    },
    "Microphone": {
        "UserAbort": "User aborted the recording.",
        "HWNotSupported": "Hardware not supported on this platform.",
        "TeamsError": "Teams SDK error during media call.",
        "NoRecording": "Could not find the recording.",
        "SaveAudio": "Saving audio."
    },
    "Camera": {
        "NotFound": "The file cannot be found.",
        "TeamsError": "Teams SDK error during media call.",
        "PermissionDenied": "The permission was not granted by the user.",
        "HWNotSupported": "This tool is not supported on this platform.",
        "UserAbort": "User has interrupted the operation.",
        "OldPlatform": "Unsupported platform.",
        "SizeExceeded": "Photo size too large."
    },
    "Sap": {
        "DefaultSelectCustomerBtnText": "Select customer",
        "DefaultSelectCustomerTitle": "Select a customer",
        "DefaultSelectCustomerCancelText": "Cancel",
        "DefaultSelectCustomerOkText": "Select",
    },
    "UserAutoRegistered": "User successfully auto-registered.",
    "Validations": {
        "FieldIsRequired": "The field is required.",
        "OnlyNumbersAllowed": "Only numbers can be entered.",
        "OnlyIntegersAllowed": "Only integers can be entered.",
        "StringIsTooLong": "The text you entered is too long.",
        "StringIsLongerThan": "The text cannot be longer than {{max}} characters.",
        "StringIsTooShort": "The text you entered is too short.",
        "NumberBetween": "The number should be between {{min}} and {{max}} characters.",
        "InvalidEmailAddress": "Invalid e-amil address.",
        "NumberTooLarge": "Too large number.",
    },
    "ConfirmButton": {
        "Cancel": "Cancel",
        "Yes": "Igen",
        "Confirm": "Megerősítés",
        "DefaultTitle": "Confirmation",
        "DefaultText": "Are you sure?"
    },
    "Equipment": {
        "EquipmentImages": "Equipment images"
    }
}