import {CDAMWMWModSapPartnerContactSave} from "crowbar-api";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {
    PartnerContactFormValues,
    usePartnerContactForm
} from "modules/worksheet/editor/shared/component/partner-contact-form/usePartnerContactForm";
import React from "react";
import PartnerContactForm from "modules/worksheet/editor/shared/component/partner-contact-form/PartnerContactForm";
import useTranslateFunction from "shared/language/useTranslateFunction";

interface WEditorPageBasicContactDialogProps {
    mode: 'new' | 'edit'
    open: boolean
    onDismiss: () => void
    onSubmit: (data: PartnerContactFormValues) => void | Promise<void>
    editModContact?: CDAMWMWModSapPartnerContactSave
}

const WEditorPageBasicContactDialog = ({
                                           mode,
                                           open,
                                           onDismiss,
                                           onSubmit,
                                           editModContact
                                       }: WEditorPageBasicContactDialogProps) => {

    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    const form = usePartnerContactForm({
        mode,
        editModContact: editModContact
    })

    const onClose = () => {
        onDismiss()
    }

    const onDialogSubmit = async () => {
        const handleFunction = form.handleSubmit((data, event) => {
            onClose()
            onSubmit(data)
        }, e => console.error(e))

        await handleFunction()
    }

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle>
                {mode === 'new' ? tk('NewPartnerContact') : tk('Edit')}
            </DialogTitle>
            <DialogContent>
                <Box sx={{p: 1}}>
                    <PartnerContactForm mode={mode} form={form}/>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    color="error"
                    variant="outlined"
                    onClick={() => onClose()}
                >
                    {tk('Cancel')}
                </Button>
                <div style={{flex: "1 0 0"}}/>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => onDialogSubmit()}
                    disabled={!form.formState.isDirty && !form.formState.isValid}
                >
                    {
                        mode === "new" ? tk('Add') : tk('Edit')
                    }
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default WEditorPageBasicContactDialog