import {useMutation} from "@tanstack/react-query";
import {CDAMCMMechanicModel, MechanicApi} from "crowbar-api";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";

const useSaveMechanic = () => {
    return useMutation(async (m: CDAMCMMechanicModel) => {
        const r = await crowbarApiFactory(MechanicApi).save(m)
        if (r.status !== 200) {
            throw new Error('Invalid status code while saving mechanic.')
        }
    })
}

export default useSaveMechanic