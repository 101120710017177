import {atom, SetStateAction, useAtom} from "jotai";
import {atomWithStorage} from "jotai/utils";

export const BuildTimeLocalStorageKey = "UiState-BuildTime"

export interface BuildInfo {
    buildTime: string | undefined
    setBuildTime: (update?: SetStateAction<string | undefined>) => void
    environmentName: string | undefined
    setEnvironmentName: (update?: SetStateAction<string | undefined>) => void
}

const buildTimeAtom = atomWithStorage<string | undefined>(BuildTimeLocalStorageKey, undefined)
const environmentNameAtom = atom<string | undefined>(undefined)

export const useBuildInfo = (): BuildInfo => {
    const [buildTime, setBuildTime] = useAtom(buildTimeAtom)
    const [environmentName, setEnvironmentName] = useAtom(environmentNameAtom)

    return {
        buildTime,
        setBuildTime,
        environmentName,
        setEnvironmentName
    }
}