import {CDAMWMWWorksheetSave} from "crowbar-api";
import {atom, SetStateAction, useAtom} from "jotai";
import {DateUtils} from "shared/utils/DateUtils";

const worksheetStateLastSaveAtom = atom<number | undefined>(undefined)
const internalWorksheetSaveStateAtom = atom<CDAMWMWWorksheetSave | undefined>(undefined)

export const worksheetSaveStateAtom = atom(
    get => get(internalWorksheetSaveStateAtom),
    (_, set, newValue: SetStateAction<CDAMWMWWorksheetSave | undefined>) => {
        set(worksheetStateLastSaveAtom, DateUtils.utcTimestampNow())
        set(internalWorksheetSaveStateAtom, newValue)
    }
)

export const useWorksheetSaveStateLastSaveTime = () => {
    return useAtom(worksheetStateLastSaveAtom)
}

/**
 *  Hook for easier usage
 */
export const useWorksheetSaveState = () => {
    return useAtom(worksheetSaveStateAtom)
}
