import {CDAMWMWServiceCallItemRowSave} from "crowbar-api";
import useIsTripMatchFn from "crowbar-api/hooks/worksheet/validations/useIsTripMatchFn";

/**
 * Decides for the item row if the
 * row is used for distance logging (trip=true)
 *
 * ItemCode is trip:
 *  1. if the IsTrip = true on the ServiceCallItemRow
 *  2. if the tripCodes contains the ItemCode (can be offline)
 * @param itemRow
 */
const useIsTripMatch = (itemRow: CDAMWMWServiceCallItemRowSave) => {
    const fn = useIsTripMatchFn()
    return fn(itemRow)
}

export default useIsTripMatch