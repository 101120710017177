import React, {useState} from 'react';
import {Button, CircularProgress, Stack} from "@mui/material";
import NewPartnerDialog from "modules/worksheet/new-worksheet/new-partner/NewPartnerDialog";
import {
    PartnerFormValues,
    UseNewPartnerFormResult
} from "modules/worksheet/new-worksheet/new-partner/useNewPartnerForm";

import {v4 as uuid} from 'uuid'
import ModSapTypeMap from "crowbar-api/enum-map/ModSapTypeMap";
import {
    useNewWorksheetFromModPartnerAction
} from "modules/worksheet/editor/state/events/useNewWorksheetFromModPartnerAction";
import {useOpenWorksheetEditor} from "modules/worksheet/editor/state/events/useOpenWorksheetEditor";
import useTranslateFunction from "shared/language/useTranslateFunction";

import PersonIcon from '@mui/icons-material/Person';
import {toastSuccess} from "shared/toast/DefaultToasts";
import {useMountedState} from "shared/hook/useMountedState";

const NewPartnerButton = () => {
    const tk = useTranslateFunction(`Worksheet.Generic.NewWorksheet`)
    const [open, setOpen] = useState<boolean>(false)

    const newWorksheetFromModPartnerAction = useNewWorksheetFromModPartnerAction()
    const openWorksheetEditor = useOpenWorksheetEditor()

    const isMounted = useMountedState()

    const onNewDialogOpen = () => {

        setOpen(true)
    }

    const onNewCustomerSubmit = async (data: PartnerFormValues, form: UseNewPartnerFormResult) => {

        const newWorksheet = await newWorksheetFromModPartnerAction.mutateAsync({
            modPartner: {
                id: uuid(),
                type: ModSapTypeMap.create,
                partnerType: "C",
                partnerName: data.partnerName,
                vatLocal: data.vatLocal,
                vatInternational: data.vatInternational,
                comment: data.comment
            }
        })
        form.reset()
        if (isMounted()) {
            openWorksheetEditor(newWorksheet.id)
        } else {
            toastSuccess(`${tk('WorksheetCreatedSilent')}${newWorksheet.partnerCardName}`)
        }
    }

    return (
        <>
            <Button
                variant="outlined"
                onClick={onNewDialogOpen}
                disabled={newWorksheetFromModPartnerAction.isLoading}
                sx={{
                    display: "block",
                    textAlign: "center",
                    maxWidth: "150px"
                }}
            >
                <Stack direction="column" spacing={0.5} alignItems="center">
                    {newWorksheetFromModPartnerAction.isLoading ? <CircularProgress/> : <PersonIcon/>}
                    {tk('WorksheetForNewCustomer')}
                </Stack>
            </Button>

            <NewPartnerDialog
                open={open}
                onDismiss={() => setOpen(false)}
                onSubmit={onNewCustomerSubmit}
            />
        </>
    )
}

export default NewPartnerButton;