import {Route, Routes} from 'react-router-dom';
import TabConfig from "modules/home/TabConfig";
import RoutesWithLayout from "system/RoutesWithLayout";
import DeliveryNotes from "modules/delivery-note/DeliveryNotes";
import React from "react";
import SentryErrorBoundary from "shared/components/error/SentryErrorBoundary";

const MainRoutes = () => {
    return (
        <SentryErrorBoundary>
            <Routes>
                <Route path="/tab-config" element={<TabConfig/>}/>

                <Route path="/teams/delivery-notes/*" element={<DeliveryNotes/>}/>

                <Route path="*" element={<RoutesWithLayout/>}/>
            </Routes>
        </SentryErrorBoundary>
    )
}

export default MainRoutes;