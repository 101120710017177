import React from 'react';
import {IconButton} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {MapUtils} from "shared/utils/MapUtils";

export interface LocationOpenMapsIconButtonProps {
    /**
     * Use this address when the map clicked
     */
    address?: string
    /**
     * Do not open map automatically
     */
    disableMapOpenOnClick?: boolean
    onOpenMapsClick?: (address?: string) => void
}

const LocationOpenMapsIconButton = ({
                                        address,
                                        disableMapOpenOnClick,
                                        onOpenMapsClick
                                    }: LocationOpenMapsIconButtonProps) => {

    const openMaps = () => {
        if (!disableMapOpenOnClick && address) {
            MapUtils.openUrlForQuery(address)
        }
        onOpenMapsClick?.(address)
    }

    return (
        <IconButton onClick={openMaps}>
            <LocationOnIcon/>
        </IconButton>
    );
};

export default LocationOpenMapsIconButton;