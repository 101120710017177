import React, {useEffect, useState} from 'react';
import {Slider, SliderProps} from "@mui/material";
import {useDebounce} from "shared/hook/useDebounce";

export type SliderAndDebounceProps = Omit<SliderProps, 'value' | 'onChange'> & {
    value: number | number[]
    onChange: (event: Event, value: number | number[], activeThumb: number) => void
}

const SliderAndDebounce = ({value, onChange, ...props}: SliderAndDebounceProps) => {

    const [currentValue, setCurrentValue] = useState<number | number[]>(value)

    // debounce
    const inputDebounce1s = useDebounce(1000)

    const onSliderChange = (event: Event, value: number | number[], activeThumb: number) => {
        // Set the controlled input value immediately
        // (otherwise it would block!)
        setCurrentValue(value)
        // Debounce the event to parent
        inputDebounce1s(() => {
            onChange?.(event, value, activeThumb)
        })
    }

    // Synchronize value changes to currentValue
    useEffect(() => {
        setCurrentValue(value)
    }, [value])

    return (
        <Slider
            {...props}
            value={currentValue}
            onChange={onSliderChange}
        />
    )
}

export default SliderAndDebounce;