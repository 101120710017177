import {CDAMWMServiceCallItemRowStatus} from "crowbar-api"
import ServiceCallItemRowStatusMap from "crowbar-api/enum-map/ServiceCallItemRowStatusMap";
import {IsTripMatchItemRow} from "crowbar-api/hooks/worksheet/validations/useIsTripMatchFn";

export type RequiredFieldsForWorksheetCatalogueValidation = {
    serviceCalls?: RequiredServiceCallFieldsForWorksheetCatalogueValidation[]
}

export type RequiredServiceCallFieldsForWorksheetCatalogueValidation = {
    itemRows?: RequiredItemRowFieldsForWorksheetCatalogueValidation[]
}

export type RequiredItemRowFieldsForWorksheetCatalogueValidation = {
    status?: CDAMWMServiceCallItemRowStatus
    isTrip?: boolean | null
}

export default class CatalogueCompletedValidation {
    public static isCompleted = (
        ws?: RequiredFieldsForWorksheetCatalogueValidation,
        isTripFn?: (itemRow: IsTripMatchItemRow) => (boolean | undefined)
    ): boolean => {
        if (!ws?.serviceCalls) return false

        for (const serviceCall of ws.serviceCalls) {
            if (!serviceCall.itemRows) {
                continue
            }

            if (!CatalogueCompletedValidation.isCompletedFor(serviceCall, isTripFn)) {
                return false
            }
        }

        return true
    }

    public static isCompletedFor = (
        sc: RequiredServiceCallFieldsForWorksheetCatalogueValidation,
        isTripFn?: (itemRow: IsTripMatchItemRow) => (boolean | undefined)
    ): boolean => {
        if (!sc.itemRows) {
            return true
        }

        for (const ir of sc.itemRows) {
            // isTrip rows are exception to the rule
            if (ir.isTrip || isTripFn?.(ir)) {
                continue
            }

            // if any of the rows are not in installed state, false
            if (ir.status !== ServiceCallItemRowStatusMap.installed) {
                return false
            }
        }

        return true
    }
}