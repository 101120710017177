import React from 'react';
import {CDAMWMWorksheetEvalModel, CDAMWMWWorksheetQuery} from "crowbar-api";
import {Card, CardContent, Grid, Typography} from "@mui/material";
import useTranslateFunction from "shared/language/useTranslateFunction";
import SkipCustomerEmail from "modules/admin/worksheet/evaluation/tabs/master-data/ExtraOptions/SkipCustomerEmail";
import OverrideEmailAddresses
    from "modules/admin/worksheet/evaluation/tabs/master-data/ExtraOptions/OverrideEmailAddresses";


export type ExtraOptionsProps = {
    wEval: CDAMWMWorksheetEvalModel | null | undefined
    worksheet: CDAMWMWWorksheetQuery | null | undefined
    onDataChange: () => void
}
const ExtraOptions = ({
                          wEval,
                          worksheet,
                          onDataChange
                      }: ExtraOptionsProps) => {
    const tk = useTranslateFunction("Admin.Worksheet")


    return (
        <Card variant="outlined">
            <CardContent>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="h6">{tk('ExtraOptions')}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <SkipCustomerEmail
                            wEval={wEval}
                            worksheet={worksheet}
                            onDataChange={onDataChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <OverrideEmailAddresses
                            wEval={wEval}
                            worksheet={worksheet}
                            onDataChange={onDataChange}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default ExtraOptions;