import {useWorksheetCartState} from "modules/worksheet/worksheet-cart/useWorksheetCartState";
import {useEffect} from "react";

export const worksheetCartLocalStorageKey = (scope?: number | string) => `worksheet-cart-${scope ?? "default"}`

export const readWorksheetCartEntriesFromLocalStorage = (scope?: number | string) => JSON.parse(localStorage.getItem(worksheetCartLocalStorageKey(scope)) ?? '[]')

/**
 * This hook handles the rehydration and the automatic save of the cart entries to localStorage
 * Every scope should only have one call for this hook.
 * If multiple components start to call this (multiple times in the tree), it will execute multiple times.
 * https://codesandbox.io/s/useeffect-called-multiple-times-2tcr07?file=/src/useCallMe.ts
 * tip: only call this on the container, in one component
 * @param scope
 */
export const useWorksheetCartSaveToLocalStorage = (scope?: string | number) => {
    const {cartEntries, setCartEntries, hydrated, setHydrated} = useWorksheetCartState(scope)

    // rehydrate on mount
    useEffect(() => {
        if (!hydrated) {
            setCartEntries(readWorksheetCartEntriesFromLocalStorage(scope))
            setHydrated(true)
        }
    }, [hydrated, scope, setCartEntries, setHydrated])

    // save the entries when they change, but only if the previous value was read before
    useEffect(() => {
        if (hydrated) {
            localStorage.setItem(worksheetCartLocalStorageKey(scope), JSON.stringify(cartEntries))
        }
    }, [cartEntries, hydrated, scope])
}