import {CDSMSSapItemForWorksheetSearchQuery} from "crowbar-api";
import {Box, Card, CardContent, CardMedia, Stack, Typography} from "@mui/material";
import ItemImageDisplay from "modules/worksheet/editor/ui/catalogue/item-image/ItemImageDisplay";

import CheckBoxIcon from '@mui/icons-material/CheckBox';

interface AddItemSelectCardProps {
    item: CDSMSSapItemForWorksheetSearchQuery
    selected: boolean
    setAsSelected: () => void
}

const AddItemSelectCard = ({item, selected, setAsSelected}: AddItemSelectCardProps) => {
    return (
        <Card
            elevation={16}
            onClick={setAsSelected}
            sx={{
                width: "200px",
                borderColor: selected ? theme => theme.palette.primary.main : 'transparent',
                borderWidth: "4px",
                borderStyle: "solid",
                //backgroundColor: selected ? theme => theme.palette.grey.A400: 'transparent'
            }}
        >
            <CardMedia sx={{
                width: 1,
                display: "flex",
                justifyContent: "center"
            }}>
                <ItemImageDisplay
                    itemCode={item.itemCode}
                    coverImage={item.coverImage ?? undefined}
                    hideBorder={true}
                    disableOnClick={true}
                />
            </CardMedia>
            <CardContent>

                {selected && (
                    <Box sx={{
                        position: "relative"
                    }}>
                        <CheckBoxIcon
                            color="primary"
                            sx={{
                                position: "absolute",
                                top: "-150px",
                                left: 0
                            }}/>
                    </Box>
                )}

                <Stack direction={"column"} sx={{
                    width: "170px"
                }}>
                    <Box
                        component="div"
                        sx={{
                            textOverflow: 'ellipsis',
                            overflow: "hidden",
                            display: "-webkit-box",
                            lineHeight: "21px",
                            height: "48px",
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical"
                        }}>
                        {item.itemName}
                    </Box>

                    <Typography>{item.itemCode}</Typography>
                </Stack>

            </CardContent>
        </Card>
    )
}

export default AddItemSelectCard