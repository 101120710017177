import {useMutation} from "@tanstack/react-query";
import httpResponseFileDownload from "shared/utils/HttpResponseFileDownload";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {WorksheetPdfApi} from "crowbar-api";
import {AxiosRequestConfig, AxiosResponse} from "axios";

export type UseWorksheetPdfDownloadProps = {
    worksheetId: string
    layout: 'admin' | 'customer'
    hideCustomerSignature?: boolean
}

export const useWorksheetPdfDownload = () => {
    return useMutation(['worksheet-pdf-download-1'], async ({
                                                                worksheetId,
                                                                layout,
                                                                hideCustomerSignature
                                                            }: UseWorksheetPdfDownloadProps) => {
        const options: AxiosRequestConfig = {
            responseType: 'arraybuffer',
            headers: {
                'Accept': 'application/pdf'
            }
        }

        const pdfApi = crowbarApiFactory(WorksheetPdfApi)
        let response: AxiosResponse;
        if (layout === 'admin') {
            response = await pdfApi.downloadAdminPdf(worksheetId, hideCustomerSignature, options)
        } else {
            response = await pdfApi.downloadCustomerPdf(worksheetId, hideCustomerSignature, options)
        }

        httpResponseFileDownload(response, `${worksheetId}.pdf`)
    })
}