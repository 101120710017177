import React from 'react';
import useGraphApiFindAllUserWithMechanicRole from "crowbar-api/hooks/mechanic/useGraphApiFindAllUserWithMechanicRole";
import {
    Box,
    Chip,
    CircularProgress,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent
} from "@mui/material";
import useTranslateFunction from "shared/language/useTranslateFunction";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

export type WorksheetListFilterByMechanicsProps = {
    selectedMechanicIds: string[]
    onChange: (mechanicIds: string[]) => void

    onClear: () => void
    onReset: () => void
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const WorksheetListFilterByMechanics = ({
                                            selectedMechanicIds,
                                            onChange,
                                            onClear,
                                            onReset
                                        }: WorksheetListFilterByMechanicsProps) => {
    const tk = useTranslateFunction(`Admin.Worksheet`)

    // we need all possible userIds for the ownerId
    const mechanicsQuery = useGraphApiFindAllUserWithMechanicRole()

    const mechanics = mechanicsQuery?.data ?? []

    if (mechanicsQuery.isLoading || mechanicsQuery.isFetching) {
        return <CircularProgress/>
    }

    const handleChange = (event: SelectChangeEvent<string[]>) => {
        const newValue = event.target.value
        if (typeof newValue === 'string') {
            onChange([])
        } else {
            onChange(newValue)
        }
    }

    return (
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={true}>
                <FormControl sx={{m: 1, minWidth: 300, width: 1}}>
                    <InputLabel id="mechanic-multiple-chip-label">{tk('Mechanics')}</InputLabel>
                    <Select
                        id="mechanic-multiple-chip"
                        labelId="mechanic-multiple-chip-label"
                        multiple
                        value={mechanics
                            .filter(m => m.id && selectedMechanicIds.includes(m.id))
                            .map(m => m.id!)
                        }
                        onChange={handleChange}
                        input={<OutlinedInput id="select-multiple-chip" label={tk('Mechanics')}/>}
                        renderValue={(selected) => (
                            <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                                {selected.map((mechanicId, i) => {
                                    const found = mechanics.find(m => m.id === mechanicId)
                                    if (!found) {
                                        return (
                                            <Chip key={i} label={"N//A"}/>
                                        )
                                    }
                                    return (
                                        <Chip key={found.id} label={found.fullName ?? "N//A"}/>
                                    )
                                })}
                            </Box>
                        )}
                        MenuProps={MenuProps}
                    >
                        {mechanics.map((m) => (
                            <MenuItem
                                key={m.id}
                                value={m.id}
                            >
                                {m.fullName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs="auto">
                <IconButton size="large" title={tk('ResetFilterByMechanicTitle')} onClick={onReset}>
                    <RestartAltIcon sx={{fontSize: "28pt"}}/>
                </IconButton>
            </Grid>
            <Grid item xs="auto">
                <IconButton size="large" title={tk('ClearFilter')} onClick={onClear}>
                    <HighlightOffIcon sx={{fontSize: "28pt"}}/>
                </IconButton>
            </Grid>
        </Grid>
    );
};

export default WorksheetListFilterByMechanics;