import {useWorksheetSaveState} from "modules/worksheet/editor/state/WorksheetSaveAtoms";
import WorksheetStatusMap from "crowbar-api/enum-map/WorksheetStatusMap";

export interface UseWorksheetSignedStateResult {
    /**
     * Edit disabled if the worksheet status is
     * signed or signSkipped or evaluated
     */
    editDisabled?: boolean

    /**
     * true if the worksheet is evaluated
     */
    evaluated?: boolean
}

const useWorksheetSignedState = (): UseWorksheetSignedStateResult => {
    const [ws] = useWorksheetSaveState()

    return {
        editDisabled: ws?.status === WorksheetStatusMap.finalSigned ||
            ws?.status === WorksheetStatusMap.finalSignSkipped ||
            ws?.status === WorksheetStatusMap.evaluated,
        evaluated: ws?.status === WorksheetStatusMap.evaluated
    }
}

export default useWorksheetSignedState