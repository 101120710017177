import {useMutation} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {CDAMWMEPartnerChangeWorksheetEvalUpdate, WorksheetEvalApi} from "crowbar-api";
import {AxiosRequestConfig} from "axios";

export const useWorksheetEvalUpdatePartner = () => {
    return useMutation(async (p: CDAMWMEPartnerChangeWorksheetEvalUpdate, options?: AxiosRequestConfig) => {
        await crowbarApiFactory(WorksheetEvalApi)
            .updatePartner(p, options)
    })
}