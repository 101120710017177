export const i18n_header_en = {
    "Nav": {
        "DeliveryNotes": "Delivery notes",
        "Worksheet": "Worksheet",
        "MyWorksheets": "My worksheets",
        "Admin": "Admin",
        "Images": "Images",
        "Equipments": "Equipments",
        "EquipmentImages": "Equipment images",
        "WorksheetTripItemCodes": "Worksheet trip ItemCodes",
        "WorksheetTimeItemCodes": "Worksheet time ItemCodes",
        "CallTypes": "Call types",
        "WorksheetAdmin": "Manage worksheets",
        "MechanicAdmin": "Mechanics",
        "ProductImages": "Product images",
        "PhotoRuleTemplates": "Photo step templates",
        "PhotoRules": "Photo rules",
        "SignOut": "Sign out",
        "Notifications": "Notification log",
        "CustomerNotificationConfiguration": "Customer notification configuration",
        "AgentNotificationConfiguration": "Agent notification configuration",

    },
    "Sidebar": {
        "MyWorksheets": "My worksheets",
        "Equipments": "Equipments",
        "Admin": "Admin",
    }
}