import React, {useState} from 'react';
import {useWorksheetPdfDownloadAsBase64} from "crowbar-api/hooks/worksheet/pdf/useWorksheetPdfDownloadAsBase64";
import {Box, CircularProgress, Grid, Theme, Typography, useMediaQuery} from "@mui/material";
import {Document, Page} from "react-pdf";
import {toastError} from "shared/toast/DefaultToasts";
import {useWindowSize} from "shared/hook/useWindowSize";

export type WorksheetPdfRenderType = {
    worksheetId: string
    layout: "admin" | "customer"
}

export const WorksheetPdfRender = ({
                                       worksheetId,
                                       layout
                                   }: WorksheetPdfRenderType) => {
    const lg = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))

    const {width,} = useWindowSize(100)

    const pdfBase64 = useWorksheetPdfDownloadAsBase64({
        worksheetId,
        layout,
        hideCustomerSignature: true
    })
    const [numPages, setNumPages] = useState(null);

    const onDocumentLoadSuccess = ({numPages: nextNumPages}: any) => {
        console.log("pdf loaded")
        setNumPages(nextNumPages)
    }

    if (pdfBase64.isLoading || pdfBase64.isFetching) {
        return (
            <CircularProgress/>
        )
    }

    if (!pdfBase64.data) {
        return (
            <Typography>Missing PDF</Typography>
        )
    } else {
        return (
            <Document
                file={pdfBase64.data}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={() => toastError("Failed to load PDF")}
                renderMode="svg" // canvas works as well, use it if svg causes problems (however canvas has the black flashes...)
                loading={<CircularProgress/>}
            >
                <Grid container spacing={2} sx={{
                    mb: 9
                }}>

                    {Array.from(new Array(numPages), (el, index) => (
                        <Grid
                            item
                            xs={12}
                            key={`page_${index + 1}`}
                            sx={{
                                display: "flex",
                                justifyContent: "center"
                            }}
                        >
                            <Box
                                sx={{
                                    border: "1px solid black",
                                    mb: 2,
                                }}
                            >
                                <Page
                                    pageNumber={index + 1}
                                    loading={<CircularProgress/>}
                                    width={
                                        lg
                                            ? width * 0.65
                                            : width * 0.85
                                    }
                                    scale={1}
                                />
                            </Box>
                        </Grid>

                    ))}

                </Grid>

            </Document>
        )
    }
}