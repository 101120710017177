import React from 'react';
import useTranslateFunction from "shared/language/useTranslateFunction";
import WorksheetSelectedDataSourceMap from "crowbar-api/enum-map/WorksheetSelectedDataSourceMap";
import ModSapTypeMap from "crowbar-api/enum-map/ModSapTypeMap";
import SapPartnerContactUtils from "crowbar-api/util/SapPartnerContactUtils";
import {Box, Stack, Typography} from "@mui/material";
import {CDAMWMWWorksheetQuery} from "crowbar-api";

export type PartnerContactMasterDataCurrentProps = {
    worksheet: CDAMWMWWorksheetQuery | null | undefined
}
const PartnerContactMasterDataCurrent = ({worksheet}: PartnerContactMasterDataCurrentProps) => {
    const tk = useTranslateFunction('Admin.Worksheet')

    const ws = worksheet

    if (!ws) return (
        <></>
    )

    const isMod = ws.contactPersonSource === WorksheetSelectedDataSourceMap.modSap
    const modId = ws.modSapPartnerContact
    const mod = ws.modSapPartnerContacts?.find(p => p.id === modId)

    const modCreate = mod?.type === ModSapTypeMap.create

    const contactStr = !isMod
        ? SapPartnerContactUtils.contactFormat({
            firstName: ws.contactFirstname ?? undefined,
            middleName: ws.contactMiddleName ?? undefined,
            lastName: ws.contactLastname ?? undefined
        })
        : SapPartnerContactUtils.contactFormatMod(mod)

    const email = !isMod
        ? ws?.contactEmail
        : mod?.email

    const phone = !isMod
        ? ws?.contactPhone1 ?? ws?.contactPhone2 ?? ws?.contactPhone3
        : mod?.phone

    return (
        <Stack direction="column" spacing={0.5}>
            {isMod && (
                <Box>
                    {modCreate ? (
                        <Typography color="red">{tk('NewPartnerContactOnWorksheet')}</Typography>
                    ) : (
                        <Typography color="red">{tk('EditedPartnerContactOnWorksheet')}</Typography>
                    )}
                </Box>
            )}
            <Typography>{contactStr}</Typography>
            <Typography>{email}</Typography>
            <Typography>{phone}</Typography>
        </Stack>
    );
};

export default PartnerContactMasterDataCurrent;