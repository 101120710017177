import React, {useEffect} from 'react';
import {TextField} from "@mui/material";
import {TextFieldProps} from "@mui/material/TextField/TextField";
import {useNewAtomWithRef} from "shared/hook/useNewatomWithRef";

export type TextFieldExProps = Omit<TextFieldProps, 'value' | 'onChange' | 'onBlur'> & {
    value: string | undefined | null
    /**
     * Called when the value is changed, but only when
     * the input lost focus. onBlur alias.
     * @param value
     */
    onBlur: (value: string) => Promise<void> | void
}

/**
 * Extended version of a normal TextField.
 * Allows only onBlur to read the changed values.
 * Handles the internal controlled state with its own atom and onChange calls.
 *
 * @param onBlur called when the input's value is changed (only on focus lost, onBlur)
 * @param value the input's starting value. Can be changed and the input will follow the change.
 * @param props Any other MUI TextField prop.
 * @constructor
 */
const TextFieldEx = ({onBlur, value, ...props}: TextFieldExProps) => {
    const [currentValue, setCurrentValue] = useNewAtomWithRef<string>(value ?? "")

    useEffect(() => {
        setCurrentValue(value ?? "")
    }, [setCurrentValue, value])

    return (
        <TextField
            value={currentValue}
            onChange={(e) => setCurrentValue(e.target.value ?? "")}
            onBlur={(e) => onBlur(e.target?.value ?? "")} /* calls parent */
            fullWidth
            {...props}
        />
    )
}

export default TextFieldEx;