import {CDAMNMEmailNotificationSource} from "crowbar-api";

export class EmailNotificationSourceMap {
    public static agent = CDAMNMEmailNotificationSource.NUMBER_1
    public static customer = CDAMNMEmailNotificationSource.NUMBER_2

    public static all = (): CDAMNMEmailNotificationSource[] => {
        return [
            EmailNotificationSourceMap.agent,
            EmailNotificationSourceMap.customer
        ]
    }

    public static translationKey = (t?: CDAMNMEmailNotificationSource) => {
        const preKey = "Admin.Worksheet.";

        if (t === EmailNotificationSourceMap.agent) {
            return preKey + "Agent"
        }

        if (t === EmailNotificationSourceMap.customer) {
            return preKey + "Customer"
        }

        return "Missing"
    }

    public static from = (value: number | string | undefined | null): CDAMNMEmailNotificationSource | undefined => {
        if (value === undefined || value === null || value < 0) return undefined
        return EmailNotificationSourceMap.all().find(n => n.valueOf() === +value)
    }
}