import React from 'react';
import {
    AgentNotificationConfigurationProps
} from "modules/admin/worksheet/notifications/agent-notification/AgentNotificationConfigurationProps";
import {
    useAgentNotificationConfigurationState
} from "modules/admin/worksheet/notifications/agent-notification/state/useAgentNotificationConfigurationState";
import {Box, CircularProgress, LinearProgress, Stack} from "@mui/material";
import {
    AgentNotificationConfigurationDialogButton
} from "modules/admin/worksheet/notifications/agent-notification/results/AgentNotificationConfigurationDialogButton";
import {
    AgentNotificationConfigurationResultsTable
} from "modules/admin/worksheet/notifications/agent-notification/results/AgentNotificationConfigurationResultsTable";

export type AgentNotificationConfigurationResultsProps = AgentNotificationConfigurationProps & {}

export const AgentNotificationConfigurationResults = ({stateAtom}: AgentNotificationConfigurationResultsProps) => {
    const {configurationQuery, employeesQuery} = useAgentNotificationConfigurationState(stateAtom)

    const employees = employeesQuery?.data ?? []

    const onStateChanged = async () => {
        await configurationQuery.refetch()
    }

    return (
        <Stack
            direction="column"
            spacing={3}
            width={1}
            alignItems="center"
            justifyContent="center"
            sx={{
                mb: 4
            }}
        >
            <Box alignItems="start"
                 justifyContent="start"
                 width={1}
            >
                {employeesQuery.isLoading ? (
                    <CircularProgress/>
                ) : (
                    <AgentNotificationConfigurationDialogButton
                        mode="new"
                        onConfigurationChange={onStateChanged}
                        variant="contained"
                        employees={employees}
                    />
                )}
            </Box>

            <AgentNotificationConfigurationResultsTable
                stateAtom={stateAtom}
                onStateChanged={onStateChanged}
            />

            {(configurationQuery.isLoading || configurationQuery.isFetching) && (
                <Box sx={{width: '100%'}}>
                    <LinearProgress/>
                </Box>
            )}
        </Stack>
    )
}