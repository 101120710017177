import {useQuery} from "@tanstack/react-query";
import {CDSMSapSalesPerson, SapSalesPersonApi} from "crowbar-api";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import AxiosCancelTokenHelper from "crowbar-api/AxiosCancelTokenHelper";
import {UseQueryOptionParams} from "crowbar-api/util/UseQueryOptionParams";

export const useSapSalesPersons = (extraOptions?: UseQueryOptionParams) => {
    return useQuery<CDSMSapSalesPerson[]>(["sap-sales-persons-1"], async (context) => {
        const response = await crowbarApiFactory(SapSalesPersonApi, undefined, true)
            .findAll(AxiosCancelTokenHelper.cancelTokenFrom(context.signal))
        return response.data ?? []
    }, {
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        staleTime: Math.pow(10, 8),
        cacheTime: Math.pow(10, 8),
        retry: 1,
        ...extraOptions
    })
}