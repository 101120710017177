import React, {ChangeEvent} from "react";

import {SetStateAction} from "jotai";
import {CDSMSSapItemForWorksheetSearchQueryParams} from "crowbar-api";
import {Checkbox, FormControlLabel, FormGroup, Grid, Stack} from "@mui/material";
import useTranslateFunction from "shared/language/useTranslateFunction";
import SearchBoxAndDebounce from "shared/components/inputs/SearchBoxAndDebounce";

interface AddItemFiltersProps {
    queryParams: CDSMSSapItemForWorksheetSearchQueryParams
    setQueryParams: (update: SetStateAction<CDSMSSapItemForWorksheetSearchQueryParams>) => void
    setSelected: (itemCode?: string) => void
    manuallySetQueryParams: boolean
}

const AddItemFilters = ({queryParams, setQueryParams, setSelected, manuallySetQueryParams}: AddItemFiltersProps) => {

    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    const onSearch = (searchValue: string) => {
        setSelected(undefined)
        setQueryParams(prev => {
            return {
                ...prev,
                search: searchValue,
                onlyFrequentlyUsed: manuallySetQueryParams ? prev.onlyFrequentlyUsed : false
            }
        })
    }

    const onFilterChange = (source: 'of' | 'is' | 'ic' | 'in' | 'sup' | 'sub', event: ChangeEvent<HTMLInputElement>) => {
        setSelected(undefined)
        switch (source) {
            case "of":
                setQueryParams(prev => {
                    return {
                        ...prev,
                        lastRowNumber: 0,
                        onlyFrequentlyUsed: event.target.checked
                    }
                })
                break;
            case "is":
                setQueryParams(prev => {
                    return {
                        ...prev,
                        lastRowNumber: 0,
                        onlyInStock: event.target.checked
                    }
                })
                break;
            case "ic":
                setQueryParams(prev => {
                    return {
                        ...prev,
                        lastRowNumber: 0,
                        searchInItemCode: event.target.checked
                    }
                })
                break;
            case "in":
                setQueryParams(prev => {
                    return {
                        ...prev,
                        lastRowNumber: 0,
                        searchInItemName: event.target.checked
                    }
                })
                break;
            case "sup":
                setQueryParams(prev => {
                    return {
                        ...prev,
                        lastRowNumber: 0,
                        searchInSuppCatNum: event.target.checked
                    }
                })
                break;
            case "sub":
                setQueryParams(prev => {
                    return {
                        ...prev,
                        lastRowNumber: 0,
                        searchInSubstitute: event.target.checked
                    }
                })
                break;
        }
    }

    return (
        <Grid container direction="row" spacing={1}>
            <Grid item xs={12} sm={6}>
                <Stack direction="column">
                    <SearchBoxAndDebounce
                        value=""
                        onChange={onSearch}
                        replaceStar={true}
                        size="small"
                    />
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={queryParams.onlyFrequentlyUsed}
                                    onChange={(e) => onFilterChange("of", e)}
                                />
                            }
                            label={tk('OnlyFrequentlyUsed')}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={queryParams.onlyInStock}
                                    onChange={(e) => onFilterChange("is", e)}
                                />
                            }
                            label={tk('OnlyInStock')}
                        />
                    </FormGroup>
                </Stack>

            </Grid>
            <Grid item xs={12} sm={6}>
                <FormGroup sx={{
                    marginLeft: {
                        xs: 0,
                        md: 2
                    }
                }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={queryParams.searchInItemCode}
                                onChange={(e) => onFilterChange("ic", e)}
                            />
                        }
                        label={tk('ItemCode')}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={queryParams.searchInItemName}
                                onChange={(e) => onFilterChange('in', e)}
                            />
                        }
                        label={tk('ItemName')}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={queryParams.searchInSuppCatNum}
                                onChange={(e) => onFilterChange('sub', e)}
                            />
                        }
                        label={tk('SuppCatNum')}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={queryParams.searchInSubstitute}
                                onChange={(e) => onFilterChange('sub', e)}
                            />
                        }
                        label={tk('Substitute')}
                    />
                </FormGroup>
            </Grid>
        </Grid>
    )
}

export default AddItemFilters