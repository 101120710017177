import React, {useRef} from 'react';
import {
    useWorksheetEvalUpdateContactAck
} from "crowbar-api/hooks/worksheet/evaluation/worksheet/useWorksheetEvalUpdateContactAck";
import {
    useWorksheetEvalUpdatePartnerContact
} from "crowbar-api/hooks/worksheet/evaluation/worksheet/useWorksheetEvalUpdatePartnerContact";
import useSapPartnerContactsByCardCode from "crowbar-api/hooks/sap/useSapPartnerContactsByCardCode";
import WorksheetSelectedDataSourceMap from "crowbar-api/enum-map/WorksheetSelectedDataSourceMap";
import {CircularProgress} from "@mui/material";
import {CheckIconFabWithLoading} from "shared/components/buttons/CheckIconFabWithLoading";
import {CDAMWMWorksheetEvalModel, CDAMWMWWorksheetQuery} from "crowbar-api";

export type PartnerContactMasterDataSelectAckProps = {
    wEval: CDAMWMWorksheetEvalModel | null | undefined
    worksheet: CDAMWMWWorksheetQuery | null | undefined
    onDataChange: () => void
}
const PartnerContactMasterDataSelectAck = ({
                                               wEval,
                                               worksheet,
                                               onDataChange
                                           }: PartnerContactMasterDataSelectAckProps) => {

    const ws = worksheet

    const ack = wEval?.contactAck

    const updateContactAck = useWorksheetEvalUpdateContactAck();
    const updatePartnerContact = useWorksheetEvalUpdatePartnerContact();

    const isUpdating = updateContactAck.isLoading || updatePartnerContact.isLoading

    const contactQuery = useSapPartnerContactsByCardCode(ws?.cardCode, {
        cacheTime: 0,
        staleTime: 0,
        keepPreviousData: true,
        refetchOnMount: true,
        refetchOnWindowFocus: true
    })
    const selectedContact = (contactQuery?.data ?? []).find(c =>
        c.contactCode === wEval?.selectedSapContactCode
    )
    const contactRef = useRef(selectedContact)
    contactRef.current = selectedContact

    const handleButtonClick = async () => {
        const p = contactRef.current

        if (ack || !p) {
            await updateServerAck(false)
        } else {
            await updatePartnerContact.mutateAsync({
                originalId: ws?.id,
                evalId: wEval?.id,
                contactCode: p.contactCode,
                contactEmail: p.email,
                contactFirstname: p.firstName,
                contactLastname: p.lastName,
                contactMiddleName: p.middleName,
                contactName: p.name,
                contactPersonSource: WorksheetSelectedDataSourceMap.sapMasterData,
                contactPhone1: p.phone1,
                contactPhone2: p.phone2,
                contactPhone3: p.phone3,
                contactPosition: p.position
            })

            await updateServerAck(true)
        }
        onDataChange()
    }

    const updateServerAck = async (newValue: boolean) => {
        await updateContactAck.mutateAsync({
            originalId: ws?.id,
            evalId: wEval?.id,
            field: newValue
        })
    }

    if (contactQuery.isLoading) {
        return (
            <CircularProgress/>
        )
    }

    return (
        <CheckIconFabWithLoading
            checked={ack}
            isLoading={isUpdating}
            disabled={isUpdating}
            onClick={handleButtonClick}
        />
    )
}

export default PartnerContactMasterDataSelectAck;