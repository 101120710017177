import {CDSMSapPartnerAddress} from "crowbar-api";
import SapPartnerAddressUtils from "crowbar-api/util/SapPartnerAddressUtils";

export default class CrowbarConstants {
    public static PhoenixForkliftCardCode = "V100729"

    public static PhoenixForkliftAddress: CDSMSapPartnerAddress = {
        cardCode: "V100729",
        addressType: "B",
        addressName: "Jánoshalma",
        country: "HU",
        zipCode: "6440",
        city: "Jánoshalma",
        street: "Kisszállási út",
        streetNo: "11."
    }
    public static PhoenixForkliftAddressFormat = SapPartnerAddressUtils.addressFormat(CrowbarConstants.PhoenixForkliftAddress)
    public static PhoenixForkliftAddressKey = SapPartnerAddressUtils.addressKey(CrowbarConstants.PhoenixForkliftAddress)

    public static IntegerMax = 2147483647 // Math.pow(2, 32) / 2 - 1 // C# int
}