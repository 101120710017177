import {useQuery} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {ServiceCallItemRowApi} from "crowbar-api";
import AxiosCancelTokenHelper from "crowbar-api/AxiosCancelTokenHelper";
import {UseQueryOptionParams} from "crowbar-api/util/UseQueryOptionParams";

export const useServiceCallItemRowFindTripItemRowsByWorksheetId = (wId: string | undefined | null, extraOptions?: UseQueryOptionParams) => {
    return useQuery(['service-call-item-rows-find-trip-item-rows-by-wid', wId], async ({signal}) => {
        if (!wId) return []
        const response = await crowbarApiFactory(ServiceCallItemRowApi)
            .findTripItemRowsByWorksheetId(wId, AxiosCancelTokenHelper.cancelTokenFrom(signal))
        return response.data ?? []
    }, {
        cacheTime: 0,
        staleTime: 0,
        refetchOnWindowFocus: false,
        refetchOnMount: true,
        retry: 1,
        ...extraOptions
    })
}