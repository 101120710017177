// Used for microsoft auth when not embedded in teams
import {Configuration} from "@azure/msal-browser";
import {StringUtils} from "shared/utils/StringUtils";

const msalScopes = [
    // "access_as_user", "profile", "openid",
    "User.Read",
    // "offline_access"
];

/*
    In order to use the token cache,
    - Login scope and acquireTokenSilent scopes must match
    - maybe: msal needs an acquireTokenSilent call for each scope separately

    Only using a single User.Read scope worked for now. (Multiple scopes resulted in
    a token request every time).
 */

/*
const devAzureAdConfig = {
    tenantId: 'b42107e7-6ad1-4772-9f05-df2fdcc5ed83',
    clientId: '2dc06158-a68d-4c4f-9927-01b387809636',
    msalScopes: msalScopes,
    teamsDomain: 'frekvent-dev01.eu.ngrok.io'
}

const liveAzureAdConfig = {
    tenantId: 'b42107e7-6ad1-4772-9f05-df2fdcc5ed83',
    clientId: '8efc7947-4f34-4052-9724-67d01f48d6a3',
    msalScopes: msalScopes,
    teamsDomain: 'crowbar.frekvent.com'
}

export const azureAdConfig = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
    ? devAzureAdConfig : liveAzureAdConfig;
*/

export const azureAdConfig = {
    tenantId: StringUtils.notUndefinedNullAndEmpty(window.env.AZURE_TENANT_ID)
        ? window.env.AZURE_TENANT_ID
        : process.env.REACT_APP_TENANT_ID,
    clientId: StringUtils.notUndefinedNullAndEmpty(window.env.AZURE_CLIENT_ID)
        ? window.env.AZURE_CLIENT_ID
        : process.env.REACT_APP_CLIENT_ID,
    msalScopes: msalScopes,
    teamsDomain: StringUtils.notUndefinedNullAndEmpty(window.env.AZURE_TEAMS_DOMAIN)
        ? window.env.AZURE_TEAMS_DOMAIN
        : process.env.REACT_APP_TEAMS_DOMAIN
}

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/initialization.md
export const msalConfig: Configuration = {
    auth: {
        clientId: azureAdConfig.clientId ?? "",
        authority: `https://login.microsoftonline.com/${azureAdConfig.tenantId}`,
        redirectUri: `${window.location.origin}/blank`,
        postLogoutRedirectUri: `${window.location.origin}`
    },
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: isIE || isEdge || isFirefox, // Set this to "true" if you are having issues on IE11 or Edge
    }
}

