import React, {useState} from 'react';
import {Button, Menu, MenuItem} from "@mui/material";

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {NavLink} from "react-router-dom";
import {useUserContext} from "shared/hook/UserContext";
import useTranslateFunction from "shared/language/useTranslateFunction";

const NavbarAdminMenu = () => {
    const tk = useTranslateFunction('Header.Nav')
    const [userId, userClaims] = useUserContext()

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleOpenMenu = (e: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(e.currentTarget)
    }

    const handleCloseMenu = () => {
        setAnchorEl(null)
    }

    const isAdmin = userClaims?.filter(c => c === 'Administrator').length > 0

    if (!isAdmin) {
        return <></>
    }

    return (
        <>
            <Button onClick={handleOpenMenu} sx={{
                color: "text.primary"
            }}>
                {tk('Admin')}&nbsp;<ArrowDropDownIcon/>
            </Button>
            <Menu
                sx={{mt: '45px'}}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
            >

                <NavLink to="/admin/worksheet-admin" onClick={handleCloseMenu} style={{
                    textDecoration: "none"
                }}>
                    <MenuItem sx={{color: "text.primary"}}>
                        {tk('Worksheet')}
                    </MenuItem>
                </NavLink>
                <NavLink to="/admin/last-user-activities" onClick={handleCloseMenu} style={{
                    textDecoration: "none"
                }}>
                    <MenuItem sx={{color: "text.primary"}}>
                        Last user actions
                    </MenuItem>
                </NavLink>
            </Menu>
        </>
    );
};

export default NavbarAdminMenu;