import {useMutation} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {WorksheetCallTypeApi} from "crowbar-api";


export const useWorksheetCallTypeDelete = () => {
    return useMutation(async (id: number) => {
        await crowbarApiFactory(WorksheetCallTypeApi)
            .deletePermenentlyBy(id)
    })
}