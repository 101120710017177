import React from 'react';
import {CDAMWMESapEquipmentServiceCallBlobQuery} from "crowbar-api";
import {Button, darken, Dialog, DialogActions, DialogContent, Stack, TextField, Typography} from "@mui/material";
import CommentIcon from "@mui/icons-material/Comment";
import {useNewAtomWithRef} from "shared/hook/useNewatomWithRef";
import {useServiceCallBlobAtom} from "modules/worksheet/editor/state/atoms/useServiceCallBlobAtom";
import useTranslateFunction from "shared/language/useTranslateFunction";

export type WEditorPhotoEntryCommentProps = {
    blobQuery: CDAMWMESapEquipmentServiceCallBlobQuery

}
const WEditorPhotoEntryComment = ({blobQuery}: WEditorPhotoEntryCommentProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    const [open, setOpen] = useNewAtomWithRef<boolean>(false)
    const [currentValue, setCurrentValue] = useNewAtomWithRef<string>(blobQuery?.comment ?? "")

    const [localBlob, setLocalBlob] = useServiceCallBlobAtom(blobQuery.serviceCallId, blobQuery.blobId)
    const hasLocalBlob = !!localBlob

    const blobCommentValue = localBlob?.comment ?? blobQuery?.comment
    const displayBlobCommentValue = blobCommentValue ?? "-"

    const onClick = () => {
        setOpen(true)
    }

    const onTextBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        setCurrentValue(e.target.value ?? "")
    }

    const onClose = () => {
        setOpen(false)
    }

    const onOkClick = () => {
        setLocalBlob(prev => {
            return {
                ...prev,
                comment: currentValue
            }
        })
        onClose()
    }

    return (
        <>
            <Stack
                onClick={onClick}
                direction="row"
                spacing={1}
                sx={{
                    ":hover": {
                        backgroundColor: darken("#FFF", 0.1),
                        cursor: "pointer"
                    }
                }}
            >
                <CommentIcon/>
                <Typography sx={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis"
                }}>
                    {displayBlobCommentValue}
                </Typography>
            </Stack>

            <Dialog
                open={open}
                onClose={() => {/* noop */
                }}
                fullWidth
            >
                <DialogContent>
                    <TextField
                        defaultValue={blobCommentValue ?? ""}
                        onBlur={onTextBlur}
                        fullWidth
                        multiline={true}
                        rows={3}
                        disabled={!hasLocalBlob}
                    />
                </DialogContent>
                <DialogActions
                    sx={{
                        display: "flex",
                        justifyContent: "space-between"
                    }}
                >
                    <Button variant="outlined" color="error" onClick={onClose}>{tk('Cancel')}</Button>
                    <Button variant="contained" disabled={!hasLocalBlob} onClick={onOkClick}>OK</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default WEditorPhotoEntryComment;