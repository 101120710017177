import React from 'react';
import WEditorPageBasicCustomer from "modules/worksheet/editor/ui/basic/WEditorPageBasicCustomer";
import {Box, Card, CardContent, Grid, Stack, Typography} from "@mui/material";
import {useWorksheetSaveState} from "modules/worksheet/editor/state/WorksheetSaveAtoms";
import DateDisplay from "shared/components/date-display/DateDisplay";
import useWorksheetSignedState from "modules/worksheet/editor/state/helpers/useWorksheetSignedState";
import WorksheetSelectedDataSourceMap from "crowbar-api/enum-map/WorksheetSelectedDataSourceMap";
import useTranslateFunction from "shared/language/useTranslateFunction";
import DownloadIcon from '@mui/icons-material/Download';
import {DownloadFileUtils} from "shared/utils/DownloadFileUtils";
import {
    WorksheetDeleteButton
} from "modules/worksheet/editor/shared/component/worksheet-delete-button/WorksheetDeleteButton";

export interface WorksheetEditorPageBasicProps {

}

const WorksheetEditorPageBasic = ({}: WorksheetEditorPageBasicProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)
    // const [info] = useWorksheetEditorHeaderInfoAtom()

    const [ws] = useWorksheetSaveState()
    const {editDisabled} = useWorksheetSignedState()
    const salesOrders = (ws?.salesOrders ?? [])

    const salesOrder = salesOrders.find(() => true)

    const salesPerson = ws?.slpName
    const salesPersonPhone = ws?.slpPhone1 ?? ws?.slpPhone2

    return (
        <Grid container spacing={1}>

            <Grid item xs={12}
                  sx={{
                      display: "flex",
                      justifyContent: "space-between"
                  }}
            >
                <Box sx={{
                    display: "flex",
                    justifyContent: "start",
                    textAlign: "left"
                }}>
                    <Stack direction="column" spacing={0.25}>
                        {ws?.serialId && (
                            <Typography variant="h6" fontWeight="bold">
                                {ws.serialId}
                                <Box
                                    component="span"
                                    title="Download the worksheet as file (for debug reasons)"
                                    onClick={() => DownloadFileUtils.downloadAsJson(ws, `${ws.serialId ?? "data"}.json`)}
                                    sx={{
                                        ":hover": {
                                            cursor: "pointer"
                                        }
                                    }}
                                >
                                    <DownloadIcon sx={{fontSize: "10pt"}}/>
                                </Box>
                            </Typography>
                        )}
                        {ws?.createdAt && (
                            <Typography variant="caption">
                                <DateDisplay dateStr={ws.createdAt ?? ""}/>
                            </Typography>
                        )}
                        {(ws?.salesPersonSource !== undefined && ws.salesPersonSource !== WorksheetSelectedDataSourceMap.notSet) && (
                            <>
                                <Typography variant="caption">
                                    {tk('BasicSalesPersonTitle')}: {salesPerson}
                                </Typography>

                                {salesPersonPhone && (
                                    <Typography variant="caption">
                                        {tk('Phone')}: {salesPersonPhone}
                                    </Typography>
                                )}
                            </>
                        )}
                    </Stack>
                </Box>

                <Box sx={{
                    display: "flex",
                    justifyContent: "right",
                    textAlign: "right"
                }}>
                    <Stack direction="column" spacing={0.25}>
                        {salesOrder?.docNum && (
                            <Typography variant="h6" fontWeight="bold">
                                {salesOrder.docNum}
                            </Typography>
                        )}
                        <Typography>
                            {ws?.partnerCardName}
                        </Typography>
                        {salesOrder?.docDate && (
                            <Typography variant="caption">
                                <DateDisplay
                                    dateStr={salesOrder.docDate}
                                    options={{
                                        hour: undefined
                                    }}
                                />
                            </Typography>
                        )}
                        {salesOrder?.numAtCard && (
                            <Typography variant="body2">
                                {tk('NumAtCard')}: {salesOrder.numAtCard}
                            </Typography>
                        )}
                    </Stack>

                </Box>
            </Grid>

            <Grid item xs={12} sx={{padding: 1}}>
                <WEditorPageBasicCustomer
                    disabled={editDisabled}
                />
            </Grid>

            <Grid item xs={12}>
                <Card variant="outlined">
                    <CardContent>
                        <Stack direction="column" spacing={1}>
                            <Typography variant="h6" fontWeight="bold">
                                {tk('WorksheetDelete')}
                            </Typography>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center"
                                }}
                            >
                                <WorksheetDeleteButton
                                    disabled={editDisabled}
                                />
                            </Box>
                        </Stack>
                    </CardContent>
                </Card>

            </Grid>

        </Grid>
    )
}

export default WorksheetEditorPageBasic;