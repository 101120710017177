import {CDAMDMLeaseStatus} from "crowbar-api";

export default class LeaseStatusMap {
    public open = CDAMDMLeaseStatus.NUMBER_1;
    public finished = CDAMDMLeaseStatus.NUMBER_2;

    public translationKey(preKey: string, t?: CDAMDMLeaseStatus): string {
        if (!t) {
            return "Missing";
        }
        if (t === CDAMDMLeaseStatus.NUMBER_1) {
            return preKey + "Open";
        }
        if (t === CDAMDMLeaseStatus.NUMBER_2) {
            return preKey + "Finished";
        }
        return "Missing";
    }

    public mapTo(s: string): CDAMDMLeaseStatus {
        if (this.open.toString() === s) {
            return this.open
        }
        if (this.finished.toString() === s) {
            return this.finished
        }
        return this.open
    }
}