import {Route, Routes} from "react-router-dom";
import NotFound from "shared/components/not-found/NotFound";
import React from "react";
import PhotoStepTemplateList from "modules/admin/worksheet/photo-rule/photo-step-template/PhotoStepTemplateList";
import PhotoRuleList from "modules/admin/worksheet/photo-rule/photo-rules/PhotoRuleList";
import PhotoRuleEdit from "modules/admin/worksheet/photo-rule/photo-rules/PhotoRuleEdit/PhotoRuleEdit";
import PhotoStepTemplateEdit from "modules/admin/worksheet/photo-rule/photo-step-template/edit/PhotoStepTemplateEdit";

const PhotoRuleRoutes = () => {
    return (
        <Routes>
            <Route path="templates" element={<PhotoStepTemplateList/>}/>
            <Route path="templates/new" element={<PhotoStepTemplateEdit mode="new"/>}/>
            <Route path="templates/edit/:templateId" element={<PhotoStepTemplateEdit mode="edit"/>}/>

            <Route path="rules" element={<PhotoRuleList/>}/>
            <Route path="rules/new" element={<PhotoRuleEdit/>}/>
            <Route path="rules/edit/:ruleId" element={<PhotoRuleEdit/>}/>

            <Route path="*" element={<NotFound/>}/>
        </Routes>
    )
}

export default PhotoRuleRoutes