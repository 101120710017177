import {useCallback} from "react";
import {WorksheetSaveWrapper} from "modules/worksheet/storage/WorksheetSaveStorage";
import {StringUtils} from "shared/utils/StringUtils";
import {useWorksheetSaveService} from "modules/worksheet/service/useWorksheetSaveService";

export const useWorksheetLocalFindWrapper = () => {
    const worksheetSaveService = useWorksheetSaveService()
    return useCallback(async (worksheetId: string | null | undefined): Promise<WorksheetSaveWrapper | null> => {
        if (StringUtils.undefinedNullOrEmpty(worksheetId)) {
            return null
        }
        return await worksheetSaveService.findWrapper(worksheetId!)
    }, [worksheetSaveService])
}