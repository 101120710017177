import {CDAMWMServiceCallOperatingHoursType} from "crowbar-api";

export default class ServiceCallOperatingHoursTypeMap {
    public static readonly provided = CDAMWMServiceCallOperatingHoursType.NUMBER_0
    public static readonly wontProvideData = CDAMWMServiceCallOperatingHoursType.NUMBER_1
    public static readonly cantRead = CDAMWMServiceCallOperatingHoursType.NUMBER_2
    public static readonly counterNotWorking = CDAMWMServiceCallOperatingHoursType.NUMBER_3

    public static values = (): CDAMWMServiceCallOperatingHoursType[] => {
        return [
            ServiceCallOperatingHoursTypeMap.provided,
            ServiceCallOperatingHoursTypeMap.wontProvideData,
            ServiceCallOperatingHoursTypeMap.cantRead,
            ServiceCallOperatingHoursTypeMap.counterNotWorking,
        ]
    }

    public static translationKey = (t?: CDAMWMServiceCallOperatingHoursType): string => {

        const preKey = "Worksheet.Generic.ServiceCallOperatingHoursType."

        if (t === this.provided) {
            return `${preKey}Provided`
        }

        if (t === this.wontProvideData) {
            return `${preKey}WontProvideData`
        }

        if (t === this.cantRead) {
            return `${preKey}CantRead`
        }

        if (t === this.counterNotWorking) {
            return `${preKey}CounterNotWorking`
        }

        return "Missing"
    }
}