import {useMutation} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {WorksheetApi} from "crowbar-api";

export type UseWorksheetUpdateSkipCustomerEmailProps = {
    worksheetId: string
    skipEmail: boolean
}

export const useWorksheetUpdateSkipCustomerEmail = () => {
    return useMutation(async ({worksheetId, skipEmail}: UseWorksheetUpdateSkipCustomerEmailProps) => {
        await crowbarApiFactory(WorksheetApi)
            .updateSkipCustomerEmail(worksheetId, skipEmail)
    })
}