import React from 'react';
import SapPartnerAddressUtils from "crowbar-api/util/SapPartnerAddressUtils";
import {Box, Stack, Typography} from "@mui/material";
import WorksheetSelectedDataSourceMap from "crowbar-api/enum-map/WorksheetSelectedDataSourceMap";
import ModSapTypeMap from "crowbar-api/enum-map/ModSapTypeMap";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {CDAMWMWWorksheetQuery} from "crowbar-api";

export type PartnerAddressMasterDataCurrentProps = {
    worksheet: CDAMWMWWorksheetQuery | null | undefined
}
const PartnerAddressMasterDataCurrent = ({worksheet}: PartnerAddressMasterDataCurrentProps) => {
    const tk = useTranslateFunction('Admin.Worksheet')
    const ws = worksheet

    if (!ws) return (
        <></>
    )

    const isMod = ws.partnerAddressSource === WorksheetSelectedDataSourceMap.modSap
    const modId = ws.modSapPartnerAddressId
    const mod = ws.modSapPartnerAddresses?.find(p => p.id === modId)

    const modCreate = mod?.type === ModSapTypeMap.create

    const address = !isMod
        ? SapPartnerAddressUtils.addressFormatWorksheet(ws)
        : SapPartnerAddressUtils.addressFormat(mod)

    return (
        <Stack direction="column" spacing={0.5}>
            {isMod && (
                <Box>
                    {modCreate ? (
                        <Typography color="red">{tk('NewPartnerAddressOnWorksheet')}</Typography>
                    ) : (
                        <Typography color="red">{tk('EditedPartnerAddressOnWorksheet')}</Typography>
                    )}
                </Box>
            )}
            <Typography>{address}</Typography>
        </Stack>
    );
};

export default PartnerAddressMasterDataCurrent;