import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack
} from "@mui/material";
import {useNewAtomWithRef} from "shared/hook/useNewatomWithRef";
import usePhotoRuleTemplates from "modules/admin/worksheet/photo-rule/photo-rules/PhotoRuleEdit/usePhotoRuleTemplates";
import usePhotoRuleStepSave from "modules/admin/worksheet/photo-rule/photo-rules/PhotoRuleEdit/usePhotoRuleStepSave";
import {CDAMPMPhotoRuleStepModel} from "crowbar-api";
import {toastError} from "shared/toast/DefaultToasts";
import useTranslateFunction from "shared/language/useTranslateFunction";

export interface LoadTemplateDialogProps {
    step: CDAMPMPhotoRuleStepModel
    afterStepSaved: () => void | Promise<void>
}

const LoadTemplateDialog = ({step, afterStepSaved}: LoadTemplateDialogProps) => {

    const [open, setOpen] = useNewAtomWithRef(false)
    const [selectedKey, setSelectedKey] = useNewAtomWithRef<string | undefined>(undefined)

    const tk = useTranslateFunction(`Admin.PhotoRule`)

    const stepSave = usePhotoRuleStepSave()
    const templatesQuery = usePhotoRuleTemplates()

    const onOkClick = async () => {

        const selected = templatesQuery.data?.find(t => t.id === selectedKey)
        if (!selected) {
            toastError('No selected template found.')
            return
        }

        const newStep: CDAMPMPhotoRuleStepModel = {
            ...step,
            overrideTemplate: false,
            title: selected.title,
            description: selected.description,
            photoRuleStepTemplateId: selected.id
        }

        await stepSave.mutateAsync(newStep, {
            onSettled: async () => {
                await afterStepSaved()
                setOpen(false)
            }
        })
    }

    const openDialog = async () => {
        setOpen(true)
        await templatesQuery.refetch()
    }

    return (
        <>
            <Button variant="outlined" color="primary" onClick={openDialog}>{tk('LoadTemplate')}</Button>

            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                fullWidth
            >
                <DialogTitle>{tk('LoadTemplate')}</DialogTitle>
                <DialogContent>
                    {templatesQuery.isLoading ? (
                        <CircularProgress/>
                    ) : (
                        <FormControl fullWidth sx={{
                            marginTop: 2
                        }}>
                            <InputLabel id="select-label">{tk('Template')}</InputLabel>
                            <Select
                                labelId="select-label"
                                fullWidth
                                value={selectedKey ?? ""}
                                label={tk('Template')}
                                onChange={(e) => setSelectedKey(e.target.value)}
                            >
                                {templatesQuery.data?.map(t => (
                                    <MenuItem key={t.id} value={t.id}>{t.title}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                </DialogContent>
                <DialogActions>
                    <Stack direction="row" justifyContent="space-between" sx={{width: 1}}>
                        <Button
                            disabled={stepSave.isLoading}
                            variant="contained"
                            color="error"
                            onClick={() => setOpen(false)}>
                            {tk('Cancel')}
                        </Button>
                        <Button
                            disabled={!selectedKey || stepSave.isLoading}
                            variant="outlined"
                            color="primary"
                            onClick={onOkClick}
                        >
                            {tk('Ok')} {stepSave.isLoading && <CircularProgress/>}
                        </Button>
                    </Stack>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default LoadTemplateDialog