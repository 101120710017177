import React, {useState} from 'react';
import {Avatar, CircularProgress, IconButton, Menu, MenuItem, Tooltip, Typography} from "@mui/material";
import useProfileImage from "crowbar-api/hooks/user/useProfileImage";
import {useAtom} from "jotai";
import {msalTeamsAuthenticationHappenedAtom} from "App";
import {useNavigate} from "react-router-dom";
import msalAuth, {msalManualAuthenticationHappenedAtom} from "shared/msal/MsalAuth";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {useUserContext} from "shared/hook/UserContext";
import {useUserDetailsById} from "crowbar-api/hooks/user/useUserDetailsById";

const NavbarUserProfileIconButton = () => {
    const tk = useTranslateFunction('Header.Nav');

    const {isLoading, data: userProfileSmallImage, isError} = useProfileImage()
    const [teamsAuthHappened] = useAtom(msalTeamsAuthenticationHappenedAtom);
    const navigate = useNavigate()

    const [, setManualAuthHappened] = useAtom(msalManualAuthenticationHappenedAtom);

    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

    const [userId] = useUserContext()
    const userDetailsQuery = useUserDetailsById(userId)
    const userFullName = userDetailsQuery?.data?.fullName

    const onSignOut = async (): Promise<void> => {
        // navigate("/msal/logout")
        await msalAuth.logout()
        setManualAuthHappened(false)
        // navigate("/", {replace: true})
        navigate(0)
    }

    const handleCloseUserMenu = () => {
        setAnchorElUser(null)
    }

    return (
        <>
            <Tooltip title="Open settings">
                <IconButton onClick={(e) => {
                    setAnchorElUser(e.currentTarget)
                }} sx={{p: 0}}>
                    {isLoading && (
                        <CircularProgress/>
                    )}
                    {(!isError && !isLoading) && (
                        <Avatar alt="User"
                                src={`data:${userProfileSmallImage?.mime};base64,${userProfileSmallImage?.data}`}
                        />
                    )}
                </IconButton>
            </Tooltip>
            <Menu
                sx={{mt: '45px'}}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >

                <MenuItem dense>
                    <Typography textAlign="center" fontWeight="bold">{userFullName}</Typography>
                </MenuItem>

                <MenuItem onClick={onSignOut} disabled={teamsAuthHappened}>
                    <Typography textAlign="center">{tk('SignOut')}</Typography>
                </MenuItem>

            </Menu>
        </>
    );
};

export default NavbarUserProfileIconButton;