import React, {useEffect, useState} from 'react';
import {CDSMSapPartner} from "crowbar-api";
import {toastWarn} from "shared/toast/DefaultToasts";
import {useTranslation} from "react-i18next";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from "@mui/material";
import {ArrowCircleLeft, ArrowCircleRight} from "@mui/icons-material";
import BusinessPartnerTable from "./BusinessPartnerTable";
import SearchBoxAndDebounce from "shared/components/inputs/SearchBoxAndDebounce";
import {useSapPartnersPageBy} from "crowbar-api/hooks/sap/useSapPartnersPageBy";

interface BusinessPartnerDialogProps {
    sourceId: string
    open: boolean
    onCancel: (sourceId: string) => void
    onSelected: (sourceId: string, selected?: CDSMSapPartner) => void
}

const BusinessPartnerDialog = (props: BusinessPartnerDialogProps) => {

    const {t} = useTranslation();

    const [sourceId, setSourceId] = useState(props.sourceId)
    const [open, setOpen] = useState(props.open)
    const [lastRowNum, setLastRowNum] = useState(0)
    const limit = 14
    const [search, setSearch] = useState('')
    const [selected, setSelected] = useState<CDSMSapPartner | undefined>(undefined)

    const partnersQuery = useSapPartnersPageBy({
        lastRowNumber: lastRowNum,
        limit: limit,
        search: search,
        orderByIsDesc: false
    });

    const partners = partnersQuery.data ?? []
    // Props
    useEffect(() => {
        let changed = false;
        if (sourceId !== props.sourceId) {
            setSourceId(props.sourceId)
            changed = true
        }
        if (open !== props.open) {
            setOpen(props.open)
            changed = true
        }
        if (changed) {
            setLastRowNum(0)
            setSearch('')
            setSelected(undefined)
        }
    }, [open, sourceId, props.sourceId, props.open])

    useEffect(() => {

        return () => {
            setSelected(undefined); //to sync state change with deselection to avoid confusions
        }
    }, []);

    return (
        <Dialog open={open}
                hideBackdrop={false}
                fullWidth={true}
                maxWidth="lg"
        >
            <DialogTitle>{t('DeliveryNote.SelectPartner.Title')}</DialogTitle>
            <DialogContent>
                <Stack spacing={2} sx={{mt: 2}}>
                    <SearchBoxAndDebounce
                        value={search ?? ""}
                        size={"small"}
                        onChange={(newValue) => {
                            setLastRowNum(0);
                            setSelected(undefined);
                            setSearch(newValue);
                        }}
                    />

                    <BusinessPartnerTable
                        items={partners}
                        isLoading={partnersQuery.isLoading}
                        onSelectClick={(item) => {
                            setSelected(item);
                        }}
                        selected={selected}
                    />

                    <Stack direction={"row"}>
                        <Button disabled={partnersQuery.isLoading || lastRowNum < 1}
                                onClick={() => {
                                    setSelected(undefined);
                                    setLastRowNum(Math.max(lastRowNum - limit, 0))
                                }}
                        >
                            <ArrowCircleLeft/>
                        </Button>

                        <Button disabled={partnersQuery.isLoading || partners.length < limit}
                                onClick={() => {
                                    setSelected(undefined);
                                    setLastRowNum(lastRowNum + limit)
                                }}
                        >
                            <ArrowCircleRight/>
                        </Button>
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions sx={{display: "flex", justifyContent: "space-between"}}>
                <Button
                    variant="outlined"
                    onClick={() => {
                        setOpen(false)
                        setSelected(undefined)
                        // call parent
                        props.onCancel(sourceId)
                    }}
                >{t('DeliveryNote.SelectPartner.Cancel')}
                </Button>

                <Button
                    variant="contained"
                    onClick={() => {
                        if (!selected) {
                            toastWarn(t('DeliveryNote.SelectPartner.Please'))
                            return
                        }
                        setOpen(false)
                        // call parent
                        props.onSelected(sourceId, selected)
                    }}
                >
                    {t('DeliveryNote.SelectPartner.SelectBtn')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default BusinessPartnerDialog;

