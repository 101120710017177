export const i18n_adminHu = {
    "EquipmentImages": {
        "Title": "Berendezések képei",
        "Loading": "Betöltés...",
        "Manufacturer": "Gyártó",
        "Set": "Beállítás",
        "ColManufacturerName": "Gyártó",
        "ColTypeName": "Típus",
        "ColImage": "Kép",
        "SetSuccessMessage": "Kép sikeresen beállítva a kiválasztott gépekre."
    },
    "ProductImages": {
        "Title": "Termékek képei",
        "SearchPlaceholder": "Termékek keresése...",
        "Set": "Beállítás",
        "ColItemCode": "Cikkszám",
        "ColItemName": "Leírás",
        "ColImage": "Kép",
        "LoadMoreStart": "Továbbiak betöltése",
        "LoadMoreProgress": "Továbbiak betöltése...",
        "OnlyFrequentlyUsedLabel": "Csak gyakran fogyó termékek?",
        "OnlyFrequentlyUsedOn": "Igen",
        "OnlyFrequentlyUsedOff": "Nem",
        "NoResult": "Nincs találat...",
        "SetSuccessMessage": "Kép sikeresen beállítva a kiválasztott cikkekre.",
    },
    "Mechanic": {
        "Title": "Szerelők",
        "Mechanic": "Szerelő",
        "Remove": "Törlés",
        "Create": "Létrehozva",
        "Update": "Módosítva",
        "Add": "Hozzáadás",
        "Active": "Aktív?",
        "Yes": "Igen",
        "No": "Nem",
        "Edit": "Szerkesztés",
        "Cancel": "Mégsem",
        "SelectIds": "Azure felhasználó és SAP felhasználó megadása",
        "SapEmployee": "Sap alkalmazott",
        "AzureUser": "Azure felhasználó",
        "AzureSelectDesc": "Csak olyan felhasználó jelenik meg, ami hozzá lett rendelve a 'Mechanic' app role-hoz! (Azure AD -> Enterprise applications -> Users and groups).",
        "Save": "Mentés",
        "Delete": "Törlés",
    },
    "PhotoRule": {
        "PhotoRules": "Fényképezés szabályai",
        "Add": "Hozzáadás",
        "RuleTitle": "Szabály címe",
        "CreatedAt": "Létrehozva",
        "UpdatedAt": "Frissítve",
        "PhotoRuleEditTitle": "{{title}} szabály szerkesztése",
        "Manufacturer": "Gyártó",
        "ManufacturerAlreadyExists": "Gyártó már hozzá lett adva a szabályhoz..",
        "NoSelectedManufacturer": "Nincs kiválasztva gyártó.",
        "DeleteManufacturer": "Gyártó hozzárendelés törlése.",
        "AddStep": "Lépés hozzáadása",
        "Template": "Sablon",
        "Templates": "Sablonok",
        "EditTemplates": "Sablonok szerkesztése",
        "Title": "Cím",
        "Description": "Leírás",
        "Delete": "Törlés",
        "SaveAsTemplate": "Mentés sablonként",
        "LoadTemplate": "Sablon betöltése",
        "Override": "Sablon felülírása?",
        "Ok": "Ok",
        "Cancel": "Mégsem",
        "Save": "Mentés",
        "NameOfTemplate": "Sablon neve",
        "StepDeleted": "Lépés törölve.",
        "RuleDelete": "Szabály törlése",
        "RuleDeleted": "Szabály törölve.",
        "AddNew": "Új hozzáadása",
        "ReplaceExisting": "Meglévő cseréje",
        "ReplaceDescription": "Egy meglévő sablont ír felül a jelenlegi értékekkel.",
        "NewDescription": "Elmenti a jelenlegi értékeket új sablonként.",
        "TemplateInUse": "A sablon használatban van. A törlés váratlan eredményeket okozhatna.",
        "TemplateDeleted": "Sablon törölve.",
        "TemplateEdit": "Sablon szerkesztése",
        "TemplateSaved": "Sablon mentve.",
        "TemplateSaveFailed": "Sablon mentése sikertelen.",
        "DeleteTemplate": "Sablon törlése",
        "NewTemplate": "Új sablon",
        "NoManufacturersAdded": "Nincs hozzáadva gyártó!",
    },
    "Worksheet": {
        "WorksheetAdmin": "Munkalapok kezelése",
        "Mechanics": "Szerelők",
        "LoadMore": "Továbbiak betöltése",
        "CustomerVersion": "Vevői változat",
        "AdminVersion": "Admin változat",
        "ToEvaluated": "Kiértékelve",
        "ToEvaluatedTitle": "Munkalap státusz módosítása",
        "ToEvaluatedText": "Biztosan szeretné módosítani a munkalap státuszát kiértékeltre?",
        "CancelEvaluated": "Mégsem kiértékelt",
        "CancelEvaluatedTitle": "Munkalap státusz módosítása",
        "CancelEvaluatedText": "Biztosan szeretné módosítani a munkalap státuszát befejezettre? (Aláírással vagy aláírás nélkül a jelenlegi tárolt aláírástól függ)",
        "ShowDeleted": "Töröltek megjelenítése",
        "DeletedWorksheet": "Törölt munkalap!",
        "Delete": "Törlés",
        "Undelete": "Törlés visszavonása",
        "ChangeStatus": "Státusz módosítása",
        "Cancel": "Mégsem",
        "Save": "Mentés",
        "Status": "Státusz",
        "StateChangeWarn": "A munkalap manuális státusz módosítása eredményezhet érvénytelen munkalapot is! (Ilyen esetben nincsenek kényszerítve az ellenőrzések, amik a munkalap írása közben kötelezőek. Például, hogy legyen minden sor ellenőrizve vagy legyen feladat leírás.)",
        "StateChangeWarn2": "Szerelő által éppen szerkesztés alatt lévő munkalap az ő mentésének megfelelően felülírhatja ezt a módosítást.",
        "EmailNotifications": "E-mail értesítések",
        "DateFilter": "Munkalap létrehozásának kezdete és vége",
        "LoadingNotifications": "Értesítések betöltése",
        "RowNum": "#",
        "Source": "Forrás típus",
        "Worksheet": "Munkalap",
        "CreatedAt": "Létrehozva",
        "EmailCreatedAt": "Email létrehozása",
        "Agent": "Ügyintéző",
        "Customer": "Vevő",
        "Missing": "Hiányzó adat",
        "Resend": "Újraküldés",
        "RevokeResend": "Újraküldés visszavonása",
        "MarkedAsResend": "Újraküldésre jelölve.",
        "ResendRevoked": "Újraküldés visszavonva.",
        "Name": "Név",
        "CustomerNotificationConfiguration": "Vevői értesítések beállításai",
        "EmailNotificationsEnabled": "Engedélyezve?",
        "EmailNotificationsEnabledEx": "E-mail értesítések engedélyezve?",
        "NotifyOnOwnPremiseWorksheets": "Helyi munkákról?",
        "NotifyOnOwnPremiseWorksheetsEx": "Értesítés küldése belső munkákról is?",
        "Yes": "Igen",
        "No": "Nem",
        "Edit": "Szerkesztés",
        "AddNew": "Új hozzáadása",
        "PressEnterToAdd": "Nyomjon enter-t hogy hozzáadja az email címet.",
        "Employee": "Alkalmazott",
        "NotifyOnFinishedWorksheet": "Értesítés?",
        "NotifyOnFinishedWorksheetEx": "Értesítés küldése befejezett munkalapokról.",
        "NotifyOnFinishedWorksheetWithFaults": "Csak hibaleírással?",
        "NotifyOnFinishedWorksheetWithFaultsEx": "Értesítés küldése hibaleírással rendelkező munkalapokról.",
        "OnlyNotifyIfCustomerSalesPerson": "Csak ha értékesítő?",
        "OnlyNotifyIfCustomerSalesPersonEx": "Csak akkor értesítsen, ha az alkalmazott az ügyfél beállított értékesítője.",
        "AgentNotificationConfiguration": "Ügyintéző e-mail értesítések beállításai",
        "EvaluateButton": "Kiértékeltre váltás",
        "CustomerCode": "Vevőkód",
        "CustomerName": "Név",
        "SalesOrder": "Vevői rendelés",
        "SalesOrderDate": "Rendelés ideje",
        "TripDistanceKm": "Munkalap kiszállási díj",
        "WorksheetDate": "Munkavégzés ideje",
        "StartDate": "Kezdés napja",
        "EndDate": "Befejezés napja",
        "LastStatusChange": "Utolsó státuszmódosítás",
        "SubstitutesTaskDescription": "Leírást helyettesít?",
        "New": "Új",
        "Ok": "Ok",
        "CallTypeDialogDescription": "Ezek szervizhívás típusok választhatóak munkalap írásakor. SAP hívás típus megfeleltetése esetén admin-on kiválasztásra kerül, de nem kötelező.",
        "CallType": "Hívás típus",
        "NameDescription": "Megjelenített név. (Eltérhet az SAP névtől).",
        "SubstitutesTaskDescriptionEx": "Leírást helyettesít? (Ha igen, nem kötelező megadni feladat leírást munkalap írása közben.)",
        "VisOrder": "Sorrend",
        "MasterData": "Törzsadatok",
        "ServiceCalls": "Szervizhívások",
        "History": "Történet",
        "Comment": "Megjegyzés",
        "Feedback": "Értékelés",
        "RefreshSAPData": "SAP adatok frissítése",
        "Partner": "Partner",
        "Contact": "Kapcsolattartó",
        "Address": "Cím",
        "TaskAddress": "Munkavégzés helye",
        "NewPartnerOnWorksheet": "Új partner a munkalapon",
        "EditedPartnerOnWorksheet": "Módosított partner a munkalapon",
        "NewPartnerAddressOnWorksheet": "Új cím a munkalapon",
        "EditedPartnerAddressOnWorksheet": "Módosított cím a munkalapon",
        "NewPartnerContactOnWorksheet": "Új kapcsolattartó a munkalapon",
        "EditedPartnerContactOnWorksheet": "Módosított kapcsolattartó a munkalapon",
        "PleaseChoose": "Kérem válasszon",
        "Type": "Típus",
        "Equipment": "Gép",
        "NewEquipment": "Új gép",
        "EditedEquipment": "Szerkesztett gép",
        "Subject": "Tárgy",
        "Description": "Leírás",
        "ManufacturerSn": "Gy.szám",
        "Images": "Képek",
        "Delivery": "Szállítás",
        "ServiceCall": "Szhívás",
        "Select": "Kiválaszt",
        "Change": "Módosít",
        "EquipmentChangeConfirmText": "Biztosan cserélni szeretnék a munkalapon megadott gépet a jelenleg kiválasztott gépre? (Minden gép adat felül lesz írva a munkalapon).",
        "ReplaceEquipment": "Gép cseréje",
        "ManufacturerAndVehicleType": "Gyártó, típus",
        "InternalSn": "Házi azonosító",
        "YoM": "Gy. év",
        "TaskDescription": "Elvégzett munka leírása",
        "FaultDescription": "Hibaleírás",
        "RequiredToReplaceModEquipment": "Kézzel hozzáadott gép cseréje kötelező!",
        "SapServiceCall": "SAP szervizhívás",
        "Catalogue": "Darabjegyzék",
        "SapCallType": "Hívás típus",
        "SapTechnician": "Technikus",
        "CurrentOperatingHours": "Üzemóra",
        "Dim1": "Üzletág",
        "Dim2": "Személy",
        "Dim4": "Lead",
        "Overwrite": "Felülír",
        "Open": "Nyitott",
        "Closed": "Zárt",
        "SalesOrderQuantity": "Vevői rendelésben",
        "InstalledQuantity": "Munkalapon",
        "DeliveryQuantity": "Szállítás mennyisége",
        "UnitOfQuantity": "ME",
        "RowAck": "",
        "ServiceCallLong": "Szervizhívás",
        "LinkToSalesOrderDescription": "Vevői rendeléshez csatolás (használja a vevői rendelést forrásként a szállítás létrehozásakor).",
        "CreateNewDraft": "Tervezet létrehozása",
        "CreateNewDelivery": "Szállítás létrehozása",
        "DraftNumber": "Tervezet száma",
        "LinkToDeliveryDescription": "Szállításhoz csatolás (használja a szállítás résznél létrehozott szállítást kapcsolt bizonylatként a szervizhívás létrehozásakor).",
        "CreateNew": "Létrehozás",
        "DeliveryAsDraft": "Tervezet létrehozása",
        "ManuallySetDeliveryDocEntry": "Szállítás megadása kézzel.",
        "FindingDraft": "Tervezet keresése...",
        "FindingDelivery": "Szállítás keresése...",
        "FindingServiceCall": "Szervizhívás keresése...",
        "InvalidDeliveryNumber": "Érvénytelen a szállítás száma. Csak létező szállítás adható meg. (SAP hozzáadáshoz képest egy kis ideig eltarthat, amíg elérhető lesz (< 1 perc)).",
        "AckAndRequiredToReplaceModEquipment": "Kiválasztott gép elfogadása és kézzel hozzáadott gép cseréje kötelező!",
        "DeliveryMissingError": "Szállításhoz csatolás esetén kötelező a szállítás számának megadása és elfogadása (kézzel is meg lehet adni).",
        "RowAckRequired": "Minden sor elfogadása kötelező szállítás létrehozásához.",
        "SapCallTypeIsRequired": "SAP hívás típus megadása kötelező.",
        "TechnicianIsRequired": "Techikus megadása kötelező.",
        "ShippingAddress": "Szállítási cím",
        "BillingAddress": "Számlázási cím",
        "BillingAddressIsRequired": "Számlázási cím kiválasztása kötelező!",
        "PartnerAckRequired": "Partner törzsadat elfogadása kötelező!",
        "AddressAckRequired": "Partner cím törzsadat elfogadása kötelező!",
        "OnWorksheet": "Munkalapon:",
        "SapSalesPerson": "Értékesítő",
        "OriginalInstalledQuantity": "Munkalapon eredetileg",
        "TripWarnTitle": "Hiányzó kiszállási díj",
        "TripWarnText": "A munkalapon megadott kiszállási díj nem lett sorhoz hozzárendelve. Folytatja?",
        "TripDistanceOnRows": "Kiszállási díj",
        "FixesWorksheetDataIconTitle": "Automatikusan cseréli a munkalapon megadott adatokat, így a munkalap nyomtatott verziójában és a géptörténetben is helyesen szerepelnek.",
        "TripDistanceWarn": "A munkalapon {{tripSumOnWorksheet}} km kiszállási díj található, viszont a sorokhoz {{tripSumOnRows}} km lett hozzárendelve!",
        "ItemCode": "Cikkszám",
        "Add": "Hozzáadás",
        "AdminCommentLabel": "Saját megjegyzés a munkalaphoz:",
        "What?": "Mit?",
        "WorksheetOriginal": "Munkalapon eredetileg",
        "CurrentValue": "Aktuális érték",
        "EquipmentChanged": "Munkalapon szereplő gép cserélve!",
        "AddedDuringEval": "Adminon hozzáadva!",
        "WorksheetFields": "Munkalap mezői",
        "CallTypeIdChanged": "Hívás típus módosítva!",
        "StartedAt": "Kezdés napja",
        "EndedAt": "Befejezés napja",
        "TaskDescriptionChanged": "Munka leírása módosítva!",
        "FaultDescriptionChanged": "Hibaleírás módosítva!",
        "CommentChanged": "Megjegyzés módosítva!",
        "CurrentEquipmentOperatingHoursChanged": "Üzemóra módosítva!",
        "MissingSignature": "Hiányzó aláírás",
        "Signature": "Aláírás",
        "PartnerAckBeforeEquipmentAck": "Partner elfogadása kötelező gép elfogadása előtt.",
        "ChangeToFinalSigned": "Visszaváltás befejezett (aláírt) státuszra",
        "ChangedToFinalSignSkipped": "Visszaváltás befejezett (aláírás nélküli) státuszra",
        "Evaluation": "Kiértékelés",
        "SapOwnerCode": "Tulajdonos",
        "ExtraOptions": "További beállítások",
        "SkipCustomerEmail": "Ne küldjön e-mail értesítőt az ügyfélnek erről a munkalapról (kiértékeltre váltás után kihagyott státuszba fog kerülni az értesítő).",
        "ResetFilterByMechanicTitle": "Alaphelyzetbe állítás",
        "ClearFilter": "Szűrő ürítése (összes kiválasztott szerelő törlése).",
        "InvalidEquipmentForPartner": "Érvénytelen berendezés. A partnernél nem található meg jelenleg (esetleg partner csere volt?).",
        "OverrideEmailNotificationAddresses": "E-mail küldése és alapértelmezett vevői értesítő e-mail címzett(ek) felülírása.",
        "EmailRecipient": "Értesítő e-mail",
        "LinkEquipmentToServiceCall": "Munkalapon szereplő gép hozzárendelése a szervizhíváshoz.",
        "NoImagesFound": "Nem található kép.",
    },
    "EmailNotificationStatus": {
        "Success": "Sikeres",
        "Failure": "Sikertelen",
        "Skipped": "Kihagyott",
    }
}