import React from 'react';
import {Card, CardContent, Grid, Typography} from "@mui/material";
import PartnerMasterDataSelect from "modules/admin/worksheet/evaluation/tabs/master-data/PartnerMasterDataSelect";
import PartnerMasterDataCurrent from "modules/admin/worksheet/evaluation/tabs/master-data/PartnerMasterDataCurrent";
import PartnerMasterDataSelectAck from "modules/admin/worksheet/evaluation/tabs/master-data/PartnerMasterDataSelectAck";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {CDAMWMWorksheetEvalModel, CDAMWMWWorksheetQuery} from "crowbar-api";
import FixesWorksheetDataIcon from "modules/admin/worksheet/evaluation/shared/FixesWorksheetDataIcon";

export type PartnerMasterDataProps = {
    wEval: CDAMWMWorksheetEvalModel | null | undefined
    worksheet: CDAMWMWWorksheetQuery | null | undefined
    onDataChange: () => Promise<void>
}

const PartnerMasterData = ({wEval, worksheet, onDataChange}: PartnerMasterDataProps) => {
    const tk = useTranslateFunction('Admin.Worksheet')

    return (
        <Card variant="outlined">
            <CardContent>
                <Grid container spacing={1}>

                    <Grid item xs={12}>
                        <Typography variant="h6">Partner &nbsp;<FixesWorksheetDataIcon/></Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={0}>
                            <Grid item xs={6}>
                                <PartnerMasterDataCurrent worksheet={worksheet}/>
                            </Grid>
                            <Grid item xs={6}>

                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <Typography>
                                            SAP {tk('Partner')} *
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container direction="row" spacing={1} alignItems="center">
                                            <Grid item xs={10}>
                                                <PartnerMasterDataSelect wEval={wEval} worksheet={worksheet}
                                                                         onDataChange={onDataChange}/>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <PartnerMasterDataSelectAck wEval={wEval} worksheet={worksheet}
                                                                            onDataChange={onDataChange}/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </CardContent>
        </Card>
    );
};

export default PartnerMasterData;