export interface ImageIconProps {
    size?: number
    image: string
}

const ImageIcon = ({size, image}: ImageIconProps) => {
    return (
        <img src={image} alt="" height={size ?? 24} width={size ?? 24}/>
    )
}

export default ImageIcon