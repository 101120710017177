import React from 'react';
import Navbar from "layout/header/Navbar";
import Sidebar from "layout/header/Sidebar";

const Header = () => {
    return (
        <>
            <Navbar/>
            <Sidebar/>
        </>
    );
};

export default Header;