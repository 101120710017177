import {useQuery} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {
    CDAMNMCustomerNotificationConfigurationSearchParameters,
    CustomerNotificationConfigurationApi
} from "crowbar-api";
import AxiosCancelTokenHelper from "crowbar-api/AxiosCancelTokenHelper";


export const useCustomerNotificationConfigurationSearchPage = (params: CDAMNMCustomerNotificationConfigurationSearchParameters) => {
    return useQuery(['customer-notification-configuration-search-1', params], async ({signal}) => {
        const response = await crowbarApiFactory(CustomerNotificationConfigurationApi)
            .searchPageBy(params, AxiosCancelTokenHelper.cancelTokenFrom(signal))
        return response.data ?? []
    }, {
        networkMode: 'online', // will not fire unless we have network
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        cacheTime: 0,// Math.pow(10, 7),
        staleTime: 0, // Math.pow(10, 7),
    })
}