import {useQuery} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {PhotoRuleStepTemplateApi} from "crowbar-api";
import AxiosCancelTokenHelper from "crowbar-api/AxiosCancelTokenHelper";


const usePhotoRuleTemplates = () => {
    return useQuery(['photo-rule-templates'], async (context) => {
        const response = await crowbarApiFactory(PhotoRuleStepTemplateApi).pageAll({
            search: "",
            lastRowNumber: 0,
            limit: 400,
            showDeleted: false
        }, AxiosCancelTokenHelper.cancelTokenFrom(context.signal))

        if (response.status !== 200) {
            throw new Error('Failed to load photo rule templates.')
        }
        return response.data
    }, {
        initialData: []
    })
}

export default usePhotoRuleTemplates