import React from 'react';
import {Box, Button, Stack, Typography} from "@mui/material";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {useDisplayHelpDialogState} from "modules/worksheet/editor/ui/photo/useDisplayHelpDialogState";
import WEditorDisplayHelpDialog from "modules/worksheet/editor/ui/photo/WEditorDisplayHelpDialog";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {CDAMWMWServiceCallSave} from "crowbar-api";

export type WEditorDisplayHelpDialogButtonProps = {
    selectedServiceCall: CDAMWMWServiceCallSave | undefined
}
const WEditorDisplayHelpDialogButton = ({selectedServiceCall}: WEditorDisplayHelpDialogButtonProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    const {open, setDialogState} = useDisplayHelpDialogState()

    const onDialogOpen = () => {
        setDialogState(prev => {
            if (prev.selectedServiceCallId !== selectedServiceCall?.id) {
                return {
                    ...prev,
                    open: true,
                    selectedServiceCallId: selectedServiceCall?.id,
                    selectedManufacturerCode: selectedServiceCall?.manufacturer ?? undefined,
                    selectedRuleId: undefined
                }
            } else {
                return {
                    ...prev,
                    open: true,
                    selectedServiceCallId: selectedServiceCall?.id
                }
            }
        })
    }

    return (
        <>
            <Button
                variant="outlined"
                color="primary"
                component="label"
                disabled={open}
                sx={{
                    width: "145px",
                    height: "130px"
                }}
                onClick={() => onDialogOpen()}
            >
                <Stack direction="column">
                    <Box sx={{
                        width: 1,
                        display: "flex",
                        justifyContent: "center",
                        justifyItems: "center"
                    }}>
                        <HelpOutlineIcon sx={{
                            fontSize: "22pt"
                        }}/>
                    </Box>
                    <Box sx={{
                        width: 1,
                        textAlign: "center"
                    }}>
                        <Typography>{tk('DisplayHelp')}</Typography>
                    </Box>
                </Stack>
            </Button>

            <WEditorDisplayHelpDialog open={open}/>
        </>
    );
};

export default WEditorDisplayHelpDialogButton;