import useWorksheetTimeItemCodes from "crowbar-api/hooks/worksheet/worksheet-time-item-codes/useWorksheetTimeItemCodes";
import {useCallback} from "react";
import {CDAMWMWServiceCallItemRowSave} from "crowbar-api";

/**
 * Decides for the item row if the
 * row is used for work hour logging (time=true)
 * or not.
 * ItemCode is time:
 *  1. if the IsTime = true on the ServiceCallItemRow
 *  2. if the timeCodes contains the ItemCode (can be offline)
 */
export const useIsTimeMatchFn = () => {
    const timeCodes = useWorksheetTimeItemCodes()
    return useCallback((itemRow: CDAMWMWServiceCallItemRowSave) => {
        const isTimeMatch = itemRow.isTime
        const timeCodesMatch = (timeCodes?.data && timeCodes.data.find(c => c.itemCode === itemRow.itemCode) !== undefined)
        return isTimeMatch || timeCodesMatch
    }, [timeCodes.data])
}