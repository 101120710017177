import WEditorPageBasicPartnerAddressDialog
    from "modules/worksheet/editor/ui/basic/address-select/WEditorPageBasicPartnerAddressDialog";
import {useNewAtomWithRef} from "shared/hook/useNewatomWithRef";
import {
    PartnerAddressFormValues
} from "modules/worksheet/editor/shared/component/partner-address-form/usePartnerAddressForm";
import {CDAMWMWModSapPartnerAddressSave} from "crowbar-api";
import {toastError} from "shared/toast/DefaultToasts";
import {v4 as uuid} from 'uuid'
import ModSapTypeMap from "crowbar-api/enum-map/ModSapTypeMap";
import useTranslateFunction from "shared/language/useTranslateFunction";
import useFindSelectedSapPartnerAddressAsMod
    from "modules/worksheet/editor/ui/basic/address-select/useFindSelectedSapPartnerAddressAsMod";
import useSapPartnerAddressSelectValuesAtom
    from "modules/worksheet/editor/ui/basic/address-select/useSapPartnerAddressSelectValuesAtom";
import {useWorksheetSaveState} from "modules/worksheet/editor/state/WorksheetSaveAtoms";

const applyFormValuesToMod = (data: PartnerAddressFormValues, mod: CDAMWMWModSapPartnerAddressSave) => {
    mod.zipCode = data.zipCode?.toString()
    mod.city = data.city?.toString()
    mod.street = data.street?.toString()
    mod.streetNo = data.streetNo?.toString()
    mod.comment = data.comment?.toString()
}

interface WEditPageBasicPartnerAddressButtonProps {
    renderButton: (onClick: () => void, disabled?: boolean) => JSX.Element
    mode: 'new' | 'edit'
    selectedKey: string | undefined
    addNewMod: (mod: CDAMWMWModSapPartnerAddressSave, mode: ("new" | "edit")) => void
    //  selectedValueAsMod?: CDAMWMWModSapPartnerAddressSave | undefined
}

export const WEditPageBasicPartnerAddressButton = ({

                                                       renderButton,
                                                       mode,
                                                       // selectedValueAsMod,
                                                       selectedKey,
                                                       addNewMod
                                                   }: WEditPageBasicPartnerAddressButtonProps) => {

    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)
    const [dialogKey, setDialogKey] = useNewAtomWithRef<string>(uuid())
    const [worksheetSave] = useWorksheetSaveState()

    const [open, setOpen] = useNewAtomWithRef<boolean>(false)

    const {selectValues} = useSapPartnerAddressSelectValuesAtom(worksheetSave?.cardCode)
    const findSelectedAsMod = useFindSelectedSapPartnerAddressAsMod()

    const selectedValueAsMod = findSelectedAsMod(selectValues, selectedKey)

    /*const {selectedValueAsMod} = useSelectedSapPartnerAddressAsMod()
    const {selectedKey, addNewMod} = useSapPartnerAddressSelectValuesAtom( worksheetSave?.cardCode)*/

    const onBtnClick = () => {
        setDialogKey(uuid())
        setOpen(true)
    }

    const onSubmit = (data: PartnerAddressFormValues) => {
        if (!selectedValueAsMod) {
            toastError('Error during partner address submit. Edit mod is undefined.')
            return
        }
        const newMod: CDAMWMWModSapPartnerAddressSave = {
            ...selectedValueAsMod,
            id: mode === "new" ? uuid() : selectedValueAsMod.id
        };
        applyFormValuesToMod(data, newMod)
        newMod.type = newMod.type === ModSapTypeMap.create ? ModSapTypeMap.create
            : mode === "new" ? ModSapTypeMap.create : ModSapTypeMap.update
        addNewMod(newMod, mode)
    }

    return (
        <>
            {renderButton(onBtnClick, mode === 'edit' && !selectedKey)}

            <WEditorPageBasicPartnerAddressDialog
                key={dialogKey}
                mode={mode}
                open={open}
                onDismiss={() => setOpen(false)}
                editModSapPartnerAddress={mode === 'edit' ? selectedValueAsMod : undefined}
                onSubmit={onSubmit}
            />
        </>
    )
}