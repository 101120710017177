import React from 'react';
import {Navigate} from 'react-router-dom';
import {useUserContext} from "shared/hook/UserContext";

export interface RequireAuthProps {
    /**
     * Roles required to access this path
     */
    requiredRole: string

    children: JSX.Element
}

const RequireAuth = ({requiredRole, children}: RequireAuthProps): JSX.Element => {
    const [, userClaims] = useUserContext()

    if (userClaims && userClaims.indexOf(requiredRole) === -1) {
        // role not authorized so redirect to specific page
        return <Navigate to="/unauthorized"/>
    }

    // authorized to view the component
    return children ?? <></>;
};

export default RequireAuth;