import React from 'react';
import {DateUtils} from "shared/utils/DateUtils";
import TextFieldEx, {TextFieldExProps} from "shared/components/inputs/TextFieldEx";

export type NativeDateInputExProps = Omit<TextFieldExProps, "onBlur"> & {
    onBlurWithNativeValue?: (dateComponent: string) => void
    onBlurWithServerValue?: (dateComponent: string) => void
}
/**
 * Example defaultValue:
 *  defaultValue={DateUtils.intlDateToFormat(new Date(), "fr-CA")}
 * @param onBlurWithNativeValue
 * @param onBlurWithServerValue
 * @param props
 * @constructor
 */
const NativeDateInputEx = ({
                               onBlurWithNativeValue,
                               onBlurWithServerValue,
                               ...props
                           }: NativeDateInputExProps) => {

    const onBlur = (value: string) => {
        const json = DateUtils.dateToServerFormat(new Date(value))
        onBlurWithNativeValue?.(value)
        onBlurWithServerValue?.(json ?? "")
    }

    return (
        <TextFieldEx
            type="date"
            sx={{width: 170}}
            InputLabelProps={{
                shrink: true,
            }}
            //defaultValue={DateUtils.intlDateToFormat(new Date(), "fr-CA")}
            {...props}
            onBlur={onBlur}
        />
    );
};

export default NativeDateInputEx;