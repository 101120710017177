import React from 'react';
import {GenericErrorDisplay} from "shared/components/error/GenericErrorDisplay";
import {Box, Container, Grid, LinearProgress} from "@mui/material";
import WEvalHeader from "modules/admin/worksheet/evaluation/header/WEvalHeader";
import WEvalTabs from "modules/admin/worksheet/evaluation/tabs/WEvalTabs";
import {useWEvalQueries} from "modules/admin/worksheet/evaluation/state/useWEvalQueries";

const WorksheetEvaluationByWId = () => {
    const {wEvalQuery, worksheetQuery} = useWEvalQueries()

    const wEval = wEvalQuery?.data;
    const worksheet = worksheetQuery?.data;

    const queriesAreLoading = wEvalQuery.isLoading || worksheetQuery.isLoading
    const queriesAreFetching = wEvalQuery.isFetching || worksheetQuery.isFetching

    const loadingOrFetching = queriesAreLoading || queriesAreFetching

    if (!queriesAreLoading && !wEval) {
        return (
            <GenericErrorDisplay message="Failed to load worksheet by id."/>
        )
    }

    const onDataChange = async () => {
        wEvalQuery.refetch().then() // must be parallel!
        worksheetQuery.refetch().then()
    }

    return (
        <Container maxWidth="xl">
            <Grid container direction="column" spacing={1} sx={{mb: 6}}>

                <Grid item xs={12}>
                    <Box sx={{width: 1, height: "4px"}}>
                        {loadingOrFetching && (
                            <LinearProgress/>
                        )}
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <WEvalHeader wEval={wEval} worksheet={worksheet} onDataChange={onDataChange}/>
                </Grid>

                {(!queriesAreLoading && wEval && worksheet) && (
                    <Grid item xs={12}>
                        <WEvalTabs wEval={wEval} worksheet={worksheet} onDataChange={onDataChange}/>
                    </Grid>
                )}

            </Grid>
        </Container>
    )
}

export default WorksheetEvaluationByWId;