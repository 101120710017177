import {useWorksheetSaveService} from "modules/worksheet/service/useWorksheetSaveService";
import {useQuery} from "@tanstack/react-query";
import {CDAMWMWWorksheetVersion} from "crowbar-api";

/**
 * Read all the local worksheet objects and prepares the same object
 * the server returns when we check versions.
 */
export const useLocalWorksheetVersions = () => {
    const saveService = useWorksheetSaveService()
    return useQuery(['local-worksheet-versions'], async (): Promise<CDAMWMWWorksheetVersion[]> => {

        const localEntries = await saveService.findAll()
        return localEntries.map((e): CDAMWMWWorksheetVersion => {
            return {
                id: e.id,
                serialId: e.serialId,
                version: e.version,
                previousVersion: e.previousVersion,
                ownerId: e.ownerId
            }
        })
    }, {
        networkMode: 'offlineFirst',
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        cacheTime: 0,
        staleTime: 0,
    })
}