import {useQuery} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {ServiceCallItemRowEvalApi} from "crowbar-api";
import AxiosCancelTokenHelper from "crowbar-api/AxiosCancelTokenHelper";
import {UseQueryOptionParams} from "crowbar-api/util/UseQueryOptionParams";

export const useServiceCallItemRowEvalFindByServiceCallEvalId = (scEvalId?: string, options?: UseQueryOptionParams) => {
    return useQuery(['service-call-item-row-eval-find-by-service-call-eval-id', scEvalId], async ({signal}) => {
        if (!scEvalId) return []
        const response = await crowbarApiFactory(ServiceCallItemRowEvalApi)
            .findByServiceCallEvalId(scEvalId, AxiosCancelTokenHelper.cancelTokenFrom(signal))
        return response.data ?? []
    }, {
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        retry: 1,
        staleTime: 0,
        cacheTime: 0,
        ...options
    })
}