import {atom, SetStateAction, useAtom} from "jotai";

export interface UseNotificationCenterStateResult extends NotificationCenterState {
    setState: (update: SetStateAction<NotificationCenterState>) => void
}

export interface NotificationCenterState {
    showRead: boolean
}

const defaultState: NotificationCenterState = {
    showRead: false
}

const notificationCenterStateAtom = atom<NotificationCenterState>(defaultState)

export const useNotificationCenterState = (): UseNotificationCenterStateResult => {
    const [state, setState] = useAtom(notificationCenterStateAtom)

    return {
        ...state,
        setState
    }
}