import {useMutation} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {CDAMWMEOperatingHoursServiceCallEvalUpdate, ServiceCallEvalApi} from "crowbar-api";
import {AxiosRequestConfig} from "axios";

export const useServiceCallEvalUpdateCurrentEquipmentOperatingHours = () => {
    return useMutation(async (p: CDAMWMEOperatingHoursServiceCallEvalUpdate, options?: AxiosRequestConfig) => {
        await crowbarApiFactory(ServiceCallEvalApi)
            .updateCurrentEquipmentOperatingHours(p, options)
    })
}