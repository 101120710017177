import {useQuery} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {WorksheetCallTypeApi} from "crowbar-api";
import AxiosCancelTokenHelper from "crowbar-api/AxiosCancelTokenHelper";
import {byPropertiesOf} from "shared/utils/ArraySort";

export const worksheetCallTypeFindAll = async (signal?: AbortSignal | undefined) => {
    const response = await crowbarApiFactory(WorksheetCallTypeApi)
        .pageAll({
            lastRowNumber: 0,
            limit: 1000,
            orderByIsDesc: false,
            search: "",
            showDeleted: false
        }, AxiosCancelTokenHelper.cancelTokenFrom(signal))
    return (response.data ?? []).sort(byPropertiesOf(['visOrder']))
}

export const useWorksheetCallTypeFindAll = () => {
    return useQuery(['worksheet-call-type-find-all-1'], async ({signal}) => {
        return worksheetCallTypeFindAll(signal)
    }, {
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        retry: 1,
        staleTime: Math.pow(10, 8),
        cacheTime: Math.pow(10, 8)
    })
}