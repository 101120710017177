import React from 'react';
import {
    useServiceCallEvalUpdateManuallySetDeliveryDocEntry
} from "crowbar-api/hooks/worksheet/evaluation/service-call/useServiceCallEvalUpdateManuallySetDeliveryDocEntry";
import {CDAMWMServiceCallEvalModel, CDAMWMServiceCallModel, CDAMWMWWorksheetQuery} from "crowbar-api";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {Checkbox, FormControlLabel} from "@mui/material";

export type UpdateManuallyCheckboxProps = {
    worksheet: CDAMWMWWorksheetQuery | null | undefined
    sc: CDAMWMServiceCallModel
    scEval: CDAMWMServiceCallEvalModel
    disabled?: boolean
    onMasterDataChange: () => Promise<void>
}

const UpdateManuallyCheckbox = ({
                                    worksheet,
                                    sc,
                                    scEval,
                                    disabled,
                                    onMasterDataChange
                                }: UpdateManuallyCheckboxProps) => {
    const tk = useTranslateFunction('Admin.Worksheet')
    const updateManuallySetDeliveryDocEntry = useServiceCallEvalUpdateManuallySetDeliveryDocEntry()

    const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked;
        await updateManuallySetDeliveryDocEntry.mutateAsync({
            evalId: scEval.id,
            originalId: scEval.serviceCallId,
            field: checked
        })
        await onMasterDataChange()
    }

    const value = scEval?.manuallySetDeliveryDocEntry ?? false

    return (
        <FormControlLabel
            disabled={disabled || updateManuallySetDeliveryDocEntry.isLoading || !!scEval.deliveryAck}
            control={
                <Checkbox
                    onChange={onChange}
                    checked={value}
                />
            }
            label={tk('ManuallySetDeliveryDocEntry')}
        />
    )
}

export default UpdateManuallyCheckbox;