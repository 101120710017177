import React from 'react';
import useTranslateFunction from "shared/language/useTranslateFunction";
import {UseNewPartnerFormResult} from "modules/worksheet/new-worksheet/new-partner/useNewPartnerForm";
import {Box, Stack, TextField} from "@mui/material";

export interface NewPartnerFormProps {
    form: UseNewPartnerFormResult
}

const NewPartnerForm = ({form}: NewPartnerFormProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.NewWorksheet`)

    const {
        formState: {errors},
        partnerNameRegister,
        vatLocalRegister,
        vatInternationalRegister,
        commentRegister
    } = form

    return (
        <Box sx={{
            width: 1,
            mt: 2
        }}>
            <Stack direction="column" spacing={1}>

                <TextField
                    inputRef={partnerNameRegister.ref}
                    error={!!errors?.partnerName?.message}
                    helperText={errors?.partnerName?.message}
                    fullWidth
                    InputLabelProps={{shrink: true}}
                    InputProps={{
                        autoComplete: "off"
                    }}
                    label={tk('PartnerName')}
                    {...partnerNameRegister}
                />

                <TextField
                    inputRef={vatLocalRegister.ref}
                    error={!!errors?.vatLocal?.message}
                    helperText={errors?.vatLocal?.message}
                    fullWidth
                    InputLabelProps={{shrink: true}}
                    InputProps={{
                        autoComplete: "off"
                    }}
                    label={tk('VatLocal')}
                    {...vatLocalRegister}
                />

                <TextField
                    inputRef={vatInternationalRegister.ref}
                    error={!!errors?.vatInternational?.message}
                    helperText={errors?.vatInternational?.message}
                    fullWidth
                    InputLabelProps={{shrink: true}}
                    InputProps={{
                        autoComplete: "off"
                    }}
                    label={tk('VatInternational')}
                    {...vatInternationalRegister}
                />

                <TextField
                    inputRef={commentRegister.ref}
                    error={!!errors.comment?.message}
                    helperText={errors.comment?.message}
                    fullWidth
                    multiline={true}
                    rows={3}
                    InputLabelProps={{shrink: true}}
                    label={tk('Comment')}
                    {...commentRegister}
                />

            </Stack>
        </Box>
    );
};

export default NewPartnerForm;