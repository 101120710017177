import {CDAMWMWServiceCallSave, CDAMWMWWorksheetSave} from 'crowbar-api';
import React from 'react';
import {Grid, Stack, Typography} from "@mui/material";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {StringUtils} from "shared/utils/StringUtils";
import DateDisplay, {dateDisplayDefaultOptions} from "shared/components/date-display/DateDisplay";
import WEditorEquipmentTextLines from "modules/worksheet/editor/ui/equipments/WEditorEquipmentTextLines";
import ServiceCallSelectedSourceMap from "crowbar-api/enum-map/ServiceCallSelectedSourceMap";

export interface WEditorHeaderContentCompactDetailsProps {
    worksheet: CDAMWMWWorksheetSave
    serviceCall: CDAMWMWServiceCallSave
}

const WEditorHeaderContentCompactDetails = ({worksheet, serviceCall}: WEditorHeaderContentCompactDetailsProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    // const fromSalesOrder = serviceCall.selectedSource === ServiceCallSelectedSourceMap.sapSalesOrder
    const salesOrder = worksheet.salesOrders?.[0]

    const hasManufacturerSn = StringUtils.notUndefinedNullAndEmpty(serviceCall.manufacturerSn)

    const isEmpty = serviceCall?.selectedSource === ServiceCallSelectedSourceMap.empty

    if (!hasManufacturerSn) {
        return (
            <Stack direction="column" spacing={1}>
                <Typography variant="caption">{tk('ServiceCallNoEquipment')}</Typography>

                {isEmpty && (
                    <Typography variant="body1">{serviceCall.emptySubject}</Typography>
                )}
            </Stack>
        )
    } else {
        return (
            <Grid container spacing={1} justifyContent="space-between">
                <Stack direction="column" spacing={0.25}>
                    <Typography>{tk('TextSelectedEquipment')}</Typography>
                    <WEditorEquipmentTextLines serviceCall={serviceCall}/>
                </Stack>

                {salesOrder && (
                    <Stack direction="column" spacing={0.25} alignItems="end">
                        <Typography>{tk('SalesOrder')}: {salesOrder.docNum}</Typography>
                        <Typography maxWidth="300px">
                            {salesOrder.cardName}
                        </Typography>
                        <Typography>
                            <DateDisplay
                                dateStr={salesOrder.docDate ?? ""}
                                options={{
                                    ...dateDisplayDefaultOptions,
                                    minute: undefined,
                                    hour: undefined,
                                }}
                            />
                        </Typography>
                    </Stack>
                )}
            </Grid>

        )
    }
}

export default WEditorHeaderContentCompactDetails;