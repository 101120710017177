import {media as TeamsMedia} from "@microsoft/teams-js";
import {toastError} from "shared/toast/DefaultToasts";

//  let y = attachments[0];
//setImgSrc(("data:" + y.mimeType + ";base64," + y.preview))

export class TeamsMediaUtils {
    public static defaultImageProp: TeamsMedia.ImageProps = {
        sources: [TeamsMedia.Source.Camera, TeamsMedia.Source.Gallery],
        startMode: TeamsMedia.CameraStartMode.Photo,
        ink: false,
        cameraSwitcher: true,
        textSticker: false,
        enableFilter: true,
    }
    public static defaultMediaInput: TeamsMedia.MediaInputs = {
        mediaType: TeamsMedia.MediaType.Image,
        maxMediaCount: 10,
        imageProps: TeamsMediaUtils.defaultImageProp
    }

    public static getMediaFromAttachment = (attachment: TeamsMedia.Media): Promise<File> => {
        return new Promise<File>((resolve) => {
            attachment.getMedia((sdkError, blob) => {
                if (sdkError) {
                    throw new Error(`${sdkError.errorCode} - ${sdkError.message}`)
                } else {
                    const newFile = new File([blob], attachment.name ?? "", {
                        type: attachment.mimeType
                    })
                    resolve(newFile)
                }
            })
        })
    }

    public static mapAttachmentsToFiles = (attachments: TeamsMedia.Media[]): Promise<File[]> => {
        return new Promise<File[]>(async (resolve, reject) => {
            if (!attachments || attachments.length < 1) {
                return resolve([])
            }
            const result: File[] = []
            for (const attachment of attachments) {
                try {
                    const file = await TeamsMediaUtils.getMediaFromAttachment(attachment)
                    result.push(file)
                } catch (e: any) {
                    toastError(e?.message)
                }
            }
            resolve(result)
        })
    }
}