import React from 'react';
import {CatalogueTableRow} from "modules/admin/worksheet/evaluation/tabs/service-calls/catalogue/table/CatalogueTable";
import CheckIconButton from "shared/components/buttons/CheckIconButton";
import {
    useServiceCallItemRowEvalUpdateRowAck
} from "crowbar-api/hooks/worksheet/evaluation/service-call-item-row/useServiceCallItemRowEvalUpdateRowAck";

export type RowAckCell = {
    row: CatalogueTableRow
    onDataChange: () => Promise<void>
    disabled?: boolean
}
const RowAckCell = ({row, onDataChange, disabled}: RowAckCell) => {

    const isChecked = row.eval?.rowAck ?? false

    const updateRowAck = useServiceCallItemRowEvalUpdateRowAck()

    const onClick = async () => {

        if (disabled) {
            return
        }

        await updateRowAck.mutateAsync({
            evalId: row.eval?.id,
            originalId: row.row?.id,
            field: !isChecked
        })
        await onDataChange()
    }

    return (
        <CheckIconButton
            checked={isChecked}
            onClick={onClick}
            disabled={/*disabled ||*/ updateRowAck.isLoading}
        />
    )
}

export default RowAckCell;