import React from 'react';
import {
    MechanicListingStateProps,
    useMechanicListingState
} from "modules/worksheet/mechanic/state/useMechanicListingState";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {Grid, Stack, Typography} from "@mui/material";
import NativeDateInput from "shared/components/inputs/NativeDateInput";
import {DateUtils} from "shared/utils/DateUtils";

export type WorksheetListDateFilterProps = MechanicListingStateProps & {}

const WorksheetListDateFilter = ({mechanicStateAtom}: WorksheetListDateFilterProps) => {

    const tk = useTranslateFunction(`Worksheet.Generic.Mechanic`)

    const {state, setState} = useMechanicListingState(mechanicStateAtom)

    const fromFormatted = DateUtils.parseServerUTCToNativeDateInput(state.worksheetSearch.worksheetFrom)
    const toFormatted = DateUtils.parseServerUTCToNativeDateInput(state.worksheetSearch.worksheetTo)

    const onChange = (field: 'from' | 'to', date: string) => {
        setState(prev => {
            return {
                ...prev,
                worksheetSearch: {
                    ...prev.worksheetSearch,
                    worksheetFrom: field === "from" ? date : prev.worksheetSearch.worksheetFrom,
                    worksheetTo: field === "to" ? date : prev.worksheetSearch.worksheetTo
                }
            }
        })
    }

    return (

        <Stack direction="column" spacing={0.5}>
            <Typography variant="caption">{tk('DateFilter')}</Typography>

            <Grid container spacing={1}>
                <Grid item xs="auto">
                    <NativeDateInput
                        value={fromFormatted ?? ""}
                        onChangeWithServerValue={(date) => onChange('from', date)}
                    />
                </Grid>
                <Grid item xs="auto">
                    <NativeDateInput
                        value={toFormatted ?? ""}
                        onChangeWithServerValue={(date) => onChange('to', date)}
                    />
                </Grid>
            </Grid>
        </Stack>
    )
}

export default WorksheetListDateFilter;