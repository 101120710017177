import React from 'react';
import EquipmentThumbnail from "modules/worksheet/editor/shared/component/equipment-thumbnail/EquipmentThumbnail";
import {useAzureBlobStorageUrlFactory} from "shared/hook/useAzureBlobStorageUrlFactory";
import {Box} from "@mui/material";

export type EquipmentSapCoverImageProps = {
    coverImage?: string | null
}

const EquipmentSapCoverImage = ({coverImage}: EquipmentSapCoverImageProps) => {
    const blobStorageUrlFactory = useAzureBlobStorageUrlFactory()
    const url = blobStorageUrlFactory.sapImageFromName(coverImage)
    if (!url) {
        return (
            <></>
        )
    }
    return (
        <Box
            sx={{
                ":hover": {
                    "cursor": "pointer"
                }
            }}
        >
            <EquipmentThumbnail
                onClick={() => window.open(url.uri, "_blank")}
                thumbSrc={url?.thumbMedium}
                size={128}
            />
        </Box>
    );
};

export default EquipmentSapCoverImage;