import React from 'react';
import {CDAMWMWorksheetEvalModel, CDAMWMWWorksheetQuery} from "crowbar-api";
import Base64Image from "shared/components/signature/Base64Image";
import {Box, Card, CardContent, Grid, Typography} from "@mui/material";
import useTranslateFunction from "shared/language/useTranslateFunction";

export type SignatureDisplayProps = {
    wEval: CDAMWMWorksheetEvalModel | null | undefined
    worksheet: CDAMWMWWorksheetQuery | null | undefined
}
const SignatureDisplay = ({
                              wEval,
                              worksheet
                          }: SignatureDisplayProps) => {
    const tk = useTranslateFunction("Admin.Worksheet")
    return (
        <Card variant="outlined">
            <CardContent>
                <Grid container spacing={1} justifyContent="space-between">
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            {tk('Signature')}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography>
                            {tk('Name')}: {worksheet?.signatureName ?? "-"}
                        </Typography>
                    </Grid>
                    <Grid item xs="auto">
                        <Box sx={{
                            border: "1px solid black"
                        }}>
                            {worksheet?.signatureBase64 ? (
                                <Base64Image base64Image={worksheet?.signatureBase64}/>
                            ) : (
                                <Typography sx={{p: 3}}>
                                    {tk('MissingSignature')}
                                </Typography>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default SignatureDisplay;