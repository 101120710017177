export class DynamicThemeUtil {

    private static valueCssClasses: string[] = [
        '--ct-color-bg',
        '--ct-color-bg-inverse',
        '--ct-color-text',
        '--ct-color-text-inverse',
        '--ct-color-text-primary',
        '--ct-color-list-border'
    ];

    public static setTheme(theme: 'default' | 'dark'): void {
        const style = document.body.style;
        for (let valueCssClass of DynamicThemeUtil.valueCssClasses) {
            style.setProperty(valueCssClass, `var(${valueCssClass}-${theme})`);
        }
    }
}