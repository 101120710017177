import {CDSMSSapItemForWorksheetSearchQuery} from "crowbar-api";
import {Box, Card, CardContent, Grid, Stack, Typography} from "@mui/material";
import ItemImageDisplay from "modules/worksheet/editor/ui/catalogue/item-image/ItemImageDisplay";
import WarehouseIcon from '@mui/icons-material/Warehouse';
import useTranslateFunction from "shared/language/useTranslateFunction";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

interface AddItemSelectListRowProps {
    item: CDSMSSapItemForWorksheetSearchQuery
    selected: boolean
    setAsSelected: () => void
}

const AddItemSelectListRow = ({item, selected, setAsSelected}: AddItemSelectListRowProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    const brandOrSww = item.brand ?? item.sww

    return (
        <Card
            elevation={16}
            onClick={setAsSelected}
            sx={{
                width: 1,
                borderColor: selected ? theme => theme.palette.primary.main : 'transparent',
                borderWidth: "4px",
                borderStyle: "solid",
                display: "flex"
                //backgroundColor: selected ? theme => theme.palette.grey.A400: 'transparent'
            }}
        >
            <Box sx={{
                width: 1,
                display: 'flex',
                flexDirection: 'column',
                ':hover': {
                    "cursor": "pointer"
                }
            }}>
                <CardContent sx={{flex: '1 0 auto'}}>

                    <Grid container direction={"row"}>

                        <Grid item xs="auto">
                            <ItemImageDisplay
                                itemCode={item.itemCode}
                                coverImage={item.coverImage ?? undefined}
                                hideBorder={true}
                                disableOnClick={true}
                            />


                            {selected && (
                                <Box sx={{
                                    position: "relative"
                                }}>
                                    <CheckBoxIcon
                                        color="primary"
                                        sx={{
                                            position: "absolute",
                                            top: -145,
                                            left: 0
                                        }}/>
                                </Box>
                            )}
                        </Grid>

                        <Grid item xs={3}>
                            <Stack direction="column" spacing={0} sx={{
                                width: 1,
                                px: 2
                            }}>
                                <Typography>{item.itemCode}</Typography>
                                {brandOrSww && (<Typography>{tk('Brand')}: {brandOrSww}</Typography>)}
                                {item.suppCatNum && (<Typography>{tk('SuppCatNum')}: {item.suppCatNum}</Typography>)}
                            </Stack>
                        </Grid>
                        <Grid item xs={true}>
                            <Box
                                component="div"
                                sx={{
                                    width: 1,
                                    whiteSpace: 'wrap',
                                    fontWeight: "bold",
                                    fontSize: "12pt"
                                }}>
                                {item.itemName}
                            </Box>
                        </Grid>
                        {item.inventoryItem && (
                            <Grid item xs="auto">
                                <Box sx={{
                                    width: 1,
                                    display: "flex",
                                    justifyContent: "end"
                                }}>
                                    <Stack direction="column" spacing={0}>
                                        <Box sx={{
                                            display: "flex",
                                            justifyContent: "end"
                                        }}>
                                            <WarehouseIcon sx={{
                                                fontSize: "40px"
                                            }}/>
                                        </Box>
                                        <Typography sx={{
                                            color: "red",
                                            fontWeight: "bold",
                                            fontSize: "14pt"
                                        }}>
                                            {item.onHand} {item.buyUnitMsr}
                                        </Typography>
                                    </Stack>
                                </Box>
                            </Grid>
                        )}
                    </Grid>

                </CardContent>
            </Box>
        </Card>
    )
}

export default AddItemSelectListRow