import React from 'react';
import {FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {
    newWorksheetSubTablePageSizes,
    useNewWorksheetState
} from "modules/worksheet/new-worksheet/useNewWorksheetState";
import useTranslateFunction from "shared/language/useTranslateFunction";

const NewWorksheetOptions = () => {
    const tk = useTranslateFunction(`Worksheet.Generic.NewWorksheet`)

    const {state, setState, resetState} = useNewWorksheetState()

    const orderBy = state.orderBy
    const subPageSize = state.subTablePageSize

    const onOrderByChange = (e: SelectChangeEvent) => {
        setState(prev => {
            return {
                ...prev,
                orderBy: e.target.value === "ASC" ? "ASC" : "DESC"
            }
        })
    }

    const onSubTablePageSizeChange = (e: SelectChangeEvent) => {
        const found = newWorksheetSubTablePageSizes.find(v => v.toString() === e.target.value.toString())
        setState(prev => {
            return {
                ...prev,
                subTablePageSize: found ?? 5
            }
        })
    }

    return (
        <Grid container spacing={1} justifyContent="space-between" sx={{marginTop: 2}}>

            <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                    <InputLabel id="orderBy-select-label">
                        {tk('OrderBy')}
                    </InputLabel>
                    <Select
                        value={orderBy ?? "DESC"}
                        onChange={onOrderByChange}
                        label={tk('OrderBy')}
                        labelId="orderBy-select-label"
                        size="small"
                    >
                        <MenuItem value="ASC">{tk('ASC')}</MenuItem>
                        <MenuItem value="DESC">{tk('DESC')}</MenuItem>
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} sx={{
                display: "flex",
                alignItems: "flex-end"
            }}>
                <FormControl fullWidth>
                    <InputLabel id="subTable-page-size-select-label">
                        {tk('TableRowNumber')}
                    </InputLabel>
                    <Select
                        value={subPageSize?.toString() ?? "5"}
                        onChange={onSubTablePageSizeChange}
                        label={tk('TableRowNumber')}
                        labelId="subTable-page-size-select-label"
                        size="small"
                    >
                        {newWorksheetSubTablePageSizes.map(s => (
                            <MenuItem key={s} value={s}>{s}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );
};

export default NewWorksheetOptions;