import {useWorksheetLocalChange} from "modules/worksheet/editor/shared/data/useWorksheetLocalChange";
import {useEffect, useState} from "react";
import {
    useWorksheetSaveState,
    useWorksheetSaveStateLastSaveTime
} from "modules/worksheet/editor/state/WorksheetSaveAtoms";
import AsyncInterval from "shared/utils/AsyncInterval";

export const worksheetAutomaticSaveFrequencyInMs = 50

/**
 * Only call this in one component.
 * Periodically saves the in memory state of the worksheet save atom to
 * indexeddb / local storage.
 * Uses the current value of the atom. Changing the atom means changing the
 * state of the browser's storage as well (through this setInterval) automatically.
 * This save is frequent enough to always have the most current state.
 * Prevents accidental overwrites from multiple components. Logic:
 *  - UI components work with the worksheetSaveStateAtom
 *      (instead of directly writing to indexeddb) and the atom's focuses
 *      and won't overwrite each other's changes that run in parallel.
 *  - This save only runs once, reading the current state
 *
 *  Uses the last save time of the worksheet atom to check whether we need to save or not.
 *  If the last save time matches the last seen one, we do nothing.
 */
export const useScheduledWorksheetSaveToLocalState = () => {
    // job queue
    // const [scheduledJobs, setScheduledJobs] = useAtom(scheduledJobsAtom)

    // in memory state of the worksheet
    const [worksheetSaveState] = useWorksheetSaveState()
    const [lastSaveTime] = useWorksheetSaveStateLastSaveTime()
    // setter for indexeddb storage
    const {mutateAsync: setWorksheetSave} = useWorksheetLocalChange();

    const [seenLastSaveTime, setSeenLastSaveTime] = useState<number | undefined>(undefined)

    // Periodically save in memory state
    useEffect(() => {
        const interval = new AsyncInterval(async () => {
            if (!worksheetSaveState) return
            if (seenLastSaveTime !== undefined && seenLastSaveTime === lastSaveTime) return

            // save the state to indexeddb
            // console.log('saving worksheet', 'seenLastSaveTime', seenLastSaveTime, 'lastSaveTime', lastSaveTime, worksheetSaveState)
            await setWorksheetSave(worksheetSaveState)
            setSeenLastSaveTime(lastSaveTime)
        }, worksheetAutomaticSaveFrequencyInMs); // save frequency in ms

        (async () => {
            await interval.start()
        })()

        // clear interval
        return () => {
            interval.stop()
        }
    }, [lastSaveTime, seenLastSaveTime, setWorksheetSave, worksheetSaveState])
}