import React from 'react';

const UnAuthorized = () => {
    return (
        <div>
            <h1>Unauthorized</h1>
            <p>You do not have permission to access this page.</p>
        </div>
    )
}

export default UnAuthorized;