import React from 'react';
import {
    MechanicListingState,
    MechanicListingStateProps,
    useMechanicListingState
} from "modules/worksheet/mechanic/state/useMechanicListingState";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {PrimitiveAtom} from "jotai";

export type MechanicWorksheetOrderByProps = MechanicListingStateProps & {
    mechanicStateAtom: PrimitiveAtom<MechanicListingState>
}
const MechanicWorksheetOrderBy = ({mechanicStateAtom}: MechanicWorksheetOrderByProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.Mechanic`)

    const {state, setState} = useMechanicListingState(mechanicStateAtom)

    const onOrderByFieldChange = (event: SelectChangeEvent<"OrderByModificationDates" | "OrderBySerialId">) => {
        setState(prev => {
            return {
                ...prev,
                selectedOrderByField: event.target.value === "OrderByModificationDates"
                    ? "OrderByModificationDates"
                    : "OrderBySerialId"
            }
        })
    }

    const onOrderByDirectionChange = (event: SelectChangeEvent<"ASC" | "DESC">) => {
        setState(prev => {
            return {
                ...prev,
                orderBy: event.target.value === "ASC" ? "ASC" : "DESC"
            }
        })
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <InputLabel id="mw-order-by-field-select">{tk('OrderByField')}</InputLabel>
                    <Select
                        labelId="mw-order-by-field-select"
                        label={tk('OrderByField')}
                        value={state.selectedOrderByField}
                        onChange={onOrderByFieldChange}
                        size="small"
                    >
                        <MenuItem value="OrderByModificationDates">{tk('ModificationDates')}</MenuItem>
                        <MenuItem value="OrderBySerialId">{tk('SerialId')}</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <InputLabel id="mw-order-by-direction-select">{tk('OrderByDirection')}</InputLabel>
                    <Select
                        labelId="mw-order-by-direction-select"
                        label={tk('OrderByDirection')}
                        value={state.orderBy}
                        onChange={onOrderByDirectionChange}
                        size="small"
                    >
                        <MenuItem value="ASC">{tk('ASC')}</MenuItem>
                        <MenuItem value="DESC">{tk('DESC')}</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );
};

export default MechanicWorksheetOrderBy;