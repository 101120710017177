import React from 'react';
import DateDisplay, {DateDisplayProps} from "shared/components/date-display/DateDisplay";
import {Stack, StackProps} from "@mui/material";
import {DateUtils} from "shared/utils/DateUtils";

export type DateRangeDisplayProps = Omit<DateDisplayProps, "dateStr"> & {
    startDate?: string | null
    endDate?: string | null

    stackProps?: StackProps
}

const DateRangeDisplay = ({startDate, endDate, stackProps, ...props}: DateRangeDisplayProps) => {

    const dates: JSX.Element[] = []

    const startDateObj = DateUtils.parseServerUTCToDate(startDate)
    const startDateStr = startDateObj ? DateUtils.intlDateToFormat(startDateObj, "fr-CA") : ""
    const endDateObj = DateUtils.parseServerUTCToDate(endDate)
    const endDateStr = endDateObj ? DateUtils.intlDateToFormat(endDateObj, "fr-CA") : ""

    const dateEquals = startDateStr === endDateStr

    if (!startDate && !endDate) return <></>

    if (startDate) {
        dates.push((
            <DateDisplay key="start" dateStr={startDate ?? ""} {...props} />
        ))
    }

    if (!dateEquals && startDate && endDate) {
        dates.push((<span key="sep">&nbsp;-&nbsp;</span>))
    }

    if (!dateEquals && endDate) {
        dates.push((
            <DateDisplay key="end" dateStr={endDate ?? ""} {...props} />
        ))
    }

    return (
        <Stack direction="row" spacing={1} {...stackProps}>
            {dates}
        </Stack>
    )
}

export default DateRangeDisplay;