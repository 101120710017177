import {CDAMDMDeliveryNoteTemplateModel} from "crowbar-api";
import {
    Checkbox,
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import EditIcon from "shared/components/icons/EditIcon";
import DateDisplay from "shared/components/date-display/DateDisplay";
import React from "react";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {useFindAllUsers} from "crowbar-api/hooks/user/useFindAllUsers";
import {useDeliveryNoteTemplatesFindAll} from "crowbar-api/hooks/delivery-note/useDeliveryNoteTemplatesFindAll";

interface TemplateListProps {
    onEdit: (template: CDAMDMDeliveryNoteTemplateModel) => void
}

const TemplateList = ({onEdit}: TemplateListProps) => {
    const tk = useTranslateFunction("DeliveryNote.List.");

    const {
        isLoading: isDeliveryNoteTemplatesLoading,
        data: deliveryNoteTemplates
    } = useDeliveryNoteTemplatesFindAll()

    const {
        isLoading: isUsersLoading,
        data: users
    } = useFindAllUsers()

    const onEditClick = (item: CDAMDMDeliveryNoteTemplateModel) => {
        onEdit(item);
    }

    return (
        <>
            {isDeliveryNoteTemplatesLoading && isUsersLoading ? <CircularProgress/> :
                <TableContainer component={Paper}>
                    <Table padding={"none"} sx={{minWidth: "700px", tableLayout: "fixed", overflowWrap: "break-word"}}>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{width: 50}}></TableCell>
                                <TableCell sx={{width: 200}}>{tk('ColCreatedAt')}</TableCell>
                                <TableCell>{tk('ColUploader')}</TableCell>
                                <TableCell sx={{width: 550}}>{tk('ColDescription')}</TableCell>
                                <TableCell>{tk('ColDefault')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {deliveryNoteTemplates?.map((item) => {
                                return (<TableRow
                                    hover
                                    key={item.id}
                                >
                                    <TableCell align={"center"}>
                                        <EditIcon
                                            fontSize={"large"}
                                            sx={theme => {
                                                return {
                                                    cursor: "pointer",
                                                    color: theme.palette.primary.main,
                                                    stroke: "white"
                                                }
                                            }}
                                            onClick={() => onEditClick(item)}
                                        />
                                    </TableCell>
                                    <TableCell><DateDisplay dateStr={item.createdAt ?? ""}
                                                            format="YYYY-MM-DD hh:mm"/></TableCell>
                                    <TableCell>{users?.find(u => u.id === item.createdBy)?.fullName}</TableCell>
                                    <TableCell>{item.description}</TableCell>
                                    <TableCell><Checkbox checked={item.defaultTemplate} disabled/></TableCell>
                                </TableRow>)
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </>
    );
}

export default TemplateList;