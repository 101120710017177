import React from 'react';
import {CDSMNNewWorksheetCustomer} from "crowbar-api";
import {Button, Card, CardActions, CardContent, CircularProgress, Grid, Typography} from "@mui/material";
import useTranslateFunction from "shared/language/useTranslateFunction";
import ImageIcon from "shared/components/image-icon/ImageIcon";
import {WorksheetIconImages} from "modules/worksheet/editor/shared/component/images/WorksheetIconImages";
import {useOpenWorksheetEditor} from "modules/worksheet/editor/state/events/useOpenWorksheetEditor";
import {useNewWorksheetFromPartnerAction} from "modules/worksheet/editor/state/events/useNewWorksheetFromPartnerAction";
import {toastError, toastSuccess} from "shared/toast/DefaultToasts";
import NewWorksheetResultCustomerTable from "modules/worksheet/new-worksheet/results/NewWorksheetResultCustomerTable";
import {useMountedState} from "shared/hook/useMountedState";

export interface NewWorksheetResultCustomerProps {
    customer: CDSMNNewWorksheetCustomer
    pageSize: number
}

const NewWorksheetResultCustomer = ({customer, pageSize}: NewWorksheetResultCustomerProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.NewWorksheet`)

    const newWorksheetFromPartnerAction = useNewWorksheetFromPartnerAction()
    const openWorksheetEditor = useOpenWorksheetEditor()

    const isMounted = useMountedState()

    const salesOrders = (customer.salesOrders ?? [])

    const onNewWorksheetForCustomerClick = async () => {
        if (!customer?.cardCode) {
            toastError('Missing customer cardCode')
            return
        }
        const newWorksheet = await newWorksheetFromPartnerAction
            .mutateAsync({cardCode: customer.cardCode})
        if (isMounted()) {
            openWorksheetEditor(newWorksheet.id)
        } else {
            toastSuccess(`${tk('WorksheetCreatedSilent')}${newWorksheet.partnerCardName}`)
        }
    }

    return (
        <Card elevation={16} sx={{
            p: 2,
            border: "2px solid #2274A5"
        }}>
            <CardContent>
                <Grid container spacing={0.5}>
                    <Grid item xs={12}>
                        <Typography
                            variant="h6"
                            sx={{
                                display: "flex",
                                alignItems: "center"
                            }}
                        >
                            <ImageIcon image={WorksheetIconImages.client} size={32}/>&nbsp;{tk('Customer')}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h6">
                            {customer.cardName} ({customer.cardCode})
                        </Typography>
                    </Grid>

                    {customer.addresses && customer.addresses.length > 0 && (
                        <Grid item xs={12}>
                            <Typography variant="body1">

                                {customer.addresses.map(a => `${a.addressZipCode} ${a.addressCity}`).join(", ")}
                            </Typography>
                        </Grid>
                    )}

                    {customer.contacts && customer.contacts.length > 0 && (
                        <Grid item xs={12}>
                            <Typography variant="body1">

                                {customer.contacts.map(a => `${a.contactName}`).join(", ")}
                            </Typography>
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <Typography variant="body2">
                            {tk('NumOfEquipments')} {customer.numberOfEquipments}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        {salesOrders.length < 1
                            ? (
                                <Typography>
                                    {tk('NoSalesOrder')}
                                </Typography>
                            )
                            : (
                                <NewWorksheetResultCustomerTable
                                    customer={customer}
                                    pageSize={pageSize}
                                />
                            )}
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions sx={{
                display: "flex",
                justifyContent: "right"
            }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onNewWorksheetForCustomerClick}
                    disabled={newWorksheetFromPartnerAction.isLoading}
                >
                    {tk('NewWorksheet')}
                    {newWorksheetFromPartnerAction.isLoading && (
                        <CircularProgress size="16px"/>
                    )}
                </Button>
            </CardActions>
        </Card>
    )
}

export default NewWorksheetResultCustomer;