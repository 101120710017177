export class StringUtils {
    public static undefinedNullOrEmpty = (s: string | undefined | null): boolean => {
        // noinspection SuspiciousTypeOfGuard
        return s === undefined || s === null || (typeof s === "string" && s.trim() === "") // type check is needed!
    }

    public static notUndefinedNullAndEmpty = (s: string | undefined | null): boolean => {
        // noinspection SuspiciousTypeOfGuard
        return s !== null && s !== undefined && typeof s === "string" && s.trim() !== ""
    }

    public static valueOrEmptyStr = (s: string | undefined | null): string => {
        return s ? s : ""
    }

    public static notEmptyValuesAsArray = (...args: (string | undefined | null)[]): string[] => {
        return args.filter(v => StringUtils.notUndefinedNullAndEmpty(v))
            .map(v => v ?? "")
    }

    /**
     * Removes the characters from the start of the string until the predicate becomes false.
     * @param input
     * @param predicate called for each character starting from the first character while it is true
     */
    public static trimStartWhile = (input: string, predicate: (char: string) => boolean) => {
        let i = 0, len = input.length
        while (i < len && predicate(input[i])) {
            i++;
        }
        return input.substring(i)
    }

    /**
     * Removes the characters from the string end of the string until the predicate becomes false.
     * @param input
     * @param predicate called for each character starting from the end while it is true
     */
    public static trimEndWhile = (input: string, predicate: (char: string) => boolean) => {
        let i = input.length - 1
        while (i >= 0 && predicate(input[i])) {
            i--;
        }
        return input.substring(0, i + 1)
    }

    public static lengthNotCountingCharsLike = (text?: string, ignoreCharsLike: string[] = ['*', '%']) => {
        if (!text) return 0
        let result = text
        for (const string of ignoreCharsLike) {
            result = result.replaceAll(string, '')
        }
        return result.length
    }
}