import React from 'react';

import WorksheetEditorPageCatalogueItemRows
    from "modules/worksheet/editor/ui/catalogue/WorksheetEditorPageCatalogueItemRows";
import AddItemFab from "modules/worksheet/editor/ui/catalogue/add-item-dialog/AddItemFab";
import useWorksheetSignedState from "modules/worksheet/editor/state/helpers/useWorksheetSignedState";

export interface WorksheetEditorPageCatalogueProps {

}

const WorksheetEditorPageCatalogue = ({}: WorksheetEditorPageCatalogueProps) => {
    //const [info, setInfo] = useWorksheetEditorHeaderInfoAtom()
    // const [worksheetSave, setWorksheetSave] = useWorksheetSaveState()
    // const selectedServiceCall = useSelectedServiceCall()
    //const selectedServiceCall = worksheetSave?.serviceCalls?.find(sc => sc.id === info.selectedServiceCallId)

    const {editDisabled} = useWorksheetSignedState()

    return (
        <div>
            <WorksheetEditorPageCatalogueItemRows disabled={editDisabled}/>

            {!editDisabled && (
                <AddItemFab/>
            )}
        </div>
    )
}

export default WorksheetEditorPageCatalogue;