import {useUserContext} from "shared/hook/UserContext";
import {useSaveNewWorksheetViaApi} from "modules/worksheet/editor/state/events/useSaveNewWorksheetViaApi";
import {useMutation} from "@tanstack/react-query";
import {CDAMWMWModSapPartnerSave, CDAMWMWWorksheetSave} from "crowbar-api";
import {WorksheetSaveFactory} from "modules/worksheet/storage/WorksheetSaveFactory";
import {UseNewWorksheetFromProps} from "modules/worksheet/editor/state/events/UseNewWorksheetFromProps";

export interface UseNewWorksheetFromModPartnerActionProps extends UseNewWorksheetFromProps {
    modPartner: CDAMWMWModSapPartnerSave
}

export const useNewWorksheetFromModPartnerAction = () => {
    const [userId] = useUserContext()
    const saveNewWorksheet = useSaveNewWorksheetViaApi()

    return useMutation(async (
        {modPartner, reloadWorksheetObjectAfterSave}: UseNewWorksheetFromModPartnerActionProps
    ): Promise<CDAMWMWWorksheetSave> => {
        // Create a new WorksheetSave from the mod partner
        if (!userId) {
            throw new Error('UserIdNullError')
        }
        if (!modPartner?.id) {
            throw new Error('ModPartnerIdIsNullError')
        }

        // New model created on client side
        const newWorksheet: CDAMWMWWorksheetSave = WorksheetSaveFactory
            .newWorksheetSaveFromModPartner(modPartner, userId)
        return await saveNewWorksheet.mutateAsync({newWorksheet, reloadWorksheetObjectAfterSave})
    })
}