import {UseQueryOptionParams} from "crowbar-api/util/UseQueryOptionParams";
import {useQuery} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {ServiceCallApi} from "crowbar-api";

export const useServiceCallFindByWorksheetId = (wId: string | undefined | null, extraOptions?: UseQueryOptionParams) => {
    return useQuery(['service-call-find-by-worksheet-id-1', wId], async () => {
        if (!wId) return []
        const response = await crowbarApiFactory(ServiceCallApi)
            .findByWorksheetId(wId)
        return response.data ?? []
    }, {
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        cacheTime: 0,
        staleTime: 0,
        retry: 1,
        ...extraOptions
    })
}