import {useMutation} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {PhotoRuleStepApi} from "crowbar-api";

export interface UsePhotoStepDeleteArgs {
    ruleId: string
    stepId: string
}

const usePhotoStepDelete = () => {
    return useMutation(async ({ruleId, stepId}: UsePhotoStepDeleteArgs) => {
        const response = await crowbarApiFactory(PhotoRuleStepApi).deleteStepAndFixOrderNumbersForRule(ruleId, stepId)
        if (response.status !== 200) {
            throw new Error('Failed to delete photo rule step.')
        }
    })
}

export default usePhotoStepDelete