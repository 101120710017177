import {AxiosResponse} from "axios";

const httpResponseFileDownload = (response: AxiosResponse<any>, defaultFileName: string = "file.bin") => {

    const filename = (response.headers['content-disposition'] !== undefined) ?
        response.headers['content-disposition']?.split('filename=')[1]?.split(';')[0] : defaultFileName;

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
}

export default httpResponseFileDownload;