import React from 'react';
import {Route, Routes} from "react-router-dom";
import NotFound from "shared/components/not-found/NotFound";
import WorksheetAdminDashboard from "modules/admin/worksheet/WorksheetAdminDashboard";
import ListLastUserActivities from "modules/admin/last-user-activity/ListLastUserActivities";
import {AdminIndex} from "modules/admin/AdminIndex";
import {createStore, Provider as JotaiProvider} from 'jotai'
// export const adminScope = Symbol()

const adminStore = createStore()

const AdminRoutes = () => {
    return (
        <JotaiProvider /*scope={adminScope}*/ store={adminStore}>
            <Routes>
                {/* <Route path={`master-data/images/sap-equipments`} element={(
                <RequireAuth requiredRole={Roles.Administrator}>
                    <SapEquipmentImages />
                </RequireAuth>
            )} />
            <Route path={`master-data/images/sap-products`} element={(
                <RequireAuth requiredRole={Roles.Administrator}>
                    <SapProductImages />
                </RequireAuth>
            )} />*/}
                <Route path="worksheet-admin/*" element={<WorksheetAdminDashboard/>}/>
                <Route path="last-user-activities/*" element={<ListLastUserActivities/>}/>
                <Route index={true} element={<AdminIndex/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Routes>
        </JotaiProvider>
    );
};

export default AdminRoutes;