import {CDAMWMWServiceCallItemRowSave} from "crowbar-api";
import ServiceCallItemRowStatusMap from "crowbar-api/enum-map/ServiceCallItemRowStatusMap";
import {useIsClosedSalesOrderRow} from "modules/worksheet/editor/ui/catalogue/useIsClosedSalesOrderRow";
import React from "react";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {Stack, Typography} from "@mui/material";
import {red} from "@mui/material/colors";
import WarningIcon from "@mui/icons-material/Warning";

export type WEditorClosedSalesOrderWarningProps = {
    itemRow: CDAMWMWServiceCallItemRowSave
}
export const WEditorClosedSalesOrderWarning = ({itemRow}: WEditorClosedSalesOrderWarningProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    const isClosedSalesOrderRow = useIsClosedSalesOrderRow(itemRow)

    const displayClosedWarning = isClosedSalesOrderRow()

    if (!displayClosedWarning || itemRow.status === ServiceCallItemRowStatusMap.installed) {
        return <></>
    }

    return (
        <Typography
            component="div"
            width={1}
            sx={{
                backgroundColor: red[700],
                color: "white",
                p: 1
            }}
            alignItems="center"
        >
            <Stack direction="row" spacing={6} alignItems="center">
                <WarningIcon/>
                {tk('ClosedSalesOrderRow')}
            </Stack>
        </Typography>
    )
}