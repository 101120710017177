import {Control, Controller, FieldError, UseFormRegisterReturn, UseFormSetValue} from "react-hook-form";
import {Autocomplete, Box, MenuItem, TextField} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import useTranslateFunction from "shared/language/useTranslateFunction";
import {ChangeEvent} from "react";
import {
    PartnerEquipmentFormValues
} from "modules/worksheet/editor/shared/component/partner-equipment-form/usePartnerEquipmentForm";
import {byPropertiesOf} from "shared/utils/ArraySort";
import {CDSMSapPsgVehicleType} from "crowbar-api";
import usePsgVehicleTypes from "crowbar-api/hooks/sap/usePsgVehicleTypes";

interface PsgVehicleTypeSelectProps {
    defaultManufacturerCode: string
    defaultVehicleTypeName: string

    manufacturerRegister: UseFormRegisterReturn<"manufacturer">
    manufacturerError: FieldError | undefined
    manufacturerWatch: string | undefined

    vehicleTypeRegister: UseFormRegisterReturn<"vehicleType">
    vehicleTypeError: FieldError | undefined
    vehicleTypeWatch: string | undefined

    setValue: UseFormSetValue<PartnerEquipmentFormValues>
    control: Control<PartnerEquipmentFormValues, any>
}

const PsgVehicleTypeSelect = ({
                                  defaultManufacturerCode,
                                  defaultVehicleTypeName,
                                  manufacturerRegister,
                                  manufacturerError,
                                  manufacturerWatch,
                                  vehicleTypeError,
                                  setValue,
                                  control
                              }: PsgVehicleTypeSelectProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    const {isLoading, data: vehicleTypes} = usePsgVehicleTypes()

    const filteredVehicleTypes = manufacturerWatch
        ? (vehicleTypes?.vehicleTypes ?? []).filter(v => v.code === manufacturerWatch)
            .sort(byPropertiesOf<CDSMSapPsgVehicleType>(['name']))
        : []

    const onManCodeChange = async (event: ChangeEvent<HTMLInputElement>) => {
        setValue("vehicleType", "", {shouldDirty: true})
        await manufacturerRegister.onChange(event)
    }

    return (
        <Box>
            {isLoading && (
                <CircularProgress/>
            )}

            {!isLoading && (
                <>
                    <Box sx={{
                        width: 1,
                        paddingBottom: 1,
                        marginTop: 1
                    }}>
                        <TextField
                            select
                            defaultValue={defaultManufacturerCode}
                            inputRef={manufacturerRegister.ref}
                            error={!!manufacturerError?.message}
                            helperText={manufacturerError?.message}
                            fullWidth
                            InputLabelProps={{shrink: true}}
                            label={tk('Manufacturer')}
                            {...manufacturerRegister}
                            onChange={onManCodeChange}
                        >
                            {vehicleTypes?.manufacturers
                                ? vehicleTypes.manufacturers.map((m, i) => (
                                    <MenuItem key={i} value={m.code ?? ""}>
                                        {m.name}
                                    </MenuItem>
                                ))
                                : (
                                    <MenuItem key="" value="">
                                        &nbsp;
                                    </MenuItem>
                                )
                            }
                        </TextField>
                    </Box>
                    {filteredVehicleTypes.length > 0 && (
                        <Box>

                            <Controller
                                name="vehicleType"
                                control={control}
                                defaultValue={defaultVehicleTypeName}
                                render={({field, fieldState, formState}) => (
                                    <Autocomplete
                                        disablePortal
                                        freeSolo
                                        // autoSelect
                                        fullWidth
                                        handleHomeEndKeys
                                        defaultValue={defaultVehicleTypeName}
                                        value={field.value ?? null}
                                        onChange={(e, value) => field.onChange(value)}
                                        onInputChange={(_, data) => {
                                            if (data) field.onChange(data);
                                        }}
                                        options={
                                            filteredVehicleTypes.map((v) => v.name)
                                                .filter((x, i, a) => a.indexOf(x) === i)
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                ref={field.ref}
                                                error={!!vehicleTypeError?.message}
                                                helperText={vehicleTypeError?.message}
                                                fullWidth
                                                InputLabelProps={{shrink: true}}
                                                label={tk('VehicleType')}
                                            />
                                        )}
                                    />
                                )}
                            />
                        </Box>
                    )}
                </>
            )}
        </Box>
    )
}

export default PsgVehicleTypeSelect