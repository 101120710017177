import {useQuery} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {ServiceCallEvalApi} from "crowbar-api";
import AxiosCancelTokenHelper from "crowbar-api/AxiosCancelTokenHelper";

export const useServiceCallEvalFindByWorksheetEvalId = (evalId: string | null | undefined) => {
    return useQuery(['service-call-eval-find-by-worksheet-eval-id', evalId], async ({signal}) => {
        if (!evalId) return []
        const response = await crowbarApiFactory(ServiceCallEvalApi)
            .findByWorksheetEvalId(evalId, AxiosCancelTokenHelper.cancelTokenFrom(signal))
        return response.data ?? []
    }, {
        networkMode: "online",
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        retry: 1,
    })
}