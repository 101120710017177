import React from 'react';
import useTranslateFunction from "shared/language/useTranslateFunction";
import {CDAMWMWWorksheetSearchQuery} from "crowbar-api";
import {WorksheetVersionMismatch} from "modules/worksheet/mechanic/state/useLocalAndServerWorksheetVersions";
import {CircularProgress, Grid, Stack, Typography} from "@mui/material";
import DateRangeDisplay from "shared/components/date-display/DateRangeDisplay";
import DateDisplay, {dateDisplayDefaultOptions} from "shared/components/date-display/DateDisplay";
import WorksheetStateDisplay from "shared/components/worksheet/WorksheetStateDisplay";
import ReportIcon from "@mui/icons-material/Report";
import {useUserDetailsById} from "crowbar-api/hooks/user/useUserDetailsById";
import MailOutlineIcon from '@mui/icons-material/MailOutline';

export type ServiceCallHeaderGridItemsProps = {
    worksheet: CDAMWMWWorksheetSearchQuery
    mismatch?: WorksheetVersionMismatch

    serviceCallId?: string
}
const ServiceCallHeaderGridItems = ({
                                        worksheet,
                                    }: ServiceCallHeaderGridItemsProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.Mechanic`)

    // const serviceCall = worksheet?.serviceCalls?.find(sc => sc.id === serviceCallId)
    const salesOrder = worksheet?.salesOrders?.find(() => true)

    const userDetails = useUserDetailsById(worksheet?.ownerId)

    return (
        <>
            <Grid item xs={6}>

                <Stack direction="column" spacing={0}>

                    {worksheet.isDeleted && (
                        <Typography color="error" fontWeight="bold">{tk('DeletedWorksheet')}</Typography>
                    )}

                    <Typography
                        variant="caption"
                        sx={{
                            fontSize: "12pt",
                            fontWeight: "bold",
                            display: "flex",
                            alignItems: "center"
                        }}
                    >
                        {worksheet.partnerCardName} {worksheet.cardCode && `(${worksheet.cardCode})`}
                    </Typography>

                    {salesOrder && (
                        <Typography
                            component="div"
                            sx={{
                                fontSize: "12pt",
                                display: "flex",
                                alignItems: "center"
                            }}
                        >
                            {tk('SalesOrder')}:&nbsp;
                            <Typography sx={{fontWeight: "bold"}}>{salesOrder.docNum}</Typography>
                        </Typography>
                    )}

                    <WorksheetStateDisplay
                        status={worksheet.status}
                        stackProps={{
                            py: 1,
                            justifyContent: "left"
                        }}
                    />

                    <Typography variant="body2" sx={{fontSize: "10pt"}}>
                        {tk('CreatedAt')}:&nbsp;
                        <DateDisplay
                            dateStr={worksheet.createdAt ?? ""}
                            sx={{fontSize: "10pt"}}
                        />
                    </Typography>
                    <Typography variant="body2" sx={{fontSize: "10pt"}}>
                        {tk('LastStatusChange')}:&nbsp;
                        <DateDisplay
                            dateStr={
                                worksheet.evaluatedDate ??
                                worksheet.finalSignedOrSkippedDate ??
                                worksheet.finalDate ??
                                worksheet.updatedAt ??
                                worksheet.createdAt ??
                                ""
                            }
                            sx={{fontSize: "10pt"}}
                        />
                    </Typography>
                </Stack>

            </Grid>
            <Grid item xs={6}>
                <Stack direction="column" spacing={0}>
                    {worksheet?.serialId && (
                        <Typography
                            variant="caption"
                            sx={{
                                fontSize: "12pt",
                                fontWeight: "bold",
                                display: "flex",
                                justifyContent: "right",
                                alignItems: "center"
                            }}
                        >
                            {worksheet?.serialId}
                        </Typography>
                    )}

                    <DateRangeDisplay
                        sx={{
                            display: "flex",
                            justifyContent: "right",
                        }}
                        stackProps={{
                            display: "flex",
                            width: 1,
                            justifyContent: "end"
                        }}
                        startDate={worksheet.startedAt}
                        endDate={worksheet.endedAt}
                        options={{
                            ...dateDisplayDefaultOptions,
                            hour: undefined,
                            minute: undefined
                        }}
                        trimDotFromEnd={true}
                    />

                    {userDetails.isLoading ? (
                        <CircularProgress/>
                    ) : (
                        <Typography variant="body2" sx={{
                            display: "flex",
                            justifyContent: "right",
                            alignItems: "center"
                        }}>
                            {tk('Owner')}: {userDetails.data?.fullName}
                        </Typography>
                    )}

                    {(worksheet.serviceCalls ?? []).length > 1 && (
                        <Stack direction="row" spacing={0}
                               sx={{
                                   width: 1,
                                   py: 0,
                                   display: "flex",
                                   alignItems: "center",
                                   justifyContent: "right"
                               }}
                        >
                            <ReportIcon color="error"/>
                            <Typography
                                variant="body2"
                                sx={{
                                    fontSize: "8pt"
                                }}
                            >
                                {tk('WarnMultipleServiceCalls')}
                            </Typography>
                        </Stack>
                    )}

                    {(worksheet.skipCustomerEmail) && (
                        <Stack direction="row" spacing={0}
                               sx={{
                                   width: 1,
                                   py: 0,
                                   display: "flex",
                                   alignItems: "center",
                                   justifyContent: "right"
                               }}
                        >
                            <MailOutlineIcon color="error"/>
                            <Typography
                                variant="body2"
                                sx={{
                                    fontSize: "8pt"
                                }}
                            >
                                {tk('SkipCustomerEmail')}
                            </Typography>
                        </Stack>
                    )}

                </Stack>
            </Grid>
        </>
    )
}

export default ServiceCallHeaderGridItems;