import React from 'react';
import {
    MechanicListingStateProps,
    useMechanicListingState
} from "modules/worksheet/mechanic/state/useMechanicListingState";
import MechanicWorksheetResultEntry from "modules/worksheet/mechanic/results/MechanicWorksheetResultEntry";
import {Grid} from "@mui/material";
import {ArrayReduceToUniqueMap} from "shared/utils/ArrayReduceToUniqueMap";

export type MechanicWorksheetResultListProps = MechanicListingStateProps & {}
const MechanicWorksheetResultList = ({mechanicStateAtom}: MechanicWorksheetResultListProps) => {

    // const tk = useTranslateFunction(`Worksheet.Generic.Mechanic`)

    const {
        worksheetInfQuery,
        worksheetVersions,
    } = useMechanicListingState(mechanicStateAtom)

    // reduce needed to filter duplicates from paging (if a new worksheet added during paging!)
    const entries = ArrayReduceToUniqueMap(
        worksheetInfQuery.data?.pages?.flatMap(p => p) ?? [],
        e => e.id
    )

    return (
        <Grid container spacing={1}>
            {entries.map(w => (
                <Grid item xs={12} key={w.id}>
                    <MechanicWorksheetResultEntry
                        mechanicStateAtom={mechanicStateAtom}
                        worksheet={w}
                        mismatch={worksheetVersions.mismatches.find((m) => m.server?.id === w.id)}
                    />
                </Grid>
            ))}
        </Grid>
    )
}

export default MechanicWorksheetResultList;