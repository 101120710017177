import {useQuery} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {CDAMPMPhotoRuleModel, PhotoRuleApi} from "crowbar-api";
import AxiosCancelTokenHelper from "crowbar-api/AxiosCancelTokenHelper";

export interface UsePhotoRuleByIdResult {
    status: "idle" | "error" | "loading" | "success"
    isError: boolean
    rule: CDAMPMPhotoRuleModel | null
    refetchRule: () => Promise<void>
}

const usePhotoRuleForEditorById = (ruleId?: string): UsePhotoRuleByIdResult => {
    const {status, data, isError, refetch} = useQuery(['photo-rule-by-id', ruleId], async (context) => {
        if (!ruleId) return null
        const response = await crowbarApiFactory(PhotoRuleApi).findById(ruleId, AxiosCancelTokenHelper.cancelTokenFrom(context.signal))
        if (response.status !== 200) {
            throw new Error('Failed to load Photo Rule. Status not 200.')
        }
        return response.data
    }, {
        cacheTime: 0,
        refetchOnWindowFocus: false,
        retry: 1
    })

    return {
        status,
        isError,
        rule: data ?? null,
        refetchRule: async () => {
            await refetch()
        }
    }
}

export default usePhotoRuleForEditorById