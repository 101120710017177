import {CDAMPMPhotoRuleManufacturerModel} from "crowbar-api";
import {CircularProgress, Link, Paper, Stack} from "@mui/material";
import usePhotoRuleManufacturerDelete
    from "modules/admin/worksheet/photo-rule/photo-rules/PhotoRuleEdit/usePhotoRuleManufacturerDelete";
import CloseIcon from '@mui/icons-material/Close';
import useTranslateFunction from "shared/language/useTranslateFunction";

export interface PhotoRuleEditManufacturerEntryProps {
    prMan: CDAMPMPhotoRuleManufacturerModel
    manufacturerName?: string
    afterDeleted: () => void
}

const PhotoRuleEditManufacturerEntry = ({
                                            prMan,
                                            manufacturerName,
                                            afterDeleted
                                        }: PhotoRuleEditManufacturerEntryProps) => {

    const tk = useTranslateFunction(`Admin.PhotoRule`)

    const deleteRuleManufacturer = usePhotoRuleManufacturerDelete()

    const onCloseClick = async () => {
        if (!prMan.id) return
        await deleteRuleManufacturer.mutateAsync(prMan.id)
        afterDeleted()
    }

    return (
        <Paper sx={{
            padding: 1,
            fontSize: "10pt",
            fontWeight: "bold"
        }}>
            <Stack direction="row" spacing="1">
                {manufacturerName ?? prMan?.sapManufacturerCode}

                {deleteRuleManufacturer?.isLoading ? (
                    <CircularProgress/>
                ) : (
                    <Link
                        title={tk('DeleteManufacturer')}
                        onClick={onCloseClick}
                        sx={{
                            cursor: "pointer",
                            marginLeft: 2
                        }}>
                        <CloseIcon/>
                    </Link>
                )}
            </Stack>
        </Paper>
    )
}

export default PhotoRuleEditManufacturerEntry