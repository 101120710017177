import React from 'react';
import {
    useWorksheetEvalCreateSapDelivery
} from "crowbar-api/hooks/worksheet/evaluation/worksheet/useWorksheetEvalCreateSapDelivery";
import {Button, CircularProgress} from "@mui/material";
import {
    CDAMWMServiceCallEvalModel,
    CDAMWMServiceCallModel,
    CDAMWMWorksheetEvalModel,
    CDAMWMWWorksheetQuery
} from "crowbar-api";
import {toastError} from "shared/toast/DefaultToasts";
import {sapErrorMessage} from "modules/admin/worksheet/evaluation/tabs/service-calls/sap-documents/SapErrorMessage";
import {
    useServiceCallItemRowEvalFindByServiceCallEvalId
} from "crowbar-api/hooks/worksheet/evaluation/service-call-item-row/useServiceCallItemRowEvalFindByServiceCallEvalId";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {StringUtils} from "shared/utils/StringUtils";

export type CreateDeliveryButtonProps = {
    wEval: CDAMWMWorksheetEvalModel | null | undefined
    label: string
    asDraft: boolean

    worksheet: CDAMWMWWorksheetQuery | null | undefined
    sc: CDAMWMServiceCallModel
    scEval: CDAMWMServiceCallEvalModel
    disabled?: boolean

    onDataChange: () => Promise<void>
}

const CreateDeliveryButton = ({
                                  wEval,
                                  label,
                                  asDraft,
                                  worksheet,
                                  sc,
                                  scEval,
                                  disabled,
                                  onDataChange
                              }: CreateDeliveryButtonProps) => {
    const tk = useTranslateFunction("Admin.Worksheet")
    const createDelivery = useWorksheetEvalCreateSapDelivery()

    const rowEvalsQuery = useServiceCallItemRowEvalFindByServiceCallEvalId(scEval.id, {
        keepPreviousData: true
    })
    const rowEvals = rowEvalsQuery.data ?? []

    const onClick = async () => {
        if (!scEval.worksheetEvalId || !scEval.id) return

        // validate required data
        if (!scEval.selectedSapCallType) {
            toastError(tk('SapCallTypeIsRequired'))
            return
        }
        if (!scEval.technicianEmpId) {
            toastError(tk('TechnicianIsRequired'))
            return
        }
        // for rows
        if (rowEvals.find(re => !re.rowAck)) {
            toastError(tk('RowAckRequired'))
            return
        }

        // check billing address
        if (StringUtils.undefinedNullOrEmpty(scEval.billingSapAddressAndType)) {
            toastError(tk('BillingAddressIsRequired'))
            return
        }

        // validate partner and partner address master data selection (required)
        if (!wEval?.partnerAck) {
            toastError(tk('PartnerAckRequired'))
            return
        }
        /*if (!wEval?.addressAck) {
            toastError(tk('AddressAckRequired'))
            return
        }*/

        // call sap delivery create
        const result = await createDelivery.mutateAsync({
            evalId: scEval.worksheetEvalId,
            scEvalId: scEval.id,
            asDraft,
            linkSalesOrder: scEval.linkSalesOrderToDelivery ?? false
        })

        // display sap error
        if (!result.success) {
            toastError(sapErrorMessage(result), {
                autoClose: false
            })
        }

        await onDataChange()
    }

    return (
        <Button
            variant="contained"
            disabled={createDelivery.isLoading || disabled || !!scEval.deliveryAck || (
                !asDraft && !!scEval.manuallySetDeliveryDocEntry
            )}
            onClick={onClick}
            sx={{
                width: 1
            }}
        >
            {createDelivery.isLoading ? (
                <CircularProgress size="28px"/>
            ) : (
                label
            )}
        </Button>
    );
};

export default CreateDeliveryButton;