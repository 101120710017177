import {useMutation} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {CDAMWMWServiceCallBlobSave, ServiceCallBlobApi} from "crowbar-api";

export interface UseReplaceServiceCallBlobSaveProps {
    oldBlobId: string
    oldServiceCallId: string
    blobSave: CDAMWMWServiceCallBlobSave
}

export const useReplaceServiceCallBlobSave = () => {
    return useMutation(async ({oldBlobId, oldServiceCallId, blobSave}: UseReplaceServiceCallBlobSaveProps) => {
        await crowbarApiFactory(ServiceCallBlobApi).replaceServiceCallBlobFor(oldBlobId, oldServiceCallId, blobSave)
    })
}