import {Box, Stack, SxProps, Theme} from "@mui/material";
import {useServiceCallItemRowAtom} from "modules/worksheet/editor/state/atoms/useServiceCallItemRowAtom";
import React, {useEffect, useState} from "react";
import {CDAMWMWServiceCallItemRowSave} from "crowbar-api";
import WEditorPageCatalogueItemRowQuantityInputField
    from "modules/worksheet/editor/ui/catalogue/WEditorPageCatalogueItemRowQuantityInputField";
import {useNewAtomWithRef} from "shared/hook/useNewatomWithRef";

import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import Decimal from "decimal.js";
import IconButtonAndDebounce from "shared/components/buttons/IconButtonAndDebounce";

interface WEditorPageCatalogueItemRowQuantityInputProps {
    itemRow: CDAMWMWServiceCallItemRowSave
    disabled: boolean
}

const qtyButtonSx = (disabled: boolean): SxProps<Theme> => {
    return {
        height: "48%",
        marginTop: "1%",
        marginBottom: "1%",
        padding: "2px",
        cursor: "pointer",
        color: theme => disabled ? theme.palette.text.disabled : theme.palette.text.primary,
        fontWeight: "bold",
        ":hover": disabled ? {} : {
            color: theme => theme.palette.text.secondary,
            transform: "scale(1.2)"
        }
    }
}

const WEditorPageCatalogueItemRowQuantityInput = ({
                                                      itemRow,
                                                      disabled
                                                  }: WEditorPageCatalogueItemRowQuantityInputProps) => {

    const [installedQty, setInstalledQty] = useState<number>(itemRow.installedQuantity ?? 0)
    const [, setItemRow] = useServiceCallItemRowAtom(itemRow.serviceCallId, itemRow.id)
    const [qtyButtonPressCount, setQtyButtonPressCount] = useNewAtomWithRef<number>(0)

    useEffect(() => {
        setItemRow(prev => {
            return {
                ...prev,
                installedQuantity: installedQty
            }
        })
    }, [installedQty, setItemRow])

    const onInstalledQuantityChange = (newValue: number) => {
        setInstalledQty(newValue)
    }

    const onChangeButton = (disabled: boolean, addValue: number) => {
        if (disabled) return
        setInstalledQty(prev => {
            // Decimal mitigates floating point rounding errors
            // example: 1.2 - 1 = 0.19999999999999996
            const dPrev = new Decimal(prev)
            const dAdd = new Decimal(addValue)
            return dPrev.add(dAdd).toNumber()
        })
        setQtyButtonPressCount(prev => prev + 1)
    }

    return (
        <Stack direction="row" sx={{
            height: 1
        }}>
            <Box sx={{
                marginRight: 1
            }}>
                <WEditorPageCatalogueItemRowQuantityInputField
                    key={[itemRow?.id ?? "", qtyButtonPressCount/*itemRow?.installedQuantity ?? 0*/].join("-")}

                    value={installedQty}
                    unitOfQuantity={itemRow?.unitOfQuantity ?? undefined}
                    onChange={onInstalledQuantityChange}
                    disabled={disabled}
                />
            </Box>
            <Stack direction="column">
                <IconButtonAndDebounce
                    sx={qtyButtonSx(disabled)}
                    onClick={() => onChangeButton(disabled, 1)}
                >
                    <ArrowCircleUpIcon
                        sx={{
                            fontSize: "44pt"
                        }}
                    />
                </IconButtonAndDebounce>
                <IconButtonAndDebounce
                    sx={qtyButtonSx(disabled)}
                    onClick={() => onChangeButton(disabled || installedQty < 1, -1)}
                >
                    <ArrowCircleDownIcon
                        sx={{
                            fontSize: "44pt"
                        }}
                    />
                </IconButtonAndDebounce>
            </Stack>

        </Stack>
    )
}

export default WEditorPageCatalogueItemRowQuantityInput