import React, {useState} from 'react';
import {Box, LinearProgress, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {useNavigate} from "react-router-dom";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {useUserContext} from "shared/hook/UserContext";
import ConfirmDialog from "shared/components/confirm-dialog/ConfirmDialog";
import WorksheetSaveConfirmationDialog
    from "modules/worksheet/editor/shared/component/worksheet-save-dialog/WorksheetSaveConfirmationDialog";
import {UseWorksheetSaveMutationResult} from "crowbar-api/hooks/worksheet/useWorksheetSaveMutation";
import useWorksheetSignedState from "modules/worksheet/editor/state/helpers/useWorksheetSignedState";
import {red} from "@mui/material/colors";

const WEditorHeaderCloseButton = () => {
    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    const [open, setOpen] = useState<boolean>(false)

    const [userId] = useUserContext()
    const navigate = useNavigate()

    const {evaluated} = useWorksheetSignedState()

    const onHeaderCloseClick = () => {
        setOpen(true)
    }

    const onCloseWithSave = async (onClickStartSave: () => Promise<UseWorksheetSaveMutationResult>) => {
        const result = await onClickStartSave()
        if (result.differentPreviousVersionError) return
        setOpen(false)
        navigate(`/worksheet/mechanic/${userId}/worksheets`)
    }

    const onCloseWithoutSave = () => {
        setOpen(false)
        navigate(`/worksheet/mechanic/${userId}/worksheets`)
    }

    return (
        <>
            <Box
                sx={{
                    display: "block",
                    cursor: "pointer"
                }}
                onClick={onHeaderCloseClick}
            >
                <CloseIcon
                    sx={{
                        fontSize: "28pt",
                        color: theme => theme.palette.error.light,
                        ":hover": {
                            color: theme => theme.palette.error.dark
                        }
                    }}
                />
            </Box>

            <WorksheetSaveConfirmationDialog
                render={(onClickStartSave, isLoading) => (
                    <ConfirmDialog
                        open={open}
                        onClose={() => setOpen(false)}
                        onConfirm={() => onCloseWithSave(onClickStartSave)}
                        onAlternativeConfirm={onCloseWithoutSave}

                        okButtonProps={{
                            disabled: isLoading || evaluated
                        }}
                        alternativeOkButtonProps={{
                            disabled: isLoading
                        }}
                        cancelButtonProps={{
                            disabled: isLoading
                        }}

                        dialogTitle={tk('WorksheetCloseTitle')}
                        dialogText={(
                            <>
                                <Typography>
                                    {tk('WorksheetCloseDescription')}
                                </Typography>
                                <Typography fontSize="10pt" fontStyle="italic">
                                    {tk('WorksheetCloseDescription2')}
                                </Typography>
                                <Typography fontWeight="bold">
                                    {tk('WorksheetCloseDescription3')}
                                </Typography>

                                {isLoading && (
                                    <LinearProgress/>
                                )}
                                {evaluated && (
                                    <Typography sx={{color: red[700]}} fontWeight="bold">
                                        {tk('EditDisabledMsgEvaluated')}
                                    </Typography>
                                )}
                            </>
                        )}

                        okButtonText={tk('CloseAndSave')}
                        alternativeOkButtonText={tk('CloseWithoutSave')}
                    />
                )}
            />
        </>
    )
}

export default WEditorHeaderCloseButton;