import {worksheetSaveStateAtom} from "modules/worksheet/editor/state/WorksheetSaveAtoms";
import {useAtom} from "jotai";
import {useMemo} from "react";
import {focusAtom} from "jotai-optics";

export const useServiceCallItemRowAtom = (
    serviceCallId: string | undefined,
    itemRowId: string | undefined) => {

    const focusedAtom = useMemo(() => {
        return focusAtom(worksheetSaveStateAtom, (optic) =>
            optic
                .optional()
                .prop('serviceCalls')
                .optional()
                .find(sc => sc.id === serviceCallId)
                .prop('itemRows')
                .optional()
                .find(ir => ir.id === itemRowId)
        )
    }, [serviceCallId, itemRowId])

    return useAtom(focusedAtom)
}