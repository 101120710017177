import {CDAMWMServiceCallSelectedSource} from "crowbar-api";

export default class ServiceCallSelectedSourceMap {
    public static readonly notSet = CDAMWMServiceCallSelectedSource.NUMBER_0
    public static readonly manuallyAdded = CDAMWMServiceCallSelectedSource.NUMBER_1
    public static readonly sapSalesOrder = CDAMWMServiceCallSelectedSource.NUMBER_3
    public static readonly sapPartnerEquipment = CDAMWMServiceCallSelectedSource.NUMBER_4
    public static readonly empty = CDAMWMServiceCallSelectedSource.NUMBER_5

    public static translationKey = (t?: CDAMWMServiceCallSelectedSource): string => {

        const preKey = "Worksheet.Generic.ServiceCallSelectedSource."

        if (t === this.notSet) {
            return `${preKey}NotSet`
        }

        if (t === this.manuallyAdded) {
            return `${preKey}ManuallyAdded`
        }

        if (t === this.sapSalesOrder) {
            return `${preKey}SapSalesOrder`
        }

        if (t === this.empty) {
            return `${preKey}Empty`
        }

        return "Missing"
    }
}