import {CDAMNMEmailNotificationStatus} from "crowbar-api";


export default class EmailNotificationStatusMap {
    public static success = CDAMNMEmailNotificationStatus.NUMBER_1
    public static failure = CDAMNMEmailNotificationStatus.NUMBER_2
    public static skipped = CDAMNMEmailNotificationStatus.NUMBER_3

    public static all(): CDAMNMEmailNotificationStatus[] {
        return [
            EmailNotificationStatusMap.success,
            EmailNotificationStatusMap.failure,
            EmailNotificationStatusMap.skipped
        ]
    }

    public static translationKey(t?: CDAMNMEmailNotificationStatus): string {

        const preKey = "Admin.EmailNotificationStatus."

        if (!t) {
            return "Missing";
        }

        if (t === CDAMNMEmailNotificationStatus.NUMBER_1) {
            return preKey + "Success"
        }

        if (t === CDAMNMEmailNotificationStatus.NUMBER_2) {
            return preKey + "Failure"
        }

        if (t === CDAMNMEmailNotificationStatus.NUMBER_3) {
            return preKey + "Skipped"
        }

        return "Missing";
    }
}