import React from 'react';
import {CDAMWMServiceCallEvalModel, CDAMWMServiceCallModel, CDAMWMWWorksheetQuery} from "crowbar-api";
import CheckIconButton from "shared/components/buttons/CheckIconButton";
import {
    useServiceCallEvalUpdateDeliveryAckByDocNum
} from "crowbar-api/hooks/worksheet/evaluation/service-call/useServiceCallEvalUpdateDeliveryAck";
import {toastError} from "shared/toast/DefaultToasts";
import useTranslateFunction from "shared/language/useTranslateFunction";

export type SapDeliveryAckProps = {
    currentDocNum: number | null | undefined

    worksheet: CDAMWMWWorksheetQuery | null | undefined
    sc: CDAMWMServiceCallModel
    scEval: CDAMWMServiceCallEvalModel
    disabled?: boolean
    onMasterDataChange: () => Promise<void>
}

const SapDeliveryAck = ({
                            currentDocNum,
                            worksheet,
                            sc,
                            scEval,
                            disabled,
                            onMasterDataChange
                        }: SapDeliveryAckProps) => {
    const tk = useTranslateFunction('Admin.Worksheet')
    const updateDeliveryAck = useServiceCallEvalUpdateDeliveryAckByDocNum()

    const onClick = async () => {
        if (!scEval.id || !scEval.serviceCallId) {
            toastError('Missing crowbar service call and eval ids.')
            return
        }
        if (!currentDocNum) {
            toastError(tk('InvalidDeliveryNumber'))
            return
        }

        const result = await updateDeliveryAck.mutateAsync({
            evalId: scEval.id,
            originalId: scEval.serviceCallId,
            deliveryAck: !scEval.deliveryAck,
            deliveryDocNum: currentDocNum
        })

        if (!result.success) {
            switch (result.errorId) {
                case 1:
                    toastError(tk('InvalidDeliveryNumber'));
                    break;
            }
        }

        await onMasterDataChange()
    }

    return (
        <CheckIconButton
            size="large"
            checked={scEval.deliveryAck ?? false}
            disabled={disabled || updateDeliveryAck.isLoading}
            checkCircleIconProps={{
                sx: {
                    fontSize: "28pt"
                }
            }}
            onClick={onClick}
        />
    )
}

export default SapDeliveryAck;