import {UseQueryResult} from "@tanstack/react-query";
import {CDAMPMPhotoRuleStepModel, CDAMPMPhotoRuleStepOrderSave,} from "crowbar-api";
import {toastError} from "shared/toast/DefaultToasts";
import {byPropertiesOf} from "shared/utils/ArraySort";
import usePhotoRuleStepSaveOrder
    from "modules/admin/worksheet/photo-rule/photo-rules/PhotoRuleEdit/usePhotoRuleStepSaveOrder";
import {useNewAtomWithRef} from "shared/hook/useNewatomWithRef";
import {Box, CircularProgress, Stack} from "@mui/material";
import VerticalAlignTopIcon from "@mui/icons-material/VerticalAlignTop";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";

export interface PhotoRuleEditStepMoveButtonsProps {
    lastOrderNum: number
    stepsQuery: UseQueryResult<CDAMPMPhotoRuleStepModel[], unknown>
    step: CDAMPMPhotoRuleStepModel
}

const PhotoRuleEditStepMoveButtons = ({lastOrderNum, stepsQuery, step}: PhotoRuleEditStepMoveButtonsProps) => {

    const [moveLocked, setMoveLocked] = useNewAtomWithRef(false)

    const orderSave = usePhotoRuleStepSaveOrder()

    const moveStep = (direction: 'up' | 'down') => {
        if (!step.orderNum || !stepsQuery.data) {
            toastError('Invalid step.orderNum or steps data.')
            return
        }

        const newOrderNum = (direction === 'up')
            ? Math.max(step.orderNum - 1, 1)
            : Math.min(step.orderNum + 1, lastOrderNum)

        if (newOrderNum === step.orderNum) {
            return;
        }

        const newOrders = stepsQuery.data.map((s): CDAMPMPhotoRuleStepOrderSave => {
            return {
                id: s.id,
                orderNum: s.orderNum
            }
        }).sort(byPropertiesOf<CDAMPMPhotoRuleStepOrderSave>(['orderNum']))

        const current = newOrders.find(o => o.id === step.id)
        if (!current) {
            return;
        }
        const currentIndex = newOrders.indexOf(current)

        newOrders[currentIndex].orderNum = newOrderNum

        if (direction === 'up' && newOrders[currentIndex - 1] !== undefined) {
            newOrders[currentIndex - 1].orderNum = newOrderNum + 1
        }
        if (direction === 'down' && newOrders[currentIndex + 1] !== undefined) {
            newOrders[currentIndex + 1].orderNum = newOrderNum - 1
        }

        setMoveLocked(true)
        orderSave.mutate(newOrders, {
            onSettled: () => {
                stepsQuery.refetch().then(() => {
                    setMoveLocked(false)
                })
            }
        })
    }


    return (
        <>
            {moveLocked ? (
                <CircularProgress/>
            ) : (
                <Stack direction="column" spacing={1}>
                    {step.orderNum && step.orderNum > 1 && (
                        <Box
                            onClick={() => moveStep('up')}
                            sx={{
                                cursor: "pointer"
                            }}
                        >
                            <VerticalAlignTopIcon/>
                        </Box>
                    )}
                    {step.orderNum && step.orderNum < lastOrderNum && (
                        <Box
                            onClick={() => moveStep('down')}
                            sx={{
                                cursor: "pointer"
                            }}
                        >
                            <VerticalAlignBottomIcon/>
                        </Box>
                    )}
                </Stack>
            )}
        </>
    )
}

export default PhotoRuleEditStepMoveButtons