import React, {useEffect, useMemo, useState} from 'react';
import {CDAMWMWServiceCallItemRowSearchQuery} from "crowbar-api";
import {PaginationState} from "@tanstack/table-core";
import {useMaterialReactTableLocalization} from "i18n/material-table/useMaterialReactTableLocalization";
import useTranslateFunction from "shared/language/useTranslateFunction";
import MaterialReactTable, {MRT_ColumnDef} from "material-react-table";
import {Box, Card, Grid, Typography} from '@mui/material';
import RenderIfVisible from "shared/render-if-visible/render-if-visible";
import ServiceCallItemRowStatusMap from "crowbar-api/enum-map/ServiceCallItemRowStatusMap";

export type ServiceCallTableProps = {
    pageSize: number
    itemRows: CDAMWMWServiceCallItemRowSearchQuery[]
}

const ServiceCallTable = ({pageSize, itemRows}: ServiceCallTableProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.Mechanic`)

    const [pagination, setPagination] = useState<PaginationState>({pageSize: pageSize, pageIndex: 0})

    const mrt_locale = useMaterialReactTableLocalization()

    const itemRowsSafe = itemRows ?? []

    // Allows to update the pageSize property from outside
    useEffect(() => {
        setPagination(prev => ({...prev, pageSize: pageSize}))
    }, [pageSize])

    const columns = useMemo<MRT_ColumnDef<CDAMWMWServiceCallItemRowSearchQuery>[]>(() => [
        {
            accessorKey: 'itemCode',
            header: tk('ItemCode'),
            minSize: 20,
            size: 90,
            maxSize: 40,
        },
        {
            accessorKey: 'itemName',
            header: tk('ItemName'),
            minSize: 20,
            size: 150,
            maxSize: 200,
        },
        {
            accessorKey: "installedQuantity",
            header: tk('InstalledQuantity'),
            minSize: 20,
            size: 70,
            maxSize: 200
        }
    ], [tk])

    return (
        <Card variant="outlined" sx={{
            height: 1,
            width: 1,
        }}>
            {itemRowsSafe.length < 1 ? ( // temp
                <Box
                    sx={{
                        p: 3
                    }}
                >
                    <Typography>
                        {tk('NoItemRows')}
                    </Typography>
                </Box>
            ) : (
                <Grid container>
                    <Grid item xs={12}>
                        <RenderIfVisible stayRendered={true}>
                            <MaterialReactTable
                                columns={columns}
                                data={itemRowsSafe}
                                localization={mrt_locale}
                                initialState={{
                                    density: 'compact'
                                }}
                                state={{
                                    pagination
                                }}
                                onPaginationChange={setPagination}
                                rowNumberMode="original" //default
                                enableColumnResizing={true}
                                enableColumnFilters={false}
                                enableColumnActions={false}
                                enableFullScreenToggle={false}
                                muiTableBodyCellProps={({table, row}) => {
                                    return {
                                        sx: (theme) => ({
                                            color: (row.original.status === ServiceCallItemRowStatusMap.installed)
                                                ? theme.palette.text.disabled
                                                : theme.palette.text.primary
                                        })
                                    }
                                }}
                            />
                        </RenderIfVisible>
                    </Grid>
                </Grid>
            )}
        </Card>
    )
}

export default ServiceCallTable;