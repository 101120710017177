import {CDAMWMWorksheetTripType} from "crowbar-api";

export default class WorksheetTripTypeMap {
    public static readonly notSet = CDAMWMWorksheetTripType.NUMBER_0
    public static readonly fromSalesOrder = CDAMWMWorksheetTripType.NUMBER_1
    public static readonly fromPartnerPreset = CDAMWMWorksheetTripType.NUMBER_2
    public static readonly AtWorkshop = CDAMWMWorksheetTripType.NUMBER_3
    public static readonly manuallySet = CDAMWMWorksheetTripType.NUMBER_4

    public static translationKey = (t?: CDAMWMWorksheetTripType): string => {

        const preKey = "Worksheet.Generic.WorksheetTripType."

        if (t === this.notSet) {
            return `${preKey}NotSet`
        }

        if (t === this.fromSalesOrder) {
            return `${preKey}SalesOrder`
        }

        if (t === this.fromPartnerPreset) {
            return `${preKey}PartnerPreset`
        }

        if (t === this.AtWorkshop) {
            return `${preKey}AtWorkshop`
        }

        return "Missing"
    }
}