import React from 'react';
import {
    CustomerNotificationConfigurationProps
} from "modules/admin/worksheet/notifications/customer-notification/CustomerNotificationConfigurationProps";
import {
    useCustomerNotificationConfigurationState
} from "modules/admin/worksheet/notifications/customer-notification/state/useCustomerNotificationConfigurationState";
import {Box, LinearProgress, Stack} from '@mui/material';
import {
    CustomerNotificationConfigurationResultsTable
} from "modules/admin/worksheet/notifications/customer-notification/results/CustomerNotificationConfigurationResultsTable";
import CustomerNotificationConfigurationDialogButton
    from "modules/admin/worksheet/notifications/customer-notification/results/CustomerNotificationConfigurationDialogButton";

export type CustomerNotificationConfigurationResultsProps = CustomerNotificationConfigurationProps & {}

export const CustomerNotificationConfigurationResults = ({stateAtom}: CustomerNotificationConfigurationResultsProps) => {
    const {configurationQuery} = useCustomerNotificationConfigurationState(stateAtom)

    const onStateChanged = async () => {
        await configurationQuery.refetch()
    }

    return (
        <Stack
            direction="column"
            spacing={3}
            width={1}
            alignItems="center"
            justifyContent="center"
            sx={{
                mb: 4
            }}
        >
            <Box alignItems="start"
                 justifyContent="start"
                 width={1}
            >
                <CustomerNotificationConfigurationDialogButton
                    mode="new"
                    onConfigurationChange={onStateChanged}
                    variant="contained"
                />
            </Box>

            <CustomerNotificationConfigurationResultsTable stateAtom={stateAtom} onStateChanged={onStateChanged}/>

            {(configurationQuery.isLoading || configurationQuery.isFetching) && (
                <Box sx={{width: '100%'}}>
                    <LinearProgress/>
                </Box>
            )}
        </Stack>
    )
}