import {useQuery} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {UserProfileApi} from "crowbar-api";

export const useProfileImageById = (userId: string | undefined | null) => {
    return useQuery(['loadProfileImageById-1', userId], async () => {
        if (!userId) return null
        try {
            const userProfileApi = crowbarApiFactory(UserProfileApi, undefined, true)
            const response = await userProfileApi.getSmallUserPhotoBase64(userId)
            if (response.status !== 200) return
            return response.data
        } catch (e: any) {
            return null
        }
    }, {
        retry: 3,
        cacheTime: Math.pow(10, 8),
        staleTime: Math.pow(10, 8),
        refetchOnWindowFocus: false,
        refetchOnMount: false
    })
}