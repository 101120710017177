import {
    SapPartnerAddressSelectValue
} from "modules/worksheet/editor/ui/basic/address-select/useSapPartnerAddressSelectValuesAtom";
import {useWorksheetSaveState} from "modules/worksheet/editor/state/WorksheetSaveAtoms";
import {useCallback} from "react";
import {CDAMWMWModSapPartnerAddressSave} from "crowbar-api";
import {v4 as uuid} from "uuid";
import ModSapTypeMap from "crowbar-api/enum-map/ModSapTypeMap";
import ModSapStatusMap from "crowbar-api/enum-map/ModSapStatusMap";
import SapPartnerAddressUtils from "crowbar-api/util/SapPartnerAddressUtils";

export const mapSelectedPartnerAddressToMod = (cardCode: string | undefined | null,
                                               spa: SapPartnerAddressSelectValue | undefined): CDAMWMWModSapPartnerAddressSave | undefined => {
    // nothing is selected --> new empty mod
    if (!spa) {
        return {
            id: uuid(),
            type: ModSapTypeMap.create,
            status: ModSapStatusMap.suggested,
            cardCode: cardCode
        }
    }

    // Editing a mod
    if (spa.source === "mod") {
        return spa.mod
    }

    // Editing an sap address --> new mod with address values
    const a = spa.address ?? {}
    const uid = SapPartnerAddressUtils.addressKey(a)
    return {
        id: uuid(),
        type: ModSapTypeMap.update,
        status: ModSapStatusMap.suggested,
        cardCode: cardCode,
        addressType: a?.addressType,
        address: a?.addressName,
        newAddressIdentifier: uid,
        newAddressType: a?.addressType,
        countryCode: a?.country,
        zipCode: a?.zipCode,
        city: a?.city,
        street: a?.street,
        streetNo: a?.streetNo
    }
}

const useFindSelectedSapPartnerAddressAsMod = (): (
    selectValues: SapPartnerAddressSelectValue[],
    selectedKey: string | undefined
) => CDAMWMWModSapPartnerAddressSave | undefined => {
    const [worksheetSave] = useWorksheetSaveState()

    return useCallback((
        selectValues: SapPartnerAddressSelectValue[],
        selectedKey: string | undefined
    ) => {
        const selectedPartnerAddress = selectValues.find(v => v.key === selectedKey)
        return mapSelectedPartnerAddressToMod(worksheetSave?.cardCode, selectedPartnerAddress)

    }, [worksheetSave?.cardCode])
}

export default useFindSelectedSapPartnerAddressAsMod