import React from 'react';
import {CDAMWMWServiceCallSave} from "crowbar-api";
import EquipmentThumbnailForServiceCall
    from "modules/worksheet/editor/shared/component/equipment-thumbnail/EquipmentThumbnailForServiceCall";

export interface WEditorHeaderCompactImageProps {
    selectedServiceCall: CDAMWMWServiceCallSave
}

const WEditorHeaderCompactImage = ({selectedServiceCall}: WEditorHeaderCompactImageProps) => {
    return (
        <EquipmentThumbnailForServiceCall size={150} serviceCall={selectedServiceCall}/>
    )
}

export default WEditorHeaderCompactImage;