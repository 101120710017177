import React from 'react';
import {CDSMNNewWorksheetEquipment} from "crowbar-api";
import {Button, Card, CardActions, CardContent, CircularProgress, Grid, Typography} from "@mui/material";
import ImageIcon from "shared/components/image-icon/ImageIcon";
import {WorksheetIconImages} from "modules/worksheet/editor/shared/component/images/WorksheetIconImages";
import useTranslateFunction from "shared/language/useTranslateFunction";
import NewWorksheetEquipmentCard from "modules/worksheet/new-worksheet/results/NewWorksheetEquipmentCard";
import NewWorksheetResultEquipmentTable from "modules/worksheet/new-worksheet/results/NewWorksheetResultEquipmentTable";
import {
    useNewWorksheetFromEquipmentAction
} from "modules/worksheet/editor/state/events/useNewWorksheetFromEquipmentAction";
import {toastError, toastSuccess} from "shared/toast/DefaultToasts";
import {useOpenWorksheetEditor} from "modules/worksheet/editor/state/events/useOpenWorksheetEditor";
import {useMountedState} from "shared/hook/useMountedState";

export interface NewWorksheetResultEquipmentProps {
    equipment: CDSMNNewWorksheetEquipment
    pageSize: number
}

const NewWorksheetResultEquipment = ({equipment, pageSize}: NewWorksheetResultEquipmentProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.NewWorksheet`)

    const newWorksheetFromEquipmentAction = useNewWorksheetFromEquipmentAction()
    const openWorksheetEditor = useOpenWorksheetEditor()

    const isMounted = useMountedState()

    const hasSalesOrders = (equipment?.salesOrders ?? []).length > 0

    const onNewWorksheetClick = async () => {
        if (!equipment?.sapEquipmentId) {
            toastError('Missing equipment id')
            return
        }
        const newWorksheet = await newWorksheetFromEquipmentAction
            .mutateAsync({sapEquipmentId: equipment.sapEquipmentId})
        if (isMounted()) {
            openWorksheetEditor(newWorksheet.id)
        } else {
            toastSuccess(`${tk('WorksheetCreatedSilent')}${newWorksheet.partnerCardName}`)
        }
    }

    return (
        <Card elevation={16} sx={{
            p: 2,
            border: "2px solid #131B23"
        }}>
            <CardContent>
                <Grid container spacing={0.5}>
                    <Grid item xs={12}>
                        <Typography
                            variant="h6"
                            sx={{
                                display: "flex",
                                alignItems: "center"
                            }}
                        >
                            <ImageIcon image={WorksheetIconImages.forkliftSmall} size={32}/>&nbsp;{tk('Equipment')}
                        </Typography>
                    </Grid>

                    <Grid item xs={6}>
                        {/*<VehicleTypeDisplay
                       variant="h6"
                       manufacturer={equipment.manufacturer}
                       vehicleType={equipment.vehicleType}
                   />*/}
                        <Typography variant="h6">
                            {equipment.cardName}&nbsp;({equipment.cardCode})
                        </Typography>
                        <Typography variant="body2">
                            {hasSalesOrders ? tk('OpenSalesOrders')
                                : tk('NoSalesOrders')
                            }
                        </Typography>
                    </Grid>

                    <Grid item xs={6}>
                        <Typography
                            variant="h6"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "right",
                                textAlign: "right"
                            }}
                        >
                            {tk('NumberOfServiceCalls')}:&nbsp;{equipment.serviceCallCount ?? 0}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container
                              direction="row"
                              spacing={1}
                              justifyContent="space-between"
                        >

                            <Grid item
                                  xs={true}
                            >
                                {hasSalesOrders && (
                                    <NewWorksheetResultEquipmentTable
                                        equipment={equipment}
                                        pageSize={pageSize}
                                    />
                                )}
                            </Grid>

                            <Grid item xs="auto">
                                <NewWorksheetEquipmentCard
                                    coverImage={equipment.coverImage}
                                    manufacturer={equipment.manufacturer}
                                    vehicleType={equipment.vehicleType}
                                    manufacturerSn={equipment.manufacturerSn}
                                    internalSn={equipment.internalSn}
                                    yearOfManufacture={equipment.yearOfManufacture}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions sx={{
                display: "flex",
                justifyContent: "right"
            }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onNewWorksheetClick}
                    disabled={newWorksheetFromEquipmentAction.isLoading}
                >
                    {tk('NewWorksheet')}
                    {newWorksheetFromEquipmentAction.isLoading && (
                        <CircularProgress size="16px"/>
                    )}
                </Button>
            </CardActions>
        </Card>
    )
}

export default NewWorksheetResultEquipment;