export default class UsePagingUtils {
    public static hasNextPage = (data: any[] | undefined, limit: number | null | undefined): boolean => {
        return data ? data.length >= (limit ?? 0) : false
    }

    public static nextPageRowNumber = (lastRowNumber?: number | undefined, limit?: number | null | undefined): number => {
        return (lastRowNumber ?? 0) + (limit ?? 0)
    }

    public static hasPreviousPage = (lastRowNumber?: number | undefined, limit?: number | null | undefined) => {
        return lastRowNumber && limit ? lastRowNumber - limit >= 0 : false
    }

    public static previousPageRowNumber = (lastRowNumber?: number | undefined, limit?: number | null | undefined): number => {
        return Math.max((lastRowNumber ?? 0) - (limit ?? 0), 0)
    }
}