import React from 'react';
import {CatalogueTableRow} from "modules/admin/worksheet/evaluation/tabs/service-calls/catalogue/table/CatalogueTable";
import CostRateSelect from "modules/admin/worksheet/evaluation/tabs/service-calls/catalogue/CostRateSelect";
import {CDSMSapCostRate} from "crowbar-api";
import {
    useServiceCallItemRowEvalUpdateOcrCode
} from "crowbar-api/hooks/worksheet/evaluation/service-call-item-row/useServiceCallItemRowEvalUpdateOcrCode";

export type CostRateCellProps = {
    row: CatalogueTableRow
    sapDim: number
    onDataChange: () => Promise<void>
    disabled?: boolean
}
const CostRateCell = ({row, sapDim, onDataChange, disabled}: CostRateCellProps) => {

    const value = sapDim === 1 ? row.eval?.ocrCode1
        : sapDim === 2 ? row.eval?.ocrCode2
            : sapDim === 3 ? row.eval?.ocrCode3
                : sapDim === 4 ? row.eval?.ocrCode4
                    : "";

    const updateOcrCode = useServiceCallItemRowEvalUpdateOcrCode();

    const onChange = async (r: CDSMSapCostRate | undefined) => {
        if (!row.eval?.id) return
        await updateOcrCode.mutateAsync({
            itemRowsIds: [row.eval.id],
            ocrCodeValue: r?.ocrCode,
            dim: sapDim
        })
        await onDataChange()
    }

    return (
        <CostRateSelect
            label={undefined}
            sapDim={sapDim}
            value={value ?? ""}
            onChange={onChange}
            disabled={disabled || updateOcrCode.isLoading}
        />
    )
}

export default CostRateCell;