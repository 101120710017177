import {IconButton, TextField as MuiTextField, TextFieldProps as MuiTextFieldsProps} from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';

export type TextFieldProps = MuiTextFieldsProps & {
    showClearButton?: boolean
    onClearClick?: () => void
}

export const TextField = ({showClearButton, onClearClick, ...props}: TextFieldProps) => {
    return (
        <MuiTextField
            {...props}
            InputProps={{
                endAdornment: (
                    !showClearButton ? <></>
                        : (
                            <IconButton onClick={() => onClearClick?.()}>
                                <ClearIcon/>
                            </IconButton>
                        )
                ),
                ...props.InputProps
            }}
        />
    )
}