import React from 'react';
import {Stack} from "@mui/material";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {
    EmailNotificationListingState
} from "modules/admin/worksheet/notifications/email-notifications/state/useEmailNotificationListingState";
import {DateUtils} from "shared/utils/DateUtils";
import EmailNotificationFilters
    from "modules/admin/worksheet/notifications/email-notifications/filters/EmailNotificationFilters";
import {atomWithStorage} from "jotai/utils";
import {
    EmailNotificationResults
} from "modules/admin/worksheet/notifications/email-notifications/results/EmailNotificationResults";

const defaultValue: EmailNotificationListingState = {
    search: "",
    source: 1,
    showDeleted: false,
    lastRowNumber: 0,
    limit: 5,
    orderByIsDesc: true,
    worksheetStart: DateUtils.newServerUTCDateAndAdd({year: -1}),
    worksheetEnd: DateUtils.newServerUTCDateAndAdd({month: 1}),
    status: undefined
}

const stateAtom = atomWithStorage<EmailNotificationListingState>("UiState-Admin-EmailNotifications", defaultValue)

const EmailNotifications = () => {
    const tk = useTranslateFunction(`Admin.Worksheet`)

    return (
        <Stack direction="column" spacing={1}>
            <h1>{tk('EmailNotifications')}</h1>

            <EmailNotificationFilters stateAtom={stateAtom}/>

            <EmailNotificationResults stateAtom={stateAtom}/>
        </Stack>
    )
}

export default EmailNotifications;