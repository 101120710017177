import React from 'react';
import {useSapServiceCallsByInsId} from "crowbar-api/hooks/sap/useSapServiceCallsByInsId";
import {CircularProgress, Grid, Stack, Typography} from "@mui/material";
import EquipmentServiceCallEntry
    from "modules/equipment/equipment-details/equipment-service-calls/EquipmentServiceCallEntry";
import ImageIcon from "shared/components/image-icon/ImageIcon";
import {WorksheetIconImages} from "modules/worksheet/editor/shared/component/images/WorksheetIconImages";
import useTranslateFunction from "shared/language/useTranslateFunction";

export type EquipmentServiceCallsProps = {
    sapEquipmentId?: number | undefined
    modSapEquipmentId?: string | undefined
}
const EquipmentServiceCalls = ({sapEquipmentId, modSapEquipmentId}: EquipmentServiceCallsProps) => {
    const tk = useTranslateFunction("Equipment")
    const serviceCallsQuery = useSapServiceCallsByInsId(sapEquipmentId)

    const serviceCalls = serviceCallsQuery.data ?? []

    const isLoading = serviceCallsQuery.isLoading || serviceCallsQuery.isFetching

    if (isLoading) {
        return (
            <CircularProgress/>
        )
    }

    if (serviceCalls.length < 1) {
        return (
            <Stack direction="row" spacing={1} alignItems="center">
                <ImageIcon image={WorksheetIconImages.sadNotFoundMedium} size={64}/>
                <Typography>{tk('NoServiceCallsFound')}</Typography>
            </Stack>
        )
    }

    return (
        <Grid container spacing={1}>
            {serviceCalls.map(sc => (
                <Grid key={sc.sapServiceCallId} item xs={12}>
                    <EquipmentServiceCallEntry
                        serviceCall={sc}
                    />
                </Grid>
            ))}
        </Grid>
    );
};

export default EquipmentServiceCalls;