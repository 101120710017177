import {CDAMDMDeliveryNoteTemplateModel} from "crowbar-api";
import React, {useState} from "react";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {Link} from "react-router-dom";
import TemplateDialog from "modules/delivery-note/template/TemplateDialog";
import TemplateList from "modules/delivery-note/template/TemplateList";
import {useTranslation} from "react-i18next";
import {Box, Button, Container, IconButton, Link as Href, Stack, Typography} from "@mui/material";
import {ArrowCircleLeft} from "@mui/icons-material";
import {useDeliveryNoteTemplatesFindAll} from "crowbar-api/hooks/delivery-note/useDeliveryNoteTemplatesFindAll";
import {useDownloadSampleXML} from "crowbar-api/hooks/delivery-note/useDownloadSampleXML";

const Template = () => {
    const {t} = useTranslation();
    const tk = useTranslateFunction("DeliveryNote.List.");
    const TemplateQuery = useDeliveryNoteTemplatesFindAll()
    const sampleXML = useDownloadSampleXML()

    const [dialogKey, setDialogKey] = useState<number>(0);
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [dialogMode, setDialogMode] = useState<"new" | "edit">("new");
    const [editItem, setEditItem] = useState<CDAMDMDeliveryNoteTemplateModel | undefined>(undefined);

    const handleSampleXmlClick = async () => {
        await sampleXML.mutateAsync();
    }

    const handleNewTemplate = () => {
        setDialogKey(x => x + 1);
        setIsDialogOpen(true);
        setDialogMode("new");
        setEditItem(undefined)
    }

    const handleEditTemplate = (template: CDAMDMDeliveryNoteTemplateModel) => {
        setDialogKey(x => x + 1);
        setIsDialogOpen(true);
        setDialogMode("edit");
        setEditItem(template)
    }

    const handleDialogCancel = () => {
        setIsDialogOpen(false);
        setEditItem(undefined);
    }

    const handleDialogSubmit = async () => {
        await TemplateQuery.refetch();
        setIsDialogOpen(false);
        setEditItem(undefined);
    }

    return (

        <Container maxWidth={"lg"}>
            <Box sx={{display: "flex", alignItems: "center"}}>

                <Link to="/delivery-notes">
                    <IconButton color={"primary"}>
                        <ArrowCircleLeft fontSize={"large"}/>
                    </IconButton>
                </Link>

                <h1>{t("DeliveryNote.Title")}</h1>
            </Box>

            <Stack spacing={1}>
                <Typography variant={"subtitle1"}>
                    {t("DeliveryNote.TemplateDownloadTextPre")} {" "}
                    <Href
                        component={"button"}
                        variant={"subtitle1"}
                        underline={"hover"}
                        onClick={handleSampleXmlClick}
                    >
                        {t("DeliveryNote.TemplateDownloadTextLink")}
                    </Href>
                </Typography>


                <Box>
                    <Button onClick={handleNewTemplate}
                            variant={"contained"}
                    >
                        {tk("NewButtonText")}
                    </Button>
                </Box>

                <TemplateDialog
                    open={isDialogOpen}
                    mode={dialogMode}
                    editItem={editItem}
                    key={dialogKey}
                    onCancel={handleDialogCancel}
                    onSubmit={handleDialogSubmit}
                />

                <TemplateList onEdit={handleEditTemplate}/>
            </Stack>
        </Container>

    );
}

export default Template;