import {useQuery} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {CDAMWMWWorksheetVersion, WorksheetApi} from "crowbar-api";
import AxiosCancelTokenHelper from "crowbar-api/AxiosCancelTokenHelper";
import {AxiosRequestConfig} from "axios";

export const findWorksheetVersionsFor = async (worksheetIds: string[], options?: AxiosRequestConfig<any>): Promise<CDAMWMWWorksheetVersion[]> => {
    if (!worksheetIds || worksheetIds.length < 1) return []
    const response = await crowbarApiFactory(WorksheetApi)
        .findVersionsFor(worksheetIds, options)
    return response.data ?? []
}

export const useFindWorksheetVersionsFor = (worksheetIds: string[]) => {
    return useQuery(['worksheet-versions-for', worksheetIds], async (context) => {
        return await findWorksheetVersionsFor(worksheetIds, AxiosCancelTokenHelper.cancelTokenFrom(context.signal))
    }, {
        networkMode: 'online', // will not fire unless we have network
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        cacheTime: Math.pow(10, 7),
        staleTime: Math.pow(10, 7),
    })
}