export interface DateParts {
    year?: number
    month?: number
    day?: number
    hour?: number
    minute?: number
}

export class DateUtils {
    /*public static newUTCDate = () => {
        return DateUtils.localDateAsUTC(new Date())
    }*/

    public static newServerUTCDateAndAdd = (parts: DateParts) => {
        return DateUtils.dateToServerFormat(DateUtils.newDateAndAdd(parts))
    }

    public static newServerUTCDate = (): string => {
        return new Date().toJSON() // toJSON also converts it to UTC. Do not use it on UTC Dates
    }

    /* public static localDateAsUTC = (date: Date) => {
         return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
     }*/

    public static parseServerUTCToDate = (isoString: string | null | undefined): Date | undefined => {
        if (!isoString) return undefined
        const s = isoString.endsWith("Z") ? isoString : `${isoString}Z`
        return new Date(s)
    }

    public static parseServerUTCToNativeDateInput = (isoString: string | null | undefined): string | undefined => {
        const date = DateUtils.parseServerUTCToDate(isoString)
        return date ? DateUtils.intlDateToFormat(date, "fr-CA") : undefined
    }

    public static dateToServerFormat = (date?: Date | undefined): string | undefined => {
        if (!date) return undefined
        return date.toJSON()
    }

    public static utcTimestampNow = (): number => {
        return new Date().getTime()
    }

    public static utcTimestampStrNow = (): string => {
        return new Date().getTime().toString()
    }

    public static newDateAndAdd = ({year, month, day, hour, minute}: DateParts): Date => {
        let result = new Date()
        if (year) {
            result.setFullYear(result.getFullYear() + year)
        }
        if (month) {
            result.setMonth(result.getMonth() + month)
        }
        if (day) {
            result.setDate(result.getDate() + day)
        }
        if (hour) {
            result.setHours(result.getHours() + hour)
        }
        if (minute) {
            result.setMinutes(result.getMinutes() + minute)
        }
        return result
    }

    /**
     * "hu-HU" --> YYYY. MM. DD.
     * "fr-CA" --> YYYY-MM-DD
     * @param date
     * @param locales
     * @param options
     */
    public static intlDateToFormat = (date: Date, locales?: string | string[], options?: Intl.DateTimeFormatOptions): string => {
        return new Intl.DateTimeFormat(locales ?? "hu-HU", options ?? {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: undefined
        }).format(date)
    }
}