import React from 'react';
import {EmailNotificationProps} from "modules/admin/worksheet/notifications/email-notifications/EmailNotificationProps";
import {
    useEmailNotificationListingState
} from "modules/admin/worksheet/notifications/email-notifications/state/useEmailNotificationListingState";
import {Grid, Stack, Typography} from "@mui/material";
import useTranslateFunction from "shared/language/useTranslateFunction";
import NativeDateInput from "shared/components/inputs/NativeDateInput";
import {DateUtils} from "shared/utils/DateUtils";

export type EmailNotificationDateRangeFilterProps = EmailNotificationProps & {}

const EmailNotificationDateRangeFilter = ({stateAtom}: EmailNotificationDateRangeFilterProps) => {
    const tk = useTranslateFunction(`Admin.Worksheet`)

    const {worksheetStart, worksheetEnd, setState} = useEmailNotificationListingState(stateAtom)

    const startFormatted = DateUtils.parseServerUTCToNativeDateInput(worksheetStart)
    const endFormatted = DateUtils.parseServerUTCToNativeDateInput(worksheetEnd)

    const onChange = (field: "start" | "end", date: string) => {
        setState(prev => {
            return {
                ...prev,
                worksheetStart: field === "start" ? date : prev.worksheetStart,
                worksheetEnd: field === "end" ? date : prev.worksheetEnd,
                lastRowNumber: 0
            }
        })
    }

    return (
        <Stack direction="column" spacing={1}>
            <Typography variant="caption">{tk('DateFilter')}</Typography>

            <Grid container spacing={1}>
                <Grid item xs="auto">
                    <NativeDateInput
                        value={startFormatted ?? ""}
                        onChangeWithServerValue={(date) => onChange("start", date)}
                    />
                </Grid>

                <Grid item xs="auto">
                    <NativeDateInput
                        value={endFormatted ?? ""}
                        onChangeWithServerValue={(date) => onChange("end", date)}
                    />
                </Grid>
            </Grid>
        </Stack>
    );
};

export default EmailNotificationDateRangeFilter;