import React, {useMemo} from 'react';
import {
    CustomerNotificationConfigurationProps
} from "modules/admin/worksheet/notifications/customer-notification/CustomerNotificationConfigurationProps";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {
    useCustomerNotificationConfigurationState
} from "modules/admin/worksheet/notifications/customer-notification/state/useCustomerNotificationConfigurationState";
import {useMaterialReactTableLocalization} from "i18n/material-table/useMaterialReactTableLocalization";
import {MRT_ColumnDef} from "material-react-table";
import {CDAMNMCustomerNotificationConfigurationSearch} from "crowbar-api";
import {
    MaterialReactTableLastRowNumPaging
} from "shared/components/material-react-table/MaterialReactTableLastRowNumPaging";
import CustomerNotificationConfigurationDialogButton
    from "modules/admin/worksheet/notifications/customer-notification/results/CustomerNotificationConfigurationDialogButton";

export type CustomerNotificationConfigurationResultsTableProps = CustomerNotificationConfigurationProps & {
    onStateChanged: () => void
}
export const CustomerNotificationConfigurationResultsTable = ({
                                                                  stateAtom,
                                                                  onStateChanged
                                                              }: CustomerNotificationConfigurationResultsTableProps) => {
    const tk = useTranslateFunction(`Admin.Worksheet`)
    const {configurationQuery, setState, lastRowNumber, limit} = useCustomerNotificationConfigurationState(stateAtom)

    const mrt_locale = useMaterialReactTableLocalization()

    const entries = configurationQuery.data ?? []

    const columns = useMemo<MRT_ColumnDef<CDAMNMCustomerNotificationConfigurationSearch>[]>(() => [
        {
            accessorKey: "cardCode",
            header: tk('Customer')
        },
        {
            accessorKey: "cardName",
            header: tk('Name')
        },
        {
            accessorKey: "emailAddresses",
            header: "E-mail"
        },
        {
            accessorFn: row => (row.emailNotificationsEnabled ? tk("Yes") : tk("No")),
            header: tk('EmailNotificationsEnabled')
        },
        {
            accessorFn: row => (row.notifyOnOwnPremiseWorksheets ? tk("Yes") : tk("No")),
            header: tk('NotifyOnOwnPremiseWorksheets')
        },
        {
            accessorFn: row => (
                <CustomerNotificationConfigurationDialogButton
                    mode="edit"
                    editConfiguration={row}
                    onConfigurationChange={onStateChanged}
                />
            ),
            header: tk('Edit')
        }
    ], [onStateChanged, tk])

    const onLastRowNumPaginationChange = (newLastRowNumber: number, newLimit: number) => {
        setState(prev => {
            return {
                ...prev,
                lastRowNumber: newLastRowNumber,
                limit: newLimit
            }
        })
    }

    return (
        <MaterialReactTableLastRowNumPaging
            lastRowNumber={lastRowNumber ?? 0}
            limit={limit ?? 10}
            data={entries}
            onLastRowNumPaginationChange={onLastRowNumPaginationChange}

            columns={columns}
            localization={mrt_locale}
            initialState={{
                density: 'compact',
            }}
            rowNumberMode="original" //default
            enableGlobalFilter={false} //disable search feature
            enableColumnResizing={true}
            enableColumnFilters={false}
            enableColumnActions={false}
            enableSorting={false}
            enableFullScreenToggle={false}
            positionToolbarAlertBanner="bottom"
        />
    )
}