import React from 'react';
import {CDAMWMServiceCallEvalModel, CDAMWMServiceCallModel, CDSMSapEmployee} from "crowbar-api";
import useTranslateFunction from "shared/language/useTranslateFunction";
import useSapEmployees from "crowbar-api/hooks/sap/useSapEmployees";
import {
    useServiceCallEvalUpdateOwnerCode
} from "crowbar-api/hooks/worksheet/evaluation/service-call/useServiceCallEvalUpdateOwnerCode";
import {LinearProgress, MenuItem, Select, SelectChangeEvent, Stack, Typography} from "@mui/material";
import {byPropertiesOf} from "shared/utils/ArraySort";

export type OwnerSelectProps = {
    sc: CDAMWMServiceCallModel
    scEval: CDAMWMServiceCallEvalModel
    disabled?: boolean
}
const OwnerSelect = ({
                         sc,
                         scEval,
                         disabled
                     }: OwnerSelectProps) => {
    const tk = useTranslateFunction("Admin.Worksheet")

    const ownerCode = scEval.ownerCode;

    const sapEmployeesQuery = useSapEmployees()
    const sapEmployees = (sapEmployeesQuery.data ?? [])
        .filter(e => e.active)
        .sort(byPropertiesOf<CDSMSapEmployee>(['lastName', 'firstName']))
    const updateOwnerCode = useServiceCallEvalUpdateOwnerCode()

    const onChange = async (event: SelectChangeEvent) => {
        const rawValue = +event.target.value
        const newValue = isNaN(rawValue) ? undefined : rawValue

        await updateOwnerCode.mutateAsync({
            evalId: scEval.id,
            originalId: scEval.serviceCallId,
            field: newValue
        })

        scEval.ownerCode = newValue
    }

    return (
        <Stack direction="column" spacing={0.5}>
            <Typography>{tk('SapOwnerCode')} *</Typography>

            {sapEmployeesQuery.isLoading ? (
                <LinearProgress/>
            ) : (
                <Select
                    size="small"
                    disabled={disabled || updateOwnerCode.isLoading}
                    fullWidth
                    required
                    value={ownerCode?.toString() ?? ""}
                    onChange={onChange}
                    error={!ownerCode}
                >
                    {sapEmployees.map(e => (
                        <MenuItem key={e.empId?.toString()} value={e.empId?.toString()}>
                            {((e.lastName ?? "") + " " + (e.middleName ?? "")).trim()} {e.firstName ?? ""}
                        </MenuItem>
                    ))}
                </Select>
            )}
        </Stack>
    )
};

export default OwnerSelect;