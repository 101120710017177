import {UseNewWorksheetFromProps} from "modules/worksheet/editor/state/events/UseNewWorksheetFromProps";
import {useUserContext} from "shared/hook/UserContext";
import {useSaveNewWorksheetViaApi} from "modules/worksheet/editor/state/events/useSaveNewWorksheetViaApi";
import {useMutation} from "@tanstack/react-query";
import {CDAMWMWWorksheetSave, SapPartnerApi} from "crowbar-api";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {WorksheetSaveFactory} from "modules/worksheet/storage/WorksheetSaveFactory";

export interface UseNewWorksheetFromPartnerActionProps extends UseNewWorksheetFromProps {
    cardCode: string
}

export const useNewWorksheetFromPartnerAction = () => {
    const [userId] = useUserContext()
    const saveNewWorksheet = useSaveNewWorksheetViaApi()

    return useMutation(async (
        {cardCode, reloadWorksheetObjectAfterSave}: UseNewWorksheetFromPartnerActionProps
    ): Promise<CDAMWMWWorksheetSave> => {
        const partnerResponse = await crowbarApiFactory(SapPartnerApi)
            .findByCardCodeForWorksheet(cardCode)
        if (partnerResponse.status !== 200) {
            throw new Error(
                'The partner query required for creating ' +
                'a new worksheet from a partner did not return OK state. ' +
                'Status: ' + partnerResponse.status
            )
        }
        const partner = partnerResponse.data
        if (!partner?.cardCode) {
            throw new Error('Partner not found')
        }

        // Create a new WorksheetSave from the partner
        if (!userId) {
            throw new Error('UserIdNullError')
        }

        // New model created on client side
        const newWorksheet: CDAMWMWWorksheetSave = WorksheetSaveFactory
            .newWorksheetSaveFromPartner(partner, userId)
        return await saveNewWorksheet.mutateAsync({newWorksheet, reloadWorksheetObjectAfterSave})
    })
}