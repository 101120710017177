import React from 'react';
import useWorksheetTimeItemCodes from "crowbar-api/hooks/worksheet/worksheet-time-item-codes/useWorksheetTimeItemCodes";
import {CircularProgress, Container, Stack, Typography} from "@mui/material";
import useTranslateFunction from "shared/language/useTranslateFunction";
import WorksheetTimeItemCodesList from "modules/admin/worksheet/worksheet-time-item-codes/WorksheetTimeItemCodesList";
import WorksheetTimeItemCodeAddField
    from "modules/admin/worksheet/worksheet-time-item-codes/WorksheetTimeItemCodeAddField";

const WorksheetTimeItemCodes = () => {

    const tk = useTranslateFunction('Worksheet.Generic.WorksheetTimeItemCodes')

    const codesQuery = useWorksheetTimeItemCodes()

    const onItemCodeAdded = async () => {
        await codesQuery.refetch()
    }

    const onItemCodeRemoved = async () => {
        await codesQuery.refetch()
    }

    return (
        <Container>
            <Stack direction="column" spacing={1}>
                <Typography
                    variant="h4"
                >
                    {tk('ListTitle')}
                </Typography>
                {codesQuery.isLoading ? (
                    <CircularProgress/>
                ) : (
                    <>
                        <WorksheetTimeItemCodeAddField
                            codes={codesQuery.data ?? []}
                            onItemCodeAdded={onItemCodeAdded}
                        />
                        <WorksheetTimeItemCodesList
                            codes={codesQuery.data ?? []}
                            onItemCodeRemoved={onItemCodeRemoved}
                        />
                    </>
                )}
            </Stack>
        </Container>
    )
}

export default WorksheetTimeItemCodes;