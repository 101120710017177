import {Card, CardActions, CardContent, Stack, SxProps, Theme, Typography} from '@mui/material';
import React from 'react';
import useTranslateFunction from "shared/language/useTranslateFunction";
import DistanceInput from "modules/worksheet/editor/ui/basic/trip/DistanceInput";
import {useWorksheetSaveState} from "modules/worksheet/editor/state/WorksheetSaveAtoms";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import Decimal from "decimal.js";
import {useNewAtomWithRef} from "shared/hook/useNewatomWithRef";
import WorksheetTripTypeMap from "crowbar-api/enum-map/WorksheetTripTypeMap";
import {grey} from "@mui/material/colors";
import {toastError} from "shared/toast/DefaultToasts";

import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import useIsTripMatchFn from "crowbar-api/hooks/worksheet/validations/useIsTripMatchFn";
import CheckIconButton from "shared/components/buttons/CheckIconButton";
import IconButtonAndDebounce from "shared/components/buttons/IconButtonAndDebounce";

export interface WEditorPageBasicTripProps {
    disabled?: boolean
}

const qtyButtonSx = (disabled?: boolean): SxProps<Theme> => {
    return {
        height: "48%",
        marginTop: "1%",
        marginBottom: "1%",
        padding: "2px",
        cursor: "pointer",
        color: theme => disabled ? theme.palette.text.disabled : theme.palette.text.primary,
        fontWeight: "bold",
        ":hover": disabled ? {} : {
            color: theme => theme.palette.text.secondary,
            transform: "scale(1.2)"
        }
    }
}

const WEditorPageBasicTrip = ({disabled: propDisabled}: WEditorPageBasicTripProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    const [qtyButtonPressCount, setQtyButtonPressCount] = useNewAtomWithRef<number>(0)

    const [ws, setWs] = useWorksheetSaveState()

    const isTrip = useIsTripMatchFn()

    /*const userIsPhoenix = ws?.cardCode === CrowbarConstants.PhoenixForkliftCardCode
    const worksheetAddressFormat = SapPartnerAddressUtils.addressFormat({
        city: ws?.addressCity,
        zipCode: ws?.addressZipCode,
        street: ws?.addressStreet,
        streetNo: ws?.addressStreetNo
    })
    const addressIsPhoenix = worksheetAddressFormat === CrowbarConstants.PhoenixForkliftAddressFormat*/

    const distanceKm = ws?.tripDistanceKm ?? 0
    // Change 2023-02-22: it is no longer required to provide distanceKm > 0 values
    // const distanceNotFilled = !userIsPhoenix && !addressIsPhoenix && distanceKm < 1
    const distanceSmallerThanZero = distanceKm < 0

    const disabled = propDisabled || !!ws?.distanceAcknowledged

    const salesOrders = (ws?.salesOrders ?? [])
    const salesOrderRows = salesOrders.length < 1 ? [] : (salesOrders[0].rows ?? [])
    const sumOfTripRows = salesOrderRows.reduce((sum, row) => {
        return isTrip(row) ? sum + (row.quantity ?? 0) : sum
    }, 0)

    const distanceChange = (newValue: number) => {
        setWs(prev => {
            return {
                ...prev,
                tripDistanceKm: newValue,
                tripType: WorksheetTripTypeMap.manuallySet
            }
        })
    }

    const onChangeButton = (addValue: number, disabled?: boolean) => {
        if (disabled) return
        setWs(prev => {
            const dPrev = new Decimal(prev?.tripDistanceKm ?? 0)
            const dAdd = new Decimal(addValue)
            const newValue = dPrev.add(dAdd).toNumber()

            return {
                ...prev,
                tripDistanceKm: newValue,
                tripType: WorksheetTripTypeMap.manuallySet
            }
        })
        setQtyButtonPressCount(prev => prev + 1)
    }

    const setSumOfTripRows = (value: number, disabled?: boolean) => {
        if (disabled) return
        setWs(prev => {
            return {
                ...prev,
                tripDistanceKm: value,
                tripType: WorksheetTripTypeMap.fromSalesOrder
            }
        })
        setQtyButtonPressCount(prev => prev + 1)
    }

    const checkClick = () => {
        if (propDisabled) {
            return;
        }

        // Only run the validation if the
        // action is check (not uncheck)
        // Change 2023-02-22: it is no longer required to provide distanceKm > 0 values
        // this validation just changed to check if the value is larger than -1
        if (!ws?.distanceAcknowledged && distanceSmallerThanZero) {
            toastError(tk('TripRequired'))
            return
        }

        setWs(prev => {
            return {
                ...prev,
                distanceAcknowledged: !ws?.distanceAcknowledged
            }
        })
    }

    return (
        <Card variant="outlined" sx={{height: "100%"}}>

            <Stack direction="column" justifyContent="space-between" sx={{
                height: 1
            }}>
                <CardContent sx={{
                    px: 2,
                    paddingTop: 2,
                    paddingBottom: 0
                }}>
                    <Stack direction="column" spacing={2}>
                        <Typography variant="h6" fontWeight="bold">
                            {tk('BasicTripTitle')}
                        </Typography>


                        <Stack direction="row" justifyContent="space-between" alignItems="center">

                            <Stack direction="column" spacing={1}>
                                {sumOfTripRows
                                    ? (
                                        <Stack direction="row" spacing={0.75} alignItems="center">
                                            <Typography fontSize="14pt">
                                                {tk('OnSalesOrder')}: <b>{sumOfTripRows}</b>
                                            </Typography>
                                            <ArrowCircleRightIcon
                                                onClick={() => setSumOfTripRows(sumOfTripRows, disabled)}
                                                sx={{
                                                    fontSize: "30pt",
                                                    "color": disabled ? grey[400] : "inherit",
                                                    ":hover": disabled ? {} : {
                                                        cursor: "pointer",
                                                        transform: "scale(1.2)"
                                                    }
                                                }}
                                            />
                                        </Stack>
                                    )
                                    : <></>
                                }
                            </Stack>

                            <Stack direction="row" spacing={0.5}>
                                <DistanceInput
                                    key={[ws?.id ?? "-", qtyButtonPressCount].join("-")}
                                    value={distanceKm ?? 0}
                                    onChange={distanceChange}
                                    disabled={disabled}
                                    required
                                    error={distanceSmallerThanZero}
                                />
                                <Stack direction="column">
                                    <IconButtonAndDebounce
                                        sx={qtyButtonSx(disabled)}
                                        onClick={() => onChangeButton(1, disabled)}
                                    >
                                        <ArrowCircleUpIcon
                                            sx={{
                                                fontSize: "30pt"
                                            }}
                                        />
                                    </IconButtonAndDebounce>
                                    <IconButtonAndDebounce
                                        sx={qtyButtonSx(disabled)}
                                        onClick={() => onChangeButton(-1, disabled || distanceKm < 1)}
                                    >
                                        <ArrowCircleDownIcon
                                            sx={{
                                                fontSize: "30pt"
                                            }}
                                        />
                                    </IconButtonAndDebounce>
                                </Stack>
                            </Stack>

                        </Stack>
                    </Stack>
                </CardContent>

                <CardActions sx={{
                    px: 2,
                    display: "flex",
                    justifyContent: "right",
                }}>
                    <CheckIconButton
                        checked={!!ws?.distanceAcknowledged}
                        onClick={checkClick}
                        sx={{}}
                        checkCircleIconProps={{
                            sx: {
                                fontSize: "30pt",
                            }
                        }}
                    />
                </CardActions>
            </Stack>
        </Card>
    )
}

export default WEditorPageBasicTrip;