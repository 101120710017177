import React from 'react';
import {createTheme as muiCreateTheme, Theme as MuiTheme} from '@mui/material/styles';
import msalAuth from "shared/msal/MsalAuth";
import 'react-toastify/dist/ReactToastify.css';
import {atom} from "jotai";
import {ITeamsContext} from "shared/teams/TeamsContext";
import {CrowbarTeamsApp} from "system/CrowbarTeamsApp";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';


import './App.scss';
import "./shared/theme/default.css";
import "i18n/i18n";

// Initialize global msal context
msalAuth.initialize();

export const appIsInitializedAtom = atom<boolean>(false)
export const appAuthenticationErrorAtom = atom<boolean>(false)
export const appAuthenticationErrorReasonAtom = atom<string | undefined>(undefined)
export const appCurrentThemeAtom = atom<string>('ct-default-theme')
export const appCurrentMuiThemeAtom = atom<MuiTheme>(muiCreateTheme({
    // https://m2.material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=f9af18&secondary.color=243749
    palette: {
        primary: {
            light: '#f1a21a',
            main: '#F17E1AFF',// '#f17e1a',
            dark: '#f1691a',
            contrastText: '#fff',
        },
        secondary: {
            light: '#4e6175',
            main: '#243749',
            dark: '#001121',
            contrastText: '#000',
        },
    },
    shape: {
        borderRadius: 6
    },
    components: {
        MuiLink: {
            styleOverrides: {
                root: {
                    textDecoration: 'none',
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: {
                html: {
                    //scrollbarWidth for Firefox?
                    scrollbarWidth: "thin"
                }
            }
        }
    },
}))

export const msalTeamsAuthenticationHappenedAtom = atom<boolean>(false)
export const msalTeamsInitializationHappenedAtom = atom<boolean | undefined>(undefined)
export const msalTeamsInitializationRejectedAtom = atom<boolean | undefined>(undefined)
export const msalTeamsContextAtom = atom<ITeamsContext | undefined>(undefined)
export const msalUserClaimsAtom = atom<string[]>([])

const App = () => {
    return (
        <CrowbarTeamsApp/>
    )
}

export default App;