import React from 'react';
import {CDAMWMWWorksheetQuery} from "crowbar-api";
import {
    useServiceCallItemRowFindTripItemRowsByWorksheetId
} from "crowbar-api/hooks/worksheet/service-call-item-rows/useServiceCallItemRowFindTripItemRowsByWorksheetId";
import {Stack, Typography} from "@mui/material";
import useTranslateFunction from "shared/language/useTranslateFunction";
import WarningIcon from '@mui/icons-material/Warning';

export type TripDistanceWarnProps = {
    worksheet: CDAMWMWWorksheetQuery | null | undefined
    disabled?: boolean
}

const TripDistanceWarn = ({
                              worksheet,
                              disabled
                          }: TripDistanceWarnProps) => {
    const tk = useTranslateFunction("Admin.Worksheet")
    const tripRowsQuery = useServiceCallItemRowFindTripItemRowsByWorksheetId(worksheet?.id)
    const tripRows = tripRowsQuery.data ?? []

    const tripSumOnRows = tripRows.reduce((sum, ir) => sum + (ir.installedQuantity ?? 0), 0)
    const tripSumOnWorksheet = worksheet?.tripDistanceKm

    if (tripSumOnWorksheet && tripSumOnRows < tripSumOnWorksheet) {
        return (
            <Stack direction="row" spacing={1} alignItems="center">
                <WarningIcon color="error"/>

                <Typography variant="caption" fontWeight="bold" color="red" fontSize="larger">
                    {tk('TripDistanceWarn', {
                        tripSumOnRows,
                        tripSumOnWorksheet
                    })}
                </Typography>
            </Stack>
        )
    } else {
        return (
            <></>
        )
    }
}

export default TripDistanceWarn;