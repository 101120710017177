// eslint-disable-next-line @typescript-eslint/no-unused-vars
// noinspection ES6UnusedImports
import localForage from "localforage"; // required import

export abstract class BaseLocalStorage<T extends object> {
    private instance: LocalForage | undefined

    public abstract createNewInstance(): LocalForage

    public abstract getValueKey(value: T): string

    /*public createNewAtomsFor(
        initialValues: T[] = []
    ): IAtomLocalStorageFactoryResult<T> {
        return atomLocalStorageFactory(initialValues, this)
    }*/

    public defaultLocalForageOptions(storeName: string, description?: string): LocalForageOptions {
        return {
            name: 'CrowbarTeams',
            version: 1.0,
            // driverOrder: [localForage.INDEXEDDB, localForage.LOCALSTORAGE],
            storeName: storeName,
            description: description ?? ""
        }
    }

    public async getInstance(): Promise<LocalForage> {
        if (this.instance === undefined) {
            this.instance = this.createNewInstance()
            await this.instance.ready()
            console.log(
                `New LocalForage instance created with driver: ${this.instance.driver()}.`
            )
            if (!this.instance) {
                throw new Error(
                    'Failed to create new LocalForage instance for storage.'
                )
            }
        }
        return this.instance
    }

    public async setItem(key: string, value: T): Promise<any> {
        return (await this.getInstance()).setItem<T>(key, value)
    }

    public async setItemFor(value: T): Promise<any> {
        const key = this.getValueKey(value);
        return await this.setItem(key, value)
    }

    public async setItemsFor(values: T[]): Promise<any> {
        for (const value of values) {
            await this.setItemFor(value)
        }
    }

    public async getItem(key: string): Promise<T | null> {
        return (await this.getInstance())
            .getItem<T>(key)
            .then((value) => (!value ? null : value))
    }

    public async keys(): Promise<string[]> {
        return (await this.getInstance()).keys()
    }

    public async getAll(): Promise<T[]> {
        const keys = await this.keys()
        const result: T[] = []
        for (const key of keys) {
            const o = await this.getItem(key)
            if (!o) continue
            result.push(o)
        }
        return result
    }

    public async setItems(values: T[]): Promise<any> {
        for (const value of values) {
            const key = this.getValueKey(value)
            await this.setItem(key, value)
        }
    }

    public async removeItemBy(key: string): Promise<any> {
        return (await this.getInstance()).removeItem(key)
    }

    public async removeItem(value: T): Promise<void> {
        const key = this.getValueKey(value)
        await this.removeItemBy(key)
    }

    public async length(): Promise<number> {
        return (await this.getInstance()).length()
    }

    public async clear() {
        await (await this.getInstance()).clear()
    }
}
