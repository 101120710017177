import {useQuery} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {SapCallTypeApi} from "crowbar-api";
import AxiosCancelTokenHelper from "crowbar-api/AxiosCancelTokenHelper";

export const useSapCallTypes = () => {
    return useQuery(['sap-call-types-1'], async ({signal}) => {
        const response = await crowbarApiFactory(SapCallTypeApi)
            .findAll(AxiosCancelTokenHelper.cancelTokenFrom(signal))
        return response.data ?? []
    }, {
        cacheTime: Math.pow(10, 7),
        staleTime: Math.pow(10, 7),
        retry: 1,
        refetchOnMount: true,
        refetchOnWindowFocus: false
    })
}