import MaterialReactTable, {MaterialReactTableProps} from "material-react-table";
import {PaginationState} from "@tanstack/table-core";

export type MaterialReactTableLastRowNumPagingProps<TData extends Record<string, any> = {}> =
    MaterialReactTableProps<TData>
    & {
    lastRowNumber: number
    limit: number

    data: TData

    onLastRowNumPaginationChange: (newLastRowNumber: number, newLimit: number) => void
}

export const MaterialReactTableLastRowNumPaging = <TData extends Record<string, any> = {}>(
    {
        lastRowNumber,
        limit,
        data,
        onLastRowNumPaginationChange,
        ...params
    }: MaterialReactTableLastRowNumPagingProps<TData>
) => {

    const currentPagination = (): PaginationState => {
        return {
            pageIndex: Math.floor(lastRowNumber / limit),
            pageSize: limit,
        }
    }

    const onPaginationChange = (page: PaginationState | ((old: PaginationState) => PaginationState)) => {
        let newPageIndex: number;
        let newPageSize: number;
        if (typeof page === "function") {
            const tmp = page(currentPagination())
            newPageIndex = tmp.pageIndex
            newPageSize = tmp.pageSize
        } else {
            newPageIndex = page.pageIndex
            newPageSize = page.pageSize
        }

        onLastRowNumPaginationChange(newPageIndex * newPageSize, newPageSize)
    }

    return (
        <MaterialReactTable
            data={data}
            enablePagination={true}
            manualPagination={true}
            onPaginationChange={onPaginationChange}
            state={{
                pagination: {
                    pageIndex: Math.floor((lastRowNumber ?? 0) / (limit ?? 10)),
                    pageSize: limit
                },
                ...params.state
            }}
            rowCount={data?.find(() => true)?.totalCount ?? 100}

            {...params}
        />
    )
}