import React from 'react';
import {CatalogueTableRow} from "modules/admin/worksheet/evaluation/tabs/service-calls/catalogue/table/CatalogueTable";

export type DescriptionCellProps = {
    row: CatalogueTableRow
}

const DescriptionCell = ({row}: DescriptionCellProps) => {
    return (
        <span>
            {row.row?.itemName ?? "-"}
        </span>
    )
}

export default DescriptionCell;