import React from 'react';
import {Box, Stack, Typography} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import useTranslateFunction from "shared/language/useTranslateFunction";
import NewServiceCallDialog from "modules/worksheet/editor/shared/dialog/new-service-call-dialog/NewServiceCallDialog";
import {useWorksheetEditorHeaderInfoAtom} from "modules/worksheet/editor/state/WorksheetEditorHeaderAtoms";
import {
    NewServiceCallDialogResult
} from "modules/worksheet/editor/shared/dialog/new-service-call-dialog/NewServiceCallDialogResult";
import {WorksheetSaveFactory} from "modules/worksheet/storage/WorksheetSaveFactory";
import {toastWarn} from "shared/toast/DefaultToasts";
import {CDAMWMWModSapEquipmentSave} from "crowbar-api";
import ModSapTypeMap from "crowbar-api/enum-map/ModSapTypeMap";
import {useWorksheetSaveState} from "modules/worksheet/editor/state/WorksheetSaveAtoms";
import useIsTripMatchFn from "crowbar-api/hooks/worksheet/validations/useIsTripMatchFn";

const WEditorAddNewServiceCallDialogButton = () => {
    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    const [info, setInfo] = useWorksheetEditorHeaderInfoAtom()
    const [worksheetSave, setWorksheetSave] = useWorksheetSaveState()

    const isTrip = useIsTripMatchFn()

    const onNewServiceCallClick = () => {
        setInfo(prev => {
            return {
                ...prev,
                newServiceCallDialogOpen: true
            }
        })
    }

    const onNewServiceCallDismiss = () => {
        setInfo(prev => {
            return {
                ...prev,
                newServiceCallDialogOpen: false
            }
        })
    }
    const onNewServiceCallSubmit = async (dResult: NewServiceCallDialogResult): Promise<boolean> => {
        if (!worksheetSave) return false

        if (dResult.mode === "equipment" && dResult.selectedEquipment) {

            const newServiceCall = WorksheetSaveFactory.newServiceCallSaveFromPartnerEquipment(worksheetSave, dResult.selectedEquipment, true, isTrip)
            // Check if vehicle is already added
            const matchingServiceCall = worksheetSave?.serviceCalls?.find(sc => sc.sapEquipmentId === newServiceCall.sapEquipmentId)
            if (matchingServiceCall !== undefined) {
                toastWarn(tk('EquipmentAlreadyAdded'))
                return false
            } else {
                setInfo(prev => {
                    return {
                        ...prev,
                        newServiceCallDialogOpen: false
                    }
                })
                setWorksheetSave(p => {
                    const prevServiceCalls = p?.serviceCalls ?? []

                    return {
                        ...p,
                        serviceCalls: [
                            ...prevServiceCalls,
                            newServiceCall
                        ]
                    }
                })
            }

        } else if (dResult.mode === "new-equipment" && dResult.newPartnerFormValues) {
            const newModEquipment: CDAMWMWModSapEquipmentSave = {
                ...WorksheetSaveFactory.newModSapEquipmentSave(ModSapTypeMap.create),
                manufacturer: dResult.newPartnerFormValues.manufacturer ?? undefined,
                vehicleType: dResult.newPartnerFormValues.vehicleType ?? undefined,
                manufacturerSn: dResult.newPartnerFormValues.manufacturerSn ?? undefined,
                internalSn: dResult.newPartnerFormValues.internalSn ?? undefined,
                yearOfManufacture: dResult.newPartnerFormValues.yoM ?? undefined
            }
            const newServiceCall = WorksheetSaveFactory.newServiceCallSaveFromModEquipment(worksheetSave, newModEquipment, true, isTrip)

            setInfo(prev => {
                return {
                    ...prev,
                    newServiceCallDialogOpen: false
                }
            })
            setWorksheetSave(p => {
                return {
                    ...p,
                    modSapEquipments: [
                        ...p?.modSapEquipments ?? [],
                        newModEquipment
                    ],
                    serviceCalls: [
                        ...p?.serviceCalls ?? [],
                        newServiceCall
                    ]
                }
            })
        } else if (dResult.mode === 'empty' && dResult.emptySubject) {
            WorksheetSaveFactory.newServiceCallSave(worksheetSave.id)

            const newServiceCall = WorksheetSaveFactory.newServiceCallSaveFromEmptySubject(worksheetSave, dResult.emptySubject, true, isTrip)

            setInfo(prev => {
                return {
                    ...prev,
                    newServiceCallDialogOpen: false
                }
            })
            setWorksheetSave(p => {
                return {
                    ...p,
                    serviceCalls: [
                        ...p?.serviceCalls ?? [],
                        newServiceCall
                    ]
                }
            })
        } else {
            setInfo(prev => {
                return {
                    ...prev,
                    newServiceCallDialogOpen: false
                }
            })
        }

        return true
    }

    return (
        <>
            <Box
                onClick={onNewServiceCallClick}
                sx={{
                    cursor: "pointer",
                    ":hover": {
                        transform: "scale(1.2)"
                    }
                }}>
                <Stack direction="column"
                       spacing={0.25}
                       justifyContent="center"
                       alignItems="center"
                >
                    <Box
                        title={tk('NewServiceCallPlusTitle')}
                    >
                        <AddCircleOutlineIcon sx={{
                            width: 70,
                            height: 70
                        }}/>
                    </Box>

                    <Typography>{tk('NewServiceCall')}</Typography>

                </Stack>
            </Box>

            <NewServiceCallDialog
                open={info.newServiceCallDialogOpen}
                onSubmit={onNewServiceCallSubmit}
                onDismiss={() => onNewServiceCallDismiss()}
                cardCode={worksheetSave?.cardCode}
            />
        </>
    )
}

export default WEditorAddNewServiceCallDialogButton;