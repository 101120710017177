import React from 'react';
import {CDAMWMServiceCallEvalModel, CDAMWMServiceCallItemRowModel} from "crowbar-api";


export type TripDistanceOnRowsProps = {
    row: CDAMWMServiceCallEvalModel

    tripItemRows: CDAMWMServiceCallItemRowModel[]
}
const TripDistanceOnRows = ({
                                row,
                                tripItemRows
                            }: TripDistanceOnRowsProps) => {

    // const tripItemCodesQuery = useWorksheetTripItemCodes()
    /*const tripItemCodes = (tripItemCodesQuery.data ?? [])
        .map(o => o.itemCode)*/

    /* const rowsQuery = useServiceCallItemRowsFindByServiceCallId(row.serviceCallId, {
         keepPreviousData: true
     })*/
    // const itemRows = rowsQuery.data ?? []

    //const tripItemRows = itemRows.filter(ir => ir.itemCode && tripItemCodes.includes(ir.itemCode))
    const distanceOnRows = tripItemRows
        .filter(ir => ir.serviceCallId === row.serviceCallId)
        .reduce((sum, ir) => sum + (ir.installedQuantity ?? 0), 0)

    return (
        <>
            {distanceOnRows ?? 0} km
        </>
    );
};

export default TripDistanceOnRows;