import React, {useMemo} from 'react';
import {DateUtils} from "shared/utils/DateUtils";
import {Typography, TypographyProps} from "@mui/material";
import {StringUtils} from "shared/utils/StringUtils";

export type DateDisplayProps = Omit<TypographyProps, "align" | "ref"> & {
    dateStr: string
    format?: string
    locale?: string
    options?: Intl.DateTimeFormatOptions

    trimDotFromEnd?: boolean
}

export const dateDisplayDefaultOptions: Intl.DateTimeFormatOptions = {
    hour12: false,
    year: "numeric",
    month: "numeric",
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: undefined
};

const defaultFormatter = new Intl.DateTimeFormat(['hu-HU'], dateDisplayDefaultOptions);

/**
 * Converts C# UTC DateTime (toString(), ISO 8601) to the specified format
 * in local time using Intl.
 * Allows updating of the dateStr or format props.
 * @constructor
 */
const DateDisplay = ({dateStr, format, locale, options, trimDotFromEnd, ...props}: DateDisplayProps) => {
    const localTime = useMemo(() => {
        const date = DateUtils.parseServerUTCToDate(dateStr)
        return !options
            ? defaultFormatter.format(date)
            : new Intl.DateTimeFormat(locale ?? ['hu-HU'], options).format(date);
    }, [dateStr, locale, options])

    const localTimeCleaned = trimDotFromEnd
        ? StringUtils.trimEndWhile(localTime, c => c === ".")
        : localTime

    return (
        <Typography
            component="span"
            {...props}
        >
            {dateStr ? localTimeCleaned : ""}
        </Typography>
    );
}

export default DateDisplay;