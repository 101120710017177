import React from 'react';
import {Field, FieldProps} from "formik";
import {SapSelectOption} from "crowbar-api/enum-map/SapMappingUtils";
import {FormControl, InputLabel, ListSubheader, MenuItem, Select} from '@mui/material';

export type FormikSelectProps = {
    fieldName: string
    label?: string
    options: SapSelectOption[]
    setter?: (fieldName: string, option?: string) => void
    required?: boolean
    disabled?: boolean
}

const FormikSelect = ({
                          fieldName,
                          label,
                          options,
                          setter,
                          required,
                          disabled = false,
                      }: FormikSelectProps) => {
    return (
        <Field name={fieldName}>
            {(fieldProps: FieldProps) => (
                <FormControl fullWidth>
                    <InputLabel variant="outlined" size="small" shrink={true}>{label} {required && "*"}</InputLabel>
                    <Select
                        variant="outlined"
                        label={label}
                        notched={true}
                        size="small"
                        onChange={(e) => {
                            setter?.(fieldName, e.target.value);
                        }}
                        value={fieldProps.field.value}
                        required={required}
                        disabled={disabled || options?.length === 0}
                        error={!!fieldProps.meta.error}
                    >
                        {options.map((option) => (
                            option.isHeader ? (
                                <ListSubheader
                                    key={option.key}
                                    value={option.key}
                                >
                                    {option.text}
                                </ListSubheader>
                            ) : (
                                <MenuItem
                                    key={option.key}
                                    value={option.key}
                                >
                                    {option.text}
                                </MenuItem>
                            )
                        ))}
                    </Select>
                </FormControl>
            )}
        </Field>
    );
};

export default FormikSelect;