import React from 'react';
import {withTranslation, WithTranslation} from "react-i18next";
import {FieldArray, FieldArrayRenderProps, FormikProps} from "formik";
import {IDeliveryNoteDialogFields} from "./IDeliveryNoteDialogFields";
import {v4 as uuid} from 'uuid'
import DeliveryNoteNewRowDialog, {NewDeliveryNoteParams} from "./delivery-note-new-row-dialog/DeliveryNoteNewRowDialog";
import {CDAMDMDeliveryNoteModel} from "crowbar-api";
import {Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import FormikTextField from 'shared/formik/FormikTextField';

interface DeliveryNoteDialogRowsFormProps extends WithTranslation {
    formikProps: FormikProps<IDeliveryNoteDialogFields>
    mode: 'new' | 'edit'
    editItem?: CDAMDMDeliveryNoteModel
    customerCardCode?: string
}

interface DeliveryNoteDialogRowsFormState {
    formikProps: FormikProps<IDeliveryNoteDialogFields>
    customerCardCode?: string
}

const TKey = "DeliveryNote.Note.Dialog.";

class DeliveryNoteDialogRowsForm extends React.Component<DeliveryNoteDialogRowsFormProps, DeliveryNoteDialogRowsFormState> {

    constructor(props: Readonly<DeliveryNoteDialogRowsFormProps> | DeliveryNoteDialogRowsFormProps) {
        super(props);

        this.state = {
            formikProps: this.props.formikProps,
        }
    }


    componentDidUpdate(prevProps: Readonly<DeliveryNoteDialogRowsFormProps>, prevState: Readonly<DeliveryNoteDialogRowsFormState>, snapshot?: any) {
        if (this.state.formikProps !== this.props.formikProps) {
            this.setState({
                formikProps: this.props.formikProps
            })
        }
        if (this.state.customerCardCode !== this.props.customerCardCode) {
            this.setState({
                customerCardCode: this.props.customerCardCode
            })
        }
    }

    /*_onNewRow = (arrayProps: FieldArrayRenderProps) => {
        const rLen = this.state.formikProps.values.rows.length;
        const key = uuid();
        arrayProps.insert(rLen, {
            uuid: key,
            description: "This is a long test to try out line wrapping and word breaking for this table.",
            quantity: 5,
            unitOfQuantity: "kg",
            opHours: 234
        })
    }*/

    _onNewRowDialogCancelled = () => {
        // no op
    }

    _onNewRowDialogSelected = (arrayProps: FieldArrayRenderProps, selected: NewDeliveryNoteParams) => {
        if (!selected) return;

        const rLen = this.state.formikProps.values.rows.length;
        const key = uuid();

        if (selected.source === "empty") {
            arrayProps.insert(rLen, {
                uuid: key,
                source: 0,
                description: "",
                quantity: "",
                unitOfQuantity: "",
                opHours: ""
            })
        }

        if (selected.source === "equipment") {
            const m = selected.selectedEquipment?.manufacturerSn ? `(${selected.selectedEquipment.manufacturerSn})` : '';

            arrayProps.insert(rLen, {
                uuid: key,
                source: 1,
                description: `${selected.selectedEquipment?.vehicleType ?? ''} ${m}`,
                quantity: 1,
                unitOfQuantity: "db",
                opHours: ""
            })
        }

        if (selected.source === "item") {
            arrayProps.insert(rLen, {
                uuid: key,
                source: 2,
                description: `${selected.selectedItem?.itemCode} ${selected.selectedItem?.itemName}`,
                quantity: 1,
                unitOfQuantity: selected.selectedItem?.buyUnitMsr ? selected.selectedItem?.buyUnitMsr : "",
                opHours: ""
            })
        }
    }

    _renderFieldArray = (arrayProps: FieldArrayRenderProps): JSX.Element => {
        const t = this.props.t;
        const fProps = this.state.formikProps;
        return (<React.Fragment>
            <DeliveryNoteNewRowDialog onCancel={this._onNewRowDialogCancelled}
                                      customerCardCode={this.state.customerCardCode}
                                      onSelected={(selected: NewDeliveryNoteParams) => {
                                          this._onNewRowDialogSelected(arrayProps, selected)
                                      }}
            />

            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650, layout: "auto"}} size={"small"}>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t(`${TKey}Row`)}</TableCell>
                            <TableCell>{t(`${TKey}ItemName`)}</TableCell>
                            <TableCell className="ct-dn-required-star" sx={{px: 1}}>{t(`${TKey}Quantity`)}</TableCell>
                            <TableCell className="ct-dn-required-star"
                                       sx={{px: 1}}>{t(`${TKey}UnitOfQuantity`)}</TableCell>
                            <TableCell sx={{px: 1}}>{t(`${TKey}OperatingHours`)}</TableCell>
                            <TableCell>{t(`${TKey}RemoveTitle`)}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fProps.values.rows.map((row, index) => {
                            return (
                                <TableRow
                                    hover
                                    key={row.uuid}
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <TableCell variant={"head"}>{index + 1}</TableCell>
                                    <TableCell sx={{width: "60%", px: 1}}>
                                        <FormikTextField fieldName={`rows[${index}].description`} type={"text"}
                                                         setter={(newValue) => {
                                                             arrayProps.replace(index, {...row, description: newValue})
                                                         }}/>
                                    </TableCell>
                                    <TableCell sx={{width: '10%', px: 1}}>
                                        <FormikTextField fieldName={`rows[${index}].quantity`} type={"text"}
                                                         setter={(newValue) => {
                                                             arrayProps.replace(index, {...row, quantity: newValue})
                                                         }}/>
                                    </TableCell>
                                    <TableCell sx={{width: '10%', px: 1}}>
                                        <FormikTextField fieldName={`rows[${index}].unitOfQuantity`} type={"text"}
                                                         setter={(newValue) => {
                                                             arrayProps.replace(index, {
                                                                 ...row,
                                                                 unitOfQuantity: newValue
                                                             })
                                                         }}/>
                                    </TableCell>
                                    <TableCell sx={{width: '10%', px: 1}}>
                                        <FormikTextField fieldName={`rows[${index}].opHours`} type={"number"}
                                                         setter={(newValue) => {
                                                             arrayProps.replace(index, {...row, opHours: newValue})
                                                         }}/>
                                    </TableCell>
                                    <TableCell sx={{width: '10%', px: 1}}>
                                        <Button variant={"outlined"}
                                                color={"error"}
                                                onClick={() => {
                                                    arrayProps.remove(index)
                                                }}>
                                            {t(`${TKey}Remove`)}
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>)
    }

    render() {
        // const t = this.props.t;
        return (
            <React.Fragment>
                <Grid item xs={12}>
                    <FieldArray name="rows"
                                render={this._renderFieldArray}
                    />
                </Grid>
            </React.Fragment>
        );
    }
}

export default withTranslation()(DeliveryNoteDialogRowsForm);