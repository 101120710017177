import {CDAMWMWServiceCallItemRowSave} from "crowbar-api";
import {Box, InputAdornment, Stack, TextField, Typography} from "@mui/material";
import React from "react";
import useAvailableItemRowSum from "modules/worksheet/editor/state/helpers/useAvailableItemRowSum";
import WEditorPageCatalogueItemRowQtySyncBtn
    from "modules/worksheet/editor/ui/catalogue/WEditorPageCatalogueItemRowQtySyncBtn";
import {WEditorPageCatalogueItemRowUtils} from "modules/worksheet/editor/ui/catalogue/WEditorPageCatalogueItemRowUtils";
import useTranslateFunction from "shared/language/useTranslateFunction";

interface WEditorPageCatalogueItemRowAvailableQuantityProps {
    itemRow: CDAMWMWServiceCallItemRowSave
    disabled?: boolean
}

const WEditorPageCatalogueItemRowAvailableQuantity = ({

                                                          itemRow,
                                                          disabled
                                                      }: WEditorPageCatalogueItemRowAvailableQuantityProps) => {

    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    const irSum = useAvailableItemRowSum(itemRow)

    const srcQty = itemRow.sourceQuantity
        ? itemRow.sourceQuantity.toLocaleString(navigator.language, {maximumFractionDigits: 2})
        : undefined

    const srcQtyWidth = WEditorPageCatalogueItemRowUtils.smallQtyInputWidthFrom(srcQty, itemRow.unitOfQuantity)
    const availableWidth = WEditorPageCatalogueItemRowUtils.smallQtyInputWidthFrom(irSum.availableAsLocaleStr, itemRow.unitOfQuantity)

    const qtyWidth = Math.max(availableWidth, srcQtyWidth)
    const lengthPx = `${qtyWidth}px`

    return (
        <Box sx={{
            marginLeft: 1,
            paddingX: 2
        }}>
            <Stack direction="column" spacing={2}>
                <Stack direction="row" spacing={2} alignItems="center">
                    <TextField
                        label={tk('Available')}
                        disabled={true}
                        value={irSum.availableAsLocaleStr ?? ""}
                        InputLabelProps={{
                            shrink: true
                        }}
                        InputProps={{
                            sx: {
                                width: lengthPx,
                                fontSize: "16pt",
                            },
                            endAdornment: <InputAdornment position="end">
                                <Typography sx={{
                                    fontSize: "12pt"
                                }}>{itemRow.unitOfQuantity}</Typography>
                            </InputAdornment>
                        }}
                    />
                    <WEditorPageCatalogueItemRowQtySyncBtn itemRow={itemRow} disabled={disabled}/>
                </Stack>
                <Stack direction="row" spacing={2} alignItems="center">

                    <TextField
                        label={tk('InOrder')}
                        disabled={true}
                        value={srcQty ?? ""}
                        InputLabelProps={{
                            shrink: true
                        }}
                        InputProps={{
                            sx: {
                                width: lengthPx,
                                fontSize: "16pt",
                            },
                            endAdornment: <InputAdornment position="end">
                                <Typography sx={{
                                    fontSize: "12pt"
                                }}>{itemRow.unitOfQuantity}</Typography>
                            </InputAdornment>
                        }}
                    />

                </Stack>
            </Stack>
        </Box>

    )
}

export default WEditorPageCatalogueItemRowAvailableQuantity