import React, {useState} from 'react';
import {useSapEquipmentImagesFindBy} from "crowbar-api/hooks/equipment/useSapEquipmentImagesFindBy";
import {CircularProgress, Grid, Paper, Stack, Typography} from "@mui/material";
import EquipmentImagesLightbox from "modules/equipment/equipment-details/equipment-images/EquipmentImagesLightbox";
import EquipmentImage from "modules/equipment/equipment-details/equipment-images/EquipmentImage";
import {CDAMCMCrowbarBlobModel} from "crowbar-api";
import ImageNotFoundText from "modules/equipment/equipment-details/equipment-images/ImageNotFoundText";
import useTranslateFunction from "shared/language/useTranslateFunction";
import EquipmentSapCoverImage from "modules/equipment/equipment-details/equipment-images/EquipmentSapCoverImage";

export type EquipmentImagesProps = {
    sapEquipmentId?: number | undefined
    modSapEquipmentId?: string | undefined
    coverImage?: string | undefined | null
}
const EquipmentImages = ({sapEquipmentId, modSapEquipmentId, coverImage}: EquipmentImagesProps) => {
    const tk = useTranslateFunction(`Equipment`)

    const [lightboxOpen, setLightboxOpen] = useState(false)
    const [clickedImageId, setClickedImageId] = useState<string | undefined>(undefined)

    const imagesQuery = useSapEquipmentImagesFindBy(sapEquipmentId, modSapEquipmentId)

    const images = imagesQuery.data ?? []

    const onImageClick = (blob: CDAMCMCrowbarBlobModel) => {
        setClickedImageId(blob.id)
        setLightboxOpen(true)
    }

    const onLightboxClose = () => {
        setLightboxOpen(false)
    }

    if (imagesQuery.isLoading || imagesQuery.isFetching) {
        return (
            <CircularProgress/>
        )
    }

    return (
        <Stack direction="column" spacing={1}>

            <Stack direction="row" spacing={1} alignItems="center">

                <Typography variant="h4">
                    {tk('WorksheetEquipmentImages')}
                </Typography>
            </Stack>

            {images.length < 1 && (
                <ImageNotFoundText/>
            )}

            <Grid container spacing={1}>
                {images.map((image, i) => (
                    <Grid key={[image.id, i].join("-")} item xs={4} md={3}>
                        <Paper style={{height: '100%'}}>
                            <EquipmentImage
                                blob={image}
                                onClick={onImageClick}
                            />
                        </Paper>
                    </Grid>
                ))}
            </Grid>

            {coverImage && (
                <>
                    <Typography variant="h4">
                        {tk('SapCoverImage')}
                    </Typography>

                    <EquipmentSapCoverImage
                        coverImage={coverImage}
                    />
                </>
            )}

            <EquipmentImagesLightbox
                open={lightboxOpen}
                onClose={onLightboxClose}
                selectedImageId={clickedImageId}
                images={images}
            />
        </Stack>
    )
};

export default EquipmentImages;