import {useQuery} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {CDSMPSapPartnerSearchPagingParams, CDSMSapPartner, SapPartnerApi} from "crowbar-api";
import AxiosCancelTokenHelper from "crowbar-api/AxiosCancelTokenHelper";
import {UseQueryOptionParams} from "crowbar-api/util/UseQueryOptionParams";

export const sapPartnersPageBy = async (params: CDSMPSapPartnerSearchPagingParams, signal: AbortSignal | undefined): Promise<CDSMSapPartner[]> => {
    const response = await crowbarApiFactory(SapPartnerApi).searchPageBy(params, AxiosCancelTokenHelper.cancelTokenFrom(signal))

    return response.data ?? [];
}

export const useSapPartnersPageBy = (params: CDSMPSapPartnerSearchPagingParams,
                                     extraOptions?: UseQueryOptionParams) => {
    return useQuery(['sap-partners', params], async ({signal}) => {
        return await sapPartnersPageBy(params, signal);
    }, {
        networkMode: "always",
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        cacheTime: Math.pow(10, 7),
        staleTime: Math.pow(10, 7),
        retry: 3,
        ...extraOptions
    })
}