import {AxiosInstance, AxiosRequestConfig} from "axios";
import {BuildTimeLocalStorageKey} from "modules/generic/query-warmup/useBuildInfo";

export class BuildTimeNotification {
    private hasNotificationForBuildTime: boolean = false
    public hasNotification = (): boolean => {
        return this.hasNotificationForBuildTime
    }
    public setNotification = (b: boolean): void => {
        this.hasNotificationForBuildTime = b
    }
}

export const buildTimeNotification = new BuildTimeNotification()

const onRequest = (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    return new Promise<AxiosRequestConfig>((resolve, reject) => {
        // Read the current build info from localStorage
        const buildTimeObj = localStorage.getItem(BuildTimeLocalStorageKey)
        let buildTime: string | undefined = undefined
        try {
            if (!!buildTimeObj && buildTimeObj !== '"undefined') {
                buildTime = JSON.parse(buildTimeObj)
            }
        } catch {
            // ignore serialization errors
        }
        if (buildTime) {
            // Check the request header value
            const headers = config?.headers ?? {}
            const headerBuildTime = headers['crowbar-version']
            if (headerBuildTime && buildTime !== headerBuildTime) {
                buildTimeNotification.setNotification(true)
            }
        }

        resolve(config)
    })
}

export const setupVersionNotificationInterceptorsTo = (axiosInstance: AxiosInstance): AxiosInstance => {
    axiosInstance.interceptors.response.use(onRequest);
    return axiosInstance;
}