import React from 'react';
import {useProfileImageById} from "crowbar-api/hooks/user/useProfileImageById";
import {Avatar, CircularProgress, Grid, Stack, Typography} from "@mui/material";
import {useUserDetailsById} from "crowbar-api/hooks/user/useUserDetailsById";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {CDAMWMWorksheetEvalModel, CDAMWMWWorksheetQuery} from "crowbar-api";

export type WEvalHeaderTitleProps = {
    wEval: CDAMWMWorksheetEvalModel | null | undefined
    worksheet: CDAMWMWWorksheetQuery | null | undefined
}
const WEvalHeaderTitle = ({wEval, worksheet}: WEvalHeaderTitleProps) => {
    const tk = useTranslateFunction("Admin.Worksheet")

    const ownerId = worksheet?.ownerId

    const {data: userDetails} = useUserDetailsById(ownerId)
    const {isLoading: imageIsLoading, data: userImage, isError: imageError} = useProfileImageById(ownerId)

    return (
        <Grid container spacing={0} justifyContent="space-between" alignItems="center">

            <Grid item xs="auto">
                <Typography variant="h4">
                    {worksheet?.serialId}
                </Typography>
            </Grid>

            <Grid item xs="auto">
                <Stack direction="row" spacing={1} alignItems="center">
                    <Typography variant="subtitle2">
                        {userDetails?.fullName}
                    </Typography>
                    {imageIsLoading ? (
                        <CircularProgress/>
                    ) : (
                        <Avatar
                            alt="user"
                            src={imageError || !userImage ? undefined : `data:${userImage?.mime};base64,${userImage?.data}`}
                        />
                    )}
                </Stack>

            </Grid>
        </Grid>
    );
};

export default WEvalHeaderTitle;