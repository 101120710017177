import {
    MechanicListingStateProps,
    useMechanicListingState
} from "modules/worksheet/mechanic/state/useMechanicListingState";
import {CircularProgress, IconButton, IconButtonProps} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import React from "react";


export type WorksheetListRefreshButtonProps = MechanicListingStateProps & IconButtonProps & {}
export const WorksheetListRefreshButton = ({mechanicStateAtom, ...props}: WorksheetListRefreshButtonProps) => {

    const {worksheetInfQuery} = useMechanicListingState(mechanicStateAtom)

    const onReload = () => {
        worksheetInfQuery.refetch().then()
    }

    return (
        <IconButton onClick={onReload} {...props}>
            {worksheetInfQuery.isLoading || worksheetInfQuery.isFetching ? (
                <CircularProgress size="20px"/>
            ) : (
                <RefreshIcon/>
            )}
        </IconButton>
    )
}