import {CDAMWMWorksheetStatus} from "crowbar-api"

export default class WorksheetStatusMap {
    public static readonly draft = CDAMWMWorksheetStatus.NUMBER_0
    public static readonly final = CDAMWMWorksheetStatus.NUMBER_1
    public static readonly finalSigned = CDAMWMWorksheetStatus.NUMBER_2
    public static readonly finalSignSkipped = CDAMWMWorksheetStatus.NUMBER_3
    public static readonly evaluated = CDAMWMWorksheetStatus.NUMBER_4

    public static allStatusesAsMap = (): Map<number, CDAMWMWorksheetStatus> => {
        const result = new Map<number, CDAMWMWorksheetStatus>()
        result.set(0, WorksheetStatusMap.draft)
        result.set(1, WorksheetStatusMap.final)
        result.set(2, WorksheetStatusMap.finalSigned)
        result.set(3, WorksheetStatusMap.finalSignSkipped)
        result.set(4, WorksheetStatusMap.evaluated)
        return result
    }
    public static allStatuses = (): CDAMWMWorksheetStatus[] => {
        return [
            WorksheetStatusMap.draft,
            WorksheetStatusMap.final,
            WorksheetStatusMap.finalSigned,
            WorksheetStatusMap.finalSignSkipped,
            WorksheetStatusMap.evaluated
        ]
    }

    public static translationKey = (t?: CDAMWMWorksheetStatus): string => {

        const preKey = "Worksheet.Generic.WorksheetStatus."

        if (t === this.draft) {
            return `${preKey}Draft`
        }

        if (t === this.final) {
            return `${preKey}Final`
        }

        if (t === this.finalSigned) {
            return `${preKey}FinalSigned`
        }

        if (t === this.finalSignSkipped) {
            return `${preKey}FinalSignSkipped`
        }

        if (t === this.evaluated) {
            return `${preKey}Evaluated`
        }

        return "Missing"
    }
}