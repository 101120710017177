import React from 'react';
import {CDAMWMServiceCallEvalModel, CDAMWMServiceCallModel} from "crowbar-api";
import ServiceCallOperatingHoursTypeMap from "crowbar-api/enum-map/ServiceCallOperatingHoursTypeMap";
import {Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {
    useServiceCallEvalUpdateCurrentEquipmentOperatingHours
} from "crowbar-api/hooks/worksheet/evaluation/service-call/useServiceCallEvalUpdateCurrentEquipmentOperatingHours";
import NumberOnlyTextField from "shared/components/inputs/NumberOnlyTextField";

export type CurrentOperatingHoursProps = {
    sc: CDAMWMServiceCallModel
    scEval: CDAMWMServiceCallEvalModel
    disabled?: boolean
    onMasterDataChange: () => Promise<void>
}

const CurrentOperatingHours = ({sc, scEval, disabled, onMasterDataChange}: CurrentOperatingHoursProps) => {
    const {t} = useTranslation()
    const tk = useTranslateFunction("Admin.Worksheet")
    /*const isProvided = sc.operatingHoursType === ServiceCallOperatingHoursTypeMap.provided ||
        sc.selectedSource === ServiceCallSelectedSourceMap.empty*/

    const updateCurrentEquipmentOperatingHours = useServiceCallEvalUpdateCurrentEquipmentOperatingHours();

    const onBlur = async (e: React.FocusEvent<HTMLInputElement>) => {
        const newValueNum = +e.target.value
        if (isNaN(newValueNum)) return
        await updateCurrentEquipmentOperatingHours.mutateAsync({
            evalId: scEval.id,
            originalId: sc.id,
            operatingHoursType: ServiceCallOperatingHoursTypeMap.provided,
            currentEquipmentOperatingHours: newValueNum
        })
        //sc.operatingHoursType = ServiceCallOperatingHoursTypeMap.provided // instead of full refetch
        //sc.currentEquipmentOperatingHours = newValueNum // instead of full refetch

        await onMasterDataChange()
    }

    return (
        <Stack direction="column" spacing={0.5}>
            <Typography>
                {tk('CurrentOperatingHours')} ({t(ServiceCallOperatingHoursTypeMap.translationKey(sc.operatingHoursType))})
            </Typography>

            <NumberOnlyTextField
                size="small"
                fullWidth
                value={sc?.currentEquipmentOperatingHours ?? 0}
                onBlur={onBlur}
                disabled={disabled || updateCurrentEquipmentOperatingHours.isLoading}
                hideZeroOnClick={true}
            />
        </Stack>
    )
}

export default CurrentOperatingHours;