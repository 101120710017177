import React from 'react';
import {withTranslation, WithTranslation} from "react-i18next";
import {FormikProps} from "formik";
import {IDeliveryNoteDialogFields} from "./IDeliveryNoteDialogFields";
import {
    CDAMDMDeliveryNoteModel,
    CDSMSapPartner,
    CDSMSapPartnerAddress,
    CDSMSapPartnerContact,
    SapPartnerAddressApi,
    SapPartnerContactApi,
} from "crowbar-api"
import {Button, Grid} from "@mui/material"
import BusinessPartnerDialog from "./delivery-note-business-partner-dialog/BusinessPartnerDialog"
import axios, {AxiosRequestConfig, CancelTokenSource} from "axios"
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory"
import {toastRequestError} from "shared/toast/DefaultToasts"
import SapMappingUtils, {SapSelectOption} from 'crowbar-api/enum-map/SapMappingUtils'
import FormikTextField from "shared/formik/FormikTextField";
import FormikSelect from "shared/formik/FormikSelect";
import FormikAutoComplete from "shared/formik/FormikAutoComplete";


interface DeliveryNoteDialogCustomerFormProps extends WithTranslation {
    formikProps: FormikProps<IDeliveryNoteDialogFields>
    mode: 'new' | 'edit'
    editItem?: CDAMDMDeliveryNoteModel
    onCustomerCardCodeChanged: (cCardCode?: string) => void
}

interface DeliveryNoteDialogCustomerFormState {
    formikProps: FormikProps<IDeliveryNoteDialogFields>
    partnerSelectDialogOpen: boolean
    partnerSelectDialogSource: string
    partnerSelectDialogSetter: (selected?: CDSMSapPartner) => void

    customerAddresses: CDSMSapPartnerAddress[]
    addressOptionsLoading: boolean
    customerBillingAddressOptions: SapSelectOption[]
    customerShippingAddressOptions: SapSelectOption[]
    customerContacts: CDSMSapPartnerContact[]
    customerContactOptions: SapSelectOption[]
    customerPhoneOptions: SapSelectOption[]
    contactOptionsLoading: boolean
}

const TKey = "DeliveryNote.Note.Dialog.";

class DeliveryNoteDialogCustomerForm extends React.Component<DeliveryNoteDialogCustomerFormProps, DeliveryNoteDialogCustomerFormState> {
    private readonly _sapPartnerAddressApi: SapPartnerAddressApi;
    private readonly _sapPartnerContactApi: SapPartnerContactApi;

    private cancelTokenSource: CancelTokenSource;
    private axiosOptions: AxiosRequestConfig

    constructor(props: Readonly<DeliveryNoteDialogCustomerFormProps> | DeliveryNoteDialogCustomerFormProps) {
        super(props);

        this.cancelTokenSource = axios.CancelToken.source()
        this.axiosOptions = {
            cancelToken: this.cancelTokenSource.token
        }

        this._sapPartnerAddressApi = crowbarApiFactory(SapPartnerAddressApi);
        this._sapPartnerContactApi = crowbarApiFactory(SapPartnerContactApi);

        this.state = {
            formikProps: this.props.formikProps,
            partnerSelectDialogOpen: false,
            partnerSelectDialogSource: 'c',
            partnerSelectDialogSetter: () => {
            },
            customerAddresses: [],
            addressOptionsLoading: false,
            customerBillingAddressOptions: [],
            customerShippingAddressOptions: [],
            customerContacts: [],
            customerContactOptions: [],
            customerPhoneOptions: [],
            contactOptionsLoading: false,
        }
    }

    componentDidMount() {
        this.cancelTokenSource = axios.CancelToken.source()
        this.axiosOptions = {
            cancelToken: this.cancelTokenSource.token
        }

        this._patchEditValueForContactIfNeeded()
        this._patchContactPhoneIfNeeded()
        this._loadAddressAndContactTimeout(true)
    }

    componentWillUnmount() {
        this.cancelTokenSource.cancel()
    }

    componentDidUpdate(prevProps: Readonly<DeliveryNoteDialogCustomerFormProps>, prevState: Readonly<DeliveryNoteDialogCustomerFormState>, snapshot?: any) {
        if (this.state.formikProps !== this.props.formikProps) {
            this.setState({
                formikProps: this.props.formikProps
            })
        }
    }

    _loadAddressOptions = () => {
        const cardCode = this.state.formikProps?.values?.customerCardCode
        if (!cardCode) {
            this.setState({
                customerBillingAddressOptions: [],
                customerShippingAddressOptions: []
            })
            return
        }

        this.setState({
            addressOptionsLoading: true,
            customerBillingAddressOptions: [],
            customerShippingAddressOptions: []
        }, () => {
            this._sapPartnerAddressApi.findByCardCode(cardCode, this.axiosOptions).then(response => {
                if (response.status !== 200) {
                    toastRequestError(this.props.t, {
                        name: "DnCustomerFormReqAddress",
                        response: response,
                        reason: 'Http status not OK'
                    })
                    this.setState({addressOptionsLoading: false})
                    return
                }
                // OK
                const sapAddressUtils = new SapMappingUtils(this.props.t);
                const [billing, shipping] = sapAddressUtils.mapAddressesToDropdown(response.data, true);
                this.setState({
                    addressOptionsLoading: false,
                    customerAddresses: response.data,
                    customerBillingAddressOptions: billing,
                    customerShippingAddressOptions: shipping
                })
            })
        })
    }

    _loadContactOptions = (keepPreviousContacts: boolean = false) => {
        const cardCode = this.state.formikProps?.values?.customerCardCode
        if (!cardCode) {
            if (!keepPreviousContacts) this.setState({customerContactOptions: []})
            return
        }

        this.setState((prevState) => {
            // if we dont keep the previous options, set to []
            return {
                contactOptionsLoading: true,
                customerContacts: !keepPreviousContacts ? [] : [...prevState.customerContacts],
                customerContactOptions: !keepPreviousContacts ? [] : [...prevState.customerContactOptions],
            }
        }, () => {
            this._sapPartnerContactApi.findByCardCode(cardCode, this.axiosOptions).then(response => {
                if (response.status !== 200) {
                    this.setState({contactOptionsLoading: false})
                    return
                }
                // OK
                const c = new SapMappingUtils(this.props.t).mapContactsToDropdown(response.data);
                this.setState((prevState) => {
                    const filtered = c.filter(mapped => prevState.customerContactOptions.find(customerContact => customerContact.key === mapped.key) === undefined)
                    // keep previous options regardless (already cleared beforehand if needed)
                    return {
                        contactOptionsLoading: false,
                        customerContacts: [...prevState.customerContacts, ...response.data],
                        customerContactOptions: [...prevState.customerContactOptions, ...filtered]
                    }
                })
            })
        })
    }

    /**
     * Hack to wait for new value to be applied
     * Pending https://github.com/jaredpalmer/formik/issues/529
     */
    _loadAddressAndContactTimeout = (keepPreviousContacts: boolean = false) => {
        setTimeout(() => {
            this._loadAddressOptions()
            this._loadContactOptions(keepPreviousContacts)
        }, 50)
    }

    _patchEditValueForContactIfNeeded = () => {
        if (this.props.mode !== "edit") return
        const val = this.props.editItem?.customerContactName ?? ''
        this.setState((prevState) => {
            if (!(prevState.customerContactOptions.filter((o) => o.key === "edit"))) { //Temporary solution for React.StrictMode duplication
                return {
                    customerContactOptions: [{key: 'edit', text: val}, ...prevState.customerContactOptions]
                }
            } else {
                return null
            }
        }, () => {
            this.state.formikProps.setFieldValue('customerContactNameValue', val)
        })
    }

    _patchContactPhoneIfNeeded = () => {
        if (this.props.mode !== "edit") return
        const val = this.props.editItem?.customerContactPhone ?? ''
        this.setState((prevState) => {
            if (!(prevState.customerPhoneOptions.filter(o => o.key === "edit"))) { //Temporary solution for React.StrictMode duplication
                return {
                    customerPhoneOptions: [{key: 'edit', text: val}, ...prevState.customerPhoneOptions]
                }
            } else {
                return null
            }
        }, () => {
            this.state.formikProps.setFieldValue('customerContactPhoneValue', val)
        })
    }

    _openBusinessPartnerSelectDialogOpen = (setter: (selected?: CDSMSapPartner) => void) => {
        this.setState({
            partnerSelectDialogOpen: true,
            partnerSelectDialogSetter: setter
        })
    }

    _onBusinessPartnerDialogSelected = (sourceId: string, selected?: CDSMSapPartner) => {
        this.setState({
            partnerSelectDialogOpen: false,
        }, () => {
            // call the setter
            this.state?.partnerSelectDialogSetter(selected)
            // Hack to wait for new value to be applied
            this._loadAddressAndContactTimeout()
        })
    }

    _onBusinessPartnerDialogClosed = (sourceId: string) => {
        this.setState({
            partnerSelectDialogOpen: false
        })
    }

    _onAddressSelectPressed = (s: 'billing' | 'shipping') => {
        const m = new SapMappingUtils(this.props.t);
        const fProps = this.props.formikProps;
        const propVal = (s === "billing") ? fProps.values.customerBillingAddressDropdown : fProps.values.customerShippingAddressDropdown;
        const foundText = (s === "billing") ? this.state.customerBillingAddressOptions?.find(a => a.key === propVal) : this.state.customerShippingAddressOptions?.find(a => a.key === propVal);
        const foundKey = this.state.customerAddresses?.find(a => m.mapAddressToKey(a) === foundText?.key)
        const newValue = (!foundKey) ? '' : m.mapAddressToText(foundKey)
        if (s === "billing") {
            fProps.setFieldValue("customerBillingAddress", newValue)
        } else {
            fProps.setFieldValue("customerShippingAddress", newValue)
        }
    }

    _onContactNameChanged = (contactKey?: string | number) => {
        // find the contact
        const matchedContact = this.state.customerContacts?.find(c => c.contactCode === contactKey);

        if (!contactKey || !matchedContact) {
            this.setState({
                customerPhoneOptions: []
            })
            return
        }

        const po: SapSelectOption[] = []
        if (matchedContact.phone1) {
            po.push({key: matchedContact.phone1, text: matchedContact.phone1})
        }
        if (matchedContact.phone2) {
            po.push({key: matchedContact.phone2, text: matchedContact.phone2})
        }
        if (matchedContact.phone3) {
            po.push({key: matchedContact.phone3, text: matchedContact.phone3})
        }

        // Set the phone numbers
        this.setState({
            customerPhoneOptions: po
        })
    }

    render() {
        const t = this.props.t;
        const fProps = this.state.formikProps;
        return (
            <React.Fragment>
                <BusinessPartnerDialog sourceId={this.state.partnerSelectDialogSource}
                                       open={this.state.partnerSelectDialogOpen}
                                       onCancel={this._onBusinessPartnerDialogClosed}
                                       onSelected={this._onBusinessPartnerDialogSelected}
                />

                <Grid container item xs={12} md={6} alignItems={"flex-start"}>

                    <Grid item>
                        <input type="hidden" name="customerCardCode" value={fProps.values.customerCardCode}/>
                    </Grid>

                    <Grid item>
                        <h2>{t(`${TKey}Customer`)}</h2>
                    </Grid>

                    <Grid item>
                        <Grid container spacing={2}>
                            <Grid item xs={7} sm={9}>
                                <FormikTextField fieldName={"customerCardName"}
                                                 label={t(`${TKey}CustomerName`)}
                                                 type={"text"}
                                                 required={true}
                                                 setter={(newValue) => {
                                                     fProps.setFieldValue("customerBillingAddressDropdown", "")
                                                     fProps.setFieldValue("customerShippingAddressDropdown", "")
                                                     fProps.setFieldValue("customerCardCode", "")
                                                     fProps.setFieldValue("customerCardName", newValue)
                                                     this._loadAddressAndContactTimeout()
                                                     this.props.onCustomerCardCodeChanged('')
                                                 }}/>
                            </Grid>

                            <Grid item xs={5} sm={3}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        maxWidth: 1,
                                        minWidth: "80%"
                                    }}
                                    onClick={() =>
                                        this._openBusinessPartnerSelectDialogOpen((selected?: CDSMSapPartner) => {
                                            fProps.setFieldValue('customerCardCode', selected?.cardCode)
                                            fProps.setFieldValue('customerCardName', selected?.cardName)
                                            // hack to wait here
                                            this.props.onCustomerCardCodeChanged(selected?.cardCode ?? '')
                                        })
                                    }
                                >
                                    {t(`${TKey}Browse`)}
                                </Button>
                            </Grid>

                            {fProps.values.customerCardCode &&
                                <Grid item xs={12}>
                                    <b>SAP: {fProps.values.customerCardCode}</b>
                                </Grid>
                            }

                            <Grid item xs={7} sm={9}>
                                <FormikSelect fieldName="customerBillingAddressDropdown"
                                              label={t(`${TKey}CustomerBillingAddress`)}
                                              options={this.state.customerBillingAddressOptions}
                                              setter={(fieldName, option) => {
                                                  fProps.setFieldValue(fieldName, option)
                                              }}/>
                            </Grid>

                            <Grid item xs={5} sm={3}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        maxWidth: 1,
                                        minWidth: "80%"
                                    }}
                                    onClick={() => {
                                        this._onAddressSelectPressed('billing')
                                    }}
                                >
                                    {t(`${TKey}SelectBtn`)}
                                </Button>
                            </Grid>

                            <Grid item xs={9}>
                                <FormikTextField
                                    variant="outlined"
                                    fieldName={"customerBillingAddress"}
                                    type={"text"}
                                    label={" "}
                                    required={true}
                                    multiline={true}
                                    rows={3}
                                    setter={(newValue) => {
                                        fProps.setFieldValue("customerBillingAddress", newValue)
                                    }}/>
                            </Grid>


                            <Grid item xs={7} sm={9} justifyContent="center" alignItems="center">
                                <FormikSelect fieldName="customerShippingAddressDropdown"
                                              label={t(`${TKey}CustomerShippingAddress`)}
                                              options={this.state.customerShippingAddressOptions}
                                              setter={(fieldName, option) => {
                                                  fProps.setFieldValue(fieldName, option)
                                              }}/>
                            </Grid>

                            <Grid item xs={5} sm={3}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        maxWidth: 1,
                                        minWidth: "80%"
                                    }}
                                    onClick={() => {
                                        this._onAddressSelectPressed("shipping")
                                    }}
                                >
                                    {t(`${TKey}SelectBtn`)}
                                </Button>
                            </Grid>

                            <Grid item xs={9}>
                                <FormikTextField
                                    variant="outlined"
                                    fieldName={"customerShippingAddress"}
                                    type={"text"}
                                    multiline={true}
                                    rows={3}
                                    setter={(newValue) => {
                                        fProps.setFieldValue("customerShippingAddress", newValue)
                                    }}/>
                            </Grid>

                            <Grid item xs={9}>
                                <FormikAutoComplete fieldName="customerContactNameValue"
                                                    label={t(`${TKey}CustomerContactName`)}
                                                    options={this.state.customerContactOptions}
                                                    freeSolo={true}
                                                    disabled={false}
                                                    setter={(fieldName, key, value) => {
                                                        fProps.setFieldValue("customerContactNameKey", key)
                                                        fProps.setFieldValue("customerContactNameValue", value)
                                                        this._onContactNameChanged(key)
                                                    }}
                                />
                            </Grid>

                            <Grid item xs={9}>
                                <FormikAutoComplete fieldName="customerContactPhoneValue"
                                                    label={t(`${TKey}CustomerContactPhone`)}
                                                    options={this.state.customerPhoneOptions}
                                                    freeSolo={true}
                                                    disabled={false}
                                                    setter={(fieldName, key, value) => {
                                                        fProps.setFieldValue("customerContactPhoneKey", key)
                                                        fProps.setFieldValue("customerContactPhoneValue", value)
                                                    }}/>

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

export default withTranslation()(DeliveryNoteDialogCustomerForm);