import React from 'react';
import {useWorksheetCartState} from "modules/worksheet/worksheet-cart/useWorksheetCartState";
import {Badge, CircularProgress, IconButton, IconButtonProps, Popover} from "@mui/material";
import ImageIcon from "shared/components/image-icon/ImageIcon";
import {WorksheetIconImages} from "modules/worksheet/editor/shared/component/images/WorksheetIconImages";
import WorksheetCart from "modules/worksheet/worksheet-cart/WorksheetCart";

export type WorksheetCartButtonProps = IconButtonProps & {
    scope?: number | string
}
const WorksheetCartButton = ({scope, ...props}: WorksheetCartButtonProps) => {
    // const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const {numberOfEntries, cleanCartEntriesIsLoading, cleanCartEntries} = useWorksheetCartState(scope)

    const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        await cleanCartEntries()
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const open = Boolean(anchorEl)

    return (
        <>
            <IconButton {...props} onClick={handleClick}>
                <Badge badgeContent={numberOfEntries} color="primary" showZero>
                    <ImageIcon image={WorksheetIconImages.signSmall}/>
                </Badge>
            </IconButton>

            <Popover
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                sx={{
                    minWidth: "350px",
                    maxWidth: "70vw"
                }}
            >
                {cleanCartEntriesIsLoading ? (
                    <CircularProgress/>
                ) : (
                    <WorksheetCart scope={scope}/>
                )}
            </Popover>
        </>
    )
}

export default WorksheetCartButton;