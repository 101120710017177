import {useMutation} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {EmailNotificationApi} from "crowbar-api";

export type UseEmailNotificationUpdateResendForProps = {
    enId?: string | undefined
    resendValue?: boolean | undefined
}
export const useEmailNotificationUpdateResendFor = () => {
    return useMutation(async ({enId, resendValue}: UseEmailNotificationUpdateResendForProps) => {
        if (!enId) throw new Error('Invalid notification id')
        await crowbarApiFactory(EmailNotificationApi)
            .updateResendFor(enId, !!resendValue)
    })
}
