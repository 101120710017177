import {useCallback} from "react";
import {useWorksheetSaveService} from "modules/worksheet/service/useWorksheetSaveService";
import {useWorksheetSaveState} from "modules/worksheet/editor/state/WorksheetSaveAtoms";

export const useWorksheetClearAndLoadFromServer = () => {
    const [ws] = useWorksheetSaveState()
    const saveService = useWorksheetSaveService()

    return useCallback(async () => {
        if (!ws?.id) return

        await saveService.clearFromStorage(ws?.id)
        window.location.reload()
    }, [saveService, ws?.id])
}