import React from 'react';
import {EquipmentSearchStateProps} from "modules/equipment/equipment-search/EquipmentSearch";
import {useEquipmentSearchState} from "modules/equipment/equipment-search/useEquipmentSearchState";
import {Button, Stack} from '@mui/material';
import useTranslateFunction from "shared/language/useTranslateFunction";
import EquipmentSearchInfQueryLoader from "modules/equipment/equipment-search/results/EquipmentSearchInfQueryLoader";
import EquipmentSearchResultGrid from "modules/equipment/equipment-search/results/EquipmentSearchResultGrid";

export type EquipmentSearchResultsProps = EquipmentSearchStateProps & {}

const EquipmentSearchResults = ({searchStateAtom}: EquipmentSearchResultsProps) => {
    const tk = useTranslateFunction(`Equipment`)

    const {
        searchInfQuery,
        isLoading,
        isFetching,
        isFetchingNextPage,
        anyHasNextPage
    } = useEquipmentSearchState(searchStateAtom)

    const loadMore = async () => {
        // parallel execution
        await Promise.all([
            searchInfQuery.fetchNextPage(),
        ])
    }

    return (
        <Stack
            direction="column"
            spacing={3}
            width={1}
            alignItems="center"
            justifyContent="center"
            sx={{
                mb: 4
            }}
        >
            <EquipmentSearchResultGrid searchStateAtom={searchStateAtom}/>

            <EquipmentSearchInfQueryLoader searchStateAtom={searchStateAtom}/>

            <Button
                variant="contained"
                color="primary"
                onClick={() => loadMore()}
                disabled={isLoading || isFetching || isFetchingNextPage || !anyHasNextPage}
            >
                {tk('LoadMore')}
            </Button>

        </Stack>
    );
};

export default EquipmentSearchResults;