import React from 'react';
import {Card, CardContent, Grid, Stack, Typography} from "@mui/material";
import NativeDateInput from "shared/components/inputs/NativeDateInput";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {useWorksheetSaveState} from "modules/worksheet/editor/state/WorksheetSaveAtoms";
import {DateUtils} from "shared/utils/DateUtils";

export interface WEditorPageBasicDurationBoxProps {
    disabled?: boolean
}

const WEditorPageBasicDurationBox = ({disabled}: WEditorPageBasicDurationBoxProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    const [ws, setWs] = useWorksheetSaveState()

    const startDate = DateUtils.parseServerUTCToDate(ws?.startedAt)
    const endDate = DateUtils.parseServerUTCToDate(ws?.endedAt)

    const todayFormatted = DateUtils.intlDateToFormat(new Date(), "fr-CA")
    const startFormatted = startDate ? DateUtils.intlDateToFormat(startDate, "fr-CA") : todayFormatted
    const endFormatted = endDate ? DateUtils.intlDateToFormat(endDate, "fr-CA") : todayFormatted

    const onChangeStart = (newDate: string) => {
        setWs(prev => {
            return {
                ...prev,
                startedAt: newDate
            }
        })
    }

    const onChangeEnd = (newDate: string) => {
        setWs(prev => {
            return {
                ...prev,
                endedAt: newDate
            }
        })
    }

    return (
        <Card variant="outlined" sx={{height: "100%"}}>
            <CardContent>
                <Grid container spacing={2}>

                    <Grid item xs={12}>
                        <Typography variant="h6" fontWeight="bold">
                            {tk('BasicDurationTitle')}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>

                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                            <Typography>{tk('StartDateLabel')}</Typography>
                            <NativeDateInput
                                disabled={disabled}
                                value={startFormatted ?? todayFormatted}
                                defaultValue={undefined}
                                onChangeWithServerValue={onChangeStart}
                            />
                        </Stack>

                    </Grid>

                    <Grid item xs={12}>

                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                            <Typography>{tk('EndDateLabel')}</Typography>
                            <NativeDateInput
                                disabled={disabled}
                                value={endFormatted ?? todayFormatted}
                                defaultValue={undefined}
                                onChangeWithServerValue={onChangeEnd}
                            />
                        </Stack>

                    </Grid>

                </Grid>
            </CardContent>
        </Card>
    )
}

export default WEditorPageBasicDurationBox;