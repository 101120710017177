import {useWorksheetListState} from "modules/admin/worksheet/worksheet-list/state/useWorksheetListState";
import {Card, Checkbox, FormControlLabel, Grid} from "@mui/material";
import SearchBoxAndDebounce from "shared/components/inputs/SearchBoxAndDebounce";
import {WorksheetStatusFilterButtonGroup} from "modules/worksheet/mechanic/filters/WorksheetStatusFilterButtonGroup";
import React from "react";
import WorksheetListFilterByMechanics
    from "modules/admin/worksheet/worksheet-list/filters/WorksheetListFilterByMechanics";
import {PrimitiveAtom} from "jotai";
import {MechanicListingState} from "modules/worksheet/mechanic/state/useMechanicListingState";
import WorksheetListDateFilter from "modules/admin/worksheet/worksheet-list/filters/WorksheetListDateFilter";
import useTranslateFunction from "shared/language/useTranslateFunction";

export type WorksheetListFiltersProps = {
    stateAtom: PrimitiveAtom<MechanicListingState>
}
export const WorksheetListFilters = ({stateAtom}: WorksheetListFiltersProps) => {
    const tk = useTranslateFunction(`Admin.Worksheet`)

    const {state, setState} = useWorksheetListState(stateAtom)

    const onSearchValueChange = (newValue: string) => {
        setState(prev => {
            return {
                ...prev,
                searchValue: newValue
            }
        })
    }

    const setOwnerIds = (mechanics: string[] | undefined) => {
        setState(prev => {
            return {
                ...prev,
                ownerIds: mechanics
            }
        })
    }

    const onShowDeletedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState(prev => {
            return {
                ...prev,
                showDeletedWorksheets: event.target.checked
            }
        })
    }

    return (
        <Card
            variant="outlined"
            sx={{
                p: 2
            }}
        >
            <Grid container spacing={1}>

                <Grid item xs={12}>
                    <SearchBoxAndDebounce
                        value={state.searchValue}
                        onChange={onSearchValueChange}
                        size="small"
                    />
                </Grid>

                <Grid item xs={12}>

                    <Grid
                        container
                        spacing={1}
                        justifyContent="space-between"
                        sx={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <Grid item xs="auto" sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <WorksheetStatusFilterButtonGroup mechanicStateAtom={stateAtom}/>
                        </Grid>
                        <Grid item xs="auto" sx={{
                            display: "flex",
                            justifyContent: "center"
                        }}>
                            <WorksheetListDateFilter mechanicStateAtom={stateAtom}/>
                        </Grid>

                        {/* <Grid item xs="auto" sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <MechanicWorksheetDisplayModeToggle mechanicStateAtom={stateAtom}/>
                        </Grid>*/}
                    </Grid>

                </Grid>

                <Grid item xs={12}>
                    <WorksheetListFilterByMechanics
                        selectedMechanicIds={state.ownerIds ?? []}
                        onChange={setOwnerIds}
                        onClear={() => setOwnerIds([])}
                        onReset={() => setOwnerIds(undefined)}
                    />
                </Grid>

                <Grid item xs="auto">
                    <FormControlLabel
                        control={
                            <Checkbox checked={state.showDeletedWorksheets} onChange={onShowDeletedChange}/>
                        }
                        label={tk('ShowDeleted')}
                    />
                </Grid>

            </Grid>
        </Card>
    )
}