import {Box, Stack} from "@mui/material";
import PhotoRuleEditStepAddButton
    from "modules/admin/worksheet/photo-rule/photo-rules/PhotoRuleEdit/PhotoRuleEditStepAddButton";
import PhotoRuleEditStepsGrid
    from "modules/admin/worksheet/photo-rule/photo-rules/PhotoRuleEdit/PhotoRuleEditStepsGrid";
import usePhotoRuleEditSteps from "modules/admin/worksheet/photo-rule/photo-rules/PhotoRuleEdit/usePhotoRuleEditSteps";
import usePhotoRuleTemplatesForSteps
    from "modules/admin/worksheet/photo-rule/photo-rules/PhotoRuleEdit/usePhotoRuleTemplatesForSteps";
import {CDAMPMPhotoRuleStepModel} from "crowbar-api";
import {toUnique} from "shared/utils/ArrayUnique";

const mapIds = (data?: CDAMPMPhotoRuleStepModel[]): string[] => {
    if (!data) return []
    return data
        .filter(s => s.id)
        .map(s => s.id!)
        .reduce(toUnique, [] as string[])
}

export interface PhotoRuleEditStepsProps {
    ruleId: string
}

const PhotoRuleEditSteps = ({ruleId}: PhotoRuleEditStepsProps) => {

    const stepsQuery = usePhotoRuleEditSteps(ruleId)
    const templatesQuery = usePhotoRuleTemplatesForSteps(mapIds(stepsQuery.data))

    return (
        <Stack direction="column">
            <Box sx={{
                marginBottom: 4
            }}>
                <PhotoRuleEditStepAddButton
                    ruleId={ruleId}
                    stepsQuery={stepsQuery}
                />
            </Box>

            <PhotoRuleEditStepsGrid
                ruleId={ruleId}
                stepsQuery={stepsQuery}
                templatesQuery={templatesQuery}
            />
        </Stack>
    )
}

export default PhotoRuleEditSteps