import React from 'react';
import {Box} from "@mui/material";
import EquipmentCoverOrBlobImage from "modules/equipment/equipment-search/results/EquipmentCoverOrBlobImage";
import {CDAMCMCrowbarBlobModel} from "crowbar-api";

export type EquipmentImageClickableProps = {
    blob: CDAMCMCrowbarBlobModel
    onClick: (blob: CDAMCMCrowbarBlobModel) => void
}
const EquipmentImageClickable = ({
                                     blob,
                                     onClick
                                 }: EquipmentImageClickableProps) => {
    return (
        <Box onClick={() => onClick(blob)}>
            <EquipmentCoverOrBlobImage
                parentFolder={blob.parentDirectory}
                filename={blob.fileName}
                coverImage={undefined}
            />
        </Box>
    );
};

export default EquipmentImageClickable;