import React, {useState} from 'react';
import {
    CDAMWMServiceCallEvalModel,
    CDAMWMServiceCallItemRowModel,
    CDAMWMServiceCallModel,
    CDAMWMWWorksheetQuery,
    CDSMSSapItemForWorksheetSearchQuery
} from "crowbar-api";
import {Button, CircularProgress} from "@mui/material";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {
    useServiceCallItemRowEvalAddItemRowAndEvaluation
} from "crowbar-api/hooks/worksheet/evaluation/service-call-item-row/useServiceCallItemRowEvalAddItemRowAndEvaluation";
import AddItemDialog from "modules/admin/worksheet/evaluation/tabs/service-calls/catalogue/AddItemDialog";
import {v4 as uuid} from 'uuid'
import ServiceCallItemRowSelectedSourceMap from "crowbar-api/enum-map/ServiceCallItemRowSelectedSourceMap";
import ServiceCallItemRowStatusMap from "crowbar-api/enum-map/ServiceCallItemRowStatusMap";

export type AddItemButtonProps = {
    rows: CDAMWMServiceCallItemRowModel[]
    worksheet: CDAMWMWWorksheetQuery | null | undefined
    sc: CDAMWMServiceCallModel
    scEval: CDAMWMServiceCallEvalModel
    disabled?: boolean
    onItemAdded: () => Promise<void>
}

const AddItemButton = ({
                           rows,
                           worksheet,
                           sc,
                           scEval,
                           disabled,
                           onItemAdded
                       }: AddItemButtonProps) => {
    const tk = useTranslateFunction("Admin.Worksheet")

    const [open, setOpen] = useState<boolean>(false)

    const addItemRowAndEvaluation = useServiceCallItemRowEvalAddItemRowAndEvaluation()

    const onAddClick = () => {
        setOpen(true)
    }

    const onItemSelected = async (item: CDSMSSapItemForWorksheetSearchQuery) => {
        console.log(item)
        setOpen(false)

        const maxVisOrder = rows.reduce((max, ir) => max > (ir.visOrder ?? 0) ? max : (ir.visOrder ?? 0), 0)
        const maxLineNum = rows.reduce((max, ir) => max > (ir.lineNum ?? 0) ? max : (ir.lineNum ?? 0), 0)

        await addItemRowAndEvaluation.mutateAsync({
            serviceCallEvalId: scEval.id,
            itemRow: {
                id: uuid(),
                serviceCallId: scEval.serviceCallId,
                status: ServiceCallItemRowStatusMap.installed,
                warningType: undefined,
                rowSelectedSource: ServiceCallItemRowSelectedSourceMap.manuallyEntered,
                sourceSalesOrderRowId: undefined,
                sourceDocEntry: undefined,
                sourceLineNum: undefined,
                sourceCoverImage: item.coverImage,
                comment: undefined,
                installedQuantity: 1,
                itemCode: item.itemCode,
                itemName: item.itemName,
                lineNum: maxLineNum + 1,
                visOrder: maxVisOrder + 1,
                sourceQuantity: undefined,
                sourceTimeRowId: undefined,
                timeUserId: undefined,
                unitOfQuantity: item.buyUnitMsr
            }
        })

        await onItemAdded()
    }

    return (
        <>
            <Button
                variant="contained"
                size="small"
                disabled={disabled || addItemRowAndEvaluation.isLoading}
                onClick={onAddClick}
            >
                {tk('Add')}
                {addItemRowAndEvaluation.isLoading && (
                    <CircularProgress/>
                )}
            </Button>

            {open && (
                <AddItemDialog
                    open={open}
                    onClose={() => setOpen(false)}
                    onItemSelected={onItemSelected}
                />
            )}
        </>
    )
}

export default AddItemButton;