import {useQuery} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {SapEmployeeApi} from "crowbar-api";
import AxiosCancelTokenHelper from "crowbar-api/AxiosCancelTokenHelper";

const useSapEmployees = () => {
    return useQuery(['1-sap-employees'], async (context) => {
        const response = await crowbarApiFactory(SapEmployeeApi)
            .findAll(AxiosCancelTokenHelper.cancelTokenFrom(context.signal))
        if (response.status !== 200) {
            throw new Error('Invalid status')
        }
        return response.data ?? []
    }, {
        cacheTime: Math.pow(10, 7),
        staleTime: Math.pow(10, 7),
        retry: 3,
        refetchOnMount: "always",
        refetchOnWindowFocus: false
    })
}

export default useSapEmployees