import {useMutation} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {CDAMWMEOcrCodeServiceCallItemRowEvalUpdate, ServiceCallItemRowEvalApi} from "crowbar-api";
import {AxiosRequestConfig} from "axios";

export const useServiceCallItemRowEvalUpdateOcrCode = () => {
    return useMutation(async (e: CDAMWMEOcrCodeServiceCallItemRowEvalUpdate, options?: AxiosRequestConfig) => {
        await crowbarApiFactory(ServiceCallItemRowEvalApi)
            .updateOcrCode(e, options)
    })
}