import React from 'react';
import {UsePhotoBlobsResult} from "modules/worksheet/editor/ui/photo/usePhotoBlobs";
import {Box, Grid} from "@mui/material";
import WEditorPhotoGroup from "modules/worksheet/editor/ui/photo/WEditorPhotoGroup";
import {useNewAtomWithRef} from "shared/hook/useNewatomWithRef";
import {
    CDAMPMPhotoRuleAndManufacturersQuery,
    CDAMWMESapEquipmentServiceCallBlobQuery,
    CDAMWMWServiceCallSave
} from "crowbar-api";
import WEditorPhotoLightbox from "modules/worksheet/editor/ui/photo/WEditorPhotoLightbox";
import useTranslateFunction from "shared/language/useTranslateFunction";
import usePhotoHelperDialogState from "modules/worksheet/editor/ui/photo/usePhotoHelperDialogState";
import {toastError} from "shared/toast/DefaultToasts";
import {useServiceCallBlobSetDelete} from "crowbar-api/hooks/worksheet/blobs/useServiceCallBlobSetDelete";

export interface WEditorPhotoDisplayProps {
    selectedServiceCall: CDAMWMWServiceCallSave | undefined
    photoBlobs: UsePhotoBlobsResult
    afterDelete: () => void
}

const WEditorPhotoDisplay = ({selectedServiceCall, photoBlobs, afterDelete}: WEditorPhotoDisplayProps) => {

    const [lightboxOpenSignal, setLightboxOpenSignal] = useNewAtomWithRef(0)
    const [lightboxCurrentBlob, setLightboxCurrentBlob] = useNewAtomWithRef<string | undefined>(undefined)

    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    const setDelete = useServiceCallBlobSetDelete()

    const {setDialogState, prepareDialogStateFor} = usePhotoHelperDialogState()

    const onImageClick = (rule?: CDAMPMPhotoRuleAndManufacturersQuery, blob?: CDAMWMESapEquipmentServiceCallBlobQuery) => {
        if (!blob?.blobId) return
        setLightboxCurrentBlob(blob.blobId)
        setLightboxOpenSignal(prev => prev + 1)
    }

    const onReplaceClick = (rule?: CDAMPMPhotoRuleAndManufacturersQuery, blob?: CDAMWMESapEquipmentServiceCallBlobQuery) => {
        if (blob?.orderNum && blob?.subOrderNum && rule?.id) {
            prepareDialogStateFor(selectedServiceCall?.manufacturer, photoBlobs, blob.orderNum, blob.subOrderNum)
            setDialogState(p => {
                return {
                    ...p,
                    selectedRuleId: rule?.id, // selects the rule as well
                    open: true
                }
            })
        } else {
            toastError('Cannot replace')
        }
    }

    const onDeleteClick = async (rule?: CDAMPMPhotoRuleAndManufacturersQuery, blob?: CDAMWMESapEquipmentServiceCallBlobQuery) => {
        if (blob?.blobId && blob?.serviceCallId) {

            await setDelete.mutateAsync({
                blobId: blob.blobId,
                serviceCallId: blob.serviceCallId,
                isDeleted: true
            })
            afterDelete()
        } else {
            toastError('Cannot delete')
        }
    }

    return (
        <Box>
            <Grid
                container
                direction="row"
                rowSpacing={2}
            >
                {photoBlobs.groupedBlobs.map(groupedBlob => (
                    <Grid key={groupedBlob.rule.id ?? ""} item xs={12}>
                        <WEditorPhotoGroup rule={groupedBlob.rule}
                                           blobs={groupedBlob.blobs}
                                           onImageClick={onImageClick}
                                           onReplaceClick={onReplaceClick}
                                           onDeleteClick={onDeleteClick}
                        />
                    </Grid>
                ))}
                {(photoBlobs.ungroupedBlobs && photoBlobs.ungroupedBlobs.length > 0) && (
                    <Grid item xs={12}>
                        <WEditorPhotoGroup
                            rule={{
                                id: undefined,
                                title: tk('EquipmentImages'),
                            }}
                            // subTitle={tk('PhotoNoRule')}
                            blobs={photoBlobs.ungroupedBlobs}
                            onImageClick={onImageClick}
                            onReplaceClick={onReplaceClick}
                            onDeleteClick={onDeleteClick}
                        />
                    </Grid>
                )}
            </Grid>

            <WEditorPhotoLightbox
                openSignal={lightboxOpenSignal}
                photoBlobs={photoBlobs}
                currentBlob={lightboxCurrentBlob}
            />
        </Box>
    );
};

export default WEditorPhotoDisplay;