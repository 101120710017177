import React from 'react';
import {useWorksheetSaveState} from "modules/worksheet/editor/state/WorksheetSaveAtoms";
import {Grid, Typography} from "@mui/material";
import SpeechToText from "modules/worksheet/speech-to-text/SpeechToText";
import {SadFaceRating} from "modules/worksheet/editor/ui/feedback/SadFaceRating";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {useMountedState} from "shared/hook/useMountedState";


const WEditorFeedback = () => {
    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)
    const [ws, setWs] = useWorksheetSaveState()

    const isMounted = useMountedState()

    const onFeedbackNumChange = (newNum?: number | null) => {
        setWs(prev => {
            return {
                ...prev,
                feedbackNum: newNum
            }
        })
    }

    const onFeedbackTextChange = (text: string) => {
        setWs(prev => {
            return {
                ...prev,
                feedbackText: text
            }
        })
    }

    const onFeedbackTextTranscribe = (text: string) => {
        if (isMounted()) {
            setWs(prev => {
                return {
                    ...prev,
                    feedbackText: ((prev?.feedbackText ?? "") + "\r\n" + text).trim()
                }
            })
        }
    }

    return (
        <Grid container spacing={1} justifyContent="center" alignItems="center">
            <Grid item xs={12}>
                <Typography variant="body1">
                    {tk('FeedbackNumLabel')}
                </Typography>
            </Grid>
            <Grid item xs="auto">
                <SadFaceRating
                    value={ws?.feedbackNum ?? null}
                    onChange={(event, value) => onFeedbackNumChange(value)}
                    sx={{
                        fontSize: "32pt"
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1">
                    {tk('FeedbackTextLabel')}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <SpeechToText
                    fullWidth
                    minRows={3}
                    maxRows={7} // https://stackoverflow.com/a/72170317/840315
                    rows={undefined}
                    value={ws?.feedbackText ?? ""}
                    onBlur={onFeedbackTextChange}
                    onTranscribeDone={onFeedbackTextTranscribe}
                />
            </Grid>
        </Grid>
    );
};

export default WEditorFeedback;