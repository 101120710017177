import {useQuery} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {AgentNotificationConfigurationApi, CDCMGenericPagingParams} from "crowbar-api";
import AxiosCancelTokenHelper from "crowbar-api/AxiosCancelTokenHelper";

export const useAgentNotificationConfigurationPageAll = (parameters: CDCMGenericPagingParams) => {
    return useQuery(['agent-notification-configuration-find-all', parameters], async ({signal}) => {
        const response = await crowbarApiFactory(AgentNotificationConfigurationApi)
            .pageAll(parameters, AxiosCancelTokenHelper.cancelTokenFrom(signal))
        return response.data ?? []
    }, {
        networkMode: 'online', // will not fire unless we have network
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        cacheTime: 0,// Math.pow(10, 7),
        staleTime: 0, // Math.pow(10, 7),
    })
}