import React, {useState} from 'react';
import {useUserContext} from "shared/hook/UserContext";
import {Button, Menu, MenuItem} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {NavLink} from "react-router-dom";
import useTranslateFunction from "shared/language/useTranslateFunction";

const NavbarWorksheetMenu = () => {
    const tk = useTranslateFunction('Header.Nav')

    const [userId, userClaims] = useUserContext()

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleOpenMenu = (e: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(e.currentTarget)
    }

    const handleCloseMenu = () => {
        setAnchorEl(null)
    }

    // const isAdmin = userClaims?.filter(c => c === 'Administrator').length > 0
    return (
        <>
            <Button onClick={handleOpenMenu} sx={{
                color: "text.primary"
            }}>
                {tk('Worksheet')}&nbsp;<ArrowDropDownIcon/>
            </Button>
            <Menu
                sx={{mt: '45px'}}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
            >
                <NavLink to={`/worksheet/mechanic/${userId}/worksheets`} style={{
                    textDecoration: "none"
                }} onClick={handleCloseMenu}>
                    <MenuItem sx={{color: "text.primary"}}>
                        {tk('MyWorksheets')}
                    </MenuItem>
                </NavLink>
            </Menu>
        </>
    );
};

export default NavbarWorksheetMenu;