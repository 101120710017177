import {FormikErrors, FormikProps} from "formik";
import {IDeliveryNoteDialogFields} from "./IDeliveryNoteDialogFields";
import {TFunction} from "i18next";
import {toastError} from "shared/toast/DefaultToasts";
import {CDAMDMDeliveryNoteModel, CDAMDMDeliveryNoteRowModel, CDAMDMDeliveryNoteRowSourceType} from "crowbar-api";
import {v4 as uuid} from 'uuid';
import {DeliveryNoteTypeMap} from "crowbar-api/enum-map/DeliveryNoteTypeMap";
import LeaseStatusMap from "crowbar-api/enum-map/LeaseStatusMap";

const TKey = "DeliveryNote.Note.Dialog.";

export class DeliveryNoteValidation {
    private readonly fProps: FormikProps<IDeliveryNoteDialogFields>;
    private readonly t: TFunction;

    constructor(t: TFunction,
                fProps: FormikProps<IDeliveryNoteDialogFields>) {
        this.t = t;
        this.fProps = fProps;
    }

    public validate = (): boolean => {
        const tf = (key: string): string => this.t(`${TKey}${key}`);
        const f = this.fProps;
        const errors: FormikErrors<IDeliveryNoteDialogFields> = {}

        // Required rows
        if (!f.values.rows || f.values.rows.length < 1) {
            toastError(tf('ReqRows'))
            return false
        }

        let hasError = false
        const missingReqRowField = f.values.rows
            .filter(row => row.quantity === undefined
                || row.quantity?.toString().trim() === ""
                || isNaN(row.quantity)
                || !row.unitOfQuantity?.trim()
            )
            .length > 0
        if (missingReqRowField) {
            toastError(tf('ReqRowFields'))
            hasError = true
        }

        // Invalid
        const invalidNumbers = f.values.rows
            .filter(row =>
                (row.quantity && isNaN(row.quantity)) ||
                (row.opHours && isNaN(row.opHours))
            ).length > 0
        if (invalidNumbers) {
            toastError(tf('InvalidNumberRows'))
            hasError = true
        }

        // Required fields
        if (!f.values.supplierCardName?.trim()) {
            errors.supplierCardName = tf('ReqField')
            hasError = true
        }

        if (!f.values.supplierAddress?.trim()) {
            errors.supplierAddress = tf('ReqField')
            hasError = true
        }

        if (!f.values.issuerNameValue?.trim()) {
            errors.issuerNameValue = tf('ReqField')
            hasError = true
        }

        if (!f.values.customerCardName?.trim()) {
            errors.customerCardName = tf('ReqField')
            hasError = true
        }

        if (!f.values.customerBillingAddress?.trim()) {
            errors.customerBillingAddress = tf('ReqField')
            hasError = true
        }

        if (!f.values.type?.trim()) {
            errors.type = tf('ReqField')
            hasError = true
        }

        // Length
        if ((f.values.supplierCardName?.length ?? 0) > 255) {
            errors.supplierCardName = tf('TooLong255')
            hasError = true
        }

        if ((f.values.supplierAddress?.length ?? 0) > 255) {
            errors.supplierAddress = tf('TooLong255')
            hasError = true
        }

        if ((f.values.supplierContactNameValue?.length ?? 0) > 255) {
            errors.supplierContactNameKey = tf('TooLong255')
            hasError = true
        }

        if ((f.values.issuerNameValue?.length ?? 0) > 255) {
            errors.issuerNameKey = tf('TooLong255')
            hasError = true
        }

        if ((f.values.customerCardName?.length ?? 0) > 255) {
            errors.customerCardName = tf('TooLong255')
            hasError = true
        }

        if ((f.values.customerBillingAddress?.length ?? 0) > 255) {
            errors.customerBillingAddress = tf('TooLong255')
            hasError = true
        }

        if ((f.values.customerShippingAddress?.length ?? 0) > 255) {
            errors.customerShippingAddress = tf('TooLong255')
            hasError = true
        }

        if ((f.values.customerContactNameValue?.length ?? 0) > 255) {
            errors.customerContactNameKey = tf('TooLong255')
            hasError = true
        }

        if ((f.values.customerContactPhoneValue?.length ?? 0) > 255) {
            errors.customerContactPhoneKey = tf('TooLong255')
            hasError = true
        }

        if ((f.values.orderNumber?.length ?? 0) > 255) {
            errors.orderNumber = tf('TooLong255')
            hasError = true
        }

        if ((f.values.licensePlateNumberValue?.length ?? 0) > 255) {
            errors.licensePlateNumberKey = tf('TooLong255')
            hasError = true
        }

        const hasInvalidLengthRows = f.values.rows
            .filter(r => r.description?.length > 255 || (r.unitOfQuantity?.length ?? 0) > 255)
            .length > 0
        if (hasInvalidLengthRows) {
            toastError(tf('TooLongRows255'))
            hasError = true
        }

        // Result
        f.setErrors(errors)

        return !hasError // meaning it is valid
    }

    public prepareObject(mode: 'new' | 'edit', prev?: CDAMDMDeliveryNoteModel): CDAMDMDeliveryNoteModel | undefined {

        const fv = this.fProps.values;
        const objectId = mode === "new" ? uuid() : prev?.id
        if (!objectId) {
            toastError("Failed to prepare object for save. ObjectId is null.")
            return undefined
        }
        // Rows
        const rows: CDAMDMDeliveryNoteRowModel[] = fv.rows?.map((row, index) => {
            return {
                id: uuid(),
                deliveryNoteId: objectId, // server will auto fill it though
                // sourceType: row.source ?? 0,
                sourceType: CDAMDMDeliveryNoteRowSourceType.NUMBER_1 === row.source ? CDAMDMDeliveryNoteRowSourceType.NUMBER_1 :
                    CDAMDMDeliveryNoteRowSourceType.NUMBER_2 === row.source ? CDAMDMDeliveryNoteRowSourceType.NUMBER_2 :
                        CDAMDMDeliveryNoteRowSourceType.NUMBER_0
                ,
                lineOrder: index,
                description: row.description,
                quantity: row.quantity,
                unitOfQuantity: row.unitOfQuantity,
                operatingHours: row.opHours ? row.opHours : undefined
            }
        })

        const typeMap = new DeliveryNoteTypeMap();
        const type = typeMap.mapTo(fv.type ?? "");
        const leaseStatusMap = new LeaseStatusMap();
        const status = leaseStatusMap.mapTo(fv.leaseState ?? "");

        return {
            id: objectId,
            serialId: prev?.serialId || "",
            type: type,
            leaseStatus: typeMap.lease === type ? status : undefined,
            //datedAt: moment(fv.datedAt).toISOString(), // UTC Time: https://momentjs.com/docs/#/displaying/as-iso-string/
            datedAt: fv.datedAt,
            supplierCardCode: fv.supplierCardCode,
            supplierCardName: fv.supplierCardName,
            supplierAddress: fv.supplierAddress,
            supplierContactName: fv.supplierContactNameValue,
            supplierContactPhone: undefined,
            issuerEmployeeId: fv.issuerEmployeeId,
            issuerContactPersonCode: fv.issuerContactPersonCode,
            issuerName: fv.issuerNameValue,
            customerCardCode: fv.customerCardCode,
            customerCardName: fv.customerCardName,
            customerBillingAddress: fv.customerBillingAddress,
            customerShippingAddress: fv.customerShippingAddress,
            customerContactName: fv.customerContactNameValue,
            customerContactPhone: fv.customerContactPhoneValue,
            orderNumber: fv.orderNumber,
            licensePlateNumber: fv.licensePlateNumberValue,
            isDeleted: fv.isDeleted,
            rows: rows
        }
    }
}