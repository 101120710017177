import {useMemo} from "react";
import {worksheetSaveStateAtom} from "modules/worksheet/editor/state/WorksheetSaveAtoms";
import {useAtom} from "jotai";
import {focusAtom} from "jotai-optics";

export const useModSapEquipment = (modEquipmentId: string | null | undefined) => {
    const focusedAtom = useMemo(() => {
        return focusAtom(worksheetSaveStateAtom, (optic) =>
            optic
                .optional()
                .prop('modSapEquipments')
                .optional()
                .find(p => p.id === modEquipmentId)
        )
    }, [modEquipmentId])

    return useAtom(focusedAtom)
}