import React, {useEffect} from "react";
import {useAtom} from "jotai";
import {
    WorksheetEditorPageName,
    WorksheetEditorPageRouteObject,
    WorksheetEditorPageRoutesAtom
} from "modules/worksheet/editor/state/WorksheetEditorRouteAtoms";
import {Navigate} from 'react-router-dom';
import WorksheetEditorPageCatalogue from "modules/worksheet/editor/ui/catalogue/WorksheetEditorPageCatalogue";
import WorksheetEditorPagePhoto from "modules/worksheet/editor/ui/photo/WorksheetEditorPagePhoto";
import WEditorCommonPlaceHolder from "modules/worksheet/editor/ui/header/WEditorCommonPlaceHolder";
import WEditorPageDescription from "modules/worksheet/editor/ui/description/WEditorPageDescription";

const useWorksheetEditorRoutes = (): WorksheetEditorPageRouteObject[] => {
    const [pageRoutes, setPageRoutes] = useAtom(WorksheetEditorPageRoutesAtom)

    useEffect(() => {
        setPageRoutes([
            {
                pageName: WorksheetEditorPageName.BASIC,
                element: (<Navigate to="basic"/>),
                // element: ( <CircularProgress />),
                index: true
            },
            {
                pageName: WorksheetEditorPageName.BASIC,
                element: (<WEditorCommonPlaceHolder/>),
                path: "basic"
            },
            {
                pageName: WorksheetEditorPageName.EQUIPMENTS,
                element: (<WEditorCommonPlaceHolder/>),
                path: "equipments"
            },
            {
                pageName: WorksheetEditorPageName.CATALOGUE,
                element: (<WorksheetEditorPageCatalogue/>),
                path: "catalogue"
            },
            {
                pageName: WorksheetEditorPageName.DESCRIPTION,
                element: (<WEditorPageDescription/>),
                path: "descriptions"
            },
            {
                pageName: WorksheetEditorPageName.PHOTO,
                element: (<WorksheetEditorPagePhoto/>),
                path: "photo"
            },
            {
                pageName: WorksheetEditorPageName.FEEDBACK,
                element: (<WEditorCommonPlaceHolder/>),
                path: "feedback"
            }
        ])
    }, [setPageRoutes])

    return pageRoutes
}

export default useWorksheetEditorRoutes