import React from 'react';

import "i18n/i18n";

import MainRoutes from 'system/Routes';
import {ThemeProvider as MuiThemeProvider} from '@mui/material/styles';
import {ThemeProvider as EmotionThemeProvider} from '@emotion/react';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useAtom} from "jotai";
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {appCurrentMuiThemeAtom} from "App";
import CrowbarTeamsMsalAuthentication from "system/CrowbarTeamsMsalAuthentication";
import {CssBaseline} from "@mui/material";
import {QueryWarmup} from "modules/generic/query-warmup/QueryWarmup";
import {BuildTimeNotification} from "modules/generic/build-time-notification/BuildTimeNotification";

// react query
// Create a client
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            cacheTime: 0,
            refetchOnWindowFocus: false,
            // networkMode: 'offlineFirst',
        },
        mutations: {
            // networkMode: 'offlineFirst',
        },
    }
})

export const CrowbarTeamsApp = () => {
    const [currentMuiTheme] = useAtom(appCurrentMuiThemeAtom);

    return (
        <CrowbarTeamsMsalAuthentication>
            <QueryClientProvider client={queryClient}>
                <div className={`ct-container ct-theme`}>
                    <MuiThemeProvider theme={currentMuiTheme}>
                        <CssBaseline enableColorScheme/>
                        <EmotionThemeProvider theme={currentMuiTheme}>
                            {/*<EnvironmentDisplay />*/}

                            {/* query warmup */}
                            <QueryWarmup>
                                <MainRoutes/>
                            </QueryWarmup>

                            <BuildTimeNotification/>

                        </EmotionThemeProvider>

                        <ToastContainer
                            limit={3}
                        />

                    </MuiThemeProvider>
                </div>
            </QueryClientProvider>
        </CrowbarTeamsMsalAuthentication>
    )
}