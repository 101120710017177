import React from 'react';
import {Grid, GridProps, Typography, TypographyProps} from "@mui/material";
import {StringUtils} from "shared/utils/StringUtils";

export type LabeledFieldProps = {
    label?: string
    value?: string | null

    hideIfValueIsMissing?: boolean

    gridProps?: GridProps
    labelTypographyProps?: TypographyProps
    valueTypographyProps?: TypographyProps
}

const LabeledField = ({
                          label,
                          value,
                          hideIfValueIsMissing,
                          gridProps,
                          labelTypographyProps,
                          valueTypographyProps
                      }: LabeledFieldProps) => {

    if (hideIfValueIsMissing && StringUtils.undefinedNullOrEmpty(value)) {
        return (
            <></>
        )
    }

    return (
        <Grid
            container
            direction="row"
            spacing={1}
            alignItems="top"
            justifyContent="space-between"
            {...gridProps}
            sx={{
                width: 1,
                ...gridProps?.sx
            }}
        >
            <Grid item xs="auto">
                <Typography fontWeight="bold" {...labelTypographyProps}>
                    {label}:
                </Typography>
            </Grid>
            <Grid item xs={true}>
                <Typography {...valueTypographyProps} sx={{
                    display: "flex",
                    justifyContent: "end",
                    ...valueTypographyProps?.sx
                }}>
                    {value}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default LabeledField;