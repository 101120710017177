import React from 'react';
import {
    Checkbox,
    LinearProgress,
    Paper,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import {useTranslation} from "react-i18next";
import {CDSMPartnerEquipmentPageRow} from "crowbar-api";

export interface DeliveryNoteNewRowEquipmentTableProps {
    items: CDSMPartnerEquipmentPageRow[]
    selected?: CDSMPartnerEquipmentPageRow
    onSelectClick: (item: CDSMPartnerEquipmentPageRow) => void
    isLoading: boolean
}

const DeliveryNoteNewRowEquipmentTable = ({
                                              items,
                                              selected,
                                              onSelectClick,
                                              isLoading
                                          }: DeliveryNoteNewRowEquipmentTableProps) => {
    const {t} = useTranslation();
    const TKey = "DeliveryNote.Note.Dialog.";

    return (
        <TableContainer component={Paper}>
            {isLoading ? <LinearProgress variant={"query"}/> : <LinearProgress variant={"determinate"} value={0}/>}
            <Table padding={"none"} sx={{minWidth: "700px", tableLayout: "fixed", overflowWrap: "break-word"}}>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{minWidth: "5%", width: "6%", paddingX: "5px"}}></TableCell>
                        <TableCell sx={{minWidth: "8%", paddingX: "5px"}}
                                   key={"cardCode"}>{t(`${TKey}ColCardCode`)}</TableCell>
                        <TableCell sx={{width: "30%", paddingX: "5px"}}
                                   key={"cardName"}>{t(`${TKey}ColCardName`)}</TableCell>
                        <TableCell sx={{paddingX: "5px"}}
                                   key={"vehicleManufacturer"}>{t(`${TKey}ColVehicleManufacturer`)}</TableCell>
                        <TableCell sx={{paddingX: "5px"}}
                                   key={"vehicleType"}>{t(`${TKey}ColVehicleType`)}</TableCell>
                        <TableCell sx={{width: "10%"}}
                                   key={"yoM"}>{t(`${TKey}ColVehicleYoM`)}</TableCell>
                        <TableCell sx={{paddingX: "5px"}}
                                   key={"manufacturerSN"}>{t(`${TKey}ColManufacturerSn`)}</TableCell>
                        <TableCell sx={{paddingX: "5px"}}
                                   key={"internalSN"}>{t(`${TKey}InternalSn`)}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {isLoading ?
                        [...Array(10)].map((_, i) => {
                            return (
                                <TableRow key={i}>
                                    <TableCell align={"center"} sx={{height: "40px"}}>
                                        <Checkbox disabled/>
                                    </TableCell>
                                    <TableCell colSpan={7} sx={{paddingRight: 5}}>
                                        <Skeleton animation="wave" height={35}/>
                                    </TableCell>
                                </TableRow>)
                        })
                        :
                        items.map((item) => {
                            return (<TableRow
                                hover
                                selected={selected === item}
                                key={item.insId}
                                sx={{'&:last-child td, &:last-child th': {border: 0}, ':hover': {cursor: "pointer"}}}
                                onClick={() => onSelectClick(item)}
                            >
                                <TableCell align={"center"} sx={{height: "40px"}}><Checkbox
                                    checked={selected === item}/>
                                </TableCell>
                                <TableCell sx={{paddingX: "5px"}}>{item.cardCode}</TableCell>
                                <TableCell sx={{paddingX: "5px"}}>{item.cardName}</TableCell>
                                <TableCell sx={{paddingX: "5px"}}>{item.vehicleManufacturer}</TableCell>
                                <TableCell sx={{paddingX: "5px"}}>{item.vehicleType}</TableCell>
                                <TableCell sx={{paddingX: "5px"}}>{item.yoM}</TableCell>
                                <TableCell sx={{paddingX: "5px"}}>{item.manufacturerSn}</TableCell>
                                <TableCell sx={{paddingX: "5px"}}>{item.internalSn}</TableCell>
                            </TableRow>)
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default DeliveryNoteNewRowEquipmentTable;