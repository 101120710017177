import React from 'react';
import {EquipmentSearchStateProps} from "modules/equipment/equipment-search/EquipmentSearch";
import {useEquipmentSearchState} from "modules/equipment/equipment-search/useEquipmentSearchState";
import {Grid} from "@mui/material";
import LinearIndeterminateProgressWithLabel from "shared/components/progress/LinearIndeterminateProgressWithLabel";
import useTranslateFunction from "shared/language/useTranslateFunction";

export type EquipmentSearchInfQueryLoaderProps = EquipmentSearchStateProps & {}
const EquipmentSearchInfQueryLoader = ({searchStateAtom}: EquipmentSearchInfQueryLoaderProps) => {
    const tk = useTranslateFunction(`Equipment`)

    const {isLoading, isFetching, isFetchingNextPage} = useEquipmentSearchState(searchStateAtom)

    const displayLoader = isLoading || isFetching || isFetchingNextPage

    return (
        <Grid container direction="row" spacing="center" alignItems="center" sx={{
            height: "20px"
        }}>
            <Grid item xs={12}>
                {displayLoader && (
                    <LinearIndeterminateProgressWithLabel
                        label={tk('EquipmentsLoading')}
                    />
                )}
            </Grid>
        </Grid>
    );
};

export default EquipmentSearchInfQueryLoader;