import React from 'react';
import {useEquipmentSearchState} from "modules/equipment/equipment-search/useEquipmentSearchState";
import usePsgVehicleTypes from "crowbar-api/hooks/sap/usePsgVehicleTypes";
import {
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent
} from "@mui/material";
import {PsgVehicleTypeUtils} from "crowbar-api/util/PsgVehicleTypeUtils";
import {EquipmentSearchStateProps} from "modules/equipment/equipment-search/EquipmentSearch";
import {CDSMSapPsgVehicleType} from "crowbar-api";

export type VehicleTypeSelectProps = EquipmentSearchStateProps & {}
const VehicleTypeSelect = ({searchStateAtom}: VehicleTypeSelectProps) => {
    const {selectedManufacturer, selectedVehicleTypeKey, setState} = useEquipmentSearchState(searchStateAtom)

    const vehicleTypesQuery = usePsgVehicleTypes()

    const vehicleTypes = (vehicleTypesQuery.data?.vehicleTypes ?? [])
        .filter(vt => !!vt.code && !!vt.lineId && (!selectedManufacturer || vt.code === selectedManufacturer))
        .reduce((map, vt) => {
            const key = PsgVehicleTypeUtils.keyNullable(vt.code, vt.lineId)
            if (!key) return map
            map.set(key, vt)
            return map
        }, new Map<string, CDSMSapPsgVehicleType>())

    const label = "Vehicle type"

    if (vehicleTypesQuery.isLoading) {
        return (
            <CircularProgress/>
        )
    }

    if (!selectedManufacturer) {
        return <></>
    }

    const handleChange = (e: SelectChangeEvent) => {
        const newKey = e.target.value as string
        const found = vehicleTypes.get(newKey)
        if (!found) return
        setState(prev => {
            return {
                ...prev,
                selectedVehicleTypeKey: newKey,
                selectedVehicleType: found
            }
        })
    }

    return (
        <FormControl fullWidth size="small">
            <InputLabel id="vehicle-type-select-label" shrink={true}>{label}</InputLabel>
            <Select
                labelId="vehicle-type-select-label"
                value={selectedVehicleTypeKey ?? ""}
                label={label}
                onChange={handleChange}
                input={<OutlinedInput notched label={label}/>}
            >
                <MenuItem value={""}>&nbsp;</MenuItem>
                {Array.from(vehicleTypes, ([key, vt], i) => {
                        //const key = PsgVehicleTypeUtils.keyNullable(vt.code, vt.lineId)
                        if (!key) return <></>
                        return (
                            <MenuItem key={[vt.code, vt.lineId, i].join("-")}
                                      value={key}
                            >
                                {vt.name ?? ""}
                            </MenuItem>
                        )
                    }
                )}
            </Select>
        </FormControl>
    );
};

export default VehicleTypeSelect;