import React from 'react';
import {useSelectedServiceCall} from "modules/worksheet/editor/shared/data/useSelectedServiceCall";
import {useServiceCallAtom} from "modules/worksheet/editor/state/atoms/useServiceCallAtom";
import CallTypeSelectButtons from "modules/worksheet/editor/shared/component/call-type-select/CallTypeSelectButtons";
import {CDAMWMWorksheetCallTypeModel} from "crowbar-api";

export type WEditorCallTypeSelectProps = {
    disabled?: boolean
}
const WEditorCallTypeSelect = ({disabled}: WEditorCallTypeSelectProps) => {

    const selectedServiceCall = useSelectedServiceCall();
    const [, setServiceCall] = useServiceCallAtom(selectedServiceCall?.id)

    const onSelectedCallTypeChange = (ct: CDAMWMWorksheetCallTypeModel) => {
        const id = ct.id
        setServiceCall(prev => {
            return {
                ...prev,
                callTypeId: prev.callTypeId === id ? undefined : id
            }
        })
    }

    return (
        <CallTypeSelectButtons
            selectedCallTypeId={selectedServiceCall?.callTypeId}
            disabled={disabled}
            onChange={onSelectedCallTypeChange}
        />
    )
}

export default WEditorCallTypeSelect;