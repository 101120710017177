import {useMemo} from "react";
import {worksheetSaveStateAtom} from "modules/worksheet/editor/state/WorksheetSaveAtoms";
import {useAtom} from "jotai";
import {focusAtom} from "jotai-optics";

export const useModSapEquipments = () => {
    const focusedAtom = useMemo(() => {
        return focusAtom(worksheetSaveStateAtom, (optic) =>
            optic
                .optional()
                .prop('modSapEquipments')
        )
    }, [])

    return useAtom(focusedAtom)
}