import React from 'react';
import {FormControl, Grid, InputLabel, MenuItem, Select} from "@mui/material";
import useTranslateFunction from "shared/language/useTranslateFunction";
import useWorksheetSignatureState from "modules/worksheet/signature/useWorksheetSignatureState";

const WorksheetSignatureNavigation = () => {
    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    const {selectedWorksheetId, worksheetIdOptions, changeSelectedWorksheet} = useWorksheetSignatureState()

    return (
        <Grid container spacing={1}>
            <Grid item xs="auto">
                <FormControl fullWidth>
                    <InputLabel id="ws-sign-select-label">{tk('Worksheets')}</InputLabel>
                    <Select
                        labelId="ws-sign-select-label"
                        value={selectedWorksheetId}
                        label={tk('Worksheets')}
                        onChange={event => changeSelectedWorksheet(event.target.value)}
                    >
                        {worksheetIdOptions.map(o => (
                            <MenuItem key={o.id} value={o.id}>{o.serialId}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );
};

export default WorksheetSignatureNavigation;