import React from 'react';
import {useWorksheetEditorHeaderInfoAtom} from "modules/worksheet/editor/state/WorksheetEditorHeaderAtoms";
import {Alert, AlertProps, Link, Typography} from "@mui/material";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {
    useWorksheetClearAndLoadFromServer
} from "modules/worksheet/editor/shared/data/useWorksheetClearAndLoadFromServer";

export type WEditorDifferentVersionWarningProps = AlertProps & {}
const WEditorDifferentVersionWarning = ({...props}: WEditorDifferentVersionWarningProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    const [info] = useWorksheetEditorHeaderInfoAtom()
    const worksheetClearAndLoadFromServer = useWorksheetClearAndLoadFromServer()

    const onClearAndReload = async () => {
        await worksheetClearAndLoadFromServer()
    }

    if (!info.differentServerVersionFlag) {
        return <></>
    }

    return (
        <Alert severity="warning" {...props}>
            <Typography variant="body2">
                {tk('DifferentServerVersionWarningPrefix')}
                <Link component="button" variant="body2"
                      onClick={onClearAndReload}>{tk('DifferentServerVersionWarningLink')}</Link>
                {tk('DifferentServerVersionWarningSuffix')}
            </Typography>
        </Alert>
    );
};

export default WEditorDifferentVersionWarning;