import React from 'react'
import './DurationDisplay.scss'

export interface IDurationDisplayProps {
    /**
     * Time in milliseconds
     */
    duration?: number

    hideMillis?: boolean
}

export const DurationDisplay = (props: IDurationDisplayProps) => {
    const d = props.duration ?? 0
    const hours = Math.floor(d / 3600000) // 60 * 60 * 1000
    const divisorForMinutes = (d / 60000) % 60
    const minutes = Math.floor(divisorForMinutes)
    const divisorForSeconds = (d / 1000) % 60
    const seconds = Math.ceil(divisorForSeconds)
    const divisorForMillis = d % 1000
    const millis = Math.ceil(divisorForMillis)

    const millisString = (millis: number): string => {
        const m = millis.toString()
        return m.length <= 1 ? `00${m}` : m.length <= 2 ? `0${m}` : m
    }

    return (
        <div className="ct-microphone-recorder-duration">
            <p>
                {hours > 0 && (
                    <span>
                        <span className="ct-microphone-recorder-duration-hours">
                            {hours <= 9 ? `0${hours}` : hours}
                        </span>
                        <span className="ct-microphone-recorder-duration-divider">:</span>
                    </span>
                )}
                <span className="ct-microphone-recorder-duration-mins">
                  {minutes ? (minutes <= 9 ? `0${minutes}` : minutes) : '00'}
                </span>
                <span className="ct-microphone-recorder-duration-divider">:</span>
                <span className="ct-microphone-recorder-duration-secs">
                  {seconds ? (seconds <= 9 ? `0${seconds}` : seconds) : '00'}
                </span>
                {!props.hideMillis && (
                    <span className="ct-microphone-recorder-duration-milliseconds">
                  {millis ? millisString(millis) : '000'}
                </span>
                )}
            </p>
        </div>
    )
}
