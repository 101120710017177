import React from 'react';
import useWEditorStatusDrawerArgsAtom from "modules/worksheet/editor/state/atoms/useWEditorStatusDrawerArgsAtom";
import {Drawer} from "@mui/material";
import WEditorStatusDrawerEntries from "modules/worksheet/editor/ui/drawer/WEditorStatusDrawerEntries";

/*export interface WEditorStatusDrawerProps {

}*/

function WEditorStatusDrawer(/*{}: WEditorStatusDrawerProps*/) {

    const {open, setOpen} = useWEditorStatusDrawerArgsAtom()

    const onDrawerClose = () => {
        setOpen(false)
    }

    return (
        <>
            <Drawer
                variant="temporary"
                anchor="right"
                open={open}
                onClose={onDrawerClose}
            >
                <WEditorStatusDrawerEntries/>
            </Drawer>
        </>
    )
}

export default WEditorStatusDrawer;