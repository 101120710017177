export class DownloadFileUtils {
    public static downloadAsJson = (obj: any, filename?: string, fileType?: string) => {
        const json = JSON.stringify(obj, null, 2)
        const type = fileType ?? "application/json"
        const name = (filename ?? "data.json").replace(/\W^./gi, '_')
        this.downloader(json, name, type)
    }

    private static downloadURI = (uri: string, name: string) => {
        let link = document.createElement("a")
        link.download = name
        link.href = uri
        link.click()
    }

    private static downloader = (data: string, name: string, type: string) => {
        const blob = new Blob([data], {type})
        const url = window.URL.createObjectURL(blob)
        this.downloadURI(url, name)
        window.URL.revokeObjectURL(url)
    }
}