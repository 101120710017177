import {CDAMWMServiceCallItemRowStatus, CDAMWMServiceCallItemRowWarningType} from "crowbar-api";

export default class ServiceCallItemRowWarningTypeMap {
    public static readonly none = CDAMWMServiceCallItemRowWarningType.NUMBER_0
    public static readonly includedInDifferentWorksheet = CDAMWMServiceCallItemRowWarningType.NUMBER_1
    public static readonly tooManyInstalledQuantity = CDAMWMServiceCallItemRowWarningType.NUMBER_2
    public static readonly remainingInstalledQuantity = CDAMWMServiceCallItemRowWarningType.NUMBER_3

    public static translationKey = (t?: CDAMWMServiceCallItemRowStatus): string => {

        const preKey = "Worksheet.Generic.ServiceCallItemRowWarningType."

        if (t === this.none) {
            return `${preKey}None`
        }

        if (t === this.includedInDifferentWorksheet) {
            return `${preKey}IncludedInDifferentWorksheet`
        }

        if (t === this.tooManyInstalledQuantity) {
            return `${preKey}TooManyInstalledQuantity`
        }

        if (t === this.remainingInstalledQuantity) {
            return `${preKey}RemainingInstalledQuantity`
        }

        return "Missing"
    }
}