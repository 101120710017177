import {useMutation} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {CDAMWMEDeliveryAckServiceCallEvalUpdate, ServiceCallEvalApi} from "crowbar-api";
import {AxiosRequestConfig} from "axios";
import {sapDeliveryExistsOrDraft} from "crowbar-api/hooks/sap/useSapDeliveryExistsOrDraft";

export const useServiceCallEvalUpdateDeliveryAck = () => {
    return useMutation(async (p: CDAMWMEDeliveryAckServiceCallEvalUpdate, options?: AxiosRequestConfig) => {
        await crowbarApiFactory(ServiceCallEvalApi)
            .updateDeliveryAck(p, options)
    })
}

export type UseServiceCallEvalUpdateDeliveryAckByDocNumParams = {
    'originalId': string
    'evalId': string
    'deliveryAck': boolean
    deliveryDocNum: number
}

export type UseServiceCallEvalUpdateDeliveryAckByDocNumResult = {
    success?: boolean
    errorId?: number
}

export const useServiceCallEvalUpdateDeliveryAckByDocNum = () => {
    return useMutation(async (p: UseServiceCallEvalUpdateDeliveryAckByDocNumParams, options?: AxiosRequestConfig): Promise<UseServiceCallEvalUpdateDeliveryAckByDocNumResult> => {

        let selectedDeliveryDocEntry: number | null = null

        // Check the delivery num in a separate request now!
        // but always allow to change the ack to false, only error out if the ack is true
        const deliveryExistsResult = await sapDeliveryExistsOrDraft({
            deliveryDocNum: p.deliveryDocNum
        })
        const match = deliveryExistsResult.find(dr => !!dr.deliveryDocNum && dr.deliveryDocNum === p.deliveryDocNum)
        if (p.deliveryAck && !match) {
            return {
                success: false,
                errorId: 1
            }
        }
        selectedDeliveryDocEntry = match?.deliveryDocEntry ?? null


        await crowbarApiFactory(ServiceCallEvalApi)
            .updateDeliveryAck({
                deliveryAck: p.deliveryAck,
                evalId: p.evalId,
                originalId: p.originalId,
                selectedDeliveryDocEntry: selectedDeliveryDocEntry
            }, options)

        return {
            success: true
        }
    })
}