import React from 'react';
import {Card, CardContent, Grid} from "@mui/material";
import SearchBoxAndDebounce from "shared/components/inputs/SearchBoxAndDebounce";
import {EmailNotificationProps} from "modules/admin/worksheet/notifications/email-notifications/EmailNotificationProps";
import {
    useEmailNotificationListingState
} from "modules/admin/worksheet/notifications/email-notifications/state/useEmailNotificationListingState";
import EmailNotificationDateRangeFilter
    from "modules/admin/worksheet/notifications/email-notifications/filters/EmailNotificationDateRangeFilter";
import {
    EmailNotificationSourceSelect
} from "modules/admin/worksheet/notifications/email-notifications/filters/EmailNotificationSourceSelect";
import {
    EmailNotificationStatusSelect
} from "modules/admin/worksheet/notifications/email-notifications/filters/EmailNotificationStatusSelect";

export type EmailNotificationFiltersProps = EmailNotificationProps & {}

const EmailNotificationFilters = ({stateAtom}: EmailNotificationFiltersProps) => {
    const {search, setState} = useEmailNotificationListingState(stateAtom)

    const onSearchValueChange = (newValue: string) => {
        setState(prev => {
            return {
                ...prev,
                search: newValue,
                lastRowNumber: 0
            }
        })
    }

    return (
        <Card
            variant="outlined"
        >
            <CardContent>
                <Grid container spacing={2}>

                    <Grid item xs={12}>
                        <SearchBoxAndDebounce
                            value={search ?? ""}
                            onChange={onSearchValueChange}
                            size="small"
                        />
                    </Grid>

                    <Grid
                        item
                        xs={2}
                        sx={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "center"
                        }}
                    >
                        <EmailNotificationSourceSelect
                            stateAtom={stateAtom}
                        />
                    </Grid>

                    <Grid
                        item
                        xs={2}
                        sx={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "center"
                        }}
                    >
                        <EmailNotificationStatusSelect
                            stateAtom={stateAtom}
                        />
                    </Grid>

                    <Grid
                        item
                        xs="auto"
                        sx={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "center"
                        }}
                    >
                        <EmailNotificationDateRangeFilter
                            stateAtom={stateAtom}
                        />
                    </Grid>

                </Grid>
            </CardContent>
        </Card>
    )
}

export default EmailNotificationFilters;