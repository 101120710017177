import React, {Component} from 'react';
import {WithTranslation, withTranslation} from "react-i18next";
import {pages as TeamsPages} from "@microsoft/teams-js";
import {Button, Container, FormControl, InputLabel, MenuItem, Select} from '@mui/material';

interface ISelectModuleOption {
    key: string
    text: string
}

interface IProps extends WithTranslation {
}

interface IState {
    selectedOptionKey?: string;
    options: ISelectModuleOption[];
}

class TabConfig extends Component<IProps, IState> {

    ServiceIssueReportKey = 'service-issue-report';
    DeliveryNoteKey = 'delivery-note';

    componentDidMount() {
        const {t} = this.props;
        this.setState({
            selectedOptionKey: "",
            options: [
                {key: '', text: ''},
                {key: this.ServiceIssueReportKey, text: t('Home.TabConfig.ServiceIssueReport')},
                {key: this.DeliveryNoteKey, text: t('Home.TabConfig.DeliveryNote')}
            ]
        });
        this.onSelectButton.bind(this);
    }

    private onSelectButton() {
        const {t} = this.props;
        TeamsPages.config.setValidityState(true);
        TeamsPages.config.registerOnSaveHandler((saveEvent) => {
            if (this.state.selectedOptionKey === this.ServiceIssueReportKey) {
                TeamsPages.config.setConfig({
                    websiteUrl: window.location.origin,
                    contentUrl: window.location.origin + '/#/service-issue-reports',
                    entityId: 'service-issue-report',
                    suggestedDisplayName: t('Home.TabConfig.ServiceIssueReport')
                }).then(() => {
                    saveEvent.notifySuccess();
                });
            } else if (this.state.selectedOptionKey === this.DeliveryNoteKey) {
                TeamsPages.config.setConfig({
                    websiteUrl: window.location.origin,
                    contentUrl: window.location.origin + '/#/delivery-notes',
                    entityId: 'delivery-note',
                    suggestedDisplayName: t('Home.TabConfig.DeliveryNote')
                }).then(() => {
                    saveEvent.notifySuccess();
                })
            } else {
                saveEvent.notifyFailure('Unknown type.');
            }
        })
    }

    render() {
        const {t} = this.props;
        return (
            <Container>
                <FormControl fullWidth>
                    <InputLabel id="ct-select-module">{t('Home.TabConfig.Module')}</InputLabel>
                    <Select
                        labelId="ct-select-module"
                        value={this.state?.selectedOptionKey ?? ""}
                        label={t('Home.TabConfig.Module')}
                        onChange={(event) => {
                            this.setState({
                                selectedOptionKey: event.target.value?.toString() ?? ""
                            })
                        }}
                    >
                        {this.state?.options?.map(o => (
                            <MenuItem key={o.key} value={o.key}>{o.text}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Button variant="contained" color="primary" onClick={() => this.onSelectButton()}>
                    {t('Home.TabConfig.Select')}
                </Button>
            </Container>
        );
    }
}

export default withTranslation()(TabConfig);