import {useMutation} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {CDAMWMESingleFieldWorksheetEvalUpdate1Boolean, ServiceCallEvalApi} from "crowbar-api";
import {AxiosRequestConfig} from "axios";

export const useServiceCallEvalUpdateEquipmentAck = () => {
    return useMutation(async (u: CDAMWMESingleFieldWorksheetEvalUpdate1Boolean, options?: AxiosRequestConfig) => {
        await crowbarApiFactory(ServiceCallEvalApi)
            .updateEquipmentAck(u, options)
    })
}