import React from 'react';
import {
    Checkbox,
    LinearProgress,
    Paper,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import {useTranslation} from "react-i18next";
import {CDSMSapItem} from "crowbar-api";

export interface DeliveryNoteNewRowItemTableProps {
    items: CDSMSapItem[]
    selected?: CDSMSapItem
    onSelectClick: (item: CDSMSapItem) => void
    isLoading: boolean
}

const DeliveryNoteNewRowItemTable = ({items, selected, onSelectClick, isLoading}: DeliveryNoteNewRowItemTableProps) => {
    const {t} = useTranslation()
    const TKey = "DeliveryNote.Note.Dialog.";

    return (
        <TableContainer component={Paper}>
            {isLoading ? <LinearProgress variant={"query"}/> : <LinearProgress variant={"determinate"} value={0}/>}
            <Table padding={"none"} sx={{minWidth: "700px", tableLayout: "fixed", overflowWrap: "break-word"}}>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{width: "6%", paddingX: "5px"}}></TableCell>
                        <TableCell sx={{minWidth: "8%", width: "8%", paddingX: "5px"}}
                                   key={"itemCode"}>{t(`${TKey}ColItemCode`)}</TableCell>
                        <TableCell sx={{width: "40%", paddingX: "5px"}}
                                   key={"itemName"}>{t(`${TKey}ColItemName`)}</TableCell>
                        <TableCell sx={{paddingX: "5px"}}
                                   key={"suppCatNum"}>{t(`${TKey}ColSww`)}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {isLoading ?
                        [...Array(10)].map((_, i) => {
                            return (
                                <TableRow key={i}>
                                    <TableCell align={"center"} sx={{height: "40px"}}>
                                        <Checkbox disabled/>
                                    </TableCell>
                                    <TableCell colSpan={3} sx={{paddingRight: 5}}>
                                        <Skeleton animation="wave" height={35}/>
                                    </TableCell>
                                </TableRow>)
                        })
                        :
                        items.map((item) => {
                            return (<TableRow
                                hover
                                selected={selected === item}
                                key={item.itemCode}
                                sx={{'&:last-child td, &:last-child th': {border: 0}, ':hover': {cursor: "pointer"}}}
                                onClick={() => onSelectClick(item)}
                            >
                                <TableCell align={"center"} sx={{height: "40px"}}>
                                    <Checkbox checked={selected === item}/>
                                </TableCell>
                                <TableCell sx={{paddingX: "5px"}}>{item.itemCode}</TableCell>
                                <TableCell sx={{paddingX: "5px"}}>{item.itemName}</TableCell>
                                <TableCell sx={{paddingX: "5px"}}>{item.sww}</TableCell>
                            </TableRow>)
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default DeliveryNoteNewRowItemTable;