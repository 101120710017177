import {Fab} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import {useNewAtomWithRef} from "shared/hook/useNewatomWithRef";
import {v4 as uuid} from 'uuid'
import AddItemDialog from "modules/worksheet/editor/ui/catalogue/add-item-dialog/AddItemDialog";

import {CDSMSSapItemForWorksheetSearchQuery} from "crowbar-api";
import {WorksheetSaveFactory} from "modules/worksheet/storage/WorksheetSaveFactory";
import ServiceCallItemRowSelectedSourceMap from "crowbar-api/enum-map/ServiceCallItemRowSelectedSourceMap";
import {toastWarn} from "shared/toast/DefaultToasts";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {
    useSelectedServiceCallItemRowsAtom
} from "modules/worksheet/editor/state/atoms/useSelectedServiceCallItemRowsAtom";
import {useSelectedServiceCall} from "modules/worksheet/editor/shared/data/useSelectedServiceCall";
import {
    useServiceCallItemRowsAndTextLines
} from "modules/worksheet/editor/state/atoms/useServiceCallItemRowsAndTextLines";

interface AddItemFabProps {

}

const AddItemFab = ({}: AddItemFabProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    const sc = useSelectedServiceCall()
    const scId = sc?.id
    const [itemRows, setServiceCallItemRows] = useSelectedServiceCallItemRowsAtom()
    const allRows = useServiceCallItemRowsAndTextLines()

    const [open, setOpen] = useNewAtomWithRef(false)
    const [dialogKey, setDialogKey] = useNewAtomWithRef("")

    const calcLastVisOrder = () => {
        return allRows?.length && allRows.length > 0 ? allRows[allRows.length - 1].visOrder ?? 0 : 0
    }

    const openDialog = () => {
        setDialogKey(uuid())
        setOpen(true)
    }

    const closeDialog = () => {
        setOpen(false)
    }

    const onItemSelected = (item: CDSMSSapItemForWorksheetSearchQuery) => {
        // check if the item code is already added
        const foundItemRow = itemRows?.find(i => i.itemCode === item.itemCode)
        if (foundItemRow) {
            toastWarn(tk('ItemAlreadyAdded'))
            return
        }

        setServiceCallItemRows(prev => {
            const lastIndex = calcLastVisOrder()
            return [
                ...prev ?? [],
                {
                    ...WorksheetSaveFactory.newServiceCallItemRowSave(scId),
                    rowSelectedSource: ServiceCallItemRowSelectedSourceMap.sapMasterData,
                    sourceCoverImage: item.coverImage,
                    itemCode: item.itemCode,
                    itemName: item.itemName,
                    lineNum: -1, // no source line num, added from master data
                    visOrder: lastIndex + 1,
                    unitOfQuantity: item.buyUnitMsr
                }
            ]
        })

        closeDialog()
    }

    const onManuallyEntered = (itemName: string, unitOfMeasure: string) => {

        setServiceCallItemRows(prev => {
            const lastIndex = calcLastVisOrder()
            return [
                ...prev ?? [],
                {
                    ...WorksheetSaveFactory.newServiceCallItemRowSave(scId),
                    rowSelectedSource: ServiceCallItemRowSelectedSourceMap.manuallyEntered,
                    sourceCoverImage: undefined,
                    itemCode: undefined,
                    itemName: itemName,
                    lineNum: -1, // no source line num, added manually
                    visOrder: lastIndex + 1,
                    unitOfQuantity: unitOfMeasure
                }
            ]
        })

        closeDialog()
    }

    return (
        <>
            <Fab
                sx={{
                    position: "fixed",
                    bottom: "32px",
                    right: "32px"
                }}
                aria-label={tk('Add')}
                //color={}
                onClick={openDialog}
            >
                <AddIcon/>
            </Fab>

            <AddItemDialog
                key={dialogKey}
                open={open}
                onClose={closeDialog}
                onItemSelected={onItemSelected}
                onManuallyEntered={onManuallyEntered}
            />
        </>
    )
}

export default AddItemFab