import React from 'react';
import {
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack
} from "@mui/material";
import useTranslateFunction from "shared/language/useTranslateFunction";
import useSapEmployees from "crowbar-api/hooks/sap/useSapEmployees";
import useGraphApiFindAllUserWithMechanicRole from "crowbar-api/hooks/mechanic/useGraphApiFindAllUserWithMechanicRole";
import useSaveMechanic from "crowbar-api/hooks/mechanic/useSaveMechanic";
import {useNewAtomWithRef} from "shared/hook/useNewatomWithRef";
import {toastError} from "shared/toast/DefaultToasts";
import {CDAMCMMechanicModel} from "crowbar-api";
import useExplicitRegisterAzureUserToApplication
    from "crowbar-api/hooks/user/useExplicitRegisterAzureUserToApplication";
import ConfirmDialogButton from "shared/components/buttons/ConfirmDialogButton";
import {useDeleteMechanic} from "crowbar-api/hooks/mechanic/useDeleteMechanic";

export interface MechanicAdminDialogProps {
    mode: 'new' | 'edit'
    editMechanic?: CDAMCMMechanicModel | undefined
    open: boolean
    onCancel: () => void
    onSubmit: () => void
}

const MechanicAdminDialog = ({mode, editMechanic, open, onCancel, onSubmit}: MechanicAdminDialogProps) => {
    const tk = useTranslateFunction("Admin.Mechanic")

    const [selectedAzureId, setSelectedAzureId] = useNewAtomWithRef<string | undefined>(editMechanic?.userId ?? undefined)
    const [selectedSapEmployee, setSelectedSapEmployee] = useNewAtomWithRef<string | undefined>(editMechanic?.sapEmployeeId?.toString() ?? undefined)
    const [active, setActive] = useNewAtomWithRef<boolean>(editMechanic?.active ?? true)

    const rolesQuery = useGraphApiFindAllUserWithMechanicRole()
    const sapEmployeesQuery = useSapEmployees()
    const saveMechanic = useSaveMechanic()
    const deleteMechanic = useDeleteMechanic()
    const registerUser = useExplicitRegisterAzureUserToApplication()

    const dataLoaded = !sapEmployeesQuery.isLoading && !rolesQuery.isLoading

    const handleAzureIdChange = (event: SelectChangeEvent) => {
        setSelectedAzureId(event.target.value as string);
    }

    const handleSapEmployeeChange = (event: SelectChangeEvent) => {
        setSelectedSapEmployee(event.target.value as string)
    }

    const validateCurrentValues = (): boolean => {
        if (!selectedAzureId || !selectedSapEmployee) {
            toastError('')
            return false
        }

        if (isNaN(+selectedSapEmployee)) {
            toastError('Invalid SAP employee number: ' + (+selectedSapEmployee))
            return false
        }

        return true
    }

    const onSave = async () => {
        if (!selectedAzureId || !selectedSapEmployee) {
            toastError('Must select both azure and sap identity!')
            return false
        }

        if (!validateCurrentValues()) return

        const azureUser = rolesQuery?.data?.find(u => u.id === selectedAzureId)
        if (!azureUser) {
            toastError('Failed to find azure user by selection.')
            return false
        }

        const userObj = await registerUser.mutateAsync(selectedAzureId)

        if (!userObj?.user?.id) {
            toastError('User id is missing after explicit register: ' + userObj.user?.id)
            return
        }

        const newMechanic: CDAMCMMechanicModel = {
            active: active,
            userId: userObj.user.id,
            azureOid: selectedAzureId,
            sapEmployeeId: +selectedSapEmployee,
            fullName: azureUser.fullName,
            firstname: azureUser.givenName,
            lastname: azureUser.surname,
            email: azureUser.email
        }
        await saveMechanic.mutateAsync(newMechanic)

        onSubmit()
    }

    const onDeleteClick = async () => {
        if (!selectedAzureId) {
            toastError('No selected azure user.')
            return
        }
        await deleteMechanic.mutateAsync(selectedAzureId)
        onSubmit()
    }

    return (
        <Dialog
            open={open}
            onClose={onCancel}
            fullWidth={true}
        >
            <DialogTitle>{tk('SelectIds')}</DialogTitle>
            <DialogContent>
                {!dataLoaded ? (
                    <CircularProgress/>
                ) : (
                    <Grid container spacing={1} sx={{p: 1}}>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel>{tk('AzureUser')}</InputLabel>
                                <Select
                                    value={selectedAzureId ?? ""}
                                    label={tk('AzureUser')}
                                    onChange={handleAzureIdChange}
                                    disabled={mode === 'edit'}
                                >
                                    {(rolesQuery.data ?? []).map(r => (
                                        <MenuItem key={r.id} value={r.id}>{r.fullName} ({r.id})</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <p>{tk('AzureSelectDesc')}</p>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel>{tk('SapEmployee')}</InputLabel>
                                <Select
                                    value={selectedSapEmployee ?? ""}
                                    label={tk('SapEmployee')}
                                    onChange={handleSapEmployeeChange}
                                >
                                    {(sapEmployeesQuery.data ?? []).map(e => (
                                        <MenuItem key={e.empId}
                                                  value={e.empId}>{e.lastName} {e.middleName} {e.firstName} ({e.empId})</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {mode === "edit" && (
                            <Grid item xs={12}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={(
                                            <Checkbox
                                                checked={active}
                                                onChange={e => setActive(e.target.checked)}
                                            />
                                        )}
                                        label={tk("Active")}
                                    />
                                </FormGroup>
                            </Grid>
                        )}
                    </Grid>
                )}
            </DialogContent>
            <DialogActions sx={{
                display: "flex",
                justifyContent: "space-between"
            }}>
                <Stack direction="row" spacing={1}>
                    <Button onClick={onCancel}>{tk('Cancel')}</Button>
                    {mode === "edit" && (
                        <ConfirmDialogButton
                            buttonText={tk('Delete')}
                            onConfirm={onDeleteClick}
                        />
                    )}
                </Stack>

                <Button
                    onClick={onSave}
                    disabled={
                        !dataLoaded || !selectedAzureId || !selectedSapEmployee ||
                        deleteMechanic.isLoading || saveMechanic.isLoading
                    }
                >
                    {(deleteMechanic.isLoading || saveMechanic.isLoading) && (
                        <CircularProgress/>
                    )}

                    {tk('Save')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default MechanicAdminDialog;