import React from 'react';
import useTranslateFunction from "shared/language/useTranslateFunction";
import {Grid, Typography} from "@mui/material";

import ErrorIcon from '@mui/icons-material/Error';

export type GenericErrorFallbackProps = {
    error: Error;
    componentStack: string | null;
    eventId: string | null;
    resetError(): void;
}

const GenericErrorFallback = ({error, componentStack, eventId, resetError}: GenericErrorFallbackProps) => {
    const tk = useTranslateFunction('Generic')
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant="h4">
                    <ErrorIcon color="error" fontSize="large"/>&nbsp;{tk('Error')}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="caption">
                    EventId: {eventId}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1">
                    {tk('GenericErrorText')}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1">
                    {tk('GenericErrorText2')}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <pre>
                    {JSON.stringify(error, null, 2)}
                </pre>
            </Grid>
        </Grid>
    );
};

export default GenericErrorFallback;