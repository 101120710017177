import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    Stack,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from "@mui/material";
import React from "react";
import useAddItemDialogPagingAtom
    from "modules/worksheet/editor/ui/catalogue/add-item-dialog/useAddItemDialogPagingAtom";
import AddItemFilters from "modules/worksheet/editor/ui/catalogue/add-item-dialog/AddItemFilters";
import AddItemDialogItems from "modules/worksheet/editor/ui/catalogue/add-item-dialog/AddItemDialogItems";
import {CDSMSSapItemForWorksheetSearchQuery} from "crowbar-api";
import ViewListIcon from '@mui/icons-material/ViewList';
import GridViewIcon from '@mui/icons-material/GridView';
import BasicCenteredPagingButtons from "shared/components/generic-paging/BasicCenteredPagingButtons";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {StringUtils} from "shared/utils/StringUtils";

interface AddItemDialogProps {
    open: boolean
    onItemSelected: (item: CDSMSSapItemForWorksheetSearchQuery) => void
    onManuallyEntered: (itemName: string, unitOfMeasure: string) => void
    onClose: () => void
}

const AddItemDialog = ({open, onItemSelected, onManuallyEntered, onClose}: AddItemDialogProps) => {

    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    const {
        itemQueryStatus,
        items,
        nextPage,
        previousPage,
        setSelected,
        selectedItemCode,
        manuallyTypedItemName,
        setManuallyTypedItemName,
        manuallyTypedUnitOfMeasure,
        setManuallyTypedUnitOfMeasure,
        hasNextPage,
        hasPreviousPage,
        queryParams,
        setQueryParams,
        manuallySetQueryParams,
        viewMode,
        setViewMode,
        source,
        setSource
    } = useAddItemDialogPagingAtom()

    const allowSubmit = (source === "sap" && selectedItemCode !== undefined) ||
        (source === "empty" && StringUtils.notUndefinedNullAndEmpty(manuallyTypedItemName))

    const onDialogSubmit = () => {
        if (source === "sap" && selectedItemCode) {
            const selected = items.find(i => i.itemCode === selectedItemCode)
            if (selected) {
                onItemSelected(selected)
            }
        } else if (source === "empty" && manuallyTypedItemName) {
            onManuallyEntered(manuallyTypedItemName, manuallyTypedUnitOfMeasure)
        }
    }

    const onViewModeChange = (e: any, value: 'list' | 'grid') => {
        if (value) {
            setViewMode(value)
        }
    }

    const onSourceChange = (e: any, value: string) => {
        setSource(value === "sap" ? "sap" : "empty")
    }

    return (
        <Dialog open={open} onClose={() => {/* no op */
        }} fullWidth maxWidth={"xl"}>
            <DialogContent>

                <Stack direction="column" spacing={1}>

                    <Stack direction={"row"} spacing={2} alignItems="center">
                        <FormLabel>{tk('Source')}:</FormLabel>
                        <RadioGroup row
                                    value={source}
                                    onChange={onSourceChange}
                        >
                            <FormControlLabel value="sap" control={<Radio/>} label={tk('Items')}/>
                            <FormControlLabel value="empty" control={<Radio/>} label={tk('ManuallyTyped')}/>
                        </RadioGroup>
                    </Stack>

                    {source === "sap" && (
                        <Stack direction="column" spacing={1}>
                            <AddItemFilters
                                queryParams={queryParams}
                                setQueryParams={setQueryParams}
                                setSelected={setSelected}
                                manuallySetQueryParams={manuallySetQueryParams}
                            />
                            <Stack
                                direction="column"
                                spacing={1}
                                sx={{
                                    minHeight: "50vh",
                                    paddingTop: 1
                                }}>
                                {itemQueryStatus === "loading" && (
                                    <Box sx={{
                                        width: 1,
                                        display: "flex",
                                        justifyContent: "center"
                                    }}>
                                        <CircularProgress/>
                                    </Box>
                                )}

                                {itemQueryStatus !== "loading" && (
                                    <Box
                                        sx={{
                                            width: 1,
                                            display: "flex",
                                            justifyContent: "right"
                                        }}
                                    >
                                        <ToggleButtonGroup
                                            orientation="horizontal"
                                            value={viewMode}
                                            exclusive

                                            onChange={onViewModeChange}
                                        >
                                            <ToggleButton value="list" aria-label="list">
                                                <ViewListIcon/>
                                            </ToggleButton>
                                            <ToggleButton value="grid" aria-label="grid">
                                                <GridViewIcon/>
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </Box>
                                )}

                                <AddItemDialogItems

                                    items={items}
                                    selectedItemCode={selectedItemCode}
                                    setSelected={setSelected}
                                    viewMode={viewMode}
                                />
                            </Stack>

                            <BasicCenteredPagingButtons
                                isLoading={itemQueryStatus !== "loading"}
                                hasPreviousPage={hasPreviousPage}
                                hasNextPage={hasNextPage}
                                onPreviousClick={() => {
                                    setSelected(undefined)
                                    previousPage()
                                }}
                                onNextClick={() => {
                                    setSelected(undefined)
                                    nextPage()
                                }}
                            />
                        </Stack>
                    )}

                    {source === "empty" && (
                        <Stack direction="column" spacing={1}>
                            <Typography fontWeight="bold">
                                {tk('ManuallyTypedLabel')}
                            </Typography>
                            <TextField
                                fullWidth
                                value={manuallyTypedItemName ?? ""}
                                InputProps={{
                                    autoComplete: "off"
                                }}
                                onChange={(e) => setManuallyTypedItemName(e.target.value.substring(0, 100))}
                            />
                            <Typography fontWeight="bold">
                                {tk('ManuallyTypedUnitMsr')}
                            </Typography>
                            <TextField
                                value={manuallyTypedUnitOfMeasure ?? ""}
                                onChange={(e) => setManuallyTypedUnitOfMeasure(e.target.value.substring(0, 10))}
                                InputProps={{
                                    autoComplete: "off",
                                    sx: {
                                        maxWidth: "150px"
                                    }
                                }}
                            />
                        </Stack>
                    )}

                </Stack>
            </DialogContent>
            <DialogActions>
                <Button
                    color="error"
                    variant="outlined"
                    onClick={() => onClose()}
                >
                    {tk('Cancel')}
                </Button>
                <div style={{flex: "1 0 0"}}/>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => onDialogSubmit()}
                    disabled={!allowSubmit}
                >
                    {tk('Add')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddItemDialog