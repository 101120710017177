import React from 'react';
import {SvgIcon, SvgIconProps} from "@mui/material";

export type EditIconProps = SvgIconProps & {}

const EditIcon = ({...props}: EditIconProps) => {
    return (
        <SvgIcon {...props}>
            <path d="M18 10L21 7L17 3L14 6M18 10L8 20H4V16L14 6M18 10L14 6"></path>
            <path d="M18 10L21 7L17 3L14 6M18 10L8 20H4V16L14 6M18 10L14 6"></path>
        </SvgIcon>
    )
        ;
};

export default EditIcon;