import React from 'react';
import {
    MechanicListingState,
    MechanicListingStateProps,
    useMechanicListingState
} from "modules/worksheet/mechanic/state/useMechanicListingState";
import {Button, Stack} from "@mui/material";
import useTranslateFunction from "shared/language/useTranslateFunction";
import MechanicWorksheetInfQueryLoader from "modules/worksheet/mechanic/results/MechanicWorksheetInfQueryLoader";
import MechanicWorksheetResultList from "modules/worksheet/mechanic/results/MechanicWorksheetResultList";
import {PrimitiveAtom} from "jotai";

export type MechanicWorksheetResultsProps = MechanicListingStateProps & {
    mechanicStateAtom: PrimitiveAtom<MechanicListingState>
}
const MechanicWorksheetResults = ({mechanicStateAtom}: MechanicWorksheetResultsProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.Mechanic`)

    const {
        worksheetInfQuery,
        worksheetVersions,
        anyHasNextPage,
        isLoading,
        isFetching,
        isFetchingNextPage
    } = useMechanicListingState(mechanicStateAtom)

    const loadMore = async () => {
        // parallel execution
        await Promise.all([
            worksheetInfQuery.fetchNextPage(),
        ])
    }

    return (
        <Stack
            direction="column"
            spacing={3}
            width={1}
            alignItems="center"
            justifyContent="center"
            sx={{
                mb: 4
            }}
        >

            <MechanicWorksheetResultList mechanicStateAtom={mechanicStateAtom}/>

            <MechanicWorksheetInfQueryLoader mechanicStateAtom={mechanicStateAtom}/>

            <Button
                variant="contained"
                color="primary"
                onClick={() => loadMore()}
                disabled={isLoading || isFetching || isFetchingNextPage || !anyHasNextPage}
            >
                {tk('LoadMore')}
            </Button>
        </Stack>
    );
};

export default MechanicWorksheetResults;