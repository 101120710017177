import {useUserContext} from "shared/hook/UserContext";
import {useMemo} from "react";
import {Roles} from "shared/authorization/Roles";

export type UseUserRolesResult = {
    // generic
    mechanic: boolean
    administrator: boolean

    // Admin
    // specific
    canEvaluateWorksheets: boolean
    /**
     * Allows to change the worksheet state on admin and
     * allows to set the isDeleted flag
     */
    canChangeWorksheetStates: boolean
}
export const useUserRoles = (): UseUserRolesResult => {
    const [, claims] = useUserContext()

    return useMemo(() => {
        return {
            mechanic: claims.includes(Roles.Mechanic),
            administrator: claims.includes(Roles.Administrator),

            canEvaluateWorksheets: claims.includes(Roles.CanEvaluateWorksheets),
            canChangeWorksheetStates: claims.includes(Roles.CanChangeWorksheetStates)
        }
    }, [claims])
}