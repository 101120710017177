import {useWorksheetSaveState} from "modules/worksheet/editor/state/WorksheetSaveAtoms";
import {useIsTimeMatchFn} from "crowbar-api/hooks/worksheet/validations/useIsTimeMatchFn";

export const useHasAnyTimeMatchRowsValidation = () => {
    const [ws] = useWorksheetSaveState()
    const isTimeMatch = useIsTimeMatchFn()

    const serviceCalls = (ws?.serviceCalls ?? [])
    const allItemRows = serviceCalls.flatMap(sc => sc.itemRows ?? [])
    return allItemRows.find(ir => isTimeMatch(ir)) !== undefined
}