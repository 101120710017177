import React from 'react';
import {useNewWorksheetState} from "modules/worksheet/new-worksheet/useNewWorksheetState";
import {
    Card,
    CardContent,
    Checkbox,
    Collapse,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    Stack,
    Typography
} from "@mui/material";
import SearchBoxAndDebounce from "shared/components/inputs/SearchBoxAndDebounce";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {StringUtils} from "shared/utils/StringUtils";
import NativeDateInput from "shared/components/inputs/NativeDateInput";
import {DateUtils} from "shared/utils/DateUtils";

import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import SliderAndDebounce from "shared/components/inputs/SliderAndDebounce";
import ExpandButton from "shared/components/buttons/ExpandButton";

const NewWorksheetFilters = () => {
    const tk = useTranslateFunction(`Worksheet.Generic.NewWorksheet`)

    const {state, setState, resetState} = useNewWorksheetState()

    const currentSearchInput = state.searchValue?.trim() ?? ""
    const currentSearchInputLength = StringUtils.lengthNotCountingCharsLike(currentSearchInput)
    const searchIsInvalid = currentSearchInputLength > 0 && currentSearchInputLength < 3

    const soFrom = state.salesOrderSearch?.salesOrderFrom
    const soFromDate = DateUtils.parseServerUTCToDate(soFrom)
    const soFromIntl = soFromDate ? DateUtils.intlDateToFormat(soFromDate, "fr-CA") : undefined
    const soTo = state.salesOrderSearch?.salesOrderTo
    const soToDate = DateUtils.parseServerUTCToDate(soTo)
    const soToIntl = soToDate ? DateUtils.intlDateToFormat(soToDate, "fr-CA") : undefined

    const completeMin = state.salesOrderSearch?.completionRateMin ?? undefined
    const completeMax = state.salesOrderSearch?.completionRateMax ?? undefined

    const onSearchChange = (newValue: string) => {
        setState(prev => {
            return {
                ...prev,
                searchValue: newValue
            }
        })
    }

    const onResetFilterClick = () => {
        resetState()
    }

    const setSearchCustomers = (newValue: boolean) => {
        setState(prev => {
            return {
                ...prev,
                searchCustomers: newValue
            }
        })
    }

    const setSearchSalesOrders = (newValue: boolean) => {
        setState(prev => {
            return {
                ...prev,
                searchSalesOrders: newValue
            }
        })
    }

    const setSearchEquipments = (newValue: boolean) => {
        setState(prev => {
            return {
                ...prev,
                searchEquipments: newValue
            }
        })
    }

    const setIncludeClosedSalesOrders = (newValue: boolean) => {
        setState(prev => {
            return {
                ...prev,
                salesOrderSearch: {
                    ...prev.salesOrderSearch,
                    includeClosed: newValue
                }
            }
        })
    }

    const onSoFromChange = (newSoFrom: string) => {
        setState(prev => {
            return {
                ...prev,
                salesOrderSearch: {
                    ...prev.salesOrderSearch,
                    salesOrderFrom: newSoFrom
                }
            }
        })
    }

    const onSoToChange = (newSoTo: string) => {
        setState(prev => {
            return {
                ...prev,
                salesOrderSearch: {
                    ...prev.salesOrderSearch,
                    salesOrderTo: newSoTo
                }
            }
        })
    }

    const onCompleteSliderChange = (e: Event, newValue: number | number[]) => {
        const [newMin, newMax] = newValue as number[]
        setState(prev => {
            return {
                ...prev,
                salesOrderSearch: {
                    ...prev.salesOrderSearch,
                    completionRateMin: newMin / 100,
                    completionRateMax: newMax / 100
                }
            }
        })
    }

    const onExpand = (newExpanded: boolean) => {
        setState(prev => {
            return {
                ...prev,
                filtersExpanded: newExpanded
            }
        })
    }

    return (
        <Card variant="outlined">
            <CardContent>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Stack direction="row" spacing={0.5} sx={{
                            display: "flex",
                            alignItems: "top"
                        }}>

                            <SearchBoxAndDebounce
                                value={state.searchValue}
                                onChange={onSearchChange}
                                replaceStar={true}
                                size="small"
                                error={searchIsInvalid}
                                helperText={searchIsInvalid ? tk('AtLeast3Char') : ""}
                            />

                            {state.filtersExpanded && (
                                <IconButton
                                    onClick={onResetFilterClick}
                                    sx={{
                                        maxHeight: "40px"
                                    }}
                                >
                                    <FilterAltOffIcon/>
                                </IconButton>
                            )}

                            <ExpandButton
                                expanded={state.filtersExpanded}
                                onClick={onExpand}
                                sx={{
                                    maxHeight: "40px"
                                }}
                            />
                        </Stack>
                    </Grid>


                    <Grid item xs={12} sm={6}>

                        <Collapse orientation="vertical" in={state.filtersExpanded}>

                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={state.searchCustomers}
                                            onChange={(event, checked) => setSearchCustomers(checked)}
                                        />
                                    }
                                    label={tk('SearchCustomers')}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={state.searchSalesOrders}
                                            onChange={(event, checked) => setSearchSalesOrders(checked)}
                                        />
                                    }
                                    label={tk('SearchSalesOrders')}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={state.searchEquipments}
                                            onChange={(event, checked) => setSearchEquipments(checked)}
                                        />
                                    }
                                    label={tk('SearchEquipments')}
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={state.salesOrderSearch?.includeClosed ?? false}
                                            onChange={(event, checked) => setIncludeClosedSalesOrders(checked)}
                                        />
                                    }
                                    label={tk('IncludeClosedSalesOrders')}
                                />
                            </FormGroup>
                        </Collapse>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Collapse orientation="vertical" in={state.filtersExpanded}>
                            {soFromIntl && soToIntl && (
                                <Stack direction="row" spacing={0.5} sx={{
                                    mt: 2
                                }}>
                                    <NativeDateInput
                                        value={soFromIntl}
                                        onChangeWithServerValue={onSoFromChange}
                                        label={tk('SalesOrdersFrom')}
                                    />
                                    <NativeDateInput
                                        value={soToIntl}
                                        onChangeWithServerValue={onSoToChange}
                                        label={tk('SalesOrdersTo')}
                                    />
                                </Stack>
                            )}

                            {completeMin !== undefined && completeMax !== undefined && (
                                <Stack
                                    direction="column"
                                    spacing={3}
                                    sx={{
                                        p: 1
                                    }}
                                >
                                    <Typography>
                                        {tk('CompletionRateLabel')}
                                    </Typography>
                                    <SliderAndDebounce
                                        value={[Math.round(completeMin * 100), Math.round(completeMax * 100)]}
                                        onChange={onCompleteSliderChange}
                                        valueLabelDisplay="auto"
                                        valueLabelFormat={(v) => `${v}%`}
                                        min={0}
                                        max={100}
                                        step={1}
                                        marks={[
                                            {
                                                value: 0,
                                                label: "0%"
                                            },
                                            {
                                                value: 50,
                                                label: "50%"
                                            },
                                            {
                                                value: 80,
                                                label: "80%"
                                            },
                                            {
                                                value: 100,
                                                label: "100%"
                                            }
                                        ]}
                                        getAriaValueText={(v) => `${v}%`}
                                    />
                                </Stack>
                            )}
                        </Collapse>
                    </Grid>
                </Grid>
            </CardContent>
            {/*   <CardActions sx={{
                display: "flex",
                justifyContent: "end"
            }}>
                <ExpandButton expanded={state.filtersExpanded} onClick={onExpand}/>
            </CardActions>*/}
        </Card>
    );
};

export default NewWorksheetFilters;