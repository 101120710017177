import React from 'react';
import {ErrorBoundary} from "@sentry/react";
import GenericErrorFallback from "shared/components/error/GenericErrorFallback";

class SentryErrorBoundary extends ErrorBoundary {
    render() {
        return (
            <ErrorBoundary fallback={(errorData): React.ReactElement => (
                <GenericErrorFallback
                    error={errorData.error}
                    componentStack={errorData.componentStack}
                    eventId={errorData.eventId}
                    resetError={errorData.resetError}
                />
            )} showDialog>
                {this.props.children}
            </ErrorBoundary>
        );
    }
}

export default SentryErrorBoundary;