import React from 'react';
import {Grid, Typography} from "@mui/material";
import {CDAMWMServiceCallModel} from "crowbar-api";
import {TextAreaProps} from "shared/components/inputs/TextArea";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {
    TaskDescriptionTextArea
} from "modules/admin/worksheet/evaluation/tabs/service-calls/descriptions/TaskDescriptionTextArea";
import CallTypeSelect from "modules/admin/worksheet/evaluation/tabs/service-calls/descriptions/CallTypeSelect";

export type TaskDescriptionAndCallTypeProps = {
    sc: CDAMWMServiceCallModel
    evalId: string

    textAreaProps?: Omit<TextAreaProps, 'value' | 'onBlur'>

    disabled?: boolean
}
const TaskDescriptionAndCallType = ({sc, evalId, textAreaProps, disabled}: TaskDescriptionAndCallTypeProps) => {
    const tk = useTranslateFunction("Admin.Worksheet")

    return (
        <Grid container spacing={1}>


            <Grid item xs={12}>

                <Grid container spacing={1} alignItems="center">
                    <Grid item xs="auto">
                        <Typography variant="body2">{tk('TaskDescription')}</Typography>
                    </Grid>

                    <Grid item xs={true}>
                        <CallTypeSelect
                            sc={sc}
                            evalId={evalId}
                            disabled={disabled}
                        />
                    </Grid>
                </Grid>

            </Grid>

            <Grid item xs={12}>
                <TaskDescriptionTextArea
                    sc={sc}
                    evalId={evalId}
                    textAreaProps={textAreaProps}
                    disabled={disabled}
                />
            </Grid>
        </Grid>
    );
};

export default TaskDescriptionAndCallType;