import {Checkbox, CircularProgress, FormControlLabel, Stack, Typography} from "@mui/material";
import {CDAMPMPhotoRuleStepModel, CDAMPMPhotoRuleStepTemplateModel} from "crowbar-api";
import usePhotoRuleStepSave from "modules/admin/worksheet/photo-rule/photo-rules/PhotoRuleEdit/usePhotoRuleStepSave";
import {useNewAtomWithRef} from "shared/hook/useNewatomWithRef";
import {ChangeEvent, useEffect, useRef} from "react";
import TextBoxAndDebounce from "shared/components/inputs/TextBoxAndDebounce";
import usePhotoStepDelete from "modules/admin/worksheet/photo-rule/photo-rules/PhotoRuleEdit/usePhotoStepDelete";
import LoadTemplateDialog from "modules/admin/worksheet/photo-rule/photo-rules/PhotoRuleEdit/LoadTemplateDialog";
import SaveTemplateDialog from "modules/admin/worksheet/photo-rule/photo-rules/PhotoRuleEdit/SaveTemplateDialog";
import useTranslateFunction from "shared/language/useTranslateFunction";
import ConfirmDialogButton from "shared/components/buttons/ConfirmDialogButton";

export interface PhotoRuleEditStepFieldsProps {
    ruleId: string
    step: CDAMPMPhotoRuleStepModel
    template: CDAMPMPhotoRuleStepTemplateModel | undefined
    afterDelete: () => void | Promise<void>
    afterTemplateLoaded: () => void | Promise<void>
    afterTemplateSaved: () => void | Promise<void>
}

const PhotoRuleEditStepFields = ({
                                     ruleId,
                                     step,
                                     template,
                                     afterDelete,
                                     afterTemplateLoaded,
                                     afterTemplateSaved
                                 }: PhotoRuleEditStepFieldsProps) => {

    const [currentStep, setCurrentStep] = useNewAtomWithRef(step)
    const [currentTemplate, setCurrentTemplate] = useNewAtomWithRef(template)

    const tk = useTranslateFunction(`Admin.PhotoRule`)

    const {mutate, isLoading} = usePhotoRuleStepSave()
    const mutateRef = useRef(mutate)
    mutateRef.current = mutate
    const photoRuleDelete = usePhotoStepDelete()

    useEffect(() => {
        if (currentStep && currentStep !== step) {
            mutateRef.current(currentStep, {
                onSettled: () => {
                    // toastInfo("Saved " + currentStep.title)
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentStep])

    useEffect(() => {
        if (step && currentStep !== step) {
            setCurrentStep(step)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step])

    useEffect(() => {
        if (currentTemplate !== template) {
            setCurrentTemplate(template)
        }
    }, [currentTemplate, setCurrentTemplate, template])

    const saveTitle = (newValue: string) => {
        setCurrentStep(prev => {
            return {
                ...prev,
                title: newValue ?? ""
            }
        })
    }

    const saveDescription = (newValue: string) => {
        setCurrentStep(prev => {
            return {
                ...prev,
                description: newValue ?? ""
            }
        })
    }

    const saveOverride = (event: ChangeEvent<HTMLInputElement>) => {
        setCurrentStep(prev => {
            return {
                ...prev,
                overrideTemplate: event.target.checked
            }
        })
    }

    const onDeleteClick = async () => {
        if (!step.id) return
        await photoRuleDelete.mutateAsync({ruleId, stepId: step.id})
        await afterDelete()
        // toastInfo(tk('StepDeleted'))
    }

    return (
        <Stack direction="column">
            <Stack direction="row" spacing={4}
                   width={1}
            >
                <Stack direction="column" spacing={4} sx={{
                    width: "80%"
                }}>
                    <FormControlLabel
                        disabled={!currentTemplate}
                        control={
                            <Checkbox
                                checked={currentStep.overrideTemplate}
                                onChange={saveOverride}
                            />
                        }
                        label={tk('Override')}
                    />

                    {currentTemplate && !currentStep.overrideTemplate ? (
                        <>
                            <Typography
                                sx={{
                                    whiteSpace: "pre-wrap"
                                }}
                            >
                                {tk('Description')}:<br/>{currentTemplate.description}
                            </Typography>
                        </>
                    ) : (
                        <>
                            <TextBoxAndDebounce
                                label={tk('Title')}
                                value={currentStep.title ?? ""}
                                onChange={saveTitle}
                            />

                            <TextBoxAndDebounce
                                label={tk('Description')}
                                value={currentStep.description ?? ""}
                                onChange={saveDescription}
                                multiline={true}
                                minRows={3}
                                maxRows={4}
                            />
                        </>
                    )}


                </Stack>

                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >

                    {isLoading && (
                        <CircularProgress/>
                    )}
                </Stack>
            </Stack>

            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                sx={{
                    marginTop: 2
                }}
            >
                <ConfirmDialogButton
                    color="error"
                    buttonText={tk('Delete')}
                    onConfirm={onDeleteClick}
                />
                <Stack
                    direction="row"
                    spacing={1}
                >
                    <LoadTemplateDialog
                        key={'load-' + step.id}
                        step={currentStep}
                        afterStepSaved={afterTemplateLoaded}
                    />

                    <SaveTemplateDialog
                        key={"save-" + step.id}
                        step={currentStep}
                        afterTemplateSaved={afterTemplateSaved}
                    />
                </Stack>

            </Stack>
        </Stack>
    )
}

export default PhotoRuleEditStepFields