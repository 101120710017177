import React from 'react';
import useTranslateFunction from "shared/language/useTranslateFunction";
import {useSelectedServiceCall} from "modules/worksheet/editor/shared/data/useSelectedServiceCall";
import {useServiceCallAtom} from "modules/worksheet/editor/state/atoms/useServiceCallAtom";
import WEditorSpeechToTextField from "modules/worksheet/editor/ui/description/WEditorSpeechToTextField";

export interface WEditorFaultDescriptionProps {
    disabled?: boolean
}

const WEditorFaultDescription = ({disabled}: WEditorFaultDescriptionProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)

    const selectedServiceCall = useSelectedServiceCall()
    const [, setSelectedServiceCall] = useServiceCallAtom(selectedServiceCall?.id)

    if (!selectedServiceCall) return <></>

    return (
        <WEditorSpeechToTextField
            disabled={disabled}
            sx={{marginTop: 2}}
            fullWidth
            minRows={4}
            maxRows={9} // https://stackoverflow.com/a/72170317/840315
            rows={undefined}
            label={tk('FaultDescriptionLabel')}
            value={selectedServiceCall?.faultDescription}
            onBlur={value => {
                setSelectedServiceCall(prev => {
                    return {
                        ...prev,
                        faultDescription: value
                    }
                })
            }}
            onTranscribeSaved={value => {
                setSelectedServiceCall(prev => {
                    return {
                        ...prev,
                        faultDescription: ((prev.faultDescription ?? "") + "\r\n" + value).trim()
                    }
                })
            }}
        />
    )
};

export default WEditorFaultDescription;