import {BaseLocalStorage} from "shared/local-storage/BaseLocalStorage";
import localForage from "localforage";
import {atom} from "jotai";
import {CDAMWMWWorksheetSave} from "crowbar-api";
import {WorksheetSaveDraftSearchParams} from "modules/worksheet/storage/WorksheetSaveDraftSearchParams";
import {StringUtils} from "shared/utils/StringUtils";

export interface WorksheetSaveWrapper {
    userId?: string
    /**
     * Saves the time of the save operation.
     * Unix timestamp in milliseconds
     */
    savedAtTimestamp?: number

    /**
     * Marks the wrapper as saved
     */
    savedToServer?: boolean

    ws?: CDAMWMWWorksheetSave
}

export const newWorksheetSaveWrapperFor = (ws: CDAMWMWWorksheetSave, saveAtTime: number, userId: string): WorksheetSaveWrapper => {
    return {
        ws: ws,
        savedAtTimestamp: saveAtTime,
        userId: userId
    }
}

export class WorksheetSaveStorage extends BaseLocalStorage<WorksheetSaveWrapper> {
    createNewInstance(): LocalForage {
        return localForage.createInstance(
            this.defaultLocalForageOptions("worksheetDocuments")
        );
    }

    getValueKey(value: WorksheetSaveWrapper): string {
        if (!value?.ws?.id) {
            throw new Error('Could not read id of the worksheet save. Null or undefined.')
        }
        return value.ws.id;
    }

    public findDraftsFor = async (searchParams: WorksheetSaveDraftSearchParams): Promise<WorksheetSaveWrapper[]> => {
        return (await this.getAll())
            .filter((w, i, a) => {
                return w?.ws?.ownerId === searchParams.userId &&
                    // (w?.ws?.status === WorksheetStatusMap.draft) &&
                    (StringUtils.undefinedNullOrEmpty(searchParams.cardCode) || w?.ws?.cardCode === searchParams.cardCode) &&
                    (StringUtils.undefinedNullOrEmpty(searchParams.search) ||
                        w?.ws.serviceCalls?.find(sc => sc.manufacturerSn?.includes(searchParams.search!)
                            || sc.internalSn?.includes(searchParams.search!)
                        )
                    )
            })
    }
}

export const WorksheetSaveStorageAtom = atom<WorksheetSaveStorage>(new WorksheetSaveStorage())

/*const internalWorksheetSaveStorageAtoms = atom<IAtomLocalStorageFactoryResult<CDAMWMWWorksheetSave>>(get =>
    get(WorksheetSaveStorageAtom).createNewAtomsFor([])
)
export const WorksheetSaveStorageAtoms = atom<IAtomLocalStorageFactoryResult<CDAMWMWWorksheetSave>>(get =>
    get(internalWorksheetSaveStorageAtoms)
)*/