import {useMutation} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {CDAMWMESingleFieldWorksheetEvalUpdate1String, ServiceCallEvalApi} from "crowbar-api";
import {AxiosRequestConfig} from "axios";

export const useServiceCallEvalUpdateComment = () => {
    return useMutation(async (p: CDAMWMESingleFieldWorksheetEvalUpdate1String, options?: AxiosRequestConfig) => {
        await crowbarApiFactory(ServiceCallEvalApi)
            .updateComment(p, options)
    })
}