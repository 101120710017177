import {PrimitiveAtom, SetStateAction, useAtom} from "jotai";
import {CDAMNMAgentNotificationConfigurationModel, CDCMGenericPagingParams, CDSMSapEmployee} from "crowbar-api";
import {
    useAgentNotificationConfigurationPageAll
} from "crowbar-api/hooks/notification/useAgentNotificationConfigurationPageAll";
import {UseQueryResult} from "@tanstack/react-query";
import useSapEmployees from "crowbar-api/hooks/sap/useSapEmployees";

export interface UseAgentNotificationConfigurationStateResult extends AgentNotificationConfigurationState {
    setState: (update: SetStateAction<AgentNotificationConfigurationState>) => void

    isLoading: boolean
    isFetching: boolean

    filteredConfigurations: CDAMNMAgentNotificationConfigurationModel[]

    employeesQuery: UseQueryResult<CDSMSapEmployee[], unknown>
    configurationQuery: UseQueryResult<CDAMNMAgentNotificationConfigurationModel[], unknown>
}

export interface AgentNotificationConfigurationState extends CDCMGenericPagingParams {

}

export const useAgentNotificationConfigurationState = (stateAtom: PrimitiveAtom<AgentNotificationConfigurationState>): UseAgentNotificationConfigurationStateResult => {

    const [state, setState] = useAtom(stateAtom)

    const employeesQuery = useSapEmployees()

    const configurationQuery = useAgentNotificationConfigurationPageAll({
        search: state.search,
        lastRowNumber: state.lastRowNumber,
        limit: state.limit,
        orderByIsDesc: state.orderByIsDesc,
        showDeleted: state.showDeleted
    })

    const employees = (employeesQuery.data ?? [])
    const configurations = (configurationQuery?.data ?? [])

    return {
        ...state,
        setState,
        isLoading: configurationQuery.isLoading,
        isFetching: configurationQuery.isFetching,
        filteredConfigurations: configurations.filter(
            c => (
                !state.search ||
                (c.emailAddresses ?? "").toLowerCase().includes(
                    (state.search ?? "").toLowerCase()
                ) ||
                (employees
                        .find(e => e.empId === c.sapEmployeeId &&
                            `${(e.firstName ?? "").toLowerCase()} ${(e.middleName ?? "").toLowerCase()} ${(e.lastName ?? "").toLowerCase()}`
                                .includes((state.search ?? "").toLowerCase())
                        )
                )
            )
        ),
        employeesQuery,
        configurationQuery
    }
}