import React, {useState} from 'react';
import {CDAMWMWorksheetEvalModel, CDAMWMWWorksheetQuery} from "crowbar-api";
import {
    useWorksheetEvalUpdateAdminComment
} from "crowbar-api/hooks/worksheet/evaluation/worksheet/useWorksheetEvalUpdateAdminComment";
import {Grid, TextField, Typography} from "@mui/material";
import useTranslateFunction from "shared/language/useTranslateFunction";

export type AdminCommentProps = {
    wEval: CDAMWMWorksheetEvalModel | null | undefined
    worksheet: CDAMWMWWorksheetQuery | null | undefined
    onDataChange: () => Promise<void>
}
const AdminComment = ({
                          wEval,
                          worksheet,
                          onDataChange
                      }: AdminCommentProps) => {
    const tk = useTranslateFunction('Admin.Worksheet')
    const [currentValue, setCurrentValue] = useState<string>(worksheet?.adminComment ?? "")

    const updateAdminComment = useWorksheetEvalUpdateAdminComment();

    const onBlur = async () => {
        if (!wEval?.id || !wEval?.worksheetId) {
            return
        }

        if (worksheet?.adminComment === currentValue) {
            return
        }

        await updateAdminComment.mutateAsync({
            evalId: wEval?.id,
            originalId: wEval?.worksheetId,
            field: currentValue ?? ""
        })

        await onDataChange()

    }

    return (
        <Grid container spacing={1}>

            <Grid item xs={12}>
                <Typography>{tk('AdminCommentLabel')}</Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    multiline
                    minRows={3}
                    value={currentValue}
                    disabled={updateAdminComment.isLoading}
                    onChange={(e) => setCurrentValue(e.target.value ?? "")}
                    onBlur={onBlur}
                />
            </Grid>
        </Grid>

    );
};

export default AdminComment;