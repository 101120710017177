import {useQuery} from "@tanstack/react-query";
import {buildTimeNotification} from "crowbar-api/interceptors/setupVersionNotificationInterceptorsTo";

export const useBuildTimeVersionNotificationQuery = () => {
    return useQuery(['local-storage-query-build-time-notification'], (): boolean => {
        return buildTimeNotification.hasNotification()
    }, {
        refetchOnMount: true,
        refetchOnWindowFocus: true,
        cacheTime: 0,
        staleTime: 0,
        retry: 3
    })
}