import {useMutation} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {PhotoRuleStepApi} from "crowbar-api";
import {v4 as uuid} from 'uuid'

export interface UsePhotoRuleEditStepAddArgs {
    ruleId: string,
    orderNum: number
}

const usePhotoRuleEditStepAdd = () => {
    return useMutation(async ({ruleId, orderNum}: UsePhotoRuleEditStepAddArgs) => {
        const response = await crowbarApiFactory(PhotoRuleStepApi).save({
            id: uuid(),
            photoRuleId: ruleId,
            orderNum: orderNum
        })

        if (response.status !== 200) {
            throw new Error('Failed to add photo rule step. Status is not 200.')
        }
    })
}

export default usePhotoRuleEditStepAdd