import {useMutation} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {ServiceCallBlobApi} from "crowbar-api";
import {useWorksheetSaveState} from "modules/worksheet/editor/state/WorksheetSaveAtoms";

export interface UseServiceCallBlobSetDeleteParams {
    blobId: string
    serviceCallId: string
    isDeleted: boolean
}

export const useServiceCallBlobSetDelete = () => {

    const [, setWs] = useWorksheetSaveState()

    return useMutation(async ({blobId, serviceCallId, isDeleted}: UseServiceCallBlobSetDeleteParams) => {
        // to keep the state in sync (when we also save the Worksheet by other means)
        // we update the ws state as well
        setWs(ws => {
            const found = ws?.serviceCalls
                ?.find(sc => sc.id === serviceCallId)
                ?.blobs
                ?.find(b => b.blobId === blobId && b.serviceCallId === serviceCallId)
            if (found) {
                found.isDeleted = true
            }
            return {
                ...ws
            }
        })
        // request to api
        const response = await crowbarApiFactory(ServiceCallBlobApi).setDeletedDirectly(blobId, serviceCallId, isDeleted)
        if (response.status !== 200) {
            throw new Error('Failed to delete. Status not OK.')
        }
    })
}