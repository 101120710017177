import React from 'react';
import {
    useServiceCallEvalFindByWorksheetEvalId
} from "crowbar-api/hooks/worksheet/evaluation/service-call/useServiceCallEvalFindByWorksheetEvalId";
import {CDAMWMServiceCallModel, CDAMWMWorksheetEvalModel, CDAMWMWWorksheetQuery} from "crowbar-api";
import {CircularProgress, Grid, Typography} from "@mui/material";
import {
    useServiceCallFindByWorksheetId
} from "crowbar-api/hooks/worksheet/service-call/useServiceCallFindByWorksheetId";
import HistoryForServiceCall from "modules/admin/worksheet/evaluation/tabs/history/HistoryForServiceCall";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {byPropertiesOf} from "shared/utils/ArraySort";

export type HistoryForServiceCallsProps = {
    wEval: CDAMWMWorksheetEvalModel | null | undefined
    worksheet: CDAMWMWWorksheetQuery | null | undefined
}
const HistoryForServiceCalls = ({
                                    wEval,
                                    worksheet
                                }: HistoryForServiceCallsProps) => {
    const tk = useTranslateFunction("Admin.Worksheet")
    const scEvalsQuery = useServiceCallEvalFindByWorksheetEvalId(wEval?.id)
    const scEvals = scEvalsQuery.data ?? []

    const serviceCallsQuery = useServiceCallFindByWorksheetId(wEval?.worksheetId)
    const serviceCalls = (serviceCallsQuery.data ?? [])
        .sort(byPropertiesOf<CDAMWMServiceCallModel>(['displayOrder']))

    if (scEvalsQuery.isLoading || serviceCallsQuery.isLoading) {
        return (
            <CircularProgress/>
        )
    }

    return (
        <Grid container spacing={1}>

            <Grid item xs={12}>
                <hr/>
            </Grid>

            {serviceCalls.map(sc => {

                const scEval = scEvals.find(sce => sce.serviceCallId === sc.id)

                if (!scEval) {
                    return (
                        <Grid item xs={12} key={sc.id}>
                            <Typography color="error" fontWeight="bold" fontSize="large">
                                Missing service call eval for {sc.serialId} ({sc.id})
                            </Typography>
                        </Grid>
                    )
                }

                return (
                    <Grid item xs={12} key={sc.id}>
                        <HistoryForServiceCall wEval={wEval} worksheet={worksheet} scEval={scEval} sc={sc}/>
                    </Grid>
                )
            })}
        </Grid>
    );
};

export default HistoryForServiceCalls;