import React, {useRef} from 'react';
import {CircularProgress} from "@mui/material";
import {
    useWorksheetEvalUpdatePartner
} from "crowbar-api/hooks/worksheet/evaluation/worksheet/useWorksheetEvalUpdatePartner";
import {useSapPartnerByCardCode} from "crowbar-api/hooks/sap/useSapPartnerByCardCode";
import WorksheetSelectedDataSourceMap from "crowbar-api/enum-map/WorksheetSelectedDataSourceMap";
import {CheckIconFabWithLoading} from "shared/components/buttons/CheckIconFabWithLoading";
import {
    useWorksheetEvalUpdatePartnerAck
} from "crowbar-api/hooks/worksheet/evaluation/worksheet/useWorksheetEvalUpdatePartnerAck";
import {CDAMWMWorksheetEvalModel, CDAMWMWWorksheetQuery} from "crowbar-api";

export type PartnerMasterDataSelectAckProps = {
    wEval: CDAMWMWorksheetEvalModel | null | undefined
    worksheet: CDAMWMWWorksheetQuery | null | undefined
    onDataChange: () => void
}
const PartnerMasterDataSelectAck = ({wEval, worksheet, onDataChange}: PartnerMasterDataSelectAckProps) => {

    const ws = worksheet

    const ack = wEval?.partnerAck

    const updatePartnerAck = useWorksheetEvalUpdatePartnerAck();
    const evalUpdatePartner = useWorksheetEvalUpdatePartner()

    const isUpdating = updatePartnerAck.isLoading || evalUpdatePartner.isLoading

    const partnerByCardCode = useSapPartnerByCardCode(wEval?.selectedSapPartnerCardCode, {
        cacheTime: 0,
        staleTime: 0,
        keepPreviousData: true,
        refetchOnMount: true,
        refetchOnWindowFocus: true
    })
    const cardCodeRef = useRef(partnerByCardCode.data)
    cardCodeRef.current = partnerByCardCode.data

    const handleButtonClick = async () => {
        let p = cardCodeRef.current

        if (ack || !p) {
            await updateServerAck(false)
        } else {
            if (wEval?.selectedSapPartnerCardCode == null || wEval?.selectedSapPartnerCardCode !== ws?.cardCode) {
                await evalUpdatePartner.mutateAsync({
                    originalId: ws?.id,
                    evalId: wEval?.id,
                    cardCode: p.cardCode,
                    partnerCardName: p.cardName,
                    partnerCardType: p.cardType,
                    partnerContactPerson: p.contactPerson,
                    partnerSource: WorksheetSelectedDataSourceMap.sapMasterData,
                    partnerVatHu: p.vatHU,
                    partnerVatInternational: p.vatEU
                })
            }
            await updateServerAck(true)
        }
        onDataChange()
    }

    const updateServerAck = async (newValue: boolean) => {
        await updatePartnerAck.mutateAsync({
            originalId: ws?.id,
            evalId: wEval?.id,
            field: newValue
        })
    }

    if (partnerByCardCode.isLoading) {
        return (
            <CircularProgress/>
        )
    }

    return (
        <CheckIconFabWithLoading
            checked={ack}
            isLoading={isUpdating}
            disabled={isUpdating}
            onClick={handleButtonClick}
        />
    )
}

export default PartnerMasterDataSelectAck;