import {useQuery} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {SapEquipmentImagesApi} from "crowbar-api";
import AxiosCancelTokenHelper from "crowbar-api/AxiosCancelTokenHelper";

export const useSapEquipmentImagesFindBy = (sapEquipmentId?: number, modSapEquipmentId?: string) => {
    return useQuery(['sap-equipment-images-find-by-1', sapEquipmentId, modSapEquipmentId], async (context) => {
        const response = await crowbarApiFactory(SapEquipmentImagesApi)
            .findBy(sapEquipmentId, modSapEquipmentId, AxiosCancelTokenHelper.cancelTokenFrom(context.signal))
        return response.data ?? []
    }, {
        networkMode: 'online', // will not fire unless we have network
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        cacheTime: 0,//Math.pow(10, 7),
        staleTime: 0,//Math.pow(10, 7),
    })
}