import React, {useState} from 'react';
import useTranslateFunction from "shared/language/useTranslateFunction";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {CDAMWMWServiceCallSave} from "crowbar-api";
import {toastError} from "shared/toast/DefaultToasts";
import {useServiceCallAtom} from "modules/worksheet/editor/state/atoms/useServiceCallAtom";
import {TextField} from "shared/components/inputs/TextField";

export type WEditorEquipmentEditSubjectDialogProps = {
    open: boolean
    onDialogClose: () => void

    serviceCall: CDAMWMWServiceCallSave
}
const WEditorEquipmentEditSubjectDialog = ({
                                               open,
                                               onDialogClose,
                                               serviceCall
                                           }: WEditorEquipmentEditSubjectDialogProps) => {
    const tk = useTranslateFunction(`Worksheet.Generic.Editor`)
    const [, setServiceCall] = useServiceCallAtom(serviceCall?.id)

    const [subject, setSubject] = useState<string>(serviceCall.emptySubject ?? "")

    const onDialogSave = () => {

        if (!subject) {
            toastError(tk('RequiredField'))
            return
        }

        setServiceCall(prev => {
            return {
                ...prev,
                emptySubject: subject
            }
        })

        onDialogClose()
    }

    return (
        <Dialog open={open} onClose={onDialogClose} fullWidth>
            <DialogTitle>{tk('Edit')}</DialogTitle>
            <DialogContent>

                <TextField
                    sx={{
                        mt: 1
                    }}
                    label={tk('Subject')}
                    value={subject}
                    onChange={e => setSubject(e.target.value?.substring(0, 100))}
                    fullWidth
                    InputLabelProps={{
                        shrink: true
                    }}
                />

            </DialogContent>
            <DialogActions sx={{
                display: "flex",
                justifyContent: "space-between"
            }}>
                <Button variant="text" color="error" onClick={onDialogClose}>{tk('Cancel')}</Button>
                <Button variant="text" color="primary" onClick={onDialogSave}>{tk('Save')}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default WEditorEquipmentEditSubjectDialog;