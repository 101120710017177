import React from 'react';
import {CDAMWMWorksheetEvalModel, CDAMWMWWorksheetQuery} from "crowbar-api";
import {Grid} from "@mui/material";
import HistoryForWorksheet from "modules/admin/worksheet/evaluation/tabs/history/HistoryForWorksheet";
import HistoryForServiceCalls from "modules/admin/worksheet/evaluation/tabs/history/HistoryForServiceCalls";

export type HistoryProps = {
    wEval: CDAMWMWorksheetEvalModel | null | undefined
    worksheet: CDAMWMWWorksheetQuery | null | undefined
}
const History = ({
                     wEval,
                     worksheet
                 }: HistoryProps) => {
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <HistoryForWorksheet wEval={wEval} worksheet={worksheet}/>
            </Grid>
            <Grid item xs={12}>
                <HistoryForServiceCalls wEval={wEval} worksheet={worksheet}/>
            </Grid>
        </Grid>
    );
};

export default History;