import {useMutation} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {CDAMWMEServiceCallItemRowEvalAddItemRowAndEvaluationParams, ServiceCallItemRowEvalApi} from "crowbar-api";
import {AxiosRequestConfig} from "axios";

export const useServiceCallItemRowEvalAddItemRowAndEvaluation = () => {
    return useMutation(async (p: CDAMWMEServiceCallItemRowEvalAddItemRowAndEvaluationParams, options?: AxiosRequestConfig) => {
        await crowbarApiFactory(ServiceCallItemRowEvalApi)
            .addItemRowAndEvaluation(p, options)
    })
}