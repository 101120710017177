// Create new axios instance for backend api
import axios, {AxiosInstance} from "axios";
import {setupBackendInterceptorsTo} from "shared/teams/CrowbarApiInterceptor";
import {setupBackendResponseInterceptorsTo} from "shared/teams/CrowbarApiResponseInterceptor";
import axiosRetry from "axios-retry";
import {setupVersionNotificationInterceptorsTo} from "crowbar-api/interceptors/setupVersionNotificationInterceptorsTo";

export const crowbarApiAxios: AxiosInstance = axios.create();
export const crowbarApiAxiosNoErrorInterceptors: AxiosInstance = axios.create();
// Setup Axios interceptors for ms auth
setupBackendInterceptorsTo(crowbarApiAxios);
setupBackendInterceptorsTo(crowbarApiAxiosNoErrorInterceptors);
// Generic error interceptors
setupBackendResponseInterceptorsTo(crowbarApiAxios);
// Version update notification interceptors
setupVersionNotificationInterceptorsTo(crowbarApiAxios)
setupVersionNotificationInterceptorsTo(crowbarApiAxiosNoErrorInterceptors)
// Create a new axios instance with a generic retry policy
// used for image loading
export const genericAxiosWithRetry: AxiosInstance = axios.create()
axiosRetry(genericAxiosWithRetry, {
    retries: 3,
    retryDelay: (retryCount, error) => {
        return retryCount * 2000;
    },
    retryCondition: (error => true)
})