import React, {useState} from 'react';
import {CDAMNMAgentNotificationConfigurationModel, CDSMSapEmployee} from "crowbar-api";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    Typography
} from "@mui/material";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {
    useAgentNotificationConfigurationSave
} from "crowbar-api/hooks/notification/useAgentNotificationConfigurationSave";
import {v4 as uuid} from 'uuid'
import {toastError} from "shared/toast/DefaultToasts";
import ConfirmDialogButton from "shared/components/buttons/ConfirmDialogButton";
import EmailAddressesInput from "shared/components/inputs/EmailAddressesInput";

export type AgentNotificationConfigurationDialogProps = DialogProps & {
    mode: 'new' | 'edit'
    editConfiguration?: CDAMNMAgentNotificationConfigurationModel
    onConfigurationChange: () => void

    onDialogClose: () => void
    employees: CDSMSapEmployee[]
}
export const AgentNotificationConfigurationDialog = ({
                                                         mode,
                                                         editConfiguration,
                                                         onConfigurationChange,
                                                         onDialogClose,
                                                         employees,
                                                         ...props
                                                     }: AgentNotificationConfigurationDialogProps) => {
    const tk = useTranslateFunction(`Admin.Worksheet`)

    const [emailAddresses, setEmailAddresses] = useState<string[]>(
        (editConfiguration?.emailAddresses ?? "").split(",").map(s => s.trim()).filter(s => !!s)
    )

    const [sapEmployeeId, setSapEmployeeId] = useState<number | undefined>(editConfiguration?.sapEmployeeId)
    const [notifyOnFinishedWorksheet, setNotifyOnFinishedWorksheet] = useState<boolean>(editConfiguration?.notifyOnFinishedWorksheet ?? true)
    const [notifyOnFinishedWorksheetWithFaults, setNotifyOnFinishedWorksheetWithFaults] = useState<boolean>(editConfiguration?.notifyOnFinishedWorksheetWithFaults ?? false)
    const [onlyNotifyIfCustomerSalesPerson, setOnlyNotifyIfCustomerSalesPerson] = useState<boolean>(editConfiguration?.onlyNotifyIfCustomerSalesPerson ?? true)

    const configurationSave = useAgentNotificationConfigurationSave()

    const asModel = (): CDAMNMAgentNotificationConfigurationModel => {
        return {
            id: mode === "new" ? uuid() : editConfiguration?.id,
            sapEmployeeId: sapEmployeeId,
            emailAddresses: emailAddresses.join(","),
            notifyOnFinishedWorksheet: notifyOnFinishedWorksheet,
            notifyOnFinishedWorksheetWithFaults: notifyOnFinishedWorksheetWithFaults,
            onlyNotifyIfCustomerSalesPerson: onlyNotifyIfCustomerSalesPerson,
            isDeleted: editConfiguration?.isDeleted ?? false
        }
    }

    const onDialogSubmit = async () => {

        if (!sapEmployeeId) {
            toastError('Missing employee')
            return
        }

        if (emailAddresses.length < 1) {
            toastError('Missing e-mail address.')
            return
        }

        await configurationSave.mutateAsync({
            ...asModel()
        })

        // callback
        onConfigurationChange?.()
    }


    const onDelete = async () => {
        await configurationSave.mutateAsync({
            ...asModel(),
            isDeleted: true
        })
        // callback
        onConfigurationChange?.()
    }

    const onEmployeeSelect = (e: SelectChangeEvent<number | string>) => {
        const val = e.target.value
        if (!isNaN(+val) && val !== "") {
            const foundEmployee = employees.find(e => e.empId === +val)
            if (foundEmployee) {
                setSapEmployeeId(foundEmployee.empId)
                return
            }
        }

        setSapEmployeeId(undefined)
    }

    const onNotifyOnFinishedWorksheet = (v: boolean) => {
        setNotifyOnFinishedWorksheet(v)
        if (v) {
            setNotifyOnFinishedWorksheetWithFaults(!v)
        }
    }

    const onNotifyOnFinishedWorksheetWithFaults = (v: boolean) => {
        setNotifyOnFinishedWorksheetWithFaults(v)
        if (v) {
            setNotifyOnFinishedWorksheet(!v)
        }
    }

    const onOnlyNotifyIfCustomerSalesPerson = (v: boolean) => {
        setOnlyNotifyIfCustomerSalesPerson(v)
    }

    return (
        <Dialog
            fullWidth={true}
            {...props}
        >
            <DialogContent sx={{pt: 5}}>
                <Grid container spacing={2}>

                    <Grid item xs={12}>
                        <FormControl sx={{width: 1}}>
                            <InputLabel id="sap-employee-select-label">{tk('Employee')}</InputLabel>
                            <Select
                                fullWidth
                                labelId="sap-employee-select-label"
                                value={sapEmployeeId ?? ""}
                                label={tk('Employee')}
                                onChange={onEmployeeSelect}
                                required
                                error={!sapEmployeeId}
                            >
                                <MenuItem value="">&nbsp;</MenuItem>
                                {employees.map(e => (
                                    <MenuItem key={e.empId} value={e.empId}>
                                        {e.lastName} {e.middleName} {e.firstName} ({e.empId})
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <EmailAddressesInput
                            defaultValue={[]}
                            emailAddresses={emailAddresses}
                            onChange={(newValues) => setEmailAddresses(newValues)}
                            onDelete={(option) => setEmailAddresses(emailAddresses.filter(a => a !== option))}
                        />

                        <Typography variant="caption" fontSize="small">{tk('PressEnterToAdd')}</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={notifyOnFinishedWorksheet}
                                              onChange={e => onNotifyOnFinishedWorksheet(e.target.checked)}/>
                                }
                                label={tk('NotifyOnFinishedWorksheetEx')}
                            />
                        </FormGroup>
                    </Grid>

                    <Grid item xs={12}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={notifyOnFinishedWorksheetWithFaults}
                                              onChange={e => onNotifyOnFinishedWorksheetWithFaults(e.target.checked)}/>
                                }
                                label={tk('NotifyOnFinishedWorksheetWithFaultsEx')}
                            />
                        </FormGroup>
                    </Grid>

                    <Grid item xs={12}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={onlyNotifyIfCustomerSalesPerson}
                                              onChange={e => onOnlyNotifyIfCustomerSalesPerson(e.target.checked)}/>
                                }
                                label={tk('OnlyNotifyIfCustomerSalesPersonEx')}
                            />
                        </FormGroup>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Stack direction="row" spacing={1} width={1} justifyContent="space-between">
                    <Stack direction="row" spacing={1}>
                        <Button color="error" onClick={onDialogClose}>{tk('Cancel')}</Button>

                        {mode === "edit" && (
                            <ConfirmDialogButton
                                color="error"
                                onConfirm={onDelete}
                                buttonText={tk('Delete')}
                            />
                        )}
                    </Stack>

                    <Button
                        color="primary"
                        variant="contained"
                        onClick={onDialogSubmit}
                        disabled={emailAddresses.length < 1 || !sapEmployeeId}
                    >
                        {tk('Save')}
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    )
}