import React from 'react';
import {CDAMWMServiceCallEvalModel, CDAMWMServiceCallModel} from "crowbar-api";
import useSapEmployees from "crowbar-api/hooks/sap/useSapEmployees";
import {
    useServiceCallEvalUpdateTechnician
} from "crowbar-api/hooks/worksheet/evaluation/service-call/useServiceCallEvalUpdateTechnician";
import {LinearProgress, MenuItem, Select, SelectChangeEvent, Stack, Typography} from "@mui/material";
import useTranslateFunction from "shared/language/useTranslateFunction";
import {byPropertiesOf} from "shared/utils/ArraySort";

export type TechnicianSelectProps = {
    sc: CDAMWMServiceCallModel
    scEval: CDAMWMServiceCallEvalModel
    disabled?: boolean
}
const TechnicianSelect = ({sc, scEval, disabled}: TechnicianSelectProps) => {

    const tk = useTranslateFunction('Admin.Worksheet')

    const sapEmployeesQuery = useSapEmployees()
    const sapEmployees = (sapEmployeesQuery.data ?? [])
        .filter(e => e.active === "Y" && e.isTechnician)
        .sort(byPropertiesOf(["lastName", "middleName", "firstName"]))

    const updateTechnician = useServiceCallEvalUpdateTechnician()

    const onChange = async (e: SelectChangeEvent<number>) => {
        const rawValue = +e.target.value
        const newValue = isNaN(rawValue) ? undefined : rawValue

        await updateTechnician.mutateAsync({
            evalId: scEval.id,
            originalId: sc.id,
            field: newValue
        })
        scEval.technicianEmpId = newValue
    }

    return (
        <Stack direction="column" spacing={0.5}>
            <Typography>{tk('SapTechnician')} *</Typography>

            {sapEmployeesQuery.isLoading ? (
                <LinearProgress/>
            ) : (
                <Select
                    size="small"
                    disabled={disabled}
                    fullWidth
                    required
                    value={scEval?.technicianEmpId ?? ""}
                    onChange={onChange}
                    error={!scEval?.technicianEmpId}
                >
                    {sapEmployees.map(e => (
                        <MenuItem key={e.empId} value={e.empId}>
                            {[e.lastName, e.middleName, e.firstName].filter(n => !!n).join(" ")}
                        </MenuItem>
                    ))}
                </Select>
            )}
        </Stack>
    );
};

export default TechnicianSelect;