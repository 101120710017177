import {useTranslation} from "react-i18next";

const useTranslateFunction = (prefix: string) => {
    const {t} = useTranslation()
    return (key: string, args?: any) => {
        const dot = prefix?.endsWith(".") ? "" : "."
        return t(`${prefix}${dot}${key}`, args)
    }
}

export default useTranslateFunction