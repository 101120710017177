import React, {useEffect, useState} from 'react';
import {useBuildTimeVersionNotificationQuery} from "crowbar-api/interceptors/useBuildTimeVersionNotificationQuery";
import {Button, Dialog, DialogActions, DialogContent, Typography} from "@mui/material";
import useTranslateFunction from "shared/language/useTranslateFunction";

const showDialogAgainAfterMilliseconds = 300000 // 5 min

export const BuildTimeNotification = () => {
    const tk = useTranslateFunction("Generic")

    const {
        data: buildTimeVersionNotification,
        refetch: buildTimeVersionNotificationRefetch
    } = useBuildTimeVersionNotificationQuery()
    const hasNotification = buildTimeVersionNotification ?? false

    const [open, setOpen] = useState<boolean>(true)

    // Automatically set the dialog to open again if dismissed
    // after a certain time
    useEffect(() => {
        const handle = setTimeout(() => {
            if (!open) {
                setOpen(true)
            }
        }, showDialogAgainAfterMilliseconds)

        return () => {
            clearTimeout(handle)
        }
    }, [open])

    // Automatically refetch query (not a web api query, only a global state)
    useEffect(() => {
        const handle = setInterval(() => {
            if (!hasNotification) {
                buildTimeVersionNotificationRefetch().then()
            }
        }, 1000)

        return () => {
            clearInterval(handle)
        }
    }, [buildTimeVersionNotificationRefetch, hasNotification])

    const reloadPage = () => {
        window.location.reload()
    }

    if (!hasNotification) return <></>

    return (
        <Dialog
            open={open}
            onClose={() => {/* no-op */
            }}
            fullWidth={true}
        >
            <DialogContent>
                <Typography>
                    {tk('NewVersionNotification')}
                </Typography>
            </DialogContent>
            <DialogActions sx={{
                justifyContent: "space-between"
            }}>
                <Button color="error" onClick={() => setOpen(false)}>{tk('Dismiss')}</Button>
                <Button color="primary" variant="contained" onClick={reloadPage}>{tk('ReloadPage')}</Button>
            </DialogActions>
        </Dialog>
    )
}