import {useQuery} from "@tanstack/react-query";
import {crowbarApiFactory} from "crowbar-api/CrowbarApiFactory";
import {PhotoRuleStepTemplateApi} from "crowbar-api";

const usePhotoStepTemplateById = (templateId?: string) => {
    return useQuery(['photo-step-template-by-id', templateId], async () => {
        if (!templateId) return null
        const response = await crowbarApiFactory(PhotoRuleStepTemplateApi).findById(templateId)
        if (response.status !== 200) {
            throw new Error('Failed to load templates by id. ' + templateId)
        }
        return response.data
    }, {
        cacheTime: 0,
        initialData: undefined,
        retry: 1
    })
}

export default usePhotoStepTemplateById