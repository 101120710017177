"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * CrowbarTeams
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailNotificationApiAxiosParamCreator = exports.DeliveryNoteTemplateApi = exports.DeliveryNoteTemplateApiFactory = exports.DeliveryNoteTemplateApiFp = exports.DeliveryNoteTemplateApiAxiosParamCreator = exports.DeliveryNoteApi = exports.DeliveryNoteApiFactory = exports.DeliveryNoteApiFp = exports.DeliveryNoteApiAxiosParamCreator = exports.CustomerNotificationConfigurationApi = exports.CustomerNotificationConfigurationApiFactory = exports.CustomerNotificationConfigurationApiFp = exports.CustomerNotificationConfigurationApiAxiosParamCreator = exports.CrowbarBlobApi = exports.CrowbarBlobApiFactory = exports.CrowbarBlobApiFp = exports.CrowbarBlobApiAxiosParamCreator = exports.AgentNotificationConfigurationApi = exports.AgentNotificationConfigurationApiFactory = exports.AgentNotificationConfigurationApiFp = exports.AgentNotificationConfigurationApiAxiosParamCreator = exports.CSTranscribeLanguage = exports.CDSMSSapSalesOrderRowsType = exports.CDSMNSapSalesOrderRowsType = exports.CDGMUserPhotoSize = exports.CDAMWMWorksheetTripType = exports.CDAMWMWorksheetStatus = exports.CDAMWMWorksheetSelectedDataSource = exports.CDAMWMWorksheetSapSalesOrderRowType = exports.CDAMWMServiceCallStatus = exports.CDAMWMServiceCallSelectedSource = exports.CDAMWMServiceCallOperatingHoursType = exports.CDAMWMServiceCallItemRowWarningType = exports.CDAMWMServiceCallItemRowStatus = exports.CDAMWMServiceCallItemRowSelectedSource = exports.CDAMWMServiceCallBlobType = exports.CDAMWMModSapType = exports.CDAMWMModSapStatus = exports.CDAMWMCompletionFeedback = exports.CDAMWEWorksheetSaveErrorCode = exports.CDAMSMServiceIssueReportStatus = exports.CDAMNMEmailNotificationStatus = exports.CDAMNMEmailNotificationSource = exports.CDAMNMEmailNotificationFailureReason = exports.CDAMEMEquipmentSearchResultType = exports.CDAMDMLeaseStatus = exports.CDAMDMDeliveryNoteType = exports.CDAMDMDeliveryNoteRowSourceType = exports.CDAMCMUserSource = exports.CDAMCMCrowbarBlobType = void 0;
exports.PublicHeartbeatApiFactory = exports.PublicHeartbeatApiFp = exports.PublicHeartbeatApiAxiosParamCreator = exports.PhotoRuleStepTemplateApi = exports.PhotoRuleStepTemplateApiFactory = exports.PhotoRuleStepTemplateApiFp = exports.PhotoRuleStepTemplateApiAxiosParamCreator = exports.PhotoRuleStepApi = exports.PhotoRuleStepApiFactory = exports.PhotoRuleStepApiFp = exports.PhotoRuleStepApiAxiosParamCreator = exports.PhotoRuleManufacturerApi = exports.PhotoRuleManufacturerApiFactory = exports.PhotoRuleManufacturerApiFp = exports.PhotoRuleManufacturerApiAxiosParamCreator = exports.PhotoRuleApi = exports.PhotoRuleApiFactory = exports.PhotoRuleApiFp = exports.PhotoRuleApiAxiosParamCreator = exports.NewWorksheetQueryApi = exports.NewWorksheetQueryApiFactory = exports.NewWorksheetQueryApiFp = exports.NewWorksheetQueryApiAxiosParamCreator = exports.ModSapPartnerApi = exports.ModSapPartnerApiFactory = exports.ModSapPartnerApiFp = exports.ModSapPartnerApiAxiosParamCreator = exports.MechanicDashboardApi = exports.MechanicDashboardApiFactory = exports.MechanicDashboardApiFp = exports.MechanicDashboardApiAxiosParamCreator = exports.MechanicApi = exports.MechanicApiFactory = exports.MechanicApiFp = exports.MechanicApiAxiosParamCreator = exports.LicensePlateApi = exports.LicensePlateApiFactory = exports.LicensePlateApiFp = exports.LicensePlateApiAxiosParamCreator = exports.LastUserActionApi = exports.LastUserActionApiFactory = exports.LastUserActionApiFp = exports.LastUserActionApiAxiosParamCreator = exports.InterfaceStateApi = exports.InterfaceStateApiFactory = exports.InterfaceStateApiFp = exports.InterfaceStateApiAxiosParamCreator = exports.EmailNotificationApi = exports.EmailNotificationApiFactory = exports.EmailNotificationApiFp = void 0;
exports.SapPsgVehicleTypeCategoryApiAxiosParamCreator = exports.SapProductBlobApi = exports.SapProductBlobApiFactory = exports.SapProductBlobApiFp = exports.SapProductBlobApiAxiosParamCreator = exports.SapPartnerEquipmentApi = exports.SapPartnerEquipmentApiFactory = exports.SapPartnerEquipmentApiFp = exports.SapPartnerEquipmentApiAxiosParamCreator = exports.SapPartnerContactApi = exports.SapPartnerContactApiFactory = exports.SapPartnerContactApiFp = exports.SapPartnerContactApiAxiosParamCreator = exports.SapPartnerAddressApi = exports.SapPartnerAddressApiFactory = exports.SapPartnerAddressApiFp = exports.SapPartnerAddressApiAxiosParamCreator = exports.SapPartnerApi = exports.SapPartnerApiFactory = exports.SapPartnerApiFp = exports.SapPartnerApiAxiosParamCreator = exports.SapItemApi = exports.SapItemApiFactory = exports.SapItemApiFp = exports.SapItemApiAxiosParamCreator = exports.SapEquipmentSearchApi = exports.SapEquipmentSearchApiFactory = exports.SapEquipmentSearchApiFp = exports.SapEquipmentSearchApiAxiosParamCreator = exports.SapEquipmentImagesApi = exports.SapEquipmentImagesApiFactory = exports.SapEquipmentImagesApiFp = exports.SapEquipmentImagesApiAxiosParamCreator = exports.SapEmployeeApi = exports.SapEmployeeApiFactory = exports.SapEmployeeApiFp = exports.SapEmployeeApiAxiosParamCreator = exports.SapDeliveryApi = exports.SapDeliveryApiFactory = exports.SapDeliveryApiFp = exports.SapDeliveryApiAxiosParamCreator = exports.SapCostRateApi = exports.SapCostRateApiFactory = exports.SapCostRateApiFp = exports.SapCostRateApiAxiosParamCreator = exports.SapCallTypeApi = exports.SapCallTypeApiFactory = exports.SapCallTypeApiFp = exports.SapCallTypeApiAxiosParamCreator = exports.PublicHeartbeatApi = void 0;
exports.TvhBlobApiFactory = exports.TvhBlobApiFp = exports.TvhBlobApiAxiosParamCreator = exports.TestApi = exports.TestApiFactory = exports.TestApiFp = exports.TestApiAxiosParamCreator = exports.SpeechToTextApi = exports.SpeechToTextApiFactory = exports.SpeechToTextApiFp = exports.SpeechToTextApiAxiosParamCreator = exports.ServiceIssueReportApi = exports.ServiceIssueReportApiFactory = exports.ServiceIssueReportApiFp = exports.ServiceIssueReportApiAxiosParamCreator = exports.ServiceCallItemRowEvalApi = exports.ServiceCallItemRowEvalApiFactory = exports.ServiceCallItemRowEvalApiFp = exports.ServiceCallItemRowEvalApiAxiosParamCreator = exports.ServiceCallItemRowApi = exports.ServiceCallItemRowApiFactory = exports.ServiceCallItemRowApiFp = exports.ServiceCallItemRowApiAxiosParamCreator = exports.ServiceCallEvalApi = exports.ServiceCallEvalApiFactory = exports.ServiceCallEvalApiFp = exports.ServiceCallEvalApiAxiosParamCreator = exports.ServiceCallBlobApi = exports.ServiceCallBlobApiFactory = exports.ServiceCallBlobApiFp = exports.ServiceCallBlobApiAxiosParamCreator = exports.ServiceCallApi = exports.ServiceCallApiFactory = exports.ServiceCallApiFp = exports.ServiceCallApiAxiosParamCreator = exports.SapVehicleTypeBlobApi = exports.SapVehicleTypeBlobApiFactory = exports.SapVehicleTypeBlobApiFp = exports.SapVehicleTypeBlobApiAxiosParamCreator = exports.SapServiceCallApi = exports.SapServiceCallApiFactory = exports.SapServiceCallApiFp = exports.SapServiceCallApiAxiosParamCreator = exports.SapSalesPersonApi = exports.SapSalesPersonApiFactory = exports.SapSalesPersonApiFp = exports.SapSalesPersonApiAxiosParamCreator = exports.SapPsgVehicleTypeCategoryApi = exports.SapPsgVehicleTypeCategoryApiFactory = exports.SapPsgVehicleTypeCategoryApiFp = void 0;
exports.WorksheetTripItemCodeApi = exports.WorksheetTripItemCodeApiFactory = exports.WorksheetTripItemCodeApiFp = exports.WorksheetTripItemCodeApiAxiosParamCreator = exports.WorksheetTimeItemCodeApi = exports.WorksheetTimeItemCodeApiFactory = exports.WorksheetTimeItemCodeApiFp = exports.WorksheetTimeItemCodeApiAxiosParamCreator = exports.WorksheetSapSalesOrderApi = exports.WorksheetSapSalesOrderApiFactory = exports.WorksheetSapSalesOrderApiFp = exports.WorksheetSapSalesOrderApiAxiosParamCreator = exports.WorksheetPdfApi = exports.WorksheetPdfApiFactory = exports.WorksheetPdfApiFp = exports.WorksheetPdfApiAxiosParamCreator = exports.WorksheetEvalApi = exports.WorksheetEvalApiFactory = exports.WorksheetEvalApiFp = exports.WorksheetEvalApiAxiosParamCreator = exports.WorksheetCallTypeApi = exports.WorksheetCallTypeApiFactory = exports.WorksheetCallTypeApiFp = exports.WorksheetCallTypeApiAxiosParamCreator = exports.WorksheetApi = exports.WorksheetApiFactory = exports.WorksheetApiFp = exports.WorksheetApiAxiosParamCreator = exports.WarmupQueryApi = exports.WarmupQueryApiFactory = exports.WarmupQueryApiFp = exports.WarmupQueryApiAxiosParamCreator = exports.VehicleTypeApi = exports.VehicleTypeApiFactory = exports.VehicleTypeApiFp = exports.VehicleTypeApiAxiosParamCreator = exports.VehicleManufacturerApi = exports.VehicleManufacturerApiFactory = exports.VehicleManufacturerApiFp = exports.VehicleManufacturerApiAxiosParamCreator = exports.UserProfileApi = exports.UserProfileApiFactory = exports.UserProfileApiFp = exports.UserProfileApiAxiosParamCreator = exports.TvhProductApi = exports.TvhProductApiFactory = exports.TvhProductApiFp = exports.TvhProductApiAxiosParamCreator = exports.TvhBlobApi = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("./common");
// @ts-ignore
var base_1 = require("./base");
/**
 *
 * @export
 * @enum {string}
 */
exports.CDAMCMCrowbarBlobType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6
};
/**
 *
 * @export
 * @enum {string}
 */
exports.CDAMCMUserSource = {
    NUMBER_0: 0
};
/**
 *
 * @export
 * @enum {string}
 */
exports.CDAMDMDeliveryNoteRowSourceType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
};
/**
 *
 * @export
 * @enum {string}
 */
exports.CDAMDMDeliveryNoteType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4
};
/**
 *
 * @export
 * @enum {string}
 */
exports.CDAMDMLeaseStatus = {
    NUMBER_1: 1,
    NUMBER_2: 2
};
/**
 *
 * @export
 * @enum {string}
 */
exports.CDAMEMEquipmentSearchResultType = {
    NUMBER_1: 1,
    NUMBER_2: 2
};
/**
 *
 * @export
 * @enum {string}
 */
exports.CDAMNMEmailNotificationFailureReason = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
};
/**
 *
 * @export
 * @enum {string}
 */
exports.CDAMNMEmailNotificationSource = {
    NUMBER_1: 1,
    NUMBER_2: 2
};
/**
 *
 * @export
 * @enum {string}
 */
exports.CDAMNMEmailNotificationStatus = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
};
/**
 *
 * @export
 * @enum {string}
 */
exports.CDAMSMServiceIssueReportStatus = {
    NUMBER_1: 1,
    NUMBER_2: 2
};
/**
 *
 * @export
 * @enum {string}
 */
exports.CDAMWEWorksheetSaveErrorCode = {
    NUMBER_0: 0,
    NUMBER_800: 800,
    NUMBER_801: 801,
    NUMBER_900: 900,
    NUMBER_901: 901,
    NUMBER_1000: 1000,
    NUMBER_1001: 1001,
    NUMBER_1002: 1002,
    NUMBER_1003: 1003,
    NUMBER_1004: 1004,
    NUMBER_1005: 1005,
    NUMBER_1006: 1006,
    NUMBER_1011: 1011,
    NUMBER_1012: 1012,
    NUMBER_1013: 1013,
    NUMBER_1014: 1014,
    NUMBER_4000: 4000
};
/**
 *
 * @export
 * @enum {string}
 */
exports.CDAMWMCompletionFeedback = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
};
/**
 *
 * @export
 * @enum {string}
 */
exports.CDAMWMModSapStatus = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
};
/**
 *
 * @export
 * @enum {string}
 */
exports.CDAMWMModSapType = {
    NUMBER_0: 0,
    NUMBER_1: 1
};
/**
 *
 * @export
 * @enum {string}
 */
exports.CDAMWMServiceCallBlobType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
};
/**
 *
 * @export
 * @enum {string}
 */
exports.CDAMWMServiceCallItemRowSelectedSource = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_3: 3,
    NUMBER_4: 4
};
/**
 *
 * @export
 * @enum {string}
 */
exports.CDAMWMServiceCallItemRowStatus = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
};
/**
 *
 * @export
 * @enum {string}
 */
exports.CDAMWMServiceCallItemRowWarningType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
};
/**
 *
 * @export
 * @enum {string}
 */
exports.CDAMWMServiceCallOperatingHoursType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
};
/**
 *
 * @export
 * @enum {string}
 */
exports.CDAMWMServiceCallSelectedSource = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5
};
/**
 *
 * @export
 * @enum {string}
 */
exports.CDAMWMServiceCallStatus = {
    NUMBER_0: 0,
    NUMBER_1: 1
};
/**
 *
 * @export
 * @enum {string}
 */
exports.CDAMWMWorksheetSapSalesOrderRowType = {
    NUMBER_0: 0,
    NUMBER_1: 1
};
/**
 *
 * @export
 * @enum {string}
 */
exports.CDAMWMWorksheetSelectedDataSource = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4
};
/**
 *
 * @export
 * @enum {string}
 */
exports.CDAMWMWorksheetStatus = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4
};
/**
 *
 * @export
 * @enum {string}
 */
exports.CDAMWMWorksheetTripType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4
};
/**
 *
 * @export
 * @enum {string}
 */
exports.CDGMUserPhotoSize = {
    NUMBER_48: 48,
    NUMBER_64: 64,
    NUMBER_96: 96,
    NUMBER_120: 120,
    NUMBER_240: 240,
    NUMBER_360: 360,
    NUMBER_432: 432,
    NUMBER_504: 504,
    NUMBER_648: 648
};
/**
 *
 * @export
 * @enum {string}
 */
exports.CDSMNSapSalesOrderRowsType = {
    NUMBER_0: 0,
    NUMBER_1: 1
};
/**
 *
 * @export
 * @enum {string}
 */
exports.CDSMSSapSalesOrderRowsType = {
    NUMBER_0: 0,
    NUMBER_1: 1
};
/**
 *
 * @export
 * @enum {string}
 */
exports.CSTranscribeLanguage = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
};
/**
 * AgentNotificationConfigurationApi - axios parameter creator
 * @export
 */
var AgentNotificationConfigurationApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {boolean} [isDeleted]
         * @param {CDAMNMAgentNotificationConfigurationModel} [cDAMNMAgentNotificationConfigurationModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: function (isDeleted, cDAMNMAgentNotificationConfigurationModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Notification/AgentNotificationConfiguration/Delete";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (isDeleted !== undefined) {
                                localVarQueryParameter['isDeleted'] = isDeleted;
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMNMAgentNotificationConfigurationModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {any} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdObject: function (body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Notification/AgentNotificationConfiguration/FindByIdObject";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(body, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMNMAgentNotificationConfigurationModel} [cDAMNMAgentNotificationConfigurationModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insert: function (cDAMNMAgentNotificationConfigurationModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Notification/AgentNotificationConfiguration/Insert";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMNMAgentNotificationConfigurationModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<CDAMNMAgentNotificationConfigurationModel>} [cDAMNMAgentNotificationConfigurationModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merge: function (cDAMNMAgentNotificationConfigurationModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Notification/AgentNotificationConfiguration/Merge";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMNMAgentNotificationConfigurationModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDCMGenericPagingParams} [cDCMGenericPagingParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageAll: function (cDCMGenericPagingParams, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Notification/AgentNotificationConfiguration/PageAll";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDCMGenericPagingParams, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMNMAgentNotificationConfigurationModel} [cDAMNMAgentNotificationConfigurationModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: function (cDAMNMAgentNotificationConfigurationModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Notification/AgentNotificationConfiguration/Save";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMNMAgentNotificationConfigurationModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMNMAgentNotificationConfigurationModel} [cDAMNMAgentNotificationConfigurationModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: function (cDAMNMAgentNotificationConfigurationModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Notification/AgentNotificationConfiguration/Update";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMNMAgentNotificationConfigurationModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.AgentNotificationConfigurationApiAxiosParamCreator = AgentNotificationConfigurationApiAxiosParamCreator;
/**
 * AgentNotificationConfigurationApi - functional programming interface
 * @export
 */
var AgentNotificationConfigurationApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.AgentNotificationConfigurationApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {boolean} [isDeleted]
         * @param {CDAMNMAgentNotificationConfigurationModel} [cDAMNMAgentNotificationConfigurationModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: function (isDeleted, cDAMNMAgentNotificationConfigurationModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator._delete(isDeleted, cDAMNMAgentNotificationConfigurationModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {any} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdObject: function (body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findByIdObject(body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMNMAgentNotificationConfigurationModel} [cDAMNMAgentNotificationConfigurationModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insert: function (cDAMNMAgentNotificationConfigurationModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.insert(cDAMNMAgentNotificationConfigurationModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<CDAMNMAgentNotificationConfigurationModel>} [cDAMNMAgentNotificationConfigurationModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merge: function (cDAMNMAgentNotificationConfigurationModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.merge(cDAMNMAgentNotificationConfigurationModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDCMGenericPagingParams} [cDCMGenericPagingParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageAll: function (cDCMGenericPagingParams, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pageAll(cDCMGenericPagingParams, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMNMAgentNotificationConfigurationModel} [cDAMNMAgentNotificationConfigurationModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: function (cDAMNMAgentNotificationConfigurationModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.save(cDAMNMAgentNotificationConfigurationModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMNMAgentNotificationConfigurationModel} [cDAMNMAgentNotificationConfigurationModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: function (cDAMNMAgentNotificationConfigurationModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.update(cDAMNMAgentNotificationConfigurationModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.AgentNotificationConfigurationApiFp = AgentNotificationConfigurationApiFp;
/**
 * AgentNotificationConfigurationApi - factory interface
 * @export
 */
var AgentNotificationConfigurationApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.AgentNotificationConfigurationApiFp)(configuration);
    return {
        /**
         *
         * @param {boolean} [isDeleted]
         * @param {CDAMNMAgentNotificationConfigurationModel} [cDAMNMAgentNotificationConfigurationModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: function (isDeleted, cDAMNMAgentNotificationConfigurationModel, options) {
            return localVarFp._delete(isDeleted, cDAMNMAgentNotificationConfigurationModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {any} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdObject: function (body, options) {
            return localVarFp.findByIdObject(body, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMNMAgentNotificationConfigurationModel} [cDAMNMAgentNotificationConfigurationModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insert: function (cDAMNMAgentNotificationConfigurationModel, options) {
            return localVarFp.insert(cDAMNMAgentNotificationConfigurationModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {Array<CDAMNMAgentNotificationConfigurationModel>} [cDAMNMAgentNotificationConfigurationModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merge: function (cDAMNMAgentNotificationConfigurationModel, options) {
            return localVarFp.merge(cDAMNMAgentNotificationConfigurationModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDCMGenericPagingParams} [cDCMGenericPagingParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageAll: function (cDCMGenericPagingParams, options) {
            return localVarFp.pageAll(cDCMGenericPagingParams, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMNMAgentNotificationConfigurationModel} [cDAMNMAgentNotificationConfigurationModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: function (cDAMNMAgentNotificationConfigurationModel, options) {
            return localVarFp.save(cDAMNMAgentNotificationConfigurationModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMNMAgentNotificationConfigurationModel} [cDAMNMAgentNotificationConfigurationModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: function (cDAMNMAgentNotificationConfigurationModel, options) {
            return localVarFp.update(cDAMNMAgentNotificationConfigurationModel, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.AgentNotificationConfigurationApiFactory = AgentNotificationConfigurationApiFactory;
/**
 * AgentNotificationConfigurationApi - object-oriented interface
 * @export
 * @class AgentNotificationConfigurationApi
 * @extends {BaseAPI}
 */
var AgentNotificationConfigurationApi = /** @class */ (function (_super) {
    __extends(AgentNotificationConfigurationApi, _super);
    function AgentNotificationConfigurationApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {boolean} [isDeleted]
     * @param {CDAMNMAgentNotificationConfigurationModel} [cDAMNMAgentNotificationConfigurationModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentNotificationConfigurationApi
     */
    AgentNotificationConfigurationApi.prototype._delete = function (isDeleted, cDAMNMAgentNotificationConfigurationModel, options) {
        var _this = this;
        return (0, exports.AgentNotificationConfigurationApiFp)(this.configuration)._delete(isDeleted, cDAMNMAgentNotificationConfigurationModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {any} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentNotificationConfigurationApi
     */
    AgentNotificationConfigurationApi.prototype.findByIdObject = function (body, options) {
        var _this = this;
        return (0, exports.AgentNotificationConfigurationApiFp)(this.configuration).findByIdObject(body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMNMAgentNotificationConfigurationModel} [cDAMNMAgentNotificationConfigurationModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentNotificationConfigurationApi
     */
    AgentNotificationConfigurationApi.prototype.insert = function (cDAMNMAgentNotificationConfigurationModel, options) {
        var _this = this;
        return (0, exports.AgentNotificationConfigurationApiFp)(this.configuration).insert(cDAMNMAgentNotificationConfigurationModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {Array<CDAMNMAgentNotificationConfigurationModel>} [cDAMNMAgentNotificationConfigurationModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentNotificationConfigurationApi
     */
    AgentNotificationConfigurationApi.prototype.merge = function (cDAMNMAgentNotificationConfigurationModel, options) {
        var _this = this;
        return (0, exports.AgentNotificationConfigurationApiFp)(this.configuration).merge(cDAMNMAgentNotificationConfigurationModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDCMGenericPagingParams} [cDCMGenericPagingParams]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentNotificationConfigurationApi
     */
    AgentNotificationConfigurationApi.prototype.pageAll = function (cDCMGenericPagingParams, options) {
        var _this = this;
        return (0, exports.AgentNotificationConfigurationApiFp)(this.configuration).pageAll(cDCMGenericPagingParams, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMNMAgentNotificationConfigurationModel} [cDAMNMAgentNotificationConfigurationModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentNotificationConfigurationApi
     */
    AgentNotificationConfigurationApi.prototype.save = function (cDAMNMAgentNotificationConfigurationModel, options) {
        var _this = this;
        return (0, exports.AgentNotificationConfigurationApiFp)(this.configuration).save(cDAMNMAgentNotificationConfigurationModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMNMAgentNotificationConfigurationModel} [cDAMNMAgentNotificationConfigurationModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentNotificationConfigurationApi
     */
    AgentNotificationConfigurationApi.prototype.update = function (cDAMNMAgentNotificationConfigurationModel, options) {
        var _this = this;
        return (0, exports.AgentNotificationConfigurationApiFp)(this.configuration).update(cDAMNMAgentNotificationConfigurationModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return AgentNotificationConfigurationApi;
}(base_1.BaseAPI));
exports.AgentNotificationConfigurationApi = AgentNotificationConfigurationApi;
/**
 * CrowbarBlobApi - axios parameter creator
 * @export
 */
var CrowbarBlobApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {string} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadFile: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Common/CrowbarBlob/DownloadFile";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (id !== undefined) {
                                localVarQueryParameter['id'] = id;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdOrDefault: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Common/CrowbarBlob/FindByIdOrDefault";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (id !== undefined) {
                                localVarQueryParameter['id'] = id;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<string>} [requestBody]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIds: function (requestBody, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Common/CrowbarBlob/FindByIds";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(requestBody, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {boolean} [forceOverwrite]
         * @param {File} [uploadedFile]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAudioFile: function (forceOverwrite, uploadedFile, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Common/CrowbarBlob/UploadAudioFile";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (forceOverwrite !== undefined) {
                                localVarQueryParameter['forceOverwrite'] = forceOverwrite;
                            }
                            if (uploadedFile !== undefined) {
                                localVarFormParams.append('uploadedFile', uploadedFile);
                            }
                            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = localVarFormParams;
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMCMCrowbarBlobType} [blobType]
         * @param {boolean} [forceOverwrite]
         * @param {File} [uploadedFile]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFileAs: function (blobType, forceOverwrite, uploadedFile, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Common/CrowbarBlob/UploadFileAs";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (blobType !== undefined) {
                                localVarQueryParameter['blobType'] = blobType;
                            }
                            if (forceOverwrite !== undefined) {
                                localVarQueryParameter['forceOverwrite'] = forceOverwrite;
                            }
                            if (uploadedFile !== undefined) {
                                localVarFormParams.append('uploadedFile', uploadedFile);
                            }
                            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = localVarFormParams;
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.CrowbarBlobApiAxiosParamCreator = CrowbarBlobApiAxiosParamCreator;
/**
 * CrowbarBlobApi - functional programming interface
 * @export
 */
var CrowbarBlobApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.CrowbarBlobApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {string} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadFile: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.downloadFile(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdOrDefault: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findByIdOrDefault(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<string>} [requestBody]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIds: function (requestBody, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findByIds(requestBody, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {boolean} [forceOverwrite]
         * @param {File} [uploadedFile]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAudioFile: function (forceOverwrite, uploadedFile, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.uploadAudioFile(forceOverwrite, uploadedFile, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMCMCrowbarBlobType} [blobType]
         * @param {boolean} [forceOverwrite]
         * @param {File} [uploadedFile]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFileAs: function (blobType, forceOverwrite, uploadedFile, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.uploadFileAs(blobType, forceOverwrite, uploadedFile, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.CrowbarBlobApiFp = CrowbarBlobApiFp;
/**
 * CrowbarBlobApi - factory interface
 * @export
 */
var CrowbarBlobApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.CrowbarBlobApiFp)(configuration);
    return {
        /**
         *
         * @param {string} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadFile: function (id, options) {
            return localVarFp.downloadFile(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdOrDefault: function (id, options) {
            return localVarFp.findByIdOrDefault(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {Array<string>} [requestBody]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIds: function (requestBody, options) {
            return localVarFp.findByIds(requestBody, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {boolean} [forceOverwrite]
         * @param {File} [uploadedFile]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAudioFile: function (forceOverwrite, uploadedFile, options) {
            return localVarFp.uploadAudioFile(forceOverwrite, uploadedFile, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMCMCrowbarBlobType} [blobType]
         * @param {boolean} [forceOverwrite]
         * @param {File} [uploadedFile]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFileAs: function (blobType, forceOverwrite, uploadedFile, options) {
            return localVarFp.uploadFileAs(blobType, forceOverwrite, uploadedFile, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.CrowbarBlobApiFactory = CrowbarBlobApiFactory;
/**
 * CrowbarBlobApi - object-oriented interface
 * @export
 * @class CrowbarBlobApi
 * @extends {BaseAPI}
 */
var CrowbarBlobApi = /** @class */ (function (_super) {
    __extends(CrowbarBlobApi, _super);
    function CrowbarBlobApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {string} [id]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrowbarBlobApi
     */
    CrowbarBlobApi.prototype.downloadFile = function (id, options) {
        var _this = this;
        return (0, exports.CrowbarBlobApiFp)(this.configuration).downloadFile(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [id]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrowbarBlobApi
     */
    CrowbarBlobApi.prototype.findByIdOrDefault = function (id, options) {
        var _this = this;
        return (0, exports.CrowbarBlobApiFp)(this.configuration).findByIdOrDefault(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {Array<string>} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrowbarBlobApi
     */
    CrowbarBlobApi.prototype.findByIds = function (requestBody, options) {
        var _this = this;
        return (0, exports.CrowbarBlobApiFp)(this.configuration).findByIds(requestBody, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {boolean} [forceOverwrite]
     * @param {File} [uploadedFile]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrowbarBlobApi
     */
    CrowbarBlobApi.prototype.uploadAudioFile = function (forceOverwrite, uploadedFile, options) {
        var _this = this;
        return (0, exports.CrowbarBlobApiFp)(this.configuration).uploadAudioFile(forceOverwrite, uploadedFile, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMCMCrowbarBlobType} [blobType]
     * @param {boolean} [forceOverwrite]
     * @param {File} [uploadedFile]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrowbarBlobApi
     */
    CrowbarBlobApi.prototype.uploadFileAs = function (blobType, forceOverwrite, uploadedFile, options) {
        var _this = this;
        return (0, exports.CrowbarBlobApiFp)(this.configuration).uploadFileAs(blobType, forceOverwrite, uploadedFile, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return CrowbarBlobApi;
}(base_1.BaseAPI));
exports.CrowbarBlobApi = CrowbarBlobApi;
/**
 * CustomerNotificationConfigurationApi - axios parameter creator
 * @export
 */
var CustomerNotificationConfigurationApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {boolean} [isDeleted]
         * @param {CDAMNMCustomerNotificationConfigurationModel} [cDAMNMCustomerNotificationConfigurationModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: function (isDeleted, cDAMNMCustomerNotificationConfigurationModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Notification/CustomerNotificationConfiguration/Delete";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (isDeleted !== undefined) {
                                localVarQueryParameter['isDeleted'] = isDeleted;
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMNMCustomerNotificationConfigurationModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {any} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdObject: function (body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Notification/CustomerNotificationConfiguration/FindByIdObject";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(body, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMNMCustomerNotificationConfigurationModel} [cDAMNMCustomerNotificationConfigurationModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insert: function (cDAMNMCustomerNotificationConfigurationModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Notification/CustomerNotificationConfiguration/Insert";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMNMCustomerNotificationConfigurationModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<CDAMNMCustomerNotificationConfigurationModel>} [cDAMNMCustomerNotificationConfigurationModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merge: function (cDAMNMCustomerNotificationConfigurationModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Notification/CustomerNotificationConfiguration/Merge";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMNMCustomerNotificationConfigurationModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDCMGenericPagingParams} [cDCMGenericPagingParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageAll: function (cDCMGenericPagingParams, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Notification/CustomerNotificationConfiguration/PageAll";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDCMGenericPagingParams, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMNMCustomerNotificationConfigurationModel} [cDAMNMCustomerNotificationConfigurationModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: function (cDAMNMCustomerNotificationConfigurationModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Notification/CustomerNotificationConfiguration/Save";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMNMCustomerNotificationConfigurationModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMNMCustomerNotificationConfigurationSearchParameters} [cDAMNMCustomerNotificationConfigurationSearchParameters]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPageBy: function (cDAMNMCustomerNotificationConfigurationSearchParameters, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Notification/CustomerNotificationConfiguration/SearchPageBy";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMNMCustomerNotificationConfigurationSearchParameters, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMNMCustomerNotificationConfigurationModel} [cDAMNMCustomerNotificationConfigurationModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: function (cDAMNMCustomerNotificationConfigurationModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Notification/CustomerNotificationConfiguration/Update";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMNMCustomerNotificationConfigurationModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.CustomerNotificationConfigurationApiAxiosParamCreator = CustomerNotificationConfigurationApiAxiosParamCreator;
/**
 * CustomerNotificationConfigurationApi - functional programming interface
 * @export
 */
var CustomerNotificationConfigurationApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.CustomerNotificationConfigurationApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {boolean} [isDeleted]
         * @param {CDAMNMCustomerNotificationConfigurationModel} [cDAMNMCustomerNotificationConfigurationModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: function (isDeleted, cDAMNMCustomerNotificationConfigurationModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator._delete(isDeleted, cDAMNMCustomerNotificationConfigurationModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {any} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdObject: function (body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findByIdObject(body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMNMCustomerNotificationConfigurationModel} [cDAMNMCustomerNotificationConfigurationModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insert: function (cDAMNMCustomerNotificationConfigurationModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.insert(cDAMNMCustomerNotificationConfigurationModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<CDAMNMCustomerNotificationConfigurationModel>} [cDAMNMCustomerNotificationConfigurationModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merge: function (cDAMNMCustomerNotificationConfigurationModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.merge(cDAMNMCustomerNotificationConfigurationModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDCMGenericPagingParams} [cDCMGenericPagingParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageAll: function (cDCMGenericPagingParams, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pageAll(cDCMGenericPagingParams, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMNMCustomerNotificationConfigurationModel} [cDAMNMCustomerNotificationConfigurationModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: function (cDAMNMCustomerNotificationConfigurationModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.save(cDAMNMCustomerNotificationConfigurationModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMNMCustomerNotificationConfigurationSearchParameters} [cDAMNMCustomerNotificationConfigurationSearchParameters]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPageBy: function (cDAMNMCustomerNotificationConfigurationSearchParameters, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.searchPageBy(cDAMNMCustomerNotificationConfigurationSearchParameters, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMNMCustomerNotificationConfigurationModel} [cDAMNMCustomerNotificationConfigurationModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: function (cDAMNMCustomerNotificationConfigurationModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.update(cDAMNMCustomerNotificationConfigurationModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.CustomerNotificationConfigurationApiFp = CustomerNotificationConfigurationApiFp;
/**
 * CustomerNotificationConfigurationApi - factory interface
 * @export
 */
var CustomerNotificationConfigurationApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.CustomerNotificationConfigurationApiFp)(configuration);
    return {
        /**
         *
         * @param {boolean} [isDeleted]
         * @param {CDAMNMCustomerNotificationConfigurationModel} [cDAMNMCustomerNotificationConfigurationModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: function (isDeleted, cDAMNMCustomerNotificationConfigurationModel, options) {
            return localVarFp._delete(isDeleted, cDAMNMCustomerNotificationConfigurationModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {any} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdObject: function (body, options) {
            return localVarFp.findByIdObject(body, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMNMCustomerNotificationConfigurationModel} [cDAMNMCustomerNotificationConfigurationModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insert: function (cDAMNMCustomerNotificationConfigurationModel, options) {
            return localVarFp.insert(cDAMNMCustomerNotificationConfigurationModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {Array<CDAMNMCustomerNotificationConfigurationModel>} [cDAMNMCustomerNotificationConfigurationModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merge: function (cDAMNMCustomerNotificationConfigurationModel, options) {
            return localVarFp.merge(cDAMNMCustomerNotificationConfigurationModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDCMGenericPagingParams} [cDCMGenericPagingParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageAll: function (cDCMGenericPagingParams, options) {
            return localVarFp.pageAll(cDCMGenericPagingParams, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMNMCustomerNotificationConfigurationModel} [cDAMNMCustomerNotificationConfigurationModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: function (cDAMNMCustomerNotificationConfigurationModel, options) {
            return localVarFp.save(cDAMNMCustomerNotificationConfigurationModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMNMCustomerNotificationConfigurationSearchParameters} [cDAMNMCustomerNotificationConfigurationSearchParameters]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPageBy: function (cDAMNMCustomerNotificationConfigurationSearchParameters, options) {
            return localVarFp.searchPageBy(cDAMNMCustomerNotificationConfigurationSearchParameters, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMNMCustomerNotificationConfigurationModel} [cDAMNMCustomerNotificationConfigurationModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: function (cDAMNMCustomerNotificationConfigurationModel, options) {
            return localVarFp.update(cDAMNMCustomerNotificationConfigurationModel, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.CustomerNotificationConfigurationApiFactory = CustomerNotificationConfigurationApiFactory;
/**
 * CustomerNotificationConfigurationApi - object-oriented interface
 * @export
 * @class CustomerNotificationConfigurationApi
 * @extends {BaseAPI}
 */
var CustomerNotificationConfigurationApi = /** @class */ (function (_super) {
    __extends(CustomerNotificationConfigurationApi, _super);
    function CustomerNotificationConfigurationApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {boolean} [isDeleted]
     * @param {CDAMNMCustomerNotificationConfigurationModel} [cDAMNMCustomerNotificationConfigurationModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerNotificationConfigurationApi
     */
    CustomerNotificationConfigurationApi.prototype._delete = function (isDeleted, cDAMNMCustomerNotificationConfigurationModel, options) {
        var _this = this;
        return (0, exports.CustomerNotificationConfigurationApiFp)(this.configuration)._delete(isDeleted, cDAMNMCustomerNotificationConfigurationModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {any} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerNotificationConfigurationApi
     */
    CustomerNotificationConfigurationApi.prototype.findByIdObject = function (body, options) {
        var _this = this;
        return (0, exports.CustomerNotificationConfigurationApiFp)(this.configuration).findByIdObject(body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMNMCustomerNotificationConfigurationModel} [cDAMNMCustomerNotificationConfigurationModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerNotificationConfigurationApi
     */
    CustomerNotificationConfigurationApi.prototype.insert = function (cDAMNMCustomerNotificationConfigurationModel, options) {
        var _this = this;
        return (0, exports.CustomerNotificationConfigurationApiFp)(this.configuration).insert(cDAMNMCustomerNotificationConfigurationModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {Array<CDAMNMCustomerNotificationConfigurationModel>} [cDAMNMCustomerNotificationConfigurationModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerNotificationConfigurationApi
     */
    CustomerNotificationConfigurationApi.prototype.merge = function (cDAMNMCustomerNotificationConfigurationModel, options) {
        var _this = this;
        return (0, exports.CustomerNotificationConfigurationApiFp)(this.configuration).merge(cDAMNMCustomerNotificationConfigurationModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDCMGenericPagingParams} [cDCMGenericPagingParams]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerNotificationConfigurationApi
     */
    CustomerNotificationConfigurationApi.prototype.pageAll = function (cDCMGenericPagingParams, options) {
        var _this = this;
        return (0, exports.CustomerNotificationConfigurationApiFp)(this.configuration).pageAll(cDCMGenericPagingParams, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMNMCustomerNotificationConfigurationModel} [cDAMNMCustomerNotificationConfigurationModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerNotificationConfigurationApi
     */
    CustomerNotificationConfigurationApi.prototype.save = function (cDAMNMCustomerNotificationConfigurationModel, options) {
        var _this = this;
        return (0, exports.CustomerNotificationConfigurationApiFp)(this.configuration).save(cDAMNMCustomerNotificationConfigurationModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMNMCustomerNotificationConfigurationSearchParameters} [cDAMNMCustomerNotificationConfigurationSearchParameters]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerNotificationConfigurationApi
     */
    CustomerNotificationConfigurationApi.prototype.searchPageBy = function (cDAMNMCustomerNotificationConfigurationSearchParameters, options) {
        var _this = this;
        return (0, exports.CustomerNotificationConfigurationApiFp)(this.configuration).searchPageBy(cDAMNMCustomerNotificationConfigurationSearchParameters, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMNMCustomerNotificationConfigurationModel} [cDAMNMCustomerNotificationConfigurationModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerNotificationConfigurationApi
     */
    CustomerNotificationConfigurationApi.prototype.update = function (cDAMNMCustomerNotificationConfigurationModel, options) {
        var _this = this;
        return (0, exports.CustomerNotificationConfigurationApiFp)(this.configuration).update(cDAMNMCustomerNotificationConfigurationModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return CustomerNotificationConfigurationApi;
}(base_1.BaseAPI));
exports.CustomerNotificationConfigurationApi = CustomerNotificationConfigurationApi;
/**
 * DeliveryNoteApi - axios parameter creator
 * @export
 */
var DeliveryNoteApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {string} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findById: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/DeliveryNote/DeliveryNote/FindById";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (id !== undefined) {
                                localVarQueryParameter['id'] = id;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMDMDeliveryNoteModel} [cDAMDMDeliveryNoteModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: function (cDAMDMDeliveryNoteModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/DeliveryNote/DeliveryNote/Save";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMDMDeliveryNoteModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMDMDeliveryNotePagingParameters} [cDAMDMDeliveryNotePagingParameters]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPageBy: function (cDAMDMDeliveryNotePagingParameters, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/DeliveryNote/DeliveryNote/SearchPageBy";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMDMDeliveryNotePagingParameters, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.DeliveryNoteApiAxiosParamCreator = DeliveryNoteApiAxiosParamCreator;
/**
 * DeliveryNoteApi - functional programming interface
 * @export
 */
var DeliveryNoteApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.DeliveryNoteApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {string} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findById: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findById(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMDMDeliveryNoteModel} [cDAMDMDeliveryNoteModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: function (cDAMDMDeliveryNoteModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.save(cDAMDMDeliveryNoteModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMDMDeliveryNotePagingParameters} [cDAMDMDeliveryNotePagingParameters]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPageBy: function (cDAMDMDeliveryNotePagingParameters, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.searchPageBy(cDAMDMDeliveryNotePagingParameters, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.DeliveryNoteApiFp = DeliveryNoteApiFp;
/**
 * DeliveryNoteApi - factory interface
 * @export
 */
var DeliveryNoteApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.DeliveryNoteApiFp)(configuration);
    return {
        /**
         *
         * @param {string} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findById: function (id, options) {
            return localVarFp.findById(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMDMDeliveryNoteModel} [cDAMDMDeliveryNoteModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: function (cDAMDMDeliveryNoteModel, options) {
            return localVarFp.save(cDAMDMDeliveryNoteModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMDMDeliveryNotePagingParameters} [cDAMDMDeliveryNotePagingParameters]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPageBy: function (cDAMDMDeliveryNotePagingParameters, options) {
            return localVarFp.searchPageBy(cDAMDMDeliveryNotePagingParameters, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.DeliveryNoteApiFactory = DeliveryNoteApiFactory;
/**
 * DeliveryNoteApi - object-oriented interface
 * @export
 * @class DeliveryNoteApi
 * @extends {BaseAPI}
 */
var DeliveryNoteApi = /** @class */ (function (_super) {
    __extends(DeliveryNoteApi, _super);
    function DeliveryNoteApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {string} [id]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryNoteApi
     */
    DeliveryNoteApi.prototype.findById = function (id, options) {
        var _this = this;
        return (0, exports.DeliveryNoteApiFp)(this.configuration).findById(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMDMDeliveryNoteModel} [cDAMDMDeliveryNoteModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryNoteApi
     */
    DeliveryNoteApi.prototype.save = function (cDAMDMDeliveryNoteModel, options) {
        var _this = this;
        return (0, exports.DeliveryNoteApiFp)(this.configuration).save(cDAMDMDeliveryNoteModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMDMDeliveryNotePagingParameters} [cDAMDMDeliveryNotePagingParameters]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryNoteApi
     */
    DeliveryNoteApi.prototype.searchPageBy = function (cDAMDMDeliveryNotePagingParameters, options) {
        var _this = this;
        return (0, exports.DeliveryNoteApiFp)(this.configuration).searchPageBy(cDAMDMDeliveryNotePagingParameters, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return DeliveryNoteApi;
}(base_1.BaseAPI));
exports.DeliveryNoteApi = DeliveryNoteApi;
/**
 * DeliveryNoteTemplateApi - axios parameter creator
 * @export
 */
var DeliveryNoteTemplateApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {string} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/DeliveryNote/DeliveryNoteTemplate/Delete";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (id !== undefined) {
                                localVarQueryParameter['id'] = id;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [deliveryNoteId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAsDocx: function (deliveryNoteId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/DeliveryNote/DeliveryNoteTemplate/DownloadAsDocx";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (deliveryNoteId !== undefined) {
                                localVarQueryParameter['deliveryNoteId'] = deliveryNoteId;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [deliveryNoteId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAsPdf: function (deliveryNoteId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/DeliveryNote/DeliveryNoteTemplate/DownloadAsPdf";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (deliveryNoteId !== undefined) {
                                localVarQueryParameter['deliveryNoteId'] = deliveryNoteId;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadTemplate: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/DeliveryNote/DeliveryNoteTemplate/DownloadTemplate";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (id !== undefined) {
                                localVarQueryParameter['id'] = id;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/DeliveryNote/DeliveryNoteTemplate/FindAll";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findById: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/DeliveryNote/DeliveryNoteTemplate/FindById";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (id !== undefined) {
                                localVarQueryParameter['id'] = id;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markAsDefault: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/DeliveryNote/DeliveryNoteTemplate/MarkAsDefault";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (id !== undefined) {
                                localVarQueryParameter['id'] = id;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sampleXmlDownload: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/DeliveryNote/DeliveryNoteTemplate/SampleXmlDownload";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMDMDeliveryNoteTemplateModel} [cDAMDMDeliveryNoteTemplateModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: function (cDAMDMDeliveryNoteTemplateModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/DeliveryNote/DeliveryNoteTemplate/Save";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMDMDeliveryNoteTemplateModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.DeliveryNoteTemplateApiAxiosParamCreator = DeliveryNoteTemplateApiAxiosParamCreator;
/**
 * DeliveryNoteTemplateApi - functional programming interface
 * @export
 */
var DeliveryNoteTemplateApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.DeliveryNoteTemplateApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {string} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator._delete(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [deliveryNoteId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAsDocx: function (deliveryNoteId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.downloadAsDocx(deliveryNoteId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [deliveryNoteId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAsPdf: function (deliveryNoteId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.downloadAsPdf(deliveryNoteId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadTemplate: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.downloadTemplate(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findAll(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findById: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findById(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markAsDefault: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.markAsDefault(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sampleXmlDownload: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.sampleXmlDownload(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMDMDeliveryNoteTemplateModel} [cDAMDMDeliveryNoteTemplateModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: function (cDAMDMDeliveryNoteTemplateModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.save(cDAMDMDeliveryNoteTemplateModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.DeliveryNoteTemplateApiFp = DeliveryNoteTemplateApiFp;
/**
 * DeliveryNoteTemplateApi - factory interface
 * @export
 */
var DeliveryNoteTemplateApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.DeliveryNoteTemplateApiFp)(configuration);
    return {
        /**
         *
         * @param {string} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: function (id, options) {
            return localVarFp._delete(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [deliveryNoteId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAsDocx: function (deliveryNoteId, options) {
            return localVarFp.downloadAsDocx(deliveryNoteId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [deliveryNoteId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAsPdf: function (deliveryNoteId, options) {
            return localVarFp.downloadAsPdf(deliveryNoteId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadTemplate: function (id, options) {
            return localVarFp.downloadTemplate(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: function (options) {
            return localVarFp.findAll(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findById: function (id, options) {
            return localVarFp.findById(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markAsDefault: function (id, options) {
            return localVarFp.markAsDefault(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sampleXmlDownload: function (options) {
            return localVarFp.sampleXmlDownload(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMDMDeliveryNoteTemplateModel} [cDAMDMDeliveryNoteTemplateModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: function (cDAMDMDeliveryNoteTemplateModel, options) {
            return localVarFp.save(cDAMDMDeliveryNoteTemplateModel, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.DeliveryNoteTemplateApiFactory = DeliveryNoteTemplateApiFactory;
/**
 * DeliveryNoteTemplateApi - object-oriented interface
 * @export
 * @class DeliveryNoteTemplateApi
 * @extends {BaseAPI}
 */
var DeliveryNoteTemplateApi = /** @class */ (function (_super) {
    __extends(DeliveryNoteTemplateApi, _super);
    function DeliveryNoteTemplateApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {string} [id]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryNoteTemplateApi
     */
    DeliveryNoteTemplateApi.prototype._delete = function (id, options) {
        var _this = this;
        return (0, exports.DeliveryNoteTemplateApiFp)(this.configuration)._delete(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [deliveryNoteId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryNoteTemplateApi
     */
    DeliveryNoteTemplateApi.prototype.downloadAsDocx = function (deliveryNoteId, options) {
        var _this = this;
        return (0, exports.DeliveryNoteTemplateApiFp)(this.configuration).downloadAsDocx(deliveryNoteId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [deliveryNoteId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryNoteTemplateApi
     */
    DeliveryNoteTemplateApi.prototype.downloadAsPdf = function (deliveryNoteId, options) {
        var _this = this;
        return (0, exports.DeliveryNoteTemplateApiFp)(this.configuration).downloadAsPdf(deliveryNoteId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [id]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryNoteTemplateApi
     */
    DeliveryNoteTemplateApi.prototype.downloadTemplate = function (id, options) {
        var _this = this;
        return (0, exports.DeliveryNoteTemplateApiFp)(this.configuration).downloadTemplate(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryNoteTemplateApi
     */
    DeliveryNoteTemplateApi.prototype.findAll = function (options) {
        var _this = this;
        return (0, exports.DeliveryNoteTemplateApiFp)(this.configuration).findAll(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [id]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryNoteTemplateApi
     */
    DeliveryNoteTemplateApi.prototype.findById = function (id, options) {
        var _this = this;
        return (0, exports.DeliveryNoteTemplateApiFp)(this.configuration).findById(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [id]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryNoteTemplateApi
     */
    DeliveryNoteTemplateApi.prototype.markAsDefault = function (id, options) {
        var _this = this;
        return (0, exports.DeliveryNoteTemplateApiFp)(this.configuration).markAsDefault(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryNoteTemplateApi
     */
    DeliveryNoteTemplateApi.prototype.sampleXmlDownload = function (options) {
        var _this = this;
        return (0, exports.DeliveryNoteTemplateApiFp)(this.configuration).sampleXmlDownload(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMDMDeliveryNoteTemplateModel} [cDAMDMDeliveryNoteTemplateModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryNoteTemplateApi
     */
    DeliveryNoteTemplateApi.prototype.save = function (cDAMDMDeliveryNoteTemplateModel, options) {
        var _this = this;
        return (0, exports.DeliveryNoteTemplateApiFp)(this.configuration).save(cDAMDMDeliveryNoteTemplateModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return DeliveryNoteTemplateApi;
}(base_1.BaseAPI));
exports.DeliveryNoteTemplateApi = DeliveryNoteTemplateApi;
/**
 * EmailNotificationApi - axios parameter creator
 * @export
 */
var EmailNotificationApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {CDAMNMEmailNotificationSearchParameters} [cDAMNMEmailNotificationSearchParameters]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPageBy: function (cDAMNMEmailNotificationSearchParameters, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Notification/EmailNotification/SearchPageBy";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMNMEmailNotificationSearchParameters, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [enId]
         * @param {boolean} [resendValue]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateResendFor: function (enId, resendValue, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Notification/EmailNotification/UpdateResendFor";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (enId !== undefined) {
                                localVarQueryParameter['enId'] = enId;
                            }
                            if (resendValue !== undefined) {
                                localVarQueryParameter['resendValue'] = resendValue;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.EmailNotificationApiAxiosParamCreator = EmailNotificationApiAxiosParamCreator;
/**
 * EmailNotificationApi - functional programming interface
 * @export
 */
var EmailNotificationApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.EmailNotificationApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {CDAMNMEmailNotificationSearchParameters} [cDAMNMEmailNotificationSearchParameters]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPageBy: function (cDAMNMEmailNotificationSearchParameters, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.searchPageBy(cDAMNMEmailNotificationSearchParameters, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [enId]
         * @param {boolean} [resendValue]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateResendFor: function (enId, resendValue, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateResendFor(enId, resendValue, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.EmailNotificationApiFp = EmailNotificationApiFp;
/**
 * EmailNotificationApi - factory interface
 * @export
 */
var EmailNotificationApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.EmailNotificationApiFp)(configuration);
    return {
        /**
         *
         * @param {CDAMNMEmailNotificationSearchParameters} [cDAMNMEmailNotificationSearchParameters]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPageBy: function (cDAMNMEmailNotificationSearchParameters, options) {
            return localVarFp.searchPageBy(cDAMNMEmailNotificationSearchParameters, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [enId]
         * @param {boolean} [resendValue]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateResendFor: function (enId, resendValue, options) {
            return localVarFp.updateResendFor(enId, resendValue, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.EmailNotificationApiFactory = EmailNotificationApiFactory;
/**
 * EmailNotificationApi - object-oriented interface
 * @export
 * @class EmailNotificationApi
 * @extends {BaseAPI}
 */
var EmailNotificationApi = /** @class */ (function (_super) {
    __extends(EmailNotificationApi, _super);
    function EmailNotificationApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {CDAMNMEmailNotificationSearchParameters} [cDAMNMEmailNotificationSearchParameters]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailNotificationApi
     */
    EmailNotificationApi.prototype.searchPageBy = function (cDAMNMEmailNotificationSearchParameters, options) {
        var _this = this;
        return (0, exports.EmailNotificationApiFp)(this.configuration).searchPageBy(cDAMNMEmailNotificationSearchParameters, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [enId]
     * @param {boolean} [resendValue]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailNotificationApi
     */
    EmailNotificationApi.prototype.updateResendFor = function (enId, resendValue, options) {
        var _this = this;
        return (0, exports.EmailNotificationApiFp)(this.configuration).updateResendFor(enId, resendValue, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return EmailNotificationApi;
}(base_1.BaseAPI));
exports.EmailNotificationApi = EmailNotificationApi;
/**
 * InterfaceStateApi - axios parameter creator
 * @export
 */
var InterfaceStateApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {boolean} [isDeleted]
         * @param {CDAMCMInterfaceStateModel} [cDAMCMInterfaceStateModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: function (isDeleted, cDAMCMInterfaceStateModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Common/InterfaceState/Delete";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (isDeleted !== undefined) {
                                localVarQueryParameter['isDeleted'] = isDeleted;
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMCMInterfaceStateModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {any} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdObject: function (body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Common/InterfaceState/FindByIdObject";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(body, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [interfaceName]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findForCurrentUserBy: function (interfaceName, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Common/InterfaceState/FindForCurrentUserBy";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (interfaceName !== undefined) {
                                localVarQueryParameter['interfaceName'] = interfaceName;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMCMInterfaceStateModel} [cDAMCMInterfaceStateModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insert: function (cDAMCMInterfaceStateModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Common/InterfaceState/Insert";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMCMInterfaceStateModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<CDAMCMInterfaceStateModel>} [cDAMCMInterfaceStateModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merge: function (cDAMCMInterfaceStateModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Common/InterfaceState/Merge";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMCMInterfaceStateModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDCMGenericPagingParams} [cDCMGenericPagingParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageAll: function (cDCMGenericPagingParams, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Common/InterfaceState/PageAll";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDCMGenericPagingParams, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMCMInterfaceStateModel} [cDAMCMInterfaceStateModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: function (cDAMCMInterfaceStateModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Common/InterfaceState/Save";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMCMInterfaceStateModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMCMInterfaceStateModel} [cDAMCMInterfaceStateModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveForCurrentUser: function (cDAMCMInterfaceStateModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Common/InterfaceState/SaveForCurrentUser";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMCMInterfaceStateModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMCMInterfaceStateModel} [cDAMCMInterfaceStateModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: function (cDAMCMInterfaceStateModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Common/InterfaceState/Update";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMCMInterfaceStateModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.InterfaceStateApiAxiosParamCreator = InterfaceStateApiAxiosParamCreator;
/**
 * InterfaceStateApi - functional programming interface
 * @export
 */
var InterfaceStateApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.InterfaceStateApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {boolean} [isDeleted]
         * @param {CDAMCMInterfaceStateModel} [cDAMCMInterfaceStateModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: function (isDeleted, cDAMCMInterfaceStateModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator._delete(isDeleted, cDAMCMInterfaceStateModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {any} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdObject: function (body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findByIdObject(body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [interfaceName]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findForCurrentUserBy: function (interfaceName, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findForCurrentUserBy(interfaceName, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMCMInterfaceStateModel} [cDAMCMInterfaceStateModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insert: function (cDAMCMInterfaceStateModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.insert(cDAMCMInterfaceStateModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<CDAMCMInterfaceStateModel>} [cDAMCMInterfaceStateModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merge: function (cDAMCMInterfaceStateModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.merge(cDAMCMInterfaceStateModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDCMGenericPagingParams} [cDCMGenericPagingParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageAll: function (cDCMGenericPagingParams, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pageAll(cDCMGenericPagingParams, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMCMInterfaceStateModel} [cDAMCMInterfaceStateModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: function (cDAMCMInterfaceStateModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.save(cDAMCMInterfaceStateModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMCMInterfaceStateModel} [cDAMCMInterfaceStateModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveForCurrentUser: function (cDAMCMInterfaceStateModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.saveForCurrentUser(cDAMCMInterfaceStateModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMCMInterfaceStateModel} [cDAMCMInterfaceStateModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: function (cDAMCMInterfaceStateModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.update(cDAMCMInterfaceStateModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.InterfaceStateApiFp = InterfaceStateApiFp;
/**
 * InterfaceStateApi - factory interface
 * @export
 */
var InterfaceStateApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.InterfaceStateApiFp)(configuration);
    return {
        /**
         *
         * @param {boolean} [isDeleted]
         * @param {CDAMCMInterfaceStateModel} [cDAMCMInterfaceStateModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: function (isDeleted, cDAMCMInterfaceStateModel, options) {
            return localVarFp._delete(isDeleted, cDAMCMInterfaceStateModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {any} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdObject: function (body, options) {
            return localVarFp.findByIdObject(body, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [interfaceName]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findForCurrentUserBy: function (interfaceName, options) {
            return localVarFp.findForCurrentUserBy(interfaceName, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMCMInterfaceStateModel} [cDAMCMInterfaceStateModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insert: function (cDAMCMInterfaceStateModel, options) {
            return localVarFp.insert(cDAMCMInterfaceStateModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {Array<CDAMCMInterfaceStateModel>} [cDAMCMInterfaceStateModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merge: function (cDAMCMInterfaceStateModel, options) {
            return localVarFp.merge(cDAMCMInterfaceStateModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDCMGenericPagingParams} [cDCMGenericPagingParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageAll: function (cDCMGenericPagingParams, options) {
            return localVarFp.pageAll(cDCMGenericPagingParams, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMCMInterfaceStateModel} [cDAMCMInterfaceStateModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: function (cDAMCMInterfaceStateModel, options) {
            return localVarFp.save(cDAMCMInterfaceStateModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMCMInterfaceStateModel} [cDAMCMInterfaceStateModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveForCurrentUser: function (cDAMCMInterfaceStateModel, options) {
            return localVarFp.saveForCurrentUser(cDAMCMInterfaceStateModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMCMInterfaceStateModel} [cDAMCMInterfaceStateModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: function (cDAMCMInterfaceStateModel, options) {
            return localVarFp.update(cDAMCMInterfaceStateModel, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.InterfaceStateApiFactory = InterfaceStateApiFactory;
/**
 * InterfaceStateApi - object-oriented interface
 * @export
 * @class InterfaceStateApi
 * @extends {BaseAPI}
 */
var InterfaceStateApi = /** @class */ (function (_super) {
    __extends(InterfaceStateApi, _super);
    function InterfaceStateApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {boolean} [isDeleted]
     * @param {CDAMCMInterfaceStateModel} [cDAMCMInterfaceStateModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterfaceStateApi
     */
    InterfaceStateApi.prototype._delete = function (isDeleted, cDAMCMInterfaceStateModel, options) {
        var _this = this;
        return (0, exports.InterfaceStateApiFp)(this.configuration)._delete(isDeleted, cDAMCMInterfaceStateModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {any} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterfaceStateApi
     */
    InterfaceStateApi.prototype.findByIdObject = function (body, options) {
        var _this = this;
        return (0, exports.InterfaceStateApiFp)(this.configuration).findByIdObject(body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [interfaceName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterfaceStateApi
     */
    InterfaceStateApi.prototype.findForCurrentUserBy = function (interfaceName, options) {
        var _this = this;
        return (0, exports.InterfaceStateApiFp)(this.configuration).findForCurrentUserBy(interfaceName, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMCMInterfaceStateModel} [cDAMCMInterfaceStateModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterfaceStateApi
     */
    InterfaceStateApi.prototype.insert = function (cDAMCMInterfaceStateModel, options) {
        var _this = this;
        return (0, exports.InterfaceStateApiFp)(this.configuration).insert(cDAMCMInterfaceStateModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {Array<CDAMCMInterfaceStateModel>} [cDAMCMInterfaceStateModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterfaceStateApi
     */
    InterfaceStateApi.prototype.merge = function (cDAMCMInterfaceStateModel, options) {
        var _this = this;
        return (0, exports.InterfaceStateApiFp)(this.configuration).merge(cDAMCMInterfaceStateModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDCMGenericPagingParams} [cDCMGenericPagingParams]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterfaceStateApi
     */
    InterfaceStateApi.prototype.pageAll = function (cDCMGenericPagingParams, options) {
        var _this = this;
        return (0, exports.InterfaceStateApiFp)(this.configuration).pageAll(cDCMGenericPagingParams, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMCMInterfaceStateModel} [cDAMCMInterfaceStateModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterfaceStateApi
     */
    InterfaceStateApi.prototype.save = function (cDAMCMInterfaceStateModel, options) {
        var _this = this;
        return (0, exports.InterfaceStateApiFp)(this.configuration).save(cDAMCMInterfaceStateModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMCMInterfaceStateModel} [cDAMCMInterfaceStateModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterfaceStateApi
     */
    InterfaceStateApi.prototype.saveForCurrentUser = function (cDAMCMInterfaceStateModel, options) {
        var _this = this;
        return (0, exports.InterfaceStateApiFp)(this.configuration).saveForCurrentUser(cDAMCMInterfaceStateModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMCMInterfaceStateModel} [cDAMCMInterfaceStateModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterfaceStateApi
     */
    InterfaceStateApi.prototype.update = function (cDAMCMInterfaceStateModel, options) {
        var _this = this;
        return (0, exports.InterfaceStateApiFp)(this.configuration).update(cDAMCMInterfaceStateModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return InterfaceStateApi;
}(base_1.BaseAPI));
exports.InterfaceStateApi = InterfaceStateApi;
/**
 * LastUserActionApi - axios parameter creator
 * @export
 */
var LastUserActionApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Shared/LastUserAction/FindAll";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.LastUserActionApiAxiosParamCreator = LastUserActionApiAxiosParamCreator;
/**
 * LastUserActionApi - functional programming interface
 * @export
 */
var LastUserActionApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.LastUserActionApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findAll(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.LastUserActionApiFp = LastUserActionApiFp;
/**
 * LastUserActionApi - factory interface
 * @export
 */
var LastUserActionApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.LastUserActionApiFp)(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: function (options) {
            return localVarFp.findAll(options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.LastUserActionApiFactory = LastUserActionApiFactory;
/**
 * LastUserActionApi - object-oriented interface
 * @export
 * @class LastUserActionApi
 * @extends {BaseAPI}
 */
var LastUserActionApi = /** @class */ (function (_super) {
    __extends(LastUserActionApi, _super);
    function LastUserActionApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LastUserActionApi
     */
    LastUserActionApi.prototype.findAll = function (options) {
        var _this = this;
        return (0, exports.LastUserActionApiFp)(this.configuration).findAll(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return LastUserActionApi;
}(base_1.BaseAPI));
exports.LastUserActionApi = LastUserActionApi;
/**
 * LicensePlateApi - axios parameter creator
 * @export
 */
var LicensePlateApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {string} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/DeliveryNote/LicensePlate/Delete";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (id !== undefined) {
                                localVarQueryParameter['id'] = id;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/DeliveryNote/LicensePlate/FindAll";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findById: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/DeliveryNote/LicensePlate/FindById";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (id !== undefined) {
                                localVarQueryParameter['id'] = id;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMDMLicensePlateModel} [cDAMDMLicensePlateModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: function (cDAMDMLicensePlateModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/DeliveryNote/LicensePlate/Save";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMDMLicensePlateModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.LicensePlateApiAxiosParamCreator = LicensePlateApiAxiosParamCreator;
/**
 * LicensePlateApi - functional programming interface
 * @export
 */
var LicensePlateApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.LicensePlateApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {string} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator._delete(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findAll(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findById: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findById(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMDMLicensePlateModel} [cDAMDMLicensePlateModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: function (cDAMDMLicensePlateModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.save(cDAMDMLicensePlateModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.LicensePlateApiFp = LicensePlateApiFp;
/**
 * LicensePlateApi - factory interface
 * @export
 */
var LicensePlateApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.LicensePlateApiFp)(configuration);
    return {
        /**
         *
         * @param {string} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: function (id, options) {
            return localVarFp._delete(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: function (options) {
            return localVarFp.findAll(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findById: function (id, options) {
            return localVarFp.findById(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMDMLicensePlateModel} [cDAMDMLicensePlateModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: function (cDAMDMLicensePlateModel, options) {
            return localVarFp.save(cDAMDMLicensePlateModel, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.LicensePlateApiFactory = LicensePlateApiFactory;
/**
 * LicensePlateApi - object-oriented interface
 * @export
 * @class LicensePlateApi
 * @extends {BaseAPI}
 */
var LicensePlateApi = /** @class */ (function (_super) {
    __extends(LicensePlateApi, _super);
    function LicensePlateApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {string} [id]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicensePlateApi
     */
    LicensePlateApi.prototype._delete = function (id, options) {
        var _this = this;
        return (0, exports.LicensePlateApiFp)(this.configuration)._delete(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicensePlateApi
     */
    LicensePlateApi.prototype.findAll = function (options) {
        var _this = this;
        return (0, exports.LicensePlateApiFp)(this.configuration).findAll(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [id]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicensePlateApi
     */
    LicensePlateApi.prototype.findById = function (id, options) {
        var _this = this;
        return (0, exports.LicensePlateApiFp)(this.configuration).findById(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMDMLicensePlateModel} [cDAMDMLicensePlateModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicensePlateApi
     */
    LicensePlateApi.prototype.save = function (cDAMDMLicensePlateModel, options) {
        var _this = this;
        return (0, exports.LicensePlateApiFp)(this.configuration).save(cDAMDMLicensePlateModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return LicensePlateApi;
}(base_1.BaseAPI));
exports.LicensePlateApi = LicensePlateApi;
/**
 * MechanicApi - axios parameter creator
 * @export
 */
var MechanicApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {string} [userId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: function (userId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Common/Mechanic/Delete";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (userId !== undefined) {
                                localVarQueryParameter['userId'] = userId;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllMechanic: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Common/Mechanic/FindAllMechanic";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphApiFindAllUserWithMechanicRole: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Common/Mechanic/GraphApiFindAllUserWithMechanicRole";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMCMMechanicModel} [cDAMCMMechanicModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: function (cDAMCMMechanicModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Common/Mechanic/Save";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMCMMechanicModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.MechanicApiAxiosParamCreator = MechanicApiAxiosParamCreator;
/**
 * MechanicApi - functional programming interface
 * @export
 */
var MechanicApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.MechanicApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {string} [userId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: function (userId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator._delete(userId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllMechanic: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findAllMechanic(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphApiFindAllUserWithMechanicRole: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.graphApiFindAllUserWithMechanicRole(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMCMMechanicModel} [cDAMCMMechanicModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: function (cDAMCMMechanicModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.save(cDAMCMMechanicModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.MechanicApiFp = MechanicApiFp;
/**
 * MechanicApi - factory interface
 * @export
 */
var MechanicApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.MechanicApiFp)(configuration);
    return {
        /**
         *
         * @param {string} [userId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: function (userId, options) {
            return localVarFp._delete(userId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllMechanic: function (options) {
            return localVarFp.findAllMechanic(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphApiFindAllUserWithMechanicRole: function (options) {
            return localVarFp.graphApiFindAllUserWithMechanicRole(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMCMMechanicModel} [cDAMCMMechanicModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: function (cDAMCMMechanicModel, options) {
            return localVarFp.save(cDAMCMMechanicModel, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.MechanicApiFactory = MechanicApiFactory;
/**
 * MechanicApi - object-oriented interface
 * @export
 * @class MechanicApi
 * @extends {BaseAPI}
 */
var MechanicApi = /** @class */ (function (_super) {
    __extends(MechanicApi, _super);
    function MechanicApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {string} [userId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MechanicApi
     */
    MechanicApi.prototype._delete = function (userId, options) {
        var _this = this;
        return (0, exports.MechanicApiFp)(this.configuration)._delete(userId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MechanicApi
     */
    MechanicApi.prototype.findAllMechanic = function (options) {
        var _this = this;
        return (0, exports.MechanicApiFp)(this.configuration).findAllMechanic(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MechanicApi
     */
    MechanicApi.prototype.graphApiFindAllUserWithMechanicRole = function (options) {
        var _this = this;
        return (0, exports.MechanicApiFp)(this.configuration).graphApiFindAllUserWithMechanicRole(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMCMMechanicModel} [cDAMCMMechanicModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MechanicApi
     */
    MechanicApi.prototype.save = function (cDAMCMMechanicModel, options) {
        var _this = this;
        return (0, exports.MechanicApiFp)(this.configuration).save(cDAMCMMechanicModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return MechanicApi;
}(base_1.BaseAPI));
exports.MechanicApi = MechanicApi;
/**
 * MechanicDashboardApi - axios parameter creator
 * @export
 */
var MechanicDashboardApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {Array<string>} [requestBody]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findWorksheetsFor: function (requestBody, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/MechanicDashboard/FindWorksheetsFor";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(requestBody, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.MechanicDashboardApiAxiosParamCreator = MechanicDashboardApiAxiosParamCreator;
/**
 * MechanicDashboardApi - functional programming interface
 * @export
 */
var MechanicDashboardApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.MechanicDashboardApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {Array<string>} [requestBody]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findWorksheetsFor: function (requestBody, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findWorksheetsFor(requestBody, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.MechanicDashboardApiFp = MechanicDashboardApiFp;
/**
 * MechanicDashboardApi - factory interface
 * @export
 */
var MechanicDashboardApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.MechanicDashboardApiFp)(configuration);
    return {
        /**
         *
         * @param {Array<string>} [requestBody]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findWorksheetsFor: function (requestBody, options) {
            return localVarFp.findWorksheetsFor(requestBody, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.MechanicDashboardApiFactory = MechanicDashboardApiFactory;
/**
 * MechanicDashboardApi - object-oriented interface
 * @export
 * @class MechanicDashboardApi
 * @extends {BaseAPI}
 */
var MechanicDashboardApi = /** @class */ (function (_super) {
    __extends(MechanicDashboardApi, _super);
    function MechanicDashboardApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {Array<string>} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MechanicDashboardApi
     */
    MechanicDashboardApi.prototype.findWorksheetsFor = function (requestBody, options) {
        var _this = this;
        return (0, exports.MechanicDashboardApiFp)(this.configuration).findWorksheetsFor(requestBody, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return MechanicDashboardApi;
}(base_1.BaseAPI));
exports.MechanicDashboardApi = MechanicDashboardApi;
/**
 * ModSapPartnerApi - axios parameter creator
 * @export
 */
var ModSapPartnerApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {CDAMWMModSapPartnerModel} [cDAMWMModSapPartnerModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        add: function (cDAMWMModSapPartnerModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/ModSapPartner/Add";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMModSapPartnerModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<CDAMWMModSapPartnerModel>} [cDAMWMModSapPartnerModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merge: function (cDAMWMModSapPartnerModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/ModSapPartner/Merge";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMModSapPartnerModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.ModSapPartnerApiAxiosParamCreator = ModSapPartnerApiAxiosParamCreator;
/**
 * ModSapPartnerApi - functional programming interface
 * @export
 */
var ModSapPartnerApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.ModSapPartnerApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {CDAMWMModSapPartnerModel} [cDAMWMModSapPartnerModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        add: function (cDAMWMModSapPartnerModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.add(cDAMWMModSapPartnerModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<CDAMWMModSapPartnerModel>} [cDAMWMModSapPartnerModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merge: function (cDAMWMModSapPartnerModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.merge(cDAMWMModSapPartnerModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.ModSapPartnerApiFp = ModSapPartnerApiFp;
/**
 * ModSapPartnerApi - factory interface
 * @export
 */
var ModSapPartnerApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.ModSapPartnerApiFp)(configuration);
    return {
        /**
         *
         * @param {CDAMWMModSapPartnerModel} [cDAMWMModSapPartnerModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        add: function (cDAMWMModSapPartnerModel, options) {
            return localVarFp.add(cDAMWMModSapPartnerModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {Array<CDAMWMModSapPartnerModel>} [cDAMWMModSapPartnerModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merge: function (cDAMWMModSapPartnerModel, options) {
            return localVarFp.merge(cDAMWMModSapPartnerModel, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.ModSapPartnerApiFactory = ModSapPartnerApiFactory;
/**
 * ModSapPartnerApi - object-oriented interface
 * @export
 * @class ModSapPartnerApi
 * @extends {BaseAPI}
 */
var ModSapPartnerApi = /** @class */ (function (_super) {
    __extends(ModSapPartnerApi, _super);
    function ModSapPartnerApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {CDAMWMModSapPartnerModel} [cDAMWMModSapPartnerModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModSapPartnerApi
     */
    ModSapPartnerApi.prototype.add = function (cDAMWMModSapPartnerModel, options) {
        var _this = this;
        return (0, exports.ModSapPartnerApiFp)(this.configuration).add(cDAMWMModSapPartnerModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {Array<CDAMWMModSapPartnerModel>} [cDAMWMModSapPartnerModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModSapPartnerApi
     */
    ModSapPartnerApi.prototype.merge = function (cDAMWMModSapPartnerModel, options) {
        var _this = this;
        return (0, exports.ModSapPartnerApiFp)(this.configuration).merge(cDAMWMModSapPartnerModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return ModSapPartnerApi;
}(base_1.BaseAPI));
exports.ModSapPartnerApi = ModSapPartnerApi;
/**
 * NewWorksheetQueryApi - axios parameter creator
 * @export
 */
var NewWorksheetQueryApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {CDSMNNewWorksheetCustomerParams} [cDSMNNewWorksheetCustomerParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageCustomersBy: function (cDSMNNewWorksheetCustomerParams, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/NewWorksheetQuery/PageCustomersBy";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDSMNNewWorksheetCustomerParams, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDSMNNewWorksheetEquipmentParams} [cDSMNNewWorksheetEquipmentParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageEquipmentsBy: function (cDSMNNewWorksheetEquipmentParams, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/NewWorksheetQuery/PageEquipmentsBy";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDSMNNewWorksheetEquipmentParams, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDSMNNewWorksheetSalesOrderProps} [cDSMNNewWorksheetSalesOrderProps]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageSalesOrdersBy: function (cDSMNNewWorksheetSalesOrderProps, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/NewWorksheetQuery/PageSalesOrdersBy";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDSMNNewWorksheetSalesOrderProps, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.NewWorksheetQueryApiAxiosParamCreator = NewWorksheetQueryApiAxiosParamCreator;
/**
 * NewWorksheetQueryApi - functional programming interface
 * @export
 */
var NewWorksheetQueryApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.NewWorksheetQueryApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {CDSMNNewWorksheetCustomerParams} [cDSMNNewWorksheetCustomerParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageCustomersBy: function (cDSMNNewWorksheetCustomerParams, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pageCustomersBy(cDSMNNewWorksheetCustomerParams, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDSMNNewWorksheetEquipmentParams} [cDSMNNewWorksheetEquipmentParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageEquipmentsBy: function (cDSMNNewWorksheetEquipmentParams, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pageEquipmentsBy(cDSMNNewWorksheetEquipmentParams, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDSMNNewWorksheetSalesOrderProps} [cDSMNNewWorksheetSalesOrderProps]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageSalesOrdersBy: function (cDSMNNewWorksheetSalesOrderProps, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pageSalesOrdersBy(cDSMNNewWorksheetSalesOrderProps, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.NewWorksheetQueryApiFp = NewWorksheetQueryApiFp;
/**
 * NewWorksheetQueryApi - factory interface
 * @export
 */
var NewWorksheetQueryApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.NewWorksheetQueryApiFp)(configuration);
    return {
        /**
         *
         * @param {CDSMNNewWorksheetCustomerParams} [cDSMNNewWorksheetCustomerParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageCustomersBy: function (cDSMNNewWorksheetCustomerParams, options) {
            return localVarFp.pageCustomersBy(cDSMNNewWorksheetCustomerParams, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDSMNNewWorksheetEquipmentParams} [cDSMNNewWorksheetEquipmentParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageEquipmentsBy: function (cDSMNNewWorksheetEquipmentParams, options) {
            return localVarFp.pageEquipmentsBy(cDSMNNewWorksheetEquipmentParams, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDSMNNewWorksheetSalesOrderProps} [cDSMNNewWorksheetSalesOrderProps]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageSalesOrdersBy: function (cDSMNNewWorksheetSalesOrderProps, options) {
            return localVarFp.pageSalesOrdersBy(cDSMNNewWorksheetSalesOrderProps, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.NewWorksheetQueryApiFactory = NewWorksheetQueryApiFactory;
/**
 * NewWorksheetQueryApi - object-oriented interface
 * @export
 * @class NewWorksheetQueryApi
 * @extends {BaseAPI}
 */
var NewWorksheetQueryApi = /** @class */ (function (_super) {
    __extends(NewWorksheetQueryApi, _super);
    function NewWorksheetQueryApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {CDSMNNewWorksheetCustomerParams} [cDSMNNewWorksheetCustomerParams]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewWorksheetQueryApi
     */
    NewWorksheetQueryApi.prototype.pageCustomersBy = function (cDSMNNewWorksheetCustomerParams, options) {
        var _this = this;
        return (0, exports.NewWorksheetQueryApiFp)(this.configuration).pageCustomersBy(cDSMNNewWorksheetCustomerParams, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDSMNNewWorksheetEquipmentParams} [cDSMNNewWorksheetEquipmentParams]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewWorksheetQueryApi
     */
    NewWorksheetQueryApi.prototype.pageEquipmentsBy = function (cDSMNNewWorksheetEquipmentParams, options) {
        var _this = this;
        return (0, exports.NewWorksheetQueryApiFp)(this.configuration).pageEquipmentsBy(cDSMNNewWorksheetEquipmentParams, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDSMNNewWorksheetSalesOrderProps} [cDSMNNewWorksheetSalesOrderProps]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewWorksheetQueryApi
     */
    NewWorksheetQueryApi.prototype.pageSalesOrdersBy = function (cDSMNNewWorksheetSalesOrderProps, options) {
        var _this = this;
        return (0, exports.NewWorksheetQueryApiFp)(this.configuration).pageSalesOrdersBy(cDSMNNewWorksheetSalesOrderProps, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return NewWorksheetQueryApi;
}(base_1.BaseAPI));
exports.NewWorksheetQueryApi = NewWorksheetQueryApi;
/**
 * PhotoRuleApi - axios parameter creator
 * @export
 */
var PhotoRuleApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {boolean} [isDeleted]
         * @param {CDAMPMPhotoRuleModel} [cDAMPMPhotoRuleModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: function (isDeleted, cDAMPMPhotoRuleModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/PhotoRule/PhotoRule/Delete";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (isDeleted !== undefined) {
                                localVarQueryParameter['isDeleted'] = isDeleted;
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMPMPhotoRuleModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allRulesAnfManufacturers: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/PhotoRule/PhotoRule/AllRulesAnfManufacturers";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [ruleId]
         * @param {string} [newTitle]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeTitle: function (ruleId, newTitle, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/PhotoRule/PhotoRule/ChangeTitle";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (ruleId !== undefined) {
                                localVarQueryParameter['ruleId'] = ruleId;
                            }
                            if (newTitle !== undefined) {
                                localVarQueryParameter['newTitle'] = newTitle;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [ruleId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePermanentlyById: function (ruleId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/PhotoRule/PhotoRule/DeletePermanentlyById";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (ruleId !== undefined) {
                                localVarQueryParameter['ruleId'] = ruleId;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [ruleId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findById: function (ruleId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/PhotoRule/PhotoRule/FindById";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (ruleId !== undefined) {
                                localVarQueryParameter['ruleId'] = ruleId;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {any} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdObject: function (body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/PhotoRule/PhotoRule/FindByIdObject";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(body, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMPMPhotoRuleModel} [cDAMPMPhotoRuleModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insert: function (cDAMPMPhotoRuleModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/PhotoRule/PhotoRule/Insert";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMPMPhotoRuleModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<CDAMPMPhotoRuleModel>} [cDAMPMPhotoRuleModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merge: function (cDAMPMPhotoRuleModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/PhotoRule/PhotoRule/Merge";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMPMPhotoRuleModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDCMGenericPagingParams} [cDCMGenericPagingParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageAll: function (cDCMGenericPagingParams, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/PhotoRule/PhotoRule/PageAll";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDCMGenericPagingParams, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMPMPhotoRulePagingParams} [cDAMPMPhotoRulePagingParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageBy: function (cDAMPMPhotoRulePagingParams, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/PhotoRule/PhotoRule/PageBy";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMPMPhotoRulePagingParams, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [ruleId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ruleByIdQuery: function (ruleId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/PhotoRule/PhotoRule/RuleByIdQuery";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (ruleId !== undefined) {
                                localVarQueryParameter['ruleId'] = ruleId;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ruleDetailsById: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/PhotoRule/PhotoRule/RuleDetailsById";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (id !== undefined) {
                                localVarQueryParameter['id'] = id;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rulesQuery: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/PhotoRule/PhotoRule/RulesQuery";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMPMPhotoRuleModel} [cDAMPMPhotoRuleModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: function (cDAMPMPhotoRuleModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/PhotoRule/PhotoRule/Save";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMPMPhotoRuleModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMPMPhotoRuleModel} [cDAMPMPhotoRuleModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: function (cDAMPMPhotoRuleModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/PhotoRule/PhotoRule/Update";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMPMPhotoRuleModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.PhotoRuleApiAxiosParamCreator = PhotoRuleApiAxiosParamCreator;
/**
 * PhotoRuleApi - functional programming interface
 * @export
 */
var PhotoRuleApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.PhotoRuleApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {boolean} [isDeleted]
         * @param {CDAMPMPhotoRuleModel} [cDAMPMPhotoRuleModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: function (isDeleted, cDAMPMPhotoRuleModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator._delete(isDeleted, cDAMPMPhotoRuleModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allRulesAnfManufacturers: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.allRulesAnfManufacturers(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [ruleId]
         * @param {string} [newTitle]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeTitle: function (ruleId, newTitle, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.changeTitle(ruleId, newTitle, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [ruleId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePermanentlyById: function (ruleId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deletePermanentlyById(ruleId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [ruleId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findById: function (ruleId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findById(ruleId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {any} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdObject: function (body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findByIdObject(body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMPMPhotoRuleModel} [cDAMPMPhotoRuleModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insert: function (cDAMPMPhotoRuleModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.insert(cDAMPMPhotoRuleModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<CDAMPMPhotoRuleModel>} [cDAMPMPhotoRuleModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merge: function (cDAMPMPhotoRuleModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.merge(cDAMPMPhotoRuleModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDCMGenericPagingParams} [cDCMGenericPagingParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageAll: function (cDCMGenericPagingParams, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pageAll(cDCMGenericPagingParams, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMPMPhotoRulePagingParams} [cDAMPMPhotoRulePagingParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageBy: function (cDAMPMPhotoRulePagingParams, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pageBy(cDAMPMPhotoRulePagingParams, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [ruleId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ruleByIdQuery: function (ruleId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.ruleByIdQuery(ruleId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ruleDetailsById: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.ruleDetailsById(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rulesQuery: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.rulesQuery(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMPMPhotoRuleModel} [cDAMPMPhotoRuleModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: function (cDAMPMPhotoRuleModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.save(cDAMPMPhotoRuleModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMPMPhotoRuleModel} [cDAMPMPhotoRuleModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: function (cDAMPMPhotoRuleModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.update(cDAMPMPhotoRuleModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.PhotoRuleApiFp = PhotoRuleApiFp;
/**
 * PhotoRuleApi - factory interface
 * @export
 */
var PhotoRuleApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.PhotoRuleApiFp)(configuration);
    return {
        /**
         *
         * @param {boolean} [isDeleted]
         * @param {CDAMPMPhotoRuleModel} [cDAMPMPhotoRuleModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: function (isDeleted, cDAMPMPhotoRuleModel, options) {
            return localVarFp._delete(isDeleted, cDAMPMPhotoRuleModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allRulesAnfManufacturers: function (options) {
            return localVarFp.allRulesAnfManufacturers(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [ruleId]
         * @param {string} [newTitle]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeTitle: function (ruleId, newTitle, options) {
            return localVarFp.changeTitle(ruleId, newTitle, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [ruleId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePermanentlyById: function (ruleId, options) {
            return localVarFp.deletePermanentlyById(ruleId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [ruleId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findById: function (ruleId, options) {
            return localVarFp.findById(ruleId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {any} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdObject: function (body, options) {
            return localVarFp.findByIdObject(body, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMPMPhotoRuleModel} [cDAMPMPhotoRuleModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insert: function (cDAMPMPhotoRuleModel, options) {
            return localVarFp.insert(cDAMPMPhotoRuleModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {Array<CDAMPMPhotoRuleModel>} [cDAMPMPhotoRuleModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merge: function (cDAMPMPhotoRuleModel, options) {
            return localVarFp.merge(cDAMPMPhotoRuleModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDCMGenericPagingParams} [cDCMGenericPagingParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageAll: function (cDCMGenericPagingParams, options) {
            return localVarFp.pageAll(cDCMGenericPagingParams, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMPMPhotoRulePagingParams} [cDAMPMPhotoRulePagingParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageBy: function (cDAMPMPhotoRulePagingParams, options) {
            return localVarFp.pageBy(cDAMPMPhotoRulePagingParams, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [ruleId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ruleByIdQuery: function (ruleId, options) {
            return localVarFp.ruleByIdQuery(ruleId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ruleDetailsById: function (id, options) {
            return localVarFp.ruleDetailsById(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rulesQuery: function (options) {
            return localVarFp.rulesQuery(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMPMPhotoRuleModel} [cDAMPMPhotoRuleModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: function (cDAMPMPhotoRuleModel, options) {
            return localVarFp.save(cDAMPMPhotoRuleModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMPMPhotoRuleModel} [cDAMPMPhotoRuleModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: function (cDAMPMPhotoRuleModel, options) {
            return localVarFp.update(cDAMPMPhotoRuleModel, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.PhotoRuleApiFactory = PhotoRuleApiFactory;
/**
 * PhotoRuleApi - object-oriented interface
 * @export
 * @class PhotoRuleApi
 * @extends {BaseAPI}
 */
var PhotoRuleApi = /** @class */ (function (_super) {
    __extends(PhotoRuleApi, _super);
    function PhotoRuleApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {boolean} [isDeleted]
     * @param {CDAMPMPhotoRuleModel} [cDAMPMPhotoRuleModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoRuleApi
     */
    PhotoRuleApi.prototype._delete = function (isDeleted, cDAMPMPhotoRuleModel, options) {
        var _this = this;
        return (0, exports.PhotoRuleApiFp)(this.configuration)._delete(isDeleted, cDAMPMPhotoRuleModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoRuleApi
     */
    PhotoRuleApi.prototype.allRulesAnfManufacturers = function (options) {
        var _this = this;
        return (0, exports.PhotoRuleApiFp)(this.configuration).allRulesAnfManufacturers(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [ruleId]
     * @param {string} [newTitle]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoRuleApi
     */
    PhotoRuleApi.prototype.changeTitle = function (ruleId, newTitle, options) {
        var _this = this;
        return (0, exports.PhotoRuleApiFp)(this.configuration).changeTitle(ruleId, newTitle, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [ruleId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoRuleApi
     */
    PhotoRuleApi.prototype.deletePermanentlyById = function (ruleId, options) {
        var _this = this;
        return (0, exports.PhotoRuleApiFp)(this.configuration).deletePermanentlyById(ruleId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [ruleId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoRuleApi
     */
    PhotoRuleApi.prototype.findById = function (ruleId, options) {
        var _this = this;
        return (0, exports.PhotoRuleApiFp)(this.configuration).findById(ruleId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {any} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoRuleApi
     */
    PhotoRuleApi.prototype.findByIdObject = function (body, options) {
        var _this = this;
        return (0, exports.PhotoRuleApiFp)(this.configuration).findByIdObject(body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMPMPhotoRuleModel} [cDAMPMPhotoRuleModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoRuleApi
     */
    PhotoRuleApi.prototype.insert = function (cDAMPMPhotoRuleModel, options) {
        var _this = this;
        return (0, exports.PhotoRuleApiFp)(this.configuration).insert(cDAMPMPhotoRuleModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {Array<CDAMPMPhotoRuleModel>} [cDAMPMPhotoRuleModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoRuleApi
     */
    PhotoRuleApi.prototype.merge = function (cDAMPMPhotoRuleModel, options) {
        var _this = this;
        return (0, exports.PhotoRuleApiFp)(this.configuration).merge(cDAMPMPhotoRuleModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDCMGenericPagingParams} [cDCMGenericPagingParams]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoRuleApi
     */
    PhotoRuleApi.prototype.pageAll = function (cDCMGenericPagingParams, options) {
        var _this = this;
        return (0, exports.PhotoRuleApiFp)(this.configuration).pageAll(cDCMGenericPagingParams, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMPMPhotoRulePagingParams} [cDAMPMPhotoRulePagingParams]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoRuleApi
     */
    PhotoRuleApi.prototype.pageBy = function (cDAMPMPhotoRulePagingParams, options) {
        var _this = this;
        return (0, exports.PhotoRuleApiFp)(this.configuration).pageBy(cDAMPMPhotoRulePagingParams, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [ruleId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoRuleApi
     */
    PhotoRuleApi.prototype.ruleByIdQuery = function (ruleId, options) {
        var _this = this;
        return (0, exports.PhotoRuleApiFp)(this.configuration).ruleByIdQuery(ruleId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [id]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoRuleApi
     */
    PhotoRuleApi.prototype.ruleDetailsById = function (id, options) {
        var _this = this;
        return (0, exports.PhotoRuleApiFp)(this.configuration).ruleDetailsById(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoRuleApi
     */
    PhotoRuleApi.prototype.rulesQuery = function (options) {
        var _this = this;
        return (0, exports.PhotoRuleApiFp)(this.configuration).rulesQuery(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMPMPhotoRuleModel} [cDAMPMPhotoRuleModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoRuleApi
     */
    PhotoRuleApi.prototype.save = function (cDAMPMPhotoRuleModel, options) {
        var _this = this;
        return (0, exports.PhotoRuleApiFp)(this.configuration).save(cDAMPMPhotoRuleModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMPMPhotoRuleModel} [cDAMPMPhotoRuleModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoRuleApi
     */
    PhotoRuleApi.prototype.update = function (cDAMPMPhotoRuleModel, options) {
        var _this = this;
        return (0, exports.PhotoRuleApiFp)(this.configuration).update(cDAMPMPhotoRuleModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return PhotoRuleApi;
}(base_1.BaseAPI));
exports.PhotoRuleApi = PhotoRuleApi;
/**
 * PhotoRuleManufacturerApi - axios parameter creator
 * @export
 */
var PhotoRuleManufacturerApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {boolean} [isDeleted]
         * @param {CDAMPMPhotoRuleManufacturerModel} [cDAMPMPhotoRuleManufacturerModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: function (isDeleted, cDAMPMPhotoRuleManufacturerModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/PhotoRule/PhotoRuleManufacturer/Delete";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (isDeleted !== undefined) {
                                localVarQueryParameter['isDeleted'] = isDeleted;
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMPMPhotoRuleManufacturerModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePermanentlyById: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/PhotoRule/PhotoRuleManufacturer/DeletePermanentlyById";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (id !== undefined) {
                                localVarQueryParameter['id'] = id;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {any} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdObject: function (body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/PhotoRule/PhotoRuleManufacturer/FindByIdObject";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(body, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [ruleId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByRuleId: function (ruleId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/PhotoRule/PhotoRuleManufacturer/FindByRuleId";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (ruleId !== undefined) {
                                localVarQueryParameter['ruleId'] = ruleId;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMPMPhotoRuleManufacturerModel} [cDAMPMPhotoRuleManufacturerModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insert: function (cDAMPMPhotoRuleManufacturerModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/PhotoRule/PhotoRuleManufacturer/Insert";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMPMPhotoRuleManufacturerModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<CDAMPMPhotoRuleManufacturerModel>} [cDAMPMPhotoRuleManufacturerModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merge: function (cDAMPMPhotoRuleManufacturerModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/PhotoRule/PhotoRuleManufacturer/Merge";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMPMPhotoRuleManufacturerModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDCMGenericPagingParams} [cDCMGenericPagingParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageAll: function (cDCMGenericPagingParams, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/PhotoRule/PhotoRuleManufacturer/PageAll";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDCMGenericPagingParams, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMPMPhotoRuleManufacturerModel} [cDAMPMPhotoRuleManufacturerModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: function (cDAMPMPhotoRuleManufacturerModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/PhotoRule/PhotoRuleManufacturer/Save";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMPMPhotoRuleManufacturerModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMPMPhotoRuleManufacturerModel} [cDAMPMPhotoRuleManufacturerModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: function (cDAMPMPhotoRuleManufacturerModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/PhotoRule/PhotoRuleManufacturer/Update";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMPMPhotoRuleManufacturerModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.PhotoRuleManufacturerApiAxiosParamCreator = PhotoRuleManufacturerApiAxiosParamCreator;
/**
 * PhotoRuleManufacturerApi - functional programming interface
 * @export
 */
var PhotoRuleManufacturerApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.PhotoRuleManufacturerApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {boolean} [isDeleted]
         * @param {CDAMPMPhotoRuleManufacturerModel} [cDAMPMPhotoRuleManufacturerModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: function (isDeleted, cDAMPMPhotoRuleManufacturerModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator._delete(isDeleted, cDAMPMPhotoRuleManufacturerModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePermanentlyById: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deletePermanentlyById(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {any} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdObject: function (body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findByIdObject(body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [ruleId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByRuleId: function (ruleId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findByRuleId(ruleId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMPMPhotoRuleManufacturerModel} [cDAMPMPhotoRuleManufacturerModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insert: function (cDAMPMPhotoRuleManufacturerModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.insert(cDAMPMPhotoRuleManufacturerModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<CDAMPMPhotoRuleManufacturerModel>} [cDAMPMPhotoRuleManufacturerModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merge: function (cDAMPMPhotoRuleManufacturerModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.merge(cDAMPMPhotoRuleManufacturerModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDCMGenericPagingParams} [cDCMGenericPagingParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageAll: function (cDCMGenericPagingParams, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pageAll(cDCMGenericPagingParams, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMPMPhotoRuleManufacturerModel} [cDAMPMPhotoRuleManufacturerModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: function (cDAMPMPhotoRuleManufacturerModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.save(cDAMPMPhotoRuleManufacturerModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMPMPhotoRuleManufacturerModel} [cDAMPMPhotoRuleManufacturerModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: function (cDAMPMPhotoRuleManufacturerModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.update(cDAMPMPhotoRuleManufacturerModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.PhotoRuleManufacturerApiFp = PhotoRuleManufacturerApiFp;
/**
 * PhotoRuleManufacturerApi - factory interface
 * @export
 */
var PhotoRuleManufacturerApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.PhotoRuleManufacturerApiFp)(configuration);
    return {
        /**
         *
         * @param {boolean} [isDeleted]
         * @param {CDAMPMPhotoRuleManufacturerModel} [cDAMPMPhotoRuleManufacturerModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: function (isDeleted, cDAMPMPhotoRuleManufacturerModel, options) {
            return localVarFp._delete(isDeleted, cDAMPMPhotoRuleManufacturerModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePermanentlyById: function (id, options) {
            return localVarFp.deletePermanentlyById(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {any} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdObject: function (body, options) {
            return localVarFp.findByIdObject(body, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [ruleId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByRuleId: function (ruleId, options) {
            return localVarFp.findByRuleId(ruleId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMPMPhotoRuleManufacturerModel} [cDAMPMPhotoRuleManufacturerModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insert: function (cDAMPMPhotoRuleManufacturerModel, options) {
            return localVarFp.insert(cDAMPMPhotoRuleManufacturerModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {Array<CDAMPMPhotoRuleManufacturerModel>} [cDAMPMPhotoRuleManufacturerModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merge: function (cDAMPMPhotoRuleManufacturerModel, options) {
            return localVarFp.merge(cDAMPMPhotoRuleManufacturerModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDCMGenericPagingParams} [cDCMGenericPagingParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageAll: function (cDCMGenericPagingParams, options) {
            return localVarFp.pageAll(cDCMGenericPagingParams, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMPMPhotoRuleManufacturerModel} [cDAMPMPhotoRuleManufacturerModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: function (cDAMPMPhotoRuleManufacturerModel, options) {
            return localVarFp.save(cDAMPMPhotoRuleManufacturerModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMPMPhotoRuleManufacturerModel} [cDAMPMPhotoRuleManufacturerModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: function (cDAMPMPhotoRuleManufacturerModel, options) {
            return localVarFp.update(cDAMPMPhotoRuleManufacturerModel, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.PhotoRuleManufacturerApiFactory = PhotoRuleManufacturerApiFactory;
/**
 * PhotoRuleManufacturerApi - object-oriented interface
 * @export
 * @class PhotoRuleManufacturerApi
 * @extends {BaseAPI}
 */
var PhotoRuleManufacturerApi = /** @class */ (function (_super) {
    __extends(PhotoRuleManufacturerApi, _super);
    function PhotoRuleManufacturerApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {boolean} [isDeleted]
     * @param {CDAMPMPhotoRuleManufacturerModel} [cDAMPMPhotoRuleManufacturerModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoRuleManufacturerApi
     */
    PhotoRuleManufacturerApi.prototype._delete = function (isDeleted, cDAMPMPhotoRuleManufacturerModel, options) {
        var _this = this;
        return (0, exports.PhotoRuleManufacturerApiFp)(this.configuration)._delete(isDeleted, cDAMPMPhotoRuleManufacturerModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [id]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoRuleManufacturerApi
     */
    PhotoRuleManufacturerApi.prototype.deletePermanentlyById = function (id, options) {
        var _this = this;
        return (0, exports.PhotoRuleManufacturerApiFp)(this.configuration).deletePermanentlyById(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {any} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoRuleManufacturerApi
     */
    PhotoRuleManufacturerApi.prototype.findByIdObject = function (body, options) {
        var _this = this;
        return (0, exports.PhotoRuleManufacturerApiFp)(this.configuration).findByIdObject(body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [ruleId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoRuleManufacturerApi
     */
    PhotoRuleManufacturerApi.prototype.findByRuleId = function (ruleId, options) {
        var _this = this;
        return (0, exports.PhotoRuleManufacturerApiFp)(this.configuration).findByRuleId(ruleId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMPMPhotoRuleManufacturerModel} [cDAMPMPhotoRuleManufacturerModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoRuleManufacturerApi
     */
    PhotoRuleManufacturerApi.prototype.insert = function (cDAMPMPhotoRuleManufacturerModel, options) {
        var _this = this;
        return (0, exports.PhotoRuleManufacturerApiFp)(this.configuration).insert(cDAMPMPhotoRuleManufacturerModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {Array<CDAMPMPhotoRuleManufacturerModel>} [cDAMPMPhotoRuleManufacturerModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoRuleManufacturerApi
     */
    PhotoRuleManufacturerApi.prototype.merge = function (cDAMPMPhotoRuleManufacturerModel, options) {
        var _this = this;
        return (0, exports.PhotoRuleManufacturerApiFp)(this.configuration).merge(cDAMPMPhotoRuleManufacturerModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDCMGenericPagingParams} [cDCMGenericPagingParams]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoRuleManufacturerApi
     */
    PhotoRuleManufacturerApi.prototype.pageAll = function (cDCMGenericPagingParams, options) {
        var _this = this;
        return (0, exports.PhotoRuleManufacturerApiFp)(this.configuration).pageAll(cDCMGenericPagingParams, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMPMPhotoRuleManufacturerModel} [cDAMPMPhotoRuleManufacturerModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoRuleManufacturerApi
     */
    PhotoRuleManufacturerApi.prototype.save = function (cDAMPMPhotoRuleManufacturerModel, options) {
        var _this = this;
        return (0, exports.PhotoRuleManufacturerApiFp)(this.configuration).save(cDAMPMPhotoRuleManufacturerModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMPMPhotoRuleManufacturerModel} [cDAMPMPhotoRuleManufacturerModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoRuleManufacturerApi
     */
    PhotoRuleManufacturerApi.prototype.update = function (cDAMPMPhotoRuleManufacturerModel, options) {
        var _this = this;
        return (0, exports.PhotoRuleManufacturerApiFp)(this.configuration).update(cDAMPMPhotoRuleManufacturerModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return PhotoRuleManufacturerApi;
}(base_1.BaseAPI));
exports.PhotoRuleManufacturerApi = PhotoRuleManufacturerApi;
/**
 * PhotoRuleStepApi - axios parameter creator
 * @export
 */
var PhotoRuleStepApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {boolean} [isDeleted]
         * @param {CDAMPMPhotoRuleStepModel} [cDAMPMPhotoRuleStepModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: function (isDeleted, cDAMPMPhotoRuleStepModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/PhotoRule/PhotoRuleStep/Delete";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (isDeleted !== undefined) {
                                localVarQueryParameter['isDeleted'] = isDeleted;
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMPMPhotoRuleStepModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [ruleId]
         * @param {string} [stepId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStepAndFixOrderNumbersForRule: function (ruleId, stepId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/PhotoRule/PhotoRuleStep/DeleteStepAndFixOrderNumbersForRule";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (ruleId !== undefined) {
                                localVarQueryParameter['ruleId'] = ruleId;
                            }
                            if (stepId !== undefined) {
                                localVarQueryParameter['stepId'] = stepId;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {any} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdObject: function (body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/PhotoRule/PhotoRuleStep/FindByIdObject";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(body, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [ruleId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByRuleId: function (ruleId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/PhotoRule/PhotoRuleStep/FindByRuleId";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (ruleId !== undefined) {
                                localVarQueryParameter['ruleId'] = ruleId;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMPMPhotoRuleStepModel} [cDAMPMPhotoRuleStepModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insert: function (cDAMPMPhotoRuleStepModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/PhotoRule/PhotoRuleStep/Insert";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMPMPhotoRuleStepModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<CDAMPMPhotoRuleStepModel>} [cDAMPMPhotoRuleStepModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merge: function (cDAMPMPhotoRuleStepModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/PhotoRule/PhotoRuleStep/Merge";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMPMPhotoRuleStepModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDCMGenericPagingParams} [cDCMGenericPagingParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageAll: function (cDCMGenericPagingParams, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/PhotoRule/PhotoRuleStep/PageAll";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDCMGenericPagingParams, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMPMPhotoRuleStepModel} [cDAMPMPhotoRuleStepModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: function (cDAMPMPhotoRuleStepModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/PhotoRule/PhotoRuleStep/Save";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMPMPhotoRuleStepModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<CDAMPMPhotoRuleStepOrderSave>} [cDAMPMPhotoRuleStepOrderSave]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setOrderNumbersLike: function (cDAMPMPhotoRuleStepOrderSave, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/PhotoRule/PhotoRuleStep/SetOrderNumbersLike";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMPMPhotoRuleStepOrderSave, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMPMPhotoRuleStepModel} [cDAMPMPhotoRuleStepModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: function (cDAMPMPhotoRuleStepModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/PhotoRule/PhotoRuleStep/Update";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMPMPhotoRuleStepModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.PhotoRuleStepApiAxiosParamCreator = PhotoRuleStepApiAxiosParamCreator;
/**
 * PhotoRuleStepApi - functional programming interface
 * @export
 */
var PhotoRuleStepApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.PhotoRuleStepApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {boolean} [isDeleted]
         * @param {CDAMPMPhotoRuleStepModel} [cDAMPMPhotoRuleStepModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: function (isDeleted, cDAMPMPhotoRuleStepModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator._delete(isDeleted, cDAMPMPhotoRuleStepModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [ruleId]
         * @param {string} [stepId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStepAndFixOrderNumbersForRule: function (ruleId, stepId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteStepAndFixOrderNumbersForRule(ruleId, stepId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {any} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdObject: function (body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findByIdObject(body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [ruleId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByRuleId: function (ruleId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findByRuleId(ruleId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMPMPhotoRuleStepModel} [cDAMPMPhotoRuleStepModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insert: function (cDAMPMPhotoRuleStepModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.insert(cDAMPMPhotoRuleStepModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<CDAMPMPhotoRuleStepModel>} [cDAMPMPhotoRuleStepModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merge: function (cDAMPMPhotoRuleStepModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.merge(cDAMPMPhotoRuleStepModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDCMGenericPagingParams} [cDCMGenericPagingParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageAll: function (cDCMGenericPagingParams, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pageAll(cDCMGenericPagingParams, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMPMPhotoRuleStepModel} [cDAMPMPhotoRuleStepModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: function (cDAMPMPhotoRuleStepModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.save(cDAMPMPhotoRuleStepModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<CDAMPMPhotoRuleStepOrderSave>} [cDAMPMPhotoRuleStepOrderSave]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setOrderNumbersLike: function (cDAMPMPhotoRuleStepOrderSave, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.setOrderNumbersLike(cDAMPMPhotoRuleStepOrderSave, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMPMPhotoRuleStepModel} [cDAMPMPhotoRuleStepModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: function (cDAMPMPhotoRuleStepModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.update(cDAMPMPhotoRuleStepModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.PhotoRuleStepApiFp = PhotoRuleStepApiFp;
/**
 * PhotoRuleStepApi - factory interface
 * @export
 */
var PhotoRuleStepApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.PhotoRuleStepApiFp)(configuration);
    return {
        /**
         *
         * @param {boolean} [isDeleted]
         * @param {CDAMPMPhotoRuleStepModel} [cDAMPMPhotoRuleStepModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: function (isDeleted, cDAMPMPhotoRuleStepModel, options) {
            return localVarFp._delete(isDeleted, cDAMPMPhotoRuleStepModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [ruleId]
         * @param {string} [stepId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStepAndFixOrderNumbersForRule: function (ruleId, stepId, options) {
            return localVarFp.deleteStepAndFixOrderNumbersForRule(ruleId, stepId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {any} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdObject: function (body, options) {
            return localVarFp.findByIdObject(body, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [ruleId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByRuleId: function (ruleId, options) {
            return localVarFp.findByRuleId(ruleId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMPMPhotoRuleStepModel} [cDAMPMPhotoRuleStepModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insert: function (cDAMPMPhotoRuleStepModel, options) {
            return localVarFp.insert(cDAMPMPhotoRuleStepModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {Array<CDAMPMPhotoRuleStepModel>} [cDAMPMPhotoRuleStepModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merge: function (cDAMPMPhotoRuleStepModel, options) {
            return localVarFp.merge(cDAMPMPhotoRuleStepModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDCMGenericPagingParams} [cDCMGenericPagingParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageAll: function (cDCMGenericPagingParams, options) {
            return localVarFp.pageAll(cDCMGenericPagingParams, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMPMPhotoRuleStepModel} [cDAMPMPhotoRuleStepModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: function (cDAMPMPhotoRuleStepModel, options) {
            return localVarFp.save(cDAMPMPhotoRuleStepModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {Array<CDAMPMPhotoRuleStepOrderSave>} [cDAMPMPhotoRuleStepOrderSave]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setOrderNumbersLike: function (cDAMPMPhotoRuleStepOrderSave, options) {
            return localVarFp.setOrderNumbersLike(cDAMPMPhotoRuleStepOrderSave, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMPMPhotoRuleStepModel} [cDAMPMPhotoRuleStepModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: function (cDAMPMPhotoRuleStepModel, options) {
            return localVarFp.update(cDAMPMPhotoRuleStepModel, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.PhotoRuleStepApiFactory = PhotoRuleStepApiFactory;
/**
 * PhotoRuleStepApi - object-oriented interface
 * @export
 * @class PhotoRuleStepApi
 * @extends {BaseAPI}
 */
var PhotoRuleStepApi = /** @class */ (function (_super) {
    __extends(PhotoRuleStepApi, _super);
    function PhotoRuleStepApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {boolean} [isDeleted]
     * @param {CDAMPMPhotoRuleStepModel} [cDAMPMPhotoRuleStepModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoRuleStepApi
     */
    PhotoRuleStepApi.prototype._delete = function (isDeleted, cDAMPMPhotoRuleStepModel, options) {
        var _this = this;
        return (0, exports.PhotoRuleStepApiFp)(this.configuration)._delete(isDeleted, cDAMPMPhotoRuleStepModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [ruleId]
     * @param {string} [stepId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoRuleStepApi
     */
    PhotoRuleStepApi.prototype.deleteStepAndFixOrderNumbersForRule = function (ruleId, stepId, options) {
        var _this = this;
        return (0, exports.PhotoRuleStepApiFp)(this.configuration).deleteStepAndFixOrderNumbersForRule(ruleId, stepId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {any} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoRuleStepApi
     */
    PhotoRuleStepApi.prototype.findByIdObject = function (body, options) {
        var _this = this;
        return (0, exports.PhotoRuleStepApiFp)(this.configuration).findByIdObject(body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [ruleId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoRuleStepApi
     */
    PhotoRuleStepApi.prototype.findByRuleId = function (ruleId, options) {
        var _this = this;
        return (0, exports.PhotoRuleStepApiFp)(this.configuration).findByRuleId(ruleId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMPMPhotoRuleStepModel} [cDAMPMPhotoRuleStepModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoRuleStepApi
     */
    PhotoRuleStepApi.prototype.insert = function (cDAMPMPhotoRuleStepModel, options) {
        var _this = this;
        return (0, exports.PhotoRuleStepApiFp)(this.configuration).insert(cDAMPMPhotoRuleStepModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {Array<CDAMPMPhotoRuleStepModel>} [cDAMPMPhotoRuleStepModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoRuleStepApi
     */
    PhotoRuleStepApi.prototype.merge = function (cDAMPMPhotoRuleStepModel, options) {
        var _this = this;
        return (0, exports.PhotoRuleStepApiFp)(this.configuration).merge(cDAMPMPhotoRuleStepModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDCMGenericPagingParams} [cDCMGenericPagingParams]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoRuleStepApi
     */
    PhotoRuleStepApi.prototype.pageAll = function (cDCMGenericPagingParams, options) {
        var _this = this;
        return (0, exports.PhotoRuleStepApiFp)(this.configuration).pageAll(cDCMGenericPagingParams, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMPMPhotoRuleStepModel} [cDAMPMPhotoRuleStepModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoRuleStepApi
     */
    PhotoRuleStepApi.prototype.save = function (cDAMPMPhotoRuleStepModel, options) {
        var _this = this;
        return (0, exports.PhotoRuleStepApiFp)(this.configuration).save(cDAMPMPhotoRuleStepModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {Array<CDAMPMPhotoRuleStepOrderSave>} [cDAMPMPhotoRuleStepOrderSave]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoRuleStepApi
     */
    PhotoRuleStepApi.prototype.setOrderNumbersLike = function (cDAMPMPhotoRuleStepOrderSave, options) {
        var _this = this;
        return (0, exports.PhotoRuleStepApiFp)(this.configuration).setOrderNumbersLike(cDAMPMPhotoRuleStepOrderSave, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMPMPhotoRuleStepModel} [cDAMPMPhotoRuleStepModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoRuleStepApi
     */
    PhotoRuleStepApi.prototype.update = function (cDAMPMPhotoRuleStepModel, options) {
        var _this = this;
        return (0, exports.PhotoRuleStepApiFp)(this.configuration).update(cDAMPMPhotoRuleStepModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return PhotoRuleStepApi;
}(base_1.BaseAPI));
exports.PhotoRuleStepApi = PhotoRuleStepApi;
/**
 * PhotoRuleStepTemplateApi - axios parameter creator
 * @export
 */
var PhotoRuleStepTemplateApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {boolean} [isDeleted]
         * @param {CDAMPMPhotoRuleStepTemplateModel} [cDAMPMPhotoRuleStepTemplateModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: function (isDeleted, cDAMPMPhotoRuleStepTemplateModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/PhotoRule/PhotoRuleStepTemplate/Delete";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (isDeleted !== undefined) {
                                localVarQueryParameter['isDeleted'] = isDeleted;
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMPMPhotoRuleStepTemplateModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [templateId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePermanentlyById: function (templateId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/PhotoRule/PhotoRuleStepTemplate/DeletePermanentlyById";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (templateId !== undefined) {
                                localVarQueryParameter['templateId'] = templateId;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [templateId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findById: function (templateId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/PhotoRule/PhotoRuleStepTemplate/FindById";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (templateId !== undefined) {
                                localVarQueryParameter['templateId'] = templateId;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {any} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdObject: function (body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/PhotoRule/PhotoRuleStepTemplate/FindByIdObject";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(body, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<string>} [requestBody]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTemplatesForStepIds: function (requestBody, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/PhotoRule/PhotoRuleStepTemplate/FindTemplatesForStepIds";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(requestBody, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMPMPhotoRuleStepTemplateModel} [cDAMPMPhotoRuleStepTemplateModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insert: function (cDAMPMPhotoRuleStepTemplateModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/PhotoRule/PhotoRuleStepTemplate/Insert";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMPMPhotoRuleStepTemplateModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<CDAMPMPhotoRuleStepTemplateModel>} [cDAMPMPhotoRuleStepTemplateModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merge: function (cDAMPMPhotoRuleStepTemplateModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/PhotoRule/PhotoRuleStepTemplate/Merge";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMPMPhotoRuleStepTemplateModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDCMGenericPagingParams} [cDCMGenericPagingParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageAll: function (cDCMGenericPagingParams, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/PhotoRule/PhotoRuleStepTemplate/PageAll";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDCMGenericPagingParams, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMPMPhotoRuleStepTemplateModel} [cDAMPMPhotoRuleStepTemplateModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: function (cDAMPMPhotoRuleStepTemplateModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/PhotoRule/PhotoRuleStepTemplate/Save";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMPMPhotoRuleStepTemplateModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMPMPhotoRuleStepTemplateModel} [cDAMPMPhotoRuleStepTemplateModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: function (cDAMPMPhotoRuleStepTemplateModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/PhotoRule/PhotoRuleStepTemplate/Update";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMPMPhotoRuleStepTemplateModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.PhotoRuleStepTemplateApiAxiosParamCreator = PhotoRuleStepTemplateApiAxiosParamCreator;
/**
 * PhotoRuleStepTemplateApi - functional programming interface
 * @export
 */
var PhotoRuleStepTemplateApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.PhotoRuleStepTemplateApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {boolean} [isDeleted]
         * @param {CDAMPMPhotoRuleStepTemplateModel} [cDAMPMPhotoRuleStepTemplateModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: function (isDeleted, cDAMPMPhotoRuleStepTemplateModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator._delete(isDeleted, cDAMPMPhotoRuleStepTemplateModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [templateId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePermanentlyById: function (templateId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deletePermanentlyById(templateId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [templateId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findById: function (templateId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findById(templateId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {any} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdObject: function (body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findByIdObject(body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<string>} [requestBody]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTemplatesForStepIds: function (requestBody, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findTemplatesForStepIds(requestBody, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMPMPhotoRuleStepTemplateModel} [cDAMPMPhotoRuleStepTemplateModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insert: function (cDAMPMPhotoRuleStepTemplateModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.insert(cDAMPMPhotoRuleStepTemplateModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<CDAMPMPhotoRuleStepTemplateModel>} [cDAMPMPhotoRuleStepTemplateModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merge: function (cDAMPMPhotoRuleStepTemplateModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.merge(cDAMPMPhotoRuleStepTemplateModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDCMGenericPagingParams} [cDCMGenericPagingParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageAll: function (cDCMGenericPagingParams, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pageAll(cDCMGenericPagingParams, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMPMPhotoRuleStepTemplateModel} [cDAMPMPhotoRuleStepTemplateModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: function (cDAMPMPhotoRuleStepTemplateModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.save(cDAMPMPhotoRuleStepTemplateModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMPMPhotoRuleStepTemplateModel} [cDAMPMPhotoRuleStepTemplateModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: function (cDAMPMPhotoRuleStepTemplateModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.update(cDAMPMPhotoRuleStepTemplateModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.PhotoRuleStepTemplateApiFp = PhotoRuleStepTemplateApiFp;
/**
 * PhotoRuleStepTemplateApi - factory interface
 * @export
 */
var PhotoRuleStepTemplateApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.PhotoRuleStepTemplateApiFp)(configuration);
    return {
        /**
         *
         * @param {boolean} [isDeleted]
         * @param {CDAMPMPhotoRuleStepTemplateModel} [cDAMPMPhotoRuleStepTemplateModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: function (isDeleted, cDAMPMPhotoRuleStepTemplateModel, options) {
            return localVarFp._delete(isDeleted, cDAMPMPhotoRuleStepTemplateModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [templateId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePermanentlyById: function (templateId, options) {
            return localVarFp.deletePermanentlyById(templateId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [templateId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findById: function (templateId, options) {
            return localVarFp.findById(templateId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {any} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdObject: function (body, options) {
            return localVarFp.findByIdObject(body, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {Array<string>} [requestBody]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTemplatesForStepIds: function (requestBody, options) {
            return localVarFp.findTemplatesForStepIds(requestBody, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMPMPhotoRuleStepTemplateModel} [cDAMPMPhotoRuleStepTemplateModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insert: function (cDAMPMPhotoRuleStepTemplateModel, options) {
            return localVarFp.insert(cDAMPMPhotoRuleStepTemplateModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {Array<CDAMPMPhotoRuleStepTemplateModel>} [cDAMPMPhotoRuleStepTemplateModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merge: function (cDAMPMPhotoRuleStepTemplateModel, options) {
            return localVarFp.merge(cDAMPMPhotoRuleStepTemplateModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDCMGenericPagingParams} [cDCMGenericPagingParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageAll: function (cDCMGenericPagingParams, options) {
            return localVarFp.pageAll(cDCMGenericPagingParams, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMPMPhotoRuleStepTemplateModel} [cDAMPMPhotoRuleStepTemplateModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: function (cDAMPMPhotoRuleStepTemplateModel, options) {
            return localVarFp.save(cDAMPMPhotoRuleStepTemplateModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMPMPhotoRuleStepTemplateModel} [cDAMPMPhotoRuleStepTemplateModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: function (cDAMPMPhotoRuleStepTemplateModel, options) {
            return localVarFp.update(cDAMPMPhotoRuleStepTemplateModel, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.PhotoRuleStepTemplateApiFactory = PhotoRuleStepTemplateApiFactory;
/**
 * PhotoRuleStepTemplateApi - object-oriented interface
 * @export
 * @class PhotoRuleStepTemplateApi
 * @extends {BaseAPI}
 */
var PhotoRuleStepTemplateApi = /** @class */ (function (_super) {
    __extends(PhotoRuleStepTemplateApi, _super);
    function PhotoRuleStepTemplateApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {boolean} [isDeleted]
     * @param {CDAMPMPhotoRuleStepTemplateModel} [cDAMPMPhotoRuleStepTemplateModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoRuleStepTemplateApi
     */
    PhotoRuleStepTemplateApi.prototype._delete = function (isDeleted, cDAMPMPhotoRuleStepTemplateModel, options) {
        var _this = this;
        return (0, exports.PhotoRuleStepTemplateApiFp)(this.configuration)._delete(isDeleted, cDAMPMPhotoRuleStepTemplateModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [templateId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoRuleStepTemplateApi
     */
    PhotoRuleStepTemplateApi.prototype.deletePermanentlyById = function (templateId, options) {
        var _this = this;
        return (0, exports.PhotoRuleStepTemplateApiFp)(this.configuration).deletePermanentlyById(templateId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [templateId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoRuleStepTemplateApi
     */
    PhotoRuleStepTemplateApi.prototype.findById = function (templateId, options) {
        var _this = this;
        return (0, exports.PhotoRuleStepTemplateApiFp)(this.configuration).findById(templateId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {any} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoRuleStepTemplateApi
     */
    PhotoRuleStepTemplateApi.prototype.findByIdObject = function (body, options) {
        var _this = this;
        return (0, exports.PhotoRuleStepTemplateApiFp)(this.configuration).findByIdObject(body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {Array<string>} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoRuleStepTemplateApi
     */
    PhotoRuleStepTemplateApi.prototype.findTemplatesForStepIds = function (requestBody, options) {
        var _this = this;
        return (0, exports.PhotoRuleStepTemplateApiFp)(this.configuration).findTemplatesForStepIds(requestBody, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMPMPhotoRuleStepTemplateModel} [cDAMPMPhotoRuleStepTemplateModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoRuleStepTemplateApi
     */
    PhotoRuleStepTemplateApi.prototype.insert = function (cDAMPMPhotoRuleStepTemplateModel, options) {
        var _this = this;
        return (0, exports.PhotoRuleStepTemplateApiFp)(this.configuration).insert(cDAMPMPhotoRuleStepTemplateModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {Array<CDAMPMPhotoRuleStepTemplateModel>} [cDAMPMPhotoRuleStepTemplateModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoRuleStepTemplateApi
     */
    PhotoRuleStepTemplateApi.prototype.merge = function (cDAMPMPhotoRuleStepTemplateModel, options) {
        var _this = this;
        return (0, exports.PhotoRuleStepTemplateApiFp)(this.configuration).merge(cDAMPMPhotoRuleStepTemplateModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDCMGenericPagingParams} [cDCMGenericPagingParams]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoRuleStepTemplateApi
     */
    PhotoRuleStepTemplateApi.prototype.pageAll = function (cDCMGenericPagingParams, options) {
        var _this = this;
        return (0, exports.PhotoRuleStepTemplateApiFp)(this.configuration).pageAll(cDCMGenericPagingParams, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMPMPhotoRuleStepTemplateModel} [cDAMPMPhotoRuleStepTemplateModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoRuleStepTemplateApi
     */
    PhotoRuleStepTemplateApi.prototype.save = function (cDAMPMPhotoRuleStepTemplateModel, options) {
        var _this = this;
        return (0, exports.PhotoRuleStepTemplateApiFp)(this.configuration).save(cDAMPMPhotoRuleStepTemplateModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMPMPhotoRuleStepTemplateModel} [cDAMPMPhotoRuleStepTemplateModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoRuleStepTemplateApi
     */
    PhotoRuleStepTemplateApi.prototype.update = function (cDAMPMPhotoRuleStepTemplateModel, options) {
        var _this = this;
        return (0, exports.PhotoRuleStepTemplateApiFp)(this.configuration).update(cDAMPMPhotoRuleStepTemplateModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return PhotoRuleStepTemplateApi;
}(base_1.BaseAPI));
exports.PhotoRuleStepTemplateApi = PhotoRuleStepTemplateApi;
/**
 * PublicHeartbeatApi - axios parameter creator
 * @export
 */
var PublicHeartbeatApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        heartbeat: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Generic/PublicHeartbeat/Heartbeat";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.PublicHeartbeatApiAxiosParamCreator = PublicHeartbeatApiAxiosParamCreator;
/**
 * PublicHeartbeatApi - functional programming interface
 * @export
 */
var PublicHeartbeatApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.PublicHeartbeatApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        heartbeat: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.heartbeat(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.PublicHeartbeatApiFp = PublicHeartbeatApiFp;
/**
 * PublicHeartbeatApi - factory interface
 * @export
 */
var PublicHeartbeatApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.PublicHeartbeatApiFp)(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        heartbeat: function (options) {
            return localVarFp.heartbeat(options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.PublicHeartbeatApiFactory = PublicHeartbeatApiFactory;
/**
 * PublicHeartbeatApi - object-oriented interface
 * @export
 * @class PublicHeartbeatApi
 * @extends {BaseAPI}
 */
var PublicHeartbeatApi = /** @class */ (function (_super) {
    __extends(PublicHeartbeatApi, _super);
    function PublicHeartbeatApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicHeartbeatApi
     */
    PublicHeartbeatApi.prototype.heartbeat = function (options) {
        var _this = this;
        return (0, exports.PublicHeartbeatApiFp)(this.configuration).heartbeat(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return PublicHeartbeatApi;
}(base_1.BaseAPI));
exports.PublicHeartbeatApi = PublicHeartbeatApi;
/**
 * SapCallTypeApi - axios parameter creator
 * @export
 */
var SapCallTypeApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Shared/SapCallType/FindAll";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.SapCallTypeApiAxiosParamCreator = SapCallTypeApiAxiosParamCreator;
/**
 * SapCallTypeApi - functional programming interface
 * @export
 */
var SapCallTypeApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.SapCallTypeApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findAll(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.SapCallTypeApiFp = SapCallTypeApiFp;
/**
 * SapCallTypeApi - factory interface
 * @export
 */
var SapCallTypeApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.SapCallTypeApiFp)(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: function (options) {
            return localVarFp.findAll(options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.SapCallTypeApiFactory = SapCallTypeApiFactory;
/**
 * SapCallTypeApi - object-oriented interface
 * @export
 * @class SapCallTypeApi
 * @extends {BaseAPI}
 */
var SapCallTypeApi = /** @class */ (function (_super) {
    __extends(SapCallTypeApi, _super);
    function SapCallTypeApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SapCallTypeApi
     */
    SapCallTypeApi.prototype.findAll = function (options) {
        var _this = this;
        return (0, exports.SapCallTypeApiFp)(this.configuration).findAll(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return SapCallTypeApi;
}(base_1.BaseAPI));
exports.SapCallTypeApi = SapCallTypeApi;
/**
 * SapCostRateApi - axios parameter creator
 * @export
 */
var SapCostRateApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Shared/SapCostRate/FindAll";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.SapCostRateApiAxiosParamCreator = SapCostRateApiAxiosParamCreator;
/**
 * SapCostRateApi - functional programming interface
 * @export
 */
var SapCostRateApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.SapCostRateApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findAll(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.SapCostRateApiFp = SapCostRateApiFp;
/**
 * SapCostRateApi - factory interface
 * @export
 */
var SapCostRateApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.SapCostRateApiFp)(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: function (options) {
            return localVarFp.findAll(options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.SapCostRateApiFactory = SapCostRateApiFactory;
/**
 * SapCostRateApi - object-oriented interface
 * @export
 * @class SapCostRateApi
 * @extends {BaseAPI}
 */
var SapCostRateApi = /** @class */ (function (_super) {
    __extends(SapCostRateApi, _super);
    function SapCostRateApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SapCostRateApi
     */
    SapCostRateApi.prototype.findAll = function (options) {
        var _this = this;
        return (0, exports.SapCostRateApiFp)(this.configuration).findAll(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return SapCostRateApi;
}(base_1.BaseAPI));
exports.SapCostRateApi = SapCostRateApi;
/**
 * SapDeliveryApi - axios parameter creator
 * @export
 */
var SapDeliveryApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {CDSMDeliveryOrDraftExistsParams} [cDSMDeliveryOrDraftExistsParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exists: function (cDSMDeliveryOrDraftExistsParams, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Shared/SapDelivery/Exists";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDSMDeliveryOrDraftExistsParams, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.SapDeliveryApiAxiosParamCreator = SapDeliveryApiAxiosParamCreator;
/**
 * SapDeliveryApi - functional programming interface
 * @export
 */
var SapDeliveryApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.SapDeliveryApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {CDSMDeliveryOrDraftExistsParams} [cDSMDeliveryOrDraftExistsParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exists: function (cDSMDeliveryOrDraftExistsParams, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.exists(cDSMDeliveryOrDraftExistsParams, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.SapDeliveryApiFp = SapDeliveryApiFp;
/**
 * SapDeliveryApi - factory interface
 * @export
 */
var SapDeliveryApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.SapDeliveryApiFp)(configuration);
    return {
        /**
         *
         * @param {CDSMDeliveryOrDraftExistsParams} [cDSMDeliveryOrDraftExistsParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exists: function (cDSMDeliveryOrDraftExistsParams, options) {
            return localVarFp.exists(cDSMDeliveryOrDraftExistsParams, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.SapDeliveryApiFactory = SapDeliveryApiFactory;
/**
 * SapDeliveryApi - object-oriented interface
 * @export
 * @class SapDeliveryApi
 * @extends {BaseAPI}
 */
var SapDeliveryApi = /** @class */ (function (_super) {
    __extends(SapDeliveryApi, _super);
    function SapDeliveryApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {CDSMDeliveryOrDraftExistsParams} [cDSMDeliveryOrDraftExistsParams]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SapDeliveryApi
     */
    SapDeliveryApi.prototype.exists = function (cDSMDeliveryOrDraftExistsParams, options) {
        var _this = this;
        return (0, exports.SapDeliveryApiFp)(this.configuration).exists(cDSMDeliveryOrDraftExistsParams, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return SapDeliveryApi;
}(base_1.BaseAPI));
exports.SapDeliveryApi = SapDeliveryApi;
/**
 * SapEmployeeApi - axios parameter creator
 * @export
 */
var SapEmployeeApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Shared/SapEmployee/FindAll";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.SapEmployeeApiAxiosParamCreator = SapEmployeeApiAxiosParamCreator;
/**
 * SapEmployeeApi - functional programming interface
 * @export
 */
var SapEmployeeApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.SapEmployeeApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findAll(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.SapEmployeeApiFp = SapEmployeeApiFp;
/**
 * SapEmployeeApi - factory interface
 * @export
 */
var SapEmployeeApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.SapEmployeeApiFp)(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: function (options) {
            return localVarFp.findAll(options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.SapEmployeeApiFactory = SapEmployeeApiFactory;
/**
 * SapEmployeeApi - object-oriented interface
 * @export
 * @class SapEmployeeApi
 * @extends {BaseAPI}
 */
var SapEmployeeApi = /** @class */ (function (_super) {
    __extends(SapEmployeeApi, _super);
    function SapEmployeeApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SapEmployeeApi
     */
    SapEmployeeApi.prototype.findAll = function (options) {
        var _this = this;
        return (0, exports.SapEmployeeApiFp)(this.configuration).findAll(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return SapEmployeeApi;
}(base_1.BaseAPI));
exports.SapEmployeeApi = SapEmployeeApi;
/**
 * SapEquipmentImagesApi - axios parameter creator
 * @export
 */
var SapEquipmentImagesApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {number} [sapEquipmentId]
         * @param {string} [modSapEquipmentId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findBy: function (sapEquipmentId, modSapEquipmentId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Equipment/SapEquipmentImages/FindBy";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (sapEquipmentId !== undefined) {
                                localVarQueryParameter['sapEquipmentId'] = sapEquipmentId;
                            }
                            if (modSapEquipmentId !== undefined) {
                                localVarQueryParameter['modSapEquipmentId'] = modSapEquipmentId;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.SapEquipmentImagesApiAxiosParamCreator = SapEquipmentImagesApiAxiosParamCreator;
/**
 * SapEquipmentImagesApi - functional programming interface
 * @export
 */
var SapEquipmentImagesApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.SapEquipmentImagesApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {number} [sapEquipmentId]
         * @param {string} [modSapEquipmentId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findBy: function (sapEquipmentId, modSapEquipmentId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findBy(sapEquipmentId, modSapEquipmentId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.SapEquipmentImagesApiFp = SapEquipmentImagesApiFp;
/**
 * SapEquipmentImagesApi - factory interface
 * @export
 */
var SapEquipmentImagesApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.SapEquipmentImagesApiFp)(configuration);
    return {
        /**
         *
         * @param {number} [sapEquipmentId]
         * @param {string} [modSapEquipmentId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findBy: function (sapEquipmentId, modSapEquipmentId, options) {
            return localVarFp.findBy(sapEquipmentId, modSapEquipmentId, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.SapEquipmentImagesApiFactory = SapEquipmentImagesApiFactory;
/**
 * SapEquipmentImagesApi - object-oriented interface
 * @export
 * @class SapEquipmentImagesApi
 * @extends {BaseAPI}
 */
var SapEquipmentImagesApi = /** @class */ (function (_super) {
    __extends(SapEquipmentImagesApi, _super);
    function SapEquipmentImagesApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {number} [sapEquipmentId]
     * @param {string} [modSapEquipmentId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SapEquipmentImagesApi
     */
    SapEquipmentImagesApi.prototype.findBy = function (sapEquipmentId, modSapEquipmentId, options) {
        var _this = this;
        return (0, exports.SapEquipmentImagesApiFp)(this.configuration).findBy(sapEquipmentId, modSapEquipmentId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return SapEquipmentImagesApi;
}(base_1.BaseAPI));
exports.SapEquipmentImagesApi = SapEquipmentImagesApi;
/**
 * SapEquipmentSearchApi - axios parameter creator
 * @export
 */
var SapEquipmentSearchApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {CDAMEMSapEquipmentSearchParameters} [cDAMEMSapEquipmentSearchParameters]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPage: function (cDAMEMSapEquipmentSearchParameters, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Equipment/SapEquipmentSearch/FindPage";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMEMSapEquipmentSearchParameters, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.SapEquipmentSearchApiAxiosParamCreator = SapEquipmentSearchApiAxiosParamCreator;
/**
 * SapEquipmentSearchApi - functional programming interface
 * @export
 */
var SapEquipmentSearchApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.SapEquipmentSearchApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {CDAMEMSapEquipmentSearchParameters} [cDAMEMSapEquipmentSearchParameters]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPage: function (cDAMEMSapEquipmentSearchParameters, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findPage(cDAMEMSapEquipmentSearchParameters, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.SapEquipmentSearchApiFp = SapEquipmentSearchApiFp;
/**
 * SapEquipmentSearchApi - factory interface
 * @export
 */
var SapEquipmentSearchApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.SapEquipmentSearchApiFp)(configuration);
    return {
        /**
         *
         * @param {CDAMEMSapEquipmentSearchParameters} [cDAMEMSapEquipmentSearchParameters]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPage: function (cDAMEMSapEquipmentSearchParameters, options) {
            return localVarFp.findPage(cDAMEMSapEquipmentSearchParameters, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.SapEquipmentSearchApiFactory = SapEquipmentSearchApiFactory;
/**
 * SapEquipmentSearchApi - object-oriented interface
 * @export
 * @class SapEquipmentSearchApi
 * @extends {BaseAPI}
 */
var SapEquipmentSearchApi = /** @class */ (function (_super) {
    __extends(SapEquipmentSearchApi, _super);
    function SapEquipmentSearchApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {CDAMEMSapEquipmentSearchParameters} [cDAMEMSapEquipmentSearchParameters]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SapEquipmentSearchApi
     */
    SapEquipmentSearchApi.prototype.findPage = function (cDAMEMSapEquipmentSearchParameters, options) {
        var _this = this;
        return (0, exports.SapEquipmentSearchApiFp)(this.configuration).findPage(cDAMEMSapEquipmentSearchParameters, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return SapEquipmentSearchApi;
}(base_1.BaseAPI));
exports.SapEquipmentSearchApi = SapEquipmentSearchApi;
/**
 * SapItemApi - axios parameter creator
 * @export
 */
var SapItemApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {string} [itemCode]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        findByItemCode: function (itemCode, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Shared/SapItem/FindByItemCode";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (itemCode !== undefined) {
                                localVarQueryParameter['itemCode'] = itemCode;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDSMPSapItemPagingParams} [cDSMPSapItemPagingParams]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        searchPageBy: function (cDSMPSapItemPagingParams, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Shared/SapItem/SearchPageBy";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDSMPSapItemPagingParams, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDSMPSapItemPagingParams} [cDSMPSapItemPagingParams]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        searchPageWithUrisBy: function (cDSMPSapItemPagingParams, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Shared/SapItem/SearchPageWithUrisBy";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDSMPSapItemPagingParams, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDSMSSapItemForWorksheetSearchQueryParams} [cDSMSSapItemForWorksheetSearchQueryParams]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        searchQueryBy: function (cDSMSSapItemForWorksheetSearchQueryParams, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Shared/SapItem/SearchQueryBy";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDSMSSapItemForWorksheetSearchQueryParams, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.SapItemApiAxiosParamCreator = SapItemApiAxiosParamCreator;
/**
 * SapItemApi - functional programming interface
 * @export
 */
var SapItemApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.SapItemApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {string} [itemCode]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        findByItemCode: function (itemCode, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findByItemCode(itemCode, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDSMPSapItemPagingParams} [cDSMPSapItemPagingParams]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        searchPageBy: function (cDSMPSapItemPagingParams, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.searchPageBy(cDSMPSapItemPagingParams, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDSMPSapItemPagingParams} [cDSMPSapItemPagingParams]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        searchPageWithUrisBy: function (cDSMPSapItemPagingParams, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.searchPageWithUrisBy(cDSMPSapItemPagingParams, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDSMSSapItemForWorksheetSearchQueryParams} [cDSMSSapItemForWorksheetSearchQueryParams]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        searchQueryBy: function (cDSMSSapItemForWorksheetSearchQueryParams, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.searchQueryBy(cDSMSSapItemForWorksheetSearchQueryParams, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.SapItemApiFp = SapItemApiFp;
/**
 * SapItemApi - factory interface
 * @export
 */
var SapItemApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.SapItemApiFp)(configuration);
    return {
        /**
         *
         * @param {string} [itemCode]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        findByItemCode: function (itemCode, options) {
            return localVarFp.findByItemCode(itemCode, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDSMPSapItemPagingParams} [cDSMPSapItemPagingParams]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        searchPageBy: function (cDSMPSapItemPagingParams, options) {
            return localVarFp.searchPageBy(cDSMPSapItemPagingParams, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDSMPSapItemPagingParams} [cDSMPSapItemPagingParams]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        searchPageWithUrisBy: function (cDSMPSapItemPagingParams, options) {
            return localVarFp.searchPageWithUrisBy(cDSMPSapItemPagingParams, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDSMSSapItemForWorksheetSearchQueryParams} [cDSMSSapItemForWorksheetSearchQueryParams]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        searchQueryBy: function (cDSMSSapItemForWorksheetSearchQueryParams, options) {
            return localVarFp.searchQueryBy(cDSMSSapItemForWorksheetSearchQueryParams, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.SapItemApiFactory = SapItemApiFactory;
/**
 * SapItemApi - object-oriented interface
 * @export
 * @class SapItemApi
 * @extends {BaseAPI}
 */
var SapItemApi = /** @class */ (function (_super) {
    __extends(SapItemApi, _super);
    function SapItemApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {string} [itemCode]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof SapItemApi
     */
    SapItemApi.prototype.findByItemCode = function (itemCode, options) {
        var _this = this;
        return (0, exports.SapItemApiFp)(this.configuration).findByItemCode(itemCode, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDSMPSapItemPagingParams} [cDSMPSapItemPagingParams]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof SapItemApi
     */
    SapItemApi.prototype.searchPageBy = function (cDSMPSapItemPagingParams, options) {
        var _this = this;
        return (0, exports.SapItemApiFp)(this.configuration).searchPageBy(cDSMPSapItemPagingParams, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDSMPSapItemPagingParams} [cDSMPSapItemPagingParams]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof SapItemApi
     */
    SapItemApi.prototype.searchPageWithUrisBy = function (cDSMPSapItemPagingParams, options) {
        var _this = this;
        return (0, exports.SapItemApiFp)(this.configuration).searchPageWithUrisBy(cDSMPSapItemPagingParams, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDSMSSapItemForWorksheetSearchQueryParams} [cDSMSSapItemForWorksheetSearchQueryParams]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof SapItemApi
     */
    SapItemApi.prototype.searchQueryBy = function (cDSMSSapItemForWorksheetSearchQueryParams, options) {
        var _this = this;
        return (0, exports.SapItemApiFp)(this.configuration).searchQueryBy(cDSMSSapItemForWorksheetSearchQueryParams, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return SapItemApi;
}(base_1.BaseAPI));
exports.SapItemApi = SapItemApi;
/**
 * SapPartnerApi - axios parameter creator
 * @export
 */
var SapPartnerApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {string} [cardCode]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByCardCode: function (cardCode, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Shared/SapPartner/FindByCardCode";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (cardCode !== undefined) {
                                localVarQueryParameter['cardCode'] = cardCode;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [cardCode]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByCardCodeForWorksheet: function (cardCode, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Shared/SapPartner/FindByCardCodeForWorksheet";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (cardCode !== undefined) {
                                localVarQueryParameter['cardCode'] = cardCode;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<string>} [requestBody]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByCardCodeIn: function (requestBody, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Shared/SapPartner/FindByCardCodeIn";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(requestBody, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDSMPSapPartnerSearchPagingParams} [cDSMPSapPartnerSearchPagingParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPageBy: function (cDSMPSapPartnerSearchPagingParams, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Shared/SapPartner/SearchPageBy";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDSMPSapPartnerSearchPagingParams, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.SapPartnerApiAxiosParamCreator = SapPartnerApiAxiosParamCreator;
/**
 * SapPartnerApi - functional programming interface
 * @export
 */
var SapPartnerApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.SapPartnerApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {string} [cardCode]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByCardCode: function (cardCode, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findByCardCode(cardCode, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [cardCode]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByCardCodeForWorksheet: function (cardCode, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findByCardCodeForWorksheet(cardCode, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<string>} [requestBody]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByCardCodeIn: function (requestBody, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findByCardCodeIn(requestBody, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDSMPSapPartnerSearchPagingParams} [cDSMPSapPartnerSearchPagingParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPageBy: function (cDSMPSapPartnerSearchPagingParams, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.searchPageBy(cDSMPSapPartnerSearchPagingParams, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.SapPartnerApiFp = SapPartnerApiFp;
/**
 * SapPartnerApi - factory interface
 * @export
 */
var SapPartnerApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.SapPartnerApiFp)(configuration);
    return {
        /**
         *
         * @param {string} [cardCode]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByCardCode: function (cardCode, options) {
            return localVarFp.findByCardCode(cardCode, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [cardCode]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByCardCodeForWorksheet: function (cardCode, options) {
            return localVarFp.findByCardCodeForWorksheet(cardCode, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {Array<string>} [requestBody]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByCardCodeIn: function (requestBody, options) {
            return localVarFp.findByCardCodeIn(requestBody, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDSMPSapPartnerSearchPagingParams} [cDSMPSapPartnerSearchPagingParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPageBy: function (cDSMPSapPartnerSearchPagingParams, options) {
            return localVarFp.searchPageBy(cDSMPSapPartnerSearchPagingParams, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.SapPartnerApiFactory = SapPartnerApiFactory;
/**
 * SapPartnerApi - object-oriented interface
 * @export
 * @class SapPartnerApi
 * @extends {BaseAPI}
 */
var SapPartnerApi = /** @class */ (function (_super) {
    __extends(SapPartnerApi, _super);
    function SapPartnerApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {string} [cardCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SapPartnerApi
     */
    SapPartnerApi.prototype.findByCardCode = function (cardCode, options) {
        var _this = this;
        return (0, exports.SapPartnerApiFp)(this.configuration).findByCardCode(cardCode, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [cardCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SapPartnerApi
     */
    SapPartnerApi.prototype.findByCardCodeForWorksheet = function (cardCode, options) {
        var _this = this;
        return (0, exports.SapPartnerApiFp)(this.configuration).findByCardCodeForWorksheet(cardCode, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {Array<string>} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SapPartnerApi
     */
    SapPartnerApi.prototype.findByCardCodeIn = function (requestBody, options) {
        var _this = this;
        return (0, exports.SapPartnerApiFp)(this.configuration).findByCardCodeIn(requestBody, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDSMPSapPartnerSearchPagingParams} [cDSMPSapPartnerSearchPagingParams]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SapPartnerApi
     */
    SapPartnerApi.prototype.searchPageBy = function (cDSMPSapPartnerSearchPagingParams, options) {
        var _this = this;
        return (0, exports.SapPartnerApiFp)(this.configuration).searchPageBy(cDSMPSapPartnerSearchPagingParams, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return SapPartnerApi;
}(base_1.BaseAPI));
exports.SapPartnerApi = SapPartnerApi;
/**
 * SapPartnerAddressApi - axios parameter creator
 * @export
 */
var SapPartnerAddressApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Shared/SapPartnerAddress/FindAll";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [cardCode]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByCardCode: function (cardCode, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Shared/SapPartnerAddress/FindByCardCode";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (cardCode !== undefined) {
                                localVarQueryParameter['cardCode'] = cardCode;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.SapPartnerAddressApiAxiosParamCreator = SapPartnerAddressApiAxiosParamCreator;
/**
 * SapPartnerAddressApi - functional programming interface
 * @export
 */
var SapPartnerAddressApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.SapPartnerAddressApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findAll(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [cardCode]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByCardCode: function (cardCode, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findByCardCode(cardCode, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.SapPartnerAddressApiFp = SapPartnerAddressApiFp;
/**
 * SapPartnerAddressApi - factory interface
 * @export
 */
var SapPartnerAddressApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.SapPartnerAddressApiFp)(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: function (options) {
            return localVarFp.findAll(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [cardCode]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByCardCode: function (cardCode, options) {
            return localVarFp.findByCardCode(cardCode, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.SapPartnerAddressApiFactory = SapPartnerAddressApiFactory;
/**
 * SapPartnerAddressApi - object-oriented interface
 * @export
 * @class SapPartnerAddressApi
 * @extends {BaseAPI}
 */
var SapPartnerAddressApi = /** @class */ (function (_super) {
    __extends(SapPartnerAddressApi, _super);
    function SapPartnerAddressApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SapPartnerAddressApi
     */
    SapPartnerAddressApi.prototype.findAll = function (options) {
        var _this = this;
        return (0, exports.SapPartnerAddressApiFp)(this.configuration).findAll(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [cardCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SapPartnerAddressApi
     */
    SapPartnerAddressApi.prototype.findByCardCode = function (cardCode, options) {
        var _this = this;
        return (0, exports.SapPartnerAddressApiFp)(this.configuration).findByCardCode(cardCode, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return SapPartnerAddressApi;
}(base_1.BaseAPI));
exports.SapPartnerAddressApi = SapPartnerAddressApi;
/**
 * SapPartnerContactApi - axios parameter creator
 * @export
 */
var SapPartnerContactApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Shared/SapPartnerContact/FindAll";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [cardCode]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByCardCode: function (cardCode, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Shared/SapPartnerContact/FindByCardCode";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (cardCode !== undefined) {
                                localVarQueryParameter['cardCode'] = cardCode;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.SapPartnerContactApiAxiosParamCreator = SapPartnerContactApiAxiosParamCreator;
/**
 * SapPartnerContactApi - functional programming interface
 * @export
 */
var SapPartnerContactApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.SapPartnerContactApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findAll(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [cardCode]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByCardCode: function (cardCode, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findByCardCode(cardCode, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.SapPartnerContactApiFp = SapPartnerContactApiFp;
/**
 * SapPartnerContactApi - factory interface
 * @export
 */
var SapPartnerContactApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.SapPartnerContactApiFp)(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: function (options) {
            return localVarFp.findAll(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [cardCode]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByCardCode: function (cardCode, options) {
            return localVarFp.findByCardCode(cardCode, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.SapPartnerContactApiFactory = SapPartnerContactApiFactory;
/**
 * SapPartnerContactApi - object-oriented interface
 * @export
 * @class SapPartnerContactApi
 * @extends {BaseAPI}
 */
var SapPartnerContactApi = /** @class */ (function (_super) {
    __extends(SapPartnerContactApi, _super);
    function SapPartnerContactApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SapPartnerContactApi
     */
    SapPartnerContactApi.prototype.findAll = function (options) {
        var _this = this;
        return (0, exports.SapPartnerContactApiFp)(this.configuration).findAll(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [cardCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SapPartnerContactApi
     */
    SapPartnerContactApi.prototype.findByCardCode = function (cardCode, options) {
        var _this = this;
        return (0, exports.SapPartnerContactApiFp)(this.configuration).findByCardCode(cardCode, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return SapPartnerContactApi;
}(base_1.BaseAPI));
exports.SapPartnerContactApi = SapPartnerContactApi;
/**
 * SapPartnerEquipmentApi - axios parameter creator
 * @export
 */
var SapPartnerEquipmentApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {string} [cardCode]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByCardCode: function (cardCode, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Shared/SapPartnerEquipment/FindByCardCode";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (cardCode !== undefined) {
                                localVarQueryParameter['cardCode'] = cardCode;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} [insId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByInsId: function (insId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Shared/SapPartnerEquipment/FindByInsId";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (insId !== undefined) {
                                localVarQueryParameter['insId'] = insId;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} [indId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByInsIdForWorksheet: function (indId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Shared/SapPartnerEquipment/FindByInsIdForWorksheet";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (indId !== undefined) {
                                localVarQueryParameter['indId'] = indId;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDSMPSapPartnerEquipmentPagingParams} [cDSMPSapPartnerEquipmentPagingParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPageBy: function (cDSMPSapPartnerEquipmentPagingParams, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Shared/SapPartnerEquipment/SearchPageBy";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDSMPSapPartnerEquipmentPagingParams, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.SapPartnerEquipmentApiAxiosParamCreator = SapPartnerEquipmentApiAxiosParamCreator;
/**
 * SapPartnerEquipmentApi - functional programming interface
 * @export
 */
var SapPartnerEquipmentApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.SapPartnerEquipmentApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {string} [cardCode]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByCardCode: function (cardCode, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findByCardCode(cardCode, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {number} [insId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByInsId: function (insId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findByInsId(insId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {number} [indId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByInsIdForWorksheet: function (indId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findByInsIdForWorksheet(indId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDSMPSapPartnerEquipmentPagingParams} [cDSMPSapPartnerEquipmentPagingParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPageBy: function (cDSMPSapPartnerEquipmentPagingParams, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.searchPageBy(cDSMPSapPartnerEquipmentPagingParams, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.SapPartnerEquipmentApiFp = SapPartnerEquipmentApiFp;
/**
 * SapPartnerEquipmentApi - factory interface
 * @export
 */
var SapPartnerEquipmentApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.SapPartnerEquipmentApiFp)(configuration);
    return {
        /**
         *
         * @param {string} [cardCode]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByCardCode: function (cardCode, options) {
            return localVarFp.findByCardCode(cardCode, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} [insId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByInsId: function (insId, options) {
            return localVarFp.findByInsId(insId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} [indId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByInsIdForWorksheet: function (indId, options) {
            return localVarFp.findByInsIdForWorksheet(indId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDSMPSapPartnerEquipmentPagingParams} [cDSMPSapPartnerEquipmentPagingParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPageBy: function (cDSMPSapPartnerEquipmentPagingParams, options) {
            return localVarFp.searchPageBy(cDSMPSapPartnerEquipmentPagingParams, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.SapPartnerEquipmentApiFactory = SapPartnerEquipmentApiFactory;
/**
 * SapPartnerEquipmentApi - object-oriented interface
 * @export
 * @class SapPartnerEquipmentApi
 * @extends {BaseAPI}
 */
var SapPartnerEquipmentApi = /** @class */ (function (_super) {
    __extends(SapPartnerEquipmentApi, _super);
    function SapPartnerEquipmentApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {string} [cardCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SapPartnerEquipmentApi
     */
    SapPartnerEquipmentApi.prototype.findByCardCode = function (cardCode, options) {
        var _this = this;
        return (0, exports.SapPartnerEquipmentApiFp)(this.configuration).findByCardCode(cardCode, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {number} [insId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SapPartnerEquipmentApi
     */
    SapPartnerEquipmentApi.prototype.findByInsId = function (insId, options) {
        var _this = this;
        return (0, exports.SapPartnerEquipmentApiFp)(this.configuration).findByInsId(insId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {number} [indId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SapPartnerEquipmentApi
     */
    SapPartnerEquipmentApi.prototype.findByInsIdForWorksheet = function (indId, options) {
        var _this = this;
        return (0, exports.SapPartnerEquipmentApiFp)(this.configuration).findByInsIdForWorksheet(indId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDSMPSapPartnerEquipmentPagingParams} [cDSMPSapPartnerEquipmentPagingParams]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SapPartnerEquipmentApi
     */
    SapPartnerEquipmentApi.prototype.searchPageBy = function (cDSMPSapPartnerEquipmentPagingParams, options) {
        var _this = this;
        return (0, exports.SapPartnerEquipmentApiFp)(this.configuration).searchPageBy(cDSMPSapPartnerEquipmentPagingParams, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return SapPartnerEquipmentApi;
}(base_1.BaseAPI));
exports.SapPartnerEquipmentApi = SapPartnerEquipmentApi;
/**
 * SapProductBlobApi - axios parameter creator
 * @export
 */
var SapProductBlobApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {string} [itemCode]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        findByKey: function (itemCode, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Common/SapProductBlob/FindByKey";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (itemCode !== undefined) {
                                localVarQueryParameter['itemCode'] = itemCode;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<CDAMCMSapProductBlobModel>} [cDAMCMSapProductBlobModel]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        saveAll: function (cDAMCMSapProductBlobModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Common/SapProductBlob/SaveAll";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMCMSapProductBlobModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.SapProductBlobApiAxiosParamCreator = SapProductBlobApiAxiosParamCreator;
/**
 * SapProductBlobApi - functional programming interface
 * @export
 */
var SapProductBlobApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.SapProductBlobApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {string} [itemCode]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        findByKey: function (itemCode, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findByKey(itemCode, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<CDAMCMSapProductBlobModel>} [cDAMCMSapProductBlobModel]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        saveAll: function (cDAMCMSapProductBlobModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.saveAll(cDAMCMSapProductBlobModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.SapProductBlobApiFp = SapProductBlobApiFp;
/**
 * SapProductBlobApi - factory interface
 * @export
 */
var SapProductBlobApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.SapProductBlobApiFp)(configuration);
    return {
        /**
         *
         * @param {string} [itemCode]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        findByKey: function (itemCode, options) {
            return localVarFp.findByKey(itemCode, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {Array<CDAMCMSapProductBlobModel>} [cDAMCMSapProductBlobModel]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        saveAll: function (cDAMCMSapProductBlobModel, options) {
            return localVarFp.saveAll(cDAMCMSapProductBlobModel, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.SapProductBlobApiFactory = SapProductBlobApiFactory;
/**
 * SapProductBlobApi - object-oriented interface
 * @export
 * @class SapProductBlobApi
 * @extends {BaseAPI}
 */
var SapProductBlobApi = /** @class */ (function (_super) {
    __extends(SapProductBlobApi, _super);
    function SapProductBlobApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {string} [itemCode]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof SapProductBlobApi
     */
    SapProductBlobApi.prototype.findByKey = function (itemCode, options) {
        var _this = this;
        return (0, exports.SapProductBlobApiFp)(this.configuration).findByKey(itemCode, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {Array<CDAMCMSapProductBlobModel>} [cDAMCMSapProductBlobModel]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof SapProductBlobApi
     */
    SapProductBlobApi.prototype.saveAll = function (cDAMCMSapProductBlobModel, options) {
        var _this = this;
        return (0, exports.SapProductBlobApiFp)(this.configuration).saveAll(cDAMCMSapProductBlobModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return SapProductBlobApi;
}(base_1.BaseAPI));
exports.SapProductBlobApi = SapProductBlobApi;
/**
 * SapPsgVehicleTypeCategoryApi - axios parameter creator
 * @export
 */
var SapPsgVehicleTypeCategoryApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Shared/SapPsgVehicleTypeCategory/FindAll";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllManufacturers: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Shared/SapPsgVehicleTypeCategory/FindAllManufacturers";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.SapPsgVehicleTypeCategoryApiAxiosParamCreator = SapPsgVehicleTypeCategoryApiAxiosParamCreator;
/**
 * SapPsgVehicleTypeCategoryApi - functional programming interface
 * @export
 */
var SapPsgVehicleTypeCategoryApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.SapPsgVehicleTypeCategoryApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findAll(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllManufacturers: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findAllManufacturers(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.SapPsgVehicleTypeCategoryApiFp = SapPsgVehicleTypeCategoryApiFp;
/**
 * SapPsgVehicleTypeCategoryApi - factory interface
 * @export
 */
var SapPsgVehicleTypeCategoryApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.SapPsgVehicleTypeCategoryApiFp)(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: function (options) {
            return localVarFp.findAll(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllManufacturers: function (options) {
            return localVarFp.findAllManufacturers(options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.SapPsgVehicleTypeCategoryApiFactory = SapPsgVehicleTypeCategoryApiFactory;
/**
 * SapPsgVehicleTypeCategoryApi - object-oriented interface
 * @export
 * @class SapPsgVehicleTypeCategoryApi
 * @extends {BaseAPI}
 */
var SapPsgVehicleTypeCategoryApi = /** @class */ (function (_super) {
    __extends(SapPsgVehicleTypeCategoryApi, _super);
    function SapPsgVehicleTypeCategoryApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SapPsgVehicleTypeCategoryApi
     */
    SapPsgVehicleTypeCategoryApi.prototype.findAll = function (options) {
        var _this = this;
        return (0, exports.SapPsgVehicleTypeCategoryApiFp)(this.configuration).findAll(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SapPsgVehicleTypeCategoryApi
     */
    SapPsgVehicleTypeCategoryApi.prototype.findAllManufacturers = function (options) {
        var _this = this;
        return (0, exports.SapPsgVehicleTypeCategoryApiFp)(this.configuration).findAllManufacturers(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return SapPsgVehicleTypeCategoryApi;
}(base_1.BaseAPI));
exports.SapPsgVehicleTypeCategoryApi = SapPsgVehicleTypeCategoryApi;
/**
 * SapSalesPersonApi - axios parameter creator
 * @export
 */
var SapSalesPersonApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Shared/SapSalesPerson/FindAll";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.SapSalesPersonApiAxiosParamCreator = SapSalesPersonApiAxiosParamCreator;
/**
 * SapSalesPersonApi - functional programming interface
 * @export
 */
var SapSalesPersonApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.SapSalesPersonApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findAll(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.SapSalesPersonApiFp = SapSalesPersonApiFp;
/**
 * SapSalesPersonApi - factory interface
 * @export
 */
var SapSalesPersonApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.SapSalesPersonApiFp)(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: function (options) {
            return localVarFp.findAll(options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.SapSalesPersonApiFactory = SapSalesPersonApiFactory;
/**
 * SapSalesPersonApi - object-oriented interface
 * @export
 * @class SapSalesPersonApi
 * @extends {BaseAPI}
 */
var SapSalesPersonApi = /** @class */ (function (_super) {
    __extends(SapSalesPersonApi, _super);
    function SapSalesPersonApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SapSalesPersonApi
     */
    SapSalesPersonApi.prototype.findAll = function (options) {
        var _this = this;
        return (0, exports.SapSalesPersonApiFp)(this.configuration).findAll(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return SapSalesPersonApi;
}(base_1.BaseAPI));
exports.SapSalesPersonApi = SapSalesPersonApi;
/**
 * SapServiceCallApi - axios parameter creator
 * @export
 */
var SapServiceCallApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {number} [callId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exists: function (callId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Shared/SapServiceCall/Exists";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (callId !== undefined) {
                                localVarQueryParameter['callId'] = callId;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} [insId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByInsId: function (insId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Shared/SapServiceCall/FindByInsId";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (insId !== undefined) {
                                localVarQueryParameter['insId'] = insId;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.SapServiceCallApiAxiosParamCreator = SapServiceCallApiAxiosParamCreator;
/**
 * SapServiceCallApi - functional programming interface
 * @export
 */
var SapServiceCallApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.SapServiceCallApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {number} [callId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exists: function (callId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.exists(callId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {number} [insId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByInsId: function (insId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findByInsId(insId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.SapServiceCallApiFp = SapServiceCallApiFp;
/**
 * SapServiceCallApi - factory interface
 * @export
 */
var SapServiceCallApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.SapServiceCallApiFp)(configuration);
    return {
        /**
         *
         * @param {number} [callId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exists: function (callId, options) {
            return localVarFp.exists(callId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} [insId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByInsId: function (insId, options) {
            return localVarFp.findByInsId(insId, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.SapServiceCallApiFactory = SapServiceCallApiFactory;
/**
 * SapServiceCallApi - object-oriented interface
 * @export
 * @class SapServiceCallApi
 * @extends {BaseAPI}
 */
var SapServiceCallApi = /** @class */ (function (_super) {
    __extends(SapServiceCallApi, _super);
    function SapServiceCallApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {number} [callId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SapServiceCallApi
     */
    SapServiceCallApi.prototype.exists = function (callId, options) {
        var _this = this;
        return (0, exports.SapServiceCallApiFp)(this.configuration).exists(callId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {number} [insId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SapServiceCallApi
     */
    SapServiceCallApi.prototype.findByInsId = function (insId, options) {
        var _this = this;
        return (0, exports.SapServiceCallApiFp)(this.configuration).findByInsId(insId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return SapServiceCallApi;
}(base_1.BaseAPI));
exports.SapServiceCallApi = SapServiceCallApi;
/**
 * SapVehicleTypeBlobApi - axios parameter creator
 * @export
 */
var SapVehicleTypeBlobApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {string} [code]
         * @param {number} [lineId]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        findByKey: function (code, lineId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Common/SapVehicleTypeBlob/FindByKey";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (code !== undefined) {
                                localVarQueryParameter['code'] = code;
                            }
                            if (lineId !== undefined) {
                                localVarQueryParameter['lineId'] = lineId;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMCMSapVehicleTypeImageQueryParams} [cDAMCMSapVehicleTypeImageQueryParams]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        findImagesFor: function (cDAMCMSapVehicleTypeImageQueryParams, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Common/SapVehicleTypeBlob/FindImagesFor";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMCMSapVehicleTypeImageQueryParams, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<CDAMCMSapVehicleTypeBlobModel>} [cDAMCMSapVehicleTypeBlobModel]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        saveAll: function (cDAMCMSapVehicleTypeBlobModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Common/SapVehicleTypeBlob/SaveAll";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMCMSapVehicleTypeBlobModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.SapVehicleTypeBlobApiAxiosParamCreator = SapVehicleTypeBlobApiAxiosParamCreator;
/**
 * SapVehicleTypeBlobApi - functional programming interface
 * @export
 */
var SapVehicleTypeBlobApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.SapVehicleTypeBlobApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {string} [code]
         * @param {number} [lineId]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        findByKey: function (code, lineId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findByKey(code, lineId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMCMSapVehicleTypeImageQueryParams} [cDAMCMSapVehicleTypeImageQueryParams]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        findImagesFor: function (cDAMCMSapVehicleTypeImageQueryParams, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findImagesFor(cDAMCMSapVehicleTypeImageQueryParams, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<CDAMCMSapVehicleTypeBlobModel>} [cDAMCMSapVehicleTypeBlobModel]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        saveAll: function (cDAMCMSapVehicleTypeBlobModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.saveAll(cDAMCMSapVehicleTypeBlobModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.SapVehicleTypeBlobApiFp = SapVehicleTypeBlobApiFp;
/**
 * SapVehicleTypeBlobApi - factory interface
 * @export
 */
var SapVehicleTypeBlobApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.SapVehicleTypeBlobApiFp)(configuration);
    return {
        /**
         *
         * @param {string} [code]
         * @param {number} [lineId]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        findByKey: function (code, lineId, options) {
            return localVarFp.findByKey(code, lineId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMCMSapVehicleTypeImageQueryParams} [cDAMCMSapVehicleTypeImageQueryParams]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        findImagesFor: function (cDAMCMSapVehicleTypeImageQueryParams, options) {
            return localVarFp.findImagesFor(cDAMCMSapVehicleTypeImageQueryParams, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {Array<CDAMCMSapVehicleTypeBlobModel>} [cDAMCMSapVehicleTypeBlobModel]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        saveAll: function (cDAMCMSapVehicleTypeBlobModel, options) {
            return localVarFp.saveAll(cDAMCMSapVehicleTypeBlobModel, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.SapVehicleTypeBlobApiFactory = SapVehicleTypeBlobApiFactory;
/**
 * SapVehicleTypeBlobApi - object-oriented interface
 * @export
 * @class SapVehicleTypeBlobApi
 * @extends {BaseAPI}
 */
var SapVehicleTypeBlobApi = /** @class */ (function (_super) {
    __extends(SapVehicleTypeBlobApi, _super);
    function SapVehicleTypeBlobApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {string} [code]
     * @param {number} [lineId]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof SapVehicleTypeBlobApi
     */
    SapVehicleTypeBlobApi.prototype.findByKey = function (code, lineId, options) {
        var _this = this;
        return (0, exports.SapVehicleTypeBlobApiFp)(this.configuration).findByKey(code, lineId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMCMSapVehicleTypeImageQueryParams} [cDAMCMSapVehicleTypeImageQueryParams]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof SapVehicleTypeBlobApi
     */
    SapVehicleTypeBlobApi.prototype.findImagesFor = function (cDAMCMSapVehicleTypeImageQueryParams, options) {
        var _this = this;
        return (0, exports.SapVehicleTypeBlobApiFp)(this.configuration).findImagesFor(cDAMCMSapVehicleTypeImageQueryParams, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {Array<CDAMCMSapVehicleTypeBlobModel>} [cDAMCMSapVehicleTypeBlobModel]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof SapVehicleTypeBlobApi
     */
    SapVehicleTypeBlobApi.prototype.saveAll = function (cDAMCMSapVehicleTypeBlobModel, options) {
        var _this = this;
        return (0, exports.SapVehicleTypeBlobApiFp)(this.configuration).saveAll(cDAMCMSapVehicleTypeBlobModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return SapVehicleTypeBlobApi;
}(base_1.BaseAPI));
exports.SapVehicleTypeBlobApi = SapVehicleTypeBlobApi;
/**
 * ServiceCallApi - axios parameter creator
 * @export
 */
var ServiceCallApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {string} [wId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByWorksheetId: function (wId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/ServiceCall/FindByWorksheetId";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (wId !== undefined) {
                                localVarQueryParameter['wId'] = wId;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMWServiceCallSave} [cDAMWMWServiceCallSave]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveServiceCallDirectly: function (cDAMWMWServiceCallSave, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/ServiceCall/SaveServiceCallDirectly";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMWServiceCallSave, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.ServiceCallApiAxiosParamCreator = ServiceCallApiAxiosParamCreator;
/**
 * ServiceCallApi - functional programming interface
 * @export
 */
var ServiceCallApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.ServiceCallApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {string} [wId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByWorksheetId: function (wId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findByWorksheetId(wId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMWServiceCallSave} [cDAMWMWServiceCallSave]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveServiceCallDirectly: function (cDAMWMWServiceCallSave, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.saveServiceCallDirectly(cDAMWMWServiceCallSave, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.ServiceCallApiFp = ServiceCallApiFp;
/**
 * ServiceCallApi - factory interface
 * @export
 */
var ServiceCallApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.ServiceCallApiFp)(configuration);
    return {
        /**
         *
         * @param {string} [wId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByWorksheetId: function (wId, options) {
            return localVarFp.findByWorksheetId(wId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMWMWServiceCallSave} [cDAMWMWServiceCallSave]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveServiceCallDirectly: function (cDAMWMWServiceCallSave, options) {
            return localVarFp.saveServiceCallDirectly(cDAMWMWServiceCallSave, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.ServiceCallApiFactory = ServiceCallApiFactory;
/**
 * ServiceCallApi - object-oriented interface
 * @export
 * @class ServiceCallApi
 * @extends {BaseAPI}
 */
var ServiceCallApi = /** @class */ (function (_super) {
    __extends(ServiceCallApi, _super);
    function ServiceCallApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {string} [wId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCallApi
     */
    ServiceCallApi.prototype.findByWorksheetId = function (wId, options) {
        var _this = this;
        return (0, exports.ServiceCallApiFp)(this.configuration).findByWorksheetId(wId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMWMWServiceCallSave} [cDAMWMWServiceCallSave]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCallApi
     */
    ServiceCallApi.prototype.saveServiceCallDirectly = function (cDAMWMWServiceCallSave, options) {
        var _this = this;
        return (0, exports.ServiceCallApiFp)(this.configuration).saveServiceCallDirectly(cDAMWMWServiceCallSave, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return ServiceCallApi;
}(base_1.BaseAPI));
exports.ServiceCallApi = ServiceCallApi;
/**
 * ServiceCallBlobApi - axios parameter creator
 * @export
 */
var ServiceCallBlobApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {CDAMWMServiceCallBlobType} [blobType]
         * @param {number} [equipmentId]
         * @param {string} [modEquipmentId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findBlobsForSapEquipmentByInsIdOrModId: function (blobType, equipmentId, modEquipmentId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/ServiceCallBlob/FindBlobsForSapEquipmentByInsIdOrModId";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (blobType !== undefined) {
                                localVarQueryParameter['blobType'] = blobType;
                            }
                            if (equipmentId !== undefined) {
                                localVarQueryParameter['equipmentId'] = equipmentId;
                            }
                            if (modEquipmentId !== undefined) {
                                localVarQueryParameter['modEquipmentId'] = modEquipmentId;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CMWMFindBlobsForSapEquipmentByInsIdsOrModIdsProps} [cMWMFindBlobsForSapEquipmentByInsIdsOrModIdsProps]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findBlobsForSapEquipmentByInsIdsOrModIds: function (cMWMFindBlobsForSapEquipmentByInsIdsOrModIdsProps, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/ServiceCallBlob/FindBlobsForSapEquipmentByInsIdsOrModIds";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cMWMFindBlobsForSapEquipmentByInsIdsOrModIdsProps, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMServiceCallBlobType} [blobType]
         * @param {string} [serviceCallId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findBlobsForServiceCallId: function (blobType, serviceCallId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/ServiceCallBlob/FindBlobsForServiceCallId";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (blobType !== undefined) {
                                localVarQueryParameter['blobType'] = blobType;
                            }
                            if (serviceCallId !== undefined) {
                                localVarQueryParameter['serviceCallId'] = serviceCallId;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [ruleId]
         * @param {CDAMWMServiceCallBlobType} [onlyBlobType]
         * @param {string} [serviceCallId]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        findByServiceCallIdAndRuleId: function (ruleId, onlyBlobType, serviceCallId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/ServiceCallBlob/FindByServiceCallIdAndRuleId";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (ruleId !== undefined) {
                                localVarQueryParameter['ruleId'] = ruleId;
                            }
                            if (onlyBlobType !== undefined) {
                                localVarQueryParameter['onlyBlobType'] = onlyBlobType;
                            }
                            if (serviceCallId !== undefined) {
                                localVarQueryParameter['serviceCallId'] = serviceCallId;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [worksheetId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByWorksheetId: function (worksheetId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/ServiceCallBlob/FindByWorksheetId";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (worksheetId !== undefined) {
                                localVarQueryParameter['worksheetId'] = worksheetId;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [oldBlobId]
         * @param {string} [oldServiceCallId]
         * @param {CDAMWMWServiceCallBlobSave} [cDAMWMWServiceCallBlobSave]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceServiceCallBlobFor: function (oldBlobId, oldServiceCallId, cDAMWMWServiceCallBlobSave, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/ServiceCallBlob/ReplaceServiceCallBlobFor";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (oldBlobId !== undefined) {
                                localVarQueryParameter['oldBlobId'] = oldBlobId;
                            }
                            if (oldServiceCallId !== undefined) {
                                localVarQueryParameter['oldServiceCallId'] = oldServiceCallId;
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMWServiceCallBlobSave, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMWServiceCallBlobSave} [cDAMWMWServiceCallBlobSave]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveServiceCallBlobDirectly: function (cDAMWMWServiceCallBlobSave, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/ServiceCallBlob/SaveServiceCallBlobDirectly";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMWServiceCallBlobSave, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [blobId]
         * @param {string} [serviceCallId]
         * @param {boolean} [isDeleted]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDeletedDirectly: function (blobId, serviceCallId, isDeleted, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/ServiceCallBlob/SetDeletedDirectly";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (blobId !== undefined) {
                                localVarQueryParameter['blobId'] = blobId;
                            }
                            if (serviceCallId !== undefined) {
                                localVarQueryParameter['serviceCallId'] = serviceCallId;
                            }
                            if (isDeleted !== undefined) {
                                localVarQueryParameter['isDeleted'] = isDeleted;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.ServiceCallBlobApiAxiosParamCreator = ServiceCallBlobApiAxiosParamCreator;
/**
 * ServiceCallBlobApi - functional programming interface
 * @export
 */
var ServiceCallBlobApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.ServiceCallBlobApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {CDAMWMServiceCallBlobType} [blobType]
         * @param {number} [equipmentId]
         * @param {string} [modEquipmentId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findBlobsForSapEquipmentByInsIdOrModId: function (blobType, equipmentId, modEquipmentId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findBlobsForSapEquipmentByInsIdOrModId(blobType, equipmentId, modEquipmentId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CMWMFindBlobsForSapEquipmentByInsIdsOrModIdsProps} [cMWMFindBlobsForSapEquipmentByInsIdsOrModIdsProps]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findBlobsForSapEquipmentByInsIdsOrModIds: function (cMWMFindBlobsForSapEquipmentByInsIdsOrModIdsProps, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findBlobsForSapEquipmentByInsIdsOrModIds(cMWMFindBlobsForSapEquipmentByInsIdsOrModIdsProps, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMServiceCallBlobType} [blobType]
         * @param {string} [serviceCallId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findBlobsForServiceCallId: function (blobType, serviceCallId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findBlobsForServiceCallId(blobType, serviceCallId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [ruleId]
         * @param {CDAMWMServiceCallBlobType} [onlyBlobType]
         * @param {string} [serviceCallId]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        findByServiceCallIdAndRuleId: function (ruleId, onlyBlobType, serviceCallId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findByServiceCallIdAndRuleId(ruleId, onlyBlobType, serviceCallId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [worksheetId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByWorksheetId: function (worksheetId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findByWorksheetId(worksheetId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [oldBlobId]
         * @param {string} [oldServiceCallId]
         * @param {CDAMWMWServiceCallBlobSave} [cDAMWMWServiceCallBlobSave]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceServiceCallBlobFor: function (oldBlobId, oldServiceCallId, cDAMWMWServiceCallBlobSave, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.replaceServiceCallBlobFor(oldBlobId, oldServiceCallId, cDAMWMWServiceCallBlobSave, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMWServiceCallBlobSave} [cDAMWMWServiceCallBlobSave]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveServiceCallBlobDirectly: function (cDAMWMWServiceCallBlobSave, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.saveServiceCallBlobDirectly(cDAMWMWServiceCallBlobSave, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [blobId]
         * @param {string} [serviceCallId]
         * @param {boolean} [isDeleted]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDeletedDirectly: function (blobId, serviceCallId, isDeleted, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.setDeletedDirectly(blobId, serviceCallId, isDeleted, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.ServiceCallBlobApiFp = ServiceCallBlobApiFp;
/**
 * ServiceCallBlobApi - factory interface
 * @export
 */
var ServiceCallBlobApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.ServiceCallBlobApiFp)(configuration);
    return {
        /**
         *
         * @param {CDAMWMServiceCallBlobType} [blobType]
         * @param {number} [equipmentId]
         * @param {string} [modEquipmentId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findBlobsForSapEquipmentByInsIdOrModId: function (blobType, equipmentId, modEquipmentId, options) {
            return localVarFp.findBlobsForSapEquipmentByInsIdOrModId(blobType, equipmentId, modEquipmentId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CMWMFindBlobsForSapEquipmentByInsIdsOrModIdsProps} [cMWMFindBlobsForSapEquipmentByInsIdsOrModIdsProps]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findBlobsForSapEquipmentByInsIdsOrModIds: function (cMWMFindBlobsForSapEquipmentByInsIdsOrModIdsProps, options) {
            return localVarFp.findBlobsForSapEquipmentByInsIdsOrModIds(cMWMFindBlobsForSapEquipmentByInsIdsOrModIdsProps, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMWMServiceCallBlobType} [blobType]
         * @param {string} [serviceCallId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findBlobsForServiceCallId: function (blobType, serviceCallId, options) {
            return localVarFp.findBlobsForServiceCallId(blobType, serviceCallId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [ruleId]
         * @param {CDAMWMServiceCallBlobType} [onlyBlobType]
         * @param {string} [serviceCallId]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        findByServiceCallIdAndRuleId: function (ruleId, onlyBlobType, serviceCallId, options) {
            return localVarFp.findByServiceCallIdAndRuleId(ruleId, onlyBlobType, serviceCallId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [worksheetId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByWorksheetId: function (worksheetId, options) {
            return localVarFp.findByWorksheetId(worksheetId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [oldBlobId]
         * @param {string} [oldServiceCallId]
         * @param {CDAMWMWServiceCallBlobSave} [cDAMWMWServiceCallBlobSave]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceServiceCallBlobFor: function (oldBlobId, oldServiceCallId, cDAMWMWServiceCallBlobSave, options) {
            return localVarFp.replaceServiceCallBlobFor(oldBlobId, oldServiceCallId, cDAMWMWServiceCallBlobSave, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMWMWServiceCallBlobSave} [cDAMWMWServiceCallBlobSave]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveServiceCallBlobDirectly: function (cDAMWMWServiceCallBlobSave, options) {
            return localVarFp.saveServiceCallBlobDirectly(cDAMWMWServiceCallBlobSave, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [blobId]
         * @param {string} [serviceCallId]
         * @param {boolean} [isDeleted]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDeletedDirectly: function (blobId, serviceCallId, isDeleted, options) {
            return localVarFp.setDeletedDirectly(blobId, serviceCallId, isDeleted, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.ServiceCallBlobApiFactory = ServiceCallBlobApiFactory;
/**
 * ServiceCallBlobApi - object-oriented interface
 * @export
 * @class ServiceCallBlobApi
 * @extends {BaseAPI}
 */
var ServiceCallBlobApi = /** @class */ (function (_super) {
    __extends(ServiceCallBlobApi, _super);
    function ServiceCallBlobApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {CDAMWMServiceCallBlobType} [blobType]
     * @param {number} [equipmentId]
     * @param {string} [modEquipmentId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCallBlobApi
     */
    ServiceCallBlobApi.prototype.findBlobsForSapEquipmentByInsIdOrModId = function (blobType, equipmentId, modEquipmentId, options) {
        var _this = this;
        return (0, exports.ServiceCallBlobApiFp)(this.configuration).findBlobsForSapEquipmentByInsIdOrModId(blobType, equipmentId, modEquipmentId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CMWMFindBlobsForSapEquipmentByInsIdsOrModIdsProps} [cMWMFindBlobsForSapEquipmentByInsIdsOrModIdsProps]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCallBlobApi
     */
    ServiceCallBlobApi.prototype.findBlobsForSapEquipmentByInsIdsOrModIds = function (cMWMFindBlobsForSapEquipmentByInsIdsOrModIdsProps, options) {
        var _this = this;
        return (0, exports.ServiceCallBlobApiFp)(this.configuration).findBlobsForSapEquipmentByInsIdsOrModIds(cMWMFindBlobsForSapEquipmentByInsIdsOrModIdsProps, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMWMServiceCallBlobType} [blobType]
     * @param {string} [serviceCallId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCallBlobApi
     */
    ServiceCallBlobApi.prototype.findBlobsForServiceCallId = function (blobType, serviceCallId, options) {
        var _this = this;
        return (0, exports.ServiceCallBlobApiFp)(this.configuration).findBlobsForServiceCallId(blobType, serviceCallId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [ruleId]
     * @param {CDAMWMServiceCallBlobType} [onlyBlobType]
     * @param {string} [serviceCallId]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ServiceCallBlobApi
     */
    ServiceCallBlobApi.prototype.findByServiceCallIdAndRuleId = function (ruleId, onlyBlobType, serviceCallId, options) {
        var _this = this;
        return (0, exports.ServiceCallBlobApiFp)(this.configuration).findByServiceCallIdAndRuleId(ruleId, onlyBlobType, serviceCallId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [worksheetId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCallBlobApi
     */
    ServiceCallBlobApi.prototype.findByWorksheetId = function (worksheetId, options) {
        var _this = this;
        return (0, exports.ServiceCallBlobApiFp)(this.configuration).findByWorksheetId(worksheetId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [oldBlobId]
     * @param {string} [oldServiceCallId]
     * @param {CDAMWMWServiceCallBlobSave} [cDAMWMWServiceCallBlobSave]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCallBlobApi
     */
    ServiceCallBlobApi.prototype.replaceServiceCallBlobFor = function (oldBlobId, oldServiceCallId, cDAMWMWServiceCallBlobSave, options) {
        var _this = this;
        return (0, exports.ServiceCallBlobApiFp)(this.configuration).replaceServiceCallBlobFor(oldBlobId, oldServiceCallId, cDAMWMWServiceCallBlobSave, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMWMWServiceCallBlobSave} [cDAMWMWServiceCallBlobSave]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCallBlobApi
     */
    ServiceCallBlobApi.prototype.saveServiceCallBlobDirectly = function (cDAMWMWServiceCallBlobSave, options) {
        var _this = this;
        return (0, exports.ServiceCallBlobApiFp)(this.configuration).saveServiceCallBlobDirectly(cDAMWMWServiceCallBlobSave, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [blobId]
     * @param {string} [serviceCallId]
     * @param {boolean} [isDeleted]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCallBlobApi
     */
    ServiceCallBlobApi.prototype.setDeletedDirectly = function (blobId, serviceCallId, isDeleted, options) {
        var _this = this;
        return (0, exports.ServiceCallBlobApiFp)(this.configuration).setDeletedDirectly(blobId, serviceCallId, isDeleted, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return ServiceCallBlobApi;
}(base_1.BaseAPI));
exports.ServiceCallBlobApi = ServiceCallBlobApi;
/**
 * ServiceCallEvalApi - axios parameter creator
 * @export
 */
var ServiceCallEvalApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {boolean} [isDeleted]
         * @param {CDAMWMServiceCallEvalModel} [cDAMWMServiceCallEvalModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: function (isDeleted, cDAMWMServiceCallEvalModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/ServiceCallEval/Delete";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (isDeleted !== undefined) {
                                localVarQueryParameter['isDeleted'] = isDeleted;
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMServiceCallEvalModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CCMWECreateServiceCallForEvalParameters} [cCMWECreateServiceCallForEvalParameters]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createServiceCallForEval: function (cCMWECreateServiceCallForEvalParameters, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/ServiceCallEval/CreateServiceCallForEval";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cCMWECreateServiceCallForEvalParameters, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {any} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdObject: function (body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/ServiceCallEval/FindByIdObject";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(body, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [wEvalId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByWorksheetEvalId: function (wEvalId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/ServiceCallEval/FindByWorksheetEvalId";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (wEvalId !== undefined) {
                                localVarQueryParameter['wEvalId'] = wEvalId;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMServiceCallEvalModel} [cDAMWMServiceCallEvalModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insert: function (cDAMWMServiceCallEvalModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/ServiceCallEval/Insert";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMServiceCallEvalModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<CDAMWMServiceCallEvalModel>} [cDAMWMServiceCallEvalModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merge: function (cDAMWMServiceCallEvalModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/ServiceCallEval/Merge";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMServiceCallEvalModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDCMGenericPagingParams} [cDCMGenericPagingParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageAll: function (cDCMGenericPagingParams, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/ServiceCallEval/PageAll";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDCMGenericPagingParams, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMServiceCallEvalModel} [cDAMWMServiceCallEvalModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: function (cDAMWMServiceCallEvalModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/ServiceCallEval/Save";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMServiceCallEvalModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMServiceCallEvalModel} [cDAMWMServiceCallEvalModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: function (cDAMWMServiceCallEvalModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/ServiceCallEval/Update";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMServiceCallEvalModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1String} [cDAMWMESingleFieldWorksheetEvalUpdate1String]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBillingSapAddressAndType: function (cDAMWMESingleFieldWorksheetEvalUpdate1String, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/ServiceCallEval/UpdateBillingSapAddressAndType";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMESingleFieldWorksheetEvalUpdate1String, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1Nullable1} [cDAMWMESingleFieldWorksheetEvalUpdate1Nullable1]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCallTypeId: function (cDAMWMESingleFieldWorksheetEvalUpdate1Nullable1, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/ServiceCallEval/UpdateCallTypeId";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMESingleFieldWorksheetEvalUpdate1Nullable1, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1String} [cDAMWMESingleFieldWorksheetEvalUpdate1String]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateComment: function (cDAMWMESingleFieldWorksheetEvalUpdate1String, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/ServiceCallEval/UpdateComment";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMESingleFieldWorksheetEvalUpdate1String, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMEOperatingHoursServiceCallEvalUpdate} [cDAMWMEOperatingHoursServiceCallEvalUpdate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCurrentEquipmentOperatingHours: function (cDAMWMEOperatingHoursServiceCallEvalUpdate, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/ServiceCallEval/UpdateCurrentEquipmentOperatingHours";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMEOperatingHoursServiceCallEvalUpdate, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMEDeliveryAckServiceCallEvalUpdate} [cDAMWMEDeliveryAckServiceCallEvalUpdate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeliveryAck: function (cDAMWMEDeliveryAckServiceCallEvalUpdate, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/ServiceCallEval/UpdateDeliveryAck";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMEDeliveryAckServiceCallEvalUpdate, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMEEquipmentChangeServiceCallEvalUpdate} [cDAMWMEEquipmentChangeServiceCallEvalUpdate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEquipment: function (cDAMWMEEquipmentChangeServiceCallEvalUpdate, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/ServiceCallEval/UpdateEquipment";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMEEquipmentChangeServiceCallEvalUpdate, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1Boolean} [cDAMWMESingleFieldWorksheetEvalUpdate1Boolean]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEquipmentAck: function (cDAMWMESingleFieldWorksheetEvalUpdate1Boolean, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/ServiceCallEval/UpdateEquipmentAck";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMESingleFieldWorksheetEvalUpdate1Boolean, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMEServiceCallEvalUpdate} [cDAMWMEServiceCallEvalUpdate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEval: function (cDAMWMEServiceCallEvalUpdate, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/ServiceCallEval/UpdateEval";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMEServiceCallEvalUpdate, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1String} [cDAMWMESingleFieldWorksheetEvalUpdate1String]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFaultDescription: function (cDAMWMESingleFieldWorksheetEvalUpdate1String, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/ServiceCallEval/UpdateFaultDescription";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMESingleFieldWorksheetEvalUpdate1String, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1Boolean} [cDAMWMESingleFieldWorksheetEvalUpdate1Boolean]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLinkEquipmentToServiceCall: function (cDAMWMESingleFieldWorksheetEvalUpdate1Boolean, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/ServiceCallEval/UpdateLinkEquipmentToServiceCall";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMESingleFieldWorksheetEvalUpdate1Boolean, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1Boolean} [cDAMWMESingleFieldWorksheetEvalUpdate1Boolean]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManuallySetDeliveryDocEntry: function (cDAMWMESingleFieldWorksheetEvalUpdate1Boolean, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/ServiceCallEval/UpdateManuallySetDeliveryDocEntry";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMESingleFieldWorksheetEvalUpdate1Boolean, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1Int32} [cDAMWMESingleFieldWorksheetEvalUpdate1Int32]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOwnerCode: function (cDAMWMESingleFieldWorksheetEvalUpdate1Int32, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/ServiceCallEval/UpdateOwnerCode";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMESingleFieldWorksheetEvalUpdate1Int32, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1Int32} [cDAMWMESingleFieldWorksheetEvalUpdate1Int32]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSelectedCallId: function (cDAMWMESingleFieldWorksheetEvalUpdate1Int32, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/ServiceCallEval/UpdateSelectedCallId";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMESingleFieldWorksheetEvalUpdate1Int32, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1Nullable1} [cDAMWMESingleFieldWorksheetEvalUpdate1Nullable1]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSelectedSapCallType: function (cDAMWMESingleFieldWorksheetEvalUpdate1Nullable1, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/ServiceCallEval/UpdateSelectedSapCallType";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMESingleFieldWorksheetEvalUpdate1Nullable1, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1String} [cDAMWMESingleFieldWorksheetEvalUpdate1String]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateShippingSapAddressAndType: function (cDAMWMESingleFieldWorksheetEvalUpdate1String, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/ServiceCallEval/UpdateShippingSapAddressAndType";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMESingleFieldWorksheetEvalUpdate1String, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1Int32} [cDAMWMESingleFieldWorksheetEvalUpdate1Int32]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSlpCode: function (cDAMWMESingleFieldWorksheetEvalUpdate1Int32, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/ServiceCallEval/UpdateSlpCode";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMESingleFieldWorksheetEvalUpdate1Int32, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1String} [cDAMWMESingleFieldWorksheetEvalUpdate1String]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubject: function (cDAMWMESingleFieldWorksheetEvalUpdate1String, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/ServiceCallEval/UpdateSubject";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMESingleFieldWorksheetEvalUpdate1String, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1String} [cDAMWMESingleFieldWorksheetEvalUpdate1String]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTaskDescription: function (cDAMWMESingleFieldWorksheetEvalUpdate1String, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/ServiceCallEval/UpdateTaskDescription";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMESingleFieldWorksheetEvalUpdate1String, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1Int32} [cDAMWMESingleFieldWorksheetEvalUpdate1Int32]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTechnician: function (cDAMWMESingleFieldWorksheetEvalUpdate1Int32, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/ServiceCallEval/UpdateTechnician";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMESingleFieldWorksheetEvalUpdate1Int32, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.ServiceCallEvalApiAxiosParamCreator = ServiceCallEvalApiAxiosParamCreator;
/**
 * ServiceCallEvalApi - functional programming interface
 * @export
 */
var ServiceCallEvalApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.ServiceCallEvalApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {boolean} [isDeleted]
         * @param {CDAMWMServiceCallEvalModel} [cDAMWMServiceCallEvalModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: function (isDeleted, cDAMWMServiceCallEvalModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator._delete(isDeleted, cDAMWMServiceCallEvalModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CCMWECreateServiceCallForEvalParameters} [cCMWECreateServiceCallForEvalParameters]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createServiceCallForEval: function (cCMWECreateServiceCallForEvalParameters, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createServiceCallForEval(cCMWECreateServiceCallForEvalParameters, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {any} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdObject: function (body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findByIdObject(body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [wEvalId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByWorksheetEvalId: function (wEvalId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findByWorksheetEvalId(wEvalId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMServiceCallEvalModel} [cDAMWMServiceCallEvalModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insert: function (cDAMWMServiceCallEvalModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.insert(cDAMWMServiceCallEvalModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<CDAMWMServiceCallEvalModel>} [cDAMWMServiceCallEvalModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merge: function (cDAMWMServiceCallEvalModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.merge(cDAMWMServiceCallEvalModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDCMGenericPagingParams} [cDCMGenericPagingParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageAll: function (cDCMGenericPagingParams, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pageAll(cDCMGenericPagingParams, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMServiceCallEvalModel} [cDAMWMServiceCallEvalModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: function (cDAMWMServiceCallEvalModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.save(cDAMWMServiceCallEvalModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMServiceCallEvalModel} [cDAMWMServiceCallEvalModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: function (cDAMWMServiceCallEvalModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.update(cDAMWMServiceCallEvalModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1String} [cDAMWMESingleFieldWorksheetEvalUpdate1String]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBillingSapAddressAndType: function (cDAMWMESingleFieldWorksheetEvalUpdate1String, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateBillingSapAddressAndType(cDAMWMESingleFieldWorksheetEvalUpdate1String, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1Nullable1} [cDAMWMESingleFieldWorksheetEvalUpdate1Nullable1]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCallTypeId: function (cDAMWMESingleFieldWorksheetEvalUpdate1Nullable1, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateCallTypeId(cDAMWMESingleFieldWorksheetEvalUpdate1Nullable1, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1String} [cDAMWMESingleFieldWorksheetEvalUpdate1String]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateComment: function (cDAMWMESingleFieldWorksheetEvalUpdate1String, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateComment(cDAMWMESingleFieldWorksheetEvalUpdate1String, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMEOperatingHoursServiceCallEvalUpdate} [cDAMWMEOperatingHoursServiceCallEvalUpdate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCurrentEquipmentOperatingHours: function (cDAMWMEOperatingHoursServiceCallEvalUpdate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateCurrentEquipmentOperatingHours(cDAMWMEOperatingHoursServiceCallEvalUpdate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMEDeliveryAckServiceCallEvalUpdate} [cDAMWMEDeliveryAckServiceCallEvalUpdate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeliveryAck: function (cDAMWMEDeliveryAckServiceCallEvalUpdate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateDeliveryAck(cDAMWMEDeliveryAckServiceCallEvalUpdate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMEEquipmentChangeServiceCallEvalUpdate} [cDAMWMEEquipmentChangeServiceCallEvalUpdate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEquipment: function (cDAMWMEEquipmentChangeServiceCallEvalUpdate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateEquipment(cDAMWMEEquipmentChangeServiceCallEvalUpdate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1Boolean} [cDAMWMESingleFieldWorksheetEvalUpdate1Boolean]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEquipmentAck: function (cDAMWMESingleFieldWorksheetEvalUpdate1Boolean, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateEquipmentAck(cDAMWMESingleFieldWorksheetEvalUpdate1Boolean, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMEServiceCallEvalUpdate} [cDAMWMEServiceCallEvalUpdate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEval: function (cDAMWMEServiceCallEvalUpdate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateEval(cDAMWMEServiceCallEvalUpdate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1String} [cDAMWMESingleFieldWorksheetEvalUpdate1String]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFaultDescription: function (cDAMWMESingleFieldWorksheetEvalUpdate1String, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateFaultDescription(cDAMWMESingleFieldWorksheetEvalUpdate1String, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1Boolean} [cDAMWMESingleFieldWorksheetEvalUpdate1Boolean]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLinkEquipmentToServiceCall: function (cDAMWMESingleFieldWorksheetEvalUpdate1Boolean, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateLinkEquipmentToServiceCall(cDAMWMESingleFieldWorksheetEvalUpdate1Boolean, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1Boolean} [cDAMWMESingleFieldWorksheetEvalUpdate1Boolean]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManuallySetDeliveryDocEntry: function (cDAMWMESingleFieldWorksheetEvalUpdate1Boolean, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateManuallySetDeliveryDocEntry(cDAMWMESingleFieldWorksheetEvalUpdate1Boolean, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1Int32} [cDAMWMESingleFieldWorksheetEvalUpdate1Int32]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOwnerCode: function (cDAMWMESingleFieldWorksheetEvalUpdate1Int32, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateOwnerCode(cDAMWMESingleFieldWorksheetEvalUpdate1Int32, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1Int32} [cDAMWMESingleFieldWorksheetEvalUpdate1Int32]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSelectedCallId: function (cDAMWMESingleFieldWorksheetEvalUpdate1Int32, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateSelectedCallId(cDAMWMESingleFieldWorksheetEvalUpdate1Int32, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1Nullable1} [cDAMWMESingleFieldWorksheetEvalUpdate1Nullable1]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSelectedSapCallType: function (cDAMWMESingleFieldWorksheetEvalUpdate1Nullable1, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateSelectedSapCallType(cDAMWMESingleFieldWorksheetEvalUpdate1Nullable1, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1String} [cDAMWMESingleFieldWorksheetEvalUpdate1String]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateShippingSapAddressAndType: function (cDAMWMESingleFieldWorksheetEvalUpdate1String, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateShippingSapAddressAndType(cDAMWMESingleFieldWorksheetEvalUpdate1String, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1Int32} [cDAMWMESingleFieldWorksheetEvalUpdate1Int32]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSlpCode: function (cDAMWMESingleFieldWorksheetEvalUpdate1Int32, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateSlpCode(cDAMWMESingleFieldWorksheetEvalUpdate1Int32, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1String} [cDAMWMESingleFieldWorksheetEvalUpdate1String]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubject: function (cDAMWMESingleFieldWorksheetEvalUpdate1String, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateSubject(cDAMWMESingleFieldWorksheetEvalUpdate1String, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1String} [cDAMWMESingleFieldWorksheetEvalUpdate1String]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTaskDescription: function (cDAMWMESingleFieldWorksheetEvalUpdate1String, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateTaskDescription(cDAMWMESingleFieldWorksheetEvalUpdate1String, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1Int32} [cDAMWMESingleFieldWorksheetEvalUpdate1Int32]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTechnician: function (cDAMWMESingleFieldWorksheetEvalUpdate1Int32, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateTechnician(cDAMWMESingleFieldWorksheetEvalUpdate1Int32, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.ServiceCallEvalApiFp = ServiceCallEvalApiFp;
/**
 * ServiceCallEvalApi - factory interface
 * @export
 */
var ServiceCallEvalApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.ServiceCallEvalApiFp)(configuration);
    return {
        /**
         *
         * @param {boolean} [isDeleted]
         * @param {CDAMWMServiceCallEvalModel} [cDAMWMServiceCallEvalModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: function (isDeleted, cDAMWMServiceCallEvalModel, options) {
            return localVarFp._delete(isDeleted, cDAMWMServiceCallEvalModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CCMWECreateServiceCallForEvalParameters} [cCMWECreateServiceCallForEvalParameters]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createServiceCallForEval: function (cCMWECreateServiceCallForEvalParameters, options) {
            return localVarFp.createServiceCallForEval(cCMWECreateServiceCallForEvalParameters, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {any} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdObject: function (body, options) {
            return localVarFp.findByIdObject(body, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [wEvalId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByWorksheetEvalId: function (wEvalId, options) {
            return localVarFp.findByWorksheetEvalId(wEvalId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMWMServiceCallEvalModel} [cDAMWMServiceCallEvalModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insert: function (cDAMWMServiceCallEvalModel, options) {
            return localVarFp.insert(cDAMWMServiceCallEvalModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {Array<CDAMWMServiceCallEvalModel>} [cDAMWMServiceCallEvalModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merge: function (cDAMWMServiceCallEvalModel, options) {
            return localVarFp.merge(cDAMWMServiceCallEvalModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDCMGenericPagingParams} [cDCMGenericPagingParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageAll: function (cDCMGenericPagingParams, options) {
            return localVarFp.pageAll(cDCMGenericPagingParams, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMWMServiceCallEvalModel} [cDAMWMServiceCallEvalModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: function (cDAMWMServiceCallEvalModel, options) {
            return localVarFp.save(cDAMWMServiceCallEvalModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMWMServiceCallEvalModel} [cDAMWMServiceCallEvalModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: function (cDAMWMServiceCallEvalModel, options) {
            return localVarFp.update(cDAMWMServiceCallEvalModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1String} [cDAMWMESingleFieldWorksheetEvalUpdate1String]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBillingSapAddressAndType: function (cDAMWMESingleFieldWorksheetEvalUpdate1String, options) {
            return localVarFp.updateBillingSapAddressAndType(cDAMWMESingleFieldWorksheetEvalUpdate1String, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1Nullable1} [cDAMWMESingleFieldWorksheetEvalUpdate1Nullable1]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCallTypeId: function (cDAMWMESingleFieldWorksheetEvalUpdate1Nullable1, options) {
            return localVarFp.updateCallTypeId(cDAMWMESingleFieldWorksheetEvalUpdate1Nullable1, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1String} [cDAMWMESingleFieldWorksheetEvalUpdate1String]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateComment: function (cDAMWMESingleFieldWorksheetEvalUpdate1String, options) {
            return localVarFp.updateComment(cDAMWMESingleFieldWorksheetEvalUpdate1String, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMWMEOperatingHoursServiceCallEvalUpdate} [cDAMWMEOperatingHoursServiceCallEvalUpdate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCurrentEquipmentOperatingHours: function (cDAMWMEOperatingHoursServiceCallEvalUpdate, options) {
            return localVarFp.updateCurrentEquipmentOperatingHours(cDAMWMEOperatingHoursServiceCallEvalUpdate, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMWMEDeliveryAckServiceCallEvalUpdate} [cDAMWMEDeliveryAckServiceCallEvalUpdate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeliveryAck: function (cDAMWMEDeliveryAckServiceCallEvalUpdate, options) {
            return localVarFp.updateDeliveryAck(cDAMWMEDeliveryAckServiceCallEvalUpdate, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMWMEEquipmentChangeServiceCallEvalUpdate} [cDAMWMEEquipmentChangeServiceCallEvalUpdate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEquipment: function (cDAMWMEEquipmentChangeServiceCallEvalUpdate, options) {
            return localVarFp.updateEquipment(cDAMWMEEquipmentChangeServiceCallEvalUpdate, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1Boolean} [cDAMWMESingleFieldWorksheetEvalUpdate1Boolean]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEquipmentAck: function (cDAMWMESingleFieldWorksheetEvalUpdate1Boolean, options) {
            return localVarFp.updateEquipmentAck(cDAMWMESingleFieldWorksheetEvalUpdate1Boolean, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMWMEServiceCallEvalUpdate} [cDAMWMEServiceCallEvalUpdate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEval: function (cDAMWMEServiceCallEvalUpdate, options) {
            return localVarFp.updateEval(cDAMWMEServiceCallEvalUpdate, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1String} [cDAMWMESingleFieldWorksheetEvalUpdate1String]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFaultDescription: function (cDAMWMESingleFieldWorksheetEvalUpdate1String, options) {
            return localVarFp.updateFaultDescription(cDAMWMESingleFieldWorksheetEvalUpdate1String, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1Boolean} [cDAMWMESingleFieldWorksheetEvalUpdate1Boolean]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLinkEquipmentToServiceCall: function (cDAMWMESingleFieldWorksheetEvalUpdate1Boolean, options) {
            return localVarFp.updateLinkEquipmentToServiceCall(cDAMWMESingleFieldWorksheetEvalUpdate1Boolean, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1Boolean} [cDAMWMESingleFieldWorksheetEvalUpdate1Boolean]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManuallySetDeliveryDocEntry: function (cDAMWMESingleFieldWorksheetEvalUpdate1Boolean, options) {
            return localVarFp.updateManuallySetDeliveryDocEntry(cDAMWMESingleFieldWorksheetEvalUpdate1Boolean, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1Int32} [cDAMWMESingleFieldWorksheetEvalUpdate1Int32]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOwnerCode: function (cDAMWMESingleFieldWorksheetEvalUpdate1Int32, options) {
            return localVarFp.updateOwnerCode(cDAMWMESingleFieldWorksheetEvalUpdate1Int32, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1Int32} [cDAMWMESingleFieldWorksheetEvalUpdate1Int32]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSelectedCallId: function (cDAMWMESingleFieldWorksheetEvalUpdate1Int32, options) {
            return localVarFp.updateSelectedCallId(cDAMWMESingleFieldWorksheetEvalUpdate1Int32, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1Nullable1} [cDAMWMESingleFieldWorksheetEvalUpdate1Nullable1]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSelectedSapCallType: function (cDAMWMESingleFieldWorksheetEvalUpdate1Nullable1, options) {
            return localVarFp.updateSelectedSapCallType(cDAMWMESingleFieldWorksheetEvalUpdate1Nullable1, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1String} [cDAMWMESingleFieldWorksheetEvalUpdate1String]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateShippingSapAddressAndType: function (cDAMWMESingleFieldWorksheetEvalUpdate1String, options) {
            return localVarFp.updateShippingSapAddressAndType(cDAMWMESingleFieldWorksheetEvalUpdate1String, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1Int32} [cDAMWMESingleFieldWorksheetEvalUpdate1Int32]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSlpCode: function (cDAMWMESingleFieldWorksheetEvalUpdate1Int32, options) {
            return localVarFp.updateSlpCode(cDAMWMESingleFieldWorksheetEvalUpdate1Int32, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1String} [cDAMWMESingleFieldWorksheetEvalUpdate1String]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubject: function (cDAMWMESingleFieldWorksheetEvalUpdate1String, options) {
            return localVarFp.updateSubject(cDAMWMESingleFieldWorksheetEvalUpdate1String, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1String} [cDAMWMESingleFieldWorksheetEvalUpdate1String]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTaskDescription: function (cDAMWMESingleFieldWorksheetEvalUpdate1String, options) {
            return localVarFp.updateTaskDescription(cDAMWMESingleFieldWorksheetEvalUpdate1String, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1Int32} [cDAMWMESingleFieldWorksheetEvalUpdate1Int32]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTechnician: function (cDAMWMESingleFieldWorksheetEvalUpdate1Int32, options) {
            return localVarFp.updateTechnician(cDAMWMESingleFieldWorksheetEvalUpdate1Int32, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.ServiceCallEvalApiFactory = ServiceCallEvalApiFactory;
/**
 * ServiceCallEvalApi - object-oriented interface
 * @export
 * @class ServiceCallEvalApi
 * @extends {BaseAPI}
 */
var ServiceCallEvalApi = /** @class */ (function (_super) {
    __extends(ServiceCallEvalApi, _super);
    function ServiceCallEvalApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {boolean} [isDeleted]
     * @param {CDAMWMServiceCallEvalModel} [cDAMWMServiceCallEvalModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCallEvalApi
     */
    ServiceCallEvalApi.prototype._delete = function (isDeleted, cDAMWMServiceCallEvalModel, options) {
        var _this = this;
        return (0, exports.ServiceCallEvalApiFp)(this.configuration)._delete(isDeleted, cDAMWMServiceCallEvalModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CCMWECreateServiceCallForEvalParameters} [cCMWECreateServiceCallForEvalParameters]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCallEvalApi
     */
    ServiceCallEvalApi.prototype.createServiceCallForEval = function (cCMWECreateServiceCallForEvalParameters, options) {
        var _this = this;
        return (0, exports.ServiceCallEvalApiFp)(this.configuration).createServiceCallForEval(cCMWECreateServiceCallForEvalParameters, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {any} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCallEvalApi
     */
    ServiceCallEvalApi.prototype.findByIdObject = function (body, options) {
        var _this = this;
        return (0, exports.ServiceCallEvalApiFp)(this.configuration).findByIdObject(body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [wEvalId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCallEvalApi
     */
    ServiceCallEvalApi.prototype.findByWorksheetEvalId = function (wEvalId, options) {
        var _this = this;
        return (0, exports.ServiceCallEvalApiFp)(this.configuration).findByWorksheetEvalId(wEvalId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMWMServiceCallEvalModel} [cDAMWMServiceCallEvalModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCallEvalApi
     */
    ServiceCallEvalApi.prototype.insert = function (cDAMWMServiceCallEvalModel, options) {
        var _this = this;
        return (0, exports.ServiceCallEvalApiFp)(this.configuration).insert(cDAMWMServiceCallEvalModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {Array<CDAMWMServiceCallEvalModel>} [cDAMWMServiceCallEvalModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCallEvalApi
     */
    ServiceCallEvalApi.prototype.merge = function (cDAMWMServiceCallEvalModel, options) {
        var _this = this;
        return (0, exports.ServiceCallEvalApiFp)(this.configuration).merge(cDAMWMServiceCallEvalModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDCMGenericPagingParams} [cDCMGenericPagingParams]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCallEvalApi
     */
    ServiceCallEvalApi.prototype.pageAll = function (cDCMGenericPagingParams, options) {
        var _this = this;
        return (0, exports.ServiceCallEvalApiFp)(this.configuration).pageAll(cDCMGenericPagingParams, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMWMServiceCallEvalModel} [cDAMWMServiceCallEvalModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCallEvalApi
     */
    ServiceCallEvalApi.prototype.save = function (cDAMWMServiceCallEvalModel, options) {
        var _this = this;
        return (0, exports.ServiceCallEvalApiFp)(this.configuration).save(cDAMWMServiceCallEvalModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMWMServiceCallEvalModel} [cDAMWMServiceCallEvalModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCallEvalApi
     */
    ServiceCallEvalApi.prototype.update = function (cDAMWMServiceCallEvalModel, options) {
        var _this = this;
        return (0, exports.ServiceCallEvalApiFp)(this.configuration).update(cDAMWMServiceCallEvalModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMWMESingleFieldWorksheetEvalUpdate1String} [cDAMWMESingleFieldWorksheetEvalUpdate1String]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCallEvalApi
     */
    ServiceCallEvalApi.prototype.updateBillingSapAddressAndType = function (cDAMWMESingleFieldWorksheetEvalUpdate1String, options) {
        var _this = this;
        return (0, exports.ServiceCallEvalApiFp)(this.configuration).updateBillingSapAddressAndType(cDAMWMESingleFieldWorksheetEvalUpdate1String, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMWMESingleFieldWorksheetEvalUpdate1Nullable1} [cDAMWMESingleFieldWorksheetEvalUpdate1Nullable1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCallEvalApi
     */
    ServiceCallEvalApi.prototype.updateCallTypeId = function (cDAMWMESingleFieldWorksheetEvalUpdate1Nullable1, options) {
        var _this = this;
        return (0, exports.ServiceCallEvalApiFp)(this.configuration).updateCallTypeId(cDAMWMESingleFieldWorksheetEvalUpdate1Nullable1, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMWMESingleFieldWorksheetEvalUpdate1String} [cDAMWMESingleFieldWorksheetEvalUpdate1String]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCallEvalApi
     */
    ServiceCallEvalApi.prototype.updateComment = function (cDAMWMESingleFieldWorksheetEvalUpdate1String, options) {
        var _this = this;
        return (0, exports.ServiceCallEvalApiFp)(this.configuration).updateComment(cDAMWMESingleFieldWorksheetEvalUpdate1String, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMWMEOperatingHoursServiceCallEvalUpdate} [cDAMWMEOperatingHoursServiceCallEvalUpdate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCallEvalApi
     */
    ServiceCallEvalApi.prototype.updateCurrentEquipmentOperatingHours = function (cDAMWMEOperatingHoursServiceCallEvalUpdate, options) {
        var _this = this;
        return (0, exports.ServiceCallEvalApiFp)(this.configuration).updateCurrentEquipmentOperatingHours(cDAMWMEOperatingHoursServiceCallEvalUpdate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMWMEDeliveryAckServiceCallEvalUpdate} [cDAMWMEDeliveryAckServiceCallEvalUpdate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCallEvalApi
     */
    ServiceCallEvalApi.prototype.updateDeliveryAck = function (cDAMWMEDeliveryAckServiceCallEvalUpdate, options) {
        var _this = this;
        return (0, exports.ServiceCallEvalApiFp)(this.configuration).updateDeliveryAck(cDAMWMEDeliveryAckServiceCallEvalUpdate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMWMEEquipmentChangeServiceCallEvalUpdate} [cDAMWMEEquipmentChangeServiceCallEvalUpdate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCallEvalApi
     */
    ServiceCallEvalApi.prototype.updateEquipment = function (cDAMWMEEquipmentChangeServiceCallEvalUpdate, options) {
        var _this = this;
        return (0, exports.ServiceCallEvalApiFp)(this.configuration).updateEquipment(cDAMWMEEquipmentChangeServiceCallEvalUpdate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMWMESingleFieldWorksheetEvalUpdate1Boolean} [cDAMWMESingleFieldWorksheetEvalUpdate1Boolean]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCallEvalApi
     */
    ServiceCallEvalApi.prototype.updateEquipmentAck = function (cDAMWMESingleFieldWorksheetEvalUpdate1Boolean, options) {
        var _this = this;
        return (0, exports.ServiceCallEvalApiFp)(this.configuration).updateEquipmentAck(cDAMWMESingleFieldWorksheetEvalUpdate1Boolean, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMWMEServiceCallEvalUpdate} [cDAMWMEServiceCallEvalUpdate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCallEvalApi
     */
    ServiceCallEvalApi.prototype.updateEval = function (cDAMWMEServiceCallEvalUpdate, options) {
        var _this = this;
        return (0, exports.ServiceCallEvalApiFp)(this.configuration).updateEval(cDAMWMEServiceCallEvalUpdate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMWMESingleFieldWorksheetEvalUpdate1String} [cDAMWMESingleFieldWorksheetEvalUpdate1String]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCallEvalApi
     */
    ServiceCallEvalApi.prototype.updateFaultDescription = function (cDAMWMESingleFieldWorksheetEvalUpdate1String, options) {
        var _this = this;
        return (0, exports.ServiceCallEvalApiFp)(this.configuration).updateFaultDescription(cDAMWMESingleFieldWorksheetEvalUpdate1String, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMWMESingleFieldWorksheetEvalUpdate1Boolean} [cDAMWMESingleFieldWorksheetEvalUpdate1Boolean]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCallEvalApi
     */
    ServiceCallEvalApi.prototype.updateLinkEquipmentToServiceCall = function (cDAMWMESingleFieldWorksheetEvalUpdate1Boolean, options) {
        var _this = this;
        return (0, exports.ServiceCallEvalApiFp)(this.configuration).updateLinkEquipmentToServiceCall(cDAMWMESingleFieldWorksheetEvalUpdate1Boolean, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMWMESingleFieldWorksheetEvalUpdate1Boolean} [cDAMWMESingleFieldWorksheetEvalUpdate1Boolean]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCallEvalApi
     */
    ServiceCallEvalApi.prototype.updateManuallySetDeliveryDocEntry = function (cDAMWMESingleFieldWorksheetEvalUpdate1Boolean, options) {
        var _this = this;
        return (0, exports.ServiceCallEvalApiFp)(this.configuration).updateManuallySetDeliveryDocEntry(cDAMWMESingleFieldWorksheetEvalUpdate1Boolean, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMWMESingleFieldWorksheetEvalUpdate1Int32} [cDAMWMESingleFieldWorksheetEvalUpdate1Int32]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCallEvalApi
     */
    ServiceCallEvalApi.prototype.updateOwnerCode = function (cDAMWMESingleFieldWorksheetEvalUpdate1Int32, options) {
        var _this = this;
        return (0, exports.ServiceCallEvalApiFp)(this.configuration).updateOwnerCode(cDAMWMESingleFieldWorksheetEvalUpdate1Int32, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMWMESingleFieldWorksheetEvalUpdate1Int32} [cDAMWMESingleFieldWorksheetEvalUpdate1Int32]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCallEvalApi
     */
    ServiceCallEvalApi.prototype.updateSelectedCallId = function (cDAMWMESingleFieldWorksheetEvalUpdate1Int32, options) {
        var _this = this;
        return (0, exports.ServiceCallEvalApiFp)(this.configuration).updateSelectedCallId(cDAMWMESingleFieldWorksheetEvalUpdate1Int32, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMWMESingleFieldWorksheetEvalUpdate1Nullable1} [cDAMWMESingleFieldWorksheetEvalUpdate1Nullable1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCallEvalApi
     */
    ServiceCallEvalApi.prototype.updateSelectedSapCallType = function (cDAMWMESingleFieldWorksheetEvalUpdate1Nullable1, options) {
        var _this = this;
        return (0, exports.ServiceCallEvalApiFp)(this.configuration).updateSelectedSapCallType(cDAMWMESingleFieldWorksheetEvalUpdate1Nullable1, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMWMESingleFieldWorksheetEvalUpdate1String} [cDAMWMESingleFieldWorksheetEvalUpdate1String]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCallEvalApi
     */
    ServiceCallEvalApi.prototype.updateShippingSapAddressAndType = function (cDAMWMESingleFieldWorksheetEvalUpdate1String, options) {
        var _this = this;
        return (0, exports.ServiceCallEvalApiFp)(this.configuration).updateShippingSapAddressAndType(cDAMWMESingleFieldWorksheetEvalUpdate1String, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMWMESingleFieldWorksheetEvalUpdate1Int32} [cDAMWMESingleFieldWorksheetEvalUpdate1Int32]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCallEvalApi
     */
    ServiceCallEvalApi.prototype.updateSlpCode = function (cDAMWMESingleFieldWorksheetEvalUpdate1Int32, options) {
        var _this = this;
        return (0, exports.ServiceCallEvalApiFp)(this.configuration).updateSlpCode(cDAMWMESingleFieldWorksheetEvalUpdate1Int32, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMWMESingleFieldWorksheetEvalUpdate1String} [cDAMWMESingleFieldWorksheetEvalUpdate1String]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCallEvalApi
     */
    ServiceCallEvalApi.prototype.updateSubject = function (cDAMWMESingleFieldWorksheetEvalUpdate1String, options) {
        var _this = this;
        return (0, exports.ServiceCallEvalApiFp)(this.configuration).updateSubject(cDAMWMESingleFieldWorksheetEvalUpdate1String, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMWMESingleFieldWorksheetEvalUpdate1String} [cDAMWMESingleFieldWorksheetEvalUpdate1String]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCallEvalApi
     */
    ServiceCallEvalApi.prototype.updateTaskDescription = function (cDAMWMESingleFieldWorksheetEvalUpdate1String, options) {
        var _this = this;
        return (0, exports.ServiceCallEvalApiFp)(this.configuration).updateTaskDescription(cDAMWMESingleFieldWorksheetEvalUpdate1String, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMWMESingleFieldWorksheetEvalUpdate1Int32} [cDAMWMESingleFieldWorksheetEvalUpdate1Int32]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCallEvalApi
     */
    ServiceCallEvalApi.prototype.updateTechnician = function (cDAMWMESingleFieldWorksheetEvalUpdate1Int32, options) {
        var _this = this;
        return (0, exports.ServiceCallEvalApiFp)(this.configuration).updateTechnician(cDAMWMESingleFieldWorksheetEvalUpdate1Int32, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return ServiceCallEvalApi;
}(base_1.BaseAPI));
exports.ServiceCallEvalApi = ServiceCallEvalApi;
/**
 * ServiceCallItemRowApi - axios parameter creator
 * @export
 */
var ServiceCallItemRowApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {string} [scId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByServiceCallId: function (scId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/ServiceCallItemRow/FindByServiceCallId";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (scId !== undefined) {
                                localVarQueryParameter['scId'] = scId;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [wId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTripItemRowsByWorksheetId: function (wId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/ServiceCallItemRow/FindTripItemRowsByWorksheetId";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (wId !== undefined) {
                                localVarQueryParameter['wId'] = wId;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.ServiceCallItemRowApiAxiosParamCreator = ServiceCallItemRowApiAxiosParamCreator;
/**
 * ServiceCallItemRowApi - functional programming interface
 * @export
 */
var ServiceCallItemRowApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.ServiceCallItemRowApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {string} [scId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByServiceCallId: function (scId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findByServiceCallId(scId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [wId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTripItemRowsByWorksheetId: function (wId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findTripItemRowsByWorksheetId(wId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.ServiceCallItemRowApiFp = ServiceCallItemRowApiFp;
/**
 * ServiceCallItemRowApi - factory interface
 * @export
 */
var ServiceCallItemRowApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.ServiceCallItemRowApiFp)(configuration);
    return {
        /**
         *
         * @param {string} [scId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByServiceCallId: function (scId, options) {
            return localVarFp.findByServiceCallId(scId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [wId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTripItemRowsByWorksheetId: function (wId, options) {
            return localVarFp.findTripItemRowsByWorksheetId(wId, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.ServiceCallItemRowApiFactory = ServiceCallItemRowApiFactory;
/**
 * ServiceCallItemRowApi - object-oriented interface
 * @export
 * @class ServiceCallItemRowApi
 * @extends {BaseAPI}
 */
var ServiceCallItemRowApi = /** @class */ (function (_super) {
    __extends(ServiceCallItemRowApi, _super);
    function ServiceCallItemRowApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {string} [scId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCallItemRowApi
     */
    ServiceCallItemRowApi.prototype.findByServiceCallId = function (scId, options) {
        var _this = this;
        return (0, exports.ServiceCallItemRowApiFp)(this.configuration).findByServiceCallId(scId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [wId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCallItemRowApi
     */
    ServiceCallItemRowApi.prototype.findTripItemRowsByWorksheetId = function (wId, options) {
        var _this = this;
        return (0, exports.ServiceCallItemRowApiFp)(this.configuration).findTripItemRowsByWorksheetId(wId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return ServiceCallItemRowApi;
}(base_1.BaseAPI));
exports.ServiceCallItemRowApi = ServiceCallItemRowApi;
/**
 * ServiceCallItemRowEvalApi - axios parameter creator
 * @export
 */
var ServiceCallItemRowEvalApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {boolean} [isDeleted]
         * @param {CDAMWMServiceCallItemRowEvalModel} [cDAMWMServiceCallItemRowEvalModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: function (isDeleted, cDAMWMServiceCallItemRowEvalModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/ServiceCallItemRowEval/Delete";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (isDeleted !== undefined) {
                                localVarQueryParameter['isDeleted'] = isDeleted;
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMServiceCallItemRowEvalModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMEServiceCallItemRowEvalAddItemRowAndEvaluationParams} [cDAMWMEServiceCallItemRowEvalAddItemRowAndEvaluationParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addItemRowAndEvaluation: function (cDAMWMEServiceCallItemRowEvalAddItemRowAndEvaluationParams, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/ServiceCallItemRowEval/AddItemRowAndEvaluation";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMEServiceCallItemRowEvalAddItemRowAndEvaluationParams, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {any} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdObject: function (body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/ServiceCallItemRowEval/FindByIdObject";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(body, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [scEvalId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByServiceCallEvalId: function (scEvalId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/ServiceCallItemRowEval/FindByServiceCallEvalId";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (scEvalId !== undefined) {
                                localVarQueryParameter['scEvalId'] = scEvalId;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMServiceCallItemRowEvalModel} [cDAMWMServiceCallItemRowEvalModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insert: function (cDAMWMServiceCallItemRowEvalModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/ServiceCallItemRowEval/Insert";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMServiceCallItemRowEvalModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<CDAMWMServiceCallItemRowEvalModel>} [cDAMWMServiceCallItemRowEvalModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merge: function (cDAMWMServiceCallItemRowEvalModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/ServiceCallItemRowEval/Merge";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMServiceCallItemRowEvalModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDCMGenericPagingParams} [cDCMGenericPagingParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageAll: function (cDCMGenericPagingParams, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/ServiceCallItemRowEval/PageAll";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDCMGenericPagingParams, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMServiceCallItemRowEvalModel} [cDAMWMServiceCallItemRowEvalModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: function (cDAMWMServiceCallItemRowEvalModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/ServiceCallItemRowEval/Save";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMServiceCallItemRowEvalModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMServiceCallItemRowEvalModel} [cDAMWMServiceCallItemRowEvalModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: function (cDAMWMServiceCallItemRowEvalModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/ServiceCallItemRowEval/Update";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMServiceCallItemRowEvalModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1Boolean} [cDAMWMESingleFieldWorksheetEvalUpdate1Boolean]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAck: function (cDAMWMESingleFieldWorksheetEvalUpdate1Boolean, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/ServiceCallItemRowEval/UpdateAck";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMESingleFieldWorksheetEvalUpdate1Boolean, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMEServiceCallItemRowEvalUpdate} [cDAMWMEServiceCallItemRowEvalUpdate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEval: function (cDAMWMEServiceCallItemRowEvalUpdate, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/ServiceCallItemRowEval/UpdateEval";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMEServiceCallItemRowEvalUpdate, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1Decimal} [cDAMWMESingleFieldWorksheetEvalUpdate1Decimal]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInstalledQuantity: function (cDAMWMESingleFieldWorksheetEvalUpdate1Decimal, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/ServiceCallItemRowEval/UpdateInstalledQuantity";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMESingleFieldWorksheetEvalUpdate1Decimal, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMEOcrCodeServiceCallItemRowEvalUpdate} [cDAMWMEOcrCodeServiceCallItemRowEvalUpdate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOcrCode: function (cDAMWMEOcrCodeServiceCallItemRowEvalUpdate, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/ServiceCallItemRowEval/UpdateOcrCode";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMEOcrCodeServiceCallItemRowEvalUpdate, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.ServiceCallItemRowEvalApiAxiosParamCreator = ServiceCallItemRowEvalApiAxiosParamCreator;
/**
 * ServiceCallItemRowEvalApi - functional programming interface
 * @export
 */
var ServiceCallItemRowEvalApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.ServiceCallItemRowEvalApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {boolean} [isDeleted]
         * @param {CDAMWMServiceCallItemRowEvalModel} [cDAMWMServiceCallItemRowEvalModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: function (isDeleted, cDAMWMServiceCallItemRowEvalModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator._delete(isDeleted, cDAMWMServiceCallItemRowEvalModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMEServiceCallItemRowEvalAddItemRowAndEvaluationParams} [cDAMWMEServiceCallItemRowEvalAddItemRowAndEvaluationParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addItemRowAndEvaluation: function (cDAMWMEServiceCallItemRowEvalAddItemRowAndEvaluationParams, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.addItemRowAndEvaluation(cDAMWMEServiceCallItemRowEvalAddItemRowAndEvaluationParams, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {any} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdObject: function (body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findByIdObject(body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [scEvalId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByServiceCallEvalId: function (scEvalId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findByServiceCallEvalId(scEvalId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMServiceCallItemRowEvalModel} [cDAMWMServiceCallItemRowEvalModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insert: function (cDAMWMServiceCallItemRowEvalModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.insert(cDAMWMServiceCallItemRowEvalModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<CDAMWMServiceCallItemRowEvalModel>} [cDAMWMServiceCallItemRowEvalModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merge: function (cDAMWMServiceCallItemRowEvalModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.merge(cDAMWMServiceCallItemRowEvalModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDCMGenericPagingParams} [cDCMGenericPagingParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageAll: function (cDCMGenericPagingParams, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pageAll(cDCMGenericPagingParams, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMServiceCallItemRowEvalModel} [cDAMWMServiceCallItemRowEvalModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: function (cDAMWMServiceCallItemRowEvalModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.save(cDAMWMServiceCallItemRowEvalModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMServiceCallItemRowEvalModel} [cDAMWMServiceCallItemRowEvalModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: function (cDAMWMServiceCallItemRowEvalModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.update(cDAMWMServiceCallItemRowEvalModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1Boolean} [cDAMWMESingleFieldWorksheetEvalUpdate1Boolean]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAck: function (cDAMWMESingleFieldWorksheetEvalUpdate1Boolean, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateAck(cDAMWMESingleFieldWorksheetEvalUpdate1Boolean, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMEServiceCallItemRowEvalUpdate} [cDAMWMEServiceCallItemRowEvalUpdate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEval: function (cDAMWMEServiceCallItemRowEvalUpdate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateEval(cDAMWMEServiceCallItemRowEvalUpdate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1Decimal} [cDAMWMESingleFieldWorksheetEvalUpdate1Decimal]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInstalledQuantity: function (cDAMWMESingleFieldWorksheetEvalUpdate1Decimal, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateInstalledQuantity(cDAMWMESingleFieldWorksheetEvalUpdate1Decimal, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMEOcrCodeServiceCallItemRowEvalUpdate} [cDAMWMEOcrCodeServiceCallItemRowEvalUpdate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOcrCode: function (cDAMWMEOcrCodeServiceCallItemRowEvalUpdate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateOcrCode(cDAMWMEOcrCodeServiceCallItemRowEvalUpdate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.ServiceCallItemRowEvalApiFp = ServiceCallItemRowEvalApiFp;
/**
 * ServiceCallItemRowEvalApi - factory interface
 * @export
 */
var ServiceCallItemRowEvalApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.ServiceCallItemRowEvalApiFp)(configuration);
    return {
        /**
         *
         * @param {boolean} [isDeleted]
         * @param {CDAMWMServiceCallItemRowEvalModel} [cDAMWMServiceCallItemRowEvalModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: function (isDeleted, cDAMWMServiceCallItemRowEvalModel, options) {
            return localVarFp._delete(isDeleted, cDAMWMServiceCallItemRowEvalModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMWMEServiceCallItemRowEvalAddItemRowAndEvaluationParams} [cDAMWMEServiceCallItemRowEvalAddItemRowAndEvaluationParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addItemRowAndEvaluation: function (cDAMWMEServiceCallItemRowEvalAddItemRowAndEvaluationParams, options) {
            return localVarFp.addItemRowAndEvaluation(cDAMWMEServiceCallItemRowEvalAddItemRowAndEvaluationParams, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {any} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdObject: function (body, options) {
            return localVarFp.findByIdObject(body, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [scEvalId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByServiceCallEvalId: function (scEvalId, options) {
            return localVarFp.findByServiceCallEvalId(scEvalId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMWMServiceCallItemRowEvalModel} [cDAMWMServiceCallItemRowEvalModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insert: function (cDAMWMServiceCallItemRowEvalModel, options) {
            return localVarFp.insert(cDAMWMServiceCallItemRowEvalModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {Array<CDAMWMServiceCallItemRowEvalModel>} [cDAMWMServiceCallItemRowEvalModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merge: function (cDAMWMServiceCallItemRowEvalModel, options) {
            return localVarFp.merge(cDAMWMServiceCallItemRowEvalModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDCMGenericPagingParams} [cDCMGenericPagingParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageAll: function (cDCMGenericPagingParams, options) {
            return localVarFp.pageAll(cDCMGenericPagingParams, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMWMServiceCallItemRowEvalModel} [cDAMWMServiceCallItemRowEvalModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: function (cDAMWMServiceCallItemRowEvalModel, options) {
            return localVarFp.save(cDAMWMServiceCallItemRowEvalModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMWMServiceCallItemRowEvalModel} [cDAMWMServiceCallItemRowEvalModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: function (cDAMWMServiceCallItemRowEvalModel, options) {
            return localVarFp.update(cDAMWMServiceCallItemRowEvalModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1Boolean} [cDAMWMESingleFieldWorksheetEvalUpdate1Boolean]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAck: function (cDAMWMESingleFieldWorksheetEvalUpdate1Boolean, options) {
            return localVarFp.updateAck(cDAMWMESingleFieldWorksheetEvalUpdate1Boolean, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMWMEServiceCallItemRowEvalUpdate} [cDAMWMEServiceCallItemRowEvalUpdate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEval: function (cDAMWMEServiceCallItemRowEvalUpdate, options) {
            return localVarFp.updateEval(cDAMWMEServiceCallItemRowEvalUpdate, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1Decimal} [cDAMWMESingleFieldWorksheetEvalUpdate1Decimal]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInstalledQuantity: function (cDAMWMESingleFieldWorksheetEvalUpdate1Decimal, options) {
            return localVarFp.updateInstalledQuantity(cDAMWMESingleFieldWorksheetEvalUpdate1Decimal, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMWMEOcrCodeServiceCallItemRowEvalUpdate} [cDAMWMEOcrCodeServiceCallItemRowEvalUpdate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOcrCode: function (cDAMWMEOcrCodeServiceCallItemRowEvalUpdate, options) {
            return localVarFp.updateOcrCode(cDAMWMEOcrCodeServiceCallItemRowEvalUpdate, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.ServiceCallItemRowEvalApiFactory = ServiceCallItemRowEvalApiFactory;
/**
 * ServiceCallItemRowEvalApi - object-oriented interface
 * @export
 * @class ServiceCallItemRowEvalApi
 * @extends {BaseAPI}
 */
var ServiceCallItemRowEvalApi = /** @class */ (function (_super) {
    __extends(ServiceCallItemRowEvalApi, _super);
    function ServiceCallItemRowEvalApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {boolean} [isDeleted]
     * @param {CDAMWMServiceCallItemRowEvalModel} [cDAMWMServiceCallItemRowEvalModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCallItemRowEvalApi
     */
    ServiceCallItemRowEvalApi.prototype._delete = function (isDeleted, cDAMWMServiceCallItemRowEvalModel, options) {
        var _this = this;
        return (0, exports.ServiceCallItemRowEvalApiFp)(this.configuration)._delete(isDeleted, cDAMWMServiceCallItemRowEvalModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMWMEServiceCallItemRowEvalAddItemRowAndEvaluationParams} [cDAMWMEServiceCallItemRowEvalAddItemRowAndEvaluationParams]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCallItemRowEvalApi
     */
    ServiceCallItemRowEvalApi.prototype.addItemRowAndEvaluation = function (cDAMWMEServiceCallItemRowEvalAddItemRowAndEvaluationParams, options) {
        var _this = this;
        return (0, exports.ServiceCallItemRowEvalApiFp)(this.configuration).addItemRowAndEvaluation(cDAMWMEServiceCallItemRowEvalAddItemRowAndEvaluationParams, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {any} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCallItemRowEvalApi
     */
    ServiceCallItemRowEvalApi.prototype.findByIdObject = function (body, options) {
        var _this = this;
        return (0, exports.ServiceCallItemRowEvalApiFp)(this.configuration).findByIdObject(body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [scEvalId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCallItemRowEvalApi
     */
    ServiceCallItemRowEvalApi.prototype.findByServiceCallEvalId = function (scEvalId, options) {
        var _this = this;
        return (0, exports.ServiceCallItemRowEvalApiFp)(this.configuration).findByServiceCallEvalId(scEvalId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMWMServiceCallItemRowEvalModel} [cDAMWMServiceCallItemRowEvalModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCallItemRowEvalApi
     */
    ServiceCallItemRowEvalApi.prototype.insert = function (cDAMWMServiceCallItemRowEvalModel, options) {
        var _this = this;
        return (0, exports.ServiceCallItemRowEvalApiFp)(this.configuration).insert(cDAMWMServiceCallItemRowEvalModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {Array<CDAMWMServiceCallItemRowEvalModel>} [cDAMWMServiceCallItemRowEvalModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCallItemRowEvalApi
     */
    ServiceCallItemRowEvalApi.prototype.merge = function (cDAMWMServiceCallItemRowEvalModel, options) {
        var _this = this;
        return (0, exports.ServiceCallItemRowEvalApiFp)(this.configuration).merge(cDAMWMServiceCallItemRowEvalModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDCMGenericPagingParams} [cDCMGenericPagingParams]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCallItemRowEvalApi
     */
    ServiceCallItemRowEvalApi.prototype.pageAll = function (cDCMGenericPagingParams, options) {
        var _this = this;
        return (0, exports.ServiceCallItemRowEvalApiFp)(this.configuration).pageAll(cDCMGenericPagingParams, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMWMServiceCallItemRowEvalModel} [cDAMWMServiceCallItemRowEvalModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCallItemRowEvalApi
     */
    ServiceCallItemRowEvalApi.prototype.save = function (cDAMWMServiceCallItemRowEvalModel, options) {
        var _this = this;
        return (0, exports.ServiceCallItemRowEvalApiFp)(this.configuration).save(cDAMWMServiceCallItemRowEvalModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMWMServiceCallItemRowEvalModel} [cDAMWMServiceCallItemRowEvalModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCallItemRowEvalApi
     */
    ServiceCallItemRowEvalApi.prototype.update = function (cDAMWMServiceCallItemRowEvalModel, options) {
        var _this = this;
        return (0, exports.ServiceCallItemRowEvalApiFp)(this.configuration).update(cDAMWMServiceCallItemRowEvalModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMWMESingleFieldWorksheetEvalUpdate1Boolean} [cDAMWMESingleFieldWorksheetEvalUpdate1Boolean]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCallItemRowEvalApi
     */
    ServiceCallItemRowEvalApi.prototype.updateAck = function (cDAMWMESingleFieldWorksheetEvalUpdate1Boolean, options) {
        var _this = this;
        return (0, exports.ServiceCallItemRowEvalApiFp)(this.configuration).updateAck(cDAMWMESingleFieldWorksheetEvalUpdate1Boolean, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMWMEServiceCallItemRowEvalUpdate} [cDAMWMEServiceCallItemRowEvalUpdate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCallItemRowEvalApi
     */
    ServiceCallItemRowEvalApi.prototype.updateEval = function (cDAMWMEServiceCallItemRowEvalUpdate, options) {
        var _this = this;
        return (0, exports.ServiceCallItemRowEvalApiFp)(this.configuration).updateEval(cDAMWMEServiceCallItemRowEvalUpdate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMWMESingleFieldWorksheetEvalUpdate1Decimal} [cDAMWMESingleFieldWorksheetEvalUpdate1Decimal]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCallItemRowEvalApi
     */
    ServiceCallItemRowEvalApi.prototype.updateInstalledQuantity = function (cDAMWMESingleFieldWorksheetEvalUpdate1Decimal, options) {
        var _this = this;
        return (0, exports.ServiceCallItemRowEvalApiFp)(this.configuration).updateInstalledQuantity(cDAMWMESingleFieldWorksheetEvalUpdate1Decimal, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMWMEOcrCodeServiceCallItemRowEvalUpdate} [cDAMWMEOcrCodeServiceCallItemRowEvalUpdate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceCallItemRowEvalApi
     */
    ServiceCallItemRowEvalApi.prototype.updateOcrCode = function (cDAMWMEOcrCodeServiceCallItemRowEvalUpdate, options) {
        var _this = this;
        return (0, exports.ServiceCallItemRowEvalApiFp)(this.configuration).updateOcrCode(cDAMWMEOcrCodeServiceCallItemRowEvalUpdate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return ServiceCallItemRowEvalApi;
}(base_1.BaseAPI));
exports.ServiceCallItemRowEvalApi = ServiceCallItemRowEvalApi;
/**
 * ServiceIssueReportApi - axios parameter creator
 * @export
 */
var ServiceIssueReportApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {string} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findById: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/ServiceIssueReport/ServiceIssueReport/FindById";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (id !== undefined) {
                                localVarQueryParameter['id'] = id;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMSMServiceIssueReportModel} [cDAMSMServiceIssueReportModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: function (cDAMSMServiceIssueReportModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/ServiceIssueReport/ServiceIssueReport/Save";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMSMServiceIssueReportModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDCMGenericPagingParams} [cDCMGenericPagingParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPageBy: function (cDCMGenericPagingParams, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/ServiceIssueReport/ServiceIssueReport/SearchPageBy";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDCMGenericPagingParams, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.ServiceIssueReportApiAxiosParamCreator = ServiceIssueReportApiAxiosParamCreator;
/**
 * ServiceIssueReportApi - functional programming interface
 * @export
 */
var ServiceIssueReportApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.ServiceIssueReportApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {string} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findById: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findById(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMSMServiceIssueReportModel} [cDAMSMServiceIssueReportModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: function (cDAMSMServiceIssueReportModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.save(cDAMSMServiceIssueReportModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDCMGenericPagingParams} [cDCMGenericPagingParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPageBy: function (cDCMGenericPagingParams, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.searchPageBy(cDCMGenericPagingParams, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.ServiceIssueReportApiFp = ServiceIssueReportApiFp;
/**
 * ServiceIssueReportApi - factory interface
 * @export
 */
var ServiceIssueReportApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.ServiceIssueReportApiFp)(configuration);
    return {
        /**
         *
         * @param {string} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findById: function (id, options) {
            return localVarFp.findById(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMSMServiceIssueReportModel} [cDAMSMServiceIssueReportModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: function (cDAMSMServiceIssueReportModel, options) {
            return localVarFp.save(cDAMSMServiceIssueReportModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDCMGenericPagingParams} [cDCMGenericPagingParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPageBy: function (cDCMGenericPagingParams, options) {
            return localVarFp.searchPageBy(cDCMGenericPagingParams, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.ServiceIssueReportApiFactory = ServiceIssueReportApiFactory;
/**
 * ServiceIssueReportApi - object-oriented interface
 * @export
 * @class ServiceIssueReportApi
 * @extends {BaseAPI}
 */
var ServiceIssueReportApi = /** @class */ (function (_super) {
    __extends(ServiceIssueReportApi, _super);
    function ServiceIssueReportApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {string} [id]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceIssueReportApi
     */
    ServiceIssueReportApi.prototype.findById = function (id, options) {
        var _this = this;
        return (0, exports.ServiceIssueReportApiFp)(this.configuration).findById(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMSMServiceIssueReportModel} [cDAMSMServiceIssueReportModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceIssueReportApi
     */
    ServiceIssueReportApi.prototype.save = function (cDAMSMServiceIssueReportModel, options) {
        var _this = this;
        return (0, exports.ServiceIssueReportApiFp)(this.configuration).save(cDAMSMServiceIssueReportModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDCMGenericPagingParams} [cDCMGenericPagingParams]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceIssueReportApi
     */
    ServiceIssueReportApi.prototype.searchPageBy = function (cDCMGenericPagingParams, options) {
        var _this = this;
        return (0, exports.ServiceIssueReportApiFp)(this.configuration).searchPageBy(cDCMGenericPagingParams, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return ServiceIssueReportApi;
}(base_1.BaseAPI));
exports.ServiceIssueReportApi = ServiceIssueReportApi;
/**
 * SpeechToTextApi - axios parameter creator
 * @export
 */
var SpeechToTextApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {string} [blobId]
         * @param {CSTranscribeLanguage} [language]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPreviousResult: function (blobId, language, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Common/SpeechToText/FindPreviousResult";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (blobId !== undefined) {
                                localVarQueryParameter['blobId'] = blobId;
                            }
                            if (language !== undefined) {
                                localVarQueryParameter['language'] = language;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [blobId]
         * @param {CSTranscribeLanguage} [language]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transcribeBlob: function (blobId, language, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Common/SpeechToText/TranscribeBlob";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (blobId !== undefined) {
                                localVarQueryParameter['blobId'] = blobId;
                            }
                            if (language !== undefined) {
                                localVarQueryParameter['language'] = language;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.SpeechToTextApiAxiosParamCreator = SpeechToTextApiAxiosParamCreator;
/**
 * SpeechToTextApi - functional programming interface
 * @export
 */
var SpeechToTextApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.SpeechToTextApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {string} [blobId]
         * @param {CSTranscribeLanguage} [language]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPreviousResult: function (blobId, language, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findPreviousResult(blobId, language, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [blobId]
         * @param {CSTranscribeLanguage} [language]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transcribeBlob: function (blobId, language, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.transcribeBlob(blobId, language, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.SpeechToTextApiFp = SpeechToTextApiFp;
/**
 * SpeechToTextApi - factory interface
 * @export
 */
var SpeechToTextApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.SpeechToTextApiFp)(configuration);
    return {
        /**
         *
         * @param {string} [blobId]
         * @param {CSTranscribeLanguage} [language]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPreviousResult: function (blobId, language, options) {
            return localVarFp.findPreviousResult(blobId, language, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [blobId]
         * @param {CSTranscribeLanguage} [language]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transcribeBlob: function (blobId, language, options) {
            return localVarFp.transcribeBlob(blobId, language, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.SpeechToTextApiFactory = SpeechToTextApiFactory;
/**
 * SpeechToTextApi - object-oriented interface
 * @export
 * @class SpeechToTextApi
 * @extends {BaseAPI}
 */
var SpeechToTextApi = /** @class */ (function (_super) {
    __extends(SpeechToTextApi, _super);
    function SpeechToTextApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {string} [blobId]
     * @param {CSTranscribeLanguage} [language]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpeechToTextApi
     */
    SpeechToTextApi.prototype.findPreviousResult = function (blobId, language, options) {
        var _this = this;
        return (0, exports.SpeechToTextApiFp)(this.configuration).findPreviousResult(blobId, language, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [blobId]
     * @param {CSTranscribeLanguage} [language]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpeechToTextApi
     */
    SpeechToTextApi.prototype.transcribeBlob = function (blobId, language, options) {
        var _this = this;
        return (0, exports.SpeechToTextApiFp)(this.configuration).transcribeBlob(blobId, language, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return SpeechToTextApi;
}(base_1.BaseAPI));
exports.SpeechToTextApi = SpeechToTextApi;
/**
 * TestApi - axios parameter creator
 * @export
 */
var TestApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        heartbeat: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Generic/Test/Heartbeat";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [worksheetId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPdf: function (worksheetId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Generic/Test/TestPdf";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (worksheetId !== undefined) {
                                localVarQueryParameter['worksheetId'] = worksheetId;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.TestApiAxiosParamCreator = TestApiAxiosParamCreator;
/**
 * TestApi - functional programming interface
 * @export
 */
var TestApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.TestApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        heartbeat: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.heartbeat(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [worksheetId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPdf: function (worksheetId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.testPdf(worksheetId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.TestApiFp = TestApiFp;
/**
 * TestApi - factory interface
 * @export
 */
var TestApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.TestApiFp)(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        heartbeat: function (options) {
            return localVarFp.heartbeat(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [worksheetId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPdf: function (worksheetId, options) {
            return localVarFp.testPdf(worksheetId, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.TestApiFactory = TestApiFactory;
/**
 * TestApi - object-oriented interface
 * @export
 * @class TestApi
 * @extends {BaseAPI}
 */
var TestApi = /** @class */ (function (_super) {
    __extends(TestApi, _super);
    function TestApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    TestApi.prototype.heartbeat = function (options) {
        var _this = this;
        return (0, exports.TestApiFp)(this.configuration).heartbeat(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [worksheetId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    TestApi.prototype.testPdf = function (worksheetId, options) {
        var _this = this;
        return (0, exports.TestApiFp)(this.configuration).testPdf(worksheetId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return TestApi;
}(base_1.BaseAPI));
exports.TestApi = TestApi;
/**
 * TvhBlobApi - axios parameter creator
 * @export
 */
var TvhBlobApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {Array<string>} [requestBody]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findBySapItemCodes: function (requestBody, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Tvh/TvhBlob/FindBySapItemCodes";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(requestBody, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.TvhBlobApiAxiosParamCreator = TvhBlobApiAxiosParamCreator;
/**
 * TvhBlobApi - functional programming interface
 * @export
 */
var TvhBlobApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.TvhBlobApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {Array<string>} [requestBody]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findBySapItemCodes: function (requestBody, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findBySapItemCodes(requestBody, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.TvhBlobApiFp = TvhBlobApiFp;
/**
 * TvhBlobApi - factory interface
 * @export
 */
var TvhBlobApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.TvhBlobApiFp)(configuration);
    return {
        /**
         *
         * @param {Array<string>} [requestBody]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findBySapItemCodes: function (requestBody, options) {
            return localVarFp.findBySapItemCodes(requestBody, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.TvhBlobApiFactory = TvhBlobApiFactory;
/**
 * TvhBlobApi - object-oriented interface
 * @export
 * @class TvhBlobApi
 * @extends {BaseAPI}
 */
var TvhBlobApi = /** @class */ (function (_super) {
    __extends(TvhBlobApi, _super);
    function TvhBlobApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {Array<string>} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TvhBlobApi
     */
    TvhBlobApi.prototype.findBySapItemCodes = function (requestBody, options) {
        var _this = this;
        return (0, exports.TvhBlobApiFp)(this.configuration).findBySapItemCodes(requestBody, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return TvhBlobApi;
}(base_1.BaseAPI));
exports.TvhBlobApi = TvhBlobApi;
/**
 * TvhProductApi - axios parameter creator
 * @export
 */
var TvhProductApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {CDAMTMTvhProductSearchParameters} [cDAMTMTvhProductSearchParameters]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByReference: function (cDAMTMTvhProductSearchParameters, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Tvh/TvhProduct/FindByReference";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMTMTvhProductSearchParameters, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.TvhProductApiAxiosParamCreator = TvhProductApiAxiosParamCreator;
/**
 * TvhProductApi - functional programming interface
 * @export
 */
var TvhProductApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.TvhProductApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {CDAMTMTvhProductSearchParameters} [cDAMTMTvhProductSearchParameters]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByReference: function (cDAMTMTvhProductSearchParameters, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findByReference(cDAMTMTvhProductSearchParameters, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.TvhProductApiFp = TvhProductApiFp;
/**
 * TvhProductApi - factory interface
 * @export
 */
var TvhProductApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.TvhProductApiFp)(configuration);
    return {
        /**
         *
         * @param {CDAMTMTvhProductSearchParameters} [cDAMTMTvhProductSearchParameters]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByReference: function (cDAMTMTvhProductSearchParameters, options) {
            return localVarFp.findByReference(cDAMTMTvhProductSearchParameters, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.TvhProductApiFactory = TvhProductApiFactory;
/**
 * TvhProductApi - object-oriented interface
 * @export
 * @class TvhProductApi
 * @extends {BaseAPI}
 */
var TvhProductApi = /** @class */ (function (_super) {
    __extends(TvhProductApi, _super);
    function TvhProductApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {CDAMTMTvhProductSearchParameters} [cDAMTMTvhProductSearchParameters]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TvhProductApi
     */
    TvhProductApi.prototype.findByReference = function (cDAMTMTvhProductSearchParameters, options) {
        var _this = this;
        return (0, exports.TvhProductApiFp)(this.configuration).findByReference(cDAMTMTvhProductSearchParameters, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return TvhProductApi;
}(base_1.BaseAPI));
exports.TvhProductApi = TvhProductApi;
/**
 * UserProfileApi - axios parameter creator
 * @export
 */
var UserProfileApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {string} [userId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        explicitRegisterAzureUserToApplication: function (userId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/UserProfile/ExplicitRegisterAzureUserToApplication";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (userId !== undefined) {
                                localVarQueryParameter['userId'] = userId;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllUsers: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/UserProfile/FindAllUsers";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [userId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAppRoleAssignmentsForUser: function (userId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/UserProfile/FindAppRoleAssignmentsForUser";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (userId !== undefined) {
                                localVarQueryParameter['userId'] = userId;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUserById: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/UserProfile/FindUserById";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (id !== undefined) {
                                localVarQueryParameter['id'] = id;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLargeUserPhotoBase64: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/UserProfile/GetLargeUserPhotoBase64";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (id !== undefined) {
                                localVarQueryParameter['id'] = id;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [id]
         * @param {CDGMUserPhotoSize} [size]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLargeUserPhotoRaw: function (id, size, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/UserProfile/GetLargeUserPhotoRaw";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (id !== undefined) {
                                localVarQueryParameter['id'] = id;
                            }
                            if (size !== undefined) {
                                localVarQueryParameter['size'] = size;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSmallCurrentUserPhotoBase64: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/UserProfile/GetSmallCurrentUserPhotoBase64";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [uid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSmallUserPhotoBase64: function (uid, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/UserProfile/GetSmallUserPhotoBase64";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (uid !== undefined) {
                                localVarQueryParameter['uid'] = uid;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUserToApplication: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/UserProfile/RegisterUserToApplication";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userClaims: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/UserProfile/UserClaims";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDetails: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/UserProfile/UserDetails";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.UserProfileApiAxiosParamCreator = UserProfileApiAxiosParamCreator;
/**
 * UserProfileApi - functional programming interface
 * @export
 */
var UserProfileApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.UserProfileApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {string} [userId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        explicitRegisterAzureUserToApplication: function (userId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.explicitRegisterAzureUserToApplication(userId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllUsers: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findAllUsers(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [userId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAppRoleAssignmentsForUser: function (userId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findAppRoleAssignmentsForUser(userId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUserById: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findUserById(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLargeUserPhotoBase64: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getLargeUserPhotoBase64(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [id]
         * @param {CDGMUserPhotoSize} [size]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLargeUserPhotoRaw: function (id, size, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getLargeUserPhotoRaw(id, size, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSmallCurrentUserPhotoBase64: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getSmallCurrentUserPhotoBase64(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [uid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSmallUserPhotoBase64: function (uid, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getSmallUserPhotoBase64(uid, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUserToApplication: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.registerUserToApplication(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userClaims: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userClaims(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDetails: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userDetails(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.UserProfileApiFp = UserProfileApiFp;
/**
 * UserProfileApi - factory interface
 * @export
 */
var UserProfileApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.UserProfileApiFp)(configuration);
    return {
        /**
         *
         * @param {string} [userId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        explicitRegisterAzureUserToApplication: function (userId, options) {
            return localVarFp.explicitRegisterAzureUserToApplication(userId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllUsers: function (options) {
            return localVarFp.findAllUsers(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [userId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAppRoleAssignmentsForUser: function (userId, options) {
            return localVarFp.findAppRoleAssignmentsForUser(userId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUserById: function (id, options) {
            return localVarFp.findUserById(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLargeUserPhotoBase64: function (id, options) {
            return localVarFp.getLargeUserPhotoBase64(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [id]
         * @param {CDGMUserPhotoSize} [size]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLargeUserPhotoRaw: function (id, size, options) {
            return localVarFp.getLargeUserPhotoRaw(id, size, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSmallCurrentUserPhotoBase64: function (options) {
            return localVarFp.getSmallCurrentUserPhotoBase64(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [uid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSmallUserPhotoBase64: function (uid, options) {
            return localVarFp.getSmallUserPhotoBase64(uid, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUserToApplication: function (options) {
            return localVarFp.registerUserToApplication(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userClaims: function (options) {
            return localVarFp.userClaims(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDetails: function (options) {
            return localVarFp.userDetails(options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.UserProfileApiFactory = UserProfileApiFactory;
/**
 * UserProfileApi - object-oriented interface
 * @export
 * @class UserProfileApi
 * @extends {BaseAPI}
 */
var UserProfileApi = /** @class */ (function (_super) {
    __extends(UserProfileApi, _super);
    function UserProfileApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {string} [userId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    UserProfileApi.prototype.explicitRegisterAzureUserToApplication = function (userId, options) {
        var _this = this;
        return (0, exports.UserProfileApiFp)(this.configuration).explicitRegisterAzureUserToApplication(userId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    UserProfileApi.prototype.findAllUsers = function (options) {
        var _this = this;
        return (0, exports.UserProfileApiFp)(this.configuration).findAllUsers(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [userId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    UserProfileApi.prototype.findAppRoleAssignmentsForUser = function (userId, options) {
        var _this = this;
        return (0, exports.UserProfileApiFp)(this.configuration).findAppRoleAssignmentsForUser(userId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [id]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    UserProfileApi.prototype.findUserById = function (id, options) {
        var _this = this;
        return (0, exports.UserProfileApiFp)(this.configuration).findUserById(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [id]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    UserProfileApi.prototype.getLargeUserPhotoBase64 = function (id, options) {
        var _this = this;
        return (0, exports.UserProfileApiFp)(this.configuration).getLargeUserPhotoBase64(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [id]
     * @param {CDGMUserPhotoSize} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    UserProfileApi.prototype.getLargeUserPhotoRaw = function (id, size, options) {
        var _this = this;
        return (0, exports.UserProfileApiFp)(this.configuration).getLargeUserPhotoRaw(id, size, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    UserProfileApi.prototype.getSmallCurrentUserPhotoBase64 = function (options) {
        var _this = this;
        return (0, exports.UserProfileApiFp)(this.configuration).getSmallCurrentUserPhotoBase64(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [uid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    UserProfileApi.prototype.getSmallUserPhotoBase64 = function (uid, options) {
        var _this = this;
        return (0, exports.UserProfileApiFp)(this.configuration).getSmallUserPhotoBase64(uid, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    UserProfileApi.prototype.registerUserToApplication = function (options) {
        var _this = this;
        return (0, exports.UserProfileApiFp)(this.configuration).registerUserToApplication(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    UserProfileApi.prototype.userClaims = function (options) {
        var _this = this;
        return (0, exports.UserProfileApiFp)(this.configuration).userClaims(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    UserProfileApi.prototype.userDetails = function (options) {
        var _this = this;
        return (0, exports.UserProfileApiFp)(this.configuration).userDetails(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return UserProfileApi;
}(base_1.BaseAPI));
exports.UserProfileApi = UserProfileApi;
/**
 * VehicleManufacturerApi - axios parameter creator
 * @export
 */
var VehicleManufacturerApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        findAll: function (limit, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Common/VehicleManufacturer/FindAll";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMCMVehicleManufacturerModel} [cDAMCMVehicleManufacturerModel]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        save: function (cDAMCMVehicleManufacturerModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Common/VehicleManufacturer/Save";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMCMVehicleManufacturerModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.VehicleManufacturerApiAxiosParamCreator = VehicleManufacturerApiAxiosParamCreator;
/**
 * VehicleManufacturerApi - functional programming interface
 * @export
 */
var VehicleManufacturerApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.VehicleManufacturerApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        findAll: function (limit, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findAll(limit, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMCMVehicleManufacturerModel} [cDAMCMVehicleManufacturerModel]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        save: function (cDAMCMVehicleManufacturerModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.save(cDAMCMVehicleManufacturerModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.VehicleManufacturerApiFp = VehicleManufacturerApiFp;
/**
 * VehicleManufacturerApi - factory interface
 * @export
 */
var VehicleManufacturerApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.VehicleManufacturerApiFp)(configuration);
    return {
        /**
         *
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        findAll: function (limit, options) {
            return localVarFp.findAll(limit, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMCMVehicleManufacturerModel} [cDAMCMVehicleManufacturerModel]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        save: function (cDAMCMVehicleManufacturerModel, options) {
            return localVarFp.save(cDAMCMVehicleManufacturerModel, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.VehicleManufacturerApiFactory = VehicleManufacturerApiFactory;
/**
 * VehicleManufacturerApi - object-oriented interface
 * @export
 * @class VehicleManufacturerApi
 * @extends {BaseAPI}
 */
var VehicleManufacturerApi = /** @class */ (function (_super) {
    __extends(VehicleManufacturerApi, _super);
    function VehicleManufacturerApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof VehicleManufacturerApi
     */
    VehicleManufacturerApi.prototype.findAll = function (limit, options) {
        var _this = this;
        return (0, exports.VehicleManufacturerApiFp)(this.configuration).findAll(limit, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMCMVehicleManufacturerModel} [cDAMCMVehicleManufacturerModel]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof VehicleManufacturerApi
     */
    VehicleManufacturerApi.prototype.save = function (cDAMCMVehicleManufacturerModel, options) {
        var _this = this;
        return (0, exports.VehicleManufacturerApiFp)(this.configuration).save(cDAMCMVehicleManufacturerModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return VehicleManufacturerApi;
}(base_1.BaseAPI));
exports.VehicleManufacturerApi = VehicleManufacturerApi;
/**
 * VehicleTypeApi - axios parameter creator
 * @export
 */
var VehicleTypeApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {string} [manufacturerId]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        findAllByManufacturerId: function (manufacturerId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Common/VehicleType/FindAllByManufacturerId";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (manufacturerId !== undefined) {
                                localVarQueryParameter['manufacturerId'] = manufacturerId;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [id]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        findById: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Common/VehicleType/FindById";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (id !== undefined) {
                                localVarQueryParameter['id'] = id;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMCMVehicleTypeModel} [cDAMCMVehicleTypeModel]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        save: function (cDAMCMVehicleTypeModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Common/VehicleType/Save";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMCMVehicleTypeModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.VehicleTypeApiAxiosParamCreator = VehicleTypeApiAxiosParamCreator;
/**
 * VehicleTypeApi - functional programming interface
 * @export
 */
var VehicleTypeApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.VehicleTypeApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {string} [manufacturerId]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        findAllByManufacturerId: function (manufacturerId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findAllByManufacturerId(manufacturerId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [id]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        findById: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findById(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMCMVehicleTypeModel} [cDAMCMVehicleTypeModel]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        save: function (cDAMCMVehicleTypeModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.save(cDAMCMVehicleTypeModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.VehicleTypeApiFp = VehicleTypeApiFp;
/**
 * VehicleTypeApi - factory interface
 * @export
 */
var VehicleTypeApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.VehicleTypeApiFp)(configuration);
    return {
        /**
         *
         * @param {string} [manufacturerId]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        findAllByManufacturerId: function (manufacturerId, options) {
            return localVarFp.findAllByManufacturerId(manufacturerId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [id]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        findById: function (id, options) {
            return localVarFp.findById(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMCMVehicleTypeModel} [cDAMCMVehicleTypeModel]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        save: function (cDAMCMVehicleTypeModel, options) {
            return localVarFp.save(cDAMCMVehicleTypeModel, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.VehicleTypeApiFactory = VehicleTypeApiFactory;
/**
 * VehicleTypeApi - object-oriented interface
 * @export
 * @class VehicleTypeApi
 * @extends {BaseAPI}
 */
var VehicleTypeApi = /** @class */ (function (_super) {
    __extends(VehicleTypeApi, _super);
    function VehicleTypeApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {string} [manufacturerId]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof VehicleTypeApi
     */
    VehicleTypeApi.prototype.findAllByManufacturerId = function (manufacturerId, options) {
        var _this = this;
        return (0, exports.VehicleTypeApiFp)(this.configuration).findAllByManufacturerId(manufacturerId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [id]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof VehicleTypeApi
     */
    VehicleTypeApi.prototype.findById = function (id, options) {
        var _this = this;
        return (0, exports.VehicleTypeApiFp)(this.configuration).findById(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMCMVehicleTypeModel} [cDAMCMVehicleTypeModel]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof VehicleTypeApi
     */
    VehicleTypeApi.prototype.save = function (cDAMCMVehicleTypeModel, options) {
        var _this = this;
        return (0, exports.VehicleTypeApiFp)(this.configuration).save(cDAMCMVehicleTypeModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return VehicleTypeApi;
}(base_1.BaseAPI));
exports.VehicleTypeApi = VehicleTypeApi;
/**
 * WarmupQueryApi - axios parameter creator
 * @export
 */
var WarmupQueryApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryWarmup: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Common/WarmupQuery/QueryWarmup";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.WarmupQueryApiAxiosParamCreator = WarmupQueryApiAxiosParamCreator;
/**
 * WarmupQueryApi - functional programming interface
 * @export
 */
var WarmupQueryApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.WarmupQueryApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryWarmup: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.queryWarmup(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.WarmupQueryApiFp = WarmupQueryApiFp;
/**
 * WarmupQueryApi - factory interface
 * @export
 */
var WarmupQueryApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.WarmupQueryApiFp)(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryWarmup: function (options) {
            return localVarFp.queryWarmup(options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.WarmupQueryApiFactory = WarmupQueryApiFactory;
/**
 * WarmupQueryApi - object-oriented interface
 * @export
 * @class WarmupQueryApi
 * @extends {BaseAPI}
 */
var WarmupQueryApi = /** @class */ (function (_super) {
    __extends(WarmupQueryApi, _super);
    function WarmupQueryApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarmupQueryApi
     */
    WarmupQueryApi.prototype.queryWarmup = function (options) {
        var _this = this;
        return (0, exports.WarmupQueryApiFp)(this.configuration).queryWarmup(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return WarmupQueryApi;
}(base_1.BaseAPI));
exports.WarmupQueryApi = WarmupQueryApi;
/**
 * WorksheetApi - axios parameter creator
 * @export
 */
var WorksheetApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {string} [worksheetId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: function (worksheetId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/Worksheet/Delete";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (worksheetId !== undefined) {
                                localVarQueryParameter['worksheetId'] = worksheetId;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<string>} [requestBody]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findStatusAndSignatureByIds: function (requestBody, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/Worksheet/FindStatusAndSignatureByIds";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(requestBody, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<string>} [requestBody]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findVersionsFor: function (requestBody, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/Worksheet/FindVersionsFor";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(requestBody, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [id]
         * @param {boolean} [includeDeleted]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findWorksheetQueryById: function (id, includeDeleted, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/Worksheet/FindWorksheetQueryById";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (id !== undefined) {
                                localVarQueryParameter['id'] = id;
                            }
                            if (includeDeleted !== undefined) {
                                localVarQueryParameter['includeDeleted'] = includeDeleted;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [id]
         * @param {boolean} [includeDeleted]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findWorksheetQueryByIdAsSave: function (id, includeDeleted, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/Worksheet/FindWorksheetQueryByIdAsSave";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (id !== undefined) {
                                localVarQueryParameter['id'] = id;
                            }
                            if (includeDeleted !== undefined) {
                                localVarQueryParameter['includeDeleted'] = includeDeleted;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {boolean} [ignorePreviousVersion]
         * @param {CDAMWMWWorksheetSave} [cDAMWMWWorksheetSave]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merge: function (ignorePreviousVersion, cDAMWMWWorksheetSave, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/Worksheet/Merge";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (ignorePreviousVersion !== undefined) {
                                localVarQueryParameter['ignorePreviousVersion'] = ignorePreviousVersion;
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMWWorksheetSave, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMWWorksheetSearchQueryParams} [cDAMWMWWorksheetSearchQueryParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPageBy: function (cDAMWMWWorksheetSearchQueryParams, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/Worksheet/SearchPageBy";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMWWorksheetSearchQueryParams, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [worksheetId]
         * @param {boolean} [isDeleted]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDelete: function (worksheetId, isDeleted, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/Worksheet/SetDelete";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (worksheetId !== undefined) {
                                localVarQueryParameter['worksheetId'] = worksheetId;
                            }
                            if (isDeleted !== undefined) {
                                localVarQueryParameter['isDeleted'] = isDeleted;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [worksheetId]
         * @param {string} [value]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmailNotificationAddresses: function (worksheetId, value, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/Worksheet/UpdateEmailNotificationAddresses";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (worksheetId !== undefined) {
                                localVarQueryParameter['worksheetId'] = worksheetId;
                            }
                            if (value !== undefined) {
                                localVarQueryParameter['value'] = value;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [worksheetId]
         * @param {boolean} [value]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOverrideEmailNotificationAddresses: function (worksheetId, value, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/Worksheet/UpdateOverrideEmailNotificationAddresses";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (worksheetId !== undefined) {
                                localVarQueryParameter['worksheetId'] = worksheetId;
                            }
                            if (value !== undefined) {
                                localVarQueryParameter['value'] = value;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<CDAMWMWWorksheetSignatureUpdate>} [cDAMWMWWorksheetSignatureUpdate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSignatureAndStatusFor: function (cDAMWMWWorksheetSignatureUpdate, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/Worksheet/UpdateSignatureAndStatusFor";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMWWorksheetSignatureUpdate, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [worksheetId]
         * @param {boolean} [skipEmail]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSkipCustomerEmail: function (worksheetId, skipEmail, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/Worksheet/UpdateSkipCustomerEmail";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (worksheetId !== undefined) {
                                localVarQueryParameter['worksheetId'] = worksheetId;
                            }
                            if (skipEmail !== undefined) {
                                localVarQueryParameter['skipEmail'] = skipEmail;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<CDAMWMWWorksheetStatusUpdate>} [cDAMWMWWorksheetStatusUpdate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStatusFor: function (cDAMWMWWorksheetStatusUpdate, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/Worksheet/UpdateStatusFor";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMWWorksheetStatusUpdate, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.WorksheetApiAxiosParamCreator = WorksheetApiAxiosParamCreator;
/**
 * WorksheetApi - functional programming interface
 * @export
 */
var WorksheetApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.WorksheetApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {string} [worksheetId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: function (worksheetId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator._delete(worksheetId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<string>} [requestBody]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findStatusAndSignatureByIds: function (requestBody, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findStatusAndSignatureByIds(requestBody, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<string>} [requestBody]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findVersionsFor: function (requestBody, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findVersionsFor(requestBody, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [id]
         * @param {boolean} [includeDeleted]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findWorksheetQueryById: function (id, includeDeleted, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findWorksheetQueryById(id, includeDeleted, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [id]
         * @param {boolean} [includeDeleted]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findWorksheetQueryByIdAsSave: function (id, includeDeleted, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findWorksheetQueryByIdAsSave(id, includeDeleted, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {boolean} [ignorePreviousVersion]
         * @param {CDAMWMWWorksheetSave} [cDAMWMWWorksheetSave]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merge: function (ignorePreviousVersion, cDAMWMWWorksheetSave, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.merge(ignorePreviousVersion, cDAMWMWWorksheetSave, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMWWorksheetSearchQueryParams} [cDAMWMWWorksheetSearchQueryParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPageBy: function (cDAMWMWWorksheetSearchQueryParams, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.searchPageBy(cDAMWMWWorksheetSearchQueryParams, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [worksheetId]
         * @param {boolean} [isDeleted]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDelete: function (worksheetId, isDeleted, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.setDelete(worksheetId, isDeleted, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [worksheetId]
         * @param {string} [value]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmailNotificationAddresses: function (worksheetId, value, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateEmailNotificationAddresses(worksheetId, value, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [worksheetId]
         * @param {boolean} [value]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOverrideEmailNotificationAddresses: function (worksheetId, value, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateOverrideEmailNotificationAddresses(worksheetId, value, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<CDAMWMWWorksheetSignatureUpdate>} [cDAMWMWWorksheetSignatureUpdate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSignatureAndStatusFor: function (cDAMWMWWorksheetSignatureUpdate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateSignatureAndStatusFor(cDAMWMWWorksheetSignatureUpdate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [worksheetId]
         * @param {boolean} [skipEmail]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSkipCustomerEmail: function (worksheetId, skipEmail, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateSkipCustomerEmail(worksheetId, skipEmail, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<CDAMWMWWorksheetStatusUpdate>} [cDAMWMWWorksheetStatusUpdate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStatusFor: function (cDAMWMWWorksheetStatusUpdate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateStatusFor(cDAMWMWWorksheetStatusUpdate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.WorksheetApiFp = WorksheetApiFp;
/**
 * WorksheetApi - factory interface
 * @export
 */
var WorksheetApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.WorksheetApiFp)(configuration);
    return {
        /**
         *
         * @param {string} [worksheetId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: function (worksheetId, options) {
            return localVarFp._delete(worksheetId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {Array<string>} [requestBody]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findStatusAndSignatureByIds: function (requestBody, options) {
            return localVarFp.findStatusAndSignatureByIds(requestBody, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {Array<string>} [requestBody]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findVersionsFor: function (requestBody, options) {
            return localVarFp.findVersionsFor(requestBody, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [id]
         * @param {boolean} [includeDeleted]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findWorksheetQueryById: function (id, includeDeleted, options) {
            return localVarFp.findWorksheetQueryById(id, includeDeleted, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [id]
         * @param {boolean} [includeDeleted]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findWorksheetQueryByIdAsSave: function (id, includeDeleted, options) {
            return localVarFp.findWorksheetQueryByIdAsSave(id, includeDeleted, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {boolean} [ignorePreviousVersion]
         * @param {CDAMWMWWorksheetSave} [cDAMWMWWorksheetSave]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merge: function (ignorePreviousVersion, cDAMWMWWorksheetSave, options) {
            return localVarFp.merge(ignorePreviousVersion, cDAMWMWWorksheetSave, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMWMWWorksheetSearchQueryParams} [cDAMWMWWorksheetSearchQueryParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPageBy: function (cDAMWMWWorksheetSearchQueryParams, options) {
            return localVarFp.searchPageBy(cDAMWMWWorksheetSearchQueryParams, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [worksheetId]
         * @param {boolean} [isDeleted]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDelete: function (worksheetId, isDeleted, options) {
            return localVarFp.setDelete(worksheetId, isDeleted, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [worksheetId]
         * @param {string} [value]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmailNotificationAddresses: function (worksheetId, value, options) {
            return localVarFp.updateEmailNotificationAddresses(worksheetId, value, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [worksheetId]
         * @param {boolean} [value]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOverrideEmailNotificationAddresses: function (worksheetId, value, options) {
            return localVarFp.updateOverrideEmailNotificationAddresses(worksheetId, value, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {Array<CDAMWMWWorksheetSignatureUpdate>} [cDAMWMWWorksheetSignatureUpdate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSignatureAndStatusFor: function (cDAMWMWWorksheetSignatureUpdate, options) {
            return localVarFp.updateSignatureAndStatusFor(cDAMWMWWorksheetSignatureUpdate, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [worksheetId]
         * @param {boolean} [skipEmail]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSkipCustomerEmail: function (worksheetId, skipEmail, options) {
            return localVarFp.updateSkipCustomerEmail(worksheetId, skipEmail, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {Array<CDAMWMWWorksheetStatusUpdate>} [cDAMWMWWorksheetStatusUpdate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStatusFor: function (cDAMWMWWorksheetStatusUpdate, options) {
            return localVarFp.updateStatusFor(cDAMWMWWorksheetStatusUpdate, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.WorksheetApiFactory = WorksheetApiFactory;
/**
 * WorksheetApi - object-oriented interface
 * @export
 * @class WorksheetApi
 * @extends {BaseAPI}
 */
var WorksheetApi = /** @class */ (function (_super) {
    __extends(WorksheetApi, _super);
    function WorksheetApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {string} [worksheetId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetApi
     */
    WorksheetApi.prototype._delete = function (worksheetId, options) {
        var _this = this;
        return (0, exports.WorksheetApiFp)(this.configuration)._delete(worksheetId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {Array<string>} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetApi
     */
    WorksheetApi.prototype.findStatusAndSignatureByIds = function (requestBody, options) {
        var _this = this;
        return (0, exports.WorksheetApiFp)(this.configuration).findStatusAndSignatureByIds(requestBody, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {Array<string>} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetApi
     */
    WorksheetApi.prototype.findVersionsFor = function (requestBody, options) {
        var _this = this;
        return (0, exports.WorksheetApiFp)(this.configuration).findVersionsFor(requestBody, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [id]
     * @param {boolean} [includeDeleted]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetApi
     */
    WorksheetApi.prototype.findWorksheetQueryById = function (id, includeDeleted, options) {
        var _this = this;
        return (0, exports.WorksheetApiFp)(this.configuration).findWorksheetQueryById(id, includeDeleted, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [id]
     * @param {boolean} [includeDeleted]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetApi
     */
    WorksheetApi.prototype.findWorksheetQueryByIdAsSave = function (id, includeDeleted, options) {
        var _this = this;
        return (0, exports.WorksheetApiFp)(this.configuration).findWorksheetQueryByIdAsSave(id, includeDeleted, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {boolean} [ignorePreviousVersion]
     * @param {CDAMWMWWorksheetSave} [cDAMWMWWorksheetSave]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetApi
     */
    WorksheetApi.prototype.merge = function (ignorePreviousVersion, cDAMWMWWorksheetSave, options) {
        var _this = this;
        return (0, exports.WorksheetApiFp)(this.configuration).merge(ignorePreviousVersion, cDAMWMWWorksheetSave, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMWMWWorksheetSearchQueryParams} [cDAMWMWWorksheetSearchQueryParams]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetApi
     */
    WorksheetApi.prototype.searchPageBy = function (cDAMWMWWorksheetSearchQueryParams, options) {
        var _this = this;
        return (0, exports.WorksheetApiFp)(this.configuration).searchPageBy(cDAMWMWWorksheetSearchQueryParams, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [worksheetId]
     * @param {boolean} [isDeleted]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetApi
     */
    WorksheetApi.prototype.setDelete = function (worksheetId, isDeleted, options) {
        var _this = this;
        return (0, exports.WorksheetApiFp)(this.configuration).setDelete(worksheetId, isDeleted, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [worksheetId]
     * @param {string} [value]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetApi
     */
    WorksheetApi.prototype.updateEmailNotificationAddresses = function (worksheetId, value, options) {
        var _this = this;
        return (0, exports.WorksheetApiFp)(this.configuration).updateEmailNotificationAddresses(worksheetId, value, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [worksheetId]
     * @param {boolean} [value]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetApi
     */
    WorksheetApi.prototype.updateOverrideEmailNotificationAddresses = function (worksheetId, value, options) {
        var _this = this;
        return (0, exports.WorksheetApiFp)(this.configuration).updateOverrideEmailNotificationAddresses(worksheetId, value, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {Array<CDAMWMWWorksheetSignatureUpdate>} [cDAMWMWWorksheetSignatureUpdate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetApi
     */
    WorksheetApi.prototype.updateSignatureAndStatusFor = function (cDAMWMWWorksheetSignatureUpdate, options) {
        var _this = this;
        return (0, exports.WorksheetApiFp)(this.configuration).updateSignatureAndStatusFor(cDAMWMWWorksheetSignatureUpdate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [worksheetId]
     * @param {boolean} [skipEmail]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetApi
     */
    WorksheetApi.prototype.updateSkipCustomerEmail = function (worksheetId, skipEmail, options) {
        var _this = this;
        return (0, exports.WorksheetApiFp)(this.configuration).updateSkipCustomerEmail(worksheetId, skipEmail, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {Array<CDAMWMWWorksheetStatusUpdate>} [cDAMWMWWorksheetStatusUpdate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetApi
     */
    WorksheetApi.prototype.updateStatusFor = function (cDAMWMWWorksheetStatusUpdate, options) {
        var _this = this;
        return (0, exports.WorksheetApiFp)(this.configuration).updateStatusFor(cDAMWMWWorksheetStatusUpdate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return WorksheetApi;
}(base_1.BaseAPI));
exports.WorksheetApi = WorksheetApi;
/**
 * WorksheetCallTypeApi - axios parameter creator
 * @export
 */
var WorksheetCallTypeApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {boolean} [isDeleted]
         * @param {CDAMWMWorksheetCallTypeModel} [cDAMWMWorksheetCallTypeModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: function (isDeleted, cDAMWMWorksheetCallTypeModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/WorksheetCallType/Delete";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (isDeleted !== undefined) {
                                localVarQueryParameter['isDeleted'] = isDeleted;
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMWorksheetCallTypeModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePermenentlyBy: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/WorksheetCallType/DeletePermenentlyBy";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (id !== undefined) {
                                localVarQueryParameter['id'] = id;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {any} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdObject: function (body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/WorksheetCallType/FindByIdObject";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(body, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMWorksheetCallTypeModel} [cDAMWMWorksheetCallTypeModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insert: function (cDAMWMWorksheetCallTypeModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/WorksheetCallType/Insert";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMWorksheetCallTypeModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<CDAMWMWorksheetCallTypeModel>} [cDAMWMWorksheetCallTypeModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merge: function (cDAMWMWorksheetCallTypeModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/WorksheetCallType/Merge";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMWorksheetCallTypeModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDCMGenericPagingParams} [cDCMGenericPagingParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageAll: function (cDCMGenericPagingParams, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/WorksheetCallType/PageAll";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDCMGenericPagingParams, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMWorksheetCallTypeModel} [cDAMWMWorksheetCallTypeModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: function (cDAMWMWorksheetCallTypeModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/WorksheetCallType/Save";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMWorksheetCallTypeModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMWorksheetCallTypeModel} [cDAMWMWorksheetCallTypeModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: function (cDAMWMWorksheetCallTypeModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/WorksheetCallType/Update";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMWorksheetCallTypeModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.WorksheetCallTypeApiAxiosParamCreator = WorksheetCallTypeApiAxiosParamCreator;
/**
 * WorksheetCallTypeApi - functional programming interface
 * @export
 */
var WorksheetCallTypeApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.WorksheetCallTypeApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {boolean} [isDeleted]
         * @param {CDAMWMWorksheetCallTypeModel} [cDAMWMWorksheetCallTypeModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: function (isDeleted, cDAMWMWorksheetCallTypeModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator._delete(isDeleted, cDAMWMWorksheetCallTypeModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {number} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePermenentlyBy: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deletePermenentlyBy(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {any} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdObject: function (body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findByIdObject(body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMWorksheetCallTypeModel} [cDAMWMWorksheetCallTypeModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insert: function (cDAMWMWorksheetCallTypeModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.insert(cDAMWMWorksheetCallTypeModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<CDAMWMWorksheetCallTypeModel>} [cDAMWMWorksheetCallTypeModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merge: function (cDAMWMWorksheetCallTypeModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.merge(cDAMWMWorksheetCallTypeModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDCMGenericPagingParams} [cDCMGenericPagingParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageAll: function (cDCMGenericPagingParams, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pageAll(cDCMGenericPagingParams, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMWorksheetCallTypeModel} [cDAMWMWorksheetCallTypeModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: function (cDAMWMWorksheetCallTypeModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.save(cDAMWMWorksheetCallTypeModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMWorksheetCallTypeModel} [cDAMWMWorksheetCallTypeModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: function (cDAMWMWorksheetCallTypeModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.update(cDAMWMWorksheetCallTypeModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.WorksheetCallTypeApiFp = WorksheetCallTypeApiFp;
/**
 * WorksheetCallTypeApi - factory interface
 * @export
 */
var WorksheetCallTypeApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.WorksheetCallTypeApiFp)(configuration);
    return {
        /**
         *
         * @param {boolean} [isDeleted]
         * @param {CDAMWMWorksheetCallTypeModel} [cDAMWMWorksheetCallTypeModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: function (isDeleted, cDAMWMWorksheetCallTypeModel, options) {
            return localVarFp._delete(isDeleted, cDAMWMWorksheetCallTypeModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePermenentlyBy: function (id, options) {
            return localVarFp.deletePermenentlyBy(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {any} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdObject: function (body, options) {
            return localVarFp.findByIdObject(body, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMWMWorksheetCallTypeModel} [cDAMWMWorksheetCallTypeModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insert: function (cDAMWMWorksheetCallTypeModel, options) {
            return localVarFp.insert(cDAMWMWorksheetCallTypeModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {Array<CDAMWMWorksheetCallTypeModel>} [cDAMWMWorksheetCallTypeModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merge: function (cDAMWMWorksheetCallTypeModel, options) {
            return localVarFp.merge(cDAMWMWorksheetCallTypeModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDCMGenericPagingParams} [cDCMGenericPagingParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageAll: function (cDCMGenericPagingParams, options) {
            return localVarFp.pageAll(cDCMGenericPagingParams, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMWMWorksheetCallTypeModel} [cDAMWMWorksheetCallTypeModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: function (cDAMWMWorksheetCallTypeModel, options) {
            return localVarFp.save(cDAMWMWorksheetCallTypeModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMWMWorksheetCallTypeModel} [cDAMWMWorksheetCallTypeModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: function (cDAMWMWorksheetCallTypeModel, options) {
            return localVarFp.update(cDAMWMWorksheetCallTypeModel, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.WorksheetCallTypeApiFactory = WorksheetCallTypeApiFactory;
/**
 * WorksheetCallTypeApi - object-oriented interface
 * @export
 * @class WorksheetCallTypeApi
 * @extends {BaseAPI}
 */
var WorksheetCallTypeApi = /** @class */ (function (_super) {
    __extends(WorksheetCallTypeApi, _super);
    function WorksheetCallTypeApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {boolean} [isDeleted]
     * @param {CDAMWMWorksheetCallTypeModel} [cDAMWMWorksheetCallTypeModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetCallTypeApi
     */
    WorksheetCallTypeApi.prototype._delete = function (isDeleted, cDAMWMWorksheetCallTypeModel, options) {
        var _this = this;
        return (0, exports.WorksheetCallTypeApiFp)(this.configuration)._delete(isDeleted, cDAMWMWorksheetCallTypeModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {number} [id]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetCallTypeApi
     */
    WorksheetCallTypeApi.prototype.deletePermenentlyBy = function (id, options) {
        var _this = this;
        return (0, exports.WorksheetCallTypeApiFp)(this.configuration).deletePermenentlyBy(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {any} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetCallTypeApi
     */
    WorksheetCallTypeApi.prototype.findByIdObject = function (body, options) {
        var _this = this;
        return (0, exports.WorksheetCallTypeApiFp)(this.configuration).findByIdObject(body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMWMWorksheetCallTypeModel} [cDAMWMWorksheetCallTypeModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetCallTypeApi
     */
    WorksheetCallTypeApi.prototype.insert = function (cDAMWMWorksheetCallTypeModel, options) {
        var _this = this;
        return (0, exports.WorksheetCallTypeApiFp)(this.configuration).insert(cDAMWMWorksheetCallTypeModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {Array<CDAMWMWorksheetCallTypeModel>} [cDAMWMWorksheetCallTypeModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetCallTypeApi
     */
    WorksheetCallTypeApi.prototype.merge = function (cDAMWMWorksheetCallTypeModel, options) {
        var _this = this;
        return (0, exports.WorksheetCallTypeApiFp)(this.configuration).merge(cDAMWMWorksheetCallTypeModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDCMGenericPagingParams} [cDCMGenericPagingParams]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetCallTypeApi
     */
    WorksheetCallTypeApi.prototype.pageAll = function (cDCMGenericPagingParams, options) {
        var _this = this;
        return (0, exports.WorksheetCallTypeApiFp)(this.configuration).pageAll(cDCMGenericPagingParams, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMWMWorksheetCallTypeModel} [cDAMWMWorksheetCallTypeModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetCallTypeApi
     */
    WorksheetCallTypeApi.prototype.save = function (cDAMWMWorksheetCallTypeModel, options) {
        var _this = this;
        return (0, exports.WorksheetCallTypeApiFp)(this.configuration).save(cDAMWMWorksheetCallTypeModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMWMWorksheetCallTypeModel} [cDAMWMWorksheetCallTypeModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetCallTypeApi
     */
    WorksheetCallTypeApi.prototype.update = function (cDAMWMWorksheetCallTypeModel, options) {
        var _this = this;
        return (0, exports.WorksheetCallTypeApiFp)(this.configuration).update(cDAMWMWorksheetCallTypeModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return WorksheetCallTypeApi;
}(base_1.BaseAPI));
exports.WorksheetCallTypeApi = WorksheetCallTypeApi;
/**
 * WorksheetEvalApi - axios parameter creator
 * @export
 */
var WorksheetEvalApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {boolean} [isDeleted]
         * @param {CDAMWMWorksheetEvalModel} [cDAMWMWorksheetEvalModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: function (isDeleted, cDAMWMWorksheetEvalModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/WorksheetEval/Delete";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (isDeleted !== undefined) {
                                localVarQueryParameter['isDeleted'] = isDeleted;
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMWorksheetEvalModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CCMWECreateDeliveryForEvalParameters} [cCMWECreateDeliveryForEvalParameters]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDeliveryForEval: function (cCMWECreateDeliveryForEvalParameters, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/WorksheetEval/CreateDeliveryForEval";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cCMWECreateDeliveryForEvalParameters, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [evalId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePermanentlyById: function (evalId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/WorksheetEval/DeletePermanentlyById";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (evalId !== undefined) {
                                localVarQueryParameter['evalId'] = evalId;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {any} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdObject: function (body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/WorksheetEval/FindByIdObject";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(body, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [worksheetId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOrCreateEvalByWorksheetId: function (worksheetId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/WorksheetEval/FindOrCreateEvalByWorksheetId";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (worksheetId !== undefined) {
                                localVarQueryParameter['worksheetId'] = worksheetId;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMWorksheetEvalModel} [cDAMWMWorksheetEvalModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insert: function (cDAMWMWorksheetEvalModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/WorksheetEval/Insert";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMWorksheetEvalModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<CDAMWMWorksheetEvalModel>} [cDAMWMWorksheetEvalModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merge: function (cDAMWMWorksheetEvalModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/WorksheetEval/Merge";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMWorksheetEvalModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDCMGenericPagingParams} [cDCMGenericPagingParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageAll: function (cDCMGenericPagingParams, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/WorksheetEval/PageAll";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDCMGenericPagingParams, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMWorksheetEvalModel} [cDAMWMWorksheetEvalModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: function (cDAMWMWorksheetEvalModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/WorksheetEval/Save";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMWorksheetEvalModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMWorksheetEvalModel} [cDAMWMWorksheetEvalModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: function (cDAMWMWorksheetEvalModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/WorksheetEval/Update";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMWorksheetEvalModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1Boolean} [cDAMWMESingleFieldWorksheetEvalUpdate1Boolean]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAddressAck: function (cDAMWMESingleFieldWorksheetEvalUpdate1Boolean, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/WorksheetEval/UpdateAddressAck";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMESingleFieldWorksheetEvalUpdate1Boolean, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1String} [cDAMWMESingleFieldWorksheetEvalUpdate1String]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminComment: function (cDAMWMESingleFieldWorksheetEvalUpdate1String, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/WorksheetEval/UpdateAdminComment";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMESingleFieldWorksheetEvalUpdate1String, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1Boolean} [cDAMWMESingleFieldWorksheetEvalUpdate1Boolean]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContactAck: function (cDAMWMESingleFieldWorksheetEvalUpdate1Boolean, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/WorksheetEval/UpdateContactAck";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMESingleFieldWorksheetEvalUpdate1Boolean, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1DateTime} [cDAMWMESingleFieldWorksheetEvalUpdate1DateTime]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEndedAt: function (cDAMWMESingleFieldWorksheetEvalUpdate1DateTime, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/WorksheetEval/UpdateEndedAt";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMESingleFieldWorksheetEvalUpdate1DateTime, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMEWorksheetEvalUpdate} [cDAMWMEWorksheetEvalUpdate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEval: function (cDAMWMEWorksheetEvalUpdate, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/WorksheetEval/UpdateEval";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMEWorksheetEvalUpdate, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMEPartnerChangeWorksheetEvalUpdate} [cDAMWMEPartnerChangeWorksheetEvalUpdate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartner: function (cDAMWMEPartnerChangeWorksheetEvalUpdate, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/WorksheetEval/UpdatePartner";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMEPartnerChangeWorksheetEvalUpdate, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1Boolean} [cDAMWMESingleFieldWorksheetEvalUpdate1Boolean]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartnerAck: function (cDAMWMESingleFieldWorksheetEvalUpdate1Boolean, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/WorksheetEval/UpdatePartnerAck";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMESingleFieldWorksheetEvalUpdate1Boolean, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMEPartnerAddressChangeWorksheetEvalUpdate} [cDAMWMEPartnerAddressChangeWorksheetEvalUpdate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartnerAddress: function (cDAMWMEPartnerAddressChangeWorksheetEvalUpdate, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/WorksheetEval/UpdatePartnerAddress";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMEPartnerAddressChangeWorksheetEvalUpdate, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMEPartnerContactChangeWorksheetEvalUpdate} [cDAMWMEPartnerContactChangeWorksheetEvalUpdate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartnerContact: function (cDAMWMEPartnerContactChangeWorksheetEvalUpdate, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/WorksheetEval/UpdatePartnerContact";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMEPartnerContactChangeWorksheetEvalUpdate, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1DateTime} [cDAMWMESingleFieldWorksheetEvalUpdate1DateTime]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStartedAt: function (cDAMWMESingleFieldWorksheetEvalUpdate1DateTime, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/WorksheetEval/UpdateStartedAt";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMESingleFieldWorksheetEvalUpdate1DateTime, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.WorksheetEvalApiAxiosParamCreator = WorksheetEvalApiAxiosParamCreator;
/**
 * WorksheetEvalApi - functional programming interface
 * @export
 */
var WorksheetEvalApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.WorksheetEvalApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {boolean} [isDeleted]
         * @param {CDAMWMWorksheetEvalModel} [cDAMWMWorksheetEvalModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: function (isDeleted, cDAMWMWorksheetEvalModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator._delete(isDeleted, cDAMWMWorksheetEvalModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CCMWECreateDeliveryForEvalParameters} [cCMWECreateDeliveryForEvalParameters]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDeliveryForEval: function (cCMWECreateDeliveryForEvalParameters, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createDeliveryForEval(cCMWECreateDeliveryForEvalParameters, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [evalId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePermanentlyById: function (evalId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deletePermanentlyById(evalId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {any} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdObject: function (body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findByIdObject(body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [worksheetId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOrCreateEvalByWorksheetId: function (worksheetId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findOrCreateEvalByWorksheetId(worksheetId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMWorksheetEvalModel} [cDAMWMWorksheetEvalModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insert: function (cDAMWMWorksheetEvalModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.insert(cDAMWMWorksheetEvalModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<CDAMWMWorksheetEvalModel>} [cDAMWMWorksheetEvalModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merge: function (cDAMWMWorksheetEvalModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.merge(cDAMWMWorksheetEvalModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDCMGenericPagingParams} [cDCMGenericPagingParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageAll: function (cDCMGenericPagingParams, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pageAll(cDCMGenericPagingParams, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMWorksheetEvalModel} [cDAMWMWorksheetEvalModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: function (cDAMWMWorksheetEvalModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.save(cDAMWMWorksheetEvalModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMWorksheetEvalModel} [cDAMWMWorksheetEvalModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: function (cDAMWMWorksheetEvalModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.update(cDAMWMWorksheetEvalModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1Boolean} [cDAMWMESingleFieldWorksheetEvalUpdate1Boolean]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAddressAck: function (cDAMWMESingleFieldWorksheetEvalUpdate1Boolean, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateAddressAck(cDAMWMESingleFieldWorksheetEvalUpdate1Boolean, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1String} [cDAMWMESingleFieldWorksheetEvalUpdate1String]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminComment: function (cDAMWMESingleFieldWorksheetEvalUpdate1String, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateAdminComment(cDAMWMESingleFieldWorksheetEvalUpdate1String, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1Boolean} [cDAMWMESingleFieldWorksheetEvalUpdate1Boolean]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContactAck: function (cDAMWMESingleFieldWorksheetEvalUpdate1Boolean, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateContactAck(cDAMWMESingleFieldWorksheetEvalUpdate1Boolean, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1DateTime} [cDAMWMESingleFieldWorksheetEvalUpdate1DateTime]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEndedAt: function (cDAMWMESingleFieldWorksheetEvalUpdate1DateTime, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateEndedAt(cDAMWMESingleFieldWorksheetEvalUpdate1DateTime, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMEWorksheetEvalUpdate} [cDAMWMEWorksheetEvalUpdate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEval: function (cDAMWMEWorksheetEvalUpdate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateEval(cDAMWMEWorksheetEvalUpdate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMEPartnerChangeWorksheetEvalUpdate} [cDAMWMEPartnerChangeWorksheetEvalUpdate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartner: function (cDAMWMEPartnerChangeWorksheetEvalUpdate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updatePartner(cDAMWMEPartnerChangeWorksheetEvalUpdate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1Boolean} [cDAMWMESingleFieldWorksheetEvalUpdate1Boolean]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartnerAck: function (cDAMWMESingleFieldWorksheetEvalUpdate1Boolean, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updatePartnerAck(cDAMWMESingleFieldWorksheetEvalUpdate1Boolean, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMEPartnerAddressChangeWorksheetEvalUpdate} [cDAMWMEPartnerAddressChangeWorksheetEvalUpdate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartnerAddress: function (cDAMWMEPartnerAddressChangeWorksheetEvalUpdate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updatePartnerAddress(cDAMWMEPartnerAddressChangeWorksheetEvalUpdate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMEPartnerContactChangeWorksheetEvalUpdate} [cDAMWMEPartnerContactChangeWorksheetEvalUpdate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartnerContact: function (cDAMWMEPartnerContactChangeWorksheetEvalUpdate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updatePartnerContact(cDAMWMEPartnerContactChangeWorksheetEvalUpdate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1DateTime} [cDAMWMESingleFieldWorksheetEvalUpdate1DateTime]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStartedAt: function (cDAMWMESingleFieldWorksheetEvalUpdate1DateTime, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateStartedAt(cDAMWMESingleFieldWorksheetEvalUpdate1DateTime, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.WorksheetEvalApiFp = WorksheetEvalApiFp;
/**
 * WorksheetEvalApi - factory interface
 * @export
 */
var WorksheetEvalApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.WorksheetEvalApiFp)(configuration);
    return {
        /**
         *
         * @param {boolean} [isDeleted]
         * @param {CDAMWMWorksheetEvalModel} [cDAMWMWorksheetEvalModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: function (isDeleted, cDAMWMWorksheetEvalModel, options) {
            return localVarFp._delete(isDeleted, cDAMWMWorksheetEvalModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CCMWECreateDeliveryForEvalParameters} [cCMWECreateDeliveryForEvalParameters]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDeliveryForEval: function (cCMWECreateDeliveryForEvalParameters, options) {
            return localVarFp.createDeliveryForEval(cCMWECreateDeliveryForEvalParameters, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [evalId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePermanentlyById: function (evalId, options) {
            return localVarFp.deletePermanentlyById(evalId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {any} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdObject: function (body, options) {
            return localVarFp.findByIdObject(body, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [worksheetId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOrCreateEvalByWorksheetId: function (worksheetId, options) {
            return localVarFp.findOrCreateEvalByWorksheetId(worksheetId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMWMWorksheetEvalModel} [cDAMWMWorksheetEvalModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insert: function (cDAMWMWorksheetEvalModel, options) {
            return localVarFp.insert(cDAMWMWorksheetEvalModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {Array<CDAMWMWorksheetEvalModel>} [cDAMWMWorksheetEvalModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merge: function (cDAMWMWorksheetEvalModel, options) {
            return localVarFp.merge(cDAMWMWorksheetEvalModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDCMGenericPagingParams} [cDCMGenericPagingParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageAll: function (cDCMGenericPagingParams, options) {
            return localVarFp.pageAll(cDCMGenericPagingParams, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMWMWorksheetEvalModel} [cDAMWMWorksheetEvalModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: function (cDAMWMWorksheetEvalModel, options) {
            return localVarFp.save(cDAMWMWorksheetEvalModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMWMWorksheetEvalModel} [cDAMWMWorksheetEvalModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: function (cDAMWMWorksheetEvalModel, options) {
            return localVarFp.update(cDAMWMWorksheetEvalModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1Boolean} [cDAMWMESingleFieldWorksheetEvalUpdate1Boolean]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAddressAck: function (cDAMWMESingleFieldWorksheetEvalUpdate1Boolean, options) {
            return localVarFp.updateAddressAck(cDAMWMESingleFieldWorksheetEvalUpdate1Boolean, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1String} [cDAMWMESingleFieldWorksheetEvalUpdate1String]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminComment: function (cDAMWMESingleFieldWorksheetEvalUpdate1String, options) {
            return localVarFp.updateAdminComment(cDAMWMESingleFieldWorksheetEvalUpdate1String, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1Boolean} [cDAMWMESingleFieldWorksheetEvalUpdate1Boolean]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContactAck: function (cDAMWMESingleFieldWorksheetEvalUpdate1Boolean, options) {
            return localVarFp.updateContactAck(cDAMWMESingleFieldWorksheetEvalUpdate1Boolean, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1DateTime} [cDAMWMESingleFieldWorksheetEvalUpdate1DateTime]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEndedAt: function (cDAMWMESingleFieldWorksheetEvalUpdate1DateTime, options) {
            return localVarFp.updateEndedAt(cDAMWMESingleFieldWorksheetEvalUpdate1DateTime, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMWMEWorksheetEvalUpdate} [cDAMWMEWorksheetEvalUpdate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEval: function (cDAMWMEWorksheetEvalUpdate, options) {
            return localVarFp.updateEval(cDAMWMEWorksheetEvalUpdate, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMWMEPartnerChangeWorksheetEvalUpdate} [cDAMWMEPartnerChangeWorksheetEvalUpdate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartner: function (cDAMWMEPartnerChangeWorksheetEvalUpdate, options) {
            return localVarFp.updatePartner(cDAMWMEPartnerChangeWorksheetEvalUpdate, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1Boolean} [cDAMWMESingleFieldWorksheetEvalUpdate1Boolean]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartnerAck: function (cDAMWMESingleFieldWorksheetEvalUpdate1Boolean, options) {
            return localVarFp.updatePartnerAck(cDAMWMESingleFieldWorksheetEvalUpdate1Boolean, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMWMEPartnerAddressChangeWorksheetEvalUpdate} [cDAMWMEPartnerAddressChangeWorksheetEvalUpdate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartnerAddress: function (cDAMWMEPartnerAddressChangeWorksheetEvalUpdate, options) {
            return localVarFp.updatePartnerAddress(cDAMWMEPartnerAddressChangeWorksheetEvalUpdate, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMWMEPartnerContactChangeWorksheetEvalUpdate} [cDAMWMEPartnerContactChangeWorksheetEvalUpdate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartnerContact: function (cDAMWMEPartnerContactChangeWorksheetEvalUpdate, options) {
            return localVarFp.updatePartnerContact(cDAMWMEPartnerContactChangeWorksheetEvalUpdate, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMWMESingleFieldWorksheetEvalUpdate1DateTime} [cDAMWMESingleFieldWorksheetEvalUpdate1DateTime]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStartedAt: function (cDAMWMESingleFieldWorksheetEvalUpdate1DateTime, options) {
            return localVarFp.updateStartedAt(cDAMWMESingleFieldWorksheetEvalUpdate1DateTime, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.WorksheetEvalApiFactory = WorksheetEvalApiFactory;
/**
 * WorksheetEvalApi - object-oriented interface
 * @export
 * @class WorksheetEvalApi
 * @extends {BaseAPI}
 */
var WorksheetEvalApi = /** @class */ (function (_super) {
    __extends(WorksheetEvalApi, _super);
    function WorksheetEvalApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {boolean} [isDeleted]
     * @param {CDAMWMWorksheetEvalModel} [cDAMWMWorksheetEvalModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetEvalApi
     */
    WorksheetEvalApi.prototype._delete = function (isDeleted, cDAMWMWorksheetEvalModel, options) {
        var _this = this;
        return (0, exports.WorksheetEvalApiFp)(this.configuration)._delete(isDeleted, cDAMWMWorksheetEvalModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CCMWECreateDeliveryForEvalParameters} [cCMWECreateDeliveryForEvalParameters]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetEvalApi
     */
    WorksheetEvalApi.prototype.createDeliveryForEval = function (cCMWECreateDeliveryForEvalParameters, options) {
        var _this = this;
        return (0, exports.WorksheetEvalApiFp)(this.configuration).createDeliveryForEval(cCMWECreateDeliveryForEvalParameters, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [evalId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetEvalApi
     */
    WorksheetEvalApi.prototype.deletePermanentlyById = function (evalId, options) {
        var _this = this;
        return (0, exports.WorksheetEvalApiFp)(this.configuration).deletePermanentlyById(evalId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {any} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetEvalApi
     */
    WorksheetEvalApi.prototype.findByIdObject = function (body, options) {
        var _this = this;
        return (0, exports.WorksheetEvalApiFp)(this.configuration).findByIdObject(body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [worksheetId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetEvalApi
     */
    WorksheetEvalApi.prototype.findOrCreateEvalByWorksheetId = function (worksheetId, options) {
        var _this = this;
        return (0, exports.WorksheetEvalApiFp)(this.configuration).findOrCreateEvalByWorksheetId(worksheetId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMWMWorksheetEvalModel} [cDAMWMWorksheetEvalModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetEvalApi
     */
    WorksheetEvalApi.prototype.insert = function (cDAMWMWorksheetEvalModel, options) {
        var _this = this;
        return (0, exports.WorksheetEvalApiFp)(this.configuration).insert(cDAMWMWorksheetEvalModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {Array<CDAMWMWorksheetEvalModel>} [cDAMWMWorksheetEvalModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetEvalApi
     */
    WorksheetEvalApi.prototype.merge = function (cDAMWMWorksheetEvalModel, options) {
        var _this = this;
        return (0, exports.WorksheetEvalApiFp)(this.configuration).merge(cDAMWMWorksheetEvalModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDCMGenericPagingParams} [cDCMGenericPagingParams]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetEvalApi
     */
    WorksheetEvalApi.prototype.pageAll = function (cDCMGenericPagingParams, options) {
        var _this = this;
        return (0, exports.WorksheetEvalApiFp)(this.configuration).pageAll(cDCMGenericPagingParams, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMWMWorksheetEvalModel} [cDAMWMWorksheetEvalModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetEvalApi
     */
    WorksheetEvalApi.prototype.save = function (cDAMWMWorksheetEvalModel, options) {
        var _this = this;
        return (0, exports.WorksheetEvalApiFp)(this.configuration).save(cDAMWMWorksheetEvalModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMWMWorksheetEvalModel} [cDAMWMWorksheetEvalModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetEvalApi
     */
    WorksheetEvalApi.prototype.update = function (cDAMWMWorksheetEvalModel, options) {
        var _this = this;
        return (0, exports.WorksheetEvalApiFp)(this.configuration).update(cDAMWMWorksheetEvalModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMWMESingleFieldWorksheetEvalUpdate1Boolean} [cDAMWMESingleFieldWorksheetEvalUpdate1Boolean]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetEvalApi
     */
    WorksheetEvalApi.prototype.updateAddressAck = function (cDAMWMESingleFieldWorksheetEvalUpdate1Boolean, options) {
        var _this = this;
        return (0, exports.WorksheetEvalApiFp)(this.configuration).updateAddressAck(cDAMWMESingleFieldWorksheetEvalUpdate1Boolean, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMWMESingleFieldWorksheetEvalUpdate1String} [cDAMWMESingleFieldWorksheetEvalUpdate1String]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetEvalApi
     */
    WorksheetEvalApi.prototype.updateAdminComment = function (cDAMWMESingleFieldWorksheetEvalUpdate1String, options) {
        var _this = this;
        return (0, exports.WorksheetEvalApiFp)(this.configuration).updateAdminComment(cDAMWMESingleFieldWorksheetEvalUpdate1String, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMWMESingleFieldWorksheetEvalUpdate1Boolean} [cDAMWMESingleFieldWorksheetEvalUpdate1Boolean]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetEvalApi
     */
    WorksheetEvalApi.prototype.updateContactAck = function (cDAMWMESingleFieldWorksheetEvalUpdate1Boolean, options) {
        var _this = this;
        return (0, exports.WorksheetEvalApiFp)(this.configuration).updateContactAck(cDAMWMESingleFieldWorksheetEvalUpdate1Boolean, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMWMESingleFieldWorksheetEvalUpdate1DateTime} [cDAMWMESingleFieldWorksheetEvalUpdate1DateTime]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetEvalApi
     */
    WorksheetEvalApi.prototype.updateEndedAt = function (cDAMWMESingleFieldWorksheetEvalUpdate1DateTime, options) {
        var _this = this;
        return (0, exports.WorksheetEvalApiFp)(this.configuration).updateEndedAt(cDAMWMESingleFieldWorksheetEvalUpdate1DateTime, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMWMEWorksheetEvalUpdate} [cDAMWMEWorksheetEvalUpdate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetEvalApi
     */
    WorksheetEvalApi.prototype.updateEval = function (cDAMWMEWorksheetEvalUpdate, options) {
        var _this = this;
        return (0, exports.WorksheetEvalApiFp)(this.configuration).updateEval(cDAMWMEWorksheetEvalUpdate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMWMEPartnerChangeWorksheetEvalUpdate} [cDAMWMEPartnerChangeWorksheetEvalUpdate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetEvalApi
     */
    WorksheetEvalApi.prototype.updatePartner = function (cDAMWMEPartnerChangeWorksheetEvalUpdate, options) {
        var _this = this;
        return (0, exports.WorksheetEvalApiFp)(this.configuration).updatePartner(cDAMWMEPartnerChangeWorksheetEvalUpdate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMWMESingleFieldWorksheetEvalUpdate1Boolean} [cDAMWMESingleFieldWorksheetEvalUpdate1Boolean]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetEvalApi
     */
    WorksheetEvalApi.prototype.updatePartnerAck = function (cDAMWMESingleFieldWorksheetEvalUpdate1Boolean, options) {
        var _this = this;
        return (0, exports.WorksheetEvalApiFp)(this.configuration).updatePartnerAck(cDAMWMESingleFieldWorksheetEvalUpdate1Boolean, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMWMEPartnerAddressChangeWorksheetEvalUpdate} [cDAMWMEPartnerAddressChangeWorksheetEvalUpdate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetEvalApi
     */
    WorksheetEvalApi.prototype.updatePartnerAddress = function (cDAMWMEPartnerAddressChangeWorksheetEvalUpdate, options) {
        var _this = this;
        return (0, exports.WorksheetEvalApiFp)(this.configuration).updatePartnerAddress(cDAMWMEPartnerAddressChangeWorksheetEvalUpdate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMWMEPartnerContactChangeWorksheetEvalUpdate} [cDAMWMEPartnerContactChangeWorksheetEvalUpdate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetEvalApi
     */
    WorksheetEvalApi.prototype.updatePartnerContact = function (cDAMWMEPartnerContactChangeWorksheetEvalUpdate, options) {
        var _this = this;
        return (0, exports.WorksheetEvalApiFp)(this.configuration).updatePartnerContact(cDAMWMEPartnerContactChangeWorksheetEvalUpdate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMWMESingleFieldWorksheetEvalUpdate1DateTime} [cDAMWMESingleFieldWorksheetEvalUpdate1DateTime]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetEvalApi
     */
    WorksheetEvalApi.prototype.updateStartedAt = function (cDAMWMESingleFieldWorksheetEvalUpdate1DateTime, options) {
        var _this = this;
        return (0, exports.WorksheetEvalApiFp)(this.configuration).updateStartedAt(cDAMWMESingleFieldWorksheetEvalUpdate1DateTime, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return WorksheetEvalApi;
}(base_1.BaseAPI));
exports.WorksheetEvalApi = WorksheetEvalApi;
/**
 * WorksheetPdfApi - axios parameter creator
 * @export
 */
var WorksheetPdfApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {string} [worksheetId]
         * @param {boolean} [hideCustomerSignature]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAdminPdf: function (worksheetId, hideCustomerSignature, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/WorksheetPdf/DownloadAdminPdf";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (worksheetId !== undefined) {
                                localVarQueryParameter['worksheetId'] = worksheetId;
                            }
                            if (hideCustomerSignature !== undefined) {
                                localVarQueryParameter['hideCustomerSignature'] = hideCustomerSignature;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [worksheetId]
         * @param {boolean} [hideCustomerSignature]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadCustomerPdf: function (worksheetId, hideCustomerSignature, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/WorksheetPdf/DownloadCustomerPdf";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (worksheetId !== undefined) {
                                localVarQueryParameter['worksheetId'] = worksheetId;
                            }
                            if (hideCustomerSignature !== undefined) {
                                localVarQueryParameter['hideCustomerSignature'] = hideCustomerSignature;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.WorksheetPdfApiAxiosParamCreator = WorksheetPdfApiAxiosParamCreator;
/**
 * WorksheetPdfApi - functional programming interface
 * @export
 */
var WorksheetPdfApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.WorksheetPdfApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {string} [worksheetId]
         * @param {boolean} [hideCustomerSignature]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAdminPdf: function (worksheetId, hideCustomerSignature, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.downloadAdminPdf(worksheetId, hideCustomerSignature, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [worksheetId]
         * @param {boolean} [hideCustomerSignature]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadCustomerPdf: function (worksheetId, hideCustomerSignature, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.downloadCustomerPdf(worksheetId, hideCustomerSignature, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.WorksheetPdfApiFp = WorksheetPdfApiFp;
/**
 * WorksheetPdfApi - factory interface
 * @export
 */
var WorksheetPdfApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.WorksheetPdfApiFp)(configuration);
    return {
        /**
         *
         * @param {string} [worksheetId]
         * @param {boolean} [hideCustomerSignature]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAdminPdf: function (worksheetId, hideCustomerSignature, options) {
            return localVarFp.downloadAdminPdf(worksheetId, hideCustomerSignature, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [worksheetId]
         * @param {boolean} [hideCustomerSignature]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadCustomerPdf: function (worksheetId, hideCustomerSignature, options) {
            return localVarFp.downloadCustomerPdf(worksheetId, hideCustomerSignature, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.WorksheetPdfApiFactory = WorksheetPdfApiFactory;
/**
 * WorksheetPdfApi - object-oriented interface
 * @export
 * @class WorksheetPdfApi
 * @extends {BaseAPI}
 */
var WorksheetPdfApi = /** @class */ (function (_super) {
    __extends(WorksheetPdfApi, _super);
    function WorksheetPdfApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {string} [worksheetId]
     * @param {boolean} [hideCustomerSignature]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetPdfApi
     */
    WorksheetPdfApi.prototype.downloadAdminPdf = function (worksheetId, hideCustomerSignature, options) {
        var _this = this;
        return (0, exports.WorksheetPdfApiFp)(this.configuration).downloadAdminPdf(worksheetId, hideCustomerSignature, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [worksheetId]
     * @param {boolean} [hideCustomerSignature]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetPdfApi
     */
    WorksheetPdfApi.prototype.downloadCustomerPdf = function (worksheetId, hideCustomerSignature, options) {
        var _this = this;
        return (0, exports.WorksheetPdfApiFp)(this.configuration).downloadCustomerPdf(worksheetId, hideCustomerSignature, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return WorksheetPdfApi;
}(base_1.BaseAPI));
exports.WorksheetPdfApi = WorksheetPdfApi;
/**
 * WorksheetSapSalesOrderApi - axios parameter creator
 * @export
 */
var WorksheetSapSalesOrderApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {number} [docEntry]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findForWorksheetByDocEntry: function (docEntry, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/WorksheetSapSalesOrder/FindForWorksheetByDocEntry";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (docEntry !== undefined) {
                                localVarQueryParameter['docEntry'] = docEntry;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDSMSSapSalesOrderForWorksheetSearchQueryParams} [cDSMSSapSalesOrderForWorksheetSearchQueryParams]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        findWorksheetSearchPage: function (cDSMSSapSalesOrderForWorksheetSearchQueryParams, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/WorksheetSapSalesOrder/FindWorksheetSearchPage";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDSMSSapSalesOrderForWorksheetSearchQueryParams, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.WorksheetSapSalesOrderApiAxiosParamCreator = WorksheetSapSalesOrderApiAxiosParamCreator;
/**
 * WorksheetSapSalesOrderApi - functional programming interface
 * @export
 */
var WorksheetSapSalesOrderApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.WorksheetSapSalesOrderApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {number} [docEntry]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findForWorksheetByDocEntry: function (docEntry, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findForWorksheetByDocEntry(docEntry, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDSMSSapSalesOrderForWorksheetSearchQueryParams} [cDSMSSapSalesOrderForWorksheetSearchQueryParams]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        findWorksheetSearchPage: function (cDSMSSapSalesOrderForWorksheetSearchQueryParams, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findWorksheetSearchPage(cDSMSSapSalesOrderForWorksheetSearchQueryParams, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.WorksheetSapSalesOrderApiFp = WorksheetSapSalesOrderApiFp;
/**
 * WorksheetSapSalesOrderApi - factory interface
 * @export
 */
var WorksheetSapSalesOrderApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.WorksheetSapSalesOrderApiFp)(configuration);
    return {
        /**
         *
         * @param {number} [docEntry]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findForWorksheetByDocEntry: function (docEntry, options) {
            return localVarFp.findForWorksheetByDocEntry(docEntry, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDSMSSapSalesOrderForWorksheetSearchQueryParams} [cDSMSSapSalesOrderForWorksheetSearchQueryParams]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        findWorksheetSearchPage: function (cDSMSSapSalesOrderForWorksheetSearchQueryParams, options) {
            return localVarFp.findWorksheetSearchPage(cDSMSSapSalesOrderForWorksheetSearchQueryParams, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.WorksheetSapSalesOrderApiFactory = WorksheetSapSalesOrderApiFactory;
/**
 * WorksheetSapSalesOrderApi - object-oriented interface
 * @export
 * @class WorksheetSapSalesOrderApi
 * @extends {BaseAPI}
 */
var WorksheetSapSalesOrderApi = /** @class */ (function (_super) {
    __extends(WorksheetSapSalesOrderApi, _super);
    function WorksheetSapSalesOrderApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {number} [docEntry]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetSapSalesOrderApi
     */
    WorksheetSapSalesOrderApi.prototype.findForWorksheetByDocEntry = function (docEntry, options) {
        var _this = this;
        return (0, exports.WorksheetSapSalesOrderApiFp)(this.configuration).findForWorksheetByDocEntry(docEntry, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDSMSSapSalesOrderForWorksheetSearchQueryParams} [cDSMSSapSalesOrderForWorksheetSearchQueryParams]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof WorksheetSapSalesOrderApi
     */
    WorksheetSapSalesOrderApi.prototype.findWorksheetSearchPage = function (cDSMSSapSalesOrderForWorksheetSearchQueryParams, options) {
        var _this = this;
        return (0, exports.WorksheetSapSalesOrderApiFp)(this.configuration).findWorksheetSearchPage(cDSMSSapSalesOrderForWorksheetSearchQueryParams, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return WorksheetSapSalesOrderApi;
}(base_1.BaseAPI));
exports.WorksheetSapSalesOrderApi = WorksheetSapSalesOrderApi;
/**
 * WorksheetTimeItemCodeApi - axios parameter creator
 * @export
 */
var WorksheetTimeItemCodeApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {boolean} [isDeleted]
         * @param {CDAMWMWorksheetTimeItemCodeModel} [cDAMWMWorksheetTimeItemCodeModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: function (isDeleted, cDAMWMWorksheetTimeItemCodeModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/WorksheetTimeItemCode/Delete";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (isDeleted !== undefined) {
                                localVarQueryParameter['isDeleted'] = isDeleted;
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMWorksheetTimeItemCodeModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePermanently: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/WorksheetTimeItemCode/DeletePermanently";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (id !== undefined) {
                                localVarQueryParameter['id'] = id;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {any} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdObject: function (body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/WorksheetTimeItemCode/FindByIdObject";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(body, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMWorksheetTimeItemCodeModel} [cDAMWMWorksheetTimeItemCodeModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insert: function (cDAMWMWorksheetTimeItemCodeModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/WorksheetTimeItemCode/Insert";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMWorksheetTimeItemCodeModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<CDAMWMWorksheetTimeItemCodeModel>} [cDAMWMWorksheetTimeItemCodeModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merge: function (cDAMWMWorksheetTimeItemCodeModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/WorksheetTimeItemCode/Merge";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMWorksheetTimeItemCodeModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDCMGenericPagingParams} [cDCMGenericPagingParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageAll: function (cDCMGenericPagingParams, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/WorksheetTimeItemCode/PageAll";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDCMGenericPagingParams, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMWorksheetTimeItemCodeModel} [cDAMWMWorksheetTimeItemCodeModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: function (cDAMWMWorksheetTimeItemCodeModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/WorksheetTimeItemCode/Save";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMWorksheetTimeItemCodeModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMWorksheetTimeItemCodeModel} [cDAMWMWorksheetTimeItemCodeModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: function (cDAMWMWorksheetTimeItemCodeModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/WorksheetTimeItemCode/Update";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMWorksheetTimeItemCodeModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.WorksheetTimeItemCodeApiAxiosParamCreator = WorksheetTimeItemCodeApiAxiosParamCreator;
/**
 * WorksheetTimeItemCodeApi - functional programming interface
 * @export
 */
var WorksheetTimeItemCodeApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.WorksheetTimeItemCodeApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {boolean} [isDeleted]
         * @param {CDAMWMWorksheetTimeItemCodeModel} [cDAMWMWorksheetTimeItemCodeModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: function (isDeleted, cDAMWMWorksheetTimeItemCodeModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator._delete(isDeleted, cDAMWMWorksheetTimeItemCodeModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePermanently: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deletePermanently(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {any} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdObject: function (body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findByIdObject(body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMWorksheetTimeItemCodeModel} [cDAMWMWorksheetTimeItemCodeModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insert: function (cDAMWMWorksheetTimeItemCodeModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.insert(cDAMWMWorksheetTimeItemCodeModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<CDAMWMWorksheetTimeItemCodeModel>} [cDAMWMWorksheetTimeItemCodeModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merge: function (cDAMWMWorksheetTimeItemCodeModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.merge(cDAMWMWorksheetTimeItemCodeModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDCMGenericPagingParams} [cDCMGenericPagingParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageAll: function (cDCMGenericPagingParams, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pageAll(cDCMGenericPagingParams, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMWorksheetTimeItemCodeModel} [cDAMWMWorksheetTimeItemCodeModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: function (cDAMWMWorksheetTimeItemCodeModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.save(cDAMWMWorksheetTimeItemCodeModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMWorksheetTimeItemCodeModel} [cDAMWMWorksheetTimeItemCodeModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: function (cDAMWMWorksheetTimeItemCodeModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.update(cDAMWMWorksheetTimeItemCodeModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.WorksheetTimeItemCodeApiFp = WorksheetTimeItemCodeApiFp;
/**
 * WorksheetTimeItemCodeApi - factory interface
 * @export
 */
var WorksheetTimeItemCodeApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.WorksheetTimeItemCodeApiFp)(configuration);
    return {
        /**
         *
         * @param {boolean} [isDeleted]
         * @param {CDAMWMWorksheetTimeItemCodeModel} [cDAMWMWorksheetTimeItemCodeModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: function (isDeleted, cDAMWMWorksheetTimeItemCodeModel, options) {
            return localVarFp._delete(isDeleted, cDAMWMWorksheetTimeItemCodeModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePermanently: function (id, options) {
            return localVarFp.deletePermanently(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {any} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdObject: function (body, options) {
            return localVarFp.findByIdObject(body, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMWMWorksheetTimeItemCodeModel} [cDAMWMWorksheetTimeItemCodeModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insert: function (cDAMWMWorksheetTimeItemCodeModel, options) {
            return localVarFp.insert(cDAMWMWorksheetTimeItemCodeModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {Array<CDAMWMWorksheetTimeItemCodeModel>} [cDAMWMWorksheetTimeItemCodeModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merge: function (cDAMWMWorksheetTimeItemCodeModel, options) {
            return localVarFp.merge(cDAMWMWorksheetTimeItemCodeModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDCMGenericPagingParams} [cDCMGenericPagingParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageAll: function (cDCMGenericPagingParams, options) {
            return localVarFp.pageAll(cDCMGenericPagingParams, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMWMWorksheetTimeItemCodeModel} [cDAMWMWorksheetTimeItemCodeModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: function (cDAMWMWorksheetTimeItemCodeModel, options) {
            return localVarFp.save(cDAMWMWorksheetTimeItemCodeModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMWMWorksheetTimeItemCodeModel} [cDAMWMWorksheetTimeItemCodeModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: function (cDAMWMWorksheetTimeItemCodeModel, options) {
            return localVarFp.update(cDAMWMWorksheetTimeItemCodeModel, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.WorksheetTimeItemCodeApiFactory = WorksheetTimeItemCodeApiFactory;
/**
 * WorksheetTimeItemCodeApi - object-oriented interface
 * @export
 * @class WorksheetTimeItemCodeApi
 * @extends {BaseAPI}
 */
var WorksheetTimeItemCodeApi = /** @class */ (function (_super) {
    __extends(WorksheetTimeItemCodeApi, _super);
    function WorksheetTimeItemCodeApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {boolean} [isDeleted]
     * @param {CDAMWMWorksheetTimeItemCodeModel} [cDAMWMWorksheetTimeItemCodeModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetTimeItemCodeApi
     */
    WorksheetTimeItemCodeApi.prototype._delete = function (isDeleted, cDAMWMWorksheetTimeItemCodeModel, options) {
        var _this = this;
        return (0, exports.WorksheetTimeItemCodeApiFp)(this.configuration)._delete(isDeleted, cDAMWMWorksheetTimeItemCodeModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [id]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetTimeItemCodeApi
     */
    WorksheetTimeItemCodeApi.prototype.deletePermanently = function (id, options) {
        var _this = this;
        return (0, exports.WorksheetTimeItemCodeApiFp)(this.configuration).deletePermanently(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {any} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetTimeItemCodeApi
     */
    WorksheetTimeItemCodeApi.prototype.findByIdObject = function (body, options) {
        var _this = this;
        return (0, exports.WorksheetTimeItemCodeApiFp)(this.configuration).findByIdObject(body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMWMWorksheetTimeItemCodeModel} [cDAMWMWorksheetTimeItemCodeModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetTimeItemCodeApi
     */
    WorksheetTimeItemCodeApi.prototype.insert = function (cDAMWMWorksheetTimeItemCodeModel, options) {
        var _this = this;
        return (0, exports.WorksheetTimeItemCodeApiFp)(this.configuration).insert(cDAMWMWorksheetTimeItemCodeModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {Array<CDAMWMWorksheetTimeItemCodeModel>} [cDAMWMWorksheetTimeItemCodeModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetTimeItemCodeApi
     */
    WorksheetTimeItemCodeApi.prototype.merge = function (cDAMWMWorksheetTimeItemCodeModel, options) {
        var _this = this;
        return (0, exports.WorksheetTimeItemCodeApiFp)(this.configuration).merge(cDAMWMWorksheetTimeItemCodeModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDCMGenericPagingParams} [cDCMGenericPagingParams]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetTimeItemCodeApi
     */
    WorksheetTimeItemCodeApi.prototype.pageAll = function (cDCMGenericPagingParams, options) {
        var _this = this;
        return (0, exports.WorksheetTimeItemCodeApiFp)(this.configuration).pageAll(cDCMGenericPagingParams, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMWMWorksheetTimeItemCodeModel} [cDAMWMWorksheetTimeItemCodeModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetTimeItemCodeApi
     */
    WorksheetTimeItemCodeApi.prototype.save = function (cDAMWMWorksheetTimeItemCodeModel, options) {
        var _this = this;
        return (0, exports.WorksheetTimeItemCodeApiFp)(this.configuration).save(cDAMWMWorksheetTimeItemCodeModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMWMWorksheetTimeItemCodeModel} [cDAMWMWorksheetTimeItemCodeModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetTimeItemCodeApi
     */
    WorksheetTimeItemCodeApi.prototype.update = function (cDAMWMWorksheetTimeItemCodeModel, options) {
        var _this = this;
        return (0, exports.WorksheetTimeItemCodeApiFp)(this.configuration).update(cDAMWMWorksheetTimeItemCodeModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return WorksheetTimeItemCodeApi;
}(base_1.BaseAPI));
exports.WorksheetTimeItemCodeApi = WorksheetTimeItemCodeApi;
/**
 * WorksheetTripItemCodeApi - axios parameter creator
 * @export
 */
var WorksheetTripItemCodeApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {boolean} [isDeleted]
         * @param {CDAMWMWorksheetTripItemCodeModel} [cDAMWMWorksheetTripItemCodeModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: function (isDeleted, cDAMWMWorksheetTripItemCodeModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/WorksheetTripItemCode/Delete";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (isDeleted !== undefined) {
                                localVarQueryParameter['isDeleted'] = isDeleted;
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMWorksheetTripItemCodeModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePermanently: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/WorksheetTripItemCode/DeletePermanently";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            if (id !== undefined) {
                                localVarQueryParameter['id'] = id;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {any} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdObject: function (body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/WorksheetTripItemCode/FindByIdObject";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(body, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMWorksheetTripItemCodeModel} [cDAMWMWorksheetTripItemCodeModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insert: function (cDAMWMWorksheetTripItemCodeModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/WorksheetTripItemCode/Insert";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMWorksheetTripItemCodeModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<CDAMWMWorksheetTripItemCodeModel>} [cDAMWMWorksheetTripItemCodeModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merge: function (cDAMWMWorksheetTripItemCodeModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/WorksheetTripItemCode/Merge";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMWorksheetTripItemCodeModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDCMGenericPagingParams} [cDCMGenericPagingParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageAll: function (cDCMGenericPagingParams, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/WorksheetTripItemCode/PageAll";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDCMGenericPagingParams, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMWorksheetTripItemCodeModel} [cDAMWMWorksheetTripItemCodeModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: function (cDAMWMWorksheetTripItemCodeModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/WorksheetTripItemCode/Save";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMWorksheetTripItemCodeModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMWorksheetTripItemCodeModel} [cDAMWMWorksheetTripItemCodeModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: function (cDAMWMWorksheetTripItemCodeModel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/Worksheet/WorksheetTripItemCode/Update";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication oauth2 required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "oauth2", [], configuration)];
                        case 1:
                            // authentication oauth2 required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cDAMWMWorksheetTripItemCodeModel, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.WorksheetTripItemCodeApiAxiosParamCreator = WorksheetTripItemCodeApiAxiosParamCreator;
/**
 * WorksheetTripItemCodeApi - functional programming interface
 * @export
 */
var WorksheetTripItemCodeApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.WorksheetTripItemCodeApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {boolean} [isDeleted]
         * @param {CDAMWMWorksheetTripItemCodeModel} [cDAMWMWorksheetTripItemCodeModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: function (isDeleted, cDAMWMWorksheetTripItemCodeModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator._delete(isDeleted, cDAMWMWorksheetTripItemCodeModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePermanently: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deletePermanently(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {any} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdObject: function (body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findByIdObject(body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMWorksheetTripItemCodeModel} [cDAMWMWorksheetTripItemCodeModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insert: function (cDAMWMWorksheetTripItemCodeModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.insert(cDAMWMWorksheetTripItemCodeModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {Array<CDAMWMWorksheetTripItemCodeModel>} [cDAMWMWorksheetTripItemCodeModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merge: function (cDAMWMWorksheetTripItemCodeModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.merge(cDAMWMWorksheetTripItemCodeModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDCMGenericPagingParams} [cDCMGenericPagingParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageAll: function (cDCMGenericPagingParams, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pageAll(cDCMGenericPagingParams, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMWorksheetTripItemCodeModel} [cDAMWMWorksheetTripItemCodeModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: function (cDAMWMWorksheetTripItemCodeModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.save(cDAMWMWorksheetTripItemCodeModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CDAMWMWorksheetTripItemCodeModel} [cDAMWMWorksheetTripItemCodeModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: function (cDAMWMWorksheetTripItemCodeModel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.update(cDAMWMWorksheetTripItemCodeModel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.WorksheetTripItemCodeApiFp = WorksheetTripItemCodeApiFp;
/**
 * WorksheetTripItemCodeApi - factory interface
 * @export
 */
var WorksheetTripItemCodeApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.WorksheetTripItemCodeApiFp)(configuration);
    return {
        /**
         *
         * @param {boolean} [isDeleted]
         * @param {CDAMWMWorksheetTripItemCodeModel} [cDAMWMWorksheetTripItemCodeModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: function (isDeleted, cDAMWMWorksheetTripItemCodeModel, options) {
            return localVarFp._delete(isDeleted, cDAMWMWorksheetTripItemCodeModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePermanently: function (id, options) {
            return localVarFp.deletePermanently(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {any} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdObject: function (body, options) {
            return localVarFp.findByIdObject(body, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMWMWorksheetTripItemCodeModel} [cDAMWMWorksheetTripItemCodeModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insert: function (cDAMWMWorksheetTripItemCodeModel, options) {
            return localVarFp.insert(cDAMWMWorksheetTripItemCodeModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {Array<CDAMWMWorksheetTripItemCodeModel>} [cDAMWMWorksheetTripItemCodeModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merge: function (cDAMWMWorksheetTripItemCodeModel, options) {
            return localVarFp.merge(cDAMWMWorksheetTripItemCodeModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDCMGenericPagingParams} [cDCMGenericPagingParams]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageAll: function (cDCMGenericPagingParams, options) {
            return localVarFp.pageAll(cDCMGenericPagingParams, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMWMWorksheetTripItemCodeModel} [cDAMWMWorksheetTripItemCodeModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: function (cDAMWMWorksheetTripItemCodeModel, options) {
            return localVarFp.save(cDAMWMWorksheetTripItemCodeModel, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CDAMWMWorksheetTripItemCodeModel} [cDAMWMWorksheetTripItemCodeModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: function (cDAMWMWorksheetTripItemCodeModel, options) {
            return localVarFp.update(cDAMWMWorksheetTripItemCodeModel, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.WorksheetTripItemCodeApiFactory = WorksheetTripItemCodeApiFactory;
/**
 * WorksheetTripItemCodeApi - object-oriented interface
 * @export
 * @class WorksheetTripItemCodeApi
 * @extends {BaseAPI}
 */
var WorksheetTripItemCodeApi = /** @class */ (function (_super) {
    __extends(WorksheetTripItemCodeApi, _super);
    function WorksheetTripItemCodeApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {boolean} [isDeleted]
     * @param {CDAMWMWorksheetTripItemCodeModel} [cDAMWMWorksheetTripItemCodeModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetTripItemCodeApi
     */
    WorksheetTripItemCodeApi.prototype._delete = function (isDeleted, cDAMWMWorksheetTripItemCodeModel, options) {
        var _this = this;
        return (0, exports.WorksheetTripItemCodeApiFp)(this.configuration)._delete(isDeleted, cDAMWMWorksheetTripItemCodeModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [id]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetTripItemCodeApi
     */
    WorksheetTripItemCodeApi.prototype.deletePermanently = function (id, options) {
        var _this = this;
        return (0, exports.WorksheetTripItemCodeApiFp)(this.configuration).deletePermanently(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {any} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetTripItemCodeApi
     */
    WorksheetTripItemCodeApi.prototype.findByIdObject = function (body, options) {
        var _this = this;
        return (0, exports.WorksheetTripItemCodeApiFp)(this.configuration).findByIdObject(body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMWMWorksheetTripItemCodeModel} [cDAMWMWorksheetTripItemCodeModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetTripItemCodeApi
     */
    WorksheetTripItemCodeApi.prototype.insert = function (cDAMWMWorksheetTripItemCodeModel, options) {
        var _this = this;
        return (0, exports.WorksheetTripItemCodeApiFp)(this.configuration).insert(cDAMWMWorksheetTripItemCodeModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {Array<CDAMWMWorksheetTripItemCodeModel>} [cDAMWMWorksheetTripItemCodeModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetTripItemCodeApi
     */
    WorksheetTripItemCodeApi.prototype.merge = function (cDAMWMWorksheetTripItemCodeModel, options) {
        var _this = this;
        return (0, exports.WorksheetTripItemCodeApiFp)(this.configuration).merge(cDAMWMWorksheetTripItemCodeModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDCMGenericPagingParams} [cDCMGenericPagingParams]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetTripItemCodeApi
     */
    WorksheetTripItemCodeApi.prototype.pageAll = function (cDCMGenericPagingParams, options) {
        var _this = this;
        return (0, exports.WorksheetTripItemCodeApiFp)(this.configuration).pageAll(cDCMGenericPagingParams, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMWMWorksheetTripItemCodeModel} [cDAMWMWorksheetTripItemCodeModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetTripItemCodeApi
     */
    WorksheetTripItemCodeApi.prototype.save = function (cDAMWMWorksheetTripItemCodeModel, options) {
        var _this = this;
        return (0, exports.WorksheetTripItemCodeApiFp)(this.configuration).save(cDAMWMWorksheetTripItemCodeModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CDAMWMWorksheetTripItemCodeModel} [cDAMWMWorksheetTripItemCodeModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorksheetTripItemCodeApi
     */
    WorksheetTripItemCodeApi.prototype.update = function (cDAMWMWorksheetTripItemCodeModel, options) {
        var _this = this;
        return (0, exports.WorksheetTripItemCodeApiFp)(this.configuration).update(cDAMWMWorksheetTripItemCodeModel, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return WorksheetTripItemCodeApi;
}(base_1.BaseAPI));
exports.WorksheetTripItemCodeApi = WorksheetTripItemCodeApi;
